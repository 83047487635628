import {ReactNode} from 'react';
import {useDragLayer} from 'react-dnd';
import styled from 'styled-components';

const LayerContainer = styled.div`
  position: fixed;
  pointer-events: none;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 915px;
`;

const CustomPreviewContainer = styled.div``;

interface CustomDragLayerProps {
  renderPreview: (item: any) => ReactNode;
}
export function CustomDragLayer({renderPreview}: CustomDragLayerProps) {
  const {isDragging, item, currentOffset} = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging) return null;

  return (
    <LayerContainer>
      <CustomPreviewContainer
        style={{
          transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
        }}>
        {renderPreview(item)}
      </CustomPreviewContainer>
    </LayerContainer>
  );
}
