import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {i18n, k} from '@i18n/translate';

interface IButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  tag?: ButtonTags;
  title?: string;
}
interface IButtonWithIsPrimary extends IButtonProps {
  isPrimary?: boolean;
}

// See Details
export function ViewDetailsButton({disabled, onClick, title}: IButtonProps) {
  return (
    <LearnInButton
      disabled={disabled}
      onClick={onClick}
      tag={ButtonTags.Link}
      aria-label={i18n.t(k.CTA__VIEW_DETAILS_ITEM__FORMAT, {
        item: title,
      })}>
      {i18n.t(k.CTA__VIEW__DETAIL__PLURAL)}
    </LearnInButton>
  );
}

// Select Program previously Add to plan
export function SelectProgramButton({
  disabled,
  onClick,
  tag = ButtonTags.Secondary,
}: IButtonProps) {
  return (
    <LearnInButton tag={tag} disabled={disabled} onClick={onClick}>
      {i18n.t(k.PROGRAM__SELECT)}
    </LearnInButton>
  );
}

// Selected
export function SelectedButton({disabled, onClick}: IButtonProps) {
  return (
    <LearnInButton
      aria-label={i18n.t(k.PROGRAM__REMOVE_FROM_PLAN)}
      disabled={disabled}
      onClick={onClick}
      tag={ButtonTags.Primary}>
      {i18n.t(k.STATUS__SELECTED)}
    </LearnInButton>
  );
}

// Add To Initiative
export function AddToInitiativeButton({disabled, onClick}: IButtonProps) {
  return (
    <LearnInButton
      disabled={disabled}
      onClick={onClick}
      tag={ButtonTags.Secondary}>
      {i18n.t(k.INITIATIVE__ADD_TO)}
    </LearnInButton>
  );
}

// Request License
export function RequestLicenseButton({
  disabled,
  onClick,
  isPrimary = false,
  title,
}: IButtonWithIsPrimary) {
  return (
    <LearnInButton
      disabled={disabled}
      tag={isPrimary ? ButtonTags.Primary : ButtonTags.Secondary}
      onClick={onClick}
      aria-label={i18n.t(k.APPROVAL__REQUEST__FORMAT, {item: title})}>
      {i18n.t(k.REQUEST__APPROVAL)}
    </LearnInButton>
  );
}

// View License
export function ViewLicenseButton({
  disabled,
  onClick,
  isPrimary = false,
}: IButtonWithIsPrimary) {
  return (
    <LearnInButton
      disabled={disabled}
      tag={isPrimary ? ButtonTags.Primary : ButtonTags.Secondary}
      onClick={onClick}>
      {i18n.t(k.PROGRAM__ACCESS)}
    </LearnInButton>
  );
}

// Request Approval
export function RequestApprovalButton({
  disabled,
  onClick,
  tag = ButtonTags.Secondary,
  title,
}: IButtonProps) {
  return (
    <LearnInButton
      disabled={disabled}
      tag={tag}
      onClick={onClick}
      aria-label={i18n.t(k.APPROVAL__REQUEST__FORMAT, {item: title})}>
      {i18n.t(k.REQUEST__APPROVAL)}
    </LearnInButton>
  );
}

export function ResubmitApprovalButton({disabled, onClick}: IButtonProps) {
  return (
    <LearnInButton
      disabled={disabled}
      tag={ButtonTags.Secondary}
      onClick={onClick}>
      {i18n.t(k.REQUEST__RESUBMIT)}
    </LearnInButton>
  );
}

// Go To Custom Program
export function GoToCustomProgramButton({
  disabled,
  onClick,
  title,
}: IButtonProps) {
  return (
    <LearnInButton
      aria-label={i18n.t(k.CTA__GO_TO_ITEM__FORMAT, {item: title})}
      data-testid="selected-button"
      disabled={disabled}
      onClick={onClick}
      tag={ButtonTags.Primary}>
      {i18n.t(k.PROGRAM__GO_TO)}
    </LearnInButton>
  );
}

// View Custom Program
export function ViewCustomProgramButton({
  disabled,
  onClick,
  title,
}: IButtonProps) {
  return (
    <LearnInButton
      aria-label={i18n.t(k.PROGRAM__CUSTOM__VIEW__FORMAT, {item: title})}
      tag={ButtonTags.Secondary}
      disabled={disabled}
      onClick={onClick}>
      {i18n.t(k.CTA__VIEW_ITEM__FORMAT, {item: i18n.t(k.PROGRAM)})}
    </LearnInButton>
  );
}

// View Custom Program
export function VisitProgramButton({disabled, onClick}: IButtonProps) {
  return (
    <LearnInButton
      tag={ButtonTags.Secondary}
      disabled={disabled}
      onClick={onClick}>
      {i18n.t(k.PROGRAM__WEBSITE__VIEW)}
    </LearnInButton>
  );
}
