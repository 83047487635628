import {useRef, useState, useEffect, useLayoutEffect} from 'react';
import ReactQuill from 'react-quill';
import {KEY_CODES} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {a11ySelectableSelector} from '@utils/a11yUtils';

const LearnInQuill = (props) => {
  const ButtonMapping = {
    header: 'A11Y__HEADING',
    bold: 'A11Y__BOLD',
    italic: 'A11Y__ITALIC',
    underline: 'A11Y__UNDERLINE',
    list: 'A11Y__LIST',
    ordered: 'A11Y__ORDERED',
    bullet: 'A11Y__BULLET',
    clean: 'A11Y__CLEAN',
    link: 'GENERIC__LINK',
  };
  const [isInitialRender, setIsInitialRender] = useState(true);
  useLayoutEffect(() => setIsInitialRender(false), []);
  const quillContainerRef = useRef(null);

  const handleFocus = (itemRef) => {
    const focusable = itemRef.nextElementSibling?.querySelector(
      a11ySelectableSelector
    );
    focusable ? focusable.focus() : handleFocus(itemRef.parentNode);
  };

  const handleKeyboardNavigation = (e) => {
    const focusableToolbarItem = e.target.querySelector('.ql-toolbar');
    const inEditor = document.activeElement.classList.contains('ql-editor');
    switch (e.key) {
      case KEY_CODES.TAB:
        if (!e.shiftKey && (focusableToolbarItem || inEditor)) {
          e.preventDefault();
          handleFocus(quillContainerRef.current);
        }
        break;
      case KEY_CODES.ENTER:
        e.preventDefault();
        focusableToolbarItem.querySelector('[tabindex="0"], button').focus();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const quillButtons = document.getElementsByClassName('ql-formats');
    if (quillButtons) {
      for (const button of quillButtons) {
        for (let i = 0; i < button.children.length; i++) {
          const child = button.children[i] as HTMLInputElement;
          const name = child.className.replace('ql-', '');
          const value = child.value ?? '';
          child.setAttribute(
            'aria-label',
            `${i18n.t(k[ButtonMapping[name]])} ${i18n.t(k[ButtonMapping[value]])}`
          );
        }
      }
    }
  }, [isInitialRender]);

  return isInitialRender ? null : (
    // Intentional tabIndex for a11y; potential improvement: role attribute
    // eslint-disable-next-line
    <span
      aria-label={i18n.t(k.QUILL__ENTER_EDITOR)}
      onKeyDown={handleKeyboardNavigation}
      ref={quillContainerRef}
      // eslint-disable-next-line
      tabIndex={0}>
      <ReactQuill {...props} />
    </span>
  );
};

export default LearnInQuill;
