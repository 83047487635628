// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/InitiativeStatus.cs

enum InitiativeStatus {
  Draft,
  Published,
  Archived,
  Deleted,
}

export default InitiativeStatus;
