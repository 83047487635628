import MobileNavMenu from './MobileNavMenu';
import usePathInfo from '@hooks/usePathInfo';
import {useCustomProgramPreviewQuery} from '@generated/hooks';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {ContentType} from '@generated/enums';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useCustomProgramPermissionVMsQuery} from '@hooks/apiEndpoints/permission/queries';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface MobileNavMenuContainerProps {
  customProgramId: number;
  selectedSectionId: number;
  onClickSectionNavItem: (id: number) => void;
  onClickNavItem: (screen: CustomProgramScreens) => void;
  hideMobileNavMenu: () => void;
}

function MobileNavMenuContainer({
  customProgramId,
  selectedSectionId,
  onClickSectionNavItem,
  onClickNavItem,
  hideMobileNavMenu,
}: MobileNavMenuContainerProps) {
  const {isAdminPath} = usePathInfo();
  const {isAdmin} = useAuth();

  const {data: customProgram} = useCustomProgramPreviewQuery(
    {customProgramId, queryParams: {includeProgress: true}},
    {enabled: false}
  );

  const {data: customProgramPermissions} =
    useCustomProgramPermissionVMsQuery(null);

  const customProgramPermission = customProgramPermissions?.find(
    (program) => program.customProgramId === customProgramId
  );

  // Check if there are projects in program to show the tab
  const programHasProjects = customProgram?.sections?.some((section) => {
    return section?.content?.some((content) => {
      return content?.contentType === ContentType.Project;
    });
  });

  const showProjectsNavItem =
    programHasProjects &&
    (isAdmin || customProgramPermission?.canReviewProject);

  const sectionNavItems = customProgram?.sections?.map(({id, order, title}) => {
    return {
      id,
      order,
      title,
      onClick: () => onClickSectionNavItem(id),
      active: id === selectedSectionId,
    };
  });

  const navItems = [
    {
      label: CustomProgramScreens.Agenda,
      onClick: () => {
        onClickNavItem(CustomProgramScreens.Agenda);
        hideMobileNavMenu();
      },
    },
    showProjectsNavItem && {
      label: CustomProgramScreens.Projects,
      onClick: () => {
        onClickNavItem(CustomProgramScreens.Projects);
        hideMobileNavMenu();
      },
    },
  ];

  const onClickCurriculum = () => {
    onClickNavItem(CustomProgramScreens.Curriculum);
  };

  return (
    <MobileNavMenu
      customProgramId={customProgramId}
      allSections={customProgram?.sections || []}
      sectionNavItems={sectionNavItems}
      progressPercentage={isAdminPath ? 0 : customProgram?.progressPercentage}
      showReportsNav={isAdminPath}
      navItems={navItems}
      onClickCurriculum={onClickCurriculum}
    />
  );
}

export default MobileNavMenuContainer;
