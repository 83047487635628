import useFeatureFlags from './useFeatureFlags';
import {useAuth} from '@utils/oidc-auth-wrapper';

/**
 *
 * @example
 *
 * import useShowSkillsTagging from '@hooks/useShowSkillsTagging';
 *
 * const {showSkillsTagging} = useShowSkillsTagging();
 *
 */

export default function useShowSkillsTagging() {
  const {user} = useAuth();

  const {isFeatureFlagOn} = useFeatureFlags();

  return {
    showSkillsTagging:
      isFeatureFlagOn.CatalogContentAcademies &&
      !user?.isAcademiesIntegratedExperienceDisabled,
  };
}
