import {UpdateAcademyUserRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {UpdateAcademyUserRequestSchema} from '../zodSchemas/UpdateAcademyUserRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyUsersController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/users/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUsersPutMutation} from '@generated/hooks';
 *
 *  const academiesUsersPut = useAcademiesUsersPutMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesUsersPut.mutate({
 *    payload: {
 *      ...UpdateAcademyUserRequest
 *    },
 *    pathVars: {
 *      academyId,
 *      userCompanyId
 *    }
 *  })
 *
 */

export const useAcademiesUsersPutMutation = mutationHookFactory<
  UpdateAcademyUserRequest, // payload
  {academyId: number; userCompanyId: number}, // path variables
  null
>(
  'useAcademiesUsersPutMutation',
  'put',
  ({academyId, userCompanyId}) =>
    `academies/${academyId}/users/${userCompanyId}`,
  {payloadSchema: UpdateAcademyUserRequestSchema}
);
