import {useMemo} from 'react';
import {
  Container,
  LoadingContainer,
  Header,
  IconContainer,
  TableHeader,
} from './BulkNudgeDrawer.styled';
import {BulkNudgeDrawerViewProps} from './BulkNudgeDrawer.interfaces';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import Search from '@components/reusable/Search';
import LearnInTable from '@components/reusable/LearnInTable';
import {getInitialsOfName} from '@components/providers/utils';
import {Avatar, Button} from 'antd';
import {getAvatarColorwaysStyles} from '@utils/color';
import {ArrowRightOutlined, BuildOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {ToggleChevron} from '@components/reusable/Button/Button.style';
import {pxToRem} from '@utils/styleMixins';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import t from '@i18n/translate';

// css is sued to target the z-index, otherwise this drawer will not show up
import './BulkNudgeDrawer.scss';

function BulkNudgeDrawerView({
  isLoading,
  onChangeSearch,
  onClickShowList,
  participants,
  participantsTotalCount,
  searchValue,
  showList,
  name,
  onClickPreview,
  ...props
}: BulkNudgeDrawerViewProps) {
  // Table Columns
  const columns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'participant',
        key: 'name',
        render: (_: any, name: string) => {
          return (
            <>
              <Avatar
                style={{
                  ...getAvatarColorwaysStyles(name?.length),
                  marginRight: '8px',
                  flexShrink: 0,
                }}>
                {getInitialsOfName(name)}
              </Avatar>{' '}
              {name}
            </>
          );
        },
      },
    ],
    []
  );

  const SEARCH_PARTICIPANTS = t('CTA__SEARCH_ITEM__FORMAT', {
    item: t('PARTICIPANT__PLURAL'),
  });

  return (
    <Container>
      <LearnInDrawer
        {...props}
        closeButtonPlacement="right"
        responsiveSize={600}
        title={t('CTA__BULK_NUDGE')}
        footer={
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <LearnInButton
              data-testid="preview-email-button"
              icon={<ArrowRightOutlined />}
              iconAlign="right"
              onClick={onClickPreview}
              tag={ButtonTags.Primary}>
              {t('ACTION__PREVIEW_EMAIL')}
            </LearnInButton>
          </div>
        }>
        <div data-testid="bulk-nudge-drawer-participants-container">
          <Header>
            <IconContainer>
              <BuildOutlined
                style={{color: COLORS.Yellow600, fontWeight: 648}}
              />
            </IconContainer>
            <span
              style={{marginRight: 6}}
              dangerouslySetInnerHTML={{
                __html: `${t('NUDGE_EMAIL__PARTICIPANTS_NOT_STARTED')} ${name}`,
              }}></span>
          </Header>

          {/* Loading Icon */}
          {isLoading ? (
            <LoadingContainer>
              <LoadingSpinner data-testid="bulk-nudge-drawer-loading-spinner" />
            </LoadingContainer>
          ) : (
            <>
              {/* Number of Participants and Hide Table Toggle */}
              <TableHeader>
                <div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('NUDGE_EMAIL__PARTICIPANTS_COUNT__FORMAT', {
                        count: String(participantsTotalCount),
                      }),
                    }}></span>
                </div>
                <div>
                  <Button
                    style={{
                      alignItems: 'center',
                      color: COLORS.Blue800,
                      display: 'flex',
                      fontSize: pxToRem(16),
                    }}
                    onClick={onClickShowList}
                    type="text"
                    data-testid="show-list-button">
                    {showList ? t('CTA__HIDE') : t('CTA__SHOW')}{' '}
                    {t('GENERIC__LIST')}{' '}
                    <ToggleChevron
                      alt="show-participants-table-chevron"
                      openDirection="up"
                      closeDirection="down"
                      open={showList}
                      style={{
                        marginLeft: '9px',
                        color: COLORS.Blue800,
                        fontSize: pxToRem(24),
                      }}
                    />
                  </Button>
                </div>
              </TableHeader>

              {/* Search Input and Table */}
              {showList && (
                <>
                  <Search
                    buttonAriaLabel={SEARCH_PARTICIPANTS}
                    data-testid="participants-table-search-input"
                    id="participants-table-search-input"
                    label={SEARCH_PARTICIPANTS}
                    onChange={onChangeSearch}
                    placeholder={t('CTA__SEARCH_ITEM__FORMAT', {item: null})}
                    style={{width: '100%', marginBottom: 16}}
                    value={searchValue}
                  />
                  <LearnInTable
                    columns={columns}
                    data-testid="participants-table"
                    dataSource={participants}
                    showHeader={false}
                  />
                </>
              )}
            </>
          )}
        </div>
      </LearnInDrawer>
    </Container>
  );
}

export default BulkNudgeDrawerView;
