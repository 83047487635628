import {CurrencyCode} from './../enums';
import {AddCreditCardAuthorizationPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AddCreditCardAuthorizationPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAddCreditCardAuthorizationPayloadMockData(
  args?: Partial<AddCreditCardAuthorizationPayload>
): AddCreditCardAuthorizationPayload {
  return {
    amount: faker.datatype.number(),
    approved: faker.datatype.boolean(),
    cardId: faker.datatype.number(),
    currencyCode: CurrencyCode.USD,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AddCreditCardAuthorizationPayloadMockData: AddCreditCardAuthorizationPayload =
  {
    amount: 5,
    approved: true,
    cardId: 1,
    currencyCode: CurrencyCode.USD,
  };
