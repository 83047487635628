// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/AttachmentLinkType.cs

enum AttachmentLinkType {
  AcademyStepAttachment,
  AcademyStepDescription,
  CustomProgramContentAttachment,
  CustomProgramContentDescription,
  UserRequestDescription,
  UserPlanAcademyStepContent,
  UserPlanCustomProgramContent,
}

export default AttachmentLinkType;
