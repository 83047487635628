import {i18n, k} from '@i18n/translate';
import {SectionNavCardContainer} from '../SectionNavCard';
import {AcademyBrandVM} from '@generated/interfaces';
import {convertHexToHSL, getAcademyBrandingBorderColor} from '@utils/color';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface SectionNavBrandingDisplayProps {
  branding: AcademyBrandVM;
}

function SectionNavBrandingDisplay({branding}: SectionNavBrandingDisplayProps) {
  const borderColor = getAcademyBrandingBorderColor(
    branding?.itemDefaultTextNavColor
  );
  const backgroundColor = convertHexToHSL(
    branding?.itemDefaultTextNavColor,
    0.1
  );
  return (
    <>
      <SectionNavCardContainer
        title={i18n.t(k.SECTION__COMPLETED)}
        textColor={branding?.itemDefaultTextNavColor}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        hoverBorderColor={branding?.secondaryBrandColor}
        progressColor={branding?.secondaryBrandColor}
        showPercentComplete={true}
        percentComplete={100}
      />
      <SectionNavCardContainer
        title={i18n.t(k.SECTION__IN_PROGRESS)}
        textColor={branding?.itemSelectedTextNavColor}
        backgroundColor={branding?.secondaryBrandColor}
        borderColor={branding?.secondaryBrandColor}
        hoverBorderColor={branding?.secondaryBrandColor}
        progressColor={branding?.secondaryBrandColor}
        showPercentComplete={true}
        percentComplete={50}
      />
      <SectionNavCardContainer
        title={i18n.t(k.SECTION__UNSTARTED)}
        textColor={branding?.itemDefaultTextNavColor}
        backgroundColor={backgroundColor}
        borderColor={borderColor}
        hoverBorderColor={branding?.secondaryBrandColor}
        progressColor={branding?.secondaryBrandColor}
        showPercentComplete={true}
        percentComplete={0}
      />
    </>
  );
}

export default SectionNavBrandingDisplay;
