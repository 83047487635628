import * as React from 'react';
import {Divider} from 'antd';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {i18n, k} from '@i18n/translate';
import {LeftOutlined} from '@ant-design/icons';
import {useShowProductSwitcher} from '@hooks/useShowProductSwitcher';
import {useAuth} from '@utils/oidc-auth-wrapper';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Title = styled.h1`
  flex-grow: 1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem !important;
  color: #1b1c23;
  margin: 0;
  padding-left: 32px;
`;

const TitleBar = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 44px;
  padding: ${({fullWidth}) =>
    fullWidth
      ? '16px 0px 0px 0px !important;'
      : '20px 32px 0px 32px !important;'};
`;

const BackButton = styled.button`
  padding: 0;
  margin: 0 1.5rem;
  height: 3rem;
  & + h1 {
    padding-left: 0 !important;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

const StyledLeftOutlined = styled(LeftOutlined)`
  font-size: 1.333rem;
  margin-top: 2px;
`;

/*
|--------------------------------------------------------------------------
| Text Component
|--------------------------------------------------------------------------
*/

/*
 Note: This component has been updated to use the product switcher to allow users switch to the 
 Degreed LXP.  This is only a temporary solution to the product switcher which will be used internally 
 for demo purposes until the new redesigned product switcher is completed.
*/
export default function PageTitleBar({
  title,
  fullWidth = true,
  hideUnderline,
  jsxComponent,
  onClickBack,
  backElement,
}: {
  title: string;
  jsxComponent?: React.ReactNode;
  hideUnderline?: boolean;
  fullWidth?: boolean;
  onClickBack?: () => void;
  backElement?: React.ReactNode;
}) {
  const {user} = useAuth();
  const {isFeatureFlagOn} = useFeatureFlags();
  const productSwitcherComponent = useShowProductSwitcher(
    isFeatureFlagOn,
    user?.isAcademiesIntegratedExperienceDisabled
  );

  return (
    <>
      <TitleBar fullWidth={fullWidth}>
        {onClickBack && (
          <BackButton onClick={onClickBack}>
            {backElement ? (
              backElement
            ) : (
              <span aria-label={i18n.t(k.GENERIC__BACK)}>
                <StyledLeftOutlined aria-hidden="true" />
              </span>
            )}
          </BackButton>
        )}
        <Title fullWidth={fullWidth}>{title}</Title>
        <div
          style={{
            display: 'flex',
            marginRight: !!productSwitcherComponent ? '16px' : '32px',
          }}>
          {jsxComponent}
          {productSwitcherComponent}
        </div>
      </TitleBar>
      {!hideUnderline && (
        <div style={{padding: fullWidth ? '0px 0px' : '0px 32px'}}>
          <Divider
            style={{
              margin: `16px 0px 0px`,
              borderTop: `1px solid ${COLORS.Neutral200}`,
            }}
          />
        </div>
      )}
    </>
  );
}
