import '../../home/UserHome.scss';
import {i18n, k} from '@i18n/translate';
import {AcademyVM} from '@models/serverModels';
import styled, {css} from 'styled-components';
import {Carousel, Col} from 'antd';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import AcademiesCarouselCard, {
  ACADEMY_CARD_HEIGHT,
} from './AcademiesCarouselCard';
import {Breakpoints} from '@utils/responsiveUtils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const CarouselSection = styled.section<{rightEdgeCard: boolean}>`
  margin-top: -16px; // reposition up so shadow shows but spacing isn't added
  padding-bottom: 24px;
  width: 100%;
  ${({rightEdgeCard}) => {
    if (!rightEdgeCard) return null;
    return css`
      /* Add a cover-up right shadow since overflow-x is needed but cuts off the box-shadow */
      &::after {
        background: transparent;
        border-radius: 8px;
        box-shadow: 8px 0 14px 0 rgba(0, 0, 0, 0.1);
        content: ' ';
        height: ${ACADEMY_CARD_HEIGHT};
        overflow-y: hidden;
        position: absolute;
        right: 0;
        top: 16px;
        width: 24px;
        z-index: -1;
      }
    `;
  }}
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface AcademiesCarouselProps {
  userAcademies: AcademyVM[];
  onClickGoToAcademy: (academy) => void;
  onClickRemoveAcademy: (academy) => void;
}

function AcademiesCarousel({
  userAcademies,
  onClickGoToAcademy,
  onClickRemoveAcademy,
}: AcademiesCarouselProps) {
  const rightEdgeCard = userAcademies?.length > 1;

  return (
    <CarouselSection
      aria-roledescription="carousel"
      aria-label={i18n.t(k.A11Y__CAROUSEL__TYPE__FORMAT, {
        type: i18n.t(k.ACADEMY__PLURAL),
      })}
      rightEdgeCard={rightEdgeCard}>
      <Carousel
        slidesToShow={window.innerWidth > Breakpoints.Tablet ? 2 : 1}
        slidesToScroll={window.innerWidth > Breakpoints.Tablet ? 2 : 1}
        dots
        dotPosition="bottom"
        infinite={false}>
        {userAcademies?.map((academy, idx) => {
          return (
            <div key={academy.id}>
              <Col
                key={academy.id}
                sm={24}
                md={24}
                style={{
                  borderRadius: '4px',
                  padding: '6px',
                  paddingLeft: '0px',
                }}
                className={
                  (idx + 1) % 3 === 0
                    ? 'last-card-in-carousel ant-carousel-card-col'
                    : 'ant-carousel-card-col'
                }>
                <AcademiesCarouselCard
                  backgroundImage={
                    academy.academyProfileImageUrl ||
                    getPlaceholderImgSrc(academy.id)
                  }
                  title={academy.name}
                  description={academy.description}
                  id={academy.id}
                  onClickGoToAcademy={() => onClickGoToAcademy(academy)}
                  onClickRemoveAcademy={() => onClickRemoveAcademy(academy)}
                />
              </Col>
            </div>
          );
        })}
      </Carousel>
    </CarouselSection>
  );
}

export default AcademiesCarousel;
