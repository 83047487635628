import './Incentives.scss';
import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Col, Row, Skeleton, Tabs} from 'antd';
import IncentiveDataCard from './components/IncentiveDataCard';
import AddMoneyIncentiveDrawer from './components/AddMoneyIncentiveDrawer';
import {ProgramTypesVM} from './store/interfaces';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {
  getFinanceIncentiveDetailRq,
  getIncentiveAdminFinanceRq,
  getIncentiveAdminTimeRq,
} from '@store/apiEndpoints';
import {getProgramTypesRq} from '@store/apiEndpoints/program/queries';
import AddTimeIncentiveDrawer from './components/AddTimeIncentiveDrawer';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import AddPersonalLearningBudgetIncentiveDrawer from './components/AddPersonalLearningBudgetIncentiveDrawer.container';
import {AddMoneyIncentivePreSelectModal} from './components/AddMoneyIncentivePreSelectModal';
import {
  FinanceDetailsVM,
  IncentiveAdminVM,
  TimeDetailsVM,
} from '@models/serverModels';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {IncentiveTypeString, IncentivesTabName} from '@models/clientEnums';
import {IncentiveAvailability} from '@components/user/store/interface';
import NoDataText from '@blocks/NoDataText';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {Helmet} from 'react-helmet-async';

const {TabPane} = Tabs;

/*
|--------------------------------------------------------------------------
| Page Component
|--------------------------------------------------------------------------
*/
interface IncentivesProps {
  title?: string;
}

function Incentives({title}: IncentivesProps) {
  const [updateTimeModalData, setUpdateTimeModalData] =
    useState<TimeDetailsVM>(null);
  const [updateMoneyModalData, setUpdateMoneyModalData] =
    useState<FinanceDetailsVM>(null);
  const [visibleTimeIncentive, setVisibleTimeIncentive] = useState(false);
  const [
    visibleMoneyIncentivePreSelectModal,
    setVisibleMoneyIncentivePreSelectModal,
  ] = useState(false);
  const [visibleMoneyIncentive, setVisibleMoneyIncentive] = useState(false);
  const [visiblePLBIncentive, setVisiblePLBIncentive] = useState(false);
  const [
    hasExistingPersonalLearningBudget,
    setHasExistingPersonalLearningBudget,
  ] = useState(false);
  const [isTimeIncentiveTab, setIsTimeIncentiveTab] = useState(false);

  const getUserQuery = useGetUserQuery();
  const {isFeatureFlagOn} = useFeatureFlags();

  const moneyIncentiveRq = getIncentiveAdminFinanceRq();
  const moneyIncentiveQuery = useQuery<IncentiveAdminVM[]>(
    moneyIncentiveRq.queryKey,
    () => simpleQueryFn(moneyIncentiveRq.path)
  );

  const timeIncentiveRq = getIncentiveAdminTimeRq();
  const timeIncentiveQuery = useQuery<IncentiveAdminVM[]>(
    timeIncentiveRq.queryKey,
    () => simpleQueryFn(timeIncentiveRq.path)
  );

  const programTypesRq = getProgramTypesRq();
  const programTypesQuery = useQuery<ProgramTypesVM>(
    programTypesRq.queryKey,
    () => simpleQueryFn(programTypesRq.path),
    {
      onError: (err) => {
        console.error('Error fetching program types', err);
      },
    }
  );

  const learningBudget = moneyIncentiveQuery?.data?.filter(
    ({learningBudget}) => learningBudget
  );

  const financeIncentiveDetailRq = getFinanceIncentiveDetailRq(
    learningBudget?.[0]?.id
  );

  const {data} = useQuery<FinanceDetailsVM>(
    financeIncentiveDetailRq.queryKey,
    () => simpleQueryFn(financeIncentiveDetailRq.path)
  );

  useEffect(() => {
    if (!moneyIncentiveQuery.isSuccess || moneyIncentiveQuery.isFetching) {
      return;
    }

    setHasExistingPersonalLearningBudget(
      moneyIncentiveQuery.data.some(
        (incentive) =>
          incentive.learningBudget &&
          incentive.availability !== IncentiveAvailability.NotAvailable
      )
    );
  }, [moneyIncentiveQuery.isSuccess, moneyIncentiveQuery.isFetching]);

  const handleEditMoneyClickSuccess = (data: FinanceDetailsVM) => {
    setUpdateMoneyModalData(data);
    if (data?.learningBudget || isRemoveOtherIncentives) {
      setVisiblePLBIncentive(true);
    } else {
      setVisibleMoneyIncentive(true);
    }
  };

  const handleEditTimeClickSuccess = (
    data: TimeDetailsVM,
    incentiveType: IncentiveTypeString.Time | IncentiveTypeString.Finance
  ) => {
    if (incentiveType === IncentiveTypeString.Time) {
      setUpdateTimeModalData(data);
      // this should be updated when Personal Learning Budgets are added to work like handleEditMoneySuccess
      setVisibleTimeIncentive(true);
    }
  };

  const handlePersonalLearningBudgetPreSelect = () => {
    setVisiblePLBIncentive(true);
  };

  const handleOtherPreSelect = () => {
    setVisibleMoneyIncentive(true);
  };

  const isTimeIncentivesOn = isFeatureFlagOn.TimeIncentives;
  const isRemoveOtherIncentives = isFeatureFlagOn.RemoveOtherIncentives;
  const isSunsetMonthlyRenewalOn = isFeatureFlagOn.SunsetMonthlyRenewal;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AddMoneyIncentivePreSelectModal
        visibleMoneyIncentivePreSelectModal={
          visibleMoneyIncentivePreSelectModal
        }
        setVisibleMoneyIncentiveModal={setVisibleMoneyIncentivePreSelectModal}
        hasExistingActivePersonalLearningBudget={
          hasExistingPersonalLearningBudget
        }
        onPersonalLearningBudgetConfirm={handlePersonalLearningBudgetPreSelect}
        onOtherConfirm={handleOtherPreSelect}
      />

      {visibleMoneyIncentive && (
        <AddMoneyIncentiveDrawer
          isSunsetMonthlyRenewalOn={isSunsetMonthlyRenewalOn}
          visible={visibleMoneyIncentive}
          updatingData={updateMoneyModalData}
          programTypesData={programTypesQuery.data || []}
          user={getUserQuery.data}
          confirmLoading={false}
          onOk={(e) => {
            if (e) {
              setVisibleMoneyIncentive(false);
              moneyIncentiveQuery.refetch();
              setUpdateMoneyModalData(null);
            }
          }}
          onCancel={() => {
            setVisibleMoneyIncentive(false);
            setUpdateMoneyModalData(null);
          }}
        />
      )}

      {visiblePLBIncentive && (
        <AddPersonalLearningBudgetIncentiveDrawer
          personalLearningBudgetId={updateMoneyModalData?.id}
          isSunsetMonthlyRenewalOn={isSunsetMonthlyRenewalOn}
          onOk={() => {
            setVisiblePLBIncentive(false);
            moneyIncentiveQuery.refetch();
            setUpdateMoneyModalData(null);
          }}
          onCancel={() => {
            setVisiblePLBIncentive(false);
            setUpdateMoneyModalData(null);
          }}
        />
      )}

      {visibleTimeIncentive && (
        <AddTimeIncentiveDrawer
          updatingData={updateTimeModalData}
          programTypesData={programTypesQuery.data || []}
          confirmLoading={false}
          onOk={(e) => {
            if (e) {
              setVisibleTimeIncentive(false);
              setUpdateTimeModalData(null);
              timeIncentiveQuery.refetch();
            }
          }}
          onCancel={() => {
            setVisibleTimeIncentive(false);
            setUpdateTimeModalData(null);
          }}
        />
      )}

      <PageTitleBar
        title={i18n.t(k.INCENTIVE__PLURAL)}
        fullWidth={true}
        jsxComponent={
          <LearnInButton
            tag={ButtonTags.Primary}
            id={
              isTimeIncentiveTab
                ? 'add-time-incentive-button'
                : 'add-finance-incentive-button'
            }
            onClick={() => {
              setUpdateMoneyModalData(null);
              isRemoveOtherIncentives
                ? handleEditMoneyClickSuccess(data)
                : isTimeIncentiveTab
                  ? setVisibleTimeIncentive(true)
                  : setVisibleMoneyIncentivePreSelectModal(true);
            }}>
            {i18n.t(
              isRemoveOtherIncentives && !!data?.id
                ? k.CTA__EDIT_ITEM__FORMAT
                : k.CTA__ADD_ITEM__FORMAT,
              {item: i18n.t(k.INCENTIVE)}
            )}
          </LearnInButton>
        }
      />

      <PageContentLayout className="incentive-tabs">
        <LearnInTabs
          onChange={() => setIsTimeIncentiveTab(!isTimeIncentiveTab)}
          tabBarStyle={
            isTimeIncentivesOn
              ? {visibility: 'visible'}
              : {visibility: 'hidden', marginTop: '-30px'}
          }
          defaultActiveKey={IncentivesTabName.FINANCE}>
          <TabPane
            id="finance-incentive-tab"
            tab={i18n.t(k.GENERIC__FINANCING)}
            key={IncentivesTabName.FINANCE}>
            {moneyIncentiveQuery.isSuccess && (
              <Row gutter={[8, 16]} style={{paddingBottom: '100px'}}>
                {moneyIncentiveQuery.data?.length > 0 ? (
                  moneyIncentiveQuery.data?.map((incentiveData) => {
                    return (
                      <Col key={incentiveData.id} xs={24} md={12}>
                        <IncentiveDataCard
                          isForTimeIncentives={false}
                          title={incentiveData?.title}
                          eligibleEmployees={incentiveData?.eligibleEmployees}
                          timesPlanned={incentiveData?.timesPlanned}
                          learningBudget={incentiveData?.learningBudget}
                          timesUsed={incentiveData?.timesUsed}
                          imageSmall={incentiveData?.imageSmall}
                          onEditSuccess={handleEditMoneyClickSuccess}
                          incentiveId={incentiveData.id}
                          incentiveType={incentiveData.incentiveType}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <NoDataText
                      headerSize={2}
                      header={i18n.t(k.INCENTIVE__EMPTY__TITLE)}
                      subHeader={
                        !isRemoveOtherIncentives &&
                        i18n.t(k.INCENTIVE__EMPTY__DESCRIPTION)
                      }
                      jsxComponent={
                        isRemoveOtherIncentives && (
                          <LearnInButton
                            tag={ButtonTags.Primary}
                            id={
                              isTimeIncentiveTab
                                ? 'add-time-incentive-button'
                                : 'add-finance-incentive-button'
                            }
                            onClick={() => handleEditMoneyClickSuccess(data)}>
                            {i18n.t(k.CTA__ADD_ITEM__FORMAT, {
                              item: i18n.t(k.INCENTIVE),
                            })}
                          </LearnInButton>
                        )
                      }
                    />
                  </div>
                )}
              </Row>
            )}
            {moneyIncentiveQuery.isLoading && (
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Skeleton active />
                </Col>
                <Col xs={24}>
                  <Skeleton active />
                </Col>
                <Col xs={24}>
                  <Skeleton active />
                </Col>
              </Row>
            )}
          </TabPane>
          <TabPane tab={i18n.t(k.TIME)} key={IncentivesTabName.TIME}>
            {timeIncentiveQuery.isSuccess && (
              <Row gutter={[8, 16]} style={{paddingBottom: '100px'}}>
                {timeIncentiveQuery.data?.length > 0 ? (
                  timeIncentiveQuery.data?.map((incentiveData, index) => {
                    return (
                      <Col key={index} xs={24} md={12}>
                        <IncentiveDataCard
                          isForTimeIncentives={true}
                          title={incentiveData?.title}
                          eligibleEmployees={incentiveData?.eligibleEmployees}
                          learningBudget={false}
                          timesPlanned={incentiveData?.timesPlanned}
                          timesUsed={incentiveData?.timesUsed}
                          imageSmall={incentiveData?.imageSmall}
                          onEditSuccess={handleEditTimeClickSuccess}
                          incentiveId={incentiveData?.id}
                        />
                      </Col>
                    );
                  })
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <NoDataText
                      header={i18n.t(k.INCENTIVE__TIME__EMPTY__TITLE)}
                      subHeader={i18n.t(k.INCENTIVE__TIME__EMPTY__DESCRIPTION)}
                    />
                  </div>
                )}
              </Row>
            )}
            {timeIncentiveQuery.isLoading && (
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Skeleton active />
                </Col>
                <Col xs={24}>
                  <Skeleton active />
                </Col>
                <Col xs={24}>
                  <Skeleton active />
                </Col>
              </Row>
            )}
          </TabPane>
        </LearnInTabs>
      </PageContentLayout>
    </>
  );
}

export default Incentives;
