import {TransactionState} from './../enums';
import {CreditCardTransactionResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: CreditCardTransactionResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCreditCardTransactionResponseMockData(
  args?: Partial<CreditCardTransactionResponse>
): CreditCardTransactionResponse {
  return {
    amount: faker.datatype.number(),
    description: faker.lorem.lines(),
    id: faker.datatype.number(),
    state: TransactionState.Authorization,
    status: faker.word.noun(),
    timestamp: String(faker.datatype.number()),
    uniqueId: String(faker.datatype.number()),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CreditCardTransactionResponseMockData: CreditCardTransactionResponse =
  {
    amount: 5,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    id: 1,
    state: TransactionState.Authorization,
    status: 'string',
    timestamp: '5',
    uniqueId: '1',
  };
