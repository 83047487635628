import {useState} from 'react';
import NoContentScreen from './NoContentScreen';
import UpsertCustomContentModal from './../curriculum/UpsertCustomContentModal.container';
import {ContentType} from '@generated/enums';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {getContentDisplayOptions} from '@utils/customProgramFullPage';
import {
  useUpdateCustomProgramSectionMutation,
  useCustomProgramSectionsQuery,
} from '@generated/hooks';
/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface NoContentScreenContainerProps {
  sectionId: number;
  customProgramId: number;
}

function NoContentScreenContainer({
  customProgramId,
  sectionId,
}: NoContentScreenContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const [showUpsertContentModal, setShowUpsertContentModal] = useState(false);
  const [contentType, setContentType] = useState<ContentType | undefined>(
    undefined
  );

  const {
    data: customProgramSections,
    invalidateExact: invalidateCustomProgramSections,
  } = useCustomProgramSectionsQuery({
    customProgramId,
  });

  const section = customProgramSections?.find(({id}) => {
    return id === sectionId;
  });
  const updateCustomProgramSection = useUpdateCustomProgramSectionMutation({
    onSuccess: () => {
      invalidateCustomProgramSections();
    },
  });

  const displayOptions = getContentDisplayOptions().map((opt) => {
    return {
      ...opt,
      isSelected:
        (opt.value && section?.isFullScreen) ||
        (!opt.value && section?.isFullScreen === false), // add logic here
    };
  });

  const handleToggleDisplayOptions = (option) => {
    updateCustomProgramSection.mutate({
      payload: {
        customProgramId,
        isFullScreen: option.value,
        sectionId,
        title: section.title,
      },
    });
  };

  return (
    <>
      <NoContentScreen
        isFullScreen={section?.isFullScreen}
        displayOptions={displayOptions}
        onClickAddContent={(contentType: ContentType) => {
          setContentType(contentType);
          setShowUpsertContentModal(true);
        }}
        onToggleDisplayOptions={handleToggleDisplayOptions}
      />
      {showUpsertContentModal && (
        <UpsertCustomContentModal
          key={`${sectionId}-${customProgramId}`}
          sectionId={sectionId}
          customProgramId={customProgramId}
          visible={showUpsertContentModal}
          contentType={contentType}
          onCancel={() => setShowUpsertContentModal(false)}
        />
      )}
    </>
  );
}

export default NoContentScreenContainer;
