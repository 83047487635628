// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/UserFinanceIncentiveUpdateType.cs

enum UserFinanceIncentiveUpdateType {
  TotalApproved,
  Balance,
  Limit,
  Note,
}

export default UserFinanceIncentiveUpdateType;
