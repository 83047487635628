import {ArrowRightOutlined} from '@ant-design/icons';
import Icon from '@blocks/Icon/Icon';
import {ViewProgramLink} from '@components/admin/pages/reports/components/ViewProgramLink';
import {ExceedsRemainingBudgetWarning} from '@components/manager/pages/approvals/components/managerApprovalsList/ManagerApprovalsList';
import {
  LearnInButton,
  LinkStyledButton,
} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {useGetCostCenterOptions} from '@components/reusable/CostCenterField/CostCenterField.hooks';
import {InlineSelect} from '@components/reusable/InlineSelect';
import {
  ColumnAlignment,
  DefaultSortOrder,
  IColumn,
  TableCell,
} from '@components/reusable/LearnInTable';
import {
  ApprovalStatus,
  LearningResourceType,
  RequestApprovalType,
  UserRequestType,
} from '@generated/enums';
import {useCostCentersQuery, useUserQuery} from '@generated/hooks';
import {UserRequestApproval} from '@generated/interfaces';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {i18n, k} from '@i18n/translate';
import {AdminPaths} from '@utils/ClientPaths';
import {COLORS} from '@utils/constants';
import {getUserRequestType} from '@utils/getUserRequestType';
import {getResourceTypeName} from '@utils/l10nUtils';
import {
  applyLicenseCurrencyOrDefault,
  formatCurrency,
  getExchangeRateOfCurrencyPair,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {DATE_FORMAT} from '@utils/timeUtils';
import {Col, Row, Typography} from 'antd';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';

export function useColumns(
  {
    approvalType,
    status,
    columnSorting,
  }: {
    approvalType: RequestApprovalType;
    status: ApprovalStatus;
    columnSorting: {column: string; order: string};
  },
  handlers: {
    onUpdateCostCenter: (
      costCenter: string,
      userRequestApprovalId: number,
      onSuccess?: () => void,
      onError?: () => void
    ) => void;
    onReviewApproval: (row: UserRequestApproval) => void;
    onViewDetails: (row: UserRequestApproval) => void;
  }
) {
  const {isFeatureFlagOn} = useFeatureFlags();

  const navigate = useNavigate();
  const {data: user} = useUserQuery(null);
  const {data: exchangeRatesList} = useGetCurrencyExchangeQuery();
  const isProgramPage = approvalType === RequestApprovalType.Program;

  const {data: costCentersList} = useCostCentersQuery(null, {
    enabled: isFeatureFlagOn.CostCenter,
  });

  const costCenters = costCentersList?.length ? [...costCentersList] : null;
  const costCenterOptions = useGetCostCenterOptions(costCenters);

  const exchangeRate = useExchangeRate(MONEY_DEFAULT.currency, user?.currency);

  const baseColumns: IColumn[] = [
    {
      title: isProgramPage ? i18n.t(k.PROGRAM__NAME) : i18n.t(k.GENERIC__TITLE),
      dataIndex: 'programTitle',
      key: 'programTitle',
      render: (title, row: UserRequestApproval) => {
        return (
          <TableCell>
            <Row align="middle">
              <Col xs={24} onClick={(e) => e.stopPropagation()}>
                {isProgramPage ? (
                  <ViewProgramLink id={row.programItemId}>
                    {title}
                  </ViewProgramLink>
                ) : (
                  <Typography.Text className="program-title">
                    {title}
                  </Typography.Text>
                )}
                <br />
              </Col>
            </Row>
          </TableCell>
        );
      },
      sorter: true,
    },
    {
      title: <>{i18n.t(k.NAME)}</>,
      dataIndex: 'requestedByName',
      key: 'requestedByName',
      sorter: true,
      render: (requestedBy, row: UserRequestApproval) => {
        return (
          <TableCell>
            <Row align="middle">
              <Col xs={24} onClick={(e) => e.stopPropagation()}>
                <LinkStyledButton
                  onClick={() =>
                    navigate(`${AdminPaths.People}/${row.requestedById}`, {
                      state: {
                        from: `${location.pathname + location.search}`,
                      },
                    })
                  }>
                  {requestedBy}
                </LinkStyledButton>
              </Col>
            </Row>
          </TableCell>
        );
      },
    },
    {
      title: i18n.t(k.FUND__REQUEST__AMOUNT),
      align: ColumnAlignment.Right,
      dataIndex: 'amount',
      key: 'amount',
      render: (_, row: UserRequestApproval) => {
        const exchangeRate = getExchangeRateOfCurrencyPair(
          exchangeRatesList,
          user?.currency,
          applyLicenseCurrencyOrDefault({
            licenseCurrency: row.licenseCurrency,
            licenseId: row.licenseId,
          })
        );
        return (
          <span data-cy="estimatedCost">
            <>
              {formatCurrency(row.amount, user?.currency, exchangeRate, {
                decimal: true,
              })}
              {!!row.balanceOverLimit && (
                <ExceedsRemainingBudgetWarning amount={row.balanceOverLimit} />
              )}
            </>
          </span>
        );
      },
      sorter: true,
    },
    {
      title: i18n.t(k.DATE__REQUESTED),
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      sorter: true,
      defaultSortOrder: DefaultSortOrder.Descend,
      render: (requestedDate) =>
        moment(requestedDate).format(DATE_FORMAT.MONTH_DAY_YEAR),
    },
  ];

  const costCenterColumn = {
    title: i18n.t(k.COST_CENTER),
    dataIndex: 'costCenter',
    key: 'provider',
    render: (provider: string, row: UserRequestApproval) => {
      const originalValue = row.costCenter;
      const updateInlineValue = (value: string) => {
        row.costCenter = value;
      };
      return (
        <button onClick={(e) => e.stopPropagation()} style={{width: '100%'}}>
          <InlineSelect
            onSubmit={(value) =>
              Promise.resolve(
                handlers.onUpdateCostCenter(
                  value,
                  row.id,
                  () => updateInlineValue(value),
                  () => updateInlineValue(originalValue)
                )
              )
            }
            value={provider}
            options={costCenterOptions}
            readOnly={false}
            notFoundContent={i18n.t(k.GENERIC__NOT_FOUND)}
          />
        </button>
      );
    },
    sorter: true,
  };

  const typeColumn = {
    title: i18n.t(k.GENERIC__TYPE),
    dataIndex: 'userRequestType',
    key: 'userRequestType',
    render: (userRequestType: UserRequestType) =>
      getUserRequestType(userRequestType),
    sorter: true,
  };

  const managerColumn = {
    title: i18n.t(k.GENERIC__MANAGER),
    dataIndex: 'managerName',
    key: 'managerName',
    render: (managerName: string) => managerName,
    sorter: true,
  };

  const approveRejectColumn = {
    title: <>{`${i18n.t(k.CTA__REJECT)} / ${i18n.t(k.CTA__APPROVE)}`}</>,
    dataIndex: '',
    fixed: 'right',
    key: '',
    render: (_, row: UserRequestApproval) => {
      return (
        <LearnInButton
          tag={ButtonTags.Link}
          color={COLORS.Blue800}
          fontWeight="500"
          onClick={(ev) => {
            ev.stopPropagation();
            handlers.onReviewApproval(row);
          }}>
          {i18n.t(k.CTA__OVERRIDE)}
        </LearnInButton>
      );
    },
    sorter: null,
  };

  const viewDetailsColumn: IColumn = {
    title: '',
    dataIndex: '',
    fixed: 'right',
    key: '',
    render: (_, row) => (
      <LearnInButton
        tag={ButtonTags.Link}
        color={COLORS.Blue800}
        fontSize={'1rem'}
        fontWeight={'500'}
        hoverUnderline
        className="btn-admin-approval-view-details"
        data-cy="viewDetails"
        onClick={(e) => {
          e.stopPropagation();
          handlers.onViewDetails(row);
        }}>
        {status === ApprovalStatus.Requested
          ? i18n.t(k.GENERIC__REVIEW)
          : i18n.t(k.GENERIC__DETAIL__PLURAL)}
        <Icon icon={<ArrowRightOutlined />} />
      </LearnInButton>
    ),
    sorter: null,
  };

  const resourcesApprovalColumns: IColumn[] = [
    {
      title: <>{i18n.t(k.NAME)}</>,
      dataIndex: 'requestedByName',
      key: 'requestedByName',
      render: (requestedBy, row: UserRequestApproval) => {
        return (
          <TableCell>
            <Row align="middle">
              <Col xs={24} onClick={(e) => e.stopPropagation()}>
                <LinkStyledButton
                  onClick={() =>
                    navigate(`${AdminPaths.People}/${row.requestedById}`, {
                      state: {
                        from: `${location.pathname + location.search}`,
                      },
                    })
                  }>
                  {requestedBy}
                </LinkStyledButton>
              </Col>
            </Row>
          </TableCell>
        );
      },
      sorter: true,
    },
    {
      title: i18n.t(k.DATE__REQUESTED),
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      sorter: true,
      defaultSortOrder: DefaultSortOrder.Descend,
      render: (requestedDate) =>
        moment(requestedDate).format(DATE_FORMAT.MONTH_DAY_YEAR),
    },
    {
      title: i18n.t(k.RESOURCE),
      dataIndex: 'resourceType',
      key: 'resourceType',
      render: (resourceType: LearningResourceType) => {
        return getResourceTypeName(resourceType);
      },
      sorter: true,
    },
    {
      title: i18n.t(k.FUND__REQUEST__AMOUNT),
      align: ColumnAlignment.Right,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, row: UserRequestApproval) => {
        return (
          <span data-cy="estimatedCost">
            <>
              {formatCurrency(amount, user?.currency, exchangeRate, {
                decimal: true,
              })}
              {!!row.balanceOverLimit && (
                <ExceedsRemainingBudgetWarning amount={row.balanceOverLimit} />
              )}
            </>
          </span>
        );
      },
      sorter: true,
    },
    isFeatureFlagOn.LearnInReimbursements && {
      title: i18n.t(k.GENERIC__TYPE),
      dataIndex: 'userRequestType',
      key: 'userRequestType',
      render: (userRequestType: UserRequestType) =>
        getUserRequestType(userRequestType),
      sorter: true,
    },
  ].filter(Boolean);

  //Make Type column visible in all tables
  if (isFeatureFlagOn.LearnInReimbursements) {
    baseColumns.push(typeColumn);
  }

  if (
    approvalType === RequestApprovalType.Program &&
    isFeatureFlagOn.CostCenter &&
    costCenters
  ) {
    baseColumns.push(costCenterColumn);
  }

  switch (status) {
    case ApprovalStatus.Approved:
    case ApprovalStatus.Rejected:
      baseColumns.push(viewDetailsColumn);
      resourcesApprovalColumns.push(viewDetailsColumn);
      break;
    case ApprovalStatus.Pending:
      baseColumns.push(managerColumn, approveRejectColumn);
      resourcesApprovalColumns.push(managerColumn, approveRejectColumn);
      break;
    default:
      baseColumns.push(viewDetailsColumn);
      resourcesApprovalColumns.push(viewDetailsColumn);
  }

  const columns =
    approvalType === RequestApprovalType.Resource
      ? resourcesApprovalColumns
      : baseColumns;

  return columns.map((column) => {
    if (column.sorter) {
      return {
        ...column,
        defaultSortOrder:
          columnSorting.column === column.key
            ? (columnSorting.order as DefaultSortOrder)
            : null,
      };
    } else {
      return column;
    }
  });
}
