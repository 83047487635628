import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import EmailNotifications, {NotificationRow} from './EmailNotifications';
import EmailMessageUpdateModal from './EmailMessageUpdateModal';
import {ProgramNotificationType} from '@generated/enums';
import {useMutation} from 'react-query';
import {CustomProgramEmailMessagePayload} from '@models/serverModels';
import {getUpdateCustomProgramEmailMessageRm} from '@store/apiEndpoints/customProgram/mutations';
import {simpleMutationFn} from '@store/queryClient';
import {notify} from '@components/user/notifications';

export interface IEmailNotificationsContainerProps {
  isWelcomeEmailEnabled?: boolean;
  isNudgeEmailEnabled?: boolean;
  isProgramCompletionEmailEnabled?: boolean;
  welcomeEmailMessage?: string;
  nudgeEmailMessage?: string;
  programCompletionMessage?: string;
  customProgramId?: number;
  onChangeWelcomeEmail: () => void;
  onChangeNudgeEmail: () => void;
  onChangeCompletionEmail: () => void;
}

const NotificationTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: ${COLORS.Neutral900};
`;

const NotificationSubtitle = styled.div`
  font-weight: normal;
  font-size: 0.75rem;
  color: ${COLORS.Neutral600};
`;

function EmailNotificationsContainer({
  isWelcomeEmailEnabled,
  isNudgeEmailEnabled,
  isProgramCompletionEmailEnabled,
  welcomeEmailMessage,
  nudgeEmailMessage,
  programCompletionMessage,
  customProgramId,
  onChangeWelcomeEmail,
  onChangeNudgeEmail,
  onChangeCompletionEmail,
}: IEmailNotificationsContainerProps) {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<
    NotificationRow | undefined
  >();

  const handleEditEmailClicked = (notification) => {
    setSelectedNotification(notification);
    setShowUpdateModal(true);
  };

  // Update Custom Program
  const updateCustomProgramMutation = useMutation(
    (customProgramEmailMessage: CustomProgramEmailMessagePayload) => {
      const updateCustomProgramEmailMessageRm =
        getUpdateCustomProgramEmailMessageRm(customProgramEmailMessage);
      return simpleMutationFn<null>(
        updateCustomProgramEmailMessageRm.path,
        updateCustomProgramEmailMessageRm.payload
      );
    },
    {
      onSuccess: async (_, data) => {
        notify.updateCustomProgramEmailMessageSuccess();
        setSelectedNotification(undefined);
        setShowUpdateModal(false);
      },
      onError: async () => {
        notify.updateCustomProgramEmailMessageError();
      },
    }
  );

  const handleUpdateClicked = async (
    type: ProgramNotificationType,
    message?: string
  ) => {
    await updateCustomProgramMutation.mutateAsync({
      customProgramId,
      type,
      message,
    });
  };

  const emailNotificationOptions: NotificationRow[] = [
    {
      title: i18n.t(k.WELCOME).toLocaleUpperCase(),
      type: ProgramNotificationType.Welcome,
      notificationDescription: (
        <>
          <NotificationTitle>{i18n.t(k.WELCOME)}</NotificationTitle>
          <NotificationSubtitle>
            {i18n.t(k.NOTIFICATION__PROGRAM_START__DESCRIPTION)}
          </NotificationSubtitle>
        </>
      ),
      email: i18n.t(k.MESSAGE__EDIT),
      message: welcomeEmailMessage,
      isChecked: isWelcomeEmailEnabled,
      onChange: onChangeWelcomeEmail,
      onEditEmailClicked: handleEditEmailClicked,
    },
    {
      title: i18n.t(k.NOTIFICATION__NUDGES),
      type: ProgramNotificationType.Nudge,
      notificationDescription: (
        <>
          <NotificationTitle>
            {i18n.t(k.NOTIFICATION__NUDGES)}
          </NotificationTitle>
          <NotificationSubtitle>
            {i18n.t(k.NOTIFICATION__NUDGES__DESCRIPTION)}
          </NotificationSubtitle>
        </>
      ),
      email: i18n.t(k.MESSAGE__EDIT),
      message: nudgeEmailMessage,
      isChecked: isNudgeEmailEnabled,
      onChange: onChangeNudgeEmail,
      onEditEmailClicked: handleEditEmailClicked,
    },
    {
      title: i18n.t(k.PROGRAM__COMPLETION),
      type: ProgramNotificationType.ProgramCompletion,
      notificationDescription: (
        <>
          <NotificationTitle>{i18n.t(k.PROGRAM__COMPLETION)}</NotificationTitle>
          <NotificationSubtitle>
            {i18n.t(k.NOTIFICATION__PROGRAM_COMPLETION__DESCRIPTION)}
          </NotificationSubtitle>
        </>
      ),
      email: i18n.t(k.MESSAGE__EDIT),
      message: programCompletionMessage,
      isChecked: isProgramCompletionEmailEnabled,
      onChange: onChangeCompletionEmail,
      onEditEmailClicked: handleEditEmailClicked,
    },
  ];
  return (
    <>
      <EmailNotifications
        canEditMessage={false}
        emailNotificationOptions={emailNotificationOptions}
      />
      {showUpdateModal && (
        <EmailMessageUpdateModal
          emailNotification={selectedNotification}
          visible={showUpdateModal}
          onOk={handleUpdateClicked}
          onCancel={() => setShowUpdateModal(false)}
        />
      )}
    </>
  );
}

export default EmailNotificationsContainer;
