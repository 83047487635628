import {useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {Route, Routes, useNavigate} from 'react-router-dom';
import AcademyContentLevelScreen from './../AcademyContentLevelScreen';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {
  useAcademyUserActivity,
  toSearchParams,
} from '@hooks/academies/academyUserActivity';

function AcademyContentContainer({
  title,
}: {
  isActivePlanItem: boolean;
  currentLevelTitle?: string;
  title?: string;
}) {
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const [searchParams] = useSearchParams();

  useSetPageTitle(title);

  const activityDetails = useAcademyUserActivity();

  useEffect(() => {
    const lastPathPart = pathname.split('/').pop();
    // This will be true when there is no level specified
    // In which case navigate to first section
    if (activityDetails && lastPathPart === 'content') {
      const {lastLevel, ...lastStepOrScroll} = activityDetails;
      const localSearchParams = toSearchParams(lastStepOrScroll);
      const scrollLevelId = searchParams.get('scrollLevelId');
      const scrollContentId = searchParams.get('scrollContentId');
      if (scrollLevelId && scrollContentId) {
        navigate(
          `${pathname}/${scrollLevelId}?scrollLevelId=${scrollLevelId}&scrollContentId=${scrollContentId}`,
          {replace: true}
        );
      } else {
        navigate(`${pathname}/${lastLevel}${localSearchParams}`, {
          replace: true,
        });
      }
    }
  }, [pathname, activityDetails, navigate]);

  return (
    <>
      <Routes>
        <Route path=":levelId/*" element={<AcademyContentLevelScreen />} />
      </Routes>
    </>
  );
}

export default AcademyContentContainer;
