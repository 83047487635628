import {ReactNode} from 'react';
import styled from 'styled-components';

const StyledLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTopSection = styled.div`
  display: flex;
  padding-bottom: 24px;
`;

const StyledBottomSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTopSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
`;

interface Props {
  children: ReactNode;
}

/**
 *
 * This is  the layout for the program specific modal. Defined in the application is how to use the layout.
 */
export function ProgramCardLayout({children}: Props) {
  return (
    <StyledLayoutWrapper>
      <StyledTopSection>
        {children[0].props.children[0]}
        <StyledTopSectionContent>
          {children[0].props.children[1].props.children}
        </StyledTopSectionContent>
      </StyledTopSection>
      <StyledBottomSection>{children[1].props.children}</StyledBottomSection>
    </StyledLayoutWrapper>
  );
}
