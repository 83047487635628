import {LearnInToggleOption} from '@components/reusable/LearnInToggle/LearnInToggle.interfaces';
import translate from '@i18n/translate';

export const getContentDisplayOptions = (): LearnInToggleOption[] => [
  {
    value: true,
    label: translate('OPTION_LABEL__FULL_PAGE_SECTION'),
  },
  {
    value: false,
    label: translate('OPTION_LABEL__STACKED_SECTION'),
  },
];
