import {ReactChild, useState} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Card, Col, Divider, List, Row, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {ReimbursementStatus} from '../../store/interface';
import PreApprovalDrawer from '../../../components/preApprovalDrawer/PreApprovalDrawer.container';
import {FinanceType, PlanItemType} from '@generated/enums';
import moment from 'moment';
import {getFinanceDetailPath} from '@utils/ClientPaths';
import {UserPlanCardActions} from '@models/clientEnums';
import {UserPlanFinanceItemVM} from '@models/serverModels';
import {UserPlanCardDataHeader} from '@blocks/userPlanCard/UserPlanCard';
import ActionButton from '@blocks/actionButton/ActionButton';
import {shouldShowExpandedCardView} from '@blocks/userPlanCardActionMenu/UserPlanCardActionMenu';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {Breakpoints} from '@utils/responsiveUtils';
import {getCurrencyEnum, formatCurrency} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {DATE_FORMAT} from '@utils/timeUtils';

const ReimbursementTitleRow = styled(Row)`
  font-size: 1rem;
  font-weight: 600;
  align-self: center;

  @media (max-width: ${Breakpoints.smallMobile}px) {
    align-self: flex-end;
  }
`;

const ReimbursementAmountRow = styled(Row)`
  font-size: 1rem;
  font-weight: 600;
  justify-content: center;

  @media (max-width: ${Breakpoints.smallMobile}px) {
    justify-content: flex-end;
  }
`;

const ReimbursementTagRow = styled(Row)`
  font-size: 1rem;
  font-weight: 600;
  justify-content: flex-end;

  @media (max-width: ${Breakpoints.Mobile}px) {
    justify-content: flex-start;
  }
`;

interface Props {
  showActions: boolean;
  item: UserPlanFinanceItemVM;
  handleSettingsActions: (action: UserPlanCardActions) => void;
}

const getItemStatusTag = (status: string): ReactChild => {
  let tagStyle;
  let tagText = '';
  switch (status) {
    case ReimbursementStatus.NOT_SUBMITTED:
      tagStyle = TagStyles.LightGrey;
      tagText = i18n.t(k.STATUS__NOT_SUBMITTED).toLocaleUpperCase();
      break;
    case ReimbursementStatus.SUBMITTED:
      tagStyle = TagStyles.Yellow;
      tagText = i18n.t(k.STATUS__SUBMITTED).toLocaleUpperCase();
      break;
    case ReimbursementStatus.APPROVED:
      tagStyle = TagStyles.Green;
      tagText = i18n.t(k.STATUS__APPROVED).toLocaleUpperCase();
      break;
    case ReimbursementStatus.REJECTED:
      tagStyle = TagStyles.Red;
      tagText = i18n.t(k.STATUS__REJECTED).toLocaleUpperCase();
      break;
    case ReimbursementStatus.CANCELLED:
      tagStyle = TagStyles.Yellow;
      tagText = i18n.t(k.STATUS__CANCELLED).toLocaleUpperCase();
      break;
    case ReimbursementStatus.PAYMENT_PENDING:
      tagStyle = TagStyles.Yellow;
      tagText = i18n.t(k.STATUS__PAYMENT_PENDING).toLocaleUpperCase();
      break;
    case ReimbursementStatus.PAID:
      tagStyle = TagStyles.Green;
      tagText = i18n.t(k.STATUS__PAID).toLocaleUpperCase();
      break;
  }

  return <LearnInTag tagStyle={tagStyle} label={tagText} />;
};

const UserPlanFinanceCard = ({
  showActions,
  item,
  handleSettingsActions,
}: Props) => {
  const navigate = useNavigate();
  const [showPreApprovalDrawer, setShowPreApprovalDrawer] =
    React.useState(false);
  const [isPrepaymentIncentive] = useState(
    item.financeType === FinanceType.Prepayment
  );
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    getCurrencyEnum(item.currencyCode),
    getUserQuery.data?.currency
  );

  return (
    <article
      aria-label={i18n.t(k.USER__FINANCE)}
      data-cy={`finance-${item.id}`}>
      <Row align="middle" justify="center">
        <Col xs={24}>
          {showPreApprovalDrawer && (
            <PreApprovalDrawer
              visible={showPreApprovalDrawer}
              itemId={item.financeIncentiveId}
              currency={getCurrencyEnum(item.currencyCode)}
              planItemId={item.id}
              planItemType={item.itemType}
              onOk={() => {
                setShowPreApprovalDrawer(false);
              }}
              onCancel={() => {
                setShowPreApprovalDrawer(false);
              }}
            />
          )}
        </Col>
      </Row>
      <Card
        role="article"
        aria-label={`${i18n.t(k.A11Y__INFO_ABOUT__FORMAT)}: ${item.title}`}
        key={item.id}
        className="user-plan-item-full-card">
        <UserPlanCardDataHeader
          item={item}
          planItemTypeWithCustomProgram={PlanItemType.Finance}
          showActions={showActions}
          handleSettingsActions={handleSettingsActions}
          handleShowPreApprovalDrawer={() => setShowPreApprovalDrawer(true)}
          onClickContainer={() =>
            navigate(getFinanceDetailPath(item.financeIncentiveId))
          }
        />

        {/* Expanded view */}
        {shouldShowExpandedCardView(PlanItemType.Finance, item.status) && (
          <Row
            align="middle"
            style={{
              margin: '0 0 12px 0',
              alignItems: 'end',
              padding: '16px 24px',
            }}
            role="button"
            tabIndex={0}
            onClick={() => {
              navigate(`/user/manage/finance/${item.id}`);
            }}>
            <Divider style={{margin: '0 0 24px 0'}} />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginBottom: '20px',
              }}>
              <Typography.Text
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  lineHeight: '0.75rem',
                }}>
                {isPrepaymentIncentive
                  ? i18n.t(k.MONEY__REMAINING_BALANCE)
                  : i18n.t(k.MONEY__PAYOUT_HISTORY)}
              </Typography.Text>
              {isPrepaymentIncentive && (
                <Typography.Text
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '0.75rem',
                    lineHeight: '0.75rem',
                  }}>
                  {formatCurrency(
                    item.balance,
                    getUserQuery.data?.currency,
                    exchangeRate
                  ) || i18n.t(k.GENERIC__N_A)}
                </Typography.Text>
              )}
              <ActionButton
                item={{
                  ...item,
                  ...{
                    itemType: PlanItemType.Finance,
                    programApprovalRequired: false,
                  },
                }}
                itemType={PlanItemType.Finance}
                handleShowPreApprovalDrawer={setShowPreApprovalDrawer}
                onSettings={handleSettingsActions}
                buttonLabel={i18n.t(k.CTA__VIEW__DETAIL__PLURAL)}
              />
            </div>
            {isPrepaymentIncentive ? null : item.reimbursements?.length ? (
              <List
                itemLayout="horizontal"
                style={{width: '100%', marginTop: '10px'}}
                dataSource={item.reimbursements}
                renderItem={(item: any) => (
                  <List.Item>
                    <Row style={{width: '100%'}}>
                      <Col xs={12} sm={4}>
                        <Row>
                          <Typography.Text strong style={{fontSize: '1rem'}}>
                            {moment(item.date).format(
                              DATE_FORMAT.MONTH_DAY_YEAR_PADDED
                            )}
                          </Typography.Text>
                        </Row>
                      </Col>
                      <Col xs={12} sm={4}>
                        <ReimbursementAmountRow>
                          {formatCurrency(
                            item.amount,
                            getUserQuery.data?.currency,
                            exchangeRate
                          )}
                        </ReimbursementAmountRow>
                      </Col>
                      <Col xs={12} sm={7}>
                        <ReimbursementTitleRow>
                          {item.title}
                        </ReimbursementTitleRow>
                      </Col>
                      <Col xs={24} sm={6}>
                        <ReimbursementTagRow>
                          {getItemStatusTag(item.approvalStatus)}
                        </ReimbursementTagRow>
                      </Col>
                    </Row>
                  </List.Item>
                )}
              />
            ) : (
              <Col sm={24}>
                <Typography.Paragraph
                  style={{
                    fontWeight: 'normal',
                    fontSize: '1.25rem',
                    lineHeight: '150%',
                    color: '#9B9B9B',
                  }}>
                  {i18n.t(k.REIMBURSEMENT__EMPTY_SUBMISSIONS)}
                </Typography.Paragraph>
              </Col>
            )}
          </Row>
        )}
      </Card>
    </article>
  );
};

export default UserPlanFinanceCard;
