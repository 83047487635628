import React from 'react';
import t from '@i18n/translate';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {DeleteOutlined, PlusOutlined} from '@ant-design/icons';
import {FooterContainer} from './InvitationEmailDrawer.styled';

interface InvitationEmailDrawerFooterProps {
  isEditing: boolean;
  hasNote: boolean;
  isDeletePendingSave: boolean;
  onRemoveNote: () => void;
  onAddNote: () => void;
  onCancel: () => void;
  onSaveChanges: () => void;
  canSaveChanges: boolean;
}

const InvitationEmailDrawerFooter: React.FC<
  InvitationEmailDrawerFooterProps
> = ({
  isEditing,
  hasNote,
  onRemoveNote,
  onAddNote,
  onCancel,
  onSaveChanges,
  canSaveChanges,
  isDeletePendingSave,
}) => {
  return (
    <FooterContainer>
      <div>
        {!isDeletePendingSave && (hasNote || isEditing) ? (
          // REMOVE NOTE BUTTON
          <LearnInButton
            tag={ButtonTags.DestructiveSecondary}
            onClick={onRemoveNote}>
            <DeleteOutlined />
            {t('NOTE__REMOVE')}
          </LearnInButton>
        ) : (
          // ADD NOTE BUTTON
          <LearnInButton tag={ButtonTags.Secondary} onClick={onAddNote}>
            <PlusOutlined />
            {t('NOTE__ADD')}
          </LearnInButton>
        )}
      </div>
      <div className="btn-group">
        {/* CANCEL BUTTON */}
        <LearnInButton
          tag={ButtonTags.SecondaryBlackRemovedBorder}
          onClick={onCancel}>
          {t('CTA__CANCEL')}
        </LearnInButton>

        {/* SAVE CHANGES BUTTON */}
        <LearnInButton
          tag={ButtonTags.Primary}
          onClick={onSaveChanges}
          disabled={!canSaveChanges}>
          {t('CTA__SAVE_CHANGES')}
        </LearnInButton>
      </div>
    </FooterContainer>
  );
};

export default InvitationEmailDrawerFooter;
