import {
  JSXElementConstructor,
  CSSProperties,
  useState,
  useRef,
  ReactNode,
  useEffect,
} from 'react';

import * as React from 'react';
import styled, {css} from 'styled-components';
import {COLORS} from '@utils/constants';
import {
  LearnInButton,
  LearnInButtonProps,
} from '@components/reusable/Button/Button.style';
import useClickOutside from '@hooks/useClickOutside';
import {Breakpoints} from '@utils/responsiveUtils';
import {a11yDropdownKeyHelper} from '@utils/a11yUtils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  position: relative;
  button {
    gap: 0;
  }
  button:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

const DropdownContainer = styled.div<{position?: ('left' | 'right')[]}>`
  background: white;
  border-radius: 8px;
  box-shadow: ${COLORS.BoxShadowStandard};
  cursor: pointer;
  max-width: 470px;
  min-width: 470px;
  position: absolute;
  top: 45px;
  z-index: 10000;
  div {
    text-align: left;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  @media (max-width: ${Breakpoints.Mobile}px) {
    min-width: 90vw;
  }
  @media (max-width: ${Breakpoints.smallMobile}px) {
    min-width: 85vw;
  }

  ${({position}) => {
    if (position?.includes('right')) {
      return css`
        right: 0;
      `;
    } else if (position?.includes('left')) {
      return css`
        left: 0;
      `;
    } else {
      return css`
        right: 0;
      `;
    }
  }}
`;

const OptionButtonContainer = styled.button`
  padding: 16px 24px;
  display: flex;
  width: 100%;
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  &:hover {
    background: ${COLORS.Neutral100};
  }
`;

const Title = styled.div`
  text-align: left;
  color: #1b1c23;
  font-size: 1rem;
  font-weight: 500;
`;

const Subtitle = styled.div`
  color: #747578;
  font-weight: 400;
  font-size: 0.75rem;
`;

const ICON_WRAPPER_SIZE = 65;
export const IconWrapper = styled.div<{background: string}>`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ICON_WRAPPER_SIZE}px;
  height: ${ICON_WRAPPER_SIZE}px;
  background: ${({background}) => background};
`;

const EmptyIconGap = styled.div`
  width: ${ICON_WRAPPER_SIZE}px;
`;

/*
|--------------------------------------------------------------------------
| Option Button
|--------------------------------------------------------------------------
*/

export interface OptionButtonProps {
  title: string;
  subtitle: string | ReactNode;
  onClick?: () => void;
  background: COLORS | string;
  color?: COLORS;
  Icon?: JSXElementConstructor<{role?: string; style?: CSSProperties}>;
  dataTestId?: string;
  enabled?: boolean;
  id?: string;
}

export function OptionButton({
  background,
  color,
  title,
  subtitle,
  onClick,
  Icon,
  dataTestId,
}: OptionButtonProps) {
  return (
    <OptionButtonContainer
      role="button"
      tabIndex={0}
      onClick={onClick}
      data-testid={dataTestId}
      data-cy={title}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '24px',
        }}>
        {Icon ? (
          <IconWrapper background={background}>
            <Icon aria-hidden="true" style={{color, fontSize: '1.75em'}} />
          </IconWrapper>
        ) : (
          <EmptyIconGap />
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </div>
    </OptionButtonContainer>
  );
}

/*
|--------------------------------------------------------------------------
| Options Button Dropdown
|--------------------------------------------------------------------------
*/

export interface OptionsFormDropdownProps {
  options: (OptionButtonProps | null | undefined)[];
  position?: ('left' | 'right')[];
  style?: React.CSSProperties;
  id?: string;
}

export function OptionsFormDropdown({
  id,
  options,
  position,
  style,
}: OptionsFormDropdownProps) {
  return (
    <DropdownContainer id={id} position={position} style={style}>
      <ul>
        {options.filter(Boolean).map((option, index) => {
          return (
            <li key={index}>
              <OptionButton key={option.title} {...option} />
            </li>
          );
        })}
      </ul>
    </DropdownContainer>
  );
}

/*
|--------------------------------------------------------------------------
| Options Form w/ Button and close on outside click
|--------------------------------------------------------------------------
*/

export interface OptionsFormProps {
  buttonProps: LearnInButtonProps;
  options: (OptionButtonProps | null | undefined)[];
  buttonLabel: string | ReactNode;
  position?: ('left' | 'right')[];
}

function OptionsForm({
  options,
  buttonProps,
  buttonLabel,
  position,
}: OptionsFormProps) {
  const [showForm, setShowForm] = useState(false);
  const [returnTarget, setReturnTarget] = useState(null);

  const containerRef = useRef();

  const handleClickOutside = () => {
    if (showForm) setShowForm(false);
  };

  const instanceId = `optionForm-${Date.now()}`;

  useClickOutside(containerRef, handleClickOutside);
  useEffect(() => {
    showForm &&
      a11yDropdownKeyHelper(instanceId, returnTarget, handleClickOutside);
  }, [showForm]);

  const handleClickButton = (e) => {
    setReturnTarget(e.target);
    setShowForm(!showForm);
  };

  const optionsWithCloseOnClick = options?.filter(Boolean).map((option) => {
    return {
      ...option,
      onClick: () => {
        option.onClick();
        setShowForm(false);
      },
    };
  });

  return (
    <Container ref={containerRef}>
      <LearnInButton
        {...buttonProps}
        onClick={handleClickButton}
        chevron
        isChevronOpen={showForm}>
        {buttonLabel}
      </LearnInButton>
      {showForm && (
        <OptionsFormDropdown
          id={instanceId}
          position={position}
          options={optionsWithCloseOnClick}
        />
      )}
    </Container>
  );
}

export default OptionsForm;
