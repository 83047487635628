import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {ACADEMY_CARD_HEIGHT} from '../AcademiesCarousel/AcademiesCarouselCard';
import {AcademyWithMembershipStatusVM} from '@generated/interfaces';
import AcademiesCardSkillTags from '../AcademySkillTags/AcademiesCardSkillTags';
import {AcademyUserStatus} from '@generated/enums';
/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const CardContainer = styled.article`
  display: inline-flex;
  height: ${ACADEMY_CARD_HEIGHT};
  margin-right: 16px;
  width: 47%;
  box-shadow: ${COLORS.BoxShadowStandard};
  margin-bottom: 24px;
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  @media (max-width: 576px) {
    width: 100%;
  }

  .tag {
    text-transform: none;
    font-weight: regular;
  }
`;

const PreviewImgContainer = styled.div`
  width: 45%;
`;
const PreviewImg = styled.img<{bgImg: string}>`
  border-radius: 10px 0 0 10px;
  object-fit: cover;
  object-position: center center;
  height: 100%;
  width: 100%;
`;
const ContentContainer = styled.div`
  border-radius: 0 10px 10px 0;
  padding: 24px;
  padding-bottom: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${COLORS.White};
  max-width: 65%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FooterContainer = styled.div``;

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  font-weight: 500;
  line-height: 1.5rem;
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Description = styled.div`
  display: -webkit-box;
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`;
const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  button {
    margin-right: 6px;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface AcademiesCardProps {
  academy: AcademyWithMembershipStatusVM;
  onClickViewAcademy: (academy: AcademyWithMembershipStatusVM) => void;
  canShowSkills?: boolean;
}

function AcademiesCard({
  academy,
  onClickViewAcademy,
  canShowSkills,
}: AcademiesCardProps) {
  const programHasBeenStarted =
    (!academy.restrictedAccess &&
      (academy.isInProgress || academy.hasCompleted)) ||
    (academy.restrictedAccess &&
      academy.academyUserStatus !== AcademyUserStatus.NotStarted);
  const nameAttribute = programHasBeenStarted
    ? `${i18n.t(k.ACADEMY__GO_TO)}: ${academy.name}`
    : `${i18n.t(k.ACADEMY__PREVIEW__VERB)}: ${academy.name}`;
  return (
    <CardContainer aria-label={`${i18n.t(k.ACADEMY)}: ${academy.name}`}>
      <PreviewImgContainer>
        <PreviewImg
          alt=""
          src={
            academy.academyProfileImageUrl
              ? academy.academyProfileImageUrl
              : getPlaceholderImgSrc(academy.id)
          }
        />
      </PreviewImgContainer>
      <ContentContainer>
        <div>
          <Title>{academy.name}</Title>
          <Description>{academy.description}</Description>
        </div>
        {canShowSkills && (
          <AcademiesCardSkillTags
            skills={academy?.academySkillVMs?.map((skill) => skill.title) || []}
          />
        )}
        <FooterContainer>
          <ButtonsContainer>
            <LearnInButton
              name={nameAttribute}
              aria-label={
                programHasBeenStarted
                  ? i18n.t(k.CTA__GO_TO_ITEM__FORMAT, {item: academy.name})
                  : i18n.t(k.CTA__PREVIEW_ITEM__FORMAT, {item: academy.name})
              }
              tag={
                programHasBeenStarted
                  ? ButtonTags.Primary
                  : ButtonTags.Secondary
              }
              onClick={() => onClickViewAcademy(academy)}>
              {programHasBeenStarted
                ? i18n.t(k.ACADEMY__GO_TO)
                : i18n.t(k.ACADEMY__PREVIEW__VERB)}
            </LearnInButton>
          </ButtonsContainer>
        </FooterContainer>
      </ContentContainer>
    </CardContainer>
  );
}

export default AcademiesCard;
