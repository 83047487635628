import {Avatar} from 'antd';
import styled from 'styled-components';
import {getAvatarColorwaysStyles} from '@utils/color';
import {getInitialsOfName} from '@components/providers/utils';
import {CustomProgramUserResponse} from '@generated/interfaces';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Member = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface MembersPreviewProps {
  members: CustomProgramUserResponse[];
}

export function MembersPreview({members}: MembersPreviewProps) {
  return (
    <Container>
      {members.map((member, i) => {
        const colorway = getAvatarColorwaysStyles(i);
        return (
          <Member key={i} data-testid="member">
            <Avatar
              key={i}
              style={{
                ...colorway,
                fontWeight: '700',
              }}>
              {getInitialsOfName(member.userName)}
            </Avatar>
            {member.userName}
          </Member>
        );
      })}
    </Container>
  );
}
