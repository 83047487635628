import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {Alert, Form, Radio} from 'antd';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {WarningOutlined} from '@ant-design/icons';
import {formatCurrency} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {CurrencyCode} from '@generated/enums';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const radioStyle = {display: 'block'};

const AlertContainer = styled.div`
  font-size 16px;
  width: 100%;
  padding-left: 24px;
`;

const AlertText = styled.div`
  margin-top: 20px
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IRemoveResourceModalProps {
  visible: boolean;
  onOk: (hasMadePurchases: boolean) => void;
  onCancel: () => void;
  approvedAmount: number;
  currency: CurrencyCode;
  isReimbursementPlan: boolean;
}

function RemoveResourceModal({
  visible,
  onOk,
  onCancel,
  approvedAmount,
  currency,
  isReimbursementPlan,
}: IRemoveResourceModalProps) {
  const [hasMadePurchases, setHasMadePurchases] = useState(undefined);
  const [form] = Form.useForm();

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(currency, getUserQuery.data?.currency);

  const handleOk = async () => {
    if (await form.validateFields()) {
      onOk(hasMadePurchases);
    }
  };

  useEffect(() => {
    hasMadePurchases !== undefined && form.validateFields(['made-purchases']);
  }, [hasMadePurchases]);

  return (
    <LearnInModal
      tag={ModalTags.RemoveProgramFromUserPlan}
      visible={visible}
      onOk={handleOk}
      width={400}
      onCancel={onCancel}
      okButtonChild={
        isReimbursementPlan
          ? i18n.t(k.RESOURCE__REMOVE__CONFIRMED)
          : i18n.t(k.RESOURCE__REMOVE)
      }
      okButtonTag={ButtonTags.Destructive}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      title={i18n.t(k.RESOURCE__REMOVE)}>
      {isReimbursementPlan ? (
        <div
          style={{padding: '8px 24px 16px 24px'}}
          dangerouslySetInnerHTML={{
            __html: i18n.t(k.REIMBURSEMENT__REMOVE_RESOURCE),
          }}
        />
      ) : (
        <Form form={form} className="delete-resource-modal">
          <Form.Item
            style={{padding: '8px 24px 16px 24px'}}
            name="made-purchases"
            rules={[
              {
                required: hasMadePurchases === undefined,
                message: i18n.t(k.VALIDATION__REQUIRED),
              },
            ]}>
            <div>{i18n.t(k.CARD__REMOVE_RESOURCE_QUESTION)}</div>
            <Radio.Group
              style={{marginTop: '9px'}}
              onChange={(value) => setHasMadePurchases(value.target.value)}
              value={hasMadePurchases}>
              <Radio value={true} style={radioStyle}>
                {i18n.t(k.GENERIC__YES_I_HAVE)}
              </Radio>
              <Radio value={false} style={radioStyle}>
                {i18n.t(k.GENERIC__NO_I_HAVE_NOT)}
              </Radio>
              {hasMadePurchases === false && (
                <AlertContainer>
                  <Alert
                    style={{
                      padding: '10px',
                      alignItems: 'center',
                      height: '62px',
                    }}
                    description={
                      <AlertText>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: i18n.t(
                              k.PROGRAM__REMOVE__WARN_VC_DEDUCTION__FORMAT,
                              {
                                localizedAmount: formatCurrency(
                                  approvedAmount,
                                  getUserQuery.data?.currency,
                                  exchangeRate,
                                  {
                                    decimal: true,
                                  }
                                ),
                              }
                            ),
                          }}></span>
                      </AlertText>
                    }
                    type="warning"
                    showIcon
                    icon={<WarningOutlined aria-hidden="true" />}
                  />
                </AlertContainer>
              )}
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </LearnInModal>
  );
}

export default RemoveResourceModal;
