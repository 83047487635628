import {AcademyUserStatus} from './../enums';
import {AcademyUserPayload} from './../interfaces';
import {
  IAcademyUserBasicInfoMockData,
  getIAcademyUserBasicInfoMockData,
} from './IAcademyUserBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyUserPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyUserPayloadMockData(
  args?: Partial<AcademyUserPayload>
): AcademyUserPayload {
  return {
    ...getIAcademyUserBasicInfoMockData(),
    academyId: faker.datatype.number(),
    status: AcademyUserStatus.InProgress,
    userCompanyIds: [faker.datatype.number()],
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyUserPayloadMockData: AcademyUserPayload = {
  ...IAcademyUserBasicInfoMockData,
  academyId: 1,
  status: AcademyUserStatus.InProgress,
  userCompanyIds: [1],
};
