import './ManagePrepayment.scss';
import {useState} from 'react';
import {CreditCardDetailsVM} from '@models/api/payment/viewModels';
import {useQuery} from 'react-query';
import PrepaymentCard from '../prepaymentCard/PrepaymentCard';
import NoCreditCard from '../noCreditCard/NoCreditCard';
import {getCardOverviewRq} from '../../store/apiEndpoints';
import {simpleQueryFn, simpleSetQueryDataFn} from '../../store/queryClient';
import LoadingSpinner from '../../elements/loadingSpinner/LoadingSpinner';
import TransactionCard from '@blocks/transactionCard/TransactionCard';
import {TransactionsTabName} from '@models/clientEnums';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {Tabs} from 'antd';
import AdminActivityContainer from '@components/reusable/AdminActivity/AdminActivity.container';
import EmptyDataText from '@blocks/NoDataText';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {MONEY_DEFAULT} from '@utils/moneyUtils';

const {TabPane} = Tabs;

const TabPaneContent = styled.div`
  background: ${COLORS.White};
`;
const CustomEmptyDataTextContainer = styled.div`
  & div {
    height: fit-content !important;
  }
  & div h1 {
    font-size: 1rem !important;
    color: ${COLORS.Neutral600} !important;
  }
`;
interface Props {
  planItemId: string | number;
}

const ManagePrepayment = ({planItemId}: Props) => {
  const cardOverviewRq = getCardOverviewRq(planItemId);
  const {data, error, isError, isLoading, isSuccess} =
    useQuery<CreditCardDetailsVM>(cardOverviewRq.queryKey, () =>
      simpleQueryFn(cardOverviewRq.path)
    );
  const [activeTabKey, setActiveTabKey] = useState(
    TransactionsTabName.TRANSACTIONS
  );
  const updateCardOverview = (data: CreditCardDetailsVM): void => {
    simpleSetQueryDataFn(cardOverviewRq.queryKey[0], data);
  };
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isSuccess && data) {
    return (
      <>
        <PrepaymentCard creditCard={data} />
        <LearnInTabs
          defaultActiveKey={TransactionsTabName.TRANSACTIONS}
          activeKey={activeTabKey}
          onTabClick={(key) => {
            switch (key) {
              case TransactionsTabName.TRANSACTIONS:
                return setActiveTabKey(TransactionsTabName.TRANSACTIONS);
              case TransactionsTabName.ACTIVITY:
                return setActiveTabKey(TransactionsTabName.ACTIVITY);
              default:
                return false;
            }
          }}>
          <TabPane
            tab={i18n.t(k.TRANSACTION__PLURAL)}
            key={TransactionsTabName.TRANSACTIONS}>
            <TabPaneContent>
              <TransactionCard planItemId={planItemId} />
            </TabPaneContent>
          </TabPane>
          <TabPane
            tab={i18n.t(k.ADMIN_ACTIVITY)}
            key={TransactionsTabName.ACTIVITY}>
            <TabPaneContent>
              {data?.adminActions?.length ? (
                <AdminActivityContainer
                  adminActions={data?.adminActions}
                  usersCurrency={getUserQuery.data?.currency}
                  exchangeRate={exchangeRate}
                />
              ) : (
                <CustomEmptyDataTextContainer>
                  <EmptyDataText
                    header={i18n.t(k.ADMIN_ACTIVITY__EMPTY__TITLE)}
                    subHeader={i18n.t(k.ADMIN_ACTIVITY__EMPTY__DESCRIPTION)}
                  />
                </CustomEmptyDataTextContainer>
              )}
            </TabPaneContent>
          </TabPane>
        </LearnInTabs>
      </>
    );
  } else {
    if (isError && (error as any)?.response?.status == 404) {
      return (
        <NoCreditCard
          planItemId={planItemId}
          errors={(error as any)?.response?.data}
          updateCardOverview={updateCardOverview}
        />
      );
    }
    return null;
  }
};

export default ManagePrepayment;
