import {useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import LearnInTable, {
  IColumn,
  renderDateTime,
} from '@components/reusable/LearnInTable';
import {FooterContainer} from '@components/reusable/Footer/Footer';
import {Checkbox, Input} from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import {
  ClearAllContainer,
  Container,
  ContentsCount,
  ContentTitle,
  FilterButton,
  FilterButtonsContainer,
  FiltersContainer,
  FooterButtonContainer,
  FooterContent,
  HostedChip,
  OptionLabel,
  ProgramsSelectedContainer,
  SearchAndButtonContainer,
} from './AddCatalogContentDrawer.styled';
import {renderInputType} from '@components/reusable/CatalogContent/utils';
import ReactSelect, {components} from 'react-select';
import {
  AcademyStepVM,
  LxpContentId,
  LxpContentSearchResult,
} from '@generated/interfaces';
import {PaginationInfo} from './AddCatalogContentDrawer.container';
import {LxpContentType} from '@generated/enums';

export interface AddMarketplaceProgramDrawerProps {
  allSelected: boolean;
  newlySelected: LxpContentId[];
  dataSource: LxpContentSearchResult[];
  onClose: () => void;
  addSteps: () => void;
  checkAll: () => void;
  onClickCheckbox: (id) => void;
  clearSelectedPrograms: () => void;
  handleSearch: (searchTerm: any) => void;
  handleTableChange: (
    pagination: Partial<{
      total: number;
    }>,
    filters: any,
    sorter: any
  ) => void;
  handleChangeFilters: (item, filter) => void;
  filters: {
    type: any;
    provider: any;
    endorsed: any;
  };
  selectContent: (content) => void;
  tableParams: any;
  typeOptions: any;
  providerOptions: any;
  paginationInfo?: PaginationInfo;
  endorsedOptions: any;
  isLoading: boolean;
  totalPages?: number;
  pageSize?: number;
  existingSteps?: AcademyStepVM[];
}

function AddCatalogContentDrawer({
  allSelected,
  newlySelected,
  dataSource,
  onClose,
  addSteps,
  checkAll,
  onClickCheckbox,
  clearSelectedPrograms,
  handleSearch,
  handleTableChange,
  handleChangeFilters,
  filters,
  selectContent,
  tableParams,
  typeOptions,
  providerOptions,
  paginationInfo,
  endorsedOptions,
  isLoading,
  totalPages,
  pageSize,
  existingSteps,
}: AddMarketplaceProgramDrawerProps) {
  const goToPathWay = (url: string) => {
    window.open(url, '_blank');
  };
  const renderDrawerHeader = () => {
    return (
      <SearchAndButtonContainer
        aria-label={i18n.t(k.PROGRAM)}
        role="search"
        onSubmit={(e) => e.preventDefault()}></SearchAndButtonContainer>
    );
  };

  const isChecked = (currentRow) => {
    return newlySelected?.some((e) => e.id === currentRow);
  };

  const programsColumns = useMemo(() => {
    return [
      {
        title: () => {
          return (
            <Checkbox
              checked={allSelected}
              onClick={checkAll}
              aria-label={i18n.t(k.CTA__CHECK_ALL)}
            />
          );
        },
        dataIndex: 'selected',
        key: 'selected',
        render: (selected, row) => {
          const existingStepTitles = new Set(
            existingSteps.map((step) => step.title)
          );
          const isExistingStep = existingStepTitles.has(row.title);
          return (
            <Checkbox
              disabled={isExistingStep}
              style={{marginTop: '8px', opacity: isExistingStep ? 0.5 : 1}}
              checked={isExistingStep || isChecked(row.id)}
              onChange={() =>
                onClickCheckbox({id: row.id, contentType: row.contentType})
              }
              aria-label={`${i18n.t(k.CTA__CHECK_ITEM)} ${
                row.title
              } ${renderInputType(row.contentType)}`}
            />
          );
        },
      },
      {
        title: i18n.t(k.GENERIC__TITLE),
        dataIndex: 'title',
        key: 'title',
        render: (title, row) => (
          <ContentTitle
            aria-label={`${row.title}`}
            onClick={
              row.contentType !== LxpContentType.Pathway
                ? () => selectContent(row)
                : () => goToPathWay(row.url)
            }>
            {title}
          </ContentTitle>
        ),
      },
      {
        title: '',
        dataIndex: 'isHosted',
        key: 'isHosted',
        render: (isHosted) => (
          <span className="admin-program-list-data">
            {isHosted ? (
              <HostedChip>{i18n.t(k.GENERIC__HOSTED)}</HostedChip>
            ) : (
              ''
            )}
          </span>
        ),
      },
      {
        title: i18n.t(k.GENERIC__TYPE),
        dataIndex: 'contentType',
        key: 'contentType',
        render: (contentType) => (
          <span className="admin-program-list-data">
            {renderInputType(contentType)}
          </span>
        ),
      },
      {
        title: i18n.t(k.GENERIC__DATE_UPDATED),
        dataIndex: 'dateUpdated',
        key: 'dateUpdated',
        render: (dateUpdated: string) => {
          return dateUpdated ? renderDateTime()(dateUpdated) : '-';
        },
      },
    ] as IColumn[];
  }, [newlySelected]);

  const Option = (props: any) => {
    if (
      props.label == 'Unknown' ||
      props.label == 'Badge' ||
      props.label == 'Position'
    ) {
      return;
    }
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <OptionLabel>{props.label}</OptionLabel>
        </components.Option>
      </div>
    );
  };

  return (
    <LearnInPageDrawer
      visible={true}
      onClose={onClose}
      headerContent={renderDrawerHeader()}
      title={i18n.t(k.CONTENT__SELECT_FROM_CATALOG)}>
      <Container>
        <FiltersContainer>
          <Input
            prefix={<SearchOutlined />}
            allowClear
            placeholder={i18n.t(k.GENERIC__SEARCH)}
            style={{
              width: '270px',
              marginRight: '16px',
              borderRadius: '8px',
              padding: '0 12px 0 12px',
            }}
            onChange={handleSearch}
          />
          <FilterButtonsContainer>
            <FilterButton>
              <ReactSelect
                options={typeOptions}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                placeholder={i18n.t(k.GENERIC__TYPE)}
                isSearchable={false}
                controlShouldRenderValue={false}
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                onChange={(item) => {
                  handleChangeFilters(item, 'Type');
                }}
              />
            </FilterButton>
            <FilterButton>
              <ReactSelect
                options={providerOptions}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                placeholder={i18n.t(k.GENERIC__PROVIDER)}
                isSearchable={false}
                controlShouldRenderValue={false}
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                onChange={(item) => {
                  handleChangeFilters(item, 'Provider');
                }}
              />
            </FilterButton>
            <FilterButton>
              <ReactSelect
                options={endorsedOptions}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                placeholder={i18n.t(k.GENERIC__ENDORSED)}
                isSearchable={false}
                controlShouldRenderValue={false}
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable={false}
                onChange={(item) => {
                  handleChangeFilters(item, 'Endorsed');
                }}
              />
            </FilterButton>
          </FilterButtonsContainer>
        </FiltersContainer>
        <ContentsCount isVisible={!!dataSource?.length}>
          {i18n.t(k.GENERIC__SHOWING_X_TO_Y_OF_Z__FORMAT, {
            ...paginationInfo,
            itemType: i18n.t(k.GENERIC__RESULTS),
          })}
        </ContentsCount>
        <LearnInTable
          dataSource={dataSource}
          columns={programsColumns}
          isLoading={isLoading}
          totalPages={totalPages}
          pageSize={pageSize}
          noSearchResults={false}
          pagination={true}
          onChange={handleTableChange}
          currentPage={tableParams?.pagination?.current}
          scroll={{x: 'max-content'}}
        />
      </Container>

      {newlySelected.length > 0 && (
        <FooterContainer>
          <FooterContent>
            <ClearAllContainer>
              <button onClick={clearSelectedPrograms}>
                {i18n.t(k.CTA__CLEAR_ALL)}
              </button>
            </ClearAllContainer>

            <FooterButtonContainer>
              <ProgramsSelectedContainer>
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(
                      newlySelected.length === 1
                        ? i18n.t(k.ITEM__SELECTED__SINGLE)
                        : k.ITEM__SELECTED__PLURAL__FORMAT,
                      {
                        number: newlySelected.length,
                      }
                    ),
                  }}></span>
              </ProgramsSelectedContainer>
              <LearnInButton tag={ButtonTags.Primary} onClick={addSteps}>
                {i18n.t(k.PROGRAM__ADD_TO_SECTION)}
              </LearnInButton>
            </FooterButtonContainer>
          </FooterContent>
        </FooterContainer>
      )}
    </LearnInPageDrawer>
  );
}

export default AddCatalogContentDrawer;
