// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/ReimbursementStatus.cs

enum ReimbursementStatus {
  NotPaid,
  PaymentPending,
  Paid,
}

export default ReimbursementStatus;
