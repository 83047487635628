import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {Col, Row} from 'antd';
import LicenseCard from '@components/admin/pages/licenses/components/LicenseCard/LicenseCard';
import AddUpdateLicenseDrawer from './components/AddUpdateLicenseDrawer';
import {EmptyBlockUntilTrue} from '@components/reusable/EmptyBlockUntilTrue';
import {LicenseCardVM} from '@models/serverModels';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {UseQueryResult} from 'react-query';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import {useLocation} from 'react-router-dom';

const NewLicenseButton = styled(LearnInButton)`
  &:focus {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }
`;
const LoadingStateContainer = styled.div`
  margin-top: 20%;
`;
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

type Props = {
  licensesQuery: UseQueryResult<LicenseCardVM[]>;
  handleDeleteLicenseClick: (id: number) => void;
  refreshList: () => void;
  providersInUse: number[];
};

export const LicensesAdminPage = ({
  licensesQuery,
  handleDeleteLicenseClick,
  refreshList,
  providersInUse,
}: Props) => {
  const {pathname} = useLocation();

  const [isLicenseDrawerVisible, setIsLicenseDrawerVisible] = useState(
    pathname?.includes('add')
  );
  const [licenseDrawerId, setLicenseDrawerId] = useState(null);
  const [prevFocusedElem, setPrevFocusedElem] = useState(null);

  const openLicenseDrawer = (licenseId?: number) => {
    setLicenseDrawerId(licenseId);
    setIsLicenseDrawerVisible(true);
    setPrevFocusedElem(document.activeElement);
  };

  const closeLicenseDrawer = () => {
    setIsLicenseDrawerVisible(false);
    if (prevFocusedElem) {
      prevFocusedElem.focus();
    }
  };

  const handleUpdateLicenseClick = (licenseId: number) => {
    openLicenseDrawer(licenseId);
  };

  return (
    <>
      <PageTitleBar
        jsxComponent={
          <NewLicenseButton
            tag={ButtonTags.Primary}
            onClick={() => openLicenseDrawer()}
            data-cy="NewLicenseButton">
            {i18n.t(k.LICENSE__NEW)}
          </NewLicenseButton>
        }
        title={i18n.t(k.LICENSE__PLURAL)}
      />
      {licensesQuery?.isLoading ? (
        <LoadingStateContainer>
          <LoadingSpinner />
        </LoadingStateContainer>
      ) : (
        <EmptyBlockUntilTrue
          condition={!!licensesQuery.data?.length}
          emptyHeader={i18n.t(k.LICENSE__ADD__EMPTY__TITLE)}
          emptyBody={i18n.t(k.LICENSE__ADD__EMPTY__DESCRIPTION)}>
          <Row gutter={[21, 21]} style={{padding: '20px 32px 20px 32px'}}>
            {licensesQuery.data.map((license: LicenseCardVM) => {
              return (
                <Col key={license.id}>
                  <LicenseCard
                    license={license}
                    handleUpdateLicenseClick={handleUpdateLicenseClick}
                    handleDeleteLicenseClick={
                      handleDeleteLicenseClick
                    }></LicenseCard>
                </Col>
              );
            })}
          </Row>
        </EmptyBlockUntilTrue>
      )}

      {isLicenseDrawerVisible && (
        <AddUpdateLicenseDrawer
          onClose={() => closeLicenseDrawer()}
          id={licenseDrawerId}
          refreshParentData={refreshList}
          visible={isLicenseDrawerVisible}
          providersInUse={providersInUse}
        />
      )}
    </>
  );
};
