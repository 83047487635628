import {CurrencyCode} from './../enums';
import {ExchangeRatesVM} from './../interfaces';
import {
  IExchangeRateBasicInfoMockData,
  getIExchangeRateBasicInfoMockData,
} from './IExchangeRateBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: ExchangeRatesVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getExchangeRatesVMMockData(
  args?: Partial<ExchangeRatesVM>
): ExchangeRatesVM {
  return {
    ...getIExchangeRateBasicInfoMockData(),
    exchangeRate: faker.datatype.number(),
    id: faker.datatype.number(),
    toCurrencyCode: CurrencyCode.USD,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ExchangeRatesVMMockData: ExchangeRatesVM = {
  ...IExchangeRateBasicInfoMockData,
  exchangeRate: 123,
  id: 1,
  toCurrencyCode: CurrencyCode.USD,
};
