import {notify} from '@components/user/notifications';
import {LicenseRedemptionType, VoucherAssignMode} from '@generated/enums';
import {RadioChangeEvent} from 'antd';
import {UseMutationResult} from 'react-query';
import {
  LicenseDuration,
  LicenseFormFieldErrors,
  LicenseFormFields,
  LicenseInternalCost,
  LicenseSubmissionWithErrorsProps,
} from './AddUpdateLicenseDrawer.types';
import {
  validateAllForLicense,
  validateLicenseApprovalGuideline,
  validateLicenseCost,
  validateLicenseDuration,
  validateLicenseFormHasErrors,
  validateProviderId,
  validateRedemptionGuideline,
  validateRedemptionType,
} from './AddUpdateLicenseDrawer.validation';
import {LicensePayload} from '@generated/interfaces';
import {
  getLicenseDurationByType,
  getLicenseInternalCostByType,
} from './AddUpdateLicenseDrawer.utils';

export const handleLicenseProviderChange = ({
  formFieldErrors,
  providerName,
  providerSuggestions,
  setFormFieldErrors,
  setProviderId,
  setProvider,
}: {
  formFieldErrors: LicenseFormFieldErrors;
  providerName: string;
  providerSuggestions: {label: string; value: string; id: number}[];
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  setProviderId: (providerId: number) => void;
  setProvider: (provider: string) => void;
}) => {
  const selectedProvider = providerSuggestions.find(
    ({label}) => label === providerName
  );
  setProviderId(selectedProvider?.id);
  validateProviderId({
    formFieldErrors,
    id: selectedProvider?.id,
    setFormFieldErrors,
  });
  setProvider(selectedProvider.label);
};

export const handleLicenseCostTypeChange = ({
  costType,
  formFieldErrors,
  setFormFieldErrors,
  setInternalCostType,
}: {
  costType: LicenseInternalCost;
  formFieldErrors: LicenseFormFieldErrors;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  setInternalCostType: (costType: LicenseInternalCost) => void;
}) => {
  setInternalCostType(costType);

  switch (costType) {
    case LicenseInternalCost.Fixed:
    default:
      setTimeout(() => {
        const costField = document.getElementById(
          LicenseFormFields.InternalCost
        );
        costField?.focus();
      }, 400);
      break;
    case LicenseInternalCost.Dependent:
      setFormFieldErrors({
        ...formFieldErrors,
        ...{[LicenseFormFields.InternalCost]: null},
      });
      break;
  }
};

export function handleLicenseCostBlur({
  formFieldErrors,
  internalCostType,
  shouldSetError = true,
  setFormFieldErrors,
  target,
}: {
  formFieldErrors: LicenseFormFieldErrors;
  internalCostType: LicenseInternalCost;
  shouldSetError: boolean;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  target: HTMLInputElement;
}) {
  return validateLicenseCost({
    cost: Number(target?.value),
    formFieldErrors,
    internalCostType,
    shouldSetError,
    setFormFieldErrors,
  });
}

export const handleLicenseRedemptionTypeChange = ({
  formFieldErrors,
  redemptionType,
  setFormFieldErrors,
  setInternalCostType,
  setRedemptionType,
  shouldSetError,
}: {
  formFieldErrors: LicenseFormFieldErrors;
  redemptionType: LicenseRedemptionType;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  setInternalCostType: (internalCostType: LicenseInternalCost) => void;
  setRedemptionType: (redemptionType: LicenseRedemptionType) => void;
  shouldSetError: boolean;
}) => {
  setRedemptionType(redemptionType);
  setInternalCostType(LicenseInternalCost.Fixed);
  validateRedemptionType({
    redemptionType,
    formFieldErrors,
    shouldSetError,
    setFormFieldErrors,
  });
};

export const handleLicenseDurationChange = ({
  event,
  formFieldErrors,
  licenseDurationType,
  setFormFieldErrors,
  setLicenseDurationType,
}: {
  event: RadioChangeEvent;
  formFieldErrors: LicenseFormFieldErrors;
  licenseDurationType: LicenseDuration;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  setLicenseDurationType: (durationType: LicenseDuration) => void;
}) => {
  setLicenseDurationType(event?.target?.value);
  if (event?.target?.value === LicenseDuration.NoExpiration) {
    // Clear any left-over error from prior duration type selection
    setFormFieldErrors({
      ...formFieldErrors,
      ...{[LicenseFormFields.LicenseDuration]: null},
    });
  }
  licenseDurationType === LicenseDuration.Fixed &&
    setTimeout(() => {
      const costField = document.getElementById(
        LicenseFormFields.LicenseDuration
      );
      costField?.focus();
    }, 400);
};

export const handleLicenseDurationBlur = ({
  duration,
  formFieldErrors,
  licenseDurationType,
  redemptionType,
  setFormFieldErrors,
  shouldSetError,
}: {
  duration: number;
  formFieldErrors: LicenseFormFieldErrors;
  licenseDurationType: LicenseDuration;
  redemptionType: LicenseRedemptionType;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  shouldSetError: boolean;
}) => {
  validateLicenseDuration({
    duration,
    redemptionType,
    formFieldErrors,
    licenseDurationType,
    setFormFieldErrors,
    shouldSetError,
  });
};

export const handleLicenseRedemptionGuidelineChange = ({
  editor,
  formFieldErrors,
  setFormFieldErrors,
  setLicenseRedemptionGuideline,
}: {
  editor;
  formFieldErrors: LicenseFormFieldErrors;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  setLicenseRedemptionGuideline: (redemptionGuideline: string) => void;
}) => {
  setLicenseRedemptionGuideline(editor);
  validateRedemptionGuideline({
    formFieldErrors,
    guideline: editor,
    setFormFieldErrors,
    shouldSetError: true,
  });
};

export const handleLicenseApprovalGuidelineChange = ({
  editor,
  formFieldErrors,
  setFormFieldErrors,
  setLicenseApprovalGuideline,
}: {
  editor;
  formFieldErrors: LicenseFormFieldErrors;
  setFormFieldErrors: (formFieldErrors: LicenseFormFieldErrors) => void;
  setLicenseApprovalGuideline: (approvalGuideline: string) => void;
}) => {
  setLicenseApprovalGuideline(editor);
  validateLicenseApprovalGuideline({
    formFieldErrors,
    guideline: editor,
    setFormFieldErrors,
    shouldSetError: true,
  });
};

export const handleLicenseSubmitForm = async (
  payload: LicensePayload,
  saveLicense: UseMutationResult
) => await saveLicense.mutateAsync(payload);

export const handleGetLicensePayload = ({
  approvalGuideline,
  companyId,
  currency,
  formFieldErrors,
  internalCost,
  internalCostType,
  license,
  licenseDuration,
  licenseDurationType,
  providerId,
  redemptionGuideline,
  redemptionType,
  requireAdminApproval,
  requireManagerApproval,
  setFormFieldErrors,
  status,
  type,
  voucherAssignMode,
}: LicenseSubmissionWithErrorsProps): LicensePayload => {
  const errors = validateAllForLicense({
    approvalGuideline,
    formFieldErrors,
    internalCost,
    internalCostType,
    licenseDuration,
    licenseDurationType,
    providerId,
    redemptionGuideline,
    redemptionType,
    setFormFieldErrors,
  });

  if (validateLicenseFormHasErrors(errors)) return;

  return {
    [LicenseFormFields.ApprovalGuideline]: approvalGuideline,
    [LicenseFormFields.CompanyId]: companyId,
    [LicenseFormFields.Currency]: currency,
    [LicenseFormFields.Id]: license?.[LicenseFormFields.Id],
    [LicenseFormFields.InternalCost]: getLicenseInternalCostByType(
      internalCostType,
      internalCost
    ),
    [LicenseFormFields.LicenseDuration]: getLicenseDurationByType(
      licenseDurationType,
      licenseDuration
    ),
    [LicenseFormFields.ProviderId]: providerId,
    [LicenseFormFields.RedemptionGuideline]: redemptionGuideline,
    [LicenseFormFields.RedemptionType]: redemptionType,
    [LicenseFormFields.RequireAdminApproval]: requireAdminApproval,
    [LicenseFormFields.RequireManagerApproval]: requireManagerApproval,
    [LicenseFormFields.Status]: status,
    [LicenseFormFields.Type]: type,
    [LicenseFormFields.VoucherAssignMode]: voucherAssignMode,
  };
};

export const handleOnSaveLicenseSuccess = (
  refreshParentData: () => void,
  onClose: () => void
) => {
  notify.saveLicenseSuccess();
  refreshParentData();
  onClose();
};

export const handleOnSaveLicenseError = () => {
  notify.saveLicenseError();
};

export const getOnSubmitLicenseHandler = ({
  approvalGuideline,
  companyId,
  currency,
  id,
  internalCost,
  internalCostType,
  license,
  licenseDuration,
  licenseDurationType,
  providerId,
  redemptionGuideline,
  redemptionType,
  requireAdminApproval,
  requireManagerApproval,
  saveLicenseMutation,
  setFormFieldErrors,
  status,
  type,
  voucherAssignMode,
}) => {
  const payload = handleGetLicensePayload({
    approvalGuideline,
    companyId,
    currency,
    id,
    internalCost,
    internalCostType,
    license,
    licenseDuration,
    licenseDurationType,
    providerId,
    redemptionGuideline,
    redemptionType,
    requireAdminApproval,
    requireManagerApproval,
    setFormFieldErrors,
    status,
    type,
    voucherAssignMode,
  });

  if (payload) handleLicenseSubmitForm(payload, saveLicenseMutation);
};
