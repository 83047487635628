import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {
  SectionNavContainer,
  SectionNavItemButton,
  ProgressContainer,
  ISectionNavProps,
  NavSectionTitle,
  SectionNavItemProps,
} from './SectionNav';
import LearnInProgressBar from '@components/learnin/ProgressBar';
import {SectionCompleteToggle} from './SectionCompleteToggle';
import {Breakpoints} from '@utils/responsiveUtils';
import {Menu} from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled(SectionNavContainer)`
  position: fixed;
  top: 72px;
  width: 100%;
  bottom: 0;
  background: white;
  z-index: 1000;
  @media (max-width: ${Breakpoints.Tablet}px) {
    display: flex;
  }
  @media (min-width: ${Breakpoints.Tablet}px) {
    display: none;
  }
`;

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

export function SectionNavItem({
  order,
  title,
  onClick,
  active,
  selectedSectionProgress,
}: SectionNavItemProps) {
  return (
    <SectionNavItemButton onClick={onClick} active={active}>
      <div style={{fontWeight: active ? 500 : 400}}>
        {order}. {title}
      </div>
      <div>
        <SectionCompleteToggle checked={selectedSectionProgress === 100} />
      </div>
    </SectionNavItemButton>
  );
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

function MobileNavMenu({
  sectionNavItems,
  allSections,
  progressPercentage,
  navItems,
  onClickCurriculum,
}: ISectionNavProps) {
  return (
    <Container>
      <Menu mode="inline">
        <SubMenu
          title={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
          onTitleClick={onClickCurriculum}>
          {sectionNavItems?.map((section: any, i: number) => {
            return (
              <SectionNavItem
                selectedSectionProgress={allSections[i].progressPercentage}
                key={section.id}
                {...section}
              />
            );
          })}
        </SubMenu>
        {navItems?.map((item) => {
          return (
            <Menu.Item key={item?.label} {...item}>
              {item?.label}
            </Menu.Item>
          );
        })}
      </Menu>
      <ProgressContainer>
        <NavSectionTitle>{i18n.t(k.PROGRESS__YOUR_PROGRESS)}</NavSectionTitle>
        <LearnInProgressBar percent={progressPercentage} />
      </ProgressContainer>
    </Container>
  );
}

export default MobileNavMenu;
