import {useState} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import CustomProgramUserDrawerHeader from './CustomProgramUserDrawerHeader';
import {Modal} from 'antd';
import {CalendarCallbackRedirect, PlanItemType} from '@generated/enums';
import useAddToPlan, {
  useDeleteFromPlanById,
} from '@hooks/apiEndpoints/userPlan/mutations';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {getAddAllCustomProgramDatesToCalendarRm} from '@store/apiEndpoints/customProgram/mutations';
import {useUpcomingCohortsQuery} from '@generated/hooks';
import {simpleMutationFn} from '@store/queryClient';
import {useMutation} from 'react-query';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import MobileNavMenu from '../MobileNavMenu.container';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import useTreatment from '@hooks/useTreatment';
import ConnectCalendarModalContainer from '@blocks/connectCalendarModal/ConnectCalendarModal.container';
import {notify} from '@components/user/notifications';
import {useCustomProgramAgendaQuery} from '@hooks/apiEndpoints/customProgram/queries';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useCustomProgramPermissionVMsQuery} from '@hooks/apiEndpoints/permission/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useCustomProgramPreviewQuery} from '@generated/hooks';

const {confirm} = Modal;

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramUserDrawerHeaderContainerProps {
  customProgramId: number;
  onChangeUpcomingCohortSelect: (id: number) => void;
  onClickEditProgram: () => void;
  onClickViewCohorts?: () => void;
  onClickSectionNavItem: (id: number) => void;
  showCohortButton?: boolean;
  parentCustomProgramId?: number | undefined;
  selectedSectionId: number;
  onClickNavItem: (screen: CustomProgramScreens) => void;
  isActivePlanItem: boolean;
  showAddToPlanButton: boolean;
}

function CustomProgramUserDrawerHeaderContainer({
  customProgramId,
  onChangeUpcomingCohortSelect,
  onClickEditProgram,
  onClickViewCohorts,
  onClickSectionNavItem,
  showCohortButton,
  parentCustomProgramId,
  selectedSectionId,
  onClickNavItem,
  isActivePlanItem,
  showAddToPlanButton,
}: CustomProgramUserDrawerHeaderContainerProps) {
  const [showMobileNavMenu, setShowMobileNavMenu] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = React.useState(false);
  const {isFeatureFlagOn} = useFeatureFlags();

  function handleOpenCalendarModal() {
    setShowCalendarModal(true);
  }

  function handleCloseCalendarModal() {
    setShowCalendarModal(false);
  }

  const getUserQuery = useGetUserQuery();

  const {data: upcomingCohorts} = useUpcomingCohortsQuery(
    {
      customProgramId: parentCustomProgramId || customProgramId,
    },
    {
      enabled: !!(parentCustomProgramId || customProgramId),
    }
  );

  const {data: customProgramPermissions} = useCustomProgramPermissionVMsQuery(
    null,
    {
      enabled: true,
    }
  );

  const programPermission = customProgramPermissions?.find(
    (permission) => permission.customProgramId === customProgramId
  );
  const userHasEditPermission = programPermission?.canEdit;
  const {isAdmin} = useAuth();

  const showEditProgramLink =
    !isActivePlanItem && (isAdmin || userHasEditPermission);
  const showCohortLink = showCohortButton && !isActivePlanItem;

  // Calendar
  const isConnectCalendarOn =
    useTreatment(FeatureFlagExperiments.ConnectCalendar) ===
    FeatureFlagTreatments.On;

  const isExternalCalendarConnected =
    getUserQuery.data?.connectedCalendar !== null;

  // Preview Details
  const customProgramsPreviewDetailsQuery = useCustomProgramPreviewQuery(
    {customProgramId, queryParams: {includeProgress: true}},
    {enabled: false}
  );

  const {data: customProgram} = customProgramsPreviewDetailsQuery;
  const CustomProgramAgendaQuery = useCustomProgramAgendaQuery(
    customProgramId,
    {
      enabled: !!customProgramId,
    }
  );

  // Add to plan
  const addToPlanMutation = useAddToPlan({
    onSuccess: async () => {
      customProgramsPreviewDetailsQuery.invalidateExact();
    },
  });

  // Add dates to calendar
  const addDatesToCalendarMutation = useMutation(
    () => {
      const queryRm = getAddAllCustomProgramDatesToCalendarRm(customProgramId);
      return simpleMutationFn(queryRm.path, queryRm.payload);
    },
    {
      onSuccess: async () => {
        customProgramsPreviewDetailsQuery.invalidateExact();
        CustomProgramAgendaQuery.invalidateExact();
        notify.addEventsToCalendarSuccess();
      },
      onError: () => {
        notify.addEventsToCalendarError();
      },
    }
  );

  const handleClickAddToPlan = () => {
    if (!isActivePlanItem) return;
    addToPlanMutation.mutate({
      optionId: customProgramId,
      planItemType: PlanItemType.Program,
    });
    if (!isExternalCalendarConnected) handleOpenCalendarModal();
    return;
  };

  // Delete plan items
  const deletePlanItemByIdMutation = useDeleteFromPlanById({
    onSuccess: () => {
      customProgramsPreviewDetailsQuery.invalidateExact();
    },
  });

  const handleClickRemove = () => {
    confirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      icon: <ExclamationCircleOutlined aria-hidden="true" />,
      okText: i18n.t(k.CTA__DELETE),
      okType: 'danger',
      title: i18n.t(k.PROMPT__DELETE),
      async onOk() {
        deletePlanItemByIdMutation.mutate({
          itemId: customProgram?.planItemId,
          itemType: PlanItemType.Program,
        });
      },
      onCancel() {
        // intentional pass
      },
    });
  };

  return (
    <>
      <CustomProgramUserDrawerHeader
        customProgramId={customProgramId}
        onChangeUpcomingCohortSelect={onChangeUpcomingCohortSelect}
        onClickAddToCalendar={async () =>
          await addDatesToCalendarMutation.mutateAsync()
        }
        onClickAddToPlan={handleClickAddToPlan}
        onClickEditProgram={onClickEditProgram}
        onClickViewCohorts={onClickViewCohorts}
        showEditProgramLink={showEditProgramLink}
        onClickRemove={handleClickRemove}
        showCohortLink={showCohortLink}
        onCloseMobileNavMenu={() => setShowMobileNavMenu(false)}
        onOpenMobileNavMenu={() => setShowMobileNavMenu(true)}
        showAddToCalendarButton={
          isActivePlanItem &&
          isConnectCalendarOn &&
          isExternalCalendarConnected &&
          customProgram?.selected &&
          !customProgram.allDatesOnCalendar
        }
        showAddToCalenderButtonAsLoading={addDatesToCalendarMutation.isLoading}
        showMobileNavMenu={showMobileNavMenu}
        showAddToPlanButton={showAddToPlanButton}
        showPlanItemContextMenu={
          isActivePlanItem &&
          customProgramsPreviewDetailsQuery?.isSuccess &&
          customProgram?.selected
        }
        showCalendarMenu={
          isActivePlanItem &&
          !isExternalCalendarConnected &&
          isConnectCalendarOn &&
          customProgramsPreviewDetailsQuery?.isSuccess &&
          customProgram?.selected
        }
        upcomingCohorts={upcomingCohorts || []}
      />
      {showMobileNavMenu && (
        <MobileNavMenu
          customProgramId={customProgramId}
          selectedSectionId={selectedSectionId}
          onClickSectionNavItem={(id) => {
            onClickSectionNavItem(id);
            setShowMobileNavMenu(false);
          }}
          onClickNavItem={onClickNavItem}
          hideMobileNavMenu={() => setShowMobileNavMenu(false)}
        />
      )}
      <ConnectCalendarModalContainer
        visible={showCalendarModal}
        id={customProgramId}
        redirectOption={CalendarCallbackRedirect.CustomProgram}
        handleCloseCalendarModal={handleCloseCalendarModal}
      />
    </>
  );
}

export default CustomProgramUserDrawerHeaderContainer;
