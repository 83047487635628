import {ContentType} from './../enums';
import {CustomProgramAgendaVM} from './../interfaces';
import {
  AttachmentVMMockData,
  getAttachmentVMMockData,
} from './AttachmentVM.mock';
import {LinkVMMockData, getLinkVMMockData} from './LinkVM.mock';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramAgendaVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramAgendaVMMockData(
  args?: Partial<CustomProgramAgendaVM>
): CustomProgramAgendaVM {
  return {
    addedToExternalCalendar: faker.datatype.boolean(),
    attachments: [getAttachmentVMMockData()],
    completed: faker.datatype.boolean(),
    contentType: ContentType.Text,
    description: faker.lorem.lines(),
    endDateUtc: String(faker.date.past()), // optional
    eventLinkUrl: faker.internet.url(),
    id: faker.datatype.number(),
    links: [getLinkVMMockData()],
    startDateUtc: String(faker.date.past()), // optional
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramAgendaVMMockData: CustomProgramAgendaVM = {
  addedToExternalCalendar: true,
  attachments: [AttachmentVMMockData],
  completed: true,
  contentType: ContentType.Text,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  endDateUtc: '2023-03-29T22:17:19.738072', // optional
  eventLinkUrl: 'https://this-person-does-not-exist.com/en',
  id: 1,
  links: [LinkVMMockData],
  startDateUtc: '2023-03-29T22:17:19.738072', // optional
  title: 'Advanced Web Design',
};
