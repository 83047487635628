import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {MultiValue, ActionMeta} from 'react-select';
import {SkillDetail} from '@generated/interfaces';
import {useState} from 'react';
import AsyncSelect from 'react-select/async';
import styled, {keyframes} from 'styled-components';
import translate from '@i18n/translate';
import {
  formatSkillTagOptions,
  MIN_LENGTH_SEARCH_TERM,
  SkillOption,
} from './SkillsTagger.container';

import './skills-tagger.scss';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${COLORS.Neutral300};
`;

const Title = styled.h3`
  font-size: 1rem;
  margin-bottom: 0;
`;

const Name = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  color: ${COLORS.Neutral950};
  margin-bottom: -4px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ErrorMessage = styled.p`
  color: ${COLORS.Red600};
`;

const SkillsSection = styled.div`
  animation: ${fadeIn} 0.3s ease-in-out;
`;

interface SkillsTaggerProps {
  showMaxSkillTagsError: boolean;
  isLoadingMutation: boolean;
  // For React-Select, which requires the inadvisable 'Function' type
  // eslint-disable-next-line @typescript-eslint/ban-types
  loadOptions: (inputValue: string, callback: Function) => void;
  skillTags: SkillDetail[];
  onChangeSkillTagSelect: (
    updatedSkillOptions: MultiValue<SkillOption>,
    event: ActionMeta<SkillOption>
  ) => void;
}

export function SkillsTagger({
  loadOptions,
  skillTags,
  isLoadingMutation,
  showMaxSkillTagsError,
  onChangeSkillTagSelect,
}: SkillsTaggerProps) {
  const [showSkills, setShowSkills] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');

  return (
    <Container>
      <Header>
        <Title>{i18n.t(k.GENERIC__SKILL__PLURAL)}</Title>
        <LearnInButton
          tag={ButtonTags.SecondaryRemovedBorder}
          onClick={() => setShowSkills(!showSkills)}>
          {showSkills ? translate('CTA__HIDE') : translate('CTA__SHOW')}
        </LearnInButton>
      </Header>
      {showSkills && (
        <SkillsSection>
          <AsyncSelect
            isLoading={isLoadingMutation}
            cacheOptions
            inputValue={inputValue}
            isClearable={false}
            isMulti
            loadOptions={loadOptions}
            name="Academy Skills"
            onInputChange={setInputValue}
            placeholder={translate('CTA__SEARCH_SKILLS')}
            value={formatSkillTagOptions(skillTags)}
            noOptionsMessage={({inputValue}) => {
              return inputValue?.length >= MIN_LENGTH_SEARCH_TERM
                ? translate('LABEL__SEARCH_NO_SKILLS_FOUND')
                : null;
            }}
            onChange={onChangeSkillTagSelect}
          />
          {showMaxSkillTagsError && (
            <ErrorMessage>{i18n.t(k.SKILL_ERROR)}</ErrorMessage>
          )}
        </SkillsSection>
      )}
    </Container>
  );
}
