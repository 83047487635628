// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/ContentType.cs

enum ContentType {
  Text,
  Project,
  Event,
  Discussion,
}

export default ContentType;
