import t from '@i18n/translate';

type CalendarLinkInfo = {
  googleCalendarLink: string;
  outlookCalendarLink: string;
};

export const getCalendarLinks = (linkInfo: CalendarLinkInfo) => {
  const links = [];
  if (linkInfo.googleCalendarLink) {
    links.push({
      label: t('CALENDAR__GOOGLE'),
      url: linkInfo.googleCalendarLink,
    });
  }
  if (linkInfo.outlookCalendarLink) {
    links.push({
      label: t('CALENDAR__OUTLOOK'),
      url: linkInfo.outlookCalendarLink,
    });
  }

  return links;
};
