import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import CustomProgramsTable from './CustomProgramsTable';
import {Divider, Modal} from 'antd';
import {CustomProgramAdminDetailVM} from '@models/serverModels';
import {useMutation} from 'react-query';
import {simpleDeleteFn} from '@store/queryClient';
import {getDeleteCustomProgramRm} from '@store/apiEndpoints/customProgram/mutations';
import {notify} from '@components/user/notifications';
import {CustomProgramStatus} from '@generated/enums';
import {useDuplicateCustomProgramMutation} from '@generated/hooks';
import {
  CustomProgramScreens,
  adminProgramsCustomProgramManage,
  adminCustomProgramScreenRoute,
  getUserProgramsCustomProgramUserDrawer,
} from '@utils/ClientPaths';
import CustomProgramCohortsPage from './customPrograms/cohorts/CustomProgramCohortsPage.container';
import CohortAdminPageDrawer from '@components/admin/pages/programs/customPrograms/cohorts/CohortAdminPageDrawer.container';
import {useNavigate} from 'react-router-dom';
import {useCustomProgramAdminDetailVMsQuery} from '@hooks/apiEndpoints/customProgram/queries';
import useLanguagePreference from '@hooks/useLanguagePreference';

const {confirm} = Modal;

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramsTableContainerProps {
  dataSource: CustomProgramAdminDetailVM[];
  pagination?: boolean;
  hasContainer?: boolean;
  closeCohortsDrawer?: () => void;
  disableClickRow?: boolean;
  openCustomProgramEditDrawerAfterDuplication?: (
    customProgram: Pick<CustomProgramAdminDetailVM, 'id' | 'status'>
  ) => void;
}

function CustomProgramsTableContainer({
  dataSource,
  pagination,
  closeCohortsDrawer,
  hasContainer,
  openCustomProgramEditDrawerAfterDuplication,
  disableClickRow,
}: CustomProgramsTableContainerProps) {
  const navigate = useNavigate();
  //const languagePreference = useLanguagePreference();

  const [customProgramIdForAddCohort, setCustomProgramIdForAddCohort] =
    useState<number | undefined>();
  const [cohortForEditId, setCohortForEditId] = useState<number | undefined>();
  const [customProgramForCohortPage, setCustomProgramForCohortPage] = useState<
    undefined | CustomProgramAdminDetailVM
  >();
  const [duplicatingCustomProgramId, setDuplicatingCustomProgramId] = useState<
    undefined | number
  >();

  // Custom Programs List Table Data
  const customProgramsAdminDetailQuery = useCustomProgramAdminDetailVMsQuery({
    enabled: false,
  });

  const deleteCustomProgramMutation = useMutation(
    (id: number) => {
      const deleteCustomProgramRm = getDeleteCustomProgramRm(id);
      return simpleDeleteFn<number>(
        deleteCustomProgramRm.path,
        deleteCustomProgramRm.payload
      );
    },
    {
      onSuccess: async (id) => {
        !!closeCohortsDrawer && closeCohortsDrawer();
        await customProgramsAdminDetailQuery.deleteProgramFromCache(id);
        notify.deleteCustomProgramSuccess();
      },
      onError: () => {
        notify.deleteCustomProgramError();
      },
    }
  );

  const handleClickDeleteProgram = async (id: number) => {
    confirm({
      icon: false,
      content: (
        <>
          <p>{i18n.t(k.PROGRAM__CUSTOM__DELETE_CONFIRM)}</p>
          <Divider
            style={{
              position: 'absolute',
              left: 0,
              bottom: '2.75rem',
            }}
          />
        </>
      ),
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__DELETE_ITEM_YES__FORMAT, {
        item: i18n.t(k.PROGRAM),
      }),
      okType: 'danger',
      bodyStyle: {borderRadius: '25px'},
      title: i18n.t(k.PROGRAM__DELETE),
      okButtonProps: {type: 'primary', shape: 'round'},
      cancelButtonProps: {type: 'primary', shape: 'round', ghost: true},
      onOk() {
        deleteCustomProgramMutation.mutateAsync(id);
      },
    });
  };

  const openUpdateCustomProgramDrawer = (
    program: CustomProgramAdminDetailVM
  ) => {
    navigate(
      adminProgramsCustomProgramManage(program?.id, CustomProgramScreens.Setup)
    );
  };

  const duplicateCustomProgram = useDuplicateCustomProgramMutation({
    onSuccess: async (newProgramId) => {
      setDuplicatingCustomProgramId(undefined);
      await customProgramsAdminDetailQuery.refetch().then((res) => {
        // After refetching programs, find the duplicated program and open the drawer.
        const duplicatedCustomProgram = res.data.find(
          (program) => program.id === newProgramId
        );
        // If not in the Cohorts Drawer, Close the Cohorts Drawer and open the Custom Program Edit Drawer
        !!closeCohortsDrawer && closeCohortsDrawer();
        !!openCustomProgramEditDrawerAfterDuplication &&
          openCustomProgramEditDrawerAfterDuplication({
            id: newProgramId,
            status: CustomProgramStatus.Draft,
          });
        // If not in the Cohorts Drawer, open up the Custom Program Edit Drawer immediately
        !!duplicatedCustomProgram &&
          !closeCohortsDrawer &&
          openUpdateCustomProgramDrawer(duplicatedCustomProgram);
      });
      notify.duplicateCustomProgramSuccess();
    },
    onError: () => {
      notify.duplicateCustomProgramError();
    },
  });

  const handleClickViewReports = (program: any) => {
    navigate(
      adminCustomProgramScreenRoute(program.id, CustomProgramScreens.Reports)
    );
  };

  const handleCloseAddCohortDrawer = () => {
    // If you open the Add Cohort Form from the Custom Programs table
    // And create a Cohort, then when you press the back button on the drawer,
    // Instead of only taking you back to the Custom Programs Table,
    // It also then opens the Cohorts Table Drawer
    if (!!cohortForEditId && !customProgramForCohortPage) {
      const customProgramOfNewlyCreatedCohort =
        customProgramsAdminDetailQuery.data?.find(
          ({id}) => id === customProgramIdForAddCohort
        );
      if (!!customProgramOfNewlyCreatedCohort)
        setCustomProgramForCohortPage(customProgramOfNewlyCreatedCohort);
    }
    setCohortForEditId(undefined);
    setCustomProgramIdForAddCohort(undefined);
  };

  const handleClickDuplicateCustomProgram = async (id: number) => {
    setDuplicatingCustomProgramId(id);
    duplicateCustomProgram.mutate({
      pathVars: {
        customProgramId: id,
      },
    });
  };

  const handleTitleClick = (id: number) => {
    navigate(
      getUserProgramsCustomProgramUserDrawer(
        id,
        CustomProgramScreens.Curriculum,
        'admin-custom-programs'
      )
    );
  };

  const handleClickRow = (row: CustomProgramAdminDetailVM) => {
    if (!disableClickRow && !!row.cohortCount) {
      setCustomProgramForCohortPage(row);
    }
  };

  const showRowHoverStyle = (row: CustomProgramAdminDetailVM): boolean => {
    return !disableClickRow && !!row?.cohortCount;
  };

  return (
    <>
      <CustomProgramsTable
        dataSource={dataSource}
        hasContainer={hasContainer}
        onClickDeleteProgram={handleClickDeleteProgram}
        onClickDuplicateCustomProgram={handleClickDuplicateCustomProgram}
        onClickViewReports={handleClickViewReports}
        onClickViewProgram={handleTitleClick}
        openUpdateCustomProgramDrawer={openUpdateCustomProgramDrawer}
        pagination={pagination}
        onClickTitle={handleTitleClick}
        onClickAddCohort={setCustomProgramIdForAddCohort}
        onClickRow={handleClickRow}
        duplicatingCustomProgramId={duplicatingCustomProgramId}
        showRowHoverStyle={showRowHoverStyle}
      />

      {/* This drawer contains a table with all the cohorts */}
      {!!customProgramForCohortPage && (
        <CustomProgramCohortsPage
          onClose={() => setCustomProgramForCohortPage(undefined)}
          customProgram={customProgramForCohortPage}
          openCustomProgramEditDrawerAfterDuplication={
            openCustomProgramEditDrawerAfterDuplication
          }
        />
      )}

      {/* This drawer is used to create a cohort and then edit a newly created cohort */}
      {!!customProgramIdForAddCohort && (
        <CohortAdminPageDrawer
          visible={!!customProgramIdForAddCohort}
          onClose={handleCloseAddCohortDrawer}
          parentCustomProgramId={customProgramIdForAddCohort}
          cohortId={cohortForEditId}
          setCohortForEditIdOnCreate={setCohortForEditId}
        />
      )}
    </>
  );
}

export default CustomProgramsTableContainer;
