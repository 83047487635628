import {FormInstance} from 'antd';
import {validate} from '../utils';
import {Moment} from 'moment';
import {FORM_KEYS} from '../constants';
import {getFormFieldErrors} from '../../AddEditContent.config';
import {RuleObject} from 'antd/lib/form';

/**
 * Validates the event date when start time or end time have been set.
 * Date is not enforced if time is not set.
 */
export function validateEventDate(form: FormInstance) {
  return function (_: RuleObject, eventDate: Moment | null | undefined) {
    const startTime = form.getFieldValue(FORM_KEYS.EVENT_START_TIME);
    const endTime = form.getFieldValue(FORM_KEYS.EVENT_END_TIME);

    if (startTime || endTime) {
      return validate(!!eventDate, getFormFieldErrors().eventDate);
    }

    return Promise.resolve();
  };
}

/**
 * Validates start time if end time is set.
 */
export function validateStartTime(form: FormInstance) {
  return function (_: RuleObject, startTime: Moment) {
    const endTime = form.getFieldValue(FORM_KEYS.EVENT_END_TIME);
    if (endTime) {
      return validate(!!startTime, getFormFieldErrors().startTime);
    }
    return Promise.resolve();
  };
}

/**
 * Validate Time Zone when Event Date, Start Time or End Time are set
 */
export function validateEventTimeZone(form: FormInstance) {
  return function (_: RuleObject, timeZoneId: string | null | undefined) {
    const startTime = form.getFieldValue(FORM_KEYS.EVENT_START_TIME);
    const endTime = form.getFieldValue(FORM_KEYS.EVENT_END_TIME);
    const eventDate = form.getFieldValue(FORM_KEYS.EVENT_DATE);

    if (eventDate || startTime || endTime) {
      return validate(!!timeZoneId, getFormFieldErrors().MissingTimeZone);
    }

    return Promise.resolve();
  };
}
