import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import React from 'react';
import t from '@i18n/translate';
import {
  CardContent,
  Header,
  Subheader,
  Card,
  Divider,
  TableHeader,
  SettingOptionContainer,
  Cell,
  SettingOptionTitle,
  SettingOptionDescription,
  EditPreviewButton,
  CheckboxOption,
  EditIcon,
} from './InvitationEmailCard.styled';

interface InvitationEmailCardProps {
  checkboxValue: boolean;
  onCheckboxChange: (e: CheckboxChangeEvent) => void;
  onClickEditAndPreview: () => void;
}

const InvitationEmailCard: React.FC<InvitationEmailCardProps> = ({
  onCheckboxChange,
  checkboxValue,
  onClickEditAndPreview,
}) => {
  return (
    <Card role="article" aria-labelledby="invitation-email">
      <CardContent>
        <Header id="invitation-email">{t('NOTIFICATION__PLURAL')}</Header>
        <Subheader>{t('ACADEMY__INVITATION_EMAIL__SETUP')}</Subheader>

        <TableHeader>
          <span>{t('NOTIFICATION__TYPE')}</span>
          <span>{t('GENERIC__EMAIL')}</span>
        </TableHeader>

        <Divider />

        <SettingOptionContainer>
          <Cell fill>
            <SettingOptionTitle>
              {t('ACADEMY__INVITATION_EMAIL__TITLE')}
            </SettingOptionTitle>
            <SettingOptionDescription>
              {t('ACADEMY__INVITATION_EMAIL__DESCRIPTION')}
            </SettingOptionDescription>
          </Cell>

          <Cell>
            <EditPreviewButton onClick={onClickEditAndPreview}>
              <EditIcon />
              {t('ACADEMY__INVITATION_EMAIL__EDIT_PREVIEW')}
            </EditPreviewButton>
          </Cell>

          <Cell>
            <CheckboxOption
              onChange={onCheckboxChange}
              checked={checkboxValue}
            />
          </Cell>
        </SettingOptionContainer>
      </CardContent>
    </Card>
  );
};

export default InvitationEmailCard;
