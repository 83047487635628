import {useCallback, useEffect, useRef, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Checkbox, Col, Form, Modal, Radio, Row, Typography} from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import {
  FinanceIncentiveType,
  Permissions,
  ProgramTypesVM,
  RequireApprovalTypeStatus,
} from '../store/interfaces';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  FileTypeDetails,
  FileUploadType,
  generateFileUploadMediaTypes,
  getBase64,
  validateUserUpload,
} from '@utils/uploadUtils';
import {IncentiveAvailability} from '@components/user/store/interface';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {
  EditedIncentiveVM,
  FinanceDetailsVM,
  FinanceIncentivePayload,
} from '@models/serverModels';
import {UserDetailsVM} from '@generated/interfaces';
import {
  BalanceRecurType,
  FinanceAmountType,
  FinanceRecurrence,
  FinanceType,
  IncentiveType,
} from '@generated/enums';
import {INCENTIVE_RECURRING_TYPE} from '@models/clientEnums';
import useDeleteCompanyIncentive from '@hooks/apiEndpoints/incentive/mutations';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {
  LearnInInput,
  LearnInMoneyInput,
} from '@components/reusable/Input/Input.style';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import queryClient, {simpleMutationFn, simplePutFn} from '@store/queryClient';
import {
  getAddFinanceIncentiveRm,
  getIncentivesCountRq,
  getUpdateFinanceIncentiveRm,
} from '@store/apiEndpoints';
import {useMutation} from 'react-query';
import {mapFinanceTypeStringToFinanceType} from '@utils/enumMapping/MapFinanceTypeStringToFinanceType';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {isOverMaxRichTextCharacterCount} from '@utils/form-validation-helpers';
import {APPROVAL_GUIDELINE_MAX_COUNT, COLORS} from '@utils/constants';
import {notify} from '@components/user/notifications';
import {RcFile} from 'antd/lib/upload';
import UploadPreviewLogo from '@blocks/UploadPreviewLogo';
import {AttachmentCard} from '@components/admin/pages/programs/customPrograms/setup/CustomProgramSetupScreen';
import {b64toBlob} from '@utils/image-utils';
import {UnprivilegedEditor} from './AddPersonalLearningBudgetIncentiveDrawer.container';
import {formatPastingOptions} from '@utils/reactQuillUtils';
import {
  exchangeAmount,
  exchangeAmountBack,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import UploadInstructions from '@components/reusable/Upload/UploadInstructions';

interface Props {
  confirmLoading: boolean;
  isSunsetMonthlyRenewalOn: boolean;
  onOk: (e: boolean) => void;
  onCancel: (e: boolean) => void;
  updatingData: FinanceDetailsVM;
  programTypesData: ProgramTypesVM;
  visible: boolean;
  user: UserDetailsVM;
}

const {confirm} = Modal;

const TITLE_FIELD = 'title';
const SHORT_DESCRIPTION_FIELD = 'shortDescription';
const LONG_DESCRIPTION_FIELD = 'longDescription';
const MONEY_FIELD = 'money';
const RECURRING_FIELD = 'singleOrRecurring';
const RECURRING_FREQUENCY_FIELD = 'recurringFrequency';
const BALANCE_RECUR_TYPE_FIELD = 'balanceRenewType';
const AVAILABILITY_FIELD = 'availability';
const PERMISSIONS_FIELD = 'permissions';
const APPROVALS_DETAIL_FIELDS = 'approvalDetails';
const REDEMPTION_TYPE_FIELD = 'redemptionType';
const PROGRAM_TYPE_FIELD = 'programType';
const MONEY_USE_FIELD = 'moneyUse';
const PRORATED_FIELD = 'isProratingEnabled';

const determineIfRequiresApproval = (
  approvalConfig: Record<string, boolean>
): boolean =>
  Object.values(approvalConfig).reduce(
    (prev: boolean, curr: boolean) => prev || curr
  );

export default function AddMoneyIncentiveDrawer({
  isSunsetMonthlyRenewalOn,
  onOk,
  onCancel,
  updatingData,
  programTypesData,
  visible,
  user,
}: Props) {
  const [formRef] = Form.useForm();
  const [approvalGuidelines, setApprovalGuidelines] = useState('');
  const [previewUrl, setPreviewUrl] = useState('');
  const [approvalGuidelinesError, setApprovalGuidelinesError] = useState(false);
  const [isRecurringIncentive, setIsRecurringIncentive] = useState(
    updatingData && updatingData.recurring
  );
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [details, setDetails] = useState('');
  const [programTypeDropdownEnabled, setProgramTypeDropdownEnabled] = useState(
    updatingData
      ? updatingData.availability === IncentiveAvailability.CertainPrograms
      : false
  );
  const [images, setImages] = useState([]);
  const [showRequireApproval, setShowRequireApproval] = useState(
    updatingData && determineIfRequiresApproval(updatingData.approvalConfig)
  );
  const [isPrepaymentIncentive, setIsPrepaymentIncentive] = useState(false);

  const {isFeatureFlagOn} = useFeatureFlags();
  const isProratingOn = isFeatureFlagOn.Prorating;
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  useEffect(() => {
    if (!images.length) {
      setPreviewUrl(null);
      return;
    }
    const url = URL.createObjectURL(b64toBlob(images[0].fileContent));
    setPreviewUrl(url);
  }, [images]);

  useEffect(() => {
    if (!updatingData) return;
    setShowRequireApproval(
      determineIfRequiresApproval(updatingData.approvalConfig)
    );
    setPreviewUrl(updatingData.imageSmall);

    // add an element to represent the image that was already stored
    setImages([
      {
        fileName: 'original',
        fileContent: '',
        description: '',
      },
    ]);
  }, [updatingData]);

  useEffect(() => {
    if (!updatingData) return;
    setIsPrepaymentIncentive(
      mapFinanceTypeStringToFinanceType(updatingData?.financeType) ===
        FinanceType.Prepayment
    );
  }, [updatingData]);

  useEffect(() => {
    updatingData?.amount &&
      formRef.setFieldsValue({
        [MONEY_FIELD]: renderAmount(updatingData?.amount),
      });
  }, [exchangeRate]);

  const deleteCompanyIncentiveMutation = useDeleteCompanyIncentive({
    onSuccess: () => {
      onOk(true);
    },
  });

  const onSubmitSuccess = useCallback(() => {
    queryClient.refetchQueries(getIncentivesCountRq().queryKey[0]);
    setDetails('');
    setConfirmLoading(false);
    onOk(true);
  }, []);

  const onSubmitError = useCallback((error) => {
    console.log('Error', error);
    setConfirmLoading(false);
  }, []);

  const addFinanceIncentiveMutation = useMutation(
    ({incentiveToAdd}: {incentiveToAdd: FinanceIncentivePayload}) => {
      const addFinanceIncentiveRm = getAddFinanceIncentiveRm(incentiveToAdd);
      return simpleMutationFn<EditedIncentiveVM>(
        addFinanceIncentiveRm.path,
        addFinanceIncentiveRm.payload
      );
    },
    {
      onSuccess: () => {
        onSubmitSuccess();
      },
      onError: (error) => onSubmitError(error),
    }
  );

  const updateFinanceIncentiveMutation = useMutation(
    ({incentiveToAdd}: {incentiveToAdd: FinanceIncentivePayload}) => {
      const updateFinanceIncentiveRm =
        getUpdateFinanceIncentiveRm(incentiveToAdd);
      return simplePutFn<EditedIncentiveVM>(
        updateFinanceIncentiveRm.path,
        updateFinanceIncentiveRm.payload
      );
    },
    {
      onSuccess: () => {
        onSubmitSuccess();
      },
      onError: (error) => onSubmitError(error),
    }
  );

  const detailsHTMLContent =
    updatingData && updatingData.sections.length && !details
      ? (
          updatingData.sections.find(
            (section) => section.title === i18n.t(k.GENERIC__DETAIL__PLURAL)
          ) || {}
        ).content
      : details;

  const radioStyle = {
    display: 'block',
    paddingLeft: '15px',
  };

  const [moneyUse, setMoneyUse] = useState<FinanceAmountType>(
    updatingData ? updatingData.amountType : null
  );

  const onApprovalGuidelinesChange = (
    _,
    __,
    ___,
    editor: UnprivilegedEditor
  ) => {
    const value = editor.getHTML();
    if (isOverMaxRichTextCharacterCount(value, APPROVAL_GUIDELINE_MAX_COUNT)) {
      setApprovalGuidelinesError(true);
    } else {
      setApprovalGuidelinesError(false);
    }
    setApprovalGuidelines(value);
  };

  const renderAmount = (amount: number) => exchangeAmount(amount, exchangeRate);

  /**
   * This sets the initial value for all fields
   */
  const getDefaultFormValues = useCallback(() => {
    if (!updatingData) {
      return {};
    }

    const approvalSettings = [];
    const approvalConfig = updatingData.approvalConfig;
    approvalConfig.adminPreApprovalRequired &&
      approvalSettings.push(RequireApprovalTypeStatus.PREAPPROVAL_ADMIN);
    approvalConfig.managerPreApprovalRequired &&
      approvalSettings.push(RequireApprovalTypeStatus.PREAPPROVAL_MANAGER);
    approvalConfig.adminRedemptionApprovalRequired &&
      approvalSettings.push(RequireApprovalTypeStatus.REDEMPTION_ADMIN);
    approvalConfig.managerRedemptionApprovalRequired &&
      approvalSettings.push(RequireApprovalTypeStatus.REDEMPTION_MANAGER);

    const permissionSettings = [];
    determineIfRequiresApproval(updatingData.approvalConfig) &&
      permissionSettings.push(Permissions.RequiresApproval);

    return {
      [TITLE_FIELD]: updatingData.title,
      [SHORT_DESCRIPTION_FIELD]: updatingData.shortDescription,
      [LONG_DESCRIPTION_FIELD]: updatingData.longDescription,
      [AVAILABILITY_FIELD]:
        updatingData.availability === null
          ? IncentiveAvailability.NotAvailable
          : updatingData.availability,
      [REDEMPTION_TYPE_FIELD]: FinanceIncentiveType[updatingData.financeType],
      [PERMISSIONS_FIELD]: permissionSettings,
      [PROGRAM_TYPE_FIELD]:
        updatingData.availability === IncentiveAvailability.CertainPrograms
          ? updatingData.programTypeIds
          : [],
      [MONEY_FIELD]: renderAmount(updatingData.amount),
      [MONEY_USE_FIELD]: updatingData.amountType,
      [RECURRING_FIELD]: updatingData.recurring
        ? INCENTIVE_RECURRING_TYPE.recurring
        : INCENTIVE_RECURRING_TYPE.single,
      [RECURRING_FREQUENCY_FIELD]: updatingData.recurrence,
      [APPROVALS_DETAIL_FIELDS]: approvalSettings,
    };
  }, [updatingData]);

  const title = i18n.t(
    !updatingData
      ? k.INCENTIVE__CREATE_A_FINANCE
      : k.INCENTIVE__UPDATE_A_FINANCE
  );

  const handleRecurringIncentiveChange = (
    newRecurrenceType: FinanceRecurrence,
    updateFieldValue = false
  ) => {
    setIsRecurringIncentive(
      newRecurrenceType === FinanceRecurrence.Monthly ||
        newRecurrenceType === FinanceRecurrence.Yearly
    );

    updateFieldValue &&
      formRef.setFieldsValue({
        [RECURRING_FREQUENCY_FIELD]: newRecurrenceType,
      });
  };

  const detailsFieldRef = useRef<ReactQuill>();
  const approvalGuidelinesFieldRef = useRef<ReactQuill>();

  return (
    <LearnInDrawer
      placement="bottom"
      responsiveSize={'100%'}
      title={title}
      visible={visible}
      onClose={() => {
        formRef.resetFields();
        setDetails('');
        onCancel(true);
      }}
      footer={[
        <Row key="submit" justify="end">
          {updatingData && (
            <LearnInButton
              tag={ButtonTags.DrawerFooterSecondary}
              style={{marginRight: '16px'}}
              data-test-id="finance-incentive-delete-button"
              onClick={() => {
                confirm({
                  title: i18n.t(k.PROMPT__DELETE),
                  content: i18n.t(k.PROMPT__DELETE_ITEM_FRIENDLY__FORMAT, {
                    item: i18n.t(k.GENERIC__CONTENT),
                  }),
                  icon: <ExclamationCircleOutlined aria-hidden="true" />,
                  okText: i18n.t(k.CTA__DELETE),
                  okType: 'danger',
                  cancelText: i18n.t(k.CTA__CANCEL),
                  onOk() {
                    deleteCompanyIncentiveMutation.mutate({
                      incentiveType: IncentiveType.Finance,
                      incentiveId: updatingData.id,
                    });
                  },
                  onCancel() {
                    // intentional pass
                  },
                });
              }}>
              {i18n.t(k.CTA__DELETE)}
            </LearnInButton>
          )}
          <LearnInButton
            tag={ButtonTags.DrawerFooterPrimary}
            style={{marginRight: '11%'}}
            onClick={async () => {
              setConfirmLoading(true);
              try {
                await formRef.validateFields();
                const data = formRef.getFieldsValue();

                const requestData: FinanceIncentivePayload = {
                  images: images,
                  title: data[TITLE_FIELD],
                  subTitle: data[TITLE_FIELD],
                  featured: false,
                  approvalGuidelines: approvalGuidelines,
                  shortDescription: data[SHORT_DESCRIPTION_FIELD],
                  longDescription: data[LONG_DESCRIPTION_FIELD],
                  availability: data[AVAILABILITY_FIELD],
                  programTypeIds:
                    data[AVAILABILITY_FIELD] ===
                    IncentiveAvailability.CertainPrograms
                      ? data[PROGRAM_TYPE_FIELD]
                      : [],
                  amount:
                    exchangeAmountBack(
                      parseInt(data[MONEY_FIELD]),
                      exchangeRate
                    ) ||
                    data[MONEY_FIELD] ||
                    0,
                  amountType: data[MONEY_USE_FIELD],
                  financeType: parseInt(data[REDEMPTION_TYPE_FIELD]),
                  recurring:
                    data[RECURRING_FREQUENCY_FIELD] !== FinanceRecurrence.Never,
                  balanceRecurType:
                    BALANCE_RECUR_TYPE_FIELD in data
                      ? data[BALANCE_RECUR_TYPE_FIELD]
                      : BalanceRecurType.NotApplicable,
                  recurrence: data[RECURRING_FREQUENCY_FIELD],
                  approvalConfig: {
                    adminRedemptionApprovalRequired:
                      data[APPROVALS_DETAIL_FIELDS]?.includes(
                        RequireApprovalTypeStatus.REDEMPTION_ADMIN
                      ) ?? false,
                    managerPreApprovalRequired:
                      data[APPROVALS_DETAIL_FIELDS]?.includes(
                        RequireApprovalTypeStatus.PREAPPROVAL_MANAGER
                      ) ?? false,
                    adminPreApprovalRequired:
                      data[APPROVALS_DETAIL_FIELDS]?.includes(
                        RequireApprovalTypeStatus.PREAPPROVAL_ADMIN
                      ) ?? false,
                    managerRedemptionApprovalRequired:
                      data[APPROVALS_DETAIL_FIELDS]?.includes(
                        RequireApprovalTypeStatus.REDEMPTION_MANAGER
                      ) ?? false,
                  },
                  details: details,
                  learningBudget: false,
                  isProratingEnabled: data[PRORATED_FIELD] ?? false,
                };

                if (updatingData) {
                  await updateFinanceIncentiveMutation.mutateAsync({
                    incentiveToAdd: {id: updatingData.id, ...requestData},
                  });
                } else {
                  await addFinanceIncentiveMutation.mutateAsync({
                    incentiveToAdd: requestData,
                  });
                }
              } catch (e) {
                console.log('Error', e);
                setConfirmLoading(false);
                return;
              }
            }}
            loading={confirmLoading}>
            {i18n.t(k.CTA__SUBMIT)}
          </LearnInButton>
        </Row>,
      ]}>
      <Form
        data-testid="finance-incentive-upsert-drawer"
        className="form-drawers"
        form={formRef}
        initialValues={getDefaultFormValues()}
        key={'money'}
        layout="vertical">
        <div className="form-content">
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              <Form.Item
                className="lb-application-drawer"
                name={TITLE_FIELD}
                label={i18n.t(k.GENERIC__TITLE)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(
                      k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                      {
                        item: i18n.t(k.GENERIC__TITLE).toLocaleLowerCase(),
                      }
                    ),
                  },
                ]}>
                <LearnInInput tag={InputTags.PRIMARY} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                className="lb-application-drawer"
                name={SHORT_DESCRIPTION_FIELD}
                label={i18n.t(k.GENERIC__DESCRIPTION__SHORT)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(
                      k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                      {
                        item: i18n
                          .t(k.GENERIC__DESCRIPTION__SHORT)
                          .toLocaleLowerCase(),
                      }
                    ),
                  },
                  {
                    max: 1000,
                    message: i18n.t(
                      k.VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT,
                      {
                        item: i18n.t(k.GENERIC__DESCRIPTION__SHORT),
                        max: 1000,
                      }
                    ),
                  },
                ]}>
                <LearnInTextArea tag={TextAreaTags.PRIMARY} maxLength={1000} />
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12}>
            <Form.Item
              className="lb-application-drawer"
              name={MONEY_USE_FIELD}
              id="moneyUse"
              label={i18n.t(k.INCENTIVE__HOW_MUCH_USE)}
              rules={[
                {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
              ]}>
              <Radio.Group
                onChange={(event) => {
                  setMoneyUse(event.target.value);
                }}>
                <Radio style={radioStyle} value={FinanceAmountType.Exact}>
                  {i18n.t(k.MONEY_AMOUNT_SPECIFIC)}
                </Radio>
                {moneyUse === FinanceAmountType.Exact && (
                  <Form.Item
                    style={{paddingLeft: '35px', paddingTop: '5px'}}
                    name={MONEY_FIELD}
                    rules={[
                      {
                        required: true,
                        message: i18n.t(k.VALIDATION__REQUIRED),
                      },
                    ]}>
                    <LearnInMoneyInput
                      currency={user?.currency}
                      min={1}
                      max={100000}
                      style={{width: '150px'}}
                    />
                  </Form.Item>
                )}
                <Radio style={radioStyle} value={FinanceAmountType.Any}>
                  {i18n.t(k.MONEY_AMOUNT_SPECIFIC__NONE)}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name={REDEMPTION_TYPE_FIELD}
              label={i18n.t(k.INCENTIVE__HOW_REDEEM)}
              valuePropName={'value'}
              rules={[
                {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
              ]}>
              <Radio.Group
                disabled={!!updatingData}
                onChange={(event) => {
                  if (event.target.value === FinanceIncentiveType.Prepayment) {
                    setIsPrepaymentIncentive(true);
                  } else {
                    setIsPrepaymentIncentive(false);
                    // next line required unless the default option can be recurring
                    handleRecurringIncentiveChange(
                      FinanceRecurrence.Never,
                      true
                    );
                  }
                }}>
                <Radio
                  style={radioStyle}
                  value={FinanceIncentiveType.ExternalReimbursement}>
                  {i18n.t(k.REIMBURSEMENT__EXTERNAL)}
                </Radio>
                <Radio
                  style={radioStyle}
                  value={FinanceIncentiveType.TuitionReimbursement}>
                  {i18n.t(k.REIMBURSEMENT__COMPANY)}
                </Radio>
                <Radio
                  style={radioStyle}
                  value={FinanceIncentiveType.Prepayment}>
                  {i18n.t(k.CARD__VIRTUAL_CARD)}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name={RECURRING_FREQUENCY_FIELD}
              label={i18n.t(k.INCENTIVE__HOW_OFTEN)}
              valuePropName={'value'}
              rules={[
                {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
              ]}>
              <Radio.Group
                onChange={(value) => {
                  handleRecurringIncentiveChange(
                    value.target.value as FinanceRecurrence
                  );
                }}>
                <Radio style={radioStyle} value={FinanceRecurrence.Never}>
                  {i18n.t(k.DATE__FREQUENCY__ONE_TIME)}
                </Radio>
                {!isSunsetMonthlyRenewalOn && (
                  <Radio
                    style={radioStyle}
                    value={FinanceRecurrence.Monthly}
                    disabled={!isPrepaymentIncentive}>
                    {i18n.t(k.DATE__FREQUENCY__FIRST_MONTH)}
                  </Radio>
                )}
                <Radio
                  style={radioStyle}
                  value={FinanceRecurrence.Yearly}
                  disabled={!isPrepaymentIncentive}>
                  {i18n.t(
                    isProratingOn
                      ? k.DATE__FREQUENCY__EVERY_YEAR
                      : k.DATE__FREQUENCY__FIRST_YEAR
                  )}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {isPrepaymentIncentive && isRecurringIncentive && (
              <Form.Item
                className="lb-application-drawer"
                name={BALANCE_RECUR_TYPE_FIELD}
                label={i18n.t(k.INITIATIVE__HOW_RELOAD)}
                valuePropName={'value'}
                rules={[
                  {
                    required: true,
                    message: i18n.t(k.VALIDATION__BALANCE_RENEWAL),
                  },
                ]}>
                <Radio.Group>
                  <Radio style={radioStyle} value={BalanceRecurType.Rollover}>
                    {i18n.t(k.MONEY__BALANCE__ROLLOVER_PREVIOUS)}
                  </Radio>
                  <Radio style={radioStyle} value={BalanceRecurType.Reset}>
                    {i18n.t(k.MONEY__BALANCE__RESET_EACH_TIME)}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            )}
            <Form.Item
              name={AVAILABILITY_FIELD}
              className={'lb-application-drawer'}
              label={i18n.t(k.INITIATIVE__VISIBILITY)}
              rules={[
                {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
              ]}>
              <Radio.Group
                onChange={(value) =>
                  setProgramTypeDropdownEnabled(
                    value.target.value === IncentiveAvailability.CertainPrograms
                  )
                }>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.AvailableToAll}>
                  {i18n.t(k.GENERIC__EVERYONE)}
                </Radio>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.CertainPrograms}>
                  {i18n.t(k.AVAILABILITY__PROGRAM_TYPES)}
                </Radio>
                <Form.Item
                  name={PROGRAM_TYPE_FIELD}
                  style={{paddingLeft: '35px', paddingTop: '5px'}}>
                  <LearnInSelect
                    disabled={!programTypeDropdownEnabled}
                    placeholder={i18n.t(k.VALIDATION__SELECT_PROGRAM)}
                    mode="multiple">
                    {programTypesData.map((programType) => (
                      <LearnInSelectOption
                        key={programType.id}
                        value={programType.id}>
                        {programType.description}
                      </LearnInSelectOption>
                    ))}
                  </LearnInSelect>
                </Form.Item>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.CertainInitiatives}>
                  {i18n.t(k.AVAILABILITY__INITIATIVE_GROUP)}
                </Radio>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.NotAvailable}>
                  {i18n.t(k.AVAILABILITY__DRAFT)}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={PERMISSIONS_FIELD}
              className={'lb-application-drawer'}
              label={i18n.t(k.PERMISSION__PLURAL)}>
              <Checkbox.Group>
                <Checkbox
                  value={Permissions.RequiresApproval}
                  onChange={() => setShowRequireApproval((value) => !value)}>
                  {i18n.t(k.AVAILABILITY__APPROVAL_REQUIRED)}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Row>
            <Col xs={24} sm={12}>
              {showRequireApproval && (
                <Form.Item
                  name={APPROVALS_DETAIL_FIELDS}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(k.VALIDATION__SELECT_REQUIRED_APPROVALS),
                    },
                  ]}>
                  <Checkbox.Group style={{width: '100%'}}>
                    <Row>
                      <Col>
                        <Typography.Text>
                          {i18n.t(k.INCENTIVE__APPROVAL_REQUIRES)}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={12}>
                        <Checkbox
                          value={RequireApprovalTypeStatus.PREAPPROVAL_MANAGER}>
                          {i18n.t(k.GENERIC__MANAGER)}
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Checkbox
                          value={RequireApprovalTypeStatus.PREAPPROVAL_ADMIN}>
                          {i18n.t(k.ADMIN)}
                        </Checkbox>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col>
                        <Typography.Text>
                          {i18n.t(k.INCENTIVE__REDEMPTION_REQUIRES)}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[8, 32]}>
                      <Col xs={24} sm={12}>
                        <Checkbox
                          value={RequireApprovalTypeStatus.REDEMPTION_MANAGER}>
                          {i18n.t(k.GENERIC__MANAGER)}
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Checkbox
                          value={RequireApprovalTypeStatus.REDEMPTION_ADMIN}>
                          {i18n.t(k.ADMIN)}
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row style={{marginBottom: '25px'}}>
            <Col xs={24}>
              {showRequireApproval && (
                <>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          {/* The onClick supports ReactQuill focus handling. */}
                          {/* eslint-disable-next-line */}
                          <label
                            htmlFor="approval-guidelines-field"
                            onClick={() =>
                              approvalGuidelinesFieldRef.current?.focus()
                            }>
                            <Typography.Text>
                              {i18n.t(k.APPROVAL__GUIDELINES)}
                            </Typography.Text>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography.Text>
                            {i18n.t(k.APPROVAL__HELP)}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <ReactQuill
                        formats={formatPastingOptions}
                        value={
                          approvalGuidelines ||
                          updatingData?.approvalGuidelines ||
                          ''
                        }
                        theme="snow"
                        onChange={onApprovalGuidelinesChange}
                        ref={approvalGuidelinesFieldRef}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {approvalGuidelinesError && (
                        <Typography.Text style={{color: COLORS.Red800}}>
                          {i18n.t(
                            k.VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT,
                            {
                              item: i18n.t(k.APPROVAL__GUIDELINES),
                              max: APPROVAL_GUIDELINE_MAX_COUNT,
                            }
                          )}
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}></Col>
          </Row>
          <Row style={{marginBottom: '4px'}}>
            <label>{i18n.t(k.GENERIC__IMAGE).toLocaleUpperCase()}</label>
          </Row>
          {previewUrl ? (
            <AttachmentCard>
              <UploadPreviewLogo imageSrc={previewUrl} size={75} />
              <button
                aria-label={i18n.t(k.CTA__DELETE)}
                onClick={(x) => setImages([])}>
                <DeleteOutlined
                  aria-hidden="true"
                  style={{cursor: 'pointer', color: COLORS.Red800}}
                />
              </button>
            </AttachmentCard>
          ) : (
            <Dragger
              customRequest={async ({file}) => {
                if (
                  validateUserUpload(
                    file as any,
                    [],
                    UploadConstraints.CustomProgramHeaderImageLimit,
                    'bytes',
                    generateFileUploadMediaTypes([FileUploadType.Images])
                  )
                ) {
                  getBase64(file, (data: any) =>
                    setImages((images: any) => [
                      ...images,
                      {
                        fileName: (file as RcFile).name,
                        fileContent: btoa(data),
                        description: '',
                      },
                    ])
                  );
                } else {
                  notify.uploadContentAttachmentIsInvalid();
                }
              }}
              showUploadList={false}
              maxCount={1}
              height={100}
              multiple={false}
              accept={[
                ...FileTypeDetails[FileUploadType.Images].fileSuffixes,
              ].join(', ')}>
              <UploadInstructions
                fileTypes={i18n.t(k.FILE__UPLOAD__DEFAULT_TYPES)}
                maxSize="1 MB"
              />
              <p style={{color: COLORS.Red800}}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}></button>
              </p>
            </Dragger>
          )}
          <Row style={{marginBottom: '4px'}}>
            {/* The onClick supports ReactQuill focus handling. */}
            {/* eslint-disable-next-line */}
            <label onClick={() => detailsFieldRef.current?.focus()}>
              {i18n.t(k.GENERIC__DETAIL__PLURAL).toLocaleUpperCase()}
            </label>
          </Row>

          <ReactQuill
            formats={formatPastingOptions}
            theme="snow"
            value={detailsHTMLContent || ''}
            onChange={(e) => setDetails(e)}
            ref={detailsFieldRef}
          />
        </div>
      </Form>
    </LearnInDrawer>
  );
}
