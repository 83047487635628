import './FinanceChart.scss';

import {Fragment, useState, useEffect} from 'react';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Divider, Row, Skeleton} from 'antd';
import {Line} from '@ant-design/charts';
import {
  AdminReportsTabName,
  FinanceChartItem,
} from '@components/admin/pages/reports/store/interface';
import {COLORS, CHART_COLORS} from '@utils/constants';
import styled from 'styled-components';
import {formatCurrency, MONEY_DEFAULT} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';

interface Props {
  onTabChange: (tabName: any) => void;
  financeChartData: FinanceChartItem[];
  financeChartLoading: boolean;
  isDirectBillingPlan?: boolean;
}

const ViewMoreLink = styled.button`
  color: ${COLORS.Blue800} !important;
  &:focus-visible,
  &:hover {
    text-decoration: underline !important;
  }
`;

const FinanceChart = ({
  onTabChange,
  financeChartData,
  financeChartLoading,
  isDirectBillingPlan,
}: Props) => {
  const [data] = useState([]);
  const [totalPaidOut, setTotalPaidOut] = useState(i18n.t(k.GENERIC__N_A));
  const [totalPaidOutColor, setTotalPaidOutColor] = useState<string>(
    CHART_COLORS.GREEN
  );
  const [totalApproved, setTotalApproved] = useState(i18n.t(k.GENERIC__N_A));
  const [totalApprovedColor, setTotalApprovedColor] = useState<string>(
    CHART_COLORS.ORANGE
  );
  const [totalRequested, setRequested] = useState(i18n.t(k.GENERIC__N_A));
  const [totalRequestedColor, setTotalRequestedColor] = useState<string>(
    CHART_COLORS.BLUE
  );
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const renderAmount = (amount: number) =>
    formatCurrency(amount, getUserQuery.data?.currency, exchangeRate);

  const TOTAL_APPROVED = i18n.t(k.APPROVAL__TOTAL_APPROVED);
  const TOTAL_SPENT = i18n.t(k.MONEY__TOTAL_SPENT);
  const TOTAL_REQUESTED = i18n.t(k.APPROVAL__TOTAL_REQUESTED);

  const totalFinanceValue = (data: FinanceChartItem[] | undefined) => {
    let totalApproved = 0;
    let totalPaid = 0;
    let totalRequested = 0;
    data?.length &&
      data.forEach((detail) => {
        if (detail.type === TOTAL_APPROVED) {
          totalApproved = totalApproved + detail.value;
        } else if (detail.type === TOTAL_REQUESTED) {
          totalRequested = totalRequested + detail.value;
        } else {
          totalPaid = totalPaid + detail.value;
        }
      });
    setTotalApproved(renderAmount(totalApproved));
    setTotalPaidOut(renderAmount(totalPaid));
    setRequested(renderAmount(totalRequested));

    // Ignores .colors provided by the API, in favor of our a11y-safe colors
    setTotalApprovedColor(CHART_COLORS.BLUE);
    setTotalPaidOutColor(CHART_COLORS.GREEN);
    setTotalRequestedColor(CHART_COLORS.ORANGE);
  };

  const getChartConfig = (data: FinanceChartItem[]) => {
    config.data = data;
    config.color = ({type}) => {
      if (type == TOTAL_APPROVED) {
        return CHART_COLORS.BLUE;
      } else if (type == TOTAL_SPENT) {
        return CHART_COLORS.GREEN;
      } else if (type == TOTAL_REQUESTED) {
        return CHART_COLORS.ORANGE;
      }
    };

    return config;
  };

  const config: any = {
    data: data || [],
    xField: 'name',
    yField: 'value',
    seriesField: 'type',
    tooltip: {
      formatter: (data) => {
        return {
          name: data.type,
          value: renderAmount(data.value),
        };
      },
    },
    yAxis: {
      label: {
        formatter: function formatter(v: any) {
          // NOTE: copied from the antd charts library
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return ''.concat(s, ',');
          });
        },
      },
      position: 'right',
    },
    smooth: true,
    colorField: 'color',
    point: {
      shape: function shape() {
        return 'circle';
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top-right',
      marker: {
        symbol: 'circle',
      },
    },
  };

  useEffect(() => {
    totalFinanceValue(financeChartData);
  }, [financeChartData]);

  return (
    <Fragment>
      <Card
        title={i18n.t(k.GENERIC__FINANCE)}
        role="figure"
        aria-label={i18n.t(k.GENERIC__FINANCE)}
        key="2"
        className="finance-item-card"
        style={{borderRadius: '8px'}}
        extra={
          <ViewMoreLink
            aria-label={i18n.t(k.FINANCE__PLURAL__VIEW_MORE)}
            onClick={() => onTabChange(AdminReportsTabName.Prepayment)}
            className="pseudo-link-hover">
            {i18n.t(k.CTA__VIEW_MORE)}
          </ViewMoreLink>
        }>
        {!financeChartLoading ? (
          financeChartData?.length ? (
            <>
              <Row
                gutter={[8, 8]}
                style={{padding: '24px 24px'}}
                className="finance-chart">
                <Col xs={24} md={24}>
                  <Line
                    {...(getChartConfig(
                      isDirectBillingPlan
                        ? financeChartData.filter(
                            ({type}) => type != i18n.t(k.MONEY__TOTAL_SPENT)
                          )
                        : financeChartData
                    ) as any)}
                  />
                </Col>
              </Row>
              <Divider style={{margin: '20px'}} />
              <Row gutter={[8, 8]} align="middle">
                {!isDirectBillingPlan && (
                  <>
                    <Col xs={7} style={{paddingLeft: '24px'}}>
                      <p style={{marginBottom: '8px'}}>
                        <span
                          className="paid-out-dot"
                          style={{backgroundColor: totalPaidOutColor}}
                        />
                        {i18n.t(k.MONEY__TOTAL_SPENT)}
                      </p>
                      <span>
                        <h1 className="total-value">{totalPaidOut}</h1>
                      </span>
                    </Col>
                    <Divider
                      type="vertical"
                      style={{
                        height: '90px',
                        marginLeft: '15px',
                        marginRight: '15px',
                      }}
                    />
                  </>
                )}
                <Col xs={7} style={{paddingLeft: '24px'}}>
                  <p style={{marginBottom: '8px'}}>
                    <span
                      className="approved-dot"
                      style={{backgroundColor: totalApprovedColor}}
                    />
                    {i18n.t(k.APPROVAL__TOTAL_APPROVED)}
                  </p>
                  <span>
                    <h1 className="total-value">{totalApproved}</h1>
                  </span>
                </Col>
                <Divider
                  type="vertical"
                  style={{
                    height: '90px',
                    marginLeft: '15px',
                    marginRight: '15px',
                  }}
                />
                <Col xs={7} style={{paddingLeft: '24px'}}>
                  <p style={{marginBottom: '8px'}}>
                    <span
                      className="approved-dot"
                      style={{backgroundColor: totalRequestedColor}}
                    />
                    {i18n.t(k.APPROVAL__TOTAL_REQUESTED)}
                  </p>
                  <span>
                    <h1 className="total-value">{totalRequested}</h1>
                  </span>
                </Col>
              </Row>
            </>
          ) : (
            <Row
              gutter={[8, 8]}
              align="middle"
              style={{padding: '20px 24px', justifyContent: 'center'}}>
              <div style={{textAlign: 'center', wordWrap: 'break-word'}}>
                <Row justify="center" align="middle" style={{marginTop: '30%'}}>
                  <Col xs={3}></Col>
                  <Col xs={18}>
                    <h1>{i18n.t(k.INCENTIVE__FINANCE__EMPTY__TITLE)}</h1>
                    <p>{i18n.t(k.INCENTIVE__FINANCE__EMPTY__DESCRIPTION)}</p>
                  </Col>
                  <Col xs={3}></Col>
                </Row>
              </div>
            </Row>
          )
        ) : (
          <Row
            gutter={[8, 8]}
            style={{padding: '20px 24px'}}
            className="finance-chart">
            <Col xs={24}>
              <Skeleton active />
            </Col>
            <Col xs={24}>
              <Skeleton active />
            </Col>
            <Col xs={24}>
              <Skeleton active />
            </Col>
          </Row>
        )}
      </Card>
    </Fragment>
  );
};

export default FinanceChart;
