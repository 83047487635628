import {ProgramSearchCategory} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {GraduationCap} from '@assets/svg/GraduationCap';
import {FormOutlined, IdcardOutlined} from '@ant-design/icons';

export const getOptionConfigs = (data) => {
  const getItem = (category) =>
    Array.isArray(data) && data?.find((item) => item.category === category);

  return [
    {
      item: getItem(ProgramSearchCategory.Degree),
      labels: {
        single: k.PROGRAM_FINDER__TYPE__DEGREE_AVAILABLE__FORMAT,
        plural: k.PROGRAM_FINDER__TYPE__DEGREES_AVAILABLE__FORMAT,
        many: k.PROGRAM_FINDER__TYPE__MANY_DEGREES_AVAILABLE__FORMAT,
      },
      color: null,
      background: 'Blue100',
      IconComponent: GraduationCap,
    },
    {
      item: getItem(ProgramSearchCategory.Course),
      labels: {
        single: k.PROGRAM_FINDER__TYPE__COURSE_AVAILABLE__FORMAT,
        plural: k.PROGRAM_FINDER__TYPE__COURSES_AVAILABLE__FORMAT,
        many: k.PROGRAM_FINDER__TYPE__MANY_COURSES_AVAILABLE__FORMAT,
      },
      color: 'Green700',
      background: 'Green200',
      IconComponent: IdcardOutlined,
    },
    {
      item: getItem(ProgramSearchCategory.Bootcamp),
      labels: {
        single: k.PROGRAM_FINDER__TYPE__BOOTCAMP_AVAILABLE__FORMAT,
        plural: k.PROGRAM_FINDER__TYPE__BOOTCAMPS_AVAILABLE__FORMAT,
        many: k.PROGRAM_FINDER__TYPE__MANY_BOOTCAMPS_AVAILABLE__FORMAT,
      },
      color: 'Purple800',
      background: 'Purple200',
      IconComponent: FormOutlined,
    },
  ];
};

export enum ProgramFinderPath {
  Skills,
  Programs,
  Coach,
  Browse,
}

export const programFinderPath = [
  {
    key: ProgramFinderPath.Skills,
    value: i18n.t(k.PROGRAM_FINDER__PATH__SKILLS),
  },
  {
    key: ProgramFinderPath.Programs,
    value: i18n.t(k.PROGRAM_FINDER__PATH__PROGRAMS),
  },
  {
    key: ProgramFinderPath.Coach,
    value: i18n.t(k.PROGRAM_FINDER__PATH__COACH),
  },
  {
    key: ProgramFinderPath.Browse,
    value: i18n.t(k.PROGRAM_FINDER__PATH__BROWSE),
  },
];
