import {i18n, k} from '@i18n/translate';
import moment from 'moment';
import LearnInTable, {dateTimeSorter} from '@components/reusable/LearnInTable';
import {TeamInsightsDetailVM, TransactionInsightVM} from '@models/serverModels';
import {
  TeamInsightEmployeeDetailVm,
  UserDetailsVM,
} from '@generated/interfaces';
import {formatCurrency} from '@utils/moneyUtils';
import {DATE_FORMAT} from '@utils/timeUtils';

export const TransactionsTable = ({
  data,
  user,
  exchangeRate,
}: {
  data: TeamInsightEmployeeDetailVm;
  user: UserDetailsVM;
  exchangeRate: number;
}) => {
  // Initially sort by date descending
  data.transactions.sort(dateTimeSorter('date')).reverse();

  return (
    <LearnInTable
      columns={[
        {
          title: i18n.t(k.DATE),
          width: '161px',
          dataIndex: 'date',
          key: 'date',
          render: (lastLoginUTC: string) =>
            moment(lastLoginUTC).format(DATE_FORMAT.MONTH_DAY_YEAR),
          sorter: dateTimeSorter('date'),
        },
        {
          title: i18n.t(k.GENERIC__DESCRIPTION),
          width: '461px',
          dataIndex: 'description',
          key: 'description',
          render: (descriptionKey: string) =>
            k[descriptionKey] ? i18n.t(k[descriptionKey]) : descriptionKey,
          sorter: (a: TransactionInsightVM, b: TransactionInsightVM) =>
            a.description.localeCompare(b.description),
        },
        {
          title: i18n.t(k.GENERIC__AMOUNT),
          width: '194px',
          dataIndex: 'amount',
          key: 'amount',
          render: (amount: number) => {
            return formatCurrency(amount, user?.currency, exchangeRate, {
              decimal: true,
            });
          },
          sorter: (a: TransactionInsightVM, b: TransactionInsightVM) =>
            a.amount - b.amount,
        },
        {
          title: i18n.t(k.STATUS__STATUS),
          dataIndex: 'status',
          key: 'status',
          render: (statusKey: string) =>
            k[statusKey] ? i18n.t(k[statusKey]) : statusKey,
          sorter: (a: TransactionInsightVM, b: TransactionInsightVM) =>
            a.status.toString().localeCompare(b.status.toString()),
        },
      ]}
      dataSource={data ? data?.transactions : []}
    />
  );
};
