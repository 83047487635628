import {AttachmentType} from './../enums';
import {UserRequestImage} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {
  IUploadedFileMockData,
  getIUploadedFileMockData,
} from './IUploadedFile.mock';
import {faker} from '@faker-js/faker';

// Interface: UserRequestImage

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserRequestImageMockData(
  args?: Partial<UserRequestImage>
): UserRequestImage {
  return {
    ...getBaseEntityMockData(),
    ...getIUploadedFileMockData(),
    deleted: faker.datatype.boolean(),
    description: faker.lorem.lines(),
    fileName: faker.name.fullName(),
    filePath: faker.word.noun(),
    id: faker.datatype.number(),
    multipartUploadCompleted: faker.datatype.boolean(),
    multipartUploadId: String(faker.datatype.number()),
    order: faker.datatype.number(),
    successfullyUploadedSize: faker.datatype.number(),
    totalSize: faker.datatype.number(),
    type: AttachmentType.File,
    uploadError: faker.word.noun(),
    userRequestId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserRequestImageMockData: UserRequestImage = {
  ...BaseEntityMockData,
  ...IUploadedFileMockData,
  deleted: true,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  fileName: 'Bobby Johnson',
  filePath: 'string',
  id: 1,
  multipartUploadCompleted: true,
  multipartUploadId: '1',
  order: 123,
  successfullyUploadedSize: 123,
  totalSize: 123,
  type: AttachmentType.File,
  uploadError: 'string',
  userRequestId: 1,
};
