import {useState} from 'react';
import NominationsTab from './NominationsTab';
import {ProgramUserRecommendationVM} from '@models/serverModels';
import {useQuery, useMutation} from 'react-query';
import {
  simpleQueryFn,
  simpleMutationFn,
  simpleDeleteFn,
} from '@store/queryClient';
import {getProgramRecommendationsRq} from '@store/apiEndpoints/program/queries';
import {
  getRecommendToUsersRm,
  getDeleteRecommendationRm,
} from '@store/apiEndpoints/program/mutations';
import {RecommendationLevel} from '@generated/enums';
import {notify} from '@components/user/notifications';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {debounce} from 'lodash';
import {api} from '@store/api';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface INominationsTabContainerProps {
  programId: number;
}

function NominationsTabContainer({programId}: INominationsTabContainerProps) {
  const [employeeSearchResults, setEmployeeSearchResults] = useState<
    {id: number; name: string}[]
  >([]);
  const [selectValue, setSelectValue] = useState<
    {id?: number; value: number; key: number; label: string}[]
  >([]);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const {isAdmin} = useAuth();

  const programRecommendationsRq = getProgramRecommendationsRq(
    programId,
    RecommendationLevel.Nominated
  );
  const programRecommendationsQuery = useQuery<ProgramUserRecommendationVM[]>(
    programRecommendationsRq.queryKey,
    () => simpleQueryFn(programRecommendationsRq.path)
  );

  const recommendedUsersMutation = useMutation(
    () => {
      const recommendToUsersRm = getRecommendToUsersRm({
        id: programId,
        users: selectValue.map(({value}) => value),
        level: RecommendationLevel.Nominated,
      });
      return simpleMutationFn<unknown>(
        recommendToUsersRm.path,
        recommendToUsersRm.payload
      );
    },
    {
      onSuccess: () => {
        setSelectValue([]);
        notify.recommendToUsersSuccess();
        programRecommendationsQuery.refetch();
      },
      onError: () => {
        notify.recommendToUsersFailure();
      },
    }
  );

  const deleteRecommendationMutation = useMutation(
    ({userRecommendationId}: {userRecommendationId: number}) => {
      const deleteRecommendationRm =
        getDeleteRecommendationRm(userRecommendationId);
      return simpleDeleteFn<unknown>(
        deleteRecommendationRm.path,
        deleteRecommendationRm.payload
      );
    },
    {
      onSuccess: () => {
        programRecommendationsQuery.refetch();
        notify.removeProgramNominationSuccess();
      },
      onError: () => {
        notify.removeProgramNominationFailure();
      },
    }
  );

  // TODO: make util hook
  const handleSearchEmployees = debounce(async (name: any) => {
    setSearchInProgress(true);
    await api.get(`user/search?name=${name}&limit=10`).then((body) => {
      setEmployeeSearchResults(
        body?.data?.map(({id, name}) => ({id, name})) || []
      );
      setSearchInProgress(false);
    });
  }, 800);

  const handleChangeSelect = (
    value: {value: number; key: number; label: string}[]
  ): void => {
    setSelectValue(value);
  };

  const handleClickNominate = async () => {
    await recommendedUsersMutation.mutateAsync();
  };

  const handleClickRemoveNomination = async (userRecommendationId: number) => {
    await deleteRecommendationMutation.mutateAsync({userRecommendationId});
  };

  return (
    <NominationsTab
      isAdmin={isAdmin}
      onSearchEmployees={handleSearchEmployees}
      onChangeSelect={handleChangeSelect}
      selectValue={selectValue}
      employeeSearchResults={employeeSearchResults}
      onClickNominate={handleClickNominate}
      dataSource={programRecommendationsQuery.data || []}
      onClickRemoveNomination={handleClickRemoveNomination}
      searchInProgress={searchInProgress}
    />
  );
}

export default NominationsTabContainer;
