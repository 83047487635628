import {useMemo} from 'react';
import {useGetTimezonesQuery} from './apiEndpoints/timeIncentive/queries';
import {getTimeZoneAbbreviation, getUserTimeZone} from '@utils/timeUtils';

const useLocalTimeZone = (): {displayName: string; abbreviated: string} => {
  const {data: timeZones} = useGetTimezonesQuery(null);

  const localTimeZone = useMemo(() => {
    const longTimeZone = timeZones?.find(
      (timezone) => timezone.id === getUserTimeZone()
    )?.displayName;

    const abbreviatedTimeZone =
      longTimeZone && getTimeZoneAbbreviation(longTimeZone);

    return {
      displayName: longTimeZone,
      abbreviated: abbreviatedTimeZone,
    };
  }, [timeZones]);

  return localTimeZone;
};

export default useLocalTimeZone;
