import './ProgramReportList.scss';
import * as React from 'react';
import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {debounce, startCase} from 'lodash';
import {formatCurrency, MONEY_DEFAULT} from '@utils/moneyUtils';
import moment from 'moment';
import {ProgramReportItem} from '@components/admin/pages/reports/store/interface';
import {ViewProgramLink} from '../ViewProgramLink';
import LearnInTable, {
  ColumnAlignment,
  dateTimeSorter,
  numberSorter,
  stringSorter,
  TableCell,
} from '@components/reusable/LearnInTable';
import {DATE_FORMAT} from '@utils/timeUtils';
import {ProgramUsageResult} from '@models/serverModels';
import {useGetCostCentersVMQuery} from '@hooks/apiEndpoints/company/queries';
import useTreatment from '@hooks/useTreatment';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {getProgramReportRq} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {notify} from '@components/user/notifications';
import LoadingSpinner from '@blocks/LoadingSpinner';
import NoDataText from '@blocks/NoDataText';
import {filterQuery} from '@utils/filterUtils';
import {useUpdateCostCenterAsAdminByUserRequestId} from '@hooks/apiEndpoints/adminApprovals/mutations';
import {CurrencyCode} from '@generated/enums';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {simpleQueryFn} from '@store/queryClient';
import {ReportYearSelect} from '../PrepaymentsReportList/ReportYearSelect';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {InlineSelect} from '@components/reusable/InlineSelect';
import {useGetCostCenterOptions} from '@components/reusable/CostCenterField/CostCenterField.hooks';
import {LearnInSearchInput} from '@components/reusable/Input/LearnInSearchInput.style';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useLocation, useNavigate} from 'react-router-dom';
import {AdminPaths} from '@utils/ClientPaths';
import {LinkStyledButton} from '@components/reusable/Button/Button.style';

const MIN_YEAR = 2022;

interface ProgramReportListProps {
  data: ProgramUsageResult[];
}

const ProgramReportList = ({data}: ProgramReportListProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {isFeatureFlagOn} = useFeatureFlags();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const isCostCenterOn =
    useTreatment(FeatureFlagExperiments.CostCenter) ===
    FeatureFlagTreatments.On;
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [showYearsDropdown, setShowYearsDropdown] = useState(false);
  const {data: costCenters} = useGetCostCentersVMQuery(null, {
    enabled: isCostCenterOn,
  });
  const costCenterOptions = useGetCostCenterOptions(costCenters);

  const checkYearDropDown = (data) => {
    return data && MIN_YEAR !== currentYear;
  };

  const lastYearProgramReportRq = getProgramReportRq(MIN_YEAR);
  useQuery<ProgramUsageResult[]>(
    lastYearProgramReportRq.queryKey,
    () => simpleQueryFn(lastYearProgramReportRq.path),
    {
      enabled: true,
      onSuccess: (data) => {
        // only using this to check if there is last year data
        if (checkYearDropDown(data)) {
          setShowYearsDropdown(true);
        }
      },
    }
  );
  const programReportRq = getProgramReportRq(selectedYear);
  const programReportQuery = useQuery<ProgramUsageResult[]>(
    programReportRq.queryKey,
    () => simpleQueryFn(programReportRq.path),
    {
      enabled: true,
      onSuccess: (data) => {
        setSearchFilteredPrograms(data);
      },
      refetchOnWindowFocus: false,
    }
  );

  const [searchFilteredPrograms, setSearchFilteredPrograms] = React.useState(
    programReportQuery?.data || []
  );

  const [searchText, setSearchText] = React.useState('');

  const applySearch = debounce(() => {
    const searchParameters = ['title', 'employeeName', 'programType'];
    const searchResults = filterQuery(
      programReportQuery.data,
      searchText,
      searchParameters
    );
    setSearchFilteredPrograms(searchResults);
  }, 500);

  React.useEffect(() => {
    programReportQuery.refetch();
    if (programReportQuery?.data) {
      applySearch();
    } else {
      setSearchFilteredPrograms([]);
    }
  }, [selectedYear, searchText]);

  const UpdateCostCenterAsAdminMutation =
    useUpdateCostCenterAsAdminByUserRequestId({
      onSuccess: async () => {
        await programReportQuery.refetch();
        notify.updateCostCenterSuccess();
      },
      onFailure: () => {
        notify.updateCostCenterError();
      },
    });

  const updateCostCenter = async (
    costCenter: string,
    userRequestId: number
  ) => {
    return await UpdateCostCenterAsAdminMutation.mutateAsync({
      costCenter: costCenter,
      userRequestId: userRequestId,
    });
  };

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const renderCurrency = (
    amount: number,
    currency: CurrencyCode = MONEY_DEFAULT.currency
  ) => {
    return (
      <div>
        {formatCurrency(amount, currency, exchangeRate, {decimal: true})}
      </div>
    );
  };

  const costCenterColumn = {
    title: i18n.t(k.COST_CENTER),
    dataIndex: 'costCenter',
    key: 'provider',
    render: (provider, row) => {
      return (
        <button onClick={(e) => e.stopPropagation()} style={{width: '100%'}}>
          <InlineSelect
            onSubmit={(value) => updateCostCenter(value, row.userRequestId)}
            value={provider}
            options={costCenterOptions}
            readOnly={!row.userRequestId}
            notFoundContent={i18n.t(k.GENERIC__NOT_FOUND)}
          />
        </button>
      );
    },
    sorter: stringSorter('provider'),
  };

  const programReportColumns = [
    {
      title: i18n.t(k.EMPLOYEE__NAME),
      dataIndex: 'employeeName',
      key: 'employeeName',
      render: (employee, row) => {
        return (
          <LinkStyledButton
            onClick={() =>
              navigate(`${AdminPaths.People}/${row.userCompanyId}`, {
                state: {from: location.pathname + location.search},
              })
            }>
            {employee}
          </LinkStyledButton>
        );
      },
      sorter: stringSorter('employeeName'),
    },
    isCostCenterOn && costCenterColumn,
    {
      title: i18n.t(k.PROGRAM__START__NOUN),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: any) => moment(date).format(DATE_FORMAT.MONTH_DAY_YEAR),
      sorter: dateTimeSorter('startDate'),
    },
    {
      title: i18n.t(k.PROGRAM__NAME),
      dataIndex: 'title',
      render: (_: any, row: any) => {
        return (
          <TableCell>
            <ViewProgramLink id={row.programId}>{row.title}</ViewProgramLink>
          </TableCell>
        );
      },
      key: 'title',
      sorter: stringSorter('title'),
    },
    {
      title: i18n.t(k.PROGRAM__TYPE),
      dataIndex: 'programType',
      key: 'programType',
      sorter: stringSorter('programType'),
    },
    {
      title: i18n.t(k.PROGRAM__DURATION),
      dataIndex: 'programLength',
      key: 'programLength',
    },
    {
      title: i18n.t(k.PROGRAM__STATUS),
      dataIndex: 'status',
      key: 'status',
      render: (status: any) => startCase(status),
      sorter: stringSorter('status'),
    },
    {
      title: i18n.t(k.FUND__REQUEST__AMOUNT),
      align: ColumnAlignment.Right,
      dataIndex: 'requestedAmount',
      key: 'requestedAmount',
      render: (requestedAmount: number, row: ProgramReportItem) =>
        renderCurrency(requestedAmount, getUserQuery.data?.currency),
      sorter: numberSorter('requestedAmount'),
    },
  ];

  return (
    <>
      <div>
        <LearnInSearchInput
          allowClear
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={i18n.t(k.PROGRAM__PLURAL__SEARCH)}
          style={{width: '400px', padding: '10px 32px'}}
          value={searchText}
        />
        {showYearsDropdown && (
          <ReportYearSelect
            style={{marginLeft: 16, marginTop: 10, height: 32}}
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}
          />
        )}
      </div>
      <>
        {programReportQuery.isFetching && !programReportQuery.data && (
          <LoadingSpinner />
        )}
        {programReportQuery.isSuccess &&
          !!programReportQuery.data?.length &&
          searchFilteredPrograms?.length > 0 && (
            <LearnInTable
              dataSource={searchFilteredPrograms}
              columns={programReportColumns}
            />
          )}
        {programReportQuery.isSuccess && !programReportQuery.data?.length ? (
          <NoDataText
            header={i18n.t(k.REPORT__POPULAR_PROGRAM__NO_ENROLLMENTS)}
            subHeader={i18n.t(
              k.REPORT__POPULAR_PROGRAM__NO_ENROLLMENTS__DESCRIPTION
            )}
          />
        ) : (
          programReportQuery.isSuccess &&
          searchText !== '' &&
          searchFilteredPrograms?.length === 0 && (
            <NoDataText
              header={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
              subHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION)}
            />
          )
        )}
      </>
    </>
  );
};

export default ProgramReportList;
