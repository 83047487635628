import {ReactNode, useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import useFeatureFlags from '@hooks/useFeatureFlags';

import {MembersPreview} from './MembersPreview';
import {MembersDrawerContainer} from '../MembersDrawer';
import {useCustomProgramsUsersQuery} from '@generated/hooks';

const Container = styled.div`
  margin-bottom: 12px;
`;

const Link = styled.button`
  font-size: 0.75rem;
  color: ${COLORS.Blue800};
  margin-bottom: 12px;

  &:focus-visible {
    outline: 2px solid ${COLORS.Blue800};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 4px;
  display: flex;
  align-items: baseline;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface MembersPreviewContainerProps {
  title: ReactNode;
  customProgramId: number;
}

export function MembersPreviewContainer({
  title,
  customProgramId,
}: MembersPreviewContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const [showDrawer, setShowDrawer] = useState(false);

  const {data: customProgramsUsers, isLoading: isCustomProgramsUsersLoading} =
    useCustomProgramsUsersQuery({
      customProgramId,
    });

  const selectRandomMembers = useMemo(() => {
    if (Array.isArray(customProgramsUsers)) {
      const shuffled = [...customProgramsUsers].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 3);
    }
  }, [customProgramsUsers]);

  return (
    isFeatureFlagOn.ViewMembersInCustomProgram && (
      <Container>
        <Header>
          <TitleContainer>
            {title}{' '}
            {!isCustomProgramsUsersLoading &&
              `(${customProgramsUsers?.length || 0})`}
          </TitleContainer>
          <Link onClick={() => setShowDrawer(true)}>
            {i18n.t(k.GENERIC__VIEW_ALL)}
          </Link>
        </Header>
        {Array.isArray(selectRandomMembers) && (
          <MembersPreview members={selectRandomMembers} />
        )}
        <MembersDrawerContainer
          membersCount={customProgramsUsers?.length || 0}
          customProgramId={customProgramId}
          visible={showDrawer}
          setShowDrawer={(shouldShow: boolean) => {
            setShowDrawer(shouldShow);
          }}
        />
      </Container>
    )
  );
}
