import {i18n, k} from '@i18n/translate';
import {pxToRem} from '@utils/styleMixins';
import React from 'react';
import styled from 'styled-components';
import {TagOutlined} from '@ant-design/icons';

const SkillsBox = styled.div`
  width: auto;
  border-radius: 12px;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  margin: 0 12px 12px 12px;
  padding: 16px;
`;

const SkillsTitle = styled.div`
  font-size: ${pxToRem(16, 16)};
  font-weight: 500;
  line-height: ${pxToRem(20, 16)};
`;

const SkillItemList = styled.ul`
  font-size: ${pxToRem(12, 16)};
  line-height: ${pxToRem(16, 16)};
  font-weight: 400;
  list-style-type: none;
  padding: 0;
  margin: 8px 0 0 0;
  li:not(:last-child) {
    margin-bottom: 6px;
  }
`;

interface AcademySidebarSkillTagsProps {
  skills: string[];
  backgroundColor: string;
  color: string;
}

const AcademySidebarSkillTags: React.FC<AcademySidebarSkillTagsProps> = ({
  skills,
  backgroundColor,
  color,
}) => {
  return (
    <SkillsBox color={color} backgroundColor={backgroundColor}>
      <SkillsTitle>
        <TagOutlined aria-hidden="true" /> {i18n.t(k.GENERIC__SKILL__PLURAL)}
        <SkillItemList>
          {skills.map((skill) => (
            <li key={skill}>{skill}</li>
          ))}
        </SkillItemList>
      </SkillsTitle>
    </SkillsBox>
  );
};

export default AcademySidebarSkillTags;
