import {LearningResourceType, ReimbursementItemType} from './../enums';
import {NewReimbursementApprovalApplicationVM} from './../interfaces';
import {
  PreApprovalQuestionVMMockData,
  getPreApprovalQuestionVMMockData,
} from './PreApprovalQuestionVM.mock';
import {faker} from '@faker-js/faker';

// Interface: NewReimbursementApprovalApplicationVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getNewReimbursementApprovalApplicationVMMockData(
  args?: Partial<NewReimbursementApprovalApplicationVM>
): NewReimbursementApprovalApplicationVM {
  return {
    learningResourceType: LearningResourceType.Book, // optional
    questions: [getPreApprovalQuestionVMMockData()],
    reimbursementType: ReimbursementItemType.Program,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const NewReimbursementApprovalApplicationVMMockData: NewReimbursementApprovalApplicationVM =
  {
    learningResourceType: LearningResourceType.Book, // optional
    questions: [PreApprovalQuestionVMMockData],
    reimbursementType: ReimbursementItemType.Program,
  };
