import {ReactNode, useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Modal, Radio, Typography} from 'antd';

interface Props {
  shouldShowPrompt: boolean;
  isAboutToLeave: boolean;
  title?: ReactNode;
  promptOptions?: {label: string; value: any}[];
  onPromptClose: (isOkay: boolean, confirmationOptions?: any) => void;
}

export const SaveChangesModal = ({
  shouldShowPrompt,
  isAboutToLeave,
  promptOptions,
  title,
  onPromptClose,
}: Props) => {
  const [confirmChanges, setConfirmChanges] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const handleOkConfirmationModal = () => {
    setModalVisible(false);
    onPromptClose?.call(null, true, confirmChanges);
  };

  const handleCancelConfirmationModal = () => {
    setModalVisible(false);
    onPromptClose?.call(null, false, confirmChanges);
  };

  useEffect(() => {
    if (shouldShowPrompt && isAboutToLeave) {
      setModalVisible(true);
    } else if (isAboutToLeave) {
      onPromptClose?.call(null, true);
    }
  }, [shouldShowPrompt, isAboutToLeave]);

  return (
    <>
      {/* <Prompt when={shouldShowPrompt} message={(location) => handleDisplayModalOnLocationChange(location)} /> */}
      <Modal
        open={modalVisible}
        onOk={handleOkConfirmationModal}
        okText={i18n.t(k.CTA__OK)}
        cancelText={i18n.t(k.CTA__CANCEL)}
        cancelButtonProps={{
          shape: 'round',
          className: 'btn-secondary admin-initiatives-blue-outline-btn',
        }}
        okButtonProps={
          {
            'data-testid': 'unsaved-changes-modal-apply-button',
            className: 'btn-primary blue-btn admin-initiatives-filled-btn',
            style: {minWidth: '90px'},
            shape: 'round',
            children: i18n.t(k.CTA__APPLY),
          } as any /* converting to any because data-testid is not a property of okButtonProps, but we want to pass it to the button */
        }
        onCancel={handleCancelConfirmationModal}
        className="admin-initiative-modal"
        style={{top: '150px'}}>
        <div data-testid="save-unsaved-changes-modal">
          <Typography.Title style={{fontSize: '1.25rem'}}>
            {title ?? i18n.t(k.GENERIC__UNSAVED_CHANGES)}
          </Typography.Title>
          {promptOptions && !!promptOptions.length && (
            <div style={{marginTop: '24px'}}>
              <Typography.Paragraph className="modal-grey-text">
                {i18n.t(k.PROMPT__GENERIC_CHOICE)}
              </Typography.Paragraph>
              <Radio.Group
                value={confirmChanges}
                onChange={(e) => {
                  setConfirmChanges(e.target.value);
                }}>
                {promptOptions?.map((option, i) => (
                  <Radio key={i} value={option.value} style={{width: '100%'}}>
                    {option.label}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default SaveChangesModal;
