import {IncentiveType} from './../enums';
import {EditedIncentiveVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: EditedIncentiveVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getEditedIncentiveVMMockData(
  args?: Partial<EditedIncentiveVM>
): EditedIncentiveVM {
  return {
    approvalGuidelines: faker.word.noun(),
    id: faker.datatype.number(),
    incentiveType: IncentiveType.Time,
    wasCreated: faker.datatype.boolean(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const EditedIncentiveVMMockData: EditedIncentiveVM = {
  approvalGuidelines: 'string',
  id: 1,
  incentiveType: IncentiveType.Time,
  wasCreated: true,
};
