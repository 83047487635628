import {Maybe} from './typeUtils';

export const quoteExp = (exp: string) => {
  return exp.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'); //replace special characters
};

export const filterQuery = (
  data: Maybe<any[]>,
  searchText: string,
  searchBy: string[]
) => {
  return data?.filter((result) => {
    if (!searchText) return true;
    return new RegExp(quoteExp(searchText?.trim()), 'i').test(
      `${searchBy?.map((parameter) => {
        return result[parameter];
      })}`
    );
  });
};

/**
 * Alphabetize by name
 */

export function alphabetizeByName(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}
