import {
  CalendarProvider,
  CurrencyCode,
  FinanceType,
  LanguagePreference,
} from './../enums';
import {UserDetailsVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserDetailsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserDetailsVMMockData(
  args?: Partial<UserDetailsVM>
): UserDetailsVM {
  return {
    canViewAcademiesFeature: faker.datatype.boolean(),
    companyId: faker.datatype.number(),
    connectedCalendar: CalendarProvider.Google, // optional
    currency: CurrencyCode.USD,
    email: String(faker.internet.email()),
    firstLogin: String(faker.date.past()), // optional
    firstName: faker.name.firstName(),
    hasAcceptedStripeTerms: faker.datatype.boolean(), // optional
    isAcademiesIntegratedExperienceDisabled: faker.datatype.boolean(),
    isAcademyApprover: faker.datatype.boolean(),
    isAdmin: faker.datatype.boolean(),
    isBeta: faker.datatype.boolean(),
    isContentMarketplaceIntegratedExperienceDisabled: faker.datatype.boolean(),
    isEligibleForPersonalLearningBudget: faker.datatype.boolean(),
    isManager: faker.datatype.boolean(),
    languagePreference: LanguagePreference.EN_US,
    lastName: faker.name.lastName(),
    lxpSsoUrl: faker.internet.url(),
    ssoProvider: faker.company.name(),
    plbType: FinanceType.Loan, // optional
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserDetailsVMMockData: UserDetailsVM = {
  canViewAcademiesFeature: true,
  companyId: 1,
  connectedCalendar: CalendarProvider.Google, // optional
  currency: CurrencyCode.USD,
  email: 'bjohnson@gmail.com',
  firstLogin: '2023-03-29T22:17:19.738072', // optional
  firstName: 'Bobby',
  hasAcceptedStripeTerms: true, // optional
  isAcademiesIntegratedExperienceDisabled: true,
  isAcademyApprover: true,
  isAdmin: true,
  isBeta: true,
  isContentMarketplaceIntegratedExperienceDisabled: true,
  isEligibleForPersonalLearningBudget: true,
  isManager: true,
  languagePreference: LanguagePreference.EN_US,
  lastName: 'Johnson',
  lxpSsoUrl: 'https://this-person-does-not-exist.com/en',
  ssoProvider: 'SomeCompanySSO',
  plbType: FinanceType.Loan, // optional
  userCompanyId: 1,
};
