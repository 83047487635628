import {List} from 'antd';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {api} from '@store/api';
import {notify} from '@components/user/notifications';
import {isBetaAny, isProductionAny} from '@utils/environment-helpers';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import LearnInTable from '@components/reusable/LearnInTable';
import {booleanSorter, stringSorter} from '@utils/tableUtils';
import {COLORS} from '@utils/constants';
import {FeatureFlagExperiments} from '@utils/feature-flag-helpers';
import useSetPageTitle from '@hooks/useSetPageTitle';
import Search from '@components/reusable/Search';
import {useState} from 'react';
import {Tag} from '@blocks/Tag/Tag';
import {TagColors} from '@blocks/Tag/Tag.model';
import {InfoCircleOutlined, WarningOutlined} from '@ant-design/icons';
const ONBOARDING_PREFIX = 'client-onboarding';

const QaPage = ({title}: {title?: string}) => {
  const getUserQuery = useGetUserQuery();
  const [featureFlagSearch, setFeatureFlagSearch] = useState('');

  useSetPageTitle(title);

  const data = [
    {name: 'Erase Time Incentives', path: 'qa/hard-reset-time-incentives'},
    {
      name: 'Erase Finance Incentives',
      path: 'qa/hard-reset-finance-incentives',
    },
    {name: 'Erase My Plan', path: 'qa/hard-reset-current-users-plan'},
    {name: 'Erase User Plans', path: 'qa/hard-reset-user-plans'},
    {
      name: 'Erase All Custom Programs',
      path: `qa/hard-reset-custom-programs`,
    },
    {
      name: 'Erase Current User Collaboration Channel Memberships',
      path: `qa/hard-reset-current-user-collaboration-channel-memberships`,
    },
    {
      name: 'Add Default Resources Approval Questions',
      path: [
        ONBOARDING_PREFIX,
        `add-default-resources-approval-questions?companyId=${getUserQuery.data?.companyId}`,
      ].join('/'),
      body: {
        addDefaultBookQuestions: true,
        addDefaultConferenceQuestions: true,
        addDefaultSubscriptionQuestions: true,
        addDefaultOtherQuestions: true,
      },
    },

    {
      name: 'Add Default Pre-Approval Questions',
      path: [
        ONBOARDING_PREFIX,
        `add-default-pre-approval-questions?companyId=${getUserQuery.data?.companyId}`,
      ].join('/'),
      body: {
        addDefaultProgramQuestions: true,
        addDefaultTimeQuestions: true,
        addDefaultFinanceQuestions: true,
        addDefaultLicenseQuestions: true,
      },
    },
    {
      name: 'Set Your Manager to You',
      path: `qa/set-self-as-manager`,
    },
    {
      name: 'Reset Job Queue For User Company',
      path: `qa/reset-job-queue-for-user-company`,
    },
  ];

  const {isFeatureFlagOn, overrideFlag, flagOverrides} = useFeatureFlags();

  if (isProductionAny || isBetaAny) {
    return null;
  }

  const flagsWithOverrides = Object.keys(flagOverrides).filter(
    (key) => flagOverrides[key] !== undefined
  );

  return (
    <>
      <List
        header={<div>QA Operations</div>}
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <LearnInButton
              tag={
                item.path.startsWith(ONBOARDING_PREFIX)
                  ? ButtonTags.Secondary
                  : ButtonTags.Destructive
              }
              onClick={async () => {
                try {
                  const result = await api.post(item.path, item.body);
                  if (result.status === 200) {
                    notify.devSuccess();
                  } else {
                    notify.devFailure();
                  }
                } catch (error) {
                  notify.devFailure();
                }
              }}>
              {item.name}
            </LearnInButton>
          </List.Item>
        )}
      />

      <div style={{padding: '16px'}}>
        <h1>Feature Flags</h1>
        <Search
          id="qa-page-search"
          style={{width: '270px', margin: '16px 0'}}
          onChange={setFeatureFlagSearch}
          value={featureFlagSearch}
          placeholder="Search"
          label="Search"
          buttonAriaLabel="Search"
        />
        {flagsWithOverrides?.length ? (
          <div>
            <div style={{fontWeight: 'bold'}}>
              <WarningOutlined style={{color: COLORS.Red800}} /> Flags with
              local overrides:
            </div>
            <div>
              <InfoCircleOutlined
                style={{color: COLORS.Blue800, marginRight: 3}}
              />
              Click to find the flag
            </div>
            <div>
              <InfoCircleOutlined
                style={{color: COLORS.Blue800, marginRight: 3}}
              />
              May need to refresh browser after overriding a flag
            </div>
            <div style={{margin: '6px 0'}}>
              {flagsWithOverrides.map((flag) => (
                <button
                  onClick={() => setFeatureFlagSearch(flag)}
                  style={{
                    cursor: 'pointer',
                    marginRight: 6,
                  }}
                  key={flag}>
                  <Tag key={flag} tagColor={TagColors.Red} label={flag} />
                </button>
              ))}
            </div>
          </div>
        ) : null}
        <LearnInTable
          pagination={false}
          columns={[
            {
              title: 'Name',
              key: 'name',
              dataIndex: 'name',
              sorter: stringSorter('name'),
            },
            {
              title: 'String',
              key: 'string',
              dataIndex: 'string',
              sorter: stringSorter('string'),
            },
            {
              title: 'State',
              key: 'isOn',
              dataIndex: 'isOn',
              sorter: booleanSorter('isOn'),
              render: (isOn: boolean) =>
                isOn ? (
                  <span style={{color: COLORS.Green600}}>On</span>
                ) : (
                  <span style={{color: COLORS.Red800}}>Off</span>
                ),
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, record) => {
                return (
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <LearnInButton
                      style={{width: '100%'}}
                      tag={ButtonTags.Secondary}
                      onClick={() => {
                        overrideFlag(record.string, !record.isOn);
                      }}>
                      Toggle
                    </LearnInButton>
                    {flagOverrides?.[record.string] !== undefined && (
                      <LearnInButton
                        style={{width: '100%', marginLeft: 0, marginTop: 4}}
                        tag={ButtonTags.Primary}
                        onClick={() => {
                          overrideFlag(record.string, undefined);
                        }}>
                        Remove Override
                      </LearnInButton>
                    )}
                  </div>
                );
              },
            },
          ]}
          dataSource={Object.entries(isFeatureFlagOn)
            .map(([name, isOn]) => ({
              name,
              isOn,
              string: FeatureFlagExperiments[name],
            }))
            .filter((flag) => {
              if (featureFlagSearch) {
                return (
                  flag.name
                    .toLowerCase()
                    .includes(featureFlagSearch.toLowerCase()) ||
                  flag.string
                    .toLowerCase()
                    .includes(featureFlagSearch.toLowerCase())
                );
              }
              return true;
            })}
        />
      </div>
    </>
  );
};

export default QaPage;
