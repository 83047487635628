import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {PROGRAM_COMPLETED} from '@assets/cloudinary-urls';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {TextTags} from '@components/reusable/Text/TextEnums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const AnimatedGif = styled.img`
  height: 176px;
  width: 176px;
  margin-bottom: 8px;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ProgramCompletedModalProps {
  visible: boolean;
  onClose: () => void;
}

function ProgramCompletedModal({visible, onClose}: ProgramCompletedModalProps) {
  return (
    <LearnInModal
      visible={visible}
      width={400}
      tag={ModalTags.RemoveProgramFromUserPlan}
      okButtonChild={i18n.t(k.CTA__OK)}
      hideLeftButton={true}
      style={{textAlign: 'center'}}
      onCancel={onClose}
      onOk={onClose}
      ariaLabel={i18n.t(k.PROGRAM__COMPLETED__TITLE)}>
      <AnimatedGif src={PROGRAM_COMPLETED} alt="" />
      <div>
        <LearnInText
          margin="8px"
          paddingBottom="0px"
          tag={TextTags.ModalSubHeader}>
          {i18n.t(k.PROGRAM__COMPLETED__TITLE)}
        </LearnInText>
        <LearnInText tag={TextTags.LearnInPersonalBudgetDescription}>
          {i18n.t(k.PROGRAM__COMPLETED__DESCRIPTION)}
        </LearnInText>
      </div>
    </LearnInModal>
  );
}

export default ProgramCompletedModal;
