import {i18n, k} from '@i18n/translate';
import {isNil} from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {DATE_FORMAT} from '@utils/timeUtils';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {InfoCircleOutlined} from '@ant-design/icons';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/
export const Label = styled.div`
  color: ${COLORS.Neutral600};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.875rem;
`;

export const Data = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Neutral950};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 140px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: ${COLORS.Neutral600};
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-bottom: 1px;
`;

/*
|--------------------------------------------------------------------------
| Helpers
|--------------------------------------------------------------------------
*/

export const getLabel = (days: number) =>
  i18n.t(days > -1 ? k.PLAN__EXPIRY__LABEL : k.PLAN__EXPIRY__EXPIRED_ON);

export const getTooltip = (days: number) =>
  i18n.t(
    days > -1 ? k.PLAN__EXPIRY__TOOLTIP : k.PLAN__EXPIRY__EXPIRED_FUNDS_REMOVED
  );

export const getDaysUntilDate = (days: number) => moment().add(days, 'days');

export const getFormattedDaysUntil = (days: number) =>
  getDaysUntilDate(days).format(DATE_FORMAT.MONTH_FULL_DAY_YEAR);

export const showUnspentFundsDisclaimer = ({
  isUnspentFundsOn,
  isLicense,
  daysUntilExpiration,
}: {
  isUnspentFundsOn: boolean;
  isLicense: boolean;
  daysUntilExpiration: number;
}) => {
  return (
    isUnspentFundsOn &&
    !isLicense &&
    !isNil(daysUntilExpiration) &&
    (daysUntilExpiration >= 0 || daysUntilExpiration < 0)
  );
};

/*
|--------------------------------------------------------------------------
| Block
|--------------------------------------------------------------------------
*/

export interface UnspentFundsDisclaimerProps {
  daysUntilExpiration: number;
}

export const UnspentFundsDisclaimer = ({
  daysUntilExpiration,
}: UnspentFundsDisclaimerProps) => {
  const label = getLabel(daysUntilExpiration);
  const tooltip = getTooltip(daysUntilExpiration);
  const formattedDaysUntil = getFormattedDaysUntil(daysUntilExpiration);
  const tooltipWidth = '220px';

  return (
    <div>
      <Label>
        {label}{' '}
        <LearnInTooltip title={tooltip} width={tooltipWidth}>
          <StyledInfoCircleOutlined aria-hidden="true" />
        </LearnInTooltip>
      </Label>
      <Data>{formattedDaysUntil}</Data>
    </div>
  );
};
