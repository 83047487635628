import {mutationHookFactory} from './../../utils';

import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramController.cs

/*
|--------------------------------------------------------------------------
|  custom-program-content-delete-attachments/{customProgramContentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramContentDeleteAttachmentsMutation} from '@generated/hooks';
 *
 *  const customProgramContentDeleteAttachments = useCustomProgramContentDeleteAttachmentsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramContentDeleteAttachments.mutate({
 *    pathVars: {
 *      customProgramContentId
 *    }
 *  })
 *
 */

export const useCustomProgramContentDeleteAttachmentsMutation =
  mutationHookFactory<
    null,
    {customProgramContentId: number | string}, // path variables
    null
  >(
    'useCustomProgramContentDeleteAttachmentsMutation',
    'delete',
    ({customProgramContentId}) =>
      `custom-program/custom-program-content-delete-attachments/${customProgramContentId}`,
    {payloadSchema: undefined}
  );

/*
|--------------------------------------------------------------------------
|  delete-user-permission/{customProgramId}/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramDeleteUserPermissionMutation} from '@generated/hooks';
 *
 *  const customProgramDeleteUserPermission = useCustomProgramDeleteUserPermissionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramDeleteUserPermission.mutate({
 *    pathVars: {
 *      customProgramId,
 *      userCompanyId
 *    }
 *  })
 *
 */

export const useCustomProgramDeleteUserPermissionMutation = mutationHookFactory<
  null,
  {customProgramId: number; userCompanyId: number}, // path variables
  boolean // return type
>(
  'useCustomProgramDeleteUserPermissionMutation',
  'delete',
  ({customProgramId, userCompanyId}) =>
    `custom-program/delete-user-permission/${customProgramId}/${userCompanyId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-cohort/{cohortCustomProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteCohortMutation} from '@generated/hooks';
 *
 *  const deleteCohort = useDeleteCohortMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteCohort.mutate({
 *    pathVars: {
 *      cohortCustomProgramId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useDeleteCohortMutation = mutationHookFactory<
  null,
  {cohortCustomProgramId: number | string}, // path variables
  null
>(
  'useDeleteCohortMutation',
  'delete',
  ({cohortCustomProgramId}) =>
    `custom-program/delete-cohort/${cohortCustomProgramId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-content-attachment/{attachmentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteContentAttachmentMutation} from '@generated/hooks';
 *
 *  const deleteContentAttachment = useDeleteContentAttachmentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteContentAttachment.mutate({
 *    pathVars: {
 *      attachmentId
 *    }
 *  })
 *
 */

export const useDeleteContentAttachmentMutation = mutationHookFactory<
  null,
  {attachmentId: number | string}, // path variables
  null
>(
  'useDeleteContentAttachmentMutation',
  'delete',
  ({attachmentId}) =>
    `custom-program/delete-content-attachment/${attachmentId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-custom-program/{customProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteCustomProgramMutation} from '@generated/hooks';
 *
 *  const deleteCustomProgram = useDeleteCustomProgramMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteCustomProgram.mutate({
 *    pathVars: {
 *      customProgramId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useDeleteCustomProgramMutation = mutationHookFactory<
  null,
  {customProgramId: number | string}, // path variables
  number // return type
>(
  'useDeleteCustomProgramMutation',
  'delete',
  ({customProgramId}) =>
    `custom-program/delete-custom-program/${customProgramId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-custom-program-content/{contentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteCustomProgramContentMutation} from '@generated/hooks';
 *
 *  const deleteCustomProgramContent = useDeleteCustomProgramContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteCustomProgramContent.mutate({
 *    pathVars: {
 *      contentId
 *    }
 *  })
 *
 */

export const useDeleteCustomProgramContentMutation = mutationHookFactory<
  null,
  {contentId: number | string}, // path variables
  number // return type
>(
  'useDeleteCustomProgramContentMutation',
  'delete',
  ({contentId}) => `custom-program/delete-custom-program-content/${contentId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-custom-program-cover-image/{customProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteCustomProgramCoverImageMutation} from '@generated/hooks';
 *
 *  const deleteCustomProgramCoverImage = useDeleteCustomProgramCoverImageMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteCustomProgramCoverImage.mutate({
 *    pathVars: {
 *      customProgramId
 *    }
 *  })
 *
 */

export const useDeleteCustomProgramCoverImageMutation = mutationHookFactory<
  null,
  {customProgramId: number | string}, // path variables
  null
>(
  'useDeleteCustomProgramCoverImageMutation',
  'delete',
  ({customProgramId}) =>
    `custom-program/delete-custom-program-cover-image/${customProgramId}`,
  {payloadSchema: undefined}
);
