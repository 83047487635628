/* Disabling for this file because `adaptedProps` is dynamic */
/* eslint-disable react/prop-types */
import {AcademyStepVM} from '@generated/interfaces';
import {DATE_FORMAT, formatUtcTime, parseToLocalMoment} from '@utils/timeUtils';
import moment from 'moment';
import {Moment} from 'moment';

/**
 * Creates a wrapper from an Ant Design form input that adapts
 * the props from which the component reads and writes values.
 * It is intended to be used as a child of Form.Item when the
 * target component doesn't use the standard `value` and `onChange`
 * props, so it renames them.
 *
 * @example
 * const AdaptedInput = createFormItemAdapter(Input, 'setValue', 'value');
 * return (
 *   <Form.Item name="username">
 *      <AdaptedInput />
 *   </Form.Item>
 * )
 */
export function createFormItemAdapter<P, V>(
  FormComponent: React.FC<P>,
  onChangeProp: string,
  valueProp: string
) {
  const AdaptedFormComponent: React.FC<
    P & {onChange?: (value: V) => void; value?: V}
  > = (props) => {
    const adaptedProps = {
      ...props,
      [onChangeProp]: props.onChange,
      [valueProp]: props.value,
    };
    return <FormComponent {...adaptedProps} />;
  };

  return AdaptedFormComponent;
}

/**
 * Utility function that checks if a condition is met, and returns a rejected promise if not.
 * This is intended to be used wtih Form.Item custom validators to display error messages
 */
export function validate(condition: boolean, errorMessage: string) {
  if (!condition) {
    return Promise.reject(errorMessage);
  }

  return Promise.resolve();
}

/**
 * Parses due date information from backend response into values
 * that can be used to set initial state of due date form controls.
 */
export function getInitialDueDateAndTime(
  step: AcademyStepVM
): {initialDueDate: Moment; initialDueDateTime: Moment} | undefined {
  if (!step?.dueDateUtc) {
    return;
  }

  const dueDate = formatUtcTime(step.dueDateUtc, step.dueDateTimeZone);

  const dueDateTime: string | undefined = dueDate?.format(
    DATE_FORMAT.TIME_24_HR_PADDED
  );

  return {
    initialDueDate: parseToLocalMoment(dueDate),
    initialDueDateTime: dueDateTime
      ? moment(dueDateTime, DATE_FORMAT.TIME_24_HR_PADDED)
      : undefined,
  };
}
