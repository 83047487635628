import React, {Fragment} from 'react';
import t from '@i18n/translate';
import {DisplayStatus} from '@generated/enums';
import {UserItemStatusVM} from '@generated/interfaces';
import ContextMenuButton from '@blocks/ContextMenu';
import CaretDown from '@assets/svg/CaretDown';
import {
  AcademyEventFooterLink,
  Divider,
  EventFooterCTAsContent,
} from './EventFooterCTAs.styled';

type EventFooterCTAsProps = {
  eventLinkUrl: string;
  eventHasPassed: boolean;
  status: UserItemStatusVM;
  calendarLinks?: {label: string; url: string}[];
  contentCardContextId?: string;
};

const EventFooterCTAs: React.FC<EventFooterCTAsProps> = ({
  eventLinkUrl,
  eventHasPassed,
  status,
  calendarLinks,
  contentCardContextId,
}) => {
  const getCTAs = () => {
    const ctas: React.ReactElement[] = [];
    if (eventLinkUrl) {
      ctas.push(
        <AcademyEventFooterLink
          href={eventLinkUrl}
          target="_blank"
          rel="noopener noreferrer">
          {t('EVENT__JOIN')}
        </AcademyEventFooterLink>
      );
    }

    if (!!calendarLinks?.length) {
      ctas.push(
        <ContextMenuButton
          popupContainerId={contentCardContextId}
          itemId={contentCardContextId}
          Target={
            <AcademyEventFooterLink onClick={(e) => e.preventDefault()}>
              {t('CALENDAR__ADD_TO')}{' '}
              <CaretDown style={{verticalAlign: 'middle'}} />
            </AcademyEventFooterLink>
          }
          menuItems={calendarLinks.map((calendarLink) => ({
            label: calendarLink.label,
            onClick: () => window.open(calendarLink.url, '_blank'),
          }))}
        />
      );
    }

    return ctas.map((ctaElem, i) => (
      <Fragment key={`CTA__${i}`}>
        {ctaElem}
        {i !== ctas.length - 1 && <Divider data-testid="divider">|</Divider>}
      </Fragment>
    ));
  };

  return !eventHasPassed && status.displayStatus !== DisplayStatus.Skipped ? (
    <EventFooterCTAsContent>{getCTAs()}</EventFooterCTAsContent>
  ) : null;
};

export default React.memo(EventFooterCTAs);
