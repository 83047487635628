import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';

import {COLORS} from '@utils/constants';
import {SUBTEXT_ID} from '@components/user/pages/components/PreApprovalFormItem/MoneyInput/MoneyInput.constants';

const StyledProgramAmountSubtext = styled.span`
  color: ${COLORS.Neutral900};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;
`;

const Emphasized = styled.strong`
  font-weight: 500;
`;

interface ProgramAmountSubtextProps {
  programCost: number;
  isProgram?: boolean;
  isResourceRequest?: boolean;
  isUserRequest?: boolean;
  costFormattedInUsersCurrency: string;
  defaultToProgramCurrency: boolean;
  costFormattedInProgramCurrency: string;
}
export const ProgramAmountSubtext = ({
  programCost,
  isProgram,
  isResourceRequest,
  isUserRequest,
  costFormattedInUsersCurrency,
  defaultToProgramCurrency,
  costFormattedInProgramCurrency,
}: ProgramAmountSubtextProps) => (
  <StyledProgramAmountSubtext data-testid={SUBTEXT_ID}>
    {!!programCost && isProgram && (
      <>
        {i18n.t(k.APPROVAL__PROGRAM_COST_IS)}{' '}
        <Emphasized>{costFormattedInUsersCurrency}</Emphasized>
        {!defaultToProgramCurrency &&
          ` (${costFormattedInProgramCurrency})`}{i18n.t(k.PUNCTATION__PERIOD)}{' '}
      </>
    )}
    {isResourceRequest || isUserRequest
      ? i18n.t(k.APPROVAL__INCLUDES_TAX_FEES)
      : i18n.t(k.APPROVAL__FEE_SUGGESTION)}
  </StyledProgramAmountSubtext>
);
