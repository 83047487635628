import {SearchOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';
import {Input} from 'antd';
import React from 'react';
import styled from 'styled-components';

const SearchWrapper = styled.section`
  input {
    height: 31px;
  }
`;

const Label = styled.label`
  display: block;
  font-weight: 500;
  font-size: ${pxToRem(16, 16)};
  line-height: ${pxToRem(20, 16)};
  color: ${COLORS.Neutral800};
  text-transform: lowercase;

  ::first-letter {
    text-transform: uppercase;
  }
`;

type SearchProps = {
  id: string;
  onChange: (filter: string) => void;
  value: string;
  label: string;
  buttonAriaLabel: string;
  placeholder?: string;
  style?: React.CSSProperties;
  ['data-testid']?: string;
};

/**
 * Search component that wraps antd's Input.Search to make it accessible
 */
const Search: React.FC<SearchProps> = ({
  id,
  buttonAriaLabel,
  label,
  onChange,
  value,
  placeholder,
  style,
  ...props
}) => {
  return (
    <SearchWrapper style={style}>
      <Label htmlFor={id}>
        <span>{label}</span>
        <Input.Search
          data-testid={props['data-testid'] || null}
          id={id}
          allowClear
          aria-label={label}
          onChange={(ev) => onChange(ev.target.value)}
          value={value}
          placeholder={placeholder}
          style={{marginTop: 4}}
          enterButton={
            <button
              aria-label={buttonAriaLabel}
              style={{
                // Styles to mimic original antd's search button
                borderRadius: '0 2px 2px 0',
                height: 40,
                width: 40,
                backgroundColor: 'white',
                border: '1px solid #d9d9d9',
                color: 'rgb(0,0,0,0.45)',
              }}>
              <SearchOutlined />
            </button>
          }
        />
      </Label>
    </SearchWrapper>
  );
};

export default Search;
