import {
  CircleIndicator,
  IndicatorContainer,
  LineIndicatorFirst,
  LineIndicatorLast,
  LineIndicatorMiddle,
} from './StepIndicator.styled';

export enum IndicatorPosition {
  FIRST,
  MIDDLE,
  LAST,
}

const lineComponents = {
  [IndicatorPosition.FIRST]: LineIndicatorFirst,
  [IndicatorPosition.MIDDLE]: LineIndicatorMiddle,
  [IndicatorPosition.LAST]: LineIndicatorLast,
};

const StepIndicator = ({
  shouldHideLine,
  position,
}: {
  position: IndicatorPosition;
  shouldHideLine: boolean;
}) => {
  const LineComponent = lineComponents[position];

  return (
    <IndicatorContainer data-testid="step-indicator">
      <CircleIndicator data-testid="indicator--bullet" />
      {!shouldHideLine && <LineComponent data-testid="indicator--line-v" />}
    </IndicatorContainer>
  );
};

export function createStepIndicator(data: unknown[]): JSX.Element[] {
  function getPosition(i: number) {
    switch (true) {
      case i === 0:
        return IndicatorPosition.FIRST;
      case data.length - 1 === i:
        return IndicatorPosition.LAST;
      default:
        return IndicatorPosition.MIDDLE;
    }
  }

  return data.map((_, i) => (
    <StepIndicator
      key={i}
      position={getPosition(i)}
      shouldHideLine={data.length === 1}
    />
  ));
}

export default StepIndicator;
