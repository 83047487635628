import {useMemo, useState} from 'react';
import * as React from 'react';
import './UserPlanItemCard.scss';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Row} from 'antd';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {WarningFilled} from '@ant-design/icons';
import {TimeAndFinanceApprovalsStatus} from '../../store/interface';
import {useLocation, useNavigate} from 'react-router-dom';
import PreApprovalDrawer from '../../../components/preApprovalDrawer/PreApprovalDrawer.container';
import {UserPlanItemCardVM} from '@models/serverModels';
import {CurrencyCode, PlanItemType} from '@generated/enums';
import {mapValueToPlanItemType} from '@utils/enumMapping/PlanItemTypeMapper';
import {formatCurrency, getCurrencyEnum} from '@utils/moneyUtils';
import ProviderLogo from '@blocks/ProviderLogo';
import {
  getApplicationStatusTagStyle,
  getApplicationStatusTitle,
  UserPlanCardActionMenuTest,
} from '@blocks/userPlanCardActionMenu/UserPlanCardActionMenu';
import {UserPlanItemStatusString} from '@models/clientEnums';
import ActionButton from '@blocks/actionButton/ActionButton';
import {DurationOrAmountColumn} from '@blocks/durationOrAmountColumn/DurationOrAmountColumn';
import {getUserPlanItemCardUrl} from '@utils/ClientPaths';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {Breakpoints} from '@utils/responsiveUtils';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import LicenseViewerModal from '@blocks/licenseViewerModal/LicenseViewerModal.container';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import ContextMenuButton from '@blocks/ContextMenu';
import {useGetDurationOrAmountColumnData} from '@hooks/useGetDurationOrAmountColumnData';

const UserPlanItemCardCard = styled(Card)`
  cursor: pointer;

  &.ant-card,
  .ant-card {
    border-radius: 8px;
  }
`;

export const SubTitle = styled.p`
  font: normal normal 8px Roboto, serif;
  line-height: 0.875rem;
  color: ${COLORS.Neutral900};
  text-transform: uppercase;

  @media (max-width: ${Breakpoints.Desktop}px) {
    font-size: 0.625rem;
  }
  @media (max-width: ${Breakpoints.Tablet}px) {
    font-size: 0.5rem;
  }
  @media (max-width: ${Breakpoints.Mobile}px) {
    font-size: 0.5rem;
  }
`;

const CardTitle = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 250px;
  font: normal normal 16px Roboto, serif;
  line-height: 150%;
  color: ${COLORS.Neutral950};
  margin: 0;
`;

interface UserPlanItemCardWithLicenseIdVM extends UserPlanItemCardVM {
  licenseId?: number;
}

interface Props {
  isPrepaymentIncentive?: boolean;
  showActions: boolean;
  item: UserPlanItemCardWithLicenseIdVM;
}

const ReimbursementExceedsTooltip = (
  cost: number,
  exchangeRate: number,
  usersCurrency: CurrencyCode
) => {
  return (
    <LearnInTooltip
      title={i18n.t(k.REIMBURSEMENT__EXCEEDS_DESCRIPTION__FORMAT, {
        localizedAmount: formatCurrency(cost, usersCurrency, exchangeRate),
      })}>
      <WarningFilled
        aria-hidden="true"
        style={{
          paddingLeft: '10px',
          paddingTop: '2px',
          color: 'rgb(255, 207, 45)',
          fontSize: '1rem',
        }}
      />
    </LearnInTooltip>
  );
};

// https://linear.app/learnin/issue/LI-725/stop-using-user-planget-item-for-userplanitemcard
export default function UserPlanItemCard(props: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const isManageFinance = pathname.includes('manage/finance');
  const isManageTime = pathname.includes('manage/time');
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    getCurrencyEnum(props.item.currencyCode),
    getUserQuery.data?.currency
  );

  const planItemTypeWithCustomProgram = props.item.itemType;

  const [hasEstimatedAmount] = useState(
    !!props.item.estimatedAmount && !props.item.isAnyAmountIncentive
  );

  const [showPreApprovalDrawer, setShowPreApprovalDrawer] =
    React.useState(false);

  const [showRequestLicenseDrawer, setShowRequestLicenseDrawer] =
    React.useState(false);

  const [showViewLicenseModal, setShowViewLicenseModal] = React.useState(false);

  const handleShowPreApprovalDrawer = (value) => {
    setShowPreApprovalDrawer(value);
  };

  const handleShowRequestLicenseDrawer = (value) => {
    setShowRequestLicenseDrawer(value);
  };

  const handleShowViewLicenseModal = (value) => {
    setShowViewLicenseModal(value);
  };

  const handleSettingsActions = useMemo(() => {
    return () => {
      // do nothing
    };
  }, []);

  const subTitleAmount = useGetDurationOrAmountColumnData({
    item: props.item,
    itemType: props.item?.itemType,
    exchangeRate,
  })[1];

  const estimatedAmount = useGetDurationOrAmountColumnData({
    item: props.item,
    itemType: props.item?.itemType,
    isManagePage: isManageTime || isManageFinance,
    isEstimatedAmount: true,
    exchangeRate,
  });

  return (
    <article
      aria-labelledby="user-plan-item-card-title"
      data-cy={`program-${props.item.id}`}>
      <Row align="middle" justify="center">
        <Col xs={24}>
          {showPreApprovalDrawer ? (
            <PreApprovalDrawer
              itemId={
                props.item.programId ||
                props.item.financeIncentiveId ||
                props.item.timeIncentiveId
              }
              visible={showPreApprovalDrawer}
              planItemId={props.item.id}
              planItemType={props.item.itemType}
              status={props.item.status}
              currency={getCurrencyEnum(props.item.currencyCode)}
              onOk={() => {
                handleShowPreApprovalDrawer(false);
              }}
              onCancel={() => {
                handleShowPreApprovalDrawer(false);
              }}
            />
          ) : (
            ' '
          )}
          {showViewLicenseModal && (
            <LicenseViewerModal
              provider={props.item.provider}
              licenseId={props.item.licenseId}
              programId={props.item.programId}
              onClose={() => {
                setShowViewLicenseModal(false);
              }}
            />
          )}
          {showRequestLicenseDrawer && (
            <PreApprovalDrawer
              itemId={props.item.programId}
              planItemType={PlanItemType.Program}
              visible={showRequestLicenseDrawer}
              licenseId={props.item.licenseId}
              status={props.item.status}
              currency={getCurrencyEnum(props.item.currencyCode)}
              onOk={() => {
                setShowRequestLicenseDrawer(false);
              }}
              onCancel={() => {
                setShowRequestLicenseDrawer(false);
              }}
            />
          )}
        </Col>
      </Row>
      <UserPlanItemCardCard
        key={props.item.id}
        role="button"
        tabIndex={0}
        onClick={() => {
          navigate(
            getUserPlanItemCardUrl(
              props.item.itemType,
              props.item?.programId ||
                props.item?.timeIncentiveId ||
                props.item?.financeIncentiveId
            )
          );
        }}>
        <Row>
          <Col xs={5} sm={3}>
            <ProviderLogo
              provider={props.item?.provider}
              providerLogo={props.item?.providerLogo}
              size={56}
            />
          </Col>
          <Col
            xs={{offset: 2, span: 17}}
            sm={{offset: 0, span: isManageTime ? 3 : 4}}>
            <Row>
              <SubTitle>
                {props.item?.itemType !== PlanItemType.Program
                  ? mapValueToPlanItemType(props.item?.itemType)
                  : subTitleAmount}
              </SubTitle>
            </Row>
            <Row>
              <CardTitle id="user-plan-item-card-title">
                {props.item?.title}
              </CardTitle>
            </Row>
          </Col>
          <Col
            xs={12}
            sm={isManageTime || hasEstimatedAmount ? 3 : 4}
            className="user-plan-card-mobile-view-new-line">
            <DurationOrAmountColumn
              data={useGetDurationOrAmountColumnData({
                item: props.item,
                itemType: props.item?.itemType,
                isManagePage: isManageTime || isManageFinance,
                exchangeRate,
              })}>
              {props.item.exceedsEstimatedCostAmount &&
                ReimbursementExceedsTooltip(
                  props.item.exceedsEstimatedCostAmount,
                  exchangeRate,
                  getUserQuery.data?.currency
                )}
            </DurationOrAmountColumn>
          </Col>

          {hasEstimatedAmount && !props.item.isPersonalLearningBudget && (
            <Col xs={12} sm={3} className="user-plan-card-mobile-view-new-line">
              <DurationOrAmountColumn data={estimatedAmount}>
                {props.item.exceedsEstimatedCostAmount &&
                  ReimbursementExceedsTooltip(
                    props.item.exceedsEstimatedCostAmount,
                    exchangeRate,
                    getUserQuery.data?.currency
                  )}
              </DurationOrAmountColumn>
            </Col>
          )}
          {!props.item.isPersonalLearningBudget && (
            <Col
              xs={12}
              sm={hasEstimatedAmount ? 3 : 4}
              className="user-plan-card-mobile-view-new-line">
              <Row>
                <SubTitle>
                  {i18n
                    .t(
                      isManageTime || isManageFinance
                        ? k.APPLICATION__STATUS
                        : k.STATUS__STATUS
                    )
                    .toLocaleUpperCase()}
                </SubTitle>
              </Row>
              <Row>
                <LearnInTag
                  tagStyle={getApplicationStatusTagStyle({
                    id: props.item.id,
                    itemType: planItemTypeWithCustomProgram,
                    licenseId: props.item.licenseId,
                    status: props.item.status as UserPlanItemStatusString,
                    programApprovalRequired: false,
                  })}
                  label={getApplicationStatusTitle(
                    props.item.status,
                    planItemTypeWithCustomProgram,
                    props.item.licenseId,
                    false,
                    null,
                    null
                  )}
                />
              </Row>
            </Col>
          )}

          {isManageTime && (
            <React.Fragment>
              <Col
                xs={12}
                sm={4}
                className="user-plan-card-mobile-view-new-line">
                <Row>
                  <SubTitle>
                    {i18n.t(k.TIME__APPROVED_BY).toLocaleUpperCase()}
                  </SubTitle>
                </Row>
                <Row>
                  <p className="font-body-large">
                    {props.item.redemptionApprovedBy}
                  </p>
                </Row>
              </Col>
              <Col
                xs={12}
                sm={4}
                className="user-plan-card-mobile-view-new-line">
                <Row>
                  <p className="font-label-small" style={{marginTop: '-6px'}}>
                    {i18n.t(k.TIME__APPROVAL).toLocaleUpperCase()}
                  </p>
                </Row>
                <Row>
                  <LearnInTag
                    label={
                      TimeAndFinanceApprovalsStatus[
                        props.item.redemptionApprovalState
                      ]
                    }
                    tagStyle={getTimeAndFinanceApprovalTagStyle(
                      props.item.redemptionApprovalState
                    )}
                  />
                </Row>
              </Col>
            </React.Fragment>
          )}
          {isManageFinance && !props.isPrepaymentIncentive && (
            <Col xs={8} sm={hasEstimatedAmount ? 4 : 5}>
              <Row>
                <p className="font-label-small">
                  {i18n.t(k.REIMBURSEMENT__APPROVED_BY)}
                </p>
              </Row>
              <Row>
                <p className="font-body-large">
                  {props.item?.redemptionApprovedBy}
                </p>
              </Row>
            </Col>
          )}
          <Col
            xs={10}
            sm={3}
            style={{textAlign: 'right'}}
            className="user-plan-card-mobile-view-new-line">
            {props.showActions ? (
              <ActionButton
                item={{
                  ...props.item,
                  ...{
                    itemType: planItemTypeWithCustomProgram,
                    programApprovalRequired: false,
                  },
                }}
                handleShowPreApprovalDrawer={handleShowPreApprovalDrawer}
                handleShowRequestLicenseDrawer={handleShowRequestLicenseDrawer}
                handleShowViewLicenseModal={handleShowViewLicenseModal}
                onSettings={handleSettingsActions}
                itemType={planItemTypeWithCustomProgram}
              />
            ) : (
              <span />
            )}
          </Col>
          <Col xs={2} sm={2}>
            {props.showActions ? (
              <ContextMenuButton
                menuItems={UserPlanCardActionMenuTest({
                  item: {
                    itemType: planItemTypeWithCustomProgram,
                    id: props.item.id,
                    licenseId: props.item.licenseId,
                    status: props.item.status as UserPlanItemStatusString,
                    programApprovalRequired: false,
                  },
                  onSettings: handleSettingsActions,
                })}
              />
            ) : (
              <span />
            )}
          </Col>
        </Row>
      </UserPlanItemCardCard>
    </article>
  );
}

const getTimeAndFinanceApprovalTagStyle = (
  timeAndFinanceApprovalStatus: TimeAndFinanceApprovalsStatus | string
): TagStyles => {
  switch (timeAndFinanceApprovalStatus) {
    case TimeAndFinanceApprovalsStatus.PendingManager:
      return TagStyles.Yellow;
    case TimeAndFinanceApprovalsStatus.PendingHR:
      return TagStyles.Yellow;
    case TimeAndFinanceApprovalsStatus.Approved:
      return TagStyles.Green;
    case TimeAndFinanceApprovalsStatus.Rejected:
      return TagStyles.Red;
    case TimeAndFinanceApprovalsStatus.ReadyToSubmit:
    default:
      return TagStyles.LightGrey;
  }
};
