export enum PresenceType {
  Online,
  Classroom,
}

export enum TimeCommitmentType {
  PartTime,
  FullTime,
}

export enum DurationUnitType {
  Days,
  Weeks,
  Months,
  Years,
}

export interface AddNewProgram {
  providerId: number;
  providerName: string;
  programTypeId: number;
  title: string;
  shortDescription: string;
  cost: number;
  url: string;
  presenceType: number;
  timeCommitmentType: number;
  programLength: number;
  programLengthUnit: number;
}

export enum ProgramStatus {
  ACCEPTED = 'Accepted',
  APPROVED = 'Approved',
  APPLIED = 'Applied',
  IN_PROGRESS = 'In Progress',
  REJECTED = 'Rejected',
  COMPLETED = 'Completed',
  NOT_ENROLLED = 'Not Enrolled',
  PENDING = 'Pending',
}
