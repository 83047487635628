import {useEffect} from 'react';
import {WelcomeContainerProps} from './Welcome.interfaces';
import WelcomeView from './Welcome.view';
import {useAuth} from '@utils/oidc-auth-wrapper';

function WelcomeContainer({
  isButtonVisible,
  next,
  setIsButtonVisible,
  setSelectedOption,
  form,
}: WelcomeContainerProps) {
  const {user} = useAuth();
  useEffect(() => form.resetFields(), []);
  return (
    <WelcomeView
      setIsButtonVisible={setIsButtonVisible}
      isButtonVisible={isButtonVisible}
      setSelectedOption={setSelectedOption}
      next={next}
      firstName={user?.firstName || ''}
    />
  );
}

export default WelcomeContainer;
