import {ContentType, SubmissionType} from './../enums';
import {CustomProgramContentVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramContentVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramContentVMMockData(
  args?: Partial<CustomProgramContentVM>
): CustomProgramContentVM {
  return {
    allowPeerVisibility: faker.datatype.boolean(),
    attachmentGuids: [faker.word.noun()],
    contentType: ContentType.Text,
    description: faker.lorem.lines(),
    descriptionAttachmentGuids: [faker.lorem.lines()],
    discussionId: String(faker.datatype.number()), // optional
    dueDateTimeZone: faker.address.timeZone(), // optional
    dueDateUtc: String(faker.date.past()), // optional
    endDateUtc: String(faker.date.past()), // optional
    endTime: String(faker.date.past()), // optional
    eventDate: String(faker.date.past()), // optional
    eventLink: faker.internet.url(), // optional
    eventTimeZone: faker.address.timeZone(), // optional
    id: faker.datatype.number(),
    isSubmissionTypeEditable: faker.datatype.boolean(),
    order: faker.datatype.number(),
    startDateUtc: String(faker.date.past()), // optional
    startTime: String(faker.date.past()), // optional
    submissionType: SubmissionType.Text, // optional
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramContentVMMockData: CustomProgramContentVM = {
  allowPeerVisibility: true,
  attachmentGuids: ['string'],
  contentType: ContentType.Text,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  descriptionAttachmentGuids: [
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  ],
  discussionId: '1', // optional
  dueDateTimeZone: 'Asia/Dubai', // optional
  dueDateUtc: '2023-03-29T22:17:19.738072', // optional
  endDateUtc: '2023-03-29T22:17:19.738072', // optional
  endTime: '2023-03-29T22:17:19.738072', // optional
  eventDate: '2023-03-29T22:17:19.738072', // optional
  eventLink: 'https://this-person-does-not-exist.com/en', // optional
  eventTimeZone: 'Asia/Dubai', // optional
  id: 1,
  isSubmissionTypeEditable: true,
  order: 123,
  startDateUtc: '2023-03-29T22:17:19.738072', // optional
  startTime: '2023-03-29T22:17:19.738072', // optional
  submissionType: SubmissionType.Text, // optional
  title: 'Advanced Web Design',
};
