import {ReactElement, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import ContextMenuButton from '@blocks/ContextMenu';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {
  BookOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import {formatCurrency} from '@utils/moneyUtils';
import {
  CurrencyCode,
  LearningResourceType,
  UserResourceStatus,
} from '@generated/enums';
import {mapLearningResourceTypeToString} from '@utils/enumMapping/LearningResourceTypeMapper';
import {COLORS} from '@utils/constants';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  ReimbursementSubtitle,
  StyledButton,
} from '@blocks/userPlanCard/UserPlanCard';
import ReimbursementDrawerContainer from '@blocks/reimbursementDrawer/ReimbursementDrawer.container';
import {UserDetailsVM} from '@generated/interfaces';
import {Divider} from 'antd';
import ReimbursementProgressBar from '@blocks/ReimbursementProgressBar/ReimbursementProgressBar';
import {
  showUnspentFundsDisclaimer,
  UnspentFundsDisclaimer,
} from '@blocks/UnspentFundsDisclaimer/UnspentFundsDisclaimer';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.article<{isReimbursement?: boolean}>`
  background: #ffffff;
  box-shadow: ${COLORS.BoxShadowStandard};
  border-radius: 8px;
  padding: ${({isReimbursement}) => (isReimbursement ? '16' : '24')}px;
  width: ${({isReimbursement}) => (isReimbursement ? '328' : '264')}px;
  height: 100%;
`;

export const Section = styled.div`
  margin-bottom: 16px;
`;

const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

const HeaderSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
`;

const Label = styled.div`
  font-weight: 700;
  font-size: 0.75rem;
  color: ${COLORS.Neutral600};
`;

const Value = styled.div`
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

export const Data = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Neutral950};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 140px;
`;

const ResubmitRequestButton = styled.button`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Blue800};
  cursor: pointer;
  margin-left: 6px;
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue800};
  }
`;

const ReimbursementSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StatusContainer = styled.div<{isReimbursement?: boolean}>`
  ${({isReimbursement}) =>
    isReimbursement
      ? ` 
      padding: 8px;
      border-radius: 8px;
      background-color: ${COLORS.Neutral50};
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      `
      : ''}
`;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

const getTagProps = (
  status: UserResourceStatus,
  additionalFundsRequested?: boolean,
  daysUntilExpiration?: number
): {label: string; tagStyle: TagStyles} => {
  switch (status) {
    case UserResourceStatus.Requested:
    case UserResourceStatus.Pending:
      if (additionalFundsRequested) {
        return {
          label: i18n.t(k.FUND__REQUESTED).toLocaleUpperCase(),
          tagStyle: TagStyles.Yellow,
        };
      } else {
        return {
          label: i18n.t(k.STATUS__AWAITING_APPROVAL).toLocaleUpperCase(),
          tagStyle: TagStyles.Yellow,
        };
      }
    case UserResourceStatus.Approved:
      if (daysUntilExpiration !== null && daysUntilExpiration < 0) {
        return {
          label: i18n.t(k.STATUS__EXPIRED).toLocaleUpperCase(),
          tagStyle: TagStyles.Red,
        };
      } else if (additionalFundsRequested) {
        return {
          label: i18n.t(k.FUND__REQUESTED).toLocaleUpperCase(),
          tagStyle: TagStyles.Yellow,
        };
      } else {
        return {
          label: i18n.t(k.STATUS__APPROVED).toLocaleUpperCase(),
          tagStyle: TagStyles.Green,
        };
      }
    case UserResourceStatus.Complete:
      return {
        label: i18n.t(k.STATUS__COMPLETE).toLocaleUpperCase(),
        tagStyle: TagStyles.Green,
      };
    case UserResourceStatus.Rejected:
      return {
        label: i18n.t(k.STATUS__DECLINED).toLocaleUpperCase(),
        tagStyle: TagStyles.Red,
      };
    default:
      return {
        label: i18n.t(k.STATUS__UNKNOWN).toLocaleUpperCase(),
        tagStyle: TagStyles.LightGrey,
      };
  }
};

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

export const COMPLETE_BUTTON_STATES = new Set([
  UserResourceStatus.Approved,
  UserResourceStatus.Purchased,
]);

export const REMOVE_BUTTON_STATES = new Set([
  UserResourceStatus.Pending,
  UserResourceStatus.Requested,
  UserResourceStatus.Approved,
  UserResourceStatus.Rejected,
  UserResourceStatus.Cancelled,
]);

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

const ICON_SIZE = 36;
const IconContainer = styled.div`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export interface ResourceTypeIconProps {
  resourceType: LearningResourceType;
}

export function ResourceTypeIcon({resourceType}: ResourceTypeIconProps) {
  let Icon: ReactElement;
  let background: string;
  switch (resourceType) {
    case LearningResourceType.Book:
      background = COLORS.Yellow200;
      Icon = (
        <BookOutlined aria-hidden="true" style={{color: COLORS.Yellow500}} />
      );
      break;
    case LearningResourceType.Conference:
      background = COLORS.Blue50;
      Icon = (
        <TeamOutlined aria-hidden="true" style={{color: COLORS.Blue800}} />
      );
      break;
    case LearningResourceType.Subscription:
      background = COLORS.Green200;
      Icon = (
        <SyncOutlined aria-hidden="true" style={{color: COLORS.Green600}} />
      );
      break;
    case LearningResourceType.Other:
      background = COLORS.Red100;
      Icon = (
        <ExperimentOutlined aria-hidden="true" style={{color: COLORS.Red500}} />
      );
      break;
    default:
      Icon = null;
  }

  return <IconContainer style={{background}}>{Icon}</IconContainer>;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ResourcePlanCardProps {
  approvedAmount: number;
  additionalRequestedAmount: number;
  currency: CurrencyCode;
  daysUntilExpiration?: number;
  id?: number;
  onClickMarkComplete: () => void;
  onClickRemoveResource: () => void;
  onClickRequestAdditionalResources: (id?: number) => void;
  onClickResubmitRequest: () => void;
  resourceType: LearningResourceType;
  showMarkCompleteOption: boolean;
  showRemoveResourceOption: boolean;
  status: UserResourceStatus;
  title: string;
  reimbursementApprovedAmount?: number;
  reimbursementRequestedAmount?: number;
  userCurrency: CurrencyCode;
  isReimbursementPlan?: boolean;
  userQueryData: UserDetailsVM;
  handleShowReimbursementModal?: () => void;
}

function ResourcePlanCard({
  additionalRequestedAmount,
  reimbursementApprovedAmount,
  reimbursementRequestedAmount,
  approvedAmount,
  currency,
  daysUntilExpiration,
  onClickMarkComplete,
  onClickRemoveResource,
  onClickRequestAdditionalResources,
  onClickResubmitRequest,
  resourceType,
  showMarkCompleteOption,
  showRemoveResourceOption,
  status,
  title,
  id,
  isReimbursementPlan,
  userQueryData,
  handleShowReimbursementModal,
}: ResourcePlanCardProps) {
  const showFundsRequestedState =
    additionalRequestedAmount !== 0 && approvedAmount !== 0;
  // Context Menu
  const contextMenuItems = [];
  const {isFeatureFlagOn} = useFeatureFlags();
  const isLearnInReimbursementsOn = isFeatureFlagOn.LearnInReimbursements;
  const isUnspentFundsOn = isFeatureFlagOn.UnspentFunds;

  const [showReimbursementDrawer, setShowReimbursementDrawer] = useState(false);

  const exchangeRate = useExchangeRate(currency, userQueryData?.currency);
  if (
    status === UserResourceStatus.Approved &&
    !showFundsRequestedState &&
    (!daysUntilExpiration || daysUntilExpiration > -1)
  ) {
    contextMenuItems.push({
      label: i18n.t(k.FUND__REQUEST__ADDITIONAL),
      onClick: () => onClickRequestAdditionalResources(id),
      dataCy: 'RequestAdditionalFunds',
    });
  }
  if (showRemoveResourceOption) {
    contextMenuItems.push({
      label: i18n.t(k.RESOURCE__REMOVE),
      onClick: onClickRemoveResource,
    });
  }
  if (showMarkCompleteOption) {
    contextMenuItems.push({
      label: i18n.t(k.CTA__MARK_AS_COMPLETE),
      onClick: onClickMarkComplete,
    });
  }
  const tagProps = getTagProps(
    status,
    showFundsRequestedState,
    daysUntilExpiration
  );
  const formattedAmount = formatCurrency(
    showFundsRequestedState
      ? approvedAmount
      : approvedAmount || additionalRequestedAmount,
    userQueryData?.currency,
    exchangeRate,
    {decimal: true}
  );

  const contextContainerId = `context-container-${id}`;
  const usersCurrency = userQueryData?.currency;
  return (
    <Container
      aria-labelledby="resource-plan-card-title"
      isReimbursement={isLearnInReimbursementsOn && isReimbursementPlan}>
      {showReimbursementDrawer && (
        <ReimbursementDrawerContainer
          isOpen={showReimbursementDrawer}
          setIsOpen={setShowReimbursementDrawer}
          selectedRequestItem={id}
        />
      )}
      <HeaderSection>
        <div style={{display: 'flex'}}>
          <div style={{marginRight: '8px'}}>
            <ResourceTypeIcon resourceType={resourceType} />
          </div>
          <div style={{maxWidth: '180px'}}>
            <Label>{mapLearningResourceTypeToString(resourceType)}</Label>
            <Data id="resource-plan-card-title" title={title}>
              {title}
            </Data>
          </div>
        </div>
        {!!contextMenuItems.length && (
          <div id={contextContainerId} style={{marginLeft: '8px'}}>
            <ContextMenuButton
              popupContainerId="main-content"
              itemId={id}
              menuItems={contextMenuItems}
              dataCy="PlanItemCardContextMenu"
            />
          </div>
        )}
      </HeaderSection>

      {!(
        isLearnInReimbursementsOn &&
        status == UserResourceStatus.Approved &&
        isReimbursementPlan
      ) && (
        <Section>
          <Label>
            {i18n.t(
              status === UserResourceStatus.Approved || showFundsRequestedState
                ? k.APPROVAL__APPROVED_AMOUNT
                : k.FUND__REQUEST__AMOUNT
            )}
            {showFundsRequestedState && (
              <LearnInTooltip
                title={i18n.t(k.FUND__REQUESTED_ADDITIONAL__FORMAT, {
                  localizedAmount: formatCurrency(
                    additionalRequestedAmount,
                    userQueryData?.currency,
                    exchangeRate,
                    {
                      decimal: true,
                    }
                  ),
                })}>
                <ExclamationCircleOutlined
                  aria-hidden="true"
                  style={{
                    color: COLORS.Yellow500,
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                  }}
                />
              </LearnInTooltip>
            )}
          </Label>
          <Data data-cy="requestAmount">{formattedAmount}</Data>
        </Section>
      )}

      {showUnspentFundsDisclaimer({
        isUnspentFundsOn,
        isLicense: false,
        daysUntilExpiration,
      }) && (
        <div style={{marginBottom: '16px'}}>
          <UnspentFundsDisclaimer daysUntilExpiration={daysUntilExpiration} />
        </div>
      )}

      <StatusContainer
        isReimbursement={isLearnInReimbursementsOn && isReimbursementPlan}>
        <div>
          {!isFeatureFlagOn.NewTags && (
            <Label>{i18n.t(k.STATUS__STATUS)}</Label>
          )}
          <div style={{display: 'flex'}}>
            <LearnInTag {...tagProps} />
            {status === UserResourceStatus.Rejected && (
              <ResubmitRequestButton onClick={onClickResubmitRequest}>
                {i18n.t(k.REQUEST__RESUBMIT)}
              </ResubmitRequestButton>
            )}
          </div>
        </div>
        {isLearnInReimbursementsOn &&
          status == UserResourceStatus.Pending &&
          isReimbursementPlan && (
            <div>
              <Label>{i18n.t(k.FUND__REQUEST__AMOUNT)}</Label>
              <Value style={{textAlign: 'end'}}>
                {formatCurrency(
                  additionalRequestedAmount,
                  userQueryData?.currency,
                  exchangeRate,
                  {
                    decimal: true,
                  }
                )}
              </Value>
            </div>
          )}
      </StatusContainer>
      {isLearnInReimbursementsOn &&
        status == UserResourceStatus.Approved &&
        isReimbursementPlan && (
          <ReimbursementSection>
            {/**Reimbursement Section */}
            <div style={{marginTop: '16px'}}>
              <Label>
                {i18n.t(k.REIMBURSEMENT__REIMBURSED_AMOUNT)}
                {reimbursementApprovedAmount > 0 && (
                  <LearnInTooltip title={i18n.t(k.REIMBURSEMENT__TAKE_A_WHILE)}>
                    {' '}
                    <QuestionCircleOutlined
                      aria-hidden="true"
                      style={{
                        color: COLORS.Neutral600,
                        width: '16px',
                        height: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </LearnInTooltip>
                )}
              </Label>
              <Value data-cy="reimbursementAmount">
                {formatCurrency(
                  reimbursementApprovedAmount || 0,
                  usersCurrency,
                  exchangeRate,
                  {
                    usersCurrency,
                  }
                )}
              </Value>
            </div>
            <div style={{marginTop: '16px'}}>
              <Label>{i18n.t(k.APPROVAL__APPROVED_AMOUNT)}</Label>
              <Value
                style={{textAlign: 'end'}}
                data-cy="approvedReimbursementAmount">
                {formatCurrency(
                  approvedAmount || 0,
                  usersCurrency,
                  exchangeRate,
                  {
                    usersCurrency,
                  }
                )}
              </Value>
            </div>
            <div style={{width: '100%'}}>
              <ReimbursementProgressBar
                pending={reimbursementRequestedAmount}
                reimbursed={reimbursementApprovedAmount}
                approved={approvedAmount}
              />
              <ReimbursementSubtitle data-cy="pendingReimbursementAmount">
                {formatCurrency(
                  reimbursementRequestedAmount || 0,
                  usersCurrency,
                  exchangeRate,
                  {usersCurrency}
                )}{' '}
                {i18n.t(k.REIMBURSEMENT__PENDING)}
              </ReimbursementSubtitle>
            </div>
            <StyledDivider />

            <StyledButton
              style={{color: COLORS.Blue800, marginRight: '8px'}}
              onClick={(e) => {
                e.stopPropagation();
                handleShowReimbursementModal();
              }}>
              {i18n.t(k.REIMBURSEMENT__SUBMIT)}
            </StyledButton>
          </ReimbursementSection>
        )}
    </Container>
  );
}

export default ResourcePlanCard;
