import {CheckOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';

const TOGGLE_SIZE = 18;
const SectionCompleteToggleButton = styled.button<{checked: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${TOGGLE_SIZE}px;
  width: ${TOGGLE_SIZE}px;
  border: 2px solid
    ${({checked}) => (checked ? COLORS.Green600 : COLORS.Neutral600)};
  border-radius: 100%;
  background: ${({checked}) => checked && COLORS.Green600};
  box-sizing: border-box;
  flex-shrink: 0;
`;

export interface ISectionCompleteToggleProps {
  checked: boolean;
}

export function SectionCompleteToggle({checked}: ISectionCompleteToggleProps) {
  return (
    <SectionCompleteToggleButton
      checked={checked}
      onClick={(e) => {
        e.stopPropagation();
      }}
      aria-label={i18n.t(k.STATUS__COMPLETED)}>
      {checked && (
        <CheckOutlined
          aria-hidden="true"
          style={{color: 'white', fontWeight: 500, fontSize: '.7em'}}
        />
      )}
    </SectionCompleteToggleButton>
  );
}
