import './TransactionCard.scss';
import {CreditCardTransactionVM} from '@models/api/payment/viewModels';
import {useQuery} from 'react-query';
import {Col, Row, Skeleton} from 'antd';
import TransactionList from '../transactionList/TransactionList';
import {useCallback} from 'react';
import {getUserCardTransactionsRq} from '@store/apiEndpoints';
import {simpleQueryFn} from '@store/queryClient';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import NoDataImage from '@blocks/NoDataImage';

interface Props {
  planItemId: number | string;
}

const TransactionCard = ({planItemId}: Props) => {
  const userCardTransactionsRq = getUserCardTransactionsRq(planItemId);
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );
  const {data, isError, isLoading, isSuccess} = useQuery<
    CreditCardTransactionVM[]
  >(userCardTransactionsRq.queryKey, () =>
    simpleQueryFn(userCardTransactionsRq.path)
  );

  const renderContent = useCallback(() => {
    if (isLoading && !data) {
      return <Skeleton />;
    } else if (isError || !DataTransfer) {
      return <NoDataImage />;
    } else {
      return (
        <TransactionList
          data={data}
          exchangeRate={exchangeRate}
          usersCurrency={getUserQuery.data?.currency}
        />
      );
    }
  }, [data, isLoading, isError]);

  if (isLoading || isError) {
    return null;
  }

  if (isSuccess && data) {
    return (
      <>
        <Row justify={'center'} align={'middle'} className={'transaction-list'}>
          <Col span={24}>{renderContent()}</Col>
        </Row>
      </>
    );
  }
};

export default TransactionCard;
