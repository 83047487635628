import {IAcademyLevelBasicInfo} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: IAcademyLevelBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIAcademyLevelBasicInfoMockData(
  args?: Partial<IAcademyLevelBasicInfo>
): IAcademyLevelBasicInfo {
  return {
    description: faker.lorem.lines(),
    id: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IAcademyLevelBasicInfoMockData: IAcademyLevelBasicInfo = {
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  id: 1,
  title: 'Advanced Web Design',
};
