import {i18n, k} from '@i18n/translate';
import {useQuery} from 'react-query';
import {Route, Routes} from 'react-router-dom';
import {getTeamInsightsOverviewRq} from '@store/apiEndpoints';
import {simpleQueryFn} from '@store/queryClient';
import {DrilldownContainer} from './Drilldown';
import {TeamInsightsOverviewVM} from '@models/serverModels';
import {TeamInsightsOverview} from './TeamInsightsOverview';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import useSetPageTitle from '@hooks/useSetPageTitle';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useAuth} from '@utils/oidc-auth-wrapper';

export const TeamInsightsContainer = ({title}: {title?: string}) => {
  useSetPageTitle(title);
  const teamInsightsRq = getTeamInsightsOverviewRq();
  const teamInsightsQuery = useQuery<TeamInsightsOverviewVM>(
    teamInsightsRq.queryKey,
    () =>
      simpleQueryFn(teamInsightsRq.path, (data: TeamInsightsOverviewVM) => {
        data.employeeOverviewVms = data.employeeOverviewVms.filter(
          (datum) => datum.employeeName !== ''
        );
        return data;
      })
  );

  const {user} = useAuth();
  const exchangeRate = useExchangeRate(MONEY_DEFAULT.currency, user?.currency);
  const {isFeatureFlagOn} = useFeatureFlags();

  return (
    <div>
      <PageTitleBar title={i18n.t(k.TEAM__INSIGHTS)} />
      <Routes>
        <Route
          path="/"
          element={
            <TeamInsightsOverview
              title={i18n.t(k.TEAM__INSIGHTS)}
              data={teamInsightsQuery.data}
              user={user}
              exchangeRate={exchangeRate}
              isFeatureFlagOn={isFeatureFlagOn}
            />
          }
        />
        <Route
          path="/:userId/*"
          element={<DrilldownContainer title={i18n.t(k.TEAM__INSIGHTS)} />}
        />
      </Routes>
    </div>
  );
};
