import {
  Container,
  ParticipantName,
  ParticipantNameCell,
} from './AcademyParticipantsTable.styled';
import {AcademyParticipantsTableViewProps} from './AcademyParticipantsTable.interfaces';
import LearnInTable, {TooltipCol} from '@components/reusable/LearnInTable';
import {Avatar} from 'antd';
import {getAvatarColorwaysStyles} from '@utils/color';
import translate from '@i18n/translate';
import moment from 'moment';
import {dateSorter, stringSorter} from '@utils/tableUtils';
import {getInitialsOfName} from '@components/providers/utils';
import {pxToRem} from '@utils/styleMixins';

function AcademyParticipantsTableView({
  dataSource,
  canShowEmployeeEmail,
}: AcademyParticipantsTableViewProps) {
  const columns = [
    canShowEmployeeEmail
      ? TooltipCol({
          title: translate('GENERIC__NAME'),
          width: 360,
          tooltipDataIndex: 'email',
          renderData: (data) => {
            return (
              <ParticipantNameCell>
                <Avatar
                  style={{
                    ...getAvatarColorwaysStyles(data?.length),
                    marginRight: '8px',
                    flexShrink: 0,
                    fontWeight: 700,
                    fontSize: pxToRem(12, 16),
                  }}>
                  {getInitialsOfName(data)}
                </Avatar>{' '}
                <ParticipantName>{data}</ParticipantName>
              </ParticipantNameCell>
            );
          },
        })
      : {
          title: translate('GENERIC__NAME'),
          dataIndex: 'name',
          key: 'name',
          width: 220,
          render(name: string) {
            return (
              <ParticipantNameCell>
                <Avatar
                  style={{
                    ...getAvatarColorwaysStyles(name?.length),
                    marginRight: '8px',
                    flexShrink: 0,
                    fontWeight: 700,
                    fontSize: pxToRem(12, 16),
                  }}>
                  {getInitialsOfName(name)}
                </Avatar>{' '}
                {name}
              </ParticipantNameCell>
            );
          },
          sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        },
    {
      title: translate('GENERIC__ROLE'),
      dataIndex: 'role',
      key: 'role',
      render: (role: any) => role,
      sorter: stringSorter('role'),
    },
    {
      title: translate('DATE__STARTED'),
      dataIndex: 'dateAdded',
      key: 'dateAdded',
      render: (dateAdded: any) => moment(dateAdded).format('MM/DD/YY'),
      sorter: dateSorter('dateAdded'),
    },
  ].filter(Boolean);
  return (
    <Container>
      <LearnInTable dataSource={dataSource} columns={columns} />
    </Container>
  );
}

export default AcademyParticipantsTableView;
