import './PreApprovalDrawer.scss';
import {useState, useEffect} from 'react';
import {useMutation, useQuery} from 'react-query';
import {Form} from 'antd';
import {
  FinanceDetailsVM,
  PreApprovalAmountBalanceVM,
  PreApprovalApplication,
  ProgramDetailsVM,
  RequestPreApprovalPayload,
  UserPlanTemplate,
} from '@models/serverModels';
import {
  ApprovalStatus,
  CurrencyCode,
  PlanItemType,
  PreApprovalQuestionInputType,
} from '@generated/enums';
import {
  getFinanceIncentiveDetailRq,
  getLicenseRq,
  getPersonalLearningBudgetLimitRq,
  getPreApprovalFormRq,
  getRequestAcademyItemPreApprovalRm,
  getRequestItemPreApprovalRm,
  getRequestPreApprovalRm,
  getUploadAttachmentRm,
} from '@store/apiEndpoints';
import {getProgramDetailsRq} from '@store/apiEndpoints/program/queries';
import queryClient, {
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import {getPlanItemQueryInvalidateKey} from '@utils/query-invalidation-helper';
import {APPROVAL_AUTOPOPULATE_MULTIPLIER} from '@utils/constants';
import PreApprovalDrawer from './PreApprovalDrawer';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  applyCurrencySelection,
  applySubscriptionSelection,
  getCostField,
  getFrequencyDropdown,
  mapResponsesToApplication,
  useGetMaxRequestAmount,
} from '@utils/preApprovalRequest';
import {
  exchangeAmount,
  getExchangeRateBySelection,
  MONEY_DEFAULT,
  REQUEST_CURRENCY_ID,
} from '@utils/moneyUtils';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {UserPlanItemStatusString} from '@models/clientEnums';
import {useDeleteAttachment} from '@hooks/apiEndpoints/attachments/mutations';
import useChunkUpload from '@hooks/useChunkUpload';
import {useStateNotReadyToDisplay} from './PreApprovalDrawer.hooks';
import {PlanItem} from '@models/clientModels';
import {useFinanceLearningBudgetsQuery} from '@generated/hooks';
import {useParams} from 'react-router-dom';
import {AddUpdateLicenseVM} from '@components/admin/pages/licenses/components/AddUpdateLicenseDrawer/AddUpdateLicenseDrawer.types';

/*
|--------------------------------------------------------------------------
| Wrapper
|--------------------------------------------------------------------------
*/

export interface PreApprovalDrawerContainerProps {
  itemId: number;
  onCancel: () => void;
  onOk: () => void;
  planItemId?: number; // finance and time incentives are added to plan before requesting approval
  planItemType: PlanItem;
  visible: boolean;
  currency?: CurrencyCode;
  licenseId?: number;
  hasLicense?: boolean;
  status?: UserPlanItemStatusString;
}

export default function PreApprovalDrawerContainer({
  itemId,
  onCancel,
  onOk,
  planItemId,
  planItemType,
  visible,
  licenseId,
  currency,
  hasLicense,
  status,
}: PreApprovalDrawerContainerProps) {
  const [form] = Form.useForm();
  const [selectedCheckboxOptions, setSelectedCheckboxOptions] = useState({});
  const [total, setTotal] = useState(null);

  const {isFeatureFlagOn} = useFeatureFlags();
  const isRequestWithCurrencyOn = isFeatureFlagOn.RequestWithCurrency;
  const isHideHeaderOnResubmitOn = isFeatureFlagOn.HideHeaderOnResubmit;
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [filesArrayLength, setFilesArrayLength] = useState(0);
  const [attachmentIds, setAttachmentIds] = useState([]);

  const [filesProgress, setFilesProgress] = useState([]);

  const {chunkUpload, multipleUploadProgress, fileId} = useChunkUpload({
    query: getUploadAttachmentRm,
    customizableLearningCategory: PlanItemType.Program,
  });
  useEffect(() => {
    //check if a file was added and not deleted
    if (filesToUpload.length > 0 && filesArrayLength < filesToUpload.length) {
      const idx = filesToUpload.length - 1;
      const latestFile = filesToUpload[idx];
      setFilesArrayLength(filesToUpload.length);
      setFilesProgress((filesProgress) => {
        const progressBars = [...filesProgress];
        progressBars[idx] = 1;
        return progressBars;
      });
      chunkUpload(latestFile, {index: idx});
    }
    //uploadAttachments
  }, [chunkUpload, filesArrayLength, filesToUpload, filesToUpload.length]);

  //update progress bars
  useEffect(() => {
    const idx = multipleUploadProgress.index;
    const progressBars = filesProgress;
    if (idx !== '') {
      progressBars[idx] = Math.round(multipleUploadProgress.progress);
    }
    setFilesProgress(progressBars);
  }, [filesProgress, multipleUploadProgress]);

  useEffect(() => {
    if (fileId.length) {
      setAttachmentIds([...attachmentIds, fileId]);
    }
  }, [attachmentIds, fileId]);

  const deleteAttachmentMutation = useDeleteAttachment();

  const urlParams = useParams();

  const deleteFunction = (idx: any) => {
    //Attachment delete API
    deleteAttachmentMutation.mutate({
      attachmentId: attachmentIds[idx],
    });
    setFilesProgress([
      ...filesProgress.slice(0, idx),
      ...filesProgress.slice(idx + 1, filesProgress.length),
    ]);
    setAttachmentIds([
      ...attachmentIds.slice(0, idx),
      ...attachmentIds.slice(idx + 1, attachmentIds.length),
    ]);
    setFilesArrayLength(filesToUpload.length - 1);
  };

  // Data Fetching
  const programDetailsRq = getProgramDetailsRq(itemId);
  const getProgramDetailsQuery = useQuery<ProgramDetailsVM>(
    programDetailsRq.queryKey,
    () => simpleQueryFn(programDetailsRq.path),
    {
      enabled: planItemType === PlanItemType.Program,
    }
  );

  const getUserQuery = useGetUserQuery();

  const exchangeRateFromDefault = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const exchangeRate = useExchangeRate(
    currency || MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: isRequestWithCurrencyOn,
  });
  const exchangeRatesList = getCurrencyExchangeQuery?.data;

  const financeIncentiveDetailsRq = getFinanceIncentiveDetailRq(itemId);
  const getFinanceIncentiveDetailQuery = useQuery<FinanceDetailsVM>(
    financeIncentiveDetailsRq.queryKey,
    () => simpleQueryFn(financeIncentiveDetailsRq.path),
    {
      enabled: planItemType === PlanItemType.Finance,
    }
  );

  const preApprovalFormRq = getPreApprovalFormRq(
    licenseId || hasLicense ? PlanItemType.ProgramLicense : planItemType,
    itemId,
    planItemId
  );
  const getPreApprovalFormQuery = useQuery<PreApprovalApplication>(
    preApprovalFormRq.queryKey,
    () => simpleQueryFn(preApprovalFormRq.path),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.status === ApprovalStatus.Rejected) {
          const question = data.application.find(
            (q) => q.inputType === PreApprovalQuestionInputType.Multiselect
          );
          if (!!question) {
            setSelectedCheckboxOptions(question.response.split(','));
            form.setFieldsValue({[question.id]: question.response.split(',')});
          }
        }
      },
    }
  );

  const preApprovalFormAmountBalanceRq = getPersonalLearningBudgetLimitRq();
  const preApprovalFormAmountBalanceQuery =
    useQuery<PreApprovalAmountBalanceVM | null>(
      preApprovalFormAmountBalanceRq.queryKey,
      () => simpleQueryFn(preApprovalFormAmountBalanceRq.path),
      {
        enabled: planItemType === PlanItemType.Program,
      }
    );
  const requestItemPreApprovalMutation = useMutation(
    ({
      planItemId,
      preApprovalApplication,
    }: {
      planItemId: number;
      preApprovalApplication: PreApprovalApplication;
    }) => {
      const requestItemPreApprovalRm = getRequestItemPreApprovalRm(
        preApprovalApplication,
        planItemId
      );
      return simpleMutationFn<UserPlanTemplate>(
        requestItemPreApprovalRm.path,
        requestItemPreApprovalRm.payload
      );
    },
    {
      onSuccess: () => {
        notify.applyPlanSuccessful();
        queryClient.refetchQueries(getPlanItemQueryInvalidateKey(planItemType));
      },
      onError: () => {
        notify.applyPlanError();
      },
    }
  );

  const requestPreApprovalMutation = useMutation(
    (args: RequestPreApprovalPayload) => {
      const requestPreApproval = !urlParams?.academyId
        ? getRequestPreApprovalRm(args)
        : getRequestAcademyItemPreApprovalRm(args, Number(urlParams.academyId));
      return simpleMutationFn<PreApprovalApplication>(
        requestPreApproval.path,
        requestPreApproval.payload
      );
    },
    {
      onSuccess: async (data) => {
        await queryClient.refetchQueries(
          getPlanItemQueryInvalidateKey(planItemType)
        );
        await simpleInvalidateExactQueryFn(preApprovalFormRq.queryKey);
        switch (data.status) {
          case ApprovalStatus.Pending:
            return notify.applyPlanPreApprovedSaveSuccessful();
          case ApprovalStatus.Requested:
            return notify.applyPlanSuccessful();
          case ApprovalStatus.Approved:
            return notify.applyPlanPreApprovedSuccessful();
          default:
            return notify.applyPlanSuccessful();
        }
      },
      onError: () => {
        notify.applyPlanError();
      },
    }
  );

  const licenseRq = getLicenseRq(licenseId);
  const getLicenseQuery = useQuery<AddUpdateLicenseVM>(
    licenseRq.queryKey,
    () => simpleQueryFn(licenseRq.path),
    {
      enabled: !!licenseId,
    }
  );

  const financeLearningBudgetsQuery = useFinanceLearningBudgetsQuery(null);

  // There should only be at most 1 field that captures the program cost information
  const costField = getCostField(getPreApprovalFormQuery.data?.application);

  const maxRequestAmount = useGetMaxRequestAmount({
    itemId,
    planItemId,
    planItemType,
    exchangeRate,
    enabled: !licenseId,
  });

  // Validate cost field on mount to show any request maximum warnings or errors immediately
  useEffect(() => {
    if (!!costField?.id) {
      form.validateFields([String(costField?.id)]);
    }
  }, [
    form,
    getPreApprovalFormQuery.isSuccess,
    getProgramDetailsQuery.isSuccess,
    preApprovalFormAmountBalanceQuery.isSuccess,
    costField?.id,
  ]);

  // Do not render the drawer until all data has loaded to ensure initial form values are set correctly
  const stateNotReady = useStateNotReadyToDisplay({
    getPreApprovalFormQuery,
    getProgramDetailsQuery,
    preApprovalFormAmountBalanceQuery,
    getFinanceIncentiveDetailQuery,
    planItemType,
    licenseId,
  });
  if (stateNotReady) return null;

  // Event Handlers
  const handleChangeSelect = async (selectedOption: string) => {
    const selectedCost = costField?.options?.find(
      ({id}) => id === selectedOption
    );
    if (!selectedCost) return;
    form.setFieldsValue({
      // Populate field with the cost of the program chosen + padding, but at most the financial incentive max allowed
      [costField?.id]: Math.min(
        exchangeAmount(
          Math.round(
            Number(selectedCost.responseValue) *
              APPROVAL_AUTOPOPULATE_MULTIPLIER
          ),
          exchangeRate
        ),
        maxRequestAmount || 1
      ),
    });
  };

  const handleChangeCheckbox = (id: any, selectedOptions: any) => {
    setSelectedCheckboxOptions((prev) => {
      return {...prev, [id]: selectedOptions};
    });
  };

  const onClose = () => {
    form.resetFields();
    onCancel();
  };

  const frequencyDropdown = getFrequencyDropdown(
    getPreApprovalFormQuery?.data?.application
  );

  const handleClickSubmit = async () => {
    try {
      await form.validateFields();
      const data = form.getFieldsValue();
      const applicationId = getPreApprovalFormQuery.data.id;
      const application = getPreApprovalFormQuery.data.application;

      applyCurrencySelection(
        application,
        data?.[REQUEST_CURRENCY_ID],
        getUserQuery.data?.currency
      );
      applySubscriptionSelection(application, frequencyDropdown, data);

      // If a currency is selected, use that exchange rate
      const requestExchangeRate = getExchangeRateBySelection(
        data?.[REQUEST_CURRENCY_ID],
        exchangeRatesList,
        exchangeRate
      );

      const appArray = mapResponsesToApplication({
        application,
        data,
        requestExchangeRate,
        total,
      });

      if (!planItemId) {
        await requestPreApprovalMutation.mutateAsync({
          optionId: itemId,
          planItemType: planItemType as PlanItemType,
          applicationDetails: {
            id: applicationId,
            status: ApprovalStatus.Requested,
            application: appArray,
          },
          attachmentIds: attachmentIds,
        });
      } else {
        await requestItemPreApprovalMutation.mutateAsync({
          planItemId,
          preApprovalApplication: {
            id: applicationId,
            status: ApprovalStatus.Requested,
            application: appArray,
            attachmentIds: attachmentIds,
          },
        });
      }
      onOk();
    } catch (error) {
      console.error(error);
    }
  };

  const appShowsRejected =
    getPreApprovalFormQuery.data?.status === ApprovalStatus.Rejected;
  const passedRejected = status === UserPlanItemStatusString.Rejected;
  // e.g. User Plan passes in the status since appShowsRejected is incorrectly success. Remove when status is consistently correct from that API.
  const isApplicationRejected = appShowsRejected || passedRejected;

  return (
    <PreApprovalDrawer
      frequencyDropdown={frequencyDropdown}
      isFormDisabled={
        requestPreApprovalMutation.isLoading ||
        requestItemPreApprovalMutation.isLoading ||
        getUserQuery.isLoading
      }
      isSubmitButtonLoading={
        requestPreApprovalMutation.isLoading ||
        requestItemPreApprovalMutation.isLoading ||
        getUserQuery.isLoading ||
        filesProgress.some((n) => n < 100)
      }
      isTextAreaDisabled={
        getPreApprovalFormQuery.data?.status !== ApprovalStatus.Pending &&
        getPreApprovalFormQuery.data?.status !== ApprovalStatus.Rejected
      }
      financeType={preApprovalFormAmountBalanceQuery.data?.financeType}
      form={form}
      setTotal={setTotal}
      total={total}
      exchangeRateFromDefault={exchangeRateFromDefault}
      exchangeRate={exchangeRate}
      exchangeAmount={exchangeAmount}
      formItems={getPreApprovalFormQuery.data?.application || []}
      onChangeCheckbox={handleChangeCheckbox}
      onChangeSelect={handleChangeSelect}
      onClickSubmit={handleClickSubmit}
      onClose={onClose}
      planItemType={planItemType as PlanItemType}
      programCost={getProgramDetailsQuery.data?.cost}
      subscriptionCost={getProgramDetailsQuery.data?.monthlySubscriptionCost}
      programCurrency={currency}
      programCostFeesMultiplier={APPROVAL_AUTOPOPULATE_MULTIPLIER}
      rejectedNotes={getPreApprovalFormQuery.data?.rejectedNotes}
      selectedCheckboxOptions={selectedCheckboxOptions}
      visible={visible}
      license={
        hasLicense || !!getLicenseQuery.data
        //changed to make pre approval drawers consistent in programs list and details since this prop is used as a boolean anyway
      }
      licenseData={getLicenseQuery?.data}
      user={getUserQuery.data}
      isApplicationRejected={isApplicationRejected}
      isHideHeaderOnResubmitOn={isHideHeaderOnResubmitOn}
      filesProgress={filesProgress}
      filesToUpload={filesToUpload}
      setFilesToUpload={setFilesToUpload}
      deleteFunction={deleteFunction}
      balance={preApprovalFormAmountBalanceQuery.data?.amountBalance}
      financeAmountType={financeLearningBudgetsQuery?.data?.financeAmountType}
    />
  );
}
