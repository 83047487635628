import {ReactMutateRequest} from './../utils';
import {
  CustomProgramCohortPayload,
  CustomProgramSectionPayload,
  CustomProgramUserPermissionPayload,
  DeleteCustomProgramSectionPayload,
  IGiveRestrictedCustomProgramAccessPayload,
  UpdateCustomProgramContentSectionPayload,
  UpdateCustomProgramStatusAsync,
  UpdateItemsOrderPayload,
  UserPermissionUpdatePayload,
  CustomProgramPayload,
  NewFeedbackPayload,
  NewCustomProgramContentPayload,
  UpdateCustomProgramContentPayload,
  CustomProgramEmailMessagePayload,
  RemoveRestrictedCustomProgramAccessPayload,
} from '@models/serverModels';
import {NEW_BASE_PATH, BASE_PATH} from '.';

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/

const encodeCustomProgramSectionPayload = (
  payload: CustomProgramSectionPayload
) => {
  return {
    ...payload,
    ...{title: encodeURIComponent(payload.title)},
  };
};

const encodeCustomProgramPayload = (
  payload: CustomProgramPayload
): CustomProgramPayload => {
  return {
    ...payload,
    ...{
      title: encodeURIComponent(payload.title),
      shortDescription: encodeURIComponent(payload.shortDescription),
      longDescription: encodeURIComponent(payload.longDescription),
    },
  };
};

const encodeCustomProgramContentPayload = (
  payload: NewCustomProgramContentPayload | UpdateCustomProgramContentPayload
) => {
  const encodedLinksPayload = payload.links.map((value) => ({
    ...value,
    ...{url: encodeURIComponent(value.url)},
  }));
  return {
    ...payload,
    ...{title: encodeURI(payload.title)},
    ...{description: encodeURI(payload.description)},
    ...{links: encodedLinksPayload},
  };
};

/*
|--------------------------------------------------------------------------
| 
|--------------------------------------------------------------------------
*/

export const getAddCustomProgramSectionRm = (
  payload: CustomProgramSectionPayload
) => {
  const path = [BASE_PATH, 'add-custom-program-section'].join('/');
  return {
    path,
    payload: encodeCustomProgramSectionPayload(payload),
  };
};

export const getUpdateCustomProgramSectionRm = (
  payload: CustomProgramSectionPayload
) => {
  const path = [BASE_PATH, 'update-custom-program-section'].join('/');
  return {
    path,
    payload: encodeCustomProgramSectionPayload(payload),
  };
};

export const getReorderCustomProgramSectionRm = (
  payload: UpdateItemsOrderPayload
) => {
  const path = [BASE_PATH, 're-order-custom-program-sections'].join('/');
  return {
    path,
    payload,
  };
};

export const getUpdateCustomProgramContentSectionRm = (
  payload: UpdateCustomProgramContentSectionPayload
) => {
  const path = [BASE_PATH, 'update-content-section'].join('/');
  return {
    path,
    payload,
  };
};

export const getCustomProgramSearchRm = (
  keywords?: string,
  programTypeIds?: (string | number)[],
  durationIds?: (string | number)[],
  skillIds?: (string | number)[]
): ReactMutateRequest => {
  const searchParams = new URLSearchParams();
  if (!!keywords?.length) {
    searchParams.append('keywords', keywords);
  }
  programTypeIds?.forEach((id) => {
    searchParams.append('programTypeIds', String(id));
  });
  durationIds?.forEach((id) => {
    searchParams.append('durationIds', String(id));
  });
  skillIds?.forEach((id) => {
    searchParams.append('skillIds', String(id));
  });
  const baseUrl = [BASE_PATH, 'search'].join('/');
  return {
    path: [baseUrl, searchParams.toString()].join('?'),
    payload: {},
  };
};

export const getUpdateCustomProgramStatusAsyncRm = (
  payload: UpdateCustomProgramStatusAsync
): ReactMutateRequest => {
  const path = [BASE_PATH, 'update-custom-program-status'].join('/');
  return {
    path,
    payload,
  };
};

export const getGiveRestrictedCustomProgramAccessRm = (
  payload: IGiveRestrictedCustomProgramAccessPayload
): ReactMutateRequest => {
  const path = [NEW_BASE_PATH, payload.id, 'users'].join('/');
  return {
    path,
    payload: payload.users,
  };
};

export const getRemoveRestrictedProgramAccessRm = (
  payload: RemoveRestrictedCustomProgramAccessPayload
): ReactMutateRequest => {
  const path = [
    NEW_BASE_PATH,
    payload.customProgramId,
    'users',
    payload.userCompanyId,
  ].join('/');
  return {
    path,
    payload: {},
  };
};

export const getCreateCustomProgramCohortRm = (
  payload: CustomProgramCohortPayload
): ReactMutateRequest => {
  const path = [BASE_PATH, 'add-cohort'].join('/');
  return {
    path,
    payload,
  };
};

export const getUpdateCustomProgramCohortRm = (
  payload: CustomProgramCohortPayload
): ReactMutateRequest => {
  const path = [BASE_PATH, 'update-cohort'].join('/');
  return {
    path,
    payload,
  };
};

export const getDeleteCustomProgramCohortRm = (
  cohortId: number
): ReactMutateRequest => {
  const path = [BASE_PATH, 'delete-cohort', cohortId].join('/');
  return {
    path,
    payload: {},
  };
};

export const getAddCustomProgramUserPermissionsRm = (
  payload: CustomProgramUserPermissionPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-user-permissions`;
  return {
    path,
    payload,
  };
};

export const getUpdateCustomProgramUserPermissionRm = (
  payload: UserPermissionUpdatePayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-user-permission`;
  return {
    path,
    payload,
  };
};

export const getDeleteCustomProgramUserPermissionRm = ({
  customProgramId,
  userCompanyId,
}: {
  customProgramId: number;
  userCompanyId: number;
}): ReactMutateRequest => {
  const path = `${BASE_PATH}/delete-user-permission/${customProgramId}/${userCompanyId}`;
  return {
    path,
    payload: {},
  };
};

export const getAddCustomProgramRm = (payload: CustomProgramPayload) => {
  const baseUrl = `/${BASE_PATH}/add-custom-program`;

  return {
    path: baseUrl,
    payload: encodeCustomProgramPayload(payload),
  };
};

export const getDeleteCustomProgramCoverImageRm = (customProgramId: number) => {
  const baseUrl = `/${BASE_PATH}/delete-custom-program-cover-image/${customProgramId}`;

  return {
    path: baseUrl,
    payload: {},
  };
};

export const getDeleteCustomProgramContentAttachmentRm = (
  customProgramContentId: number
) => {
  const baseUrl = `/${BASE_PATH}/custom-program-content-delete-attachments/${customProgramContentId}`;

  return {
    path: baseUrl,
    payload: {},
  };
};

export const getUpdateCustomProgramAttachmentRm = (id: number) => {
  const baseUrl = `/${BASE_PATH}/upload-content-attachment/${id}`;

  return {
    path: baseUrl,
    payload: {},
  };
};

export const getDeleteCustomProgramRm = (id: number) => {
  const baseUrl = `${BASE_PATH}/delete-custom-program`;
  const path = `${baseUrl}/${id}`;

  return {
    path,
    payload: {},
  };
};

export const getAddCustomProgramContentRm = (
  payload: NewCustomProgramContentPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-custom-program-section-content`;

  return {
    path,
    payload: encodeCustomProgramContentPayload(payload),
  };
};

export const getUpdateCustomProgramContentRm = (
  payload: UpdateCustomProgramContentPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-custom-program-content`;

  return {
    path,
    payload: encodeCustomProgramContentPayload(payload),
  };
};

export const getAddCustomProgramDateToCalendarRm = (
  contentId: number
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-date-to-my-calendar/${contentId}`;

  return {
    path,
    payload: {},
  };
};

export const getAddAllCustomProgramDatesToCalendarRm = (
  customProgramId: number
): ReactMutateRequest => {
  const path = `${BASE_PATH}/add-all-dates-to-my-calendar/${customProgramId}`;

  return {
    path,
    payload: {},
  };
};

export const getDeleteCustomProgramContentRm = (id: number) => {
  const baseUrl = `${BASE_PATH}/delete-custom-program-content`;
  const path = `${baseUrl}/${id}`;

  return {
    path,
    payload: {},
  };
};

export const getUpdateCustomProgramContentOrderRm = (
  payload: UpdateItemsOrderPayload
) => {
  const path = `${BASE_PATH}/re-order-custom-program-content`;

  return {
    path,
    payload,
  };
};

export const getUpdateCustomProgramEmailMessageRm = (
  payload: CustomProgramEmailMessagePayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/update-custom-program-email-message`;

  return {
    path,
    payload,
  };
};

export const getSubmitContentFeedbackRm = (
  payload: NewFeedbackPayload
): ReactMutateRequest => {
  const path = `${BASE_PATH}/submit-content-feedback`;

  return {
    path,
    payload,
  };
};

export const getUpdateCustomProgramRm = (payload: CustomProgramPayload) => {
  const baseUrl = `/${BASE_PATH}/update-custom-program`;

  return {
    path: baseUrl,
    payload: encodeCustomProgramPayload(payload),
  };
};
