import {AcademySkillVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AcademySkillVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademySkillVMMockData(
  args?: Partial<AcademySkillVM>
): AcademySkillVM {
  return {
    id: faker.datatype.number(),
    name: faker.name.fullName(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademySkillVMMockData: AcademySkillVM = {
  id: 1,
  name: 'Bobby Johnson',
  title: 'Advanced Web Design',
};
