import {useRef, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {CustomProgramSectionPreviewVM} from '@generated/interfaces';
import {CustomizableLearningCategory} from '@generated/enums';
import LearnInPageDrawer, {
  NAV_PANEL_WIDTH,
} from '@components/reusable/LearnInPageDrawer';
import SectionNav from './SectionNav.container';
import ContentCard from './ContentCard.container';
import FirstSectionContentHeader from './FirstSectionContentHeader.container';
import {Breakpoints} from '@utils/responsiveUtils';
import EmptyDataText from '@blocks/NoDataText';
import CustomProgramUserDrawerAdminSideNav from './CustomProgramUserDrawerAdminSideNav.container';
import {ReportsContainer} from '@components/admin/pages/programs/customPrograms/CustomProgramAdminDrawer';
import CustomProgramReportsScreen from '@components/admin/pages/programs/customPrograms/reports/CustomProgramReportsScreen.container';
import ProjectsScreen from '@components/admin/pages/programs/customPrograms/projects/ProjectsScreen';
import LoadingSpinner from '@blocks/LoadingSpinner';
import CustomProgramUserDrawerHeader from './CustomProgramUserDrawerHeader';
import AgendaScreen from './Agenda/AgendaScreen/AgendaScreen.container';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {ProgramContentStatus} from '@models/clientEnums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const MainContainer = styled.section<{showAdminNav: boolean}>`
  padding: 16px 0px 72px ${96 + NAV_PANEL_WIDTH}px;
  width: 1400px;
  margin: auto;
  box-sizing: border-box;
  max-width: 90%;
  @media (max-width: ${Breakpoints.Tablet}px) {
    padding: 0px;
    width: 100%;
    max-width: unset;
    left: 96px;
  }
`;

const SectionTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  margin-left: 50px;
  margin-bottom: 16px;
`;

const ContentCardsContainer = styled.div`
  @media (max-width: ${Breakpoints.Tablet}px) {
    padding: 0;
  }
`;

const LoadingStateContainer = styled.div`
  position: fixed;
  margin-left: 50%;
  margin-top: 20%;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ICustomProgramUserDrawerProps {
  initialScreen: CustomProgramScreens;
  onChangeUpcomingCohortSelect: (id: number) => void;
  onClickEditProgram: () => void;
  onClickViewCohorts: () => void;
  onClickSectionNavItem: (id: number) => void;
  onClose: () => void;
  parentCustomProgramId: number | undefined;
  programId: number;
  programTitle: string;
  showCohortButton?: boolean;
  programIsSelected?: boolean;
  selectedContentId?: number;
  selectedSection: CustomProgramSectionPreviewVM | null;
  nextSection?: CustomProgramSectionPreviewVM | null;
  showAddToPlanButton: boolean;
  showAdminNav: boolean;
  showLoadingScreen: boolean;
  showPlanItemContextMenu?: boolean;
  showProgramHeader: boolean;
  visible: boolean;
  isActivePlanItem: boolean;
  onContentCompleteStatusChange?: (
    contentId: number,
    newStatus: ProgramContentStatus
  ) => void;
}

export function CustomProgramUserDrawer({
  initialScreen,
  onChangeUpcomingCohortSelect,
  onClickEditProgram,
  onClickViewCohorts,
  onClickSectionNavItem,
  onClose,
  parentCustomProgramId,
  programId,
  programTitle,
  showCohortButton,
  programIsSelected,
  selectedContentId,
  selectedSection,
  nextSection,
  showAdminNav,
  showLoadingScreen,
  showProgramHeader,
  visible,
  isActivePlanItem,
  showAddToPlanButton,
  onContentCompleteStatusChange,
}: ICustomProgramUserDrawerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [currentScreen, setCurrentScreen] = useState(initialScreen);

  return (
    <LearnInPageDrawer
      title={programTitle}
      visible={visible}
      onClose={onClose}
      contentWrapperStyle={{transform: 'none'}}
      headerContent={
        <CustomProgramUserDrawerHeader
          isActivePlanItem={isActivePlanItem}
          customProgramId={programId}
          onChangeUpcomingCohortSelect={onChangeUpcomingCohortSelect}
          onClickEditProgram={onClickEditProgram}
          showCohortButton={showCohortButton}
          onClickViewCohorts={onClickViewCohorts}
          parentCustomProgramId={parentCustomProgramId}
          onClickSectionNavItem={onClickSectionNavItem}
          selectedSectionId={selectedSection?.id}
          onClickNavItem={(screen) => setCurrentScreen(screen)}
          showAddToPlanButton={showAddToPlanButton}
        />
      }>
      <CustomProgramUserDrawerAdminSideNav
        currentScreen={currentScreen}
        customProgramId={programId}
        onClickNavItem={(screen) => setCurrentScreen(screen)}
      />

      {showLoadingScreen ? (
        <LoadingStateContainer>
          <LoadingSpinner />
        </LoadingStateContainer>
      ) : (
        <>
          {currentScreen === CustomProgramScreens.Agenda && (
            <AgendaScreen
              programId={programId}
              isActivePlanItem={isActivePlanItem}
            />
          )}
          {currentScreen == CustomProgramScreens.Curriculum && (
            <>
              <SectionNav
                customProgramId={programId}
                selectedSectionId={selectedSection?.id}
                onClickSectionNavItem={(id) => {
                  onClickSectionNavItem?.(id);
                  containerRef.current?.scrollIntoView(false);
                }}
                aria-label={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
              />
              <MainContainer
                showAdminNav={showAdminNav}
                aria-labelledby="selected-section-title">
                {showProgramHeader && (
                  <FirstSectionContentHeader customProgramId={programId} />
                )}
                <SectionTitle ref={containerRef} id="selected-section-title">
                  {selectedSection?.title}
                </SectionTitle>
                <ContentCardsContainer>
                  {selectedSection?.content
                    ?.sort((a, b) => a.order - b.order)
                    .map(
                      (
                        {
                          attachments,
                          textContentCompleted,
                          contentType,
                          description,
                          dueDateUtc,
                          endDateUtc,
                          eventLink,
                          eventTimeZone,
                          id,
                          links,
                          startDateUtc,
                          submissionType,
                          submittedFiles,
                          submittedLinks,
                          projectContentCompletedOn,
                          submittedResponse,
                          title,
                          rsvpStatus,
                          feedback,
                          submissionCount,
                          messagesPreview,
                          discussionId,
                        },
                        idx
                      ) => {
                        if (selectedSection.isFullScreen && idx !== 0) {
                          return null;
                        }
                        return (
                          <ContentCard
                            peerSubmissionCount={submissionCount}
                            isFullPage={selectedSection.isFullScreen}
                            showSkipProjectButton={false}
                            rsvpStatus={rsvpStatus}
                            attachments={attachments}
                            discussionId={discussionId}
                            messagesPreview={messagesPreview}
                            academyId={programId}
                            projectContentCompletedOn={
                              projectContentCompletedOn
                            }
                            programIsSelected={programIsSelected}
                            completed={
                              isActivePlanItem ? textContentCompleted : false
                            }
                            contentType={contentType}
                            customProgramId={programId}
                            customizableLearningCategory={
                              CustomizableLearningCategory.CustomProgram
                            }
                            description={description}
                            dueDate={dueDateUtc}
                            dueDateUtc={dueDateUtc}
                            endDateUtc={endDateUtc}
                            eventLink={eventLink}
                            eventTimeZone={eventTimeZone}
                            id={id}
                            isActivePlanItem={isActivePlanItem}
                            key={id}
                            links={links}
                            showSkipProgramButton={false}
                            showUnskipProgramButton={false}
                            order={idx + 1}
                            shouldBeInFocus={selectedContentId === id}
                            startDateUtc={startDateUtc}
                            submissionType={submissionType}
                            title={title}
                            submittedFile={
                              !!submittedFiles?.length &&
                              submittedFiles[submittedFiles.length - 1]
                            }
                            submittedLink={
                              !!submittedLinks?.length &&
                              submittedLinks[submittedLinks.length - 1]
                            }
                            submittedResponse={submittedResponse}
                            showIndexRule={
                              selectedSection?.content?.length > 1 &&
                              idx !== selectedSection?.content?.length - 1
                            }
                            onContentCompleteStatusChange={
                              onContentCompleteStatusChange
                            }
                            feedback={feedback}
                          />
                        );
                      }
                    )}
                  {nextSection && (
                    <div style={{display: 'flex', marginTop: '12px'}}>
                      <span
                        style={{
                          display: 'flex',
                          fontSize: '1rem',
                          lineHeight: '1.125rem',
                          marginLeft: 'auto',
                          alignItems: 'center',
                        }}>
                        {i18n.t(k.SECTION__NEXT__GO_TO)}
                      </span>
                      <LearnInButton
                        color={COLORS.Blue800}
                        fontSize="16px"
                        padding="10px 0px 10px 8px"
                        hoverUnderline
                        showArrowIcon
                        tag={ButtonTags.Link}
                        onClick={() => {
                          onClickSectionNavItem?.(nextSection.id);
                          containerRef.current?.scrollIntoView(false);
                        }}>
                        {nextSection.title}
                      </LearnInButton>
                    </div>
                  )}
                </ContentCardsContainer>
                {!selectedSection?.content.length && (
                  <EmptyDataText
                    header={i18n.t(k.PROGRAM__CUSTOM__EMPTY__TITLE)}
                    subHeader={i18n.t(
                      k.PROGRAM__CUSTOM__EMPTY__DESCRIPTION
                    )}></EmptyDataText>
                )}
              </MainContainer>
            </>
          )}
          {/* REPORTS SCREEN*/}
          {currentScreen === CustomProgramScreens.Reports && (
            <ReportsContainer>
              <CustomProgramReportsScreen customProgramId={programId} />
            </ReportsContainer>
          )}

          {/* PROJECTS SCREEN */}
          {currentScreen === CustomProgramScreens.Projects && (
            <ReportsContainer isCurriculumTabSelected>
              <ProjectsScreen customProgramId={programId} />
            </ReportsContainer>
          )}
        </>
      )}
    </LearnInPageDrawer>
  );
}

export default CustomProgramUserDrawer;
