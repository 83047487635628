import {ContentType} from './../enums';
import {UserCustomProgramContentPreviewVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserCustomProgramContentPreviewVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserCustomProgramContentPreviewVMMockData(
  args?: Partial<UserCustomProgramContentPreviewVM>
): UserCustomProgramContentPreviewVM {
  return {
    contentId: faker.datatype.number(),
    contentTitle: faker.company.catchPhrase(),
    contentType: ContentType.Text,
    customProgramId: faker.datatype.number(),
    sectionId: faker.datatype.number(),
    sectionTitle: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserCustomProgramContentPreviewVMMockData: UserCustomProgramContentPreviewVM =
  {
    contentId: 1,
    contentTitle: 'Advanced Web Design',
    contentType: ContentType.Text,
    customProgramId: 1,
    sectionId: 1,
    sectionTitle: 'Advanced Web Design',
  };
