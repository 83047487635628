import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import ProgramReviewCard from './ProgramReviewCard';
import {
  ProgramReviewUpsertModal,
  ProgramReviewDeleteConfirmationModal,
  UpsertMode,
} from './ProgramReviewFormModals';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {ProgramReviewVM} from '@models/serverModels';
import {Moment} from 'moment';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IHandleUpsertArgs {
  id: number | undefined;
  upsertMode: UpsertMode;
  rating: number;
  title: string;
  reviewContent: string;
  hasCompletedProgram: boolean;
  programCompletedOn: Moment;
}

export interface IReviewsTabProps {
  closeDeleteConfirmationModal: () => void;
  closeUpsertModal: () => void;
  handleDeleteReview: () => void;
  handleSubmitUpsert: (args: IHandleUpsertArgs) => void;
  isDeleteConfirmationModalVisible: boolean;
  isUpsertModalVisible: boolean;
  openDeleteConfirmationModal: (id: number) => void;
  openUpsertModal: (review?: ProgramReviewVM | undefined) => void;
  programReviews: ProgramReviewVM[];
  programReviewToEdit: ProgramReviewVM | null;
  showLeaveReviewButton: boolean;
}

function ReviewsTab({
  closeDeleteConfirmationModal,
  closeUpsertModal,
  handleDeleteReview,
  handleSubmitUpsert,
  isDeleteConfirmationModalVisible,
  isUpsertModalVisible,
  openDeleteConfirmationModal,
  openUpsertModal,
  programReviews,
  programReviewToEdit,
  showLeaveReviewButton,
}: IReviewsTabProps) {
  return (
    <>
      <ProgramReviewUpsertModal
        programReview={programReviewToEdit}
        visible={isUpsertModalVisible}
        handleSubmitUpsert={handleSubmitUpsert}
        onCancel={closeUpsertModal}
      />
      <ProgramReviewDeleteConfirmationModal
        visible={isDeleteConfirmationModalVisible}
        onCancel={closeDeleteConfirmationModal}
        handleDeleteReview={handleDeleteReview}
      />
      <TitleBar>
        <h2>{i18n.t(k.PROGRAM__REVIEW__PLURAL)}</h2>
        {showLeaveReviewButton && (
          <LearnInButton
            data-testid="leave-a-review-button"
            style={{margin: '5px'}}
            tag={ButtonTags.Secondary}
            onClick={() => openUpsertModal()}>
            {i18n.t(k.REVIEW__ADD)}
          </LearnInButton>
        )}
      </TitleBar>
      <div>
        {programReviews.map((review) => {
          return (
            <ProgramReviewCard
              openUpsertModal={openUpsertModal}
              openDeleteConfirmationModal={openDeleteConfirmationModal}
              programReview={review}
              key={review.id}
            />
          );
        })}
      </div>
    </>
  );
}

export default ReviewsTab;
