import {i18n, k} from '@i18n/translate';
import {useNavigate} from 'react-router-dom';
import {AcademyScreens} from '@utils/ClientPaths';
import styled from 'styled-components';
import NavButton from '@blocks/NavigationButton';
import {COLORS} from '@utils/constants';
import {
  BgColorsOutlined,
  InfoCircleOutlined,
  LayoutOutlined,
  LockOutlined,
} from '@ant-design/icons';
import {useAcademyLevelVMsQuery} from '@hooks/apiEndpoints/academy/queries';
import {getLabelByScreen} from './Setup.utils';

/*
|--------------------------------------------------------------------------
| Styled Component
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  align-items: center;
  background: white;
  bottom: 0;
  box-shadow: inset -1px 0px 0px ${COLORS.Neutral200};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding-top: 4px;
  position: absolute;
  top: ${() => HEADER_HEIGHT}px;
  width: ${() => SIDE_NAV_WIDTH}px;
  z-index: 90;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const HEADER_HEIGHT = 72;
const SIDE_NAV_WIDTH = 96;

export interface AcademyConfigSideNavProps {
  currentScreen: string;
  getScreenNavItemUrlPath: (screen: AcademyScreens, levelId?: number) => string;
  academyId: number;
}

function AcademyConfigSideNav({
  currentScreen,
  getScreenNavItemUrlPath,
  academyId,
}: AcademyConfigSideNavProps) {
  const navigate = useNavigate();
  const {data: levels} = useAcademyLevelVMsQuery({
    academyId,
    excludeUserProgress: true,
  });

  return (
    <Container>
      <div>
        <NavButton
          Icon={InfoCircleOutlined}
          isSelected={currentScreen === AcademyScreens.setup}
          label={getLabelByScreen(AcademyScreens.setup)}
          onClick={() =>
            navigate(getScreenNavItemUrlPath(AcademyScreens.setup))
          }
          style={{marginBottom: '8px'}}
        />
        <NavButton
          Icon={LockOutlined}
          isSelected={currentScreen === AcademyScreens.permissions}
          label={getLabelByScreen(AcademyScreens.permissions)}
          onClick={() =>
            navigate(getScreenNavItemUrlPath(AcademyScreens.permissions))
          }
          style={{marginBottom: '8px'}}
        />
        <NavButton
          Icon={BgColorsOutlined}
          isSelected={currentScreen === AcademyScreens.branding}
          label={getLabelByScreen(AcademyScreens.branding)}
          onClick={() => {
            return navigate(getScreenNavItemUrlPath(AcademyScreens.branding));
          }}
          style={{marginBottom: '8px'}}
        />
        <NavButton
          Icon={LayoutOutlined}
          isSelected={currentScreen === AcademyScreens.overview}
          label={getLabelByScreen(AcademyScreens.overview)}
          onClick={() =>
            navigate(
              getScreenNavItemUrlPath(AcademyScreens.overview, levels?.[0]?.id)
            )
          }
          style={{marginBottom: '8px'}}
        />
      </div>
    </Container>
  );
}

/*
|--------------------------------------------------------------------------
| Default Export
|--------------------------------------------------------------------------
*/

export default AcademyConfigSideNav;
