import styled from 'styled-components';
import {COLORS} from '@utils/constants';

export const AdminUsersContainer = styled.div`
  .ant-tabs-tabpane {
    min-height: 50px;
  }
`;

export const AdminUsersPeopleCount = styled.div`
  color: ${COLORS.Neutral700};
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 10px;
`;
