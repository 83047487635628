import {LearnInButton} from '@components/reusable/Button/Button.style';
import {CheckOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import styled, {css, keyframes} from 'styled-components';

export const ANIMATION_SPEED = 0.8;

export const shadowPulse = keyframes`
  0% { 
    box-shadow: none;
    transform: scale(1);
  }
  50% { 
    box-shadow: 0 0 1px 9px ${COLORS.Green200};
    transform: scale(1.1);
  }
  60% {
    transform: scale(1);
  }
  100% { 
    box-shadow: none;
  }
`;

export const collapse = keyframes`
  0% { 
    width: 200px; 
    padding-left: 20px;
  }
  100% { 
    width: 38px; 
    padding-left: 10px;
    background-color: ${COLORS.Green600};
    color: ${COLORS.White};
    border: 1px solid ${COLORS.Green600}
  }
`;

export const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

export const spinAndGrow = keyframes`
  0% {
    transform: scale(1) rotate(0);
  }
  30% {
    transform: scale(1) rotate(10deg);
  } 
  100% {
    transform: scale(1.4) rotate(360deg);
  }
`;

export const shrink = keyframes`
0% {
  transform: scale(1.4);
}
40% {
  transform: scale(1.1);
}
100% {
  transform: scale(1);
}
`;

export const growToCompletedButton = keyframes`
  0% { 
    width: 38px; 
    padding-left: 10px;
    background-color: ${COLORS.Green600};
    color: ${COLORS.White};
    border: 1px solid ${COLORS.Green600}
  }
  100% {
    transform: scale(1);
    width: 143px; 
    padding-left: 20px;
    background-color: ${COLORS.White};
    color: ${COLORS.Neutral600};
    border: 1px solid ${COLORS.Neutral300}
  }
`;

export const CompleteButton = styled(LearnInButton)`
  overflow: hidden;
  white-space: nowrap;
  justify-content: flex-start;
  height: 38px;
  ${({completed}) => css`
    border: 1px solid ${!completed ? COLORS.Blue800 : COLORS.Neutral300};
    color: ${!completed ? COLORS.Blue800 : COLORS.Neutral600};
  `}
  span {
    display: inline-block;
    ${({animate}) =>
      animate &&
      css`
        animation: ${spinAndGrow} ${0.31 * ANIMATION_SPEED}s
            cubic-bezier(0.65, 0, 0.35, 1) forwards ${0.27 * ANIMATION_SPEED}s,
          ${shrink} ${0.2 * ANIMATION_SPEED}s ease-in-out forwards
            ${0.67 * ANIMATION_SPEED}s,
          ${pulse} ${0.29 * ANIMATION_SPEED}s ease-in-out forwards
            ${1.66 * ANIMATION_SPEED}s;
      `}
  }
  ${({animate}) =>
    animate &&
    css`
      animation: ${collapse} ${0.14 * ANIMATION_SPEED}s
          cubic-bezier(0.4, 0, 0.2, 1) forwards ${0.85 * ANIMATION_SPEED}s,
        ${pulse} ${0.29 * ANIMATION_SPEED}s ease-in-out,
        ${shadowPulse} ${0.64 * ANIMATION_SPEED}s ease-in-out forwards
          ${1 * ANIMATION_SPEED}s,
        ${growToCompletedButton} ${0.12 * ANIMATION_SPEED}s ease-in-out forwards
          ${1.64 * ANIMATION_SPEED}s,
        ${pulse} ${0.29 * ANIMATION_SPEED}s ease-in-out forwards
          ${1.66 * ANIMATION_SPEED}s;
    `}
`;

export const Checkmark = styled(CheckOutlined)`
  margin-right: 8px;
  fontweight: 500;
`;

export const SectionCompleteContainer = styled.div`
  margin-right: 8px;
`;
export const Container = styled.span`
  font-weight: 500;
`;
