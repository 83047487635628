import {CustomProgramScreens} from './ClientPaths';

export const createCustomProgramScreenUtils = (
  defaultScreen: CustomProgramScreens
) => {
  return {
    isCurrenScreenUrlPathInvalid: (pathname: string) => {
      return isCurrenScreenUrlPathInvalid(pathname, defaultScreen);
    },
    pathnameIncludesPath: (pathname: string, screen: string) => {
      return pathnameIncludesPath(pathname, screen);
    },
    getCurrentScreenFromUrl: (pathname: string) => {
      return getCurrentScreenFromUrl(pathname, defaultScreen);
    },
  };
};

const isCurrenScreenUrlPathInvalid = (
  pathname: string,
  defaultScreen: CustomProgramScreens
) => {
  const currentScreen = getCurrentScreenFromUrl(pathname, defaultScreen);
  return (
    currentScreen === defaultScreen &&
    !pathnameIncludesPath(pathname, defaultScreen)
  );
};

const pathnameIncludesPath = (pathname: string, screen: string) => {
  return pathname.split('/').includes(screen.toLowerCase());
};

const getCurrentScreenFromUrl = (
  pathname: string,
  defaultScreen: CustomProgramScreens
): CustomProgramScreens => {
  for (const screen in CustomProgramScreens) {
    if (pathnameIncludesPath(pathname, screen)) {
      return screen as CustomProgramScreens;
    }
  }
  return defaultScreen;
};
