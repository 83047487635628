import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {
  displayCurrencyCode,
  formatCurrency,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {COLORS} from '@utils/constants';
import {WalletOutlined} from '@ant-design/icons';
import {Col, Row} from 'antd';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {CurrencyCode} from '@generated/enums';

const DisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 185px;
  padding: 45px 10px;
  border: 1px ${COLORS.Neutral200} solid;
  border-radius: 8px;
  font-size: 0.75rem;
`;

const BoldNumber = styled.span`
  font-weight: 500;
  font-size: 1.5rem;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 26px;
  height: 26px;
  background-color: ${({iconColor}) => iconColor ?? COLORS.Blue800};
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 5px;
`;

const Currency = styled.span`
  font-size: 0.75rem;
  margin-left: 5px;
`;

export interface Props {
  totalAmount: string;
  currencyCode: CurrencyCode;
  primaryBrandColor: string;
  isCurrencySymbolSimplificationOn: boolean;
}

function PersonalLearningBudgetUptoDisplay({
  totalAmount,
  currencyCode,
  primaryBrandColor,
  isCurrencySymbolSimplificationOn,
}: Props) {
  return (
    <DisplayContainer>
      <IconContainer iconColor={primaryBrandColor}>
        <WalletOutlined aria-hidden="true" style={{color: COLORS.White}} />
      </IconContainer>
      <Row>
        <Col style={{fontSize: '0.75rem'}}>{i18n.t(k.APPROVAL__FOR)}</Col>
      </Row>
      <Row>
        <Col>
          <BoldNumber>{totalAmount}</BoldNumber>
          {!isCurrencySymbolSimplificationOn && (
            <Currency>{displayCurrencyCode(currencyCode)}</Currency>
          )}
        </Col>
      </Row>
    </DisplayContainer>
  );
}

export default PersonalLearningBudgetUptoDisplay;
