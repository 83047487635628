import {useEffect, useState} from 'react';
import {truncate} from 'lodash';
import {i18n, k} from '@i18n/translate';
import styled, {DefaultTheme} from 'styled-components';
import useOpenUpload from '@hooks/useOpenUpload';
import {ProjectSubmissionVM} from '@generated/interfaces';
import {CustomizableLearningCategory} from '@generated/enums';
import {formatDateTimeToMMMMDYYYY} from '@utils/timeUtils';
import {ArrowRightOutlined, ArrowLeftOutlined} from '@ant-design/icons';
import {Typography} from 'antd';
import {COLORS} from '@utils/constants';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import {useKeyPress} from '@hooks/useKeyPress';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {formatUrl} from '@utils/stringUtils';
import {
  useCustomProgramsSectionsContentsSubmissionsQuery,
  usePeerViewableAcademySubmissionsQuery,
} from '@generated/hooks';
import {Paginator} from '@blocks/CustomProgramUserDrawer/MembersDrawer/Paginator';

const Name = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: ${COLORS.Neutral950};
  margin-bottom: 0;
`;

const Date = styled(Typography)`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${COLORS.Neutral600};
  margin-bottom: 8px;
`;

const SubmissionsContainer = styled.div`
  display: inline-grid;
  grid-gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  max-width: 752px;
`;
const SubmissionLink = styled.a`
  padding: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  border: ${(props: {selected?: boolean}) =>
    props.selected
      ? `1px solid ${COLORS.Blue800}`
      : `1px solid ${COLORS.Neutral200}`};
  background-color: ${(props: {selected?: boolean}) =>
    props.selected ? COLORS.Blue50 : COLORS.White};

  box-shadow: none;
  border-radius: 8px;
  cursor: pointer;
  max-width: 240px;
  height: 288px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  &:focus {
    outline: 2px solid ${(props: DefaultTheme) => props.itemDefaultTextNavColor};
  }
`;

const SubmissionButton = styled.button`
  padding: 16px;
  text-align: left;
  display: flex;
  flex-direction: column;
  border: ${(props: {selected?: boolean}) =>
    props.selected
      ? `1px solid ${COLORS.Blue800}`
      : `1px solid ${COLORS.Neutral200}`};
  background-color: ${(props: {selected?: boolean}) =>
    props.selected ? COLORS.Blue50 : COLORS.White};

  box-shadow: none;
  border-radius: 8px;
  cursor: pointer;
  max-width: 240px;
  height: 288px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  &:focus {
    outline: 2px solid ${(props: DefaultTheme) => props.itemDefaultTextNavColor};
  }
`;

const SubmissionText = styled(Typography)`
  margin-top: 8px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${COLORS.Neutral900};
`;

const LinkText = styled.a`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

const DrawerNavigator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: middle;
  border-bottom: 1px solid ${COLORS.Neutral200};
  padding: 16px 25px;
  height: 52px;
`;

const DrawerNavigationButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  color: ${(props: {disabled?: boolean}) =>
    props?.disabled ? COLORS.Neutral600 : COLORS.Blue800};
  cursor: ${(props: {disabled?: boolean}) =>
    props?.disabled ? 'auto' : 'pointer'};
  &:focus {
    outline: 2px solid ${(props: DefaultTheme) => props.itemDefaultTextNavColor};
  }
`;

const DrawerNavigationButtonText = styled(Typography)`
  color: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  margin-bottom: 0px;
`;

const DrawerBody = styled.div`
  background-color: ${COLORS.Neutral50};
  margin: 16px 24px;
  padding: 16px;
  border-radius: 8px;
`;

const DrawerBodyTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 25px 0px 25px;
  & h3 {
    color: ${COLORS.Neutral950};
    weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
  }
  & p {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${COLORS.Neutral900};
  }
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const ImageContainer = styled.img`
  width: 208px;
  height: 208px;
  object-fit: cover;
`;

const EmptyText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0;
`;

const EmptyContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const Container = styled.div`
  text-align: center;
`;

interface SelectedSubmission extends ProjectSubmissionVM {
  index: number;
  stepId?: number;
}

export function PeerSubmissions({
  academyOrProgramId,
  sectionId,
  stepId,
  isAcademy,
}: {
  academyOrProgramId?: number;
  sectionId?: number;
  stepId: number;
  isAcademy?: boolean;
}) {
  let data: ProjectSubmissionVM[] | undefined = [];
  const {data: academyData, isLoading: isLoadingForAcademy} =
    usePeerViewableAcademySubmissionsQuery(
      {
        academyStepId: stepId,
      },
      {
        enabled: isAcademy,
      }
    );
  const rightPress = useKeyPress('ArrowRight');
  const leftPress = useKeyPress('ArrowLeft');
  const openUpload = useOpenUpload(CustomizableLearningCategory.Academy);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 6;
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedSubmission, setSelectedSubmission] = useState<
    SelectedSubmission | undefined
  >(undefined);
  const {data: cpData, isLoading: isLoadingForCustomProgram} =
    useCustomProgramsSectionsContentsSubmissionsQuery({
      contentId: stepId,
      customProgramId: academyOrProgramId,
      sectionId,
    });
  data = academyData ? academyData : cpData;

  useEffect(() => {
    if (leftPress && showDrawer && selectedSubmission?.index !== 0) {
      if (selectedSubmission !== undefined) {
        const newSub = {
          ...data[selectedSubmission?.index - 1],
          index: selectedSubmission?.index - 1,
        } as SelectedSubmission;
        setSelectedSubmission(newSub);
      }
    } else if (
      rightPress &&
      showDrawer &&
      selectedSubmission?.index !== data?.length - 1
    ) {
      if (selectedSubmission !== undefined) {
        const newSub = {
          ...data[selectedSubmission?.index + 1],
          index: selectedSubmission?.index + 1,
        } as SelectedSubmission;
        setSelectedSubmission(newSub);
      }
    }
  }, [leftPress, rightPress, showDrawer]);

  useEffect(() => {
    const index = selectedSubmission?.index + 1;
    if (showDrawer) {
      if (index > currentPage * pageSize) {
        setCurrentPage(currentPage + 1);
      } else if (index < currentPage * pageSize - pageSize + 1) {
        setCurrentPage(currentPage - 1);
      }
    }
  }, [currentPage, selectedSubmission]);

  return (data === undefined && isLoadingForAcademy) ||
    (data === undefined && isLoadingForCustomProgram) ? (
    <LoadingSpinner />
  ) : (
    <Container>
      {data.length > 0 ? (
        <SubmissionsContainer>
          {data
            .slice((currentPage - 1) * pageSize, currentPage * pageSize)
            .map((submission, index) => {
              return submission?.links?.length > 0 ? (
                <SubmissionLink
                  target="_blank"
                  rel="noreferrer"
                  tabIndex={0}
                  selected={
                    index ===
                    selectedSubmission?.index - (currentPage - 1) * pageSize
                  }
                  key={index}
                  bordered={false}
                  href={
                    formatUrl(
                      submission?.links?.[submission?.links?.length - 1]
                    ) || ''
                  }>
                  <Name>{submission.participantName}</Name>
                  <Date>
                    {submission?.submittedOn &&
                      i18n.t(k.STATUS__SUBMITTED_ON__FORMAT, {
                        date: formatDateTimeToMMMMDYYYY(
                          submission?.submittedOn
                        ),
                      })}
                  </Date>
                  {submission?.uploads.length > 0 && (
                    <ImageContainer
                      src={
                        submission?.uploads?.[0]?.image ||
                        getPlaceholderImgSrc(submission?.id)
                      }
                    />
                  )}
                  {submission?.response && (
                    <SubmissionText>
                      {truncate(submission.response, {
                        length: 250,
                        separator: ' ',
                      })}
                    </SubmissionText>
                  )}
                </SubmissionLink>
              ) : (
                <SubmissionButton
                  tabIndex={0}
                  selected={
                    index ===
                    selectedSubmission?.index - (currentPage - 1) * pageSize
                  }
                  key={index}
                  bordered={false}
                  onClick={() => {
                    if (submission?.uploads.length > 0) {
                      openUpload(submission?.uploads[0].id);
                    }
                    if (submission?.response) {
                      setShowDrawer(true);
                    }
                    const newSub = {
                      ...submission,
                      index: index + (currentPage - 1) * pageSize,
                    } as SelectedSubmission;
                    setSelectedSubmission(newSub);
                  }}>
                  <Name>{submission.participantName}</Name>
                  <Date>
                    {submission?.submittedOn &&
                      i18n.t(k.STATUS__SUBMITTED_ON__FORMAT, {
                        date: formatDateTimeToMMMMDYYYY(
                          submission?.submittedOn
                        ),
                      })}
                  </Date>
                  {submission?.uploads.length > 0 && (
                    <ImageContainer
                      src={
                        submission?.uploads?.[0]?.image ||
                        getPlaceholderImgSrc(submission?.id)
                      }
                    />
                  )}
                  {submission?.response && (
                    <SubmissionText>
                      {submission.response
                        .slice(0, 250)
                        .concat(submission.response.length > 250 ? '...' : '')}
                    </SubmissionText>
                  )}
                </SubmissionButton>
              );
            })}
          <LearnInDrawer
            title={selectedSubmission?.participantName}
            placement="right"
            closeButtonPlacement="right"
            onClose={() => setShowDrawer(false)}
            size="large"
            bodyStyle={{padding: '0px', backgroundColor: COLORS.White}}
            visible={showDrawer}>
            <DrawerNavigator>
              <DrawerNavigationButton
                tabIndex={0}
                disabled={selectedSubmission?.index === 0}
                onClick={() => {
                  if (selectedSubmission !== undefined) {
                    const newSub = {
                      ...data[selectedSubmission?.index - 1],
                      index: selectedSubmission?.index - 1,
                    } as SelectedSubmission;
                    setSelectedSubmission(newSub);
                  }
                }}>
                <ArrowLeftOutlined
                  aria-hidden="true"
                  style={{marginRight: '10px'}}
                  color={COLORS.Blue800}
                />
                <DrawerNavigationButtonText>
                  {i18n.t(k.SUBMISSION__NAVIGATE_PREVIOUS)}
                </DrawerNavigationButtonText>
              </DrawerNavigationButton>
              <DrawerNavigationButton
                disabled={selectedSubmission?.index === data.length - 1}
                onClick={() => {
                  if (selectedSubmission !== undefined) {
                    const newSub = {
                      ...data[selectedSubmission?.index + 1],
                      index: selectedSubmission?.index + 1,
                    } as SelectedSubmission;
                    setSelectedSubmission(newSub);
                  }
                }}>
                <DrawerNavigationButtonText>
                  {i18n.t(k.SUBMISSION__NAVIGATE_NEXT)}
                </DrawerNavigationButtonText>
                <ArrowRightOutlined
                  aria-hidden="true"
                  style={{marginLeft: '10px'}}
                  color={COLORS.Blue800}
                />
              </DrawerNavigationButton>
            </DrawerNavigator>
            <DrawerBodyTitle>
              <h3>
                {i18n.t(k.SUBMISSION__FROM__FORMAT, {
                  participant: selectedSubmission?.participantName,
                })}
              </h3>
              <p>
                {i18n.t(k.STATUS__SUBMITTED_ON__FORMAT, {
                  date: formatDateTimeToMMMMDYYYY(
                    selectedSubmission?.submittedOn
                  ),
                })}
              </p>
            </DrawerBodyTitle>
            <DrawerBody>{selectedSubmission?.response}</DrawerBody>
          </LearnInDrawer>
        </SubmissionsContainer>
      ) : (
        <EmptyContainer>
          <EmptyText>{i18n.t(k.SUBMISSION__NO_SUBMISSIONS)}</EmptyText>
        </EmptyContainer>
      )}
      {data.length > pageSize && (
        <PaginationContainer>
          <Paginator
            current={currentPage}
            total={data?.length || 0}
            pageSize={pageSize}
            onChange={(page) => {
              setCurrentPage(page);
            }}
          />
        </PaginationContainer>
      )}
    </Container>
  );
}
