// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CreditCardStatus.cs

enum CreditCardStatus {
  Pending,
  Active,
  Disabled,
}

export default CreditCardStatus;
