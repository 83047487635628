import {InitiativePurpose, InitiativeStatus} from './../enums';
import {InitiativePayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: InitiativePayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getInitiativePayloadMockData(
  args?: Partial<InitiativePayload>
): InitiativePayload {
  return {
    audience: faker.word.noun(),
    financeIncentiveIds: [faker.datatype.number()],
    id: faker.datatype.number(), // optional
    programIds: [faker.datatype.number()],
    purpose: InitiativePurpose.LearnCriticalSkill,
    purposeDescription: faker.lorem.lines(),
    status: InitiativeStatus.Draft,
    timeIncentiveIds: [faker.datatype.number()],
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const InitiativePayloadMockData: InitiativePayload = {
  audience: 'string',
  financeIncentiveIds: [1],
  id: 1, // optional
  programIds: [1],
  purpose: InitiativePurpose.LearnCriticalSkill,
  purposeDescription:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  status: InitiativeStatus.Draft,
  timeIncentiveIds: [1],
  title: 'Advanced Web Design',
};
