import styled from 'styled-components';
import {Form, Steps} from 'antd';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {Breakpoints} from '@utils/responsiveUtils';

export const LearnInDrawerContainer = styled(LearnInDrawer)`
  .ant-drawer-body {
    height: 100vh;
    overflow: auto;
    padding-right: 0;
  }

  .ant-steps-item-tail {
    display: none !important;
  }
`;

export const Container = styled(Form)`
  width: 100%;
  align-items: start;

  span {
    top: 0 !important;
  }

  .keep-right {
    margin-left: auto;
    display: flex;
    flex-direction: row-reverse;
    gap: 2rem;
  }
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    padding: 0;
  }

  h3 {
    margin: 0;
  }

  justify-content: start;
  margin: 3vw 6vw;

  .ant-space {
    width: 100%;
  }

  .ant-steps {
    flex-direction: row !important;
    align-items: end;
    margin-bottom: 10vh;
  }

  .ant-steps-item-title {
    font-size: 0.75rem !important;
    color: ${COLORS.Neutral600} !important;
    text-transform: uppercase;

    ::after {
      background-color: transparent !important;
    }
  }

  .ant-steps-item {
    padding-left: 2px;
    min-width: 33.33%;
  }

  .ant-steps-item-active {
    .ant-steps-item-title {
      color: ${COLORS.Blue800} !important;
    }

    .ant-steps-item-content {
      border-bottom: 8px solid ${COLORS.Blue800};
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-content {
      border-bottom: 8px solid ${COLORS.Blue800};
    }
  }

  .ant-steps-item-content {
    border-bottom: 8px solid ${COLORS.Neutral600};
  }

  .ant-steps-item-icon {
    display: none;
  }

  .ant-steps-item-container {
    display: flex;
    flex-direction: column-reverse;
    min-width: max-content;
  }
`;

export const StyledButton = styled(LearnInButton)`
  visibility: ${({visible}) => (visible ? 'visible' : 'hidden')};
`;

export const ImageBanner = styled.img`
  height: auto;
  @media (max-width: ${Breakpoints.Desktop}px) {
    display: none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 1.25rem 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const MainContent = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const StyledSteps = styled(Steps)`
  gap: 0.5rem;
  margin-bottom: 2.5rem;
  width: 60%;

  .ant-steps-item {
    flex-grow: 1;
    padding: 0 !important;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  background-color: ${COLORS.Purple200};
  border-radius: 100px;
  padding: 1rem;
`;
