import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';
import styled from 'styled-components';

export const DoubleLineOption = styled.div`
  font-size: ${pxToRem(12, 16)};
  font-weight: 500;
  color: ${COLORS.Neutral950};

  span {
    display: block;
    font-size: ${pxToRem(14, 16)};
    font-weight: 400;
    line-height: ${pxToRem(20, 16)};
    color: ${COLORS.Neutral700};
  }
`;
