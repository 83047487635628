import './TheBasics.scss';
import {useCallback, useEffect, useState} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {Card, Divider, Form, Input, Radio, Row, Typography} from 'antd';
import {api} from '@store/api';
import {COLORS} from '@utils/constants';
import {InitiativeFor} from '../../../../store/interface';
import {InitiativePurpose} from '@generated/enums';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {InitiativePayload} from '@models/serverModels';
import {FormInstance} from 'antd/lib/form';

interface Props {
  isVisible: boolean;
  formInstance: FormInstance;
  data: InitiativePayload;
  onFormDirty: () => void;
}

const TheBasics = ({formInstance, data, isVisible, onFormDirty}: Props) => {
  const getInitialPurposeValue = (): InitiativePurpose =>
    data?.purpose ?? InitiativePurpose.LearnCriticalSkill;
  const getInitiativeForValue = useCallback((): InitiativeFor => {
    if (!data) {
      // default to whole company
      return InitiativeFor.WHOLE_COMPANY;
    }

    // backend passes "Whole Company" if that's the audience otherwise it can pass anything
    if (
      data.audience === InitiativeFor.WHOLE_COMPANY ||
      data.audience === null
    ) {
      return InitiativeFor.WHOLE_COMPANY;
    }

    return InitiativeFor.SPECIFIC_AUDIENCE;
  }, [data, isVisible]);

  const [initiativeFor, setInitiativeFor] = React.useState<InitiativeFor>(
    getInitiativeForValue()
  );
  const [initiativePurpose, setInitiativePurpose] = useState<InitiativePurpose>(
    getInitialPurposeValue()
  );
  const [audience, setAudience] = React.useState([]);

  useEffect(() => {
    if (data) {
      setInitiativePurpose(getInitialPurposeValue());
      setInitiativeFor(getInitiativeForValue());
    }
  }, [data, isVisible]);

  useEffect(() => {
    setPurposeDescriptionInForm(formInstance, data);
  }, [data, initiativeFor]);

  const setPurposeDescriptionInForm = (
    formInstance: FormInstance<any>,
    data: InitiativePayload
  ) => {
    if (!data) {
      return;
    }

    if (data?.purpose == InitiativePurpose.PrepareForNewRole) {
      formInstance.setFieldsValue({
        purposeDescriptionRole: data?.purposeDescription,
      });
    } else if (data?.purpose == InitiativePurpose.Other) {
      formInstance.setFieldsValue({
        purposeDescriptionOther: data?.purposeDescription,
      });
    } else {
      formInstance.setFieldsValue({
        purposeDescriptionOther: undefined,
        purposeDescriptionRole: undefined,
      });
    }
  };

  useEffect(() => {
    //TODO: Switch out call to reactQuery
    api
      .get('audience/get-position-audiences')
      .then((resp) => {
        if (resp.status === 200) {
          setAudience(resp.data || []);
        }
      })
      .catch((error) => {
        console.log('Error while loading audiences', error);
      });
  }, []);

  return (
    <>
      <Card
        role="article"
        aria-labelledby="sub-component-card-title"
        className="sub-component-card"
        data-testid="initiative-basics-form-card">
        <Row className="sub-component-card-header">
          <Typography.Title
            className="sub-component-card-title"
            id="sub-component-card-title">
            {i18n.t(k.INITIATIVE__THE_BASICS)}
          </Typography.Title>
        </Row>
        <Divider style={{background: COLORS.Neutral300, margin: '8px 0'}} />

        <Form
          onValuesChange={() => onFormDirty?.call(null)}
          layout={'vertical'}
          form={formInstance}
          name="basic"
          className="new-initiative-sub-component-form"
          initialValues={{remember: true}}>
          <Form.Item
            label={i18n.t(k.INITIATIVE__NAME)}
            name="title"
            rules={[
              {
                required: true,
                message: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                  item: i18n.t(k.INITIATIVE__NAME).toLocaleLowerCase(),
                }),
              },
            ]}
            initialValue={data?.title}>
            <LearnInInput
              tag={InputTags.PRIMARY}
              placeholder={i18n.t(k.INITIATIVE__NAME__EXAMPLE)}
              style={{width: '400px'}}
            />
          </Form.Item>

          <Form.Item
            name="initiativeFor"
            label={i18n.t(k.INITIATIVE__FOR)}
            initialValue={initiativeFor}
            rules={[
              {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
            ]}>
            <Radio.Group
              onChange={(event) => {
                setInitiativeFor(event.target.value);
              }}>
              <Radio value={InitiativeFor.WHOLE_COMPANY}>
                {i18n.t(k.GENERIC__WHOLE_COMPANY)}
              </Radio>
              <Radio value={InitiativeFor.SPECIFIC_AUDIENCE}>
                {i18n.t(k.INITIATIVE__SPECIFIC_AUDIENCE)}
              </Radio>
            </Radio.Group>
          </Form.Item>

          {initiativeFor === InitiativeFor.SPECIFIC_AUDIENCE && (
            <Form.Item
              name="audience"
              label={i18n.t(k.INITIATIVE__SELECT_AUDIENCE)}
              initialValue={data?.audience}
              style={{left: '24px', position: 'relative', top: '-16px'}}>
              <LearnInSelect style={{width: 280}}>
                {audience.map((value) => (
                  <LearnInSelectOption key={value} value={value}>
                    {value}
                  </LearnInSelectOption>
                ))}
              </LearnInSelect>
            </Form.Item>
          )}

          <Form.Item
            name="purpose"
            label={i18n.t(k.INITIATIVE__PURPOSE)}
            initialValue={getInitialPurposeValue()}
            rules={[
              {
                required: true,
                message: i18n.t(k.VALIDATION__SELECT_ONE__POLITE),
              },
            ]}>
            <Radio.Group
              onChange={(event) => {
                setInitiativePurpose(event.target.value);
              }}>
              <Radio value={InitiativePurpose.LearnCriticalSkill}>
                {i18n.t(k.INITIATIVE__HELP_LEARN)}
              </Radio>
              <Radio value={InitiativePurpose.PrepareForNewRole}>
                {i18n.t(k.INITIATIVE__HELP_PREPARE)}
              </Radio>
              {initiativePurpose === InitiativePurpose.PrepareForNewRole && (
                <Form.Item
                  name="purposeDescriptionRole"
                  initialValue={
                    getInitialPurposeValue() ===
                    InitiativePurpose.PrepareForNewRole
                      ? data?.purposeDescription ?? ''
                      : ''
                  }
                  style={{left: '24px', position: 'relative', top: '8px'}}>
                  <LearnInInput
                    tag={InputTags.PRIMARY}
                    onChange={(e) =>
                      formInstance.setFieldsValue({
                        purposeDescription: e.target.value,
                      })
                    }
                    placeholder={i18n.t(k.INITIATIVE__NEW_ROLE)}
                    style={{width: '376px'}}
                  />
                </Form.Item>
              )}

              <Radio value={InitiativePurpose.Other}>
                {i18n.t(k.GENERIC__OTHER)}
              </Radio>
              {initiativePurpose === InitiativePurpose.Other && (
                <Form.Item
                  name="purposeDescriptionOther"
                  style={{left: '24px', position: 'relative', top: '8px'}}>
                  <LearnInInput
                    tag={InputTags.PRIMARY}
                    onChange={(e) =>
                      formInstance.setFieldsValue({
                        purposeDescription: e.target.value,
                      })
                    }
                    placeholder={i18n.t(k.GENERIC__ADDITIONAL_DETAILS)}
                    style={{width: '376px'}}
                  />
                </Form.Item>
              )}
            </Radio.Group>
          </Form.Item>
          <Form.Item style={{display: 'none'}} name="purposeDescription">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default TheBasics;
