import {DurationUnitType} from '@generated/enums';

export const mapDurationTypeString = (s: string) => {
  switch (s.toLowerCase()) {
    case 'day':
    case 'days':
      return DurationUnitType.Days;
    case 'week':
    case 'weeks':
      return DurationUnitType.Weeks;
    case 'month':
    case 'months':
      return DurationUnitType.Months;
    case 'year':
    case 'years':
      return DurationUnitType.Years;
    default:
      return null;
  }
};
