import * as React from 'react';
import styled, {css} from 'styled-components';
import LearnInPageDrawer, {
  ILearnInPageDrawerProps,
} from '@components/reusable/LearnInPageDrawer';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import {ProviderLogo} from '@blocks/ProviderLogo';
import {COLORS} from '@utils/constants';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {Breakpoints} from '@utils/responsiveUtils';
import {QuillHtmlViewer} from '@components/admin/pages/approvals/components/QuillHtmlViewer';
import {formatDescriptionEmailAndUrlToLink} from '@utils/stringUtils';
import {InfoCircleOutlined} from '@ant-design/icons';
import DrawerPage from '../DrawerPage';
import {
  OVERVIEW_PANEL_DEFAULT_WIDTH,
  OVERVIEW_SIDE_CARD_WIDTH,
} from './Tabs/OverviewTab';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const SIDE_PADDING = '16%';
const SIDE_PADDING_MOBILE = '8%';
const Container = styled.div<{isNotFinanceIncentive: boolean}>`
  .ant-tabs-nav {
    background: white;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid white;
    padding-left: ${SIDE_PADDING};
    @media (max-width: ${Breakpoints.Tablet}px) {
      padding: 0;
    }
  }
  .ant-tabs-content-holder {
    padding: 23px ${SIDE_PADDING};
    background: ${({isNotFinanceIncentive}) =>
      isNotFinanceIncentive ? COLORS.Blue50 : COLORS.Neutral50};

    @media (max-width: ${Breakpoints.Tablet}px) {
      padding: 5px 1% 10px;
    }
  }
`;

const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  max-height: 240px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${SIDE_PADDING};
  position: relative;
  margin: auto;
  background: white;
  @media (max-width: ${Breakpoints.Tablet}px) {
    padding: 0 ${SIDE_PADDING_MOBILE};
  }
`;

const HeaderContentContainer = styled.div`
  max-width: ${OVERVIEW_SIDE_CARD_WIDTH + OVERVIEW_PANEL_DEFAULT_WIDTH + 16}px;
  display: flex;
  flex-direction: column;
  gap: 21px;
  padding: 32px 0px;
`;
const HeaderInfoContainer = styled.div`
  display: flex;
  gap: 21px;
  @media (max-width: ${Breakpoints.Tablet}px) {
    flex-wrap: wrap;
  }
`;

const Provider = styled.div`
  font-weight: 700;
  font-size: 0.75rem;
  color: ${COLORS.Neutral600};
  line-height: 1rem;
`;

const TitleContainer = styled.div`
  width: 100%;
  min-width: 100px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  color: ${COLORS.Neutral950};
  line-height: 1.75rem;
`;

const InfoItemTitle = styled.div`
  font-weight: 700;
  font-size: 0.75rem;
  color: ${COLORS.Neutral600};
  line-height: 1rem;
`;

const InfoItemValue = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  color: ${COLORS.Neutral950};
  line-height: 1.75rem;
`;

const Description = styled.div`
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
`;

const MetaData = styled.div`
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${COLORS.Neutral950};
`;

const ItemInfoRow = styled.div`
  align-items: end;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 100%;
  @media (max-width: ${Breakpoints.Tablet}px) {
    align-items: start;
  }
`;

const ButtonsContainer = styled.div<{
  direction: 'ltr' | 'rtl';
  verticalForMobile: boolean;
}>`
  display: flex;
  row-gap: 1rem;

  ${({verticalForMobile}) => {
    if (verticalForMobile) {
      return css`
        @media (max-width: ${Breakpoints.Tablet}px) {
          align-items: flex-start;
          flex-direction: column;
          align-content: space-between;
        }
      `;
    }
  }}

  ${({direction}) => {
    if (direction === 'ltr') {
      return css`
        button {
          margin-right: 16px;
        }
      `;
    }
    if (direction === 'rtl') {
      return css`
        button {
          margin-left: 16px;
        }
      `;
    }
    return null;
  }}
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IPlanItemDetailPageProps extends ILearnInPageDrawerProps {
  id: number;
  description: string;
  headerImageUrl?: string;
  provider: string;
  title: string;
  providerLogoSrc;
  itemInfo?: {
    title: string;
    value: string;
    tooltip?: string;
  };
  metadata?: string[];
  renderPageInsteadOfDrawer?: boolean;
  buttons: React.ReactNode | React.ReactNode[];
  children: React.ReactNode | React.ReactNode[];
  isNotFinanceIncentive?: boolean;
}

function PlanItemDetailPage({
  buttons,
  children,
  description,
  headerImageUrl,
  id,
  itemInfo,
  metadata,
  onClose,
  provider,
  providerLogoSrc,
  renderPageInsteadOfDrawer,
  title,
  visible,
  isNotFinanceIncentive,
}: IPlanItemDetailPageProps) {
  const renderButtons = ({direction, verticalForMobile}) => {
    return (
      <ButtonsContainer
        verticalForMobile={verticalForMobile}
        direction={direction}>
        {buttons}
      </ButtonsContainer>
    );
  };

  const _metadata = metadata?.filter(Boolean).join(' | ');

  const contents = (
    <Container isNotFinanceIncentive={isNotFinanceIncentive}>
      {/* Header */}
      <HeaderImage src={headerImageUrl || getPlaceholderImgSrc(id)} alt="" />
      <HeaderContainer>
        <HeaderContentContainer>
          <HeaderInfoContainer>
            <ProviderLogo
              size={56}
              providerLogoSrc={providerLogoSrc}
              border={false}
            />
            <TitleContainer>
              <Provider>{provider}</Provider>
              <Title>{title}</Title>
            </TitleContainer>
            {!!itemInfo && (
              <ItemInfoRow>
                <InfoItemTitle>
                  {itemInfo?.title}
                  {itemInfo?.tooltip && (
                    <LearnInTooltip title={itemInfo?.tooltip}>
                      <InfoCircleOutlined aria-hidden="true" />
                    </LearnInTooltip>
                  )}
                </InfoItemTitle>
                <InfoItemValue>{itemInfo?.value}</InfoItemValue>
              </ItemInfoRow>
            )}
          </HeaderInfoContainer>
          {!!_metadata?.length && <MetaData>{_metadata}</MetaData>}
          <Description>
            <QuillHtmlViewer
              html={
                description && formatDescriptionEmailAndUrlToLink(description)
              }></QuillHtmlViewer>
          </Description>
          <div>
            {/*TODO: https://linear.app/learnin/issue/LI-5021/change-program-detail-page-buttons Fix how the buttons look*/}
            {renderButtons({direction: 'ltr', verticalForMobile: true})}
          </div>
        </HeaderContentContainer>
      </HeaderContainer>

      {/* Tabs */}
      {children}
    </Container>
  );

  return renderPageInsteadOfDrawer ? (
    <DrawerPage title={''} onClickBack={onClose} useStaticPosition={true}>
      {contents}
    </DrawerPage>
  ) : (
    <LearnInPageDrawer
      visible={visible}
      onClose={onClose}
      headerContent={
        !isNotFinanceIncentive &&
        renderButtons({
          direction: 'rtl',
          verticalForMobile: false,
        })
      }>
      {contents}
    </LearnInPageDrawer>
  );
}

export default PlanItemDetailPage;
