import React, {useState, useEffect, useRef} from 'react';
import pickBy from 'lodash/pickBy';
import {UnprivilegedEditor} from 'react-quill';
import {
  useAcademyNudgeEmailQuery,
  useCustomProgramNudgeEmailQuery,
  usePersonalizationQuery,
  useUserQuery,
} from '@generated/hooks';
import EmailNoteEditor from '@components/reusable/EmailNoteEditorDrawer';
import NudgeEmailPreviewFooter from './NudgeEmailPreview.footer';
import {splitDOM} from '@utils/domUtils';
import {ArrowLeftOutlined} from '@ant-design/icons';
import {DrawerTitle} from './NudgeEmailPreview.styled';
import DOMPurify from 'dompurify';
import {i18n, k} from '@i18n/translate';

// rich text editor character limit for validation
const MAX_NOTES_CHAR = 800;

/**
 * Token to be included in the email template
 * and will be replaced by the custom note
 */
const PLACEHOLDER_TOKEN = '{{NOTE}}';

export enum Feature {
  Academy = 'Academy',
  CustomProgram = 'CustomProgram',
  AcademyStep = 'Step',
  CustomProgramContent = 'CustomProgramContent',
}

function decodeEscapedCharacters(textValue: string) {
  const mapping = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#x27;': "'",
    '&apos;': "'",
    '&#x2F;': '/',
  };
  return textValue.replace(
    /(&amp;|&lt;|&gt;|&quot;|&#x27;|&apos;|&#x2F;)/g,
    (match) => mapping[match]
  );
}

export interface NudgeEmailPreviewContainerProps {
  onClose: () => void;
  onSendNudge: (emailMetadata: {note: string; subject: string}) => void;
  open: boolean;
  /** Displays the number of participants in the footer */
  participantsCount?: number;
  /** Allows displaying the number of participants in the footer */
  showParticipantsCount?: boolean;
  /** When the target is a single participant,
   * displays the name in the email template */
  participantName?: string | JSX.Element;
  /** Program or academy name to show in the subject */
  programOrAcademyName: string | JSX.Element;
  feature: Feature;
}

const NudgeEmailPreviewContainer: React.FC<NudgeEmailPreviewContainerProps> = ({
  onClose,
  open,
  participantsCount,
  showParticipantsCount,
  participantName,
  programOrAcademyName,
  feature,
  onSendNudge,
}) => {
  const {data: user} = useUserQuery(null);
  const companyPersonalization = usePersonalizationQuery(null);

  /**
   * Copy of the template, intended to be manipulated locally
   * to show what the preview will look like
   */
  const [emailHtmlContent, setEmailHtmlContent] = useState<string>('');

  /**
   * RichTextEditor HTML content
   */
  const [emailNote, setEmailNote] = useState('');

  /**
   * Original email template fetched from BE
   */
  const useNudgeEmailTemplate =
    feature === Feature.Academy
      ? useAcademyNudgeEmailQuery
      : useCustomProgramNudgeEmailQuery;
  const {data: emailTemplate} = useNudgeEmailTemplate(
    {
      queryParams: pickBy(
        {
          name: participantName || '[Participant Name]', // TODO: for multiple participants, use "[Participant Name]" <- create i18n keys
          companyLogoUrl: companyPersonalization.data?.companyLogoUrlRectangle,
          note: PLACEHOLDER_TOKEN,
        },
        Boolean
      ),
    },
    {
      enabled: open && companyPersonalization.isFetched,
      onSuccess: (originalEmailTemplate: string) => {
        setEmailHtmlContent(
          originalEmailTemplate.replace(PLACEHOLDER_TOKEN, emailNote)
        );
      },
    }
  );

  /**
   * Ref to parse HTML string into actual DOM elements
   */
  const htmlParserElementRef = useRef(null);

  /**
   * DOM sections obtained from parsed HTML
   * [upper, middle element, lower]
   */
  const [emailSections, setEmailSections] =
    useState<[HTMLElement, HTMLElement, HTMLElement]>(null);

  /**
   * Text included in the original email that acts as an intro
   * to the note and here's manually added above the editor
   */
  const [emailNoteIntro, setEmailNoteIntro] = useState('');

  /**
   * RichTextEditor character count (text content only, html tags not taken into account)
   */
  const [emailNoteCharCount, setEmailNoteCharCount] = useState<number>(0);

  /**
   * Indicates whether the email contains a note
   */
  const [emailHasNote, setEmailHasNote] = useState<boolean>(false);

  /**
   * Indicates whether the component should be in edit mode
   * and display the text editor
   */
  const [isEditing, setIsEditing] = useState<boolean>(true);
  const emailSubject = i18n.t('NUDGE_EMAIL__EMAIL_PREVIEW_SUBJECT__FORMAT', {
    type:
      feature === Feature.Academy || feature === Feature.AcademyStep
        ? i18n.t('ACADEMY')
        : feature === Feature.CustomProgram
        ? i18n.t('PROGRAM__CUSTOM')
        : i18n.t('GENERIC__STEP'),
    name: typeof programOrAcademyName === 'string' ? programOrAcademyName : '',
  });

  useEffect(() => {
    // Once react has a reference to the injected HTML, parse it
    if (htmlParserElementRef.current && emailHtmlContent) {
      const emailSections = splitDOM(
        '#notes',
        htmlParserElementRef.current,
        'table:first-child > tbody > tr > td'
      );
      setEmailSections(emailSections);
      if (emailSections[1]) {
        const noteIntro =
          emailSections[1].firstElementChild.getAttribute('data-note-intro');
        setEmailNoteIntro(noteIntro);
      }
    }
  }, [open, emailHtmlContent]);

  const handleOnNoteChange = (
    htmlValue: string,
    _delta,
    _source,
    quillEditor: UnprivilegedEditor
  ) => {
    setEmailNote(htmlValue);
    setEmailNoteCharCount(quillEditor.getLength());
  };

  const handleSaveNote = () => {
    setEmailHtmlContent(
      (emailTemplate as string).replace(PLACEHOLDER_TOKEN, emailNote)
    );
    setIsEditing(false);
    setEmailHasNote(true);
  };

  const handleSendNudge = () => {
    onSendNudge({
      note: emailNote,
      subject: decodeEscapedCharacters(emailSubject),
    });
  };

  const handleEditNote = () => {
    setIsEditing(true);
  };
  return (
    <>
      {/* div below used only as a medium to parse the email HTML string,
          converting it into an actual DOM tree */}
      <div
        style={{display: 'none'}}
        ref={htmlParserElementRef}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(emailHtmlContent as string),
        }}
      />
      <EmailNoteEditor
        open={open}
        emailNote={emailNote}
        emailNoteCharCount={emailNoteCharCount}
        onClose={onClose}
        isEditingNote={isEditing}
        companyId={user?.companyId}
        onEditNote={handleEditNote}
        onNoteChange={handleOnNoteChange}
        emailHasNote={emailHasNote}
        emailSections={emailSections}
        drawerTitle={
          <>
            <ArrowLeftOutlined onClick={onClose} />
            <DrawerTitle>{i18n.t(k.GENERIC__NUDGE_EMAIL_PREVIEW)}</DrawerTitle>
          </>
        }
        emailNoteIntro={emailNoteIntro}
        emailSubject={`${i18n.t(k.GENERIC__SUBJECT)}: ${decodeEscapedCharacters(
          emailSubject
        )}`}
        isDeletePendingSave={false}
        footer={
          <NudgeEmailPreviewFooter
            canSaveChanges={
              emailNoteCharCount <= MAX_NOTES_CHAR && emailNoteCharCount > 1
            }
            canSendNudge={!isEditing}
            participantCount={participantsCount}
            showParticipantCount={showParticipantsCount}
            onSaveChanges={handleSaveNote}
            onSendNudge={handleSendNudge}
          />
        }
      />
    </>
  );
};

export default NudgeEmailPreviewContainer;
