import '@components/user/pages/home/UserHome.scss';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Carousel, Col, Row, Skeleton} from 'antd';
import Text from 'antd/lib/typography/Text';
import {useNavigate} from 'react-router-dom';
import CarouselCard from '@components/user/pages/home/components/CarouselCard';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {
  getCarouselItemDetailPath,
  getUserProgramFinderPath,
  userCustomProgramDetailRoute,
  UserPaths,
} from '@utils/ClientPaths';
import {PlanItemType} from '@generated/enums';
import {mapValueToPlanItemType} from '@utils/enumMapping/PlanItemTypeMapper';
import {ProgramForCarousel} from '@models/serverModels';
import {Breakpoints} from '@utils/responsiveUtils';
import useTreatment from '@hooks/useTreatment';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {Wand} from '@assets/svg/Wand';
import {
  ProgramFinderLaunchOrigin,
  trackProgramFinderWelcomeModalView,
} from '@utils/analytics';

interface Props {
  isDirectBilling: boolean;
  programsForCarousel: ProgramForCarousel[];
  type: PlanItemType;
}

const StyledCarousel = styled(Carousel)`
  // Equal height cards:
  .slick-track {
    display: flex;
  }
  .slick-slide {
    height: inherit;
    > div {
      display: flex;
      height: 100%;
    }
    .ant-carousel-card-col {
      height: 100%;
      .ant-card {
        height: 100%;
      }
    }
  }
  //^ Equal height cards
`;

export const determineCarouselTitle = (
  listType: string,
  isProgramFinderEnabled: boolean
): string => {
  switch (listType) {
    case i18n.t(k.PROGRAM):
      if (isProgramFinderEnabled)
        return i18n.t(k.PROGRAM_FINDER__PROGRAM__CAROUSEL__EMPTY__PROGRAM);
      return i18n.t(k.PROGRAM__CAROUSEL__EMPTY__PROGRAM);
    case i18n.t(k.TIME):
      return i18n.t(k.PROGRAM__CAROUSEL__EMPTY__TIME);
    case i18n.t(k.GENERIC__FINANCE):
      return i18n.t(k.PROGRAM__CAROUSEL__EMPTY__FINANCE);
    default:
      return '';
  }
};

export function ProgramsCarouselSection({
  isDirectBilling,
  programsForCarousel,
  type,
}: Props) {
  const navigate = useNavigate();
  const onlyShowCustomPrograms =
    useTreatment(FeatureFlagExperiments.OnlyCustomProgramsUser) ===
    FeatureFlagTreatments.On;
  const isProgramFinderEnabled = !onlyShowCustomPrograms && !isDirectBilling;

  const determineCarouselButtonText = (listType: string): string => {
    switch (listType) {
      case i18n.t(k.PROGRAM):
        return isProgramFinderEnabled
          ? i18n.t(k.PROGRAM_FINDER__TRY__TITLE)
          : i18n.t(k.PROGRAM__PLURAL__EXPLORE);
      case i18n.t(k.TIME):
        return i18n.t(k.INCENTIVE__PLURAL__EXPLORE);
      case i18n.t(k.GENERIC__FINANCE):
        return i18n.t(k.FINANCE__PLURAL__EXPLORE);
      default:
        return '';
    }
  };

  return (
    <div
      style={{
        background: COLORS.White,
        padding: '24px',
        borderRadius: '8px',
        boxShadow: `0px 0px 20px ${COLORS.BoxShadow1}`,
        marginBottom: '24px',
      }}>
      <Row className="carousel-section">
        <Col
          xs={22}
          sm={24}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="carousel-title-text-col">
          <div>
            <Text
              style={{textAlign: 'center', textJustify: 'inter-word'}}
              className="illustrator-description">
              {determineCarouselTitle(
                mapValueToPlanItemType(type),
                isProgramFinderEnabled
              )}
            </Text>
          </div>
          <div>
            <LearnInButton
              style={{marginLeft: '10px'}}
              tag={ButtonTags.Secondary}
              onClick={() => {
                if (type === PlanItemType.Finance) {
                  return navigate(UserPaths.FinanceIncentivesSearch);
                }
                if (type === PlanItemType.Time) {
                  return navigate(UserPaths.TimeIncentivesSearch);
                }
                if (isProgramFinderEnabled) {
                  trackProgramFinderWelcomeModalView({
                    programFinder: {
                      launchOrigin: ProgramFinderLaunchOrigin.UserPlanEmpty,
                    },
                  });
                  return navigate(getUserProgramFinderPath());
                } else return navigate(UserPaths.ProgramsSearch);
              }}>
              {isProgramFinderEnabled && <Wand />}
              {determineCarouselButtonText(mapValueToPlanItemType(type))}
            </LearnInButton>
          </div>
        </Col>
        <Col xs={24} sm={24} style={{marginTop: '20px'}}>
          {programsForCarousel && programsForCarousel.length > 0 ? (
            <StyledCarousel
              slidesToShow={3}
              slidesToScroll={3}
              dots
              responsive={[
                {
                  breakpoint: Breakpoints.Mobile,
                  settings: {slidesToShow: 1, slidesToScroll: 1},
                },
                {
                  breakpoint: Breakpoints.Tablet,
                  settings: {slidesToShow: 2, slidesToScroll: 2},
                },
              ]}
              dotPosition="bottom"
              infinite={false}>
              {programsForCarousel.map((program, idx) => {
                return (
                  <button
                    key={program.id}
                    onClick={() => {
                      navigate(
                        onlyShowCustomPrograms
                          ? userCustomProgramDetailRoute(
                              program.id,
                              onlyShowCustomPrograms
                            )
                          : getCarouselItemDetailPath(
                              program.id,
                              mapValueToPlanItemType(type).toLocaleLowerCase()
                            )
                      );
                    }}>
                    <Col
                      key={program.id}
                      sm={24}
                      md={24}
                      style={{
                        padding: '10px 10px 10px 10px',
                        minWidth: '200px',
                      }}
                      className={
                        (idx + 1) % 3 === 0
                          ? 'last-card-in-carousel  ant-carousel-card-col'
                          : 'ant-carousel-card-col'
                      }>
                      <CarouselCard
                        backgroundImage={program.imageSmall}
                        title={program.title}
                        subtitle={program.subtitle}
                        logo={program.logo}
                        key={program.id}
                      />
                    </Col>
                  </button>
                );
              })}
            </StyledCarousel>
          ) : (
            <Skeleton active />
          )}
        </Col>
      </Row>
    </div>
  );
}
