import {CompanySetting} from './../enums';
import {CompanySettingResponse} from './../interfaces';
import {
  ICompanySettingResponseMockData,
  getICompanySettingResponseMockData,
} from './ICompanySettingResponse.mock';
import {faker} from '@faker-js/faker';

// Interface: CompanySettingResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCompanySettingResponseMockData(
  args?: Partial<CompanySettingResponse>
): CompanySettingResponse {
  return {
    ...getICompanySettingResponseMockData(),
    key: CompanySetting.LxpDomain,
    value: faker.word.noun(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CompanySettingResponseMockData: CompanySettingResponse = {
  ...ICompanySettingResponseMockData,
  key: CompanySetting.LxpDomain,
  value: 'string', // optional
};
