/**
 * Helper methods for skinning the app for clients
 */

/**
 * Helper method to determine when we're dealing with Learn In as Provider
 * @param providerName
 * @param strictCheck whether to use includes (false) or === (true) for comparison
 */
export function isLearnInProvider(providerName?: string, strictCheck = true) {
  if (!providerName) return false;
  const learnInWithSpace = 'learn in';
  const learnInNoSpace = 'learnin';
  if (strictCheck) {
    return (
      providerName.trim().toLowerCase() === learnInWithSpace ||
      providerName.trim().toLowerCase() === learnInNoSpace
    );
  } else {
    return (
      providerName.toLowerCase().includes(learnInWithSpace) ||
      providerName.toLowerCase().includes(learnInNoSpace)
    );
  }
}

export function getClientAwareProviderName(
  companyName?: string,
  providerName?: string
) {
  if (!providerName) return companyName;
  return isLearnInProvider(providerName, true) && companyName
    ? companyName
    : providerName;
}
