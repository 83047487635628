import {i18n, k} from '@i18n/translate';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import {Input} from 'antd';
import LearnInTable from '@components/reusable/LearnInTable';
import {AdminUsersViewProps} from './AdminUsers.interfaces';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {AdminUsersContainer, AdminUsersPeopleCount} from './AdminUsers.styles';

const AdminUsers = ({
  columns,
  counts,
  isLoading,
  tableParams,
  totalPages,
  users,
  handleTableChange,
  handleSearch,
}: AdminUsersViewProps) => {
  return (
    <AdminUsersContainer>
      <PageTitleBar fullWidth={true} title={i18n.t(k.GENERIC__PEOPLE)} />
      <Input.Search
        allowClear
        placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
          item: i18n.t(k.GENERIC__PEOPLE),
        })}
        style={{width: '400px', padding: '10px 32px'}}
        onChange={handleSearch}
      />
      <LearnInContainer
        tag={ContainerTags.TableBottomPadding}
        style={{padding: '10px 32px'}}>
        <AdminUsersPeopleCount>
          {i18n.t(k.GENERIC__SHOWING_X_TO_Y_OF_Z__FORMAT, {
            startCount: counts.start,
            endCount: counts.end,
            totalCount: counts.total,
            itemType: i18n.t(k.GENERIC__PEOPLE),
          })}
        </AdminUsersPeopleCount>
        {
          <LearnInTable
            noDataHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
            noDataSubheader={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION_ALT)}
            dataSource={users}
            scroll={{x: 'max-content'}}
            columns={columns}
            pagination={true}
            onChange={handleTableChange}
            totalPages={totalPages}
            isLoading={isLoading}
            pageSize={100}
            currentPage={tableParams?.pagination?.current}
          />
        }
      </LearnInContainer>
    </AdminUsersContainer>
  );
};

export default AdminUsers;
