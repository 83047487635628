import {AcademyStepType} from './../enums';
import {AcademyProgramStepPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AcademyProgramStepPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyProgramStepPayloadMockData(
  args?: Partial<AcademyProgramStepPayload>
): AcademyProgramStepPayload {
  return {
    academyLevelId: faker.datatype.number(),
    newSteps: [faker.datatype.number()],
    type: AcademyStepType.MarketplaceProgram,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyProgramStepPayloadMockData: AcademyProgramStepPayload = {
  academyLevelId: 1,
  newSteps: [123],
  type: AcademyStepType.MarketplaceProgram,
};
