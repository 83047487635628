import {ForwardRefExoticComponent} from 'react';
import styled from 'styled-components';
import NavButton from '@blocks/NavigationButton';
import {COLORS} from '@utils/constants';
import {HEADER_HEIGHT, SIDE_NAV_WIDTH} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';

/*
|--------------------------------------------------------------------------
| Styled Component
|--------------------------------------------------------------------------
*/

const NavContainer = styled.nav`
  align-items: center;
  background: white;
  bottom: 0;
  box-shadow: inset -1px 0px 0px ${COLORS.Neutral200};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding-top: 4px;
  position: absolute;
  top: ${() => HEADER_HEIGHT}px;
  width: ${() => SIDE_NAV_WIDTH}px;
  z-index: 90;
  @media (max-width: ${Breakpoints.Tablet}px) {
    display: none;
  }
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;
const NavListItem = styled.li``;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface SideNavItem {
  Icon: ForwardRefExoticComponent<any>;
  isSelected: boolean;
  label: string;
  onClick: () => void;
}
export interface CustomProgramDrawerSideNavProps {
  navItems: SideNavItem[];
}

function CustomProgramDrawerSideNav({
  navItems,
}: CustomProgramDrawerSideNavProps) {
  return (
    <NavContainer>
      <NavList>
        {navItems.map((navItem) => {
          return (
            <NavListItem key={String(navItem.label)}>
              <NavButton {...navItem} style={{marginBottom: '8px'}} />
            </NavListItem>
          );
        })}
      </NavList>
    </NavContainer>
  );
}

/*
|--------------------------------------------------------------------------
| Default Export
|--------------------------------------------------------------------------
*/

export default CustomProgramDrawerSideNav;
