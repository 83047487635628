import {useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import {useNavigate} from 'react-router-dom';
import {LockOutlined, GlobalOutlined} from '@ant-design/icons';
import LearnInTable, {stringSorter} from '@components/reusable/LearnInTable';
import ContextMenuButton from '@blocks/ContextMenu';
import {TableColText} from '@components/reusable/LearnInTable';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {CustomProgramStatus} from '@generated/enums';
import {COLORS} from '@utils/constants';
import {EmptyBlockUntilTrue} from '@components/reusable/EmptyBlockUntilTrue';
import LoadingSpinner from '@blocks/LoadingSpinner';
import NoDataText from '@blocks/NoDataText';
import {
  editAcademyUrl,
  adminAcademyReportsRoute,
  viewAcademyUrl,
} from '@utils/ClientPaths';
import styled from 'styled-components';
import {AcademyVM} from '@generated/interfaces';
import {getTitle} from '@utils/enumMapping/MapTextToUserLanguage';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';

const StyledPermission = styled.div`
  font-size: 1rem;
`;

const AcademyNameButton = styled.button`
  color: ${COLORS.Blue800};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const getPermissionTypeDisplay = (restrictedAccess) => {
  return !restrictedAccess ? (
    <>
      {' '}
      <GlobalOutlined aria-hidden="true" style={{marginRight: '6px'}} />
      {i18n.t(k.ACCESS__PUBLIC)}
    </>
  ) : (
    <>
      {' '}
      <LockOutlined aria-hidden="true" style={{marginRight: '6px'}} />
      {i18n.t(k.ACCESS__RESTRICTED)}
    </>
  );
};

/*
|--------------------------------------------------------------------------
| Reusable Table Rows
|--------------------------------------------------------------------------
*/

export const renderPermissionsColumn = (permissions) => {
  return (
    <StyledPermission>{getPermissionTypeDisplay(permissions)}</StyledPermission>
  );
};

export const renderStatusColumn = (status) => {
  const isPublished = status === CustomProgramStatus.Published;
  return (
    <LearnInTag
      label={
        isPublished ? i18n.t(k.ACCESS__PUBLISHED) : i18n.t(k.ACCESS__DRAFT)
      }
      tagStyle={isPublished ? TagStyles.Green : TagStyles.LightGrey}
      style={{width: 'fit-content'}}
    />
  );
};

/*
|--------------------------------------------------------------------------
| Table Component
|--------------------------------------------------------------------------
*/

interface AcademiesTableProps {
  isLoading?: boolean;
  pagination?: true;
  dataSource: AcademyVM[] | [];
  onClickDelete: (id: number) => Promise<void>;
  onClickDuplicateAcademy?: (id: number) => void;
}

function AcademiesTable({
  isLoading,
  pagination,
  dataSource,
  onClickDelete,
  onClickDuplicateAcademy,
}: AcademiesTableProps) {
  const navigate = useNavigate();
  const isLxpAdmin = useIsLxpAdmin();
  const columns = useMemo(() => {
    return [
      {
        title: i18n.t(k.ACADEMY__NAME),
        dataIndex: 'name',
        key: 'name',
        render: (academyName: string, {id}) => (
          <TableColText data-testid={`table-cell-title-${id}`}>
            <AcademyNameButton onClick={() => navigate(editAcademyUrl(id))}>
              {getTitle(academyName)}
            </AcademyNameButton>
          </TableColText>
        ),
        sorter: stringSorter<any>('name'),
      },
      {
        title: i18n.t(k.PERMISSION__PLURAL),
        dataIndex: 'restrictedAccess',
        key: 'restrictedAccess',
        render: renderPermissionsColumn,
      },
      {
        title: i18n.t(k.STATUS__STATUS),
        dataIndex: 'status',
        key: 'status',
        render: renderStatusColumn,
      },
      {
        title: null,
        dataIndex: null,
        key: 'contextMenu',
        render: (_, row) => {
          return (
            <ContextMenuButton
              popupContainerId="main-content"
              itemId={row.id}
              menuItems={[
                {
                  label: i18n.t(k.ACADEMY__VIEW),
                  onClick: () => navigate(viewAcademyUrl(row.id, 'content')),
                  dataTestId: `view-academy-option-${row.id}`,
                },
                {
                  label: i18n.t(k.ACADEMY__EDIT),
                  onClick: () => {
                    return navigate(editAcademyUrl(row.id));
                  },
                  dataTestId: `remove-academy-option-${row.id}`,
                },
                {
                  label: i18n.t(k.REPORT__PLURAL__VIEW),
                  onClick: () => navigate(adminAcademyReportsRoute(row.id)),
                  dataTestId: `view-academy-option-${row.id}`,
                },
                {
                  label: i18n.t(k.ACADEMY__DUPLICATE__VERB),
                  onClick: () => onClickDuplicateAcademy(row.id),
                  dataTestId: `duplicate-academy-option-${row.id}`,
                },
                {
                  label: (
                    <span style={{color: COLORS.Red800}}>
                      {i18n.t(k.ACADEMY__DELETE)}
                    </span>
                  ),
                  onClick: () => onClickDelete(row.id),
                  dataTestId: `remove-academy-option-${row.id}`,
                },
              ].filter(Boolean)}
            />
          );
        },
      },
    ];
  }, [navigate, onClickDelete, onClickDuplicateAcademy]);

  if (isLoading) {
    return (
      <NoDataText
        header={i18n.t(k.STATUS__LOADING)}
        subHeader={<LoadingSpinner />}
      />
    );
  }

  return (
    <div style={{padding: isLxpAdmin ? '24px' : '0px'}}>
      <EmptyBlockUntilTrue
        condition={!!dataSource?.length}
        emptyBody={i18n.t(k.ACADEMY__TABLE__EMPTY__DESCRIPTION)}
        emptyHeader={i18n.t(k.ACADEMY__TABLE__EMPTY__TITLE)}>
        <LearnInTable
          pagination={pagination}
          dataSource={dataSource}
          columns={columns}
        />
      </EmptyBlockUntilTrue>
    </div>
  );
}

export default AcademiesTable;
