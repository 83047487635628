import {useMemo, MouseEvent} from 'react';
import {i18n, k} from '@i18n/translate';
import {GlobalOutlined, LockOutlined} from '@ant-design/icons';
import LearnInTable, {
  DurationCol,
  stringSorter,
  TableCell,
  TableColLink,
} from '@components/reusable/LearnInTable';
import ContextMenuButton from '@blocks/ContextMenu';
import {CustomProgramAdminDetailVM, SkillTag} from '@models/serverModels';
import LearnInTag, {TagsContainer, TagStyles} from '@blocks/LearnInTag';
import {CustomProgramStatus} from '@generated/enums';
import {COLORS} from '@utils/constants';
import {EmptyBlockUntilTrue} from '@components/reusable/EmptyBlockUntilTrue';
import LoadingSpinner from '@blocks/LoadingSpinner';
import NoDataText from '@blocks/NoDataText';
import styled from 'styled-components';
import CaretRight from '@assets/svg/CaretRight';
import {getTitle} from '@utils/enumMapping/MapTextToUserLanguage';
import useFeatureFlags from '@hooks/useFeatureFlags';

const StyledPermission = styled.div`
  font-size: 1.02em;
`;

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

const getPermissionTypeDisplay = (restrictedAccess: any) => {
  return !restrictedAccess ? (
    <div style={{display: 'flex'}}>
      <div>
        <GlobalOutlined aria-hidden="true" style={{marginRight: '6px'}} />
      </div>
      <div>{i18n.t(k.ACCESS__PUBLIC)}</div>
    </div>
  ) : (
    <div style={{display: 'flex'}}>
      <div>
        <LockOutlined aria-hidden="true" style={{marginRight: '6px'}} />
      </div>
      <div>{i18n.t(k.ACCESS__RESTRICTED)}</div>
    </div>
  );
};

const DuplicationInProgress = () => {
  return (
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        fontSize: '0.8em',
      }}>
      <LoadingSpinner size="small" style={{marginRight: '6px'}} />{' '}
      {i18n.t(k.STATUS__DUPLICATING)}{' '}
    </div>
  );
};

/*
|--------------------------------------------------------------------------
| Reusable Table Rows
|--------------------------------------------------------------------------
*/

export const renderPermissionsColumn = (restrictedAccess: boolean) => {
  return (
    <StyledPermission>
      {getPermissionTypeDisplay(restrictedAccess)}
    </StyledPermission>
  );
};

export const renderStatusColumn = (status: any) => {
  const isPublished = status === CustomProgramStatus.Published;
  return (
    <LearnInTag
      label={i18n.t(isPublished ? k.ACCESS__PUBLISHED : k.ACCESS__DRAFT)}
      tagStyle={isPublished ? TagStyles.Green : TagStyles.LightGrey}
    />
  );
};

/*
|--------------------------------------------------------------------------
| Table Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramsTableProps {
  dataSource: CustomProgramAdminDetailVM[];
  isLoading?: boolean;
  onClickTitle: (id: number) => void;
  onClickAddCohort: (id: number) => void;
  onClickDeleteProgram: (id: number) => void;
  onClickDuplicateCustomProgram: (programId: number) => void;
  onClickViewReports: (program: CustomProgramAdminDetailVM) => void;
  onClickViewProgram: (id: number) => void;
  openUpdateCustomProgramDrawer: (program: CustomProgramAdminDetailVM) => void;
  pagination?: boolean;
  onClickRow: (row: CustomProgramAdminDetailVM) => void;
  duplicatingCustomProgramId?: number;
  showRowHoverStyle: (row: CustomProgramAdminDetailVM) => boolean;
  hasContainer?: boolean;
}

function CustomProgramsTable({
  dataSource,
  isLoading,
  onClickAddCohort,
  onClickDeleteProgram,
  onClickDuplicateCustomProgram,
  onClickRow,
  onClickViewProgram,
  onClickViewReports,
  openUpdateCustomProgramDrawer,
  pagination,
  duplicatingCustomProgramId,
  hasContainer,
  showRowHoverStyle,
}: CustomProgramsTableProps) {
  const {
    isFeatureFlagOn: {DurationStrings},
  } = useFeatureFlags();
  const columns = useMemo(() => {
    return [
      {
        title: i18n.t(k.PROGRAM__NAME),
        dataIndex: 'title',
        key: 'title',
        render: (title: string, row: CustomProgramAdminDetailVM) => {
          return (
            <TableCell>
              <TableColLink
                data-testid={`table-cell-title-${row.id}`}
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  openUpdateCustomProgramDrawer(row);
                }}>
                {getTitle(title)}
              </TableColLink>
              {row.id === duplicatingCustomProgramId && (
                <DuplicationInProgress />
              )}
            </TableCell>
          );
        },
        sorter: stringSorter<any>('title'),
      },
      {
        title: i18n.t(k.GENERIC__TAG__PLURAL),
        dataIndex: 'skills',
        key: 'skills',
        render: (skills: SkillTag[], {id}) => {
          return (
            <TagsContainer
              tags={skills?.map(({name}) => name)}
              getTagDataTestId={(tag) => `skill-tag-${id}-${tag}`}
            />
          );
        },
      },
      DurationCol(DurationStrings),
      {
        title: i18n.t(k.PERMISSION__PLURAL),
        dataIndex: 'restrictedAccess',
        key: 'restrictedAccess',
        render: renderPermissionsColumn,
      },
      {
        title: i18n.t(k.STATUS__STATUS),
        dataIndex: 'status',
        key: 'status',
        render: renderStatusColumn,
      },
      {
        title: null,
        dataIndex: null,
        width: '48px',
        fixed: 'right',
        key: 'contextMenu',
        render: (_: any, row: CustomProgramAdminDetailVM) => {
          return (
            <ContextMenuButton
              title={row.title}
              menuItems={[
                {
                  label: i18n.t(k.PROGRAM__VIEW),
                  onClick: () => onClickViewProgram(row.id),
                  dataTestId: `view-program-option-${row.id}`,
                },
                !row.cohortCount && {
                  label: i18n.t(k.REPORT__PLURAL__VIEW),
                  onClick: () => onClickViewReports(row),
                  dataTestId: `view-reports-program-option-${row.id}`,
                },
                {
                  label: i18n.t(k.COHORT__ADD),
                  onClick: () => onClickAddCohort(row?.id),
                },
                {
                  label: i18n.t(k.PROGRAM__EDIT),
                  onClick: () => openUpdateCustomProgramDrawer(row),
                  dataTestId: `edit-program-option-${row.id}`,
                },
                {
                  label: i18n.t(k.PROGRAM__DUPLICATE__VERB),
                  onClick: () => onClickDuplicateCustomProgram(row.id),
                  dataTestId: `duplicate-program-option-${row.id}`,
                },
                {
                  label: (
                    <span style={{color: COLORS.Red800}}>
                      {i18n.t(k.PROGRAM__DELETE)}
                    </span>
                  ),
                  onClick: () => onClickDeleteProgram(row?.id),
                  dataTestId: `delete-program-option-${row.id}`,
                },
              ]}
            />
          );
        },
      },
      {
        title: null,
        dataIndex: null,
        width: '48px',
        fixed: 'right',
        key: 'cohortsPageChevron',
        render: (_, row: CustomProgramAdminDetailVM) => {
          return showRowHoverStyle(row) ? (
            <CaretRight
              data-testid="icon--caret-right"
              fill={COLORS.Neutral600}
              style={{display: 'flex'}}
            />
          ) : null;
        },
      },
    ];
  }, [duplicatingCustomProgramId]);

  if (isLoading) {
    return (
      <NoDataText
        header={i18n.t(k.STATUS__LOADING)}
        subHeader={<LoadingSpinner />}
      />
    );
  }

  return (
    <EmptyBlockUntilTrue
      condition={!!dataSource?.length}
      emptyHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
      emptyBody={i18n.t(k.PROGRAM__TABLE__EMPTY__DESCRIPTION)}>
      <LearnInTable
        scroll={{x: 'max-content'}}
        hasContainer={hasContainer}
        showRowHoverStyle={showRowHoverStyle}
        columns={columns}
        dataSource={dataSource}
        pagination={pagination}
        onClickRow={onClickRow}
      />
    </EmptyBlockUntilTrue>
  );
}

export default CustomProgramsTable;
