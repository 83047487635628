// https://linear.app/learnin/issue/LI-1682/change-usages-of-categorytype-to-be-planitemtype

import {PlanItemType} from '@generated/enums';

export enum CategoryName {
  PROGRAM = 'Program',
  TIME = 'Time',
  FINANCE = 'Finance',
}

export interface FeaturedCategoryMap {
  [PlanItemType.Time]: any[];
  [PlanItemType.Program]: any[];
  [PlanItemType.Finance]: any[];
}

export enum USER_PROGRAM_STATUS {
  Planned,
  Applied,
  Admitted,
  Denied,
  InProgress,
  Complete,
  Cancelled,
}

export enum TimeType {
  Hours = 'Hours',
  Days = 'Days',
  Weeks = 'Weeks',
  Months = 'Months',
}

export enum TimeName {
  Hours,
  Days,
  Weeks,
  Months,
}

export enum TimeAndFinanceApprovalsStatus {
  ReadyToSubmit = 'Ready to Submit',
  PendingManager = 'Pending Manager',
  ManagerRejected = 'Manager Rejected',
  ManagerCancelled = 'Manager Cancelled',
  PendingHR = 'Pending HR',
  HRRejected = 'HR Rejected',
  HRCancelled = 'HR Cancelled',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Unknown = 'Unknown',
}

export enum UserProgressStatus {
  UPCOMING = 'Upcoming',
  DUE = 'Due',
  LATE = 'Late',
  SUBMITTED = 'Submitted',
}

export enum ReimbursementStatus {
  NOT_SUBMITTED = 'Not Submitted',
  SUBMITTED = 'Submitted',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  PAYMENT_PENDING = 'Payment Pending',
  PAID = 'Paid',
}

export enum TimeEventsStatus {
  NOT_SUBMITTED = 'NotSubmitted',
  SUBMITTED = 'Submitted',
  SCHEDULEAPPROVED = 'ScheduleApproved',
  SCHEDULEREJECTED = 'ScheduleRejected',
  COMPLETE = 'Completed',
  CANCELLED = 'Cancelled',
}
