import {DefaultPreApprovalQuestions} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: DefaultPreApprovalQuestions

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getDefaultPreApprovalQuestionsMockData(
  args?: Partial<DefaultPreApprovalQuestions>
): DefaultPreApprovalQuestions {
  return {
    addDefaultFinanceQuestions: faker.datatype.boolean(),
    addDefaultLicenseQuestions: faker.datatype.boolean(),
    addDefaultProgramQuestions: faker.datatype.boolean(),
    addDefaultTimeQuestions: faker.datatype.boolean(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const DefaultPreApprovalQuestionsMockData: DefaultPreApprovalQuestions =
  {
    addDefaultFinanceQuestions: true,
    addDefaultLicenseQuestions: true,
    addDefaultProgramQuestions: true,
    addDefaultTimeQuestions: true,
  };
