import {useState, useEffect, useRef} from 'react';
import * as React from 'react';
import AcademyContentLevelScreenView from './AcademyContentLevelScreen.view';
import {useParams, useSearchParams, useLocation} from 'react-router-dom';
import {SectionNavContainer} from '../SectionNav';
import {useUserPlanProgramItemVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {getBlockingTitle} from '@utils/requirementGating';
import {simpleMutationFn, STALE} from '@store/queryClient';
import {getAddStepDateToCalendarRm} from '@store/apiEndpoints/academy/mutations';
import {useMutation} from 'react-query';
import {notify} from '@components/user/notifications';
import {ScreenContainer} from './AcademyContentLevelScreen.styled';
import {CalendarCallbackRedirect} from '@generated/enums';
import ConnectCalendarModalContainer from '@blocks/connectCalendarModal/ConnectCalendarModal.container';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {UserPaths} from '@utils/ClientPaths';
import {theme} from '@utils/color';
import {useSaveScrollPosition} from '@hooks/academies/academyUserActivity';
import ResponsiveVisibilityToggle from '../ResponsiveVisibilityToggle/ResponsiveVisibilityToggle';
import {i18n, k} from '@i18n/translate';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {
  useAcademy,
  useAcademyLevels,
  useAcademyLevelStep,
} from '../academy.queries';

function AcademyContentLevelScreenContainer() {
  // Hooks
  const {isFeatureFlagOn} = useFeatureFlags();
  const {levelId, academyId} = useParams();
  const [searchParams] = useSearchParams();
  const {pathname} = useLocation();
  const {saveScrollPosition} = useSaveScrollPosition();
  const scrollRef = useRef<HTMLDivElement>(null);

  // State
  const [eventsAreAdding, setEventsAreAdding] = React.useState(false);
  const [showConnectCalendarModal, setShowConnectCalendarModal] =
    React.useState(false);
  const [blockingTitle, setBlockingTitle] = useState<string | null>(null);

  // Data Fetching
  const {user} = useAuth();
  const {academy, isLoading: isLoadingAcademy} = useAcademy(
    parseInt(academyId)
  );
  const {levels: academyLevels, isLoading: isLoadingLevels} = useAcademyLevels({
    academyId: parseInt(academyId),
  });
  const {data: plan} = useUserPlanProgramItemVMQuery(2);

  const selectedLevel = academyLevels?.find(
    (level) => level.id === parseInt(levelId)
  );

  const {
    data: userAcademyLevelStep,
    isLoading: isUserAcademyLevelStepLoading,
    isSuccess: isAcademyLevelSuccess,
  } = useAcademyLevelStep(selectedLevel?.id, !!selectedLevel);

  // Scroll To Content from URL params
  const selectedContentId =
    searchParams?.has('scrollContentId') &&
    parseInt(searchParams.get('scrollContentId'));

  useEffect(() => {
    if (
      isAcademyLevelSuccess &&
      searchParams.has('lastPosition') &&
      !Number.isNaN(parseInt(searchParams.get('lastPosition')))
    ) {
      /** Brief delay to wait for the drawer animation */
      setTimeout(() => {
        scrollRef.current?.scrollTo({
          top: parseInt(searchParams.get('lastPosition')),
          behavior: 'smooth',
        });
      }, 500);
    }
  }, [isAcademyLevelSuccess, searchParams]);

  // Mutations
  const addDatesToCalendarMutation = useMutation(
    ({academyStepId}: {academyStepId: number}) => {
      const queryRm = getAddStepDateToCalendarRm(academyStepId);
      return simpleMutationFn(queryRm.path, queryRm.payload);
    },
    {
      onSuccess: () => {
        notify.addEventsToCalendarSuccess();
      },
      onError: () => {
        notify.addEventsToCalendarError();
      },
    }
  );

  // Event Handlers
  const handleClickAddDatesToCalendar = async () => {
    const addStepEventsPromises = userAcademyLevelStep
      ?.filter((step) => !!step.startDateUtc)
      .map((step) =>
        addDatesToCalendarMutation.mutateAsync({
          academyStepId: step.id,
        })
      );

    if (!!addStepEventsPromises?.length) {
      setEventsAreAdding(true);
      await Promise.all([addStepEventsPromises]).then(() =>
        setEventsAreAdding(false)
      );
    }
  };

  useEffect(() => {
    if (!!selectedLevel && !!academyLevels) {
      setBlockingTitle(
        getBlockingTitle(selectedLevel?.requiredId, academyLevels)
      );
    }
  }, [academyLevels, selectedLevel]);

  const thereAreUnconnectedEvents = userAcademyLevelStep?.some(
    ({startDateUtc, isEventConnectedToCalendar}) => {
      return !!startDateUtc && !isEventConnectedToCalendar;
    }
  );

  return (
    <ScreenContainer
      branding={
        !!academy?.branding?.primaryBrandColor // Legacy academies without brand colors will use the default theme
          ? academy.branding
          : theme
      }>
      <ResponsiveVisibilityToggle
        style={{
          background: academy?.branding?.primaryBrandColor,
          color: academy?.branding?.itemDefaultTextNavColor,
        }}
        defaultVisibility={true}
        setToggleTitle={(isVisible) =>
          i18n.t(
            isVisible ? k.CTA__HIDE_ITEM__FORMAT : k.CTA__SHOW_ITEM__FORMAT,
            {item: i18n.t(k.SECTION__PLURAL)}
          )
        }>
        <SectionNavContainer />
      </ResponsiveVisibilityToggle>

      <AcademyContentLevelScreenView
        isLoading={
          isLoadingAcademy ||
          isLoadingLevels ||
          isUserAcademyLevelStepLoading ||
          blockingTitle === null
        }
        academy={{
          ...academy,
          branding: !!academy?.branding?.primaryBrandColor // Legacy academies without brand colors will use the default theme
            ? academy.branding
            : theme,
        }}
        addEventsToCalendarLoading={eventsAreAdding}
        blockingTitle={blockingTitle}
        isActivePlanItem={pathname.includes(UserPaths.Academies)}
        isExternalCalendarConnected={!!user?.connectedCalendar}
        level={selectedLevel}
        onClickAddDatesToCalendar={handleClickAddDatesToCalendar}
        onClickConnectCalendar={() => setShowConnectCalendarModal(true)}
        plan={plan}
        selectedContentId={selectedContentId}
        showConnectCalendarCard={
          isFeatureFlagOn.ConnectCalendar && thereAreUnconnectedEvents
        }
        showGatedCardIfStepIsBlocked={pathname.includes(UserPaths.Academies)}
        steps={userAcademyLevelStep}
        onScroll={saveScrollPosition}
        scrollRef={scrollRef}
      />
      <ConnectCalendarModalContainer
        handleCloseCalendarModal={() => setShowConnectCalendarModal(false)}
        id={academy?.id}
        redirectOption={CalendarCallbackRedirect.Academy}
        visible={showConnectCalendarModal}
      />
    </ScreenContainer>
  );
}

export default AcademyContentLevelScreenContainer;
