import {Form, Radio} from 'antd';

import {i18n, k} from '@i18n/translate';
import {InputLabel} from '@components/reusable/LearnInForm';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {COLORS} from '@utils/constants';
import {
  AcademyStepType,
  AcademyStepRequirementProperty,
} from '@generated/enums';

interface StepRequirementFieldsProps {
  stepRequirementOptions: {id: number; title: string; type?: AcademyStepType}[];
  showFields: boolean;
  onClickAdd?: () => void;
  onClickRemove?: () => void;
  setPreviousStepIsRequired?: (value: boolean) => void;
  currentlySelectedRequirementId?: number;
  previousStepIsRequired?: boolean;
}

export function StepRequirementFields({
  stepRequirementOptions,
  setPreviousStepIsRequired,
  showFields,
  onClickAdd,
  onClickRemove,
  currentlySelectedRequirementId,
  previousStepIsRequired,
}: StepRequirementFieldsProps) {
  const currentRequirementIsMarketplaceProgram =
    stepRequirementOptions.find(({id}) => id === currentlySelectedRequirementId)
      ?.type === AcademyStepType.MarketplaceProgram;

  return stepRequirementOptions.length > 0 ? (
    <div style={{width: '100%'}}>
      {showFields ? (
        <div>
          <div>
            <InputLabel
              htmlFor="required-section-select"
              label={i18n.t(k.LEVEL__REQUIREMENT__WHICH_STEP)}
            />
            <Form.Item name="previousStepRequired">
              <Radio
                onClick={() => {
                  setPreviousStepIsRequired(true);
                }}
                checked={previousStepIsRequired}>
                {i18n.t(k.LEVEL__REQUIREMENT__PREVIOUS_STEP)}
              </Radio>
              <br />
              <Radio
                onClick={() => {
                  setPreviousStepIsRequired(false);
                }}
                checked={!previousStepIsRequired}>
                {i18n.t(k.LEVEL__REQUIREMENT__SPECIFIC_STEP)}
              </Radio>
            </Form.Item>
            <div style={{display: previousStepIsRequired ? 'none' : 'block'}}>
              <Form.Item name="requiredId">
                <LearnInSelect
                  id="required-section-select"
                  placeholder={i18n.t(k.LEVEL__SEARCH)}
                  style={{
                    marginRight: '12px',
                    width: '100%',
                    maxWidth: '400px',
                  }}
                  showSearch
                  allowClear
                  filterOption={false}>
                  {stepRequirementOptions?.map(({id, title}) => {
                    return (
                      <LearnInSelectOption value={id} key={id}>
                        {title}
                      </LearnInSelectOption>
                    );
                  })}
                </LearnInSelect>
              </Form.Item>
            </div>
          </div>
          {currentRequirementIsMarketplaceProgram && (
            <div
              style={{
                backgroundColor: COLORS.Neutral100,
                padding: '16px 16px 1px 16px',
                borderRadius: '4px',
                marginBottom: '30px',
              }}>
              <InputLabel
                htmlFor="program-requirement-property"
                label={i18n.t(k.LEVEL__REQUIREMENT__IS_PROGRAM_STEP)}
              />
              <Form.Item
                name="requirementProperty"
                id="program-requirement-property">
                <Radio.Group>
                  <Radio value={AcademyStepRequirementProperty.Completion}>
                    {i18n.t(
                      k.LEVEL__REQUIREMENT__SPECIFIC_STEP_MARKED_COMPLETE
                    )}
                  </Radio>
                  <br />
                  <Radio value={AcademyStepRequirementProperty.Approval}>
                    {i18n.t(k.LEVEL__REQUIREMENT__SPECIFIC_STEP_APPROVED)}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          )}
          {onClickRemove && (
            <LearnInButton
              noPadding
              style={{position: 'relative', top: '-14px'}}
              tag={ButtonTags.DestructiveRemovedBorder}
              onClick={onClickRemove}>
              {i18n.t(k.LEVEL__REQUIREMENT__REMOVE)}
            </LearnInButton>
          )}
        </div>
      ) : (
        <div style={{marginBottom: '24px'}}>
          <LearnInButton
            noPadding
            tag={ButtonTags.Link}
            color={COLORS.Blue800}
            onClick={onClickAdd}>
            + {i18n.t(k.LEVEL__REQUIREMENT__ADD)}
          </LearnInButton>
        </div>
      )}
    </div>
  ) : null;
}
