import {Route, Routes} from 'react-router-dom';
import ManageProgram from './components/manageProgram/ManageProgram';
import ManageFinanceWrapper from '@pages/manageFinance/ManageFinanceWrapper';
import UserPlan from './UserPlan';
import ManageUserPlanWrapper from './components/manageUserPlan/ManageUserPlanWrapper';
import {i18n, k} from '@i18n/translate';
import useFeatureFlags from '@hooks/useFeatureFlags';

function UserPlanInternalRoutes(props: any) {
  const {isFeatureFlagOn} = useFeatureFlags();
  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <UserPlan
            title={
              isFeatureFlagOn.YourPlanNameChange
                ? i18n.t(k.GENERIC__HOME)
                : i18n.t(k.PLAN__YOUR)
            }
          />
        }
      />
      <Route
        path={`program/:id`}
        element={<ManageProgram title={i18n.t(k.PROGRAM)} {...props} />}
      />
      <Route
        path={`time/:id`}
        element={<ManageUserPlanWrapper title={i18n.t(k.TIME)} {...props} />}
      />
      <Route
        path={`finance/:id`}
        element={<ManageFinanceWrapper title={i18n.t(k.GENERIC__FINANCE)} />}
      />
    </Routes>
  );
}

//removed withRouter
export default UserPlanInternalRoutes;
