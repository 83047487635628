import {useEffect, useState} from 'react';
import {getProgressReportDetailsRq} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {
  ProgramProgressUpdateDetailsVM,
  ProgramProgressUpdateResult,
} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import ProgressReportList from '@components/admin/pages/reports/components/ProgressReportList/ProgressReportList';

interface Props {
  data: ProgramProgressUpdateResult[];
}
export default function ProgressReportListWrapper({data}: Props) {
  const [idToFetch, setIdToFetch] = useState<number | string | null>(null);
  const progressReportDetailsRq = getProgressReportDetailsRq(idToFetch);
  const progressReportDetailsQuery = useQuery<ProgramProgressUpdateDetailsVM[]>(
    progressReportDetailsRq.queryKey,
    () => simpleQueryFn(progressReportDetailsRq.path),
    {
      enabled: false,
    }
  );

  useEffect(() => {
    progressReportDetailsQuery.refetch();
  }, [idToFetch]);

  return (
    <ProgressReportList
      data={data}
      fetchProgressReportDetails={(id: string | number) => setIdToFetch(id)}
      progressReportDetailLoading={progressReportDetailsQuery.isFetching}
      progressReportDetailData={progressReportDetailsQuery.data}
    />
  );
}
