import {CurrencyCode, UserFinanceIncentiveUpdateType} from '@generated/enums';
import {CreditCardAdminActionVM} from '@models/api/payment/viewModels';
import {COLORS} from '@utils/constants';
import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {formatCurrency} from '@utils/moneyUtils';

const Container = styled.div`
  padding-left: 19px;
`;

const ActivityContainer = styled.div`
  position: relative;
  padding: 16px;
`;

const ActivityDate = styled(Text)`
  width: 141px;
  left: 40px;
  top: 16px;

  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.Neutral600};
`;

const ActivityDetails = styled.div`
  left: 40px;
  top: 34px;
`;

const ActivityNote = styled.div`
  background: #f2f2f2;
  border-radius: 4px;
  padding: 16px;
`;

const IndexRule = styled.div`
  border-left: 1px solid ${COLORS.Neutral200};
  width: 1px;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 23px;
  z-index: 10;
`;

const IndexRuleTop = styled(IndexRule)`
  height: 0px;
`;

const IndexRuleBottom = styled(IndexRule)``;

const INDEX_SIZE = 8;
const Index = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.Neutral200};
  border-radius: 100%;
  color: ${COLORS.Neutral600};
  font-weight: 500;
  font-size: 0.5rem;
  margin-left: -20px;
  width: ${INDEX_SIZE}px;
  height: ${INDEX_SIZE}px;
  background: ${COLORS.Neutral200};
  z-index: 20;
  padding: 0px;
  position: absolute;
  top: 23px;
`;

const EmptyIndex = styled.div`
  width: 42px;
`;

export interface AdminActivityProps {
  activity: CreditCardAdminActionVM;
  index: number;
  cardCount: number;
  usersCurrency: CurrencyCode;
  exchangeRate: number;
}

function AdminActivity({
  activity,
  index,
  cardCount,
  usersCurrency,
  exchangeRate,
}: AdminActivityProps) {
  const first = index === 0;
  const last = index === cardCount - 1;
  return (
    <Container>
      <ActivityContainer first={first} last={last}>
        {!first && cardCount > 1 && <IndexRuleTop />}
        {!last && cardCount > 1 && <IndexRuleBottom />}
        {cardCount > 1 ? <Index></Index> : <EmptyIndex />}
        <ActivityDate>{activity.timestamp}</ActivityDate>
        <ActivityDetails>
          {activity?.details?.length &&
            activity?.details?.map((detail, idx) => {
              return (
                <>
                  {idx === 0 && <Text strong>{activity.updatedBy} </Text>}
                  <Text>
                    <span
                      dangerouslySetInnerHTML={{
                        __html:
                          detail?.type === UserFinanceIncentiveUpdateType.Note
                            ? i18n.t(k.ADMIN_ACTIVITY__NOTE)
                            : detail?.type ===
                              UserFinanceIncentiveUpdateType.Limit
                            ? i18n.t(k.ADMIN_ACTIVITY__DETAIL_LIMIT__FORMAT, {
                                fromAmount: formatCurrency(
                                  detail?.amountBeforeUpdate,
                                  usersCurrency,
                                  exchangeRate
                                ),
                                toAmount: formatCurrency(
                                  detail?.amountAfterUpdate,
                                  usersCurrency,
                                  exchangeRate
                                ),
                              })
                            : detail?.type ===
                              UserFinanceIncentiveUpdateType.Balance
                            ? i18n.t(k.ADMIN_ACTIVITY__DETAIL_BALANCE__FORMAT, {
                                fromAmount: formatCurrency(
                                  detail?.amountBeforeUpdate,
                                  usersCurrency,
                                  exchangeRate
                                ),
                                toAmount: formatCurrency(
                                  detail?.amountAfterUpdate,
                                  usersCurrency,
                                  exchangeRate
                                ),
                              })
                            : i18n.t(
                                k.ADMIN_ACTIVITY__DETAIL_LEARNING_BUDGET__FORMAT,
                                {
                                  fromAmount: formatCurrency(
                                    detail?.amountBeforeUpdate,
                                    usersCurrency,
                                    exchangeRate
                                  ),
                                  toAmount: formatCurrency(
                                    detail?.amountAfterUpdate,
                                    usersCurrency,
                                    exchangeRate
                                  ),
                                }
                              ),
                      }}></span>
                  </Text>
                  {activity?.details?.length - 1 > idx && (
                    <Text> {i18n.t('GENERIC__AND').toLocaleLowerCase()} </Text>
                  )}
                </>
              );
            })}
        </ActivityDetails>
        {activity?.note && <ActivityNote>{activity?.note}</ActivityNote>}
      </ActivityContainer>
    </Container>
  );
}

export default AdminActivity;
