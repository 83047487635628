import {UserProgressSummary} from './../enums';
import {ProgramProgressUpdate} from './../interfaces';
import {
  ProgressSkillMockData,
  getProgressSkillMockData,
} from './ProgressSkill.mock';
import {faker} from '@faker-js/faker';

// Interface: ProgramProgressUpdate

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramProgressUpdateMockData(
  args?: Partial<ProgramProgressUpdate>
): ProgramProgressUpdate {
  return {
    content: faker.word.noun(),
    id: faker.datatype.number(),
    rating: faker.datatype.number(),
    skills: [getProgressSkillMockData()],
    submitted: faker.datatype.boolean(),
    summary: UserProgressSummary.FeelingGreat, // optional
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramProgressUpdateMockData: ProgramProgressUpdate = {
  content: 'string',
  id: 1,
  rating: 123,
  skills: [ProgressSkillMockData],
  submitted: true,
  summary: UserProgressSummary.FeelingGreat, // optional
  title: 'Advanced Web Design',
};
