import React from 'react';
import {Checkbox, Form} from 'antd';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {FORM_KEYS} from './constants';

const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  .ant-form-item-control-input {
    min-height: unset;
  }
`;

interface FormCheckboxProps {
  formKey: string;
  initialValue?: boolean;
  children?: React.ReactNode;
}

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  initialValue,
  children,
  formKey,
}) => {
  return (
    <FormItem
      style={{marginBottom: 0}}
      name={formKey}
      initialValue={initialValue}
      valuePropName="checked">
      <Checkbox>{children}</Checkbox>
    </FormItem>
  );
};

export default React.memo(FormCheckbox);

export const AllowSkipCheckbox: React.FC<
  Pick<FormCheckboxProps, 'initialValue'>
> = ({initialValue}) => (
  <FormCheckbox formKey={FORM_KEYS.ALLOW_SKIP} initialValue={initialValue}>
    {i18n.t(k.ACADEMY_STEP_CHECKBOX_LABEL_ALLOW_SKIP_STEP)}
  </FormCheckbox>
);

export const PeerVisibilityCheckbox: React.FC<
  Pick<FormCheckboxProps, 'initialValue'>
> = ({initialValue}) => (
  <FormCheckbox
    formKey={FORM_KEYS.ALLOW_PEER_VISIBILITY}
    initialValue={initialValue}>
    {i18n.t(k.PROGRAM__CUSTOM__PEER__VISIBILITY)}
  </FormCheckbox>
);
