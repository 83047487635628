import {LanguagePreference} from './../enums';
import {RequestedEmail} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: RequestedEmail

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getRequestedEmailMockData(
  args?: Partial<RequestedEmail>
): RequestedEmail {
  return {
    amount: String(faker.datatype.number()),
    approvalRank: faker.word.noun(), // optional
    buttonUrl: faker.internet.url(),
    companyLogoUrl: faker.internet.url(), // optional
    itemType: faker.word.noun(),
    languagePreference: LanguagePreference.EN_US,
    name: faker.name.fullName(),
    programName: faker.name.fullName(),
    providerImageUrl: faker.image.imageUrl(), // optional
    providerName: faker.name.fullName(),
    requesterName: faker.name.fullName(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const RequestedEmailMockData: RequestedEmail = {
  amount: '5',
  approvalRank: 'string', // optional
  buttonUrl: 'https://this-person-does-not-exist.com/en',
  companyLogoUrl: 'https://this-person-does-not-exist.com/en', // optional
  itemType: 'string',
  languagePreference: LanguagePreference.EN_US,
  name: 'Bobby Johnson',
  programName: 'Bobby Johnson',
  providerImageUrl: 'https://loremflickr.com/640/480', // optional
  providerName: 'Bobby Johnson',
  requesterName: 'Bobby Johnson',
};
