import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/
export const ToggleListUl = styled.ul`
  list-style: none;
  padding: 0;
  li {
    align-items: center;
    border-bottom: solid 1px ${COLORS.Neutral200};
    color: ${COLORS.Neutral900};
    display: flex;
    font-size: 1rem;
    list-style: none;
    > * {
      flex: 1;
    }
    .icon {
      background: ${COLORS.White};
      border: solid 1px ${COLORS.Neutral100};
      border-radius: 4px;
      flex-grow: 0;
      margin: 8px 4px 8px 0;
      padding: 6px;
      > svg,
      > img {
        display: block;
      }
    }
    &:first-child {
      border-top: solid 1px ${COLORS.Neutral200};
    }
  }
`;

export const ToggleListActionCol = styled.div`
  text-align: right;
`;

export const ToggleListButton = styled.button`
  color: ${COLORS.Blue800};
  font-size: 1rem;
  cursor: pointer;
  background: none;
  border: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export interface IToggleListItem {
  handleToggleChange: () => void;
  title: string;
  id: string;
  iconSrc?: string;
  iconAlt?: string;
  isOn?: boolean;
}

export interface IToggleListProps {
  items: IToggleListItem[];
  iconSize?: number;
  activeText?: string;
  inactiveText?: string;
}

export default function ToggleList({
  items,
  iconSize,
  activeText,
  inactiveText,
}: IToggleListProps) {
  iconSize = iconSize ?? 20;
  activeText = activeText ?? i18n.t(k.CTA__DISCONNECT);
  inactiveText = inactiveText ?? i18n.t(k.CTA__CONNECT);

  const rows = [];
  items.map((item) => {
    rows.push(
      <li key={item.id}>
        {item.iconSrc && (
          <span className={'icon'}>
            <img
              src={item.iconSrc}
              alt={item.iconAlt ?? ''}
              width={iconSize}
              height={iconSize}
            />
          </span>
        )}
        <span>{item.title}</span>
        <ToggleListActionCol>
          <ToggleListButton
            aria-label={`${item.title}: ${
              item.isOn ? activeText : inactiveText
            }`}
            onClick={item.handleToggleChange}>
            {item.isOn ? activeText : inactiveText}
          </ToggleListButton>
        </ToggleListActionCol>
      </li>
    );
  });

  return rows.length && <ToggleListUl>{rows}</ToggleListUl>;
}
