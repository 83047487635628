import {useState} from 'react';

function useTrackIds() {
  const [ids, setIds] = useState([]);

  const updateIds = (id) => {
    setIds((ids) => {
      if (ids.includes(id)) {
        const updatedIds = ids.filter((_id) => _id !== id);
        return [...updatedIds];
      } else {
        return [...ids, id];
      }
    });
  };

  return {ids, updateIds};
}
export default useTrackIds;
