import {CustomizableLearningCategory} from './../enums';
import {
  LinkPayload,
  UserPlanProjectSubmission,
  UserPlanTemplate,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {LinkPayloadSchema} from '../zodSchemas/LinkPayloadSchema.schema';
import {UserPlanProjectSubmissionSchema} from '../zodSchemas/UserPlanProjectSubmissionSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/UserPlan/UserPlanController.cs

/*
|--------------------------------------------------------------------------
|  upload-project-submission/{projectId}/{customizableLearningCategory}/upload/{uploadId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUploadProjectSubmissionByProjectIdMutation} from '@generated/hooks';
 *
 *  const uploadProjectSubmissionByProjectId = useUploadProjectSubmissionByProjectIdMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  uploadProjectSubmissionByProjectId.mutate({
 *    pathVars: {
 *      customizableLearningCategory,
 *      projectId,
 *      uploadId
 *    }
 *  })
 *
 */

export const useUploadProjectSubmissionByProjectIdMutation =
  mutationHookFactory<
    null,
    {
      customizableLearningCategory: CustomizableLearningCategory;
      projectId: number;
      uploadId: number;
    }, // path variables
    number // return type
  >(
    'useUploadProjectSubmissionByProjectIdMutation',
    'put',
    ({projectId, customizableLearningCategory, uploadId}) =>
      `user-plan/upload-project-submission/${projectId}/${customizableLearningCategory}/upload/${uploadId}`,
    {payloadSchema: undefined}
  );

/*
|--------------------------------------------------------------------------
|  submit-project/{customizableLearningCategory}/content/{contentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserPlanSubmitProjectContentMutation} from '@generated/hooks';
 *
 *  const userPlanSubmitProjectContent = useUserPlanSubmitProjectContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  userPlanSubmitProjectContent.mutate({
 *    payload: {
 *      ...UserPlanProjectSubmission
 *    },
 *    pathVars: {
 *      contentId,
 *      customizableLearningCategory
 *    }
 *  })
 *
 */

export const useUserPlanSubmitProjectContentMutation = mutationHookFactory<
  UserPlanProjectSubmission, // payload
  {
    contentId: number;
    customizableLearningCategory: CustomizableLearningCategory;
  }, // path variables
  UserPlanTemplate // return type
>(
  'useUserPlanSubmitProjectContentMutation',
  'put',
  ({customizableLearningCategory, contentId}) =>
    `user-plan/submit-project/${customizableLearningCategory}/content/${contentId}`,
  {payloadSchema: UserPlanProjectSubmissionSchema}
);

/*
|--------------------------------------------------------------------------
|  submit-project/{customizableLearningCategory}/content/{contentId}/link 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserPlanSubmitProjectContentLinkMutation} from '@generated/hooks';
 *
 *  const userPlanSubmitProjectContentLink = useUserPlanSubmitProjectContentLinkMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  userPlanSubmitProjectContentLink.mutate({
 *    payload: {
 *      ...LinkPayload
 *    },
 *    pathVars: {
 *      contentId,
 *      customizableLearningCategory
 *    }
 *  })
 *
 */

export const useUserPlanSubmitProjectContentLinkMutation = mutationHookFactory<
  LinkPayload, // payload
  {
    contentId: number;
    customizableLearningCategory: CustomizableLearningCategory;
  }, // path variables
  LinkPayload // return type
>(
  'useUserPlanSubmitProjectContentLinkMutation',
  'put',
  ({customizableLearningCategory, contentId}) =>
    `user-plan/submit-project/${customizableLearningCategory}/content/${contentId}/link`,
  {payloadSchema: LinkPayloadSchema}
);
