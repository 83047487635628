import {datadogLogs} from '@datadog/browser-logs';
import {useState, useEffect} from 'react';
import {i18n, k} from '@i18n/translate';
import {useQuery, useMutation} from 'react-query';
import {
  simpleQueryFn,
  simpleMutationFn,
  simpleDeleteFn,
} from '@store/queryClient';
import {CUSTOM_CONTENT_DESCRIPTION_MAX_LENGTH} from '@utils/constants';
import {
  getAddAcademyContentStep,
  getUpdateCustomAcademyAttachmentRm,
  getUpdateAcademyContentStepRm,
  getDeleteAcademyStepRm,
  getDeleteAcademyStepContentAttachmentRm,
} from '@store/apiEndpoints/academy/mutations';
import {
  getCustomStepContentLinksRq,
  getCustomAcademyContentAttachmentsRq,
} from '@store/apiEndpoints/academy/queries';
import {
  AcademyStepAttachment,
  AcademyStepContentPayload,
  CustomContentLink,
  AcademyStepContentUpdatePayload,
  AttachmentResponse,
} from '@models/serverModels';
import {AcademyStepType, CustomizableLearningCategory} from '@generated/enums';
import useChunkUpload from '@hooks/useChunkUpload';
import {notify} from '@components/user/notifications';
import CustomContentModal from './CustomContentModal';
import {Modal} from 'antd';
import Form from 'antd/es/form';
import {useUpdateAcademyStepRequirement} from '@hooks/apiEndpoints/academy/mutations';
import {basicSorter} from '@utils/tableUtils';
import {AttachmentLinkType} from '@generated/enums';
import {
  useUserQuery,
  useAcademyLevelsQuery,
  useAcademyLevelStepQuery,
} from '@generated/hooks';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomContentModalContainerProps {
  id?: number | undefined;
  onCancel: () => void;
  title?: string;
  description?: string;
  visible: boolean;
  academyId: number;
  levelId?: number;
  stepId?: number;
  attachmentGuids?: string[];
}

const {confirm} = Modal;

function CustomContentModalContainer({
  visible,
  onCancel,
  id,
  title,
  description,
  levelId,
  academyId,
  stepId,
}: CustomContentModalContainerProps) {
  const {data: user} = useUserQuery(null);

  // Attachment Uploading
  const {fileId, chunkUpload, uploadProgress, cancelUpload} = useChunkUpload({
    query: getUpdateCustomAcademyAttachmentRm,
    customizableLearningCategory: CustomizableLearningCategory.Academy,
    onComplete: () => {
      contentAttachmentsQuery.refetch();
    },
  });

  const [showStepRequirementOptions, setShowStepRequirementOptions] =
    useState<boolean>(false);

  // Get Content Links and Attachments
  const customStepContentLinksRq = getCustomStepContentLinksRq(id);
  const customStepContentQuery = useQuery<CustomContentLink[]>(
    customStepContentLinksRq.queryKey,
    () => simpleQueryFn(customStepContentLinksRq.path),
    {
      placeholderData: () => [],
      enabled: !!id,
    }
  );

  const [form] = Form.useForm();

  const {invalidateExact: invalidateAcademyLevels} = useAcademyLevelsQuery({
    academyId,
    queryParams: {
      excludeUserProgress: true,
    },
  });

  const {
    data: steps,
    isLoading: isLoadingSteps,
    invalidateExact: invalidateSteps,
  } = useAcademyLevelStepQuery({
    academyLevelId: levelId,
  });

  const step = !!stepId && steps?.find((s) => s.id === stepId);
  const stepRequirementOptions = steps
    ?.filter((s) => !step || s.order < step?.order)
    .sort(basicSorter('order'))
    .map((s) => ({
      title: s.title,
      id: s.id,
    }));

  useEffect(() => {
    if (!!step?.requiredId) {
      form.setFieldsValue({requiredId: step?.requiredId});
      setShowStepRequirementOptions(true);
    } else {
      form.setFieldsValue({requiredId: null});
      setShowStepRequirementOptions(false);
    }
  }, [step]);

  const customProgramContentAttachmentsRq =
    getCustomAcademyContentAttachmentsRq(id);
  const contentAttachmentsQuery = useQuery<AcademyStepAttachment[]>(
    customProgramContentAttachmentsRq.queryKey,
    () => simpleQueryFn(customProgramContentAttachmentsRq.path),
    {
      placeholderData: () => [],
      enabled: !!id,
    }
  );
  // Update custom content
  const updateCustomContentMutation = useMutation(
    (args: AcademyStepContentUpdatePayload) => {
      const updateCustomContentRm = getUpdateAcademyContentStepRm(args);
      return simpleMutationFn<number>(
        updateCustomContentRm.path,
        updateCustomContentRm.payload
      );
    },
    {
      onSuccess: () => {
        invalidateSteps();
      },
      onError: () => {
        notify.addCustomProgramContentError();
      },
    }
  );

  // Create custom content
  const addCustomContentMutation = useMutation(
    (args: AcademyStepContentPayload) => {
      const addCustomContentRm = getAddAcademyContentStep(args);
      return simpleMutationFn<number>(
        addCustomContentRm.path,
        addCustomContentRm.payload
      );
    },
    {
      onSuccess: () => {
        invalidateSteps();
      },
      onError: () => {
        notify.addCustomProgramContentError();
      },
    }
  );

  // Update Content
  const updateAcademyStepRequirement = useUpdateAcademyStepRequirement({
    onSuccess: () => {
      invalidateAcademyLevels();
      invalidateSteps();
    },
  });

  const handleOk = async (
    title: string,
    description: string,
    link: CustomContentLink,
    file: any,
    allowSkip: boolean
  ) => {
    let contentId;
    if (description.length > CUSTOM_CONTENT_DESCRIPTION_MAX_LENGTH) {
      return notify.addCustomProgramContentLengthError();
    }

    // Determine if edit or create update
    const isEditUpdate = !!id;

    if (!isEditUpdate) {
      // Add Content
      const newContentId = await addCustomContentMutation.mutateAsync({
        academyLevelId: levelId,
        type: AcademyStepType.TextContent,
        title,
        description,
        links: link ? [link] : [],
        attachmentIds: [],
        allowSkip,
      });
      contentId = newContentId;
    } else {
      await updateCustomContentMutation.mutateAsync({
        id,
        title,
        description,
        links: link ? [link] : [],
        allowSkip,
      });
      contentId = id;
    }
    const {requiredId} = form.getFieldsValue();
    updateAcademyStepRequirement.mutate({
      payload: {
        blockedId: contentId,
        requiredId,
      },
    });
    if (!!file) {
      try {
        await chunkUpload(file, {
          contentId,
          linkType: AttachmentLinkType.AcademyStepAttachment,
        });
      } catch (error) {
        datadogLogs.logger.info('Custom Content Upload Error', error);
        notify.uploadContentAttachmentError();
        return;
      }
    }
    if (isEditUpdate) {
      notify.updateStepSuccess();
    } else {
      notify.addStepSuccess();
    }
    onCancel();
  };

  const deleteContentAttachmentMutation = useMutation(
    (attachmentId: number) => {
      const deleteAcademyStepContentAttachmentRm =
        getDeleteAcademyStepContentAttachmentRm(attachmentId);
      return simpleDeleteFn<null>(
        deleteAcademyStepContentAttachmentRm.path,
        deleteAcademyStepContentAttachmentRm.payload
      );
    },
    {
      onSuccess: () => {
        contentAttachmentsQuery.refetch();
        notify.deleteCustomProgramContentAttachmentSuccess();
      },
      onError: () => {
        notify.deleteCustomProgramContentAttachmentError();
      },
    }
  );

  const deleteAcademyStepMutation = useMutation(
    () => {
      const deleteAcademyStepRm = getDeleteAcademyStepRm(id);
      return simpleDeleteFn<null>(
        deleteAcademyStepRm.path,
        deleteAcademyStepRm.payload
      );
    },
    {
      onSuccess: async () => {
        invalidateAcademyLevels();
        invalidateSteps();
        onCancel();
      },
      onError: () => {
        notify.deleteAcademyStepError();
      },
    }
  );

  const handleDelete = () => {
    confirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__DELETE_ITEM_YES__FORMAT, {
        item: i18n.t(k.CONTENT),
      }),
      okType: 'danger',
      title: i18n.t(k.CONTENT__DELETE),
      async onOk() {
        await deleteAcademyStepMutation.mutateAsync();
      },
    });
  };

  const handleDeleteAttachment = async () => {
    const attachmentId = contentAttachmentsQuery.data?.[0]?.id;
    await deleteContentAttachmentMutation.mutateAsync(attachmentId);
  };

  let attachmentData: AttachmentResponse | AcademyStepAttachment;
  if (!!id) {
    attachmentData = contentAttachmentsQuery.data?.[0];
  }

  return (
    <CustomContentModal
      cancelUpload={cancelUpload}
      companyId={user?.companyId}
      attachment={attachmentData}
      contentUploadPercent={uploadProgress}
      description={description || ''}
      allowSkip={!!step?.allowSkip}
      form={form}
      id={id}
      key={id}
      link={!!id ? customStepContentQuery.data?.[0] : undefined}
      onCancel={onCancel}
      onClickAddRequirement={() => setShowStepRequirementOptions(true)}
      onClickDelete={handleDelete}
      onClickRemoveRequirement={() => setShowStepRequirementOptions(false)}
      onDeleteAttachment={handleDeleteAttachment}
      onOk={handleOk}
      showStepRequirementOptions={showStepRequirementOptions}
      stepRequirementOptions={stepRequirementOptions}
      title={title || ''}
      visible={visible}
      isLoading={isLoadingSteps}
      showStepRequirementFields={!!stepRequirementOptions?.length}
    />
  );
}

export default CustomContentModalContainer;
