import * as React from 'react';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';

export const TableCell = styled.div`
  display: flex;
  align-items: center;
`;

export const TableColLink = styled.button`
  color: ${COLORS.Blue800};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  &:hover {
    text-decoration: underline;
  }
`;

export interface ITableCellButtonLinkProps {
  children?: React.ReactNode;
  onClick?: () => void;
}

export function TableCellButtonLink({
  children,
  onClick,
}: ITableCellButtonLinkProps) {
  return (
    <TableCell>
      <TableColLink
        role="button"
        onClick={(e) => {
          if (!!onClick) {
            e.stopPropagation();
            onClick?.();
          }
        }}>
        {children}
      </TableColLink>
    </TableCell>
  );
}
