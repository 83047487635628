import React from 'react';
import {Navigate, useNavigate} from 'react-router-dom';
import {i18n, k} from '@i18n/translate';
import PlanItemDetailPage from '@components/reusable/PlanItemDetailPage/PlanItemDetailPage.container';
import {PlanItemType} from '@generated/enums';
import {
  getManagerPermissionsRoute,
  getUserProgramsBasePath,
  getUserProgramsCustomProgramUserDrawer,
  ManagedPermissionsPageTabKey,
  ProgramDetailPathFragment,
  UserProgramsPageTab,
} from '@utils/ClientPaths';
import ProgramFinder from '@pages/ProgramFinder';
import CustomProgramUserDrawerWithRouting from '@blocks/CustomProgramUserDrawer/CustomProgramUserDrawerWithRouting.container';
import UserPlanInternalRoutes from '@components/user/pages/userPlan/UserPlanInternalRoutes';
import SettingsContainer from '@components/user/pages/settings/Settings.container';
import CustomProgramCurriculumPage from '@components/user/pages/customProgramCurriculum/CustomProgramCurriculumPage';
import Feedback from '@components/user/pages/feedback/Feedback';
import AcademiesListContainer from '@components/user/pages/academy/AcademiesList/AcademiesList.container';
import ManagerPermissionsPageContainer from '@components/user/pages/managedPermissions/ManagedPermissionsPage.container';
import PageNotFound from '404';
import {RouteElement, UseCanShowProgramsVM} from '@models/clientModels';
import {UserLogOut} from '@components/user/UserLogOut';
import {useGoBack} from './useGoBack';

// Lazy-Loaded Routes
const {lazy} = React;
const UserFinance = lazy(
  () => import('../components/user/pages/finance/UserFinance')
);
const UserTimeAllotments = lazy(
  () => import('../components/user/pages/timeAllotment/UserTimeAllotments')
);
const UserPrograms = lazy(
  () => import('../components/user/pages/program/UserPrograms')
);

export interface UseGetEnabledUserRoutesProps {
  canShowPrograms: UseCanShowProgramsVM;
  hideMarketplaceFromLxp: boolean;
  hidePrimaryNavigation: boolean;
  isAcademiesOn: boolean;
  isApprover: boolean;
  isIntegratedUser: boolean;
}

/** Returns the user-routes enabled for the user, based on the passed criteria.
 *  This is used in combination of useBuildRouteElements to handle complexity.
 **/
export const useGetEnabledUserRoutes = ({
  canShowPrograms,
  hideMarketplaceFromLxp,
  hidePrimaryNavigation,
  isAcademiesOn,
  isApprover,
  isIntegratedUser,
}: UseGetEnabledUserRoutesProps): RouteElement[] => {
  const enabled = true;
  const forNonIntegratedOrIntegratedApprover =
    !isIntegratedUser || (isIntegratedUser && isApprover);

  const pageNotFound = (
    <PageNotFound title={i18n.t(k.ERROR__PAGE__NOT__FOUND)} />
  );

  // As with static <Route />s, the order matters: the first match wins
  return [
    {
      path: '/',
      enabled,
      element: <Navigate to="/user/manage" />,
    },
    {
      path: 'logout',
      enabled,
      element: <UserLogOut />,
    },
    {
      path: 'time',
      enabled,
      element: <UserTimeAllotments title={i18n.t(k.TIME)} />,
    },
    {
      path: 'time-detail/:id',
      enabled,
      element: (
        <PlanItemDetailPageContainer
          title={i18n.t(k.TIME__DETAILS)}
          visible
          itemType={PlanItemType.Time}
          renderPageInsteadOfDrawer={hidePrimaryNavigation}
        />
      ),
    },
    {
      path: 'program-finder/*',
      enabled: forNonIntegratedOrIntegratedApprover,
      element: <ProgramFinder />,
    },
    {
      path: 'programs',
      enabled: forNonIntegratedOrIntegratedApprover,
      element: (
        <Navigate
          to={getUserProgramsBasePath(UserProgramsPageTab.MarketPlace)}
        />
      ),
    },
    {
      path: 'programs/:tab/*',
      enabled,
      element:
        hideMarketplaceFromLxp || !forNonIntegratedOrIntegratedApprover ? (
          <Navigate to={`/user/manage`} />
        ) : canShowPrograms.any ? (
          <UserPrograms title={i18n.t(k.PROGRAM__PLURAL)} />
        ) : !canShowPrograms.loading ? (
          pageNotFound
        ) : null,
      children: [
        {
          path: ':customProgramId/view/*',
          enabled,
          element: (
            <CustomProgramUserDrawerWithRoutingContainer
              title={i18n.t(k.PROGRAM__PLURAL)}
              isActivePlanItem
              getScreenNavItemUrlPath={getUserProgramsCustomProgramUserDrawer}
            />
          ),
        },
      ],
    },
    {
      path: ProgramDetailPathFragment,
      enabled,
      element: (
        <PlanItemDetailPageContainer
          title={i18n.t(k.PROGRAM__DETAILS)}
          visible
          itemType={PlanItemType.Program}
          renderPageInsteadOfDrawer={hidePrimaryNavigation}
        />
      ),
    },
    {
      path: 'financing',
      enabled: forNonIntegratedOrIntegratedApprover,
      element: <UserFinance title={i18n.t(k.GENERIC__FINANCING)} />,
    },
    {
      path: 'finance-detail/:id',
      enabled,
      element: (
        <PlanItemDetailPageContainer
          title={i18n.t(k.FINANCE__DETAILS)}
          itemType={PlanItemType.Finance}
          renderPageInsteadOfDrawer={hidePrimaryNavigation}
          visible
        />
      ),
    },
    {
      path: 'manage/*',
      enabled,
      element: <UserPlanInternalRoutes />,
    },
    {
      path: 'settings',
      enabled,
      element: (
        <SettingsContainer renderPageInsteadOfDrawer={hidePrimaryNavigation} />
      ),
    },
    {
      path: 'custom-program/:customProgramId',
      enabled,
      element: (
        <CustomProgramCurriculumPage
          title={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
        />
      ),
    },
    {
      path: 'feedback',
      enabled,
      element: <Feedback title={i18n.t(k.FEEDBACK)} />,
    },
    {
      path: 'academies/*',
      enabled,
      element: <AcademiesListContainer title={i18n.t(k.ACADEMY__PLURAL)} />,
    },
    {
      path: 'permissions/:tab/*',
      enabled: forNonIntegratedOrIntegratedApprover,
      element: (
        <ManagerPermissionsPageContainer title={i18n.t(k.PERMISSION__PLURAL)} />
      ),
    },
    {
      path: 'permissions',
      enabled: forNonIntegratedOrIntegratedApprover,
      element: (
        <Navigate
          to={getManagerPermissionsRoute(
            isAcademiesOn
              ? ManagedPermissionsPageTabKey.Academies
              : ManagedPermissionsPageTabKey.Programs
          )}
        />
      ),
    },
    {
      path: '*',
      enabled,
      element: pageNotFound,
    },
  ];
};

/*
|-----------------------------------------------------------
| Wrappers
|-----------------------------------------------------------
*/

function CustomProgramUserDrawerWithRoutingContainer(props: any) {
  const navigate = useNavigate();
  return (
    <CustomProgramUserDrawerWithRouting
      {...props}
      onClose={() => {
        navigate(getUserProgramsBasePath(UserProgramsPageTab.Custom));
      }}
    />
  );
}

function PlanItemDetailPageContainer(props: any) {
  const onClose = useGoBack();
  return <PlanItemDetailPage {...props} onClose={onClose} />;
}
