import {queryHookFactory} from '@hooks/apiEndpoints/utils';
import {
  NewReimbursementApprovalApplicationVM,
  UserPlanReimbursementItemVM,
} from '@models/serverModels';
import {
  getAttachmentURLRq,
  getReimbursementApprovalApplicationsRq,
  getReimbursementImageURLRq,
  getUserPreApprovedItemsRq,
} from '@store/apiEndpoints';

/*
|--------------------------------------------------------------------------
| Attachment
|--------------------------------------------------------------------------
*/

export const useGetReimbursementAttachmentUrlQuery = queryHookFactory<
  number,
  string
>(getReimbursementImageURLRq);

export const useGetReimbursementApprovalApplicationsQuery = queryHookFactory<
  null,
  NewReimbursementApprovalApplicationVM[]
>(getReimbursementApprovalApplicationsRq);

export const useGetUserPreApprovedItemsQuery = queryHookFactory<
  null,
  UserPlanReimbursementItemVM[]
>(getUserPreApprovedItemsRq);

export const useGetAttachmentUrlQuery = queryHookFactory<number, string>(
  getAttachmentURLRq
);
