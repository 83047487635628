import React from 'react';
import moment from 'moment';
import {ContentFeedbackVM} from '@generated/interfaces';
import {DATE_FORMAT} from '@utils/timeUtils';
import translate from '@i18n/translate';
import {
  Container,
  ItemContainer,
  ItemHeader,
  Message,
} from './ProjectSubmissionFeedback.styled';
import DOMPurify from 'dompurify';

interface ProjectSubmissionFeedbackProps {
  feedback: ContentFeedbackVM[];
}

const ProjectSubmissionFeedback: React.FC<ProjectSubmissionFeedbackProps> = ({
  feedback,
}) => {
  return (
    <Container>
      {feedback?.map((feedbackItem) => (
        <ItemContainer key={feedbackItem.id}>
          <ItemHeader>
            <span className="name">{feedbackItem.createdBy}</span>{' '}
            {translate('DATE__FEEDBACK_LEFT_ON__FORMAT', {
              date: moment(new Date(feedbackItem.createdOn)).format(
                DATE_FORMAT.MONTH_LONG_DAY_YEAR
              ),
            })}
          </ItemHeader>
          <Message
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(feedbackItem.message),
            }}
          />
        </ItemContainer>
      ))}
    </Container>
  );
};

export default ProjectSubmissionFeedback;
