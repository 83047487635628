import styled from 'styled-components';
import {Checkbox} from 'antd';
import {COLORS} from '@utils/constants';

export const Container = styled.div``;

export const CheckboxGroup = styled(Checkbox.Group)`
  width: 100%;

  .ant-checkbox-wrapper-checked {
    background: ${COLORS.Blue50};
  }

  .hide-checkbox {
    .ant-checkbox-inner {
      display: none !important;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  border-radius: 8px;
  border: 1px solid ${COLORS.Neutral600};
  height: 5.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  margin-left: 0 !important;

  .ant-checkbox + span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-checkbox-inner {
    height: 24px;
    width: 24px;
    border-radius: 4px;

    &::after {
      background-color: transparent !important;
      color: ${COLORS.White};
      content: url("data:image/svg+xml,<svg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M10.0873 0.057787C10.0054 -0.0192361 9.87595 -0.019266 9.79405 0.0577193L3.57289 5.90512L1.21204 3.68401C1.13035 3.60715 1.00124 3.60693 0.919278 3.6835L0.0654283 4.48115C0.0237342 4.5201 0.000101877 4.57401 3.32951e-07 4.6304C-0.000101884 4.6868 0.0233355 4.74079 0.0648889 4.77988L3.42617 7.94221C3.50807 8.01926 3.63758 8.01926 3.71947 7.94221L10.9351 1.15366C10.9766 1.11466 11 1.06082 11 1.00455C11 0.948286 10.9766 0.894448 10.9351 0.855442L10.0873 0.057787Z' fill='white'/></svg>");
      transform: none;
      width: 100%;
      height: 100%;
      padding: 0.25rem;
      top: 0;
      left: 0;
      display: flex;
      margin: 0;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner::after {
    background-color: ${COLORS.Blue800};
    border-color: transparent !important;
  }

  .ant-checkbox-wrapper-checked,
  & + .ant-checkbox-wrapper {
    margin-top: 16px;
  }

  &:hover {
    background: ${COLORS.Blue50};
  }
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  background: ${({background}) => background};
  color: ${({color}) => color};
  width: 2.5rem;
  height: 2.5rem;
`;
