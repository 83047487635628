import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Avatar, Card} from 'antd';
import {StarRatingDisplay} from './StarRatings';
import {COLORS} from '@utils/constants';
import {ProgramReviewVM} from 'models/serverModels';
import ContextMenuButton from '@blocks/ContextMenu';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const TopRow = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-left: 0;
`;

const Description = styled.p`
  color: ${COLORS.Neutral600};
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 20px;
`;

const IMG_SIZE = 32;
const ProfileImg = styled.img`
  border-radius: 100%;
  height: ${IMG_SIZE}px;
  width: ${IMG_SIZE}px;
`;
const AuthorName = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
`;

const Dates = styled.div`
  color: ${COLORS.Neutral600};
  font-size: 0.75rem;
`;

/*
|--------------------------------------------------------------------------
| Card Component
|--------------------------------------------------------------------------
*/

export interface Props {
  openDeleteConfirmationModal: (id: any) => void;
  openUpsertModal: (review: ProgramReviewVM) => void;
  programReview: ProgramReviewVM;
}

const ProgramReviewCard = ({
  openUpsertModal,
  openDeleteConfirmationModal,
  programReview,
}: Props) => {
  const onClickEdit = () => {
    openUpsertModal(programReview);
  };

  return (
    <Card
      role="article"
      aria-label={`${i18n.t(k.PROGRAM__REVIEW)}: ${programReview?.title}`}
      data-testid={`program-review-card-${programReview?.id}`}
      style={{
        marginBottom: '18px',
        border: 'none',
        boxShadow: 'none',
        borderRadius: '10px',
      }}>
      <TopRow>
        <div>
          <StarRatingDisplay rating={programReview?.rating} />
        </div>
        {programReview?.editable && (
          <ContextMenuButton
            menuItems={[
              {
                label: i18n.t(k.REVIEW__EDIT),
                onClick: onClickEdit,
              },
              {
                label: i18n.t(k.REVIEW__DELETE),
                onClick: onClickEdit,
              },
            ]}
          />
        )}
      </TopRow>
      <Title data-testid="title">{programReview?.title}</Title>
      <Description data-testid="description">
        {programReview?.reviewContent}
      </Description>
      <div style={{display: 'flex'}}>
        <div style={{marginRight: '8px'}}>
          {!!programReview?.profileImgUrl ? (
            <ProfileImg src={programReview?.profileImgUrl} />
          ) : (
            <Avatar
              style={{
                backgroundColor: COLORS.Red500,
                verticalAlign: 'middle',
              }}
              size="large"
              gap={1}>
              {programReview?.reviewer
                ?.split(' ')
                .map((name) => name[0].toUpperCase())
                .join('')}
            </Avatar>
          )}
        </div>
        <div>
          <AuthorName>{programReview?.reviewer}</AuthorName>
          <Dates>
            <span
              data-testid="reviewCreatedOn"
              dangerouslySetInnerHTML={{
                __html: i18n.t(k.DATE__POSTED_ON__FORMAT, {
                  date: programReview?.reviewCreatedOn,
                }),
              }}></span>
            {programReview?.hasCompletedProgram && (
              <span
                data-testid="programCompletedOn"
                dangerouslySetInnerHTML={{
                  __html:
                    ' • ' +
                    i18n.t(k.PROGRAM__COMPLETED_ON__FORMAT, {
                      date: programReview?.programCompletedOn,
                    }),
                }}></span>
            )}
          </Dates>
        </div>
      </div>
    </Card>
  );
};

export default ProgramReviewCard;
