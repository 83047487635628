import {InputLabel} from '@components/reusable/LearnInForm';
import LearnInQuill from '@components/reusable/LearnInQuill';
import {RichTextEditor} from '@components/reusable/RichTextEditor';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import React from 'react';
import {
  fileUploadInFlight,
  noCharLimitExceeded,
} from './Description.validators';
import {useUserQuery} from '@generated/hooks';
import {FORM_KEYS} from './constants';

interface DescriptionProps {
  richTextUploads: boolean;
  initialValue?: string;
}

const Description: React.FC<DescriptionProps> = ({
  richTextUploads,
  initialValue = '',
}) => {
  const {data: user} = useUserQuery(null);
  const description = Form.useWatch(FORM_KEYS.DESCRIPTION);
  return (
    <>
      <InputLabel
        htmlFor="description-input"
        label={i18n.t(k.GENERIC__DESCRIPTION)}
      />
      <Form.Item
        name={FORM_KEYS.DESCRIPTION}
        initialValue={initialValue}
        rules={[
          {validator: noCharLimitExceeded},
          {validator: fileUploadInFlight},
        ]}>
        {richTextUploads ? (
          <RichTextEditor
            id="description-input"
            value={description}
            companyId={user?.companyId}
          />
        ) : (
          <LearnInQuill
            id="description-input"
            theme="snow"
            value={description}
          />
        )}
      </Form.Item>
    </>
  );
};

export default Description;
