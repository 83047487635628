import {useMemo} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import ProviderLogo from '@blocks/ProviderLogo';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import LearnInTable, {
  IColumn,
  durationSorter,
  stringSorter,
} from '@components/reusable/LearnInTable';
import {FooterContainer} from '@components/reusable/Footer/Footer';
import {MarketPlaceProgramsTableData} from '@models/serverModels';
import {getAdminProgramDetailPath} from '@utils/ClientPaths';
import {COLORS} from '@utils/constants';
import {formatCurrency} from '@utils/moneyUtils';
import {Checkbox, Form, Input} from 'antd';
import {CurrencyCode} from '@generated/enums';
import {localizeDuration, localizeGeneric} from '@utils/l10nUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  margin: auto;
  margin-bottom: 75px;
  width: 80%;
`;

const SearchAndButtonContainer = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProgramTitleLink = styled.a`
  color: ${COLORS.Blue800} !important;
`;

const FooterContent = styled.div`
  display: flex;
  margin: auto;
  width: 80%;
`;

const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 0;
`;

const ProgramsSelectedContainer = styled.div`
  margin: auto;
  margin-right: 16px;
`;

const ClearAllContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: auto;
  margin-left: 0;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface AddMarketplaceProgramDrawerProps {
  allSelected: boolean;
  newlySelected: number[];
  programSearchText: string;
  dataSource: MarketPlaceProgramsTableData[];
  usersCurrency: CurrencyCode;
  itemsExchangeRate: (currency: CurrencyCode) => number;
  onClose: () => void;
  addSteps: () => void;
  checkAll: () => void;
  onClickCheckbox: (id) => void;
  clearSelectedPrograms: () => void;
  openMarketplaceProgramForm: () => void;
  setProgramSearchText: (value: React.SetStateAction<string>) => void;
}

function AddMarketplaceProgramDrawer({
  allSelected,
  newlySelected,
  programSearchText,
  dataSource,
  usersCurrency,
  itemsExchangeRate,
  onClose,
  addSteps,
  checkAll,
  onClickCheckbox,
  clearSelectedPrograms,
  openMarketplaceProgramForm,
  setProgramSearchText,
}: AddMarketplaceProgramDrawerProps) {
  const renderDrawerHeader = () => {
    return (
      <SearchAndButtonContainer
        aria-label={i18n.t(k.PROGRAM)}
        role="search"
        onSubmit={(e) => e.preventDefault()}>
        <Input.Search
          allowClear
          placeholder={i18n.t(k.PROGRAM__PLURAL__SEARCH)}
          onChange={(e) => setProgramSearchText(e.target.value)}
          style={{width: '270px', marginRight: '16px'}}
          value={programSearchText}
        />
        <LearnInButton
          onClick={openMarketplaceProgramForm}
          tag={ButtonTags.Secondary}
          style={{whiteSpace: 'nowrap'}}>
          {i18n.t(k.PROGRAM__ADD)}
        </LearnInButton>
      </SearchAndButtonContainer>
    );
  };
  const LEARNIN = 'Learn In';
  const {
    isFeatureFlagOn: {DurationStrings},
  } = useFeatureFlags();
  const programsColumns = useMemo(() => {
    return [
      {
        title: () => {
          return (
            <Checkbox
              checked={allSelected}
              onClick={checkAll}
              aria-label={i18n.t(k.CTA__CHECK_ALL)}
            />
          );
        },
        dataIndex: 'selected',
        key: 'selected',
        render: (selected, row) => {
          return (
            <Form.Item
              className="lb-application-drawer admin-program-list-checkbox"
              valuePropName="checked">
              <Checkbox
                checked={newlySelected?.includes(row.id)}
                onChange={() => onClickCheckbox(row.id)}
                aria-label={i18n.t(k.CTA__CHECK_ITEM)}
              />
            </Form.Item>
          );
        },
      },
      {
        title: i18n.t(k.PROVIDER),
        dataIndex: 'provider',
        key: 'provider',
        render: (provider, row) => {
          return (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <ProviderLogo
                provider={provider}
                providerLogo={row.providerLogo}
                size={48}
                style={{marginRight: '16px'}}
              />
              <span className="admin-program-list-provider">{provider}</span>
            </div>
          );
        },
        sorter: (a, b) => a.provider.localeCompare(b.provider),
      },
      {
        title: i18n.t(k.PROGRAM__NAME),
        dataIndex: 'programTitle',
        key: 'programTitle',
        render: (programTitle, row) => (
          <span className="admin-program-list-data">
            <ProgramTitleLink href={`#${getAdminProgramDetailPath(row.id)}`}>
              {programTitle}
            </ProgramTitleLink>
          </span>
        ),
        sorter: stringSorter<any>('programTitle'),
      },
      {
        title: i18n.t(k.GENERIC__TYPE),
        dataIndex: 'programType',
        key: 'programType',
        render: (programType) => (
          <span className="admin-program-list-data">
            {localizeGeneric(programType)}
          </span>
        ),
      },
      {
        title: i18n.t(k.TIME__DURATION),
        dataIndex: 'duration',
        key: 'duration',
        render: (duration, {id}) => (
          <span className="admin-program-list-data">
            {localizeDuration(duration, DurationStrings)}
          </span>
        ),
        sorter: durationSorter('duration'),
      },
      {
        title: i18n.t(k.MONEY__COST),
        dataIndex: 'cost',
        key: 'cost',
        render: (cost, row) => (
          <span className="admin-program-list-data">
            {formatCurrency(
              cost,
              usersCurrency,
              itemsExchangeRate(row.currency)
            )}
          </span>
        ),
        sorter: (a, b) => a.cost - b.cost,
      },
      {
        title: i18n.t(k.GENERIC__ADDED_BY),
        dataIndex: 'addedBy',
        key: 'addedBy',
        render: (addedBy: string, {id}) => {
          if (addedBy?.toLowerCase() === LEARNIN.toLowerCase()) {
            return <span className="admin-program-list-data">{addedBy}</span>;
          }
          return (
            <div style={{display: 'flex', alignItems: 'center'}}>
              <span
                className="admin-program-list-data"
                style={{marginRight: '12px'}}>
                {addedBy}
              </span>
            </div>
          );
        },
        sorter: (
          a: MarketPlaceProgramsTableData,
          b: MarketPlaceProgramsTableData
        ) => {
          const aIsLearnIn = a.addedBy.toLowerCase() === LEARNIN.toLowerCase();
          const bIsLearnIn = b.addedBy.toLowerCase() === LEARNIN.toLowerCase();
          if (aIsLearnIn && !bIsLearnIn) return 1;
          if (bIsLearnIn && !aIsLearnIn) return -1;
          return 0;
        },
      },
    ] as IColumn[];
  }, [newlySelected]);

  return (
    <LearnInPageDrawer
      visible={true}
      onClose={onClose}
      headerContent={renderDrawerHeader()}
      title={i18n.t(k.PROGRAM__MARKETPLACE__SELECT)}>
      <Container>
        <LearnInTable
          dataSource={dataSource}
          columns={programsColumns}
          isLoading={false}
          noSearchResults={false}
          pagination={true}
        />
      </Container>

      {newlySelected.length > 0 && (
        <FooterContainer>
          <FooterContent>
            <ClearAllContainer>
              <button onClick={clearSelectedPrograms}>
                {i18n.t(k.CTA__CLEAR_ALL)}
              </button>
            </ClearAllContainer>

            <FooterButtonContainer>
              <ProgramsSelectedContainer>
                <span
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(
                      newlySelected.length === 1
                        ? i18n.t(k.PROGRAM__SELECTED__SINGLE)
                        : k.PROGRAM__SELECTED__PLURAL__FORMAT,
                      {
                        number: newlySelected.length,
                      }
                    ),
                  }}></span>
              </ProgramsSelectedContainer>
              <LearnInButton tag={ButtonTags.Primary} onClick={addSteps}>
                {false // This ternary doesn't make sense (?)
                  ? i18n.t(k.STATUS__ADDING)
                  : i18n.t(k.PROGRAM__ADD_TO_SECTION)}
              </LearnInButton>
            </FooterButtonContainer>
          </FooterContent>
        </FooterContainer>
      )}
    </LearnInPageDrawer>
  );
}

export default AddMarketplaceProgramDrawer;
