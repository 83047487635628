import {LicenseResponse, ViewLicenseResponse} from './../interfaces';

import {queryHookFactory} from './../../utils';
import {LicenseResponseSchema} from '../zodSchemas/LicenseResponseSchema.schema';
import {ViewLicenseResponseSchema} from '../zodSchemas/ViewLicenseResponseSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Licenses/LicenseController.cs

/*
|--------------------------------------------------------------------------
|  get-view-license/{programId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useViewLicenseQuery} from '@generated/hooks';
 *
 *  const {
 *    data: viewLicense,
 *    isLoading: isViewLicenseLoading,
 *    invalidateExact: invalidateViewLicense,
 *  } = useViewLicenseQuery({
 *    programId
 *  })
 *
 */

export const useViewLicenseQuery = queryHookFactory<
  {programId: number | string}, // path variables
  ViewLicenseResponse // return type
>(
  'useViewLicenseQuery',
  ({programId}) => `licenses/get-view-license/${programId}`,
  {responseSchema: ViewLicenseResponseSchema}
);

/*
|--------------------------------------------------------------------------
|  {licenseId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useLicensesByIdQuery} from '@generated/hooks';
 *
 *  const {
 *    data: licensesById,
 *    isLoading: isLicensesByIdLoading,
 *    invalidateExact: invalidateLicensesById,
 *  } = useLicensesByIdQuery({
 *    licenseId
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useLicensesByIdQuery = queryHookFactory<
  {licenseId: number | string}, // path variables
  LicenseResponse // return type
>('useLicensesByIdQuery', ({licenseId}) => `licenses/${licenseId}`, {
  responseSchema: LicenseResponseSchema,
});
