import {ApprovalStatus, ApprovalType} from '@generated/enums';
import {ReactMutateRequest, ReactQueryRequest} from '@store/apiEndpoints';
import {useCallback, useRef, useState} from 'react';
import {FormInstance} from 'antd/lib/form';
import {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import {
  ApprovalRequestDetailsVM,
  UserRequestApprovalVM,
} from '@models/serverModels';
import {useMutation, useQuery} from 'react-query';
import {notify} from '@components/user/notifications';
import {useUpdateApprovalExpirationMutation} from '@generated/hooks';

interface getApprovalRequestProps {
  approvalType: ApprovalType;
  approvalId: number;
  onOk: () => void;
  getApprovalDetailsRq: (
    approvalType: ApprovalType,
    approvalId: number
  ) => ReactQueryRequest;
  getUpdateUserRequestApprovalRm: (
    approvalId: number,
    approvalType: ApprovalType,
    approvalStatus: ApprovalStatus,
    notes: string,
    costCenter: string
  ) => ReactMutateRequest;
}

export const useGetApprovalRequest = ({
  approvalType,
  approvalId,
  onOk,
  getApprovalDetailsRq,
  getUpdateUserRequestApprovalRm,
}: getApprovalRequestProps) => {
  const formRef = useRef<FormInstance>(null);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const updateApproval = useCallback(
    async ({approvalId, approvalType, approvalStatus, notes, costCenter}) => {
      const {path: updateApprovalPath, payload} =
        getUpdateUserRequestApprovalRm(
          approvalId,
          approvalType,
          approvalStatus,
          notes,
          costCenter
        );

      return simpleMutationFn<UserRequestApprovalVM>(
        updateApprovalPath,
        payload
      );
    },
    [getUpdateUserRequestApprovalRm]
  );

  const {queryKey, path: approvalDetailsPath} = getApprovalDetailsRq(
    approvalType,
    approvalId
  );

  const getApprovalDetailsQuery = useQuery<ApprovalRequestDetailsVM>(
    queryKey,
    () => simpleQueryFn(approvalDetailsPath),
    {
      // we want this query to run only when there's a valid approvalId
      enabled: approvalId === 0 || !!approvalId,
    }
  );

  const updateApprovalMutation = useMutation(updateApproval, {
    onSuccess: async (data: UserRequestApprovalVM) => {
      onOk();
      if (data.status === ApprovalStatus.Approved) notify.approveSuccessful();
      else if (data.status === ApprovalStatus.Rejected)
        notify.rejectSuccessful();
    },
    onError: () => notify.approvalUpdateError(),
  });

  const updateFundsExpirationMutation = useUpdateApprovalExpirationMutation({
    onError: () => notify.approvalUpdateError(),
  });

  return {
    formRef,
    confirmLoading,
    setConfirmLoading,
    getApprovalDetailsQuery,
    updateApprovalMutation,
    updateFundsExpirationMutation,
    requestData: getApprovalDetailsQuery.data,
    requestLoading: getApprovalDetailsQuery.isLoading,
    requestSuccess: getApprovalDetailsQuery.isSuccess,
  };
};
