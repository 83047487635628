import './ChatlioContainer.scss';
import {useEffect} from 'react';
import {useAuth} from '../../../../../utils/oidc-auth-wrapper';

const ChatlioContainer = () => {
  const {user} = useAuth();
  useEffect(() => {
    const {email, nickname} = user;
    const script = document.createElement('script');

    const scriptText = document.createTextNode(`
            window._chatlio = window._chatlio||[];
            !function(){
                var t=document.getElementById(\"chatlio-widget-embed\");
                if(t&&window.ChatlioReact&&_chatlio.init)return void _chatlio.init(t,ChatlioReact);
                for(var e=function(t){return function(){_chatlio.push([t].concat(arguments)) }},i=[\"configure\",\"identify\",\"track\",\"show\",\"hide\",\"isShown\",\"isOnline\", \"page\", \"open\", \"showOrHide\"],a=0;a<i.length;a++)_chatlio[i[a]]||(_chatlio[i[a]]=e(i[a]));

                var n=document.createElement(\"script\"),c=document.getElementsByTagName(\"script\")[0];
                n.id=\"chatlio-widget-embed\",n.src=\"https://w.chatlio.com/w.chatlio-widget.js\",n.async=!0,n.setAttribute(\"data-embed-version\",\"2.3\");
                n.setAttribute('data-widget-options', '{\"embedInline\": true}');
                n.setAttribute('data-widget-id','2ac01293-9898-4c9c-4cc9-295e51f5d4ea');
                n.setAttribute('data-disable-cookies', true);
                c.parentNode.insertBefore(n,c);
            }();
        `);

    script.appendChild(scriptText);
    document.head.appendChild(script);
    document.addEventListener(
      'chatlio.ready',
      function () {
        (window as any)._chatlio.identify(email, {
          name: nickname,
          email: email,
        });
      },
      false
    );
  }, []);

  return (
    <div id="chatlioWidgetPlaceholder" className="chatlio-container"></div>
  );
};

export default ChatlioContainer;
