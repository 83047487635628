import {DeleteCustomProgramSectionPayload} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {DeleteCustomProgramSectionPayloadSchema} from '../zodSchemas/DeleteCustomProgramSectionPayloadSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramController.cs

/*
|--------------------------------------------------------------------------
|  delete-custom-program-section/{sectionId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteCustomProgramSectionMutation} from '@generated/hooks';
 *
 *  const deleteCustomProgramSection = useDeleteCustomProgramSectionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteCustomProgramSection.mutate({
 *    payload: {
 *      ...DeleteCustomProgramSectionPayload
 *    },
 *    pathVars: {
 *      sectionId
 *    }
 *  })
 *
 */

export const useDeleteCustomProgramSectionMutation = mutationHookFactory<
  DeleteCustomProgramSectionPayload, // payload
  {sectionId: number | string}, // path variables
  number // return type
>(
  'useDeleteCustomProgramSectionMutation',
  'put',
  ({sectionId}) => `custom-program/delete-custom-program-section/${sectionId}`,
  {payloadSchema: DeleteCustomProgramSectionPayloadSchema}
);
