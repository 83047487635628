import {ResourceSkillType} from './../enums';
import {RemoveSkillTagPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: RemoveSkillTagPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getRemoveSkillTagPayloadMockData(
  args?: Partial<RemoveSkillTagPayload>
): RemoveSkillTagPayload {
  return {
    entityId: faker.datatype.number(),
    entityType: ResourceSkillType.Academy,
    resourceSkillId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const RemoveSkillTagPayloadMockData: RemoveSkillTagPayload = {
  entityId: 1,
  entityType: ResourceSkillType.Academy,
  resourceSkillId: 1,
};
