import styled from 'styled-components';
import {Card, Divider, Space} from 'antd';
import {Breakpoints} from '@utils/responsiveUtils';

export const Container = styled(Card)<{removeBorder?: boolean}>`
  border-radius: 8px;
  z-index: 0;
  .responsive-flex {
    div:last-of-type {
      flex: 2;
    }
  }
  ${({removeBorder}) =>
    removeBorder &&
    `
    &.ant-card-bordered {
      border: none;
    }
    &.ant-card {
        box-shadow: none;
    }
  `}
`;

export const ResponsiveSpace = styled(Space)`
  @media (max-width: ${Breakpoints.Mobile}px) {
    column-gap: 0rem !important;
    row-gap: 0.5rem !important;
    justify-content: center;
  }
`;

export const ResponsiveDivider = styled(Divider)`
  height: 200px;
  margin: 0 2rem;
  @media (max-width: ${Breakpoints.Mobile}px) {
    display: none;
  }
`;
