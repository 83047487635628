// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/UserRequestRedemptionType.cs

enum UserRequestRedemptionType {
  VirtualCard,
  License,
  Reimbursement,
}

export default UserRequestRedemptionType;
