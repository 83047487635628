import React from 'react';
import {Sources} from 'quill';
import {UnprivilegedEditor} from 'react-quill';
import identity from 'lodash/identity';
import noop from 'lodash/noop';

import {RichTextEditor} from '@components/reusable/RichTextEditor';
import {
  CharacterCount,
  StyledLearnInDrawer,
  Subject,
  EditorContainer,
  DrawerBodyContent,
  IntroText,
} from './EmailNoteEditorDrawer.styled';

// removes buttons from the rich text editor
const QUILL_MODULES = {toolbar: false};

// rich text editor character limit for validation
const MAX_NOTES_CHAR = 800;

export interface EmailNoteEditorProps {
  onClose: () => void;
  open: boolean;
  emailNote?: string;
  emailHasNote: boolean;
  isEditingNote: boolean;
  onNoteChange: (
    newValue: string,
    delta: Record<string, unknown>,
    source: Sources,
    editor: UnprivilegedEditor
  ) => void;
  emailSections: [HTMLElement, HTMLElement, HTMLElement];
  emailNoteCharCount: number;
  isDeletePendingSave: boolean;
  transformNoteBeforeRender?: (noteQuillMarkup: string) => string;
  emailNoteIntro?: string;
  onEditNote: () => void;
  companyId: number;
  footer?: React.JSX.Element;
  emailSubject?: string;
  drawerTitle?: string | React.JSX.Element;
}

const EmailNoteEditor: React.FC<EmailNoteEditorProps> = ({
  onClose,
  open,
  emailHasNote,
  emailSections,
  emailNoteIntro,
  isEditingNote,
  onNoteChange,
  emailNote,
  footer,
  emailNoteCharCount,
  transformNoteBeforeRender,
  onEditNote,
  isDeletePendingSave,
  companyId,
  emailSubject,
  drawerTitle,
}) => {
  const transformNote = transformNoteBeforeRender || identity;

  return (
    <StyledLearnInDrawer
      open={open}
      destroyOnClose={true}
      closeButtonPlacement={'right'}
      title={drawerTitle}
      onClose={onClose}
      footer={footer}
      style={{zIndex: 1006}}>
      <Subject>{emailSubject || ''}</Subject>

      <DrawerBodyContent>
        {emailSections && (
          <>
            {/* EMAIL's MAIN CONTENT */}
            <section
              style={{pointerEvents: 'none'}}
              dangerouslySetInnerHTML={{
                __html: emailSections[0]?.innerHTML.toString(),
              }}
            />

            {/* TOGGLE NOTE's CONTENT AND EDITOR */}
            {isEditingNote ? (
              <EditorContainer>
                <IntroText>{emailNoteIntro}</IntroText>
                <RichTextEditor
                  onChange={onNoteChange}
                  value={emailNote}
                  modules={QUILL_MODULES}
                  companyId={companyId}
                />
                <CharacterCount>
                  {Math.max(0, emailNoteCharCount - 1)}/{MAX_NOTES_CHAR}
                </CharacterCount>
              </EditorContainer>
            ) : (
              !isDeletePendingSave && (
                <section
                  className="note-container"
                  role="button"
                  tabIndex={0}
                  onKeyDown={noop}
                  onClick={onEditNote}
                  dangerouslySetInnerHTML={{
                    __html: emailHasNote
                      ? transformNote(emailSections[1]?.innerHTML.toString())
                      : null,
                  }}
                />
              )
            )}

            {/* FOOTER AND REST OF THE CONTENT */}
            <section
              style={{pointerEvents: 'none'}}
              dangerouslySetInnerHTML={{
                __html: emailSections[2]?.innerHTML.toString(),
              }}
            />
          </>
        )}
      </DrawerBodyContent>
    </StyledLearnInDrawer>
  );
};

export default EmailNoteEditor;
