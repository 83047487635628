import {ApprovalStatus} from './../enums';
import {PreApprovalApplicationVM} from './../interfaces';
import {
  PreApprovalQuestionVMMockData,
  getPreApprovalQuestionVMMockData,
} from './PreApprovalQuestionVM.mock';
import {faker} from '@faker-js/faker';

// Interface: PreApprovalApplicationVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPreApprovalApplicationVMMockData(
  args?: Partial<PreApprovalApplicationVM>
): PreApprovalApplicationVM {
  return {
    application: [getPreApprovalQuestionVMMockData()],
    attachmentIds: [String(faker.datatype.number())],
    id: faker.datatype.number(),
    notes: faker.word.noun(), // optional
    rejectedNotes: faker.word.noun(), // optional
    status: ApprovalStatus.Pending,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PreApprovalApplicationVMMockData: PreApprovalApplicationVM = {
  application: [PreApprovalQuestionVMMockData],
  attachmentIds: ['1'],
  id: 1,
  notes: 'string', // optional
  rejectedNotes: 'string', // optional
  status: ApprovalStatus.Pending,
};
