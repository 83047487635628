import {ReactNode, ReactNodeArray} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import SearchCardHeader from './SearchCardHeader';
import RecommendationTag from './RecommendationTag';
import {getPlaceholderImgSrc, resizeIfCloudinary} from '@utils/image-utils';
import {COLORS} from '@utils/constants';
import {TagsContainer} from '@blocks/LearnInTag';
import {Breakpoints} from '@utils/responsiveUtils';
/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const CardContainer = styled.article<{removeBottomMargin?: boolean}>`
  box-shadow: ${COLORS.BoxShadowStandard};
  margin-bottom: ${({removeBottomMargin}) =>
    removeBottomMargin ? '0px' : '24px'};
  position: relative;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  .nominated-marker {
    background-color: ${COLORS.Red500} !important;
    color: ${COLORS.White} !important;
    padding: 10px;
    font-weight: 800;
    font-size: 1.25rem;
    position: absolute;
    bottom: -1px;
    right: -1px;
    opacity: 0.8;
    border-bottom-right-radius: 8px;
  }
`;

const InnerContainer = styled.div<{notAvailable?: boolean}>`
  display: flex;
  width: 100%;
  opacity: ${({notAvailable}) => notAvailable && '25%'};
`;

const ContentContainer = styled.div`
  border-radius: 0px 10px 10px 0px;
  padding: 24px;
  width: 100%;
  background-color: ${COLORS.White};
  @media (max-width: ${Breakpoints.Mobile}px) {
    min-width: 150px;
    border-radius: 10px;
  }
`;

const PreviewImgContainer = styled.div`
  min-width: 210px;
  width: 210px;
  position: relative;
  @media (max-width: ${Breakpoints.Tablet}px) {
    min-width: 150px;
    max-width: 150px;
  }
  @media (max-width: ${Breakpoints.Mobile}px) {
    display: none;
  }
`;

const PreviewImg = styled.img<{bgImg: string}>`
  border-radius: 10px 0 0 10px;
  object-fit: cover;
  object-position: center center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const MetaData = styled.div`
  color: ${COLORS.Neutral950};
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.1875rem;
  margin: 0 0 4px;
`;

const Description = styled.div`
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 20px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`;

const FootContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${Breakpoints.Mobile}px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`;

const RecTagContainer = styled.div`
  align-items: center;
  display: flex;
  padding-right: 10px;
`;

const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
  button {
    margin-right: 6px;
  }
`;

const FinanceIncentiveType = styled.div`
  font-size: 1rem;
  line-height: 1.1875rem;
  margin-bottom: 12px;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ISearchCardProps {
  id: number;
  provider: string;
  providerImgSrc: string | unknown;
  title: string;
  previewImgSrc: string;
  description: string;
  metadata?: string[];
  notAvailable?: boolean;
  financeIncentiveType?: string;
  headerData?: {
    title: string;
    value: string;
    tooltip?: string;
  };
  recommendationTag?: {
    label: string;
    tooltipText?: string;
  };
  buttons: ReactNode | ReactNodeArray;
  tags?: string[];
  removeBottomMargin?: boolean;
}

function SearchCard({
  id,
  provider,
  providerImgSrc,
  title,
  previewImgSrc,
  description,
  metadata,
  headerData,
  buttons,
  recommendationTag,
  notAvailable,
  financeIncentiveType,
  tags,
  removeBottomMargin,
}: ISearchCardProps) {
  const _metadata = metadata?.filter(Boolean).join(' | ');

  return (
    <CardContainer
      aria-label={`${i18n.t(k.GENERIC__SEARCH_RESULTS)}: ${title}`}
      data-cy={`marketplace-${id}`}
      removeBottomMargin={removeBottomMargin}>
      <InnerContainer notAvailable={notAvailable}>
        <PreviewImgContainer>
          <PreviewImg
            alt=""
            src={
              !!previewImgSrc
                ? resizeIfCloudinary(previewImgSrc, null, 307)
                : getPlaceholderImgSrc(title?.length)
            }
          />
        </PreviewImgContainer>
        <ContentContainer>
          <SearchCardHeader
            provider={provider}
            providerImgSrc={providerImgSrc}
            title={title}
            headerData={headerData}
          />
          {!!_metadata?.length && (
            <MetaData
              dangerouslySetInnerHTML={{
                __html: _metadata,
              }}></MetaData>
          )}
          {!!financeIncentiveType && (
            <FinanceIncentiveType>{financeIncentiveType}</FinanceIncentiveType>
          )}
          <Description>{description}</Description>
          <TagsContainer tags={tags} style={{marginBottom: '16px'}} />
          <FootContainer>
            <ButtonsContainer>{buttons}</ButtonsContainer>
            {!!recommendationTag?.label && (
              <RecTagContainer>
                <RecommendationTag {...recommendationTag} />
              </RecTagContainer>
            )}
          </FootContainer>
        </ContentContainer>
      </InnerContainer>
    </CardContainer>
  );
}

export default SearchCard;
