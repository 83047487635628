import {i18n, k} from '@i18n/translate';
import {Form, Checkbox} from 'antd';
import ProviderLogo from '@blocks/ProviderLogo';
import LearnInTable, {
  ColumnAlignment,
  DurationCol,
  stringSorter,
} from '@components/reusable/LearnInTable';
import {MarketPlaceProgramsTableData} from '@models/serverModels';
import {booleanSorter} from '@utils/tableUtils';
import {EditOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {formatCurrency, getExchangeRateOfCurrencyPair} from '@utils/moneyUtils';
import styled from 'styled-components';
import {getAdminProgramDetailPath} from '@utils/ClientPaths';
import {localizeGeneric} from '@utils/l10nUtils';
import NoDataText from '@blocks/NoDataText';
import {CurrencyCode} from '@generated/enums';
import {UseCurrencyRateQueryReturnType} from '@hooks/apiEndpoints/localization/queries';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import useFeatureFlags from '@hooks/useFeatureFlags';

export interface IMarketPlaceProgramsTableProps {
  dataSource: MarketPlaceProgramsTableData[];
  usersCurrency: CurrencyCode;
  exchangeRates: UseCurrencyRateQueryReturnType;
  onToggleIsProgramFeatured: (
    row: MarketPlaceProgramsTableData,
    event: any
  ) => void;
  onToggleIsProgramAvailable: (
    row: MarketPlaceProgramsTableData,
    event: any
  ) => void;
  isLoading: boolean;
  noSearchResults: boolean;
  onClickEditIcon: (id: number) => void;
  programSearchText?: string;
}

const PROGRAM_SEARCH_VALUE = 'program-search-value';
const LEARNIN = 'Learn In'; //Defines uploaded programs
const DEGREED = 'Degreed'; //Defines Degreed uploaded programs
const ProgramTitleLink = styled.a`
  color: ${COLORS.Blue800} !important;
`;

function MarketPlaceProgramsTable({
  dataSource,
  usersCurrency,
  exchangeRates,
  onToggleIsProgramFeatured,
  onToggleIsProgramAvailable,
  isLoading,
  noSearchResults,
  onClickEditIcon,
  programSearchText,
}: IMarketPlaceProgramsTableProps) {
  const {
    isFeatureFlagOn: {DurationStrings},
  } = useFeatureFlags();
  const programsColumns = [
    {
      title: i18n.t(k.PROVIDER),
      dataIndex: 'provider',
      key: 'provider',
      render: (provider, row) => {
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <ProviderLogo
              provider={provider}
              providerLogo={row?.providerLogo}
              size={48}
              style={{marginRight: '16px'}}
            />
            <span className="admin-program-list-provider">{provider}</span>
          </div>
        );
      },
      sorter: (a, b) => a.provider.localeCompare(b.provider),
    },
    {
      title: i18n.t(k.PROGRAM__NAME),
      dataIndex: 'programTitle',
      key: 'programTitle',
      render: (programTitle: any, row: any) => (
        <span className="admin-program-list-data">
          <ProgramTitleLink
            href={`#${getAdminProgramDetailPath(row?.id)}`}
            onClick={() => {
              localStorage.setItem(PROGRAM_SEARCH_VALUE, programSearchText);
            }}>
            {programTitle}
          </ProgramTitleLink>
        </span>
      ),
      sorter: stringSorter<any>('programTitle'),
    },
    {
      title: i18n.t(k.GENERIC__TYPE),
      dataIndex: 'programType',
      key: 'programType',
      render: (programType) => (
        <span className="admin-program-list-data">
          {localizeGeneric(programType)}
        </span>
      ),
      sorter: stringSorter('programType'),
    },
    DurationCol(DurationStrings),
    {
      align: ColumnAlignment.Right,
      title: i18n.t(k.MONEY__COST),
      dataIndex: 'cost',
      key: 'cost',
      render: (cost, row) => {
        const exchangeRate = getExchangeRateOfCurrencyPair(
          exchangeRates?.data,
          usersCurrency,
          row?.currency
        );

        const currencyDisplay = 'narrowSymbol';

        return (
          <span className="admin-program-list-data">
            {formatCurrency(cost, usersCurrency, exchangeRate, {
              currencyDisplay,
            })}
          </span>
        );
      },
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: i18n.t(k.GENERIC__FEATURED),
      dataIndex: 'featured',
      key: 'featured',
      render: (featured, row) => {
        return (
          <Form.Item
            className="lb-application-drawer admin-program-list-checkbox"
            valuePropName="checked">
            <Checkbox
              defaultChecked={featured}
              onChange={(event) => {
                onToggleIsProgramFeatured(row, event);
              }}
              aria-label={i18n.t(k.CTA__MARK_AS_FEATURED)}
            />
          </Form.Item>
        );
      },
      sorter: booleanSorter('featured'),
    },
    {
      title: i18n.t(k.STATUS__AVAILABLE),
      dataIndex: 'available',
      key: 'available',
      render: (available, row) => {
        return (
          <Form.Item
            className="lb-application-drawer admin-program-list-checkbox"
            valuePropName="checked">
            <Checkbox
              defaultChecked={available}
              onChange={(event) => {
                onToggleIsProgramAvailable(row, event);
              }}
              aria-label={i18n.t(k.CTA__MARK_AS_AVAILABLE)}
            />
          </Form.Item>
        );
      },
      sorter: booleanSorter('available'),
    },
    {
      title: i18n.t(k.GENERIC__ADDED_BY),
      dataIndex: 'addedBy',
      key: 'addedBy',
      render: (addedBy: string, row) => {
        if (addedBy === LEARNIN || addedBy === DEGREED) {
          return <span className="admin-program-list-data">{addedBy}</span>;
        }
        return (
          <div style={{display: 'flex', alignItems: 'center'}}>
            <span
              className="admin-program-list-data"
              style={{marginRight: '12px'}}>
              {addedBy}
            </span>
            <LearnInButton
              onClick={() => onClickEditIcon(row?.id)}
              tag={ButtonTags.SecondaryRemovedBorder}
              aria-label={i18n.t(k.CTA__EDIT)}
              icon={<EditOutlined aria-hidden="true" />}
            />
          </div>
        );
      },
      sorter: stringSorter('addedBy'),
    },
  ];

  return (
    <>
      {!dataSource?.length && programSearchText !== '' ? (
        <NoDataText
          header={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
          subHeader={i18n.t(k.PROGRAM__TABLE__EMPTY__DESCRIPTION)}
        />
      ) : (
        <LearnInTable
          dataSource={dataSource}
          columns={programsColumns}
          isLoading={isLoading}
          scroll={{x: 'max-content'}}
          noSearchResults={noSearchResults}
        />
      )}
    </>
  );
}

export default MarketPlaceProgramsTable;
