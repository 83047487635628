// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/AcademyContentStatus.cs

enum AcademyContentStatus {
  Planned,
  Completed,
  VerifiedCompleted,
  InProgress,
  Skipped,
  RsvpYes,
  RsvpNo,
}

export default AcademyContentStatus;
