import {useCallback, useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Checkbox, Col, Form, Row} from 'antd';
import {notify} from '@components/user/notifications';
import {includes, isEqual} from 'lodash';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {NotificationSettings, UserSettingsVM} from '@models/serverModels';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {LearnInWrapper} from '@components/reusable/Wrapper/Wrapper.style';
import {WrapperTags} from '@components/reusable/Wrapper/WrapperEnums';
import ModalGif from '@assets/email-sent.gif';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {TextTags} from '@components/reusable/Text/TextEnums';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {COLORS} from '@utils/constants';
import ToggleList from '@components/reusable/ToggleList/ToggleList';
import GoogleCalendarIcon from '@assets/vendors/gcal.png';
import OutlookCalendarIcon from '@assets/vendors/outlook.png';
import learnInConfirm from '@components/reusable/learnInConfirm';
import {CalendarProvider} from '@generated/enums';
import {LANGUAGE, isSkippedLanguage} from '@utils/l10nUtils';
import {currencyOptions} from '@utils/moneyUtils';
import {LearnInSelect} from '@components/reusable/LearnInForm';
import {BodySpan} from '@components/reusable/Typography';
import {stripeTosUrl} from '@components/CardIssuerTerms/CardIssuerTerms.shared';
import {trackTermsLinkClick} from '@utils/analytics';
import {LearnInSelectOption} from '@components/reusable/Select/Select.style';
import {
  SettingsAnimatedGif,
  SettingsBox,
  SettingsCheckboxGroup,
  SettingsCurrencySelect,
  SettingsFooter,
  SettingsForm,
  SettingsFormItem,
  SettingsHomeOutlined,
  SettingsIconText,
  SettingsIntegrations,
  SettingsPaymentTerms,
  SettingsProfileCol,
  SettingsProfileRow,
  SettingsSaveButton,
  SettingsTitle,
  settingsProfileColWidth,
} from './Settings.styled';
import {SettingsProps} from './Settings.interfaces';
import {
  PROGRAM_NOMINATION_FIELD,
  PROGRAM_ACCESS_FIELD,
  APPLICATION_NOTIFICATIONS_FIELD,
  MANAGER_APPROVAL_REQUESTS_FIELD,
  PROGRAM_NUDGE_REMINDERS,
  DUE_DATE_REMINDERS,
  EVENT_REMINDERS,
  APPROVAL_REQUESTS_FIELD,
  ALLOW_EMAIL_FIELD,
  PROJECT_SUBMISSION,
  PROJECT_FEEDBACK,
  CONTRIBUTOR_UPDATE,
  GIVEN_NAME_FIELD,
  FAMILY_NAME_FIELD,
  JOB_ROLE_FIELD,
  EMAIL_FIELD,
  PHONE_FIELD,
  LOCATION_FIELD,
  CURRENCY_FIELD,
  LANGUAGE_FIELD,
  EMAIL_SETTINGS_FIELDS,
} from './Settings.constants';

// end checkbox group

const Settings = ({
  data,
  googleCalConnected,
  handleCalendarConnection,
  isFeatureFlagOn,
  languagePreference,
  isGoogleCalendarOn,
  outlookCalConnected,
  updateSettings,
  settingsCheckboxes,
  showContributorUpdateCheckbox,
  showCurrencySettings,
  showIntegrationsSettings,
  showProfileSettings,
  showStripeLinks,
  onCloseWithLxpOption,
  viaUnknownExternal,
}: SettingsProps) => {
  const [form] = Form.useForm();
  const [allowEmails, setAllowEmails] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [requestPasswordModal, setRequestPasswordModal] =
    useState<boolean>(false);
  const [defaultCurrency] = useState(data?.currency);
  const [defaultLanguage] = useState(languagePreference ?? LANGUAGE.EN_US.enum);

  const generateNotificationSettings = useCallback(
    ({
      allowEmails,
      allowTexts,
      applicationNotificationsEmail,
      approvalRequestsEmail,
      dueDateReminders,
      eventReminders,
      managerApprovalRequestsEmail,
      newProjectSubmission,
      newProjectFeedback,
      programAccessEmail,
      programNominationsEmail,
      programNudgeReminders,
      weeklyFollowUpText,
      contributorUpdate,
    }): NotificationSettings => {
      return {
        programNomination: {
          name: data?.notificationSettings?.programNomination?.name,
          isEmailEnabled: programNominationsEmail,
          isTextEnabled:
            data?.notificationSettings?.programNomination?.isTextEnabled,
        },
        programAccess: {
          name: data?.notificationSettings?.programAccess?.name,
          isEmailEnabled: programAccessEmail,
          isTextEnabled:
            data?.notificationSettings?.programAccess?.isTextEnabled,
        },
        approvalRequestUpdated: {
          name: data?.notificationSettings?.approvalRequestUpdated?.name,
          isEmailEnabled: applicationNotificationsEmail,
          isTextEnabled:
            data?.notificationSettings?.approvalRequestUpdated?.isTextEnabled,
        },
        managerApprovalRequested: {
          name: data?.notificationSettings?.managerApprovalRequested?.name,
          isEmailEnabled: managerApprovalRequestsEmail,
          isTextEnabled:
            data?.notificationSettings?.managerApprovalRequested?.isTextEnabled,
        },
        approvalRequested: {
          name: data?.notificationSettings?.approvalRequested?.name,
          isEmailEnabled: approvalRequestsEmail,
          isTextEnabled:
            data?.notificationSettings?.approvalRequested?.isTextEnabled,
        },
        weeklyFollowUp: {
          name: data?.notificationSettings?.weeklyFollowUp?.name,
          isEmailEnabled:
            data?.notificationSettings?.weeklyFollowUp?.isEmailEnabled,
          isTextEnabled: weeklyFollowUpText,
        },
        // keep values since we're not asking on the front end
        completeYourPlan: {
          name: data?.notificationSettings?.completeYourPlan?.name,
          isEmailEnabled:
            data?.notificationSettings?.completeYourPlan?.isEmailEnabled,
          isTextEnabled:
            data?.notificationSettings?.completeYourPlan?.isTextEnabled,
        },
        // keep values since we're not asking on the front end
        programProgressUpdate: {
          name: data?.notificationSettings?.programProgressUpdate?.name,
          isEmailEnabled:
            data?.notificationSettings?.programProgressUpdate?.isEmailEnabled,
          isTextEnabled:
            data?.notificationSettings?.programProgressUpdate?.isTextEnabled,
        },
        programNudgeReminders: {
          name: data?.notificationSettings?.programNudgeReminders?.name,
          isEmailEnabled: programNudgeReminders,
          isTextEnabled:
            data?.notificationSettings?.programNudgeReminders?.isTextEnabled,
        },
        dueDateReminders: {
          name: data?.notificationSettings?.dueDateReminders?.name,
          isEmailEnabled: dueDateReminders,
          isTextEnabled:
            data?.notificationSettings?.dueDateReminders?.isTextEnabled,
        },
        eventReminders: {
          name: data?.notificationSettings?.eventReminders?.name,
          isEmailEnabled: eventReminders,
          isTextEnabled:
            data?.notificationSettings?.eventReminders?.isTextEnabled,
        },
        newProjectSubmission: {
          name: data?.notificationSettings?.newProjectSubmission?.name,
          isEmailEnabled: newProjectSubmission,
          isTextEnabled:
            data?.notificationSettings?.newProjectSubmission?.isTextEnabled,
        },
        newProjectFeedback: {
          name: data?.notificationSettings?.newProjectFeedback?.name,
          isEmailEnabled: newProjectFeedback,
          isTextEnabled:
            data?.notificationSettings?.newProjectFeedback?.isTextEnabled,
        },
        contributorUpdate: {
          name: data?.notificationSettings?.contributorUpdate?.name,
          isEmailEnabled: contributorUpdate,
          isTextEnabled:
            data?.notificationSettings?.contributorUpdate?.isTextEnabled,
        },
        allowEmails,
        allowTexts,
      };
    },

    [data]
  );

  useEffect(() => {
    setAllowEmails(data?.notificationSettings?.allowEmails);
  }, [data]);

  const getInitialFormValues = useCallback(() => {
    const emailSettings = [];
    data?.notificationSettings?.programNomination?.isEmailEnabled &&
      emailSettings.push(PROGRAM_NOMINATION_FIELD);
    data?.notificationSettings?.programAccess?.isEmailEnabled &&
      emailSettings.push(PROGRAM_ACCESS_FIELD);
    data?.notificationSettings?.approvalRequestUpdated?.isEmailEnabled &&
      emailSettings.push(APPLICATION_NOTIFICATIONS_FIELD);
    data?.notificationSettings?.managerApprovalRequested?.isEmailEnabled &&
      emailSettings.push(MANAGER_APPROVAL_REQUESTS_FIELD);
    data?.notificationSettings?.programNudgeReminders?.isEmailEnabled &&
      emailSettings.push(PROGRAM_NUDGE_REMINDERS);
    data?.notificationSettings?.dueDateReminders?.isEmailEnabled &&
      emailSettings.push(DUE_DATE_REMINDERS);
    data?.notificationSettings?.eventReminders?.isEmailEnabled &&
      emailSettings.push(EVENT_REMINDERS);
    data?.notificationSettings?.approvalRequested?.isEmailEnabled &&
      emailSettings.push(APPROVAL_REQUESTS_FIELD);
    data?.notificationSettings?.allowEmails &&
      emailSettings.push(ALLOW_EMAIL_FIELD);
    data?.notificationSettings?.newProjectSubmission?.isEmailEnabled &&
      emailSettings.push(PROJECT_SUBMISSION);
    data?.notificationSettings?.newProjectFeedback?.isEmailEnabled &&
      emailSettings.push(PROJECT_FEEDBACK);
    data?.notificationSettings?.contributorUpdate?.isEmailEnabled &&
      emailSettings.push(CONTRIBUTOR_UPDATE);

    return {
      [GIVEN_NAME_FIELD]: data?.givenName,
      [FAMILY_NAME_FIELD]: data?.familyName,
      [JOB_ROLE_FIELD]: data?.jobTitle,
      [EMAIL_FIELD]: data?.email,
      [PHONE_FIELD]: data?.phoneNumber,
      [LOCATION_FIELD]: data?.location,
      [CURRENCY_FIELD]: defaultCurrency,
      [LANGUAGE_FIELD]: defaultLanguage,
      [EMAIL_SETTINGS_FIELDS]: emailSettings,
    };
  }, [data, defaultCurrency, defaultLanguage]);

  const checkIfFieldsChanged = useCallback(() => {
    const originalValues = getInitialFormValues();
    const sortedOriginalValues = {
      ...originalValues,
      ...{
        [EMAIL_SETTINGS_FIELDS]: originalValues[EMAIL_SETTINGS_FIELDS].sort(),
      },
    };

    const currentValues = form.getFieldsValue();

    const sortedCurrentValues = {
      ...currentValues,
      ...{
        [EMAIL_SETTINGS_FIELDS]: currentValues[EMAIL_SETTINGS_FIELDS].sort(),
      },
    };

    setIsSaveDisabled(isEqual(sortedOriginalValues, sortedCurrentValues));
  }, [getInitialFormValues, form]);

  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();

      const formData = form.getFieldsValue();

      const isCheckboxSelected = (checkboxName: string) =>
        includes(formData[EMAIL_SETTINGS_FIELDS], checkboxName);

      const requestData: UserSettingsVM = {
        email: '', // changing email not currently supported
        givenName: formData[GIVEN_NAME_FIELD],
        familyName: formData[FAMILY_NAME_FIELD],
        currency: formData[CURRENCY_FIELD],
        languagePreference: formData[LANGUAGE_FIELD],
        jobTitle: formData[JOB_ROLE_FIELD] ?? '',
        phoneNumber: formData[PHONE_FIELD] ?? '',
        location: formData[LOCATION_FIELD] ?? '',
        notificationSettings: generateNotificationSettings({
          programNominationsEmail: isCheckboxSelected(PROGRAM_NOMINATION_FIELD),
          programAccessEmail: isCheckboxSelected(PROGRAM_ACCESS_FIELD),
          applicationNotificationsEmail: isCheckboxSelected(
            APPLICATION_NOTIFICATIONS_FIELD
          ),
          managerApprovalRequestsEmail: isCheckboxSelected(
            MANAGER_APPROVAL_REQUESTS_FIELD
          ),
          approvalRequestsEmail: isCheckboxSelected(APPROVAL_REQUESTS_FIELD),
          weeklyFollowUpText: false, // default since we don't support text updates right now
          programNudgeReminders: isCheckboxSelected(PROGRAM_NUDGE_REMINDERS),
          dueDateReminders: isCheckboxSelected(DUE_DATE_REMINDERS),
          eventReminders: isCheckboxSelected(EVENT_REMINDERS),
          allowEmails: isCheckboxSelected(ALLOW_EMAIL_FIELD),
          allowTexts: false, // default since we don't support text updates right now
          newProjectSubmission: isCheckboxSelected(PROJECT_SUBMISSION),
          newProjectFeedback: isCheckboxSelected(PROJECT_FEEDBACK),
          contributorUpdate:
            showContributorUpdateCheckbox &&
            isCheckboxSelected(CONTRIBUTOR_UPDATE),
        }),
      };
      updateSettings(requestData);
    } catch (err) {
      console.error(err.message);
    }
  }, [
    generateNotificationSettings,
    showContributorUpdateCheckbox,
    updateSettings,
    form,
  ]);

  const updateCalSelection = (
    calendarProvider: CalendarProvider,
    makingSelection: boolean,
    calendarId?: number
  ) => {
    const anotherIsConnected =
      (calendarProvider === CalendarProvider.Google && outlookCalConnected) ||
      (calendarProvider === CalendarProvider.Outlook && googleCalConnected);
    const confirmChange = anotherIsConnected && makingSelection;

    if (!confirmChange) {
      handleCalendarConnection(
        calendarProvider,
        makingSelection,
        false,
        calendarId
      );
    } else {
      const googleName = 'Google';
      const outlookName = 'Outlook';
      const calendars = {
        connect:
          calendarProvider === CalendarProvider.Google
            ? googleName
            : outlookName,
        disconnect:
          calendarProvider === CalendarProvider.Google
            ? outlookName
            : googleName,
      };

      learnInConfirm({
        cancelText: i18n.t(k.CTA__CANCEL),
        okText: i18n.t(k.CTA__CONTINUE),
        okButton: {color: COLORS.Blue800, type: 'primary'},
        content: i18n.t(k.CALENDAR__DISCONNECT_PROMPT__FORMAT, {
          connecting: calendars.connect,
          disconnecting: calendars.disconnect,
        }),
        title: i18n.t(k.CALENDAR__DISCONNECT_PROMPT__SHORT__FORMAT, {
          calendar: calendars.disconnect,
        }),
        onOk() {
          handleCalendarConnection(
            calendarProvider,
            makingSelection,
            true,
            calendarId
          );
        },
        onCancel() {
          // intentional pass
        },
      });
    }
  };

  const calendarIntegrations = (() => {
    const calendars = [
      {
        title: i18n.t(k.CALENDAR__OUTLOOK),
        id: 'outlookCal',
        iconSrc: OutlookCalendarIcon,
        isOn: outlookCalConnected,
        handleToggleChange: () =>
          updateCalSelection(
            CalendarProvider.Outlook,
            !outlookCalConnected,
            null
          ),
      },
    ];

    if (isGoogleCalendarOn) {
      calendars.unshift({
        title: i18n.t(k.CALENDAR__GOOGLE),
        id: 'googleCal',
        iconSrc: GoogleCalendarIcon,
        isOn: googleCalConnected,
        handleToggleChange: () =>
          updateCalSelection(
            CalendarProvider.Google,
            !googleCalConnected,
            null
          ),
      });
    }

    return calendars;
  })();

  return (
    <>
      <PageTitleBar
        title={i18n.t(k.SETTING__PLURAL)}
        onClickBack={onCloseWithLxpOption}
        backElement={
          viaUnknownExternal && (
            <SettingsIconText>
              <SettingsHomeOutlined aria-hidden="true" />
              {i18n.t(k.HOME__GO_TO)}
            </SettingsIconText>
          )
        }
      />

      <PageContentLayout>
        <SettingsForm
          name="settings"
          initialValues={getInitialFormValues()}
          onFinishFailed={() => {
            notify.settingsUpdateFormError();
          }}
          autoComplete="off"
          onFieldsChange={checkIfFieldsChanged}
          layout="vertical"
          form={form}>
          {showProfileSettings && (
            <SettingsBox>
              <SettingsTitle>{i18n.t(k.SETTING__PROFILE)}</SettingsTitle>
              <SettingsProfileRow>
                <SettingsProfileCol xs={24} sm={12}>
                  <SettingsFormItem
                    label={i18n.t(k.GENERIC__FIRST_NAME)}
                    name={GIVEN_NAME_FIELD}
                    rules={[
                      {
                        required: true,
                        message: i18n.t(
                          k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                          {
                            fieldName: i18n.t(k.GENERIC__FIRST_NAME),
                          }
                        ),
                      },
                    ]}>
                    <LearnInInput tag={InputTags.PRIMARY} disabled />
                  </SettingsFormItem>
                </SettingsProfileCol>
                <SettingsProfileCol xs={24} sm={12}>
                  <SettingsFormItem
                    label={i18n.t(k.GENERIC__LAST_NAME)}
                    name={FAMILY_NAME_FIELD}
                    rules={[
                      {
                        required: true,
                        message: i18n.t(
                          k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                          {
                            fieldName: i18n.t(k.GENERIC__LAST_NAME),
                          }
                        ),
                      },
                    ]}>
                    <LearnInInput tag={InputTags.PRIMARY} disabled />
                  </SettingsFormItem>
                </SettingsProfileCol>
                <SettingsProfileCol xs={24} sm={12}>
                  <SettingsFormItem
                    label={i18n.t(k.EMPLOYEE__JOB_ROLE)}
                    name={JOB_ROLE_FIELD}
                    rules={[{required: false}]}>
                    <LearnInInput tag={InputTags.PRIMARY} disabled />
                  </SettingsFormItem>
                </SettingsProfileCol>
                <SettingsProfileCol xs={24} sm={12}>
                  <SettingsFormItem
                    label={i18n.t(k.GENERIC__EMAIL)}
                    name={EMAIL_FIELD}
                    rules={[
                      {
                        required: true,
                        message: i18n.t(
                          k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                          {
                            fieldName: i18n.t(k.GENERIC__EMAIL),
                          }
                        ),
                      },
                      {
                        type: 'email',
                        message: i18n.t(
                          k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                          {
                            fieldName: i18n.t(k.GENERIC__EMAIL),
                          }
                        ),
                      },
                    ]}>
                    <LearnInInput tag={InputTags.PRIMARY} disabled />
                  </SettingsFormItem>
                </SettingsProfileCol>
              </SettingsProfileRow>
            </SettingsBox>
          )}
          <SettingsBox>
            <Row>
              <SettingsProfileCol md={12}>
                <Row>
                  <Col xs={24}>
                    <SettingsTitle>
                      {i18n.t(k.SETTING__EMAIL_PREFERENCES)}
                    </SettingsTitle>
                  </Col>
                  <Col xs={24}>
                    <SettingsFormItem
                      name={EMAIL_SETTINGS_FIELDS}
                      noStyle={true}>
                      <SettingsCheckboxGroup>
                        <Row>
                          {settingsCheckboxes.map((config) => {
                            if (config.show === false) return null;
                            if (config.enabled === undefined) {
                              config.enabled = !allowEmails;
                            }
                            const isAllowEmailField =
                              config.value === ALLOW_EMAIL_FIELD;
                            return (
                              <Col
                                xs={24}
                                key={`settings-checkbox-${config.value}`}>
                                <Checkbox
                                  disabled={!isAllowEmailField && !allowEmails}
                                  onChange={
                                    isAllowEmailField
                                      ? () => setAllowEmails(!allowEmails)
                                      : null
                                  }
                                  value={config.value}>
                                  {config.label}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </SettingsCheckboxGroup>
                    </SettingsFormItem>
                  </Col>
                </Row>
              </SettingsProfileCol>
            </Row>
          </SettingsBox>
          <SettingsBox>
            <Row>
              <Col xs={24}>
                <Row>
                  <Col xs={24}>
                    <SettingsTitle>
                      {i18n.t(k.SETTING__DEFAULT_CONFIGURATION)}
                    </SettingsTitle>
                  </Col>
                </Row>
                <Row>
                  <SettingsProfileCol>
                    <SettingsFormItem
                      name={LANGUAGE_FIELD}
                      label={i18n.t(k.SETTING__LANGUAGE)}
                      rules={[{required: false}]}>
                      <LearnInSelect
                        aria-label={i18n.t(k.SETTING__LANGUAGE_PREFERENCE)}
                        placeholder={i18n.t(k.VALIDATION__SELECT_ONE)}
                        value={defaultLanguage}
                        id="language-select"
                        data-testid="language-select"
                        style={{width: `${settingsProfileColWidth}px`}}>
                        {Object.keys(LANGUAGE)
                          .sort((a, b) => a.localeCompare(b))
                          .map((lang) => {
                            if (
                              !isSkippedLanguage({
                                lang,
                                isFeatureFlagOn,
                              })
                            ) {
                              return (
                                <LearnInSelectOption
                                  key={LANGUAGE[lang].locale}
                                  value={LANGUAGE[lang].enum}
                                  data-testid={`${LANGUAGE[lang].locale}-select`}>
                                  {LANGUAGE[lang].label}
                                </LearnInSelectOption>
                              );
                            }
                          })}
                      </LearnInSelect>
                    </SettingsFormItem>
                  </SettingsProfileCol>
                  {showCurrencySettings && (
                    <SettingsProfileCol>
                      <Row>
                        <SettingsFormItem
                          name={CURRENCY_FIELD}
                          label={i18n.t(k.SETTING__CURRENCY)}
                          rules={[{required: false}]}>
                          <SettingsCurrencySelect
                            aria-label={i18n.t(k.SETTING__CURRENCY_PREFERENCE)}
                            placeholder={i18n.t(k.VALIDATION__SELECT_ONE)}
                            id="currency-select"
                            style={{
                              width: `${settingsProfileColWidth / 2}px`,
                            }}>
                            {currencyOptions}
                          </SettingsCurrencySelect>
                        </SettingsFormItem>
                      </Row>
                    </SettingsProfileCol>
                  )}
                </Row>
              </Col>
            </Row>
          </SettingsBox>

          {showIntegrationsSettings && (
            <SettingsBox>
              <Row>
                <Col xs={24}>
                  <Row>
                    <Col xs={24}>
                      <SettingsTitle>
                        {i18n.t(k.INTEGRATION__PLURAL)}
                      </SettingsTitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <SettingsIntegrations>
                        <ToggleList items={calendarIntegrations} />
                      </SettingsIntegrations>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </SettingsBox>
          )}

          <LearnInModal
            closeIcon={<div> </div>}
            maskClosable={false}
            width={400}
            tag={ModalTags.LicenseAuto}
            onCancel={() => setRequestPasswordModal(false)}
            visible={requestPasswordModal}
            footer={
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <LearnInButton
                  tag={ButtonTags.Primary}
                  onClick={() => setRequestPasswordModal(false)}>
                  {i18n.t(k.CTA__OK)}
                </LearnInButton>
              </div>
            }
            ariaLabel={i18n.t(k.NOTIFICATION__CHECK_EMAIL)}>
            <LearnInWrapper tag={WrapperTags.RequestPasswordModalWrapper}>
              <SettingsAnimatedGif src={ModalGif} alt="" />
              <LearnInText tag={TextTags.RequestPasswordModalHeader}>
                {i18n.t(k.NOTIFICATION__CHECK_EMAIL)}
              </LearnInText>
              <LearnInText tag={TextTags.LearnInPersonalBudgetDescription}>
                {i18n.t(k.NOTIFICATION__RESET_LINK)}
              </LearnInText>
            </LearnInWrapper>
          </LearnInModal>

          <SettingsFooter>
            <SettingsSaveButton
              tag={ButtonTags.Primary}
              onClick={handleSubmit}
              disabled={isSaveDisabled}>
              {i18n.t(k.CTA__SAVE_CHANGES)}
            </SettingsSaveButton>
            {showStripeLinks && (
              <SettingsPaymentTerms>
                <BodySpan size="small">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href={stripeTosUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{textDecoration: 'underline'}}
                    onClick={() => trackTermsLinkClick('settings')}>
                    {i18n.t(k.TOS__STRIPE__LINK)}
                  </a>
                </BodySpan>
              </SettingsPaymentTerms>
            )}
          </SettingsFooter>
        </SettingsForm>
      </PageContentLayout>
    </>
  );
};

export default Settings;
