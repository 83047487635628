import {
  Container,
  HeaderContainer,
} from './ReportsBySectionDetailsTable.styled';
import {ReportsBySectionDetailsTableViewProps} from './ReportsBySectionDetailsTable.interfaces';
import LearnInTable from '@components/reusable/LearnInTable';
import {i18n, k} from '@i18n/translate';
import Search from '@components/reusable/Search';
import BulkNudgeButton from '@blocks/BulkNudgeButton.view';

function ReportsBySectionDetailsTableView({
  dataSource,
  dataPanelItems,
  columns,
  isLoading,
  onChangeSearchInput,
  searchInputValue,
  noDataTableProps,
  showBulkNudgeButton,
  onClickBulkNudge,
}: ReportsBySectionDetailsTableViewProps) {
  const searchNameText = i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
    item: i18n.t(k.GENERIC__NAME),
  });
  return (
    <Container>
      <LearnInTable
        dataSource={dataSource}
        columns={columns}
        dataPanelItems={dataPanelItems}
        isLoading={isLoading}
        noDataStyle={noDataTableProps?.noDataStyle}
        noDataHeader={noDataTableProps?.noDataHeader}
        noDataSubheader={noDataTableProps?.noDataSubheader}
        customContent={
          <HeaderContainer>
            <Search
              id="reports__sections-table"
              style={{width: '270px', marginBottom: '24px'}}
              onChange={onChangeSearchInput}
              value={searchInputValue}
              placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {item: null})}
              label={searchNameText}
              buttonAriaLabel={searchNameText}
            />
            {showBulkNudgeButton && (
              <BulkNudgeButton
                onClick={onClickBulkNudge}
                style={{height: 38}}
              />
            )}
          </HeaderContainer>
        }
      />
    </Container>
  );
}

export default ReportsBySectionDetailsTableView;
