import {useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Spin} from 'antd';
import {ProgramUserRecommendationVM} from '@models/serverModels';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import LearnInTable from '@components/reusable/LearnInTable';
import {getInitialsOfName} from '@components/providers/utils';
import {mapStatusForDisplay} from '.';
import {ProgramStatus} from '@components/user/pages/program/store/interface';
import {Avatar} from 'antd';
import moment from 'moment';
import {COLORS} from '@utils/constants';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {DATE_FORMAT} from '@utils/timeUtils';
import {DebouncedFunc} from 'lodash';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  .ant-select {
    width: fit-content;
    min-width: 300px;
  }
`;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

export const getTagStyle = (status: ProgramStatus): TagStyles | undefined => {
  switch (status) {
    case ProgramStatus.ACCEPTED:
    case ProgramStatus.APPROVED:
      return TagStyles.Green;
    case ProgramStatus.PENDING:
    case ProgramStatus.APPLIED:
    case ProgramStatus.IN_PROGRESS:
    case ProgramStatus.NOT_ENROLLED:
      return TagStyles.Yellow;
    case ProgramStatus.REJECTED:
      return TagStyles.Red;
    case ProgramStatus.COMPLETED:
      return TagStyles.LightGrey;
  }
};

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

export const StatusTag = ({status}: {status: ProgramStatus}) => {
  return (
    <LearnInTag
      label={mapStatusForDisplay(status)}
      tagStyle={getTagStyle(status)}
    />
  );
};
/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface INominationsTabProps {
  onSearchEmployees: DebouncedFunc<(name: any) => Promise<void>>;
  onChangeSelect: (value: any) => void;
  selectValue: any;
  employeeSearchResults: {id: number; name: string}[];
  onClickNominate: (value: any) => void;
  dataSource: ProgramUserRecommendationVM[];
  onClickRemoveNomination: (id: number) => void;
  isAdmin: boolean;
  searchInProgress: boolean;
}

function NominationsTab({
  isAdmin,
  onSearchEmployees,
  onChangeSelect,
  selectValue,
  employeeSearchResults,
  onClickNominate,
  dataSource,
  onClickRemoveNomination,
  searchInProgress,
}: INominationsTabProps) {
  const columns = useMemo(() => {
    const defaultColumns: any[] = [
      {
        title: i18n.t(k.EMPLOYEE__NAME),
        dataIndex: 'employeeName',
        key: 'employeeName',
        render: (employeeName: any) => {
          return (
            <div>
              <Avatar
                style={{
                  backgroundColor: COLORS.Red500,
                  marginRight: '15px',
                  verticalAlign: 'middle',
                }}
                size="large"
                gap={1}>
                {getInitialsOfName(employeeName)}
              </Avatar>
              <span>{employeeName}</span>
            </div>
          );
        },
        sorter: (a: any, b: any) =>
          a.employeeName.localeCompare(b.employeeName),
      },
      {
        title: i18n.t(k.STATUS__STATUS),
        dataIndex: 'status',
        key: 'status',
        render: (status: any) => {
          return <StatusTag status={status} />;
        },
        sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      },
      {
        title: i18n.t(k.DATE__NOMINATED),
        dataIndex: 'recommendedDate',
        key: 'recommendedDate',
        render: (recommendedDate: any) =>
          moment(recommendedDate).format(DATE_FORMAT.MONTH_DAY_YEAR_PADDED),
        sorter: (a: any, b: any) =>
          moment(a.recommendedDate).diff(moment(b.recommendedDate)),
      },
    ];

    const adminColumns = [
      {
        title: i18n.t(k.NOMINATION__REMOVE),
        dataIndex: 'id',
        key: 'id',
        render: (userRecommendationId: any) => (
          <LearnInButton
            tag={ButtonTags.Footer}
            onClick={() => {
              onClickRemoveNomination(userRecommendationId);
            }}>
            {i18n.t(k.CTA__REMOVE)}
          </LearnInButton>
        ),
      },
    ];

    return isAdmin ? defaultColumns.concat(adminColumns) : defaultColumns;
  }, [isAdmin]);

  return (
    <Container>
      <div style={{width: '100%', display: 'flex', marginBottom: '24px'}}>
        <LearnInSelect
          mode="multiple"
          labelInValue
          value={selectValue}
          placeholder={i18n.t(k.EMPLOYEE__SEARCH_TO_NOMINATE)}
          notFoundContent={searchInProgress ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={onSearchEmployees}
          onChange={onChangeSelect}>
          {employeeSearchResults?.map(({id, name}) => {
            if (dataSource?.some((row) => row.userId === id)) return null;
            return (
              <LearnInSelectOption value={id} key={id}>
                {name}
              </LearnInSelectOption>
            );
          })}
        </LearnInSelect>
        <LearnInButton
          tag={ButtonTags.Primary}
          disabled={!selectValue?.length}
          onClick={onClickNominate}
          style={{marginLeft: '16px'}}>
          {i18n.t(k.CTA__NOMINATE)}
        </LearnInButton>
      </div>

      <LearnInTable dataSource={dataSource} columns={columns} />
    </Container>
  );
}

export default NominationsTab;
