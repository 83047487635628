import {useCustomProgramAgendaQuery} from '@hooks/apiEndpoints/customProgram/queries';
import AgendaScreen from './AgendaScreen';
import useSetPageTitle from '@hooks/useSetPageTitle';

interface IAgendaScreenContainerProps {
  isActivePlanItem: boolean;
  programId: number;
  title?: string;
}

function AgendaScreenContainer({
  isActivePlanItem,
  programId,
  title,
}: IAgendaScreenContainerProps) {
  const {data: agendas} = useCustomProgramAgendaQuery(programId);

  useSetPageTitle(title);

  return (
    <AgendaScreen
      agendas={agendas}
      customProgramId={programId}
      isActivePlanItem={isActivePlanItem}
    />
  );
}

export default AgendaScreenContainer;
