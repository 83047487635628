import {useState} from 'react';
import {AttachmentPreviewImg} from '@blocks/CustomProgramUserDrawer/ContentCard';
import attachmentPreviewImg from '@assets/contentAttachmentPreview.png';
import {Form} from 'antd';
import {PreApprovalQuestionInputType} from '@generated/enums';
import {useGetReimbursementAttachmentUrlQuery} from '@hooks/apiEndpoints/reimbursements/queries';
import {localizeText} from '@utils/l10nUtils';

interface ReimbursementImagesProps {
  app: any;
}
export const ReimbursementImages = ({app}: ReimbursementImagesProps) => {
  const [reimbursementAttachmentId, setReimbursementAttachmentId] = useState();

  useGetReimbursementAttachmentUrlQuery(reimbursementAttachmentId, {
    enabled: !!reimbursementAttachmentId,
    onSuccess(url) {
      if (!!url) {
        window.open(url, '_blank').focus();
        setReimbursementAttachmentId(undefined);
      }
    },
  });
  return (
    app.inputType == PreApprovalQuestionInputType.ImageUpload && (
      <Form.Item
        label={localizeText({
          translationKey: app.localizationId,
          translationValue: app.value,
        })}>
        {app?.images?.map((img) => (
          <button
            key={img.id}
            onClick={() => setReimbursementAttachmentId(img.id)}
            style={{marginLeft: '16px', marginBottom: '10px'}}>
            <AttachmentPreviewImg
              style={{maxHeight: '200px', maxWidth: '200px'}}
              src={img.image || attachmentPreviewImg}
              onError={(e: any) => (e.target.onerror = null)}
            />
          </button>
        ))}
      </Form.Item>
    )
  );
};
