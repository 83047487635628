import styled from 'styled-components';
import {COLORS, SIDE_NAV_WIDTH} from '@utils/constants';

export const FooterContainer = styled.div<{left?: number}>`
  background: white;
  position: absolute;
  height: 72px;
  bottom: 0;
  left: ${({left}) => (left >= 0 ? left : SIDE_NAV_WIDTH)}px;
  right: 0;
  box-shadow: inset 0 1px 0 ${COLORS.Neutral200};
  background: ${COLORS.White};
  display: flex;
  align-items: center;
  z-index: 100;
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 928px;
  width: 100%;
  margin: auto;
`;
