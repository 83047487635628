import './ProductSwitcher.scss';
import {useRef, useState} from 'react';
import lxpIcon from '@assets/lxp-icon@2x.png';
import academiesIcon from '@assets/academies-icon-v1@2x.png';
import {UserPaths} from '@utils/ClientPaths';
import {useNavigate} from 'react-router-dom';
import useClickOutside from '@hooks/useClickOutside';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {i18n, k} from '@i18n/translate';

/*
 Note: This product switcher component allows users to switch to the Degreed LXP via 
 popover menu.  This is only a temporary solution to the product switcher which will 
 be used internally for demo purposes until the new redesigned product switcher is completed.
*/
export const ProductSwitcher = ({showLxpOption}: {showLxpOption: boolean}) => {
  const navigate = useNavigate();
  const [switcherVisible, showProductSwitcher] = useState(false);
  const showPopover = switcherVisible ? 'product-switcher__popover-show' : '';
  const buttonRef = useRef();
  const handleClickOutside = () => {
    switcherVisible && showProductSwitcher(!switcherVisible);
  };
  useClickOutside(buttonRef, handleClickOutside);
  const {data: user} = useGetUserQuery();
  const lxpSsoUrl = user?.lxpSsoUrl;

  return (
    <div>
      <button
        ref={buttonRef}
        className="product-switcher__trigger"
        onClick={() => showProductSwitcher(!switcherVisible)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          role="img"
          focusable="false"
          data-icon="grid"
          aria-label="Toggle Product Menu"
          width="16"
          height="16">
          <path d="M14 9a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h4zM6 9a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h4zm0-8a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h4zm8 0a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h4z"></path>
          <title>Toggle Product Menu</title>
        </svg>
      </button>
      <div className={`product-switcher__popover ${showPopover}`}>
        <div className="product-switcher__popover-content">
          <span className="product-switcher__popover-content-header">
            Switch To
          </span>
          <div role="menu">
            {showLxpOption && (
              <a
                role="menuitem"
                className="product-switcher__product"
                href={lxpSsoUrl}>
                <img className="product-switcher__icon" src={lxpIcon} alt="" />
                <span>LXP</span>
              </a>
            )}
            <button
              role="menuitem"
              className="product-switcher__product"
              onClick={() => navigate(UserPaths.ManagePlan)}>
              <img
                className="product-switcher__icon"
                src={academiesIcon}
                alt=""
              />
              <span>{i18n.t(k.GENERIC__APP_NAME)}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
