import {UserBudget} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {BudgetMockData, getBudgetMockData} from './Budget.mock';
import {UserCompanyMockData, getUserCompanyMockData} from './UserCompany.mock';
import {
  UserFinanceIncentiveMockData,
  getUserFinanceIncentiveMockData,
} from './UserFinanceIncentive.mock';
import {
  UserPersonalLearningBudgetSnapshotMockData,
  getUserPersonalLearningBudgetSnapshotMockData,
} from './UserPersonalLearningBudgetSnapshot.mock';
import {faker} from '@faker-js/faker';

// Interface: UserBudget

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserBudgetMockData(args?: Partial<UserBudget>): UserBudget {
  return {
    ...getBaseEntityMockData(),
    amountAllocated: faker.datatype.number(),
    availableRemainingAmount: faker.datatype.number(),
    budget: getBudgetMockData(),
    budgetId: faker.datatype.number(),
    endDate: String(faker.date.past()),
    id: faker.datatype.number(),
    migratedData: faker.datatype.boolean(),
    oldUserFinanceIncentive: getUserFinanceIncentiveMockData(),
    oldUserFinanceIncentiveId: faker.datatype.number(), // optional
    oldUserPersonalLearningBudgetSnapshot:
      getUserPersonalLearningBudgetSnapshotMockData(),
    oldUserPersonalLearningBudgetSnapshotId: faker.datatype.number(), // optional
    startDate: String(faker.date.past()),
    totalApprovedAmount: faker.datatype.number(),
    totalRequestedAmount: faker.datatype.number(),
    userCompany: getUserCompanyMockData(),
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserBudgetMockData: UserBudget = {
  ...BaseEntityMockData,
  amountAllocated: 5,
  availableRemainingAmount: 5,
  budget: BudgetMockData,
  budgetId: 1,
  endDate: '2023-03-29T22:17:19.738072',
  id: 1,
  migratedData: true,
  oldUserFinanceIncentive: UserFinanceIncentiveMockData,
  oldUserFinanceIncentiveId: 1, // optional
  oldUserPersonalLearningBudgetSnapshot:
    UserPersonalLearningBudgetSnapshotMockData,
  oldUserPersonalLearningBudgetSnapshotId: 1, // optional
  startDate: '2023-03-29T22:17:19.738072',
  totalApprovedAmount: 5,
  totalRequestedAmount: 5,
  userCompany: UserCompanyMockData,
  userCompanyId: 1,
};
