import './StepsProgress.scss';
import {i18n, k} from '@i18n/translate';
import {Card} from 'antd';
import {InitiativeFlowSteps} from '@components/admin/pages/initiatives/store/interface';
import {InitiativeStepTitles} from '@components/admin/pages/initiatives/components/NewInitiative/NewInitiative.container';

export interface StepsInterface {
  title: string;
}
interface Props {
  current: number;
  steps: StepsInterface[];
  setCurrentStep: (step: any) => void;
  canSkip?: boolean;
}

const StepsProgress = ({steps, current, setCurrentStep, canSkip}: Props) => {
  const totalSteps = steps.length;

  const getBarClassName = (index: number): string => {
    let classString = 'bar-container';
    if (index === current) {
      classString = classString + ' bar-container-active';
    } else if (index > current) {
      classString = classString + ' bar-container-pending';
    } else {
      classString = classString + ' bar-container-visited';
    }

    if (index === 0) {
      classString = classString + ' bar-container-first';
    } else if (index === steps.length - 1) {
      classString = classString + ' bar-container-last';
    }

    return classString;
  };

  function GetStepNumber(step: StepsInterface) {
    switch (step.title) {
      case InitiativeStepTitles.TheBasics:
        return InitiativeFlowSteps.TheBasics;
      case InitiativeStepTitles.Financial:
        return InitiativeFlowSteps.Finance;
      case InitiativeStepTitles.Programs:
        return InitiativeFlowSteps.Programs;
      case InitiativeStepTitles.Time:
        return InitiativeFlowSteps.Time;
    }
  }

  const stepClicked = (step: StepsInterface): void => {
    const stepNumber = GetStepNumber(step);
    if (stepNumber < current || canSkip) {
      setCurrentStep(stepNumber);
    }
  };

  return (
    <Card
      role="figure"
      aria-label={i18n.t(k.PROGRESS)}
      style={{margin: '16px 32px'}}
      className={'steps-progress-card'}>
      <div style={{display: 'flex'}}>
        {steps.map((step, index) => (
          <div
            style={{width: `calc(100% / ${totalSteps})`}}
            key={index}
            className={getBarClassName(index)}>
            <div className="bar"></div>
            <button className="bar-title" onClick={() => stepClicked(step)}>
              {step.title}
            </button>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default StepsProgress;
