import './InitiativeProgramsPanel.scss';
import {Fragment, useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {
  Card,
  Col,
  Divider,
  Input,
  Row,
  Skeleton,
  Typography,
} from 'antd';
import {COLORS} from '@utils/constants';
import {getSearchInitiativesProgramsRq} from '@store/apiEndpoints/program/queries';
import {useQuery} from 'react-query';
import {InitiativePayload, ProgramSearchResultVM} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import {debounce} from 'lodash';
import styled from 'styled-components';
import InitiativeCard from '@components/reusable/ItemSearchCard/InitiativeCard';
import {PlanItemType} from '@generated/enums';
import {buildMetadataArray} from '@utils/metadataUtils';
import {getCurrencyEnum} from '@utils/moneyUtils';
import NoDataImage from '@blocks/NoDataImage';
import {Paginator} from '@blocks/CustomProgramUserDrawer/MembersDrawer/Paginator';

const PaginationContainer = styled.div`
  margin: 10px 0px 38px;
  display: flex;
  justify-content: flex-end;
`;

const PaginationStatus = styled.p`
  font-family: Roboto;
  font-style: normal;
  color: ${COLORS.Neutral900};
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 8px;
  margin-top: 20px;
`;

const {Search} = Input;
const PROGRAMS_PER_PAGE = 30;
interface Props {
  updateSelectedInitiativeItem: (
    arg1: boolean,
    programId: number,
    planItemType: number
  ) => void;
  data?: InitiativePayload;
}

const InitiativeProgramsPanel = ({
  updateSelectedInitiativeItem,
  data,
}: Props) => {
  const [page, setPage] = useState<number>(0);
  const [searchProgramString, setSearchProgramString] = useState('');
  const [programs, setPrograms] = useState<ProgramSearchResultVM[]>([]);
  const [totalProgramsCount, setTotalProgramsCount] = useState<number>(0);
  const [showPagination, setShowPagination] = useState(false);
  const [isResultEmpty, setIsResultEmpty] = useState(false);
  const totalSelectedItems = data?.programIds?.length;

  const searchInitiativesProgramsRm = getSearchInitiativesProgramsRq(
    searchProgramString,
    data?.id
  );
  const searchInitiativesProgramsQuery = useQuery<ProgramSearchResultVM[]>(
    searchInitiativesProgramsRm.queryKey,
    () => simpleQueryFn(searchInitiativesProgramsRm.path),
    {
      placeholderData: () => [],
      onSuccess: (data1) => {
        setIsResultEmpty(!data1?.length);
      },
    }
  );

  const onSearch = (searchString: string) => {
    setSearchProgramString(searchString);
    debounce((searchString: any) => {
      setSearchProgramString(searchString);
    }, 1000);
  };

  const updateProgramSearchResults = () => {
    setPage(0);
    setPrograms(searchInitiativesProgramsQuery.data);
    const newProgramCount = searchInitiativesProgramsQuery.data?.length || 0;
    setTotalProgramsCount(newProgramCount);
    setShowPagination(newProgramCount > PROGRAMS_PER_PAGE);
  };
  useEffect(() => {
    updateProgramSearchResults();
  }, [searchInitiativesProgramsQuery.data]);

  // Determine subset of programs to display
  const programsIdxMin = PROGRAMS_PER_PAGE * page;
  const programsIdxMax = PROGRAMS_PER_PAGE * page + PROGRAMS_PER_PAGE;
  const slicedPrograms = programs.slice(programsIdxMin, programsIdxMax);

  const LoadingPlaceholder = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Skeleton active />
        </Col>
        <Col xs={24}>
          <Skeleton active />
        </Col>
        <Col xs={24}>
          <Skeleton active />
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <Card
        role="article"
        aria-labelledby="sub-component-card-title"
        className="sub-component-card">
        <Row className="sub-component-card-header">
          <Typography.Title
            className="sub-component-card-title"
            id="sub-component-card-title">
            {i18n.t(k.INITIATIVE__SELECT_PROGRAMS_FOR)}
          </Typography.Title>
        </Row>
        <Divider style={{background: COLORS.Neutral300, margin: '8px 0'}} />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '16px 0',
          }}>
          <Search
            style={{width: 200}}
            className="sub-component-card-search-bar"
            onSearch={onSearch}
            allowClear
            placeholder={i18n.t(k.A11Y__SEARCH_FOR_PROGRAMS)}
          />
          <Typography.Text
            style={{
              fontWeight: 'normal',
              fontSize: '1rem',
              lineHeight: '2.5rem',
              textAlign: 'right',
              color: COLORS.Black,
            }}>
            {totalSelectedItems ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t(
                    totalSelectedItems === 1
                      ? k.PROGRAM__SELECTED__SINGLE
                      : k.PROGRAM__SELECTED__PLURAL__FORMAT,
                    {
                      number: totalSelectedItems,
                    }
                  ),
                }}></span>
            ) : (
              i18n.t(k.PROGRAM__SELECTED__NONE)
            )}{' '}
          </Typography.Text>
        </div>

        {searchInitiativesProgramsQuery.isSuccess && showPagination && (
          <PaginationStatus>
            {i18n.t(k.GENERIC__SHOWING_X_TO_Y_OF_Z__FORMAT, {
              startCount: programsIdxMin + 1,
              endCount: programsIdxMax,
              totalCount: totalProgramsCount,
              itemType: i18n.t(k.PROGRAM__PLURAL),
            })}
          </PaginationStatus>
        )}

        {searchInitiativesProgramsQuery.isSuccess &&
          slicedPrograms.map((program) => {
            const handleClickAddToInitiative = () => {
              updateSelectedInitiativeItem(
                true,
                program.id,
                PlanItemType.Program
              );
            };
            const handleClickSelected = () => {
              updateSelectedInitiativeItem(
                false,
                program.id,
                PlanItemType.Program
              );
            };
            return (
              <InitiativeCard
                key={program.id}
                id={program.id}
                description={program.shortDescription}
                itemType={PlanItemType.Program}
                metadata={buildMetadataArray(program)}
                onClickAddToInitiative={handleClickAddToInitiative}
                onClickSelected={handleClickSelected}
                providerImgSrc={program.providerLogo}
                previewImgSrc={program.imageLarge}
                selected={data.programIds?.includes(program.id)}
                provider={program.provider}
                title={program.title}
                cost={program.cost}
                currency={getCurrencyEnum(program.currency)}
              />
            );
          })}

        {showPagination && !!programs.length && (
          <PaginationContainer>
            <Paginator
              current={page + 1}
              onChange={(page) => {
                setPage(page - 1);
              }}
              pageSize={PROGRAMS_PER_PAGE}
              showSizeChanger={false}
              total={programs.length}
            />
          </PaginationContainer>
        )}

        {searchInitiativesProgramsQuery.isLoading ||
        (!searchInitiativesProgramsQuery.data.length && !isResultEmpty) ? (
          <LoadingPlaceholder />
        ) : (
          !searchInitiativesProgramsQuery.data.length &&
          isResultEmpty && <NoDataImage />
        )}
      </Card>
    </Fragment>
  );
};

export default InitiativeProgramsPanel;
