import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import PageContentLayout from '../../components/providers/pageContentLayout/PageContentLayout';
import PageTitleBar from '../../components/providers/pageTitleBar/PageTitleBar';
import UserPlanItemCard from '../../components/user/pages/userPlan/components/userPlanItemCard/UserPlanItemCard';
import AddReimbursementDrawer from '../../components/user/pages/userPlan/components/addReimbursementDrawer/AddReimbursementDrawer';
import {Row, Col, Skeleton} from 'antd';
import ProgressCard from '../../blocks/progressCard/ProgressCard';
import LoadingSpinner from '../../elements/loadingSpinner/LoadingSpinner';
import ManagePrepayment from '../../blocks/managePrepayment/ManagePrepayment';
import {
  ReimbursementTargetVM,
  ReimbursementVM,
  UserPlanItemCardVM,
} from '@models/serverModels';
import {FinanceType} from '@generated/enums';
import {includes} from 'lodash';
import {useEffect, useState} from 'react';
import {formatCurrency} from '@utils/moneyUtils';
import {ReimbursementPayload} from '@models/api/finance/payloads';

interface ManageFinanceProps {
  userPlanItemId: string | number;
  item: UserPlanItemCardVM | undefined;
  isUserItemFetchLoading: boolean;
  userItemFetchError: string;
  userItemData: UserPlanItemCardVM | undefined;
  isUserReimbursementFetchLoading: boolean;
  userReimbursementFetchError: string;
  userReimbursementData: ReimbursementVM[] | undefined;
  refetchUserReimbursements: () => void;
  isUserReimbursementTargetsFetchLoading: boolean;
  userReimbursementTargetsFetchError: string;
  userReimbursementTargetsData: ReimbursementTargetVM | undefined;
  addReimbursements: (payload: ReimbursementPayload) => void;
}

const REIMBURSABLE_TYPES = [
  FinanceType.TuitionReimbursement,
  FinanceType.Scholarship,
];

const ManageFinance = ({
  userPlanItemId,
  item,
  isUserItemFetchLoading,
  userItemData,
  isUserReimbursementFetchLoading,
  userReimbursementData,
  refetchUserReimbursements,
  isUserReimbursementTargetsFetchLoading,
  userReimbursementTargetsData,
  addReimbursements,
}: ManageFinanceProps) => {
  const [showAddReimbursementDrawer, setShowAddReimbursementDrawer] =
    React.useState(false);
  const [isPrepaymentIncentive, setIsPrepaymentIncentive] =
    useState<boolean>(false);

  useEffect(() => {
    if (!isUserReimbursementTargetsFetchLoading) {
      setIsPrepaymentIncentive(
        userReimbursementTargetsData?.financeType === FinanceType.Prepayment
      );
    }
  }, [userReimbursementData, isUserReimbursementTargetsFetchLoading]);

  const _formatCurrency = formatCurrency;

  return (
    <>
      <PageTitleBar
        title={i18n.t(k.CTA__MANAGE, {item: i18n.t(k.GENERIC__FINANCING)})}
      />
      <PageContentLayout>
        {isUserReimbursementTargetsFetchLoading &&
        !userReimbursementTargetsData ? (
          <LoadingSpinner />
        ) : (
          <Row align="middle" justify="center">
            <Col xs={24}>
              <AddReimbursementDrawer
                visible={showAddReimbursementDrawer}
                targets={userReimbursementTargetsData || []}
                userPlanItemId={userPlanItemId}
                onOk={async () => {
                  await refetchUserReimbursements();
                  setShowAddReimbursementDrawer(false);
                }}
                onCancel={() => {
                  setShowAddReimbursementDrawer(false);
                }}
                addReimbursements={addReimbursements}
                formatCurrency={_formatCurrency}
              />
            </Col>
          </Row>
        )}
        {isUserItemFetchLoading && (
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Skeleton active />
            </Col>
          </Row>
        )}
        {(item || (userItemData && !isUserItemFetchLoading)) &&
          !userItemData?.isPersonalLearningBudget && (
            <div style={{marginBottom: '24px'}}>
              <UserPlanItemCard
                key={'1'}
                showActions={false}
                item={item || userItemData}
                isPrepaymentIncentive={isPrepaymentIncentive}
              />
            </div>
          )}
        {includes(
          REIMBURSABLE_TYPES,
          userReimbursementTargetsData?.financeType
        ) &&
          (item ||
            (userReimbursementData && !isUserReimbursementFetchLoading)) && (
            <Row>
              <Col span={24}>
                <ProgressCard
                  setShowAddReimbursementDrawer={setShowAddReimbursementDrawer}
                  reimbursementData={userReimbursementData || []}
                />
              </Col>
            </Row>
          )}
        {userReimbursementTargetsData?.financeType ===
          FinanceType.Prepayment && (
          <ManagePrepayment planItemId={userPlanItemId} />
        )}
      </PageContentLayout>
    </>
  );
};
export default ManageFinance;
