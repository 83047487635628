import {ErrorMessage} from '@components/reusable/LearnInForm';
import {
  CurrencyCode,
  LicenseRedemptionType,
  LicenseUsageType,
  VoucherAssignMode,
} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {Checkbox, Col, Radio, RadioChangeEvent, Row, Typography} from 'antd';
import {
  handleLicenseDurationBlur,
  handleLicenseDurationChange,
  handleLicenseRedemptionTypeChange,
} from '../AddUpdateLicenseDrawer.handlers';
import {
  LicenseAside,
  LicenseDurationItem,
  LicenseFieldSubText,
  LicenseFormItem,
  LicenseFundingType,
  LicenseLabel,
  LicenseNumberInput,
  LicenseOptionDetails,
  LicenseRadioRow,
  LicenseUsageItem,
} from '../AddUpdateLicenseDrawer.styled';
import {
  LicenseDuration,
  LicenseFormFieldErrors,
  LicenseFormFields,
  LicenseInternalCost,
} from '../AddUpdateLicenseDrawer.types';
import LicenseCostItem from './LicenseCostItem';
import useFeatureFlags from '@hooks/useFeatureFlags';

interface LicenseFundingTypeItemProps {
  currency: CurrencyCode;
  formFieldErrors: LicenseFormFieldErrors;
  internalCost: number;
  internalCostType: LicenseInternalCost;
  licenseDuration: number;
  licenseDurationType: LicenseDuration;
  redemptionType: LicenseRedemptionType;
  voucherAssignMode: VoucherAssignMode;
  setFormFieldErrors: (errors: LicenseFormFieldErrors) => void;
  setInternalCost: (cost: number) => void;
  setInternalCostType: (costType: LicenseInternalCost) => void;
  setLicenseDuration: (duration: number) => void;
  setLicenseDurationType: (durationType: LicenseDuration) => void;
  setRedemptionType: (redemptionType: LicenseRedemptionType) => void;
  setType: (type: LicenseUsageType) => void;
  setVoucherAssignMode: (mode: VoucherAssignMode) => void;
}

function LicenseFundingTypeItem({
  currency,
  formFieldErrors,
  internalCost,
  internalCostType,
  licenseDuration,
  licenseDurationType,
  redemptionType,
  setFormFieldErrors,
  setInternalCost,
  setInternalCostType,
  setLicenseDuration,
  setLicenseDurationType,
  setRedemptionType,
  setType,
  voucherAssignMode,
  setVoucherAssignMode,
}: LicenseFundingTypeItemProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isVoucherCodeOn = isFeatureFlagOn.VoucherCodes;

  return (
    <LicenseFundingType>
      <LicenseFormItem
        label={
          <LicenseLabel required>
            {i18n.t(k.LICENSE__FUNDING_TYPE)}
          </LicenseLabel>
        }
        name={LicenseFormFields.InternalCostType}>
        <LicenseRadioRow>
          <Col>
            <Radio
              value={LicenseRedemptionType.Voucher}
              checked={LicenseRedemptionType.Voucher === redemptionType}
              onChange={(event) =>
                handleLicenseRedemptionTypeChange({
                  formFieldErrors,
                  redemptionType: event?.target?.value,
                  setFormFieldErrors,
                  setInternalCostType,
                  setRedemptionType,
                  shouldSetError: true,
                })
              }>
              <Typography.Text>{i18n.t(k.LICENSE)}</Typography.Text>
              <LicenseFieldSubText size="small">
                {i18n.t(k.LICENSE__OPTION)}
              </LicenseFieldSubText>

              {LicenseRedemptionType.Voucher === redemptionType && (
                <LicenseOptionDetails>
                  <LicenseCostItem
                    currency={currency}
                    formFieldErrors={formFieldErrors}
                    internalCost={internalCost}
                    internalCostType={internalCostType}
                    setFormFieldErrors={setFormFieldErrors}
                    setInternalCost={setInternalCost}
                    setInternalCostType={setInternalCostType}
                    setRedemptionType={setRedemptionType}
                  />
                  <LicenseUsageItem
                    label={
                      <LicenseLabel required>
                        {i18n.t(k.LICENSE__USAGE)}
                      </LicenseLabel>
                    }
                    name={LicenseFormFields.Type}>
                    <Radio.Group name={i18n.t(k.LICENSE__USAGE)}>
                      <Row>
                        <Col>
                          <Radio
                            id={i18n.t(k.AVAILABILITY__PROVIDER)}
                            value={LicenseUsageType.AllPrograms}
                            onChange={() =>
                              setType(LicenseUsageType.AllPrograms)
                            }>
                            {i18n.t(k.AVAILABILITY__PROVIDER)}
                          </Radio>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio
                            id={i18n.t(k.AVAILABILITY__REQUESTED)}
                            value={LicenseUsageType.RequestedProgram}
                            onChange={() =>
                              setType(LicenseUsageType.RequestedProgram)
                            }>
                            <Typography.Text>
                              {i18n.t(k.AVAILABILITY__REQUESTED)}
                            </Typography.Text>
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </LicenseUsageItem>
                  {isVoucherCodeOn && (
                    <LicenseUsageItem
                      label={
                        <LicenseLabel>
                          {i18n.t(k.LICENSE__ALLOCATION)}
                        </LicenseLabel>
                      }
                      name={LicenseFormFields.VoucherAssignMode}>
                      <Row>
                        <Col>
                          <Checkbox
                            checked={
                              VoucherAssignMode.AutoAssignCode ===
                              voucherAssignMode
                            }
                            onClick={() =>
                              setVoucherAssignMode(
                                voucherAssignMode ===
                                  VoucherAssignMode.AutoAssignCode
                                  ? VoucherAssignMode.NoCodes
                                  : VoucherAssignMode.AutoAssignCode
                              )
                            }>
                            {i18n.t(k.LICENSE__AUTO_ALLOCATE)}
                          </Checkbox>
                        </Col>
                      </Row>
                    </LicenseUsageItem>
                  )}

                  <LicenseDurationItem
                    label={
                      <LicenseLabel required>
                        {i18n.t(k.LICENSE__ACTIVE_DURATION)}
                      </LicenseLabel>
                    }
                    name={LicenseFormFields.LicenseDurationType}>
                    <Radio.Group
                      onChange={(event: RadioChangeEvent) =>
                        handleLicenseDurationChange({
                          event,
                          formFieldErrors,
                          licenseDurationType,
                          setFormFieldErrors,
                          setLicenseDurationType,
                        })
                      }>
                      <Row>
                        <Col>
                          <Radio value={LicenseDuration.Fixed}>
                            <LicenseNumberInput
                              type="number"
                              min="0"
                              value={licenseDuration | 0}
                              updateFunc={setLicenseDuration}
                              onChange={(e) =>
                                setLicenseDuration(Number(e.target.value))
                              }
                              id={LicenseFormFields.LicenseDuration}
                              disabled={
                                licenseDurationType !== LicenseDuration.Fixed
                              }
                              onBlur={(e) => {
                                handleLicenseDurationBlur({
                                  duration: Number(e?.target?.value),
                                  redemptionType,
                                  formFieldErrors,
                                  licenseDurationType,
                                  setFormFieldErrors,
                                  shouldSetError: true,
                                });
                              }}
                            />
                            <LicenseAside>
                              <Typography.Text>
                                {i18n
                                  .t(k.DATE__DAY__PLURAL)
                                  .toLocaleLowerCase()}
                              </Typography.Text>
                            </LicenseAside>
                            <ErrorMessage
                              message={
                                formFieldErrors[
                                  LicenseFormFields.LicenseDuration
                                ]
                              }
                            />
                          </Radio>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Radio value={LicenseDuration.NoExpiration}>
                            <Typography.Text>
                              {i18n.t(k.STATUS__EXPIRES_NEVER)}
                            </Typography.Text>
                          </Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </LicenseDurationItem>
                </LicenseOptionDetails>
              )}
            </Radio>
          </Col>
        </LicenseRadioRow>
        <LicenseRadioRow>
          <Col>
            <Radio
              value={LicenseRedemptionType.External}
              checked={LicenseRedemptionType.External === redemptionType}
              onChange={(event) =>
                handleLicenseRedemptionTypeChange({
                  formFieldErrors,
                  redemptionType: event?.target?.value,
                  setFormFieldErrors,
                  setInternalCostType,
                  setRedemptionType,
                  shouldSetError: true,
                })
              }>
              <Typography.Text>
                {i18n.t(k.LICENSE__DIRECT_FUNDING)}
              </Typography.Text>
              <LicenseFieldSubText size="small">
                {i18n.t(k.LICENSE__DIRECT_FUNDING__OPTION)}
              </LicenseFieldSubText>
            </Radio>
            {LicenseRedemptionType.External === redemptionType && (
              <LicenseOptionDetails>
                <LicenseCostItem
                  currency={currency}
                  formFieldErrors={formFieldErrors}
                  internalCost={internalCost}
                  internalCostType={internalCostType}
                  setFormFieldErrors={setFormFieldErrors}
                  setInternalCost={setInternalCost}
                  setInternalCostType={setInternalCostType}
                  setRedemptionType={setRedemptionType}
                />
              </LicenseOptionDetails>
            )}
          </Col>
        </LicenseRadioRow>
        <ErrorMessage
          message={formFieldErrors[LicenseFormFields.RedemptionType]}
        />
      </LicenseFormItem>
    </LicenseFundingType>
  );
}

export default LicenseFundingTypeItem;
