import {ReactMutateRequest} from './../utils';
import {BASE_PATH} from '.';
import {UserSettingsVM} from '@models/serverModels';

export const getUpdateSettingsRm = (
  settings: UserSettingsVM
): ReactMutateRequest => {
  const path = `/${BASE_PATH}/update-settings`;
  return {
    path,
    payload: settings,
  };
};

export const getRequestPasswordResetEmailRm = (): ReactMutateRequest => {
  const path = `/${BASE_PATH}/request-password-reset-email`;
  return {
    path,
    payload: {},
  };
};
