import {
  CurrencyCode,
  LicenseRedemptionType,
  LicenseStatus,
  LicenseUsageType,
  VoucherAssignMode,
} from '@generated/enums';
import {LicensePayload, ProgramFilterVM} from '@generated/interfaces';
import {FormInstance} from 'antd';
import ReactQuill from 'react-quill';

export interface AddUpdateLicenseDrawerContainerProps {
  id?: number;
  onClose: () => void;
  providersInUse: number[];
  refreshParentData?: () => void;
  visible: boolean;
}

export interface AddUpdateLicenseVM {
  id: number;
  companyId: number;
  currency?: CurrencyCode;
  type: LicenseUsageType;
  providerId: number;
  licenseDuration: number;
  internalCost: number;
  currencyCode: CurrencyCode;
  status: LicenseStatus;
  requireManagerApproval: boolean;
  requireAdminApproval: boolean;
  redemptionGuideline: string;
  approvalGuideline: string;
}

export interface AddUpdateLicenseDrawerViewProps {
  a11yAlertMessage: string;
  approvalGuideline: string;
  approvalGuidelineRef: React.MutableRefObject<ReactQuill>;
  currency: CurrencyCode;
  defaultFormValues: LicenseFormData;
  form: FormInstance;
  formFieldErrors: LicenseFormFieldErrors;
  handleOnSubmit: () => void;
  internalCost: number;
  internalCostType: LicenseInternalCost;
  isNew: boolean;
  license: AddUpdateLicenseVM;
  licenseDuration: number;
  licenseDurationType: LicenseDuration;
  loading: boolean;
  onClose: () => void;
  provider: string;
  providerId: number;
  providers: ProgramFilterVM[];
  providersInUse: number[];
  providerSuggestions: {label: string; value: string; id: number}[];
  redemptionGuideline: string;
  redemptionGuidelineRef: React.MutableRefObject<ReactQuill>;
  redemptionType: LicenseRedemptionType;
  requireAdminApproval: boolean;
  requireManagerApproval: boolean;
  setApprovalGuideline: (guideline: string) => void;
  setFormFieldErrors: (errors: LicenseFormFieldErrors) => void;
  setInternalCost: (cost: number) => void;
  setInternalCostType: (costType: LicenseInternalCost) => void;
  setLicenseDuration: (duration: number) => void;
  setLicenseDurationType: (duration: LicenseDuration) => void;
  setProvider: (provider: string) => void;
  setProviderId: (provider: number) => void;
  setRedemptionGuideline: (guideline: string) => void;
  setRedemptionType: (redemptionType: LicenseRedemptionType) => void;
  setRequireAdminApproval: (required: boolean) => void;
  setRequireManagerApproval: (required: boolean) => void;
  setStatus: (status: LicenseStatus) => void;
  setType: (type: LicenseUsageType) => void;
  visible: boolean;
  voucherAssignMode: VoucherAssignMode;
  setVoucherAssignMode: (mode: VoucherAssignMode) => void;
}

export enum LicenseCharLimits {
  Description = 10000,
}

export enum LicenseDuration {
  Fixed = 'fixed',
  NoExpiration = 'noExpiration',
}

export enum LicenseInternalCost {
  Fixed = 'fixed',
  Dependent = 'dependent',
  Zero = 'zero',
}

export const LICENSE_DURATION_NON_FIXED = -1;

export enum LicenseFormFields {
  ApprovalGuideline = 'approvalGuideline',
  Approvals = 'approvals',
  CompanyId = 'companyId',
  Currency = 'currency',
  Id = 'id',
  InternalCost = 'internalCost',
  InternalCostType = 'internalCostType',
  LicenseDuration = 'licenseDuration',
  LicenseDurationType = 'licenseDurationType',
  ProviderId = 'providerId',
  RedemptionGuideline = 'redemptionGuideline',
  RedemptionType = 'redemptionType',
  RequireAdminApproval = 'requireAdminApproval',
  RequireManagerApproval = 'requireManagerApproval',
  Status = 'status',
  Type = 'type',
  VoucherAssignMode = 'voucherAssignMode',
}

export interface LicenseFormFieldErrors {
  [LicenseFormFields.ApprovalGuideline]?: string;
  [LicenseFormFields.InternalCost]?: string;
  [LicenseFormFields.InternalCostType]?: string;
  [LicenseFormFields.LicenseDuration]?: string;
  [LicenseFormFields.LicenseDurationType]?: string;
  [LicenseFormFields.ProviderId]?: string;
  [LicenseFormFields.RedemptionGuideline]?: string;
  [LicenseFormFields.RedemptionType]?: string;
  [LicenseFormFields.RequireAdminApproval]?: string;
  [LicenseFormFields.RequireManagerApproval]?: string;
  [LicenseFormFields.Status]?: string;
  [LicenseFormFields.Type]?: string;
}

/** Client-side model which extends the server's LicensePayload with non-submitted form fields. These extra fields are used to handle conditions which will alter LicensePayload values before form submission. */
export interface LicenseFormData extends LicensePayload {
  licenseDurationType: LicenseDuration;
  internalCostType: LicenseInternalCost;
}

export interface LicenseSubmissionProps extends LicenseFormData {
  license: AddUpdateLicenseVM;
}

export interface LicenseSubmissionWithErrorsProps
  extends LicenseSubmissionProps {
  formFieldErrors?: LicenseFormFieldErrors;
  setFormFieldErrors?: (errors: LicenseFormFieldErrors) => void;
}
