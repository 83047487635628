import './TransactionList.scss';
import {i18n, k} from '@i18n/translate';
import moment from 'moment';
import {CreditCardTransactionVM} from '@models/api/payment/viewModels';
import {
  adjustAmountByStatus,
  formatCurrency,
  getColorByAmountAndStatus,
} from '@utils/moneyUtils';
import LearnInTable, {ColumnAlignment} from '@components/reusable/LearnInTable';
import {CurrencyCode} from '@generated/enums';
import {DATE_FORMAT} from '@utils/timeUtils';
import {TransactionStatuses} from '@models/clientEnums';
import {renderBalanceColumn} from '@utils/tableUtils';

interface Props {
  data: CreditCardTransactionVM[];
  exchangeRate: number;
  usersCurrency: CurrencyCode;
}

const TransactionList = ({data, exchangeRate, usersCurrency}: Props) => {
  const transactionColumns = [
    {
      title: i18n.t(k.GENERIC__DESCRIPTION),
      dataIndex: 'description',
      key: 'description',
      render: (description: string) =>
        k[description] ? i18n.t(k[description]) : description,
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
    },
    {
      title: i18n.t(k.DATE),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp: any) =>
        moment(timestamp).format(DATE_FORMAT.MONTH_DAY_YEAR),
      sorter: (a: any, b: any) => {
        return moment(a.timestamp).diff(moment(b.timestamp));
      },
    },
    {
      title: i18n.t(k.STATUS__STATUS),
      dataIndex: 'status',
      key: 'status',
      render: (status: any) => status,
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
    },
    {
      title: (
        <div style={{display: 'flex', justifyContent: 'end'}}>
          {i18n.t(k.GENERIC__AMOUNT)}
        </div>
      ),
      dataIndex: 'amount',
      key: 'amount',
      align: ColumnAlignment.Right,
      render: (amount: number, row: CreditCardTransactionVM) =>
        renderBalanceColumn({
          amount,
          status: row?.status?.toLowerCase(),
          usersCurrency,
          exchangeRate,
        }),
      sorter: (a: any, b: any) => a.amount - b.amount,
    },
  ];

  return (
    <LearnInTable
      dataSource={!!data?.length ? data : []}
      columns={transactionColumns}
    />
  );
};

export default TransactionList;
