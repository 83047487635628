import './ProgressReportList.scss';
import {Fragment, useCallback, useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import moment from 'moment';
import ProgressReportDetailsDrawer from './components/progressReportDetailsDrawer/ProgressReportDetailsDrawer';
import {ProgressReportItemSummary} from '../../store/interface';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  ProgramProgressUpdateDetailsVM,
  ProgramProgressUpdateResult,
} from '@models/serverModels';
import LearnInTable from '@components/reusable/LearnInTable';
import {DATE_FORMAT} from '@utils/timeUtils';

const getSummaryString = (summaryIndex: number): string => {
  switch (summaryIndex) {
    case ProgressReportItemSummary.FeelingGreat:
      return i18n.t(k.PROGRESS__STATUS__FEELING_GREAT);
    case ProgressReportItemSummary.MakingProgress:
      return i18n.t(k.PROGRESS__STATUS__MAKING_PROGRESS);
    case ProgressReportItemSummary.TooBusy:
      return i18n.t(k.PROGRESS__STATUS__TOO_BUSY);
    case ProgressReportItemSummary.Stressed:
      return i18n.t(k.PROGRESS__STATUS__STRESSED);
    case ProgressReportItemSummary.NeedHelp:
      return i18n.t(k.PROGRESS__STATUS__NEED_HELP);
    default:
      return '-';
  }
};

interface ProgressReportListProps {
  data: ProgramProgressUpdateResult[];
  fetchProgressReportDetails: (id: string | number) => void;
  progressReportDetailData: ProgramProgressUpdateDetailsVM[];
  progressReportDetailLoading: boolean;
}

const ProgressReportList = ({
  data,
  fetchProgressReportDetails,
  progressReportDetailData,
  progressReportDetailLoading,
}: ProgressReportListProps) => {
  const [progressReportVisible, setProgressReportVisible] = useState(false);
  const progressReportColumns = useMemo(() => {
    return [
      {
        title: i18n.t(k.EMPLOYEE__NAME),
        dataIndex: 'employeeName',
        key: 'employeeName',
        render: (employee: any) => employee,
        sorter: (a: any, b: any) =>
          a.employeeName.localeCompare(b.employeeName),
      },
      {
        title: i18n.t(k.DATE),
        dataIndex: 'submittedDate',
        key: 'submittedDate',
        render: (date: any) => {
          const formattedDate = moment(date).format(DATE_FORMAT.MONTH_DAY_YEAR);
          return formattedDate;
        },
        sorter: (a: any, b: any) => {
          return moment(a.date).diff(moment(b.date));
        },
      },
      {
        title: i18n.t(k.GENERIC__RATING),
        dataIndex: 'rating',
        key: 'rating',
        render: (rating: any) => (rating ? `${rating}/5` : '-'),
        sorter: (a: any, b: any) => a.rating - b.rating,
      },
      {
        title: i18n.t(k.GENERIC__SUMMARY),
        dataIndex: 'summary',
        key: 'summary',
        render: (summary: any) => getSummaryString(summary),
        sorter: (a: any, b: any) => a.summary - b.summary,
      },
      {
        title: i18n.t(k.GENERIC__SKILL__PLURAL),
        dataIndex: 'skills',
        key: 'skills',
        render: (skills: any) => skills || '-',
        sorter: (a: any, b: any) => a.skills.localeCompare(b.skills),
      },
      {
        key: 'details',
        title: i18n.t(k.GENERIC__DETAIL__PLURAL),
        render: (_: any, row: any) => {
          return (
            <LearnInButton
              tag={ButtonTags.Secondary}
              onClick={() => {
                changeProgressReportDetailsDrawer(true, row.id);
              }}>
              {i18n.t(k.CTA__VIEW__DETAIL__PLURAL)}
            </LearnInButton>
          );
        },
      },
    ];
  }, []);

  const changeProgressReportDetailsDrawer = useCallback(
    (showProgressDrawer: boolean, id?: number) => {
      if (showProgressDrawer) {
        fetchProgressReportDetails(id);
        setProgressReportVisible(true);
      } else {
        setProgressReportVisible(false);
      }
    },
    []
  );
  return (
    <Fragment>
      {!progressReportDetailLoading && (
        <ProgressReportDetailsDrawer
          toggleDrawer={changeProgressReportDetailsDrawer}
          visible={progressReportVisible}
          data={progressReportDetailData}
          getSummaryString={getSummaryString}
        />
      )}
      <LearnInTable
        pagination={false}
        dataSource={data && data.length ? data : []}
        columns={progressReportColumns}
      />
    </Fragment>
  );
};

export default ProgressReportList;
