import {
  ApprovalRequestVM,
  CostCenterOverridePayload,
  UpdateApprovalExpirationPayload,
  UpdateApprovalRequest,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {UpdateApprovalExpirationPayloadSchema} from '../zodSchemas/UpdateApprovalExpirationPayloadSchema.schema';
import {UpdateApprovalRequestSchema} from '../zodSchemas/UpdateApprovalRequestSchema.schema';
import {CostCenterOverridePayloadSchema} from '../zodSchemas/CostCenterOverridePayloadSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Approvals/ManagerApprovalsController.cs

/*
|--------------------------------------------------------------------------
|  update-approval 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagerApprovalsUpdateApprovalMutation} from '@generated/hooks';
 *
 *  const managerApprovalsUpdateApproval = useManagerApprovalsUpdateApprovalMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  managerApprovalsUpdateApproval.mutate({
 *    payload: {
 *      ...UpdateApprovalRequest
 *    },
 *  })
 *
 */

export const useManagerApprovalsUpdateApprovalMutation = mutationHookFactory<
  UpdateApprovalRequest, // payload
  null,
  ApprovalRequestVM // return type
>(
  'useManagerApprovalsUpdateApprovalMutation',
  'post',
  () => `manager-approvals/update-approval`,
  {payloadSchema: UpdateApprovalRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  update-cost-center 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagerApprovalsUpdateCostCenterMutation} from '@generated/hooks';
 *
 *  const managerApprovalsUpdateCostCenter = useManagerApprovalsUpdateCostCenterMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  managerApprovalsUpdateCostCenter.mutate({
 *    payload: {
 *      ...CostCenterOverridePayload
 *    },
 *  })
 *
 *  @access Admin or Manager Access Only
 *
 */

export const useManagerApprovalsUpdateCostCenterMutation = mutationHookFactory<
  CostCenterOverridePayload, // payload
  null,
  null
>(
  'useManagerApprovalsUpdateCostCenterMutation',
  'post',
  () => `manager-approvals/update-cost-center`,
  {payloadSchema: CostCenterOverridePayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-user-request-approval 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useManagerApprovalsUpdateUserRequestApprovalMutation} from '@generated/hooks';
 *
 *  const managerApprovalsUpdateUserRequestApproval = useManagerApprovalsUpdateUserRequestApprovalMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  managerApprovalsUpdateUserRequestApproval.mutate({
 *    payload: {
 *      ...UpdateApprovalRequest
 *    },
 *  })
 *
 */

export const useManagerApprovalsUpdateUserRequestApprovalMutation =
  mutationHookFactory<
    UpdateApprovalRequest, // payload
    null,
    boolean // return type
  >(
    'useManagerApprovalsUpdateUserRequestApprovalMutation',
    'post',
    () => `manager-approvals/update-user-request-approval`,
    {payloadSchema: UpdateApprovalRequestSchema}
  );

/*
|--------------------------------------------------------------------------
|  update-approval-expiration 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateApprovalExpirationMutation} from '@generated/hooks';
 *
 *  const updateApprovalExpiration = useUpdateApprovalExpirationMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateApprovalExpiration.mutate({
 *    payload: {
 *      ...UpdateApprovalExpirationPayload
 *    },
 *  })
 *
 *  @access Admin or Manager Access Only
 *
 */

export const useUpdateApprovalExpirationMutation = mutationHookFactory<
  UpdateApprovalExpirationPayload, // payload
  null,
  null
>(
  'useUpdateApprovalExpirationMutation',
  'post',
  () => `manager-approvals/update-approval-expiration`,
  {payloadSchema: UpdateApprovalExpirationPayloadSchema}
);
