import {ProgramTypeViewProps} from './ProgramType.interfaces';
import {H3} from '@components/reusable/Typography';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {Form, Space} from 'antd';
import {CheckboxGroup, StyledCheckbox} from './ProgramType.styled';
import {ProgramFinderHeadings} from '@pages/ProgramFinder/ProgramFinderHeadings';
import DOMPurify from 'dompurify';

function ProgramTypeView({
  handleAction,
  filteredOptions,
  form,
  isSingleOption,
}: ProgramTypeViewProps) {
  const skills = form.getFieldValue('skills');
  const title = skills
    ? i18n.t(k.PROGRAM_FINDER__TYPE__TITLE__FORMAT, {
        skill: DOMPurify.sanitize(form.getFieldValue('skills')),
        interpolation: {escapeValue: false},
      })
    : i18n.t(k.PROGRAM_FINDER__TITLE__PROGRAMS);

  return (
    <Space style={{width: '100%'}} direction="vertical" size="large">
      <ProgramFinderHeadings
        title={!isSingleOption && title}
        subtitle={i18n.t(
          isSingleOption
            ? k.PROGRAM_FINDER__TYPE__TITLE__ALT__FORMAT
            : k.PROGRAM_FINDER__TYPE__SUBTITLE,
          {
            skill: DOMPurify.sanitize(form.getFieldValue('skills')),
            interpolation: {escapeValue: false},
          }
        )}
      />
      <Form.Item name="programType">
        <CheckboxGroup
          onChange={handleAction}
          value={isSingleOption ? [filteredOptions[0].value] : []}>
          {filteredOptions.map(({label, value, icon}) => (
            <StyledCheckbox
              disabled={isSingleOption}
              key={value}
              value={value}
              className={isSingleOption ? 'hide-checkbox' : ''}>
              <H3 color={COLORS.Neutral600}>{label}</H3>
              {icon}
            </StyledCheckbox>
          ))}
        </CheckboxGroup>
      </Form.Item>
    </Space>
  );
}

export default ProgramTypeView;
