import {LxpContentType} from './../enums';
import {LxpContentIdAndTypePair} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: LxpContentIdAndTypePair

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLxpContentIdAndTypePairMockData(
  args?: Partial<LxpContentIdAndTypePair>
): LxpContentIdAndTypePair {
  return {
    lxpContentId: faker.datatype.number(),
    lxpContentType: LxpContentType.Unknown,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LxpContentIdAndTypePairMockData: LxpContentIdAndTypePair = {
  lxpContentId: 1,
  lxpContentType: LxpContentType.Unknown,
};
