import {AcademyUserStatus} from './../enums';
import {AcademyUserResult} from './../interfaces';
import {
  IAcademyUserBasicInfoMockData,
  getIAcademyUserBasicInfoMockData,
} from './IAcademyUserBasicInfo.mock';
import {UserMockData, getUserMockData} from './User.mock';
import {UserCompanyMockData, getUserCompanyMockData} from './UserCompany.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyUserResult

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyUserResultMockData(
  args?: Partial<AcademyUserResult>
): AcademyUserResult {
  return {
    ...getIAcademyUserBasicInfoMockData(),
    academyId: faker.datatype.number(),
    createdOn: String(faker.date.past()),
    currentLevelId: faker.datatype.number(), // optional
    email: String(faker.internet.email()), // optional
    lastNudgeSent: faker.word.noun(), // optional
    status: AcademyUserStatus.InProgress,
    user: getUserMockData(),
    userCompany: getUserCompanyMockData(),
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyUserResultMockData: AcademyUserResult = {
  ...IAcademyUserBasicInfoMockData,
  academyId: 1,
  createdOn: '2023-03-29T22:17:19.738072',
  currentLevelId: 1, // optional
  email: 'bjohnson@gmail.com', // optional
  lastNudgeSent: 'string', // optional
  status: AcademyUserStatus.InProgress,
  user: UserMockData,
  userCompany: UserCompanyMockData,
  userCompanyId: 1,
};
