import {
  PreApprovalQuestionFunctionalityType,
  PreApprovalQuestionInputType,
  PreApprovalQuestionResponseSource,
} from './../enums';
import {PreApprovalQuestion} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: PreApprovalQuestion

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPreApprovalQuestionMockData(
  args?: Partial<PreApprovalQuestion>
): PreApprovalQuestion {
  return {
    functionalityType: PreApprovalQuestionFunctionalityType.NotFeatureQuestion,
    id: faker.datatype.number(),
    inputType: PreApprovalQuestionInputType.Text,
    isConditionalOnAnswerId: faker.datatype.number(), // optional
    localizationId: String(faker.datatype.number()),
    required: faker.datatype.boolean(),
    response: faker.word.noun(),
    responseOptions: [faker.word.noun()],
    responseType: PreApprovalQuestionResponseSource.Text,
    value: faker.word.noun(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PreApprovalQuestionMockData: PreApprovalQuestion = {
  functionalityType: PreApprovalQuestionFunctionalityType.NotFeatureQuestion,
  id: 1,
  inputType: PreApprovalQuestionInputType.Text,
  isConditionalOnAnswerId: 1, // optional
  localizationId: '1',
  required: true,
  response: 'string',
  responseOptions: ['string'],
  responseType: PreApprovalQuestionResponseSource.Text,
  value: 'string',
};
