import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import ProgramCurriculumCard from './ProgramCurriculumCard';
import UpsertCustomContentModal from '@components/admin/pages/programs/customPrograms/curriculum/UpsertCustomContentModal.container';
import MoveContentToNewSectionModal from '@components/admin/pages/programs/customPrograms/curriculum/MoveContentToNewSectionModal.container';
import {useMutation} from 'react-query';
import {simpleDeleteFn, simpleMutationFn} from '@store/queryClient';
import {
  getDeleteCustomProgramContentRm,
  getUpdateCustomProgramContentOrderRm,
} from '@store/apiEndpoints/customProgram/mutations';
import {notify} from '@components/user/notifications';
import {CustomProgramAdminSectionVM} from '@models/serverModels';
import {ContentType, SubmissionType} from '@generated/enums';
import {useDuplicateCustomProgramContentMutation} from '@generated/hooks';
import {getTimeZoneAbbreviation, getUserTimeZone} from '@utils/timeUtils';
import learnInConfirm from '@components/reusable/learnInConfirm';
import {useCustomProgramAdminSectionVMsQuery} from '@hooks/apiEndpoints/customProgram/queries';
import {useGetTimezonesQuery} from '@hooks/apiEndpoints/timeIncentive/queries';
import {getTitle} from '@utils/enumMapping/MapTextToUserLanguage';
import {useGetLabelsByContentType} from '@hooks/useGetLabelsByContentType';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface ProgramCurriculumCardContainerProps {
  cardCount: number;
  isDraggingContentOrder: number | null;
  id: number;
  idx: number;
  order: number;
  title: string;
  description: string;
  contentType: ContentType;
  customProgramId: number;
  setContentReorderIdx: () => void;
  setIsDraggingContentOrder: () => void;
  isSubmissionTypeEditable?: boolean;
  contentReorderIdx: number | null;
  sections: Pick<CustomProgramAdminSectionVM, 'id' | 'title'>[];
  dueDateUtc?: string;
  dueDateTimeZone?: string;
  submissionType?: SubmissionType;
  eventLink?: string;
  eventStartDateTimeUtc?: string;
  eventEndDateTimeUtc?: string;
  eventTimeZone?: string;
  attachmentGuids?: string[];
  allowPeerVisibility?: boolean;
}

function ProgramCurriculumCardContainer({
  idx,
  id,
  order,
  title,
  contentType,
  cardCount,
  isDraggingContentOrder,
  description,
  customProgramId,
  setContentReorderIdx,
  setIsDraggingContentOrder,
  isSubmissionTypeEditable,
  contentReorderIdx,
  sections,
  dueDateUtc,
  dueDateTimeZone,
  submissionType,
  eventLink,
  eventStartDateTimeUtc,
  eventEndDateTimeUtc,
  eventTimeZone,
  attachmentGuids,
  allowPeerVisibility,
}: ProgramCurriculumCardContainerProps) {
  const [showUpsertModal, setShowUpsertModal] = useState(false);
  const [showMoveContentModal, setShowMoveContentModal] = useState(false);

  const timeZoneQuery = useGetTimezonesQuery(null);
  const longTimeZone = timeZoneQuery?.data?.find(
    (i) => i.id === getUserTimeZone()
  )?.displayName;
  const timeZoneAbbreviation =
    longTimeZone && getTimeZoneAbbreviation(longTimeZone);

  const customProgramSectionsQuery = useCustomProgramAdminSectionVMsQuery(
    customProgramId,
    {enabled: !!customProgramId}
  );

  const deleteCustomProgramContentMutation = useMutation(
    () => {
      const deleteCustomProgramContentRm = getDeleteCustomProgramContentRm(id);
      return simpleDeleteFn<null>(
        deleteCustomProgramContentRm.path,
        deleteCustomProgramContentRm.payload
      );
    },
    {
      onSuccess: async () => {
        await customProgramSectionsQuery.invalidateExact();
      },
      onError: () => {
        notify.deleteCustomProgramContentError();
      },
    }
  );

  const duplicateCustomProgramContent =
    useDuplicateCustomProgramContentMutation({
      onSuccess: async () => {
        await customProgramSectionsQuery.invalidateExact();
      },
      onError: () => {
        notify.duplicateCustomProgramContentError();
      },
    });

  const updateContentOrderMutation = useMutation(
    (newOrder: number) => {
      const updateCustomProgramContentOrderRm =
        getUpdateCustomProgramContentOrderRm({id, newOrder});
      return simpleMutationFn<null>(
        updateCustomProgramContentOrderRm.path,
        updateCustomProgramContentOrderRm.payload
      );
    },
    {
      onSuccess: async () => {
        await customProgramSectionsQuery.invalidateExact();
      },
      onError: () => {
        notify.reorderCustomProgramContentError();
      },
    }
  );

  const {deletePrompt} = useGetLabelsByContentType(contentType);

  const handleClickDelete = () => {
    let contentTypeString;
    switch (contentType) {
      case ContentType.Text:
        contentTypeString = i18n.t(k.CONTENT);
        break;
      case ContentType.Project:
        contentTypeString = i18n.t(k.PROJECT);
        break;
      case ContentType.Event:
        contentTypeString = i18n.t(k.EVENT);
        break;
      default:
        contentTypeString = '';
        break;
    }

    learnInConfirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__DELETE_ITEM_YES__FORMAT, {
        item: contentTypeString,
      }),
      content: deletePrompt,
      title: i18n.t(k.CTA__DELETE, {item: contentTypeString}),
      async onOk() {
        try {
          await deleteCustomProgramContentMutation.mutateAsync();
          notify.deleteCustomProgramContentSuccess(contentTypeString);
        } catch (error) {
          notify.deleteCustomProgramContentError(contentTypeString);
        }
      },
      onCancel() {
        // intentional pass
      },
    });
  };

  return (
    <>
      <ProgramCurriculumCard
        eventStartDateTimeUtc={eventStartDateTimeUtc}
        timeZoneAbbreviation={timeZoneAbbreviation}
        key={`${id}-pcc`}
        cardCount={cardCount}
        idx={idx}
        contentType={contentType ?? ContentType.Text}
        id={id}
        order={order}
        title={getTitle(title)}
        isDraggingContentOrder={isDraggingContentOrder}
        onClick={() => setShowUpsertModal(true)}
        onClickDelete={handleClickDelete}
        onClickDuplicate={async (e) => {
          e.stopPropagation();
          duplicateCustomProgramContent.mutate({
            pathVars: {
              contentId: id,
            },
          });
        }}
        onClickEdit={() => setShowUpsertModal(true)}
        onClickMoveToBottom={async () =>
          await updateContentOrderMutation.mutateAsync(cardCount)
        }
        onClickMoveToTop={async () =>
          await updateContentOrderMutation.mutateAsync(1)
        }
        reorderContentItem={async () => {
          if (contentReorderIdx !== order)
            await updateContentOrderMutation.mutateAsync(contentReorderIdx);
        }}
        setContentReorderIdx={setContentReorderIdx}
        setIsDraggingContentOrder={setIsDraggingContentOrder}
        onClickMoveToSection={() => setShowMoveContentModal(true)}
        isContentReorderLoading={updateContentOrderMutation.isLoading}
        dueDateUtc={dueDateUtc}
      />
      {showUpsertModal && (
        <UpsertCustomContentModal
          allowPeerVisibility={allowPeerVisibility}
          key={id}
          customProgramId={customProgramId}
          contentType={contentType}
          visible={showUpsertModal}
          onCancel={() => setShowUpsertModal(false)}
          id={id}
          isSubmissionTypeEditable={isSubmissionTypeEditable}
          description={description}
          title={getTitle(title)}
          dueDateUtc={dueDateUtc}
          dueDateTimeZone={dueDateTimeZone}
          submissionType={submissionType}
          eventLink={eventLink}
          eventStartDateTimeUtc={eventStartDateTimeUtc}
          eventEndDateTimeUtc={eventEndDateTimeUtc}
          eventTimeZone={eventTimeZone}
        />
      )}
      <MoveContentToNewSectionModal
        visible={showMoveContentModal}
        customProgramId={customProgramId}
        onCancel={() => setShowMoveContentModal(false)}
        sections={sections}
        contentId={id}
      />
    </>
  );
}

export default ProgramCurriculumCardContainer;
