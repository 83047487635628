import {AcademyUserStatus} from './../enums';
import {AcademyWithMembershipStatusVM} from './../interfaces';
import {AcademyVMMockData, getAcademyVMMockData} from './AcademyVM.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyWithMembershipStatusVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyWithMembershipStatusVMMockData(
  args?: Partial<AcademyWithMembershipStatusVM>
): AcademyWithMembershipStatusVM {
  return {
    ...getAcademyVMMockData(),
    academyUserStatus: AcademyUserStatus.InProgress, // optional
    hasCompleted: faker.datatype.boolean(),
    isInProgress: faker.datatype.boolean(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyWithMembershipStatusVMMockData: AcademyWithMembershipStatusVM =
  {
    ...AcademyVMMockData,
    academyUserStatus: AcademyUserStatus.InProgress, // optional
    hasCompleted: true,
    isInProgress: true,
  };
