import {InitiativePurpose} from './../enums';
import {InitiativePurposeDescriptionVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: InitiativePurposeDescriptionVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getInitiativePurposeDescriptionVMMockData(
  args?: Partial<InitiativePurposeDescriptionVM>
): InitiativePurposeDescriptionVM {
  return {
    programId: faker.datatype.number(),
    purpose: InitiativePurpose.LearnCriticalSkill,
    purposeDescriptions: [faker.lorem.lines()],
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const InitiativePurposeDescriptionVMMockData: InitiativePurposeDescriptionVM =
  {
    programId: 1,
    purpose: InitiativePurpose.LearnCriticalSkill,
    purposeDescriptions: [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ],
  };
