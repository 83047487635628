import CustomProgramAdminDrawerSideNav, {
  CustomProgramDrawerSideNavProps,
} from './CustomProgramAdminDrawerSideNav';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function CustomProgramAdminDrawerSideNavContainer(
  props: Omit<CustomProgramDrawerSideNavProps, 'showPermissionsTab'>
) {
  return <CustomProgramAdminDrawerSideNav {...props} />;
}

export default CustomProgramAdminDrawerSideNavContainer;
