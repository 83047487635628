// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CustomProgramContentStatus.cs

enum CustomProgramContentStatus {
  Planned,
  Completed,
  RsvpYes,
  RsvpNo,
}

export default CustomProgramContentStatus;
