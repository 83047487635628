import {useLayoutEffect, useRef} from 'react';
import styled from 'styled-components';
import {pxToRem} from '@utils/styleMixins';

import {i18n, k} from '@i18n/translate';
import {Form, Modal, Spin, Checkbox, FormInstance} from 'antd';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {ContributorPermissionType} from '@models/clientEnums';
import {CustomizableLearningCategory} from '@generated/enums';
import {UserSearchResultVM} from '@generated/interfaces';
import '@fontsource/roboto/500.css';

import LearnInSelect, {
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {FormItemProps} from 'antd/es/form/FormItem';
import {setModalAriaLabel} from '@components/reusable/Modal/utils/setModalAriaLabel';

export enum ContributorPermissionFields {
  userCompanyIds = 'userCompanyIds',
  permissionGroup = 'permissionGroup',
  canReviewProject = 'canReviewProject',
  canViewReport = 'canViewReport',
  canEdit = 'canEdit',
}

export interface AddContributorsModalFormInstance {
  userCompanyIds: number[] | string[];
  permissionGroup: ContributorPermissionType[];
}
export interface ContributorPermission {
  userCompanyIds: number[];
  canReviewProject: boolean;
  canViewReport: boolean;
  canEdit: boolean;
}

const StyledModal = styled(Modal)`
  & .ant-modal-content {
    margin: 0 auto;
    padding: 0px;
    width: 400px;
    box-shadow: ${COLORS.BoxShadowStandard};
    border-radius: 8px;
  }

  & .ant-modal-body {
    padding-top: 0px;
  }

  & .anticon.anticon-close {
    color: ${COLORS.Neutral950};
  }

  & .ant-modal-header {
    border-radius: 8px;
    border: none;
    padding: 16px 24px;
  }
  & .ant-modal-footer {
    border-top: 1px solid ${COLORS.Neutral200};
    padding: 16px 24px;
  }
  &
    .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    content: '';
  }
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
`;

const Label = styled.p`
  font-size: 1rem;
  line-height: 1.171875rem;
  margin: 0;
  color: ${COLORS.Neutral900};
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  > div {
    display: flex;
  }
`;

const FooterButton = styled(LearnInButton)`
  font-family: Roboto;
  width: fit-content;
  height: 42px;
`;

const CheckBoxContainer = styled.div`
  background: ${COLORS.Neutral50};
  padding: 16px;
`;

const FormItem = styled(Form.Item)<FormItemProps>`
  margin-bottom: 0;
` as typeof Form.Item;

const Name = styled.div`
  font-weight: 500;
  font-size: ${pxToRem(12, 16)};
  color: ${COLORS.Neutral950};
  margin-bottom: -4px;
`;

const Email = styled.div`
  font-weight: 400;
  font-size: ${pxToRem(14, 16)};
  color: ${COLORS.Neutral700};
`;

export type AddContributorsModalProps = {
  disableSubmitButton: boolean;
  form: FormInstance<AddContributorsModalFormInstance>;
  isSearchLoading?: boolean;
  onClose: () => void;
  onFieldsChange: (changedFields: any, allFields: any) => void;
  onSearchUser?: (value?: string) => Promise<void> | void;
  onSubmit: () => Promise<void> | void;
  userList: UserSearchResultVM[] | undefined;
  visible: boolean;
  learningCategory: CustomizableLearningCategory;
};

const AddContributorsModal = ({
  disableSubmitButton,
  form,
  isSearchLoading,
  onClose,
  onFieldsChange,
  onSearchUser,
  onSubmit,
  userList,
  visible,
  learningCategory,
}: AddContributorsModalProps) => {
  const useSearchInputRef = useRef(null);
  useLayoutEffect(() => {
    if (visible) {
      setModalAriaLabel(i18n.t(k.CONTRIBUTOR__ADD));
      useSearchInputRef?.current?.focus();
    }
  }, [visible]);
  return (
    <StyledModal
      onCancel={onClose}
      title={<Title>{i18n.t(k.CONTRIBUTOR__ADD)}</Title>}
      open={visible}
      footer={[
        <Footer key="modal-footer">
          <div>
            <FooterButton
              onClick={onClose}
              key={1}
              tag={ButtonTags.Secondary}
              style={{marginRight: '5px'}}>
              {i18n.t(k.CTA__CANCEL)}
            </FooterButton>
            <FooterButton
              disabled={disableSubmitButton}
              onClick={onSubmit}
              key={2}
              tag={ButtonTags.Primary}
              style={{minWidth: '139px'}}>
              {i18n.t(k.CTA__SUBMIT)}
            </FooterButton>
          </div>
        </Footer>,
      ]}>
      <Form layout="vertical" form={form} onFieldsChange={onFieldsChange}>
        <FormItem
          name={ContributorPermissionFields.userCompanyIds}
          rules={[
            {
              required: true,
              message: i18n.t(k.VALIDATION__SELECT_AT_LEAST__FORMAT, {
                item: i18n.t(k.USER),
              }),
            },
          ]}
          label={<Label>{i18n.t(k.CONTRIBUTOR__ADD_WHO)}</Label>}>
          <LearnInSelect
            ref={useSearchInputRef}
            onChange={() => useSearchInputRef.current?.blur()}
            mode="multiple"
            style={{marginRight: '12px', width: '100%'}}
            maxTagCount={2}
            allowClear
            placeholder={i18n.t(k.A11Y__TYPE_TO_SELECT)}
            notFoundContent={isSearchLoading ? <Spin size="small" /> : null}
            filterOption={false}
            onSearch={onSearchUser}>
            {userList?.map(({id, name, email}) => {
              return (
                <LearnInSelectOption value={`${name},${email},${id}`} key={id}>
                  <Name>{name}</Name>
                  <Email>{email}</Email>
                </LearnInSelectOption>
              );
            })}
          </LearnInSelect>
        </FormItem>
        <Label
          for={ContributorPermissionFields.userCompanyIds}
          style={{margin: '16px 0px 8px'}}>
          {i18n.t(k.PERMISSION__SELECT)}
        </Label>
        <CheckBoxContainer>
          <FormItem
            name={ContributorPermissionFields.permissionGroup}
            rules={[
              {
                required: true,
                message: i18n.t(k.VALIDATION__SELECT_AT_LEAST__FORMAT, {
                  item: i18n.t(k.PERMISSION),
                }),
              },
            ]}>
            <Checkbox.Group
              aria-label={i18n.t(k.VALIDATION__SELECT_AT_LEAST__FORMAT, {
                item: i18n.t(k.PERMISSION),
              })}>
              <div>
                <Checkbox value={ContributorPermissionFields.canReviewProject}>
                  {i18n.t(k.PROJECT__REVIEW__PLURAL)}
                </Checkbox>
              </div>
              <div>
                <Checkbox value={ContributorPermissionFields.canViewReport}>
                  {i18n.t(k.REPORT__PLURAL__VIEW)}
                </Checkbox>
              </div>
              <div>
                <Checkbox value={ContributorPermissionFields.canEdit}>
                  {i18n.t(
                    learningCategory === CustomizableLearningCategory.Academy
                      ? k.ACADEMY__EDIT
                      : learningCategory ===
                        CustomizableLearningCategory.CustomProgram
                      ? k.PROGRAM__EDIT
                      : k.CTA__EDIT
                  )}
                </Checkbox>
              </div>
            </Checkbox.Group>
          </FormItem>
        </CheckBoxContainer>
      </Form>
    </StyledModal>
  );
};

export default AddContributorsModal;
