import {User} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {faker} from '@faker-js/faker';

// Interface: User

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserMockData(args?: Partial<User>): User {
  return {
    ...getBaseEntityMockData(),
    email: String(faker.internet.email()), // optional
    externalId: String(faker.datatype.number()),
    id: String(faker.datatype.number()),
    name: faker.name.fullName(),
    position: faker.word.noun(),
    salaryRange: faker.word.noun(),
    sourceDb: faker.word.noun(),
    timezone: faker.address.timeZone(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserMockData: User = {
  ...BaseEntityMockData,
  email: 'bjohnson@gmail.com', // optional
  externalId: '1',
  id: '1',
  name: 'Bobby Johnson',
  position: 'string',
  salaryRange: 'string',
  sourceDb: 'string',
  timezone: 'Asia/Dubai',
};
