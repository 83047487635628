import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {
  CUSTOM_CONTENT_DESCRIPTION_MAX_LENGTH,
  SECTIONS_NAV_WIDTH,
  SIDE_NAV_WIDTH,
} from '@utils/constants';
import {AcademyStepContentModalPayload} from '@models/clientModels';
import {
  AcademyStepAttachment,
  AcademyStepContentPayload,
  AcademyStepContentUpdatePayload,
  AttachmentVM,
  TimeZoneVM,
} from '@models/serverModels';
import {
  cacheForeverOptions,
  simpleDeleteFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {getTimezonesRq} from '@store/apiEndpoints';
import {
  getAddAcademyContentStep,
  getDeleteAcademyStepContentAttachmentRm,
  getDeleteAcademyStepRm,
  getUpdateAcademyContentStepRm,
  getUpdateCustomAcademyAttachmentRm,
} from '@store/apiEndpoints/academy/mutations';
import {getCustomAcademyContentAttachmentsRq} from '@store/apiEndpoints/academy/queries';
import {useMutation, useQuery} from 'react-query';
import LevelsScreenHeaderContainer from './LevelsScreenHeader.container';
import LevelNoContentScreenContainer from './NoContent/LevelNoContentScreen.container';
import SideNavContainer from './LevelsSideNav/SideNav.container';
import CustomContentModal from './CustomContent/CustomContentModal.container';
import useChunkUpload from '@hooks/useChunkUpload';
import {
  ContentType,
  CustomizableLearningCategory,
  AcademyStepType,
  AcademyStepRequirementProperty,
} from '@generated/enums';
import {notify} from '@components/user/notifications';
import {AddDiscussionStepModal} from '@components/reusable/AddDiscussionStepModal/AddDiscussionStepModal';
import {StepRequirementFields} from '@components/reusable/StepRequirementFields';
import {Form, Modal} from 'antd';
import {Maybe} from '@utils/typeUtils';
import {
  useAcademyLevelVMsQuery,
  useAcademyStepVMsQuery,
} from '@hooks/apiEndpoints/academy/queries';
import {useUpdateAcademyStepRequirement} from '@hooks/apiEndpoints/academy/mutations';
import {basicSorter} from '@utils/tableUtils';
import {AttachmentLinkType} from '@generated/enums';
import {
  UpdateAcademyStepRequirementPayload,
  AcademyStepVM,
} from '@generated/interfaces';
import {useUserQuery} from '@generated/hooks';
import {mapContentTypeToAcademyStepType} from '@utils/enumMapping/MapContentTypeToAcademyStepType';
import {useNavigate} from 'react-router-dom';
import LoadingSpinner from '@blocks/LoadingSpinner';
import AddEditContent from '@blocks/AddEditContentModal/AddEditContent';
import {
  getTitle,
  mapTextToEnglish,
} from '@utils/enumMapping/MapTextToUserLanguage';
import AddCatalogContentDrawer from '../AddCatalogContentDrawer/AddCatalogContentDrawer.container';
import {Helmet} from 'react-helmet-async';

const {confirm} = Modal;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  background: rgb(249, 249, 249);
  padding: 16px 16px 72px ${SIDE_NAV_WIDTH + SECTIONS_NAV_WIDTH + 16}px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  max-width: 90%;
`;

const HeaderAndCardContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto 32px;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export type setShowContentUpsertModal = (
  show: boolean,
  id?: number,
  contentType?: ContentType
) => void;

export interface LevelsScreenProps {
  isNewLevel: boolean;
  getScreenNavItemUrlPath: (levelId: number, _new?: boolean) => string;
  title?: string;

  /* Causes the component to be remounted.
   * Currently used to avoid form data conflicts between steps
   * TODO: REMOVE! https://linear.app/learnin/issue/ACA-3612/cleanup-workarounds-and-temporary-fixes */
  onForceRemount?: () => void;
}

function LevelsScreen({
  isNewLevel,
  getScreenNavItemUrlPath,
  title,
  onForceRemount,
}: LevelsScreenProps) {
  const navigate = useNavigate();
  const {academyId, levelId} = useParams();
  const [form] = Form.useForm<{
    requiredId: number;
    requirementProperty: AcademyStepRequirementProperty;
  }>();
  const requiredId = Form.useWatch('requiredId', form);

  const {data: user} = useUserQuery(null);

  const {data: levels, invalidateExact: invalidateLevels} =
    useAcademyLevelVMsQuery({
      academyId: parseInt(academyId),
      excludeUserProgress: true,
    });

  const handleRenderWithNoLevelId = () => {
    if (!levelId && levels?.length) {
      navigate(getScreenNavItemUrlPath(levels?.[0]?.id), {replace: true});
    }
  };

  React.useEffect(handleRenderWithNoLevelId, [
    levels,
    levelId,
    getScreenNavItemUrlPath,
    navigate,
  ]);

  const closeContentUpsertModal = () => {
    setShowContentUpsertModal([false, undefined, undefined]);
    form.setFieldsValue({requiredId: null});
    setShowStepRequirementOptions(false);
    onForceRemount?.();
  };

  const {chunkUpload, uploadProgress, cancelUpload} = useChunkUpload({
    query: getUpdateCustomAcademyAttachmentRm,
    customizableLearningCategory: CustomizableLearningCategory.Academy,
    onComplete: () => {
      contentAttachmentsQuery.refetch();
      invalidateSteps();
      setShowDiscussionModal(false);
      closeContentUpsertModal();
    },
  });

  const [hasRenamedLevel, setHasRenamedLevel] = React.useState<boolean>(false);
  const [previousStepId, setPreviousStepId] = React.useState<number | null>(
    null
  );
  const [showDiscussionModal, setShowDiscussionModal] = React.useState(false);
  const [previousStepIsRequired, setPreviousStepIsRequired] =
    React.useState<boolean>(false);
  const [selectedStep, setSelectedStep] = React.useState<AcademyStepVM>();
  const [showCustomContentModal, setShowCustomContentModal] =
    React.useState(false);
  const [showEventModal, setShowContentUpsertModal] = React.useState<
    [boolean, Maybe<number>, Maybe<ContentType>]
  >([false, undefined, undefined]);
  const [showStepRequirementOptions, setShowStepRequirementOptions] =
    React.useState(false);
  const [showCatalogContentDrawer, setShowCatalogContentDrawer] =
    React.useState(false);

  const {data: steps, invalidateExact: invalidateSteps} =
    useAcademyStepVMsQuery(parseInt(levelId), {
      enabled: !!levelId,
    });

  const updateAcademyStepRequirement = useUpdateAcademyStepRequirement({
    onSuccess: () => {
      invalidateSteps();
      invalidateLevels();
    },
  });

  React.useEffect(() => {
    if (
      (showEventModal[1] !== undefined &&
        selectedStep?.id !== showEventModal[1]) ||
      showDiscussionModal
    ) {
      // update form fields ui
      const selectedStep = steps.find((step) => step.id === showEventModal[1]);

      const stepIds = steps.map((s) => s.id);
      const selectedStepIndex = stepIds.indexOf(selectedStep?.id || NaN);
      const previousStepId = stepIds[selectedStepIndex - 1];
      if (selectedStep?.title) {
        selectedStep.title = getTitle(selectedStep.title);
      }
      setPreviousStepId(previousStepId || steps[steps.length - 1]?.id);
      if (previousStepId === selectedStep?.requiredId) {
        setPreviousStepIsRequired(true);
      } else {
        setPreviousStepIsRequired(false);
      }
      setSelectedStep(selectedStep);
      if (!!selectedStep?.requiredId) {
        form.setFieldsValue({
          requiredId: selectedStep?.requiredId,
          requirementProperty: selectedStep?.requirementProperty,
        });
        setShowStepRequirementOptions(true);
      }
    }
  }, [selectedStep?.id, showEventModal, showDiscussionModal, form, steps]);

  // Attachments
  const customStepContentAttachmentsRq = getCustomAcademyContentAttachmentsRq(
    selectedStep?.id
  );

  const timezonesRq = getTimezonesRq();
  const timeZoneQuery = useQuery<TimeZoneVM[]>(
    timezonesRq.queryKey,
    () => simpleQueryFn(timezonesRq.path),
    cacheForeverOptions
  );
  const contentAttachmentsQuery = useQuery<AcademyStepAttachment[]>(
    customStepContentAttachmentsRq.queryKey,
    () => simpleQueryFn(customStepContentAttachmentsRq.path),
    {
      placeholderData: () => [],
      enabled: !!selectedStep?.id,
    }
  );

  const deleteContentAttachmentMutation = useMutation(
    (attachmentId: number) => {
      const deleteAcademyStepContentAttachmentRm =
        getDeleteAcademyStepContentAttachmentRm(attachmentId);
      return simpleDeleteFn<null>(
        deleteAcademyStepContentAttachmentRm.path,
        deleteAcademyStepContentAttachmentRm.payload
      );
    },
    {
      onSuccess: () => {
        contentAttachmentsQuery.refetch();
        invalidateSteps();
        invalidateLevels();
        notify.deleteCustomProgramContentAttachmentSuccess();
      },
      onError: () => {
        notify.deleteCustomProgramContentAttachmentError();
      },
    }
  );
  const handleDeleteAttachment = () => {
    const attachmentId = contentAttachmentsQuery.data?.[0]?.id;
    return deleteContentAttachmentMutation.mutateAsync(attachmentId);
  };

  // Create or edit events
  const updateAcademyContentStep = useMutation(
    (args: AcademyStepContentUpdatePayload) => {
      const updateCustomContentRm = getUpdateAcademyContentStepRm(args);
      return simpleMutationFn<number>(
        updateCustomContentRm.path,
        updateCustomContentRm.payload
      );
    },
    {
      onSuccess: () => {
        invalidateSteps();
      },
      onError: () => {
        notify.updateStepError();
      },
    }
  );
  const addCustomContentMutation = useMutation(
    (args: AcademyStepContentPayload) => {
      const addCustomContentRm = getAddAcademyContentStep(args);
      return simpleMutationFn<number>(
        addCustomContentRm.path,
        addCustomContentRm.payload
      );
    },
    {
      onSuccess: () => {
        invalidateSteps();
      },
      onError: () => {
        notify.addStepError();
      },
    }
  );

  const deleteAcademyStepMutation = useMutation(
    () => {
      const deleteAcademyStepRm = getDeleteAcademyStepRm(selectedStep.id);
      return simpleDeleteFn<null>(
        deleteAcademyStepRm.path,
        deleteAcademyStepRm.payload
      );
    },
    {
      onSuccess: async () => {
        invalidateSteps();
        invalidateLevels();
        closeContentUpsertModal();
      },
      onError: () => {
        notify.deleteAcademyStepError();
      },
    }
  );

  const handleDelete = () => {
    confirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__DELETE_ITEM_YES__FORMAT, {
        item: i18n.t(k.EVENT),
      }),
      okType: 'danger',
      title: i18n.t(k.EVENT__DELETE),
      async onOk() {
        await deleteAcademyStepMutation.mutateAsync();
        setSelectedStep(undefined);
      },
    });
  };

  const stepRequirementOptions = steps
    ?.filter(
      (step) => !selectedStep || step?.order < (selectedStep?.order || Infinity)
    )
    .sort(basicSorter('order'))
    .map(
      ({
        id,
        title,
        type,
      }: {
        id: number;
        title: string;
        type: AcademyStepType;
      }) => ({id, title, type})
    );

  let attachmentData: AttachmentVM = null;
  if (!!selectedStep && !!contentAttachmentsQuery.data?.length) {
    // Use AttachmentVM
    attachmentData = contentAttachmentsQuery.data?.[0];
  }

  function handleAddRequirement() {
    const previousStepId =
      stepRequirementOptions[stepRequirementOptions.length - 1]?.id;
    setPreviousStepIsRequired(true);
    setSelectedStep({
      ...selectedStep,
      requiredId: previousStepId,
    });
    form.setFieldValue('requiredId', previousStepId);
    setShowStepRequirementOptions(true);
  }

  async function handleOk({
    title,
    description,
    submissionType,
    eventLink,
    startDate,
    startTime,
    endTime,
    endDate,
    eventTimeZone,
    link,
    allowPeerVisibility,
    dueDateTimeZone,
    dueTime,
    dueDate,
    allowSkip,
    file,
  }: AcademyStepContentModalPayload) {
    await form.validateFields();
    let stepId: number;
    if (description.length > CUSTOM_CONTENT_DESCRIPTION_MAX_LENGTH) {
      return notify.addCustomProgramContentLengthError();
    }

    // Determine if edit or create update
    const isEditUpdate = selectedStep?.id;

    if (isEditUpdate) {
      stepId = selectedStep.id;
      await updateAcademyContentStep.mutateAsync({
        id: stepId,
        title: mapTextToEnglish(title),
        description,
        links: link ? [link] : undefined,
        eventLink,
        startDate,
        endDate: endTime && endDate,
        startTime,
        endTime,
        eventTimeZone: !!startDate || !!endDate ? eventTimeZone : undefined,
        submissionType,
        allowPeerVisibility,
        dueDateTimeZone,
        dueDate,
        dueTime,
        allowSkip,
      });
    } else {
      const type = showEventModal[2]
        ? mapContentTypeToAcademyStepType(showEventModal[2])
        : showDiscussionModal
          ? AcademyStepType.Discussion
          : AcademyStepType.TextContent;
      stepId = await addCustomContentMutation.mutateAsync({
        academyLevelId: parseInt(levelId),
        type,
        title,
        description,
        links: link ? [link] : undefined,
        eventLink,
        startDate,
        endDate: endTime && endDate,
        startTime,
        endTime,
        eventTimeZone: !!startDate || !!endDate ? eventTimeZone : undefined,
        submissionType,
        allowPeerVisibility,
        dueDateTimeZone,
        dueDate,
        dueTime,
        allowSkip,
      });
    }

    // update requirements
    const {requiredId, requirementProperty} = form.getFieldsValue();
    let id = requiredId;
    if (previousStepIsRequired) {
      id = previousStepId || steps[steps.length - 1]?.id;
    }
    if (showStepRequirementOptions === false) {
      id = null;
    }
    updateAcademyStepRequirement.mutate({
      payload: {
        blockedId: stepId,
        requiredId: id,
        requirementProperty,
      } as UpdateAcademyStepRequirementPayload,
    });
    if (!!file) {
      await chunkUpload(file, {
        contentId: stepId,
        linkType: AttachmentLinkType.AcademyStepAttachment,
      });
    }
    if (isEditUpdate) {
      notify.updateStepSuccess();
    } else {
      notify.addStepSuccess();
    }
    setShowDiscussionModal(false);
    closeContentUpsertModal();
  }

  if (!levelId) {
    return (
      <Container style={{padding: '25% 60px'}}>
        <LoadingSpinner />
      </Container>
    );
  }

  /**
   * This expression ensures the initial values from `selectedStep` object
   * are present when the modal mounts
   */
  const shouldShowUpsertModal =
    showEventModal[0] &&
    ((showEventModal[1] && selectedStep) || !showEventModal[1]);

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SideNavContainer
        setHasRenamedLevel={setHasRenamedLevel}
        academyId={parseInt(academyId)}
        selectedLevelId={parseInt(levelId)}
        getScreenNavItemUrlPath={getScreenNavItemUrlPath}
      />
      <HeaderAndCardContainer>
        <LevelsScreenHeaderContainer
          getScreenNavItemUrlPath={getScreenNavItemUrlPath}
          hasRenamedLevel={hasRenamedLevel}
          setShowCustomContentModal={setShowCustomContentModal}
          setShowDiscussionModal={setShowDiscussionModal}
          setShowContentUpsertModal={(show, id, type) =>
            setShowContentUpsertModal([show, id, type])
          }
          selectedLevelId={parseInt(levelId)}
          academyId={parseInt(academyId)}
          isNewLevel={isNewLevel}
        />
      </HeaderAndCardContainer>
      {showCustomContentModal && (
        <CustomContentModal
          levelId={parseInt(levelId)}
          onCancel={() => setShowCustomContentModal(false)}
          visible={true}
          academyId={parseInt(academyId)}
        />
      )}
      {shouldShowUpsertModal && (
        <AddEditContent
          uploadProgress={uploadProgress}
          step={selectedStep}
          contentType={showEventModal[2]}
          onClickDelete={handleDelete}
          onDeleteAttachment={handleDeleteAttachment}
          isLoading={
            updateAcademyContentStep.isLoading ||
            addCustomContentMutation.isLoading
          }
          onCancel={() => {
            closeContentUpsertModal();
            setSelectedStep(undefined);
          }}
          visible={true}
          onSubmit={handleOk}
          attachment={attachmentData}
          learningCategory={CustomizableLearningCategory.Academy}
          onCancelUpload={cancelUpload}
          stepRequirementComponent={
            <StepRequirementFields
              currentlySelectedRequirementId={requiredId}
              previousStepIsRequired={previousStepIsRequired}
              setPreviousStepIsRequired={(isRequired) => {
                form.setFieldValue('requiredId', previousStepId);
                setPreviousStepIsRequired(isRequired);
              }}
              stepRequirementOptions={stepRequirementOptions}
              showFields={
                !!stepRequirementOptions?.length && showStepRequirementOptions
              }
              onClickAdd={handleAddRequirement}
              onClickRemove={() => setShowStepRequirementOptions(false)}
            />
          }
        />
      )}
      {showDiscussionModal && (
        <AddDiscussionStepModal
          timeZones={timeZoneQuery.isSuccess && timeZoneQuery.data}
          companyId={user?.companyId}
          form={form}
          stepRequirementComponent={
            <StepRequirementFields
              currentlySelectedRequirementId={requiredId}
              previousStepIsRequired={previousStepIsRequired}
              setPreviousStepIsRequired={(isRequired) => {
                form.setFieldValue('requiredId', previousStepId);
                setPreviousStepIsRequired(isRequired);
              }}
              stepRequirementOptions={stepRequirementOptions}
              showFields={
                !!stepRequirementOptions?.length && showStepRequirementOptions
              }
              onClickAdd={handleAddRequirement}
              onClickRemove={() => setShowStepRequirementOptions(false)}
            />
          }
          handleClose={() => setShowDiscussionModal(false)}
          onOk={handleOk}
        />
      )}

      {showCatalogContentDrawer && (
        <AddCatalogContentDrawer
          onClose={({shouldRefresh}) => {
            setShowCatalogContentDrawer(false);
            if (shouldRefresh) {
              invalidateLevels();
              invalidateSteps();
            }
          }}
          existingSteps={steps}
        />
      )}

      <LevelNoContentScreenContainer
        academyId={parseInt(academyId)}
        setShowCustomContentModal={setShowCustomContentModal}
        setShowDiscussionModal={setShowDiscussionModal}
        setShowContentUpsertModal={(open, id, type) => {
          setShowContentUpsertModal([open, id, type]);
        }}
        setShowCatalogContentDrawer={setShowCatalogContentDrawer}
        selectedLevelId={parseInt(levelId)}
      />
    </Container>
  );
}

export default LevelsScreen;
