import {useEffect} from 'react';
import {SkillsContainerProps} from './Skills.interfaces';
import SkillsView from './Skills.view';
import {useProgramTitlesQuery} from '@generated/hooks';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {ProgramSearchVM} from '@generated/interfaces';
import {localizeSkill} from '@utils/l10nUtils';
import {PROGRAM_FILTER_LANGUAGES} from '@utils/constants';

function SkillsContainer({
  handleAction,
  includeSkills = false,
  setIsButtonVisible,
  form,
  topicsQuery,
}: SkillsContainerProps) {
  const getUserQuery = useGetUserQuery();
  const {data: programTitles} = useProgramTitlesQuery({
    queryParams: {
      languages: JSON.parse(localStorage.getItem(PROGRAM_FILTER_LANGUAGES)),
    },
  });
  const programOptions = programTitles
    ? programTitles?.map(
        (program): {label: ProgramSearchVM; value: ProgramSearchVM} => ({
          value: program,
          label: program,
        })
      )
    : [];

  const skillOptions = topicsQuery?.map(({description}) => ({
    value: description,
    label: localizeSkill(description),
  }));

  useEffect(() => {
    setIsButtonVisible(false);
    form.resetFields(['skills']);
  }, []);

  return (
    <SkillsView
      includeSkills={includeSkills}
      skillOptions={skillOptions}
      programOptions={programOptions}
      handleAction={handleAction}
    />
  );
}

export default SkillsContainer;
