import {useMutation, UseMutationResult} from 'react-query';
import {PlanItemType} from '@generated/enums';
import {
  getAddToPlanRm,
  getDeletePlanItemByResourceRm,
  getDeletePlanItemRm,
  getDeleteProgramPlanItemByItemIdRm,
  getDeleteProgramPlanItemByProgramIdRm,
} from '../../../store/apiEndpoints';
import queryClient, {
  simpleDeleteFn,
  simpleMutationFn,
} from '../../../store/queryClient';
import {UserPlanTemplate} from '../../../models/serverModels';
import {notify} from '../../../components/user/notifications';
import {getPlanItemQueryInvalidateKey} from '../../../utils/query-invalidation-helper';

interface Props {
  onSuccess?: () => void;
  onFailure?: () => void;
  onFinished?: () => void;
}

export default function useAddToPlan({
  onSuccess,
  onFailure,
  onFinished,
}: Props): UseMutationResult<UserPlanTemplate, unknown> {
  const addToPlanMutation = useMutation(
    ({
      optionId,
      planItemType,
    }: {
      optionId: string | number;
      planItemType: PlanItemType;
    }) => {
      const addToPlanRm = getAddToPlanRm(optionId, planItemType);
      // no return type
      return simpleMutationFn<UserPlanTemplate>(
        addToPlanRm.path,
        addToPlanRm.payload
      );
    },
    {
      onSuccess: (_, {planItemType}) => {
        onSuccess && onSuccess();
        queryClient.refetchQueries(getPlanItemQueryInvalidateKey(planItemType));
        notify.addToPlanSuccessful();
      },
      onError: () => {
        onFailure && onFailure();
        notify.addToPlanError();
      },
      onSettled: () => {
        onFinished && onFinished();
      },
    }
  );

  return addToPlanMutation;
}

interface UseDeleteFromPlanByIdProps {
  onSuccess?: () => void;
  onFailure?: () => void;
  onFinished?: () => void;
}

/**
 * Removing an item from the user plan using the unique plan item id generated when the plan was added
 */
export function useDeleteFromPlanById({
  onSuccess,
  onFailure,
  onFinished,
}: UseDeleteFromPlanByIdProps): UseMutationResult<unknown, unknown> {
  return useMutation(
    ({itemId}: {itemId: number; itemType: PlanItemType}) => {
      const deletePlanItemByIdRm = getDeletePlanItemRm(itemId);
      // no return type
      return simpleDeleteFn<undefined>(
        deletePlanItemByIdRm.path,
        deletePlanItemByIdRm.payload
      );
    },
    {
      onSuccess: (_, {itemType}) => {
        onSuccess && onSuccess();
        queryClient.refetchQueries(getPlanItemQueryInvalidateKey(itemType));
        notify.deleteFromPlanSuccessful();
      },
      onError: () => {
        onFailure && onFailure();
        notify.deleteFromPlanError();
      },
      onSettled: () => {
        onFinished && onFinished();
      },
    }
  );
}

interface UseDeleteFromPlanByResourceIdProps {
  onSuccess?: () => void;
  onFailure?: (error?: any) => void;
  onFinished?: () => void;
}

/**
 * Removing an item from the user plan using the plan item type (program, time or finance) and the id of the program or incentive
 * Used to allow removing an item from parts of the UI that are not aware of the user plan item id such as the lists of incentives or programs
 */
export function useDeleteFromPlanByResourceId({
  onSuccess,
  onFailure,
  onFinished,
}: UseDeleteFromPlanByResourceIdProps): UseMutationResult<unknown, unknown> {
  return useMutation(
    ({
      resourceId,
      itemType,
    }: {
      resourceId: string | number;
      itemType: PlanItemType;
    }) => {
      const deletePlanItemByResourceRm = getDeletePlanItemByResourceRm(
        resourceId,
        itemType
      );
      // no return type
      return simpleDeleteFn<undefined>(
        deletePlanItemByResourceRm.path,
        deletePlanItemByResourceRm.payload
      );
    },
    {
      onSuccess: (_, {itemType}) => {
        onSuccess && onSuccess();
        queryClient.refetchQueries(getPlanItemQueryInvalidateKey(itemType));
        notify.deleteFromPlanSuccessful();
      },
      onError: (error: any) => {
        if (
          error?.response?.data ===
          'Items for which an application has been started cannot be deleted.'
        ) {
          notify.deleteFromPlanInProgressError();
        } else {
          notify.deleteFromPlanError();
        }
        onFailure && onFailure(error);
      },
      onSettled: () => {
        onFinished && onFinished();
      },
    }
  );
}

interface UseDeleteProgramFromPlanByItemIdProps {
  onSuccess?: () => void;
  onFailure?: () => void;
  onFinished?: () => void;
}

/**
 * Removing an item from the user plan using the unique plan item id generated when the plan was added
 */
export function useDeleteProgramFromPlanByItemId({
  onSuccess,
  onFailure,
  onFinished,
}: UseDeleteProgramFromPlanByItemIdProps): UseMutationResult<unknown, unknown> {
  return useMutation(
    ({itemId, amountSpent}: {itemId: number; amountSpent: number}) => {
      const deleteProgramPlanItemByIdRm = getDeleteProgramPlanItemByItemIdRm(
        itemId,
        amountSpent
      );
      // no return type
      return simpleDeleteFn<number>(
        deleteProgramPlanItemByIdRm.path,
        deleteProgramPlanItemByIdRm.payload
      );
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        queryClient.refetchQueries(
          getPlanItemQueryInvalidateKey(PlanItemType.Program)
        );
        queryClient.refetchQueries(
          getPlanItemQueryInvalidateKey(PlanItemType.Finance, true)
        );
        notify.deleteFromPlanSuccessful();
      },
      onError: () => {
        onFailure && onFailure();
        notify.deleteFromPlanError();
      },
      onSettled: () => {
        onFinished && onFinished();
      },
    }
  );
}

interface UseDeleteProgramFromPlanByProgramIdProps {
  onSuccess?: () => void;
  onFailure?: () => void;
  onFinished?: () => void;
}

/**
 * Removing an item from the user plan using the plan item type (program, time or finance) and the id of the program or incentive
 * Used to allow removing an item from parts of the UI that are not aware of the user plan item id such as the lists of incentives or programs
 */
export function useDeleteProgramFromPlanByProgramId({
  onSuccess,
  onFailure,
  onFinished,
}: UseDeleteProgramFromPlanByProgramIdProps): UseMutationResult<
  unknown,
  unknown
> {
  return useMutation(
    ({programId, amountSpent}: {programId: number; amountSpent: number}) => {
      const deletePlanItemByResourceRm = getDeleteProgramPlanItemByProgramIdRm(
        programId,
        amountSpent
      );
      // no return type
      return simpleDeleteFn<number>(
        deletePlanItemByResourceRm.path,
        deletePlanItemByResourceRm.payload
      );
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        queryClient.refetchQueries(
          getPlanItemQueryInvalidateKey(PlanItemType.Program)
        );
        queryClient.refetchQueries(
          getPlanItemQueryInvalidateKey(PlanItemType.Finance, true)
        );
        notify.deleteFromPlanSuccessful();
      },
      onError: () => {
        onFailure && onFailure();
        notify.deleteFromPlanError();
      },
      onSettled: () => {
        onFinished && onFinished();
      },
    }
  );
}
