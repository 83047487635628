import {AcademyVM} from '@generated/interfaces';

export enum BrandingTab {
  Overview = 'overview',
  Welcome = 'welcome',
}

export interface BrandingScreenProps {
  academyData: AcademyVM;
  refetchAcademy: () => void;
  title?: string;
}
