// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/FinanceRecurrence.cs

enum FinanceRecurrence {
  Never,
  Monthly,
  Yearly,
}

export default FinanceRecurrence;
