import {i18n, k} from '@i18n/translate';
import {Card, Row, Col, Typography, Image} from 'antd';
import {
  getFinanceIncentiveDetailRq,
  getTimeIncentiveDetailRq,
} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {useState} from 'react';
import {FinanceDetailsVM, TimeDetailsVM} from '@models/serverModels';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {AdminReportsTabName} from '@components/admin/pages/reports/store/interface';
import {getAdminReportsPageTabPath} from '@store/apiEndpoints';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import PlanItemDetailPage from '@components/reusable/PlanItemDetailPage/PlanItemDetailPage.container';
import ContextMenuButton from '@blocks/ContextMenu';
import {FinanceIncentiveType} from '../store/interfaces';
import {IncentiveType, PlanItemType} from '@generated/enums';
import {
  FinanceIncentiveTypeString,
  IncentiveTypeString,
} from '@models/clientEnums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.article`
  .ant-card {
    border: none;
    // padding: 0;
    .ant-card-body {
      padding: 0;

      .incentive-card-content {
        height: 182px;

        .img-wrapper {
          height: 100%;

          .ant-image {
            height: 100%;

            img {
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .card-content-wrapper {
          padding: 12px;

          .btn-incentive {
            border: none;
            padding: 0px;
            color: #919294;
          }
        }
      }
    }
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface Props {
  isForTimeIncentives: boolean;
  title: string;
  eligibleEmployees: number;
  learningBudget: boolean;
  timesPlanned: number;
  timesUsed: number;
  imageSmall: string;
  incentiveId: number;
  onEditSuccess: (
    data: TimeDetailsVM | FinanceDetailsVM,
    incentiveType: IncentiveTypeString.Time | IncentiveTypeString.Finance
  ) => void;
  incentiveType?: FinanceIncentiveTypeString | IncentiveType;
}

export default function IncentiveDataCard(props: Props) {
  const navigate = useNavigate();
  const {incentiveId, isForTimeIncentives, incentiveType} = props;
  const [shouldFetchFinance, setShouldFetchFinance] = useState(false);
  const [shouldFetchTime, setShouldFetchTime] = useState(false);
  const [isDetailsDrawerVisible, setIsDetailsDrawerVisible] = useState(false);
  const [shouldOpenEditDrawer, setShouldOpenEditDrawer] = useState(false);
  const financeIncentiveDetailRq = getFinanceIncentiveDetailRq(
    props.incentiveId
  );
  useQuery<FinanceDetailsVM>(
    financeIncentiveDetailRq.queryKey,
    () => simpleQueryFn(financeIncentiveDetailRq.path),
    {
      enabled: shouldFetchFinance,
      onSuccess: (data) => {
        if (shouldOpenEditDrawer) {
          props.onEditSuccess(data, IncentiveTypeString.Finance);
        }
        setShouldFetchFinance(false);
      },
    }
  );

  const timeIncentiveDetailRq = getTimeIncentiveDetailRq(props.incentiveId);
  useQuery<TimeDetailsVM>(
    timeIncentiveDetailRq.queryKey,
    () => simpleQueryFn(timeIncentiveDetailRq.path),
    {
      enabled: shouldFetchTime,
      onSuccess: (data) => {
        if (shouldOpenEditDrawer) {
          props.onEditSuccess(data, IncentiveTypeString.Time);
        }
        setShouldFetchTime(false);
      },
    }
  );

  const onClickEdit = () => {
    setShouldOpenEditDrawer(true);
    if (props.isForTimeIncentives) {
      setShouldFetchTime(true);
    } else {
      setShouldFetchFinance(true);
    }
  };

  const onClickViewDetails = () => {
    setShouldOpenEditDrawer(false);
    setIsDetailsDrawerVisible(true);
  };

  const onCloseDetailsPageDrawer = () => {
    setIsDetailsDrawerVisible(false);
  };

  const onClickManageBalances = () => {
    navigate(getAdminReportsPageTabPath(AdminReportsTabName.Prepayment));
  };

  return (
    <Container aria-label={i18n.t(k.INCENTIVE__PLURAL)}>
      <Card
        role="article"
        aria-labelledby="incentive-title"
        className="incentive-card"
        id={`incentive-card-${props.incentiveId}`}>
        {isDetailsDrawerVisible && (
          <PlanItemDetailPage
            id={incentiveId}
            visible={isDetailsDrawerVisible}
            onClose={onCloseDetailsPageDrawer}
            forAdminInitiative
            itemType={
              isForTimeIncentives ? PlanItemType.Time : PlanItemType.Finance
            }
          />
        )}
        <Row className="incentive-card-content" align="middle">
          <Col className="img-wrapper" xs={0} sm={6} md={6} lg={6}>
            <button
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{height: '100%'}}>
              <Image
                src={
                  props.imageSmall
                    ? props.imageSmall
                    : getPlaceholderImgSrc(incentiveId)
                }
                alt={i18n.t(k.A11Y__PHOTO_OF_INCENTIVE)}
              />
            </button>
          </Col>
          <Col className="card-content-wrapper" xs={24} sm={18} md={18} lg={18}>
            <Row gutter={[0, 16]}>
              <Col xs={22}>
                <Row gutter={[8, 8]}>
                  <Col>
                    <Row>
                      <Typography.Title
                        className="incentive-title-paragraph"
                        level={5}>
                        {props.learningBudget &&
                          !props.isForTimeIncentives &&
                          i18n.t(k.PLB).toLocaleUpperCase()}
                        {!props.learningBudget &&
                          props.isForTimeIncentives &&
                          i18n.t(k.TIME__INCENTIVE).toLocaleUpperCase()}
                        {!props.learningBudget &&
                          !props.isForTimeIncentives &&
                          i18n.t(k.INCENTIVE__FINANCE).toLocaleUpperCase()}
                      </Typography.Title>
                    </Row>
                    <Row>
                      <Typography.Title
                        className="incentive-title"
                        id="incentive-title"
                        level={4}>
                        {props?.title}
                      </Typography.Title>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={2}>
                <ContextMenuButton
                  popupContainerId="main-content"
                  itemId={props?.incentiveId}
                  menuItems={[
                    {
                      label: i18n.t(k.INCENTIVE__EDIT),
                      onClick: onClickEdit,
                    },
                    {
                      label: i18n.t(k.CTA__VIEW__DETAIL__PLURAL),
                      onClick: onClickViewDetails,
                    },
                    !isForTimeIncentives &&
                    incentiveType === FinanceIncentiveTypeString.Prepayment
                      ? {
                          label: i18n.t(k.CTA__MANAGE, {
                            item: i18n.t(k.MONEY__BALANCE__PLURAL),
                          }),
                          onClick: onClickManageBalances,
                        }
                      : null,
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={8} md={8}>
                <Row style={{textAlign: 'center'}}>
                  <Col xs={24}>
                    <Typography.Text className="incentive-text-bold">
                      {props?.eligibleEmployees}
                    </Typography.Text>
                  </Col>
                  <Col xs={24}>
                    <Typography.Text className="incentive-label-small">
                      {i18n.t(k.EMPLOYEE__ELIGIBLE)}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col xs={8} md={8}>
                <Row style={{textAlign: 'center'}}>
                  <Col xs={24}>
                    <Typography.Text className="incentive-text-bold">
                      {props?.timesPlanned}
                    </Typography.Text>
                  </Col>
                  <Col xs={24}>
                    <Typography.Text className="incentive-label-small">
                      {i18n.t(k.GENERIC__TIMES_PLANNED)}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              <Col xs={8} md={8}>
                <Row style={{textAlign: 'center'}}>
                  <Col xs={24}>
                    <Typography.Text className="incentive-text-bold">
                      {props?.timesUsed}
                    </Typography.Text>
                  </Col>
                  <Col xs={24}>
                    <Typography.Text className="incentive-label-small">
                      {i18n.t(k.GENERIC__TIMES_USED)}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}
