import styled from 'styled-components';

export const Container = styled.div``;

export const DrawerContentContainer = styled.div`
  max-width: 1200px;
  padding: 24px;
  margin: auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
