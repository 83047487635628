// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CreditCardApprovalStatus.cs

enum CreditCardApprovalStatus {
  NoCreditCardExists,
  CreditCardCreated,
  CreditCardFailed,
  CreditCardRequiresApproval,
}

export default CreditCardApprovalStatus;
