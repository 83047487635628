import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import NavButton from '@blocks/NavigationButton';
import {COLORS} from '@utils/constants';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {
  SnippetsOutlined,
  InfoCircleOutlined,
  LockOutlined,
} from '@ant-design/icons';
import {HEADER_HEIGHT, SIDE_NAV_WIDTH} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Component
|--------------------------------------------------------------------------
*/

const NavContainer = styled.nav`
  align-items: center;
  background: white;
  bottom: 0;
  box-shadow: inset -1px 0px 0px ${COLORS.Neutral200};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding-top: 4px;
  position: absolute;
  top: ${() => HEADER_HEIGHT}px;
  width: ${() => SIDE_NAV_WIDTH}px;
  z-index: 90;
`;

const NavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavListItem = styled.li``;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramDrawerSideNavProps {
  currentScreen: CustomProgramScreens;
  onClickCurriculum: () => void;
  onClickSetup: () => void;
  onClickPermissions: () => void;
}

function CustomProgramDrawerSideNav({
  currentScreen,
  onClickCurriculum,
  onClickSetup,
  onClickPermissions,
}: CustomProgramDrawerSideNavProps) {
  return (
    <NavContainer>
      <NavList>
        <NavListItem>
          <NavButton
            Icon={InfoCircleOutlined}
            isSelected={[
              CustomProgramScreens.Setup,
              CustomProgramScreens.Setup.toLowerCase(),
            ].includes(currentScreen)}
            label={i18n.t(k.GENERIC__SETUP)}
            onClick={onClickSetup}
            style={{marginBottom: '8px'}}
          />
        </NavListItem>
        {
          <NavListItem>
            <NavButton
              Icon={LockOutlined}
              isSelected={[
                CustomProgramScreens.Permissions,
                CustomProgramScreens.Permissions.toLowerCase(),
              ].includes(currentScreen)}
              label={i18n.t(k.PERMISSION__PLURAL)}
              onClick={onClickPermissions}
              style={{marginBottom: '8px'}}
            />
          </NavListItem>
        }
        <NavListItem>
          <NavButton
            Icon={SnippetsOutlined}
            isSelected={[
              CustomProgramScreens.Curriculum,
              CustomProgramScreens.Curriculum.toLowerCase(),
            ].includes(currentScreen)}
            label={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
            onClick={onClickCurriculum}
            style={{marginBottom: '8px'}}
          />
        </NavListItem>
      </NavList>
    </NavContainer>
  );
}

/*
|--------------------------------------------------------------------------
| Default Export
|--------------------------------------------------------------------------
*/

export default CustomProgramDrawerSideNav;
