import {
  CurrencyCode,
  DurationUnitType,
  PresenceType,
  TimeCommitmentType,
} from './../enums';
import {NewProgram} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: NewProgram

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getNewProgramMockData(args?: Partial<NewProgram>): NewProgram {
  return {
    cost: faker.datatype.number(),
    currency: CurrencyCode.USD,
    presenceType: PresenceType.Online,
    programLength: faker.datatype.number(),
    programLengthUnit: DurationUnitType.Days,
    programTypeId: faker.datatype.number(), // optional
    providerId: faker.datatype.number(),
    providerName: faker.name.fullName(),
    shortDescription: faker.lorem.lines(),
    timeCommitmentType: TimeCommitmentType.PartTime,
    title: faker.company.catchPhrase(),
    url: faker.internet.url(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const NewProgramMockData: NewProgram = {
  cost: 123,
  currency: CurrencyCode.USD,
  presenceType: PresenceType.Online,
  programLength: 123,
  programLengthUnit: DurationUnitType.Days,
  programTypeId: 1, // optional
  providerId: 1,
  providerName: 'Bobby Johnson',
  shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  timeCommitmentType: TimeCommitmentType.PartTime,
  title: 'Advanced Web Design',
  url: 'https://this-person-does-not-exist.com/en',
};
