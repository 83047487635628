import {useEffect} from 'react';
import styled from 'styled-components';
import CustomProgramProjectsTableWithRoutes from './CustomProgramProjectsTableWithRoutes.container';
import {useResetCustomProgramProjectCount} from '@hooks/apiEndpoints/permission/mutations';
import {useParams} from 'react-router-dom';
import {useCustomProgramPermissionVMsQuery} from '@hooks/apiEndpoints/permission/queries';
import {Helmet} from 'react-helmet-async';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  width: 100%;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
interface ProjectsScreenWithRoutingProps {
  basePath: string;
  onClose: () => void;
  title?: string;
}

function ProjectsScreenWithRouting(props: ProjectsScreenWithRoutingProps) {
  const {customProgramId} = useParams();

  const {invalidateExact: invalidateCustomPogramPermissions} =
    useCustomProgramPermissionVMsQuery(null, {
      enabled: true,
    });

  const {mutate: resetCustomProgramProjectCount} =
    useResetCustomProgramProjectCount({
      onSuccess: invalidateCustomPogramPermissions,
    });

  useEffect(() => {
    resetCustomProgramProjectCount({
      payload: {customProgramId: parseInt(customProgramId)},
    });
  }, []);

  return (
    <Container>
      <Helmet>
        <title>{props.title}</title>
      </Helmet>
      <CustomProgramProjectsTableWithRoutes {...props} />
    </Container>
  );
}

export default ProjectsScreenWithRouting;
