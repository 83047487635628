import './InitiativeTimeIncentivePanel.scss';
import {Fragment, useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Divider, Input, Row, Skeleton, Typography} from 'antd';
import {getInitiativeIncentivesRq} from '@store/apiEndpoints';
import {IncentiveType, PlanItemType} from '@generated/enums';
import {includes} from 'lodash';
import {COLORS} from '@utils/constants';
import {InitiativePayload, TimeIncentiveVM} from '@models/serverModels';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import InitiativeCard from '@components/reusable/ItemSearchCard/InitiativeCard';
import NoDataImage from '@blocks/NoDataImage';

const {Search} = Input;

interface Props {
  updateSelectedInitiativeItem: (
    arg1: boolean,
    incentiveId: any,
    PlanItemType: number
  ) => void;
  data?: InitiativePayload;
}

const InitiativeTimeIncentivePanel = ({
  updateSelectedInitiativeItem,
  data,
}: Props) => {
  const [isResultEmpty, setIsResultEmpty] = useState(false);
  const [searchString, setSearchString] = useState<string>('');
  const [filteredTimeIncentives, setFilteredTimeIncentives] = useState<
    TimeIncentiveVM[]
  >([]);
  const totalSelectedItems = data?.timeIncentiveIds?.length;
  const initiativeIncentivesRq = getInitiativeIncentivesRq(
    IncentiveType.Time,
    data?.id
  );
  const initiativeIncentivesQuery = useQuery<TimeIncentiveVM[]>(
    initiativeIncentivesRq.queryKey,
    () => simpleQueryFn(initiativeIncentivesRq.path),
    {
      placeholderData: () => [],
    }
  );

  const onSearch = (searchString: string) => {
    const _searchString = searchString.trim().toLocaleLowerCase() ?? '';
    setSearchString(_searchString);
  };

  useEffect(() => {
    const filterSearch = () => {
      let newResult: any;
      if (searchString) {
        newResult = initiativeIncentivesQuery.data.filter((incentive) =>
          includes(incentive.title.toLocaleLowerCase().trim(), searchString)
        );
      } else {
        newResult = initiativeIncentivesQuery.data;
      }
      setFilteredTimeIncentives(newResult);
      setIsResultEmpty(!!newResult?.length);
    };
    filterSearch();
  }, [searchString, initiativeIncentivesQuery.data, totalSelectedItems]);

  const LoadingPlaceholder = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Skeleton active />
        </Col>
        <Col xs={24}>
          <Skeleton active />
        </Col>
        <Col xs={24}>
          <Skeleton active />
        </Col>
      </Row>
    );
  };

  return (
    <Fragment>
      <Card
        role="article"
        aria-labelledby="sub-component-card-title"
        className="sub-component-card">
        <Row className="sub-component-card-header">
          <Typography.Title
            className="sub-component-card-title"
            id="sub-component-card-title">
            {i18n.t(k.INCENTIVE__SELECT_TIME)}
          </Typography.Title>
        </Row>
        <Divider style={{background: COLORS.Neutral300, margin: '8px 0'}} />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            margin: '16px 0',
          }}>
          <Search
            style={{width: 200}}
            className="sub-component-card-search-bar"
            onSearch={onSearch}
            allowClear
            placeholder={i18n.t(k.A11Y__SEARCH_FOR_INCENTIVES)}
          />
          <Typography.Text
            style={{
              fontWeight: 'normal',
              fontSize: '1rem',
              lineHeight: '2.5rem',
              textAlign: 'right',
              color: '#000000',
            }}>
            {totalSelectedItems
              ? totalSelectedItems === 1
                ? i18n.t(k.INCENTIVE__SELECTED__SINGLE)
                : i18n.t(k.INCENTIVE__SELECTED__PLURAL__FORMAT, {
                    count: totalSelectedItems,
                  })
              : i18n.t(k.INCENTIVE__NONE)}{' '}
          </Typography.Text>
        </div>

        {filteredTimeIncentives.map((incentive) => {
          const handleClickAddToInitiative = () => {
            updateSelectedInitiativeItem(true, incentive.id, PlanItemType.Time);
          };
          const handleClickSelected = () => {
            updateSelectedInitiativeItem(
              false,
              incentive.id,
              PlanItemType.Time
            );
          };
          return (
            <InitiativeCard
              key={incentive.id}
              id={incentive.id}
              description={incentive.shortDescription}
              itemType={PlanItemType.Time}
              onClickAddToInitiative={handleClickAddToInitiative}
              onClickSelected={handleClickSelected}
              providerImgSrc={incentive.providerLogo}
              previewImgSrc={incentive.imageLarge}
              selected={data?.timeIncentiveIds?.includes(incentive.id)}
              provider={incentive.provider}
              title={incentive.title}
            />
          );
        })}

        {initiativeIncentivesQuery.isLoading ||
        (!filteredTimeIncentives.length && !isResultEmpty) ? (
          <LoadingPlaceholder />
        ) : (
          !filteredTimeIncentives.length && isResultEmpty && <NoDataImage />
        )}
      </Card>
    </Fragment>
  );
};

export default InitiativeTimeIncentivePanel;
