import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import Grid from '@blocks/Grid';
import {InputLabel, LearnInSelect} from '@components/reusable/LearnInForm';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {LearnInSelectOption} from '@components/reusable/Select/Select.style';
import {CustomProgramAdminDetailVM} from '@models/serverModels';
import {ChatExternalChannelVisibility} from '@generated/enums';

enum ChannelEnabled {
  On,
  Off,
}

interface Props {
  customProgramForEdit?: CustomProgramAdminDetailVM;
}

function TempSlackConfigurationForm({customProgramForEdit}: Props) {
  const [slackEnabled, setSlackEnabled] = useState<ChannelEnabled>(
    customProgramForEdit?.chatEnabled ? ChannelEnabled.On : ChannelEnabled.Off
  );
  const [channelName, setChannelName] = useState<string>(
    customProgramForEdit?.chatExternalChannelName ?? ''
  );
  const [hasExternalChatId, setHasExternalChatId] = useState<string>(
    customProgramForEdit?.hasExternalChatId ? 'Yes' : 'No'
  );
  const [channelVisibility, setChannelVisibility] =
    useState<ChatExternalChannelVisibility>(
      customProgramForEdit?.chatExternalChannelVisibility ??
        ChatExternalChannelVisibility.Private
    );

  const handleChangeChannelName = (event: any) => {
    event?.target?.value && setChannelName(event.target.value);
  };

  return (
    <Grid.Container>
      <Grid.Box>
        <InputLabel
          htmlFor="slack-enabled-select"
          label={i18n.t(k.SLACK__CONFIGURATION__ENABLED)}
        />
        <LearnInSelect
          id="slack-enabled-select"
          data-testid="slack-enabled-select"
          placeholder={i18n.t(k.SLACK__CONFIGURATION__ENABLED__PLACEHOLDER)}
          onChange={setSlackEnabled}
          value={slackEnabled}
          isError={false}>
          <LearnInSelectOption
            data-testid="slack-on-option"
            key={ChannelEnabled.On}
            value={ChannelEnabled.On}>
            {i18n.t(k.STATUS__ON)}
          </LearnInSelectOption>
          <LearnInSelectOption
            data-testid="slack-off-option"
            key={ChannelEnabled.Off}
            value={ChannelEnabled.Off}>
            {i18n.t(k.STATUS__OFF)}
          </LearnInSelectOption>
        </LearnInSelect>
      </Grid.Box>
      {slackEnabled === ChannelEnabled.On && (
        <>
          <Grid.Box>
            <InputLabel
              htmlFor="slack-channel-external-input"
              label="Has External Channel Id"
            />
            <LearnInInput
              id="slack-channel-external-input"
              data-testid="slack-channel-external-input"
              disabled={true}
              tag={InputTags.PRIMARY}
              value={hasExternalChatId}
              onChange={() => {
                return;
              }}
              isError={false}
            />
          </Grid.Box>
          <Grid.Box>
            <InputLabel
              htmlFor="slack-channel-name-input"
              label="Channel Name"
            />
            <LearnInInput
              id="slack-channel-name-input"
              data-testid="slack-channel-name-input"
              tag={InputTags.PRIMARY}
              value={channelName}
              onChange={handleChangeChannelName}
              isError={false}
            />
          </Grid.Box>
          <Grid.Box>
            <InputLabel
              htmlFor="channel-visibility-input"
              label={i18n.t(k.SLACK__CONFIGURATION__VISIBILITY__TITLE)}
            />
            <LearnInSelect
              id="channel-visibility-input"
              data-testid="channel-visibility-select"
              placeholder={i18n.t(
                k.SLACK__CONFIGURATION__VISIBILITY__DESCRIPTION
              )}
              onChange={setChannelVisibility}
              value={channelVisibility}
              isError={false}>
              <LearnInSelectOption
                data-testid="slack-public-option"
                key={ChatExternalChannelVisibility.Public}
                value={ChatExternalChannelVisibility.Public}>
                {i18n.t(k.ACCESS__PUBLIC)}
              </LearnInSelectOption>
              <LearnInSelectOption
                data-testid="slack-private-option"
                key={ChatExternalChannelVisibility.Private}
                value={ChatExternalChannelVisibility.Private}>
                {i18n.t(k.ACCESS__PRIVATE)}
              </LearnInSelectOption>
            </LearnInSelect>
          </Grid.Box>
        </>
      )}
    </Grid.Container>
  );
}

export default TempSlackConfigurationForm;
