import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInTable, {
  basicSorter,
  DefaultSortOrder,
  TableCell,
  TableColLink,
} from '@components/reusable/LearnInTable';
import {
  renderPermissionsColumn,
  renderStatusColumn,
} from './../../CustomProgramsTable';
import ContextMenu from '@blocks/ContextMenu';
import {parseToLocalMoment, DATE_FORMAT} from '@utils/timeUtils';
import {COLORS} from '@utils/constants';
import {CustomProgramCohortAdminVM} from '@generated/interfaces';

/* 
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div``;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CohortsTableProps {
  dataSource: CustomProgramCohortAdminVM[];
  onClickDelete: (id: number) => void;
  onClickEdit: (id: number) => void;
  onClickTitle: (id: number) => void;
  onClickViewReports: (id: number) => void;
  onClickViewCohort: (id: number) => void;
  onClickDuplicate: (id: number) => void;
}

function CohortsTable({
  dataSource,
  onClickDelete,
  onClickEdit,
  onClickTitle,
  onClickViewReports,
  onClickViewCohort,
  onClickDuplicate,
}: CohortsTableProps) {
  const columns = [
    {
      title: i18n.t(k.COHORT__PLURAL),
      dataIndex: null,
      key: 'startAndEndDates',
      render: (_: any, row: CustomProgramCohortAdminVM) => {
        const {cohortTitle} = row;

        const formattedStartDate = parseToLocalMoment(row.startDateUtc).format(
          DATE_FORMAT.MONTH_DAY_YEAR
        );
        const formattedEndDate = parseToLocalMoment(row.endDateUtc).format(
          DATE_FORMAT.MONTH_DAY_YEAR
        );

        const cellContent = cohortTitle
          ? `${cohortTitle} (${formattedStartDate} - ${formattedEndDate})`
          : `${formattedStartDate} - ${formattedEndDate}`;

        return (
          <TableColLink
            onClick={(e) => {
              e.stopPropagation();
              onClickTitle(row.id);
            }}>
            {cellContent}
          </TableColLink>
        );
      },
      sorter: (
        a: CustomProgramCohortAdminVM,
        b: CustomProgramCohortAdminVM
      ) => {
        const _a = new Date(a.startDateUtc);
        const _b = new Date(b.startDateUtc);
        return _a.getTime() < _b.getTime() ? 1 : -1;
      },
      defaultSortOrder: DefaultSortOrder.Descend,
    },
    {
      title: i18n.t(k.PARTICIPANT__PLURAL),
      dataIndex: 'participantCount',
      key: 'participantCount',
      sorter: basicSorter('participantCount'),
    },
    {
      title: i18n.t(k.PERMISSION__PLURAL),
      dataIndex: 'restrictedAccess',
      key: 'restrictedAccess',
      render: renderPermissionsColumn,
    },
    {
      title: i18n.t(k.STATUS__STATUS),
      dataIndex: 'status',
      key: 'status',
      render: renderStatusColumn,
    },
    {
      title: null,
      dataIndex: null,
      key: 'optionsMenu',
      render: (_, row) => {
        return (
          <TableCell>
            <ContextMenu
              menuItems={[
                {
                  label: i18n.t(k.COHORT__VIEW__PLURAL),
                  onClick: () => onClickViewCohort(row.id),
                },
                {
                  label: i18n.t(k.REPORT__PLURAL__VIEW),
                  onClick: () => onClickViewReports(row.id),
                },
                {
                  label: i18n.t(k.COHORT__EDIT),
                  onClick: () => onClickEdit(row.id),
                },
                {
                  label: i18n.t(k.COHORT__DUPLICATE__VERB),
                  onClick: () => onClickDuplicate(row.id),
                },
                {
                  label: (
                    <span style={{color: COLORS.Red800}}>
                      {i18n.t(k.COHORT__DELETE)}
                    </span>
                  ),
                  onClick: () => onClickDelete(row.id),
                },
              ]}
            />
          </TableCell>
        );
      },
    },
  ];
  return (
    <Container>
      <LearnInTable hasContainer dataSource={dataSource} columns={columns} />
    </Container>
  );
}

export default CohortsTable;
