import * as React from 'react';
import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {AcademyWithMembershipStatusVM} from '@generated/interfaces';
import {AcademyUserStatus} from '@generated/enums';
import AcademyWelcomeDrawer from '../AcademyWelcome/AcademyWelcomeDrawer.container';
import AcademiesList from './AcademiesList';
import AcademyDrawer from '../AcademyDrawer/AcademyDrawer.container';

import {
  getAcademyUserDrawerScreenNavItemUrlPath,
  userAcademiesRoute,
  userAcademyRoute,
  userWelcomeAcademyRoute,
} from '@utils/ClientPaths';
import {i18n, k} from '@i18n/translate';
import isNil from 'lodash/isNil';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {useState} from 'react';
import {usePublishedAcademies} from '../academy.queries';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function AcademiesListContainer({title}: {title?: string}) {
  const navigate = useNavigate();
  const [prevPath, setPrevPath] = useState('');

  useSetPageTitle(title);

  // Fetch all published academies
  const {isLoading: isLoadingPublishedAcademies, data: publishedAcademies} =
    usePublishedAcademies();

  const handleClickViewAcademy = (academy: AcademyWithMembershipStatusVM) => {
    setPrevPath(userAcademiesRoute());
    if (
      academy.academyUserStatus === AcademyUserStatus.NotStarted ||
      academy.academyUserStatus === AcademyUserStatus.Dropped ||
      isNil(academy.academyUserStatus)
    ) {
      navigate(userWelcomeAcademyRoute(academy.id));
    } else {
      navigate(userAcademyRoute(academy.id));
    }
  };

  return (
    <>
      <Routes>
        <Route path="" element={<Outlet />}>
          <Route
            path="/"
            element={
              <AcademiesList
                title={i18n.t(k.ACADEMY__PLURAL)}
                academies={publishedAcademies || []}
                isLoading={isLoadingPublishedAcademies}
                onClickViewAcademy={handleClickViewAcademy}
              />
            }
          />
          <Route
            path="welcome/:academyId"
            element={
              <AcademyWelcomeDrawer
                transitionOnRender
                title={i18n.t(k.WELCOME)}
                prevPath={prevPath}
              />
            }
          />
          <Route
            path="view/:academyId/*"
            element={
              <AcademyDrawer
                transitionOnRender
                title={i18n.t(k.GENERIC__OVERVIEW)}
                isActivePlanItem
                getScreenNavItemUrlPath={
                  getAcademyUserDrawerScreenNavItemUrlPath
                }
                onClose={undefined}
                prevPath={prevPath}
              />
            }
          />
        </Route>
      </Routes>
    </>
  );
}

export default AcademiesListContainer;
