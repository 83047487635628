import * as React from 'react';
import styled from 'styled-components';

const FormGroupContainer = styled.div``;

const GroupTitle = styled.h3`
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 16px;
`;

export interface SideColorSelectorProps {
  children: React.ReactNode;
  groupTitle: string;
}

function SideColorSelector({children, groupTitle}: SideColorSelectorProps) {
  return (
    <FormGroupContainer>
      <GroupTitle>{groupTitle}</GroupTitle>
      {children}
    </FormGroupContainer>
  );
}

export default SideColorSelector;
