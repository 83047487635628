// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/InitiativePurpose.cs

enum InitiativePurpose {
  LearnCriticalSkill,
  PrepareForNewRole,
  Other,
}

export default InitiativePurpose;
