import {notify} from '@components/user/notifications';
import {
  AcademyUserPermissionPayload,
  DeleteAcademyUserPermissionPayload,
  UserPermissionUpdatePayload,
  AcademySingleUserPayload,
} from '@models/serverModels';
import {
  getAddAcademyUserPermissionsRm,
  getDeleteAcademyUserPermissionRm,
  getUpdateUserPermissionRm,
} from '@store/apiEndpoints/academy/mutations';
import {simpleDeleteFn, simpleMutationFn} from '@store/queryClient';
import {useMutation, UseMutationOptions, UseMutationResult} from 'react-query';
import {mutationHookFactory, MutationMethods} from '../utils';
import {UpdateAcademyStepRequirementPayload} from '@models/api/academy/payloads';
import {
  AcademyProgramStepPayload,
  AcademyLevelPayload,
} from '@generated/interfaces';

export const BASE_PATH = 'academies';

export function useDeleteAcademyUserPermission(
  options?: UseMutationOptions<
    DeleteAcademyUserPermissionPayload,
    unknown,
    unknown
  >
): UseMutationResult<DeleteAcademyUserPermissionPayload, unknown> {
  return useMutation(
    (payload: DeleteAcademyUserPermissionPayload) => {
      const mutationRm = getDeleteAcademyUserPermissionRm(payload);
      return simpleDeleteFn<DeleteAcademyUserPermissionPayload>(
        mutationRm.path,
        mutationRm.payload
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context);
        notify.deleteAcademyUserPermissionSuccess();
      },
      onError: (error, variables, context) => {
        options.onError && options.onError(error, variables, context);
        notify.deleteAcademyUserPermissionError();
      },
    }
  );
}

export function useAddAcademyUserPermission(
  options?: UseMutationOptions<AcademyUserPermissionPayload, unknown, unknown>
): UseMutationResult<AcademyUserPermissionPayload, unknown> {
  return useMutation(
    (payload: AcademyUserPermissionPayload) => {
      const mutationRm = getAddAcademyUserPermissionsRm(payload);
      return simpleMutationFn<AcademyUserPermissionPayload>(
        mutationRm.path,
        mutationRm.payload
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context);
        notify.addAcademyUserPermissionsSuccess();
      },
      onError: (error, variables, context) => {
        options.onError && options.onError(error, variables, context);
        notify.addAcademyUserPermissionsError();
      },
    }
  );
}

export function useUpdateAcademyUserPermission(
  options?: UseMutationOptions<UserPermissionUpdatePayload, unknown, unknown>
): UseMutationResult<UserPermissionUpdatePayload, unknown> {
  return useMutation(
    (payload: UserPermissionUpdatePayload) => {
      const mutationRm = getUpdateUserPermissionRm(payload);
      return simpleMutationFn<UserPermissionUpdatePayload>(
        mutationRm.path,
        mutationRm.payload
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context);
        notify.updateAcademyUserPermissionSuccess();
      },
      onError: (error, variables, context) => {
        options.onError && options.onError(error, variables, context);
        notify.updateAcademyUserPermissionError();
      },
    }
  );
}

export const useDeleteAcademy = mutationHookFactory<null, {academyId: number}>(
  MutationMethods.Delete,
  ({academyId}: {academyId: number}) =>
    `${BASE_PATH}/delete-academy/${academyId}`,
  {
    onSuccessNotification: notify.removeAcademySuccess,
    onErrorNotification: notify.removeAcademyError,
  }
);

export const useAddAcademyProgramStep =
  mutationHookFactory<AcademyProgramStepPayload>(
    MutationMethods.Post,
    `/${BASE_PATH}/add-academy-program-steps`,
    {
      onSuccessNotification: notify.addStepsSuccess,
    }
  );

export const useUpdateAcademyLevel = mutationHookFactory<AcademyLevelPayload>(
  MutationMethods.Post,
  `/${BASE_PATH}/update-academy-level`
);

export const useUpdateAcademyStepRequirement =
  mutationHookFactory<UpdateAcademyStepRequirementPayload>(
    MutationMethods.Post,
    `/${BASE_PATH}/update-academy-step-requirement`
  );

export const useAddUserToAcademy =
  mutationHookFactory<AcademySingleUserPayload>(
    MutationMethods.Post,
    `${BASE_PATH}/add-academy-user`,
    {
      onSuccessNotification: notify.academyUserAddedSuccess,
      onErrorNotification: notify.academyUserAddedError,
    }
  );
