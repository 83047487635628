import {CustomProgramAdminDetailVM as LegacyCustomProgramAdminDetailVM} from '@models/serverModels';
import {useCallback, useMemo, useState} from 'react';
import * as React from 'react';
import AddCustomProgramDrawer from './AddCustomProgramDrawer';
import {debounce} from 'lodash';
import {useParams} from 'react-router-dom';
import CustomProgramAdminDrawer from '../../programs/customPrograms/CustomProgramAdminDrawer.container';
import {useAcademyStepVMsQuery} from '@hooks/apiEndpoints/academy/queries';
import {useAddAcademyProgramStep} from '@hooks/apiEndpoints/academy/mutations';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useParentCustomProgramsQuery} from '@generated/hooks';
import {CustomProgramStatus} from '@generated/enums';
import {CustomProgramAdminDetailVM} from '@generated/interfaces';
import {AcademyStepVM} from '@generated/interfaces';
import {AcademyStepType} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface AddCustomProgramDrawerContainerProps {
  onClose: (callbackOptions?: {shouldRefresh: boolean}) => void;
  levelId: number;
  existingSteps?: AcademyStepVM[];
}

function AddCustomProgramDrawerContainer({
  onClose,
  existingSteps,
  levelId,
}: AddCustomProgramDrawerContainerProps) {
  const [programSearchText, setProgramSearchText] = React.useState('');
  const [searchFilteredCustomPrograms, setSearchFilteredCustomPrograms] =
    useState<CustomProgramAdminDetailVM[]>([]);
  const [allSelected, setAllSelected] = React.useState(false);
  const [newlySelected, setNewlySelected] = React.useState([]);
  const [customProgramForEdit, setCustomProgramForEdit] = React.useState<Pick<
    CustomProgramAdminDetailVM,
    'id' | 'status'
  > | null>(null);
  const [showCustomProgramAdminDrawer, setShowCustomProgramAdminDrawer] =
    useState(false);

  // Custom Programs List Table Data
  const getCustomProgramsQuery = useParentCustomProgramsQuery({
    queryParams: {status: CustomProgramStatus.Published},
  });

  const {availableCustomPrograms, previouslySelected} = useMemo(() => {
    const existingStepsIds = existingSteps
      .filter(({type}) => type == AcademyStepType.CustomProgram)
      .map((d) => d.cohortDisplayed?.parentCustomProgramId || d.programId);

    return {
      previouslySelected: existingStepsIds,
      availableCustomPrograms: getCustomProgramsQuery.data?.filter(
        (datum: CustomProgramAdminDetailVM) =>
          !existingStepsIds.includes(datum.id)
      ),
    };
  }, [existingSteps, getCustomProgramsQuery.data]);

  // Search marketplace programs
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const applyCustomProgramsSearch = useCallback(
    debounce(() => {
      const searchResults = availableCustomPrograms?.filter(({title}) => {
        if (!programSearchText) return true;
        return new RegExp(programSearchText, 'i').test(`${title}`);
      });
      setSearchFilteredCustomPrograms(searchResults);
    }, 750),
    [availableCustomPrograms, programSearchText]
  );
  const params = useParams();
  React.useEffect(() => {
    applyCustomProgramsSearch();
  }, [applyCustomProgramsSearch]);

  const handleClickCheckbox = (id) => {
    !newlySelected.includes(id)
      ? setNewlySelected((selectedPrograms) => [...selectedPrograms, id])
      : setNewlySelected([...newlySelected.filter((x) => x !== id)]);
  };

  const handleClearSelectedPrograms = () => {
    setNewlySelected([]);
  };

  //Check or uncheck all elements
  const handleCheckAll = () => {
    setAllSelected(!allSelected);
  };

  const {isAdmin} = useAuth();

  React.useEffect(() => {
    !!allSelected
      ? setNewlySelected(searchFilteredCustomPrograms.map((s) => s.id))
      : setNewlySelected([]);
  }, [allSelected]);

  //Add selected steps to program
  const addMarketplaceProgramAsStepMutation = useAddAcademyProgramStep({
    onSuccess: () => {
      onClose({shouldRefresh: true});
    },
  });

  const addSteps = () => {
    addMarketplaceProgramAsStepMutation.mutate({
      payload: {
        academyLevelId: levelId,
        type: AcademyStepType.CustomProgram,
        newSteps: previouslySelected.concat(newlySelected), // `newSteps` is actually the full list of step ids, inclusive of those previously existing.
      },
    });
  };

  const refreshAndSelectLastProgram = () => {
    getCustomProgramsQuery.refetch().then((res) => {
      if (res.data) {
        const latestProgram = res.data[res.data?.length - 1];
        setNewlySelected((selectedPrograms) => [
          ...selectedPrograms,
          latestProgram.id,
        ]);
        placeLatestProgramOnTop(latestProgram);
      }
    });
  };

  //Set the newest program on top after creating it
  const placeLatestProgramOnTop = debounce((program) => {
    const newSorted = [...availableCustomPrograms];
    newSorted.unshift(program);
    newSorted.pop();
    setSearchFilteredCustomPrograms(newSorted);
  }, 750);

  const openCustomProgramDrawer = () => {
    setShowCustomProgramAdminDrawer(true);
  };

  return (
    <>
      <AddCustomProgramDrawer
        dataSource={searchFilteredCustomPrograms || []}
        newlySelected={newlySelected}
        setProgramSearchText={setProgramSearchText}
        programSearchText={programSearchText}
        onClickCheckbox={handleClickCheckbox}
        clearSelectedPrograms={handleClearSelectedPrograms}
        onClose={onClose}
        allSelected={allSelected}
        checkAll={handleCheckAll}
        addSteps={addSteps}
        openCustomProgramDrawer={openCustomProgramDrawer}
        canCreateNewProgram={isAdmin}
      />
      {showCustomProgramAdminDrawer && (
        <CustomProgramAdminDrawer
          customProgramId={customProgramForEdit?.id}
          onClose={() => {
            setShowCustomProgramAdminDrawer(false);
            setCustomProgramForEdit(undefined);
          }}
          setCustomProgramForEdit={setCustomProgramForEdit}
          visible={showCustomProgramAdminDrawer}
          refreshParentData={refreshAndSelectLastProgram}
        />
      )}
    </>
  );
}

export default AddCustomProgramDrawerContainer;
