import * as React from 'react';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {GlobalOutlined, LockOutlined} from '@ant-design/icons';
import LearnInTag, {TagsContainer, TagStyles} from '@blocks/LearnInTag';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {EmptyBlockUntilTrue} from '@components/reusable/EmptyBlockUntilTrue';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import LearnInTable, {
  DurationCol,
  IColumn,
  TableColText,
} from '@components/reusable/LearnInTable';
import {FooterContainer} from '@components/reusable/Footer/Footer';
import {CustomProgramStatus} from '@generated/enums';
import {CustomProgramAdminDetailVM} from '@generated/interfaces';
import {SkillTag} from '@models/serverModels';
import {stringSorter} from '@utils/tableUtils';
import {Checkbox, Form, Input} from 'antd';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  margin: auto;
  margin-bottom: 75px;
  width: 80%;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FooterContent = styled.div`
  display: flex;
  margin: auto;
  width: 80%;
`;

const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 0;
`;

const ProgramsSelectedContainer = styled.div`
  margin: auto;
  margin-right: 16px;
`;

const ClearAllContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: auto;
  margin-left: 0;
`;

const StyledPermission = styled.div`
  font-size: 1rem;
`;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

const getPermissionTypeDisplay = (restrictedAccess: any) => {
  return !restrictedAccess ? (
    <>
      {' '}
      <GlobalOutlined aria-hidden="true" style={{marginRight: '6px'}} />
      {i18n.t(k.ACCESS__PUBLIC)}
    </>
  ) : (
    <>
      {' '}
      <LockOutlined aria-hidden="true" style={{marginRight: '6px'}} />
      {i18n.t(k.ACCESS__RESTRICTED)}
    </>
  );
};

/*
|--------------------------------------------------------------------------
| Reusable Table Rows
|--------------------------------------------------------------------------
*/

export const renderPermissionsColumn = (permissions: any) => {
  return (
    <StyledPermission>{getPermissionTypeDisplay(permissions)}</StyledPermission>
  );
};

export const renderStatusColumn = (status: any) => {
  const isPublished = status === CustomProgramStatus.Published;
  return (
    <LearnInTag
      label={
        isPublished ? i18n.t(k.ACCESS__PUBLISHED) : i18n.t(k.ACCESS__DRAFT)
      }
      tagStyle={isPublished ? TagStyles.Green : TagStyles.LightGrey}
    />
  );
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface AddCustomProgramDrawerProps {
  allSelected: boolean;
  newlySelected: number[];
  programSearchText: string;
  dataSource: CustomProgramAdminDetailVM[];
  onClose: () => void;
  addSteps: () => void;
  checkAll: () => void;
  onClickCheckbox: (id) => void;
  openCustomProgramDrawer: () => void;
  clearSelectedPrograms: () => void;
  setProgramSearchText: (value: React.SetStateAction<string>) => void;
  canCreateNewProgram: boolean;
}

function AddCustomProgramDrawer({
  allSelected,
  newlySelected,
  programSearchText,
  dataSource,
  onClose,
  addSteps,
  checkAll,
  onClickCheckbox,
  clearSelectedPrograms,
  setProgramSearchText,
  openCustomProgramDrawer,
  canCreateNewProgram,
}: AddCustomProgramDrawerProps) {
  const {
    isFeatureFlagOn: {DurationStrings},
  } = useFeatureFlags();
  const renderDrawerHeader = () => {
    return (
      <SearchContainer aria-label={i18n.t(k.PROGRAM__SEARCH)} role="search">
        <form style={{display: 'flex'}}>
          <fieldset>
            <Input.Search
              allowClear
              placeholder={i18n.t(k.PROGRAM__PLURAL__SEARCH)}
              onChange={(e) => setProgramSearchText(e.target.value)}
              style={{width: '270px', marginRight: '16px'}}
              value={programSearchText}
            />
          </fieldset>
        </form>
        {canCreateNewProgram && (
          <LearnInButton
            tag={ButtonTags.Secondary}
            onClick={openCustomProgramDrawer}>
            {i18n.t(k.PROGRAM__NEW)}
          </LearnInButton>
        )}
      </SearchContainer>
    );
  };
  const columns = React.useMemo(() => {
    return [
      {
        title: () => {
          return (
            <Checkbox
              checked={allSelected}
              onClick={checkAll}
              aria-label={i18n.t(k.CTA__CHECK_ALL)}
            />
          );
        },
        dataIndex: 'selected',
        key: 'selected',
        render: (selected, row) => {
          return (
            <Form.Item
              className="lb-application-drawer admin-program-list-checkbox"
              valuePropName="checked"
              style={{marginBottom: '0px'}}>
              <Checkbox
                checked={newlySelected?.includes(row.id)}
                onChange={() => onClickCheckbox(row.id)}
                aria-label={i18n.t(k.CTA__CHECK_ITEM)}
              />
            </Form.Item>
          );
        },
      },
      {
        title: i18n.t(k.PROGRAM__NAME),
        dataIndex: 'title',
        key: 'title',
        render: (programTitle: any, {id}) => (
          <TableColText data-testid={`table-cell-title-${id}`}>
            {programTitle}
          </TableColText>
        ),
        sorter: stringSorter<any>('title'),
      },
      {
        title: i18n.t(k.TAG__PLURAL),
        dataIndex: 'skills',
        key: 'skills',
        render: (skills: SkillTag[], {id}) => {
          return (
            <TagsContainer
              tags={skills?.map(({name}) => name)}
              getTagDataTestId={(tag) => `skill-tag-${id}-${tag}`}
            />
          );
        },
      },
      DurationCol(DurationStrings),
      {
        title: i18n.t(k.PERMISSION__PLURAL),
        dataIndex: 'restrictedAccess',
        key: 'restrictedAccess',
        render: renderPermissionsColumn,
      },
    ] as IColumn[];
  }, [newlySelected]);

  return (
    <LearnInPageDrawer
      visible={true}
      onClose={onClose}
      headerContent={renderDrawerHeader()}
      title={i18n.t(k.PROGRAM__CUSTOM__SELECT)}>
      <EmptyBlockUntilTrue
        condition={dataSource.length > 0}
        emptyHeader={i18n.t(k.PROGRAM__CUSTOM__LIST__EMPTY__TITLE)}
        emptyBody={i18n.t(k.PROGRAM__CUSTOM__LIST__EMPTY__DESCRIPTION)}>
        <Container>
          <LearnInTable
            dataSource={dataSource}
            columns={columns}
            isLoading={false}
            noSearchResults={false}
            pagination={true}
          />
        </Container>
      </EmptyBlockUntilTrue>

      {newlySelected.length > 0 && (
        <FooterContainer left={0}>
          <FooterContent>
            <ClearAllContainer>
              <button className="pseudo-link" onClick={clearSelectedPrograms}>
                {i18n.t(k.CTA__CLEAR_ALL)}
              </button>
            </ClearAllContainer>
            <FooterButtonContainer>
              <ProgramsSelectedContainer>
                {
                  <span
                    dangerouslySetInnerHTML={{
                      __html: i18n.t(
                        newlySelected.length === 1
                          ? k.PROGRAM__SELECTED__SINGLE
                          : k.PROGRAM__SELECTED__PLURAL__FORMAT,
                        {
                          number: newlySelected.length,
                        }
                      ),
                    }}></span>
                }
              </ProgramsSelectedContainer>
              <LearnInButton tag={ButtonTags.Primary} onClick={addSteps}>
                {false
                  ? i18n.t(k.STATUS__ADDING)
                  : i18n.t(k.PROGRAM__ADD_TO_SECTION)}
              </LearnInButton>
            </FooterButtonContainer>
          </FooterContent>
        </FooterContainer>
      )}
    </LearnInPageDrawer>
  );
}

export default AddCustomProgramDrawer;
