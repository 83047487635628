import {LearningResourceType, UserResourceStatus} from './../enums';
import {UserPlanResourceVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserPlanResourceVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanResourceVMMockData(
  args?: Partial<UserPlanResourceVM>
): UserPlanResourceVM {
  return {
    additionalRequestedAmount: faker.datatype.number(),
    approvedAmount: faker.datatype.number(),
    dateRequested: String(faker.date.past()), // optional
    daysUntilExpiration: faker.datatype.number(), // optional
    id: faker.datatype.number(),
    purchaseMade: faker.datatype.boolean(), // optional
    reimbursementApprovedAmount: faker.datatype.number(), // optional
    reimbursementRequestedAmount: faker.datatype.number(), // optional
    resourceType: LearningResourceType.Book,
    status: UserResourceStatus.Pending,
    title: faker.company.catchPhrase(),
    url: faker.internet.url(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanResourceVMMockData: UserPlanResourceVM = {
  additionalRequestedAmount: 5,
  approvedAmount: 5,
  dateRequested: '2023-03-29T22:17:19.738072', // optional
  daysUntilExpiration: 123, // optional
  id: 1,
  purchaseMade: true, // optional
  reimbursementApprovedAmount: 5, // optional
  reimbursementRequestedAmount: 5, // optional
  resourceType: LearningResourceType.Book,
  status: UserResourceStatus.Pending,
  title: 'Advanced Web Design',
  url: 'https://this-person-does-not-exist.com/en',
};
