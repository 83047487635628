import {UserProgramStatus} from './../enums';
import {AcademyProgramStatusUpdate} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AcademyProgramStatusUpdate

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyProgramStatusUpdateMockData(
  args?: Partial<AcademyProgramStatusUpdate>
): AcademyProgramStatusUpdate {
  return {
    contentId: faker.datatype.number(),
    status: UserProgramStatus.Planned,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyProgramStatusUpdateMockData: AcademyProgramStatusUpdate = {
  contentId: 1,
  status: UserProgramStatus.Planned,
};
