import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {StepsInterface} from '@components/providers/stepsProgress/StepsProgress';
import {InitiativeStatus} from '@generated/enums';
import {Button} from 'antd';
import {DeleteModal} from './DeleteModal';
import ContextMenuButton from '@blocks/ContextMenu';

interface Props {
  isNewInitiative?: boolean;
  initiativeStatus?: InitiativeStatus;
  onUnpublishedClicked?: () => void;
  onPublishedClicked?: () => void;
  onDeleteInitiativeClicked?: () => void;
  onNextStepClicked?: () => void;
  currentStep: number;
  steps: StepsInterface[];
  isDirty?: boolean;
}

export default function NewInitiativeHeader({
  isNewInitiative,
  initiativeStatus,
  onUnpublishedClicked,
  onPublishedClicked,
  onDeleteInitiativeClicked,
  onNextStepClicked,
  currentStep,
  steps,
  isDirty,
}: Props) {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const handleOkDeleteModal = () => {
    setDeleteModalVisible(false);
    onDeleteInitiativeClicked?.call(this);
  };
  const handleCancelDeleteModal = () => {
    setDeleteModalVisible(false);
  };

  const showPublishButton =
    (isNewInitiative && currentStep >= steps.length - 1) ||
    (initiativeStatus === InitiativeStatus.Published && isDirty) ||
    (!isNewInitiative && initiativeStatus === InitiativeStatus.Draft);

  const SecondaryOptionDropdown = (
    <div style={{display: 'inline-block'}}>
      <ContextMenuButton
        menuItems={[
          initiativeStatus === InitiativeStatus.Published && {
            label: i18n.t(k.INITIATIVE__UNPUBLISH),
            onClick: () => onUnpublishedClicked?.call(this),
          },

          {
            label: i18n.t(k.INITIATIVE__DELETE),
            onClick: () => setDeleteModalVisible(true),
          },
        ]}
        size={'large'}
      />
    </div>
  );

  return (
    <>
      <div>
        {!isNewInitiative && SecondaryOptionDropdown}
        {currentStep < steps.length - 1 && (
          <>
            <Button
              className="btn-secondary admin-initiatives-filled-btn"
              shape="round"
              onClick={() => {
                onNextStepClicked?.call(this);
              }}>
              {i18n.t(k.CTA__NEXT_STEP).toLocaleUpperCase()}
            </Button>
          </>
        )}

        {showPublishButton && (
          <Button
            className="btn btn-primary admin-initiatives-filled-btn"
            shape="round"
            onClick={() => {
              onPublishedClicked?.call(this);
            }}>
            {!isNewInitiative && initiativeStatus === InitiativeStatus.Published
              ? i18n.t(k.CTA__SAVE_CHANGES).toLocaleUpperCase()
              : i18n.t(k.INITIATIVE__PUBLISH).toLocaleUpperCase()}
          </Button>
        )}
      </div>
      <DeleteModal
        isVisible={deleteModalVisible}
        onOkClicked={handleOkDeleteModal}
        OnCancelClicked={handleCancelDeleteModal}
      />
    </>
  );
}
