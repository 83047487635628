import {AcademyLevelVM} from './../interfaces';
import {
  AcademyStepVMMockData,
  getAcademyStepVMMockData,
} from './AcademyStepVM.mock';
import {
  IAcademyLevelBasicInfoMockData,
  getIAcademyLevelBasicInfoMockData,
} from './IAcademyLevelBasicInfo.mock';
import {
  UserItemStatusVMMockData,
  getUserItemStatusVMMockData,
} from './UserItemStatusVM.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyLevelVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyLevelVMMockData(
  args?: Partial<AcademyLevelVM>
): AcademyLevelVM {
  return {
    ...getIAcademyLevelBasicInfoMockData(),
    academySteps: [getAcademyStepVMMockData()],
    description: faker.lorem.lines(),
    id: faker.datatype.number(),
    order: faker.datatype.number(),
    requiredId: faker.datatype.number(), // optional
    status: getUserItemStatusVMMockData(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyLevelVMMockData: AcademyLevelVM = {
  ...IAcademyLevelBasicInfoMockData,
  academySteps: [AcademyStepVMMockData],
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  id: 1,
  order: 123,
  requiredId: 1, // optional
  status: UserItemStatusVMMockData,
  title: 'Advanced Web Design',
};
