import {LearnInContainer} from '@components/reusable/Container/Container';
import {Breakpoints} from '@utils/responsiveUtils';
import {Col} from 'antd';
import styled from 'styled-components';

export const StyledLearnInContainer = styled(LearnInContainer)`
  @media (max-width: ${Breakpoints.Mobile}px) {
    .ant-table-wrapper {
      max-width: unset;
      width: max-content;
    }
  }
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

export const CenterView = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
