import React from 'react';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {MailOutlined} from '@ant-design/icons';
import t from '@i18n/translate';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface BulkNudgeButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

function BulkNudgeButton({onClick, style}: BulkNudgeButtonProps) {
  return (
    <LearnInButton tag={ButtonTags.Secondary} onClick={onClick} style={style}>
      <MailOutlined style={{marginRight: 6}} aria-hidden="true" />
      {t('CTA__BULK_NUDGE')}
    </LearnInButton>
  );
}

export default BulkNudgeButton;
