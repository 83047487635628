import {RemoveSkillTagPayload} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {RemoveSkillTagPayloadSchema} from '../zodSchemas/RemoveSkillTagPayloadSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/SkillsController.cs

/*
|--------------------------------------------------------------------------
|  delete 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteSkillsMutation} from '@generated/hooks';
 *
 *  const delete = useDeleteSkillsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  delete.mutate({
 *    payload: {
 *      ...RemoveSkillTagPayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useDeleteSkillsMutation = mutationHookFactory<
  RemoveSkillTagPayload, // payload
  null,
  boolean // return type
>('useDeleteSkillsMutation', 'delete', () => `skills/delete`, {
  payloadSchema: RemoveSkillTagPayloadSchema,
});
