import {i18n, k} from '@i18n/translate';
import {LearnInProgressBar} from '@components/reusable/Progress/Progress.style';
import {ProgressTags} from '@components/reusable/Progress/ProgressEnums';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {
  HEADER_HEIGHT,
  NAV_PANEL_WIDTH,
} from '@components/reusable/LearnInPageDrawer';
import {CustomProgramSectionPreviewVM} from '@generated/interfaces';
import {SectionCompleteToggle} from './SectionCompleteToggle';
import {MembersPreviewContainer} from './MembersPreview';
import {Breakpoints} from '@utils/responsiveUtils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const SectionNavContainer = styled.nav<{showReportsNav: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  bottom: 0;
  left: ${({showReportsNav}) => (showReportsNav ? '96px' : '0')};
  width: ${NAV_PANEL_WIDTH}px;
  background: white;
  z-index: 99;
  border-right: 1px solid ${COLORS.Neutral200};
  @media (max-width: ${Breakpoints.Tablet}px) {
    display: none;
  }
`;

export const SectionNavItemButton = styled.button<{active: boolean}>`
  font-weight: ${({active}) => (active ? '500' : '400')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
  box-sizing: border-box;
  background: ${({active}) => (active ? COLORS.Blue50 : 'white')};
  width: 100%;
  &:hover {
    background: ${({active}) => (active ? COLORS.Blue50 : COLORS.Neutral50)};
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

export const ProgressContainer = styled.div`
  padding: 16px 16px 30px;
  border-top: 1px solid ${COLORS.Neutral200};
`;

export const NavSectionTitle = styled.div`
  font-weight: bold;
  font-size: 0.75rem;
  color: ${COLORS.Neutral900};
  margin-bottom: 12px;
`;

/*
|--------------------------------------------------------------------------
| Section Nav Item
|--------------------------------------------------------------------------
*/

export interface SectionNavItemProps {
  id: number;
  order: number;
  title: string;
  onClick: () => void;
  active: boolean;
  selectedSectionProgress: number;
}

export function SectionNavItem({
  order,
  title,
  onClick,
  active,
  selectedSectionProgress,
}: SectionNavItemProps) {
  return (
    <SectionNavItemButton onClick={onClick} active={active}>
      {order}. {title}
      <SectionCompleteToggle checked={selectedSectionProgress === 100} />
    </SectionNavItemButton>
  );
}

/*
|--------------------------------------------------------------------------
| Section Nav Items Container
|--------------------------------------------------------------------------
*/
interface NavItem {
  label: string;
  onClick: () => void;
}

export interface ISectionNavProps {
  allSections: CustomProgramSectionPreviewVM[];
  progressPercentage: number;
  sectionNavItems: SectionNavItemProps[] | unknown[];
  showReportsNav: boolean;
  navItems?: NavItem[] | unknown[];
  onClickCurriculum?: () => void;
  customProgramId: number;
}

function SectionNav({
  allSections,
  progressPercentage,
  sectionNavItems,
  showReportsNav,
  customProgramId,
}: ISectionNavProps) {
  return (
    <SectionNavContainer showReportsNav={showReportsNav}>
      <div>
        {sectionNavItems?.map((section, i) => {
          return (
            <SectionNavItem
              selectedSectionProgress={allSections[i]?.progressPercentage}
              key={section.id}
              {...section}
            />
          );
        })}
      </div>
      <ProgressContainer>
        <MembersPreviewContainer
          customProgramId={customProgramId}
          title={
            <NavSectionTitle>{i18n.t(k.COHORT__YOUR_COHORT)}</NavSectionTitle>
          }
        />
        <NavSectionTitle>{i18n.t(k.PROGRESS__YOUR_PROGRESS)}</NavSectionTitle>
        <LearnInProgressBar
          percent={progressPercentage}
          tag={
            progressPercentage === 100
              ? ProgressTags.COMPLETED
              : ProgressTags.PRIMARY
          }
        />
      </ProgressContainer>
    </SectionNavContainer>
  );
}

export default SectionNav;
