import {CharLimits, getFormFieldErrors} from '../AddEditContent.config';
import {validate} from './utils';
import {stripHtmlTags} from '@utils/stringUtils';

/**
 * Checks for rich text image uploads in progress
 */
export function fileUploadInFlight(_: unknown, value: string) {
  return validate(
    !value.includes('data:image'),
    getFormFieldErrors().FileUploadInFlight
  );
}

/**
 * Checks that the character count after removing all the HTML tags
 * from the string is not 0.
 * However the check will pass if there's an image tag
 */
export function noEmptyHTML(_: unknown, htmlString: string) {
  const cleanText = stripHtmlTags(htmlString).trim();

  return validate(
    cleanText.length > 0 || htmlString.toLowerCase().includes('img'),
    getFormFieldErrors().MissingDescription
  );
}

/**
 * Checks that the length of the content does not exceed the limit.
 * This check is skipped during rich text image upload
 */
export function noCharLimitExceeded(_: unknown, htmlString: string) {
  const cleanText = stripHtmlTags(htmlString).trim();

  if (!htmlString.includes('data:image')) {
    return validate(
      cleanText.length < CharLimits.Description,
      getFormFieldErrors().CharLimitDescription
    );
  }

  return Promise.resolve();
}
