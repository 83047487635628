import {DurationUnitType, TimeUnitType} from './../enums';
import {TimeDetailsVM} from './../interfaces';
import {DetailsVMMockData, getDetailsVMMockData} from './DetailsVM.mock';
import {faker} from '@faker-js/faker';

// Interface: TimeDetailsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getTimeDetailsVMMockData(
  args?: Partial<TimeDetailsVM>
): TimeDetailsVM {
  return {
    ...getDetailsVMMockData(),
    perUnit: faker.datatype.number(),
    perUnitType: DurationUnitType.Days,
    unit: faker.datatype.number(),
    unitType: TimeUnitType.Hours,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const TimeDetailsVMMockData: TimeDetailsVM = {
  ...DetailsVMMockData,
  perUnit: 123,
  perUnitType: DurationUnitType.Days,
  unit: 123,
  unitType: TimeUnitType.Hours,
};
