import {ApprovalConfig} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ApprovalConfig

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getApprovalConfigMockData(
  args?: Partial<ApprovalConfig>
): ApprovalConfig {
  return {
    adminPreApprovalRequired: faker.datatype.boolean(),
    adminRedemptionApprovalRequired: faker.datatype.boolean(),
    managerPreApprovalRequired: faker.datatype.boolean(),
    managerRedemptionApprovalRequired: faker.datatype.boolean(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ApprovalConfigMockData: ApprovalConfig = {
  adminPreApprovalRequired: true,
  adminRedemptionApprovalRequired: true,
  managerPreApprovalRequired: true,
  managerRedemptionApprovalRequired: true,
};
