import {ReportsBySectionDetailsTableContainerProps} from './ReportsBySectionDetailsTable.interfaces';
import ReportsBySectionDetailsTableView from './ReportsBySectionDetailsTable.view';
import {
  useAcademyOverviewLevelUserReportQuery,
  useAcademyOverviewStepUserReportQuery,
} from '@generated/hooks';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import Progressbar from '@components/learnin/ProgressBar';
import {AcademyStepType, CustomizableLearningCategory} from '@generated/enums';
import {UserItemStatusTag} from '@blocks/LearnInTag';
import {
  AcademyOverviewLevelUserCompletionReportVm,
  AcademyOverviewStepUserCompletionReportVm,
  UserItemStatusVM,
} from '@generated/interfaces';
import translate from '@i18n/translate';
import {
  basicSorter,
  basicSorterNestedKey,
  stringSorter,
} from '@utils/tableUtils';
import useLocalSearch from '@hooks/useLocalSearch';
import NudgeEmailPreview, {Feature} from '@blocks/NudgeEmailPreview';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import useNudgeEmail from '@blocks/NudgeEmailPreview/useNudgeEmail';
import BulkNudgeDrawer from '@blocks/BulkNudgeDrawer';
import {
  TooltipCol,
  dateTimeSorter,
  renderDateTime,
} from '@components/reusable/LearnInTable';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  AcademyReportsPageTabs,
  AcademyReportsSectionPaths,
  AcademyScreens,
  DrawerRoutes,
  UserPaths,
  viewAcademyUrl,
} from '@utils/ClientPaths';

function ReportsBySectionDetailsTableContainer(
  props: ReportsBySectionDetailsTableContainerProps
) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {academyId, academyLevelId, academyStepId} = useParams();
  const basePath = [
    UserPaths.ViewAcademies,
    academyId,
    AcademyScreens.reports,
    AcademyReportsPageTabs.Sections,
    AcademyReportsSectionPaths.Step,
    academyStepId,
  ].join('/');
  const navigate = useNavigate();

  const {
    data: academyOverviewLevelUserReport,
    isLoading: isLevelReportLoading,
  } = useAcademyOverviewLevelUserReportQuery(
    {
      academyLevelId: parseInt(academyLevelId),
    },
    {
      enabled: !!academyLevelId,
    }
  );

  const {data: academyOverviewStepUserReport, isLoading: isStepReportLoading} =
    useAcademyOverviewStepUserReportQuery(
      {
        academyStepId: parseInt(academyStepId),
      },
      {
        enabled: !!academyStepId,
      }
    );

  const dataSource = (() => {
    if (!!academyLevelId) {
      return academyOverviewLevelUserReport?.users?.map((user) => ({
        ...user,
        id: user?.userCompanyId,
      }));
    }

    if (!!academyStepId) {
      return academyOverviewStepUserReport?.users?.map((user) => ({
        ...user,
        id: user?.userCompanyId,
      }));
    }
  })();

  const {filteredData, searchFilter, setSearchFilter, getNoDataTableProps} =
    useLocalSearch<
      (
        | AcademyOverviewLevelUserCompletionReportVm
        | AcademyOverviewStepUserCompletionReportVm
      ) & {id: number}
    >(dataSource, 'name');

  const {drawerProps, nudgeEmailsEnabled, onOpenNudgeEmailPreview} =
    useNudgeEmail({
      feature: Feature.Academy,
      route: {
        basePath,
      },
      participants: filteredData,
      stepId: academyStepId !== undefined && parseInt(academyStepId),
    });

  const columns = (() => {
    const columnTitle = `${translate('EMPLOYEE')} ${translate(
      'GENERIC__NAME'
    )}`;
    if (!!academyLevelId) {
      return [
        isFeatureFlagOn.ShowEmployeeEmail
          ? TooltipCol({
              title: columnTitle,
              width: 360,
              tooltipDataIndex: 'email',
            })
          : {
              title: columnTitle,
              key: 'name',
              dataIndex: 'name',
              sorter: stringSorter('name'),
            },
        {
          title: translate('PROGRESS'),
          key: 'completionPercentage',
          render: (progress: number) => <Progressbar percent={progress} />,
          sorter: basicSorter('completionPercentage'),
        },
      ].filter(Boolean);
    }

    if (!!academyStepId) {
      const progressColTitle = (() => {
        switch (academyOverviewStepUserReport?.academyStepType) {
          case AcademyStepType.CustomProgram:
            return translate('PROGRESS');
          case AcademyStepType.MarketplaceProgram:
          case AcademyStepType.TextContent:
          case AcademyStepType.ProjectContent:
            return translate('STATUS__STATUS');
          case AcademyStepType.EventContent:
            return translate('STATUS__COMPLETED');
        }
      })();

      return [
        isFeatureFlagOn.ShowEmployeeEmail
          ? TooltipCol({
              title: columnTitle,
              width: 340,
              tooltipDataIndex: 'email',
            })
          : {
              title: columnTitle,
              key: 'name',
              dataIndex: 'name',
              sorter: stringSorter('name'),
            },
        {
          title: progressColTitle,
          key: 'status',
          render: (status: UserItemStatusVM) => (
            <div style={{marginRight: '16px'}}>
              <UserItemStatusTag status={status} />
            </div>
          ),
          sorter: basicSorterNestedKey('status.displayStatus'),
        },
        nudgeEmailsEnabled && {
          title: translate('GENERIC__LAST_NUDGE_SENT'),
          dataIndex: 'lastNudgeSent',
          key: 'lastNudgeSent',
          sorter: dateTimeSorter('lastNudgeSent'),
          render: (date: string) => {
            return date ? renderDateTime()(date) : '-';
          },
        },
        nudgeEmailsEnabled && {
          title: '',
          key: null,
          render: (data) => {
            return (
              <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <LearnInButton
                  tag={ButtonTags.SecondaryRemovedBorder}
                  style={{fontWeight: 400}}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    onOpenNudgeEmailPreview(data);
                  }}>
                  {translate('CTA__SEND_NUDGE')}
                </LearnInButton>
              </div>
            );
          },
        },
      ].filter(Boolean);
    }
  })();

  const dataPanelItems = (() => {
    if (!!academyLevelId) {
      return [
        {
          label: translate('PROGRESS'),
          display: (
            <Progressbar
              percent={academyOverviewLevelUserReport?.completionPercentage}
            />
          ),
        },
        {
          label: translate('PROGRESS__TOTAL_COMPLETED'),
          display: academyOverviewLevelUserReport?.totalCompleted,
        },
        {
          label: translate('PROGRESS__TOTAL_OUTSTANDING'),
          display: academyOverviewLevelUserReport?.totalOutstanding,
        },
      ];
    }
    if (!!academyStepId) {
      switch (academyOverviewStepUserReport?.academyStepType) {
        case AcademyStepType.EventContent:
          return [
            {
              label: translate('EVENT__RSVP_YES'),
              display: academyOverviewStepUserReport?.totalRsvpYes,
            },
            {
              label: translate('EVENT__RSVP_NO'),
              display: academyOverviewStepUserReport?.totalRsvpNo,
            },
            {
              label: translate('EVENT__RSVP_DID_NOT'),
              display: academyOverviewStepUserReport?.totalOutstanding,
            },
          ];
      }
      return [
        {
          label: translate('PROGRESS'),
          display: (
            <Progressbar
              percent={academyOverviewStepUserReport?.completionPercentage}
            />
          ),
        },
        {
          label: translate('PROGRESS__TOTAL_COMPLETED'),
          display: academyOverviewStepUserReport?.totalCompleted,
        },
        {
          label: translate('PROGRESS__TOTAL_OUTSTANDING'),
          display: academyOverviewStepUserReport?.totalOutstanding,
        },
      ];
    }
  })();

  const handleCloseBulkNudgeDrawer = () => {
    navigate(
      [
        viewAcademyUrl(academyId, AcademyScreens.reports),
        AcademyReportsPageTabs.Sections,
        AcademyReportsSectionPaths.Step,
        academyStepId,
      ].join('/')
    );
  };

  const handleClickBulkNudge = () => {
    navigate(DrawerRoutes.BulkNudge);
  };

  return (
    <>
      <ReportsBySectionDetailsTableView
        columns={columns}
        dataSource={filteredData}
        dataPanelItems={dataPanelItems}
        isLoading={isLevelReportLoading || isStepReportLoading}
        onChangeSearchInput={setSearchFilter}
        searchInputValue={searchFilter}
        noDataTableProps={getNoDataTableProps()}
        showBulkNudgeButton={props.showBulkNudgeButton}
        onClickBulkNudge={handleClickBulkNudge}
      />
      <Routes>
        <Route
          path="participants/:userId/nudge"
          element={<NudgeEmailPreview {...drawerProps} />}
        />
        <Route
          path={DrawerRoutes.BulkNudge}
          element={
            <BulkNudgeDrawer
              customizableLearningCategory={
                CustomizableLearningCategory.Academy
              }
              visible={true}
              onClose={handleCloseBulkNudgeDrawer}
              name={drawerProps.programOrAcademyName}
              feature={Feature.Academy}
            />
          }
        />
      </Routes>
    </>
  );
}

export default ReportsBySectionDetailsTableContainer;
