import {useState, useEffect} from 'react';
import styled from 'styled-components';
import {DndProvider as DragAndDropProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import ProgramCurriculumCard from '@components/admin/pages/programs/customPrograms/curriculum/ProgramCurriculumCard.container';
import CurriculumScreenHeader from '@components/admin/pages/programs/customPrograms/curriculum/CurriculumScreenHeader.container';
import NoContentScreen from '@components/admin/pages/programs/customPrograms/curriculum/NoContentScreen.container';
import CurriculumScreenNav from '@components/admin/pages/programs/customPrograms/curriculum/CurriculumScreenNav.container';
import {useCustomProgramSectionsQuery} from '@generated/hooks';
import UpsertCustomContentModal from '@components/admin/pages/programs/customPrograms/curriculum/UpsertCustomContentModal.container';
import {ContentType} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const HeaderAndCardContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0px auto 32px;
`;

export const LabelAndIconContainer = styled.div<{marginBottom: string}>`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin-bottom: ${({marginBottom}) => marginBottom};
`;

export const LabelSubContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PROGRAMS_ICON_SIZE = 32;
export const ProgramsIconContainer = styled.div<{
  color: string;
  background: string;
}>`
  width: ${PROGRAMS_ICON_SIZE}px;
  height: ${PROGRAMS_ICON_SIZE}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 16px;
  background-color: ${({background}) => background};
  color: ${({color}) => color};
  & :first-child {
    margin: 3px;
    font-size: 1.1875rem;
  }
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

export interface ICurriculumScreenContainerProps {
  customProgramId: number;
  updateModalBackground: (white: boolean) => void;
}

function CurriculumScreenContainer({
  customProgramId,
  updateModalBackground,
}: ICurriculumScreenContainerProps) {
  const {data: customProgramSections} = useCustomProgramSectionsQuery({
    customProgramId,
  });

  const [selectedSectionId, setSelectedSectionId] = useState<
    undefined | number
  >(customProgramSections?.[0]?.id);
  const [contentReorderIdx, setContentReorderIdx] = useState<null | number>(
    null
  );
  const [isNewSection, setIsNewSection] = useState(false);
  const selectedSection =
    customProgramSections?.find(({id}) => {
      return selectedSectionId === id;
    }) || customProgramSections?.[0];

  const selectedSectionContent = selectedSection?.content || [];

  const [isDraggingContentOrder, setIsDraggingContentOrder] = useState<
    null | number
  >(null);

  // This is needed to handle the edge case of an admin deleting the last section, which unselects all sections
  const _selectedSectionId = selectedSectionId || selectedSection?.id;

  const showNoContentScreen = !selectedSectionContent?.length;
  const showFullPageForm =
    selectedSectionContent?.length === 1 &&
    selectedSectionContent[0].contentType !== ContentType.Discussion &&
    selectedSection.isFullScreen;

  useEffect(() => {
    updateModalBackground(showFullPageForm);
  }, [showFullPageForm, updateModalBackground]);

  const showContentCards =
    !!selectedSectionContent?.length && !selectedSection.isFullScreen;

  const showFullPageToggle =
    selectedSectionContent?.length === 1 &&
    selectedSectionContent[0].contentType !== ContentType.Discussion;

  const fullScreenContent = selectedSectionContent?.[0];

  return (
    <>
      <CurriculumScreenNav
        customProgramId={customProgramId}
        setSelectedSectionId={setSelectedSectionId}
        selectedSectionId={_selectedSectionId}
        setIsNewSection={setIsNewSection}
      />
      <HeaderAndCardContainer>
        <CurriculumScreenHeader
          key={_selectedSectionId}
          customProgramId={customProgramId}
          selectedSectionId={_selectedSectionId}
          setSelectedSectionId={setSelectedSectionId}
          isNewSection={isNewSection}
          setIsNewSection={setIsNewSection}
          showFullPageToggle={showFullPageToggle}
          showAddContentButton={showContentCards}
          showFullPageForm={showFullPageForm}
        />

        {/* NO CONTENT */}
        {showNoContentScreen && (
          <NoContentScreen
            sectionId={_selectedSectionId}
            customProgramId={customProgramId}
          />
        )}

        {/* FULL PAGE FORM */}
        {showFullPageForm && (
          <>
            <UpsertCustomContentModal
              noModal
              contentType={fullScreenContent?.contentType}
              visible
              customProgramId={customProgramId}
              description={fullScreenContent?.description}
              dueDateUtc={fullScreenContent?.dueDateUtc}
              dueDateTimeZone={fullScreenContent?.dueDateTimeZone}
              eventEndDateTimeUtc={fullScreenContent?.endDateUtc}
              eventLink={fullScreenContent?.eventLink}
              eventStartDateTimeUtc={fullScreenContent?.startDateUtc}
              eventTimeZone={fullScreenContent?.eventTimeZone}
              id={fullScreenContent?.id}
              onCancel={() => null}
              sectionId={_selectedSectionId}
              submissionType={fullScreenContent?.submissionType}
              title={fullScreenContent?.title}
              allowPeerVisibility={fullScreenContent?.allowPeerVisibility}
            />
          </>
        )}

        {/* CONTENT CARDS, click to edit */}
        {showContentCards && (
          <DragAndDropProvider backend={HTML5Backend} context={window}>
            {selectedSectionContent
              ?.sort((a, b) => a.order - b.order)
              .map((content, idx) => {
                return (
                  <ProgramCurriculumCard
                    key={`${content.id}-pcc-container`}
                    idx={idx}
                    allowPeerVisibility={content.allowPeerVisibility}
                    dueDateUtc={content.dueDateUtc}
                    dueDateTimeZone={content.dueDateTimeZone}
                    cardCount={selectedSectionContent?.length}
                    customProgramId={customProgramId}
                    contentType={content.contentType}
                    submissionType={content.submissionType}
                    eventLink={content.eventLink}
                    isSubmissionTypeEditable={content.isSubmissionTypeEditable}
                    eventStartDateTimeUtc={
                      content.startDateUtc || content.dueDateUtc
                    }
                    eventEndDateTimeUtc={content.endDateUtc}
                    eventTimeZone={content.eventTimeZone}
                    id={content.id}
                    order={content.order}
                    title={content.title}
                    description={content.description}
                    contentReorderIdx={contentReorderIdx}
                    setContentReorderIdx={() => {
                      if (content.order !== contentReorderIdx) {
                        setContentReorderIdx(content.order);
                      }
                    }}
                    setIsDraggingContentOrder={() => {
                      if (isDraggingContentOrder !== content.order) {
                        setIsDraggingContentOrder(content.order);
                      }
                    }}
                    isDraggingContentOrder={isDraggingContentOrder}
                    sections={customProgramSections
                      ?.filter(({id}) => id !== _selectedSectionId)
                      .map(({id, title}) => ({id, title}))}
                    attachmentGuids={content.attachmentGuids}
                  />
                );
              })}
          </DragAndDropProvider>
        )}
      </HeaderAndCardContainer>
    </>
  );
}

export default CurriculumScreenContainer;
