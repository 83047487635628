import React from 'react';
import {Container} from './TitleDescription.styled';
import {TitleDescriptionViewProps} from './TitleDescription.interfaces';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {QuillHtmlViewer} from '@components/admin/pages/approvals/components/QuillHtmlViewer';

function TitleDescriptionView({
  displayTitle,
  displayDescription,
  direction,
}: TitleDescriptionViewProps) {
  return (
    <Container direction={direction}>
      <LearnInText
        tag={TextTags.LearnInPersonalBudgetHeader}
        data-testid="plb-title">
        {displayTitle}
      </LearnInText>
      <div data-testid="plb-description">
        <QuillHtmlViewer html={displayDescription} />
      </div>
    </Container>
  );
}

export default TitleDescriptionView;
