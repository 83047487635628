import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {CALENDAR_CONNECT} from '@assets/cloudinary-urls';
import GoogleCalendarIcon from '@assets/vendors/gcal.png';
import OutlookCalendarIcon from '@assets/vendors/outlook.png';
import {CalendarProvider} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const ModalContainer = styled.div`
  background-image: url(${CALENDAR_CONNECT});
  background-repeat: no-repeat;
  background-size: contain;
  font-size: 1rem;
  padding: 156px 24px 24px;
  text-align: center;
`;

const ModalDescription = styled.p`
  line-height: 1.25rem;
  margin-bottom: 16px;
  text-align: center;
`;

const ModalProductIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 9px;
`;

const ModalList = styled.ul`
  display: inline-block;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  li {
    display: inline-block;
  }
`;

interface IConnectCalendarModalProps {
  visible: boolean;
  id?: number;
  title?: string;
  description?: string;
  isGoogleCalendarOn: boolean;
  onCloseCalendarModal: () => void;
  onClickConnectCalendar: (
    calendarProvider: CalendarProvider,
    id?: number
  ) => void;
}

export function ConnectCalendarModal({
  visible,
  id,
  title = i18n.t(k.CALENDAR__CONNECT),
  description = i18n.t(k.CALENDAR__ADD_PROMPT),
  isGoogleCalendarOn,
  onCloseCalendarModal,
  onClickConnectCalendar,
}: IConnectCalendarModalProps) {
  return (
    <LearnInModal
      width={400}
      visible={visible}
      tag={ModalTags.ConnectCalendar}
      buttonDisabled={true}
      closable={true}
      maskClosable={true}
      onCancel={onCloseCalendarModal}
      footer={null}
      title={title}>
      <ModalContainer>
        <ModalDescription>{description}</ModalDescription>
        <ModalList>
          {isGoogleCalendarOn && (
            <li>
              <LearnInButton
                tag={ButtonTags.Link}
                color={COLORS.Blue800}
                style={{padding: '8px'}}
                onClick={() =>
                  onClickConnectCalendar(CalendarProvider.Google, id)
                }>
                <ModalProductIcon
                  src={GoogleCalendarIcon}
                  alt={i18n.t(k.A11Y__LOGO_OF__FORMAT, {
                    company: i18n.t(k.CALENDAR__GOOGLE),
                  })}
                />
                {i18n.t(k.CALENDAR__CONNECT_CALENDAR__FORMAT, {
                  calendar: i18n.t(k.CALENDAR__GOOGLE),
                })}
              </LearnInButton>
            </li>
          )}

          <li>
            <LearnInButton
              tag={ButtonTags.Link}
              color={COLORS.Blue800}
              style={{padding: '8px'}}
              onClick={() =>
                onClickConnectCalendar(CalendarProvider.Outlook, id)
              }>
              <ModalProductIcon
                src={OutlookCalendarIcon}
                alt={i18n.t(k.A11Y__LOGO_OF__FORMAT, {
                  company: i18n.t(k.CALENDAR__OUTLOOK),
                })}
              />
              {i18n.t(k.CALENDAR__CONNECT_CALENDAR__FORMAT, {
                calendar: i18n.t(k.CALENDAR__OUTLOOK),
              })}
            </LearnInButton>
          </li>
        </ModalList>
      </ModalContainer>
    </LearnInModal>
  );
}
