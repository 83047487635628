import {forwardRef, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Col, Form, Row} from 'antd';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {FormInstance} from 'antd/lib/form';
import LearnInAutocomplete from '@components/reusable/Autocomplete/Autocomplete';
import {
  LearnInInput,
  LearnInMoneyInput,
  LearnInNumberInput,
} from '@components/reusable/Input/Input.style';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {
  DurationUnitType,
  PresenceType,
  TimeCommitmentType,
} from './store/interface';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import LearnInDrawer, {
  FooterButtonsContainer,
} from '@components/reusable/LearnInDrawer';
import {AddNewProgram} from '@store/apiEndpoints';
import {ProgramTypesVM} from '@components/admin/pages/investments/components/incentives/store/interfaces';
import {CurrencyCode} from '@generated/enums';
import {CurrencyCodeString} from '@models/clientEnums';
import {UserDetailsVM} from '@generated/interfaces';
import {currencyOptions, getCurrencyEnum} from '@utils/moneyUtils';
import styled from 'styled-components';
import {localizeGeneric} from '@utils/l10nUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';

export const CurrencySelect = styled(LearnInSelect)<{rowGutter?: number}>`
  margin-top: ${({rowGutter}) => rowGutter}px;
  width: 100%;
  position: relative;
  .ant-select-selector {
    align-items: center;
  }
`;

export enum FormFields {
  Cost = 'cost',
  Currency = 'currency',
  PresenceType = 'presenceType',
  Provider = 'provider',
  ProgramLength = 'programLength',
  ProgramLengthUnit = 'programLengthUnit',
  ProgramTypeId = 'programTypeId',
  ShortDescription = 'shortDescription',
  TimeCommitmentType = 'timeCommitmentType',
  Title = 'title',
  Url = 'url',
}

export interface ICreateProgramDrawerProps {
  onClose: () => void;
  visible: boolean;
  programTypes: ProgramTypesVM;
  providerSuggestions: {label: string; value: string}[];
  userCurrency?: CurrencyCode;
  id: number;
  provider?: string;
  user: UserDetailsVM;
  onChangeProvider: (value: string) => void;
  onSubmitForm: (args: AddNewProgram & {provider?: string}) => void;
}

const CreateProgramDrawer = forwardRef<FormInstance, ICreateProgramDrawerProps>(
  function CreateProgramDrawer(
    {
      onClose,
      visible,
      onSubmitForm,
      programTypes,
      providerSuggestions,
      id,
      userCurrency,
      provider,
      onChangeProvider,
      user,
    },
    formRef
  ) {
    const [currency, setCurrency] = useState(userCurrency);
    const {isFeatureFlagOn} = useFeatureFlags();
    const isRenameFieldsEnabled = isFeatureFlagOn.RenameApprovalFields;
    const fullColumn = 24;
    const halfColumn = fullColumn / 2;
    const commonGutterSize = 8;
    const rowGutterSize = 30;

    const validateMessages = {
      required: i18n.t(k.VALIDATION__REQUIRED),
      types: {
        email: i18n.t(k.VALIDATION__EMAIL_INVALID__POLITE),
        number: i18n.t(k.VALIDATION__REQUIRED),
        url: i18n.t(k.VALIDATION__URL_INVALID__FORMAT),
      },
    };

    return (
      <LearnInDrawer
        closeButtonPlacement={'right'}
        title={i18n.t(!id ? k.PROGRAM__ADD__NEW : k.PROGRAM__EDIT)}
        closable={true}
        onClose={onClose}
        visible={visible}
        footer={[
          <FooterButtonsContainer key="footer-buttons">
            <LearnInButton
              tag={ButtonTags.DrawerFooterSecondary}
              onClick={onClose}>
              {i18n.t(k.CTA__CANCEL)}
            </LearnInButton>
            <LearnInButton
              tag={ButtonTags.DrawerFooterPrimary}
              htmlType="submit"
              form="addNewProgramForm">
              {i18n.t(k.PROGRAM__SAVE)}
            </LearnInButton>
          </FooterButtonsContainer>,
        ]}>
        <Form
          name="nest-messages"
          id="addNewProgramForm"
          className="add-new-program-form-drawer"
          onFinish={(values) => {
            values.currency = getCurrencyEnum(currency);
            onSubmitForm({...values, provider});
          }}
          validateMessages={validateMessages}
          ref={formRef}
          layout={'vertical'}>
          <div className="form-content">
            <Row gutter={[commonGutterSize, commonGutterSize]}>
              <Col xs={fullColumn} sm={fullColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__PROVIDER)}
                  name={FormFields.Provider}
                  rules={[{required: true, max: 150}]}>
                  <LearnInAutocomplete
                    value={provider}
                    style={{width: '100%'}}
                    options={providerSuggestions}
                    placeholder={i18n.t(k.PROGRAM__PROVIDER__SELECT_OR_ADD)}
                    filterOption={(inputValue, {value}) => {
                      return (
                        value
                          ?.toUpperCase()
                          .indexOf(inputValue?.toUpperCase()) !== -1
                      );
                    }}
                    onChange={onChangeProvider}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[commonGutterSize, commonGutterSize]}>
              <Col xs={fullColumn} sm={fullColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__TYPE)}
                  name={FormFields.ProgramTypeId}
                  rules={[{required: true}]}>
                  <LearnInSelect>
                    {programTypes?.map((programType, key) => (
                      <LearnInSelectOption value={programType.id} key={key}>
                        {localizeGeneric(programType.description)}
                      </LearnInSelectOption>
                    ))}
                  </LearnInSelect>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[commonGutterSize, commonGutterSize]}>
              <Col xs={fullColumn} sm={fullColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__TITLE)}
                  name={FormFields.Title}
                  rules={[{required: true, max: 150}]}>
                  <LearnInInput
                    tag={InputTags.PRIMARY}
                    placeholder={i18n.t(k.VALIDATION__MISSING_FIELD__FORMAT, {
                      fieldName: i18n.t(k.PROGRAM__TITLE).toLocaleLowerCase(),
                    })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[commonGutterSize, commonGutterSize]}>
              <Col xs={fullColumn} sm={fullColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__URL)}
                  name={FormFields.Url}
                  rules={[{required: true, type: 'url'}]}>
                  <LearnInInput
                    tag={InputTags.PRIMARY}
                    placeholder={i18n.t(k.VALIDATION__MISSING_FIELD__FORMAT, {
                      fieldName: i18n.t(k.GENERIC__URL),
                    })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[commonGutterSize, commonGutterSize]}>
              <Col xs={fullColumn} sm={fullColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__DESCRIPTION__SHORT)}
                  name={FormFields.ShortDescription}
                  rules={[{required: true}]}>
                  <LearnInTextArea tag={TextAreaTags.PRIMARY} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[commonGutterSize, commonGutterSize]}>
              <Col xs={halfColumn} sm={halfColumn}>
                <Form.Item
                  name={FormFields.Cost}
                  className="lb-application-drawer"
                  label={i18n.t(
                    isRenameFieldsEnabled
                      ? k.PROGRAM__TOTAL_COST_IN_CURRENCY
                      : !isRenameFieldsEnabled
                      ? k.PROGRAM__COST_IN_CURRENCY
                      : isRenameFieldsEnabled
                      ? k.PROGRAM__TOTAL_COST_IN_CURRENCY__FORMAT
                      : k.PROGRAM__COST_IN_CURRENCY__FORMAT,
                    {
                      currency: CurrencyCodeString.USD,
                    }
                  )}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      min: 0,
                      message: i18n.t(k.VALIDATION__AMOUNT),
                    },
                  ]}>
                  <LearnInMoneyInput
                    currency={user?.currency}
                    prefix={null}
                    min={1}
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </Col>
              <Col xs={halfColumn} sm={halfColumn}>
                <Form.Item name={FormFields.Currency} noStyle>
                  <CurrencySelect
                    size="large"
                    defaultValue={userCurrency}
                    onChange={(currency: CurrencyCode) => setCurrency(currency)}
                    rowGutter={rowGutterSize}>
                    {currencyOptions}
                  </CurrencySelect>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[commonGutterSize, commonGutterSize]}>
              <Col xs={halfColumn} sm={halfColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__PRESENCE_TYPE)}
                  name={FormFields.PresenceType}
                  rules={[{required: true}]}>
                  <LearnInSelect>
                    <LearnInSelectOption value={PresenceType.Online}>
                      {i18n.t(k.PROGRAM__PRESENCE_TYPE__ONLINE)}
                    </LearnInSelectOption>
                    <LearnInSelectOption value={PresenceType.Classroom}>
                      {i18n.t(k.PROGRAM__PRESENCE_TYPE__CLASSROOM)}
                    </LearnInSelectOption>
                  </LearnInSelect>
                </Form.Item>
              </Col>
              <Col xs={halfColumn} sm={halfColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__PART_FULL_TIME)}
                  name={FormFields.TimeCommitmentType}
                  rules={[{required: true}]}>
                  <LearnInSelect>
                    <LearnInSelectOption value={TimeCommitmentType.PartTime}>
                      {i18n.t(k.PROGRAM__PART_TIME)}
                    </LearnInSelectOption>
                    <LearnInSelectOption value={TimeCommitmentType.FullTime}>
                      {i18n.t(k.PROGRAM__FULL_TIME)}
                    </LearnInSelectOption>
                  </LearnInSelect>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[commonGutterSize, commonGutterSize]}>
              <Col xs={fullColumn} sm={halfColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__LENGTH)}
                  name={FormFields.ProgramLength}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      min: 0,
                      validator: (_, value) => {
                        if (Number.isInteger(value)) return Promise.resolve();
                        return Promise.reject(
                          new Error(i18n.t(k.VALIDATION__NUMBER))
                        );
                      },
                    },
                  ]}>
                  <LearnInNumberInput style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col xs={fullColumn} sm={halfColumn}>
                <Form.Item
                  className="lb-application-drawer"
                  label={i18n.t(k.PROGRAM__LENGTH_UNIT)}
                  name={FormFields.ProgramLengthUnit}
                  rules={[{required: true}]}>
                  <LearnInSelect>
                    <LearnInSelectOption value={DurationUnitType.Hours}>
                      {i18n.t(k.DATE__HOUR__PLURAL)}
                    </LearnInSelectOption>
                    <LearnInSelectOption value={DurationUnitType.Days}>
                      {i18n.t(k.DATE__DAY__PLURAL)}
                    </LearnInSelectOption>
                    <LearnInSelectOption value={DurationUnitType.Weeks}>
                      {i18n.t(k.DATE__WEEK__PLURAL)}
                    </LearnInSelectOption>
                    <LearnInSelectOption value={DurationUnitType.Months}>
                      {i18n.t(k.DATE__MONTH__PLURAL)}
                    </LearnInSelectOption>
                    <LearnInSelectOption value={DurationUnitType.Years}>
                      {i18n.t(k.DATE__YEAR__PLURAL)}
                    </LearnInSelectOption>
                  </LearnInSelect>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
      </LearnInDrawer>
    );
  }
);

export default CreateProgramDrawer;
