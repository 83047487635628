import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';
import './ClickToUnenrollModal.scss';
import {clickToUnenrollText} from './ClickToUnenroll.model';
import {useDeleteAcademyUserMutation} from '@generated/hooks';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {notify} from '@components/user/notifications';
import {UserPaths} from '@utils/ClientPaths';
import {useNavigate} from 'react-router-dom';
import {usePublishedAcademies} from '@components/user/pages/academy/academy.queries';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/
const ModalContainer = styled.div`
  padding: 16px 24px;
  color: ${COLORS.Neutral950};
`;

const ModalTitle = styled.div`
  font-size: ${pxToRem(16)};
  font-weight: 500;
`;
const ModalBody = styled.div`
  font-size: ${pxToRem(12)};
  font-weight: 400;
  color: ${COLORS.Neutral800};
`;

interface ClickToUnenrolleModalProps {
  academyId: number;
  academyName: string;
  onClose: () => void;
}

/**
 * Renders a modal for the Click To Unenroll Academy users.
 */
export function ClickToUnenrollModal({
  academyId,
  academyName,
  onClose,
}: ClickToUnenrolleModalProps) {
  const {user} = useAuth();
  const navigate = useNavigate();
  const text = clickToUnenrollText();
  const {invalidateExact: invalidateAcademiesList} =
    usePublishedAcademies(false);
  const deleteAcademyUser = useDeleteAcademyUserMutation({
    onSuccess: () => {
      notify.onUnenrollAcademySuccess();
      invalidateAcademiesList();
      navigate(UserPaths.Academies);
    },
    onError: () => {
      notify.onUnenrollAcademyError();
    },
  });

  const handleUnenroll = () => {
    deleteAcademyUser.mutate({
      pathVars: {
        academyId,
        userCompanyId: user.userCompanyId,
      },
    });
  };

  return (
    <LearnInModal
      width={567}
      min-width={390}
      visible={true}
      tag={ModalTags.Default}
      buttonDisabled={true}
      closable={true}
      maskClosable={true}
      onCancel={onClose}
      footer={null}
      ariaLabel={text.label}>
      <ModalContainer>
        <ModalTitle>{text.modalTitle(academyName)}</ModalTitle>
        <ModalBody>
          <div className="ct-unenroll-view-access-box">
            <div className="text-container">
              <span className="text">{text.confirmation}</span>
            </div>
          </div>
          <div className="ct-unenroll-button-container">
            <LearnInButton
              tag={ButtonTags.Secondary}
              color={COLORS.Neutral800}
              border={`1px solid ${COLORS.Neutral800}`}
              padding={'10px 16px'}
              onClick={onClose}>
              {text.cancel}
            </LearnInButton>
            <LearnInButton
              tag={ButtonTags.Destructive}
              padding={'10px 16px'}
              onClick={handleUnenroll}>
              {text.label}
            </LearnInButton>
          </div>
        </ModalBody>
      </ModalContainer>
    </LearnInModal>
  );
}
