import {DefaultResourcesApprovalQuestions} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: DefaultResourcesApprovalQuestions

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getDefaultResourcesApprovalQuestionsMockData(
  args?: Partial<DefaultResourcesApprovalQuestions>
): DefaultResourcesApprovalQuestions {
  return {
    addDefaultBookQuestions: faker.datatype.boolean(),
    addDefaultConferenceQuestions: faker.datatype.boolean(),
    addDefaultOtherQuestions: faker.datatype.boolean(),
    addDefaultSubscriptionQuestions: faker.datatype.boolean(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const DefaultResourcesApprovalQuestionsMockData: DefaultResourcesApprovalQuestions =
  {
    addDefaultBookQuestions: true,
    addDefaultConferenceQuestions: true,
    addDefaultOtherQuestions: true,
    addDefaultSubscriptionQuestions: true,
  };
