import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Grid from '@blocks/Grid';
import {ErrorMessage, InputLabel} from '@components/reusable/LearnInForm';
import {COLORS} from '@utils/constants';
import {LabelAndIconContainer} from '../../curriculum/CurriculumScreen.container';
import {TagsContainer} from '@blocks/LearnInTag';
import {NotificationRow} from './EmailNotifications';
import {ProgramNotificationType} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Styled Component
|--------------------------------------------------------------------------
*/

const MainContainer = styled.div`
  .ql-container {
    min-height: 80px;
  }
`;

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
`;

/*
|--------------------------------------------------------------------------
| Form Modal
|--------------------------------------------------------------------------
*/

interface EmailMessageUpdateModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (type: ProgramNotificationType, message: string) => void;
  emailNotification: NotificationRow;
  showDate?: boolean;
}

function EmailMessageUpdateModal({
  emailNotification,
  onCancel,
  onOk,
  visible,
  showDate,
}: EmailMessageUpdateModalProps) {
  const [message, setMessage] = useState(emailNotification?.message);

  const FormFieldErrors = {
    MissingMessage: i18n.t(k.VALIDATION__REQUIRED),
  };
  
  const [formFieldErrors, setFormFieldErrors] = useState({message: ''});

  // Reset Form
  const resetForm = () => {
    setMessage('');
  };

  const placeHolders = [
    `${i18n.t(k.PARTICIPANT)} ${i18n.t(k.NAME)}`.toLocaleUpperCase(),
    i18n.t(k.PROGRAM__TITLE).toLocaleUpperCase(),
  ];
  if (showDate) {
    placeHolders.push(i18n.t(k.DATE__START).toLocaleUpperCase());
    placeHolders.push(i18n.t(k.DATE__END).toLocaleUpperCase());
  }

  const handleOkClicked = () => {
    const errorMessages = getFormValidationErrors();
    setFormFieldErrors(errorMessages);
    if (!errorMessages) {
      return;
    }

    onOk(emailNotification?.type, message);
  };

  const handleMessageChange = (value: any) => {
    setMessage(value);
    // add validation errors
    if (!value?.length && !formFieldErrors.message) {
      setFormFieldErrors({
        ...formFieldErrors,
        message: FormFieldErrors.MissingMessage,
      });
    }
  };

  const getFormValidationErrors = () => {
    let errorMessages = undefined;
    if (message?.length) {
      errorMessages = {
        ...errorMessages,
        description: FormFieldErrors.MissingMessage,
      };
    }
    return errorMessages;
  };

  // Refresh form when it opens
  useEffect(() => {
    const updateFromContent = () => {
      resetForm();

      setMessage(emailNotification?.message || '');
    };

    if (visible) {
      updateFromContent();
    }
  }, [visible, emailNotification?.notificationDescription]);

  return (
    <LearnInModal
      width={800}
      visible={visible}
      tag={ModalTags.MoneyIncentive}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      okButtonChild={i18n.t(k.EMAIL__SAVE)}
      onCancel={onCancel}
      onOk={handleOkClicked}
      ariaLabel={emailNotification?.title}>
      <MainContainer>
        <div style={{marginBottom: '24px'}}>
          <LabelAndIconContainer>
            <ModalTitle>{emailNotification?.title}</ModalTitle>
          </LabelAndIconContainer>
        </div>
        <Grid.Container>
          <Grid.Box>
            <div style={{display: 'flex', gap: '10px'}}>
              <span>{i18n.t(k.GENERIC__SHORTCUT__PLURAL)}:</span>
              <TagsContainer tags={[...placeHolders.map((x) => `[${x}]`)]} />
            </div>
          </Grid.Box>
          <Grid.Box>
            <InputLabel
              htmlFor="personal-message-input"
              label={i18n.t(k.MESSAGE__PERSONAL)}
              required
            />
            <ReactQuill
              id="personal-message-input"
              theme="snow"
              value={message}
              onChange={handleMessageChange}
            />
            <ErrorMessage message={formFieldErrors.message} />
          </Grid.Box>
        </Grid.Container>
      </MainContainer>
    </LearnInModal>
  );
}

export default EmailMessageUpdateModal;
