import {i18n, k} from '@i18n/translate';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';

interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onOk?: () => void;
  itemId?: number;
  programId?: number;
}

export default function DeleteAcademyModal({
  isVisible,
  setIsVisible,
  onOk,
}: Props) {
  const onCancel = () => setIsVisible(false);
  return (
    <LearnInModal
      tag={ModalTags.RemoveProgramFromUserPlan}
      visible={isVisible}
      onOk={onOk}
      width={400}
      onCancel={onCancel}
      okButtonChild={i18n.t(k.PROMPT__REMOVE_ACADEMY_YES)}
      okButtonTag={ButtonTags.Destructive}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      title={i18n.t(k.PROMPT__REMOVE_ITEM__FORMAT, {
        item: i18n.t(k.ACADEMY),
      })}>
      <div style={{padding: '10px 25px 25px'}}>
        {i18n.t(k.PROMPT__REMOVE_ITEM_FRIENDLY__FORMAT, {
          item: i18n.t(k.ACADEMY).toLocaleLowerCase(),
        })}
      </div>
    </LearnInModal>
  );
}
