import {IncentiveAvailability} from './../enums';
import {DetailsVM} from './../interfaces';
import {
  ApprovalConfigMockData,
  getApprovalConfigMockData,
} from './ApprovalConfig.mock';
import {
  AttachmentVMMockData,
  getAttachmentVMMockData,
} from './AttachmentVM.mock';
import {
  DetailsSectionVMMockData,
  getDetailsSectionVMMockData,
} from './DetailsSectionVM.mock';
import {faker} from '@faker-js/faker';

// Interface: DetailsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getDetailsVMMockData(args?: Partial<DetailsVM>): DetailsVM {
  return {
    approvalConfig: getApprovalConfigMockData(),
    approvalGuidelines: faker.word.noun(),
    approvalRequested: faker.datatype.boolean(),
    approvalRequired: faker.datatype.boolean(),
    availability: IncentiveAvailability.All,
    companyIncentive: faker.datatype.boolean(),
    id: faker.datatype.number(),
    imageLarge: faker.word.noun(),
    images: [getAttachmentVMMockData()],
    imageSmall: faker.word.noun(),
    learningBudget: faker.datatype.boolean(),
    logo: faker.word.noun(),
    longDescription: faker.lorem.lines(),
    picture: faker.word.noun(),
    programTypeIds: [faker.datatype.number()],
    provider: faker.word.noun(),
    sections: [getDetailsSectionVMMockData()],
    selected: faker.datatype.boolean(),
    shortDescription: faker.lorem.lines(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const DetailsVMMockData: DetailsVM = {
  approvalConfig: ApprovalConfigMockData,
  approvalGuidelines: 'string',
  approvalRequested: true,
  approvalRequired: true,
  availability: IncentiveAvailability.All,
  companyIncentive: true,
  id: 1,
  imageLarge: 'string',
  images: [AttachmentVMMockData],
  imageSmall: 'string',
  learningBudget: true,
  logo: 'string',
  longDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  picture: 'string',
  programTypeIds: [1],
  provider: 'string',
  sections: [DetailsSectionVMMockData],
  selected: true,
  shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  title: 'Advanced Web Design',
};
