import {Dispatch, SetStateAction, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {useMutation, useQuery} from 'react-query';
import {
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {
  getUpdateUserBudgetRm,
  getUserCurrentFinanceIncentiveDetailsRq,
} from '@store/apiEndpoints';
import {notify} from '@components/user/notifications';
import AddFundsDrawer from './AddFundsDrawer';
import {Form} from 'antd';
import {
  EmployeeCurrentFinanceIncentiveDetailsVM,
  PrepaymentReportVM,
} from '@models/serverModels';
import Limit from '@assets/svg/limit.svg';
import CardBalance from '@assets/svg/card-balance.svg';
import Remaining from '@assets/svg/remaining.svg';
import Spent from '@assets/svg/spent.svg';
import {
  exchangeAmountBack,
  formatCurrency,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {datadogLogs} from '@datadog/browser-logs';
import {FinanceAmountType} from '@generated/enums';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/
export interface FormItems {
  editable: boolean;
  editing?: boolean;
  header: string;
  helperText?: string;
  hideItem: boolean;
  icon: any;
  inputField?: boolean;
  name: string;
  subheader: string;
  total: number;
  setShowEditField(inputField: any): void;
  setAmountToAdd(value: number): void;
  amountToAdd: number;
  dataCy?: string;
}

export interface AddFundsDrawerContainerProps {
  amountType: FinanceAmountType;
  userFinanceIncentive: PrepaymentReportVM;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
  onOk: () => void;
}

function AddFundsDrawerContainer({
  amountType,
  userFinanceIncentive,
  isVisible,
  setIsVisible,
  onOk,
}: AddFundsDrawerContainerProps) {
  const enum inputName {
    cardBalance = 'cardBalance',
    limit = 'limit',
    remainingBudget = 'remainingBudget',
    totalSpent = 'totalSpent',
  }
  const [form] = Form.useForm();
  const [limitValue, setLimitValue] = useState(0);
  const [balanceValue, setBalanceValue] = useState(0);
  //get user plan data
  const userCurrentFinanceIncentiveDetailsRq =
    getUserCurrentFinanceIncentiveDetailsRq(
      userFinanceIncentive?.userFinanceIncentiveId
    );
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );
  const programDetailsQuery =
    useQuery<EmployeeCurrentFinanceIncentiveDetailsVM>(
      userCurrentFinanceIncentiveDetailsRq.queryKey,
      () => simpleQueryFn(userCurrentFinanceIncentiveDetailsRq.path),
      {
        onSuccess(data) {
          setLimitValue(data?.spendingLimit || 0);
          setBalanceValue(data?.balance || 0);
        },
        refetchOnWindowFocus: false,
      }
    );

  const [showLimit, setShowLimit] = useState(false);
  const [showCardBalance, setShowCardBalance] = useState(false);
  const [note, setNote] = useState('');
  const [noteError, setNoteError] = useState('');

  const [amountToAdd, setAmountToAdd] = useState(0);

  const totalSpent = programDetailsQuery?.data?.totalSpent || 0;
  const updateUserBudgetMutation = useMutation(
    ({
      note,
      balance,
      budgetLimit,
      userFinanceIncentiveId,
    }: {
      note: string;
      balance: number;
      budgetLimit: number;
      userFinanceIncentiveId: number;
    }) => {
      const updateUserBudget = getUpdateUserBudgetRm(
        userFinanceIncentiveId,
        budgetLimit,
        balance,
        note
      );
      return programDetailsQuery?.data?.balance !== balanceValue ||
        programDetailsQuery?.data?.spendingLimit !== limitValue ||
        note
        ? simpleMutationFn<null>(
            updateUserBudget.path,
            updateUserBudget.payload
          )
        : null;
    },
    {
      onSuccess: () => {
        setIsVisible(false);
        onOk();
        simpleInvalidateExactQueryFn(
          userCurrentFinanceIncentiveDetailsRq.queryKey
        );
        programDetailsQuery.refetch();
        notify.updateBalanceSuccess();
      },
      onError: () => {
        notify.increaseBalanceFailure();
      },
    }
  );

  const handleOk = async () => {
    try {
      await form.validateFields();
      updateUserBudgetMutation.mutate({
        note: note,
        balance: balanceValue,
        budgetLimit: limitValue,
        userFinanceIncentiveId: userFinanceIncentive.userFinanceIncentiveId,
      });
    } catch (error) {
      datadogLogs.logger.error('Error updating funds', error);
    }
  };

  const isValidInput = (name) => {
    const isLimitValidForApproved =
      limitValue >= programDetailsQuery?.data?.totalApproved;
    const isLimitValidForSpent = limitValue >= totalSpent;

    switch (name) {
      case inputName.limit:
        if (!isLimitValidForApproved) {
          return i18n.t(k.PLB__VALIDATION__BE_MORE_THAN_APPROVED__FORMAT, {
            amount: formatCurrency(
              programDetailsQuery?.data?.totalApproved,
              getUserQuery.data?.currency,
              exchangeRate
            ),
          });
        } else if (!isLimitValidForSpent) {
          return i18n.t(k.PLB__VALIDATION__BE_MORE_THAN_SPENT__FORMAT, {
            amount: formatCurrency(
              totalSpent,
              getUserQuery.data?.currency,
              exchangeRate
            ),
          });
        }
        return;
      case inputName.cardBalance:
        return (
          isLimitValidForApproved &&
          balanceValue > limitValue - totalSpent &&
          amountType !== FinanceAmountType.Any &&
          i18n.t(k.PLB__VALIDATION__EQUAL_OR_LESS_BUDGET__FORMAT, {
            amount: formatCurrency(
              limitValue - totalSpent,
              getUserQuery.data?.currency,
              exchangeRate
            ),
          })
        );
      case 'note':
        setNoteError(
          !note.length
            ? `${i18n.t(k.FUND__ADD__NOTE_REQUIRED)} ${i18n.t(
                k.FUND__ADD__NOTE_VISIBILITY_NOTICE
              )}`
            : ''
        );
        return !!note.length;
      default:
        break;
    }
  };

  const formItems = [
    {
      editable: true,
      header: i18n.t(k.GENERIC__TOTAL_BUDGET),
      icon: Limit,
      inputField: showLimit,
      hideItem: amountType === FinanceAmountType.Any,
      name: inputName.limit,
      subheader: i18n.t(k.PLB__MAX_APPROVAL),
      total: programDetailsQuery?.data?.spendingLimit,
      setShowEditField(inputField) {
        return setShowLimit(inputField);
      },
      editing: showLimit,
      amountToAdd: limitValue,
      setAmountToAdd(value) {
        return setLimitValue(exchangeAmountBack(value, exchangeRate));
      },
      dataCy: 'totalBudget',
    },
    {
      editable: true,
      header: i18n.t(k.GENERIC__READY_TO_SPEND),
      icon: CardBalance,
      inputField: showCardBalance,
      hideItem: false,
      name: inputName.cardBalance,
      subheader: i18n.t(k.PLB__APPROVED_NOT_SPENT),
      total: programDetailsQuery?.data?.balance,
      setShowEditField(inputField) {
        return setShowCardBalance(inputField);
      },
      editing: showCardBalance,
      amountToAdd: balanceValue,
      setAmountToAdd(value) {
        return setBalanceValue(exchangeAmountBack(value, exchangeRate));
      },
      dataCy: 'readyToSpend',
    },
    {
      editable: false,
      header: i18n.t(k.PLB__BUDGET_REMAINING),
      hideItem:
        amountType === FinanceAmountType.Any ||
        !programDetailsQuery?.data?.preApprovalRequired,
      icon: Remaining,
      name: inputName.remainingBudget,
      subheader: i18n.t(k.PLB__BUDGET_MINUS_APPROVED),
      total: limitValue - (balanceValue + totalSpent),
      dataCy: 'budgetRemaining',
    },
    {
      editable: false,
      header: i18n.t(k.MONEY__TOTAL_SPENT),
      icon: Spent,
      hideItem: false,
      name: inputName.totalSpent,
      subheader: i18n.t(k.PLB__AMOUNT_PEOPLE_SPENT),
      total: totalSpent,
      dataCy: 'totalSpent',
    },
  ] as FormItems[];

  return (
    <AddFundsDrawer
      amountToAdd={amountToAdd}
      form={form}
      formItems={formItems.filter(({hideItem}) => !hideItem)}
      isValidInput={isValidInput}
      isVisible={isVisible}
      onClose={() => setIsVisible(false)}
      onOk={handleOk}
      setAmountToAdd={setAmountToAdd}
      userFinanceIncentive={programDetailsQuery?.data}
      note={note}
      setNote={setNote}
      usersCurrency={getUserQuery.data?.currency}
      exchangeRate={exchangeRate}
      noteError={noteError}
    />
  );
}

export default AddFundsDrawerContainer;
