import {ILongId} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ILongId

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getILongIdMockData(args?: Partial<ILongId>): ILongId {
  return {
    id: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ILongIdMockData: ILongId = {
  id: 1,
};
