import {i18n, k} from '@i18n/translate';
import {stripHtmlTags} from './stringUtils';

export const isOverMaxRichTextCharacterCount = (html: string, max = 200) => {
  return (stripHtmlTags(html)?.length || 0) > max;
};

export const richTextCharacterCount = (html: string) => {
  return stripHtmlTags(html)?.length || 0;
};

export const maxRichTextCharacterCount = (max = 200, message = '') => {
  message = message || i18n.t(k.VALIDATION__CHAR_LIMIT_REACHED__FORMAT, {max});
  const validator = (_, value, callback) => {
    if (!value) {
      callback();
      return;
    }
    if (isOverMaxRichTextCharacterCount(value, max)) {
      callback(message);
      return;
    }
    callback();
  };

  return validator;
};
