import {useEffect} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import translate, {i18n, k} from '@i18n/translate';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {Tabs} from 'antd';
import {AcademyReportsPageTabs} from '@utils/ClientPaths';
import AcademyReportsParticipantsTable from './AcademyReportsParticipantsTable';
import ReportsBySection from './ReportsBySection';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function AcademyReportsUsersTableContainer({
  basePath,
  title,
}: {
  basePath: string;
  title?: string;
}) {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  // TODO: make util function
  const activeTabKey = pathname.split('/').find((part) => {
    return Object.values(AcademyReportsPageTabs).includes(part as any);
  });

  useEffect(() => {
    if (title) document.title = i18n.t(k.REPORT__PLURAL);
  }, []);

  const ensureValidTabKey = () => {
    if (
      activeTabKey === undefined ||
      Object.values(AcademyReportsPageTabs).every((key) => key !== activeTabKey)
    ) {
      navigate([basePath, AcademyReportsPageTabs.Participants].join('/'), {
        replace: true,
      });
    }
  };

  useEffect(ensureValidTabKey, [activeTabKey]);

  const onChangeTab = (tabKey: AcademyReportsPageTabs) => {
    navigate([basePath, tabKey].join('/'));
  };

  return (
    <>
      <LearnInTabs activeKey={activeTabKey} onChange={onChangeTab}>
        <Tabs.TabPane
          tab={translate('PARTICIPANT__PLURAL')}
          key={AcademyReportsPageTabs.Participants}>
          <AcademyReportsParticipantsTable basePath={basePath} />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={translate('SECTION__PLURAL')}
          key={AcademyReportsPageTabs.Sections}>
          <ReportsBySection basePath={basePath} />
        </Tabs.TabPane>
      </LearnInTabs>
    </>
  );
}

export default AcademyReportsUsersTableContainer;
