import {RecommendationItemType, RecommendationLevel} from './../enums';
import {UserRecommendationPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserRecommendationPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserRecommendationPayloadMockData(
  args?: Partial<UserRecommendationPayload>
): UserRecommendationPayload {
  return {
    financeIncentiveId: faker.datatype.number(), // optional
    level: RecommendationLevel.Featured,
    message: faker.lorem.lines(),
    programId: faker.datatype.number(), // optional
    timeIncentiveId: faker.datatype.number(), // optional
    type: RecommendationItemType.Program,
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserRecommendationPayloadMockData: UserRecommendationPayload = {
  financeIncentiveId: 1, // optional
  level: RecommendationLevel.Featured,
  message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  programId: 1, // optional
  timeIncentiveId: 1, // optional
  type: RecommendationItemType.Program,
  userCompanyId: 1,
};
