// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/UserProgressSummary.cs

enum UserProgressSummary {
  FeelingGreat,
  MakingProgress,
  TooBusy,
  Stressed,
  NeedHelp,
}

export default UserProgressSummary;
