import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {i18n, k} from '@i18n/translate';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import Grid from '@blocks/Grid';
import {DeleteOutlined, LoadingOutlined} from '@ant-design/icons';
import {
  charLimitErrorMessage,
  ErrorMessage,
  InputLabel,
  LearnInInput,
} from '@components/reusable/LearnInForm';
import {
  CustomContentLink,
  AcademyStepAttachment,
  AttachmentResponse,
} from '@models/serverModels';
import {LearnInWrapper} from '@components/reusable/Wrapper/Wrapper.style';
import {WrapperTags} from '@components/reusable/Wrapper/WrapperEnums';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {UploadIcon} from '@assets/upload-icon';
import {COLORS} from '@utils/constants';
import {LinkIcon} from '@assets/link-icon';
import {notify} from '@components/user/notifications';
import {Form, Upload, Checkbox} from 'antd';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {
  RichTextEditor,
  isShowingRichTextEditor,
} from '@components/reusable/RichTextEditor';
import LearnInQuill from '@components/reusable/LearnInQuill';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import {FileUploadType, FileTypeDetails} from '@utils/uploadUtils';
import {FormInstance} from 'antd/es/form';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {StepRequirementFields} from '@components/reusable/StepRequirementFields';
import UploadInstructions from '@components/reusable/Upload/UploadInstructions';
import LoadingSpinner from '@blocks/LoadingSpinner';
import AttachmentUploadProgress from '@blocks/AddEditContentModal/Attachments/AttachmentUploadProgress.view';
import AttachmentCard from '@blocks/AddEditContentModal/Attachments/AttachmentCard.view';

const {Dragger} = Upload;

/*
|--------------------------------------------------------------------------
| Styled Component
|--------------------------------------------------------------------------
*/

const MainContainer = styled.div`
  .ql-container {
    min-height: 80px;
  }
`;

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  margin-bottom: 21px;
`;

const LinkText = styled.button`
  color: ${COLORS.Blue800};
  font-weight: 400;
  font-size: 1rem;
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const DestructiveLinkText = styled(LinkText)`
  color: ${COLORS.Red800};
`;

const AttachmentOptionButton = styled.button`
  &:focus > div {
    background: ${COLORS.Neutral100};
    border-color: ${COLORS.Neutral600};
  }
  &:focus > div {
    box-shadow: 0 0 10px ${COLORS.Neutral600};
  }
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

enum AttachmentType {
  Link = 'link',
  File = 'file',
}

enum CharLimits {
  Title = 70,
  Description = 10000,
}

/*
|--------------------------------------------------------------------------
| Form Modal
|--------------------------------------------------------------------------
*/

interface CustomContentModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: any;
  cancelUpload: () => Promise<void>;
  onClickPreviewContent?: () => void;
  onClickDelete: (id: number) => void;
  id: number | undefined;
  title: string | undefined;
  description: string | undefined;
  attachment: AcademyStepAttachment | AttachmentResponse | undefined;
  link: CustomContentLink | undefined;
  contentUploadPercent: number | undefined;
  onDeleteAttachment: () => void | Promise<void>;
  // step requirements
  showStepRequirementFields: boolean;
  stepRequirementOptions: {title: string; id: number}[];
  showStepRequirementOptions: boolean;
  onClickAddRequirement: () => void;
  onClickRemoveRequirement: () => void;
  form: FormInstance;
  allowSkip: boolean;
  isLoading?: boolean;
  companyId: number;
}

const getInitialErrorMessageState = () => ({title: '', description: ''});

function CustomContentModal({
  id,
  title,
  description,
  attachment,
  link,
  onCancel,
  onOk,
  visible,
  onClickDelete,
  cancelUpload,
  contentUploadPercent,
  onDeleteAttachment,
  showStepRequirementFields,
  showStepRequirementOptions,
  stepRequirementOptions,
  onClickAddRequirement,
  onClickRemoveRequirement,
  form,
  allowSkip,
  isLoading,
  companyId,
}: CustomContentModalProps) {
  
  const FormFieldErrors = {
    FileUploadInFlight: i18n.t(k.VALIDATION__IMAGE_IS_UPLOADING),
    MissingTitle: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
      item: i18n.t(k.GENERIC__TITLE),
    }),
    MissingDescription: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
      item: i18n.t(k.GENERIC__DESCRIPTION),
    }),
    CharLimitTitle: charLimitErrorMessage('title', CharLimits.Title),
    CharLimitDescription: charLimitErrorMessage(
      'description',
      CharLimits.Description
    ),
  };

  const [_attachment, setAttachment] = useState<any>();
  const [_description, setDescription] = useState('');
  const [_link, setLink] = useState<CustomContentLink | undefined>();
  const [_title, setTitle] = useState('');
  const [_allowSkip, setAllowSkip] = useState(false);
  const [attachmentType, setAttachmentType] = useState<
    AttachmentType | undefined
  >();
  const [fileToUpload, setFileToUpload] = useState<any>();
  const [formFieldErrors, setFormFieldErrors] = useState(
    getInitialErrorMessageState()
  );

  const {isFeatureFlagOn} = useFeatureFlags();
  const {pathname} = useLocation();
  const [isDeletingAttachment, setIsDeletingAttachment] = useState(false);
  const [shouldDeleteAttachment, setShouldDeleteAttachment] = useState(false);
  const [isSavingContent, setIsSavingContent] = useState<boolean>(false);

  const showRichTextUploads = isShowingRichTextEditor({
    pathname,
  });

  // Input handlers w/ on the fly error checking
  const handleTitleChange = (value: any) => {
    setTitle(value);
    // add validation errors
    if (value?.length > CharLimits.Title && !formFieldErrors.title) {
      setFormFieldErrors({
        ...formFieldErrors,
        title: FormFieldErrors.CharLimitTitle,
      });
    }
    if (
      !!value &&
      value?.length <= CharLimits.Title &&
      !!formFieldErrors.title
    ) {
      setFormFieldErrors({...formFieldErrors, title: ''});
    }
  };
  const handleDescriptionChange = (value: any) => {
    setDescription(value);
    // add validation errors
    if (
      value?.length > CharLimits.Description &&
      !formFieldErrors.description &&
      !value.includes('data:image')
    ) {
      setFormFieldErrors({
        ...formFieldErrors,
        description: FormFieldErrors.CharLimitDescription,
      });
    }
    if (
      !!value &&
      value?.length <= CharLimits.Description &&
      !!formFieldErrors.description
    ) {
      setFormFieldErrors({...formFieldErrors, description: ''});
    }
  };

  // Reset Form
  const resetForm = () => {
    setAttachment(undefined);
    setAttachmentType(null);
    setDescription('');
    setFileToUpload(undefined);
    setFormFieldErrors(getInitialErrorMessageState());
    setIsDeletingAttachment(false);
    setLink(undefined);
    setShouldDeleteAttachment(false);
    setTitle('');
    setAllowSkip(false);
  };

  const setFormValidationErrors = (): boolean => {
    let errorMessages = getInitialErrorMessageState();
    if (!_title) {
      errorMessages = {...errorMessages, title: FormFieldErrors.MissingTitle};
    }
    if (_title?.length > CharLimits.Title) {
      errorMessages = {...errorMessages, title: FormFieldErrors.CharLimitTitle};
    }

    if (_description.includes('data:image')) {
      errorMessages = {
        ...errorMessages,
        description: FormFieldErrors.FileUploadInFlight,
      };
    } else if (_description?.length > CharLimits.Description) {
      errorMessages = {
        ...errorMessages,
        description: FormFieldErrors.CharLimitDescription,
      };
    }

    // If any error messages are not empty strings, update formFieldErrors
    if (Object.values(errorMessages).some((msg) => !!msg)) {
      setFormFieldErrors(errorMessages);
      return true;
    }
    return false;
  };

  // Refresh form when it opens
  useEffect(() => {
    const updateFromContent = () => {
      resetForm();
      setTitle(title || '');
      setDescription(description || '');
      setAllowSkip(allowSkip);
      if (!!link) {
        setLink(link);
        setAttachmentType(AttachmentType.Link);
      } else if (!!attachment) {
        setAttachment(attachment);
        setAttachmentType(AttachmentType.File);
      } else {
        setAttachmentType(null);
      }
    };

    if (visible) {
      if (!!id) {
        updateFromContent();
      } else {
        resetForm();
      }
    }
  }, [visible, id]);

  // Load links and attachments when they load
  useEffect(() => {
    if (!!attachment) {
      setAttachment(attachment);
      setAttachmentType(AttachmentType.File);
    } else {
      if (!link) setAttachmentType(null);
    }
  }, [link, attachment]);

  useEffect(() => {
    if (!!link) {
      setLink(link);
      setAttachmentType(AttachmentType.Link);
    } else {
      if (!attachment) setAttachmentType(null);
    }
  }, [link, attachment]);

  const saveButtonLabel = (() => {
    if (!!fileToUpload) return i18n.t(k.FILE__UPLOAD__SAVE_AND_UPLOAD_CONTENT);
    if (shouldDeleteAttachment)
      return i18n.t(k.FILE__UPLOAD__SAVE_AND_DELETE_ATTACHMENT);
    return i18n.t(k.CONTENT__SAVE);
  })();

  const showAttachmentTypeOptions =
    !attachmentType &&
    !isDeletingAttachment &&
    !_attachment &&
    !_link &&
    !fileToUpload &&
    contentUploadPercent === undefined;

  const showAttachmentUploadInput =
    (shouldDeleteAttachment && !fileToUpload) ||
    (attachmentType === AttachmentType.File &&
      !_attachment &&
      !fileToUpload &&
      contentUploadPercent === undefined);

  const modalTitle = i18n.t(!!id ? k.CONTENT__EDIT : k.CONTENT__NEW);

  return (
    <LearnInModal
      width={800}
      visible={visible}
      tag={ModalTags.MoneyIncentive}
      okButtonChild={saveButtonLabel}
      buttonDisabled={isDeletingAttachment || isSavingContent}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      onCancel={onCancel}
      onOk={async () => {
        if (setFormValidationErrors()) return;
        if (shouldDeleteAttachment) {
          setIsDeletingAttachment(true);
          await onDeleteAttachment();
          setIsDeletingAttachment(false);
          setShouldDeleteAttachment(false);
        }

        setIsSavingContent(true);
        onOk(
          _title,
          _description,
          attachmentType === AttachmentType.Link && _link,
          fileToUpload,
          _allowSkip
        );
      }}
      footerLeft={
        !!id ? (
          <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
            <DestructiveLinkText
              onClick={() => {
                onClickDelete(id);
              }}>
              {i18n.t(k.CONTENT__DELETE)}
            </DestructiveLinkText>
          </div>
        ) : null
      }
      ariaLabel={modalTitle}>
      <MainContainer>
        <Form layout="vertical" form={form}>
          <ModalTitle>
            {modalTitle} {isLoading && <LoadingSpinner />}
          </ModalTitle>
          <Grid.Container>
            <Grid.Box>
              <InputLabel
                htmlFor="title-input"
                label={i18n.t(k.GENERIC__TITLE)}
                required
              />
              <LearnInInput
                id="title-input"
                value={_title}
                aria-required
                updateFunc={handleTitleChange}
              />
              <ErrorMessage message={formFieldErrors.title} />
            </Grid.Box>
            <Grid.Box>
              <InputLabel
                htmlFor="description-input"
                label={i18n.t(k.GENERIC__DESCRIPTION)}
              />
              {showRichTextUploads ? (
                <RichTextEditor
                  id="description-input"
                  value={_description}
                  onChange={handleDescriptionChange}
                  companyId={companyId}
                />
              ) : (
                <LearnInQuill
                  id="description-input"
                  theme="snow"
                  value={_description}
                  onChange={handleDescriptionChange}
                />
              )}
              <ErrorMessage message={formFieldErrors.description} />
            </Grid.Box>
            {/* Deleting Attachment Indicator */}
            {isDeletingAttachment && (
              <div style={{marginBottom: '20px'}}>
                <LoadingOutlined
                  aria-hidden="true"
                  style={{fontSize: '1.4em', marginRight: '9px'}}
                />{' '}
                {i18n.t(
                  fileToUpload
                    ? k.FILE__ATTACHMENT__DELETING_OLD
                    : k.FILE__ATTACHMENT__DELETING
                )}
              </div>
            )}

            {showStepRequirementFields && (
              <StepRequirementFields
                stepRequirementOptions={stepRequirementOptions}
                showFields={showStepRequirementOptions}
                onClickAdd={onClickAddRequirement}
                onClickRemove={onClickRemoveRequirement}
              />
            )}

            {isFeatureFlagOn.SkipAcademyStep && (
              <div style={{marginBottom: '24px'}}>
                <Checkbox
                  onChange={() => setAllowSkip(!_allowSkip)}
                  checked={_allowSkip}
                  value="allowSkipAcademyStep">
                  {i18n.t(k.ACADEMY_STEP_CHECKBOX_LABEL_ALLOW_SKIP_STEP)}
                </Checkbox>
              </div>
            )}

            {/* Attachment Type Options */}
            {showAttachmentTypeOptions && (
              <Grid.Box>
                <InputLabel
                  htmlFor="attachment-input"
                  label={i18n.t(k.FILE__ATTACHMENT)}
                />
                <LearnInWrapper tag={WrapperTags.UploadButtonWrapper}>
                  <LearnInTooltip
                    title={i18n.t(k.FILE__UPLOAD__VERB)}
                    childHasButton={true}>
                    <AttachmentOptionButton
                      data-testid="add-attachment-button-custom-content"
                      aria-label={i18n.t(k.FILE__UPLOAD__VERB)}
                      onClick={() => setAttachmentType(AttachmentType.File)}>
                      <LearnInContainer
                        id="attachment-input"
                        tag={ContainerTags.ButtonUploadContainer}
                        border={`1px solid ${COLORS.Neutral300}`}
                        background={
                          attachmentType === AttachmentType.File
                            ? COLORS.Neutral100
                            : COLORS.White
                        }>
                        <UploadIcon
                          fill={COLORS.Neutral600}
                          alt={i18n.t(k.FILE__UPLOAD__VERB)}
                        />
                      </LearnInContainer>
                    </AttachmentOptionButton>
                  </LearnInTooltip>
                  <LearnInTooltip
                    title={i18n.t(k.ADD_URL_LINK)}
                    childHasButton={true}>
                    <AttachmentOptionButton
                      data-testid="add-link-button-custom-content"
                      aria-label={i18n.t(k.ADD_URL_LINK)}
                      onClick={() => setAttachmentType(AttachmentType.Link)}>
                      <LearnInContainer
                        id="link-input"
                        tag={ContainerTags.ButtonUploadContainer}
                        border={`1px solid ${COLORS.Neutral300}`}
                        background={
                          attachmentType === AttachmentType.Link
                            ? COLORS.Neutral100
                            : COLORS.White
                        }
                        margin="0px 0px 0px 16px">
                        <LinkIcon
                          fill={COLORS.Neutral600}
                          alt={i18n.t(k.ADD_URL_LINK)}
                        />
                      </LearnInContainer>
                    </AttachmentOptionButton>
                  </LearnInTooltip>
                </LearnInWrapper>
              </Grid.Box>
            )}

            {/* Link Form */}
            {attachmentType === AttachmentType.Link && (
              <Grid.Box style={{display: 'flex'}}>
                <div style={{marginRight: '8px', width: '100%'}}>
                  <div>{i18n.t(k.FILE__ATTACHMENT__LINK_TITLE)}</div>
                  <LearnInInput
                    value={_link?.title || ''}
                    updateFunc={(val: any) => setLink({..._link, title: val})}
                  />
                </div>

                <div style={{marginRight: '8px', width: '100%'}}>
                  <div>{i18n.t(k.GENERIC__URL)}</div>
                  <LearnInInput
                    value={_link?.url || ''}
                    updateFunc={(val: any) => setLink({..._link, url: val})}
                  />
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: '13px',
                    fontSize: '1.3em',
                    marginTop: '20px',
                  }}>
                  <button
                    onClick={() => {
                      setLink(undefined);
                      setAttachmentType(undefined);
                    }}
                    aria-label={i18n.t(k.CTA__DELETE)}>
                    <DeleteOutlined aria-hidden="true" />
                  </button>
                </div>
              </Grid.Box>
            )}

            {/* Upload Progress Indicator */}
            {contentUploadPercent !== undefined && (
              <AttachmentUploadProgress
                cancelUpload={() => {
                  setAttachment(undefined);
                  setAttachmentType(null);
                  return cancelUpload();
                }}
                fileName={fileToUpload.name}
                uploadPercentage={contentUploadPercent}
              />
            )}

            {/* Attachment Form */}
            <Grid.Box>
              {showAttachmentUploadInput && (
                <Dragger
                  customRequest={async ({file}) => {
                    // Prevent files that are too large from being uploaded
                    if (
                      ((file as File)?.size as number) >
                      UploadConstraints.MaxUserContentUploadSizeBytes
                    ) {
                      return notify.uploadContentAttachmentTooLargeError(
                        (file as File)?.name || i18n.t(k.FILE)
                      );
                    }
                    setFileToUpload(file);
                  }}
                  showUploadList={false}
                  maxCount={1}
                  multiple={false}
                  accept={[
                    ...FileTypeDetails[FileUploadType.Images].fileSuffixes,
                    ...FileTypeDetails[FileUploadType.Videos].fileSuffixes,
                    ...FileTypeDetails[FileUploadType.Pdf].fileSuffixes,
                  ].join(', ')}>
                  <UploadInstructions
                    fileTypes={i18n.t(
                      k.FILE__UPLOAD__IMAGES_DOCUMENTS_AND_VIDEOS
                    )}
                  />
                  <p style={{color: COLORS.Red800}}>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setAttachmentType(null);
                      }}>
                      {i18n.t(k.CTA__CANCEL)}
                    </button>
                  </p>
                </Dragger>
              )}

              {/* Current Attachment */}
              {!!_attachment && !shouldDeleteAttachment && (
                <AttachmentCard
                  fileName={_attachment?.description || _attachment?.fileName}
                  onClickDelete={() => {
                    setShouldDeleteAttachment(true);
                  }}
                />
              )}

              {/* Queued Attachment */}
              {!!fileToUpload && contentUploadPercent === undefined && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}>
                  <div>{fileToUpload.name}</div>
                  <div>
                    <button
                      onClick={() => {
                        setFileToUpload(undefined);
                      }}>
                      <DeleteOutlined
                        alt={i18n.t(k.CTA__DELETE)}
                        style={{
                          color: COLORS.Red800,
                          fontSize: '1.4em',
                        }}
                      />
                    </button>
                  </div>
                </div>
              )}
            </Grid.Box>
          </Grid.Container>
        </Form>
      </MainContainer>
    </LearnInModal>
  );
}

export default CustomContentModal;
