import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {AcademyItemType} from '@models/clientEnums';
import {LockOutlined} from '@ant-design/icons';
import Typography from '@components/reusable/Typography';
import WithStepIndexRule from '@components/user/pages/academy/AcademyContent/WithStepIndexRule';
import {Breakpoints} from '@utils/responsiveUtils';
import {AcademyStepRequirementProperty} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.article`
  align-items: center;
  background: ${COLORS.White};
  border-radius: 8px;
  box-shadow: ${COLORS.BoxShadowStandard};
  display: flex;
  margin: ${(props) => (props.isStep ? '0 10px 16px 20px' : '0 0 16px')};
  max-width: 1042px;
  padding: 24px;
  width: 100%;

  @media (max-width: ${Breakpoints.Desktop}px) {
    margin-right: 0;
    border-radius: 0;
  }
`;

const StepLockIconSize = 34;
const GatedStepLockIconContainer = styled.div`
  align-items: center;
  background: ${COLORS.Neutral200};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  height: ${StepLockIconSize}px;
  justify-content: center;
  padding: 8px;
  width: ${StepLockIconSize}px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTypographyBodySpan = styled(Typography.Body.Span)`
  strong {
    color: ${COLORS.Neutral950};
    fontweight: 500;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface GatedCardProps {
  title: string;
  requiredTitle: string;
  academyItemType: AcademyItemType;
  style?: React.CSSProperties;
  requirementType?: AcademyStepRequirementProperty;
}

export function GatedCard({
  title,
  requiredTitle,
  academyItemType,
  style,
  requirementType,
}: GatedCardProps) {
  const isLevel = academyItemType === AcademyItemType.Level;
  const isStep = academyItemType === AcademyItemType.Step;
  const i18nKey =
    isStep && requirementType === AcademyStepRequirementProperty.Approval
      ? 'ACADEMY__GET_APPROVED_TO_ACCESS__FORMAT'
      : 'ACADEMY__COMPLETE_TO_ACCESS__FORMAT';
  return (
    <Container isStep={isStep} style={style}>
      <div style={{marginRight: '10px'}}>
        {isLevel && (
          <LockOutlined
            aria-hidden="true"
            style={{fontSize: '2.125rem', color: COLORS.Neutral900}}
          />
        )}
        {isStep && (
          <GatedStepLockIconContainer>
            <LockOutlined
              aria-hidden="true"
              style={{fontSize: '1rem', color: COLORS.Neutral950}}
            />
          </GatedStepLockIconContainer>
        )}
      </div>
      <TextContainer>
        <Typography.Body.Span color={COLORS.Neutral950} fontWeight={500}>
          {title}
        </Typography.Body.Span>
        <StyledTypographyBodySpan color={COLORS.Neutral600}>
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.t(k[i18nKey], {
                requiredTitle,
              }),
            }}></span>
        </StyledTypographyBodySpan>
      </TextContainer>
    </Container>
  );
}

export function StepGatedCard({
  index,
  cardCount,
  ...gatedCardProps
}: {index: number; cardCount: number} & GatedCardProps) {
  return (
    <WithStepIndexRule index={index} cardCount={cardCount}>
      <GatedCard {...gatedCardProps} />
    </WithStepIndexRule>
  );
}

export default GatedCard;
