export const GraduationCap = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1643 7.2274C14.6604 6.94195 14.6604 6.2137 14.1643 5.92825L8.35976 2.58822C8.13648 2.45974 7.86351 2.45974 7.64023 2.58822L1.83569 5.92825C1.33962 6.21369 1.33962 6.94194 1.83569 7.22739L7.64024 10.5674C7.86352 10.6959 8.13648 10.6959 8.35977 10.5674L14.1643 7.2274ZM15 8.45507C14.9623 8.47981 14.9236 8.50365 14.8838 8.52654L13.9018 9.09163C13.9279 9.15961 13.942 9.23206 13.942 9.30725V13.3725C13.942 14.3736 13.09 15.2668 11.8495 15.4155C10.8544 15.5348 9.43332 15.6667 8.00003 15.6667C6.56675 15.6667 5.14563 15.5348 4.15059 15.4155C2.91007 15.2668 2.05804 14.3736 2.05804 13.3725V9.30725C2.05804 9.23207 2.07215 9.15962 2.09828 9.09166L1.11616 8.52653C-0.372054 7.67019 -0.372056 5.48545 1.11616 4.6291L6.92071 1.28908C7.59055 0.90364 8.40944 0.903641 9.07929 1.28908L14.8838 4.62911C15.6919 5.0941 16.0612 5.95077 15.9917 6.77375C15.9972 6.81237 16 6.85218 16 6.89285V10.8214C16 11.183 15.7761 11.4762 15.5 11.4762C15.2239 11.4762 15 11.183 15 10.8214V8.45507ZM9.07929 11.8666L12.2443 10.0454V13.3725C12.2443 13.7135 11.9628 13.9742 11.6075 14.0167C10.6538 14.1311 9.32082 14.2535 8.00003 14.2535C6.67924 14.2535 5.34628 14.1311 4.39254 14.0167C4.03727 13.9742 3.75575 13.7135 3.75575 13.3725V10.0454L6.92071 11.8666C7.59055 12.252 8.40945 12.252 9.07929 11.8666Z"
        fill="#146FE3"
      />
    </svg>
  );
};
