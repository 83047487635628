import './PreApprovalDrawer.scss';
import {i18n, k} from '@i18n/translate';
import {Divider, Form} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {PreApprovalQuestion} from '@models/serverModels';
import {UserDetailsVM} from '@generated/interfaces';
import {
  CurrencyCode,
  FinanceAmountType,
  FinanceType,
  PlanItemType,
  PreApprovalQuestionResponseSource,
} from '@generated/enums';
import LearnInDrawer, {renderFooter} from '@components/reusable/LearnInDrawer';
import PreApprovalFormItem from '../PreApprovalFormItem/PreApprovalFormItem.container';
import {WhenLoaded} from '@utils/typeUtils';
import {BodySpan, H1} from '@components/reusable/Typography';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {RejectionNotes} from '@components/user/pages/components/PreApprovalFormItem/RejectionNotes';
import {useState} from 'react';
import {MONEY_DEFAULT, formatCurrency, exchangeAmount} from '@utils/moneyUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import WhatsNext from '@components/WhatsNext';
import Speaker from '@assets/svg/speaker.svg';
import {AddUpdateLicenseVM} from '@components/admin/pages/licenses/components/AddUpdateLicenseDrawer/AddUpdateLicenseDrawer.types';

export const WhatsNextContainer = styled.div`
  margin-top: 2rem;
`;

const PreApprovalParagraph = styled.p`
  font-size: 0.875rem;
  color: ${COLORS.Neutral950};
`;

const InstructionsParagraph = styled.div`
  color: ${COLORS.Neutral950};
  margin-bottom: 16px;
`;

const AlertBanner = styled.div`
  background: ${COLORS.Yellow50};
  border-radius: 4px;
  border: 1px solid ${COLORS.Yellow500};
  color: ${COLORS.Neutral900};
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 30px;
  padding: 18px;
  display: flex;
`;

const AlertTitle = styled.span`
  color: ${COLORS.Neutral900};
  font-weight: 500;
`;

const AlertParagraph = styled.span`
  color: ${COLORS.Neutral700};
`;

/*
|--------------------------------------------------------------------------
| Component Fragments
|--------------------------------------------------------------------------
*/

export const renderHeadingContent = ({
  isHideHeaderOnResubmitOn,
  isApplicationRejected,
  license,
  isProgram,
  isPrepayment,
  planItemTypeTitle,
  programCost,
  exchangeRate,
  user,
  programCurrency,
  isInstructionsTextOn,
  isWhatsNextOn,
  isDirectBilling,
  licenseData,
}: {
  isHideHeaderOnResubmitOn: boolean;
  isApplicationRejected: boolean;
  license?: boolean;
  isProgram: boolean;
  isPrepayment: boolean;
  planItemTypeTitle: string;
  programCost?: number;
  exchangeRate?: number;
  user?: UserDetailsVM;
  programCurrency?: CurrencyCode;
  isInstructionsTextOn?: boolean;
  isWhatsNextOn?: boolean;
  isDirectBilling?: boolean;
  licenseData?: AddUpdateLicenseVM;
}) => {
  if (isHideHeaderOnResubmitOn && isApplicationRejected) return;

  if (license)
    return (
      <>
        {isInstructionsTextOn ? (
          <InstructionsParagraph
            dangerouslySetInnerHTML={{
              __html: isDirectBilling
                ? i18n.t(k.APPROVAL__REQUIRED__BILLING_PARAGRAPH__FORMAT, {
                    programCost: `${formatCurrency(
                      licenseData?.internalCost !== -1
                        ? licenseData?.internalCost
                        : programCost,
                      user?.currency,
                      exchangeRate
                    )}`,
                  })
                : i18n.t(k.APPROVAL__REQUIRED__PARAGRAPH__FORMAT),
            }}></InstructionsParagraph>
        ) : (
          <>
            {!isWhatsNextOn && (
              <>
                <PreApprovalParagraph>
                  {i18n.t(k.APPROVAL__REQUIRED)}
                </PreApprovalParagraph>
                <Divider />
              </>
            )}
          </>
        )}
      </>
    );
  if (isProgram)
    return (
      !isWhatsNextOn && (
        <>
          <BodySpan>
            {i18n.t(k.APPROVAL__REQUIRED__DETAILED)}
            <ol>
              <li>{i18n.t(k.APPROVAL__REQUEST_APPROVAL)}</li>
              <li>
                {i18n.t(
                  isPrepayment
                    ? k.APPROVAL__ONCE_APPROVED_VIRTUAL
                    : k.APPROVAL__ONCE_APPROVED_PURCHASE
                )}
              </li>
              <li>{i18n.t(k.APPROVAL__START_JOURNEY)}</li>
            </ol>
          </BodySpan>

          <Divider />
        </>
      )
    );
  return (
    <>
      <H1>{i18n.t(k.APPROVAL__COMPLETE__TITLE)}</H1>
      <BodySpan>
        {i18n.t(k.APPROVAL__APPLICATION_FOR__FORMAT, {
          planItemTypeTitle: planItemTypeTitle?.toLocaleLowerCase(),
        })}
      </BodySpan>
      <Divider />
    </>
  );
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface PreApprovalDrawerProps {
  form: FormInstance;
  financeType: FinanceType;
  formItems: PreApprovalQuestion[];
  isFormDisabled: boolean;
  isHideHeaderOnResubmitOn: boolean;
  isSubmitButtonLoading: boolean;
  isTextAreaDisabled: boolean;
  onChangeCheckbox: (id, selectedOptions: string[]) => void;
  onChangeSelect: (selectedOption: string) => void;
  onClickSubmit: () => Promise<void>;
  onClose: () => void;
  planItemType: PlanItemType;
  programCost?: WhenLoaded<number>;
  programCurrency?: CurrencyCode | null;
  programCostFeesMultiplier?: number;
  rejectedNotes: WhenLoaded<string>;
  selectedCheckboxOptions: {[key: number]: string[]};
  subscriptionCost?: number | null;
  visible: boolean;
  isApplicationRejected?: boolean;
  license?: WhenLoaded<boolean>;
  user: WhenLoaded<UserDetailsVM>;
  setTotal?: (total: number) => void;
  total?: number;
  exchangeRateFromDefault: number;
  exchangeRate: number;
  exchangeAmount: (
    amount: number,
    exchangeRate: number,
    toCents?: boolean
  ) => number;
  frequencyDropdown?: PreApprovalQuestion;
  filesProgress?: any[];
  filesToUpload?: any[];
  setFilesToUpload?: (files: any[]) => void;
  deleteFunction?: (idx) => void;
  balance?: number;
  licenseData?: AddUpdateLicenseVM;
  financeAmountType?: FinanceAmountType;
}

export default function PreApprovalDrawer({
  frequencyDropdown,
  subscriptionCost,
  form,
  programCost,
  programCostFeesMultiplier,
  programCurrency,
  onChangeCheckbox,
  onChangeSelect,
  onClickSubmit,
  onClose,
  selectedCheckboxOptions,
  visible,
  planItemType,
  financeType,
  formItems,
  rejectedNotes,
  isFormDisabled,
  isHideHeaderOnResubmitOn,
  isSubmitButtonLoading,
  isTextAreaDisabled,
  isApplicationRejected,
  license,
  user,
  setTotal,
  total,
  exchangeRateFromDefault,
  exchangeRate,
  exchangeAmount,
  filesProgress,
  filesToUpload,
  setFilesToUpload,
  deleteFunction,
  balance,
  licenseData,
  financeAmountType,
}: PreApprovalDrawerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isWhatsNextOn = isFeatureFlagOn.WhatsNext;
  const isInstructionsTextOn = isFeatureFlagOn.InstructionText;

  const isPrepayment = financeType === FinanceType.Prepayment;
  const isProgram = planItemType === PlanItemType.Program;
  const isDirectBilling = financeType === FinanceType.DirectBilling;
  const [rangeDates, setRangeDates] = useState({startDate: '', endDate: ''});
  const showDirectBillingAlert =
    isDirectBilling &&
    financeAmountType !== FinanceAmountType.Any &&
    (licenseData?.internalCost !== -1
      ? exchangeAmount(licenseData?.internalCost, exchangeRate) > balance
      : programCost > balance);
  const planItemTypeTitle = (() => {
    switch (planItemType) {
      case PlanItemType.Finance:
        return i18n.t(k.GENERIC__FINANCE);
      case PlanItemType.Time:
        return i18n.t(k.TIME);
      case PlanItemType.Program:
      default:
        // this intentionally catches License
        return i18n.t(k.PROGRAM);
    }
  })();
  return (
    <LearnInDrawer
      zIndex={1000}
      closeButtonPlacement={'right'}
      responsiveSize={560}
      title={`${i18n.t(k.REQUEST)} ${planItemTypeTitle} ${i18n.t(k.APPROVAL)}`}
      visible={visible}
      footer={renderFooter({
        loading: isSubmitButtonLoading,
        onClose,
        onOk: onClickSubmit,
        disableButton: showDirectBillingAlert,
      })}
      onClose={onClose}>
      {showDirectBillingAlert ? (
        <AlertBanner>
          <img
            src={Speaker}
            alt="speaker"
            height="72px"
            width="86px"
            style={{marginRight: '20px'}}
          />
          <div>
            <AlertTitle>{i18n.t(k.APPROVAL__EXCEEDS_BALANCE)}</AlertTitle>
            <br />
            <AlertParagraph
              dangerouslySetInnerHTML={{
                __html: i18n.t(k.APPROVAL__EXCEEDS_BALANCE_DISCLAIMER__FORMAT, {
                  programCost: `${formatCurrency(
                    licenseData?.internalCost !== -1
                      ? licenseData?.internalCost
                      : programCost,
                    user?.currency,
                    exchangeRate
                  )}`,
                  balance: `${formatCurrency(
                    balance,
                    user?.currency,
                    exchangeRateFromDefault
                  )}`,
                }),
              }}></AlertParagraph>
          </div>
        </AlertBanner>
      ) : (
        renderHeadingContent({
          isHideHeaderOnResubmitOn,
          isApplicationRejected,
          license,
          isProgram,
          isPrepayment,
          planItemTypeTitle,
          programCost,
          exchangeRate,
          user,
          programCurrency,
          isInstructionsTextOn,
          isWhatsNextOn,
          isDirectBilling,
          licenseData,
        })
      )}

      <Form form={form} layout="vertical">
        {formItems.map((item) => {
          const isUserPlanProgramAmount =
            item.responseType ===
            PreApprovalQuestionResponseSource.UserPlanProgramAmount;
          return (
            <PreApprovalFormItem
              frequencyDropdown={frequencyDropdown}
              key={item.id}
              form={form}
              formQuestions={formItems}
              item={item}
              onChangeSelect={onChangeSelect}
              selectedCheckboxOptions={selectedCheckboxOptions}
              onChangeCheckbox={onChangeCheckbox}
              isFormDisabled={isFormDisabled || showDirectBillingAlert}
              isTextAreaDisabled={isTextAreaDisabled || showDirectBillingAlert}
              programCost={programCost}
              subscriptionCost={subscriptionCost}
              onTotalChange={setTotal}
              programCostFeesMultiplier={programCostFeesMultiplier}
              programCurrency={programCurrency}
              isProgram={isProgram}
              isUserRequest={false}
              isApplicationRejected={isApplicationRejected}
              isForPlanItemOrResourcePreapproval
              setInitialCostEstimate={isUserPlanProgramAmount}
              isCostField={isUserPlanProgramAmount}
              total={total}
              rangeDates={rangeDates}
              setRangeDates={setRangeDates}
              filesToUpload={filesToUpload}
              filesProgress={filesProgress}
              deleteFunction={deleteFunction}
              setFilesToUpload={setFilesToUpload}
            />
          );
        })}
        <RejectionNotes rejectedNotes={rejectedNotes} />
        <WhatsNext />
      </Form>
    </LearnInDrawer>
  );
}
