import {ErrorMessage} from '@components/reusable/LearnInForm';
import {CurrencyCode, LicenseRedemptionType} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {currencyOptions} from '@utils/moneyUtils';
import {Col, Form, Radio, RadioChangeEvent, Row, Typography} from 'antd';
import {ChangeEvent, SyntheticEvent} from 'react';
import {
  handleLicenseCostBlur,
  handleLicenseCostTypeChange,
} from '../AddUpdateLicenseDrawer.handlers';
import {
  LicenseCostItemContainer,
  LicenseLabel,
  LicenseMoneyInput,
  LicenseStyledCurrencySelect,
} from '../AddUpdateLicenseDrawer.styled';
import {
  LicenseFormFieldErrors,
  LicenseFormFields,
  LicenseInternalCost,
} from '../AddUpdateLicenseDrawer.types';

interface LicenseCostItemProps {
  currency: CurrencyCode;
  formFieldErrors: LicenseFormFieldErrors;
  internalCost: number;
  internalCostType: LicenseInternalCost;
  setFormFieldErrors: (errors: LicenseFormFieldErrors) => void;
  setInternalCost: (cost: number) => void;
  setInternalCostType: (costType: LicenseInternalCost) => void;
  setRedemptionType: (redemptionType: LicenseRedemptionType) => void;
}

function LicenseCostItem({
  currency,
  formFieldErrors,
  internalCost,
  internalCostType,
  setFormFieldErrors,
  setInternalCost,
  setInternalCostType,
}: LicenseCostItemProps) {
  const currencyDropdownRenderId = 'currencyDropdownRenderId';
  const preventEventBubbling = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <LicenseCostItemContainer
      label={
        <LicenseLabel required>{i18n.t(k.LICENSE__INTERNAL_COST)}</LicenseLabel>
      }
      initialValue={LicenseInternalCost.Fixed}
      name={LicenseFormFields.InternalCostType}>
      <Radio.Group
        name={i18n.t(k.LICENSE__INTERNAL_COST)}
        onChange={(event: RadioChangeEvent) =>
          handleLicenseCostTypeChange({
            costType: event?.target?.value,
            formFieldErrors,
            setFormFieldErrors,
            setInternalCostType,
          })
        }>
        <Row>
          <Col>
            <Radio value={LicenseInternalCost.Fixed}>
              <LicenseMoneyInput
                type="number"
                min="0"
                step="any"
                id={LicenseFormFields.InternalCost}
                data-cy="internalLicenseCost"
                disabled={internalCostType !== LicenseInternalCost.Fixed}
                value={internalCost}
                updateFunc={setInternalCost}
                onChange={(e) => setInternalCost(Number(e.target.value))}
                onBlur={({target}: ChangeEvent<HTMLInputElement>) =>
                  handleLicenseCostBlur({
                    formFieldErrors,
                    internalCostType,
                    shouldSetError: true,
                    setFormFieldErrors,
                    target,
                  })
                }
              />
            </Radio>
            <Form.Item
              name={LicenseFormFields.Currency}
              noStyle
              initialValue={currency}>
              <LicenseStyledCurrencySelect
                size="large"
                getPopupContainer={() =>
                  document.getElementById(currencyDropdownRenderId)
                }
                data-cy="licenseCurrency"
                disabled={internalCostType !== LicenseInternalCost.Fixed}
                onChange={() => preventEventBubbling(event)}
                onClick={(event: SyntheticEvent) =>
                  preventEventBubbling(event)
                }>
                {currencyOptions}
              </LicenseStyledCurrencySelect>
            </Form.Item>
            <ErrorMessage
              message={formFieldErrors[LicenseFormFields.InternalCost]}
            />
          </Col>
        </Row>
        <Row>
          <Radio value={LicenseInternalCost.Dependent}>
            {i18n.t(k.LICENSE__COST__DEPENDENT)}
          </Radio>
        </Row>
        <Row>
          <Radio value={LicenseInternalCost.Zero}>
            {i18n.t(k.LICENSE__COST__NO_COST)}
          </Radio>
        </Row>
      </Radio.Group>
    </LicenseCostItemContainer>
  );
}

export default LicenseCostItem;
