export const FORM_KEYS = {
  TITLE: 'title',
  DESCRIPTION: 'description',
  EVENT_LINK: 'eventLink',
  DUE_DATE: 'dueDate',
  DUE_TIME: 'dueTime',
  DUE_DATE_TIME_ZONE: 'dueDateTimeZone',
  EVENT_DATE: 'eventDate',
  EVENT_START_TIME: 'startTime',
  EVENT_TIME_ZONE: 'eventTimeZone',
  EVENT_END_TIME: 'endTime',
  ALLOW_SKIP: 'allowSkip',
  ALLOW_PEER_VISIBILITY: 'allowPeerVisibility',
  SUBMISSION_TYPE: 'submissionType',
  EVENT_START_DATE: 'startDate',
  EVENT_END_DATE: 'endDate',
};
