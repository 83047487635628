import {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import {Sources, StringMap} from 'quill';
import ImageUploader from './UploadModule/imageUploader';
import {upload} from './directChunkUploader';
import './UploadModule/styles.scss';
import {COLORS} from '@utils/constants';

Quill.register('modules/imageUploader', ImageUploader);

export interface RichTextEditorProps {
  id?: string;
  onChange?: (
    value: string,
    delta?: Record<string, unknown> | unknown,
    source?: Sources,
    editor?: ReactQuill.UnprivilegedEditor
  ) => void;
  readOnly?: boolean;
  theme?: string;
  value: string;
  modules?: StringMap;
  companyId?: number;
}

export const RichTextEditor = ({
  id,
  onChange,
  readOnly,
  theme,
  value,
  modules,
  companyId,
}: RichTextEditorProps) => {
  const quillRef = useRef(null);
  const previousValueRef = useRef(value);
  const [localValue, setLocalValue] = useState(value);

  const [isInitialRender, setIsInitialRender] = useState(true);
  useLayoutEffect(() => setIsInitialRender(false), []);

  const cleanUpInitialContent = (editorRoot: HTMLElement) => {
    const uploadingImages = editorRoot.querySelectorAll('.image-uploading');
    uploadingImages.forEach((uploadingSpan) => {
      while (uploadingSpan.firstChild) {
        uploadingSpan.parentNode.insertBefore(
          uploadingSpan.firstChild,
          uploadingSpan
        );
      }
      uploadingSpan.parentNode.removeChild(uploadingSpan);
    });
  };

  useEffect(() => {
    if (readOnly && value.includes('image-uploading')) {
      setLocalValue(value.replace('image-uploading', ''));
    } else if (
      quillRef.current &&
      previousValueRef.current === '' &&
      value !== ''
    ) {
      previousValueRef.current = value;
      const editor = quillRef.current.getEditor();
      cleanUpInitialContent(editor.root);
    }
  }, [quillRef, value, previousValueRef, localValue, readOnly]);

  const quillModules = useMemo(() => {
    return {
      toolbar: [
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        ['strike', 'bold', 'italic', 'underline'],
        [{list: 'ordered'}, {list: 'bullet'}],
        ['clean'],
        ['link', 'image'],
      ],
      imageUploader: {
        upload: async (file: any) => {
          try {
            const imageUrl = await upload(file, companyId);
            return imageUrl;
          } catch (e) {
            console.error('Somthing went wrong uploading the image', e);
          }
        },
      },
    };
    /*
     * It is important that this dependency array remains empty.
     * Quill runs outside of the react lifecycle and will crash
     * if the reference to `quillModules` changes.
     * */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => {
    if (quillRef.current && id) {
      const editor = quillRef.current.getEditor();
      editor.root.id = id;
      /* 
        This is needed as an a11y fix for ReactQuill,  which previously allowed tabbing within the Quill 
        editor, preventing tabbing to the next element.  This removes the binding ReactQuill puts in 
        place so the default (accessible) behavior is restored. 
      */

      const removeTabBinding = () => {
        if (quillRef.current === null) {
          return;
        }
        const keyboard = quillRef.current.getEditor().getModule('keyboard');
        // 'hotkeys' have been renamed to 'bindings'
        delete keyboard.bindings[9];
      };

      removeTabBinding();
    }
  }, [id]);

  return isInitialRender ? null : (
    <ReactQuill
      ref={quillRef}
      onChange={onChange}
      theme={theme || 'snow'}
      value={readOnly ? localValue : value}
      readOnly={readOnly || false}
      modules={modules || quillModules}
      style={{borderColor: COLORS.Neutral300}}
    />
  );
};
