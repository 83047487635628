import {AcademyUserStatus} from './../enums';
import {UpdateAcademyUserRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdateAcademyUserRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdateAcademyUserRequestMockData(
  args?: Partial<UpdateAcademyUserRequest>
): UpdateAcademyUserRequest {
  return {
    status: AcademyUserStatus.InProgress,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdateAcademyUserRequestMockData: UpdateAcademyUserRequest = {
  status: AcademyUserStatus.InProgress,
};
