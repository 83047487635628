import React, {useState} from 'react';
import {
  ContentWrapper,
  ToggleButton,
} from './ResponsiveVisibilityToggle.styled';

const ResponsiveVisibilityToggle: React.FC<{
  children: React.ReactElement;
  defaultVisibility?: boolean;
  style?: React.CSSProperties;
  setToggleTitle: (isVisible: boolean) => string;
}> = ({children, defaultVisibility, setToggleTitle, style = {}}) => {
  const [isVisible, setIsVisible] = useState<boolean>(
    defaultVisibility || true
  );
  return (
    <div style={style}>
      <ContentWrapper isVisible={isVisible}>{children}</ContentWrapper>
      <ToggleButton onClick={() => setIsVisible((isVisible) => !isVisible)}>
        {setToggleTitle(isVisible)}
      </ToggleButton>
    </div>
  );
};

export default ResponsiveVisibilityToggle;
