import * as React from 'react';
import AdminUsers from './AdminUsers';
import {AdminUsersContainerProps, TableParams} from './AdminUsers.interfaces';
import {useEffect, useState} from 'react';
import {
  useAdminUsersGetColumns,
  useAdminUsersGetCounts,
} from './AdminUsers.hooks';
import {AdminUserTabVM} from '@generated/interfaces';
import {useNavigate} from 'react-router-dom';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useAllUsersQuery, useUserDetailsQuery} from '@generated/hooks';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {FinanceType} from '@generated/enums';
import {DefaultSortOrder} from '@components/reusable/LearnInTable';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {Helmet} from 'react-helmet-async';

function AdminUsersWrapper({title}: AdminUsersContainerProps) {
  // State
  const [searchTerm, setSearchTerm] = useState('');
  const [delayedSearchTerm, setDelayedSearchTerm] = useState('');
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 100,
      total: 100,
    },
    field: 'UserName',
  });

  // Hooks
  const {isFeatureFlagOn} = useFeatureFlags();
  const getUserQuery = useGetUserQuery();
  const {data: userDetails} = useUserDetailsQuery({
    queryParams: {userCompanyId: getUserQuery.data?.userCompanyId},
  });
  const navigate = useNavigate();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );
  const {data, isLoading} = useAllUsersQuery({
    queryParams: {
      orderBy:
        tableParams?.field?.charAt(0).toUpperCase() +
        tableParams?.field?.slice(1),
      isAscending: tableParams?.order !== DefaultSortOrder.Descend,
      page: tableParams?.pagination?.current,
      searchTerm,
    },
  });

  // User
  const hasPlb = getUserQuery.data?.plbType !== null;
  const hasPrepayment = getUserQuery.data?.plbType === FinanceType.Prepayment;

  // Table
  const totalPages = data?.totalPages;
  const users = data?.users;
  const columns = useAdminUsersGetColumns({
    currency: getUserQuery.data?.currency,
    exchangeRate,
    hasPlb,
    hasPrepayment,
    isCostCenterOn: isFeatureFlagOn.CostCenter,
    navigate,
  });
  const countData: AdminUserTabVM = data;
  const [totalResults, setTotalResults] = useState(countData?.totalResults);
  const counts = useAdminUsersGetCounts(
    tableParams?.pagination?.current,
    totalResults
  );

  // Handlers
  const handleTableChange = (
    pagination: Partial<{total: number}>,
    filters,
    sorter
  ) => {
    pagination.total = tableParams.pagination.pageSize;
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const handleSearch = (e) => {
    setDelayedSearchTerm(e.target.value);
  };

  // Effects
  useEffect(() => {
    if (countData?.totalResults && countData?.totalResults != totalResults)
      setTotalResults(countData?.totalResults);
  }, [countData?.totalResults]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [data]);

  useEffect(() => {
    //Delay the search so the user gets time to type
    const handler = setTimeout(() => {
      setSearchTerm(delayedSearchTerm);
      //return the user to the first page
      setTableParams({
        ...tableParams,
        pagination: {
          current: 1,
          pageSize: tableParams.pagination.pageSize,
          total: tableParams.pagination.pageSize,
        },
      });
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [delayedSearchTerm]);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AdminUsers
        columns={columns}
        counts={counts}
        handleSearch={handleSearch}
        handleTableChange={handleTableChange}
        isLoading={isLoading}
        tableParams={tableParams}
        totalPages={totalPages}
        users={users}
      />
    </div>
  );
}

export default AdminUsersWrapper;
