import LearnInSelect, {
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import moment from 'moment';

export interface ReportYearSelectProps {
  selectedYear: number;
  setSelectedYear: (value) => void;
  style: object;
}

export const YEAR_SELECT_WIDTH = 119;

export function ReportYearSelect({
  selectedYear,
  setSelectedYear,
  style,
}: ReportYearSelectProps) {
  const currentYear: number = moment().year();
  const startYear = 2022;
  const years: number[] = Array.from(
    {length: currentYear - startYear + 1},
    (_, i) => startYear + i
  );

  return (
    <LearnInSelect
      style={{minWidth: YEAR_SELECT_WIDTH, ...style}}
      defaultValue={selectedYear}
      onChange={(year) => setSelectedYear(year)}>
      {years.map((year: number) => (
        <LearnInSelectOption key={year} value={year}>
          {year}
        </LearnInSelectOption>
      ))}
    </LearnInSelect>
  );
}
