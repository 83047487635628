import {
  updateCostCenterAsAdminByUserRequestIdRq,
  updateCostCenterAsAdminRq,
} from '../../../store/apiEndpoints/index';
import {simpleMutationFn} from '@store/queryClient';
import {useMutation, UseMutationResult} from 'react-query';
import {
  CostCenterOverridePayload,
  CostCenterUserRequestOverridePayload,
} from '@models/serverModels';
import {notify} from '@components/user/notifications';

interface UseUpdateCostCenterAsAdminProps {
  onSuccess?: () => void;
  onFailure?: () => void;
  onFinished?: () => void;
}

export function useUpdateCostCenterAsAdmin({
  onSuccess,
  onFailure,
  onFinished,
}: UseUpdateCostCenterAsAdminProps | undefined): UseMutationResult<
  CostCenterOverridePayload,
  unknown
> {
  return useMutation(
    (payload: CostCenterOverridePayload) => {
      const addToPlanRm = updateCostCenterAsAdminRq(payload);
      // no return type
      return simpleMutationFn<CostCenterOverridePayload>(
        addToPlanRm.path,
        addToPlanRm.payload
      );
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();

        notify.updateCostCenterSuccess();
      },
      onError: () => {
        onFailure && onFailure();
        notify.updateCostCenterError();
      },
      onSettled: () => {
        onFinished && onFinished();
      },
    }
  );
}

interface UseUpdateCostCenterAsAdminByUserRequestIdProps {
  onSuccess?: () => void;
  onFailure?: () => void;
  onFinished?: () => void;
}

export function useUpdateCostCenterAsAdminByUserRequestId({
  onSuccess,
  onFailure,
  onFinished,
}:
  | UseUpdateCostCenterAsAdminByUserRequestIdProps
  | undefined): UseMutationResult<
  CostCenterUserRequestOverridePayload,
  unknown
> {
  return useMutation(
    (payload: CostCenterUserRequestOverridePayload) => {
      const addToPlanRm = updateCostCenterAsAdminByUserRequestIdRq(payload);
      // no return type
      return simpleMutationFn<CostCenterUserRequestOverridePayload>(
        addToPlanRm.path,
        addToPlanRm.payload
      );
    },
    {
      onSuccess: () => {
        onSuccess && onSuccess();

        notify.updateCostCenterSuccess();
      },
      onError: () => {
        onFailure && onFailure();
        notify.updateCostCenterError();
      },
      onSettled: () => {
        onFinished && onFinished();
      },
    }
  );
}
