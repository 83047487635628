import './Initiatives.scss';
import {useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Typography} from 'antd';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {InitiativeListItem, InitiativeStatus} from './store/interface';
import {COLORS} from '@utils/constants';
import LoadingSpinner from '../../../../elements/loadingSpinner/LoadingSpinner';
import {LearnInButton} from '../../../reusable/Button/Button.style';
import {ButtonTags} from '../../../reusable/Button/ButtonEnums';
import NewInitiativeContainer from './components/NewInitiative/NewInitiative.container';
import styled from 'styled-components';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import LearnInTable, {
  IColumn,
  TableCell,
} from '@components/reusable/LearnInTable';
import {localizeGeneric} from '@utils/l10nUtils';

const InitiativeTitleLink = styled.a`
  color: ${COLORS.Blue800} !important;
`;

interface Props {
  data: InitiativeListItem[];
  loading: boolean;
  onRefreshRequested: () => void;
}

const getTagStyle = (status: InitiativeStatus): TagStyles => {
  switch (status) {
    case InitiativeStatus.PUBLISHED:
      return TagStyles.Green;
    case InitiativeStatus.DRAFT:
    default:
      return TagStyles.LightGrey;
  }
};

const EmptyInitiatives = ({onClick}: {onClick: () => void}) => {
  return (
    <div style={{marginTop: '50px'}}>
      <Typography.Paragraph
        style={{
          fontWeight: 'normal',
          fontSize: '1.25rem',
          lineHeight: '150%',
          textAlign: 'center',
          color: COLORS.Black,
        }}>
        {i18n.t(k.INITIATIVE__EMPTY__TITLE)}
      </Typography.Paragraph>
      <Typography.Paragraph className="empty-initiatives-small-text">
        {i18n.t(k.INITIATIVE__EMPTY__DESCRIPTION)}
      </Typography.Paragraph>
      <div style={{textAlign: 'center'}}>
        <div style={{display: 'inline-block'}} className={'navbar-item'}>
          <LearnInButton tag={ButtonTags.Secondary} onClick={onClick}>
            {i18n.t(k.INITIATIVE__CREATE)}
          </LearnInButton>
        </div>
      </div>
    </div>
  );
};

const Initiatives = ({data, loading, onRefreshRequested}: Props) => {
  const [selectedInitiativeId, setSelectedInitiativeId] = useState<number>();
  const [showNewInitiativeDrawer, setShowNewInitiativeDrawer] =
    useState<boolean>();

  const openNewInitiatve = () => {
    setShowNewInitiativeDrawer(true);
    setSelectedInitiativeId(null);
  };
  const onCompleted = () => {
    setShowNewInitiativeDrawer(false);
    setSelectedInitiativeId(null);
    onRefreshRequested?.call(null);
  };

  const programsColumns: IColumn<InitiativeListItem>[] = useMemo(() => {
    return [
      {
        title: i18n.t(k.INITIATIVE),
        dataIndex: 'title',
        key: 'title',
        render: (title, row) => {
          return (
            <TableCell>
              <InitiativeTitleLink
                type="link"
                data-testid="edit-initiative-link"
                style={{display: 'inline-block'}}
                onClick={() => {
                  setShowNewInitiativeDrawer(true);
                  setSelectedInitiativeId(row.id);
                }}>
                {title}
              </InitiativeTitleLink>
            </TableCell>
          );
        },
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: i18n.t(k.INITIATIVE__AUDIENCE),
        dataIndex: 'audience',
        key: 'audience',
        render: (audience) => localizeGeneric(audience),
        sorter: (a, b) => a.audience.localeCompare(b.audience),
      },
      {
        title: i18n.t(k.PROGRAM__PLURAL),
        dataIndex: 'programs',
        key: 'programs',
        render: (programs) => {
          const isOnlyOneProgram = programs === 1;
          return (
            <span>
              {i18n.t(k.GENERIC__X_ITEMS__FORMAT, {
                count: programs,
                item: i18n.t(isOnlyOneProgram ? k.PROGRAM : k.PROGRAM__PLURAL),
              })}
            </span>
          );
        },
        sorter: (a, b) => (a.programs || 0) - (b.programs || 0),
      },
      {
        title: i18n.t(k.INCENTIVE__PLURAL),
        dataIndex: 'incentives',
        key: 'incentives',
        render: (incentives) => {
          const isOnlyOneIncentive = incentives === 1;
          return (
            <span>
              {i18n.t(k.GENERIC__X_ITEMS__FORMAT, {
                count: incentives,
                item: i18n.t(
                  isOnlyOneIncentive ? k.INCENTIVE : k.INCENTIVE__PLURAL
                ),
              })}
            </span>
          );
        },
        sorter: (a, b) => (a.incentives || 0) - (b.incentives || 0),
      },
      {
        title: i18n.t(k.STATUS__STATUS),
        dataIndex: 'status',
        key: 'status',
        render: (status: InitiativeStatus) => {
          let label: string;
          switch (status) {
            case InitiativeStatus.PUBLISHED:
              label = k.ACCESS__PUBLISHED;
              break;
            case InitiativeStatus.DRAFT:
              label = k.ACCESS__DRAFT;
              break;
            default:
              label = k.STATUS__UNKNOWN;
              break;
          }
          return (
            <LearnInTag tagStyle={getTagStyle(status)} label={i18n.t(label)} />
          );
        },
        sorter: (a, b) => a.status.localeCompare(b.status),
      },
    ];
  }, []);

  return (
    <>
      <PageTitleBar
        title={i18n.t(k.INITIATIVE__PLURAL)}
        fullWidth={true}
        jsxComponent={
          <LearnInButton
            onClick={openNewInitiatve}
            tag={ButtonTags.Primary}
            data-testid="add-initiative-button">
            {i18n.t(k.INITIATIVE__NEW)}
          </LearnInButton>
        }
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <PageContentLayout fullWidth>
          {data && data.length ? (
            <LearnInTable dataSource={data} columns={programsColumns} />
          ) : (
            <>
              <EmptyInitiatives onClick={openNewInitiatve} />
            </>
          )}
        </PageContentLayout>
      )}
      {showNewInitiativeDrawer && (
        <NewInitiativeContainer
          showDrawer={showNewInitiativeDrawer}
          initiativeId={selectedInitiativeId}
          onCompleted={onCompleted}
        />
      )}
    </>
  );
};

export default Initiatives;
