import {FormInstance} from 'antd';
import {validate} from '../utils';
import {Moment} from 'moment';
import {getFormFieldErrors} from '../../AddEditContent.config';

/**
 * Validates the Due Date when Due Time is set
 */
export function validateDueDate(form: FormInstance) {
  return function (_, dueDate: Moment | null | undefined) {
    const dueTime = form.getFieldValue('dueTime');
    if (dueTime) {
      return validate(!!dueDate, getFormFieldErrors().dueDate);
    }

    return Promise.resolve();
  };
}

/**
 * Validates the Due Time when Due Date is set
 */
export function validateDueTime(form: FormInstance) {
  return function (_, dueTime: Moment | null | undefined) {
    const dueDate = form.getFieldValue('dueDate');

    if (!!dueDate) {
      return validate(!!dueTime, getFormFieldErrors().dueTime);
    }

    return Promise.resolve();
  };
}

/**
 * Validates the Time Zone when Due Date and Due Time are set
 */
export function validateTimeZone(form: FormInstance) {
  return function (_, timeZoneId: string | null | undefined) {
    const dueDate = form.getFieldValue('dueDate');
    const dueTime = form.getFieldValue('dueTime');
    if (!!dueDate && !!dueTime) {
      return validate(!!timeZoneId, getFormFieldErrors().MissingTimeZone);
    }

    return Promise.resolve();
  };
}
