import {LxpContentType} from './../enums';
import {LxpContentId} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: LxpContentId

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLxpContentIdMockData(
  args?: Partial<LxpContentId>
): LxpContentId {
  return {
    contentType: LxpContentType.Unknown,
    id: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LxpContentIdMockData: LxpContentId = {
  contentType: LxpContentType.Unknown,
  id: 1,
};
