// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/TimeIncentive.cs

enum TimeUnitType {
  Hours,
  Days,
  Weeks,
  Months,
}

export default TimeUnitType;
