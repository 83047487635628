import useSearchParamUtils from '@hooks/useSearchParamUtils';
import {useEffect, useState} from 'react';
import {useCustomProgramsUsersPostMutation} from '@generated/hooks';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useNavigate} from 'react-router-dom';
import {
  UserPaths,
  UserProgramsPageTab,
  getCompanyProgramPath,
} from '@utils/ClientPaths';
import {getUrlParameter, removeParameter} from '@utils/urlUtils';

/**
 * Join program via redirect if joinId is in url params
 * @param customProgramId
 */
export const useJoinCustomProgram = (customProgramId) => {
  const joinId = getUrlParameter(location.href, 'joinId');
  const [isLoading, setLoading] = useState(joinId ? true : false);
  const {data} = useGetUserQuery();
  const navigate = useNavigate();
  const joinProgram = useCustomProgramsUsersPostMutation({
    onSuccess: () => {
      removeParameter(window.location.href, 'joinId');
      navigate(getCompanyProgramPath(customProgramId));
    },
    onError: () => {
      setLoading(false);
      navigate(`${UserPaths.ProgramsSearch}/${UserProgramsPageTab.Custom}`);
    },
  });

  useEffect(() => {
    const joinId = getUrlParameter(location.href, 'joinId');
    if (joinId) {
      joinProgram.mutate({
        pathVars: {
          customProgramId,
          userCompanyId: data.userCompanyId,
        },
        payload: {
          joinId: joinId as string,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return isLoading;
};
