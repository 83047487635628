import {ReimbursementTargetProgramVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ReimbursementTargetProgramVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getReimbursementTargetProgramVMMockData(
  args?: Partial<ReimbursementTargetProgramVM>
): ReimbursementTargetProgramVM {
  return {
    linked: faker.datatype.boolean(),
    programTitle: faker.company.catchPhrase(),
    userPlanItemId: faker.datatype.number(),
    userPlanProgramId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ReimbursementTargetProgramVMMockData: ReimbursementTargetProgramVM =
  {
    linked: true,
    programTitle: 'Advanced Web Design',
    userPlanItemId: 1,
    userPlanProgramId: 1,
  };
