import {DiscussionStatus} from './../enums';
import {Discussion} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {MessageMockData, getMessageMockData} from './Message.mock';
import {faker} from '@faker-js/faker';

// Interface: Discussion

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

// WARNING: This interface has a recursive field. You will need to manually add a mock for this field to avoid an infinite loop.
export function getDiscussionMockData(args?: Partial<Discussion>): Discussion {
  return {
    ...getBaseEntityMockData(),
    id: String(faker.datatype.number()),
    messages: [], // This field is recursive
    status: DiscussionStatus.Active,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

// WARNING: This interface has a recursive field. You will need to manually add a mock for this field to avoid an infinite loop.
export const DiscussionMockData: Discussion = {
  ...BaseEntityMockData,
  id: '1',
  messages: [], // This field is recursive
  status: DiscussionStatus.Active,
};
