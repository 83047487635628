import {
  LxpAdminFragment,
  LxpAndAcademiesFragment,
  NewWindowFragment,
  SourceLxpFragment,
  SsoProviderFragment,
} from '@utils/ClientPaths';

/**
 * LXP links append source=lxp, lxpAdmin=true or newWindow=true to the URL to indicate the user is from an external source
 */
export const useUrlContainsIndicator = (
  href: string,
  apolloLxpAdminLayoutFlag = false,
  ignoreSsoProvider = false
) => {
  const indicators = [
    LxpAndAcademiesFragment,
    NewWindowFragment,
    SourceLxpFragment,
  ];
  if (!ignoreSsoProvider) {
    indicators.push(SsoProviderFragment);
  }
  if (!apolloLxpAdminLayoutFlag) {
    indicators.push(LxpAdminFragment);
  }

  const hrefCaseNormalized = href.toLowerCase();
  for (let i = 0; i < indicators.length; i++) {
    const indicator = indicators[i];
    const indicatorCaseNormalized = indicator.toLowerCase();
    if (hrefCaseNormalized.indexOf(indicatorCaseNormalized) > -1) {
      return true;
    }
  }

  return false;
};
