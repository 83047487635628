import {useRef} from 'react';
import {i18n, k} from '@i18n/translate';
import {Col, Typography, Form, Rate, Row} from 'antd';
import {HeartFilled} from '@ant-design/icons';
import {FormInstance} from 'antd/lib/form';
import moment from 'moment';
import {mapValueToProgressReportSummary} from '../../../../../../utils/enumMapping/ProgressReportSummaryMapper';
import {LearnInInput} from '../../../../../../components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {DATE_FORMAT} from '@utils/timeUtils';

interface ManagerReportProgressDrawerProps {
  showDrawer: boolean;
  closeDrawer: () => void;
  id: number;
  employeeName: string | undefined;
  programTitle: string;
  rating: number;
  skills: string;
  summary: number;
  submittedDate: string;
}

const ViewProgressReportDrawer = ({
  showDrawer,
  closeDrawer,
  id,
  employeeName,
  programTitle,
  rating,
  skills,
  summary,
  submittedDate,
}: ManagerReportProgressDrawerProps) => {
  const formRef = useRef<FormInstance>(null);

  return (
    <LearnInDrawer
      key={id}
      responsiveSize={625}
      placement="bottom"
      visible={showDrawer}
      onClose={() => {
        formRef.current.resetFields();
        closeDrawer();
      }}>
      <Row className="progress-drawer-content-layout display-flex">
        <Col
          style={{height: 560, padding: '50px'}}
          xs={24}
          md={7}
          className="progress-drawer-paragraph">
          <Row>
            <Typography.Title>{i18n.t(k.PROGRESS__TAKE_NOTE)}</Typography.Title>
          </Row>
          <Row>
            <Typography.Text>
              {i18n.t(k.PROGRESS__PROVIDE_NOTES)}
            </Typography.Text>
          </Row>
        </Col>
        <Col xs={24} md={7} className="progress-drawer-form-layout ">
          <Form className="form-drawer" ref={formRef} layout="vertical">
            {employeeName && (
              <Form.Item
                className="lb-application-drawer"
                name="note"
                label={i18n.t(k.NAME).toLocaleUpperCase()}
                initialValue={employeeName}>
                <LearnInInput tag={InputTags.PRIMARY} readOnly />
              </Form.Item>
            )}
            <Form.Item
              className="lb-application-drawer"
              name="title"
              label={i18n.t(k.GENERIC__TITLE).toLocaleUpperCase()}
              initialValue={programTitle}>
              <LearnInInput tag={InputTags.PRIMARY} readOnly />
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="date"
              label={i18n.t(k.DATE).toLocaleUpperCase()}
              initialValue={moment(submittedDate).format(
                DATE_FORMAT.MONTH_DAY_YEAR_PADDED
              )}>
              <LearnInInput tag={InputTags.PRIMARY} readOnly />
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="summary"
              label={i18n.t(k.GENERIC__SUMMARY).toLocaleUpperCase()}
              initialValue={mapValueToProgressReportSummary(summary)}>
              <LearnInInput tag={InputTags.PRIMARY} readOnly />
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="skills"
              label={i18n.t(k.GENERIC__SKILL__PLURAL).toLocaleUpperCase()}
              initialValue={skills}>
              <LearnInInput tag={InputTags.PRIMARY} readOnly />
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="ratings"
              label={i18n.t(k.GENERIC__RATING__PLURAL).toLocaleUpperCase()}
              initialValue={rating}
              shouldUpdate={false}>
              <Rate
                disabled
                character={<HeartFilled aria-hidden="true" />}
                style={{color: '#ff6d85'}}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </LearnInDrawer>
  );
};
export default ViewProgressReportDrawer;
