import {CustomProgramStatus} from './../enums';
import {CustomProgramCohortAdminVM} from './../interfaces';
import {
  CohortParticipantPreviewVMMockData,
  getCohortParticipantPreviewVMMockData,
} from './CohortParticipantPreviewVM.mock';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramCohortAdminVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramCohortAdminVMMockData(
  args?: Partial<CustomProgramCohortAdminVM>
): CustomProgramCohortAdminVM {
  return {
    cohortTitle: faker.company.catchPhrase(), // optional
    endDateUtc: String(faker.date.past()),
    id: faker.datatype.number(), // optional
    parentCustomProgramId: faker.datatype.number(),
    participantCount: faker.datatype.number(),
    participantPreview: [getCohortParticipantPreviewVMMockData()],
    recommendationPermissionCompanyIds: [faker.datatype.number()],
    restrictedAccess: faker.datatype.boolean(),
    startDateUtc: String(faker.date.past()),
    status: CustomProgramStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramCohortAdminVMMockData: CustomProgramCohortAdminVM = {
  cohortTitle: 'Advanced Web Design', // optional
  endDateUtc: '2023-03-29T22:17:19.738072',
  id: 1, // optional
  parentCustomProgramId: 1,
  participantCount: 5,
  participantPreview: [CohortParticipantPreviewVMMockData],
  recommendationPermissionCompanyIds: [1],
  restrictedAccess: true,
  startDateUtc: '2023-03-29T22:17:19.738072',
  status: CustomProgramStatus.Draft,
};
