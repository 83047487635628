import {COLORS} from '@utils/constants';
export const ProgramFinderIcon = ({...rest}) => {
  const fillColor = rest?.fill || COLORS.Purple800;
  const bgColor = rest?.backgroundColor || COLORS.Purple200;
  const size = rest?.size || 24;
  const circleRadius = rest?.circleSize || 14;
  const translationValue = (circleRadius * 2 - 32) / 2;

  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${circleRadius * 2} ${circleRadius * 2}`}
      {...rest}>
      <circle
        cx={circleRadius}
        cy={circleRadius}
        r={circleRadius}
        fill={bgColor}
      />
      <path
        d="M30.6643 4.73698C31.1203 4.85556 31.1089 5.50891 30.649 5.61141L27.4343 6.32789C27.2699 6.36454 27.1398 6.49053 27.0975 6.65407L26.2771 9.82776C26.1589 10.2851 25.5074 10.2737 25.4052 9.81246L24.6909 6.58828C24.6543 6.42335 24.5287 6.29288 24.3656 6.25048L21.2013 5.42767C20.7453 5.30909 20.7567 4.65574 21.2165 4.55325L24.4312 3.83676C24.5957 3.80011 24.7258 3.67413 24.7681 3.51058L25.5884 0.336894C25.7067 -0.120469 26.3581 -0.109034 26.4603 0.352199L27.1747 3.57637C27.2112 3.7413 27.3368 3.87178 27.4999 3.91418L30.6643 4.73698ZM14.8344 7.74007C15.1601 7.82477 15.152 8.29145 14.8235 8.36466L12.5273 8.87643C12.4098 8.90261 12.3169 8.9926 12.2867 9.10942L11.7007 11.3763C11.6162 11.703 11.1509 11.6949 11.0779 11.3654L10.5677 9.06242C10.5416 8.94462 10.4518 8.85142 10.3354 8.82114L8.07509 8.23342C7.74937 8.14872 7.75751 7.68204 8.08599 7.60883L10.3822 7.09705C10.4997 7.07088 10.5926 6.98089 10.6228 6.86407L11.2088 4.59715C11.2932 4.27046 11.7585 4.27863 11.8315 4.60808L12.3418 6.91106C12.3679 7.02887 12.4576 7.12206 12.5741 7.15235L14.8344 7.74007ZM19.5161 9.13772L22.7944 12.4258L3.27832 31.9998L0 28.7117L19.5161 9.13772ZM19.5161 11.1913L16.0824 14.6352L17.3132 15.8697L20.7469 12.4258L19.5161 11.1913ZM30.7494 17.0768C31.0779 17.0036 31.086 16.5369 30.7603 16.4522L28.5 15.8645C28.3835 15.8342 28.2938 15.741 28.2677 15.6232L27.7574 13.3202C27.6844 12.9907 27.2191 12.9826 27.1347 13.3093L26.5487 15.5762C26.5185 15.693 26.4256 15.783 26.3081 15.8092L24.0119 16.3209C23.6834 16.3942 23.6753 16.8608 24.001 16.9455L26.2613 17.5333C26.3777 17.5635 26.4675 17.6567 26.4936 17.7745L27.0038 20.0775C27.0768 20.407 27.5421 20.4151 27.6266 20.0885L28.2126 17.8215C28.2428 17.7047 28.3357 17.6147 28.4532 17.5885L30.7494 17.0768Z"
        fill={fillColor}
        transform={`translate(${translationValue}, ${translationValue})`}
      />
    </svg>
  );
};
