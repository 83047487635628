import {useEffect} from 'react';
import {Outlet, Route, Routes, useNavigate, useParams} from 'react-router-dom';
import ManagerPermissionsPage from './ManagedPermissionsPage';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import useTreatment from '@hooks/useTreatment';
import {
  AcademyScreens,
  CustomProgramScreens,
  getAcademyUserDrawerManagePermissionsScreenNavItemUrlPath,
  getManagerPermissionsAcademyAdminDrawerRoute,
  getManagerPermissionsAcademyUserDrawerRoute,
  getManagerPermissionsCustomProgramAdminDrawerRoute,
  getManagerPermissionsCustomProgramUserDrawerRoute,
  getManagerPermissionsRoute,
  getPermissionCohortAdminDrawerRoute,
  ManagedPermissionsPageTabKey,
  UserPaths,
} from '@utils/ClientPaths';
import AcademySetupDrawer from '@components/admin/pages/academies/Setup/Setup.container';
import AcademyDrawerContainer from '@components/user/pages/academy/AcademyDrawer/AcademyDrawer.container';
import CustomProgramUserDrawerWithRouting from '@blocks/CustomProgramUserDrawer/CustomProgramUserDrawerWithRouting.container';
import CustomProgramAdminDrawer from '@components/admin/pages/programs/customPrograms/CustomProgramAdminDrawerWithRouting.container';
import {
  useAcademyPermissionVMsQuery,
  useCustomProgramPermissionVMsQuery,
} from '@hooks/apiEndpoints/permission/queries';
import {ManagerPermissionsTableRow} from '@components/user/pages/managedPermissions/ManagedPermissionsTable';
import {ContributorPermissionType} from '@models/clientEnums';
import {CustomizableLearningCategory} from '@generated/enums';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import CohortAdminPageDrawerContainerWithRouting from '@components/admin/pages/programs/customPrograms/cohorts/CohortAdminPageDrawerWithRouting.container';
import {i18n, k} from '@i18n/translate';
import useSetPageTitle from '@hooks/useSetPageTitle';

/*
|--------------------------------------------------------------------------
| Page Container
|--------------------------------------------------------------------------
*/

function ManagerPermissionsPageContainer({title}: {title?: string}) {
  const isAcademiesOn =
    useTreatment(FeatureFlagExperiments.Academies) === FeatureFlagTreatments.On;

  const {tab} = useParams();
  const navigate = useNavigate();
  const handleChangeTabs = (key: ManagedPermissionsPageTabKey) => {
    navigate(getManagerPermissionsRoute(key));
  };

  const {data: currentUser} = useGetUserQuery();

  useSetPageTitle(title);

  // Get manager permission table data
  const {
    data: academyPermissions,
    isLoading: isLoadingAcademyPermissions,
    invalidateExact: invalidateAcademyPermissions,
  } = useAcademyPermissionVMsQuery(null, {
    enabled: tab === ManagedPermissionsPageTabKey.Academies && isAcademiesOn,
  });

  const formattedAcademyPermissions: ManagerPermissionsTableRow[] =
    academyPermissions?.map((permission) => ({
      ...permission,
      name: permission.academyName,
      id: permission.academyId,
      userCompanyId: currentUser?.userCompanyId,
    }));

  const {
    data: customProgramPermissions,
    isLoading: isLoadingCustomProgramPermissions,
    invalidateExact: invalidateProgramPermissions,
  } = useCustomProgramPermissionVMsQuery(null, {
    enabled: tab === ManagedPermissionsPageTabKey.Programs,
  });

  const formattedCustomProgramPermissions: ManagerPermissionsTableRow[] =
    customProgramPermissions?.map((permission) => ({
      ...permission,
      name: permission.programName,
      id: permission.customProgramId,
      userCompanyId: currentUser?.userCompanyId,
    }));

  const managerPermissionsTableDataSource =
    tab === ManagedPermissionsPageTabKey.Academies
      ? formattedAcademyPermissions
      : tab === ManagedPermissionsPageTabKey.Programs
        ? formattedCustomProgramPermissions
        : [];

  // Ensure url path is valid
  const academiesTableVisible = isAcademiesOn && !!academyPermissions?.length;
  const customProgramTableVisible = !!customProgramPermissions?.length;

  const ensureValidUrlPath = () => {
    // Do not handle routing until permissions has loaded
    if (isAcademiesOn && isLoadingAcademyPermissions) {
      return;
    }
    if (isLoadingCustomProgramPermissions) {
      return;
    }
    // If user navigated here by url, but there are no permissions,
    // this page doesn't exist and reroute user to User Plan
    if (!academiesTableVisible && !customProgramTableVisible) {
      return navigate(UserPaths.ManagePlan);
    }
    // Handle user navigating to a table that isn't visible
    if (
      !academiesTableVisible &&
      tab === ManagedPermissionsPageTabKey.Academies
    ) {
      return navigate(
        getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Programs)
      );
    }
    if (
      !customProgramTableVisible &&
      tab === ManagedPermissionsPageTabKey.Programs
    ) {
      return navigate(
        getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Academies)
      );
    }
    // Handle user navigating to an invalid tab key
    if (
      Object.values(ManagedPermissionsPageTabKey).every((key) => tab !== key)
    ) {
      return navigate(
        getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Academies)
      );
    }
  };

  useEffect(ensureValidUrlPath, [
    tab,
    academiesTableVisible,
    customProgramTableVisible,
    isLoadingAcademyPermissions,
    isLoadingCustomProgramPermissions,
    isAcademiesOn,
    navigate,
  ]);

  // Table event handlers
  const handleClickTitle = (
    id: number,
    learningCategory: CustomizableLearningCategory
  ) => {
    switch (learningCategory) {
      case CustomizableLearningCategory.CustomProgram:
        return navigate(
          getManagerPermissionsCustomProgramUserDrawerRoute(
            id,
            CustomProgramScreens.Curriculum
          )
        );
      case CustomizableLearningCategory.Academy:
        return navigate(
          getManagerPermissionsAcademyUserDrawerRoute(
            id,
            CustomProgramScreens.Content
          )
        );
    }
  };

  const handleClickIcon = (
    id: number,
    learningCategory: CustomizableLearningCategory,
    permissionType: ContributorPermissionType,
    parentCustomProgramId: number | undefined
  ) => {
    switch (learningCategory) {
      case CustomizableLearningCategory.Academy:
        switch (permissionType) {
          case ContributorPermissionType.canEdit:
            return navigate(
              getManagerPermissionsAcademyAdminDrawerRoute(
                id,
                AcademyScreens.setup
              )
            );
          case ContributorPermissionType.canReviewProject:
            return navigate(
              getManagerPermissionsAcademyUserDrawerRoute(
                id,
                CustomProgramScreens.Projects
              )
            );
          case ContributorPermissionType.canViewReport:
            return navigate(
              getManagerPermissionsAcademyUserDrawerRoute(
                id,
                CustomProgramScreens.Reports
              )
            );
          default:
            return;
        }
      case CustomizableLearningCategory.CustomProgram:
        switch (permissionType) {
          case ContributorPermissionType.canEdit:
            if (!!parentCustomProgramId) {
              return navigate(
                getPermissionCohortAdminDrawerRoute(id, parentCustomProgramId)
              );
            } else {
              return navigate(
                getManagerPermissionsCustomProgramAdminDrawerRoute(
                  id,
                  CustomProgramScreens.Setup
                )
              );
            }
          case ContributorPermissionType.canReviewProject:
            return navigate(
              getManagerPermissionsCustomProgramUserDrawerRoute(
                id,
                CustomProgramScreens.Projects
              )
            );
          case ContributorPermissionType.canViewReport:
            return navigate(
              getManagerPermissionsCustomProgramUserDrawerRoute(
                id,
                CustomProgramScreens.Reports
              )
            );
        }
    }
  };

  const handleCloseCustomProgramDrawers = () => {
    invalidateProgramPermissions();
    navigate(getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Programs));
  };

  const handleCloseAcademyDrawers = () => {
    invalidateAcademyPermissions();
    navigate(
      getManagerPermissionsRoute(ManagedPermissionsPageTabKey.Academies)
    );
  };

  const isLoadingTableData =
    (tab === ManagedPermissionsPageTabKey.Academies &&
      isLoadingAcademyPermissions) ||
    (tab === ManagedPermissionsPageTabKey.Programs &&
      isLoadingCustomProgramPermissions);

  return (
    <>
      <ManagerPermissionsPage
        showEmptyScreen={
          !managerPermissionsTableDataSource?.length && !isLoadingTableData
        }
        isLoading={isLoadingTableData}
        onClickTableIcon={handleClickIcon}
        onClickTitle={handleClickTitle}
        managerPermissionsTableDataSource={managerPermissionsTableDataSource}
        academiesTableVisible={academiesTableVisible}
        customProgramTableVisible={customProgramTableVisible}
        showAcademiesTable={tab === ManagedPermissionsPageTabKey.Academies}
        showProgramsTable={tab === ManagedPermissionsPageTabKey.Programs}
        onChangeTabs={handleChangeTabs}
      />
      <Routes>
        {tab === ManagedPermissionsPageTabKey.Academies && (
          <>
            <Route
              path=":academyId/configure/:screen/*"
              element={
                <AcademySetupDrawer
                  title={i18n.t(k.GENERIC__SETUP)}
                  hidePreviewAcademyLink
                  onClose={handleCloseAcademyDrawers}
                  getScreenNavItemUrlPath={
                    getManagerPermissionsAcademyAdminDrawerRoute
                  }
                />
              }
            />
            <Route
              path=":academyId/view/*"
              element={
                <AcademyDrawerContainer
                  title={i18n.t(k.GENERIC__OVERVIEW)}
                  isActivePlanItem={false}
                  hideEditAcademyLink={false}
                  getScreenNavItemUrlPath={
                    getAcademyUserDrawerManagePermissionsScreenNavItemUrlPath
                  }
                  onClose={handleCloseAcademyDrawers}
                />
              }
            />
          </>
        )}
        {tab === ManagedPermissionsPageTabKey.Programs && (
          <>
            <Route
              path=":customProgramId/view/*"
              element={
                <CustomProgramUserDrawerWithRouting
                  title={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
                  isActivePlanItem={false}
                  getScreenNavItemUrlPath={
                    getManagerPermissionsCustomProgramUserDrawerRoute
                  }
                  onClose={handleCloseCustomProgramDrawers}
                />
              }
            />
            <Route
              path=":customProgramId/configure/:screen"
              element={
                <CustomProgramAdminDrawer
                  title={i18n.t(k.PROGRAM)}
                  onClose={handleCloseCustomProgramDrawers}
                  getScreenNavItemUrlPath={
                    getManagerPermissionsCustomProgramAdminDrawerRoute
                  }
                  visible
                />
              }
            />
            <Route
              path=":customProgramId/cohort/:cohortId"
              element={
                <CohortAdminPageDrawerContainerWithRouting
                  title={i18n.t(k.COHORT)}
                  onClose={handleCloseCustomProgramDrawers}
                  visible
                />
              }
            />
          </>
        )}
      </Routes>
      <Outlet />
    </>
  );
}

export default ManagerPermissionsPageContainer;
