import {InfoCircleOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import React from 'react';
import styled from 'styled-components';

const Trigger = styled.div<{width?: number}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({width}) => (width ? `${width}px` : 'unset')};
`;

const ContentWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)<{
  isHovering: boolean;
}>`
  font-size: 1rem;
  color: ${({isHovering}) => (isHovering ? COLORS.Blue800 : COLORS.Neutral500)};
`;

const StyledLearnInTooltip = styled(LearnInTooltip)`
  margin-left: 8px;
`;

const WithTooltip = (props: {
  title: string;
  children: React.ReactNode;
  width?: number;
}) => {
  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <Trigger
      tabindex="0"
      width={props.width}
      onFocus={() => setIsHovering(true)}
      onBlur={() => setIsHovering(false)}
      onPointerEnter={() => setIsHovering(true)}
      onPointerLeave={() => setIsHovering(false)}>
      <ContentWrapper>{props.children}</ContentWrapper>
      <StyledLearnInTooltip title={props.title} open={isHovering}>
        <StyledInfoCircleOutlined isHovering={isHovering} />
      </StyledLearnInTooltip>
    </Trigger>
  );
};

export default WithTooltip;
