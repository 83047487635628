import {i18n, k} from '@i18n/translate';
import {Input} from 'antd';

import {COLORS} from '@utils/constants';

interface MembersDrawerSearchProps {
  onSearch: (term: string) => void;
}

export function MembersDrawerSearch({onSearch}: MembersDrawerSearchProps) {
  return (
    <Input.Search
      allowClear
      onSearch={onSearch}
      placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
        item: i18n.t(k.COHORT),
      })}
      style={{
        fontSize: '1rem',
        padding: '16px',
        backgroundColor: COLORS.Neutral50,
      }}
    />
  );
}
