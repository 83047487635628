import {CloseCircleOutlined} from '@ant-design/icons';
import styled, {DefaultTheme} from 'styled-components';

interface ClickToUnenrollButtonProps {
  label: string;
  onClick: () => void;
}

const UnenrollButtonContainer = styled.div`
  margin: 24px 16px 16px;
`;

const UnenrollButton = styled.button`
  display: flex;
  padding: 6px 18px;
  font-size: 1rem;
  border-radius: 100px;
  font-weight: 400;
  width: 100%;
  border: 1px solid
    ${(props: DefaultTheme) => props?.theme?.itemDefaultTextNavColor};
  background: ${(props: DefaultTheme) =>
    props?.theme?.itemHoverBackgroundNavColor};
  color: ${(props: DefaultTheme) =>
    props?.theme?.itemDefaultTextNavColor} !important;
  text-decoration: none;
  &:hover {
    background: ${(props: DefaultTheme) =>
      props?.theme?.itemSelectedBackgroundNavColor};
    border: 1px solid
      ${(props: DefaultTheme) => props?.theme?.itemSelectedBackgroundNavColor};
  }
  &:focus {
    outline: 2px solid
      ${(props: DefaultTheme) => props?.theme?.itemDefaultTextNavColor};
  }
  justify-content: center;
`;

export function ClickToUnenrollButton({
  label,
  onClick,
}: ClickToUnenrollButtonProps) {
  return (
    <UnenrollButtonContainer>
      <UnenrollButton
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer">
        <CloseCircleOutlined
          style={{marginRight: '8px', fontSize: '1.5rem'}}
          className="ctunenroll-button-icon"
        />
        {label}
      </UnenrollButton>
    </UnenrollButtonContainer>
  );
}
