import LoadingImage from './loadingImage';
import StyledImage from './styledImage';
import Quill from 'quill';

class ImageUploader {
  quill: Quill;
  options: any;
  range: any;
  placeholderDelta: any;
  fileHolder: HTMLInputElement;

  constructor(quill: Quill, options: any) {
    this.quill = quill;
    this.options = options;
    this.range = null;
    this.placeholderDelta = null;

    if (typeof this.options.upload !== 'function') {
      console.warn(
        '[Missing config] upload function that returns a promise is required'
      );
    }

    const toolbar = this.quill.getModule('toolbar');
    if (toolbar) {
      toolbar.addHandler('image', this.selectLocalImage.bind(this));
    }

    this.handlePaste = this.handlePaste.bind(this);

    this.quill.root.addEventListener('paste', this.handlePaste, false);
  }

  selectLocalImage() {
    this.quill.focus();
    this.range = this.quill.getSelection();
    this.fileHolder = document.createElement('input');
    this.fileHolder.setAttribute('type', 'file');
    this.fileHolder.setAttribute('accept', 'image/*');
    this.fileHolder.setAttribute('style', 'visibility:hidden');

    this.fileHolder.onchange = this.fileChanged.bind(this);

    document.body.appendChild(this.fileHolder);

    this.fileHolder.click();

    window.requestAnimationFrame(() => {
      document.body.removeChild(this.fileHolder);
    });
  }

  // This was disabled to prevent multiple drag-and-drop contexts from existing on the page.
  // Should be fixed with ticket number ACA-3670.

  // handleDrop(evt: DragEvent) {
  //   if (
  //     evt.dataTransfer &&
  //     evt.dataTransfer.files &&
  //     evt.dataTransfer.files.length
  //   ) {
  //     evt.stopPropagation();
  //     evt.preventDefault();
  //     const selection = document.getSelection();
  //     const range = this.getCaretRangeFromPoint(evt.clientX, evt.clientY);
  //     if (selection && range) {
  //       selection.setBaseAndExtent(
  //         range.startContainer,
  //         range.startOffset,
  //         range.startContainer,
  //         range.startOffset
  //       );
  //     }
  //
  //     this.quill.focus();
  //     this.range = this.quill.getSelection();
  //     const file = evt.dataTransfer.files[0];
  //
  //     setTimeout(() => {
  //       this.quill.focus();
  //       this.range = this.quill.getSelection();
  //       this.readAndUploadFile(file);
  //     }, 0);
  //   }
  // }

  handlePaste(evt: ClipboardEvent) {
    const clipboard = evt.clipboardData;

    // IE 11 is .files other browsers are .items
    if (clipboard && (clipboard.items || clipboard.files)) {
      const items = clipboard.items || clipboard.files;
      const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;

      for (let i = 0; i < items.length; i++) {
        if (IMAGE_MIME_REGEX.test(items[i].type)) {
          const file =
            items[i] instanceof File
              ? (items[i] as File)
              : (items[i] as DataTransferItem).getAsFile();

          if (file) {
            this.quill.focus();
            this.range = this.quill.getSelection();
            evt.preventDefault();
            setTimeout(() => {
              this.quill.focus();
              this.range = this.quill.getSelection();
              this.readAndUploadFile(file);
            }, 0);
          }
        }
      }
    }
  }

  getCaretRangeFromPoint(x: number, y: number) {
    if (typeof document.caretRangeFromPoint !== 'undefined') {
      return document.caretRangeFromPoint(x, y);
    } else if (
      typeof (document as any).caretPositionFromPoint !== 'undefined'
    ) {
      const position = (document as any).caretPositionFromPoint(x, y);
      if (position) {
        const range = document.createRange();
        range.setStart(position.offsetNode, position.offset);
        range.setEnd(position.offsetNode, position.offset);
        return range;
      }
    }
    return null;
  }

  readAndUploadFile(file: File) {
    let isUploadReject = false;

    const fileReader = new FileReader();

    fileReader.addEventListener(
      'load',
      () => {
        if (!isUploadReject) {
          const base64ImageSrc = fileReader.result as string;
          this.insertBase64Image(base64ImageSrc);
        }
      },
      false
    );

    if (file) {
      fileReader.readAsDataURL(file);
    }

    this.options.upload(file).then(
      (imageUrl: string) => {
        this.insertToEditor(imageUrl);
      },
      (error: any) => {
        isUploadReject = true;
        this.removeBase64Image();
        console.warn(error);
      }
    );
  }

  fileChanged() {
    const file = this.fileHolder.files[0];
    this.readAndUploadFile(file);
  }

  insertBase64Image(url: string) {
    const range = this.range;

    this.placeholderDelta = this.quill.insertEmbed(
      range.index,
      LoadingImage.blotName,
      `${url}`,
      'user'
    );
  }

  insertToEditor(url: string) {
    const range = this.range;

    const lengthToDelete = this.calculatePlaceholderInsertLength();

    // Delete the placeholder image
    this.quill.deleteText(range.index, lengthToDelete, 'user');
    // Insert the server saved image with style
    this.quill.insertEmbed(range.index, StyledImage.blotName, `${url}`, 'user');

    // Increment the range index
    range.index++;

    // Set the new selection
    this.quill.setSelection(range, 'user');

    // Remove any remaining 'image-uploading' placeholders
    this.removeAllUploadingImages();
  }

  removeAllUploadingImages() {
    const uploadingImages =
      this.quill.root.querySelectorAll('.image-uploading');
    uploadingImages.forEach((uploadingSpan) => {
      // Move each child of the span to its parent node
      while (uploadingSpan.firstChild) {
        uploadingSpan.parentNode.insertBefore(
          uploadingSpan.firstChild,
          uploadingSpan
        );
      }
      // Remove the now-empty span
      uploadingSpan.parentNode.removeChild(uploadingSpan);
    });
  }

  // The length of the insert delta from insertBase64Image can vary depending on what part of the line the insert occurs
  calculatePlaceholderInsertLength() {
    return this.placeholderDelta.ops.reduce(
      (accumulator: number, deltaOperation: any) => {
        if (deltaOperation.hasOwnProperty('insert')) accumulator++;

        return accumulator;
      },
      0
    );
  }

  removeBase64Image() {
    // First, remove the image at the original range
    const lengthToDelete = this.calculatePlaceholderInsertLength();
    this.quill.deleteText(this.range.index, lengthToDelete, 'user');

    // Now, search through the entire editor's content for any other 'image-uploading' elements
    const uploadingImages =
      this.quill.root.querySelectorAll('.image-uploading');
    uploadingImages.forEach((uploadingImage) => {
      const blot = Quill.find(uploadingImage);
      if (blot && blot.domNode) {
        const blotRange = this.quill.getIndex(blot);
        this.quill.deleteText(blotRange, 1, 'user');
      }
    });
  }
}

(window as any).ImageUploader = ImageUploader;
export default ImageUploader;
