import {ProgramProgressPreviewVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ProgramProgressPreviewVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramProgressPreviewVMMockData(
  args?: Partial<ProgramProgressPreviewVM>
): ProgramProgressPreviewVM {
  return {
    date: String(faker.date.past()),
    id: faker.datatype.number(),
    status: faker.word.noun(),
    title: faker.company.catchPhrase(),
    week: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramProgressPreviewVMMockData: ProgramProgressPreviewVM = {
  date: '2023-03-29T22:17:19.738072',
  id: 1,
  status: 'string',
  title: 'Advanced Web Design',
  week: 123,
};
