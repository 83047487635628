import React, {useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInTable, {
  basicSorter,
  stringSorter,
  renderProgressBar,
  renderCompleteStatus,
  IDataPanelItem,
  dateTimeSorter,
  ColumnAlignment,
  renderDateTime,
  TooltipCol,
} from '@components/reusable/LearnInTable';
import {TableCellButtonLink} from '@components/reusable/TableCellButtonLink/TableCellButtonLink';
import {AcademyStepStatus} from '@models/serverModels';
import {RsvpStatus, ContentType} from '@generated/enums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {startCase} from 'lodash';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div``;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IProgressTableDataSourceRow {
  id: number;
  title: string;
  progressPercentage?: number;
  completedOn?: string;
  rsvpStatus?: RsvpStatus;
  type?: ContentType;
  status?: AcademyStepStatus;
}

export interface IProgressTableProps {
  columnTitle: string;
  dataSource: IProgressTableDataSourceRow[];
  onClickRow?: (record?: any, rowIndex?: number) => void;
  nestedTable?: {
    dataSource: Record<number, IProgressTableDataSourceRow[]>;
    onExpand?: (expanded: boolean, record: IProgressTableDataSourceRow) => void;
    onClickRow?: (
      record?: IProgressTableDataSourceRow,
      rowIndex?: number
    ) => void;
    showCompleteStatus?: boolean;
  };
  dataPanelItems?: IDataPanelItem[];
  showCompleteStatus?: boolean;
  searchControl?: React.ReactElement;
  noDataTableProps?: {
    noDataStyle: React.CSSProperties;
    noDataHeader?: string;
    noDataSubheader?: string;
  };
  canShowNudgeColumn: boolean;
  onOpenNudgeEmailPreview: (data: {
    employeeName: string;
    userCompanyId: number;
  }) => void;
  showEmailColumn?: boolean;
}

function ProgressTable({
  columnTitle,
  dataSource,
  onClickRow,
  nestedTable,
  dataPanelItems,
  showCompleteStatus,
  searchControl,
  noDataTableProps: searchNoDataProps,
  canShowNudgeColumn,
  onOpenNudgeEmailPreview,
  showEmailColumn,
}: IProgressTableProps) {
  const columns = useMemo(() => {
    return [
      showEmailColumn
        ? TooltipCol({
            title: startCase(columnTitle),
            tooltipDataIndex: 'email',
            dataIndex: 'title',
          })
        : {
            title: columnTitle,
            dataIndex: 'title',
            key: 'title',
            render: (text, row) =>
              !onClickRow ? (
                <>{text}</>
              ) : (
                <TableCellButtonLink onClick={() => onClickRow(row)}>
                  {text}
                </TableCellButtonLink>
              ),
            sorter: stringSorter<any>('title'),
          },
      showCompleteStatus
        ? {
            title: i18n.t(k.PROGRESS),
            dataIndex: 'completedOn',
            key: 'completedOn',
            sorter: dateTimeSorter('completedOn'),
            render: renderCompleteStatus,
            width: '50%',
          }
        : {
            title: i18n.t(k.PROGRESS),
            dataIndex: 'progressPercentage',
            key: 'progressPercentage',
            sorter: basicSorter<any>('progressPercentage'),
            render: renderProgressBar,
            width: '50%',
          },
      canShowNudgeColumn && {
        title: i18n.t(k.GENERIC__LAST_NUDGE_SENT),
        dataIndex: 'lastCustomProgramNudgeSent',
        key: 'lastCustomProgramNudgeSent',
        sorter: dateTimeSorter('lastCustomProgramNudgeSent'),
        render: (date: string) => {
          return date ? renderDateTime()(date) : '-';
        },
      },
      canShowNudgeColumn && {
        title: '',
        key: null,
        align: ColumnAlignment.Right,
        render: (data) => {
          return (
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
              <LearnInButton
                tag={ButtonTags.SecondaryRemovedBorder}
                style={{fontWeight: 400}}
                onClick={(ev) => {
                  ev.stopPropagation();
                  onOpenNudgeEmailPreview({
                    employeeName: data.employeeName,
                    userCompanyId: data.employeeId,
                  });
                }}>
                {i18n.t(k.CTA__SEND_NUDGE)}
              </LearnInButton>
            </div>
          );
        },
      },
    ].filter(Boolean);
  }, [
    canShowNudgeColumn,
    columnTitle,
    onClickRow,
    onOpenNudgeEmailPreview,
    showCompleteStatus,
    showEmailColumn,
  ]);

  const nestedColumns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'title',
        key: 'title',
        render: (text, row) =>
          !onClickRow ? (
            <>{text}</>
          ) : (
            <TableCellButtonLink onClick={() => nestedTable?.onClickRow?.(row)}>
              {text}
            </TableCellButtonLink>
          ),
        sorter: stringSorter<any>('title'),
      },
      nestedTable?.showCompleteStatus
        ? {
            title: i18n.t(k.PROGRESS),
            dataIndex: 'completedOn',
            key: 'completedOn',
            sorter: dateTimeSorter('completedOn'),
            render: renderCompleteStatus,
            width: '50%',
          }
        : {
            title: i18n.t(k.PROGRESS),
            dataIndex: 'progressPercentage',
            key: 'progressPercentage',
            sorter: basicSorter<any>('progressPercentage'),
            render: renderProgressBar,
            width: '50%',
          },
    ];
  }, []);

  return (
    <Container>
      <LearnInTable
        hasContainer={true}
        dataPanelItems={dataPanelItems}
        dataSource={dataSource}
        columns={columns}
        onClickRow={onClickRow}
        customContent={searchControl}
        noDataHeader={searchNoDataProps?.noDataHeader}
        noDataSubheader={searchNoDataProps?.noDataSubheader}
        noDataStyle={searchNoDataProps?.noDataStyle}
        nestedTable={
          !!nestedTable
            ? {
                ...nestedTable,
                columns: nestedColumns,
                showHeader: false,
              }
            : null
        }
      />
    </Container>
  );
}

export default ProgressTable;
