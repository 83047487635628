import {i18n} from '@i18n/translate';
import {
  AcmOptions,
  BaseOptions,
  useApolloLayout_Acm,
  UseApolloLayoutResponse,
} from '@learnin-inc/apollo-react-data-access';
import {isLocal} from '@utils/environment-helpers';

import usePathInfo from '@hooks/usePathInfo';
import {useCallback, useEffect, useState} from 'react';
import {useAcmOptions} from './useAcmOptions';
import {useLxpFeatureFlags} from './useLxpFeatureFlags';

export const useApolloLayoutForAcm = (
  isLxpAdmin: boolean
): readonly [UseApolloLayoutResponse, AcmOptions] => {
  const {isAdminPath} = usePathInfo();
  const featureFlags = useLxpFeatureFlags(!isLocal && isLxpAdmin);

  const buildOptions = useCallback(
    () => ({
      isLxpApiEnabled: !isLocal && isLxpAdmin,
      translate: i18n.t,
      featureFlags,
      isAdminPath,
    }),
    [isAdminPath, featureFlags, isLxpAdmin]
  );

  const [options, setOptions] = useState<BaseOptions>(buildOptions);
  const acmOptions = useAcmOptions(options);

  useEffect(() => {
    if (featureFlags) setOptions(buildOptions());
  }, [buildOptions, featureFlags]);

  const aspect = useApolloLayout_Acm(acmOptions);

  return [aspect, acmOptions] as const;
};
