import {AdminPaths, UserPaths} from '@utils/ClientPaths';

export function isShowingRichTextEditor({pathname}): boolean {
  if (pathname.includes(AdminPaths.Academies)) {
    return true;
  } else if (pathname.includes(AdminPaths.Programs)) {
    return true;
  } else if (pathname.includes(UserPaths.Permissions)) {
    return true;
  }
  return false;
}
