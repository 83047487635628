import {TagComponetProps} from './Tag.model';
import './Tag.scss';

export const Tag = ({label, dataTestId, tagColor}: TagComponetProps) => {
  return (
    <div className={`tag tag--${tagColor}`} data-testid={dataTestId || ''}>
      {label}
    </div>
  );
};
