import styled from 'styled-components';
import {Breakpoints} from '@utils/responsiveUtils';
import {useNavigate} from 'react-router-dom';
import usePathInfo from '@hooks/usePathInfo';
import {AdminPaths, UserPaths} from '@utils/ClientPaths';
import {useGetCompanyPersonalizationQuery} from '@hooks/apiEndpoints/user/queries';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const ButtonContainer = styled.button`
  &:focus-visible img {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 4px;
  }
`;

const LogoImg = styled.img`
  margin: 27px 0px 81px 16px;
  max-height: 68px;
  max-width: 210px;
  cursor: pointer;
  visibility: ${(props: {isVisible: boolean}) =>
    props.isVisible ? 'visible' : 'hidden'};
  opacity: ${(props: {isVisible: boolean}) => (props.isVisible ? 1 : 0)};
  transition: opacity 50ms 350ms ease-out; // delay to prevent a potential flickering
  @media (max-width: ${Breakpoints.Desktop}px) {
    max-height: 40px;
    margin: 0px;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

const TRANSPARENT_PX =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkYPhfDwAChwGA60e6kgAAAABJRU5ErkJggg==';

function AppHeaderLogo() {
  const navigate = useNavigate();
  const {isAdminPath} = usePathInfo();
  const {data: companyPersonalization, isSuccess} =
    useGetCompanyPersonalizationQuery();
  const src = isSuccess
    ? companyPersonalization?.companyLogoUrlRectangle || TRANSPARENT_PX
    : TRANSPARENT_PX;

  return (
    <ButtonContainer
      onClick={() => {
        navigate(isAdminPath ? AdminPaths.Index : UserPaths.ManagePlan);
      }}>
      <LogoImg
        isVisible={isSuccess}
        src={src}
        alt={i18n.t(k.COMPANY__HOME__FORMAT, {
          company: companyPersonalization?.companyDisplayName,
        })}
      />
    </ButtonContainer>
  );
}

export default AppHeaderLogo;
