import {useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import styled from 'styled-components';
import NoDataText from '@blocks/NoDataText';
import ContributorsTable from '@blocks/Permissions/ContributorsTable';
import AddContributorsModal, {
  AddContributorsModalFormInstance,
} from '@blocks/Permissions/AddContributorsModal';
import AddContributorsButton from '@blocks/Permissions/AddContributorsButton';
import {CustomizableLearningCategory} from '@generated/enums';
import {
  useAddCustomProgramUserPermissions,
  useDeleteCustomProgramUserPermission,
  useUpdateCustomProgramUserPermission,
} from '@hooks/apiEndpoints/customProgram/mutations';
import {useUserSearchQuery} from '@hooks/apiEndpoints/user/queries';
import debounce from 'lodash/debounce';
import {stringSorter} from '@utils/tableUtils';
import {CustomProgramUserPermissionPayload} from '@models/serverModels';
import {ContributorPermissionType} from '@models/clientEnums';
import learnInConfirm from '@components/reusable/learnInConfirm';
import {useCustomProgramPermissionVMsQuery} from '@hooks/apiEndpoints/permission/queries';
import {
  useUserQuery,
  useCustomProgramUserPermissionsQuery,
} from '@generated/hooks';

const COMPONENT_NAME = 'CustomProgramPermissionsScreen.container';

const AddContributorsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function CustomProgramPermissionsScreenContainer({
  customProgramId: propCustomProgramId,
}: {
  customProgramId: number;
}) {
  const [addContributorsModalVisible, setAddContributorsModalVisible] =
    useState(false);
  const [
    disableAddContributorsSubmitButton,
    setDisableAddContributorsSubmitButton,
  ] = useState(true);
  const [form] = Form.useForm<AddContributorsModalFormInstance>();
  const openAddContributorsModal = () => setAddContributorsModalVisible(true);
  const [userSearchName, setUserSearchName] = useState('');
  const customProgramId = propCustomProgramId;

  // get contributors table dataSource
  const {
    data: contributors,
    invalidateExact: invalidateContributors,
    isSuccess: contributorsLoadedSuccessfully,
  } = useCustomProgramUserPermissionsQuery({customProgramId});

  const {invalidateExact: invalidateUserProgramPermissions} =
    useCustomProgramPermissionVMsQuery(null, {
      enabled: false,
      component: COMPONENT_NAME,
    });

  const onSuccessUpdatePermissions = () => {
    invalidateContributors();
    invalidateUserProgramPermissions();
  };

  // update custom program user permissions
  const updateCustomProgramUserPermission =
    useUpdateCustomProgramUserPermission({
      onSuccess: onSuccessUpdatePermissions,
    });

  // delete custom program contributor user permissions
  const deleteCustomProgramUserPermission =
    useDeleteCustomProgramUserPermission({
      onSuccess: onSuccessUpdatePermissions,
    });

  // get company users for add contributors users search
  const {data: user} = useUserQuery(null);
  const {data: userSearchResults, isLoading: isUserSearchLoading} =
    useUserSearchQuery(
      {name: userSearchName, companyId: user?.companyId},
      {
        enabled: !!userSearchName,
      }
    );

  // add contributors
  const addCustomProgramUserPermission = useAddCustomProgramUserPermissions({
    onSuccess: onSuccessUpdatePermissions,
  });
  const showNoContributorsScreen =
    contributorsLoadedSuccessfully && !contributors?.length;

  const handleSubmit = async () => {
    form.validateFields().then(async (payload) => {
      const userCompanyIds = payload.userCompanyIds.map(
        (id: number | string) => {
          if (typeof id === 'string') {
            return parseInt(id.split(',')[2]);
          }
          return id;
        }
      );
      const customProgramPermissionPayload: CustomProgramUserPermissionPayload =
        {
          customProgramId,
          canEdit: payload.permissionGroup.includes(
            ContributorPermissionType.canEdit
          ),
          canReviewProject: payload.permissionGroup.includes(
            ContributorPermissionType.canReviewProject
          ),
          canViewReport: payload.permissionGroup.includes(
            ContributorPermissionType.canViewReport
          ),
          userCompanyIds,
        };
      await addCustomProgramUserPermission.mutateAsync(
        customProgramPermissionPayload
      );
      setAddContributorsModalVisible(false);
      setDisableAddContributorsSubmitButton(true);
      form.resetFields();
    });
  };

  const handleClickDelete = (userId: number) => {
    const contributor = contributors.find(
      (userPermission) => userPermission.userCompanyId === userId
    );
    learnInConfirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__REMOVE_CONTRIBUTOR_YES),
      content: i18n.t(k.PROMPT__REMOVE_PERMISSIONS_USER__FORMAT, {
        user: contributor.name,
      }),
      title: i18n.t(k.CONTRIBUTOR__REMOVE),
      onOk() {
        deleteCustomProgramUserPermission.mutate({
          customProgramId,
          userCompanyId: userId,
        });
      },
      onCancel() {
        // intentional pass
      },
    });
  };

  const handleClickUpdate = (
    userId: number,
    permissionType: ContributorPermissionType,
    enabled: boolean
  ) => {
    const contributor = contributors.find(
      (userPermission) => userPermission.userCompanyId === userId
    );

    if (!contributor) {
      return;
    }

    contributor[permissionType] = enabled;
    const {canEdit, canReviewProject, canViewReport, userCompanyId} =
      contributor;
    const allPermissionsRemoved =
      !canEdit && !canReviewProject && !canViewReport;

    // If all permissions removed, delete permission
    if (allPermissionsRemoved) {
      handleClickDelete(userCompanyId);
    } else {
      updateCustomProgramUserPermission.mutateAsync({
        payload: {
          contentId: customProgramId,
          ...contributor,
        },
      });
    }
  };

  const handleCloseModal = () => {
    setAddContributorsModalVisible(false);
    form.resetFields();
  };

  const filteredUserSearchResults = useMemo(() => {
    // This map allows searching for existing contributors in O(1) time
    const contributorsById = contributors?.reduce(
      (contributorIds, {userCompanyId}) => {
        contributorIds[userCompanyId] = true;
        return contributorIds;
      },
      {}
    );

    return userSearchResults?.filter(({id}) => !contributorsById[id]);
  }, [contributors, userSearchResults]);

  const handleCompanyUserSearch = debounce((name) => {
    setUserSearchName(name);
  }, 800);

  const handleFieldsChange = (_, allFields) => {
    // Disable submit button until all fields are valid
    setDisableAddContributorsSubmitButton(
      allFields.some(({value}) => !value?.length)
    );
  };

  return (
    <>
      {showNoContributorsScreen ? (
        <NoDataText
          header={i18n.t(k.PERMISSION__NO_CONTRIBUTORS_CUSTOM_PROGRAM)}
          subHeader={
            <>
              <>{i18n.t(k.PERMISSION__SELECT_CONTRIBUTORS_CUSTOM_PROGRAM)}</>
              <AddContributorsButtonContainer>
                <AddContributorsButton
                  onClick={openAddContributorsModal}
                  style={{marginTop: '16px'}}
                />
              </AddContributorsButtonContainer>
            </>
          }
        />
      ) : (
        contributorsLoadedSuccessfully && (
          <ContributorsTable
            dataSource={contributors?.sort(stringSorter('name')) ?? []}
            onClickDelete={handleClickDelete}
            onChangePermission={handleClickUpdate}
            onClickAddContributors={openAddContributorsModal}
            learningCategory={CustomizableLearningCategory.CustomProgram}
          />
        )
      )}
      {addContributorsModalVisible && (
        <AddContributorsModal
          learningCategory={CustomizableLearningCategory.CustomProgram}
          visible={addContributorsModalVisible}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          userList={filteredUserSearchResults}
          isSearchLoading={!!userSearchName && isUserSearchLoading}
          onSearchUser={handleCompanyUserSearch}
          disableSubmitButton={disableAddContributorsSubmitButton}
          form={form}
          onFieldsChange={handleFieldsChange}
        />
      )}
    </>
  );
}

export default CustomProgramPermissionsScreenContainer;
