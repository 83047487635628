import {BalanceRecurType, FinanceAmountType} from './../enums';
import {UserFinanceIncentiveTransactionsVM} from './../interfaces';
import {
  CreditCardAdminActionVMMockData,
  getCreditCardAdminActionVMMockData,
} from './CreditCardAdminActionVM.mock';
import {
  CreditCardTransactionVMMockData,
  getCreditCardTransactionVMMockData,
} from './CreditCardTransactionVM.mock';
import {faker} from '@faker-js/faker';

// Interface: UserFinanceIncentiveTransactionsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserFinanceIncentiveTransactionsVMMockData(
  args?: Partial<UserFinanceIncentiveTransactionsVM>
): UserFinanceIncentiveTransactionsVM {
  return {
    adminActions: [getCreditCardAdminActionVMMockData()],
    amountType: FinanceAmountType.UpTo,
    balance: faker.datatype.number(),
    incentiveTitle: faker.company.catchPhrase(),
    isActiveOnUserPlan: faker.datatype.boolean(),
    programPreApprovalRequired: faker.datatype.boolean(),
    recurrenceType: BalanceRecurType.NotApplicable,
    spendingLimit: faker.datatype.number(),
    totalApproved: faker.datatype.number(),
    totalSpent: faker.datatype.number(),
    transactions: [getCreditCardTransactionVMMockData()],
    userFinanceIncentiveId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserFinanceIncentiveTransactionsVMMockData: UserFinanceIncentiveTransactionsVM =
  {
    adminActions: [CreditCardAdminActionVMMockData],
    amountType: FinanceAmountType.UpTo,
    balance: 123,
    incentiveTitle: 'Advanced Web Design',
    isActiveOnUserPlan: true,
    programPreApprovalRequired: true,
    recurrenceType: BalanceRecurType.NotApplicable,
    spendingLimit: 123,
    totalApproved: 123,
    totalSpent: 123,
    transactions: [CreditCardTransactionVMMockData],
    userFinanceIncentiveId: 1,
  };
