import {RefObject} from 'react';
import {FormInstance} from 'antd';
import {Moment} from 'moment';
import {dateToUTCMoment} from '@utils/timeUtils';

export const updateFundsExpireOnFormValue = (
  formRef: RefObject<FormInstance>,
  approvalExpirationDate?: Moment
) => {
  if (!approvalExpirationDate) return;
  formRef.current.setFieldsValue({
    ['fundsExpireOn']: dateToUTCMoment(approvalExpirationDate),
  });
  return approvalExpirationDate;
};
