import InsightsReport from './InsightsReport';
import useTreatment from '@hooks/useTreatment';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {
  getEmployeeEngagementRq,
  getFinanceByProgramTypeRq,
  getPopularProgramsRq,
  getProgressUpdatesBySummaryRq,
} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {
  EmployeeEngagementVM,
  FinanceApprovedVsSpentVM,
  PopularProgramVM,
  ProgressUpdatesBySummaryVM,
} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import {useEffect} from 'react';

interface Props {
  onTabChange: (tabName: any) => void;
  currentYear: number | string;
  isDirectBillingPlan?: boolean;
}

const InsightsReportWrapper = ({
  onTabChange,
  currentYear,
  isDirectBillingPlan,
}: Props) => {
  const areProgressUpdatesEnabled =
    useTreatment(FeatureFlagExperiments.DisableProgressUpdates) ===
    FeatureFlagTreatments.Off;

  const employeeEngagementRq = getEmployeeEngagementRq(currentYear);
  const employeeEngagementQuery = useQuery<EmployeeEngagementVM[]>(
    employeeEngagementRq.queryKey,
    () => {
      return simpleQueryFn(employeeEngagementRq.path);
    },
    {refetchOnWindowFocus: false}
  );

  const financeByProgramTypeRq = getFinanceByProgramTypeRq(currentYear);
  const financeByProgramTypeQuery = useQuery<FinanceApprovedVsSpentVM[]>(
    financeByProgramTypeRq.queryKey,
    () => {
      return simpleQueryFn(financeByProgramTypeRq.path);
    },
    {refetchOnWindowFocus: false}
  );

  const progressUpdatesBySummaryRq = getProgressUpdatesBySummaryRq(currentYear);
  const progressUpdatesBySummaryQuery = useQuery<ProgressUpdatesBySummaryVM[]>(
    progressUpdatesBySummaryRq.queryKey,
    () => simpleQueryFn(progressUpdatesBySummaryRq.path)
  );

  const popularProgramsRq = getPopularProgramsRq(currentYear);
  const popularProgramsQuery = useQuery<PopularProgramVM[]>(
    popularProgramsRq.queryKey,
    () => simpleQueryFn(popularProgramsRq.path)
  );

  useEffect(() => {
    if (!employeeEngagementQuery.isLoading) {
      employeeEngagementQuery.refetch();
    }

    if (!financeByProgramTypeQuery.isLoading) {
      financeByProgramTypeQuery.refetch();
    }

    if (!progressUpdatesBySummaryQuery.isLoading) {
      progressUpdatesBySummaryQuery.refetch();
    }

    if (!popularProgramsQuery.isLoading) {
      popularProgramsQuery.refetch();
    }
  }, [currentYear]);

  return (
    <div>
      <InsightsReport
        areProgressUpdatesEnabled={areProgressUpdatesEnabled}
        onTabChange={(activeKey: any) => onTabChange(activeKey)}
        employeeEngagementData={employeeEngagementQuery.data}
        employeeEngagementLoading={employeeEngagementQuery.isLoading}
        financeChartData={financeByProgramTypeQuery.data}
        financeChartLoading={financeByProgramTypeQuery.isLoading}
        progressChartData={progressUpdatesBySummaryQuery.data}
        progressChartLoading={progressUpdatesBySummaryQuery.isLoading}
        popularProgramData={popularProgramsQuery.data}
        popularProgramLoading={popularProgramsQuery.isLoading}
        isDirectBillingPlan={isDirectBillingPlan}
      />
    </div>
  );
};

export default InsightsReportWrapper;
