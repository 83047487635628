import {Container} from './AcademyPeoplePage.styled';
import {AcademyPeoplePageViewProps} from './AcademyPeoplePage.interfaces';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {Tabs} from 'antd';
import {AcademyPeoplePageTabs} from '@utils/ClientPaths';
import translate from '@i18n/translate';
import AcademyPeopleLeadershipTab from '../AcademyPeopleLeadershipTab';
import AcademyPeopleParticipantsTab from '../AcademyPeopleParticipantsTab';

function AcademyPeoplePageView({
  onChangeTabs,
  activeTabKey,
  showLeadershipTab,
}: AcademyPeoplePageViewProps) {
  return (
    <Container>
      {showLeadershipTab ? (
        <LearnInTabs activeKey={activeTabKey} onChange={onChangeTabs}>
          <Tabs.TabPane
            tab={translate('PEOPLE__LEADERSHIP')}
            key={AcademyPeoplePageTabs.Leadership}>
            <AcademyPeopleLeadershipTab />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={translate('PARTICIPANT__PLURAL')}
            key={AcademyPeoplePageTabs.Participants}>
            <AcademyPeopleParticipantsTab />
          </Tabs.TabPane>
        </LearnInTabs>
      ) : (
        <AcademyPeopleParticipantsTab />
      )}
    </Container>
  );
}

export default AcademyPeoplePageView;
