import {i18n, k} from '@i18n/translate';

export interface ClickToUnenrollText {
  confirmation?: string;
  label: string;
  academyNameLabel: string;
}

export interface ClickToUnenrollDisplayConfig {
  text: ClickToUnenrollText;
  visible: boolean;
}

export const clickToUnenrollText = () => ({
  label: i18n.t(k.UNENROLL__ACADEMY),
  modalTitle: (academyName) => i18n.t(k.UNENROLL__ACADEMY_NAME, {academyName}),
  confirmation: i18n.t(k.UNENROLL__ACADEMY_CONFIRMATION),
  cancel: i18n.t(k.CTA__CANCEL),
});
