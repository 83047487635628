import {useEffect, useState} from 'react';
import AdminReports from './AdminReports';
import {AdminReportsTabName} from './store/interface';
import {useLocation} from 'react-router-dom';
import {
  getFinanceReportRq,
  getIncentiveAdminFinanceRq,
  getProgramReportRq,
  getProgressReportRq,
} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {
  ProgramProgressUpdateResult,
  ProgramUsageResult,
  ReimbursementsReportResult,
} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import {FinanceType, ReportAccessType} from '@generated/enums';
import {useFinanceLearningBudgetsQuery} from '@generated/hooks';
import {Helmet} from 'react-helmet-async';

interface ProgramReportWrapperProps {
  title?: string;
}

function ProgramReportWrapper({title}: ProgramReportWrapperProps) {
  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const initialTab = searchParams.get('tab');
  const [activeKey, setActiveKey] = useState(
    AdminReportsTabName[initialTab] ? initialTab : AdminReportsTabName.INSIGHTS
  );
  const [showReimbursementsTab, setShowReimbursementsTab] = useState(false);

  useEffect(() => {
    //Checking for empty report to hide the reimbursements tab
    //should be replaced when the endpoint is ready https://linear.app/learnin/issue/LI-4061/create-endpoint-to-determine-if-reimbursements-are-being-used
    financeReportQuery.refetch();
  }, []);

  const programReportRq = getProgramReportRq();
  const programReportQuery = useQuery<ProgramUsageResult[]>(
    programReportRq.queryKey,
    () => simpleQueryFn(programReportRq.path, (data) => (!!data ? data : [])),
    {
      enabled: false,
    }
  );

  const financeReportRq = getFinanceReportRq();
  const financeReportQuery = useQuery<ReimbursementsReportResult[]>(
    financeReportRq.queryKey,
    () => simpleQueryFn(financeReportRq.path),
    {
      enabled: false,
      onSuccess(data) {
        data.length && setShowReimbursementsTab(true);
      },
    }
  );

  const progressReportRq = getProgressReportRq(ReportAccessType.Admin);
  const progressReportQuery = useQuery<ProgramProgressUpdateResult[]>(
    progressReportRq.queryKey,
    () => simpleQueryFn(progressReportRq.path),
    {
      enabled: false,
    }
  );

  const {data: financeLearningBuds} = useFinanceLearningBudgetsQuery(null);

  const fetchReport = async (currentTabIndex: AdminReportsTabName) => {
    setActiveKey(currentTabIndex);
    switch (currentTabIndex) {
      case AdminReportsTabName.INSIGHTS:
        break;
      case AdminReportsTabName.PROGRAM:
        await programReportQuery.refetch();
        break;
      case AdminReportsTabName.FINANCE:
        await financeReportQuery.refetch();
        break;
      case AdminReportsTabName.TIME:
        break;
      case AdminReportsTabName.PROGRESS:
        await progressReportQuery.refetch();
        break;
      default:
        await programReportQuery.refetch();
        break;
    }
  };

  const getLearningBudgetInfoQuery = getIncentiveAdminFinanceRq();
  const {data: learningBudgetInfo} = useQuery(
    getLearningBudgetInfoQuery.queryKey,
    () => simpleQueryFn(getLearningBudgetInfoQuery.path)
  );

  const directBilling = 'DirectBilling';
  const isDirectBillingPlan =
    learningBudgetInfo?.[0]?.incentiveType === directBilling;
  const isReimbursementPlan =
    financeLearningBuds?.financeType === FinanceType.ExternalReimbursement ||
    financeLearningBuds?.financeType === FinanceType.TuitionReimbursement;

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AdminReports
        programReportData={programReportQuery.data}
        financeReportData={financeReportQuery.data}
        progressReportData={progressReportQuery.data}
        programReportLoading={programReportQuery.isFetching}
        financeReportLoading={financeReportQuery.isFetching}
        progressReportLoading={progressReportQuery.isFetching}
        showReimbursementsTab={showReimbursementsTab}
        fetchReport={fetchReport}
        activeKey={activeKey}
        isDirectBillingPlan={isDirectBillingPlan}
        isReimbursementPlan={isReimbursementPlan}
      />
    </div>
  );
}

export default ProgramReportWrapper;
