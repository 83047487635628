import {i18n, k} from '@i18n/translate';
import {DurationOrAmountColumnData} from '@blocks/durationOrAmountColumn/DurationOrAmountColumn';
import {CurrencyCode, PlanItemType} from '@generated/enums';
import {formatCurrency} from '@utils/moneyUtils';
import {UserPlanItemStatusString} from '@models/clientEnums';
import {DATE_FORMAT} from '@utils/timeUtils';
import moment from 'moment';

interface Props {
  item: any;
  itemType: number;
  exchangeFromUsdRate?: number;
  exchangeRate?: number;
  isEstimatedAmount?: boolean;
  isManagePage?: boolean;
  showFundsRequestedState?: boolean;
  usersCurrency?: CurrencyCode;
}

export const useGetDurationOrAmountColumnData = ({
  item,
  itemType,
  exchangeFromUsdRate,
  exchangeRate,
  isEstimatedAmount,
  isManagePage,
  showFundsRequestedState,
  usersCurrency,
}: Props): DurationOrAmountColumnData => {
  switch (itemType) {
    case PlanItemType.Program:
      return isManagePage
        ? {
            label: i18n.t(k.TIME__DURATION),
            value: item?.duration
              ? item?.duration.toString() + ' ' + item?.durationType
              : i18n.t(k.GENERIC__N_A),
          }
        : determineLabels({
            item,
            exchangeRate,
            exchangeFromUsdRate,
            usersCurrency,
            showFundsRequestedState,
          });
    case PlanItemType.Time:
      return isManagePage
        ? {
            label: i18n.t(k.GENERIC__AMOUNT),
            value: item?.duration
              ? item?.duration.toString() + ' ' + item?.durationType
              : i18n.t(k.GENERIC__N_A),
          }
        : {
            label: i18n.t(k.DATE__NEXT_SCHEDULED_TIME),
            value: moment(item.nextEvent).format(
              DATE_FORMAT.MONTH_FULL_DAY_YEAR
            ),
          };
    case PlanItemType.Finance:
      if (isEstimatedAmount) {
        return {
          label: i18n.t(k.PROGRAM__EST_PROGRAM_COST),
          value: item.estimatedAmount
            ? formatCurrency(
                item.estimatedAmount,
                usersCurrency,
                exchangeRate
              ) || i18n.t(k.GENERIC__N_A)
            : '',
        };
      }
      return isManagePage
        ? {
            label: i18n.t(k.GENERIC__AMOUNT),
            value: displayAnyAsAmount(item.amount, item.status)
              ? i18n.t(k.GENERIC__ANY)
              : formatCurrency(item.money, usersCurrency, exchangeRate) ||
                i18n.t(k.GENERIC__N_A),
          }
        : {
            label: i18n.t(k.MONEY__TOTAL_AMOUNT),
            value: displayAnyAsAmount(item.amount, item.status)
              ? i18n.t(k.GENERIC__ANY)
              : formatCurrency(item.amount, usersCurrency, exchangeRate) ||
                i18n.t(k.GENERIC__N_A),
          };
    default:
      break;
  }
};

const displayAnyAsAmount = (amount: number, status: UserPlanItemStatusString) =>
  amount === 0 && status !== UserPlanItemStatusString.Approved;

const determineLabels = ({
  item,
  exchangeRate,
  exchangeFromUsdRate,
  usersCurrency,
  showFundsRequestedState,
}: {
  item: any;
  exchangeRate: number;
  exchangeFromUsdRate: number;
  usersCurrency: CurrencyCode;
  showFundsRequestedState: boolean;
}): {label: string; value: string} => {
  const commonMoneyOptions = {decimal: true};
  if (
    (item.approvalRequired &&
      item.status === UserPlanItemStatusString.NotSubmitted) ||
    !item.approvalRequired
  ) {
    return {
      label: i18n.t(k.PROGRAM__COST),
      value: formatCurrency(
        item.cost,
        usersCurrency,
        exchangeRate,
        commonMoneyOptions
      ),
    };
  } else if (item.approvalRequired && item.isLicenseProgram) {
    return {
      label: i18n.t(k.PROGRAM__COST),
      value: formatCurrency(
        item.licenseCost ? item.licenseCost : item.cost,
        usersCurrency,
        exchangeRate,
        commonMoneyOptions
      ),
    };
  } else if (
    item.approvalRequired &&
    item.requestedAmount !== 0 &&
    item.approvedAmount === 0 &&
    (item.status === UserPlanItemStatusString.Submitted ||
      item.status === UserPlanItemStatusString.Requested ||
      item.status === UserPlanItemStatusString.Pending)
  ) {
    return {
      label: i18n.t(k.FUND__REQUEST__AMOUNT),
      value: formatCurrency(
        showFundsRequestedState ? item.approvedAmount : item.requestedAmount,
        usersCurrency,
        exchangeFromUsdRate || exchangeRate,
        commonMoneyOptions
      ),
    };
  } else if (
    item.approvalRequired &&
    (item.status === UserPlanItemStatusString.Approved ||
      item.status === UserPlanItemStatusString.InProgress)
  ) {
    return {
      label: i18n.t(k.APPROVAL__APPROVED_AMOUNT),
      value: formatCurrency(
        item.approvedAmount,
        usersCurrency,
        exchangeFromUsdRate || exchangeRate,
        commonMoneyOptions
      ),
    };
  } else if (showFundsRequestedState) {
    return {
      label: i18n.t(k.APPROVAL__APPROVED_AMOUNT),
      value: formatCurrency(
        item.approvedAmount,
        usersCurrency,
        exchangeFromUsdRate || exchangeRate,
        commonMoneyOptions
      ),
    };
  } else if (item.status === UserPlanItemStatusString.Rejected) {
    return {
      label: i18n.t(k.FUND__REQUEST__AMOUNT),
      value: formatCurrency(
        item.declinedAmount,
        usersCurrency,
        exchangeFromUsdRate || exchangeRate,
        commonMoneyOptions
      ),
    };
  }
};
