import SectionNav from './SectionNav';
import usePathInfo from '@hooks/usePathInfo';
import {useCustomProgramPreviewQuery} from '@generated/hooks';
import useSetPageTitle from '@hooks/useSetPageTitle';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface SectionNavContainerProps {
  customProgramId: number;
  selectedSectionId: number;
  onClickSectionNavItem: (id: number) => void;
  title?: string;
}

function SectionNavContainer({
  customProgramId,
  selectedSectionId,
  onClickSectionNavItem,
  title,
}: SectionNavContainerProps) {
  const {isAdminPath} = usePathInfo();

  const {data: customProgram} = useCustomProgramPreviewQuery({
    customProgramId,
    queryParams: {includeProgress: true},
  });

  useSetPageTitle(title);

  const sectionNavItems = customProgram?.sections?.map(({id, order, title}) => {
    return {
      id,
      order,
      title,
      onClick: () => onClickSectionNavItem(id),
      active: id === selectedSectionId,
    };
  });

  return (
    <SectionNav
      customProgramId={customProgramId}
      allSections={customProgram?.sections || []}
      sectionNavItems={sectionNavItems}
      progressPercentage={isAdminPath ? 0 : customProgram?.progressPercentage}
      showReportsNav={true}
    />
  );
}

export default SectionNavContainer;
