import {RsvpStatus} from './../enums';
import {CustomProgramContentPreviewVM} from './../interfaces';
import {
  AttachmentVMMockData,
  getAttachmentVMMockData,
} from './AttachmentVM.mock';
import {
  ContentFeedbackVMMockData,
  getContentFeedbackVMMockData,
} from './ContentFeedbackVM.mock';
import {
  CustomProgramContentVMMockData,
  getCustomProgramContentVMMockData,
} from './CustomProgramContentVM.mock';
import {LinkVMMockData, getLinkVMMockData} from './LinkVM.mock';
import {MessageDtoMockData, getMessageDtoMockData} from './MessageDto.mock';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramContentPreviewVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramContentPreviewVMMockData(
  args?: Partial<CustomProgramContentPreviewVM>
): CustomProgramContentPreviewVM {
  return {
    ...getCustomProgramContentVMMockData(),
    attachments: [getAttachmentVMMockData()],
    discussionId: String(faker.datatype.number()), // optional
    feedback: [getContentFeedbackVMMockData()],
    googleCalendarLink: faker.internet.url(), // optional
    links: [getLinkVMMockData()],
    messageCount: faker.datatype.number(),
    messagesPreview: [getMessageDtoMockData()], // optional
    outlookCalendarLink: faker.internet.url(), // optional
    projectContentCompletedOn: String(faker.date.past()), // optional
    rsvpStatus: RsvpStatus.None, // optional
    submissionCount: faker.datatype.number(),
    submittedFiles: [getAttachmentVMMockData()],
    submittedLinks: [getLinkVMMockData()],
    submittedResponse: faker.word.noun(), // optional
    textContentCompleted: faker.datatype.boolean(),
    userMessage: getMessageDtoMockData(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramContentPreviewVMMockData: CustomProgramContentPreviewVM =
  {
    ...CustomProgramContentVMMockData,
    attachments: [AttachmentVMMockData],
    discussionId: '1', // optional
    feedback: [ContentFeedbackVMMockData],
    googleCalendarLink: 'https://this-person-does-not-exist.com/en', // optional
    links: [LinkVMMockData],
    messageCount: 5,
    messagesPreview: [MessageDtoMockData], // optional
    outlookCalendarLink: 'https://this-person-does-not-exist.com/en', // optional
    projectContentCompletedOn: '2023-03-29T22:17:19.738072', // optional
    rsvpStatus: RsvpStatus.None, // optional
    submissionCount: 5,
    submittedFiles: [AttachmentVMMockData],
    submittedLinks: [LinkVMMockData],
    submittedResponse: 'string', // optional
    textContentCompleted: true,
    userMessage: MessageDtoMockData, // optional
  };
