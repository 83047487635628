import {PermissionRole} from './../enums';
import {NewClientCompany} from './../interfaces';
import {
  DefaultPreApprovalQuestionsMockData,
  getDefaultPreApprovalQuestionsMockData,
} from './DefaultPreApprovalQuestions.mock';
import {
  DefaultReimbursementQuestionsMockData,
  getDefaultReimbursementQuestionsMockData,
} from './DefaultReimbursementQuestions.mock';
import {
  DefaultResourcesApprovalQuestionsMockData,
  getDefaultResourcesApprovalQuestionsMockData,
} from './DefaultResourcesApprovalQuestions.mock';
import {
  StripeConfigDTOMockData,
  getStripeConfigDTOMockData,
} from './StripeConfigDTO.mock';
import {faker} from '@faker-js/faker';

// Interface: NewClientCompany

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getNewClientCompanyMockData(
  args?: Partial<NewClientCompany>
): NewClientCompany {
  return {
    addDefaultPreApprovalQuestions: getDefaultPreApprovalQuestionsMockData(),
    addDefaultReimbursementQuestions:
      getDefaultReimbursementQuestionsMockData(),
    addDefaultResourcesApprovalQuestions:
      getDefaultResourcesApprovalQuestionsMockData(),
    billingAddress: faker.word.noun(),
    billingCity: faker.word.noun(),
    billingCountry: String(faker.datatype.number()),
    billingPostalCode: faker.word.noun(),
    billingState: faker.word.noun(),
    createStripeCustomConnectedAccount: faker.datatype.boolean(),
    defaultPermissionRole: PermissionRole.AcademiesUser, // optional
    isAcademiesIntegratedExperienceDisabled: faker.datatype.boolean(),
    isContentMarketplaceIntegratedExperienceDisabled: faker.datatype.boolean(),
    learnInAdminsEmailSuffix: String(faker.internet.email()),
    lxpId: faker.datatype.number(), // optional
    name: faker.name.fullName(),
    primaryBrandColor: faker.internet.color(),
    rectangleLogoUrl: faker.internet.url(),
    squareLogoUrl: faker.internet.url(),
    stripeConfig: getStripeConfigDTOMockData(), // optional
    virtualCardColor: faker.internet.color(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const NewClientCompanyMockData: NewClientCompany = {
  addDefaultPreApprovalQuestions: DefaultPreApprovalQuestionsMockData,
  addDefaultReimbursementQuestions: DefaultReimbursementQuestionsMockData,
  addDefaultResourcesApprovalQuestions:
    DefaultResourcesApprovalQuestionsMockData,
  billingAddress: 'string',
  billingCity: 'string',
  billingCountry: '5',
  billingPostalCode: 'string',
  billingState: 'string',
  createStripeCustomConnectedAccount: true,
  defaultPermissionRole: PermissionRole.AcademiesUser, // optional
  isAcademiesIntegratedExperienceDisabled: true,
  isContentMarketplaceIntegratedExperienceDisabled: true,
  learnInAdminsEmailSuffix: 'bjohnson@gmail.com',
  lxpId: 1, // optional
  name: 'Bobby Johnson',
  primaryBrandColor: '#FFD7DD',
  rectangleLogoUrl: 'https://this-person-does-not-exist.com/en',
  squareLogoUrl: 'https://this-person-does-not-exist.com/en',
  stripeConfig: StripeConfigDTOMockData, // optional
  virtualCardColor: '#FFD7DD',
};
