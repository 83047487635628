import {CurrencyCode, LanguagePreference} from './../enums';
import {UserCompany} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {
  NotificationSettingsMockData,
  getNotificationSettingsMockData,
} from './NotificationSettings.mock';
import {
  UserMetadataMockData,
  getUserMetadataMockData,
} from './UserMetadata.mock';
import {faker} from '@faker-js/faker';

// Interface: UserCompany

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserCompanyMockData(
  args?: Partial<UserCompany>
): UserCompany {
  return {
    ...getBaseEntityMockData(),
    canViewAcademiesFeature: faker.datatype.boolean(), // optional
    companyId: faker.datatype.number(),
    costCenter: faker.word.noun(),
    currency: CurrencyCode.USD,
    deleted: faker.datatype.boolean(),
    employeeDepartment: faker.word.noun(),
    firstLogin: String(faker.date.past()), // optional
    hasAcceptedStripeTerms: faker.datatype.boolean(),
    id: faker.datatype.number(),
    isEligibleForPersonalLearningBudget: faker.datatype.boolean(), // optional
    languagePreference: LanguagePreference.EN_US,
    lastLogin: faker.word.noun(), // optional
    lxpUserId: String(faker.datatype.number()),
    managerId: faker.datatype.number(), // optional
    metadata: getUserMetadataMockData(),
    notificationSettings: getNotificationSettingsMockData(),
    slackId: String(faker.datatype.number()),
    startDate: String(faker.date.past()), // optional
    toBeProRated: faker.datatype.boolean(),
    userId: String(faker.datatype.number()),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserCompanyMockData: UserCompany = {
  ...BaseEntityMockData,
  canViewAcademiesFeature: true, // optional
  companyId: 1,
  costCenter: 'string',
  currency: CurrencyCode.USD,
  deleted: true,
  employeeDepartment: 'string',
  firstLogin: '2023-03-29T22:17:19.738072', // optional
  hasAcceptedStripeTerms: true,
  id: 1,
  isEligibleForPersonalLearningBudget: true, // optional
  languagePreference: LanguagePreference.EN_US,
  lastLogin: 'string', // optional
  lxpUserId: '1',
  managerId: 1, // optional
  metadata: UserMetadataMockData,
  notificationSettings: NotificationSettingsMockData,
  slackId: '1',
  startDate: '2023-03-29T22:17:19.738072', // optional
  toBeProRated: true,
  userId: '1',
};
