import {InputLabel} from '@components/reusable/LearnInForm';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import useTimeZones from '@hooks/useTimeZones';
import {i18n, k} from '@i18n/translate';
import {incrementToQuarterHour, isToday} from '@utils/timeUtils';
import {Form} from 'antd';
import moment, {Moment} from 'moment';
import React, {useEffect} from 'react';
import {FORM_KEYS} from '../constants';
import {DatePicker, TimePicker} from './EventDate.adapted';
import {
  EndTime,
  EventDateTime,
  EventTime,
  StartTime,
  TimeRange,
  TimezoneSelect,
} from './EventDate.styled';
import {
  validateEventDate,
  validateEventTimeZone,
  validateStartTime,
} from './EventDate.validators';

interface EventDateProps {
  initialTimeZoneId?: string;
  initialDate?: Moment;
  initialStartTime?: Moment;
  initialEndTime?: Moment;
}

const EventDate: React.FC<EventDateProps> = ({
  initialTimeZoneId,
  initialDate,
  initialEndTime,
  initialStartTime,
}) => {
  const form = Form.useFormInstance();
  const eventDate = Form.useWatch<Moment>(FORM_KEYS.EVENT_DATE, form);
  const startTime = Form.useWatch<Moment>(FORM_KEYS.EVENT_START_TIME, form);
  const endTime = Form.useWatch<Moment>(FORM_KEYS.EVENT_END_TIME, form);

  const {simplifiedTimeZones} = useTimeZones(
    {
      initialTimeZoneId,
      onTimeZoneDataReady({initialTimeZone}) {
        form.setFieldValue(FORM_KEYS.EVENT_TIME_ZONE, initialTimeZone?.id);
      },
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  );

  const disabledHours = (startingPoint?: Moment) => {
    const disabledTime = isToday(eventDate) ? [] : startingPoint;
    return disabledTime
      ? Array.from(Array(moment(disabledTime).hour()).keys())
      : [];
  };

  const disabledMinutes = (endingPoint: Moment) => {
    const starting = startTime?.isValid() && startTime;
    const sameHour = starting?.isSame(endingPoint, 'hour');
    if (sameHour) {
      const disabledTime =
        (!isToday(eventDate) || !endingPoint?.isSame()) && starting;
      return Array.from(Array(moment(disabledTime || []).minutes()).keys());
    }
    return [];
  };

  useEffect(
    function adjustStartTime() {
      if (isToday(eventDate) && eventDate?.isBefore()) {
        form.setFieldValue(
          FORM_KEYS.EVENT_START_TIME,
          incrementToQuarterHour()
        );
      }
    },
    [form, eventDate]
  );

  useEffect(
    function adjustEndTime() {
      const start = startTime?.clone();
      if (startTime && endTime?.isBefore(startTime)) {
        form.setFieldValue(FORM_KEYS.EVENT_END_TIME, start.add(15, 'minutes'));
      }
    },
    [form, startTime, endTime]
  );

  return (
    <EventDateTime>
      <div>
        <InputLabel htmlFor="event-date-input" label={i18n.t(k.DATE)} />
        <Form.Item
          name={FORM_KEYS.EVENT_DATE}
          rules={[{validator: validateEventDate(form)}]}
          initialValue={initialDate}>
          <DatePicker id="event-date-input" size="large" />
        </Form.Item>
      </div>
      <div>
        <EventTime>
          <InputLabel htmlFor="start-time-input" label={i18n.t(k.TIME)} />
          <TimeRange>
            <StartTime>
              <Form.Item
                name={FORM_KEYS.EVENT_START_TIME}
                initialValue={initialStartTime}
                rules={[{validator: validateStartTime(form)}]}>
                <TimePicker
                  id="start-time-input"
                  placeholder={i18n.t(k.TIME__START)}
                  format="h:mm a"
                  use12Hours={true}
                  minuteStep={15}
                  size="large"
                  disabledHours={() => disabledHours()}
                  disabledMinutes={() => disabledMinutes(moment())}
                />
              </Form.Item>
            </StartTime>
            <EndTime>
              <Form.Item
                name={FORM_KEYS.EVENT_END_TIME}
                initialValue={initialEndTime}
                rules={[]}>
                <TimePicker
                  placeholder={i18n.t(k.TIME__END)}
                  format="h:mm a"
                  use12Hours={true}
                  minuteStep={15}
                  size="large"
                  disabledHours={() => disabledHours(startTime)}
                  disabledMinutes={() => disabledMinutes(endTime)}
                />
              </Form.Item>
            </EndTime>
          </TimeRange>
        </EventTime>
      </div>
      <TimezoneSelect>
        <Form.Item
          name={FORM_KEYS.EVENT_TIME_ZONE}
          rules={[{validator: validateEventTimeZone(form)}]}>
          <LearnInSelect
            aria-label={i18n.t(k.VALIDATION__SELECT_TIMEZONE)}
            showSearch
            bordered={false}
            showArrow={false}
            filterOption={(input, {value}) => {
              return value
                ?.toLocaleLowerCase()
                .includes(input.toLocaleLowerCase());
            }}
            dropdownMatchSelectWidth={false}
            placeholder={i18n.t(k.VALIDATION__SELECT_TIMEZONE__POLITE)}>
            {simplifiedTimeZones?.map((timeZone) => (
              <LearnInSelectOption
                key={timeZone.id}
                data-testid={`${timeZone.id}-select-option`}
                value={timeZone.id}>
                {timeZone.displayName}
              </LearnInSelectOption>
            ))}
          </LearnInSelect>
        </Form.Item>
      </TimezoneSelect>
    </EventDateTime>
  );
};

export default EventDate;
