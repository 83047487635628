import './FullCalendar.scss';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {Modal, Radio} from 'antd';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {FormInstance} from 'antd/lib/form';
import {notify} from '../../../../notifications';
import {api} from '@store/api';
import moment from 'moment';
import AddUpdateEventDrawer from './components/AddUpdateEventDrawer';
import {NewUserEvent, UpdateUserEvent} from '@models/serverModels';
import {DATE_FORMAT, splitDateTime} from '@utils/timeUtils';
import {CalendarCallbackRedirect} from '@generated/enums';
import {SyncCalendarRequest} from '@generated/interfaces';
import {LearnInTooltip} from '@components/reusable/Tooltip';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '1.875rem',
};

export enum CALENDAR_RECURRENCE_TYPE {
  Daily,
  Weekly,
  Monthly,
  Yearly,
}

// function getPlanStatusString(status: USER_PLAN_ITEM_STATUS) {
//   switch(status) {
//     case USER_PLAN_ITEM_STATUS.Pending: return "Pending"
//     case USER_PLAN_ITEM_STATUS.Approved: return "Approved"
//     case USER_PLAN_ITEM_STATUS.Cancelled: return "Cancelled"
//     case USER_PLAN_ITEM_STATUS.Rejected: return "Rejected"
//     case USER_PLAN_ITEM_STATUS.Requested: return "Requested"
//   }
// }

function convertToOpaqueBackground(color: string): string {
  const rgbColor: string[] = parseColorStringForRGB(color);

  if (rgbColor && rgbColor.length) {
    return `rgb(${rgbColor[0]}, ${rgbColor[1]}, ${rgbColor[2]}, .7)`;
  } else {
    return `rgb(125, 190, 255, .7)`; // the blue that Full calendar uses
  }
}

function parseColorStringForRGB(colorString: any): string[] {
  if (colorString) {
    // Tokenize input
    const stringMatch = colorString.match(/^\#|^rgb\(|[\d\w]+$|\d{3}/g);

    // Other variables
    let value: any, values: any;
    let valid = true,
      double = false;

    // If no matches, return empty []
    if (!stringMatch) return [];

    // If hex value
    if (stringMatch.length < 3) {
      // Get the value
      value = stringMatch[stringMatch.length - 1];
      // Split into parts, either x,x,x or xx,xx,xx
      values =
        value.length == 3
          ? (double = true && value.split(''))
          : value.match(/../g);
      // Convert to decimal values - if #nnn, double up on values 345 => 334455
      values.forEach(function (v: any, i: any) {
        values[i] = parseInt(double ? '' + v + v : v, 16);
      });
      // Otherwise it's rgb, get the values
    } else {
      values =
        stringMatch.length == 3 ? stringMatch.slice() : stringMatch.slice(1);
    }
    // Check that each value is between 0 and 255 inclusive and return the result
    values.forEach(function (v: any) {
      valid = valid ? v >= 0 && v <= 255 : false;
    });
    // If string is invalid, return false, otherwise return an array of the values
    return valid && values;
  } else {
    return [];
  }
}

function renderEventContent(eventInfo: any) {
  return (
    <div>
      <LearnInTooltip placement="topLeft" title={eventInfo.event.title}>
        <div
          style={{
            padding: '4px',
            fontSize: '0.625rem',
            fontWeight: 500,
            textTransform: 'uppercase',
            backgroundColor: eventInfo.event._def.extendedProps.eventColor,
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            width: '101%',
          }}>
          <b>{eventInfo.timeText}</b>
          <i style={{float: 'right'}}>
            {eventInfo.event._def.extendedProps.isEditable ||
            eventInfo.event._def.extendedProps.externalId
              ? ''
              : 'Pending'}
          </i>
        </div>
        <div
          style={{
            backgroundColor: convertToOpaqueBackground(
              eventInfo.event._def.extendedProps.eventColor
            ),
            height: '89%',
            width: '100%',
            position: 'absolute',
            padding: '4px',
            lineHeight: '1',
            fontSize: '0.875rem',
            textTransform: 'uppercase',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
          }}>
          {eventInfo.event.title}
        </div>
      </LearnInTooltip>
    </div>
  );
}

function renderEventClassNames(eventInfo: any) {
  if (eventInfo.event._def.extendedProps.isEditable) {
    return ['eventContainer'];
  } else {
    return ['eventContainer eventNotEditable'];
  }
}

type Props = {
  data: any;
  connectCalendar: ({payload}: {payload: SyncCalendarRequest}) => void;
  addEvent: (payload: NewUserEvent) => void;
  updateEvent: (payload: UpdateUserEvent) => void;
  planItemId: string;
  fetchUserEvents: (weekStart: any, weekEnd: any, type: any) => void;
  start: any;
  end: any;
  view: string;
};

export default class Calender extends React.Component<Props> {
  formRef: React.RefObject<FormInstance> = null;
  calRef: React.RefObject<FullCalendar> = null;
  syncFormRef: React.RefObject<FormInstance> = null;

  constructor(props: any) {
    super(props);
    this.formRef = React.createRef<FormInstance>();
    this.calRef = React.createRef<FullCalendar>();
    this.syncFormRef = React.createRef<FormInstance>();
  }

  componentDidMount() {
    if (!!this.props.start) {
      this.calRef.current.getApi().gotoDate(this.props.start);
    }
    this.initialCalendarLoadingScript();
  }

  componentDidUpdate() {
    this.initialCalendarLoadingScript();
  }

  initialCalendarLoadingScript() {
    const script = document.createElement('script');
    script.innerHTML = `
      document.getElementsByClassName('fc-scroller')[0].classList.add('fc-top-row')
    `;
    document.head.appendChild(script);
  }

  state = {
    weekendsVisible: true,
    formSubmitting: false,
    currentEvents: [],
    visible: false,
    eventId: false,
    allDayEvent: false,
    syncModalVisible: false,
    syncCalenderValue: 0,
    eventTitle: '',
    isRecurringEvent: false,
    previousStartDate: null,
    visibleAddEventDrawer: false,
  };

  onCloseDrawer = () => {
    this.setState({visibleAddEventDrawer: false});
  };
  showDrawer = () => {
    this.setState({visibleAddEventDrawer: true});
  };

  // renderEventContent = (eventInfo) => {
  //   return (
  //     <>
  //       <b>{eventInfo.timeText}</b>&nbsp;
  //       <i>{eventInfo.event.title + " - " + getPlanStatusString(this.props.planStatus)}</i>
  //     </>
  //   );
  // }

  handleCancel = () => {
    this.setState({
      visibleAddEventDrawer: false,
    });
  };

  handleOk = async () => {
    try {
      this.setState({
        formSubmitting: true,
      });
      await this.formRef.current.validateFields();
      const data = this.formRef.current.getFieldsValue();
      const {
        Title,
        Description,
        EndDate,
        StartDate,
        EndTime,
        StartTime,
        Cancel,
        AllDayEvent,
        Recurring,
        RecurrenceType,
      } = data;
      const StartDateFormatted = moment(StartDate).format(DATE_FORMAT.ISO_DATE);
      const EndDateFormatted = moment(EndDate).format(DATE_FORMAT.ISO_DATE);
      const StartTimeFormatted = moment(StartTime).format(
        DATE_FORMAT.TIME_WITH_SECONDS_24_HR_PADDED
      );
      const EndTimeFormatted = moment(EndTime).format(
        DATE_FORMAT.TIME_WITH_SECONDS_24_HR_PADDED
      );
      const isStartTimeAfterEndTime = moment(data.StartTime).isAfter(
        moment(data.EndTime)
      );
      const PreviousStartDate = moment(this.state.previousStartDate).format(
        DATE_FORMAT.ISO_DATE
      );
      const RecurringDetail: any = {};

      if (Recurring) {
        RecurringDetail.RecurrenceType = RecurrenceType;
        RecurringDetail.DayOfWeek = moment(StartDate).weekday();
        RecurringDetail.MonthOfYear = moment(StartDate).month() + 1;
      }

      const startDate = moment(data.StartDate).toDate().setHours(0, 0, 0, 0);
      const endDate = moment(data.EndDate).toDate().setHours(0, 0, 0, 0);

      // const endDatePrevious = moment(moment(data.EndDate).add(-1).toDate().setHours(0, 0, 0, 0)).format(DATE_FORMAT.ISO_DATE);
      // allDayEvent ? endDatePrevious : EndDateFormatted,

      if (startDate > endDate) {
        notify.startDateAfterEndDate();
        throw new Error();
      }

      if (startDate === endDate && isStartTimeAfterEndTime) {
        notify.startTimeAfterEndTime();
        throw new Error();
      }

      if (this.state.eventId) {
        this.props.updateEvent({
          id: Number(this.state.eventId),
          userPlanItemId: Number(this.props.planItemId),
          title: Title,
          notes: Description,
          startDate: StartDateFormatted,
          startTime: AllDayEvent ? null : StartTimeFormatted,
          endDate: EndDateFormatted,
          endTime: AllDayEvent ? null : EndTimeFormatted,
          allDayEvent: AllDayEvent,
          cancel: Cancel,
          recurring: Recurring,
          recurringDetail: RecurringDetail,
          previousStartDate: PreviousStartDate,
        });
      } else {
        this.props.addEvent({
          userPlanItemId: Number(this.props.planItemId),
          title: Title,
          notes: Description,
          startDate: StartDateFormatted,
          startTime: AllDayEvent ? null : StartTimeFormatted,
          endDate: EndDateFormatted,
          endTime: AllDayEvent ? null : EndTimeFormatted,
          allDayEvent: AllDayEvent,
          recurring: Recurring,
          recurringDetail: RecurringDetail,
        });
      }
      this.setState({
        visibleAddEventDrawer: false,
        formSubmitting: false,
        eventId: false,
      });
    } catch (e) {
      this.setState({
        formSubmitting: false,
      });
    }
  };

  setToFullDayEvent = (isFullDayEvent: boolean) => {
    if (isFullDayEvent) {
      const {StartDate} = this.formRef.current.getFieldsValue();
      const StartDateMoment = moment(StartDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      this.formRef.current.setFieldsValue({
        StartDate: moment(StartDateMoment, 'YYYY-MM-DD'),
        StartTime: moment(StartDateMoment, 'HH:mm:ss'),
        EndDate: moment(StartDateMoment, 'YYYY-MM-DD'),
        EndTime: moment(StartDateMoment, 'HH:mm:ss'),
      });
    }

    this.setState({
      allDayEvent: isFullDayEvent,
    });
  };

  validateDate = (startDate: any) => {
    if (this.state.isRecurringEvent) {
      this.formRef.current.setFieldsValue({
        EndDate: startDate,
      });
    }

    if (this.state.allDayEvent) {
      const StartDateMoment = moment(startDate).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });
      this.formRef.current.setFieldsValue({
        StartDate: moment(StartDateMoment, 'YYYY-MM-DD'),
        StartTime: moment(StartDateMoment, 'HH:mm:ss'),
        EndDate: moment(StartDateMoment, 'YYYY-MM-DD'),
        EndTime: moment(StartDateMoment, 'HH:mm:ss'),
      });
    }
  };

  render() {
    return (
      <>
        <Modal
          title={i18n.t(k.CALENDAR__SYNC_WITH)}
          open={this.state.syncModalVisible}
          okText={i18n.t(k.CTA__SYNC)}
          onOk={() => {
            this.props.connectCalendar({
              payload: {
                calendar: this.state.syncCalenderValue,
                id: Number(this.props.planItemId),
                redirectOption: CalendarCallbackRedirect.UserTime,
              },
            });
            this.setState({syncModalVisible: false, syncCalenderValue: -1});
          }}
          onCancel={() => this.setState({syncModalVisible: false})}>
          <Radio.Group
            onChange={(e) => this.setState({syncCalenderValue: e.target.value})}
            value={this.state.syncCalenderValue}>
            <Radio style={radioStyle} value={0}>
              {i18n.t(k.CALENDAR__GOOGLE)}
            </Radio>
            <Radio style={radioStyle} value={1}>
              {i18n.t(k.CALENDAR__OUTLOOK)}
            </Radio>
          </Radio.Group>
        </Modal>

        <AddUpdateEventDrawer
          eventId={this.state.eventId}
          onCloseDrawer={this.onCloseDrawer}
          visibleAddEventDrawer={this.state.visibleAddEventDrawer}
          handleCancel={this.handleCancel}
          handleOk={this.handleOk}
          setToFullDayEvent={this.setToFullDayEvent}
          validateDate={this.validateDate}
          allDayEvent={this.state.allDayEvent}
          isRecurringEvent={this.state.isRecurringEvent}
          onIsRecurringCheckboxChange={this.onIsRecurringCheckboxChange}
          formRef={this.formRef}
        />

        <div>
          <FullCalendar
            ref={this.calRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev title next today',
              center: 'approve sync',
              right: 'toDay toWeek toMonth',
            }}
            initialView={this.props.view}
            editable={false}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            customButtons={{
              sync: {
                text: i18n.t(k.CTA__SYNC),
                click: () => {
                  this.setState({
                    syncModalVisible: true,
                  });
                },
              },
              approve: {
                text: i18n.t(k.CTA__SUBMIT_FOR_APPROVAL),
                click: () => {
                  api
                    .post('time/request-approval', {
                      UserPlanItemId: +this.props.planItemId,
                    })
                    .then((result) => {
                      if (result.data) {
                        notify.submitTimeForApprovalSuccess();
                      } else {
                        notify.submitTimeForApprovalError();
                      }
                    });
                  this.setState({
                    syncModalVisible: false,
                    syncCalenderValue: -1,
                  });
                },
              },
              today: {
                text: i18n.t(k.DATE__TODAY),
                click: () => {
                  this.handleToDay();
                },
              },
              toDay: {
                text: i18n.t(k.DATE__DAY),
                click: () => {
                  this.handleToDay();
                },
              },
              toWeek: {
                text: i18n.t(k.DATE__WEEK),
                click: () => {
                  this.handleToWeek();
                },
              },
              toMonth: {
                text: i18n.t(k.DATE__MONTH),
                click: () => {
                  this.handleToMonth();
                },
              },
              next: {
                text: i18n.t(k.CTA__NEXT__ABBREVIATED).toLocaleLowerCase(),
                click: () => {
                  this.calRef.current.getApi().next();
                  this.fetchWithCurrentDate(this.props.view);
                },
              },
              prev: {
                text: i18n.t(k.CTA__PREVIOUS__ABBREVIATED).toLocaleLowerCase(),
                click: () => {
                  this.calRef.current.getApi().prev();
                  this.fetchWithCurrentDate(this.props.view);
                },
              },
            }}
            // datesSet={e => {
            //   console.log("paginate", e);
            //   this.props.fetchUserEvents(moment(e.start), moment(e.end));
            // }}
            visibleRange={{end: this.props.end, start: this.props.start}}
            weekends={this.state.weekendsVisible}
            initialEvents={this.props.data} // alternatively, use the `events` setting to fetch from a feed
            select={this.handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            eventClassNames={renderEventClassNames}
            displayEventEnd={true}
            /* you can update a remote database when these fire:
                        eventAdd={function(){}}
                        eventChange={function(){}}
                        eventRemove={function(){}}
                        */
          />
        </div>
      </>
    );
  }

  fetchWithCurrentDate(view_name?: string) {
    const currentEnd = this.calRef.current.getApi().view.currentEnd;
    const currentStart = this.calRef.current.getApi().view.currentStart;
    this.props.fetchUserEvents(
      moment(currentStart),
      moment(currentEnd),
      view_name
    );
  }

  handleCurrentDay = () => {
    this.fetchWithCurrentDate('timeGridDay');
  };

  handleToDay = () => {
    const currentDate = moment();
    this.props.fetchUserEvents(
      moment(currentDate),
      moment(currentDate),
      'timeGridDay'
    );
  };

  handleToWeek = () => {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('isoWeek');
    const weekEnd = currentDate.clone().endOf('isoWeek');
    this.props.fetchUserEvents(
      moment(weekStart),
      moment(weekEnd),
      'timeGridWeek'
    );
  };

  handleToMonth = () => {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('months');
    const weekEnd = currentDate.clone().endOf('months');
    this.props.fetchUserEvents(
      moment(weekStart),
      moment(weekEnd),
      'dayGridMonth'
    );
  };

  handleWeekendsToggle = () => {
    this.setState({
      weekendsVisible: !this.state.weekendsVisible,
    });
  };

  handleDateSelect = (selectInfo: any) => {
    this.formRef?.current?.resetFields();
    this.setState({
      visibleAddEventDrawer: true,
      eventId: false,
      allDayEvent: selectInfo.allDay,
      isRecurringEvent: false,
    });

    this.formRef?.current?.setFieldsValue({
      AllDayEvent: selectInfo.allDay,
      StartDate: moment(selectInfo.start),
      StartTime: moment(selectInfo.start),
      EndDate: moment(selectInfo.end),
      EndTime: moment(selectInfo.end),
    });
  };

  handleEventClick = (clickInfo: any) => {
    // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove()
    // }
    if (clickInfo.event.extendedProps.isEditable) {
      const start = splitDateTime(clickInfo.event.start);
      const end = splitDateTime(clickInfo.event.end);

      this.formRef?.current?.resetFields();
      this.setState({
        visible: true,
        visibleAddEventDrawer: true,
        eventId: clickInfo.event.id,
        allDayEvent: clickInfo.event.allDay,
        eventTitle: clickInfo.event.title,
        isRecurringEvent: clickInfo.event.extendedProps.recurring,
        previousStartDate: clickInfo.event.start,
      });

      const startDate = moment(start.date, 'YYYY-MM-DD');
      const startTime = moment(start.time, 'HH:mm:ss');

      this.formRef?.current?.setFieldsValue({
        Title: clickInfo.event.title,
        Description: clickInfo.event.extendedProps.description,
        StartDate: startDate,
        StartTime: startTime,
        EndDate: clickInfo.event.allDay
          ? startDate
          : moment(end.date, 'YYYY-MM-DD'),
        EndTime: clickInfo.event.allDay
          ? startTime
          : moment(end.time, 'HH:mm:ss'),
        AllDayEvent: clickInfo.event.allDay,
        Recurring: clickInfo.event.extendedProps.recurring,
      });

      if (this.state.isRecurringEvent) {
        this.formRef?.current?.setFieldsValue({
          RecurrenceType:
            clickInfo.event.extendedProps.recurringDetail.recurrenceType,
        });
      }
    }
  };

  handleEvents = (events: any) => {
    this.setState({
      currentEvents: events,
    });
  };

  onIsRecurringCheckboxChange = (event: any) => {
    const isRecurringEvent = event.target.checked;
    this.setState({isRecurringEvent});
    // Set the end date as the same as the start date when you make a recurring event
    if (isRecurringEvent) {
      const {StartDate} = this.formRef.current.getFieldsValue();
      this.formRef.current.setFieldsValue({
        EndDate: StartDate,
      });
    }
  };
}
