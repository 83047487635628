import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import {AcademyVM} from '@generated/interfaces';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {useGetDefaultAcademyTheme} from '@utils/academyBranding';
import AcademyDrawerSideBar from './AcademyDrawerSideBar.container';
import DrawerPage from '@components/reusable/DrawerPage';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const EditAcademy = styled.button`
  color: ${COLORS.Blue800};
  font-size: 1rem;
  cursor: pointer;
  margin-right: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const TransitionContainer = styled.div<{opacity: number}>`
  transition: opacity 0.8s ease;
  opacity: ${({opacity}) => opacity};
`;

const PreviewBanner = styled.div`
  width: 100%;
  background-color: ${COLORS.Red100};
  color: ${COLORS.Red900};
  padding: 7px;
  position: fixed;
  z-index: 3;
  text-align: center;
  font-weight: 600;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface AcademyDrawerProps {
  academy?: AcademyVM;
  onClose: () => void;
  children: React.ReactNode;
  currentLevelTitle?: string;
  isLoading: boolean;
  showEditAcademyLink: boolean;
  getScreenNavItemUrlPath: (academyId: number, screen: string) => string;
  onClickEdit: () => void;
  renderPageInsteadOfDrawer?: boolean;
  transitionOnRender?: boolean;
  isActivePlanItem: boolean;
}

function AcademyDrawer({
  academy,
  onClose,
  children,
  currentLevelTitle,
  isLoading,
  showEditAcademyLink,
  getScreenNavItemUrlPath,
  onClickEdit,
  renderPageInsteadOfDrawer,
  transitionOnRender,
  isActivePlanItem,
}: AcademyDrawerProps) {
  useGetDefaultAcademyTheme(academy);
  const [transitionContainerOpacity, setTransitionContainerOpacity] =
    React.useState(transitionOnRender ? 0 : 1);

  React.useEffect(() => {
    if (!transitionContainerOpacity) {
      setTransitionContainerOpacity(1);
    }
  }, []);

  return renderPageInsteadOfDrawer ? (
    <TransitionContainer opacity={transitionContainerOpacity}>
      <DrawerPage
        headerRightContent={
          showEditAcademyLink ? (
            <EditAcademy onClick={onClickEdit}>
              {i18n.t(k.ACADEMY__EDIT)}
            </EditAcademy>
          ) : null
        }
        title={academy?.name ?? i18n.t(k.STATUS__LOADING)}
        onClickBack={onClose}>
        {!isActivePlanItem && (
          <PreviewBanner>{i18n.t(k.GENERIC__PREVIEW_NOTICE)}</PreviewBanner>
        )}
        <AcademyDrawerSideBar
          getScreenNavItemUrlPath={getScreenNavItemUrlPath}
          academy={academy}
          currentLevelTitle={currentLevelTitle}
          isLoading={isLoading}
          isActivePlanItem={isActivePlanItem}>
          {children}
        </AcademyDrawerSideBar>
      </DrawerPage>
    </TransitionContainer>
  ) : (
    <LearnInPageDrawer
      contentWrapperStyle={{transform: 'none'}}
      headerContent={
        showEditAcademyLink && (
          <EditAcademy onClick={onClickEdit}>
            {i18n.t(k.ACADEMY__EDIT)}
          </EditAcademy>
        )
      }
      title={academy?.name ?? i18n.t(k.STATUS__LOADING)}
      onClose={onClose}
      visible={true}
      drawerStyle={{transform: 'none'}}
      zIndex={998}>
      {!isActivePlanItem && (
        <PreviewBanner>{i18n.t(k.GENERIC__PREVIEW_NOTICE)}</PreviewBanner>
      )}
      <AcademyDrawerSideBar
        getScreenNavItemUrlPath={getScreenNavItemUrlPath}
        academy={academy}
        currentLevelTitle={currentLevelTitle}
        isLoading={isLoading}
        isActivePlanItem={isActivePlanItem}>
        {children}
      </AcademyDrawerSideBar>
    </LearnInPageDrawer>
  );
}

export default AcademyDrawer;
