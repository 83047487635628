import {useEffect, useState} from 'react';
import styled from 'styled-components';
import 'react-quill/dist/quill.snow.css';
import {
  CustomProgramAdminDetailVM,
  CustomProgramAdminSectionVM,
} from '@models/serverModels';
import {COLORS, SECTIONS_NAV_WIDTH, SIDE_NAV_WIDTH} from '@utils/constants';
import {notify} from '@components/user/notifications';
import CustomProgramAdminDrawerHeader from './CustomProgramAdminDrawerHeader.container';
import CustomProgramAdminDrawerSideNav from './CustomProgramAdminDrawerSideNav.container';
import CustomProgramSetupScreen from './setup/CustomProgramSetupScreen.container';
import LearnInPageDrawer, {
  IconContainer,
  TitleContainer,
} from '@components/reusable/LearnInPageDrawer';
import CurriculumScreen from './curriculum/CurriculumScreen.container';
import {SnippetsOutlined} from '@ant-design/icons';
import CustomProgramPermissionsScreenContainer from './permissions/CustomProgramPermissionsScreen.container';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {Breakpoints} from '@utils/responsiveUtils';
import {i18n, k} from '@i18n/translate';

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const ScreenContainer = styled.div<{isCurriculumTabSelected: boolean}>`
  background: ${COLORS.Neutral50};
  padding: 16px 16px 72px
    ${({isCurriculumTabSelected}) =>
      isCurriculumTabSelected
        ? SIDE_NAV_WIDTH + SECTIONS_NAV_WIDTH + 16
        : SIDE_NAV_WIDTH + 16}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: auto;
  width: 1200px;
  max-width: 90%;
  .ql-container {
    min-height: 80px;
  }
`;

export const ReportsContainer = styled.div<{isCurriculumTabSelected: boolean}>`
  padding: 16px 16px 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-left: ${() => SIDE_NAV_WIDTH}px;
  width: 90%;
  .ql-container {
    min-height: 80px;
  }
  @media (max-width: ${Breakpoints.Tablet}px) {
    margin-left: 0;
  }
`;

/*
|--------------------------------------------------------------------------
| Drawer Component
|--------------------------------------------------------------------------
*/

interface CustomProgramAdminDrawerProps {
  customProgramId: number;
  initialScreen?: CustomProgramScreens;
  isCustomProgramsReportsOn?: boolean;
  onClose: () => void;
  visible: boolean;
  setCustomProgramForEdit?: (program: CustomProgramAdminDetailVM) => void;
  isOverlay?: boolean;
  refreshParentData?: () => void;
  onChangeScreen?: (screen: CustomProgramScreens) => void;
  currentScreen?: CustomProgramScreens;
}

export default function CustomProgramAdminDrawer({
  onClose,
  visible,
  initialScreen,
  currentScreen: controlledCurrentScreen,
  customProgramId,
  setCustomProgramForEdit,
  isOverlay,
  refreshParentData,
  onChangeScreen,
}: CustomProgramAdminDrawerProps) {
  const [isWelcomeEmailEnabled, setIsWelcomeEmailEnabled] = useState<
    boolean | undefined
  >(undefined);
  const [_currentScreen, setCurrentScreen] = useState(
    CustomProgramScreens.Setup
  );
  const [modalClassName, setModalClassName] = useState('');

  // allow controlling state to take precedence over local state
  const currentScreen = controlledCurrentScreen || _currentScreen;

  const formattedCurrentScreen = currentScreen?.toLowerCase();
  const currentScreenTitles = {
    Setup: i18n.t(k.GENERIC__SETUP),
    Curriculum: i18n.t(k.PROGRAM__CUSTOM__CURRICULUM),
    Projects: i18n.t(k.PROJECT__PLURAL),
    Reports: i18n.t(k.REPORT__PLURAL),
    Agenda: i18n.t(k.AGENDA),
    Permissions: i18n.t(k.PERMISSION__PLURAL),
    Content: i18n.t(k.CONTENT),
  };

  useEffect(() => {
    setCurrentScreen(initialScreen || CustomProgramScreens.Setup);
  }, [visible, initialScreen]);

  const isCurriculumTabSelected =
    formattedCurrentScreen === CustomProgramScreens.Curriculum.toLowerCase();

  const handleChangeScreen = (screen: CustomProgramScreens) => {
    if (onChangeScreen) {
      onChangeScreen(screen);
    } else {
      setCurrentScreen(screen);
    }
  };

  const updateModalBackground = (toggleWhiteBackgroundOn: boolean) => {
    setModalClassName(toggleWhiteBackgroundOn ? 'white-background' : '');
  };

  return (
    <LearnInPageDrawer
      visible={visible}
      destroyOnClose={true}
      onClose={onClose}
      className={modalClassName}
      title={
        <TitleContainer>
          <IconContainer>
            <SnippetsOutlined aria-hidden="true" />
          </IconContainer>
          <div>{currentScreenTitles[currentScreen]}</div>
        </TitleContainer>
      }
      headerContent={
        <CustomProgramAdminDrawerHeader
          isWelcomeEmailEnabled={isWelcomeEmailEnabled}
          customProgramId={customProgramId}
          isOverlay={isOverlay}
          closeDrawer={onClose}
        />
      }>
      {/* MAIN SIDE NAV */}
      <CustomProgramAdminDrawerSideNav
        currentScreen={currentScreen}
        onClickSetup={() => handleChangeScreen(CustomProgramScreens.Setup)}
        onClickPermissions={() =>
          handleChangeScreen(CustomProgramScreens.Permissions)
        }
        onClickCurriculum={() => {
          if (!customProgramId) {
            return notify.saveProgramRequired();
          }
          handleChangeScreen(CustomProgramScreens.Curriculum);
        }}
      />

      {/* SETUP SCREEN */}
      {formattedCurrentScreen === CustomProgramScreens.Setup.toLowerCase() && (
        <ScreenContainer isCurriculumTabSelected={isCurriculumTabSelected}>
          <CustomProgramSetupScreen
            isWelcomeEmailEnabled={isWelcomeEmailEnabled}
            setIsWelcomeEmailEnabled={setIsWelcomeEmailEnabled}
            customProgramId={customProgramId}
            setCustomProgramForEdit={setCustomProgramForEdit}
            refreshParentData={refreshParentData}
          />
        </ScreenContainer>
      )}

      {/* Permissions SCREEN */}
      {formattedCurrentScreen ===
        CustomProgramScreens.Permissions.toLowerCase() && (
        <ScreenContainer isCurriculumTabSelected={isCurriculumTabSelected}>
          <CustomProgramPermissionsScreenContainer
            customProgramId={customProgramId}
          />
        </ScreenContainer>
      )}

      {/* CURRICULUM SCREEN */}
      {isCurriculumTabSelected && (
        <ScreenContainer
          isCurriculumTabSelected={isCurriculumTabSelected}
          style={{
            background: modalClassName === 'white-background' && 'white',
          }}>
          <CurriculumScreen
            customProgramId={customProgramId}
            updateModalBackground={updateModalBackground}
          />
        </ScreenContainer>
      )}
    </LearnInPageDrawer>
  );
}
