import * as React from 'react';
import styled, {css} from 'styled-components';
import {COLORS} from '@utils/constants';
import {noop} from 'lodash';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const SIZE = 84;

const StyledButton = styled.button<{isSelected?: boolean}>`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: ${SIZE}px;
  justify-content: center;
  padding: 5px;
  width: ${SIZE}px;
  .label {
    color: ${COLORS.Neutral900};
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 1rem;
  }
  .icon {
    color: #bcbdbe;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }
  &:hover {
    background: ${COLORS.Neutral50};
    .label {
      color: ${COLORS.Neutral900};
    }
    .icon {
      color: ${COLORS.Blue800} !important;
    }
  }
  ${({isSelected}) => {
    return (
      isSelected &&
      css`
        cursor: default;
        background: ${COLORS.Blue50};
        &:hover {
          background: ${COLORS.Blue50};
        }
        .label {
          font-weight: 500;
          color: ${COLORS.Neutral950};
        }
        .icon {
          color: ${COLORS.Blue800} !important;
        }
      `
    );
  }}
  ${({disabled}) => {
    return (
      disabled &&
      css`
        cursor: default;
        background: ${COLORS.Neutral100};
        &:hover {
          background: ${COLORS.Neutral100};
        }
        .label {
          font-weight: 500;
          color: ${COLORS.Neutral300};
        }
        .icon {
          color: ${COLORS.Neutral300} !important;
        }
        &:hover {
          cursor: default;
          background: ${COLORS.Neutral100};
          &:hover {
            background: ${COLORS.Neutral100};
          }
          .label {
            font-weight: 500;
            color: ${COLORS.Neutral300};
          }
          .icon {
            color: ${COLORS.Neutral300} !important;
          }
        }
      `
    );
  }}
`;

/*
|--------------------------------------------------------------------------
| Button Component
|--------------------------------------------------------------------------
*/

export interface NavigationButtonProps {
  disabled?: boolean;
  Icon: any;
  isSelected?: boolean;
  label: string;
  onClick: () => void;
  style?: React.CSSProperties;
}

function NavigationButton({
  isSelected = false,
  label,
  onClick = noop,
  disabled,
  style,
  Icon,
}: NavigationButtonProps) {
  return (
    <StyledButton
      data-testid={`nav-button-${label}`}
      onClick={disabled ? noop : onClick}
      isSelected={isSelected}
      style={style}
      disabled={disabled}>
      {/* using role="presentation" vs. aria-hidden="true" is intentional here due to CustomProgramUserDrawerWithRouting.test.tsx */}
      <Icon
        role="presentation"
        className="icon"
        style={{marginBottom: '8px', fontSize: '1.25rem'}}
      />
      <div className="label" data-testid={`nav-button-label-${label}`}>
        {label}
      </div>
    </StyledButton>
  );
}

export default NavigationButton;
