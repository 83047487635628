import {Container, StyledSelect} from './Skills.styled';
import {SkillsViewProps} from './Skills.interfaces';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import __ from 'lodash';
import {ProgramFinderHeadings} from '@pages/ProgramFinder/ProgramFinderHeadings';
import {ProgramSearchVM} from '@generated/interfaces';
import {useState} from 'react';

function SkillsView({
  programOptions,
  handleAction,
  skillOptions,
  includeSkills,
}: SkillsViewProps) {
  const options = __.uniqBy(
    (includeSkills ? skillOptions : programOptions) as {
      value: string | ProgramSearchVM;
      label: string | ProgramSearchVM;
    }[],
    'value'
  ).map(({label, value}) => ({value: label, key: value}));

  const filterOptions = (input, option) =>
    option.value.toUpperCase().includes(input.toUpperCase());
  const [searchValue, setSearchValue] = useState('');
  const handleSearch = (event) => {
    if (event.key === 'Enter' || event.type === 'blur') {
      const filteredOptions = options.filter(
        (option) =>
          typeof option.value === 'string' &&
          option.value.toUpperCase().includes(searchValue.toUpperCase())
      );
      handleAction(filteredOptions.length > 0 && searchValue);
    }
  };

  return (
    <Container direction="vertical" size="large">
      {includeSkills ? (
        <ProgramFinderHeadings
          title={i18n.t(k.PROGRAM_FINDER__TITLE__SKILLS)}
          subtitle={i18n.t(k.PROGRAM_FINDER__SUBTITLE__SKILLS)}
        />
      ) : (
        <ProgramFinderHeadings
          title={i18n.t(k.PROGRAM_FINDER__TITLE__PROGRAMS)}
          subtitle={i18n.t(k.PROGRAM_FINDER__SUBTITLE__PROGRAMS)}
        />
      )}
      <Form.Item name="skills">
        <StyledSelect
          options={options}
          onChange={setSearchValue}
          onKeyDown={handleSearch}
          onSelect={handleAction}
          onBlur={handleSearch}
          placeholder={i18n.t(k.SKILL__COMMUNICATION)}
          filterOption={filterOptions}
        />
      </Form.Item>
    </Container>
  );
}

export default SkillsView;
