import {BaseEntity} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: BaseEntity

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getBaseEntityMockData(args?: Partial<BaseEntity>): BaseEntity {
  return {
    createdOn: String(faker.date.past()),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const BaseEntityMockData: BaseEntity = {
  createdOn: '2023-03-29T22:17:19.738072',
};
