import {AcademyVM} from '@models/serverModels';
import {useNavigate} from 'react-router-dom';
import {getDeleteSelfAcademyUserRm} from '@store/apiEndpoints/academy/mutations';
import {useState} from 'react';
import {simpleDeleteFn} from '@store/queryClient';
import AcademiesCarousel from './AcademiesCarousel';
import DeleteAcademyModal from './DeleteAcademyModal';
import {useMutation} from 'react-query';
import {notify} from '@components/user/notifications';
import {userAcademyRoute} from '@utils/ClientPaths';
import {useEnrolledAcademyVMsQuery} from '@hooks/apiEndpoints/academy/queries';
import {useAcademiesUserCompanyByIdQuery} from '@generated/hooks';
import {useAuth} from '@utils/oidc-auth-wrapper';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface AcademiesCarouselContainerProps {
  enrolledAcademies: AcademyVM[];
}

function AcademiesCarouselContainer({
  enrolledAcademies,
}: AcademiesCarouselContainerProps) {
  const navigate = useNavigate();
  const [selectedAcademy, setSelectedAcademy] = useState<AcademyVM | null>(
    null
  );
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const {refetch: refetchEnrolledAcademies} = useEnrolledAcademyVMsQuery(null);

  const {user} = useAuth();
  const {invalidateExact: invalidateEnrolledAcademyUserQuery} =
    useAcademiesUserCompanyByIdQuery(
      {
        academyId: selectedAcademy?.id,
        userCompanyId: user?.userCompanyId,
      },
      {enabled: false}
    );

  // Delete Custom Program
  const deleteAcademyMutation = useMutation(
    (academyId: number) => {
      const deleteAcademyRm = getDeleteSelfAcademyUserRm(academyId);
      return simpleDeleteFn<null>(
        deleteAcademyRm.path,
        deleteAcademyRm.payload
      );
    },
    {
      onSuccess: async () => {
        await refetchEnrolledAcademies();
        notify.removeAcademySuccess();
        setShowRemoveModal(false);
        invalidateEnrolledAcademyUserQuery();
      },
      onError: () => {
        notify.removeAcademyError();
      },
    }
  );
  const handleClickViewAcademy = (academy: AcademyVM) => {
    navigate(userAcademyRoute(academy.id));
    setSelectedAcademy(academy);
  };

  const handleShowRemoveAcademy = (academy) => {
    setSelectedAcademy(academy);
    setShowRemoveModal(true);
  };

  const removeAcademy = () => {
    deleteAcademyMutation.mutate(selectedAcademy.id);
  };

  return (
    <>
      <AcademiesCarousel
        userAcademies={enrolledAcademies}
        onClickGoToAcademy={handleClickViewAcademy}
        onClickRemoveAcademy={handleShowRemoveAcademy}
      />
      <DeleteAcademyModal
        isVisible={showRemoveModal}
        setIsVisible={setShowRemoveModal}
        onOk={removeAcademy}
      />
    </>
  );
}

export default AcademiesCarouselContainer;
