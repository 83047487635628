import {useState, useEffect} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Radio} from 'antd';
import {StarRatingForm} from './StarRatings';
import moment, {Moment} from 'moment';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '../../Button/Button.style';
import {ButtonTags} from '../../Button/ButtonEnums';
import {ProgramReviewVM} from '../../../../models/serverModels';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {IHandleUpsertArgs} from './ReviewsTab';
import {LearnInInput} from '../../Input/Input.style';
import {LearnInTextArea} from '../../TextArea/TextArea.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {StyledLearnInDatePicker} from '@components/reusable/LearnInDatePicker';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  .ant-btn.ant-btn-primary.ant-btn-round {
    background: tomato !important;
  }
`;

const ModalTitle = styled.h1`
  font-size: 1.25rem;
  margin-bottom: 24px;
`;

const InputLabel = styled.label`
  display: block;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  margin-bottom: 7px;
`;

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const VisibilityInfo = styled.p`
  color: ${COLORS.Neutral600};
  font-size: 0.75rem;
  margin-top: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  padding: 6px 0px;
  button {
    margin-left: 8px;
  }
`;

const RequiredMarker = () => <span style={{color: COLORS.Red800}}>*</span>;

/*
|--------------------------------------------------------------------------
| Upsert Modal
|--------------------------------------------------------------------------
*/

export enum UpsertMode {
  Add,
  Edit,
}

interface IProgramReviewUpsertModalProps {
  programReview: ProgramReviewVM | null;
  visible: boolean;
  onCancel: () => void;
  handleSubmitUpsert: (args: IHandleUpsertArgs) => void;
}

export const ProgramReviewUpsertModal = ({
  programReview,
  visible,
  onCancel,
  handleSubmitUpsert,
}: IProgramReviewUpsertModalProps) => {
  const [hasCompletedProgram, setHasCompletedProgram] = useState<
    boolean | null
  >(null);
  const [programCompletedOn, setProgramCompletedOn] = useState<
    string | null | Moment
  >(null);
  const [rating, setRating] = useState(0);
  const [reviewContent, setReviewContent] = useState('');
  const [title, setTitle] = useState('');
  const upsertMode = !!programReview ? UpsertMode.Edit : UpsertMode.Add;

  // Reset form fields when modal is opened
  useEffect(() => {
    if (visible) {
      setTitle(programReview?.title || '');
      setReviewContent(programReview?.reviewContent || '');
      setRating(programReview?.rating || 0);
      setHasCompletedProgram(
        typeof programReview?.hasCompletedProgram === 'boolean'
          ? programReview?.hasCompletedProgram
          : null
      );
      setProgramCompletedOn(
        programReview?.hasCompletedProgram &&
          programReview?.programCompletedOn !== null
          ? moment(programReview?.programCompletedOn).toString()
          : null
      );
    }
  }, [visible]);

  // Update Form Fields
  const onStarRatingFormClick = (idx: any) => {
    setRating(idx + 1);
  };

  const onHasCompletedProgramChange = (e: any) => {
    setHasCompletedProgram(e.target.value);
    if (!programCompletedOn) {
      setProgramCompletedOn(moment());
    }
  };

  const onProgramCompletedOnChange = (date: any) => {
    setProgramCompletedOn(date);
  };

  const onTitleChange = (e: any) => {
    setTitle(e.target.value);
  };

  const onReviewContentChange = (e: any) => {
    setReviewContent(e.target.value);
  };

  // Submit upsert
  const isSubmitDisabled =
    !rating ||
    !title ||
    !reviewContent ||
    typeof hasCompletedProgram !== 'boolean' ||
    (hasCompletedProgram && !programCompletedOn);

  const handleSubmitClick = () => {
    handleSubmitUpsert({
      id: programReview?.id,
      hasCompletedProgram,
      programCompletedOn: moment(programCompletedOn, 'x'),
      rating,
      reviewContent,
      title,
      upsertMode,
    });
  };
  const modalTitle = !!programReview?.id
    ? i18n.t(k.REVIEW__EDIT)
    : i18n.t(k.REVIEW__ADD);

  return (
    <Container>
      <LearnInModal
        tag={ModalTags.MoneyIncentive}
        onCancel={onCancel}
        visible={visible}
        footer={
          <ButtonsContainer>
            <LearnInButton tag={ButtonTags.Secondary} onClick={onCancel}>
              {i18n.t(k.CTA__CANCEL)}
            </LearnInButton>
            <LearnInButton
              data-testid="submit-review-button"
              tag={ButtonTags.Primary}
              onClick={handleSubmitClick}
              disabled={isSubmitDisabled}>
              {upsertMode === UpsertMode.Edit
                ? i18n.t(k.REVIEW__UPDATE)
                : i18n.t(k.REVIEW__SUBMIT)}
            </LearnInButton>
          </ButtonsContainer>
        }
        ariaLabel={modalTitle}>
        <ModalTitle>{modalTitle}</ModalTitle>
        <form>
          <InputContainer>
            <InputLabel htmlFor="review-program-yes">
              {i18n.t(k.PROGRAM__REVIEW__HAVE_COMPLETED)}
            </InputLabel>
            <Radio.Group
              value={hasCompletedProgram}
              onChange={onHasCompletedProgramChange}>
              <div>
                <Radio value={false} id="review-program-no">
                  {i18n.t(k.GENERIC__NO)}
                </Radio>
              </div>
              <div>
                <Radio value={true} id="review-program-yes">
                  {i18n.t(k.GENERIC__YES_COMPLETED_ON)}
                </Radio>
                <StyledLearnInDatePicker
                  style={{width: '180px'}}
                  value={
                    hasCompletedProgram ? moment(programCompletedOn, 'x') : null
                  }
                  onChange={onProgramCompletedOnChange}
                  disabled={!hasCompletedProgram}
                />
              </div>
            </Radio.Group>
          </InputContainer>
          <InputContainer>
            <InputLabel htmlFor="star-input-1">
              {i18n.t(k.PROGRAM__REVIEW__HOW_RATE)}
            </InputLabel>
            <StarRatingForm rating={rating} onClick={onStarRatingFormClick} />
          </InputContainer>
          <InputContainer>
            <InputLabel htmlFor="program-review-input">
              {i18n.t(k.PROGRAM__REVIEW__TITLE)} <RequiredMarker />
            </InputLabel>
            <LearnInInput
              id="program-review-input"
              tag={InputTags.PRIMARY}
              value={title}
              onChange={onTitleChange}
              style={{
                borderRadius: '4px',
                padding: '10px 16px',
                fontSize: '1rem',
              }}
            />
          </InputContainer>
          <div>
            <InputLabel htmlFor="describe-experience-input">
              {i18n.t(k.PROGRAM__REVIEW__DESCRIBE_EXPERIENCE)}{' '}
              <RequiredMarker />
            </InputLabel>
            <LearnInTextArea
              id="describe-experience-input"
              tag={TextAreaTags.PRIMARY}
              value={reviewContent}
              onChange={onReviewContentChange}
              style={{
                height: '100px',
                borderRadius: '4px',
                padding: '10px 16px',
                fontSize: '1rem',
              }}
            />
          </div>
          <VisibilityInfo>{i18n.t(k.PROGRAM__VISIBLE_COMPANY)}</VisibilityInfo>
        </form>
      </LearnInModal>
    </Container>
  );
};

/*
|--------------------------------------------------------------------------
| Confirm Delete Modal
|--------------------------------------------------------------------------
*/

export const ProgramReviewDeleteConfirmationModal = ({
  visible,
  onCancel,
  handleDeleteReview,
}: {
  visible: any;
  onCancel: any;
  handleDeleteReview: any;
}) => {
  const modalTitle = i18n.t(k.CTA__DELETE_ITEM__FORMAT, {
    item: i18n.t(k.REVIEW),
  });
  return (
    <LearnInModal
      tag={ModalTags.MoneyIncentive}
      onCancel={onCancel}
      visible={visible}
      okText={i18n.t(k.REVIEW__DELETE)}
      footer={
        <ButtonsContainer>
          <LearnInButton tag={ButtonTags.Secondary} onClick={onCancel}>
            {i18n.t(k.CTA__CANCEL)}
          </LearnInButton>
          <LearnInButton
            data-testid="delete-review-button"
            tag={ButtonTags.Destructive}
            onClick={handleDeleteReview}>
            {i18n.t(k.REVIEW__DELETE)}
          </LearnInButton>
        </ButtonsContainer>
      }
      ariaLabel={modalTitle}>
      <h1>{modalTitle}</h1>
      <p>{i18n.t(k.REVIEW__DELETE__PROMPT)}</p>
    </LearnInModal>
  );
};
