interface Props extends React.SVGProps<SVGSVGElement> {
  alt?: string;
}

export const UploadIcon = (props: Props) => {
  return (
    <svg
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <title>{props.alt}</title>
      <path
        d="M7.70588 4.48165H9.87941V14.0915C9.87941 14.2165 9.98529 14.3187 10.1147 14.3187H11.8794C12.0088 14.3187 12.1147 14.2165 12.1147 14.0915V4.48165H14.2941C14.4912 4.48165 14.6 4.26292 14.4794 4.1152L11.1853 0.0871928C11.1633 0.0600313 11.1352 0.0380648 11.1031 0.0229579C11.071 0.00785099 11.0357 0 11 0C10.9643 0 10.929 0.00785099 10.8969 0.0229579C10.8648 0.0380648 10.8367 0.0600313 10.8147 0.0871928L7.52059 4.11236C7.4 4.26292 7.50882 4.48165 7.70588 4.48165ZM21.7647 13.2393H20C19.8706 13.2393 19.7647 13.3416 19.7647 13.4666V17.8411H2.23529V13.4666C2.23529 13.3416 2.12941 13.2393 2 13.2393H0.235294C0.105882 13.2393 0 13.3416 0 13.4666V19.091C0 19.5938 0.420588 20 0.941176 20H21.0588C21.5794 20 22 19.5938 22 19.091V13.4666C22 13.3416 21.8941 13.2393 21.7647 13.2393Z"
        fill={props.fill || '#44444A'}
      />
    </svg>
  );
};
