import {
  getAdminMarketplaceProgramsRq,
  getProgramDetailsRq,
  getProgramTypesRq,
  getProgramsProgramTypesRq,
  getProvidersRq,
} from '@store/apiEndpoints/program/queries';
import {queryHookFactory} from '../utils';
import {
  MarketPlaceProgramsTableData,
  ProgramDetailsVM,
  ProgramFilterVM,
} from '@models/serverModels';
import {ProgramTypesVM} from '@components/admin/pages/investments/components/incentives/store/interfaces';

export const useGetProgramDetailsQuery = queryHookFactory<
  number,
  ProgramDetailsVM
>(getProgramDetailsRq);

export const useGetProvidersQuery = queryHookFactory<null, ProgramFilterVM[]>(
  getProvidersRq
);

export const useGetProgramTypesQuery = queryHookFactory<null, ProgramTypesVM>(
  getProgramTypesRq
);

export const useGetProgramsProgramTypesQuery = queryHookFactory<
  null,
  ProgramTypesVM
>(getProgramsProgramTypesRq);

export const useGetAdminMarketplaceProgramsQuery = queryHookFactory<
  null,
  MarketPlaceProgramsTableData[]
>(getAdminMarketplaceProgramsRq);
