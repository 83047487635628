import {ReactNode} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import styled, {css} from 'styled-components';
import {LinkVM} from '@models/serverModels';
import {NewWindowIcon} from '@assets/new-window-icon';
import {RichTextEditor} from '@components/reusable/RichTextEditor';
import attachmentPreviewImg from '@assets/contentAttachmentPreview.png';
import {Video} from 'cloudinary-react';
import {ContentLink} from '@components/reusable/ContentLink';
import {formatUrl} from '@utils/stringUtils';
import {AttachmentTypeString} from '@models/clientEnums';
import {ContentType} from '@generated/enums';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import moment from 'moment';
import {parseToLocalMoment} from '@utils/timeUtils';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {AttachmentVM} from '@generated/interfaces';
import ContentDisplay from '@blocks/ContentDisplay';
import {Breakpoints, ResponsiveContainer} from '@utils/responsiveUtils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const ContentCardContainerStyled = styled.div<{
  addBottomMargin: boolean;
  isFullPage: boolean | undefined;
}>`
  background: ${COLORS.White};
  box-shadow: ${COLORS.BoxShadowStandard};
  border-radius: 10px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: ${({addBottomMargin}) => addBottomMargin && '24px'};
  ${({isFullPage}) => {
    return (
      isFullPage &&
      css`
        box-shadow: unset;
        background: transparent;
        padding-left: ${INDEX_SIZE + 16}px;
      `
    );
  }}

  @media (max-width: ${Breakpoints.Tablet}px) {
    border-radius: 0;
  }
`;

export const ContentCardTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral900};
`;

export const ContentCardDescription = styled.div`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Neutral900};
  margin-bottom: 16px;
  word-break: break-word;

  width: 100%;
  .ql-editor {
    padding: 0 !important;
    p {
      font-size: 1rem;
    }
    li {
      font-size: 1rem;
    }
    a {
      color: ${COLORS.Blue800};
    }
  }
  .ql-container.ql-disabled .ql-tooltip {
    display: none;
  }
`;

const INDEX_SIZE = 32;
export const Index = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.Neutral200};
  border-radius: 100%;
  color: ${COLORS.Neutral600};
  font-weight: 500;
  font-size: 1rem;
  margin-right: 16px;
  width: ${INDEX_SIZE}px;
  height: ${INDEX_SIZE}px;
  background: white;
  z-index: 20;
  padding: 12px;
  position: relative;
  top: 24px;
`;

export const IndexRule = styled.div`
  border-left: 1px solid ${COLORS.Neutral200};
  width: 1px;
  height: 100%;
  position: absolute;
  top: 54px;
  left: 15px;
  z-index: 10;
`;

export const AttachmentPreviewImg = styled.img`
  border: 1px solid ${COLORS.Neutral300};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.Neutral600};
  }
`;

const ContentContainerLeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

export const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px auto 24px;
  width: 100%;
`;

export const ContentBody = styled.div`
  padding: 0px ${({isFullPage}) => !isFullPage && '24px'}};
  padding-top: 24px;
  padding-bottom: 16px;
`;

const TagWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  ${({extraStyles}) => ({...extraStyles})};
`;

const AttachmentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const ImgAttachmentButton = styled.button`
  margin: 0px 0px 16px 16px;
  @media (max-width: 768px) {
    margin: 16px 0px 24px;
  }
`;

/*
|--------------------------------------------------------------------------
| Tag Component
|--------------------------------------------------------------------------
*/

export const ContentDueDateStatus = ({
  dueDateUtc,
  completed,
  style,
}: {
  dueDateUtc: string;
  completed: boolean;
  style?: React.CSSProperties;
}) => {
  // to be updated with LI-3447 / LI-3345
  const isPastDue =
    !completed && parseToLocalMoment(dueDateUtc).isBefore(moment());
  return (
    <TagWrapper extraStyles={style}>
      <LearnInTag
        label={
          isPastDue
            ? i18n.t(k.DATE__PAST_DUE).toLocaleUpperCase()
            : i18n.t(k.DATE__DUE_DATE).toLocaleUpperCase()
        }
        tagStyle={
          isPastDue
            ? TagStyles.Red
            : completed
            ? TagStyles.LightGrey
            : TagStyles.Green
        }
      />
    </TagWrapper>
  );
};

/*
|--------------------------------------------------------------------------
| Card Component
|--------------------------------------------------------------------------
*/

export interface IContentCardProps {
  attachment: AttachmentVM | undefined;
  contentType: ContentType;
  dueDate: string | undefined;
  formattedDescription: string;
  handleClickFileAttachment: () => void;
  link: LinkVM | undefined;
  order?: number;
  renderActionButton?: () => ReactNode;
  renderFooter?: () => ReactNode;
  renderSubTitle?: () => ReactNode;
  showIndexRule: boolean;
  title: ReactNode;
  isFullPage: boolean | undefined;
  contentId: number;
}

export default function ContentCard({
  attachment,
  contentType,
  formattedDescription,
  handleClickFileAttachment,
  link,
  order,
  renderActionButton = () => null,
  renderFooter = () => null,
  renderSubTitle = () => null,
  showIndexRule,
  title,
  isFullPage,
  contentId,
}: IContentCardProps) {
  const attachmentIsFile = attachment?.type === AttachmentTypeString.FileString;
  const attachmentIsVideo =
    attachment?.type === AttachmentTypeString.VideoString;

  return (
    <Container aria-label={i18n.t(k.A11Y__ITEM__FORMAT, {title})}>
      {/* Vertical Rule & Order */}
      <ResponsiveContainer min={Breakpoints.Tablet}>
        {!isFullPage && showIndexRule && <IndexRule />}
        {!isFullPage && order && <Index>{order}</Index>}
      </ResponsiveContainer>

      <ContentCardContainerStyled
        addBottomMargin={showIndexRule}
        isFullPage={isFullPage}>
        <ContentBody isFullPage={isFullPage}>
          <ContentDisplay title={title} contentType={contentType} />

          <AttachmentContainer>
            <ContentContainerLeftColumn>
              {renderSubTitle()}
              {!!formattedDescription && (
                <ContentCardDescription>
                  <RichTextEditor
                    value={formattedDescription}
                    readOnly
                    theme="bubble"
                    id={`content-${contentId}`}
                  />
                </ContentCardDescription>
              )}
              {attachmentIsVideo && (
                <VideoContainer>
                  <Video
                    cloudName="dsmsrmuyr"
                    publicId={attachment.fileName}
                    sourceTypes="mp4"
                    controls
                    width={640}
                    style={{maxWidth: '100%'}}
                  />
                </VideoContainer>
              )}
              {/* Bottom Left Content Container */}
              <div>
                {/* Link */}
                {!!link && (
                  <ContentLink
                    style={{marginBottom: '16px'}}
                    ariaLabel={i18n.t(k.A11Y__LINK__OPEN_ATTACHMENT__FORMAT, {
                      title,
                    })}
                    title={link.title}
                    key={link.id}
                    url={formatUrl(link.url)}
                  />
                )}

                {/* File Attachment Link */}
                {attachmentIsFile && (
                  <LearnInButton
                    key={attachment.url}
                    tag={ButtonTags.SecondaryRemovedBorder}
                    onClick={handleClickFileAttachment}
                    icon={<NewWindowIcon />}>
                    {i18n.t(k.FILE__ATTACHMENT_OPEN)}
                  </LearnInButton>
                )}
              </div>
            </ContentContainerLeftColumn>
            {/* Right Container, File Attachment Preview */}
            {attachmentIsFile && (
              <ImgAttachmentButton onClick={handleClickFileAttachment}>
                <AttachmentPreviewImg
                  style={{maxHeight: '200px', maxWidth: '200px'}}
                  src={
                    attachment.image || attachment.url || attachmentPreviewImg
                  }
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = attachmentPreviewImg;
                  }}
                />
              </ImgAttachmentButton>
            )}
          </AttachmentContainer>
          {/* Complete or Join Event Button */}
          {renderActionButton()}

          {/* Project Submission Footer */}
        </ContentBody>
        {renderFooter()}
      </ContentCardContainerStyled>
    </Container>
  );
}
