import './Feedback.scss';
import {i18n, k} from '@i18n/translate';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {Col, Row} from 'antd';
import ChatlioContainer from './components/ChatlioContainer';
import useSetPageTitle from '@hooks/useSetPageTitle';

const Feedback = ({title}: {title?: string}) => {
  useSetPageTitle(title);

  return (
    <>
      <PageTitleBar title={i18n.t(k.FEEDBACK)} />
      <PageContentLayout>
        <Row align="top" gutter={[64, 16]}>
          <Col sm={24}>
            <div className="feedback-text">
              {i18n.t(k.SUPPORT__BETA_THANKS)}
            </div>
            <ChatlioContainer />
          </Col>
        </Row>
      </PageContentLayout>
    </>
  );
};

export default Feedback;
