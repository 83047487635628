import {i18n, k} from '@i18n/translate';
import NoDataText from '@blocks/NoDataText';
import {ContentType} from '@generated/enums';
import {OptionsFormDropdown} from '@components/reusable/OptionForm';
import {
  contentOptionsWithDiscussions,
  contentOptions,
} from './CurriculumScreenNewContentButton';
import LearnInToggle from '@components/reusable/LearnInToggle';
import {LearnInToggleOption} from '@components/reusable/LearnInToggle/LearnInToggle.interfaces';
import useFeatureFlags from '@hooks/useFeatureFlags';

export interface NoContentScreenProps {
  displayOptions: LearnInToggleOption[];
  onClickAddContent: (contentType: ContentType) => void;
  onToggleDisplayOptions: (option: LearnInToggleOption) => void;
  isFullScreen?: boolean;
}

function NoContentScreen({
  isFullScreen,
  displayOptions,
  onClickAddContent,
  onToggleDisplayOptions,
}: NoContentScreenProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const showDiscussions = isFeatureFlagOn.CustomProgramDiscussionSteps;
  return (
    <NoDataText
      header={i18n.t(k.PROGRAM__CUSTOM__GET_STARTED)}
      style={{height: '100%'}}
      subHeader={
        <>
          <div>{i18n.t(k.PROGRAM__CUSTOM__NO_CURRICULUM)}</div>
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <LearnInToggle
              options={displayOptions}
              onToggle={onToggleDisplayOptions}
            />
          </div>
          <OptionsFormDropdown
            style={{position: 'relative', margin: 'auto', zIndex: 0}}
            options={
              showDiscussions && !isFullScreen
                ? contentOptionsWithDiscussions(onClickAddContent)
                : contentOptions(onClickAddContent)
            }
          />
        </>
      }
    />
  );
}

export default NoContentScreen;
