import {CursorEnvelopeMessageResponse} from './../interfaces';

import {queryHookFactory, addQueryParams} from './../../utils';
import {CursorEnvelopeMessageResponseSchema} from '../zodSchemas/CursorEnvelopeMessageResponseSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramMessagesController.cs

/*
|--------------------------------------------------------------------------
|  {customProgramId}/sections/{sectionId}/contents/{contentId}/discussions/{discussionId}/messages 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsSectionsContentsDiscussionsMessagesQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramsSectionsContentsDiscussionsMessages,
 *    isLoading: isCustomProgramsSectionsContentsDiscussionsMessagesLoading,
 *    invalidateExact: invalidateCustomProgramsSectionsContentsDiscussionsMessages,
 *  } = useCustomProgramsSectionsContentsDiscussionsMessagesQuery({
 *    contentId,
 *    customProgramId,
 *    discussionId,
 *    sectionId,
 *      queryParams: {
 *        after,
 *        size
 *    }
 *  })
 *
 */

export const useCustomProgramsSectionsContentsDiscussionsMessagesQuery =
  queryHookFactory<
    {
      contentId: number;
      customProgramId: number;
      discussionId: string;
      sectionId: number;
      queryParams?: {after?: string; size?: number};
    }, // path variables
    CursorEnvelopeMessageResponse // return type
  >(
    'useCustomProgramsSectionsContentsDiscussionsMessagesQuery',
    ({contentId, customProgramId, discussionId, sectionId, queryParams}) =>
      addQueryParams(
        ({customProgramId, sectionId, contentId, discussionId}) =>
          `custom-programs/${customProgramId}/sections/${sectionId}/contents/${contentId}/discussions/${discussionId}/messages`,
        {contentId, customProgramId, discussionId, sectionId},
        queryParams
      ),
    {responseSchema: CursorEnvelopeMessageResponseSchema}
  );
