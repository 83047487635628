import {SyncCalendarRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {SyncCalendarRequestSchema} from '../zodSchemas/SyncCalendarRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Calendar/CalendarController.cs

/*
|--------------------------------------------------------------------------
|  connect-calendar 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useConnectCalendarMutation} from '@generated/hooks';
 *
 *  const connectCalendar = useConnectCalendarMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  connectCalendar.mutate({
 *    payload: {
 *      ...SyncCalendarRequest
 *    },
 *  })
 *
 */

export const useConnectCalendarMutation = mutationHookFactory<
  SyncCalendarRequest, // payload
  null,
  null
>('useConnectCalendarMutation', 'post', () => `calendar/connect-calendar`, {
  payloadSchema: SyncCalendarRequestSchema,
});

/*
|--------------------------------------------------------------------------
|  disconnect-calendar 
|--------------------------------------------------------------------------
*/

/**
*  @example
*
*  import {useDisconnectCalendarMutation} from '@generated/hooks';
*
*  const disconnectCalendar = useDisconnectCalendarMutation({
*    onSuccess: () => {},
*  });
*
*  disconnectCalendar.mutate({

*  }) 
*
*/

export const useDisconnectCalendarMutation = mutationHookFactory<
  null,
  null,
  null
>(
  'useDisconnectCalendarMutation',
  'post',
  () => `calendar/disconnect-calendar`,
  {payloadSchema: undefined}
);
