import './TransactionsTableDrawer.scss';
import {useState} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {useQuery} from 'react-query';
import {
  basicSorter,
  renderBalanceColumn,
  renderCurrency,
  stringSorter,
} from '@utils/tableUtils';
import {DollarOutlined, CreditCardOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {
  UserFinanceIncentiveTransactionsVM,
  PrepaymentReportVM,
} from '@models/serverModels';
import {simpleInvalidateExactQueryFn, simpleQueryFn} from '@store/queryClient';
import {
  getPrepaymentReportRq,
  getUserTransactionsReportRq,
} from '@store/apiEndpoints';
import {
  adjustAmountByStatus,
  formatCurrency,
  getColorByAmountAndStatus,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import EmptyDataText from '@blocks/NoDataText';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import LearnInTable, {
  ColumnAlignment,
  dateTimeSorter,
} from '@components/reusable/LearnInTable';
import LoadingSpinner from '@blocks/LoadingSpinner';
import AddFundsDrawerContainer from '@blocks/addFundsDrawer/AddFundsDrawer.container';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {Tabs} from 'antd';
import {TransactionStatuses, TransactionsTabName} from '@models/clientEnums';
import AdminActivityContainer from '@components/reusable/AdminActivity/AdminActivity.container';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {CreditCardTransactionVM} from '@generated/interfaces';
const {TabPane} = Tabs;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const ContentContainer = styled.div`
  padding: 0px 16px 16px;
`;

const Balances = styled.div`
  border-radius: 4px;
  background: white;
  height: 68px;
  align-items: center;
  display: flex;
  width: 50%;
  padding: 0px 16px;
  box-sizing: border-box;
  &:first-child {
    border-right: 1px solid ${COLORS.Neutral200};
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: #1b1c23;
  margin: 16px 0;
`;

const ClickableText = styled.button`
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  color: ${COLORS.Blue800};
  cursor: pointer;
`;

const ShadowContainer = styled.div`
  border-radius: 4px;
  box-shadow: ${COLORS.BoxShadowStandard};
`;

const CustomEmptyDataTextContainer = styled.div`
  & div {
    height: fit-content !important;
  }
  & div h1 {
    font-size: 1rem !important;
    color: ${COLORS.Neutral600} !important;
  }
`;

const TabPaneContent = styled.div`
  background: ${COLORS.White};
`;

/*
|--------------------------------------------------------------------------
| Drawer & Table Component
|--------------------------------------------------------------------------
*/

interface ITransactionsTableDrawerProps {
  userFinanceIncentive: PrepaymentReportVM;
  onClose: () => void;
  visible: boolean;
}

const TransactionsTableDrawer = ({
  userFinanceIncentive,
  onClose,
  visible,
}: ITransactionsTableDrawerProps) => {
  const [showFundsModal, setShowFundsModal] = useState(false);
  const [activeTabKey, setActiveTabKey] = React.useState(
    TransactionsTabName.TRANSACTIONS
  );
  const [transactionsReport, setTransactionsReport] = useState([]);

  const prepaymentReportRq = getPrepaymentReportRq();

  const userTransactionsReportRq = getUserTransactionsReportRq(
    userFinanceIncentive.userFinanceIncentiveId
  );
  const getUserTransactionsReportQuery =
    useQuery<UserFinanceIncentiveTransactionsVM>(
      userTransactionsReportRq.queryKey,
      () => simpleQueryFn(userTransactionsReportRq.path)
    );

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  React.useEffect(() => {
    if (visible) {
      getUserTransactionsReportQuery.refetch();
    }
  }, [visible]);

  React.useEffect(() => {
    setTransactionsReport(getUserTransactionsReportQuery?.data?.transactions);
  }, [getUserTransactionsReportQuery?.data?.transactions]);

  const UserTransactionsIColumn = [
    {
      title: i18n.t(k.DATE),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (timestamp) => {
        return <div>{timestamp}</div>;
      },
      sorter: dateTimeSorter('timestamp'),
    },
    {
      title: i18n.t(k.GENERIC__DESCRIPTION),
      dataIndex: 'description',
      key: 'description',
      render: (description: string) =>
        k[description] ? i18n.t(k[description]) : description,
      sorter: stringSorter<any>('description'),
    },
    {
      title: i18n.t(k.STATUS__STATUS),
      dataIndex: 'status',
      key: 'status',
      sorter: stringSorter<any>('status'),
    },
    {
      title: i18n.t(k.GENERIC__AMOUNT),
      dataIndex: 'amount',
      key: 'amount',
      align: ColumnAlignment.Right,
      render: (amount: number, row: CreditCardTransactionVM) =>
        renderBalanceColumn({
          amount,
          status: row?.status?.toLowerCase(),
          usersCurrency: getUserQuery.data?.currency,
          exchangeRate,
        }),
      sorter: basicSorter<any>('amount'),
    },
  ];

  React.useEffect(() => {
    //If drawer comes from transactions table add the following data
    if (getUserTransactionsReportQuery.data) {
      userFinanceIncentive.balance =
        getUserTransactionsReportQuery.data.balance ??
        userFinanceIncentive.balance;
      userFinanceIncentive.recurrenceType =
        getUserTransactionsReportQuery.data.recurrenceType ??
        userFinanceIncentive.recurrenceType;
      userFinanceIncentive.spendingLimit =
        getUserTransactionsReportQuery.data.spendingLimit ??
        userFinanceIncentive.spendingLimit;
      userFinanceIncentive.totalApproved =
        getUserTransactionsReportQuery.data.totalApproved ??
        userFinanceIncentive.totalApproved;
    }
  }, [getUserTransactionsReportQuery]);

  const handleAddFundsOk = () => {
    simpleInvalidateExactQueryFn(prepaymentReportRq.queryKey);
    simpleInvalidateExactQueryFn(userTransactionsReportRq.queryKey);
    getUserTransactionsReportQuery.refetch();
  };

  // Learning Budget titles are user-input. However, we can translate the default "Personal Learning Budget"
  const commonPLBTitle = 'Personal Learning Budget';

  return (
    <>
      {showFundsModal && (
        <AddFundsDrawerContainer
          amountType={getUserTransactionsReportQuery.data.amountType}
          userFinanceIncentive={userFinanceIncentive}
          isVisible={showFundsModal}
          setIsVisible={setShowFundsModal}
          onOk={handleAddFundsOk}
        />
      )}
      {visible && (
        <LearnInDrawer
          closeButtonPlacement={'right'}
          title={userFinanceIncentive.employeeName}
          bodyStyle={{
            background: COLORS.Neutral50,
            padding: 0,
            borderRadius: 0,
          }}
          onClose={onClose}
          visible={visible}>
          {!getUserTransactionsReportQuery.isSuccess &&
            getUserTransactionsReportQuery.isLoading && (
              <EmptyDataText header={<LoadingSpinner />} subHeader="" />
            )}
          <ContentContainer>
            {getUserTransactionsReportQuery.isSuccess &&
              !!getUserTransactionsReportQuery.data && (
                <div>
                  <Header>
                    {getUserTransactionsReportQuery.data.incentiveTitle?.toLowerCase() ===
                    commonPLBTitle.toLowerCase()
                      ? i18n.t(k.PLB)
                      : getUserTransactionsReportQuery.data.incentiveTitle}
                    <ClickableText
                      onClick={() => {
                        setShowFundsModal(true);
                      }}>
                      {i18n.t(k.FUND__MANAGE)}
                    </ClickableText>{' '}
                  </Header>
                  <ShadowContainer
                    style={{display: 'flex', marginBottom: '20px'}}>
                    <Balances>
                      <DollarOutlined
                        aria-hidden="true"
                        className="balance-icon"
                      />
                      <div>
                        <div className="prepayment-balance-label">
                          {i18n.t(k.GENERIC__TOTAL_BUDGET)}
                        </div>
                        {formatCurrency(
                          getUserTransactionsReportQuery.data.spendingLimit,
                          getUserQuery.data?.currency,
                          exchangeRate,
                          {decimal: true}
                        )}
                      </div>
                    </Balances>
                    <Balances>
                      <CreditCardOutlined
                        aria-hidden="true"
                        className="balance-icon"
                      />
                      <div>
                        <div className="prepayment-balance-label">
                          {i18n.t(k.GENERIC__READY_TO_SPEND)}
                        </div>
                        <div>
                          {formatCurrency(
                            getUserTransactionsReportQuery.data.balance,
                            getUserQuery.data?.currency,
                            exchangeRate,
                            {decimal: true}
                          )}
                        </div>
                      </div>
                    </Balances>
                  </ShadowContainer>
                </div>
              )}
          </ContentContainer>
          <LearnInTabs
            defaultActiveKey={TransactionsTabName.TRANSACTIONS}
            activeKey={activeTabKey}
            onTabClick={(key) => {
              switch (key) {
                case TransactionsTabName.TRANSACTIONS:
                  return setActiveTabKey(TransactionsTabName.TRANSACTIONS);
                case TransactionsTabName.ACTIVITY:
                  return setActiveTabKey(TransactionsTabName.ACTIVITY);
                default:
                  return false;
              }
            }}>
            <TabPane
              tab={i18n.t(k.TRANSACTION__PLURAL)}
              key={TransactionsTabName.TRANSACTIONS}>
              <TabPaneContent>
                {!!getUserTransactionsReportQuery.data?.transactions?.length ? (
                  <LearnInTable
                    columns={UserTransactionsIColumn}
                    dataSource={transactionsReport}
                  />
                ) : (
                  <CustomEmptyDataTextContainer>
                    <EmptyDataText
                      header={i18n.t(k.TRANSACTION__NO_BY_USER__FORMAT, {
                        user: userFinanceIncentive?.employeeName.split(' ')[0],
                      })}
                      subHeader=""
                    />
                  </CustomEmptyDataTextContainer>
                )}
              </TabPaneContent>
            </TabPane>
            <TabPane
              tab={i18n.t(k.ADMIN_ACTIVITY)}
              key={TransactionsTabName.ACTIVITY}>
              <TabPaneContent>
                {!!getUserTransactionsReportQuery?.data?.adminActions
                  ?.length ? (
                  <AdminActivityContainer
                    adminActions={
                      getUserTransactionsReportQuery?.data?.adminActions
                    }
                    usersCurrency={getUserQuery.data?.currency}
                    exchangeRate={exchangeRate}
                  />
                ) : (
                  <CustomEmptyDataTextContainer>
                    <EmptyDataText
                      header={i18n.t(k.ADMIN_ACTIVITY__EMPTY__TITLE)}
                      subHeader={i18n.t(k.ADMIN_ACTIVITY__EMPTY__DESCRIPTION)}
                    />
                  </CustomEmptyDataTextContainer>
                )}
              </TabPaneContent>
            </TabPane>
          </LearnInTabs>
        </LearnInDrawer>
      )}
    </>
  );
};

export default TransactionsTableDrawer;
