import {useState} from 'react';
import {AddedEmployees} from './AddedEmployees';
import {
  IGiveRestrictedCustomProgramAccessPayload,
  IRestrictedProgramRecommendedUserVM,
  RemoveRestrictedCustomProgramAccessPayload,
} from '@models/serverModels';
import {useQuery, useMutation} from 'react-query';
import {
  simpleQueryFn,
  simpleMutationFn,
  simpleDeleteFn,
} from '@store/queryClient';
import {getCompanyUsers} from '@utils/ClientPaths';
import {getRestrictedCustomProgramAccessUsersRq} from '@store/apiEndpoints/customProgram/queries';
import {
  getGiveRestrictedCustomProgramAccessRm,
  getRemoveRestrictedProgramAccessRm,
} from '@store/apiEndpoints/customProgram/mutations';
import {api} from '@store/api';
import {notify} from '@components/user/notifications';
import {useUserQuery, useCustomProgramsUsersQuery} from '@generated/hooks';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface IAddedEmployeesContainerProps {
  customProgramId: number;
}

function AddedEmployeesContainer({
  customProgramId,
}: IAddedEmployeesContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {data: user} = useUserQuery(null, {enabled: false});
  const [selectedUsersForAdding, setCompanyUsersForAdding] = useState([]);

  const {
    data: customProgramsUsers,
    invalidateExact: invalidateCustomProgramsUsers,
  } = useCustomProgramsUsersQuery(
    {
      customProgramId,
      //   queryParams: {
      //     incompleteContentId,
      //     limit,
      //     page,
      //     searchText
      // }
    },
    {
      enabled: !!customProgramId,
    }
  );

  const removeRestrictedProgramAccessMutation = useMutation(
    (payload: RemoveRestrictedCustomProgramAccessPayload) => {
      const removeRestrictedProgramAccess =
        getRemoveRestrictedProgramAccessRm(payload);
      return simpleDeleteFn<null>(
        removeRestrictedProgramAccess.path,
        removeRestrictedProgramAccess.payload
      );
    },
    {
      onSuccess: async () => {
        notify.removeUserProgramAccessSuccess();
        await invalidateCustomProgramsUsers();
      },
    }
  );

  const giveRestrictedAccessMutation = useMutation(
    (args: IGiveRestrictedCustomProgramAccessPayload) => {
      const giveRestrictedCustomProgramAccess =
        getGiveRestrictedCustomProgramAccessRm(args);
      return simpleMutationFn<null>(
        giveRestrictedCustomProgramAccess.path,
        giveRestrictedCustomProgramAccess.payload
      );
    },
    {
      onSuccess: async () => {
        notify.recommendUsersSuccess();
        setCompanyUsersForAdding([]);
        await invalidateCustomProgramsUsers();
      },
    }
  );

  const handleClickAddRecommendedUsers = async () => {
    // Program must be saved to add user access
    if (!customProgramId) {
      return notify.saveProgramRequired();
    }
    // Send api call to give users access
    await giveRestrictedAccessMutation.mutateAsync({
      id: customProgramId,
      users: selectedUsersForAdding.map(({value}) => value),
    });
  };

  const loadOptions = async (name: string, done) => {
    try {
      const response = await api.get(getCompanyUsers(user?.companyId, {name}));
      const options = response.data
        .filter((item) => {
          return customProgramsUsers?.every(
            (recommendedUser) => recommendedUser.email !== item.email
          );
        })
        .map((item) => ({
          label: item.name,
          value: item.id,
          email: item.email,
          canShowEmployeeEmail: isFeatureFlagOn.ShowEmployeeEmail,
        }));

      done(options);
    } catch (error) {
      console.error('Error fetching data:', error);
      done([]);
    }
  };

  return (
    <AddedEmployees
      loadOptions={loadOptions}
      onClickAddRecommendedUsers={handleClickAddRecommendedUsers}
      onCompanyUserSearchChange={setCompanyUsersForAdding}
      recommendedUsers={customProgramsUsers}
      removeUser={async (
        userRecommendationId: number,
        customProgramId: number
      ) => {
        await removeRestrictedProgramAccessMutation.mutateAsync({
          userCompanyId: userRecommendationId,
          customProgramId,
        });
      }}
      selectedUsersForAdding={selectedUsersForAdding}
    />
  );
}

export default AddedEmployeesContainer;
