import {CreateAcademyIntegrationChatChannelRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {CreateAcademyIntegrationChatChannelRequestSchema} from '../zodSchemas/CreateAcademyIntegrationChatChannelRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyIntegrationController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/integrations/chat/channels 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesIntegrationsChatChannelsPostMutation} from '@generated/hooks';
 *
 *  const academiesIntegrationsChatChannelsPost = useAcademiesIntegrationsChatChannelsPostMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesIntegrationsChatChannelsPost.mutate({
 *    payload: {
 *      ...CreateAcademyIntegrationChatChannelRequest
 *    },
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAcademiesIntegrationsChatChannelsPostMutation =
  mutationHookFactory<
    CreateAcademyIntegrationChatChannelRequest, // payload
    {academyId: number}, // path variables
    null
  >(
    'useAcademiesIntegrationsChatChannelsPostMutation',
    'post',
    ({academyId}) => `academies/${academyId}/integrations/chat/channels`,
    {payloadSchema: CreateAcademyIntegrationChatChannelRequestSchema}
  );
