import {ApprovalRankType, ApprovalStatus, ApprovalType} from './../enums';
import {ApprovalRequestVM} from './../interfaces';
import {
  PreApprovalApplicationVMMockData,
  getPreApprovalApplicationVMMockData,
} from './PreApprovalApplicationVM.mock';
import {faker} from '@faker-js/faker';

// Interface: ApprovalRequestVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getApprovalRequestVMMockData(
  args?: Partial<ApprovalRequestVM>
): ApprovalRequestVM {
  return {
    amount: faker.datatype.number(),
    amountOverLimit: faker.datatype.number(), // optional
    amountType: String(faker.datatype.number()),
    applicationDetails: getPreApprovalApplicationVMMockData(),
    approvalGuidelines: faker.word.noun(),
    approverNotes: faker.word.noun(),
    costCenter: faker.word.noun(),
    employeeComments: faker.word.noun(),
    employeeReasonForApplication: faker.word.noun(),
    id: faker.datatype.number(),
    itemId: faker.datatype.number(),
    linkedUserRequestApprovalId: faker.datatype.number(), // optional
    manager: faker.word.noun(),
    managerId: faker.datatype.number(), // optional
    previousRejectionNotes: faker.word.noun(), // optional
    provider: faker.word.noun(),
    rank: ApprovalRankType.Initial,
    rejectedById: String(faker.datatype.number()), // optional
    requestedBy: faker.word.noun(),
    requestedDate: String(faker.date.past()),
    status: ApprovalStatus.Pending,
    title: faker.company.catchPhrase(),
    type: ApprovalType.PLAN_ITEM,
    uniqueId: String(faker.datatype.number()),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ApprovalRequestVMMockData: ApprovalRequestVM = {
  amount: 5,
  amountOverLimit: 5, // optional
  amountType: '5',
  applicationDetails: PreApprovalApplicationVMMockData,
  approvalGuidelines: 'string',
  approverNotes: 'string',
  costCenter: 'string',
  employeeComments: 'string',
  employeeReasonForApplication: 'string',
  id: 1,
  itemId: 1,
  linkedUserRequestApprovalId: 1, // optional
  manager: 'string',
  managerId: 1, // optional
  previousRejectionNotes: 'string', // optional
  provider: 'string',
  rank: ApprovalRankType.Initial,
  rejectedById: '1', // optional
  requestedBy: 'string',
  requestedDate: '2023-03-29T22:17:19.738072',
  status: ApprovalStatus.Pending,
  title: 'Advanced Web Design',
  type: ApprovalType.PLAN_ITEM,
  uniqueId: '1',
};
