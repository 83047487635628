import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Form} from 'antd';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {APPROVAL_AUTOPOPULATE_MULTIPLIER, COLORS} from '@utils/constants';
import '@fontsource/roboto/500.css';
import LearnInDrawer, {
  FooterButtonsContainer,
} from '@components/reusable/LearnInDrawer';
import {ApplicationQuestionVM, PreApprovalQuestion} from '@models/serverModels';
import {ResourceOrPlanItemPreApprovalQuestion} from '@models/clientModels';
import PreApprovalFormItem from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem.container';
import {
  CurrencyCode,
  PlanItemType,
  PreApprovalQuestionInputType,
} from '@generated/enums';
import LoadingSpinner from '@blocks/LoadingSpinner';

const ErrorBox = styled.div`
  background: ${COLORS.Red100};
  border-radius: 4px;
  padding: 8px;
  display: flex;
  margin-bottom: 24px;
`;

const ErrorTextContainer = styled.div``;

const ErrorTitle = styled.h1`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.171875rem;
  margin-bottom: 4px;
`;

const ErrorText = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  margin-bottom: 0px;
`;

const StyledWarningIcon = styled(ExclamationCircleOutlined)`
  color: ${COLORS.Red800};
  margin-right: 8px;
  font-size: 1rem;
`;

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: auto;
  width: 100%;
`;

type Props = {
  currency: CurrencyCode;
  formQuestions: PreApprovalQuestion[] | ApplicationQuestionVM[];
  isFormDisabled: boolean;
  isTextAreaDisabled: boolean;
  isVisible: boolean;
  onClose: () => void;
  onSubmit: (values?: {amount: string; reason: string}) => void;
  planItemType?: PlanItemType;
  showError?: boolean;
};

const RequestAdditionalFundsModal = ({
  currency,
  formQuestions,
  isFormDisabled,
  isTextAreaDisabled,
  isVisible,
  onClose,
  onSubmit,
  planItemType,
  showError,
}: Props) => {
  const [form] = Form.useForm();

  function handleSubmit() {
    form
      .validateFields()
      .then((values) => onSubmit(values))
      .catch((errorInfo) => {
        console.log('einfo', errorInfo);
      });
  }

  return (
    <LearnInDrawer
      title={i18n.t(k.FUND__REQUEST__ADDITIONAL)}
      placement="right"
      closeButtonPlacement="right"
      onClose={onClose}
      visible={isVisible}
      footer={[
        <FooterButtonsContainer key="modal-footer">
          <LearnInButton
            tag={ButtonTags.DrawerFooterSecondary}
            onClick={onClose}
            key={'cancel'}>
            {i18n.t(k.CTA__CANCEL)}
          </LearnInButton>
          <LearnInButton
            onClick={handleSubmit}
            tag={ButtonTags.DrawerFooterPrimary}
            htmlType="submit"
            data-testid="requestAdditionalFundsForm"
            key={'requestAdditionalFundsForm'}
            style={{minWidth: '139px'}}>
            {i18n.t(k.CTA__SUBMIT)}
          </LearnInButton>
        </FooterButtonsContainer>,
      ]}>
      {showError && (
        <ErrorBox>
          <StyledWarningIcon aria-hidden="true" />
          <ErrorTextContainer>
            <ErrorTitle>{i18n.t(k.ERROR__FRIENDLY)}</ErrorTitle>
            <ErrorText>{i18n.t(k.ERROR__TRY_AGAIN)}</ErrorText>
          </ErrorTextContainer>
        </ErrorBox>
      )}
      <Form layout="vertical" form={form}>
        {isFormDisabled ? (
          <StyledLoadingSpinner />
        ) : (
          formQuestions?.map((item: ResourceOrPlanItemPreApprovalQuestion) => {
            return (
              <PreApprovalFormItem
                key={item.id}
                form={form}
                formQuestions={formQuestions}
                item={item}
                isFormDisabled={isFormDisabled}
                isTextAreaDisabled={isTextAreaDisabled}
                programCostFeesMultiplier={APPROVAL_AUTOPOPULATE_MULTIPLIER}
                programCurrency={currency}
                isProgram={planItemType === PlanItemType.Program}
                isUserRequest={false}
                isApplicationRejected={false}
                isForPlanItemOrResourcePreapproval
                isCostField={
                  item.inputType === PreApprovalQuestionInputType.Money
                }
              />
            );
          })
        )}
      </Form>
    </LearnInDrawer>
  );
};

export default RequestAdditionalFundsModal;
