import {Fragment} from 'react';
import {Col, Row} from 'antd';
import EmployeeEngagementChart from './components/EmployeeEngagementChart/EmployeeEngagementChart';
import FinanceChart from './components/FinanceChart/FinanceChart';
import ProgressChart from './components/ProgressChart/ProgressChart';
import PopularProgramList from './components/PopularProgramlList/PopularProgramList';
import {
  EmployeeEngagementVM,
  FinanceApprovedVsSpentVM,
  PopularProgramVM,
  ProgressUpdatesBySummaryVM,
} from '@models/serverModels';
import {i18n, k} from '@i18n/translate';

/*
|--------------------------------------------------------------------------
| Functions
|--------------------------------------------------------------------------
*/

const localizeEngagement = (datum: EmployeeEngagementVM) => {
  const engagement = {
    activatedAccounts: {
      name: 'ACTIVATED_ACCOUNTS',
      label: i18n.t(k.REPORT__ACTIVATED_ACCOUNT__PLURAL),
    },
    loggedIn: {
      name: 'LOGGED_IN',
      label: i18n.t(k.REPORT__LOGGED_IN),
    },
    usedCard: {
      name: 'USED_CARD',
      label: i18n.t(k.REPORT__USED_VC),
    },
  };

  switch (datum.name) {
    case engagement.activatedAccounts.name:
      datum.category = engagement.activatedAccounts.label;
      break;
    case engagement.loggedIn.name:
      datum.category = engagement.loggedIn.label;
      break;
    case engagement.usedCard.name:
      datum.category = engagement.usedCard.label;
      break;
    default:
      datum.category = datum.category;
      break;
  }

  datum.month = i18n.t(k[`DATE__MONTH__${datum.monthNumber}__SHORT`]);
};

const localizeFinance = (datum: FinanceApprovedVsSpentVM) => {
  const finance = {
    totalRequested: {
      type: 'Total Requested',
      label: i18n.t(k.APPROVAL__TOTAL_REQUESTED),
    },
    totalSpent: {
      type: 'Total Spent',
      label: i18n.t(k.MONEY__TOTAL_SPENT),
    },
    totalApproved: {
      type: 'Total Approved',
      label: i18n.t(k.APPROVAL__TOTAL_APPROVED),
    },
  };

  switch (datum.type) {
    case finance.totalRequested.type:
      datum.type = finance.totalRequested.label;
      break;
    case finance.totalSpent.type:
      datum.type = finance.totalSpent.label;
      break;
    case finance.totalApproved.type:
      datum.type = finance.totalApproved.label;
      break;
    default:
      break;
  }

  datum.name = i18n.t(k[`DATE__MONTH__${datum.month}__SHORT`]);
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface Props {
  onTabChange: (tabName: any) => void;
  employeeEngagementData: EmployeeEngagementVM[];
  employeeEngagementLoading: boolean;
  financeChartData: FinanceApprovedVsSpentVM[];
  financeChartLoading: boolean;
  progressChartData: ProgressUpdatesBySummaryVM[];
  progressChartLoading: boolean;
  popularProgramData: PopularProgramVM[];
  popularProgramLoading: boolean;
  areProgressUpdatesEnabled: boolean;
  isDirectBillingPlan?: boolean;
}

const InsightsReport = ({
  areProgressUpdatesEnabled,
  onTabChange,
  employeeEngagementData,
  employeeEngagementLoading,
  financeChartData,
  financeChartLoading,
  progressChartData,
  progressChartLoading,
  popularProgramData,
  popularProgramLoading,
  isDirectBillingPlan,
}: Props) => {
  employeeEngagementData?.forEach(localizeEngagement);
  financeChartData?.forEach(localizeFinance);

  return (
    <Fragment>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={24}>
          <EmployeeEngagementChart
            isDirectBillingPlan={isDirectBillingPlan}
            employeeEngagementData={employeeEngagementData}
            employeeEngagementLoading={employeeEngagementLoading}
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={areProgressUpdatesEnabled ? 12 : 24}>
          <FinanceChart
            onTabChange={(activeKey) => onTabChange(activeKey)}
            financeChartData={financeChartData}
            financeChartLoading={financeChartLoading}
            isDirectBillingPlan={isDirectBillingPlan}
          />
        </Col>
        <Col xs={24} md={12}>
          {areProgressUpdatesEnabled && (
            <ProgressChart
              onTabChange={(activeKey) => onTabChange(activeKey)}
              progressChartData={progressChartData}
              progressChartLoading={progressChartLoading}
            />
          )}
        </Col>
      </Row>
      <Row gutter={[8, 8]}>
        <Col xs={24} md={24}>
          <PopularProgramList
            onTabChange={(activeKey) => onTabChange(activeKey)}
            popularProgramData={popularProgramData}
            popularProgramLoading={popularProgramLoading}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default InsightsReport;
