import {UserProgressStatus} from './../enums';
import {ProgramProgressVM} from './../interfaces';
import {
  ProgramProgressUpdateMockData,
  getProgramProgressUpdateMockData,
} from './ProgramProgressUpdate.mock';
import {faker} from '@faker-js/faker';

// Interface: ProgramProgressVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramProgressVMMockData(
  args?: Partial<ProgramProgressVM>
): ProgramProgressVM {
  return {
    ...getProgramProgressUpdateMockData(),
    date: String(faker.date.past()),
    status: UserProgressStatus.Upcoming,
    week: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramProgressVMMockData: ProgramProgressVM = {
  ...ProgramProgressUpdateMockData,
  date: '2023-03-29T22:17:19.738072',
  status: UserProgressStatus.Upcoming,
  week: 123,
};
