import {mutationHookFactory, MutationMethods} from './../utils';

const BASE_PATH = 'permission';

export const useResetAcademyProjectCount = mutationHookFactory<{
  academyId: number;
}>(
  MutationMethods.Post,
  `${BASE_PATH}/reset-user-academy-permission-project-submission-count`
);

export const useResetCustomProgramProjectCount = mutationHookFactory<{
  customProgramId: number;
}>(
  MutationMethods.Post,
  `${BASE_PATH}/reset-user-custom-program-permission-project-submission-count`
);
