import {AcademyContentStatus} from './../enums';
import {UserPlanAcademyStepContentVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserPlanAcademyStepContentVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanAcademyStepContentVMMockData(
  args?: Partial<UserPlanAcademyStepContentVM>
): UserPlanAcademyStepContentVM {
  return {
    contentId: faker.datatype.number(),
    status: AcademyContentStatus.Planned,
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanAcademyStepContentVMMockData: UserPlanAcademyStepContentVM =
  {
    contentId: 1,
    status: AcademyContentStatus.Planned,
    userCompanyId: 1,
  };
