import {useState} from 'react';
import CustomProgramProjectsTable from './CustomProgramProjectsTable';
import CustomProgramProjectParticipantsDrawer from './CustomProgramProjectParticipantsDrawer.container';
import {ProjectAdminVM} from '@models/serverModels';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramProjectsTableContainerProps {
  dataSource: ProjectAdminVM[];
}

function CustomProgramProjectsTableContainer({
  dataSource,
}: CustomProgramProjectsTableContainerProps) {
  const [projectForDetailsDrawer, setProjectForDetailsDrawer] = useState<
    undefined | ProjectAdminVM
  >();

  return (
    <>
      <CustomProgramProjectsTable
        dataSource={dataSource}
        onClickRow={setProjectForDetailsDrawer}
      />
      <CustomProgramProjectParticipantsDrawer
        project={projectForDetailsDrawer}
        onClose={() => setProjectForDetailsDrawer(undefined)}
      />
    </>
  );
}

export default CustomProgramProjectsTableContainer;
