import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import learnInConfirm from '@components/reusable/learnInConfirm';
import {notify} from '@components/user/notifications';
import {getDeleteLicenseRm, getLicensesRq} from '@store/apiEndpoints';
import {simpleDeleteFn, simpleQueryFn} from '@store/queryClient';
import {useMutation, useQuery} from 'react-query';
import {Outlet} from 'react-router-dom';
import {LicensesAdminPage} from './LicensesAdminPage';
import {LicenseCardVM} from '@models/serverModels';
import {Helmet} from 'react-helmet-async';

const LicensesAdminPageContainer = ({title}: {title?: string}) => {
  const [providersInUse, setProvidersInUse] = useState([]);
  const licensesRq = getLicensesRq();
  const licensesQuery = useQuery<LicenseCardVM[]>(licensesRq.queryKey, () =>
    simpleQueryFn(licensesRq.path)
  );

  const refetchData = () => {
    licensesQuery.refetch();
  };

  useEffect(() => {
    setProvidersInUse(licensesQuery?.data?.map((p) => p.providerId));
  }, [licensesQuery.data]);

  const deleteLicenseMutation = useMutation(
    (id: number) => {
      const deleteLicenseRm = getDeleteLicenseRm(id);
      return simpleDeleteFn<null>(
        deleteLicenseRm.path,
        deleteLicenseRm.payload
      );
    },
    {
      onSuccess: async () => {
        refetchData();
        notify.removeLicenseSuccess();
      },
      onError: () => {
        notify.removeLicenseError();
      },
    }
  );

  const confirmDeleteLicense = (id: number) => {
    learnInConfirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__DELETE_ITEM_YES__FORMAT, {
        item: i18n.t(k.LICENSE),
      }),
      content: i18n.t(k.LICENSE__DELETE__PROMPT),
      title: i18n.t(k.LICENSE__DELETE),
      async onOk() {
        await deleteLicenseMutation.mutateAsync(id);
      },
      onCancel() {
        // intentional pass
      },
    });
  };

  return (
    <section>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LicensesAdminPage
        handleDeleteLicenseClick={confirmDeleteLicense}
        licensesQuery={licensesQuery}
        refreshList={refetchData}
        providersInUse={providersInUse}
      />
      <Outlet />
    </section>
  );
};

export default LicensesAdminPageContainer;
