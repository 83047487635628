import React, {memo} from 'react';
import {BudgetUptoDisplayContainerProps} from './BudgetUptoDisplay.interfaces';
import BudgetUptoDisplayView from './BudgetUptoDisplay.view';
import NoDataImage from '@blocks/NoDataImage';
import {FinanceAmountType, FinanceType} from '@generated/enums';
import BudgetDonut from '../BudgetDonut';
import StartLearningCard from '@blocks/StartLearningCard';

function BudgetUptoDisplayContainer({
  financeLearningBuds,
  isFinanceLearningBudsSuccess,
  personalization,
  user,
  exchangeRate,
  userProgramPlan,
  hasProgramsOrResources,
  drillDownData,
}: BudgetUptoDisplayContainerProps) {
  const hasNoBudget =
    financeLearningBuds?.financeAmountType === FinanceAmountType.Any;

  if (!isFinanceLearningBudsSuccess) return <NoDataImage />;
  if (
    financeLearningBuds?.financeAmountType === FinanceAmountType.Any &&
    financeLearningBuds?.financeType !== FinanceType.Prepayment &&
    financeLearningBuds?.financeType !== FinanceType.TuitionReimbursement
  ) {
    return (
      <BudgetUptoDisplayView
        exchangeRate={exchangeRate}
        financeLearningBuds={financeLearningBuds}
        drillDownData={drillDownData}
        isFinanceLearningBudsSuccess={isFinanceLearningBudsSuccess}
        personalization={personalization}
        user={user}
      />
    );
  }

  if (hasNoBudget && !hasProgramsOrResources)
    return (
      <StartLearningCard
        data-testid="start-learning-card"
        type={financeLearningBuds?.financeType}
      />
    );
  else
    return (
      <BudgetDonut
        exchangeRate={exchangeRate}
        financeLearningBuds={financeLearningBuds}
        isFinanceLearningBudsSuccess={isFinanceLearningBudsSuccess}
        personalization={personalization}
        user={user}
        userProgramPlan={userProgramPlan}
        drillDownData={drillDownData}
      />
    );
}

export default memo(BudgetUptoDisplayContainer);
