import {Breakpoints} from '@utils/responsiveUtils';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  display: block;

  @media (max-width: ${Breakpoints.Desktop}px) {
    display: ${({isVisible}) => (isVisible ? 'block' : 'none')};
  }
`;

export const ToggleButton = styled.button`
  width: 100%;
  padding: 10px 16px;
  text-align: center;

  :hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition: background-color 0.3s ease-in-out;
  }

  @media (min-width: ${Breakpoints.Desktop}px) {
    display: none;
  }
`;
