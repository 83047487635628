import {
  ButtonGroup,
  Container,
  ContentWrapper,
  ImageBanner,
  LearnInDrawerContainer,
  MainContent,
  StyledButton,
  StyledSteps,
} from './ProgramFinder.styled';
import {ProgramFinderViewProps} from './ProgramFinder.interfaces';
import {i18n, k} from '@i18n/translate';
import {ArrowLeftOutlined, ArrowRightOutlined} from '@ant-design/icons';
import {Space, Steps} from 'antd';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {COLORS, KEY_CODES} from '@utils/constants';
import Welcome from '@components/Welcome';
import program_finder_side_banner from '@assets/svg/program_finder_side_banner.svg';
import {Wand} from '@assets/svg/Wand';
import {H2, H3} from '@components/reusable/Typography';
import {ProgramFinderChatCard} from '@pages/ProgramFinder/ProgramFinderChatCard';

const {Step} = Steps;

function ProgramFinderView({
  current,
  currentPath,
  form,
  handleFinish,
  isButtonVisible,
  next,
  prev,
  setIsButtonVisible,
  setSelectedOption,
  onClose,
  totalCount,
}: ProgramFinderViewProps) {
  return (
    <LearnInDrawerContainer
      bodyStyle={{padding: 0}}
      closeButtonPlacement={'right'}
      onClose={onClose}
      responsiveSize={'100%'}
      title={<H2 fontWeight={500}>{i18n.t(k.PROGRAM_FINDER__WELCOME)}</H2>}
      width={'100%'}
      visible={true}>
      <ContentWrapper>
        <ImageBanner src={program_finder_side_banner} alt="" />
        <Container
          form={form}
          onKeyDown={(e) => e.key === KEY_CODES.ENTER && e.preventDefault()}>
          {current === -1 && (
            <Welcome
              setIsButtonVisible={setIsButtonVisible}
              isButtonVisible={isButtonVisible}
              setSelectedOption={setSelectedOption}
              next={next}
              form={form}
            />
          )}
          {current >= 0 && (
            <>
              <StyledSteps current={current}>
                {currentPath?.map((step) => (
                  <Step key={step?.title} title={step?.title} />
                ))}
              </StyledSteps>
              {currentPath[current]?.content && (
                <MainContent>
                  <LearnInButton
                    style={{width: '2rem', height: '2rem', padding: '0 8px'}}
                    tag={ButtonTags.SecondaryBlack}
                    icon={<ArrowLeftOutlined />}
                    onClick={prev}
                  />
                  <Space
                    style={{width: '100%'}}
                    direction="vertical"
                    size="large">
                    <div>
                      {currentPath[current].content}
                      {totalCount === 0 && (
                        <H3 color={COLORS.Neutral600}>
                          {i18n.t(k.PROGRAM_FINDER__NO_RESULTS)}
                        </H3>
                      )}
                    </div>
                    <ButtonGroup>
                      {current < currentPath.length - 1 ? (
                        <>
                          <LearnInButton
                            color={COLORS.Neutral600}
                            noPadding
                            tag={ButtonTags.SecondaryBlackRemovedBorder}
                            onClick={() => next({skip: true})}>
                            {i18n.t(k.CTA__SKIP)}
                          </LearnInButton>
                          {totalCount !== 0 && (
                            <div className="keep-right">
                              {totalCount > 0 && totalCount <= 5 && (
                                <StyledButton
                                  visible={isButtonVisible}
                                  tag={ButtonTags.Primary}
                                  onClick={() => handleFinish({skip: false})}>
                                  <Wand color={COLORS.White} />
                                  {totalCount === 1
                                    ? i18n.t(
                                        k.PROGRAM_FINDER_VIEW_PROGRAM__FORMAT,
                                        {count: totalCount}
                                      )
                                    : i18n.t(
                                        k.PROGRAM_FINDER_VIEW_PROGRAMS__FORMAT,
                                        {
                                          amount: totalCount,
                                        }
                                      )}
                                </StyledButton>
                              )}
                              <StyledButton
                                visible={isButtonVisible}
                                tag={ButtonTags.SecondaryRemovedBorder}
                                onClick={next}>
                                {i18n.t(k.CTA__NEXT_STEP)}
                              </StyledButton>
                            </div>
                          )}
                        </>
                      ) : (
                        <>
                          <LearnInButton
                            color={COLORS.Neutral600}
                            noPadding
                            tag={ButtonTags.SecondaryBlackRemovedBorder}
                            onClick={() => handleFinish({skip: true})}>
                            {i18n.t(k.CTA__SKIP)}
                          </LearnInButton>
                          {totalCount !== 0 && (
                            <LearnInButton
                              tag={ButtonTags.Primary}
                              onClick={() => handleFinish({skip: false})}>
                              {i18n.t(k.CTA__VIEW_RESULTS)}
                              <ArrowRightOutlined />
                            </LearnInButton>
                          )}
                        </>
                      )}
                    </ButtonGroup>
                    {totalCount === 0 && <ProgramFinderChatCard />}
                  </Space>
                </MainContent>
              )}
            </>
          )}
        </Container>
      </ContentWrapper>
    </LearnInDrawerContainer>
  );
}

export default ProgramFinderView;
