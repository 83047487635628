import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import ReportsBySectionDetailsTableContainer from './ReportsBySectionDetailsTable.container';
import {
  useAcademyOverviewLevelUserReportQuery,
  useAcademyOverviewStepUserReportQuery,
} from '@generated/hooks';
import {useParams} from 'react-router-dom';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {DrawerContentContainer} from './ReportsBySectionDetailsTable.styled';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface ReportsBySectionDetailsTableDrawerProps {
  onClose: () => void;
  showBulkNudgeButton?: boolean;
}

function ReportsBySectionDetailsTableDrawer({
  onClose,
  showBulkNudgeButton,
}: ReportsBySectionDetailsTableDrawerProps) {
  // Get path variables
  const {academyLevelId, academyStepId} = useParams();

  // Fetch data
  const {
    data: academyOverviewLevelUserReport,
    isLoading: isLevelReportLoading,
  } = useAcademyOverviewLevelUserReportQuery(
    {
      academyLevelId: parseInt(academyLevelId),
    },
    {
      enabled: !!academyLevelId,
    }
  );

  const {data: academyOverviewStepUserReport, isLoading: isStepReportLoading} =
    useAcademyOverviewStepUserReportQuery(
      {
        academyStepId: parseInt(academyStepId),
      },
      {
        enabled: !!academyStepId,
      }
    );

  // Get Drawer title from data
  const title = (() => {
    if (isLevelReportLoading || isStepReportLoading) {
      return <LoadingSpinner size="large" />;
    }
    if (!!academyLevelId) {
      return academyOverviewLevelUserReport?.academyLevelName;
    }

    if (!!academyStepId) {
      return academyOverviewStepUserReport?.academyStepName;
    }
    return '';
  })();

  return (
    <LearnInPageDrawer
      onClose={onClose}
      title={title}
      visible
      zIndex={1005}
      contentWrapperStyle={{transform: 'none'}}>
      <DrawerContentContainer>
        <ReportsBySectionDetailsTableContainer
          showBulkNudgeButton={showBulkNudgeButton}
        />
      </DrawerContentContainer>
    </LearnInPageDrawer>
  );
}

export default ReportsBySectionDetailsTableDrawer;
