import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {LearnInWrapper} from '@components/reusable/Wrapper/Wrapper.style';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {WrapperTags} from '@components/reusable/Wrapper/WrapperEnums';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import {UserPlanProgramItemVM} from '@models/serverModels';
import {openInNewTab} from '@components/providers/utils';
import {QuillHtmlViewer} from '@components/admin/pages/approvals/components/QuillHtmlViewer';
import {formatEmailAndUrlToLink} from '@utils/stringUtils';
import {CopyToClipboard} from '@blocks/CopyToClipboard';
import {Typography} from 'antd';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {useState} from 'react';
import {ViewLicenseResponse} from '@generated/interfaces';
import {AddUpdateLicenseVM} from '@components/admin/pages/licenses/components/AddUpdateLicenseDrawer/AddUpdateLicenseDrawer.types';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const RedemptionNotesWrapperDeprecated = styled.div`
  margin-top: 13px;
`;

const RedemptionNotesWrapper = styled.div`
  margin-top: 10px;
`;

const RedemptionNoteTitleDeprecated = styled.div`
  font-weight: 500;
  margin-bottom: 6px;
`;

const RedemptionNoteTitle = styled.div`
  font-weight: 500;
  font-size: 0.75rem;
  margin-bottom: 6px;
`;

const RedemptionNotes = styled.p`
  background: ${COLORS.Neutral100};
  border-radius: 8px;
  font-size: 0.75rem;
  padding: 16px;
  width: 100%;
`;

const RedemptionNotesDeprecated = styled.p`
  background: ${COLORS.Neutral50};
  display: flex;
  padding: 12px;
  width: 100%;
`;

const RedemptionVoucher = styled.button`
  background: ${COLORS.Neutral100};
  border: solid 1px ${COLORS.Neutral300};
  border-radius: 4px;
  color: ${COLORS.Neutral800};
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 800;
  padding: 4px 8px;
`;

const RedemptionLabel = styled(Typography.Text)`
  color: ${COLORS.Neutral950};
  display: block;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 0.25rem;
`;

const RedemptionLabelBadge = styled.div`
  margin: 1.5rem 0;
`;

const RedemptionApprovalNotes = styled.div`
  // Adjusts for default modal bottom padding being too much for this design
  margin-bottom: -1rem;
`;

const RedemptionLoadingWrapper = styled.div`
  padding: 1.5rem;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface Props {
  onClose: () => void;
  provider: string;
  program: UserPlanProgramItemVM;
  isLoading?: boolean;
  license: ViewLicenseResponse;
  deprecatedLicenseVM: AddUpdateLicenseVM;
  isLicenseModalUpdateOn: boolean;
}

export default function LicenseViewerModal({
  onClose,
  provider,
  program,
  license,
  isLoading,
  deprecatedLicenseVM,
  isLicenseModalUpdateOn,
}: Props) {
  const programUrl = program?.applicationUrl || program?.externalUrl;
  const [tooltipText, setTooltipText] = useState(
    i18n.t(k.CTA__COPY_TO_CLIPBOARD)
  );

  return isLicenseModalUpdateOn ? (
    <LearnInModal
      tag={ModalTags.LicenseAuto}
      visible={true}
      width={567}
      hideLeftButton={isLoading || !license}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      onCancel={() => onClose()}
      onOk={() =>
        license && programUrl?.length ? openInNewTab(programUrl) : onClose()
      }
      okButtonChild={
        isLoading || license ? i18n.t(k.PROGRAM__ACCESS) : i18n.t(k.CTA__CLOSE)
      }
      ariaLabel={`${provider} ${i18n.t(k.LICENSE)}`}>
      <LearnInWrapper tag={WrapperTags.ModalProgramHeader}>
        <LearnInText tag={TextTags.ModalHeader}>
          {provider} {i18n.t(k.LICENSE)}
        </LearnInText>
        {!license ? (
          isLoading ? (
            <RedemptionLoadingWrapper>
              <LoadingSpinner />
            </RedemptionLoadingWrapper>
          ) : (
            i18n.t(k.ERROR__TRY_AGAIN)
          )
        ) : (
          <LearnInText tag={TextTags.LicenseViewerModalBody}>
            <QuillHtmlViewer html={license.redemptionGuideline} />
            <div>
              {license.voucherCode && (
                <RedemptionLabelBadge>
                  <RedemptionLabel>
                    {i18n.t(k.LICENSE__VOUCHER_CODE__COLON)}
                  </RedemptionLabel>
                  <CopyToClipboard
                    text={license.voucherCode}
                    onTextCopied={() => {
                      setTooltipText(i18n.t(k.GENERIC__COPIED));
                    }}
                    onMouseLeave={() => {
                      setTimeout(
                        () => setTooltipText(i18n.t(k.CTA__COPY_TO_CLIPBOARD)),
                        200
                      );
                    }}>
                    <LearnInTooltip title={tooltipText} childHasButton={true}>
                      <RedemptionVoucher>
                        {license.voucherCode}
                      </RedemptionVoucher>
                    </LearnInTooltip>
                  </CopyToClipboard>
                </RedemptionLabelBadge>
              )}
              {license.adminNote ||
                (license.managerNote && (
                  <RedemptionApprovalNotes>
                    {license.adminNote?.length && (
                      <RedemptionNotesWrapper>
                        <RedemptionNotes>
                          <RedemptionNoteTitle>
                            {i18n.t(k.GENERIC__NOTES_FROM__FORMAT, {
                              name: license.adminName,
                            })}
                          </RedemptionNoteTitle>
                          <QuillHtmlViewer
                            html={formatEmailAndUrlToLink(license.adminNote)}
                          />
                        </RedemptionNotes>
                      </RedemptionNotesWrapper>
                    )}

                    {license.managerNote?.length && (
                      <RedemptionNotesWrapper>
                        <RedemptionNotes>
                          <RedemptionNoteTitle>
                            {i18n.t(k.GENERIC__NOTES_FROM__FORMAT, {
                              name: license.managerName,
                            })}
                          </RedemptionNoteTitle>
                          <QuillHtmlViewer
                            html={formatEmailAndUrlToLink(license.managerNote)}
                          />
                        </RedemptionNotes>
                      </RedemptionNotesWrapper>
                    )}
                  </RedemptionApprovalNotes>
                ))}
            </div>
          </LearnInText>
        )}
      </LearnInWrapper>
    </LearnInModal>
  ) : (
    <LearnInModal
      tag={ModalTags.LicenseAuto}
      visible={true}
      width={400}
      onCancel={() => onClose()}
      onOk={() => (programUrl?.length ? openInNewTab(programUrl) : onClose())}
      okButtonChild={i18n.t(
        program?.applicationUrl.length ? k.CTA__VISIT_WEBSITE : k.CTA__OK
      )}
      cancelButtonProps={{color: COLORS.Blue800, style: {border: 'none'}}}
      ariaLabel={`${provider} ${i18n.t(k.LICENSE)}`}>
      <LearnInWrapper tag={WrapperTags.ModalProgramHeader}>
        <LearnInText tag={TextTags.ModalHeader}>
          {provider} {i18n.t(k.LICENSE)}
        </LearnInText>
        {!deprecatedLicenseVM ? (
          <LoadingSpinner />
        ) : (
          <LearnInText tag={TextTags.LicenseViewerModalBody}>
            <QuillHtmlViewer html={deprecatedLicenseVM.redemptionGuideline} />
            {program?.approvalNotes?.length && (
              <RedemptionNotesWrapperDeprecated>
                <RedemptionNoteTitleDeprecated>
                  {i18n.t(k.LICENSE__NOTES)}
                </RedemptionNoteTitleDeprecated>
                <RedemptionNotesDeprecated>
                  <QuillHtmlViewer
                    html={formatEmailAndUrlToLink(program?.approvalNotes)}
                  />
                </RedemptionNotesDeprecated>
              </RedemptionNotesWrapperDeprecated>
            )}
          </LearnInText>
        )}
      </LearnInWrapper>
    </LearnInModal>
  );
}
