import {Dispatch, useCallback, useState} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import moment, {Moment} from 'moment-timezone';
import {Form} from 'antd';
import {
  DATE_FORMAT,
  disableDatesBeforeYesterdayAfterYear,
  dateToUTCMoment,
  stripTimezoneMakeUtc,
} from '@utils/timeUtils';
import {LearnInDatePicker} from '../LearnInDatePicker';
import {handleInputErrorText, noUserInputAllowedHtml} from '@utils/itemUtils';
import {localizeText} from '@utils/l10nUtils';

/*
|--------------------------------------------------------------------------
| Styled Component
|--------------------------------------------------------------------------
*/
const StyledFormItem = styled(Form.Item)`
  display: block;

  .ant-form-item-label {
    padding-bottom: 0 !important;
  }
  .ant-form-item-control-input {
    min-height: 0 !important;
  }
  .ant-picker-status-error.ant-picker {
    background: inherit !important;
  }
  .ant-picker {
    min-width: 250px !important; /* localizations can be longer than en-US, but antD doesn't redefine the inline width after Moment has formatted into localizations */
  }
  ${(props) =>
    props.suppressAsterisk &&
    `
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: none !important;
    }
  `}
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export interface FundsExpireOnFieldProps {
  approvalExpirationDate: Moment | null;
  setApprovalExpirationDate?:
    | Dispatch<Moment>
    | Dispatch<React.SetStateAction<string | Moment>>;
  suppressAsterisk?: boolean;
  isHelperTextOn?: boolean;
  helperText?: string;
  localizationId?: string;
}

function FundsExpireOnField({
  approvalExpirationDate,
  setApprovalExpirationDate,
  suppressAsterisk = true,
  isHelperTextOn,
  helperText,
  localizationId,
}: FundsExpireOnFieldProps) {
  const [expirationValue, setExpirationValue] = useState<Moment | null>(
    dateToUTCMoment(approvalExpirationDate)
  );

  const handleDateChange = useCallback(
    (date: Moment) => {
      const utcDate = moment(stripTimezoneMakeUtc(date));
      setExpirationValue(moment(utcDate));
      setApprovalExpirationDate?.(moment(utcDate)); // notify parent
    },
    [setApprovalExpirationDate]
  );

  const dateFormat = DATE_FORMAT.MONTH_LONG_DAY_YEAR;
  const placeholder = approvalExpirationDate
    ? approvalExpirationDate.format(dateFormat)
    : i18n.t(k.DATE__SELECT);

  return (
    <StyledFormItem
      name="fundsExpireOn"
      initialValue={expirationValue}
      className="lb-application-drawer"
      rules={[
        {
          required: true,
          message: noUserInputAllowedHtml(
            handleInputErrorText(
              i18n.t(k.VALIDATION__REQUIRED),
              localizeText({
                translationKey: localizationId,
                translationValue: helperText,
              }),
              isHelperTextOn
            )
          ),
        },
      ]}
      label={i18n.t(k.PLAN__EXPIRY__FUNDS_EXPIRE_ON)}
      $suppressAsterisk={suppressAsterisk}>
      <LearnInDatePicker
        id="funds-expire-on-date-input"
        value={expirationValue}
        setValue={handleDateChange}
        disabledDate={disableDatesBeforeYesterdayAfterYear}
        format={dateFormat}
        showTodayBtn={false}
        placeholder={placeholder}
        theme="link"
      />
    </StyledFormItem>
  );
}

export default FundsExpireOnField;
