import {CompanySetting} from './../enums';
import {ICompanySettingResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ICompanySettingResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getICompanySettingResponseMockData(
  args?: Partial<ICompanySettingResponse>
): ICompanySettingResponse {
  return {
    key: CompanySetting.LxpDomain,
    value: faker.word.noun(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ICompanySettingResponseMockData: ICompanySettingResponse = {
  key: CompanySetting.LxpDomain,
  value: 'string', // optional
};
