import {useCallback, useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import SideColorSelectorContainer from './SideColorSelector/SideColorSelector.container';
import styled from 'styled-components';
import {Checkbox, Form} from 'antd';
import {AcademyBrandPayload} from '@generated/interfaces';
import {useParams} from 'react-router-dom';
import {SECTIONS_NAV_WIDTH, SIDE_NAV_WIDTH} from '@utils/constants';
import {
  FooterButtonContainer,
  FooterContainer,
} from '@components/reusable/Footer/Footer';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {
  determineBlackOrWhite,
  determineColorShade,
  getAlphanumericString,
  isHex,
} from '@utils/color';
import {useGetDefaultAcademy, useUpdateTheming} from '@utils/academyBranding';
import BrandingPreviewPane from './BrandingPreviewPane';
import {BrandingScreenProps} from './Branding.types';
import {useUpdateAcademyBrandingMutation} from '@generated/hooks';
import {notify} from '@store/apiEndpoints/utils';
import {Helmet} from 'react-helmet-async';

const Container = styled.div`
  padding: 0 0 72px ${SIDE_NAV_WIDTH + SECTIONS_NAV_WIDTH + 88}px;
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0.5rem 1rem;
`;
function BrandingScreen({
  academyData,
  refetchAcademy,
  title,
}: BrandingScreenProps) {
  const {branding, academyProfileImageUrl} = academyData || {};
  const defaultAcademy = useGetDefaultAcademy();
  const [form] = Form.useForm();
  const {academyId} = useParams();
  const {updateAcademyTheme} = useUpdateTheming(branding);
  const {isDefaultTheme} = branding || defaultAcademy;
  const [_isDefaultTheme, setIsDefaultTheme] = useState(isDefaultTheme);

  const handleColorChange = (e) => {
    const updateAcademyBranding = (value, name) => {
      if (name === 'primaryBrandColor' || name === 'secondaryBrandColor') {
        const welcomeBackgroundStyle = academyProfileImageUrl
          ? determineBlackOrWhite(branding['primaryBrandColor'])
          : branding['primaryBrandColor'];

        for (const property in branding) {
          const handleColorChange = (color) =>
            updateColors(name, value, property, color);
          switch (property) {
            case 'backgroundColor':
            case 'primaryBrandColor':
              handleColorChange(branding['primaryBrandColor']);
              break;
            case 'buttonDefaultWelcomeColor':
            case 'itemSelectedBackgroundNavColor':
            case 'secondaryBrandColor':
              handleColorChange(branding['secondaryBrandColor']);
              break;
            case 'itemDefaultTextNavColor':
            case 'itemHoverTextNavColor':
              handleColorChange(
                determineBlackOrWhite(branding['backgroundColor'])
              );
              break;
            case 'itemSelectedTextNavColor':
              handleColorChange(
                determineBlackOrWhite(
                  branding['itemSelectedBackgroundNavColor']
                )
              );
              break;
            case 'itemHoverBackgroundNavColor':
              handleColorChange(
                determineColorShade(branding['backgroundColor'])
              );
              break;
            case 'buttonHoverWelcomeColor':
              handleColorChange(
                determineColorShade(branding['backgroundWelcomeColor'])
              );
              break;
            case 'backgroundWelcomeColor':
              branding[property] = welcomeBackgroundStyle;
              form.setFieldsValue({[property]: welcomeBackgroundStyle});
              break;
            case 'itemDefaultTextWelcomeColor':
              handleColorChange(
                determineBlackOrWhite(branding['buttonDefaultWelcomeColor'])
              );
              break;
            default:
              handleColorChange(branding[property]);
              break;
          }
        }
      } else {
        updateAllAcademyBranding(value, name);
      }
    };
    const formattedColor = `#${getAlphanumericString(e.target.value)}`;
    const colorName = e.target.name;

    if (isHex(formattedColor) || e.target.type === 'checkbox') {
      updateAcademyBranding(formattedColor, colorName);
    } else if (e.type === 'blur') {
      updateAcademyBranding(branding[colorName], colorName);
    }
    updateAcademyTheme();
  };

  const updateColors = useCallback(
    (name: string, value: string, property: string, defaultColor: string) => {
      const color = property === name ? value : defaultColor;

      branding[property] = color;
      form.setFieldsValue({
        [property]: getAlphanumericString(color),
      });
    },
    [branding, form]
  );

  const updateAllAcademyBranding = useCallback(
    (value: string, name: string) => {
      for (const property in branding) {
        updateColors(name, value, property, branding[property]);
      }
    },
    [branding, updateColors]
  );

  const updateAcademyBranding = useUpdateAcademyBrandingMutation({
    onSuccess: () => {
      notify({
        message: i18n.t(k.NOTIFICATION__BRANDING_UPDATE_SUCCESS),
      });
    },
    onError: () => {
      notify({
        type: 'error',
        message: i18n.t(k.NOTIFICATION__BRANDING_UPDATE_ERROR),
      });
    },
  });

  useEffect(() => {
    return function cleanup() {
      // Added cleanup to prevent unsaved colors to show
      refetchAcademy();
    };
  }, [refetchAcademy, title]);

  useEffect(() => {
    for (const property in branding) {
      updateAllAcademyBranding(
        branding?.[property] || defaultAcademy?.[property],
        property
      );
    }
    updateAcademyTheme();
    // Intentionally not including `updateAcademyTheme` in exhaustive-deps so
    // it doesn't infinitely loop. See hook for details on behavior.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branding, defaultAcademy, updateAllAcademyBranding]);

  function handleSubmit() {
    const payload = {
      academyId: parseInt(academyId),
      ...branding,
    } as AcademyBrandPayload;
    updateAcademyBranding.mutate({
      payload,
    });
  }

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <SideColorSelectorContainer
        handleChange={handleColorChange}
        form={form}
      />
      <BrandingPreviewPane academyData={academyData} branding={branding} />
      <FooterContainer>
        <FooterButtonContainer>
          <StyledCheckbox
            checked={_isDefaultTheme}
            onClick={() => setIsDefaultTheme(!_isDefaultTheme)}>
            {i18n.t(k.BRAND__MAKE_DEFAULT)}
          </StyledCheckbox>
          <LearnInButton
            onClick={handleSubmit}
            htmlType="submit"
            tag={ButtonTags.Primary}>
            {i18n.t(k.CTA__SAVE)}
          </LearnInButton>
        </FooterButtonContainer>
      </FooterContainer>
    </Container>
  );
}

export default BrandingScreen;
