import {AcademyUserStatus} from './../enums';
import {AcademyUser} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {
  IAcademyUserBasicInfoMockData,
  getIAcademyUserBasicInfoMockData,
} from './IAcademyUserBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyUser

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyUserMockData(
  args?: Partial<AcademyUser>
): AcademyUser {
  return {
    ...getBaseEntityMockData(),
    ...getIAcademyUserBasicInfoMockData(),
    academyId: faker.datatype.number(),
    enrollmentDate: String(faker.date.past()),
    lastNudgeSent: faker.word.noun(), // optional
    lxpCompletionId: String(faker.datatype.number()),
    status: AcademyUserStatus.InProgress,
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyUserMockData: AcademyUser = {
  ...BaseEntityMockData,
  ...IAcademyUserBasicInfoMockData,
  academyId: 1,
  enrollmentDate: '2023-03-29T22:17:19.738072',
  lastNudgeSent: 'string', // optional
  lxpCompletionId: '1',
  status: AcademyUserStatus.InProgress,
  userCompanyId: 1,
};
