import {useEffect, useState} from 'react';
import ProgramFinderView from './ProgramFinder.view';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import Skills from '@components/Skills';
import ProgramTypeContainer from '@components/ProgramType';
import Budget from '@components/Budget';
import {
  MAX_PROGRAM_PRICE_FOR_FILTER,
  PROGRAM_FILTER_LANGUAGES,
} from '@utils/constants';
import {useNavigate} from 'react-router-dom';
import ProgramSearchCategory from '../../api/generated/enums/ProgramSearchCategory.enum';
import {
  useProgramFinderSearchQuery,
  useTopicsQuery,
  useUserQuery,
} from '@generated/hooks';
import {ProgramFinderPath} from '@components/ProgramType/programFinderHelpers';
import {ProgramFinderButtonType} from '@utils/analytics';
import {trackView} from '@components/ProgramType/trackView';
import {NamePath} from 'antd/lib/form/interface';
import {getPersonalLearningBudgetLimitRq} from '@store/apiEndpoints';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {exchangeAmount, MONEY_DEFAULT} from '@utils/moneyUtils';
import {useQuery} from 'react-query';
import {PreApprovalAmountBalanceVM} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import {getMaxPossibleProgramPriceRq} from '@store/apiEndpoints/program/queries';
import {updatePageTitle} from '@hooks/useSetPageTitle';

enum ProgramType {
  Bootcamp = 1,
  Degree = 2,
  Certificate = 3,
  IndividualCourse = 4,
  Coaching = 5,
  Course = 7,
  BachelorsDegree = 8,
  Certification = 9,
  MastersDegree = 10,
  Membership = 11,
  Series = 12,
}

function ProgramFinderContainer() {
  updatePageTitle(i18n.t(k.PROGRAM_FINDER__TITLE));

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [step, setStep] = useState(-1);
  const [selectedPath, setSelectedPath] = useState<ProgramFinderPath>(null);
  const [formData, setFormData] = useState({
    skills: undefined,
    program: undefined,
    budget: undefined,
  });
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const [includeSkills, setIncludeSkills] = useState(false);

  const {data: topicsQuery} = useTopicsQuery(null);

  const {data: user} = useUserQuery(null);
  const currencyCode = user?.currency;

  const preApprovalAmountBalanceRq = getPersonalLearningBudgetLimitRq();
  const exchangeRate = useExchangeRate(MONEY_DEFAULT.currency, currencyCode);

  const {data: preApprovalAmountBalance} =
    useQuery<PreApprovalAmountBalanceVM | null>(
      preApprovalAmountBalanceRq.queryKey,
      () => simpleQueryFn(preApprovalAmountBalanceRq.path)
    );
  const amountBalanceExchanged = exchangeAmount(
    preApprovalAmountBalance?.amountLimit -
      preApprovalAmountBalance?.amountBalance,
    exchangeRate
  );
  const maxRequestAmount = amountBalanceExchanged || 0;
  const maxPossibleProgramPriceRq = getMaxPossibleProgramPriceRq();
  const maxPossibleProgramPriceQuery = useQuery<number>(
    maxPossibleProgramPriceRq.queryKey,
    () => simpleQueryFn(maxPossibleProgramPriceRq.path)
  );
  const getParams = ({skip = false, includeProgramType = false}) => {
    const queryParams = {
      maxPrice: MAX_PROGRAM_PRICE_FOR_FILTER,
      languages: JSON.parse(localStorage.getItem(PROGRAM_FILTER_LANGUAGES)),
    };
    const skills = form.getFieldValue('skills');
    if (skills) {
      const skillMatch = topicsQuery.find(
        (item) => item.description === skills
      );

      if (skillMatch) queryParams['skillIds'] = skillMatch.id.toString();
      else queryParams['keywords'] = skills;
    }
    queryParams['maxPrice'] =
      form.getFieldValue('max') !== undefined
        ? form.getFieldValue('max')
        : MAX_PROGRAM_PRICE_FOR_FILTER;
    queryParams['minPrice'] =
      form.getFieldValue('min') !== undefined ? form.getFieldValue('min') : 0;

    if (includeProgramType) {
      if (form.getFieldValue('programType')) {
        const programTypeIds = [];
        form.getFieldValue('programType').map((item) => {
          switch (item) {
            case ProgramSearchCategory.Course:
              programTypeIds.push(
                ProgramType.Course,
                ProgramType.Certificate,
                ProgramType.Certification,
                ProgramType.Membership,
                ProgramType.Series,
                ProgramType.IndividualCourse
              );
              break;
            case ProgramSearchCategory.Degree:
              programTypeIds.push(
                ProgramType.Degree,
                ProgramType.MastersDegree,
                ProgramType.BachelorsDegree
              );
              break;
            case ProgramSearchCategory.Bootcamp:
              programTypeIds.push(ProgramType.Bootcamp);
              break;
          }
        });
        queryParams['programTypeIds'] = programTypeIds;
      }
    }
    return queryParams;
  };

  const {data, invalidateExact} = useProgramFinderSearchQuery({
    queryParams: getParams({skip: false, includeProgramType: true}),
  });
  const [total, setTotal] = useState(1);
  const {data: dataWithoutProgramType} = useProgramFinderSearchQuery({
    queryParams: getParams({
      skip: false,
      includeProgramType: false,
    }),
  });
  const handleAction = (value) => {
    if (Array.isArray(value)) setIsButtonVisible(!(value.length === 0));
    else if (typeof data === 'string' && data === '') setIsButtonVisible(false);
    else setIsButtonVisible(!!value);
    invalidateExact();
  };
  const createSteps = (path?: ProgramFinderPath | null) => {
    const skillsStep = path !== ProgramFinderPath.Browse && {
      title: includeSkills ? i18n.t(k.GENERIC__SKILL) : i18n.t(k.PROGRAM),
      content: (
        <Skills
          form={form}
          setIsButtonVisible={setIsButtonVisible}
          handleAction={handleAction}
          includeSkills={includeSkills}
          topicsQuery={topicsQuery}
        />
      ),
      next: true,
    };

    const programTypeStep = {
      title: i18n.t(k.PROGRAM_FINDER__PROGRAM_TYPE),
      content: (
        <ProgramTypeContainer
          handleAction={handleAction}
          form={form}
          data={dataWithoutProgramType}
          currencyCode={currencyCode}
          maxRequestAmount={
            preApprovalAmountBalance?.amountLimit === 0
              ? maxPossibleProgramPriceQuery?.data
              : preApprovalAmountBalance?.amountBalance
          }
        />
      ),
      next: true,
    };

    const budgetStep = {
      title: i18n.t(k.PROGRAM_FINDER__BUDGET),
      content: (
        <Budget
          handleAction={handleAction}
          currencyCode={currencyCode}
          form={form}
          maxRequestAmount={
            preApprovalAmountBalance?.amountLimit === 0
              ? maxRequestAmount
              : preApprovalAmountBalance?.amountBalance
          }
        />
      ),
      next: null,
    };

    return [skillsStep, programTypeStep, budgetStep].filter(Boolean);
  };

  const initialScreen = {
    title: i18n.t(k.WELCOME),
    content: '',
    next: null,
  };

  const programPath = '/user/programs/marketplace-programs';

  const navigateToCoach = () => {
    const coachProgramTypeId = ProgramType.Coaching;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('programTypeIds', coachProgramTypeId.toString());
    const coachParams = searchParams.toString();
    navigate(`${programPath}?${coachParams}`);
  };

  const options = [
    {value: ProgramFinderPath.Skills, path: createSteps()},
    {value: ProgramFinderPath.Programs, path: createSteps()},
    {value: ProgramFinderPath.Coach, onNavigate: navigateToCoach},
    {
      value: ProgramFinderPath.Browse,
      path: createSteps(ProgramFinderPath.Browse),
    },
  ];
  const getCurrentPath = () => {
    if (step === -1) return [initialScreen];

    const selectedOptionObj = options.find(
      (option) => option.value === selectedPath
    );

    return selectedOptionObj?.path.map((step) => ({
      title: step?.title || '',
      content: step?.content || '',
      next: typeof step.next === 'function' ? step.next : null,
    }));
  };

  const currentPath = getCurrentPath();
  const sharedTrackingProps = {form, currentPath, selectedPath, step};

  const resetFieldsByStep = (step: number) => {
    const stepToField: Record<string, NamePath | NamePath[]> = {
      [i18n.t(k.PROGRAM_FINDER__SKILLS)]: 'skills',
      [i18n.t(k.PROGRAM__PLURAL)]: 'skills',
      [i18n.t(k.PROGRAM_FINDER__PROGRAM_TYPE)]: 'programType',
      [i18n.t(k.PROGRAM_FINDER__BUDGET)]: ['min', 'max'],
    };
    const currentStep = currentPath[step];
    const fieldMapping = stepToField[currentStep?.title];

    if (Array.isArray(fieldMapping)) {
      form.resetFields(['min']);
      form.setFieldValue('max', MAX_PROGRAM_PRICE_FOR_FILTER);
    } else form.resetFields([fieldMapping]);

    const values = form.getFieldsValue();
    setFormData({...formData, ...values});
  };

  const prev = () => {
    setTotal(
      Array.isArray(data) ? data?.reduce((acc, obj) => acc + obj.count, 0) : 0
    );
    setIsButtonVisible(false);
    trackView({
      buttonType: ProgramFinderButtonType.Back,
      ...sharedTrackingProps,
    });
    setStep(step - 1);
    resetFieldsByStep(step);
  };

  const next = ({skip = false}: {skip: boolean}) => {
    trackView({
      buttonType: ProgramFinderButtonType.Next,
      ...sharedTrackingProps,
    });

    const selected = options.find((option) => option.value === selectedPath);
    if (selected?.onNavigate) selected.onNavigate();

    if (skip) resetFieldsByStep(step);

    setStep(step + 1);
    setIsButtonVisible(false);
  };

  const onClose = () => {
    trackView({
      buttonType: ProgramFinderButtonType.Close,
      ...sharedTrackingProps,
    });
    navigate(-1);
  };

  const handleFinish = ({skip = false}: {skip: boolean}) => {
    trackView({
      buttonType: ProgramFinderButtonType[skip ? 'Skip' : 'Next'],
      ...sharedTrackingProps,
    });
    const values = form.getFieldsValue();
    setFormData({...formData, ...values});
    const searchParams = new URLSearchParams(location.search);
    const skills = form.getFieldValue('skills');
    if (skills) {
      const skillMatch = topicsQuery.find(
        (item) => item.description === skills
      );

      if (skillMatch) searchParams.set('skillIds', skillMatch.id.toString());
      else searchParams.set('search', encodeURIComponent(skills));
    }
    searchParams.set(
      'maxPrice',
      skip ? MAX_PROGRAM_PRICE_FOR_FILTER : form.getFieldValue('max')
    );
    searchParams.set('minPrice', skip ? 0 : form.getFieldValue('min'));
    if (form.getFieldValue('programType')) {
      const programTypeIds = [];
      form.getFieldValue('programType').map((item) => {
        switch (item) {
          case ProgramSearchCategory.Course:
            programTypeIds.push(
              ProgramType.Course,
              ProgramType.Certificate,
              ProgramType.Certification,
              ProgramType.Membership,
              ProgramType.Series,
              ProgramType.IndividualCourse
            );
            break;
          case ProgramSearchCategory.Degree:
            programTypeIds.push(
              ProgramType.Degree,
              ProgramType.MastersDegree,
              ProgramType.BachelorsDegree
            );
            break;
          case ProgramSearchCategory.Bootcamp:
            programTypeIds.push(ProgramType.Bootcamp);
            break;
        }
      });
      programTypeIds.length > 0 &&
        searchParams.set('programTypeIds', programTypeIds.toString());
    }

    const programParams = searchParams.toString();
    navigate(`${programPath}?${programParams}`);
  };

  useEffect(
    () =>
      selectedPath === 0 ? setIncludeSkills(true) : setIncludeSkills(false),
    [selectedPath, includeSkills]
  );

  useEffect(() => {
    setTotal(
      Array.isArray(data) ? data?.reduce((acc, obj) => acc + obj.count, 0) : 1
    );
    if (typeof data === 'string' && data === '') {
      setIsButtonVisible(false);
      setTotal(0);
    }
  }, [data]);

  return (
    <ProgramFinderView
      totalCount={total}
      current={step}
      currentPath={currentPath}
      form={form}
      handleFinish={handleFinish}
      isButtonVisible={isButtonVisible}
      next={next}
      prev={prev}
      setIsButtonVisible={setIsButtonVisible}
      setSelectedOption={setSelectedPath}
      onClose={onClose}
    />
  );
}

export default ProgramFinderContainer;
