import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {UserPlanItemCardVM} from '@models/serverModels';
import ManageFinanceDataWrapper from '@pages/manageFinance/ManageFinanceDataWrapper';
import {Hash, Key, Pathname, Search} from 'history';
import useSetPageTitle from '@hooks/useSetPageTitle';

interface Location {
  pathname?: Pathname;
  search?: Search;
  state?: any;
  hash?: Hash;
  key?: Key | undefined;
  item?: UserPlanItemCardVM;
}

const getUserPlanItemId = (location: Location) => {
  return location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
};

const ManageFinanceWrapper = ({title}: {title?: string}) => {
  const location: Location = useLocation();
  const [item, setItem] = useState<UserPlanItemCardVM | undefined>(
    location.state?.item
  );

  const [userPlanItemId, setUserPlanItemId] = useState<string>(
    getUserPlanItemId(location)
  );

  useSetPageTitle(title);

  useEffect(() => {
    setItem(location.state?.item);
    setUserPlanItemId(getUserPlanItemId(location));
  }, [location]);

  return (
    <ManageFinanceDataWrapper item={item} userPlanItemId={userPlanItemId} />
  );
};

export default ManageFinanceWrapper;
