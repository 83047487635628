import {CurrencyCode} from './../enums';
import {VoucherImport} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: VoucherImport

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getVoucherImportMockData(
  args?: Partial<VoucherImport>
): VoucherImport {
  return {
    companyId: faker.datatype.number(), // optional
    programUniqueId: String(faker.datatype.number()),
    providerUniqueId: String(faker.datatype.number()),
    voucherCode: faker.word.noun(),
    voucherCost: faker.datatype.number(), // optional
    voucherCurrency: CurrencyCode.USD, // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const VoucherImportMockData: VoucherImport = {
  companyId: 1, // optional
  programUniqueId: '1',
  providerUniqueId: '1',
  voucherCode: 'string',
  voucherCost: 123, // optional
  voucherCurrency: CurrencyCode.USD, // optional
};
