import {Card, Form, Row} from 'antd';
import styled, {css} from 'styled-components';
import {COLORS} from '@utils/constants';
import {LearnInSelect} from '@components/reusable/Select/Select.style';
import {CurrencySelect} from '@components/admin/pages/programs/CreateProgramDrawer';
import {LearnInInput} from '@components/reusable/LearnInForm';
import {BodySpan} from '@components/reusable/Typography';
import LearnInQuill from '@components/reusable/LearnInQuill';

// AntD Overrides
export const LicenseContainer = styled.div`
  color: ${COLORS.Neutral900};
  display: block;

  h2 {
    padding: 0;
  }

  .ant-form {
    label,
    input {
      font-size: 1rem;
    }
    h3 {
      label {
        font-size: 1.25rem;
      }
    }
  }

  .ant-form-item-label {
    padding: 0 0 0.25rem;
    > label {
      font-weight: 500;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    font-size: 1rem;
    line-height: 2rem;
  }

  .ant-radio-wrapper {
    font-weight: 400;
  }
`;

export const LicenseCard = styled(Card)`
  border-radius: 0.625rem;
  box-shadow: ${COLORS.BoxShadowStandard};
  margin: 2rem;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const LicenseProviderSelect = styled(LearnInSelect)`
  width: 100% !important; // Responsive minimum
  max-width: 28rem;
`;

export const LicenseSectionTitleContainer = styled.div`
  margin-bottom: 1.3333rem;
`;

export const LicenseSectionTitle = styled.h2`
  color: ${COLORS.Neutral900};
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.25rem;
  margin-bottom: 1rem;
`;

export const LicenseButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 10%;
  button {
    margin-left: 1rem;
  }
`;

export const LicenseProviderContainer = styled.div`
  font-size: 1rem;
`;

export const LicenseLabel = styled.label<{
  required: boolean;
  margin?: string;
}>`
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: ${({margin}) => margin ?? 0};

  ${({required}) =>
    required &&
    css`
      ::after {
        color: ${COLORS.Red800};
        content: ' *';
        font-weight: 400;
      }
    `}
`;

export const LicenseRadioRow = styled(Row)`
  margin-bottom: 0.5rem;
`;

export const LicenseStyledCurrencySelect = styled(CurrencySelect)`
  width: 100px !important;
`;

export const LicenseRequiredByFormItem = styled(Form.Item)`
  margin-bottom: 1.75rem !important;
`;

export const LicenseReactQuill = styled(LearnInQuill)`
  width: 100%; // Responsive minimum
  max-width: 45rem;

  .ql-editor {
    min-height: 5.25rem;
  }
`;

export const LicenseFieldSubText = styled(BodySpan)`
  display: block;
`;

export const LicenseFundingType = styled.div`
  width: 100%; // Responsive minimum
  max-width: 34rem;
`;

export const LicenseOptionDetails = styled.div`
  background: ${COLORS.Neutral100};
  border-radius: 0.25rem;
  margin: 0.5rem 0;
  padding: 1rem 1.5rem;
`;

export const LicenseCostItemContainer = styled(Form.Item)`
  margin-bottom: 1rem !important;
`;

export const LicenseFormItem = styled(Form.Item)`
  margin-bottom: 1rem !important;
`;

export const LicenseDurationItem = styled(Form.Item)``;

export const LicenseUsageItem = styled(Form.Item)`
  margin-bottom: 1rem !important;
`;

export const LicenseMoneyInput = styled(LearnInInput)`
  width: 12.5rem;
`;

export const LicenseNumberInput = styled(LearnInInput)`
  width: 6rem !important;
`;

export const LicenseAside = styled.span`
  margin-left: 0.5rem;
`;
