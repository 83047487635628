import {ApprovalRankType, ApprovalStatus} from './../enums';
import {PreApprovalApplication} from './../interfaces';
import {
  PreApprovalQuestionMockData,
  getPreApprovalQuestionMockData,
} from './PreApprovalQuestion.mock';
import {faker} from '@faker-js/faker';

// Interface: PreApprovalApplication

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPreApprovalApplicationMockData(
  args?: Partial<PreApprovalApplication>
): PreApprovalApplication {
  return {
    application: [getPreApprovalQuestionMockData()],
    approvalRankType: ApprovalRankType.Initial,
    id: faker.datatype.number(),
    status: ApprovalStatus.Pending,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PreApprovalApplicationMockData: PreApprovalApplication = {
  application: [PreApprovalQuestionMockData],
  approvalRankType: ApprovalRankType.Initial,
  id: 1,
  status: ApprovalStatus.Pending,
};
