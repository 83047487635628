import {IconContainer} from '@pages/ProgramFinder/ProgramFinder.styled';
import Icon from '@blocks/Icon/Icon';
import {COLORS} from '@utils/constants';
import {CommentOutlined} from '@ant-design/icons';
import {H3} from '@components/reusable/Typography';
import {i18n, k} from '@i18n/translate';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {openWidget} from '@utils/chat/chat-utils';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {Card} from 'antd';

export const ProgramFinderChatCard = () => (
  <Card
    bodyStyle={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '1rem',
      textAlign: 'center',
    }}>
    <IconContainer>
      <Icon
        color={COLORS.Purple800}
        size="XL"
        icon={<CommentOutlined aria-hidden="true" />}
      />
    </IconContainer>
    <H3 fontWeight={500}>{i18n.t(k.PROGRAM_FINDER__NO_RESULTS__TITLE)}</H3>
    <H3 style={{marginTop: 0}} color={COLORS.Neutral600}>
      {i18n.t(k.PROGRAM_FINDER__NO_RESULTS__SUBTITLE)}
    </H3>
    <LearnInButton onClick={() => openWidget()} tag={ButtonTags.Primary}>
      {i18n.t(k.SUPPORT__CHAT_NOW)}
    </LearnInButton>
  </Card>
);
