import {AcademyStepType} from './../enums';
import {AcademyStepReportVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AcademyStepReportVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyStepReportVMMockData(
  args?: Partial<AcademyStepReportVM>
): AcademyStepReportVM {
  return {
    academyLevelId: faker.datatype.number(),
    academyUserCount: faker.datatype.number(),
    completedCount: faker.datatype.number(),
    completionPercent: faker.datatype.number(),
    id: faker.datatype.number(),
    order: faker.datatype.number(),
    programId: faker.datatype.number(), // optional
    title: faker.company.catchPhrase(),
    type: AcademyStepType.MarketplaceProgram,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyStepReportVMMockData: AcademyStepReportVM = {
  academyLevelId: 1,
  academyUserCount: 5,
  completedCount: 5,
  completionPercent: 123,
  id: 1,
  order: 123,
  programId: 1, // optional
  title: 'Advanced Web Design',
  type: AcademyStepType.MarketplaceProgram,
};
