import {i18n, k} from '@i18n/translate';
import {useState} from 'react';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/../components/reusable/Button/ButtonEnums';
import {ExternalLinkIcon} from '@assets/svg/ExternalLinkIcon';
import {ClickToJoinShareButton} from '@components/ClickToJoin/ClickToJoinShareButton';
/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const HeaderLink = styled.button`
  color: ${COLORS.Blue800};
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
  margin-right: 24px;
  display: flex;
  align-items: center;
  &:hover,
  &:focus {
    color: ${COLORS.Blue950} !important;
    text-decoration: none !important;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ICustomProgramDrawerHeaderProps {
  isWelcomeEmailEnabled?: boolean;
  onClickPublish: () => void;
  onClickShareProgram?: () => void;
  onClickPreviewProgram: () => void;
  showPublishButton: boolean;
  canShowShareableLink?: boolean;
  programUserCount: number;
}

function CustomProgramDrawerHeader({
  isWelcomeEmailEnabled,
  onClickPublish,
  onClickShareProgram,
  onClickPreviewProgram,
  showPublishButton,
  canShowShareableLink,
  programUserCount,
}: ICustomProgramDrawerHeaderProps) {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <div style={{display: 'flex'}}>
      {showConfirmation && (
        <LearnInModal
          visible={true}
          tag={ModalTags.Default}
          onCancel={() => setShowConfirmation(false)}
          cancelButtonChild={i18n.t(k.CTA__CANCEL)}
          okButtonChild={i18n.t(k.PROGRAM__PUBLISH)}
          title={i18n.t(k.PROGRAM__PUBLISH)}
          onOk={() => {
            onClickPublish();
            setShowConfirmation(false);
          }}>
          <div style={{padding: '24px'}}>
            {programUserCount ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t(k.PROGRAM__PUBLISH_WARNING__FORMAT, {
                    count: programUserCount,
                  }),
                }}></span>
            ) : (
              i18n.t(k.PROGRAM__PUBLISH_WARNING)
            )}
          </div>
        </LearnInModal>
      )}
      {canShowShareableLink && (
        <ClickToJoinShareButton
          label={i18n.t(k.PROGRAM__CUSTOM__SHARE)}
          onClick={onClickShareProgram}
        />
      )}
      <HeaderLink
        onClick={onClickPreviewProgram}
        data-testid="preview-program-link">
        <ExternalLinkIcon
          style={{marginRight: '8px', fill: COLORS.Neutral600}}
        />
        {i18n.t(k.PROGRAM__PREVIEW__VERB)}
      </HeaderLink>
      {showPublishButton && (
        <LearnInButton
          data-testid="publish-program-button"
          tag={ButtonTags.Primary}
          onClick={() => {
            if (isWelcomeEmailEnabled) {
              setShowConfirmation(true);
            } else {
              onClickPublish();
            }
          }}>
          {i18n.t(k.PROGRAM__PUBLISH)}
        </LearnInButton>
      )}
    </div>
  );
}

/*
|--------------------------------------------------------------------------
| Export Default
|--------------------------------------------------------------------------
*/

export default CustomProgramDrawerHeader;
