import {useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import {Avatar} from 'antd';
import moment from 'moment';
import {getInitialsOfName} from '@components/providers/utils';
import LearnInTable from '@components/reusable/LearnInTable';
import {ProgramParticipantVM} from '@models/serverModels';
import {COLORS} from '@utils/constants';
import {StatusTag} from './NominationsTab';
import {DATE_FORMAT} from '@utils/timeUtils';
import {LinkStyledButton} from '@components/reusable/Button/Button.style';
import {useLocation, useNavigate} from 'react-router';
import {AdminPaths} from '@utils/ClientPaths';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useCanManageApprovals} from '@hooks/useCanManageApprovals';
import {ProgramStatus} from '@components/user/pages/program/store/interface';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IParticipantsTabsProps {
  dataSource: ProgramParticipantVM[];
  canShowEmployeeEmail: boolean;
}

function ParticipantsTabs({
  dataSource,
  canShowEmployeeEmail,
}: IParticipantsTabsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const isApprover = useCanManageApprovals();
  const columns = useMemo(() => {
    return [
      {
        title: i18n.t(k.EMPLOYEE__NAME),
        dataIndex: 'employeeName',
        key: 'employeeName',
        render: (employeeName: any, row) => {
          return (
            <div>
              <Avatar
                style={{
                  backgroundColor: COLORS.Red500,
                  marginRight: '15px',
                  verticalAlign: 'middle',
                }}
                size="large"
                gap={1}>
                {getInitialsOfName(employeeName)}
              </Avatar>

              {canShowEmployeeEmail && isApprover ? (
                <LinkStyledButton
                  onClick={() =>
                    navigate(`${AdminPaths.People}/${row.userCompanyId}`, {
                      state: {from: -1},
                    })
                  }>
                  {employeeName}
                </LinkStyledButton>
              ) : (
                <span>{employeeName}</span>
              )}
            </div>
          );
        },
        sorter: (a: any, b: any) =>
          a.employeeName.localeCompare(b.employeeName),
      },
      {
        title: i18n.t(k.STATUS__STATUS),
        dataIndex: 'status',
        key: 'status',
        render: (status: ProgramStatus) => {
          return <StatusTag status={status} />;
        },
        sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      },
      {
        title: i18n.t(k.TIME__ENROLLED),
        dataIndex: 'timeEnrolled',
        key: 'timeEnrolled',
        render: (timeEnrolled: any) => (timeEnrolled ? timeEnrolled : '-'),
        sorter: (a: any, b: any) => a.timeEnrolled - b.timeEnrolled,
      },
      {
        title: i18n.t(k.GENERIC__LAST_UPDATE),
        dataIndex: 'lastUpdated',
        key: 'lastUpdated',
        render: (lastUpdated: any) =>
          lastUpdated
            ? moment(lastUpdated).format(DATE_FORMAT.MONTH_DAY_YEAR)
            : '-',
        sorter: (a: any, b: any) =>
          moment(a.lastUpdated).diff(moment(b.lastUpdated)),
      },
    ];
  }, []);

  return <LearnInTable columns={columns} dataSource={dataSource} />;
}

export default ParticipantsTabs;
