import {TimePicker as AntdTimePicker} from 'antd';
import {LearnInDatePickerProps} from '@components/reusable/LearnInDatePicker';
import {createFormItemAdapter} from '../utils';
import {Moment} from 'moment';
import {DatePickerContainer} from './DueDate.styled';

export const DatePicker = createFormItemAdapter<LearnInDatePickerProps, Moment>(
  DatePickerContainer,
  'setValue',
  'value'
);

export const TimePicker = createFormItemAdapter(
  AntdTimePicker,
  'onSelect',
  'value'
);
