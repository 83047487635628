import {DetailsSectionVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: DetailsSectionVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getDetailsSectionVMMockData(
  args?: Partial<DetailsSectionVM>
): DetailsSectionVM {
  return {
    content: faker.word.noun(),
    sequenceNum: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const DetailsSectionVMMockData: DetailsSectionVM = {
  content: 'string',
  sequenceNum: 123,
  title: 'Advanced Web Design',
};
