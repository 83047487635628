import {ContentType} from './../enums';
import {NewCustomProgramContentPayload} from './../interfaces';
import {
  BaseCustomProgramContentPayloadMockData,
  getBaseCustomProgramContentPayloadMockData,
} from './BaseCustomProgramContentPayload.mock';
import {faker} from '@faker-js/faker';

// Interface: NewCustomProgramContentPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getNewCustomProgramContentPayloadMockData(
  args?: Partial<NewCustomProgramContentPayload>
): NewCustomProgramContentPayload {
  return {
    ...getBaseCustomProgramContentPayloadMockData(),
    contentType: ContentType.Text,
    customProgramId: faker.datatype.number(),
    order: faker.datatype.number(), // optional
    sectionId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const NewCustomProgramContentPayloadMockData: NewCustomProgramContentPayload =
  {
    ...BaseCustomProgramContentPayloadMockData,
    contentType: ContentType.Text,
    customProgramId: 1,
    order: 123, // optional
    sectionId: 1,
  };
