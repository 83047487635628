import {UseQueryResult} from 'react-query';
import {PlanItemType} from '@generated/enums';
import {PlanItem} from '@models/clientModels';

export interface UseStateNotReadyToDisplayProps {
  getPreApprovalFormQuery: UseQueryResult;
  getProgramDetailsQuery: UseQueryResult;
  preApprovalFormAmountBalanceQuery: UseQueryResult;
  getFinanceIncentiveDetailQuery: UseQueryResult;
  planItemType: PlanItem;
  licenseId?: number;
}

export const useStateNotReadyToDisplay = ({
  getPreApprovalFormQuery,
  getProgramDetailsQuery,
  preApprovalFormAmountBalanceQuery,
  getFinanceIncentiveDetailQuery,
  planItemType,
  licenseId,
}: UseStateNotReadyToDisplayProps) => {
  const approvalFormNotReady =
    getPreApprovalFormQuery.isLoading || getPreApprovalFormQuery.isFetching;

  const programDetailsNotReady =
    planItemType === PlanItemType.Program && !getProgramDetailsQuery.isSuccess;

  const amountBalanceNotReady =
    !licenseId &&
    planItemType === PlanItemType.Program &&
    !preApprovalFormAmountBalanceQuery.isSuccess;

  const incentiveDetailsNotReady =
    planItemType === PlanItemType.Finance &&
    !getFinanceIncentiveDetailQuery.isSuccess;

  return (
    approvalFormNotReady ||
    programDetailsNotReady ||
    amountBalanceNotReady ||
    incentiveDetailsNotReady
  );
};
