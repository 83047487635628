import {PermissionRole} from './../enums';
import {UpdateCompanyRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdateCompanyRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdateCompanyRequestMockData(
  args?: Partial<UpdateCompanyRequest>
): UpdateCompanyRequest {
  return {
    defaultPermissionRole: PermissionRole.AcademiesUser, // optional
    isAcademiesIntegratedExperienceDisabled: faker.datatype.boolean(), // optional
    isContentMarketplaceIntegratedExperienceDisabled: faker.datatype.boolean(), // optional
    lxpId: faker.datatype.number(), // optional
    name: faker.name.fullName(), // optional
    primaryBrandColor: faker.internet.color(), // optional
    rectangleLogoUrl: faker.internet.url(), // optional
    squareLogoUrl: faker.internet.url(), // optional
    virtualCardColor: faker.internet.color(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdateCompanyRequestMockData: UpdateCompanyRequest = {
  defaultPermissionRole: PermissionRole.AcademiesUser, // optional
  isAcademiesIntegratedExperienceDisabled: true, // optional
  isContentMarketplaceIntegratedExperienceDisabled: true, // optional
  lxpId: 1, // optional
  name: 'Bobby Johnson', // optional
  primaryBrandColor: '#FFD7DD', // optional
  rectangleLogoUrl: 'https://this-person-does-not-exist.com/en', // optional
  squareLogoUrl: 'https://this-person-does-not-exist.com/en', // optional
  virtualCardColor: '#FFD7DD', // optional
};
