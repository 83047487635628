import {api} from './api';
import {QueryClient, UseQueryResult} from 'react-query';
import {identity} from 'lodash';
import {CustomProgramAttachmentPayload} from '@models/serverModels';

// Stale Time Helpers
export const STALE = {
  MINUTES: {
    ONE: 1000 * 60,
    FIVE: 1000 * 60 * 5,
    TEN: 1000 * 60 * 10,
  },
  HOURS: {
    ONE: 1000 * 60 * 60,
  },
  INFINITY: Infinity,
};

export const addStaleTime = (staleTime?: number) =>
  staleTime || STALE.MINUTES.TEN;

/*
|--------------------------------------------------------------------------
| Cache Invalidation
|--------------------------------------------------------------------------
*/

export const simpleInvalidateExactQueryFn = async (key: string[]) => {
  return queryClient.invalidateQueries(key, {exact: true});
};

export const simpleInvalidateAllQueriesFn = async () => {
  return queryClient.invalidateQueries();
};

/*
|--------------------------------------------------------------------------
| Attachments
|--------------------------------------------------------------------------
*/

export async function largeAttachmentQueryFn(
  path: string,
  fileChunk: CustomProgramAttachmentPayload
) {
  const formData = new FormData();
  Object.keys(fileChunk).forEach((key) => {
    if (
      fileChunk[key.toString()] !== undefined &&
      fileChunk[key.toString()] !== null
    ) {
      formData.append(key.toString(), fileChunk[key.toString()]);
    }
  });

  if (!formData.get('description')) {
    formData.append('description', '');
  }
  const {data} = await api.post(path, formData, {
    headers: {'content-type': 'multipart/form-data'},
  });
  return data;
}

export async function multipleAttachmentProgressQueryFn(
  path: string,
  fileChunk: CustomProgramAttachmentPayload,
  index: number,
  setMultipleUploadProgress: ({progress, index}) => void
) {
  const formData = new FormData();
  Object.keys(fileChunk).forEach((key) => {
    if (!!fileChunk[key.toString()] || fileChunk[key.toString()] === 0) {
      formData.append(key.toString(), fileChunk[key.toString()]);
    }
  });
  const {data} = await api.post(path, formData, {
    headers: {'content-type': 'multipart/form-data'},
    onUploadProgress: (progressEvent) => {
      setMultipleUploadProgress({
        progress: (100 * progressEvent.loaded) / progressEvent.total,
        index,
      });
    },
  });

  return data;
}

/*
|--------------------------------------------------------------------------
| Common GET|POST|PUT|DELETE|PATCH
|--------------------------------------------------------------------------
*/

// Defaults
const defaultQueryFn = async ({queryKey}) => {
  const {data} = await api.get(queryKey[0]);
  return data;
};

const defaultMutationFn = async ({queryKey}) => {
  const {data} = await api.post(queryKey[0]);
  return data;
};

// Simples
export const simpleQueryFn = async (
  path: string,
  transformData: (data: any) => any = identity
) => {
  const {data, ...rest} = await api.get(path);
  if (data && typeof data === 'object') {
    data.meta = rest;
  }
  return transformData(data);
};

export async function simpleGetMutationFn<TData>(path: string): Promise<TData> {
  const {data} = await api.get(path);
  return data;
}

export async function simpleMutationFn<TData>(
  path: string,
  payload: any
): Promise<TData> {
  const {data} = await api.post(path, payload);
  return data;
}

export async function simplePutFn<TData>(
  path: string,
  payload: any
): Promise<TData> {
  const {data} = await api.put(path, payload);
  return data;
}

export async function simplePatchFn<TData>(
  path: string,
  payload: Record<string, unknown>
): Promise<TData> {
  const {data} = await api.patch(path, payload);
  return data;
}

export async function simpleDeleteFn<TData>(
  path: string,
  payload: any
): Promise<TData> {
  const {data} = await api.delete(path, {data: payload});
  return data;
}

/*
|--------------------------------------------------------------------------
| Updating the Cache
|--------------------------------------------------------------------------
*/

// Generic
export function simpleSetQueryDataFn<TItem>(
  key: string | string[],
  data: TItem
) {
  return queryClient.setQueryData(key, data);
}

// Add
export function addQueryDataItem<TItem>(key: string[], item: TItem) {
  return queryClient.setQueryData(key, (items: TItem[]) => [...items, item]);
}

// Delete by Id
export function deleteQueryDataItemById(key: string[], id: string | number) {
  return queryClient.setQueryData(key, (items: (unknown & {id: number})[]) =>
    items.filter((item) => item.id !== id)
  );
}

// Update by Id
export function updateQueryDataItemById<TItem extends {id: number}>(
  key: string[],
  updateItem: TItem
) {
  return queryClient.setQueryData(key, (items: TItem[]) =>
    items.map((existingItem) =>
      existingItem.id === updateItem.id ? updateItem : existingItem
    )
  );
}

/*
|--------------------------------------------------------------------------
| Caching
|--------------------------------------------------------------------------
*/

export const cacheForeverOptions = {
  refetchOnWindowFocus: false,
  staleTime: Infinity,
};

export const cacheForHours = (
  hours = 1,
  refetchOnWindowFocus = true
): {staleTime: number; refetchOnWindowFocus: boolean} => {
  return {
    staleTime: hours * 1000 * 60 * 60, // 1000ms * 60s * 60m
    refetchOnWindowFocus,
  };
};

/*
|--------------------------------------------------------------------------
| Combined Queries
|--------------------------------------------------------------------------
*/

// Returns a facade from multiple <UseQueryResult[]>. The basic abstraction
// can be used to combine related queries that return an array of data.
export function parallelQueryResults({
  queries,
  filterBy,
  sortBy,
}: {
  queries: UseQueryResult[];
  filterBy?: (i) => void;
  sortBy?: (a, b) => number;
}): Partial<UseQueryResult> & {data} {
  const combinedData = (a, q) => (a = a.concat(q.data)) && a;
  const filterEmpty = (j) => j !== undefined;
  let data = queries.reduce(combinedData, []).filter(filterEmpty);
  if (!!filterBy) {
    data = data.filter(filterBy);
  }
  sortBy && data.sort(sortBy);

  return {
    data,
    isFetching: queries.every((r) => r.isFetching),
    isSuccess: queries.every((r) => r.isSuccess),
    refetch: async () =>
      queries
        .map((r) => r.refetch())
        .reduce((a, b) => Promise.all([a, b]).then()),
  };
}

/*
|--------------------------------------------------------------------------
| Query Client
|--------------------------------------------------------------------------
*/

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: defaultQueryFn,
      retry: (retryCount, error: {response: {status: number}}) => {
        if (error?.response?.status === 404) return false;
        if (retryCount >= 3) return false;
        return true;
      },
    },
    mutations: {
      mutationFn: defaultMutationFn,
    },
  },
});

export default queryClient;
