import {DUPLICATE, NEW_SECTION, SECTION_1} from '@components/admin/constants';
import {i18n, k} from '@i18n/translate';

/**
 * Maps text from the database to the user's preferred language.
 * @param value - The text value to be mapped.
 * @param includes - Optional string to check if `value` includes it.
 * @returns The mapped text in the user's language.
 */
export const mapTextToUserLanguage = (
  value: string,
  includes?: {text: string; translationKey: string}
): string => {
  if (includes) {
    return replaceIncludeWithTranslation();
  }
  switch (value) {
    case NEW_SECTION:
      return i18n.t(k.SECTION__NEW);
    case SECTION_1:
      return i18n.t(k.SECTION__NEW);
    default:
      return value;
  }

  /**
   * Replaces the `includes` string with the translated text.
   * @returns The updated text with the translation.
   */
  function replaceIncludeWithTranslation() {
    return value.includes(includes.text)
      ? value.replace(
          `${includes.text}`,
          `${i18n.t(k[includes.translationKey])}`
        )
      : value;
  }
};

/**
 * Helper function to check titles that may contain '- Duplicate' and need translation.
 * @param title - The title to be checked.
 * @returns The updated title if it includes '- Duplicate', otherwise the original title.
 */
export const getTitle = (title: string) => {
  return title?.includes(`- ${DUPLICATE}`)
    ? mapTextToUserLanguage(title, {
        text: DUPLICATE,
        translationKey: 'CTA__DUPLICATE',
      })
    : title;
};

/**
 * Maps text from the user's preferred language to English needed for the backend db.
 * @param text - The text to be mapped.
 * @returns The mapped text in English.
 */
export const mapTextToEnglish = (text: string) => {
  if (text?.includes(`- ${i18n.t(k.CTA__DUPLICATE)}`)) {
    return text.replace(i18n.t(k.CTA__DUPLICATE), DUPLICATE);
  }
  if (text?.includes(i18n.t(k.SECTION__NEW))) {
    return text.replace(i18n.t(k.SECTION__NEW), NEW_SECTION);
  }

  return text;
};
