import {LinkVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: LinkVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLinkVMMockData(args?: Partial<LinkVM>): LinkVM {
  return {
    id: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    url: faker.internet.url(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LinkVMMockData: LinkVM = {
  id: 1,
  title: 'Advanced Web Design',
  url: 'https://this-person-does-not-exist.com/en',
};
