import {PlanItemType} from '@generated/enums';

export enum CategoryPageName {
  TIME = 'time',
  FINANCE = 'finance',
  PROGRAM = 'program',
  CUSTOM_PROGRAM = 'customProgram',
}

export const mapPageNameToPlanType = (
  pageName: CategoryPageName
): PlanItemType => {
  switch (pageName) {
    case CategoryPageName.CUSTOM_PROGRAM:
    case CategoryPageName.PROGRAM:
      return PlanItemType.Program;
    case CategoryPageName.FINANCE:
      return PlanItemType.Finance;
    case CategoryPageName.TIME:
      return PlanItemType.Time;
    default:
      return null;
  }
};

export enum IncentiveAvailabilityString {
  ALL = 'All',
  NOT_AVAILABLE = 'Not Available',
  INCENTIVE_SPECIFIC = 'Initiative Specific',
}

export enum IncentiveAvailability {
  AvailableToAll = 0,
  CertainPrograms = 1,
  CertainInitiatives = 2,
  NotAvailable = 3,
}
