import React from 'react';
import {Container} from './BudgetUptoDisplay.styled';
import {BudgetUptoDisplayViewProps} from './BudgetUptoDisplay.interfaces';
import PersonalLearningBudgetUptoDisplay from '@components/user/pages/userPlan/components/personalLearningBudgetCard/components/PersonalLearningBudgetUptoDisplay';
import {formatCurrency} from '@utils/moneyUtils';
import NoDataImage from '@blocks/NoDataImage';

function BudgetUptoDisplayView({
  exchangeRate,
  financeLearningBuds,
  isCurrencySymbolSimplificationOn,
  isFinanceLearningBudsSuccess,
  personalization,
  user,
}: BudgetUptoDisplayViewProps) {
  return (
    <Container>
      {isFinanceLearningBudsSuccess ? (
        <PersonalLearningBudgetUptoDisplay
          currencyCode={user?.currency}
          isCurrencySymbolSimplificationOn={isCurrencySymbolSimplificationOn}
          primaryBrandColor={personalization?.primaryBrandColor}
          totalAmount={formatCurrency(
            financeLearningBuds?.totalApprovedFunds,
            user?.currency,
            exchangeRate,
            {
              decimal: true,
            }
          )}
        />
      ) : (
        <NoDataImage />
      )}
    </Container>
  );
}

export default BudgetUptoDisplayView;
