import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import NewInitiative from './NewInitiative';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import {
  getAddInitiativeRm,
  getDeleteInitiativesRm,
  getInitiativeDetailsRq,
  getInitiativesRq,
  getUpdateInitiativeRm,
  getUpdateInitiativesStatusRm,
} from '@store/apiEndpoints';
import {useMutation, useQuery} from 'react-query';
import {
  simpleDeleteFn,
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {InitiativePayload} from '@models/serverModels';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import useTreatment from '@hooks/useTreatment';
import {StepsInterface} from '@components/providers/stepsProgress/StepsProgress';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {InitiativeStatus, PlanItemType} from '@generated/enums';
import {
  ConfirmationOptions,
  InitiativeFlowSteps,
  InitiativeFor,
} from '../../store/interface';
import {NewInitiativeHeader} from './components/NewInitiativeHeader';
import {notify} from '@components/user/notifications';
import {Form} from 'antd';
import {SaveChangesModal} from './components/SaveChangesModal';
import {useNavigate} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

export const InitiativeStepTitles = {
  TheBasics: i18n.t(k.GENERIC__THE_BASICS),
  Programs: i18n.t(k.PROGRAM__PLURAL),
  Financial: i18n.t(k.INCENTIVE__FINANCE__PLURAL),
  Time: i18n.t(k.TIME__INCENTIVE__PLURAL),
};

type Props = {
  showDrawer: boolean;
  initiativeId?: number;
  onCompleted: () => void;
  title?: string;
};

export default function NewInitiativeContainer({
  showDrawer,
  initiativeId,
  onCompleted,
  title,
}: Props) {
  const navigate = useNavigate();
  const [theBasicsForm] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [initiative, setInitiative] = useState<InitiativePayload | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [displayPrompt, setDisplayPrompt] = useState(true);
  const [isUserTryingToLeave, setIsUserTryingToLeave] = useState(false);
  const [usersNextDestination, setUsersNextDestination] = useState(null);
  const isTimeIncentivesOn =
    useTreatment(FeatureFlagExperiments.TimeIncentives) ===
    FeatureFlagTreatments.On;
  const stepsArray = isTimeIncentivesOn
    ? [
        InitiativeStepTitles.TheBasics,
        InitiativeStepTitles.Programs,
        InitiativeStepTitles.Time,
        InitiativeStepTitles.Financial,
      ]
    : [
        InitiativeStepTitles.TheBasics,
        InitiativeStepTitles.Programs,
        InitiativeStepTitles.Financial,
      ];
  const steps: StepsInterface[] = stepsArray.map((title: string) => ({title}));

  const isEditingInitiative = !!initiativeId;
  const parsedInitiativeId = initiativeId;
  const initiativeDetailsRq = getInitiativeDetailsRq(parsedInitiativeId);

  const editQuery = useQuery<InitiativePayload | null>(
    initiativeDetailsRq?.queryKey,
    () => simpleQueryFn(initiativeDetailsRq?.path),
    {
      enabled: !!isEditingInitiative && !!parsedInitiativeId,
    }
  );

  const handlePublishInitiative = async () => {
    await SaveChanges(InitiativeStatus.Published);
  };

  const handleUnpublishInitiative = async () => {
    if (!initiative?.id) {
      return;
    }
    await unPublishInitiative.mutateAsync(initiative.id);
  };
  const handleDeleteInitiative = async () => {
    if (!initiative?.id) {
      return;
    }
    await deleteInitiative.mutateAsync(initiative.id);
  };

  // The "onLoad" effect
  useEffect(() => {
    if (editQuery.isSuccess) {
      setInitiative(editQuery.data);
    } else {
      setInitiative(null);
    }
    const audience = editQuery.data?.audience;
    theBasicsForm.setFieldsValue({
      ...editQuery.data,
      initiativeFor:
        audience == null
          ? InitiativeFor.WHOLE_COMPANY
          : InitiativeFor.SPECIFIC_AUDIENCE,
    });

    setIsDirty(false);
    setDisplayPrompt(true);
    setIsUserTryingToLeave(false);
    setCurrentStep(InitiativeFlowSteps.TheBasics);
  }, [showDrawer, editQuery.data]);

  // Mutations / Query

  const publishInitiative = useMutation(
    (payload: InitiativePayload) => {
      const isUpdating = !!payload.id;
      const upsertInitiativeRm = isUpdating
        ? getUpdateInitiativeRm(payload)
        : getAddInitiativeRm(payload);
      return simpleMutationFn<null>(
        upsertInitiativeRm.path,
        upsertInitiativeRm.payload
      );
    },
    {
      onSuccess: async (_, variables) => {
        if (variables?.status === InitiativeStatus.Draft) {
          notify.draftInitiativeChangesSuccess();
        } else {
          notify.publishInitiativeChangesSuccess();
        }
        setDisplayPrompt(false);
        onCompleted?.call(null);
      },
      onError: () => {
        notify.publishInitiativeChangesFailure();
      },
    }
  );

  const deleteInitiative = useMutation(
    (initiativeId: number | string) => {
      const deleteInitiativeRm = getDeleteInitiativesRm(initiativeId);
      return simpleDeleteFn(
        deleteInitiativeRm.path,
        deleteInitiativeRm.payload
      );
    },
    {
      onSuccess: async () => {
        setDisplayPrompt(false);
        await simpleInvalidateExactQueryFn(getInitiativesRq().queryKey);
        onCompleted?.call(null);
      },
      onError: (error) => {
        console.log(
          'something went wrong while deleting the initiative',
          error
        );
      },
    }
  );

  const unPublishInitiative = useMutation(
    (initiativeId: number) => {
      const updateInitiativesStatusRm = getUpdateInitiativesStatusRm({
        id: initiativeId,
        status: InitiativeStatus.Draft,
      });
      return simpleMutationFn<null>(
        updateInitiativesStatusRm.path,
        updateInitiativesStatusRm.payload
      );
    },
    {
      onSuccess: async () => {
        await simpleInvalidateExactQueryFn(getInitiativesRq().queryKey);
        setDisplayPrompt(false);
        onCompleted?.call(null);
      },
      onError: (error) => {
        console.log(
          'something went wrong while un-publishing the initiative',
          error
        );
      },
    }
  );

  const hasTheBasicFormChanged = (
    original: InitiativePayload | null,
    current: InitiativePayload | null
  ) => {
    if ((!original && !!current) || (!!original && !current)) {
      return true;
    }

    if (!current && !original) {
      return false;
    }

    return (
      (original.audience ?? '') !== (current.audience ?? '') ||
      original.purpose !== current.purpose ||
      (original.purposeDescription ?? '') !==
        (current.purposeDescription ?? '') ||
      original.title !== current.title
    );
  };

  const nextButtonClickedForStep = (
    step: InitiativeFlowSteps
  ): InitiativeFlowSteps => {
    switch (step) {
      case InitiativeFlowSteps.TheBasics:
        if (!initiativeId && !theBasicsForm.isFieldsTouched()) {
          // trying to skip first page on a new initiative
          theBasicsForm.validateFields();
          return InitiativeFlowSteps.TheBasics;
        }
        const basicFormIsInValid =
          theBasicsForm
            .getFieldsError()
            .reduce((a, b) => a + b?.errors?.length, 0) > 0;

        if (basicFormIsInValid) {
          return InitiativeFlowSteps.TheBasics;
        }

        const theBasicsData = theBasicsForm?.getFieldsValue();
        if (theBasicsData.initiativeFor === InitiativeFor.WHOLE_COMPANY) {
          theBasicsData.audience = null;
        }

        if (hasTheBasicFormChanged(initiative, theBasicsData)) {
          setIsDirty(true);
        }
        setInitiative({...initiative, ...theBasicsData});
        return InitiativeFlowSteps.Programs;
      case InitiativeFlowSteps.Programs:
        return isTimeIncentivesOn
          ? InitiativeFlowSteps.Time
          : InitiativeFlowSteps.Finance;
      case InitiativeFlowSteps.Time:
        return InitiativeFlowSteps.Finance;
      case InitiativeFlowSteps.Finance:
        return InitiativeFlowSteps.End;
      default:
        return InitiativeFlowSteps.End;
    }
  };

  const handleUpdateSelectedInitiativeItem = (
    toBeAdded: boolean,
    id: number,
    itemType: PlanItemType
  ): void => {
    switch (itemType) {
      case PlanItemType.Program:
        toBeAdded
          ? setInitiative({
              ...initiative,
              programIds: [...(initiative?.programIds ?? []), id],
            })
          : setInitiative({
              ...initiative,
              programIds: [...initiative?.programIds.filter((x) => x !== id)],
            });
        break;
      case PlanItemType.Time:
        toBeAdded
          ? setInitiative({
              ...initiative,
              timeIncentiveIds: [...(initiative?.timeIncentiveIds ?? []), id],
            })
          : setInitiative({
              ...initiative,
              timeIncentiveIds: [
                ...initiative?.timeIncentiveIds.filter((x) => x !== id),
              ],
            });
        break;
      case PlanItemType.Finance:
        toBeAdded
          ? setInitiative({
              ...initiative,
              financeIncentiveIds: [
                ...(initiative?.financeIncentiveIds ?? []),
                id,
              ],
            })
          : setInitiative({
              ...initiative,
              financeIncentiveIds: [
                ...initiative?.financeIncentiveIds.filter((x) => x !== id),
              ],
            });
        break;
      default:
        break;
    }
    setIsDirty(true);
  };

  const SaveChanges = async (status: InitiativeStatus) => {
    const {timeIncentiveIds, financeIncentiveIds, programIds} = initiative;
    let {...theBasics} = initiative;

    if (currentStep == InitiativeFlowSteps.TheBasics) {
      // if this is the first step, trust the current form values
      theBasics = theBasicsForm.getFieldsValue();
    }

    const payload: InitiativePayload = {
      title: theBasics.title,
      audience: theBasics.audience,
      programIds: programIds,
      timeIncentiveIds: timeIncentiveIds,
      financeIncentiveIds: financeIncentiveIds,
      status: status,
      purpose: theBasics.purpose,
      purposeDescription: theBasics?.purposeDescription ?? '',
    };
    const isUpdatingInitiative = Boolean(editQuery.data);
    if (isUpdatingInitiative) {
      payload.id = editQuery.data.id;
    }
    publishInitiative.mutate(payload);
  };

  const handleConfirmationPromptClose = (
    isOkay: boolean,
    confirmationOptions?: ConfirmationOptions | undefined
  ) => {
    if (isOkay) {
      if (!confirmationOptions) {
        onCompleted?.call(null);
      } else {
        if (!!usersNextDestination) {
          // This was done because calling history.push didn't work after (or before) the onCompleted call.
          // This is because this component is hidden/destroyed in this call.
          setTimeout(() => navigate(usersNextDestination), 500);
        }
        switch (confirmationOptions) {
          case ConfirmationOptions.DISCARD_CHANGES:
            onCompleted?.call(null);
            break;
          case ConfirmationOptions.SAVE_CHANGES:
            SaveChanges(InitiativeStatus.Draft);
            break;
          default:
            onCompleted?.call(null);
        }
      }
    }
    setUsersNextDestination(null);
    setIsUserTryingToLeave(false);
  };

  const handleDrawerClose = () => {
    if (isDirty) {
      setDisplayPrompt(true);
      setIsUserTryingToLeave(true);
    } else {
      setDisplayPrompt(false);
      setIsUserTryingToLeave(true);
    }
  };

  const handleNextStepClicked = () => {
    const newStep = nextButtonClickedForStep(currentStep);
    setCurrentStep(newStep);
  };

  const handleUserNavigationRequest = (path: string) => {
    setIsUserTryingToLeave(true);
    setUsersNextDestination(path);
  };

  const getHeader = (isDirty: boolean) => (
    <NewInitiativeHeader
      onNextStepClicked={handleNextStepClicked}
      onPublishedClicked={handlePublishInitiative}
      onUnpublishedClicked={handleUnpublishInitiative}
      onDeleteInitiativeClicked={handleDeleteInitiative}
      currentStep={currentStep}
      isNewInitiative={!initiative?.id}
      steps={steps}
      isDirty={isDirty}
      initiativeStatus={initiative?.status}
    />
  );

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {(!editQuery.isLoading && editQuery.data) || !parsedInitiativeId ? (
        <LearnInPageDrawer
          title={
            !!initiative?.id
              ? i18n.t(k.INITIATIVE__EDIT)
              : i18n.t(k.INITIATIVE__CREATE)
          }
          visible={showDrawer}
          headerContent={getHeader(isDirty)}
          onClose={handleDrawerClose}>
          <NewInitiative
            initialValue={initiative}
            currentStep={currentStep}
            basicForm={theBasicsForm}
            isNewInitiative={!initiative?.id}
            onUpdateSelectedInitiativeItem={handleUpdateSelectedInitiativeItem}
            onStepChangeRequested={setCurrentStep}
            changesAreUnsaved={isDirty}
            onBasicsFormDirty={() => setIsDirty(true)}
            onUserWantsToNavigateToPathRequest={handleUserNavigationRequest}
            steps={steps}
          />
        </LearnInPageDrawer>
      ) : (
        <LoadingSpinner />
      )}
      <SaveChangesModal
        isAboutToLeave={isUserTryingToLeave}
        onPromptClose={handleConfirmationPromptClose}
        shouldShowPrompt={displayPrompt}
        promptOptions={[
          {
            label: i18n.t(k.GENERIC__SAVE_TO_DRAFT),
            value: ConfirmationOptions.SAVE_CHANGES,
          },
          {
            label: i18n.t(k.CTA__DISCARD_CHANGES),
            value: ConfirmationOptions.DISCARD_CHANGES,
          },
        ]}
        title={i18n.t(k.INITIATIVE__UNSAVED_ITEMS)}
      />
    </div>
  );
}
