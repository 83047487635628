import {AttachmentType} from './../enums';
import {BaseAttachmentPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: BaseAttachmentPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getBaseAttachmentPayloadMockData(
  args?: Partial<BaseAttachmentPayload>
): BaseAttachmentPayload {
  return {
    chunkNumber: faker.datatype.number(),
    description: faker.lorem.lines(),
    file: undefined,
    fileName: faker.name.fullName(),
    totalChunks: faker.datatype.number(),
    type: AttachmentType.File,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const BaseAttachmentPayloadMockData: BaseAttachmentPayload = {
  chunkNumber: 123,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  file: undefined,
  fileName: 'Bobby Johnson',
  totalChunks: 123,
  type: AttachmentType.File,
};
