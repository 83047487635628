import {AcademyReportsParticipantsTableContainerProps} from './AcademyReportsParticipantsTable.interfaces';
import AcademyReportsParticipantsTableView from './AcademyReportsParticipantsTable.view';
import {useAcademyOverviewReportQuery} from '@generated/hooks';
import {useParams, Route, Routes, useNavigate} from 'react-router-dom';
import UserDrillDown from '@components/user/pages/academy/UserDrillDown/UserDrillDown.container';
import {i18n, k} from '@i18n/translate';
import {AcademyReportsPageTabs, DrawerRoutes} from '@utils/ClientPaths';
import {AcademyReportEmployeesVM} from '@generated/interfaces';
import useLocalSearch from '@hooks/useLocalSearch';
import NudgeEmailPreview, {Feature} from '@blocks/NudgeEmailPreview';
import useNudgeEmail from '@blocks/NudgeEmailPreview/useNudgeEmail';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {CustomizableLearningCategory} from '@generated/enums';
import BulkNudgeDrawer from '@blocks/BulkNudgeDrawer';

function AcademyReportsParticipantsTableContainer({
  basePath,
}: AcademyReportsParticipantsTableContainerProps) {
  const {academyId} = useParams();
  const navigate = useNavigate();
  const {isFeatureFlagOn} = useFeatureFlags();
  const {data: academyOverviewReport} = useAcademyOverviewReportQuery({
    academyId: parseInt(academyId),
  });

  const {
    filteredData: filteredAcademyOverviewReport,
    searchFilter,
    setSearchFilter,
    getNoDataTableProps,
  } = useLocalSearch<AcademyReportEmployeesVM>(
    academyOverviewReport,
    'employeeName'
  );

  const {drawerProps, onOpenNudgeEmailPreview, nudgeEmailsEnabled} =
    useNudgeEmail({
      feature: Feature.Academy,
      route: {
        basePath,
        pathSuffix: AcademyReportsPageTabs.Participants,
      },
      participants: filteredAcademyOverviewReport,
    });

  const onClickRow = (row: AcademyReportEmployeesVM) => {
    if (row.userCompanyId) {
      navigate(
        [basePath, AcademyReportsPageTabs.Participants, row.userCompanyId].join(
          '/'
        )
      );
    }
  };

  return (
    <>
      <AcademyReportsParticipantsTableView
        dataSource={filteredAcademyOverviewReport}
        onClickRow={onClickRow}
        searchInputValue={searchFilter}
        onChangeSearchInput={setSearchFilter}
        noDataTableProps={getNoDataTableProps()}
        nudgeEmailsEnabled={nudgeEmailsEnabled}
        onOpenNudgeEmailPreview={onOpenNudgeEmailPreview}
        canShowEmployeeEmail={isFeatureFlagOn.ShowEmployeeEmail}
        onClickBulkNudgeButton={() =>
          navigate(
            [
              basePath,
              AcademyReportsPageTabs.Participants,
              DrawerRoutes.BulkNudge,
            ].join('/')
          )
        }
        showBulkNudgeButton={isFeatureFlagOn.BulkNudgeAllParticipants}
      />
      <Routes>
        <Route
          path="participants/:userId/nudge"
          element={<NudgeEmailPreview {...drawerProps} />}
        />
        <Route
          path="participants/:userId"
          element={
            <UserDrillDown
              title={i18n.t(k.REPORT__DETAILS)}
              onClose={() => {
                navigate(
                  [basePath, AcademyReportsPageTabs.Participants].join('/')
                );
              }}
            />
          }
        />
        <Route
          path={[
            AcademyReportsPageTabs.Participants,
            DrawerRoutes.BulkNudge,
          ].join('/')}
          element={
            <BulkNudgeDrawer
              customizableLearningCategory={
                CustomizableLearningCategory.Academy
              }
              visible={true}
              onClose={() =>
                navigate(
                  [basePath, AcademyReportsPageTabs.Participants].join('/')
                )
              }
              name={drawerProps.programOrAcademyName}
              feature={Feature.Academy}
              participantsCount={academyOverviewReport?.length}
              bypassParticipantsList
            />
          }
        />
      </Routes>
    </>
  );
}

export default AcademyReportsParticipantsTableContainer;
