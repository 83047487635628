import MaskedCreditCard from '@blocks/maskedCreditCard/MaskedCreditCard';
import NoDataImage from '@blocks/NoDataImage';
import {CreditCardApprovalStatus} from '@generated/enums';
import {COLORS} from '@utils/constants';
import React from 'react';
import PersonalLearningBudgetPlaceholderCreditCard from '../components/PersonalLearningBudgetPlaceholderCreditCard';
import {BudgetCreditCardContainerProps} from './BudgetCreditCard.interfaces';
import {useCardPaymentInfoQuery} from '@generated/hooks';

function BudgetCreditCardContainer({
  exchangeRate,
  financeLearningBuds,
  isFinanceLearningBudsError,
  isFinanceLearningBudsSuccess,
  personalization,
  user,
}: BudgetCreditCardContainerProps) {
  const {
    data: cardPaymentInfo,
    isLoading: isCardPaymentInfoLoading,
    isSuccess: isCardPaymentInfoSuccess,
    isError: isCardPaymentInfoError,
    error: cardPaymentInfoError,
  } = useCardPaymentInfoQuery(
    {
      cardId: financeLearningBuds?.creditCard?.cardId,
    },
    {
      enabled: !!financeLearningBuds?.creditCard?.cardId,
    }
  );

  if (
    isFinanceLearningBudsError ||
    (isFinanceLearningBudsSuccess &&
      financeLearningBuds.creditCardApprovalStatus ==
        CreditCardApprovalStatus.NoCreditCardExists)
  ) {
    return <NoDataImage />;
  } else if (isFinanceLearningBudsSuccess) {
    switch (financeLearningBuds.creditCardApprovalStatus) {
      case CreditCardApprovalStatus.CreditCardCreated:
        return (
          <MaskedCreditCard
            approvalRequired={financeLearningBuds.programPreApprovalRequired}
            balance={parseFloat(financeLearningBuds?.balance.toFixed(2))}
            cardId={financeLearningBuds?.creditCard?.cardId?.toString()}
            cardPaymentInfo={cardPaymentInfo}
            cardPaymentInfoError={cardPaymentInfoError}
            currencyCode={user?.currency}
            isCardPaymentInfoError={isCardPaymentInfoError}
            isCardPaymentInfoLoading={isCardPaymentInfoLoading}
            isCardPaymentInfoSuccess={isCardPaymentInfoSuccess}
            last4={financeLearningBuds?.creditCard?.last4}
            virtualCardColor={
              personalization?.virtualCardColor || COLORS.Blue50
            }
          />
        );
      case CreditCardApprovalStatus.CreditCardFailed:
      case CreditCardApprovalStatus.CreditCardRequiresApproval:
      default:
        return (
          <PersonalLearningBudgetPlaceholderCreditCard
            currencyCode={user?.currency}
            exchangeRate={exchangeRate}
            financeAmountType={financeLearningBuds.financeAmountType}
            hasCardCreationError={
              financeLearningBuds.creditCardApprovalStatus ==
              CreditCardApprovalStatus.CreditCardFailed
            }
            totalAmount={parseInt(
              financeLearningBuds?.limitOnRequestedFunds?.toFixed(2)
            )}
          />
        );
    }
  }
}

export default BudgetCreditCardContainer;
