import {useEffect, useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {
  getAddEventRm,
  getFinanceIncentiveRq,
  getUpdateEventRm,
  getUserCalendarEventsRq,
} from '@store/apiEndpoints';
import {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import {
  NewUserEvent,
  UpdateUserEvent,
  UserEventVM,
  UserPlanItemCardVM,
} from '@models/serverModels';
import ManageUserPlan from '@components/user/pages/userPlan/components/manageUserPlan/ManageUserPlan';
import moment, {Moment} from 'moment';
import {DATE_FORMAT} from '@utils/timeUtils';
import {useConnectCalendarMutation} from '@generated/hooks';

export default function ManageUserPlanWrapper() {
  const currentDate = moment();
  const [startDate, setStartDate] = useState<Moment>(
    moment(currentDate).startOf('week')
  );
  const [endDate, setEndDate] = useState<Moment>(
    moment(currentDate).endOf('week')
  );
  const [formattedStartDate, setFormattedStartDate] = useState<string>(
    startDate.format(DATE_FORMAT.ISO_DATE)
  );
  const [formattedEndDate, setFormattedEndDate] = useState<string>(
    endDate.format(DATE_FORMAT.ISO_DATE)
  );
  const [view, setView] = useState<string>('timeGridWeek');

  const [calendarStartDate, setCalendarStartDate] = useState<
    string | undefined
  >();
  const [calendarEndDate, setCalendarEndDate] = useState<string | undefined>();
  const [calendarView, setCalendarView] = useState<string | undefined>();
  const [userPlanItem, setUserPlanItem] = useState<number | string | null>(
    null
  );

  const addEventMutation = useMutation(
    (payload: NewUserEvent) => {
      const addEvent = getAddEventRm(payload);
      return simpleMutationFn<number>(addEvent.path, addEvent.payload);
    },
    {
      onError: notify.addEventError,
      onSuccess: notify.addEventSuccessful,
    }
  );

  const updateEventMutation = useMutation(
    (payload: UpdateUserEvent) => {
      const updateEventRm = getUpdateEventRm(payload);
      return simpleMutationFn<number>(
        updateEventRm.path,
        updateEventRm.payload
      );
    },
    {
      onError: notify.updateEventError,
      onSuccess: notify.updateEventSuccessful,
    }
  );

  const userCalendarEventsRq = getUserCalendarEventsRq(
    startDate.format(DATE_FORMAT.ISO_DATE),
    endDate.format(DATE_FORMAT.ISO_DATE)
  );
  const userCalendarEventsQuery = useQuery<UserEventVM[]>(
    userCalendarEventsRq.queryKey,
    () => simpleQueryFn(userCalendarEventsRq.path),
    {
      enabled: !!startDate && !!endDate,
      placeholderData: () => [],
      onSuccess: () => {
        if (view) {
          setCalendarStartDate(formattedStartDate);
          setCalendarEndDate(formattedEndDate);
          setCalendarView(view);
        }
      },
    }
  );

  const financeIncentiveRq = getFinanceIncentiveRq(userPlanItem);
  const financeIncentiveQuery = useQuery<UserPlanItemCardVM>(
    financeIncentiveRq.queryKey,
    () => simpleQueryFn(financeIncentiveRq.path),
    {
      enabled: !!userPlanItem,
    }
  );

  const connectCalendar = useConnectCalendarMutation({
    onSuccess: (applicationUrl: string) => {
      // Redirect to the URL built up by our API
      window.location.href = applicationUrl;
    },
  });

  const fetchUserEvents = (
    _startDate: Moment,
    _endDate: Moment,
    _view: string
  ) => {
    setStartDate(_startDate);
    setEndDate(_endDate);
    setView(_view);
    // user events should automatically fetch when these update
  };

  const fetchUserItem = (userPlanItemId: number | string) => {
    setUserPlanItem(userPlanItemId);
    // should trigger query
  };

  useEffect(() => {
    if (startDate) {
      setFormattedStartDate(startDate.format(DATE_FORMAT.ISO_DATE));
      setFormattedEndDate(endDate.format(DATE_FORMAT.ISO_DATE));
    }
  }, [startDate, endDate]);

  return (
    <ManageUserPlan
      addEvent={addEventMutation.mutateAsync}
      updateEvent={updateEventMutation.mutateAsync}
      fetchUserEvents={fetchUserEvents}
      FETCH_USER_EVENTS_DATA={userCalendarEventsQuery.data}
      FETCH_USER_EVENTS_LOADING={userCalendarEventsQuery.isLoading}
      CALENDAR_START_DATE={calendarStartDate}
      CALENDAR_END_DATE={calendarEndDate}
      CALENDAR_VIEW={calendarView}
      fetchUserItem={fetchUserItem}
      FETCH_USER_ITEM_DATA_DATA={financeIncentiveQuery.data}
      FETCH_USER_ITEM_DATA_LOADING={financeIncentiveQuery.isLoading}
      connectCalendar={connectCalendar.mutate}
    />
  );
}
