import {ConnectCalendarModal} from './ConnectCalendarModal';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useConnectCalendarMutation} from '@generated/hooks';
import {CalendarCallbackRedirect, CalendarProvider} from '@generated/enums';

interface IConnectCalendarModalProps {
  visible: boolean;
  id?: number;
  title?: string;
  description?: string;
  redirectOption: CalendarCallbackRedirect;
  handleCloseCalendarModal: () => void;
}

function ConnectCalendarModalContainer({
  visible,
  id,
  title,
  description,
  redirectOption,
  handleCloseCalendarModal,
}: IConnectCalendarModalProps) {
  const {isFeatureFlagOn} = useFeatureFlags();

  const connectCalendar = useConnectCalendarMutation({
    onSuccess: (applicationUrl: string) => {
      // Redirect to the URL built up by our API
      window.location.href = applicationUrl;
    },
  });

  const handleClickConnectCalendar = (
    calendar: CalendarProvider,
    id?: number
  ) => {
    connectCalendar.mutate({
      payload: {
        id,
        calendar,
        redirectOption,
      },
    });
  };

  return (
    <ConnectCalendarModal
      visible={visible}
      id={id}
      title={title}
      description={description}
      isGoogleCalendarOn={isFeatureFlagOn.GoogleCalendar}
      onClickConnectCalendar={handleClickConnectCalendar}
      onCloseCalendarModal={handleCloseCalendarModal}
    />
  );
}

export default ConnectCalendarModalContainer;
