import {i18n, k} from '@i18n/translate';
import moment from 'moment';
import LearnInTable, {
  renderProgressBar,
} from '@components/reusable/LearnInTable';
import LearnInTag, {StatusTag, TagStyles} from '@blocks/LearnInTag';
import {
  TeamInsightsDetailVM,
  UserAcademyInsightVM,
  UserItemStatusVM,
} from '@models/serverModels';
import {DisplayStatus, DisplayStatusWarningLevel} from '@generated/enums';
import {DATE_FORMAT} from '@utils/timeUtils';
import {COLUMN_WIDTHS} from '../../utils';
import {TeamInsightEmployeeDetailVm} from '@generated/interfaces';

export const AcademiesTable = ({
  data,
  showReportStatusTag,
}: {
  data: TeamInsightEmployeeDetailVm;
  showReportStatusTag: boolean;
}) => {
  return (
    <>
      <LearnInTable
        columns={[
          {
            title: i18n.t(k.DATE__STARTED),
            width: COLUMN_WIDTHS.date,
            dataIndex: 'dateStarted',
            key: 'dateStarted',
            render: (dateStarted: string) =>
              moment(dateStarted).format(DATE_FORMAT.MONTH_DAY_YEAR),
            sorter: (a: UserAcademyInsightVM, b: UserAcademyInsightVM) =>
              a.dateStarted.localeCompare(b.dateStarted),
          },
          {
            title: i18n.t(k.ACADEMY),
            dataIndex: 'name',
            key: 'name',
            sorter: (a: UserAcademyInsightVM, b: UserAcademyInsightVM) =>
              a.name.localeCompare(b.name),
          },
          {
            title: i18n.t(k.STATUS__STATUS),
            dataIndex: 'status',
            key: 'status',
            width: COLUMN_WIDTHS.status,
            render: ({
              displayStatus,
              displayStatusWarningLevel,
              completedPercent,
              completedOn,
              skippedOn,
            }) => {
              if (showReportStatusTag) {
                return (
                  <StatusTag
                    displayStatus={displayStatus}
                    displayStatusWarningLevel={displayStatusWarningLevel}
                    completedPercent={completedPercent}
                    completedOn={completedOn}
                    skippedOn={skippedOn}
                  />
                );
              }
              const style =
                DisplayStatusWarningLevel.Valid === displayStatusWarningLevel
                  ? TagStyles.Green
                  : DisplayStatusWarningLevel.Warning ===
                    displayStatusWarningLevel
                  ? TagStyles.Yellow
                  : TagStyles.Red;
              return completedPercent !== null &&
                completedPercent < 100 &&
                completedPercent > 0 ? (
                renderProgressBar(completedPercent)
              ) : (
                <LearnInTag
                  label={DisplayStatus[displayStatus]}
                  tagStyle={style}
                />
              );
            },
            sorter: (a: UserAcademyInsightVM, b: UserAcademyInsightVM) =>
              a.status.toString().localeCompare(b.status.toString()),
          },
        ]}
        dataSource={data?.academies || []}
      />
    </>
  );
};
