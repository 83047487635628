import {SyntheticEvent} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {
  HEADER_HEIGHT,
  NAV_PANEL_WIDTH,
} from '@components/reusable/LearnInPageDrawer';
import {AcademyVM} from '@generated/interfaces';
import {COLORS} from '@utils/constants';
import {formatUrl} from '@utils/stringUtils';
import styled, {DefaultTheme} from 'styled-components';
import {Breakpoints} from '@utils/responsiveUtils';
import ProviderLogo from '@blocks/ProviderLogo';
import {Skeleton, Divider} from 'antd';
import {CommentOutlined} from '@ant-design/icons';
import {useGetDefaultAcademyTheme} from '@utils/academyBranding';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {convertHexToHSL, getAcademyBrandingBorderColor} from '@utils/color';
import {trackChannelLinkClick} from '@utils/analytics';
import {AcademyIntegrationChatChannelResponse as ChatChannel} from '@generated/interfaces';
import AcademySidebarSkillTags from '../AcademySkillTags/AcademySidebarSkillTags';
import {theme as defaultTheme} from '@utils/color';
import {ClickToUnenrollButton} from '@components/ClickToUnEnroll/ClickToUnenrollButton';
import {ClickToUnenrollModal} from '@components/ClickToUnEnroll/ClickToUnenrollModal';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const SidePanelContainer = styled.div`
  align-items: center;
  background: ${(props: DefaultTheme) =>
    props.theme.backgroundColor} !important;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 0;
  padding-top: 4px;
  position: absolute;
  top: ${(props: DefaultTheme) => props.topAlign ?? HEADER_HEIGHT}px;
  width: ${NAV_PANEL_WIDTH}px;
  border-right: 1px solid
    ${(props: DefaultTheme) =>
      getAcademyBrandingBorderColor(props.theme.itemDefaultTextNavColor)};
  margin-top: ${({isPreview}) => (isPreview ? '24px' : 'unset')};
  @media (max-width: ${Breakpoints.Desktop}px) {
    height: ${({isCollapsed}) =>
      isCollapsed ? `${HEADER_HEIGHT}px` : `calc(100% - ${HEADER_HEIGHT}px)`};
    overflow: hidden;
    width: 100%;
    z-index: 30;
    position: static;
    margin-top: ${({isPreview}) => (isPreview ? '32px' : 'unset')};
  }
`;

const SubText = styled.div`
  color: ${(props: DefaultTheme) => props.theme.itemDefaultTextNavColor};
  font-family: Roboto, serif;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1rem;
  margin-bottom: 2px;
  text-align: left;
`;

const HeaderInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const InfoColumn = styled.div`
  align-items: center;
  display: flex;
`;

const Title = styled.div`
  color: ${(props: DefaultTheme) => props.theme.itemDefaultTextNavColor};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.75rem;
  @media (max-width: ${Breakpoints.Tablet}px) {
    font-size: 1.25rem;
  }
`;
const LogoAndNameContainer = styled.div`
  display: flex;
  margin: 28px auto 0 23px;
  @media (max-width: ${Breakpoints.Desktop}px) {
    ${({isCollapsed}) => isCollapsed && 'height: 100%'};
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

const AcademyIconContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin-right: 15px;
  min-width: 48px;
  overflow: hidden;
`;
const HighestLevelContainer = styled.div`
  border-top: 1px solid
    ${(props: DefaultTheme) =>
      getAcademyBrandingBorderColor(props.theme.itemDefaultTextNavColor)};
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  @media (max-width: ${Breakpoints.Desktop}px) {
    display: none;
  }
`;

const YourLevel = styled.div`
  color: ${(props: DefaultTheme) => props.theme.itemDefaultTextNavColor};
  font-family: Roboto, serif;
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 1rem;
  margin-bottom: 9px;
  text-align: left;
`;

const Level = styled.div`
  color: ${(props: DefaultTheme) => props.theme.itemDefaultTextNavColor};
  font-family: Roboto, serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.25rem;
  text-align: left;
`;

const MainDetails = styled.div`
  margin-top: ${({isPreview}) => (isPreview ? '48px' : 'unset')};
  margin-left: 272px;
  @media (max-width: ${Breakpoints.Desktop}px) {
    margin-left: 0;
  }
`;

const MobileMenu = styled(LearnInButton)`
  @media (min-width: ${Breakpoints.Desktop}px) {
    display: none;
  }
`;

const NavContainer = styled.nav`
  text-align: center;
  width: 99%;
  display: flex;
  flex-direction: column;
  @media (max-width: ${Breakpoints.Desktop}px) {
    height: 100%;
  }
`;

const NavItem = styled.button<{isSelected: boolean}>`
  background: ${(props: DefaultTheme) =>
    props.isSelected ? props.theme.itemSelectedBackgroundNavColor : ''};
  color: ${(props: DefaultTheme) =>
    props.isSelected
      ? props.theme.itemSelectedTextNavColor
      : props.theme.itemDefaultTextNavColor};
  font-family: Roboto, serif;
  font-size: 1rem;
  font-weight: 400;
  height: 40px;
  letter-spacing: 0;
  line-height: 1.25rem;
  padding: 12px 24px;
  text-align: left;
  &:hover {
    background: ${(props: DefaultTheme) =>
      props.isSelected ? '' : props.theme.itemHoverBackgroundNavColor};
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

const ChatButtonContainer = styled.div`
  margin: 0 16px;
`;

const ChatLink = styled.a`
  display: flex;
  padding: 6px 16px;
  font-size: 1rem;
  border-radius: 100px;
  font-weight: 400;
  width: 100%;
  border: 1px solid
    ${(props: DefaultTheme) => props?.theme?.itemDefaultTextNavColor};
  background: ${(props: DefaultTheme) =>
    props?.theme?.itemHoverBackgroundNavColor};
  color: ${(props: DefaultTheme) =>
    props?.theme?.itemDefaultTextNavColor} !important;
  text-decoration: none;
  &:hover {
    background: ${(props: DefaultTheme) =>
      props?.theme?.itemSelectedBackgroundNavColor};
    border: 1px solid
      ${(props: DefaultTheme) => props?.theme?.itemSelectedBackgroundNavColor};
  }
  &:focus {
    outline: 2px solid
      ${(props: DefaultTheme) => props?.theme?.itemDefaultTextNavColor};
  }
`;

const IconContainer = styled.div`
  background: ${COLORS.Purple200};
  margin: 0 auto;
  border-radius: 100%;
  width: 104px;
  height: 104px;
  text-align: center;
  padding-top: 30px;
`;

const ModalContentContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 27px;
`;

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: ${COLORS.Neutral950};
`;

const ModalDescription = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Neutral600};
`;

const SideBarFooter = styled.div`
  width: 100%;
`;

export interface DrawerNavItem {
  onClick: (event: SyntheticEvent) => void;
  label: React.ReactNode;
  isSelected: boolean;
  key: string | number;
  ariaLabel: string;
}

export interface AcademyDrawerSideBarProps {
  chatChannel?: ChatChannel;
  isChatChannelLoading?: boolean;
  academy?: AcademyVM;
  children: React.ReactNode;
  currentLevelTitle?: string;
  handleCollapse: () => void;
  isCollapsed: boolean;
  isLoading: boolean;
  navItems: DrawerNavItem[];
  subTitle?: React.ReactNode;
  topAlign?: number;
  isBranding: boolean;
  title: string;
  canShowSkills: boolean;
  isActivePlanItem: boolean;
}

export enum AcademyDrawerTabs {
  Content = 'content',
  Reports = 'reports',
  Projects = 'projects',
  People = 'people',
}

function addHasViewedAcademyChatModal(academyId: number) {
  const seenAcademyModals = JSON.parse(
    localStorage.getItem('seenAcademyModals') || '[]'
  );
  if (!seenAcademyModals.includes(academyId)) {
    seenAcademyModals.push(academyId);
    localStorage.setItem(
      'seenAcademyModals',
      JSON.stringify(seenAcademyModals)
    );
  }
}

function hasViewedAcademyChatModal(academyId: number) {
  const seenAcademyModals = JSON.parse(
    localStorage.getItem('seenAcademyModals') || '[]'
  );
  return seenAcademyModals.includes(academyId);
}

export function AcademyDrawerSideBar({
  academy,
  chatChannel,
  children,
  currentLevelTitle,
  handleCollapse,
  isCollapsed,
  isLoading,
  isChatChannelLoading,
  navItems,
  subTitle,
  topAlign,
  isBranding,
  title,
  canShowSkills,
  isActivePlanItem,
}: AcademyDrawerSideBarProps) {
  useGetDefaultAcademyTheme(academy);
  const [showModal, setShowModal] = React.useState(false);
  const [unenrollModalVisible, setUnenrollModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (
      !isChatChannelLoading &&
      chatChannel &&
      !hasViewedAcademyChatModal(chatChannel.academyId) &&
      !isBranding
    ) {
      setShowModal(true);
    }
  }, [chatChannel, isChatChannelLoading, isBranding]);

  const handleClickToUnenroll = () => {
    setUnenrollModalVisible(!unenrollModalVisible);
  };

  return (
    <>
      {isLoading ? (
        <SidePanelContainer>
          <Skeleton active style={{padding: '16px'}} paragraph={{rows: 5}} />
        </SidePanelContainer>
      ) : (
        <SidePanelContainer
          isCollapsed={isCollapsed}
          topAlign={topAlign}
          isPreview={!isActivePlanItem}>
          <LogoAndNameContainer isCollapsed={isCollapsed}>
            <AcademyIconContainer>
              <ProviderLogo
                border={false}
                size={50}
                provider={''}
                providerLogo={undefined}
                showCompanyLogo={true}
              />
            </AcademyIconContainer>
            <HeaderInfoContainer>
              <InfoColumn>
                <div>
                  <SubText>{title}</SubText>
                  <Title>{subTitle}</Title>
                </div>
              </InfoColumn>
            </HeaderInfoContainer>
            <MobileMenu
              chevron
              isChevronOpen={!isCollapsed}
              onClick={handleCollapse}
              style={{background: 'transparent'}}
              color={academy?.branding?.itemDefaultTextNavColor}
              tag={ButtonTags.SecondaryRemovedBorder}
            />
          </LogoAndNameContainer>

          <NavContainer>
            {!isChatChannelLoading && !!chatChannel && (
              <ChatButtonContainer>
                <ChatLink
                  href={formatUrl(chatChannel?.channelLink)}
                  onClick={() => {
                    return trackChannelLinkClick(chatChannel?.academyId);
                  }}
                  target="_blank"
                  rel="noopener noreferrer">
                  <CommentOutlined
                    style={{marginRight: '8px', fontSize: '1.5rem'}}
                  />
                  {i18n.t(k.ACADEMY__CHAT_APPLICATION_COLLABORATE)}
                </ChatLink>
                <Divider
                  style={{
                    borderColor:
                      academy?.branding?.itemDefaultTextNavColor ??
                      COLORS.Neutral300,
                  }}
                />
              </ChatButtonContainer>
            )}
            {navItems.map((navItem) => (
              <NavItem
                aria-label={navItem.ariaLabel}
                key={navItem.key}
                onClick={navItem.onClick}
                isSelected={navItem.isSelected}>
                {navItem.label}
              </NavItem>
            ))}
          </NavContainer>
          <SideBarFooter>
            {canShowSkills && academy?.academySkillVMs?.length ? (
              <AcademySidebarSkillTags
                backgroundColor={convertHexToHSL(
                  academy?.branding?.itemDefaultTextNavColor ||
                    defaultTheme.itemDefaultTextNavColor,
                  0.1
                )}
                color={
                  academy?.branding?.itemDefaultTextNavColor ||
                  defaultTheme.itemDefaultTextNavColor
                }
                skills={
                  academy?.academySkillVMs?.map((skill) => skill.title) || []
                }
              />
            ) : null}
            <HighestLevelContainer>
              <YourLevel>{i18n.t(k.SECTION__CURRENT)}</YourLevel>
              <Level>{currentLevelTitle}</Level>
            </HighestLevelContainer>
            {!academy.restrictedAccess && (
              <ClickToUnenrollButton
                label={i18n.t(k.UNENROLL__ACADEMY)}
                onClick={handleClickToUnenroll}
              />
            )}
          </SideBarFooter>
        </SidePanelContainer>
      )}
      <MainDetails isPreview={!isActivePlanItem}>{children}</MainDetails>
      <LearnInModal
        zIndex={1001}
        tag={ModalTags.MoneyIncentive}
        onCancel={() => {
          setShowModal(false);
          addHasViewedAcademyChatModal(chatChannel.academyId);
        }}
        onOk={() => {
          trackChannelLinkClick(chatChannel?.academyId);
          window.open(
            formatUrl(chatChannel?.channelLink),
            '_blank',
            'noopener, noreferrer'
          );
        }}
        visible={showModal}
        width={400}
        bodyStyle={{
          borderRadius: '10px',
        }}
        cancelButtonChild={<span>{i18n.t(k.CTA__CANCEL)}</span>}
        okButtonChild={i18n.t(k.ACADEMY__CHAT_APPLICATION_MODAL_OPEN_CHANNEL)}
        ariaLabel={i18n.t(k.ACADEMY__CHAT_APPLICATION_MODAL_TITLE)}>
        <IconContainer>
          <CommentOutlined
            style={{color: COLORS.Purple800, fontSize: '2.875rem'}}
          />
        </IconContainer>
        <ModalContentContainer>
          <ModalTitle>
            {i18n.t(k.ACADEMY__CHAT_APPLICATION_MODAL_TITLE)}
          </ModalTitle>
          <ModalDescription>
            {i18n.t(k.ACADEMY__CHAT_APPLICATION_MODAL_DESCRIPTION)}
          </ModalDescription>
        </ModalContentContainer>
      </LearnInModal>
      {unenrollModalVisible && (
        <ClickToUnenrollModal
          academyId={academy?.id}
          academyName={academy?.name}
          onClose={handleClickToUnenroll}
        />
      )}
    </>
  );
}
