import {
  ApprovalType,
  CurrencyCode,
  LearningResourceType,
  UserRequestType,
} from './../enums';
import {UserRequestApproval} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserRequestApproval

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserRequestApprovalMockData(
  args?: Partial<UserRequestApproval>
): UserRequestApproval {
  return {
    amount: faker.datatype.number(),
    approvalRequestId: faker.datatype.number(),
    approvalType: ApprovalType.PLAN_ITEM,
    balanceOverLimit: faker.datatype.number(), // optional
    costCenter: faker.word.noun(), // optional
    id: faker.datatype.number(),
    isAdditionalApproval: faker.datatype.boolean(),
    licenseCurrency: CurrencyCode.USD, // optional
    licenseId: faker.datatype.number(), // optional
    managerId: faker.datatype.number(), // optional
    managerName: faker.name.fullName(),
    programItemId: faker.datatype.number(), // optional
    programTitle: faker.company.catchPhrase(), // optional
    provider: faker.word.noun(), // optional
    requestedById: faker.datatype.number(),
    requestedByName: faker.name.fullName(),
    requestedDate: String(faker.date.past()),
    resourceType: LearningResourceType.Book, // optional
    userRequestType: UserRequestType.Funds,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserRequestApprovalMockData: UserRequestApproval = {
  amount: 5,
  approvalRequestId: 1,
  approvalType: ApprovalType.PLAN_ITEM,
  balanceOverLimit: 123, // optional
  costCenter: 'string', // optional
  id: 1,
  isAdditionalApproval: true,
  licenseCurrency: CurrencyCode.USD, // optional
  licenseId: 1, // optional
  managerId: 1, // optional
  managerName: 'Bobby Johnson',
  programItemId: 1, // optional
  programTitle: 'Advanced Web Design', // optional
  provider: 'string', // optional
  requestedById: 1,
  requestedByName: 'Bobby Johnson',
  requestedDate: '2023-03-29T22:17:19.738072',
  resourceType: LearningResourceType.Book, // optional
  userRequestType: UserRequestType.Funds,
};
