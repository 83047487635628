import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {CopyOutlined} from '@ant-design/icons';
import './ClickToJoinShareButton.scss';
import {COLORS} from '@utils/constants';

interface ClickToJoinShareButtonProps {
  label: string;
  onClick: () => void;
}

export function ClickToJoinShareButton({
  label,
  onClick,
}: ClickToJoinShareButtonProps) {
  return (
    <LearnInButton
      data-testid="share-program-button"
      tag={ButtonTags.Secondary}
      onClick={onClick}
      border="0"
      hoverBorder="0">
      <CopyOutlined
        className="ctj-share-button-icon"
        style={{fill: COLORS.Neutral600, color: COLORS.Neutral600}}
      />
      {label}
    </LearnInButton>
  );
}
