import {PlanItemType} from './../enums';
import {FeaturedDashboardItemVM} from './../interfaces';
import {
  ApprovalConfigMockData,
  getApprovalConfigMockData,
} from './ApprovalConfig.mock';
import {
  GetStartedItemVMMockData,
  getGetStartedItemVMMockData,
} from './GetStartedItemVM.mock';
import {faker} from '@faker-js/faker';

// Interface: FeaturedDashboardItemVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getFeaturedDashboardItemVMMockData(
  args?: Partial<FeaturedDashboardItemVM>
): FeaturedDashboardItemVM {
  return {
    ...getGetStartedItemVMMockData(),
    approvalConfig: getApprovalConfigMockData(),
    description: faker.lorem.lines(),
    featured: faker.datatype.boolean(),
    itemType: PlanItemType.Program,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const FeaturedDashboardItemVMMockData: FeaturedDashboardItemVM = {
  ...GetStartedItemVMMockData,
  approvalConfig: ApprovalConfigMockData,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  featured: true,
  itemType: PlanItemType.Program,
};
