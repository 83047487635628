import {i18n, k} from '@i18n/translate';
import {List, Card, Row, Col, Button} from 'antd';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import {
  ProgramProgressItemStatus,
  ProgramProgressItemStatusName,
} from '../../store/interface';
import {LearnInButton} from '../../../../../../../reusable/Button/Button.style';
import {ButtonTags} from '../../../../../../../reusable/Button/ButtonEnums';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import NoDataImage from '@blocks/NoDataImage';
import {ProgramProgressVM} from '@models/serverModels';
import {DATE_FORMAT} from '@utils/timeUtils';

interface Props {
  programProgressData: ProgramProgressVM[];
  handleShowProgramProgressUpdatesDrawer: (item: any) => void;
  handleChangeCurrentProgressItem: (item: any) => void;
  handleSetIsReadOnlyCardVisible: (item: any) => void;
}

export default function ProgramProgressCard(props: Props) {
  const {
    programProgressData,
    handleShowProgramProgressUpdatesDrawer,
    handleChangeCurrentProgressItem,
    handleSetIsReadOnlyCardVisible,
  } = props;
  return (
    <Card
      role="article"
      aria-labelledby="program-progress-card-title"
      bordered={false}
      bodyStyle={{padding: '20px 0px 15px 0px'}}
      style={{borderRadius: '12px', padding: 0}}>
      <Row
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '13px',
        }}>
        <Col xs={24}>
          <Title
            level={4}
            style={{marginTop: '.5em'}}
            id="program-progress-card-title">
            {i18n.t(k.PROGRAM__PROGRESS)}
          </Title>
        </Col>
      </Row>
      <hr style={{opacity: 0.2, margin: 0}} />
      {programProgressData && programProgressData.length ? (
        <List
          itemLayout="horizontal"
          dataSource={programProgressData}
          renderItem={(item: any) => (
            <List.Item>
              <Row
                style={{
                  width: '100%',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
                align="middle">
                <Col xs={6}>
                  <Row>
                    <Text strong style={{fontSize: '1rem'}}>
                      {moment(item.date).format(
                        DATE_FORMAT.MONTH_DAY_YEAR_PADDED
                      )}
                    </Text>
                  </Row>
                </Col>
                <Col xs={8}>
                  <Row>
                    <Text strong style={{fontSize: '1rem'}}>
                      {item.title}
                    </Text>
                  </Row>
                </Col>
                <Col xs={4}>
                  <Row>
                    {item.status === ProgramProgressItemStatus.Upcoming && (
                      <LearnInTag
                        label={i18n
                          .t(k[ProgramProgressItemStatusName.Upcoming])
                          .toLocaleUpperCase()}
                      />
                    )}
                    {item.status === ProgramProgressItemStatus.Due && (
                      <LearnInTag
                        tagStyle={TagStyles.Yellow}
                        label={i18n
                          .t(k[ProgramProgressItemStatusName.Due])
                          .toLocaleUpperCase()}
                      />
                    )}
                    {item.status === ProgramProgressItemStatus.Late && (
                      <LearnInTag
                        label={i18n
                          .t(k[ProgramProgressItemStatusName.Late])
                          .toLocaleUpperCase()}
                        tagStyle={TagStyles.Red}
                      />
                    )}
                    {item.status === ProgramProgressItemStatus.Submitted && (
                      <LearnInTag
                        label={i18n
                          .t(k[ProgramProgressItemStatusName.Submitted])
                          .toLocaleUpperCase()}
                        tagStyle={TagStyles.Green}
                      />
                    )}
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row justify="end">
                    {item.status === ProgramProgressItemStatus.Submitted ? (
                      <Button
                        className="btn-action"
                        shape="round"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleChangeCurrentProgressItem(item);
                          handleSetIsReadOnlyCardVisible(true);
                        }}>
                        {i18n.t(k.CTA__VIEW).toLocaleUpperCase()}
                      </Button>
                    ) : (
                      <LearnInButton
                        tag={ButtonTags.Secondary}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleChangeCurrentProgressItem(item);
                          handleShowProgramProgressUpdatesDrawer(true);
                        }}>
                        {i18n.t(k.CTA__CREATE)}
                      </LearnInButton>
                    )}
                  </Row>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      ) : (
        <NoDataImage />
      )}
    </Card>
  );
}
