import {UpdateCustomProgramContentMessageRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {UpdateCustomProgramContentMessageRequestSchema} from '../zodSchemas/UpdateCustomProgramContentMessageRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramMessagesController.cs

/*
|--------------------------------------------------------------------------
|  {customProgramId}/sections/{sectionId}/contents/{contentId}/discussions/{discussionId}/messages/{messageId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation} from '@generated/hooks';
 *
 *  const customProgramsSectionsContentsDiscussionsMessagesPut = useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramsSectionsContentsDiscussionsMessagesPut.mutate({
 *    payload: {
 *      ...UpdateCustomProgramContentMessageRequest
 *    },
 *    pathVars: {
 *      contentId,
 *      customProgramId,
 *      discussionId,
 *      messageId,
 *      sectionId
 *    }
 *  })
 *
 */

export const useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation =
  mutationHookFactory<
    UpdateCustomProgramContentMessageRequest, // payload
    {
      contentId: number;
      customProgramId: number;
      discussionId: string;
      messageId: string;
      sectionId: number;
    }, // path variables
    null
  >(
    'useCustomProgramsSectionsContentsDiscussionsMessagesPutMutation',
    'put',
    ({customProgramId, sectionId, contentId, discussionId, messageId}) =>
      `custom-programs/${customProgramId}/sections/${sectionId}/contents/${contentId}/discussions/${discussionId}/messages/${messageId}`,
    {payloadSchema: UpdateCustomProgramContentMessageRequestSchema}
  );
