import {useState, useEffect, MouseEvent} from 'react';
import {i18n, k} from '@i18n/translate';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {SectionCompleteToggle} from '@blocks/CustomProgramUserDrawer/SectionCompleteToggle';
import {
  ANIMATION_SPEED,
  Checkmark,
  CompleteButton,
  Container,
  SectionCompleteContainer,
} from './ContentCompleteButton.styles';

export interface IContentCompleteButtonProps {
  completed: boolean;
  onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  title: string;
  disabled: boolean;
  isVerifiedCompleted?: boolean;
}

export function ContentCompleteButton({
  completed,
  onClick,
  title,
  disabled,
  isVerifiedCompleted,
}: IContentCompleteButtonProps) {
  const [animateUncompleteButton, setAnimateUncompleteButton] = useState(false);
  /*
   * There are two pieces of state here in order to describe a
   * subtle disconnect between the `completed` state of the button,
   * and the content of the button. We change the content of the
   * button to a completed state slightly before updating the completed
   * state.
   */
  const [localComplete, setLocalComplete] = useState(completed);
  const [showCompletedText, setShowCompletedText] = useState(completed);

  const getButtonText = () => {
    if (isVerifiedCompleted) {
      return i18n.t(k.STATUS__VERIFIED_COMPLETED);
    } else if (!showCompletedText) {
      return i18n.t(k.CTA__MARK_AS_COMPLETE);
    } else {
      return i18n.t(k.STATUS__COMPLETED);
    }
  };

  const ariaLabelText = showCompletedText
    ? k.CTA__MARK_AS_NOT_COMPLETED_ITEM__FORMAT
    : k.CTA__MARK_AS_COMPLETE_ITEM__FORMAT;

  // keep completed state in sync with local completed state
  useEffect(() => {
    if (completed !== localComplete) {
      setLocalComplete(completed);
      setShowCompletedText(completed);
    }
  }, [localComplete, completed]);

  return (
    <Container>
      <CompleteButton
        disabled={disabled}
        aria-label={i18n.t(ariaLabelText, {
          item: title,
        })}
        animate={!localComplete && animateUncompleteButton}
        tag={ButtonTags.Secondary}
        completed={localComplete}
        onClick={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          if (localComplete) {
            setLocalComplete(false);
            setShowCompletedText(false);
            onClick(e);
            return;
          }
          setAnimateUncompleteButton(true);
          /*
           * Two setTimeouts are running so we can change the button content
           * during the animation. This way we don't have a flash of incomplete
           * state UI at the end of the animation.
           */
          setTimeout(() => {
            setShowCompletedText(true);
          }, 1700 * ANIMATION_SPEED);
          setTimeout(() => {
            setAnimateUncompleteButton(false);
            setLocalComplete(!localComplete);
            onClick(e);
          }, 2000 * ANIMATION_SPEED);
        }}>
        {!showCompletedText ? (
          <Checkmark aria-hidden="true" />
        ) : (
          <SectionCompleteContainer>
            <SectionCompleteToggle checked />
          </SectionCompleteContainer>
        )}
        {getButtonText()}
      </CompleteButton>
    </Container>
  );
}
