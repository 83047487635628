import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import CohortsTable from './CohortsTable';
import {useMutation} from 'react-query';
import {simpleDeleteFn, simpleMutationFn} from '@store/queryClient';
import {getDeleteCustomProgramCohortRm} from '@store/apiEndpoints/customProgram/mutations';
import {notify} from '@components/user/notifications';
import CohortAdminPageDrawer from '@components/admin/pages/programs/customPrograms/cohorts/CohortAdminPageDrawer.container';
import {Divider, Modal} from 'antd';
import NoDataText from '@blocks/NoDataText';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {useCustomProgramAdminDetailVMsQuery} from '@hooks/apiEndpoints/customProgram/queries';
import {
  CustomProgramScreens,
  adminCustomProgramScreenRoute,
} from '@utils/ClientPaths';
import {useNavigate} from 'react-router-dom';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  useCohortsQuery,
  useDuplicateCustomProgramMutation,
} from '@generated/hooks';
import useLanguagePreference from '@hooks/useLanguagePreference';

const {confirm} = Modal;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const TableHeader = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 24px;
  colors: ${COLORS.Neutral950};
`;

/*
|--------------------------------------------------------------------------
| Add Cohort Button
|--------------------------------------------------------------------------
*/

const AddCohortButton = ({onClick}: {onClick: () => void}) => {
  return (
    <LearnInButton tag={ButtonTags.Secondary} onClick={onClick}>
      {i18n.t(k.COHORT__ADD)}
    </LearnInButton>
  );
};

/*
|--------------------------------------------------------------------------
| Empty Table Component
|--------------------------------------------------------------------------
*/

export const NoCohortsNotice = ({
  onClickAddCohort,
}: {
  onClickAddCohort: () => void;
}) => {
  return (
    <NoDataText
      style={{height: 'fit-content'}}
      header={i18n.t(k.COHORT__TABLE__EMPTY__TITLE)}
      subHeader={
        <>
          {i18n.t(k.COHORT__TABLE__EMPTY__DESCRIPTION)}`
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '16px',
            }}>
            <AddCohortButton onClick={onClickAddCohort} />
          </div>
        </>
      }
    />
  );
};

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CohortsTableContainerProps {
  customProgramId: number;
}

function CohortsTableContainer({customProgramId}: CohortsTableContainerProps) {
  const navigate = useNavigate();
  const [cohortForEditId, setCohortForEditId] = useState<number | undefined>();
  const [showAddEditCohortDrawer, setShowAddEditCohortDrawer] = useState(false);

  const {isFeatureFlagOn} = useFeatureFlags();

  // Get Cohorts Table Data
  const {
    data: cohorts,
    invalidateExact: invalidateCohorts,
    isSuccess: isCohortsSuccess,
  } = useCohortsQuery({
    parentCustomProgramId: customProgramId,
  });

  // Used to refresh Custom Program Table Data
  const customProgramsQuery = useCustomProgramAdminDetailVMsQuery({
    enabled: false,
  });

  const deleteCohortMutation = useMutation(
    (id: number) => {
      const deleteCohortRm = getDeleteCustomProgramCohortRm(id);
      return simpleDeleteFn<null>(deleteCohortRm.path, deleteCohortRm.payload);
    },
    {
      onSuccess: () => {
        notify.deleteCohortSuccess();
        invalidateCohorts();
        customProgramsQuery.invalidateExact();
      },
      onError: () => {
        notify.deleteCohortError();
      },
    }
  );

  const duplicateCustomProgram = useDuplicateCustomProgramMutation({
    onSuccess: async (newCohortId) => {
      await invalidateCohorts();
      handleClickEdit(newCohortId);
    },
    onError: () => {
      notify.duplicateCustomProgramError();
    },
  });

  const languagePreference = useLanguagePreference();

  const handleClickDelete = (id: number) => {
    confirm({
      icon: false,
      content: (
        <>
          <p>{i18n.t(k.COHORT__DELETE__CONFIRM__DESCRIPTION)} </p>
          <Divider
            style={{
              position: 'absolute',
              left: 0,
              bottom: '2.75rem',
            }}
          />
        </>
      ),
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.COHORT__DELETE),
      okType: 'danger',
      bodyStyle: {borderRadius: '25px'},
      title: i18n.t(k.COHORT__DELETE),
      okButtonProps: {type: 'primary', shape: 'round'},
      cancelButtonProps: {type: 'primary', shape: 'round', ghost: true},
      onOk() {
        deleteCohortMutation.mutateAsync(id);
      },
    });
  };

  const handleCloseAdminDrawer = () => {
    setShowAddEditCohortDrawer(false);
    setCohortForEditId(undefined);
  };

  const handleClickEdit = (id: number) => {
    setCohortForEditId(id);
    setShowAddEditCohortDrawer(true);
  };

  const navigateToCohortPreview = (
    id: number,
    screen: CustomProgramScreens
  ) => {
    navigate(adminCustomProgramScreenRoute(id, screen));
  };

  const showNoCohortsNotice = isCohortsSuccess && !cohorts?.length;
  const showCohortsTable = isCohortsSuccess && !!cohorts?.length;
  return (
    <>
      {/* Heading */}
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <TableHeader>{i18n.t(k.COHORT__PLURAL)}</TableHeader>
        <div>
          {showCohortsTable && (
            <AddCohortButton onClick={() => setShowAddEditCohortDrawer(true)} />
          )}
        </div>
      </div>

      {/* Cohorts Table, only shown when there are cohorts */}
      {showCohortsTable && (
        <CohortsTable
          dataSource={cohorts || []}
          onClickDelete={handleClickDelete}
          onClickEdit={handleClickEdit}
          onClickTitle={(cohortId) =>
            navigateToCohortPreview(cohortId, CustomProgramScreens.Curriculum)
          }
          onClickViewReports={(cohortId) =>
            navigateToCohortPreview(cohortId, CustomProgramScreens.Reports)
          }
          onClickViewCohort={(cohortId) =>
            navigateToCohortPreview(cohortId, CustomProgramScreens.Curriculum)
          }
          onClickDuplicate={(customProgramId) =>
            duplicateCustomProgram.mutate({
              pathVars: {
                customProgramId,
              },
            })
          }
        />
      )}

      {/* Notice For When No Cohorts Exist */}
      {showNoCohortsNotice && (
        <NoCohortsNotice
          onClickAddCohort={() => setShowAddEditCohortDrawer(true)}
        />
      )}

      {/* Cohort Add/Edit Drawer */}
      {showAddEditCohortDrawer && (
        <CohortAdminPageDrawer
          visible={showAddEditCohortDrawer}
          onClose={handleCloseAdminDrawer}
          parentCustomProgramId={customProgramId}
          cohortId={cohortForEditId}
          setCohortForEditIdOnCreate={setCohortForEditId}
        />
      )}
    </>
  );
}

export default CohortsTableContainer;
