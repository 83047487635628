// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/PermissionRole.cs

enum PermissionRole {
  AcademiesUser,
  BenefitsUser,
  DevelopUser,
  Admin,
  MarketplaceUser,
}

export default PermissionRole;
