import {
  UpdateAcademyStepMessageRequest,
  UpdateAcademyStepRequest,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {UpdateAcademyStepRequestSchema} from '../zodSchemas/UpdateAcademyStepRequestSchema.schema';
import {UpdateAcademyStepMessageRequestSchema} from '../zodSchemas/UpdateAcademyStepMessageRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/academy-levels/{academyLevelId}/academy-steps/{academyStepId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesAcademyLevelsAcademyStepsMutation} from '@generated/hooks';
 *
 *  const academiesAcademyLevelsAcademySteps = useAcademiesAcademyLevelsAcademyStepsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesAcademyLevelsAcademySteps.mutate({
 *    payload: {
 *      ...UpdateAcademyStepRequest
 *    },
 *    pathVars: {
 *      academyId,
 *      academyLevelId,
 *      academyStepId
 *    }
 *  })
 *
 */

export const useAcademiesAcademyLevelsAcademyStepsMutation =
  mutationHookFactory<
    UpdateAcademyStepRequest, // payload
    {
      academyId: number | string;
      academyLevelId: number | string;
      academyStepId: number | string;
    }, // path variables
    null
  >(
    'useAcademiesAcademyLevelsAcademyStepsMutation',
    'put',
    ({academyId, academyLevelId, academyStepId}) =>
      `academies/${academyId}/academy-levels/${academyLevelId}/academy-steps/${academyStepId}`,
    {payloadSchema: UpdateAcademyStepRequestSchema}
  );

/*
|--------------------------------------------------------------------------
|  {academyId}/academy-levels/{academyLevelId}/academy-steps/{academyStepId}/discussions/{discussionId}/messages/{messageId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation} from '@generated/hooks';
 *
 *  const academiesAcademyLevelsAcademyStepsDiscussionsMessagesPut = useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesAcademyLevelsAcademyStepsDiscussionsMessagesPut.mutate({
 *    payload: {
 *      ...UpdateAcademyStepMessageRequest
 *    },
 *    pathVars: {
 *      academyId,
 *      academyLevelId,
 *      academyStepId,
 *      discussionId,
 *      messageId
 *    }
 *  })
 *
 */

export const useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation =
  mutationHookFactory<
    UpdateAcademyStepMessageRequest, // payload
    {
      academyId: number | string;
      academyLevelId: number | string;
      academyStepId: number | string;
      discussionId: string;
      messageId: string;
    }, // path variables
    null
  >(
    'useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesPutMutation',
    'put',
    ({academyId, academyLevelId, academyStepId, discussionId, messageId}) =>
      `academies/${academyId}/academy-levels/${academyLevelId}/academy-steps/${academyStepId}/discussions/${discussionId}/messages/${messageId}`,
    {payloadSchema: UpdateAcademyStepMessageRequestSchema}
  );
