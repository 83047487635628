import {CurrencyCode} from './../enums';
import {IExchangeRateBasicInfo} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: IExchangeRateBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIExchangeRateBasicInfoMockData(
  args?: Partial<IExchangeRateBasicInfo>
): IExchangeRateBasicInfo {
  return {
    exchangeRate: faker.datatype.number(),
    id: faker.datatype.number(),
    toCurrencyCode: CurrencyCode.USD,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IExchangeRateBasicInfoMockData: IExchangeRateBasicInfo = {
  exchangeRate: 123,
  id: 1,
  toCurrencyCode: CurrencyCode.USD,
};
