import {usePublishedAcademies} from '@components/user/pages/academy/academy.queries';
import {useAcademiesUsersPostMutation} from '@generated/hooks';
import {UserPaths, userAcademyRoute} from '@utils/ClientPaths';
import {getUrlParameter, removeParameter} from '@utils/urlUtils';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

/**
 * Custom hook that encapsulates the logic related to
 * joining an academy after following a URL with a joinId
 *
 * @param academyId Academy ID
 * @param userCompanyId User Company ID
 * @param options
 */
export default function useClickToJoinAcademy(
  academyId: string,
  userCompanyId: number
) {
  const navigate = useNavigate();

  const {invalidateExact: invalidateAcademiesList} =
    usePublishedAcademies(false);

  const joinWithJoinIdMutation = useAcademiesUsersPostMutation({
    onSuccess: () => {
      invalidateAcademiesList();
      removeParameter(window.location.href, 'joinId');
      navigate(userAcademyRoute(academyId), {replace: true});
    },
    onError: () => {
      navigate(UserPaths.Academies);
    },
  });

  useEffect(() => {
    const joinId = getUrlParameter(location.href, 'joinId');
    if (academyId && joinId && userCompanyId) {
      joinWithJoinIdMutation.mutate({
        pathVars: {academyId: parseInt(academyId), userCompanyId},
        payload: {joinId},
      });
    }
  }, [academyId, userCompanyId]);
}
