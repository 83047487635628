import {ProgressSkill} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ProgressSkill

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgressSkillMockData(
  args?: Partial<ProgressSkill>
): ProgressSkill {
  return {
    id: faker.datatype.number(), // optional
    name: faker.name.fullName(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgressSkillMockData: ProgressSkill = {
  id: 1, // optional
  name: 'Bobby Johnson',
};
