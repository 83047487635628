import {AcademyStepType} from './../enums';
import {IAcademyStepBasicInfo} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: IAcademyStepBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIAcademyStepBasicInfoMockData(
  args?: Partial<IAcademyStepBasicInfo>
): IAcademyStepBasicInfo {
  return {
    academyLevelId: faker.datatype.number(),
    allowPeerVisibility: faker.datatype.boolean(),
    allowSkip: faker.datatype.boolean(),
    id: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    type: AcademyStepType.MarketplaceProgram,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IAcademyStepBasicInfoMockData: IAcademyStepBasicInfo = {
  academyLevelId: 1,
  allowPeerVisibility: true,
  allowSkip: true,
  id: 1,
  title: 'Advanced Web Design',
  type: AcademyStepType.MarketplaceProgram,
};
