export enum UserProgressSummary {
  FeelingGreat,
  MakingProgress,
  TooBusy,
  Stressed,
  NeedHelp,
}

export interface ProgramProgressItem {
  date: Date;
  week: number;
  status: ProgramProgressItemStatus;
  id: number;
  title: string;
  content: string;
  rating: number;
  summary?: any;
  skills?: any;
  submitted: boolean;
}

export interface ProgramProgressUpdatesDrawerProps {
  visible: boolean;
  currentProgressItem: ProgramProgressItem;
  currentPlanId: string;
  onOk: () => void;
  onCancel: () => void;
  programProgressUpdateSubmit: (id: any, payload: any) => void;
  skills: any[];
}

export enum ProgramProgressItemStatus {
  Upcoming,
  Due,
  Late,
  Submitted,
}

export enum ProgramProgressItemStatusName {
  Upcoming = 'DATE__UPCOMING',
  Due = 'DATE__DUE',
  Late = 'STATUS__LATE',
  Submitted = 'STATUS__SUBMITTED',
}
