import React from 'react';
import {Container, HeaderContainer} from './CustomProgramCohortTable.styled';
import {CustomProgramCohortTableViewProps} from './CustomProgramCohortTable.interfaces';
import {useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import LearnInTable, {
  basicSorter,
  dateTimeSorter,
  renderDateTime,
  renderProgressBar,
  ColumnAlignment,
  TooltipCol,
  stringSorter,
} from '@components/reusable/LearnInTable';
import {TableCellButtonLink} from '@components/reusable/TableCellButtonLink/TableCellButtonLink';
import Search from '@components/reusable/Search';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import BulkNudgeButton from '@blocks/BulkNudgeButton.view';

function CustomProgramCohortTableView({
  dataSource,
  onClickRow,
  onChangeSearchInput,
  searchInputValue,
  noDataTableProps,
  canShowNudgeColumn,
  onOpenNudgeEmailPreview,
  showEmailColumn,
  showBulkNudgeButton,
  onClickBulkNudgeButton,
}: CustomProgramCohortTableViewProps) {
  const columns = useMemo(() => {
    return [
      showEmailColumn
        ? TooltipCol({
            title: i18n.t(k.EMPLOYEE__NAME),
            tooltipDataIndex: 'email',
            dataIndex: 'employeeName',
            renderData: (text) => (
              <TableCellButtonLink>{text}</TableCellButtonLink>
            ),
          })
        : {
            title: i18n.t(k.EMPLOYEE__NAME),
            render: (text) => <TableCellButtonLink>{text}</TableCellButtonLink>,
            dataIndex: 'employeeName',
            key: 'employeeName',
            sorter: stringSorter('employeeName'),
          },
      ,
      {
        title: i18n.t(k.PROGRESS),
        dataIndex: 'progressPercentage',
        key: 'progressPercentage',
        sorter: basicSorter('progressPercentage'),
        render: renderProgressBar,
      },
      {
        title: i18n.t(k.PROGRAM__START__NOUN),
        dataIndex: 'startDate',
        key: 'startDate',
        render: renderDateTime(),
        sorter: dateTimeSorter('startDate'),
      },
      {
        title: i18n.t(k.GENERIC__LAST_ACTIVE),
        dataIndex: 'lastActiveDate',
        key: 'lastActiveDate',
        render: renderDateTime(),
        sorter: dateTimeSorter('lastActiveDate'),
      },
      canShowNudgeColumn && {
        title: i18n.t(k.GENERIC__LAST_NUDGE_SENT),
        dataIndex: 'lastCustomProgramNudgeSent',
        key: 'lastCustomProgramNudgeSent',
        sorter: dateTimeSorter('lastCustomProgramNudgeSent'),
        render: (date: string) => {
          return date ? renderDateTime()(date) : '-';
        },
      },
      canShowNudgeColumn && {
        title: '',
        key: null,
        align: ColumnAlignment.Right,
        render: (data) => {
          return (
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
              <LearnInButton
                tag={ButtonTags.SecondaryRemovedBorder}
                style={{fontWeight: 400}}
                onClick={(ev) => {
                  ev.stopPropagation();
                  onOpenNudgeEmailPreview({
                    employeeName: data.employeeName,
                    userCompanyId: data.employeeId,
                  });
                }}>
                {i18n.t(k.CTA__SEND_NUDGE)}
              </LearnInButton>
            </div>
          );
        },
      },
    ].filter(Boolean);
  }, []);

  const searchNameText = i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
    item: i18n.t(k.GENERIC__NAME),
  });

  return (
    <Container>
      <HeaderContainer>
        <Search
          id="reports__participant-table"
          style={{width: '270px', margin: '24px 0'}}
          onChange={onChangeSearchInput}
          value={searchInputValue}
          placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {item: null})}
          label={searchNameText}
          buttonAriaLabel={searchNameText}
        />
        {showBulkNudgeButton && (
          <BulkNudgeButton
            onClick={onClickBulkNudgeButton}
            style={{height: 38, margin: 'auto 0'}}
          />
        )}
      </HeaderContainer>
      <LearnInTable
        hasContainer={true}
        columns={columns}
        dataSource={dataSource}
        onClickRow={onClickRow}
        noDataStyle={noDataTableProps?.noDataStyle}
        noDataHeader={noDataTableProps?.noDataHeader}
        noDataSubheader={noDataTableProps?.noDataSubheader}
      />
    </Container>
  );
}

export default CustomProgramCohortTableView;
