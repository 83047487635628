import React from 'react';
import {Checkbox} from 'antd';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {pxToRem} from '@utils/styleMixins';

export const TableHeader = styled.section`
  display: flex;
  justify-content: space-between;

  font-size: ${pxToRem(12, 16)};
  line-height: ${pxToRem(16, 16)};
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${COLORS.Neutral200};
  width: 100%;
  margin-bottom: 20px;
`;

export const SettingOptionContainer = styled.section`
  display: flex;
  align-items: center;
`;

export const SettingOptionTitle = styled.h3`
  font-size: ${pxToRem(16, 16)};
  font-weight: 500;
  line-height: ${pxToRem(20, 16)};
`;

export const SettingOptionDescription = styled.p`
  color: ${COLORS.Neutral600};
  font-size: ${pxToRem(12, 16)};
  line-height: ${pxToRem(16, 16)};
  margin-bottom: 0;
`;

export const Cell = styled.div<{fill: boolean}>`
  flex-shrink: 0;
  ${(props) => (props.fill ? 'flex-grow: 1;' : '')}
`;

export const EditPreviewButton: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = styled.button`
  color: ${COLORS.Blue800};
  font-size: ${pxToRem(16, 16)};
  line-height: ${pxToRem(20, 16)};
`;

export const CheckboxOption: typeof Checkbox = styled(Checkbox)`
  padding: 8px;
  margin-left: 16px;
`;

export * from '../../Setup.styled';
