import {useState} from 'react';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {CustomProgramAgendaVM} from '@models/serverModels';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import {
  DATE_FORMAT,
  parseToLocalMoment,
  utcStringIsInThePast,
} from '@utils/timeUtils';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {Tabs} from 'antd';
import AgendaDaySection from './AgendaDaySection/AgendaDaySection.container';
import EmptyDataText from '@blocks/NoDataText';
import {dateTimeSorter} from '@components/reusable/LearnInTable';
import {EmptyBlockUntilTrue} from '@components/reusable/EmptyBlockUntilTrue';
import {SIDE_NAV_WIDTH} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';

const {TabPane} = Tabs;

export enum AgendaTab {
  Upcoming = 'Upcoming',
  Past = 'Past',
}

const AgendaScreenContainer = styled.div`
  padding: 0 16px 72px;
  margin-left: ${() => SIDE_NAV_WIDTH}px;
  margin-bottom: 80px;
  width: 90%;
  @media (max-width: ${Breakpoints.Tablet}px) {
    margin-left: 0;
  }
`;

const AgendaSectionsContainer = styled.div`
  max-width: 907px;
  margin: 60px auto auto;
`;

export interface IAgendaScreenProps {
  agendas: CustomProgramAgendaVM[] | undefined;
  customProgramId: number;
  isActivePlanItem: boolean;
}

export default function AgendaScreen({
  agendas,
  customProgramId,
  isActivePlanItem,
}: IAgendaScreenProps) {
  const [tab, setTab] = useState<AgendaTab>(AgendaTab.Upcoming);
  const [selectedCardId, setSelectedCardId] = useState<number | undefined>();
  const datePropertyName = 'startDateUtc';
  const upcomingAgenda = agendas
    ?.filter((x) => parseToLocalMoment(x.startDateUtc)?.isSameOrAfter())
    .sort(dateTimeSorter(datePropertyName))
    .reverse();
  const upcomingAgendaGroupByDay = groupBy(upcomingAgenda, (x) =>
    moment(x.startDateUtc).format(DATE_FORMAT.SORTING_ONLY)
  );

  const pastAgenda = agendas
    ?.filter((x) => utcStringIsInThePast(x.startDateUtc))
    .sort(dateTimeSorter(datePropertyName))
    .reverse();

  const pastAgendaGroupByDay = groupBy(pastAgenda, (x) =>
    moment(x.startDateUtc).format(DATE_FORMAT.SORTING_ONLY)
  );

  if (!agendas) {
    <EmptyDataText
      header={i18n.t(k.AGENDA__EMPTY__TITLE)}
      subHeader={i18n.t(k.AGENDA__EMPTY__DESCRIPTION)}
    />;
  }

  return (
    <AgendaScreenContainer>
      <LearnInTabs
        tabBarSticky
        onChange={(key: AgendaTab) => {
          setTab(key);
        }}
        activeKey={tab}>
        <TabPane tab={i18n.t(k.DATE__UPCOMING)} key={AgendaTab.Upcoming}>
          <EmptyBlockUntilTrue
            style={{height: '90vh'}}
            emptyHeader={i18n.t(k.AGENDA__NO_UPCOMING_TITLE)}
            emptyBody={i18n.t(k.AGENDA__NO_UPCOMING_DESCRIPTION)}
            condition={
              !!upcomingAgendaGroupByDay &&
              !!Object.entries(upcomingAgendaGroupByDay).length
            }>
            <AgendaSectionsContainer>
              {sortBy(
                Object.entries(upcomingAgendaGroupByDay),
                (a) => a[0]
              ).map((agendasGroup) => (
                <AgendaDaySection
                  isActivePlanItem={isActivePlanItem}
                  key={agendasGroup[0]}
                  customProgramId={customProgramId}
                  onCardSelected={setSelectedCardId}
                  selectedCardId={selectedCardId}
                  agendas={agendasGroup[1] as CustomProgramAgendaVM[]}
                />
              ))}
            </AgendaSectionsContainer>
          </EmptyBlockUntilTrue>
        </TabPane>
        <TabPane tab={i18n.t(k.DATE__PAST)} key={AgendaTab.Past}>
          <EmptyBlockUntilTrue
            style={{height: '90vh'}}
            emptyHeader={i18n.t(k.AGENDA__PAST_TITLE)}
            emptyBody={i18n.t(k.AGENDA__PAST_DESCRIPTION)}
            condition={
              !!pastAgendaGroupByDay &&
              !!Object.entries(pastAgendaGroupByDay).length
            }>
            <AgendaSectionsContainer>
              {sortBy(Object.entries(pastAgendaGroupByDay), (a) => a[0]).map(
                (agendasGroup) => (
                  <AgendaDaySection
                    isActivePlanItem={isActivePlanItem}
                    key={agendasGroup[0]}
                    customProgramId={customProgramId}
                    onCardSelected={(id) => setSelectedCardId(id)}
                    selectedCardId={selectedCardId}
                    agendas={agendasGroup[1] as CustomProgramAgendaVM[]}
                  />
                )
              )}
            </AgendaSectionsContainer>
          </EmptyBlockUntilTrue>
        </TabPane>
      </LearnInTabs>
    </AgendaScreenContainer>
  );
}
