import {COLORS} from '@utils/constants';
import styled, {useTheme} from 'styled-components';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {InputLabel} from '@components/reusable/LearnInForm';
import {Form} from 'antd';
import {getAlphanumericString} from '@utils/color';

const NameInput = styled(LearnInInput)`
  font-size: 0.875rem;
  .ant-input-group-addon {
    background: none;
    border: none;
    padding-right: 0;
  }
  .ant-input-prefix {
    margin: 0;
  }
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ColorInput = styled.input.attrs(({backgroundColor, name}) => ({
  color: COLORS.Black,
  name: name,
  type: 'color',
  value: backgroundColor,
}))`
  border: 0;
  border-radius: 4px;
  background-color: ${({backgroundColor}) => backgroundColor};
  width: 26px;
  height: 26px;

  &::-webkit-color-swatch {
    border: none;
  }
`;

export interface ColorInputFieldProps {
  inputLabel: string;
  name: string;
  handleChange?: (e) => void;
}

function ColorInputField({
  inputLabel,
  name,
  handleChange,
}: ColorInputFieldProps) {
  const theme = useTheme();
  const color = theme[name];
  return (
    <>
      <InputLabel htmlFor="color-input" label={inputLabel} />
      <FormItem name={name}>
        <NameInput
          addonBefore={
            <Form.Item name={name} noStyle>
              <ColorInput
                id="color-input"
                backgroundColor={color}
                name={name}
                onChange={handleChange}
                value={color}
              />
            </Form.Item>
          }
          bordered={false}
          maxLength={6}
          name={name}
          onBlur={handleChange}
          onInput={(e) =>
            (e.target.value = getAlphanumericString(e.target.value))
          }
          prefix={'#'}
          size="large"
          tag={InputTags.PRIMARY}
        />
      </FormItem>
    </>
  );
}

export default ColorInputField;
