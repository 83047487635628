import {StartLearningCardViewProps} from './StartLearningCard.interfaces';
import {Card, Image, Space} from 'antd';
import {H3} from '@components/reusable/Typography';
import {COLORS} from '@utils/constants';
import {StyledBodySpan, StyledSpace} from './StartLearningCard.styled';
import {i18n, k} from '@i18n/translate';
import {LEARNING_TROPHY} from '@assets/cloudinary-urls';
import RequestMenuButtonAndDrawer from '@components/user/components/request/RequestMenuButtonAndDrawer';
import {FinanceType} from '@generated/enums';

function StartLearningCardView({type}: StartLearningCardViewProps) {
  return (
    <Card>
      <StyledSpace size="large">
        <Image
          height="152px"
          src={LEARNING_TROPHY}
          alt={i18n.t(k.START_LEARNING__IMG_ALT)}
          width={'max-content'}
        />
        <Space direction="vertical">
          <H3 noMargin color={COLORS.Blue800}>
            {i18n.t(k.START_LEARNING__TITLE)}
          </H3>
          <StyledBodySpan color={COLORS.Neutral600}>
            {type === FinanceType.TuitionReimbursement
              ? i18n.t(k.START_LEARNING__SUBTITLE__REIMBURSEMENT)
              : i18n.t(k.START_LEARNING__SUBTITLE)}
          </StyledBodySpan>
          <RequestMenuButtonAndDrawer
            buttonAltStyle
            buttonTitle={i18n.t(k.START_LEARNING__BUTTON_TITLE)}
            showRequestProgramOption={true}
          />
        </Space>
      </StyledSpace>
    </Card>
  );
}

export default StartLearningCardView;
