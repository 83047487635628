import {
  Container,
  FullName,
  Role,
  Initials,
} from './AcademyLeadershipCard.styled';
import {AcademyLeadershipCardViewProps} from './AcademyLeadershipCard.interfaces';
import {Avatar} from 'antd';
import {getAvatarColorwaysStyles} from '@utils/color';
import {COLORS} from '@utils/constants';

function AcademyLeadershipCardView({
  initials,
  name,
  role,
  style,
}: AcademyLeadershipCardViewProps) {
  const randomColorwayIdx = name.length;
  return (
    <Container style={style}>
      <Avatar size={64} style={getAvatarColorwaysStyles(randomColorwayIdx)}>
        <Initials>{initials}</Initials>
      </Avatar>
      <FullName>{name}</FullName>
      <Role size="small" color={COLORS.Neutral600}>
        {role}
      </Role>
    </Container>
  );
}

export default AcademyLeadershipCardView;
