import {AcademyOverviewStepUserCompletionReportVm} from './../interfaces';
import {
  UserItemStatusVMMockData,
  getUserItemStatusVMMockData,
} from './UserItemStatusVM.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyOverviewStepUserCompletionReportVm

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyOverviewStepUserCompletionReportVmMockData(
  args?: Partial<AcademyOverviewStepUserCompletionReportVm>
): AcademyOverviewStepUserCompletionReportVm {
  return {
    email: String(faker.internet.email()), // optional
    lastNudgeSent: faker.word.noun(), // optional
    name: faker.name.fullName(),
    status: getUserItemStatusVMMockData(),
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyOverviewStepUserCompletionReportVmMockData: AcademyOverviewStepUserCompletionReportVm =
  {
    email: 'bjohnson@gmail.com', // optional
    lastNudgeSent: 'string', // optional
    name: 'Bobby Johnson',
    status: UserItemStatusVMMockData,
    userCompanyId: 1,
  };
