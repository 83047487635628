import './AdminPreApprovalDrawer.scss';
import {useMutation} from 'react-query';
import {notify} from '@components/user/notifications';
import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  ApprovalType,
} from '@generated/enums';
import {
  ResourceApprovalRequest,
  UserRequestApprovalVM,
} from '@models/serverModels';
import queryClient, {simpleMutationFn} from '@store/queryClient';
import {
  getAdminApprovalDetailsRq,
  getAdminUpdateUserRequestApprovalRm,
  getManagerUpdateUserRequestApprovalRm,
  getPreApprovalFormUrlBases,
} from '@store/apiEndpoints';

import {PreApprovalDrawer} from '@components/admin/pages/approvals/components/PreApprovalDrawer';
import {useGetApprovalRequest} from '@hooks/useGetApprovalRequest';
import {AdminScreenApprovalStatus} from '@models/clientEnums';

interface Props {
  activeTab: AdminScreenApprovalStatus;
  approvalId: number;
  approvalItemType: ApprovalsDiscriminator;
  approvalType: ApprovalType;
  isOverride: boolean;
  isReadonly?: boolean;
  licenseId?: number;
  onCancel: () => void;
  onOk: () => void;
  selectedApproval?: UserRequestApprovalVM | ResourceApprovalRequest | null;
  selectedCostCenter?: string;
  showPreApprovalDrawer: boolean;
  updateCostCenter?: (
    costCenter: string,
    approvalId: number,
    onSuccess?: () => void,
    onError?: () => void
  ) => void;
  updateFundsExpiration?: (callback?: () => void) => void;
  userRequestType?: string;
}

export default function AdminPreApprovalDrawer({
  activeTab,
  approvalId,
  approvalType,
  isOverride,
  isReadonly,
  licenseId,
  onCancel,
  onOk,
  selectedApproval,
  selectedCostCenter,
  showPreApprovalDrawer,
  updateCostCenter,
  userRequestType,
}: Props) {
  const adminUpdatePendingManagerApprovalMutation = useMutation(
    ({
      approvalId,
      approvalType,
      approvalStatus,
      notes,
      costCenter,
    }: {
      approvalId: number;
      approvalType: ApprovalType;
      approvalStatus: ApprovalStatus;
      notes: string;
      costCenter: string;
    }) => {
      const managerUpdateApprovalRm = getManagerUpdateUserRequestApprovalRm(
        approvalId,
        approvalType,
        approvalStatus,
        notes,
        costCenter
      );
      return simpleMutationFn<UserRequestApprovalVM>(
        managerUpdateApprovalRm.path,
        managerUpdateApprovalRm.payload
      );
    },
    {
      onSuccess: async (data) => {
        // moving this into the onclick seems to break the behavior so please leave here
        onOk();
        if (data.status == ApprovalStatus.Approved) notify.approveSuccessful();
        else if (data.status == ApprovalStatus.Rejected)
          notify.rejectSuccessful();
        for (const base of getPreApprovalFormUrlBases())
          await queryClient.invalidateQueries(base);
      },
      onError: () => notify.approvalUpdateError(),
    }
  );

  const {
    formRef,
    confirmLoading,
    setConfirmLoading,
    getApprovalDetailsQuery,
    updateApprovalMutation,
    updateFundsExpirationMutation,
    requestData,
    requestLoading,
    requestSuccess,
  } = useGetApprovalRequest({
    approvalType,
    approvalId,
    onOk,
    getApprovalDetailsRq: getAdminApprovalDetailsRq,
    getUpdateUserRequestApprovalRm: getAdminUpdateUserRequestApprovalRm,
  });

  const disableUpdateApprovalStatusButtons =
    adminUpdatePendingManagerApprovalMutation.isLoading ||
    updateApprovalMutation.isLoading;

  return (
    <PreApprovalDrawer
      activeTab={activeTab}
      adminUpdateFn={adminUpdatePendingManagerApprovalMutation.mutate}
      approvalId={approvalId}
      confirmLoading={confirmLoading}
      selectedCostCenter={selectedCostCenter}
      disableUpdateApprovalStatusButtons={disableUpdateApprovalStatusButtons}
      formRef={formRef}
      getApprovalDetailsQuery={getApprovalDetailsQuery}
      isOverride={isOverride}
      isReadonly={isReadonly}
      licenseId={licenseId}
      onCancel={onCancel}
      onOk={onOk}
      requestData={requestData}
      requestLoading={requestLoading}
      requestSuccess={requestSuccess}
      selectedApproval={selectedApproval}
      setConfirmLoading={setConfirmLoading}
      updateApprovalFn={updateApprovalMutation.mutate}
      updateCostCenter={updateCostCenter}
      updateFundsExpiration={updateFundsExpirationMutation.mutate}
      userRequestType={userRequestType}
      visible={showPreApprovalDrawer}
    />
  );
}
