import {Container} from './AcademyPeopleParticipantsTab.styled';
import {AcademyPeopleParticipantsTabViewProps} from './AcademyPeopleParticipantsTab.interfaces';
import AcademyParticipantsTable from '../AcademyParticipantsTable';
import {i18n, k} from '@i18n/translate';
import Search from '@components/reusable/Search';

function AcademyPeopleParticipantsTabView({
  onChangeSearchInput,
  searchInputValue,
  participantsTableDataSource,
}: AcademyPeopleParticipantsTabViewProps) {
  return (
    <Container>
      <Search
        id="people__participants-table"
        style={{width: '270px', margin: '16px 24px'}}
        onChange={onChangeSearchInput}
        value={searchInputValue}
        placeholder={i18n.t(k.NAME__SEARCH)}
        label={i18n.t(k.NAME__SEARCH)}
        buttonAriaLabel={i18n.t(k.NAME__SEARCH)}
      />
      <AcademyParticipantsTable dataSource={participantsTableDataSource} />
    </Container>
  );
}

export default AcademyPeopleParticipantsTabView;
