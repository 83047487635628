import {useQuery, UseQueryOptions} from 'react-query';
import {
  simpleQueryFn,
  simpleInvalidateExactQueryFn,
  deleteQueryDataItemById,
} from '@store/queryClient';
import {
  getCustomProgramAdminDetailVMRq,
  getCustomProgramAdminDetailVMsRq,
  getProgramSkillVMsRq,
  getCustomProgramContentLinksRq,
  getCustomProgramContentAttachmentsRq,
} from '@store/apiEndpoints/customProgram/queries';
import {
  getCustomProgramAgendaRq,
  getCustomProgramPermissionVMsRq,
  getProjectSubmissionAttachmentUrlRq,
  getRestrictedCustomProgramAccessUsersRq,
} from '@store/apiEndpoints/customProgram/queries';
import {
  AttachmentVM,
  CustomProgramAdminDetailVM,
  CustomProgramAdminSectionVM,
  CustomProgramAgendaVM,
  CustomProgramPreviewVM,
  IRestrictedProgramRecommendedUserVM,
  LinkVM,
  PermissionVM,
  ProgramSkillVM,
} from '@models/serverModels';
import {identity} from '@fullcalendar/react';
import {queryHookFactory} from '@hooks/apiEndpoints/utils';

const BASE_PATH = 'custom-program';
/*
|--------------------------------------------------------------------------
| Permissions
|--------------------------------------------------------------------------
*/

export const useGetAdminCustomProgramPermissionVMsQuery = queryHookFactory<
  number,
  PermissionVM[]
>(getCustomProgramPermissionVMsRq);

/*
|--------------------------------------------------------------------------
| CustomProgramAdminDetailVM[]
|--------------------------------------------------------------------------
*/

// Used for Custom Program Admin Table
export const useCustomProgramAdminDetailVMsQuery = (
  queryOptions?: UseQueryOptions<CustomProgramAdminDetailVM[]>,
  transformData: (data: any) => any = identity
) => {
  const queryRq = getCustomProgramAdminDetailVMsRq();
  const query = useQuery<CustomProgramAdminDetailVM[]>(
    queryRq.queryKey,
    () => simpleQueryFn(queryRq.path, transformData),
    queryOptions
  );

  const invalidateExact = async () =>
    await simpleInvalidateExactQueryFn(queryRq.queryKey);

  const deleteProgramFromCache = async (id: number) =>
    await deleteQueryDataItemById(queryRq.queryKey, id);

  return {
    ...query,
    invalidateExact,
    deleteProgramFromCache,
  };
};

/*
|--------------------------------------------------------------------------
| CustomProgramAdminSectionVM[]
|--------------------------------------------------------------------------
*/

export const useCustomProgramAdminSectionVMsQuery = queryHookFactory<
  number,
  CustomProgramAdminSectionVM[]
>((customProgramId: number) =>
  [BASE_PATH, 'get-custom-program-sections', customProgramId].join('/')
);

/*
|--------------------------------------------------------------------------
| CustomProgramAdminDetailVM (or sometimes CustomProgramCohortAdminVM)
|--------------------------------------------------------------------------
*/

export function useCustomProgramAdminDetailVMQuery<
  ReturnDataType = CustomProgramAdminDetailVM
>(id: number, queryOptions?: UseQueryOptions<ReturnDataType>) {
  const queryRq = getCustomProgramAdminDetailVMRq(id);
  const query = useQuery<ReturnDataType>(
    queryRq.queryKey,
    () => simpleQueryFn(queryRq.path),
    queryOptions
  );

  const invalidateExact = async () =>
    await simpleInvalidateExactQueryFn(queryRq.queryKey);

  return {
    ...query,
    invalidateExact,
  };
}

/*
|--------------------------------------------------------------------------
| ProgramSkillVM[]
|--------------------------------------------------------------------------
*/

export const useProgramSkillVMsQuery = queryHookFactory<null, ProgramSkillVM[]>(
  getProgramSkillVMsRq
);

/*
|--------------------------------------------------------------------------
| customProgramsPreviewDetailsQuery
|--------------------------------------------------------------------------
*/

export const useCustomProgramAgendaQuery = queryHookFactory<
  number,
  CustomProgramAgendaVM[]
>(getCustomProgramAgendaRq);

export const useGetCustomProgramContentLinksQuery = queryHookFactory<
  number,
  LinkVM[]
>(getCustomProgramContentLinksRq);

export const useGetCustomProgramContentAttachmentsQuery = queryHookFactory<
  number,
  AttachmentVM[]
>(getCustomProgramContentAttachmentsRq);

export const useGetProjectSubmissionAttachmentUrlQuery = queryHookFactory<
  number,
  string
>(getProjectSubmissionAttachmentUrlRq);

export const useGetRestrictedCustomProgramAccessUsersQuery = queryHookFactory<
  number,
  IRestrictedProgramRecommendedUserVM[]
>(getRestrictedCustomProgramAccessUsersRq);
