import {
  LxpContentSearchRequest,
  LxpContentSearchResponse,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {LxpContentSearchRequestSchema} from '../zodSchemas/LxpContentSearchRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/LxpContent/LxpContentController.cs

/*
|--------------------------------------------------------------------------
|  search 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useLxpcontentSearchMutation} from '@generated/hooks';
 *
 *  const lxpcontentSearch = useLxpcontentSearchMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  lxpcontentSearch.mutate({
 *    payload: {
 *      ...LxpContentSearchRequest
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useLxpcontentSearchMutation = mutationHookFactory<
  LxpContentSearchRequest, // payload
  null,
  LxpContentSearchResponse // return type
>('useLxpcontentSearchMutation', 'post', () => `lxpcontent/search`, {
  payloadSchema: LxpContentSearchRequestSchema,
});
