import {ErrorMessage} from '@components/reusable/LearnInForm';
import {LearnInSelectOption} from '@components/reusable/Select/Select.style';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import {handleLicenseProviderChange} from '../AddUpdateLicenseDrawer.handlers';
import {
  LicenseLabel,
  LicenseProviderContainer,
  LicenseProviderSelect,
} from '../AddUpdateLicenseDrawer.styled';
import {
  LICENSE_DURATION_NON_FIXED,
  LicenseFormFieldErrors,
  LicenseFormFields,
} from '../AddUpdateLicenseDrawer.types';

interface LicenseProviderItemProps {
  formFieldErrors: LicenseFormFieldErrors;
  isNew: boolean;
  provider: string;
  providerId: number;
  providers: {id: number; description: string}[];
  providersInUse: number[];
  providerSuggestions: {label: string; value: string; id: number}[];
  setFormFieldErrors: (errors: LicenseFormFieldErrors) => void;
  setProvider: (provider: string) => void;
  setProviderId: (providerId: number) => void;
}

function LicenseProviderItem({
  formFieldErrors,
  isNew,
  provider,
  providerId,
  providersInUse,
  providers,
  providerSuggestions,
  setFormFieldErrors,
  setProvider,
  setProviderId,
}: LicenseProviderItemProps) {
  return !isNew ? (
    <LicenseProviderContainer>
      <LicenseLabel>{i18n.t(k.GENERIC__PROVIDER)}</LicenseLabel>
      <div>
        {providers?.map(({id, description}) => {
          return providerId === id && <span>{description}</span>;
        })}
      </div>
    </LicenseProviderContainer>
  ) : (
    <Form.Item
      label={
        <LicenseLabel htmlFor="provider-id" required>
          {i18n.t(k.GENERIC__PROVIDER)}
        </LicenseLabel>
      }
      name={LicenseFormFields.ProviderId}>
      <LicenseProviderSelect
        id="provider-id"
        value={provider}
        placeholder={i18n.t(k.GENERIC__SELECT_ONE)}
        filterOption={(inputValue, {value}) => {
          return (
            value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !==
            LICENSE_DURATION_NON_FIXED
          );
        }}
        onChange={(providerName: string) =>
          handleLicenseProviderChange({
            formFieldErrors,
            providerName,
            providerSuggestions,
            setFormFieldErrors,
            setProviderId,
            setProvider,
          })
        }>
        {providerSuggestions?.map((provider) => (
          <LearnInSelectOption
            disabled={providersInUse?.includes(provider.id)}
            key={provider.id}
            value={provider.value}>
            {provider.value}
          </LearnInSelectOption>
        ))}
      </LicenseProviderSelect>
      <ErrorMessage message={formFieldErrors[LicenseFormFields.ProviderId]} />
    </Form.Item>
  );
}

export default LicenseProviderItem;
