import {SyntheticEvent, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {CustomProgramAgendaVM} from '@models/serverModels';
import {COLORS} from '@utils/constants';
import {PaperClipOutlined} from '@ant-design/icons';
import attachmentPreviewImg from '@assets/contentAttachmentPreview.png';
import {NewWindowIcon} from '@assets/new-window-icon';
import {
  AttachmentPreviewImg,
  ContentDueDateStatus,
  VideoContainer,
} from '@blocks/CustomProgramUserDrawer/ContentCard';
import {
  EventTime,
  LiveEventTag,
} from '@blocks/CustomProgramUserDrawer/ContentCard.container';
import {ContentCompleteButtonContainer} from '@blocks/CustomProgramUserDrawer/ContentCompleteButton';
import {LabelAndIconContainer} from '@components/admin/pages/programs/customPrograms/curriculum/CurriculumScreen.container';
import {ContentLink} from '@components/reusable/ContentLink';
import {AttachmentType, ContentType} from '@generated/enums';
import {ConvertRelativeLinksInText, formatUrl} from '@utils/stringUtils';
import {parseToLocalMoment, utcStringIsInThePast} from '@utils/timeUtils';
import {Video} from 'cloudinary-react';
import ReactQuill from 'react-quill';
import ContentTypeIcon from '@blocks/ContentTypeIcon';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {AttachmentPayload} from '@models/api/shared/payloads';
import {CheckCircleFilled} from '@ant-design/icons';
import {openInNewTab} from '@components/providers/utils';

/*
|--------------------------------------------------------------------------
| Child Component
|--------------------------------------------------------------------------
*/

export const EventCompleted = () => (
  <div style={{display: 'flex', alignItems: 'center', marginBottom: '20px'}}>
    <CheckCircleFilled aria-hidden="true" style={{color: COLORS.Green600}} />
    <span style={{marginLeft: '5px'}}>{i18n.t(k.EVENT__COMPLETED)}</span>
  </div>
);

export const JoinEventButton = ({
  eventLinkUrl,
  isActivePlanItem,
}: {
  eventLinkUrl: string;
  isActivePlanItem: boolean;
}) => {
  return (
    <LearnInButton
      tag={ButtonTags.Secondary}
      onClick={() => {
        if (!isActivePlanItem) return;
        openInNewTab(eventLinkUrl);
      }}>
      {i18n.t(k.EVENT__JOIN)}
    </LearnInButton>
  );
};

const SubTitle = ({agenda}: {agenda: CustomProgramAgendaVM}) => {
  const eventHasPassed = agenda.endDateUtc
    ? utcStringIsInThePast(agenda.endDateUtc)
    : true;
  const eventTimeZone = parseToLocalMoment(agenda.startDateUtc).zoneName();

  switch (agenda.contentType) {
    case ContentType.Project:
    case ContentType.Text:
      return !!agenda.startDateUtc ? (
        <ContentDueDateStatus
          completed={eventHasPassed}
          dueDateUtc={agenda.startDateUtc}
        />
      ) : null;
    case ContentType.Event:
      return (
        <div style={{display: 'flex', minWidth: '120px'}}>
          <LiveEventTag eventHasPassed={eventHasPassed} />
          <EventTime
            endDateUtc={agenda.endDateUtc}
            startDateUtc={agenda.startDateUtc}
            timeZone={eventTimeZone}
          />
        </div>
      );

    default:
      return null;
  }
};

const ActionButton = ({
  agenda,
  customProgramId,
  isActivePlanItem,
  completingContentDisabled,
}: {
  agenda: CustomProgramAgendaVM;
  customProgramId: number;
  isActivePlanItem: boolean;
  completingContentDisabled: boolean;
}) => {
  const eventHasPassed = agenda.endDateUtc
    ? utcStringIsInThePast(agenda.endDateUtc)
    : true;

  switch (agenda.contentType) {
    case ContentType.Text:
      return (
        <ContentCompleteButtonContainer
          disabled={completingContentDisabled}
          isActivePlanItem={isActivePlanItem}
          completed
          contentId={agenda.id}
          customProgramId={customProgramId}
          title={agenda.title}
        />
      );

    case ContentType.Event:
      if (eventHasPassed) return <EventCompleted />;
      if (!!agenda.eventLinkUrl)
        return (
          <JoinEventButton
            isActivePlanItem={isActivePlanItem}
            eventLinkUrl={agenda.eventLinkUrl}
          />
        );
      return null;
    default:
      return null;
  }
};

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const AgendaCardTitleActionBar = styled.div`
  display: flex;
  gap: 0.5rem;
  & > *:not(:first-child):before {
    align-self: stretch;
    content: '';
    border: 1px solid ${COLORS.Neutral200};
  }
  button,
  a {
    padding: 0;
    &:hover,
    &:focus-visible {
      background: none;
      text-decoration: underline;
    }
  }
`;

export const AgendaCardTitle = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: ${COLORS.Neutral900};
`;

export const AgendaCardContainerStyled = styled.button`
  background: ${COLORS.White};
  border: 1px solid ${COLORS.Neutral200};
  box-sizing: border-box;
  color: ${COLORS.Neutral900};
  font-size: 1rem;
  font-weight: 500;
  padding: 24px;
  position: relative;
  transition: background-color 0.3s;
  width: 100%;
  &:hover {
    background: ${COLORS.Neutral100};
    cursor: pointer;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: -2px;
  }
  &.expanded ${AgendaCardTitleActionBar} {
    display: none;
    transition: 0.5s;
  }

  &.expanded ${AgendaCardTitle} {
    font-size: 1.25rem;
  }
`;

export const AgendaCardHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const AgendaCardBody = styled.div`
  display: none;
  padding-top: 20px;

  justify-content: space-between;
  transition: 2s;

  &.collapsed {
    display: none;
    height: 0;
  }
  &.expanded {
    display: flex;
    height: fit-content;
  }
`;

export const AgendaCardBodyColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: start;
  button {
    width: fit-content;
    &:focus-visible {
      outline: 0;
    }
    &:hover {
      background: none;
      border: none;
    }
  }
`;
export const AgendaCardDescription = styled.div`
  font-weight: normal;
  flex: auto; // force description to take up extra space
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Neutral900};
  margin-bottom: 21px;
  width: 100%;
  .ql-editor {
    padding: 0 !important;
    p {
      font-size: 1rem;
    }
  }
  .ql-container.ql-disabled .ql-tooltip {
    display: none;
  }
`;

export interface IAgendaCardProps {
  agenda: CustomProgramAgendaVM;
  handleClickFileAttachment: (...args: any[]) => any;
  onCardSelected: (id: number | undefined) => void;
  onAddDateToCalendar: () => void;
  selectedCardId: number | undefined;
  customProgramId: number;
  isConnectCalendarOn: boolean;
  isExternalCalendarConnected: boolean;
  isActivePlanItem: boolean;
}

export default function AgendaCard({
  agenda,
  handleClickFileAttachment,
  onCardSelected,
  onAddDateToCalendar,
  selectedCardId,
  customProgramId,
  isConnectCalendarOn,
  isExternalCalendarConnected,
  isActivePlanItem,
}: IAgendaCardProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState<boolean>(true);

  useEffect(() => {
    if (selectedCardId === agenda.id) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  }, [agenda.id, selectedCardId]);

  const onFileAttachmentClicked = (
    _: SyntheticEvent,
    attachment: AttachmentPayload
  ) => {
    handleClickFileAttachment?.(attachment);
  };

  const onCardClicked = () => onCardSelected?.(collapsed ? agenda.id : null);

  const onAddDateToCalendarClicked = () => onAddDateToCalendar();

  const formattedDescription = ConvertRelativeLinksInText(agenda.description);
  const attachment = agenda?.attachments?.[0];
  const link = agenda.links[0];
  const attachmentIsFile = attachment?.type === AttachmentType.File;
  const attachmentIsVideo = attachment?.type === AttachmentType.Video;
  const eventHasPassed =
    utcStringIsInThePast(agenda.endDateUtc) ||
    utcStringIsInThePast(agenda.startDateUtc);

  return (
    <AgendaCardContainerStyled
      ref={containerRef}
      onClick={onCardClicked}
      className={collapsed ? 'collapsed' : 'expanded'}
      aria-label={`${i18n.t(k.AGENDA__ITEM)}: ${agenda.title}`}>
      <AgendaCardHeaderRow>
        <LabelAndIconContainer>
          <ContentTypeIcon
            contentType={agenda.contentType ?? ContentType.Text}
          />
          {agenda.title}
        </LabelAndIconContainer>
        <AgendaCardTitleActionBar>
          {!!attachment && attachmentIsFile && (
            <LearnInButton
              icon={<PaperClipOutlined aria-hidden="true" />}
              onClick={(e) => onFileAttachmentClicked(e, attachment)}
              tag={ButtonTags.SecondaryRemovedBorder}
              title={attachment.fileName}
            />
          )}

          {!!agenda.links?.length && (
            <ContentLink
              title={link.title}
              key={link.id}
              url={formatUrl(link.url)}
            />
          )}

          {isConnectCalendarOn &&
            isExternalCalendarConnected &&
            !agenda.addedToExternalCalendar &&
            !eventHasPassed && (
              <LearnInButton
                tag={ButtonTags.SecondaryRemovedBorder}
                onClick={onAddDateToCalendarClicked}>
                {i18n.t(k.CALENDAR__ADD_TO)}
              </LearnInButton>
            )}

          {!!agenda.eventLinkUrl?.length && !eventHasPassed && (
            <ContentLink
              title={i18n.t(k.EVENT__JOIN)}
              url={formatUrl(agenda.eventLinkUrl)}
            />
          )}
        </AgendaCardTitleActionBar>
      </AgendaCardHeaderRow>
      <AgendaCardBody className={collapsed ? 'collapsed' : 'expanded'}>
        <AgendaCardBodyColumnLeft>
          <SubTitle agenda={agenda} />

          {!!formattedDescription && (
            <AgendaCardDescription>
              <ReactQuill
                value={formattedDescription || ''}
                readOnly
                theme="bubble"
              />

              {attachmentIsVideo && (
                <VideoContainer>
                  <Video
                    cloudName="dsmsrmuyr"
                    publicId={attachment.fileName}
                    sourceTypes="mp4"
                    controls
                    width={640}
                  />
                </VideoContainer>
              )}
            </AgendaCardDescription>
          )}

          {!!link && (
            <ContentLink
              title={link.title}
              key={link.id}
              url={formatUrl(link.url)}
            />
          )}

          {/* File Attachment Link */}
          {attachmentIsFile && (
            <LearnInButton
              key={attachment.url}
              tag={ButtonTags.SecondaryRemovedBorder}
              hoverUnderline
              onClick={(e) => onFileAttachmentClicked(e, attachment)}
              icon={<NewWindowIcon alt={i18n.t(k.LINK__VIEW)} />}>
              {i18n.t(k.FILE__ATTACHMENT_OPEN)}
            </LearnInButton>
          )}

          <ActionButton
            customProgramId={customProgramId}
            agenda={agenda}
            isActivePlanItem={isActivePlanItem}
            completingContentDisabled={false}
          />
        </AgendaCardBodyColumnLeft>

        {attachmentIsFile && (
          <button
            onClick={(e) => onFileAttachmentClicked(e, attachment)}
            style={{marginLeft: '16px'}}>
            <AttachmentPreviewImg
              alt={i18n.t(k.FILE__ATTACHMENT)}
              style={{maxHeight: '200px', maxWidth: '200px'}}
              src={attachment.image || attachmentPreviewImg}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = attachmentPreviewImg;
              }}
            />
          </button>
        )}
      </AgendaCardBody>
    </AgendaCardContainerStyled>
  );
}
