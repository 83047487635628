import {datadogLogs} from '@datadog/browser-logs';
import {ContentType} from '@generated/enums';
import {i18n, k} from '@i18n/translate';

export interface ContentTypeLabels {
  editLabel: string;
  deleteLabel: string;
  deletePrompt: string;
  duplicateLabel: string;
  newLabel: string;
  label: string;
  saveLabel: string;
}

export const useGetLabelsByContentType = (
  contentType: ContentType
): ContentTypeLabels => {
  const content = {
    [ContentType.Text]: {
      editLabel: k.CONTENT__EDIT,
      deleteLabel: k.CONTENT__DELETE,
      deletePrompt: k.CONTENT__DELETE__PROMPT,
      duplicateLabel: k.CONTENT__DUPLICATE__VERB,
      newLabel: k.CONTENT__NEW,
      label: k.CONTENT,
      saveLabel: k.CONTENT__SAVE,
    },
    [ContentType.Project]: {
      editLabel: k.PROJECT__EDIT,
      deleteLabel: k.PROJECT__DELETE,
      deletePrompt: k.PROJECT__DELETE__PROMPT,
      duplicateLabel: k.PROJECT__DUPLICATE__VERB,
      label: k.PROJECT,
      newLabel: k.PROJECT__NEW,
      saveLabel: k.PROJECT__SAVE,
    },
    [ContentType.Event]: {
      editLabel: k.EVENT__EDIT,
      deleteLabel: k.EVENT__DELETE,
      deletePrompt: k.EVENT__DELETE__PROMPT,
      duplicateLabel: k.EVENT__DUPLICATE__VERB,
      label: k.EVENT,
      newLabel: k.EVENT__NEW,
      saveLabel: k.EVENT__SAVE,
    },
    [ContentType.Discussion]: {
      editLabel: k.DISCUSSION__EDIT,
      deleteLabel: k.DISCUSSION__DELETE,
      deletePrompt: k.DISCUSSION__DELETE__PROMPT,
      duplicateLabel: k.DISCUSSION__DUPLICATE__VERB,
      label: k.DISCUSSION,
      newLabel: k.DISCUSSION__NEW,
      saveLabel: k.DISCUSSION__SAVE,
    },
  };

  const labels = content[contentType];

  if (!labels) {
    datadogLogs.logger.error('Error getting content type name', {
      contentType: contentType,
    });
  }

  const getLabel = (label: string) => (labels ? i18n.t(labels[label]) : '');

  return {
    editLabel: getLabel('editLabel'),
    deleteLabel: getLabel('deleteLabel'),
    deletePrompt: getLabel('deletePrompt'),
    duplicateLabel: getLabel('duplicateLabel'),
    label: getLabel('label'),
    newLabel: getLabel('newLabel'),
    saveLabel: getLabel('saveLabel'),
  };
};
