import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LearnInWrapper} from '@components/reusable/Wrapper/Wrapper.style';
import {WrapperTags} from '@components//reusable/Wrapper/WrapperEnums';
import {WalletIcon} from '@assets/wallet';
import {MoneyIcon} from '@assets/money';
import {FINANCE_INCENTIVE_ADD_TYPES} from '@models/clientEnums';
import styled from 'styled-components';

const ModalTitle = styled.h2`
  margin: 0;
`;

const StyledLearnInContainer = styled(LearnInContainer)`
  cursor: pointer;
`;

interface Props {
  setVisibleMoneyIncentiveModal: (value: boolean) => void;
  visibleMoneyIncentivePreSelectModal: boolean;
  hasExistingActivePersonalLearningBudget: boolean;
  onPersonalLearningBudgetConfirm: () => void;
  onOtherConfirm: () => void;
}

export function AddMoneyIncentivePreSelectModal({
  setVisibleMoneyIncentiveModal,
  visibleMoneyIncentivePreSelectModal,
  hasExistingActivePersonalLearningBudget,
  onPersonalLearningBudgetConfirm,
  onOtherConfirm,
}: Props) {
  const [
    selectedMoneyIncentiveModalChoice,
    setSelectedMoneyIncentiveModalChoice,
  ] = useState<FINANCE_INCENTIVE_ADD_TYPES>(null);

  return (
    <LearnInModal
      width={480}
      visible={visibleMoneyIncentivePreSelectModal}
      onCancel={() => {
        setVisibleMoneyIncentiveModal(false);
        setSelectedMoneyIncentiveModalChoice(null);
      }}
      onOk={() => {
        setVisibleMoneyIncentiveModal(false);
        switch (selectedMoneyIncentiveModalChoice) {
          case FINANCE_INCENTIVE_ADD_TYPES.PersonalLearningBudget:
            onPersonalLearningBudgetConfirm();
            break;
          case FINANCE_INCENTIVE_ADD_TYPES.License:
            // not implemented right now
            break;
          case FINANCE_INCENTIVE_ADD_TYPES.Other:
            onOtherConfirm();
            break;
        }
        setSelectedMoneyIncentiveModalChoice(null);
      }}
      okButtonChild={i18n.t(k.CTA__CONTINUE)}
      okButtonProps={{id: 'submit-incentive-type-button'}}
      buttonDisabled={selectedMoneyIncentiveModalChoice === null}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      tag={ModalTags.MoneyIncentive}
      ariaLabel={i18n.t(k.INCENTIVE__ADD__WHAT_TYPE)}>
      <section aria-labelledby="add-money-incentive-pre-select-modal-title">
        <header>
          <ModalTitle>
            <LearnInText
              tag={TextTags.ModalHeader}
              id="add-money-incentive-pre-select-modal-title">
              {i18n.t(k.INCENTIVE__ADD__WHAT_TYPE)}
            </LearnInText>
          </ModalTitle>
        </header>
        <LearnInWrapper tag={WrapperTags.MoneyIncentiveCardWrapper}>
          <StyledLearnInContainer
            id="create-personal-learning-budget-button"
            tag={
              hasExistingActivePersonalLearningBudget
                ? ContainerTags.DisabledMoneyIncentiveModalCard
                : selectedMoneyIncentiveModalChoice ===
                  FINANCE_INCENTIVE_ADD_TYPES.PersonalLearningBudget
                ? ContainerTags.SelectedMoneyIncentiveModalCard
                : ContainerTags.MoneyIncentiveModalCard
            }
            role="button"
            tabIndex="0"
            onClick={() => {
              if (hasExistingActivePersonalLearningBudget) {
                return;
              }
              setSelectedMoneyIncentiveModalChoice(
                FINANCE_INCENTIVE_ADD_TYPES.PersonalLearningBudget
              );
            }}>
            <LearnInWrapper tag={WrapperTags.MoneyIncentiveCardImageWrapper}>
              <WalletIcon disabled={hasExistingActivePersonalLearningBudget} />
            </LearnInWrapper>
            <div>
              <LearnInText tag={TextTags.MoneyIncentiveModalCardHeader}>
                {i18n.t(k.PLB)}
              </LearnInText>
              <LearnInText tag={TextTags.MoneyIncentiveModalCardText}>
                {i18n.t(k.INCENTIVE__LEARNING_BUDGET_DESCRIPTION)}
              </LearnInText>
            </div>
          </StyledLearnInContainer>
          <StyledLearnInContainer
            id="incentive-modal-type-other"
            tag={
              selectedMoneyIncentiveModalChoice ===
              FINANCE_INCENTIVE_ADD_TYPES.Other
                ? ContainerTags.SelectedMoneyIncentiveModalCard
                : ContainerTags.MoneyIncentiveModalCard
            }
            role="button"
            tabIndex="0"
            onClick={() =>
              setSelectedMoneyIncentiveModalChoice(
                FINANCE_INCENTIVE_ADD_TYPES.Other
              )
            }>
            <LearnInWrapper tag={WrapperTags.MoneyIncentiveCardImageWrapper}>
              <MoneyIcon />
            </LearnInWrapper>
            <div>
              <LearnInText tag={TextTags.MoneyIncentiveModalCardHeader}>
                {i18n.t(k.GENERIC__OTHER)}
              </LearnInText>
              <LearnInText tag={TextTags.MoneyIncentiveModalCardText}>
                {i18n.t(k.INCENTIVE__SCHOLARSHIPS_ETC)}
              </LearnInText>
            </div>
          </StyledLearnInContainer>
        </LearnInWrapper>
      </section>
    </LearnInModal>
  );
}
