import {useEffect, useContext, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled, {DefaultTheme, ThemeContext} from 'styled-components';
import {ArrowRightOutlined} from '@ant-design/icons';
import {COLORS, HEADER_HEIGHT} from '@utils/constants';
import {AcademyVM} from '@generated/interfaces';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {Skeleton} from 'antd';
import useViewportSize from '@hooks/useViewportSize';
import {convertHexToHSL} from '@utils/color';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  background: ${(props: DefaultTheme) =>
    props.shouldTint ? `rgba(0,0,0,0.8)` : `rgba(0,0,0,0)`};
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
`;

const MobileContainer = styled.div`
  background: ${(props: DefaultTheme) => {
    if (props.image) {
      return `url(${props.image}) !important;`;
    }
    return `${convertHexToHSL(props.backgroundColor, 1)} !important;`;
  }}
  background-size: cover !important;
  width: 100%;
  display: flex;
  height: calc(100vh - 72px);
  position: relative;
`;

const Background = styled.div.attrs(() => ({
  className: 'academy-welcome-drawer-bg',
}))`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  width: 100%;
  background: ${(props: DefaultTheme) => {
    if (props.image) {
      return `url(${props.image}) !important;`;
    }
    return `${convertHexToHSL(props.backgroundColor, 1)} !important;`;
  }}
  background-size: cover !important;
  button:focus-visible {
    outline: 4px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }
`;

const MobileTitle = styled.div`
  font-family: Roboto, serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: 0;
  text-align: center;
  color: ${(props: DefaultTheme) =>
    props?.shouldTint ? COLORS.White : props?.color};
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-family: Roboto, serif;
  font-size: 6.25rem;
  font-weight: 500;
  line-height: 7.36875rem;
  letter-spacing: 0;
  text-align: center;
  color: ${(props: DefaultTheme) =>
    props?.shouldTint ? COLORS.White : props?.color};
  margin-bottom: 24px;
`;

const Description = styled.div`
  font-family: Roboto, serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-align: center;
  color: ${(props: DefaultTheme) =>
    props?.shouldTint ? COLORS.White : props?.color};
  margin-bottom: 16px;
`;
const StyledContent = styled.div`
  margin: 32px auto;
  overflow-y: hidden;
  max-width: 1075px;
`;

const TitleAndButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const MobileContent = styled.div`
  background: ${(props: DefaultTheme) =>
    props.shouldTint ? `rgba(0,0,0,0.8)` : `rgba(0,0,0,0)`};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
`;

const TransitionContainer = styled.div<{opacity: number}>`
  transition: opacity 0.8s ease;
  opacity: ${({opacity}) => opacity};
`;

export interface ContentProps {
  academy?: AcademyVM;
  userFirstName: string;
  clickJoinAcademy?: () => void;
  isBranding?: boolean;
  breakpoint: number;
  transitionOnRender?: boolean;
}

export function Content({
  academy,
  clickJoinAcademy,
  userFirstName,
  isBranding,
  breakpoint,
  transitionOnRender,
}: ContentProps) {
  const [transitionContainerOpacity, setTransitionContainerOpacity] = useState(
    transitionOnRender ? 0 : 1
  );
  const defaultTheme = useContext<DefaultTheme>(ThemeContext);
  const theme = academy?.branding;

  // If the academy has no theme, we will just apply the default theme.
  useEffect(() => {
    if (academy?.branding?.primaryBrandColor === null) {
      academy.branding = defaultTheme;
    }
  }, [academy, defaultTheme]);

  useEffect(() => {
    !transitionContainerOpacity && setTransitionContainerOpacity(1);
  }, []);

  const {width: viewportWidth} = useViewportSize();
  const hasImage = !!academy?.academyProfileImageUrl;
  return (
    <>
      {viewportWidth > breakpoint ? (
        <Background
          data-testid="academy-welcome-background"
          backgroundColor={academy?.branding?.backgroundWelcomeColor}
          image={academy?.academyProfileImageUrl}>
          <Container shouldTint={hasImage}>
            <TitleAndButtonContainer>
              <StyledContent>
                {isBranding ? (
                  <>
                    <Title
                      color={academy?.branding?.itemDefaultTextNavColor}
                      shouldTint={hasImage}>
                      {i18n.t(k.ACADEMY__WELCOME_TO)}
                    </Title>
                    <Description
                      color={academy?.branding?.itemDefaultTextNavColor}
                      shouldTint={hasImage}>
                      <Skeleton />
                    </Description>
                  </>
                ) : (
                  <TransitionContainer opacity={transitionContainerOpacity}>
                    <Title
                      color={academy?.branding?.itemDefaultTextNavColor}
                      shouldTint={hasImage}>
                      {i18n.t(k.ACADEMY__WELCOME__FORMAT, {
                        name: userFirstName,
                      })}
                    </Title>
                    <Description
                      color={academy?.branding?.itemDefaultTextNavColor}
                      shouldTint={hasImage}>
                      {academy?.description}
                    </Description>
                  </TransitionContainer>
                )}
              </StyledContent>
              <div style={{width: '176px', margin: '0 auto'}}>
                <LearnInButton
                  data-testid="academy-welcome-button"
                  data-bg={theme?.buttonDefaultWelcomeColor}
                  border={`1px solid ${theme?.buttonDefaultWelcomeColor}`}
                  icon={
                    <ArrowRightOutlined color="#000000" aria-hidden="true" />
                  }
                  background={theme?.buttonDefaultWelcomeColor}
                  color={theme?.itemDefaultTextWelcomeColor}
                  hoverBackground={theme?.buttonHoverWelcomeColor}
                  hoverBorder={`1px solid ${theme?.buttonHoverWelcomeColor}`}
                  hoverColor={theme?.itemHoverTextNavColor}
                  onClick={clickJoinAcademy}
                  iconAlign="right"
                  tag={ButtonTags.Primary}>
                  {i18n.t(k.ACADEMY__JOIN)}
                </LearnInButton>
              </div>
            </TitleAndButtonContainer>
          </Container>
        </Background>
      ) : (
        <MobileContainer
          backgroundColor={academy?.branding?.primaryBrandColor}
          image={academy?.academyProfileImageUrl}>
          <MobileContent shouldTint={hasImage}>
            <StyledContent style={{padding: '24px'}}>
              <MobileTitle
                color={academy?.branding?.itemDefaultTextNavColor}
                shouldTint={hasImage}>
                {i18n.t(k.ACADEMY__WELCOME__FORMAT, {name: userFirstName})}
              </MobileTitle>
              <Description
                color={academy?.branding?.itemDefaultTextNavColor}
                shouldTint={hasImage}>
                {academy?.description}
              </Description>
            </StyledContent>
            <div style={{width: '176px', margin: '0 auto'}}>
              <LearnInButton
                border={`1px solid ${theme?.buttonDefaultWelcomeColor}`}
                icon={<ArrowRightOutlined aria-hidden="true" />}
                background={theme?.buttonDefaultWelcomeColor}
                color={theme?.itemDefaultTextNavColor}
                onClick={clickJoinAcademy}
                iconAlign="right"
                tag={ButtonTags.Primary}>
                {i18n.t(k.ACADEMY__JOIN)}
              </LearnInButton>
            </div>
          </MobileContent>
        </MobileContainer>
      )}
    </>
  );
}
