import {useState} from 'react';
import {UpsertMode} from './ProgramReviewFormModals';
import {ProgramReviewVM, ProgramReviewPayload} from '@models/serverModels';
import {getProgramReviewsRq} from '@store/apiEndpoints/program/queries';
import {
  getAddProgramReviewRm,
  getUpdateProgramReviewRm,
  getDeleteProgramReviewRm,
} from '@store/apiEndpoints/program/mutations';
import queryClient, {
  simpleQueryFn,
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleDeleteFn,
} from '@store/queryClient';
import {useQuery, useMutation} from 'react-query';
import {notify} from '@components/user/notifications';
import {Moment} from 'moment';
import ReviewsTab from './ReviewsTab';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface IHandleUpsertArgs {
  id: number | undefined;
  upsertMode: UpsertMode;
  rating: number;
  title: string;
  reviewContent: string;
  hasCompletedProgram: boolean;
  programCompletedOn: Moment;
}

export interface IReviewsTabContainerProps {
  programId: number;
}

function ReviewsTabContainer({programId}: IReviewsTabContainerProps) {
  const [isUpsertModalVisible, setIsUpsertModalVisible] = useState(false);
  const [
    isDeleteConfirmationModalVisible,
    setIsDeleteConfirmationModalVisible,
  ] = useState(false);
  const [programReviewToEdit, setProgramReviewToEdit] =
    useState<ProgramReviewVM | null>(null);
  const [deleteProgramReviewId, setDeleteProgramReviewId] = useState();

  const programReviewsRq = getProgramReviewsRq(programId);
  const getProgramReviewsQuery = useQuery<ProgramReviewVM[]>(
    programReviewsRq.queryKey,
    () => simpleQueryFn(programReviewsRq.path),
    {
      refetchOnWindowFocus: false,
    }
  );

  // Mutation: add a review
  const addProgramReviewMutation = useMutation(
    ({
      rating,
      title,
      reviewContent,
      hasCompletedProgram,
      programCompletedOn,
    }: Omit<IHandleUpsertArgs, 'upsertMode'>) => {
      const addProgramReviewRm = getAddProgramReviewRm({
        programId,
        rating,
        title,
        reviewContent,
        hasCompletedProgram,
        programCompletedOn: programCompletedOn?.toISOString() || '',
      });
      return simpleMutationFn<ProgramReviewPayload>(
        addProgramReviewRm.path,
        addProgramReviewRm.payload
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          programReviewsRq.queryKey,
          (reviews: ProgramReviewVM[]) => [data, ...reviews]
        );
        closeUpsertModal();
        notify.addProgramReviewSuccess();
      },
      onError: () => {
        setIsUpsertModalVisible(true);
        notify.addProgramReviewError();
      },
    }
  );

  // Mutation: update a review
  const updateProgramReviewMutation = useMutation(
    ({
      id,
      rating,
      title,
      reviewContent,
      hasCompletedProgram,
      programCompletedOn,
    }: Omit<IHandleUpsertArgs, 'upsertMode'>) => {
      const updateProgramReviewRm = getUpdateProgramReviewRm({
        id,
        programId,
        rating,
        title,
        reviewContent,
        hasCompletedProgram,
        programCompletedOn: programCompletedOn.toISOString() || '',
      });
      return simpleMutationFn<ProgramReviewPayload>(
        updateProgramReviewRm.path,
        updateProgramReviewRm.payload
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          programReviewsRq.queryKey,
          (reviews: ProgramReviewVM[]) =>
            reviews.map((review) => (review.id === data.id ? data : review))
        );
        closeUpsertModal();
        notify.updateProgramReviewSuccess();
      },
      onError: () => {
        setIsUpsertModalVisible(true);
        notify.updateProgramReviewError();
      },
    }
  );

  // Mutation: delete a review
  const deleteProgramReviewMutation = useMutation(
    () => {
      const deleteProgramReviewRm = getDeleteProgramReviewRm(
        deleteProgramReviewId
      );
      return simpleDeleteFn<ProgramReviewPayload>(
        deleteProgramReviewRm.path,
        deleteProgramReviewRm.payload
      );
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(
          programReviewsRq.queryKey,
          (reviews: ProgramReviewVM[]) =>
            reviews.filter((review) => review.id !== deleteProgramReviewId)
        );
        notify.deleteProgramReviewSuccess();
      },
      onError: () => {
        notify.deleteProgramReviewError();
      },
    }
  );

  // Upsert modal functions
  const openUpsertModal = (programReview?: ProgramReviewVM | null) => {
    setIsUpsertModalVisible(true);
    if (!!programReview) {
      setProgramReviewToEdit(programReview);
    }
  };

  const closeUpsertModal = () => {
    setIsUpsertModalVisible(false);
  };

  const handleSubmitUpsert = ({
    upsertMode,
    ...upsertArgs
  }: IHandleUpsertArgs) => {
    if (upsertMode === UpsertMode.Add) {
      addProgramReviewMutation.mutateAsync(upsertArgs);
    } else if (upsertMode === UpsertMode.Edit) {
      updateProgramReviewMutation.mutateAsync(upsertArgs);
    } else {
      console.warn('option to add or update review was not chosen');
    }
    simpleInvalidateExactQueryFn(programReviewsRq.queryKey);
  };

  // Delete modal functions
  const openDeleteConfirmationModal = (id) => {
    setIsDeleteConfirmationModalVisible(true);
    setDeleteProgramReviewId(id);
  };

  const closeDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalVisible(false);
  };

  const handleDeleteReview = () => {
    deleteProgramReviewMutation.mutateAsync();
    closeDeleteConfirmationModal();
  };

  const showLeaveReviewButton = getProgramReviewsQuery.data?.every(
    ({editable}) => !editable
  );
  return (
    <ReviewsTab
      closeDeleteConfirmationModal={closeDeleteConfirmationModal}
      closeUpsertModal={closeUpsertModal}
      handleDeleteReview={handleDeleteReview}
      handleSubmitUpsert={handleSubmitUpsert}
      isDeleteConfirmationModalVisible={isDeleteConfirmationModalVisible}
      isUpsertModalVisible={isUpsertModalVisible}
      openDeleteConfirmationModal={openDeleteConfirmationModal}
      openUpsertModal={openUpsertModal}
      programReviews={getProgramReviewsQuery.data || []}
      programReviewToEdit={programReviewToEdit}
      showLeaveReviewButton={showLeaveReviewButton}
    />
  );
}

export default ReviewsTabContainer;
