import {useQuery} from 'react-query';
import {UserPlanProgramItemVM} from '@models/serverModels';
import {getLicenseRq, getUserProgramPlanRq} from '@store/apiEndpoints';
import {simpleQueryFn} from '@store/queryClient';
import LicenseViewerModal from './LicenseViewerModal';
import {AddUpdateLicenseVM} from '@components/admin/pages/licenses/components/AddUpdateLicenseDrawer/AddUpdateLicenseDrawer.types';
import {useViewLicenseQuery} from '@generated/hooks';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {notify} from '@components/user/notifications';

interface ILicenseViewerModalContainerProps {
  provider: string;
  programId: number;
  licenseId: number;
  onClose: () => void;
}

function LicenseViewerModalContainer({
  provider,
  programId,
  licenseId,
  onClose,
}: ILicenseViewerModalContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  // Deprecated. We can remove this when isFeatureFlagOn.LicenseModalUpdate is permanent
  const licenseRq = getLicenseRq(licenseId);
  const getLicenseQuery = useQuery<AddUpdateLicenseVM>(
    licenseRq.queryKey,
    () => simpleQueryFn(licenseRq.path),
    {
      enabled: !!licenseId && !isFeatureFlagOn.LicenseModalUpdate,
    }
  );
  // ^ Deprecated

  const {data: license, isLoading} = useViewLicenseQuery(
    {
      programId,
    },
    {
      enabled: !!licenseId && isFeatureFlagOn.LicenseModalUpdate,
      onError: () => notify.openLicenseError(),
    }
  );

  const userProgramPlanRq = getUserProgramPlanRq(2);
  const userProgramPlanQuery = useQuery<UserPlanProgramItemVM[]>(
    userProgramPlanRq.queryKey,
    () => simpleQueryFn(userProgramPlanRq.path),
    {
      placeholderData: () => [],
    }
  );
  const program = userProgramPlanQuery.data.find(
    (program) => program.programId === programId
  );

  return (
    <LicenseViewerModal
      provider={provider}
      license={license}
      isLoading={isLoading}
      deprecatedLicenseVM={getLicenseQuery.data}
      program={program}
      isLicenseModalUpdateOn={isFeatureFlagOn.LicenseModalUpdate}
      onClose={onClose}
    />
  );
}

export default LicenseViewerModalContainer;
