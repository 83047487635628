import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import {clearLocalAcademiesActivity} from '@hooks/academies/academyUserActivity';
import {useAuth} from '@utils/oidc-auth-wrapper';

export function UserLogOut() {
  const {logout: logoutOfACM} = useAuth();

  clearLocalAcademiesActivity();
  logoutOfACM();

  return <LoadingSpinner />;
}
