// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/IncentiveAvailability.cs

enum IncentiveAvailability {
  All,
  ProgramTypeSpecific,
  InitiativeSpecific,
  NotAvailable,
}

export default IncentiveAvailability;
