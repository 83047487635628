import {MessageDto} from '@generated/interfaces';
import {i18n, k} from '@i18n/translate';
import {getInitialsOfName} from '@components/providers/utils';
import {getAvatarColorwaysStyles} from '@utils/color';
import {Avatar} from 'antd';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import './DiscussionStepFooter.scss';

interface PeerPreviewProps {
  messages: MessageDto[];
  openDrawer: () => void;
  messageCount?: number;
}

export function PeerPreview({
  messages,
  messageCount,
  openDrawer,
}: PeerPreviewProps) {
  const linkText =
    messageCount > 1
      ? i18n.t(k.DISCUSSION__VIEW_ALL_RESPONSES__FORMAT, {
          count: messageCount,
        })
      : i18n.t(k.DISCUSSION__VIEW_RESPONSE);
  return messages?.length ? (
    <div className="peer-preview-container">
      <div>
        {messages.length > 1 ? (
          [...messages]
            .slice(0, 3)
            .map((message: MessageDto, index: number) => {
              return (
                <Avatar
                  className="peer-preview-avatar"
                  key={index}
                  style={{
                    ...getAvatarColorwaysStyles(
                      message.author?.name?.length + 4
                    ),
                  }}>
                  {getInitialsOfName(message.author?.name)}
                </Avatar>
              );
            })
        ) : (
          <Avatar
            className="peer-preview-avatar"
            style={{
              ...getAvatarColorwaysStyles(
                messages[0]?.author?.name?.length + 4
              ),
            }}>
            {getInitialsOfName(messages[0]?.author?.name)}
          </Avatar>
        )}
        {messages.length > 3 && (
          <Avatar
            className="peer-preview-avatar"
            style={{
              ...getAvatarColorwaysStyles(messages.length - 3),
            }}>
            +{messages.length - 3}
          </Avatar>
        )}
      </div>
      <LearnInButton
        onClick={openDrawer}
        style={{paddingLeft: '4px', paddingRight: '4px', fontWeight: 400}}
        tag={ButtonTags.SecondaryRemovedBorder}>
        {linkText}
      </LearnInButton>
    </div>
  ) : null;
}
