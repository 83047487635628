import {useEffect, useState} from 'react';
import {ProgramTypeContainerProps} from './ProgramType.interfaces';
import ProgramTypeView from './ProgramType.view';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {StyledIcon} from './ProgramType.styled';
import {getOptionConfigs} from '@components/ProgramType/programFinderHelpers';

function ProgramTypeContainer({
  handleAction,
  form,
  data,
  currencyCode,
  maxRequestAmount,
}: ProgramTypeContainerProps) {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isSingleOption, setIsSingleOption] = useState(false);

  useEffect(() => handleAction(isSingleOption), [isSingleOption]);

  useEffect(() => {
    getOptionConfigs(data).forEach(
      ({item, labels, color, background, IconComponent}) => {
        const coaching = 'Coaching';
        const totalProgramsInBudget = item?.programs?.filter(
          ({cost, programType}) =>
            cost <= maxRequestAmount && programType !== coaching
        ).length;
        const totalPrograms = item?.programs?.filter(
          ({programType}) => programType !== coaching
        ).length;
        item &&
          setFilteredOptions((filteredOptions) => [
            ...filteredOptions,
            {
              icon: (
                <StyledIcon
                  color={color && COLORS[color]}
                  background={COLORS[background]}>
                  <IconComponent />
                </StyledIcon>
              ),
              label: i18n
                .t(
                  totalPrograms === 1
                    ? labels.single
                    : totalPrograms > 20
                      ? labels.many
                      : labels.plural,
                  {
                    amount: (totalPrograms > 20 ? '20' : totalPrograms) || 0,
                  }
                )
                .concat(
                  totalProgramsInBudget === totalPrograms ||
                    totalProgramsInBudget > 20
                    ? ''
                    : i18n.t(k.PROGRAM_FINDER__TYPE__WITHIN_BUDGET__FORMAT, {
                        amount: totalProgramsInBudget || 0,
                      })
                ),
              value: item.category,
            },
          ]);
      }
    );
  }, [data]);

  useEffect(() => {
    const programType =
      filteredOptions.length === 1 ? [filteredOptions[0]?.value] : [];
    form.setFieldsValue({programType});
    data && setIsSingleOption(filteredOptions.length === 1);
  }, [filteredOptions]);

  return (
    <ProgramTypeView
      handleAction={handleAction}
      isSingleOption={isSingleOption}
      filteredOptions={filteredOptions}
      form={form}
    />
  );
}

export default ProgramTypeContainer;
