import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';

export const Container = styled.div`
  z-index: 1000;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

export const Header = styled.div`
  background: ${COLORS.Yellow50};
  padding: 16px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: ${COLORS.Neutral950};
  font-size: ${pxToRem(16, 16)};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  padding: 8px;
  background: rgba(253, 245, 218, 1); // In mocks, but not in color library
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  color: ${COLORS.Neutral900};
  font-size: ${pxToRem(16, 16)};
`;
