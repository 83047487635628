import {CustomProgramAgendaVM} from '@models/serverModels';
import AgendaDaySection from './AgendaDaySection';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface IAgendaDaySectionContainerProps {
  agendas: CustomProgramAgendaVM[];
  customProgramId: number;
  isActivePlanItem: boolean;
  onCardSelected: (id: number | undefined) => void;
  selectedCardId: number | undefined;
}

function AgendaDaySectionContainer({
  agendas,
  customProgramId,
  isActivePlanItem,
  onCardSelected,
  selectedCardId,
}: IAgendaDaySectionContainerProps) {
  return (
    <AgendaDaySection
      agendas={agendas}
      customProgramId={customProgramId}
      handleCardSelected={onCardSelected}
      isActivePlanItem={isActivePlanItem}
      selectedCardId={selectedCardId}
    />
  );
}

export default AgendaDaySectionContainer;
