import {useEffect, useMemo} from 'react';
import CustomProgramAdminDrawer from './CustomProgramAdminDrawer';
import {CustomProgramAdminDetailVM} from '@models/serverModels';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {createCustomProgramScreenUtils} from '@utils/customProgramScreens';
import {Helmet} from 'react-helmet-async';

const {isCurrenScreenUrlPathInvalid, getCurrentScreenFromUrl} =
  createCustomProgramScreenUtils(CustomProgramScreens.Setup);

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramAdminDrawerContainerProps {
  onClose: () => void;
  visible: boolean;
  setCustomProgramForEdit?: (program: CustomProgramAdminDetailVM) => void;
  isOverlay?: boolean;
  refreshParentData?: () => void;
  title?: string;
  getScreenNavItemUrlPath: (
    customProgramId: number,
    screen: CustomProgramScreens
  ) => string;
}

function CustomProgramAdminDrawerContainer({
  onClose,
  visible,
  setCustomProgramForEdit,
  isOverlay,
  refreshParentData,
  title,
  getScreenNavItemUrlPath,
}: CustomProgramAdminDrawerContainerProps) {
  const params = useParams();
  const customProgramId = parseInt(params.customProgramId);
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const initialScreen =
    (params.screen as CustomProgramScreens) ?? CustomProgramScreens.Setup;

  useEffect(() => {
    if (isCurrenScreenUrlPathInvalid(pathname)) {
      navigate(
        getScreenNavItemUrlPath(customProgramId, CustomProgramScreens.Setup)
      );
    }
  }, [pathname, customProgramId, navigate]);

  const handleChangeScreen = (screen: CustomProgramScreens) => {
    navigate(
      getScreenNavItemUrlPath(
        customProgramId,
        !isCurrenScreenUrlPathInvalid(pathname)
          ? screen
          : CustomProgramScreens.Setup
      )
    );
  };

  const currentScreen = useMemo(() => {
    return getCurrentScreenFromUrl(pathname);
  }, [pathname]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomProgramAdminDrawer
        customProgramId={customProgramId}
        onClose={onClose}
        visible={visible}
        initialScreen={initialScreen}
        setCustomProgramForEdit={setCustomProgramForEdit}
        isOverlay={isOverlay}
        refreshParentData={refreshParentData}
        onChangeScreen={handleChangeScreen}
        currentScreen={currentScreen}
      />
    </>
  );
}

export default CustomProgramAdminDrawerContainer;
