import styled from 'styled-components';
import DetailPageBannerImage from '@components/user/components/images/DetailPageBannerImage';
import ProviderLogo from '@blocks/ProviderLogo';
import {TagsContainer} from '@blocks/LearnInTag';
import {COLORS} from '@utils/constants';

export const FirstSectionContentHeaderContainer = styled.header`
  .content-container {
    width: fit-content;
    position: relative;
    left: 46px;
    top: -24px;
    display: flex;
  }
`;

export const ProgramTitle = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  color: ${COLORS.Neutral950};
`;

export interface IFirstSectionContentHeaderProps {
  programTitle: string;
  programTags: string[];
  companyName: string;
  companyLogoSrc: string;
  customProgramHeaderImgSrc: string;
  programId: number;
}

function FirstSectionContentHeader({
  programTitle = null,
  programTags,
  companyName,
  companyLogoSrc,
  customProgramHeaderImgSrc,
  programId,
}: IFirstSectionContentHeaderProps) {
  return (
    <FirstSectionContentHeaderContainer>
      <DetailPageBannerImage
        style={{maxHeight: '296px'}}
        src={customProgramHeaderImgSrc}
        id={programId}
        imgStyle={{borderRadius: '8px'}}
      />
      <div
        className="content-container"
        style={{alignItems: !programTags?.length && 'center'}}>
        <ProviderLogo
          provider={companyName}
          providerLogo={companyLogoSrc}
          size={112}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            marginLeft: '8px',
          }}>
          <ProgramTitle>{programTitle}</ProgramTitle>
          <TagsContainer
            tags={programTags}
            style={{display: 'flex', flexWrap: 'wrap'}}
            tagStyle={{marginBottom: '0px'}}
          />
        </div>
      </div>
    </FirstSectionContentHeaderContainer>
  );
}

export default FirstSectionContentHeader;
