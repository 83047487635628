import styled from 'styled-components';
import {InputNumber} from 'antd';
import {COLORS} from '@utils/constants';

export const Container = styled.div``;

export const StyledLearnInMoneyInput = styled(InputNumber)`
  width: 100%;
  input {
    text-align: center;
    font-size: 1.5rem;
    color: ${COLORS.Neutral950};
    font-weight: 400;
    border-bottom: 1px solid ${COLORS.Blue800};
    &:focus {
      background: ${COLORS.Neutral50};
    }
  }
  .ant-input-number-handler {
    border-left: none;
  }
`;
