import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';
import React from 'react';
import styled from 'styled-components';

const SkillList = styled.section`
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
`;
const SkillTag = styled.article`
  border-radius: 16px;
  background-color: ${COLORS.Neutral200};
  color: ${COLORS.Neutral800};
  font-size: ${pxToRem(12, 16)};
  line-height: ${pxToRem(16, 16)};
  font-weight: 700;
  padding: 4px 8px;
  margin-right: 12px;
  margin-bottom: 8px;
  max-width: 230px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface AcademiesCardSkillTagsProps {
  skills: string[];
}

const AcademiesCardSkillTags: React.FC<AcademiesCardSkillTagsProps> = ({
  skills,
}) => {
  return (
    <SkillList>
      {skills.map((skill) => (
        <SkillTag key={skill} title={skill}>
          {skill}
        </SkillTag>
      ))}
    </SkillList>
  );
};

export default AcademiesCardSkillTags;
