import {i18n, k} from '@i18n/translate';
import moment from 'moment';
import LearnInTable, {
  ColumnAlignment,
  renderProgressBar,
} from '@components/reusable/LearnInTable';
import LearnInTag, {StatusTag, TagStyles} from '@blocks/LearnInTag';
import {TeamInsightsDetailVM, UserProgramInsightVM} from '@models/serverModels';
import {
  TeamInsightEmployeeDetailVm,
  UserDetailsVM,
} from '@generated/interfaces';
import {DisplayStatus, DisplayStatusWarningLevel} from '@generated/enums';
import {formatCurrency} from '@utils/moneyUtils';
import {DATE_FORMAT} from '@utils/timeUtils';
import {COLUMN_WIDTHS} from '../../utils';

export type ProgramsTablePropsType = {
  data: TeamInsightEmployeeDetailVm;
  user: UserDetailsVM;
  exchangeRate: number;
  showReportStatusTag: boolean;
};

export const ProgramsTable = ({
  data,
  user,
  exchangeRate,
  showReportStatusTag,
}: ProgramsTablePropsType) => {
  return (
    <LearnInTable
      columns={[
        {
          title: i18n.t(k.DATE__STARTED),
          width: COLUMN_WIDTHS.date,
          dataIndex: 'dateStarted',
          key: 'dateStarted',
          render: (dateStarted: string) => {
            if (!dateStarted) {
              return i18n.t(k.STATUS__NOT_STARTED);
            }

            return moment(dateStarted).format(DATE_FORMAT.MONTH_DAY_YEAR);
          },
          sorter: (a: UserProgramInsightVM, b: UserProgramInsightVM) =>
            a.dateStarted.localeCompare(b.dateStarted),
        },
        {
          title: i18n.t(k.PROVIDER),
          width: COLUMN_WIDTHS.type,
          dataIndex: 'provider',
          key: 'provider',
          sorter: (a: UserProgramInsightVM, b: UserProgramInsightVM) =>
            a.provider.localeCompare(b.provider),
        },
        {
          title: i18n.t(k.PROGRAM),
          dataIndex: 'name',
          key: 'name',
          sorter: (a: UserProgramInsightVM, b: UserProgramInsightVM) =>
            a.name.localeCompare(b.name),
        },
        {
          title: i18n.t(k.MONEY__COST),
          width: COLUMN_WIDTHS.amount,
          align: ColumnAlignment.Right,
          dataIndex: 'amount',
          key: 'amount',
          render: (amount: number) =>
            formatCurrency(amount, user?.currency, exchangeRate, {
              decimal: true,
            }),
          sorter: (a: UserProgramInsightVM, b: UserProgramInsightVM) =>
            a.amount - b.amount,
        },
        {
          title: i18n.t(k.STATUS__STATUS),
          dataIndex: 'status',
          key: 'status',
          width: COLUMN_WIDTHS.status,
          render: ({
            displayStatus,
            displayStatusWarningLevel,
            completedPercent,
            completedOn,
            skippedOn,
          }) => {
            if (showReportStatusTag) {
              return (
                <StatusTag
                  displayStatus={displayStatus}
                  displayStatusWarningLevel={displayStatusWarningLevel}
                  completedPercent={completedPercent}
                  completedOn={completedOn}
                  skippedOn={skippedOn}
                />
              );
            }
            let style = TagStyles.DarkGrey;
            switch (displayStatusWarningLevel) {
              case DisplayStatusWarningLevel.Valid:
                style = TagStyles.Green;
                break;
              case DisplayStatusWarningLevel.Warning:
                style = TagStyles.Yellow;
                break;
              case DisplayStatusWarningLevel.Error:
                style = TagStyles.Red;
                break;
              case DisplayStatusWarningLevel.Default:
                style = TagStyles.LightGrey;
                break;
              default:
                style = TagStyles.DarkGrey;
            }
            return completedPercent !== null &&
              completedPercent < 100 &&
              completedPercent > 0 ? (
              renderProgressBar(completedPercent)
            ) : (
              <LearnInTag
                label={DisplayStatus[displayStatus]}
                tagStyle={style}
              />
            );
          },
          sorter: (a: UserProgramInsightVM, b: UserProgramInsightVM) =>
            a.status.toString().localeCompare(b.status.toString()),
        },
      ]}
      dataSource={data ? data?.programs : []}
    />
  );
};
