import {LanguagePreference} from './../enums';
import {ReimbursementRequestEmail} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ReimbursementRequestEmail

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getReimbursementRequestEmailMockData(
  args?: Partial<ReimbursementRequestEmail>
): ReimbursementRequestEmail {
  return {
    amount: String(faker.datatype.number()),
    approved: faker.datatype.boolean(),
    approverName: faker.name.fullName(), // optional
    approverNote: faker.word.noun(), // optional
    companyLogoUrl: faker.internet.url(), // optional
    languagePreference: LanguagePreference.EN_US,
    name: faker.name.fullName(),
    programName: faker.name.fullName(),
    programUrl: faker.internet.url(),
    providerImageUrl: faker.image.imageUrl(), // optional
    providerName: faker.name.fullName(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ReimbursementRequestEmailMockData: ReimbursementRequestEmail = {
  amount: '5',
  approved: true,
  approverName: 'Bobby Johnson', // optional
  approverNote: 'string', // optional
  companyLogoUrl: 'https://this-person-does-not-exist.com/en', // optional
  languagePreference: LanguagePreference.EN_US,
  name: 'Bobby Johnson',
  programName: 'Bobby Johnson',
  programUrl: 'https://this-person-does-not-exist.com/en',
  providerImageUrl: 'https://loremflickr.com/640/480', // optional
  providerName: 'Bobby Johnson',
};
