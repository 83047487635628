import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {Card, Col, Empty, Rate, Row, Typography} from 'antd';
import {HeartFilled} from '@ant-design/icons';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import moment from 'moment';
import ViewProgressReportDrawer from './ViewProgressReportDrawer';
import {api} from '@store/api';
import LoadingSpinner from '../../../../../../elements/loadingSpinner/LoadingSpinner';
import {getInitialsOfName} from '@components/providers/utils';
import Avatar from 'antd/lib/avatar/avatar';
import {isEmpty} from 'lodash';
import {mapValueToProgressReportSummary} from '@utils/enumMapping/ProgressReportSummaryMapper';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {ProgramProgressUpdateResult} from '@models/serverModels';
import {DATE_FORMAT} from '@utils/timeUtils';

interface Props {
  data: ProgramProgressUpdateResult[];
  loading: boolean;
}

function ManagerReportProgressList({data, loading}: Props) {
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [isItemViewed, setIsItemViewed] = useState(false);
  const [itemInDrawer, setItemInDrawer] = useState<ProgramProgressUpdateResult>(
    {
      id: null,
      employeeName: '',
      programTitle: '',
      rating: null,
      skills: '',
      submittedDate: '',
      summary: undefined,
      viewedBy: '',
      viewedDate: undefined,
    }
  );

  const closeDrawer = (): void => {
    setShowDrawer(false);
    setIsItemViewed(false);
  };

  const openDrawer = (item: ProgramProgressUpdateResult): void => {
    setShowDrawer(true);
    setItemInDrawer(item);
    setIsItemViewed(false);
  };

  const handleItemViewed = (item: ProgramProgressUpdateResult): void => {
    if (!isItemViewed && !item.viewedBy) {
      setIsItemViewed(true);
      try {
        api.post(`user-plan/view-program-progress-details/${item.id}`);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const truncateString = (str: any, length: any) => {
    return str.length > length ? str.substr(0, length - 1) + '...' : str;
  };

  const itemViewed = (
    item: ProgramProgressUpdateResult,
    component: string
  ): string => {
    const _isItemViewed = isItemViewed || item.viewedBy;
    if (component === 'card')
      return _isItemViewed
        ? 'user-plan-item-card item-viewed-card'
        : 'user-plan-item-card item-not-viewed-card';
    if (component === 'col header')
      return _isItemViewed
        ? 'item-viewed-col-header'
        : 'item-not-viewed-col-header';
    if (component === 'col')
      return _isItemViewed ? 'item-viewed-col' : 'item-not-viewed-col';
    if (component === 'bold') return _isItemViewed ? '' : 'bold-text';
  };

  return (
    <>
      <PageTitleBar title={i18n.t(k.PROGRESS__UPDATES)} />
      <PageContentLayout>
        <Row align="top" gutter={[64, 16]}>
          <Col sm={24} lg={24}>
            {!data ? (
              <LoadingSpinner />
            ) : isEmpty(data) ? (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
              data.map((item: ProgramProgressUpdateResult, idx) => {
                return (
                  <div key={idx}>
                    <Card
                      role="article"
                      aria-label={i18n.t(k.PROGRESS__UPDATES)}
                      className={itemViewed(item, 'card')}>
                      <Row align="middle" justify="center">
                        <Col className="content-wrapper" span={22}>
                          <Col className="avatar-image" xs={1}>
                            <Avatar
                              style={{
                                backgroundColor: COLORS.Red500,
                                verticalAlign: 'middle',
                              }}
                              size="large"
                              gap={1}>
                              {getInitialsOfName(item.employeeName)}
                            </Avatar>
                          </Col>
                          <Col
                            className={
                              itemViewed(item, 'col header') + ' nameDate'
                            }
                            xs={5}>
                            <Typography className="name-item">
                              {item.employeeName || '-'}
                            </Typography>
                            <Typography className="date-item">
                              {item.submittedDate
                                ? moment(item.submittedDate).format(
                                    DATE_FORMAT.MONTH_DAY_YEAR_PADDED
                                  )
                                : '-'}
                            </Typography>
                          </Col>
                          <Col className="rating-wrapper" xs={4}>
                            <Row className="sub-header">
                              <Typography>
                                {i18n.t(k.GENERIC__RATING).toLocaleUpperCase()}
                              </Typography>
                            </Row>
                            <Row>
                              <Rate
                                value={1}
                                count={1}
                                disabled
                                allowClear={false}
                                character={<HeartFilled aria-hidden="true" />}
                                style={{color: '#ff6d85'}}
                              />
                              <Typography className="rating rating-text">
                                {item.rating || 0}/5
                              </Typography>
                            </Row>
                          </Col>
                          <Col
                            className={
                              itemViewed(item, 'col') + 'summary-wrapper'
                            }
                            xs={4}>
                            <Row className="sub-header">
                              <Typography>
                                {i18n.t(k.GENERIC__SUMMARY).toLocaleUpperCase()}
                              </Typography>
                            </Row>
                            <Row className="summary-header">
                              {mapValueToProgressReportSummary(item.summary)}
                            </Row>
                          </Col>
                          <Col className="skills-wrapper" xs={6}>
                            <Row className="sub-header">
                              <Typography>
                                {i18n.t(k.GENERIC__SKILL__PLURAL)}
                              </Typography>
                            </Row>
                            <Row className="skill-content">
                              <Typography className={itemViewed(item, 'col')}>
                                {truncateString(item.skills, 36) ||
                                  truncateString(
                                    i18n.t(k.REPORT__GENERIC_SKILL),

                                    36
                                  )}
                              </Typography>
                            </Row>
                          </Col>
                          <Col xs={4}>
                            <LearnInButton
                              onClick={() => {
                                openDrawer(item);
                                handleItemViewed(item);
                              }}
                              style={{padding: '5px 20px 5px 20px'}}
                              tag={ButtonTags.Secondary}>
                              <Typography
                                className={
                                  itemViewed(item, 'col') + ' btn-text'
                                }>
                                {i18n.t(k.CTA__VIEW__DETAIL__PLURAL)}
                              </Typography>
                            </LearnInButton>
                          </Col>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                );
              })
            )}
          </Col>
        </Row>
        {showDrawer && (
          <ViewProgressReportDrawer
            showDrawer={showDrawer}
            closeDrawer={closeDrawer}
            id={itemInDrawer.id}
            employeeName={itemInDrawer.employeeName}
            programTitle={itemInDrawer.programTitle}
            rating={itemInDrawer.rating}
            skills={itemInDrawer.skills}
            summary={itemInDrawer.summary}
            submittedDate={itemInDrawer.submittedDate}
          />
        )}
      </PageContentLayout>
    </>
  );
}

export default ManagerReportProgressList;
