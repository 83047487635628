import {LanguagePreference} from './../enums';
import {UserSettingsUpdateLanguagePreferenceRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserSettingsUpdateLanguagePreferenceRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserSettingsUpdateLanguagePreferenceRequestMockData(
  args?: Partial<UserSettingsUpdateLanguagePreferenceRequest>
): UserSettingsUpdateLanguagePreferenceRequest {
  return {
    languagePreference: LanguagePreference.EN_US,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserSettingsUpdateLanguagePreferenceRequestMockData: UserSettingsUpdateLanguagePreferenceRequest =
  {
    languagePreference: LanguagePreference.EN_US,
  };
