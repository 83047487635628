import {COLORS} from '@utils/constants';
import {Button} from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  margin: auto;
  margin-bottom: 75px;
  width: 80%;
`;

export const SearchAndButtonContainer = styled.form`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProgramTitleLink = styled.a`
  color: ${COLORS.Blue800} !important;
`;

export const FooterContent = styled.div`
  display: flex;
  margin: auto;
  width: 80%;
`;

export const FooterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-right: 0;
`;

export const ProgramsSelectedContainer = styled.div`
  margin: auto;
  margin-right: 16px;
`;

export const ClearAllContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: auto;
  margin-left: 0;
`;

export const FiltersContainer = styled.div`
  display: flex;
  padding: 24px 0 24px 0;
`;

export const FilterButtonsContainer = styled.div`
  gap: 12px;
  display: flex;
`;

export const FilterButton = styled.div`
  .react-select__control {
    padding: 0px 16px 0px 16px;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid ${COLORS.Neutral300};
    min-width: 85px;
  }
  .react-select__placeholder {
    color: ${COLORS.Neutral800};
  }
  .react-select__value-container {
    padding: 0px;
  }
  .react-select__indicator-separator {
    display: none;
  }
  .react-select__dropdown-indicator {
    padding: 0px;
    color: ${COLORS.Neutral800};
  }
  .react-select__menu {
    min-width: 195px;
    padding: 0.75rem;
    z-index: 10;
  }
  .react-select__option {
    display: flex;
  }
  .react-select__option--is-selected {
    background-color: unset;
    color: unset;
  }
`;

export const HostedChip = styled.div`
  background: ${COLORS.Blue50};
  width: 65px;
  height: 24px;
  padding: 4px 8px 4px 8px;
  border-radius: 16px;
  border: 1px solid ${COLORS.Blue200};
  font-size: 0.75rem;
  font-weight: 900;
  color: ${COLORS.Blue800};
  line-height: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
`;

export const ContentTitle = styled.button`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: ${COLORS.Blue800};
  cursor: pointer;
  &::hover {
    text-decoration: underline;
  }
`;

export const ContentsCount = styled.div`
  color: ${COLORS.Neutral700};
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 10px;
  visibility: ${({ isVisible }) => isVisible ? 'visible': 'hidden'};
`;

export const OptionLabel = styled.label`
  margin-top: 2px;
  margin-left: 8px;
`;
