import {useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {FinanceType, PlanItemType, UserProgramStatus} from '@generated/enums';
import {
  UserPlanFinanceItemVM,
  UserPlanProgramItemVM,
  UserPlanTemplate,
  UserPlanTimeItemVM,
} from '@models/serverModels';
import UserPlanTimeCard from '@components/user/pages/userPlan/components/userPlanTimeCard/UserPlanTimeCard';
import UserPlanFinanceCard from '@components/user/pages/userPlan/components/userPlanFinanceCard/UserPlanFinanceCard';
import UserPlanProgramCard from '@components/user/pages/userPlan/components/userPlanProgramCard/UserPlanProgramCard';
import {useMutation} from 'react-query';
import {getUpdateProgramStatusRm} from '@store/apiEndpoints';
import {getProgramDetailPath} from '@utils/ClientPaths';
import queryClient, {simpleMutationFn} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import {
  UserPlanCardActions,
  UserPlanItemStatusString,
} from '@models/clientEnums';
import {getPlanItemQueryInvalidateKey} from '@utils/query-invalidation-helper';
import DeleteProgramItemModal from '@blocks/deleteProgramItemModal/DeleteProgramItemModal';
import {RequestAdditionalFundsModal} from '@blocks/RequestAdditionalFundsModal';
import styled from 'styled-components';

import {
  exchangeAmount,
  exchangeAmountBack,
  formatCurrency,
  getExchangeRateBySelection,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {COLORS} from '@utils/constants';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {
  getClientAwareProviderName,
  isLearnInProvider,
} from '@utils/custom-client-helpers';
import ProviderLogo from '@blocks/ProviderLogo';
import DeleteLicensedOrCustomProgramItemModal from '@blocks/deleteLicensedOrCustomProgramItemModal/DeleteLicensedOrCustomProgramItemModal';
import {
  openProgramExternalUrl,
  TItem,
  UserPlanCardItem,
} from '@utils/itemUtils';
import {
  getApplicationStatusTagStyle,
  getApplicationStatusTitle,
  hasMenuItem,
  shouldShowExpandedCardView,
  UserPlanCardActionMenuTest,
} from '@blocks/userPlanCardActionMenu/UserPlanCardActionMenu';
import ActionButton from '@blocks/actionButton/ActionButton';

import {Breakpoints, ResponsiveContainer} from '@utils/responsiveUtils';
import LicenseViewerModal from '@blocks/licenseViewerModal/LicenseViewerModal.container';
import PreApprovalDrawer from '@components/user/pages/components/preApprovalDrawer/PreApprovalDrawer.container';
import {useNavigate} from 'react-router-dom';
import {useDeleteFromPlanById} from '@hooks/apiEndpoints/userPlan/mutations';
import ReimbursementProgressBar from '@blocks/ReimbursementProgressBar/ReimbursementProgressBar';
import {useGetCompanyPersonalizationQuery} from '@hooks/apiEndpoints/user/queries';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import ContextMenuButton from '@blocks/ContextMenu';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  showUnspentFundsDisclaimer,
  UnspentFundsDisclaimer,
} from '@blocks/UnspentFundsDisclaimer/UnspentFundsDisclaimer';
import {Divider} from 'antd';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {pxToRem} from '@utils/styleMixins';
import {useGetDurationOrAmountColumnData} from '@hooks/useGetDurationOrAmountColumnData';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useFinanceLearningBudgetsQuery} from '@generated/hooks';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const CardDataHeaderContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 18px 24px;
  @media (max-width: ${Breakpoints.Tablet}px) {
    flex-wrap: wrap;
    padding-bottom: 12px;
  }
`;

export const ReimbursementSubtitle = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0;
  text-align: left;
  color: ${COLORS.Neutral600};
`;
export const CardDataHeaderSection = styled.div<{
  width?: string;
  padding?: string;
  mobileWidth?: number;
  flexEndMobile?: boolean;
}>`
  display: flex;
  width: ${({width}) => width || '50%'};
  padding: ${({padding}) => padding || 0};
  box-sizing: border-box;
  flex-grow: 1;

  @media (max-width: ${Breakpoints.Mobile}px) {
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${Breakpoints.Mobile}px) {
    margin-top: 8px;
  }
`;

export const Label = styled.div`
  color: ${COLORS.Neutral600};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.875rem;
  margin-bottom: 4px;
`;

export const Value = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Neutral950};
`;

export const ActionButtonsContainer = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;
  flex-wrap: wrap;
`;

export const StyledDivider = styled(Divider)`
  background: ${COLORS.Neutral200};
  height: calc(100% - 16px);
  width: 1px;
`;

const GrayContainer = styled.div`
  display: flex;
  width: 100%;
  background: ${COLORS.Neutral50};
  margin-top: 16px;
`;

const commonLinkStyles = `
  display: flex;
  margin: 0 5px 0 5px;
  font-size: 1rem;
  align-items: center;
  gap: 5px;
  
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue800};
  }
`;

const SectionDivider = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

export const LinkButton = styled.a`
  ${commonLinkStyles}
`;

export const StyledButton = styled.button`
  ${commonLinkStyles}
  margin: 0;
  padding: 0 2px;
`;

const cardDataHeaderSectionPadding = '8px';

/*
|--------------------------------------------------------------------------
| Item Card Data Header
|--------------------------------------------------------------------------
*/

export function UserPlanCardDataHeader({
  onClickContainer,
  item,
  planItemTypeWithCustomProgram,
  showActions,
  handleShowPreApprovalDrawer,
  handleShowRequestLicenseDrawer,
  handleShowViewLicenseModal,
  handleSettingsActions,
  handleShowReimbursementModal,
  isReimbursementPlan,
}: {
  onClickContainer?: () => void;
  item: UserPlanCardItem &
    Partial<{
      exchangedAdditionalRequestedAmount: number;
      exchangedApprovedAmount: number;
      reimbursementApprovedAmount: number;
      reimbursementRequestedAmount: number;
    }>;
  planItemTypeWithCustomProgram: PlanItemType;
  showActions: boolean;
  handleShowPreApprovalDrawer: () => void;
  handleShowRequestLicenseDrawer?: () => void;
  handleShowViewLicenseModal?: () => void;
  handleSettingsActions: (action: UserPlanCardActions) => void;
  handleShowReimbursementModal?: () => void;
  isCustomLogo?: boolean;
  isReimbursementPlan?: boolean;
}) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isLearnInReimbursementsOn = isFeatureFlagOn.LearnInReimbursements;
  const isUnspentFundsOn = isFeatureFlagOn.UnspentFunds;
  const showFundsRequestedState =
    planItemTypeWithCustomProgram !== PlanItemType.Time &&
    planItemTypeWithCustomProgram !== PlanItemType.Finance &&
    item.requestedAmount !== 0 &&
    item.approvedAmount !== 0;
  const showActionButton =
    showActions &&
    !shouldShowExpandedCardView(planItemTypeWithCustomProgram, item.status);
  const showDropdownMenu =
    showActions &&
    item.status !== UserPlanItemStatusString.Complete &&
    hasMenuItem(
      item.status,
      item.itemType,
      item?.approvalRequired,
      item?.licenseId
    ); // unclear isCustomProgram is always false
  const showMultipleMobileRow = showActionButton && showDropdownMenu;
  const {user} = useAuth();

  // Exchange Rate and Currency
  const exchangeRate = useExchangeRate(
    (item.isLicenseProgram && item?.licenseCurrency) ||
      item?.currency ||
      MONEY_DEFAULT.currency,
    user?.currency
  );
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery();
  const exchangeRatesList = getCurrencyExchangeQuery?.data;
  const exchangeFromUsdRate = getExchangeRateBySelection(
    user?.currency,
    exchangeRatesList,
    exchangeRate
  );

  const usersCurrency = user?.currency;
  const showReimbursementLink =
    item.status ===
      (UserPlanItemStatusString.Approved ||
        UserPlanItemStatusString.InProgress) &&
    item.marketplaceProgram === true &&
    !!item.externalReimbursementApplicationUrl &&
    !item.isLicenseProgram;
  const showInternalReimbursementLink =
    isLearnInReimbursementsOn &&
    !item.isLicenseProgram &&
    (item.status === UserPlanItemStatusString.Approved ||
      (item.status === UserPlanItemStatusString.InProgress &&
        item.marketplaceProgram === true));
  const {data: companyPersonalization} = useGetCompanyPersonalizationQuery();

  const exchangedAdditionalRequestedAmount = exchangeAmountBack(
    item.requestedAmount,
    exchangeRate
  );

  item.exchangedApprovedAmount = exchangeAmount(
    item.approvedAmount,
    exchangeRate
  );

  const CardActionButtons = (
    <LabelContainer>
      <ActionButtonsContainer>
        {!showInternalReimbursementLink && showReimbursementLink && (
          <LinkButton
            style={{color: COLORS.Blue800, marginRight: '8px'}}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            href={item.externalReimbursementApplicationUrl}>
            {i18n.t(k.REIMBURSEMENT__SUBMIT)}
          </LinkButton>
        )}
        {showInternalReimbursementLink && isReimbursementPlan && (
          <StyledButton
            style={{color: COLORS.Blue800}}
            onClick={(e) => {
              e.stopPropagation();
              handleShowReimbursementModal();
            }}>
            {i18n.t(k.REIMBURSEMENT__SUBMIT)}
          </StyledButton>
        )}
        {showActionButton && (
          <ActionButton
            item={{
              ...item,
              programApprovalRequired: item.approvalRequired,
              licenseId: item.licenseId,
            }}
            itemType={planItemTypeWithCustomProgram}
            handleShowPreApprovalDrawer={handleShowPreApprovalDrawer}
            handleShowRequestLicenseDrawer={handleShowRequestLicenseDrawer}
            handleShowViewLicenseModal={handleShowViewLicenseModal}
            onSettings={handleSettingsActions}
          />
        )}

        {showDropdownMenu && (
          <ContextMenuButton
            title={item.title}
            popupContainerId={'main-content'}
            itemId={item.licenseId || item.programId || item.id}
            menuItems={UserPlanCardActionMenuTest({
              item: {
                ...item,
                programApprovalRequired: item.approvalRequired,
                licenseId: item.licenseId,
              },
              onSettings: handleSettingsActions,
            })}
            size={'middle'}
            dataCy="contextButton"
          />
        )}
      </ActionButtonsContainer>
    </LabelContainer>
  );

  const itemUniqueId = `user-plan-card-title-${item?.itemType}-${item?.id}`;

  const reimbursementAmount = useGetDurationOrAmountColumnData({
    item,
    itemType: planItemTypeWithCustomProgram,
    showFundsRequestedState,
    usersCurrency,
    exchangeRate,
    exchangeFromUsdRate,
  })?.value;

  const amount = useGetDurationOrAmountColumnData({
    item,
    itemType: planItemTypeWithCustomProgram,
    showFundsRequestedState,
    usersCurrency,
    exchangeRate,
    exchangeFromUsdRate,
  })?.label;

  return (
    /* False flag since this is role="button" */
    /* eslint-disable */
    <article
      style={{cursor: 'auto'}}
      aria-labelledby={itemUniqueId}
      onClick={onClickContainer}
      role="button"
      tabIndex={0}>
      {/* eslint-enable */}
      <CardDataHeaderContainer>
        {/* Top-Left Section */}
        <CardDataHeaderSection mobileWidth={100}>
          <ProviderLogo
            provider={item?.provider}
            providerLogo={item?.providerLogo}
            style={{marginRight: '16px'}}
            size={56}
          />

          <LabelContainer style={{overflowX: 'hidden'}}>
            <Label>
              {isLearnInProvider(item?.provider) && !!companyPersonalization
                ? getClientAwareProviderName(
                    companyPersonalization?.companyDisplayName,
                    item?.provider
                  )
                : item?.provider}
            </Label>
            <Value
              id={itemUniqueId}
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}>
              {item?.title}
            </Value>
          </LabelContainer>
        </CardDataHeaderSection>

        {/* Top-Right Section */}
        <CardDataHeaderSection
          style={{flexDirection: 'row-reverse', paddingLeft: '0px'}}
          mobileWidth={showMultipleMobileRow ? 100 : 30}>
          <ResponsiveContainer min={Breakpoints.Mobile}>
            {CardActionButtons}
          </ResponsiveContainer>{' '}
        </CardDataHeaderSection>

        <GrayContainer>
          {/* Bottom-Left Section */}
          <CardDataHeaderSection
            mobileWidth={showMultipleMobileRow ? 50 : 30}
            padding={cardDataHeaderSectionPadding}>
            <div>
              <Label>
                {
                  useGetDurationOrAmountColumnData({
                    item,
                    itemType: planItemTypeWithCustomProgram,
                    showFundsRequestedState,
                    usersCurrency,
                    exchangeRate,
                    exchangeFromUsdRate,
                  })?.label
                }
              </Label>
              <Value data-cy="programRequestAmount">
                {
                  useGetDurationOrAmountColumnData({
                    item,
                    itemType: planItemTypeWithCustomProgram,
                    showFundsRequestedState,
                    usersCurrency,
                    exchangeRate,
                    exchangeFromUsdRate,
                  })?.value
                }{' '}
                {showFundsRequestedState && (
                  <LearnInTooltip
                    title={i18n.t(k.FUND__REQUESTED_ADDITIONAL__FORMAT, {
                      localizedAmount: formatCurrency(
                        exchangedAdditionalRequestedAmount,
                        usersCurrency,
                        // Amount already exchanged, just needs formatting
                        MONEY_DEFAULT.exchangeRate,
                        {decimal: true}
                      ),
                    })}>
                    <ExclamationCircleOutlined
                      aria-hidden="true"
                      style={{
                        color: COLORS.Yellow500,
                        width: '16px',
                        height: '16px',
                        cursor: 'pointer',
                      }}
                    />
                  </LearnInTooltip>
                )}
              </Value>
            </div>
          </CardDataHeaderSection>

          {showUnspentFundsDisclaimer({
            isUnspentFundsOn,
            isLicense: item?.licenseId && item?.licenseId > -1,
            daysUntilExpiration: item?.daysUntilExpiration,
          }) && (
            <>
              <SectionDivider>
                <StyledDivider type="vertical" />
              </SectionDivider>

              <CardDataHeaderSection padding={cardDataHeaderSectionPadding}>
                <UnspentFundsDisclaimer
                  daysUntilExpiration={item?.daysUntilExpiration}
                />
              </CardDataHeaderSection>
            </>
          )}

          <SectionDivider>
            <StyledDivider type="vertical" />
          </SectionDivider>

          {/* Bottom-Right Section */}
          <CardDataHeaderSection
            padding={cardDataHeaderSectionPadding}
            mobileWidth={showMultipleMobileRow ? 50 : 30}
            flexEndMobile>
            <div>
              {!isFeatureFlagOn.NewTags && (
                <Label>{i18n.t(k.STATUS__STATUS)}</Label>
              )}

              <Value
                style={
                  isFeatureFlagOn.NewTags ? {padding: `${pxToRem(6)} 0`} : {}
                }>
                <LearnInTag
                  tagStyle={
                    showFundsRequestedState
                      ? TagStyles.Yellow
                      : getApplicationStatusTagStyle({
                          ...item,
                          licenseId: item.licenseId,
                          programApprovalRequired: item.approvalRequired,
                          isReimbursementPlan,
                        })
                  }
                  label={
                    showFundsRequestedState
                      ? i18n.t(k.FUND__REQUESTED).toLocaleUpperCase()
                      : getApplicationStatusTitle(
                          item.status,
                          item?.itemType,
                          item?.licenseId,
                          item?.approvalRequired,
                          item?.daysUntilExpiration,
                          item?.purchaseMade
                        ).toUpperCase()
                  }
                />
              </Value>
            </div>
          </CardDataHeaderSection>
        </GrayContainer>
        {isLearnInReimbursementsOn &&
        isReimbursementPlan &&
        (item?.reimbursementRequestedAmount > 0 ||
          item?.reimbursementApprovedAmount > 0) ? (
          <>
            {/**Reimbursement Section */}
            <CardDataHeaderSection
              mobileWidth={showMultipleMobileRow ? 50 : 30}
              style={{marginTop: '16px'}}>
              <div>
                <Label>
                  {i18n.t(k.REIMBURSEMENT__REIMBURSED_AMOUNT)}
                  {item?.reimbursementApprovedAmount > 0 && (
                    <LearnInTooltip
                      title={i18n.t(k.REIMBURSEMENT__TAKE_A_WHILE)}>
                      {' '}
                      <QuestionCircleOutlined
                        aria-hidden="true"
                        style={{
                          color: COLORS.Neutral600,
                          width: '16px',
                          height: '16px',
                          cursor: 'pointer',
                        }}
                      />
                    </LearnInTooltip>
                  )}
                </Label>
                <Value data-cy="programReimbursementAmount">
                  {formatCurrency(
                    item.reimbursementApprovedAmount || 0,
                    usersCurrency,
                    exchangeRate
                  )}
                </Value>
              </div>
            </CardDataHeaderSection>
            <CardDataHeaderSection
              mobileWidth={showMultipleMobileRow ? 50 : 30}
              style={{marginTop: '16px', flexDirection: 'row-reverse'}}>
              <div>
                <Label>{amount}</Label>
                <Value data-cy="approvedProgramReimbursementAmount">
                  {reimbursementAmount}
                </Value>
              </div>
            </CardDataHeaderSection>
            <div style={{width: '100%'}}>
              <ReimbursementProgressBar
                pending={item.reimbursementRequestedAmount}
                reimbursed={item.reimbursementApprovedAmount}
                approved={item.approvedAmount}
              />
              <ReimbursementSubtitle data-cy="pendingProgramReimbursementAmount">
                {formatCurrency(
                  item.reimbursementRequestedAmount || 0,
                  usersCurrency,
                  exchangeRate
                )}{' '}
                {i18n.t(k.REIMBURSEMENT__PENDING)}
              </ReimbursementSubtitle>
            </div>
          </>
        ) : null}
        <CardDataHeaderSection
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <ResponsiveContainer
            max={Breakpoints.Mobile}
            style={{marginTop: '8px'}}>
            {CardActionButtons}
          </ResponsiveContainer>
        </CardDataHeaderSection>
      </CardDataHeaderContainer>
    </article>
  );
}

/*
|--------------------------------------------------------------------------
| Item Card Factory
|--------------------------------------------------------------------------
*/

interface Props {
  planItemType: PlanItemType;
  showActions: boolean;
  item: TItem;
}

const isTimeType = (
  planItemType: PlanItemType,
  item: any
): item is UserPlanTimeItemVM => planItemType === PlanItemType.Time;
const isFinanceType = (
  planItemType: PlanItemType,
  item: any
): item is UserPlanFinanceItemVM => planItemType === PlanItemType.Finance;
const isProgramType = (
  planItemType: PlanItemType,
  item: any
): item is UserPlanProgramItemVM => planItemType === PlanItemType.Program;

/**
 * Parent component for the various PlanItemType Plan cards
 * @param planItemType
 * @param item
 * @constructor
 */
export default function UserPlanCard({planItemType, item}: Props) {
  const [
    isRemoveCustomProgramItemModalVisible,
    setIsRemoveCustomProgramItemModalVisible,
  ] = useState(false);
  const [isRemoveProgramModalVisible, setIsRemoveProgramModalVisible] =
    useState(false);
  const [showRequestAdditionalFundsModal, setShowRequestAdditionalFundsModal] =
    useState(false);
  const [showViewLicenseModal, setShowViewLicenseModal] = useState(false);
  const [showRequestLicenseDrawer, setShowRequestLicenseDrawer] =
    useState(false);
  const navigate = useNavigate();
  const getLearningBudgetInfoQuery =
    useUserPlanFinanceLearningBudgetOverviewVMQuery();
  const isReimbursementPlan =
    getLearningBudgetInfoQuery.data?.financeType ===
    FinanceType.TuitionReimbursement;

  const deletePlanItemByIdMutation = useDeleteFromPlanById({});

  const updateProgramStatus = useMutation(
    ({
      planItemId,
      targetStatus,
    }: {
      planItemId: string | number;
      targetStatus: UserProgramStatus;
    }) => {
      const updateProgramProgressRm = getUpdateProgramStatusRm(
        planItemId,
        targetStatus
      );
      return simpleMutationFn<UserPlanTemplate>(
        updateProgramProgressRm.path,
        updateProgramProgressRm.payload
      );
    },
    {
      onSuccess: (data, variables) => {
        queryClient.refetchQueries(getPlanItemQueryInvalidateKey(planItemType));
        switch (variables.targetStatus) {
          case UserProgramStatus.Applied:
            notify.updateUserProgramAppliedSuccess();
            break;
          case UserProgramStatus.Admitted:
            notify.updateUserProgramAdmittedSuccess();
            break;
          default:
            break;
        }
      },
      onError: (error, variables) => {
        switch (variables.targetStatus) {
          case UserProgramStatus.Applied:
            notify.updateUserProgramAppliedError();
            break;
          case UserProgramStatus.Admitted:
            notify.updateUserProgramAdmittedError();
            break;
          default:
            break;
        }
      },
    }
  );

  const handleSettingsActions = useMemo(() => {
    return async (action: UserPlanCardActions) => {
      switch (action) {
        case UserPlanCardActions.Request:
          setShowRequestAdditionalFundsModal(true);
          break;
        case UserPlanCardActions.Remove:
          if (
            planItemType === PlanItemType.Program &&
            item.requestRemoveConfirmation &&
            !(item as UserPlanCardItem).licenseId
          ) {
            setIsRemoveProgramModalVisible(true);
          } else {
            setIsRemoveCustomProgramItemModalVisible(true);
          }
          break;
        case UserPlanCardActions.Apply:
          await updateProgramStatus.mutate({
            planItemId: item.id,
            targetStatus: UserProgramStatus.Applied,
          });
          break;
        case UserPlanCardActions.Admit:
          await updateProgramStatus.mutate({
            planItemId: item.id,
            targetStatus: UserProgramStatus.Admitted,
          });
          break;
        case UserPlanCardActions.Start:
          await updateProgramStatus.mutate({
            planItemId: item.id,
            targetStatus: UserProgramStatus.InProgress,
          });
          if (planItemType === PlanItemType.Program)
            openProgramExternalUrl(item as UserPlanProgramItemVM);
          break;
        case UserPlanCardActions.Complete:
          await updateProgramStatus.mutate({
            planItemId: item.id,
            targetStatus: UserProgramStatus.Complete,
          });
          break;
        case UserPlanCardActions.ViewLicense:
          setShowViewLicenseModal(true);
          break;
        case UserPlanCardActions.ViewWebsite:
          if (planItemType === PlanItemType.Program)
            openProgramExternalUrl(item as UserPlanProgramItemVM);
          break;
        case UserPlanCardActions.ViewDetails:
          if (planItemType === PlanItemType.Program) {
            navigate(
              getProgramDetailPath((item as UserPlanProgramItemVM).programId)
            );
          }
          break;
        case UserPlanCardActions.Finish:
          console.warn(
            `User clicked on Finish context menu and it's not implemented.`
          );
          break;
        case UserPlanCardActions.Reapply:
          console.warn(
            `User clicked on Reapply context menu and it's not implemented.`
          );
          break;
        default:
          break;
      }
    };
  }, [deletePlanItemByIdMutation, updateProgramStatus, item]);
  const {invalidateExact} = useFinanceLearningBudgetsQuery(null, {
    enabled: false,
  });
  const deleteFromPlan = async () => {
    await deletePlanItemByIdMutation.mutate({
      itemId: item.id,
      itemType: planItemType,
    });
    setTimeout(() => {
      invalidateExact(); // Added a timeout since the API is not immediately updated
    }, 1000);
  };
  if (isTimeType(planItemType, item)) {
    return (
      <UserPlanTimeCard
        showActions={true}
        item={item}
        handleSettingsActions={handleSettingsActions}
      />
    );
  } else if (isFinanceType(planItemType, item)) {
    return (
      <UserPlanFinanceCard
        showActions={true}
        item={item}
        handleSettingsActions={handleSettingsActions}
      />
    );
  } else if (isProgramType(planItemType, item)) {
    return (
      <>
        <DeleteLicensedOrCustomProgramItemModal
          isVisible={isRemoveCustomProgramItemModalVisible}
          setIsVisible={setIsRemoveCustomProgramItemModalVisible}
          itemId={item.id}
          onOk={deleteFromPlan}
        />
        {isRemoveProgramModalVisible && (
          <DeleteProgramItemModal
            isVisible={isRemoveProgramModalVisible}
            setIsVisible={setIsRemoveProgramModalVisible}
            itemId={item.id}
            programId={item.programId}
            programCurrency={item.currency}
            approvedAmount={item.approvedAmount}
            isReimbursementPlan={isReimbursementPlan}
          />
        )}
        {showRequestAdditionalFundsModal && (
          <RequestAdditionalFundsModal
            planItemType={planItemType}
            program={item}
            onClose={() => setShowRequestAdditionalFundsModal(false)}
            isVisible={showRequestAdditionalFundsModal}
          />
        )}
        {showRequestLicenseDrawer && (
          <PreApprovalDrawer
            itemId={item.programId}
            planItemType={PlanItemType.Program}
            currency={item.currency}
            licenseId={item.licenseId}
            status={item.status}
            visible={showRequestLicenseDrawer}
            onOk={() => {
              setShowRequestLicenseDrawer(false);
            }}
            onCancel={() => {
              setShowRequestLicenseDrawer(false);
            }}
          />
        )}
        {showViewLicenseModal && (
          <LicenseViewerModal
            provider={item.provider}
            licenseId={item.licenseId}
            programId={item.programId}
            onClose={() => {
              setShowViewLicenseModal(false);
            }}
          />
        )}
        <UserPlanProgramCard
          showActions={true}
          item={item}
          handleSettingsActions={handleSettingsActions}
        />
      </>
    );
  }

  // this should never be hit
  return null;
}
