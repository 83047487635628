import './FinancialIncentives.scss';
import {Fragment, useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {
  Input,
  Card,
  Divider,
  Row,
  Typography,
  Col,
  Skeleton,
  Pagination,
} from 'antd';
import {getInitiativeIncentivesRq} from '@store/apiEndpoints';
import {CurrencyCode, IncentiveType} from '@generated/enums';
import {includes} from 'lodash';
import {COLORS} from '@utils/constants';
import {FinanceIncentiveVM, InitiativePayload} from '@models/serverModels';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import NoDataText from '@blocks/NoDataText';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import InitiativeCard from '@components/reusable/ItemSearchCard/InitiativeCard';
import {PlanItemType} from '@generated/enums';
import styled from 'styled-components';
import NoDataImage from '@blocks/NoDataImage';
import {Paginator} from '@blocks/CustomProgramUserDrawer/MembersDrawer/Paginator';

const PaginationNav = styled.nav`
  margin: 10px 0px 38px;
  display: flex;
  justify-content: flex-end;
`;

const PaginationStatus = styled.p`
  font-family: Roboto;
  font-style: normal;
  color: ${COLORS.Neutral900};
  font-weight: 500;
  font-size: 0.875rem;
  margin-bottom: 8px;
  margin-top: 20px;
`;

const {Search} = Input;
const INCENTIVES_PER_PAGE = 30;

interface Props {
  updateSelectedInitiativeItem: (
    arg1: boolean,
    incentiveId: number,
    planItemType: number
  ) => void;
  data?: InitiativePayload;
  onUserWantsToNavigateToPathRequest: (path: string) => void;
}

const InitiativeFinanceIncentivePanel = ({
  updateSelectedInitiativeItem,
  data,
  onUserWantsToNavigateToPathRequest,
}: Props) => {
  const [isResultEmpty, setIsResultEmpty] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [filteredFinanceIncentives, setFilteredFinanceIncentives] = useState<
    FinanceIncentiveVM[]
  >([]);
  const totalSelectedItems = data?.financeIncentiveIds?.length;
  const [page, setPage] = useState<number>(0);
  const [totalIncentivesCount, setTotalIncentivesCount] = useState<number>(0);
  const [showPagination, setShowPagination] = useState(false);

  const initiativeIncentivesRq = getInitiativeIncentivesRq(
    IncentiveType.Finance,
    data?.id
  );
  const initiativeIncentivesQuery = useQuery<FinanceIncentiveVM[]>(
    initiativeIncentivesRq.queryKey,
    () => simpleQueryFn(initiativeIncentivesRq.path),
    {
      placeholderData: () => [],
    }
  );

  const onSearch = (searchString: string) => {
    const _searchString = searchString.trim().toLocaleLowerCase() || '';
    setSearchString(_searchString);
  };
  const filterSearch = () => {
    const newResult = initiativeIncentivesQuery.data.filter(
      (incentive) =>
        !searchString ||
        includes(incentive.title.toLocaleLowerCase().trim(), searchString)
    );
    setPage(0);
    setFilteredFinanceIncentives(newResult);
    setIsResultEmpty(!!newResult?.length);
    setTotalIncentivesCount(newResult.length || 0);
    setShowPagination(newResult.length > INCENTIVES_PER_PAGE);
  };

  useEffect(() => {
    filterSearch();
  }, [searchString, initiativeIncentivesQuery.data]);

  const incentivesIdxMin = INCENTIVES_PER_PAGE * page;
  const incentivesIdxMax = INCENTIVES_PER_PAGE * page + INCENTIVES_PER_PAGE;
  const slicedIncentives = filteredFinanceIncentives.slice(
    incentivesIdxMin,
    incentivesIdxMax
  );

  const LoadingPlaceholder = () => {
    return (
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <Skeleton active />
        </Col>
        <Col xs={24}>
          <Skeleton active />
        </Col>
        <Col xs={24}>
          <Skeleton active />
        </Col>
      </Row>
    );
  };

  return (
    <>
      {initiativeIncentivesQuery.isSuccess &&
      !initiativeIncentivesQuery.data?.length ? (
        <NoDataText
          header={<p>{i18n.t(k.INITIATIVE__NONE__DESCRIPTION)}</p>}
          subHeader={
            <LearnInButton
              tag={ButtonTags.Secondary}
              onClick={() =>
                onUserWantsToNavigateToPathRequest?.call(
                  null,
                  '/admin/investments/incentives'
                )
              }>
              {i18n.t(k.INCENTIVE__NEW)}
            </LearnInButton>
          }
        />
      ) : (
        <Fragment>
          <Card
            role="article"
            aria-labelledby="initiative-finance-incentive-panel-title"
            className="sub-component-card">
            <Row className="sub-component-card-header">
              <Typography.Title
                className="sub-component-card-title"
                id="initiative-finance-incentive-panel-title"></Typography.Title>
            </Row>
            <Divider style={{background: COLORS.Neutral300, margin: '8px 0'}} />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '16px 0',
              }}>
              <Search
                style={{width: 200}}
                className="sub-component-card-search-bar"
                onSearch={onSearch}
                allowClear
                placeholder={i18n.t(k.A11Y__SEARCH_FOR_INCENTIVES)}
              />
              <Typography.Text
                style={{
                  fontWeight: 'normal',
                  fontSize: '1rem',
                  lineHeight: '2.5rem',
                  textAlign: 'right',
                  color: COLORS.Black,
                }}>
                {totalSelectedItems
                  ? totalSelectedItems === 1
                    ? i18n.t(k.INCENTIVE__SELECTED__SINGLE)
                    : i18n.t(k.INCENTIVE__SELECTED__PLURAL__FORMAT, {
                        count: totalSelectedItems,
                      })
                  : i18n.t(k.INCENTIVE__NONE)}{' '}
              </Typography.Text>
            </div>

            {initiativeIncentivesQuery.isSuccess && showPagination && (
              <PaginationStatus>
                {i18n.t(k.GENERIC__SHOWING_X_TO_Y_OF_Z__FORMAT, {
                  startCount: incentivesIdxMin + 1,
                  endCount: incentivesIdxMax,
                  totalCount: totalIncentivesCount,
                  itemType: i18n.t(k.INCENTIVE__PLURAL),
                })}
              </PaginationStatus>
            )}

            {slicedIncentives.map((incentive) => {
              const handleClickAddToInitiative = () => {
                updateSelectedInitiativeItem(
                  true,
                  incentive.id,
                  PlanItemType.Finance
                );
              };
              const handleClickSelected = () => {
                updateSelectedInitiativeItem(
                  false,
                  incentive.id,
                  PlanItemType.Finance
                );
              };
              return (
                <InitiativeCard
                  key={incentive.id}
                  id={incentive.id}
                  description={incentive.shortDescription}
                  itemType={PlanItemType.Finance}
                  onClickAddToInitiative={handleClickAddToInitiative}
                  onClickSelected={handleClickSelected}
                  providerImgSrc={incentive.providerLogo}
                  previewImgSrc={incentive.imageLarge}
                  selected={data?.financeIncentiveIds?.includes(incentive.id)}
                  provider={incentive.provider}
                  title={incentive.title}
                  cost={incentive.amount}
                  currency={CurrencyCode.USD}
                />
              );
            })}

            {showPagination && !!filteredFinanceIncentives.length && (
              <PaginationNav aria-label={i18n.t(k.A11Y__PAGINATION)}>
                <Paginator
                  current={page + 1}
                  onChange={(page) => {
                    setPage(page - 1);
                  }}
                  pageSize={INCENTIVES_PER_PAGE}
                  showSizeChanger={false}
                  total={filteredFinanceIncentives.length}
                />
              </PaginationNav>
            )}

            {initiativeIncentivesQuery.isLoading ||
            (!filteredFinanceIncentives.length && !isResultEmpty) ? (
              <LoadingPlaceholder />
            ) : (
              !filteredFinanceIncentives.length &&
              isResultEmpty && <NoDataImage />
            )}
          </Card>
        </Fragment>
      )}
    </>
  );
};

export default InitiativeFinanceIncentivePanel;
