import CustomProgramCohortsPage from './CustomProgramCohortsPage';
import {CustomProgramAdminDetailVM} from '@models/serverModels';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramCohortsPageContainerProps {
  customProgram: any | undefined;
  onClose: () => void;
  openCustomProgramEditDrawerAfterDuplication?: (
    customProgram: Pick<CustomProgramAdminDetailVM, 'id' | 'status'>
  ) => void;
}

function CustomProgramCohortsPageContainer({
  customProgram,
  onClose,
  openCustomProgramEditDrawerAfterDuplication,
}: CustomProgramCohortsPageContainerProps) {
  return (
    <CustomProgramCohortsPage
      visible={!!customProgram}
      onClose={onClose}
      customProgram={customProgram}
      openCustomProgramEditDrawerAfterDuplication={
        openCustomProgramEditDrawerAfterDuplication
      }
    />
  );
}

export default CustomProgramCohortsPageContainer;
