import ProjectResponseDrawer from './ProjectResponseDrawer';
import {useParams} from 'react-router-dom';
import {NewFeedbackPayload} from '@models/serverModels';
import {getSubmitContentFeedbackRm} from '@store/apiEndpoints/customProgram/mutations';
import {notify} from '@components/user/notifications';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import useOpenUpload from '@hooks/useOpenUpload';
import {CustomizableLearningCategory} from '@generated/enums';
import {
  useUserQuery,
  useCustomProgramProjectSubmissionsQuery,
} from '@generated/hooks';
import {Helmet} from 'react-helmet-async';

export interface ProjectResponseDrawerContainerProps {
  allowFeedback: boolean;
  onClose: () => void;
  title?: string;
}

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function ProjectResponseDrawerContainer({
  allowFeedback,
  onClose,
  title,
}: ProjectResponseDrawerContainerProps) {
  const {projectId, submissionId} = useParams();
  const {data: user} = useUserQuery(null);

  const openUpload = useOpenUpload(CustomizableLearningCategory.CustomProgram);

  const {
    data: customProgramProjectSubmissions,
    refetch: refetchCustomProgramProjectSubmissions,
  } = useCustomProgramProjectSubmissionsQuery(
    {
      projectId: parseInt(projectId),
    },
    {
      enabled: false,
    }
  );

  const submission = customProgramProjectSubmissions?.find(
    ({id}) => id === parseInt(submissionId)
  );

  const submitContentFeedbackMutation = useMutation(
    (feedback: string) => {
      if (!feedback) return;
      const getSubmitContentFeedback = getSubmitContentFeedbackRm({
        feedback,
        projectSubmissionId: parseInt(submissionId),
      });
      return simpleMutationFn<NewFeedbackPayload[]>(
        getSubmitContentFeedback.path,
        getSubmitContentFeedback.payload
      );
    },
    {
      onSuccess: () => {
        refetchCustomProgramProjectSubmissions();
        notify.submitCustomProgramProjectFeedbackSuccess();
      },
      onError: () => {
        notify.submitCustomProgramProjectFeedbackError();
      },
    }
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ProjectResponseDrawer
        companyId={user?.companyId}
        allowFeedback={allowFeedback}
        submission={submission}
        visible={!!submission}
        onClose={onClose}
        onClickUploadLink={openUpload}
        onClickSubmitFeedback={async (feedbackText) => {
          await submitContentFeedbackMutation.mutateAsync(feedbackText);
        }}
      />
    </>
  );
}

export default ProjectResponseDrawerContainer;
