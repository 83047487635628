import {ApprovalStatus, ApprovalType} from './../enums';
import {UpdateApprovalRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdateApprovalRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdateApprovalRequestMockData(
  args?: Partial<UpdateApprovalRequest>
): UpdateApprovalRequest {
  return {
    costCenter: faker.word.noun(), // optional
    id: faker.datatype.number(),
    notes: faker.word.noun(),
    status: ApprovalStatus.Pending,
    type: ApprovalType.PLAN_ITEM,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdateApprovalRequestMockData: UpdateApprovalRequest = {
  costCenter: 'string', // optional
  id: 1,
  notes: 'string',
  status: ApprovalStatus.Pending,
  type: ApprovalType.PLAN_ITEM,
};
