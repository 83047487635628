import {CustomProgramStatus} from './../enums';
import {UpdateCustomProgramStatus} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdateCustomProgramStatus

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdateCustomProgramStatusMockData(
  args?: Partial<UpdateCustomProgramStatus>
): UpdateCustomProgramStatus {
  return {
    id: faker.datatype.number(),
    status: CustomProgramStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdateCustomProgramStatusMockData: UpdateCustomProgramStatus = {
  id: 1,
  status: CustomProgramStatus.Draft,
};
