export const MoneyIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 0C8.95536 0 0 8.95536 0 20C0 31.0446 8.95536 40 20 40C31.0446 40 40 31.0446 40 20C40 8.95536 31.0446 0 20 0ZM20 36.6071C10.8304 36.6071 3.39286 29.1696 3.39286 20C3.39286 10.8304 10.8304 3.39286 20 3.39286C29.1696 3.39286 36.6071 10.8304 36.6071 20C36.6071 29.1696 29.1696 36.6071 20 36.6071ZM22.1295 18.9643L20.9955 18.7009V12.7054C22.692 12.9375 23.7411 14 23.9196 15.3036C23.942 15.4821 24.0938 15.6116 24.2723 15.6116H26.2768C26.4866 15.6116 26.6518 15.4286 26.6339 15.2188C26.3616 12.4375 24.0714 10.6518 21.0134 10.3438V8.88393C21.0134 8.6875 20.8527 8.52679 20.6563 8.52679H19.4018C19.2054 8.52679 19.0446 8.6875 19.0446 8.88393V10.3571C15.8839 10.6652 13.4107 12.4107 13.4107 15.6696C13.4107 18.6875 15.6339 20.1429 17.9688 20.7009L19.0714 20.9821V27.3527C17.0982 27.0893 15.9911 26.0357 15.7634 24.6161C15.7366 24.4464 15.5848 24.3214 15.4107 24.3214H13.3482C13.1384 24.3214 12.9732 24.5 12.9911 24.7098C13.192 27.1652 15.0536 29.4241 19.0268 29.7143V31.1161C19.0268 31.3125 19.1875 31.4732 19.3839 31.4732H20.6518C20.8482 31.4732 21.0089 31.3125 21.0089 31.1116L21 29.6964C24.4955 29.3884 26.9955 27.5179 26.9955 24.1607C26.9911 21.0625 25.0223 19.6786 22.1295 18.9643ZM19.067 18.2411C18.817 18.1696 18.6071 18.1027 18.3973 18.0179C16.8884 17.4732 16.1875 16.5938 16.1875 15.4598C16.1875 13.8393 17.4152 12.9152 19.067 12.7054V18.2411ZM20.9955 27.3661V21.3973C21.1339 21.4375 21.2589 21.4688 21.3884 21.4955C23.5 22.1384 24.2098 23.0312 24.2098 24.4018C24.2098 26.1473 22.8973 27.1964 20.9955 27.3661Z"
      fill="#146FE3"
    />
  </svg>
);
