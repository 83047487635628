import {AttachmentPayload} from './../interfaces';
import {
  BaseAttachmentPayloadMockData,
  getBaseAttachmentPayloadMockData,
} from './BaseAttachmentPayload.mock';
import {faker} from '@faker-js/faker';

// Interface: AttachmentPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAttachmentPayloadMockData(
  args?: Partial<AttachmentPayload>
): AttachmentPayload {
  return {
    ...getBaseAttachmentPayloadMockData(),
    fileContent: faker.word.noun(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AttachmentPayloadMockData: AttachmentPayload = {
  ...BaseAttachmentPayloadMockData,
  fileContent: 'string',
};
