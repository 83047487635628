import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import AddPersonalLearningBudgetIncentiveDrawer, {
  PlbFormFields,
} from './AddPersonalLearningBudgetIncentiveDrawer';
import {Form} from 'antd';
import queryClient, {simpleMutationFn, simplePutFn} from '@store/queryClient';
import {
  BalanceRecurType,
  FinanceAmountType,
  FinanceRecurrence,
  IncentiveType,
} from '@generated/enums';
import useDeleteCompanyIncentive from '@hooks/apiEndpoints/incentive/mutations';
import {
  getAddFinanceIncentiveRm,
  getIncentiveAdminFinanceRq,
  getIncentivesCountRq,
  getUpdateFinanceIncentiveRm,
} from '@store/apiEndpoints';
import {
  useGetFinanceDetailsVMQuery,
  useGetFinanceIncentiveAdminVMsQuery,
} from '@hooks/apiEndpoints/incentive/queries';
import learninConfirm from '@components/reusable/learnInConfirm';
import {
  FinanceIncentiveType,
  Permissions,
  RequireApprovalTypeStatus,
} from '../store/interfaces';
import {IncentiveAvailability} from '@components/user/store/interface';
import {INCENTIVE_RECURRING_TYPE} from '@models/clientEnums';
import {FormInstance} from 'antd/lib/form';
import {
  EditedIncentiveVM,
  FinanceDetailsVM,
  FinanceIncentivePayload,
} from '@models/serverModels';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import useTreatment from '@hooks/useTreatment';
import {useMutation} from 'react-query';
import AdminPersonalLearningBudgetIncentiveConfirmationModal from './AdminIncentiveConfirmationModal';
import {
  APPROVAL_GUIDELINE_MAX_COUNT,
  SHORT_DESCRIPTION_CHARACTER_MAX_COUNT,
} from '@utils/constants';
import {
  isOverMaxRichTextCharacterCount,
  richTextCharacterCount,
} from '@utils/form-validation-helpers';
import {datadogLogs} from '@datadog/browser-logs';
import {addHttpsToUrl} from '@components/reusable/LearnInForm';
import {useHasResourcesEnabledQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {
  useGetCompanyPersonalizationQuery,
  useGetUserQuery,
} from '@hooks/apiEndpoints/user/queries';
import {
  exchangeAmountBack,
  formatCurrency,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {isNil} from 'lodash';
import moment from 'moment';

export interface UnprivilegedEditor {
  getLength(): number;
  getText(index?: number, length?: number): string;
  getHTML(): string;
}

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface IAddPersonalLearningBudgetIncentiveDrawerContainerProps {
  onOk: () => void;
  onCancel: () => void;
  personalLearningBudgetId: number;
  isSunsetMonthlyRenewalOn: boolean;
}

function AddPersonalLearningBudgetIncentiveDrawerContainer({
  onOk,
  onCancel,
  personalLearningBudgetId,
  isSunsetMonthlyRenewalOn,
}: IAddPersonalLearningBudgetIncentiveDrawerContainerProps) {
  const [
    showExternalReimbursementUrlInput,
    setShowExternalReimbursementUrlInput,
  ] = useState(false);
  const [isPrepaymentIncentive, setIsPrepaymentIncentive] = useState(false);
  const [isRecurringIncentive, setIsRecurringIncentive] = useState(false);
  const [isTuitionReimbursement, setIsTuitionReimbursement] = useState(false);
  const [isReimbursementIncentive, setIsReimbursementIncentive] =
    useState(false);
  const [isDirectBillingIncentive, setIsDirectBillingIncentive] =
    useState(false);
  const [showRequireApprovalOptions, setShowRequireApprovalOptions] =
    useState(false);
  const [showAllowRemovalOption, setShowAllowRemovalOption] = useState(false);
  const [showExpirationOption, setShowExpirationOption] = useState(false);
  const [showResourceOptions, setShowResourceOptions] = useState(false);
  const [showUpsertConfirmationModal, setShowUpsertConfirmationModal] =
    useState(false);
  const [details, setDetails] = useState('');
  const [approvalGuidelines, setApprovalGuidelines] = useState('');
  const [approvalGuidelinesErrorMessage, setApprovalGuidelinesErrorMessage] =
    useState<string | undefined>();
  const [shortDescription, setShortDescription] = useState<string | undefined>(
    ''
  );
  const [shortDescriptionErrorMessage, setShortDescriptionErrorMessage] =
    useState<string | undefined>();
  const [showSpecificAmountInput, setShowSpecificAmountInput] = useState(false);
  const [showHowOftenInput, setShowHowOftenInput] = useState(false);
  const [showProratingInput, setShowProratingInput] = useState(false);
  const {isFeatureFlagOn} = useFeatureFlags();
  const isUnspentFundsOn = isFeatureFlagOn.UnspentFunds;
  const isProratingOn = isFeatureFlagOn.Prorating;
  const isDirectBillingOn = isFeatureFlagOn.DirectBilling;
  const isHideEveryYearDirectBillingOn =
    isFeatureFlagOn.HideEveryYearDirectBilling;
  const isHideEveryYearReimbursement =
    isFeatureFlagOn.HideEveryYearReimbursement;
  const isHideRenewalDate = isFeatureFlagOn.HideRenewalDate;
  const isHideRollOver = isFeatureFlagOn.HideRollOver;
  const defaultApprovalExpirationInDays = 30;

  const handleChangeApprovalGuidelines = (
    _,
    __,
    ___,
    editor: UnprivilegedEditor
  ) => {
    const value = editor.getHTML();
    const isTextOverCharCount = isOverMaxRichTextCharacterCount(
      value,
      APPROVAL_GUIDELINE_MAX_COUNT
    );

    // Handle showing error
    if (isTextOverCharCount && !approvalGuidelinesErrorMessage) {
      setApprovalGuidelinesErrorMessage(
        `Approval Guidelines must be under ${APPROVAL_GUIDELINE_MAX_COUNT}`
      );
    } else if (!isTextOverCharCount && !!approvalGuidelinesErrorMessage) {
      setApprovalGuidelinesErrorMessage(undefined);
    }
    setApprovalGuidelines(value);
  };

  const handleShortDescription = (_, __, ___, editor: UnprivilegedEditor) => {
    const value = editor.getHTML();
    const isTextOverCharCount = isOverMaxRichTextCharacterCount(
      value,
      SHORT_DESCRIPTION_CHARACTER_MAX_COUNT
    );
    const textLength = richTextCharacterCount(value);
    // Handle showing error
    if (textLength === 0 && !shortDescriptionErrorMessage) {
      setShortDescriptionErrorMessage(`Short description is required`);
    } else if (isTextOverCharCount && !shortDescriptionErrorMessage) {
      setShortDescriptionErrorMessage(
        `Short description must be under ${SHORT_DESCRIPTION_CHARACTER_MAX_COUNT}`
      );
    } else if (
      textLength !== 0 &&
      !isTextOverCharCount &&
      !!shortDescriptionErrorMessage
    ) {
      setShortDescriptionErrorMessage(undefined);
    }
    setShortDescription(value);
  };

  const [form] = Form.useForm();

  const isExternalReimbursementsOn =
    useTreatment(FeatureFlagExperiments.ExternalReimbursements) ===
    FeatureFlagTreatments.On;

  const {invalidate: invalidateFinanceIncentives} =
    useGetFinanceIncentiveAdminVMsQuery();

  const {invalidate: invalidateHasResourcesEnabled} =
    useHasResourcesEnabledQuery();

  const {invalidate: invalidateCompanyPersonalization} =
    useGetCompanyPersonalizationQuery();

  const {data: personalLearningBudget} = useGetFinanceDetailsVMQuery(
    personalLearningBudgetId
  );

  const deleteCompanyIncentiveMutation = useDeleteCompanyIncentive({
    onSuccess: () => {
      onCancel();
      invalidateFinanceIncentives();
      queryClient.refetchQueries(getIncentiveAdminFinanceRq().queryKey[0]);
      queryClient.refetchQueries(getIncentivesCountRq().queryKey[0]);
      invalidateCompanyPersonalization();
    },
  });

  const getUserQuery = useGetUserQuery();
  const usersCurrency = getUserQuery.data?.currency;
  const exchangeRate = useExchangeRate(MONEY_DEFAULT.currency, usersCurrency);

  const handleClickDelete = () => {
    learninConfirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.CTA__DELETE),
      title: i18n.t(k.PROMPT__DELETE),
      onOk() {
        deleteCompanyIncentiveMutation.mutate({
          incentiveType: IncentiveType.Finance,
          incentiveId: personalLearningBudgetId,
        });
      },
    });
  };

  const updateFormUIFromPlb = () => {
    if (!!personalLearningBudget) {
      const {
        allowedLearningResources,
        amountType,
        approvalConfig,
        approvalGuidelines,
        financeType,
        longDescription,
        shortDescription,
      } = personalLearningBudget;
      handleChangeRedemptionTypeRadioGroup(FinanceIncentiveType[financeType]);
      handleChangeMoneyUseRadioGroup(amountType);
      if (!!allowedLearningResources?.length) {
        setShowResourceOptions(true);
      }
      handleChangeRecurrenceFrequencyRadioGroup(
        personalLearningBudget[PlbFormFields.RECURRING_FREQUENCY_FIELD]
      );

      if (isApprovalRequired(approvalConfig)) {
        setShowRequireApprovalOptions(true);
      }

      setDetails(longDescription || '');
      setApprovalGuidelines(approvalGuidelines || '');
      setShortDescription(shortDescription || '');
    }
  };

  const canShowExpirationOption = () => {
    setShowExpirationOption(
      isUnspentFundsOn && isPrepaymentIncentive && showRequireApprovalOptions
    );
  };

  useEffect(() => {
    populateFormFieldsFromPlb(
      personalLearningBudget,
      form,
      usersCurrency,
      exchangeRate
    );
    updateFormUIFromPlb();
  }, [personalLearningBudget]);

  useEffect(() => {
    canShowExpirationOption();
  }, [isPrepaymentIncentive, showRequireApprovalOptions]);

  const handleClose = () => {
    form.resetFields();
    setApprovalGuidelines('');
    setDetails('');
    onCancel();
  };

  const handleChangeRedemptionTypeRadioGroup = (
    value: FinanceIncentiveType
  ) => {
    if (
      value !== FinanceIncentiveType.Prepayment &&
      value !== FinanceIncentiveType.DirectBilling
    ) {
      setIsRecurringIncentive(false);
      setShowProratingInput(false);
      form.setFieldsValue({
        [PlbFormFields.RECURRING_FREQUENCY_FIELD]: FinanceRecurrence.Never,
      });
    }
    switch (value) {
      case FinanceIncentiveType.Prepayment:
        setShowExternalReimbursementUrlInput(false);
        setIsDirectBillingIncentive(false);
        setIsPrepaymentIncentive(true);
        setIsReimbursementIncentive(false);
        setIsTuitionReimbursement(false);
        break;
      case FinanceIncentiveType.TuitionReimbursement:
        setShowExternalReimbursementUrlInput(false);
        setIsDirectBillingIncentive(false);
        setIsPrepaymentIncentive(false);
        setIsReimbursementIncentive(true);
        setIsTuitionReimbursement(true);
        break;
      case FinanceIncentiveType.ExternalReimbursement:
        setShowExternalReimbursementUrlInput(true);
        setIsDirectBillingIncentive(false);
        setIsPrepaymentIncentive(false);
        setIsReimbursementIncentive(true);
        setIsTuitionReimbursement(false);
        break;
      case FinanceIncentiveType.DirectBilling:
        setIsDirectBillingIncentive(true);
        setShowExternalReimbursementUrlInput(false);
        setIsPrepaymentIncentive(false);
        setIsReimbursementIncentive(false);
        setIsTuitionReimbursement(false);
        break;
    }
  };

  const handleChangeRecurrenceFrequencyRadioGroup = (
    value: FinanceRecurrence
  ) => {
    setIsRecurringIncentive(
      value === FinanceRecurrence.Monthly || value === FinanceRecurrence.Yearly
    );
    setShowProratingInput(value === FinanceRecurrence.Yearly && isProratingOn);
  };

  const getRequiresApprovalCheckBoxLabel = () => {
    if (isReimbursementIncentive) {
      return i18n.t(k.APPROVAL__REQUIRED_FOR_INCENTIVE);
    }
    if (isPrepaymentIncentive) {
      return i18n.t(k.APPROVAL__REQUIRED_FOR_PROGRAM);
    }
  };

  const handleClickRequireApproval = () => {
    setShowRequireApprovalOptions((value) => !value);
    if (!form.getFieldValue(PlbFormFields.PERMISSIONS_FIELD)) {
      form.setFieldsValue({
        [PlbFormFields.PERMISSIONS_FIELD]: true,
      });
    }
  };

  const handleClickAllowRemoval = () =>
    setShowAllowRemovalOption((value) => !value);

  const handleChangeMoneyUseRadioGroup = (value) => {
    const isUpToOrExact =
      value === FinanceAmountType.Exact || value === FinanceAmountType.UpTo;
    setShowSpecificAmountInput(isUpToOrExact);
    setShowHowOftenInput(isUpToOrExact);

    if (isUpToOrExact) {
      form.setFieldValue(
        [PlbFormFields.MONEY_USE_FIELD],
        FinanceAmountType.UpTo
      );
    } else {
      setShowProratingInput(false);
    }
  };

  const handleClickAllowResources = () => {
    setShowResourceOptions((value) => !value);
  };

  const handleCloseConfirmationModal = () =>
    setShowUpsertConfirmationModal(false);

  const handleSuccessSubmit = () => {
    queryClient.refetchQueries(getIncentiveAdminFinanceRq().queryKey[0]);
    queryClient.refetchQueries(getIncentivesCountRq().queryKey[0]);
    setShowUpsertConfirmationModal(false);
    handleCloseConfirmationModal();
    invalidateCompanyPersonalization();
    invalidateHasResourcesEnabled();
    onOk();
  };

  const handleErrorSubmit = (error) => {
    datadogLogs.logger.error('Error while submitting PLB', error);
    setShowUpsertConfirmationModal(false);
  };

  const addFinanceIncentiveMutation = useMutation(
    ({incentiveToAdd}: {incentiveToAdd: FinanceIncentivePayload}) => {
      const addFinanceIncentiveRm = getAddFinanceIncentiveRm(incentiveToAdd);
      return simpleMutationFn<EditedIncentiveVM>(
        addFinanceIncentiveRm.path,
        addFinanceIncentiveRm.payload
      );
    },
    {
      onSuccess: handleSuccessSubmit,
      onError: handleErrorSubmit,
    }
  );

  const updateFinanceIncentiveMutation = useMutation(
    ({incentiveToAdd}: {incentiveToAdd: FinanceIncentivePayload}) => {
      incentiveToAdd.amount =
        exchangeAmountBack(incentiveToAdd.amount, exchangeRate) ||
        incentiveToAdd.amount;

      const updateFinanceIncentiveRm =
        getUpdateFinanceIncentiveRm(incentiveToAdd);
      return simplePutFn<EditedIncentiveVM>(
        updateFinanceIncentiveRm.path,
        updateFinanceIncentiveRm.payload
      );
    },
    {
      onSuccess: handleSuccessSubmit,
      onError: handleErrorSubmit,
    }
  );

  const handleClickSubmit = async () => {
    try {
      await form.validateFields();
      // Determine if submission requires confirmation
      const data = form.getFieldsValue();

      if (shortDescriptionErrorMessage) {
        return;
      }
      const availability = data[PlbFormFields.AVAILABILITY_FIELD];
      const redemptionType = data[PlbFormFields.REDEMPTION_TYPE_FIELD];
      if (availability === IncentiveAvailability.AvailableToAll) {
        // Condition 1: Available for everyone + prepayment + program approval required
        const confirmationCondition1 =
          redemptionType === FinanceIncentiveType.Prepayment &&
          showRequireApprovalOptions;
        // Condition 2: Available for everyone + reimbursement + program approval required or not required
        const confirmationCondition2 = [
          FinanceIncentiveType.ExternalReimbursement,
          FinanceIncentiveType.TuitionReimbursement,
        ].includes(redemptionType);
        if (confirmationCondition1 || confirmationCondition2) {
          return setShowUpsertConfirmationModal(true);
        }
      }
      upsertPlb();
    } catch (error) {
      datadogLogs.logger.error(
        'Error in handleClickSubmit AddPersonalLearningBudgetIncentiveDrawer',
        error
      );
    }
  };

  const upsertPlb = async () => {
    try {
      await form.validateFields();
      const data = form.getFieldsValue();

      const approvalConfig = {
        adminPreApprovalRequired: false,
        adminRedemptionApprovalRequired: false,
        managerPreApprovalRequired: false,
        managerRedemptionApprovalRequired: false,
      };

      if (isPrepaymentIncentive) {
        approvalConfig.adminPreApprovalRequired = data[
          PlbFormFields.APPROVALS_DETAIL_FIELDS
        ]?.includes(RequireApprovalTypeStatus.PREAPPROVAL_ADMIN);

        approvalConfig.managerPreApprovalRequired = data[
          PlbFormFields.APPROVALS_DETAIL_FIELDS
        ]?.includes(RequireApprovalTypeStatus.PREAPPROVAL_MANAGER);
      }

      if (isReimbursementIncentive) {
        approvalConfig.adminPreApprovalRequired = data[
          PlbFormFields.PRE_APPROVAL_DETAILS
        ]?.includes(RequireApprovalTypeStatus.PREAPPROVAL_ADMIN);

        approvalConfig.managerPreApprovalRequired = data[
          PlbFormFields.PRE_APPROVAL_DETAILS
        ]?.includes(RequireApprovalTypeStatus.PREAPPROVAL_MANAGER);

        approvalConfig.adminRedemptionApprovalRequired = data[
          PlbFormFields.APPROVALS_DETAIL_FIELDS
        ]?.includes(RequireApprovalTypeStatus.REDEMPTION_ADMIN);

        approvalConfig.managerRedemptionApprovalRequired = data[
          PlbFormFields.APPROVALS_DETAIL_FIELDS
        ]?.includes(RequireApprovalTypeStatus.REDEMPTION_MANAGER);
      }
      if (!isTuitionReimbursement) {
        if (
          approvalConfig.adminPreApprovalRequired ||
          approvalConfig.adminRedemptionApprovalRequired
        ) {
          approvalConfig.adminPreApprovalRequired = true;
          approvalConfig.adminRedemptionApprovalRequired = true;
        }
        if (
          approvalConfig.managerPreApprovalRequired ||
          approvalConfig.managerRedemptionApprovalRequired
        ) {
          approvalConfig.managerPreApprovalRequired = true;
          approvalConfig.managerRedemptionApprovalRequired = true;
        }
      }

      const requestData: FinanceIncentivePayload = {
        allowApprovedItemsCancellation:
          showAllowRemovalOption ||
          data[PlbFormFields.PERMISSIONS_FIELD]?.includes(
            Permissions.AllowRemoval
          ),
        amount:
          ((data[PlbFormFields.MONEY_USE_FIELD] === FinanceAmountType.Exact ||
            data[PlbFormFields.MONEY_USE_FIELD] === FinanceAmountType.UpTo) &&
            exchangeAmountBack(
              parseInt(data[PlbFormFields.MONEY_FIELD]),
              exchangeRate
            )) ||
          parseInt(data[PlbFormFields.MONEY_FIELD]) ||
          0,
        amountType: data[PlbFormFields.MONEY_USE_FIELD],
        approvalGuidelines,
        approvalConfig,
        availability: data[PlbFormFields.AVAILABILITY_FIELD],
        details: details,
        featured: false,
        financeType: parseInt(data[PlbFormFields.REDEMPTION_TYPE_FIELD]),
        images: [],
        learningBudget: true,
        longDescription: details,
        programTypeIds: [],
        recurrence: data[PlbFormFields.RECURRING_FREQUENCY_FIELD],
        recurring:
          data[PlbFormFields.RECURRING_FREQUENCY_FIELD] !==
          FinanceRecurrence.Never,
        balanceRecurType:
          PlbFormFields.BALANCE_RECUR_TYPE_FIELD in data
            ? data[PlbFormFields.BALANCE_RECUR_TYPE_FIELD]
            : BalanceRecurType.NotApplicable,
        shortDescription: shortDescription,
        subTitle: data[PlbFormFields.TITLE_FIELD],
        title: data[PlbFormFields.TITLE_FIELD],
        allowedLearningResources: data[PlbFormFields.RESOURCES_FIELD] || [],
        externalReimbursementApplicationUrl: showExternalReimbursementUrlInput
          ? data[PlbFormFields.EXTERNAL_REIMBURSEMENT_EXPENSE_REPORT_URL]
          : null,
        expirationInDays: isUnspentFundsOn
          ? data[PlbFormFields.EXPIRATION_IN_DAYS]
          : null,
        isProratingEnabled: isNil(data[PlbFormFields.PRORATED_FIELD])
          ? false
          : data[PlbFormFields.PRORATED_FIELD],
        renewalMonth: data[PlbFormFields.PRORATED_RENEWAL_MONTH],
      };

      if (!!personalLearningBudget) {
        await updateFinanceIncentiveMutation.mutateAsync({
          incentiveToAdd: {id: personalLearningBudgetId, ...requestData},
        });
      } else {
        await addFinanceIncentiveMutation.mutateAsync({
          incentiveToAdd: requestData,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <AddPersonalLearningBudgetIncentiveDrawer
        approvalGuidelines={approvalGuidelines}
        approvalGuidelinesErrorMessage={approvalGuidelinesErrorMessage}
        shortDescription={shortDescription}
        shortDescriptionErrorMessage={shortDescriptionErrorMessage}
        defaultApprovalExpirationInDays={defaultApprovalExpirationInDays}
        currency={getUserQuery.data?.currency}
        details={details}
        onChangeShortDescription={handleShortDescription}
        disableEditFrequency={!!personalLearningBudget}
        disableEditing={
          !!personalLearningBudget &&
          personalLearningBudget.availability !==
            IncentiveAvailability.NotAvailable
        }
        form={form}
        disableFinanceRecurrenceMonthlyRadioOption={
          !isPrepaymentIncentive && !isDirectBillingOn
        }
        disableFinanceRecurrenceYearlyRadioOption={
          !isPrepaymentIncentive && !isDirectBillingOn
        }
        onChangeMoneyUseRadioGroup={handleChangeMoneyUseRadioGroup}
        isDirectBillingOn={isDirectBillingOn}
        isProratingOn={isProratingOn}
        isSunsetMonthlyRenewalOn={isSunsetMonthlyRenewalOn}
        isHideEveryYearDirectBillingOn={isHideEveryYearDirectBillingOn}
        isHideEveryYearReimbursement={isHideEveryYearReimbursement}
        isHideRenewalDate={isHideRenewalDate}
        isHideRollOver={isHideRollOver}
        isSubmitLoading={
          updateFinanceIncentiveMutation.isLoading ||
          addFinanceIncentiveMutation.isLoading
        }
        onChangeApprovalGuidelines={handleChangeApprovalGuidelines}
        onChangeDetails={setDetails}
        onChangeRecurrenceFrequencyRadioGroup={
          handleChangeRecurrenceFrequencyRadioGroup
        }
        onChangeRedemptionTypeRadioGroup={handleChangeRedemptionTypeRadioGroup}
        onClickAllowRemoval={handleClickAllowRemoval}
        onClickAllowResources={handleClickAllowResources}
        onClickDelete={handleClickDelete}
        onClickRequireApproval={handleClickRequireApproval}
        onClickSubmit={handleClickSubmit}
        onClose={handleClose}
        requiresApprovalCheckBoxLabel={getRequiresApprovalCheckBoxLabel()}
        showBalanceRecurTypeRadioGroup={
          (isPrepaymentIncentive && isRecurringIncentive) ||
          (isDirectBillingOn && isRecurringIncentive)
        }
        showDeleteButton={!!personalLearningBudget}
        showExternalReimbursementRadioOption={isExternalReimbursementsOn}
        showExternalReimbursementUrlInput={showExternalReimbursementUrlInput}
        showPermissionsFields={
          isReimbursementIncentive || isPrepaymentIncentive
        }
        showDirectBillingOption={isDirectBillingIncentive}
        showRequirePreApprovalFromOptions={isPrepaymentIncentive}
        showRequireRedemptionApprovalFromOptions={isReimbursementIncentive}
        showRequireApprovalOptions={showRequireApprovalOptions}
        showExpirationOption={showExpirationOption}
        showResourceOptions={showResourceOptions}
        showSpecificAmountInput={showSpecificAmountInput}
        title={i18n.t(
          !!personalLearningBudget
            ? k.INCENTIVE__LEARNING_BUDGET__UPDATE
            : k.INCENTIVE__LEARNING_BUDGET__CREATE
        )}
        showReimbursementRequireRedemptionApprovalFromOptions={
          isTuitionReimbursement
        }
        showHowOftenInput={showHowOftenInput}
        showProratingInput={showProratingInput}
      />
      <AdminPersonalLearningBudgetIncentiveConfirmationModal
        onCancel={handleCloseConfirmationModal}
        onOk={upsertPlb}
        visible={showUpsertConfirmationModal}
      />
    </>
  );
}

export default AddPersonalLearningBudgetIncentiveDrawerContainer;

/*
|--------------------------------------------------------------------------
| Util Functions
|--------------------------------------------------------------------------
*/

const isApprovalRequired = (approvalConfig: Record<string, boolean>): boolean =>
  Object.values(approvalConfig).some(Boolean);

const populateFormFieldsFromPlb = (
  plb: undefined | FinanceDetailsVM,
  form: FormInstance,
  usersCurrency,
  exchangeRate
) => {
  if (!plb) {
    return form.setFieldsValue({});
  }
  const {
    allowApprovedItemsCancellation,
    allowedLearningResources,
    amount,
    amountType,
    approvalConfig,
    approvalGuidelines,
    availability,
    balanceRecurType,
    programTypeIds,
    recurring,
    shortDescription,
    title,
    recurrence,
    financeType,
    applicationUrl,
    approvalExpirationInDays,
    isProratingEnabled,
    renewalMonth,
  } = plb;
  //add pre-approval data for LearnIn reimbursements.
  const approvalSettings = [];
  approvalConfig.adminPreApprovalRequired &&
    approvalSettings.push(RequireApprovalTypeStatus.PREAPPROVAL_ADMIN);
  approvalConfig.managerPreApprovalRequired &&
    approvalSettings.push(RequireApprovalTypeStatus.PREAPPROVAL_MANAGER);
  approvalConfig.adminRedemptionApprovalRequired &&
    approvalSettings.push(RequireApprovalTypeStatus.REDEMPTION_ADMIN);
  approvalConfig.managerRedemptionApprovalRequired &&
    approvalSettings.push(RequireApprovalTypeStatus.REDEMPTION_MANAGER);

  const permissionSettings = [];
  isApprovalRequired(approvalConfig) &&
    permissionSettings.push(Permissions.RequiresApproval);
  if (!!allowedLearningResources?.length) {
    permissionSettings.push(Permissions.RequestResources);
  }
  if (allowApprovedItemsCancellation)
    permissionSettings.push(Permissions.AllowRemoval);

  form.setFieldsValue({
    [PlbFormFields.APPROVALS_DETAIL_FIELDS]: approvalSettings,
    [PlbFormFields.PRE_APPROVAL_DETAILS]: approvalSettings,
    [PlbFormFields.AVAILABILITY_FIELD]:
      availability === null ? IncentiveAvailability.NotAvailable : availability,
    [PlbFormFields.MONEY_FIELD]: plb
      ? formatCurrency(amount, usersCurrency, exchangeRate)
      : amount,
    [PlbFormFields.MONEY_USE_FIELD]: amountType,
    [PlbFormFields.PERMISSIONS_FIELD]: permissionSettings,
    [PlbFormFields.PROGRAM_TYPE_FIELD]:
      availability === IncentiveAvailability.CertainPrograms
        ? programTypeIds
        : [],
    [PlbFormFields.RECURRING_FIELD]: recurring
      ? INCENTIVE_RECURRING_TYPE.recurring
      : INCENTIVE_RECURRING_TYPE.single,
    [PlbFormFields.RECURRING_FREQUENCY_FIELD]: recurrence,
    [PlbFormFields.BALANCE_RECUR_TYPE_FIELD]: balanceRecurType,
    [PlbFormFields.REDEMPTION_TYPE_FIELD]: FinanceIncentiveType[financeType],
    [PlbFormFields.SHORT_DESCRIPTION_FIELD]: shortDescription,
    [PlbFormFields.TITLE_FIELD]: title,
    [PlbFormFields.RESOURCES_FIELD]: allowedLearningResources,
    [PlbFormFields.APPROVAL_GUIDELINE_FIELD]: approvalGuidelines,
    [PlbFormFields.EXTERNAL_REIMBURSEMENT_EXPENSE_REPORT_URL]:
      addHttpsToUrl(applicationUrl),
    [PlbFormFields.EXPIRATION_IN_DAYS]: approvalExpirationInDays,
    [PlbFormFields.PRORATED_FIELD]: isProratingEnabled,
    [PlbFormFields.PRORATED_RENEWAL_MONTH]: renewalMonth
      ? moment(renewalMonth).toISOString(false)
      : null,
  });
};
