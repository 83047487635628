import {LearningResourceType} from '@generated/enums';
import {i18n, k} from '@i18n/translate';

export function mapLearningResourceTypeToString(
  resource: LearningResourceType
): string {
  switch (resource) {
    case LearningResourceType.Book:
      return i18n.t(k.RESOURCE__BOOK);
    case LearningResourceType.Conference:
      return i18n.t(k.RESOURCE__CONFERENCE);
    case LearningResourceType.Subscription:
      return i18n.t(k.RESOURCE__SUBSCRIPTION);
    case LearningResourceType.Other:
      return i18n.t(k.GENERIC__OTHER);
  }
}
