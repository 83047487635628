import styled, {css} from 'styled-components';
import {ProviderLogo} from '@blocks/ProviderLogo';
import {Breakpoints} from '@utils/responsiveUtils';
import {COLORS} from '@utils/constants';
import {InfoCircleOutlined} from '@ant-design/icons';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {i18n, k} from '@i18n/translate';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const CardHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  @media (max-width: ${Breakpoints.Mobile}px) {
    flex-wrap: wrap;
    gap: 15px;
  }
`;

const ProviderLogoContainer = styled.div`
  margin-right: 12px;
`;

const HeaderInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${Breakpoints.Mobile}px) {
    flex-wrap: wrap;
    gap: 15px;
  }
`;

const Title = styled.div<{length: number}>`
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  font-weight: 500;
  line-height: 1.75rem;
  @media (max-width: ${Breakpoints.Tablet}px) {
    font-size: 1.25rem;
  }
  ${({length}) => {
    if (length > 32) {
      return css`
        font-size: 1.3125rem;
        @media screen and (max-width: 740px) {
          font-size: 1.125rem;
          line-height: 1.375rem;
        }
        @media screen and (max-width: 1200px) and (min-width: 1000px) {
          font-size: 1.125rem;
          line-height: 1.375rem;
        }
      `;
    }
  }}
`;

const DataTitle = styled.div`
  font-size: 0.75rem;
  color: ${COLORS.Neutral600};
  text-align: right;
  font-weight: 700;
  font-style: normal;
  line-height: 0.875rem;
  white-space: nowrap;
  margin-bottom: 4px;
`;

const DataValue = styled.div`
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  text-align: right;
  font-weight: 500;
  line-height: 1.5rem;
  @media (max-width: ${Breakpoints.Tablet}px) {
    font-size: 1.25rem;
  }
`;

const Provider = styled.div`
  font-size: 0.75rem;
  color: ${COLORS.Neutral600};
  font-weight: 700;
  font-style: normal;
  line-height: 0.75rem;
  margin-bottom: 4px;
`;

const InfoColumn = styled.div`
  align-items: center;
  display: flex;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface ICardHeaderProps {
  provider: string;
  providerImgSrc: string | unknown;
  title: string;
  headerData?: {
    title: string;
    value: string;
    tooltip?: string;
  };
}
function CardHeader({
  provider,
  providerImgSrc,
  title,
  headerData,
}: ICardHeaderProps) {
  return (
    <CardHeaderContainer>
      {providerImgSrc !== '' && (
        <ProviderLogoContainer>
          <ProviderLogo size={77} providerLogoSrc={providerImgSrc} />
        </ProviderLogoContainer>
      )}
      <HeaderInfoContainer>
        <InfoColumn>
          <div>
            {provider && <Provider>{provider}</Provider>}
            <Title length={title.length}>{title}</Title>
          </div>
        </InfoColumn>
        <div style={{marginLeft: '18px'}}>
          <DataTitle>
            {headerData?.title}
            {headerData?.tooltip && (
              <LearnInTooltip title={headerData?.tooltip}>
                <InfoCircleOutlined aria-hidden="true" />
              </LearnInTooltip>
            )}
          </DataTitle>
          <DataValue data-cy="programCost">{headerData?.value}</DataValue>
        </div>
      </HeaderInfoContainer>
    </CardHeaderContainer>
  );
}

export default CardHeader;
