import {AcademyContentStatus} from './../enums';
import {UserPlanAcademyContentStatusUpdatePayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserPlanAcademyContentStatusUpdatePayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanAcademyContentStatusUpdatePayloadMockData(
  args?: Partial<UserPlanAcademyContentStatusUpdatePayload>
): UserPlanAcademyContentStatusUpdatePayload {
  return {
    contentId: faker.datatype.number(),
    status: AcademyContentStatus.Planned,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanAcademyContentStatusUpdatePayloadMockData: UserPlanAcademyContentStatusUpdatePayload =
  {
    contentId: 1,
    status: AcademyContentStatus.Planned,
  };
