// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/RsvpStatus.cs

enum RsvpStatus {
  None,
  Yes,
  No,
}

export default RsvpStatus;
