import CustomProgramProjectsTable from './CustomProgramProjectsTable';
import CustomProgramProjectParticipantsDrawerWithRoutes from './CustomProgramProjectParticipantsDrawerWithRoutes.container';
import {ProjectAdminVM} from '@models/serverModels';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import {useCustomProgramProjectsQuery} from '@generated/hooks';
import {i18n, k} from '@i18n/translate';
import {sortProjectByOrder} from '@utils/sort';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

function CustomProgramProjectsTableContainer({
  onClose,
  basePath,
}: {
  onClose: () => void;
  basePath: string;
}) {
  const {customProgramId} = useParams();

  const {data: customProgramProjects} = useCustomProgramProjectsQuery(
    {
      customProgramId,
    },
    {
      enabled: !!customProgramId,
      select: (customProgramProjects) =>
        customProgramProjects?.sort(sortProjectByOrder) || [],
    }
  );

  const navigate = useNavigate();

  const handleClickRow = (project: ProjectAdminVM) => {
    navigate([project?.id, 'submissions'].join('/'));
  };

  return (
    <>
      <CustomProgramProjectsTable
        dataSource={customProgramProjects}
        onClickRow={handleClickRow}
      />
      <Routes>
        <Route
          path=":projectId/submissions/*"
          element={
            <CustomProgramProjectParticipantsDrawerWithRoutes
              title={i18n.t(k.SUBMISSION__PLURAL)}
              onCloseSubmissionDrawer={(projectId) => {
                navigate([basePath, projectId, 'submissions'].join('/'));
              }}
              onClose={onClose}
            />
          }
        />
      </Routes>
    </>
  );
}

export default CustomProgramProjectsTableContainer;
