import {ApprovalsDiscriminator} from './../enums';
import {IUserRequestBasicInfo} from './../interfaces';
import {
  PreApprovalQuestionMockData,
  getPreApprovalQuestionMockData,
} from './PreApprovalQuestion.mock';
import {faker} from '@faker-js/faker';

// Interface: IUserRequestBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIUserRequestBasicInfoMockData(
  args?: Partial<IUserRequestBasicInfo>
): IUserRequestBasicInfo {
  return {
    amount: faker.datatype.number(),
    amountType: String(faker.datatype.number()),
    application: [getPreApprovalQuestionMockData()],
    discriminator: ApprovalsDiscriminator.Incentive,
    initialAdminApprovalId: faker.datatype.number(), // optional
    requestedById: faker.datatype.number(),
    requestedItemId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IUserRequestBasicInfoMockData: IUserRequestBasicInfo = {
  amount: 5,
  amountType: '5',
  application: [PreApprovalQuestionMockData],
  discriminator: ApprovalsDiscriminator.Incentive,
  initialAdminApprovalId: 1, // optional
  requestedById: 1,
  requestedItemId: 1,
};
