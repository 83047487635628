import {AcademyStepVM, AcademyLevelVM} from '@generated/interfaces';

export function getBlockingTitle(
  id: number,
  items: {id: number; title: string}[]
): string | undefined {
  if (!items) return undefined;
  const item = items?.find((i) => i.id === id);
  return item?.title || '';
}

export function hasRequirement(
  item: AcademyStepVM | AcademyLevelVM | undefined
): boolean {
  return !!item?.requiredId;
}
