import CustomProgramProjectParticipantsDrawer from './CustomProgramProjectParticipantsDrawer';
import ProjectResponseDrawerWithRoutes from './ProjectResponseDrawerWithRoutes.container';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import useOpenUpload from '@hooks/useOpenUpload';
import {CustomizableLearningCategory} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {
  useCustomProgramProjectsQuery,
  useCustomProgramProjectSubmissionsQuery,
} from '@generated/hooks';
import {Helmet} from 'react-helmet-async';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramProjectParticipantsDrawerContainerProps {
  onClose: () => void;
  allowFeedback?: boolean;
  onCloseSubmissionDrawer: (projectId: string | number) => void;
  title?: string;
}

function CustomProgramProjectParticipantsDrawerContainer({
  allowFeedback = true,
  onClose,
  onCloseSubmissionDrawer,
  title,
}: CustomProgramProjectParticipantsDrawerContainerProps) {
  const openUpload = useOpenUpload(CustomizableLearningCategory.CustomProgram);
  const {projectId, customProgramId} = useParams();
  const navigate = useNavigate();

  const {data: customProgramProjects} = useCustomProgramProjectsQuery(
    {
      customProgramId,
    },
    {
      enabled: !!projectId,
    }
  );

  const project = customProgramProjects?.find(
    ({id}) => id === parseInt(projectId)
  );

  // Get Project Submissions
  const {data: customProgramProjectSubmissions} =
    useCustomProgramProjectSubmissionsQuery(
      {
        projectId: project?.id,
      },
      {
        enabled: !!project?.id,
        select: (customProgramProjectSubmissions) =>
          customProgramProjectSubmissions.filter(
            ({submittedOn}) => !!submittedOn
          ),
      }
    );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomProgramProjectParticipantsDrawer
        dataSource={customProgramProjectSubmissions || []}
        maxSubmitted={customProgramProjectSubmissions?.length || 0}
        onClickUploadLink={openUpload}
        onClose={onClose}
        projectDueDate={project?.dueDateUtc}
        showFeedbackColumn={allowFeedback}
        title={project?.title || ''}
        totalSubmitted={customProgramProjectSubmissions?.length || 0}
        visible={!!project}
        onClickRow={(row) => navigate(`${row?.id}/feedback`)}
        onClickSubmitFeedback={(id) => navigate(`${id}/feedback`)}
        showRowHoverStyle={() => true}
      />
      <Routes>
        <Route
          path=":submissionId/feedback"
          element={
            <ProjectResponseDrawerWithRoutes
              title={i18n.t(k.FEEDBACK)}
              onClose={() => onCloseSubmissionDrawer(projectId)}
              allowFeedback={allowFeedback}
            />
          }
        />
      </Routes>
    </>
  );
}

export default CustomProgramProjectParticipantsDrawerContainer;
