/** Dynamically-built strings
 *
 *  These should remain limited. However, some keys are required to be built-up, or are served via the API. Because of this, they don't have direct references in the code, but are in use. This constant organizes our usages for our app code and supporting tools.
 *
 * Please add a comment with context for each entry.
 */
export const DYNAMIC_KEYS = {
  PREFIXES: {
    APPROVAL_FORM: 'APPROVAL_FORM__QUESTION__', // Approval form questions
    APPROVAL_FORM_CUSTOM: 'APPROVAL_FORM__CUSTOM__', // Custom approval questions
    DATE: 'DATE__', // See l10nUtils' localizeDurationFromParts()
    DATE_MONTH: 'DATE__MONTH__', // Select options built-up dynamically
    DURATION: 'DURATION__', // See l10nUtils' localizeDurationFromParts()
    GENERIC: 'GENERIC__', // Localizes non-localized words from the backend
    SKILL: 'SKILL__', // See l10nUtils' localizeSkill()
    STATUS: 'STATUS__', // See l10nUtils' localizeStatus()
    SUPPORT: 'SUPPORT__', // Localizations fed to ZenDesk's app
    TRANSACTION: 'TRANSACTION__', // CreditCardTransaction.cs/GetTransactionDescription()
  },
  SUFFIXES: {
    PLURAL: '__PLURAL', // Standard plural-form suffix
    HELPER: '__HELPER', // Standard helper text suffix for fields
  },
  SERVER: {
    INCENTIVE__COMPANY_SPONSORED: 'INCENTIVE__COMPANY_SPONSORED', // IncentiveController.cs/GetStartedIncentivesAsync()
  },
  INDICATORS: {
    MONEY_FIELD: 'HOW_MUCH', // Standard indicator of currency-related field
  },
};
