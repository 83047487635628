import React, {useEffect} from 'react';
import {Form} from 'antd';
import moment, {Moment} from 'moment';
import {InputLabel} from '@components/reusable/LearnInForm';
import {i18n, k} from '@i18n/translate';
import {DeleteOutlined} from '@ant-design/icons';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {
  disableDatesInThePast,
  incrementToQuarterHour,
  isToday,
} from '@utils/timeUtils';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {DueDateContainer, TimezoneSelect} from './DueDate.styled';
import {
  validateDueDate,
  validateDueTime,
  validateTimeZone,
} from './DueDate.validators';
import {FORM_KEYS} from '../constants';
import useTimeZones from '@hooks/useTimeZones';
import {DatePicker, TimePicker} from './DueDate.adapted';

interface DueDateProps {
  initialTimeZoneId?: string;
  initialDueDate?: Moment;
  initialDueDateTime?: Moment;
}

const DueDate: React.FC<DueDateProps> = ({
  initialTimeZoneId,
  initialDueDateTime,
  initialDueDate,
}) => {
  const form = Form.useFormInstance();
  const dueDate = Form.useWatch(FORM_KEYS.DUE_DATE, form);
  const dueTime = Form.useWatch(FORM_KEYS.DUE_TIME, form);

  const {simplifiedTimeZones} = useTimeZones(
    {
      initialTimeZoneId,
      onTimeZoneDataReady({initialTimeZone}) {
        form.setFieldValue(FORM_KEYS.DUE_DATE_TIME_ZONE, initialTimeZone?.id);
      },
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  );

  const disabledHours = (startingPoint?: Moment) => {
    const disabledTime = isToday(dueDate) ? [] : startingPoint;
    return disabledTime
      ? Array.from(Array(moment(disabledTime).hour()).keys())
      : [];
  };

  const disabledMinutes = (endingPoint: Moment = moment()) => {
    const starting = dueTime?.isValid() && dueTime;
    const sameHour = starting?.isSame(endingPoint, 'hour');
    if (sameHour) {
      const disabledTime =
        (!isToday(dueDate) || !endingPoint?.isSame()) && starting;
      return Array.from(Array(moment(disabledTime || []).minutes()).keys());
    }
    return [];
  };

  useEffect(
    /**
     * Default time should be 6:00 PM on selected day.
     * This checks if the selected day isToday and if it's after 6:00 PM today.
     */
    function setDefaultDueTime() {
      if (!form.isFieldTouched(FORM_KEYS.DUE_DATE)) {
        return;
      }

      const afterSix = isToday(dueDate) && moment().hour() >= 18;

      if (!afterSix && !dueTime) {
        form.setFieldValue(
          FORM_KEYS.DUE_TIME,
          dueDate?.set({hour: 18, minute: 0, second: 0})
        );
      } else if (isToday(dueDate) && dueDate?.isBefore()) {
        form.setFieldValue(FORM_KEYS.DUE_TIME, incrementToQuarterHour());
      }
    },
    [dueTime, form, dueDate]
  );

  return (
    <DueDateContainer>
      <div>
        <InputLabel htmlFor="due-date-input" label={i18n.t(k.DATE__DUE_DATE)} />
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Form.Item
            name={FORM_KEYS.DUE_DATE}
            initialValue={initialDueDate}
            rules={[{validator: validateDueDate(form)}]}>
            <DatePicker
              id="due-date-input"
              disabledDate={disableDatesInThePast}
              placeholder={i18n.t(k.DATE)}
            />
          </Form.Item>
          <Form.Item>
            <LearnInButton
              icon={<DeleteOutlined aria-hidden="true" />}
              aria-label={i18n.t(k.CTA__DELETE)}
              tag={ButtonTags.DestructiveRemovedBorder}
              onClick={() => {
                form.setFieldValue(FORM_KEYS.DUE_DATE, undefined);
                form.setFieldValue(FORM_KEYS.DUE_TIME, undefined);
              }}
            />
          </Form.Item>
        </div>
      </div>
      <div>
        <InputLabel htmlFor="due-time-input" label={i18n.t(k.TIME)} />
        <Form.Item
          name={FORM_KEYS.DUE_TIME}
          initialValue={initialDueDateTime}
          rules={[{validator: validateDueTime(form)}]}>
          <TimePicker
            id="due-time-input"
            format="h:mm a"
            use12Hours={true}
            minuteStep={15}
            size="large"
            disabledHours={disabledHours}
            disabledMinutes={() => disabledMinutes()}
          />
        </Form.Item>
      </div>
      <TimezoneSelect>
        <Form.Item
          name={FORM_KEYS.DUE_DATE_TIME_ZONE}
          rules={[{validator: validateTimeZone(form)}]}>
          <LearnInSelect
            aria-label={i18n.t(k.VALIDATION__SELECT_TIMEZONE)}
            showSearch
            bordered={false}
            showArrow={false}
            filterOption={(input: string, {value}: {value: string}) => {
              return value
                ?.toLocaleLowerCase()
                .includes(input.toLocaleLowerCase());
            }}
            dropdownMatchSelectWidth={false}>
            {simplifiedTimeZones?.map((timeZone) => (
              <LearnInSelectOption
                key={timeZone.id}
                data-testid={`${timeZone.id}-select-option`}
                value={timeZone.id}>
                {timeZone.displayName}
              </LearnInSelectOption>
            ))}
          </LearnInSelect>
        </Form.Item>
      </TimezoneSelect>
    </DueDateContainer>
  );
};

export default DueDate;
