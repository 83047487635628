import {
  BalanceRecurType,
  CreditCardApprovalStatus,
  FinanceAmountType,
  FinanceRecurrence,
  FinanceType,
} from './../enums';
import {UserPlanFinanceLearningBudgetOverviewVM} from './../interfaces';
import {
  CreditCardOverviewVMMockData,
  getCreditCardOverviewVMMockData,
} from './CreditCardOverviewVM.mock';
import {faker} from '@faker-js/faker';

// Interface: UserPlanFinanceLearningBudgetOverviewVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanFinanceLearningBudgetOverviewVMMockData(
  args?: Partial<UserPlanFinanceLearningBudgetOverviewVM>
): UserPlanFinanceLearningBudgetOverviewVM {
  return {
    advancedAmount: faker.datatype.number(),
    awaitingApproval: faker.datatype.number(),
    balance: faker.datatype.number(),
    creditCard: getCreditCardOverviewVMMockData(), // optional
    creditCardApprovalStatus: CreditCardApprovalStatus.NoCreditCardExists,
    description: faker.lorem.lines(),
    financeAmountType: FinanceAmountType.UpTo,
    financeIncentiveAmount: faker.datatype.number(),
    financeIncentiveId: faker.datatype.number(),
    financeType: FinanceType.Loan,
    limitOnRequestedFunds: faker.datatype.number(),
    pendingReimbursement: faker.datatype.number(),
    programPreApprovalRequired: faker.datatype.boolean(),
    recurrenceFrequency: FinanceRecurrence.Never,
    recurrenceType: BalanceRecurType.NotApplicable,
    remaining: faker.datatype.number(),
    spentAmount: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    totalApprovedFunds: faker.datatype.number(),
    userPlanItemId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanFinanceLearningBudgetOverviewVMMockData: UserPlanFinanceLearningBudgetOverviewVM =
  {
    advancedAmount: 5,
    awaitingApproval: 123,
    balance: 123,
    creditCard: CreditCardOverviewVMMockData, // optional
    creditCardApprovalStatus: CreditCardApprovalStatus.NoCreditCardExists,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    financeAmountType: FinanceAmountType.UpTo,
    financeIncentiveAmount: 5,
    financeIncentiveId: 1,
    financeType: FinanceType.Loan,
    limitOnRequestedFunds: 123,
    pendingReimbursement: 123,
    programPreApprovalRequired: true,
    recurrenceFrequency: FinanceRecurrence.Never,
    recurrenceType: BalanceRecurType.NotApplicable,
    remaining: 123,
    spentAmount: 5,
    title: 'Advanced Web Design',
    totalApprovedFunds: 123,
    userPlanItemId: 1,
  };
