import {CustomProgramUserDrawer} from '@blocks/CustomProgramUserDrawer';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {
  IsPreviewQueryParam,
  sendToUserPrograms,
  userAcademyRoute,
  userCustomProgramDetailRoute,
} from '@utils/ClientPaths';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {useBackToLxpLink} from '@hooks/integrated/useBackToLxpLink';
import {useAuth} from '@utils/oidc-auth-wrapper';

function CustomProgramCurriculumPage({title}: {title?: string}) {
  const params = useParams();
  const {user} = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const customProgramId = Number(params.customProgramId);

  const isFromAcademy = () =>
    searchParams.has('from') &&
    searchParams.get('from').startsWith('academyId');

  const linkToLxpAcademies = useBackToLxpLink(user, {
    type: 'custom-program',
    resourceId: customProgramId,
  });
  const handleClose = () => {
    if (isFromAcademy()) {
      const academyId = searchParams.get('from').replace('academyId', '');
      navigate(userAcademyRoute(academyId));
    } else if (searchParams.has(IsPreviewQueryParam)) {
      navigate(-1);
    } else {
      const onCloseWithLxpOption = () => {
        window.location.href = linkToLxpAcademies;
      };
      linkToLxpAcademies
        ? onCloseWithLxpOption()
        : navigate(sendToUserPrograms());
    }
  };

  const handleSetEffectiveCustomProgramId = (customProgramId: number) => {
    navigate(
      userCustomProgramDetailRoute(
        customProgramId,
        searchParams.has(IsPreviewQueryParam)
      )
    );
  };

  useSetPageTitle(title);

  return (
    <CustomProgramUserDrawer
      isActivePlanItem
      customProgramId={customProgramId}
      setEffectiveCustomProgramId={handleSetEffectiveCustomProgramId}
      visible
      onClose={handleClose}
    />
  );
}

export default CustomProgramCurriculumPage;
