import {DurationUnitType} from './../enums';
import {CustomProgramSearchResult} from './../interfaces';
import {
  CustomProgramCohortSearchVMMockData,
  getCustomProgramCohortSearchVMMockData,
} from './CustomProgramCohortSearchVM.mock';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramSearchResult

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramSearchResultMockData(
  args?: Partial<CustomProgramSearchResult>
): CustomProgramSearchResult {
  return {
    id: faker.datatype.number(),
    imageLarge: faker.word.noun(), // optional
    imageSmall: faker.word.noun(), // optional
    nextCohort: getCustomProgramCohortSearchVMMockData(), // optional
    presenceType: faker.word.noun(), // optional
    programLength: faker.word.noun(), // optional
    programLengthUnit: DurationUnitType.Days,
    ptft: faker.word.noun(), // optional
    rank: faker.datatype.number(),
    selected: faker.datatype.boolean(),
    selectedCohort: getCustomProgramCohortSearchVMMockData(), // optional
    shortDescription: faker.lorem.lines(), // optional
    skills: faker.word.noun(),
    timeCommitHoursWeek: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramSearchResultMockData: CustomProgramSearchResult = {
  id: 1,
  imageLarge: 'string', // optional
  imageSmall: 'string', // optional
  nextCohort: CustomProgramCohortSearchVMMockData, // optional
  presenceType: 'string', // optional
  programLength: 'string', // optional
  programLengthUnit: DurationUnitType.Days,
  ptft: 'string', // optional
  rank: 123,
  selected: true,
  selectedCohort: CustomProgramCohortSearchVMMockData, // optional
  shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', // optional
  skills: 'string',
  timeCommitHoursWeek: 5,
  title: 'Advanced Web Design',
};
