import {BudgetAllocationType, FinanceRecurrence} from './../enums';
import {BudgetOverviewResponse} from './../interfaces';
import {
  ApprovalConfigMockData,
  getApprovalConfigMockData,
} from './ApprovalConfig.mock';
import {
  IBudgetToOldFinanceIncentiveEnumMockData,
  getIBudgetToOldFinanceIncentiveEnumMockData,
} from './IBudgetToOldFinanceIncentiveEnum.mock';
import {faker} from '@faker-js/faker';

// Interface: BudgetOverviewResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getBudgetOverviewResponseMockData(
  args?: Partial<BudgetOverviewResponse>
): BudgetOverviewResponse {
  return {
    ...getIBudgetToOldFinanceIncentiveEnumMockData(),
    activeUserBudgetCount: faker.datatype.number(),
    allocationType: BudgetAllocationType.PreAllocated,
    allowDirectBilling: faker.datatype.boolean(),
    allowExternalReimbursement: faker.datatype.boolean(),
    allowReimbursement: faker.datatype.boolean(),
    allowVirtualCard: faker.datatype.boolean(),
    approvalConfig: getApprovalConfigMockData(),
    approvalGuidelines: faker.word.noun(),
    creditCardWithAuthorizationCount: faker.datatype.number(),
    description: faker.lorem.lines(),
    eligibleEmployees: faker.datatype.number(),
    id: faker.datatype.number(),
    recurrenceFrequency: FinanceRecurrence.Never,
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const BudgetOverviewResponseMockData: BudgetOverviewResponse = {
  ...IBudgetToOldFinanceIncentiveEnumMockData,
  activeUserBudgetCount: 5,
  allocationType: BudgetAllocationType.PreAllocated,
  allowDirectBilling: true,
  allowExternalReimbursement: true,
  allowReimbursement: true,
  allowVirtualCard: true,
  approvalConfig: ApprovalConfigMockData,
  approvalGuidelines: 'string',
  creditCardWithAuthorizationCount: 5,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  eligibleEmployees: 123,
  id: 1,
  recurrenceFrequency: FinanceRecurrence.Never,
  title: 'Advanced Web Design',
};
