import {ApprovalStatus, ApproverType, RequestApprovalType} from './../enums';
import {GetUserRequestApprovalVMsQuery} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: GetUserRequestApprovalVMsQuery

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getGetUserRequestApprovalVMsQueryMockData(
  args?: Partial<GetUserRequestApprovalVMsQuery>
): GetUserRequestApprovalVMsQuery {
  return {
    approverType: ApproverType.Manager,
    currentCompanyId: faker.datatype.number(),
    currentUserCompanyId: faker.datatype.number(),
    includeManagerOverrideApprovals: faker.datatype.boolean(),
    isAscending: faker.datatype.boolean(),
    pageNumber: faker.datatype.number(),
    pageSize: faker.datatype.number(),
    requestApprovalType: RequestApprovalType.Program,
    searchTerm: faker.word.noun(), // optional
    sortBy: faker.word.noun(),
    status: ApprovalStatus.Pending,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const GetUserRequestApprovalVMsQueryMockData: GetUserRequestApprovalVMsQuery =
  {
    approverType: ApproverType.Manager,
    currentCompanyId: 1,
    currentUserCompanyId: 1,
    includeManagerOverrideApprovals: true,
    isAscending: true,
    pageNumber: 123,
    pageSize: 123,
    requestApprovalType: RequestApprovalType.Program,
    searchTerm: 'string', // optional
    sortBy: 'string',
    status: ApprovalStatus.Pending,
  };
