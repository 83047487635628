import {MessageDto} from './../interfaces';
import {AuthorMockData, getAuthorMockData} from './Author.mock';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {faker} from '@faker-js/faker';

// Interface: MessageDto

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getMessageDtoMockData(args?: Partial<MessageDto>): MessageDto {
  return {
    ...getBaseEntityMockData(),
    author: getAuthorMockData(), // optional
    authorId: faker.datatype.number(),
    content: faker.word.noun(), // optional
    discussionId: String(faker.datatype.number()),
    id: String(faker.datatype.number()),
    parentId: String(faker.datatype.number()), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const MessageDtoMockData: MessageDto = {
  ...BaseEntityMockData,
  author: AuthorMockData, // optional
  authorId: 1,
  content: 'string', // optional
  discussionId: '1',
  id: '1',
  parentId: '1', // optional
};
