import {i18n, k} from '@i18n/translate';
import moment from 'moment';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {Divider, Space, Tabs} from 'antd';
import {COLORS} from '@utils/constants';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import {DataPanel} from '@components/reusable/LearnInTable';
import {AdminPaths, ManagerPaths} from '@utils/ClientPaths';
import {
  TeamInsightEmployeeDetailVm,
  UserDetailsVM,
} from '@generated/interfaces';
import {formatCurrency} from '@utils/moneyUtils';
import {AcademiesTable} from './AcademiesTable';
import {ProgramsTable} from './ProgramsTable';
import {ResourcesTable} from './ResourcesTable';
import {TransactionsTable} from './TransactionsTable';
import {DATE_FORMAT} from '@utils/timeUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import BudgetCardView from '@components/user/pages/userPlan/components/personalLearningBudgetCard/BudgetCard/BudgetCard.view';
import {
  useFinanceLearningBudgetsQuery,
  usePersonalizationQuery,
  useUserDetailsQuery,
} from '@generated/hooks';
import {UseQueryResult} from 'react-query/types/react';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {BodySpan, H3} from '@components/reusable/Typography';
import NoDataText from '@blocks/NoDataText';
import {
  useDrilldownCanSeeTransactions,
  useDrilldownHasEmptyData,
  useDrilldownPlanActivityTitle,
} from './Drilldown.hooks';
import {FinanceType} from '@generated/enums';

const {TabPane} = Tabs;

const TableSectionHeader = styled.h3`
  font-weight: 500;
  font-size: 1rem;
  color: ${COLORS.Neutral950};
  margin-bottom: 8px;
`;

const TableSection = styled.div`
  margin-bottom: 16px;
`;

const EmptyTable = styled.div`
  margin: 0 auto;
  max-width: 680px;
`;

const Container = styled.div`
  max-width: 1102px;
  margin: 0 auto;
`;

const Card = styled.div`
  background-color: ${COLORS.White};
  border-radius: 8px;
  border: 1px solid ${COLORS.Neutral200};
`;

const SectionContainer = styled(Card)`
  margin-top: 16px;
  display: flex;
  weight: 400;
  margin-bottom: 16px;
`;

const SectionContent = styled.p`
  font-size: 0.875rem;
  margin-bottom: 0px;
  color: ${COLORS.Neutral950};
  line-height: 1.25rem;
  text-align: right;
`;

const StyledDivider = styled(Divider)`
  border-top: 1px solid ${COLORS.Neutral200};
  margin: 16px 0;
`;

const FlexContainer = styled.span`
  display: flex;
  justify-content: space-between;
`;

const DrilldownDrawer = styled(LearnInPageDrawer)<{isAdminUsers?: number}>`
  ${({isAdminUsers}) =>
    isAdminUsers &&
    `
.ant-drawer-content-wrapper > div > div > div.ant-drawer-body {
  background: ${COLORS.Blue50};
}`}
`;

const StyledSpace = styled(Space)`
  background: ${COLORS.Neutral50};
  border-radius: 4px;
  width: 100%;
  padding: 24px;
  margin-left: 40px;
`;

const Row = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
`;

const Summary = ({
  data,
  user,
  exchangeRate,
}: {
  data: TeamInsightEmployeeDetailVm;
  user: UserDetailsVM;
  exchangeRate: number;
}) => {
  const showSpentAmount = data?.isEligibleForPersonalLearningBudget;
  const dataPanelItems = [
    {
      label: i18n.t(k.GENERIC__LAST_LOGIN),
      display: (
        <SectionContent>
          {data?.lastLoginUtc && data?.lastLoginUtc !== ''
            ? moment(data?.lastLoginUtc).format(DATE_FORMAT.MONTH_DAY_YEAR)
            : i18n.t(k.GENERIC__HAS_NOT_LOGGED_IN)}
        </SectionContent>
      ),
    },
  ];
  if (showSpentAmount) {
    dataPanelItems.push(
      {
        label: i18n.t(k.MONEY__AMOUNT_SPENT),
        display: (
          <SectionContent>
            {formatCurrency(
              Math.abs(data?.spentAmount),
              user?.currency,
              exchangeRate,
              {decimal: true}
            )}
          </SectionContent>
        ),
      },
      {
        label: i18n.t(k.APPROVAL__APPROVED_AMOUNT),
        display: (
          <SectionContent>
            {formatCurrency(data?.approvedAmount, user?.currency, exchangeRate, {
              decimal: true,
            })}
          </SectionContent>
        ),
      }
    );
  }
  return (
    <SectionContainer>
      <DataPanel items={dataPanelItems} />
    </SectionContainer>
  );
};

export const Drilldown = ({
  data,
  user,
  exchangeRate,
  showReportStatusTag,
  drillDownQuery,
}: {
  data: TeamInsightEmployeeDetailVm;
  user: UserDetailsVM;
  exchangeRate: number;
  showReportStatusTag: boolean;
  drillDownQuery: UseQueryResult;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname} = useLocation();
  const isUserDetails = pathname.includes('people');
  const {isFeatureFlagOn} = useFeatureFlags();
  // Caution: `firstName` being parsed like this isn't based on a safe assumption: spaces uncommonly exist within first names.
  const [firstName] = data.employeeName.split(' ');
  const {data: personalization} = usePersonalizationQuery(null);
  const {data: financeLearningBuds} = useFinanceLearningBudgetsQuery(null);
  const showCreditCard = false;
  const isTranslatePlbFieldsOn = isFeatureFlagOn.TranslatePlbFields;
  const showEmptyState = useDrilldownHasEmptyData(data);
  const planActivityTitle = useDrilldownPlanActivityTitle({
    isUsersTabOn: true,
    isYourPlanNameChangeOn: isFeatureFlagOn.YourPlanNameChange,
    firstName,
  });

  const {data: userDetails} = useUserDetailsQuery({
    queryParams: {userCompanyId: data.userCompanyId},
  });

  const canSeeTransactions = useDrilldownCanSeeTransactions({
    flagOn: true,
    hasPrepayment: user?.plbType === FinanceType.Prepayment,
    isEligibleForPLB: userDetails?.isEligibleForPersonalLearningBudget,
  });

  const hasAccess =
    userDetails?.isEligibleForPersonalLearningBudget ||
    userDetails?.canViewAcademiesFeature;

  const emptyData = '—';

  const formattedDate = (date) =>
    !!date && moment(date).format(DATE_FORMAT.MONTH_DAY_YEAR);

  const formatOutput = (
    output: string,
    formatter?: () => string | JSX.Element
  ) => (!!output ? (formatter ? formatter() : output) : emptyData);

  // Each `key` matches a `userDetails` object key.
  const userRoles = [
    {key: 'isAdmin', label: i18n.t(k.ADMIN)},
    {key: 'isManager', label: i18n.t(k.GENERIC__MANAGER)},
    {key: 'isLearnInAdmin', label: `Degreed ${i18n.t(k.GENERIC__SUPPORT)}`},
    {key: 'isDev', label: i18n.t(k.DEVELOPER)},
  ];

  const hasAtLeastOneRole = userRoles.some((ur) => userDetails?.[ur.key]);

  return (
    <DrilldownDrawer
      title={`${data.employeeName}`}
      onClose={() =>
        navigate(
          isUserDetails
            ? location.state?.from || AdminPaths.People
            : ManagerPaths.TeamInsights
        )
      }
      visible={true}
      drawerStyle={{transform: 'none'}}
      contentContainerStyle={{
        background: COLORS.White,
      }}
      isAdminUsers>
      <Container>
        {user?.plbType !== null &&
          userDetails?.isEligibleForPersonalLearningBudget && (
            <BudgetCardView
              hasProgramsOrResources={true}
              userProgramPlan={data.programs}
              direction={showCreditCard ? 'vertical' : 'horizontal'}
              exchangeRate={exchangeRate}
              financeLearningBuds={financeLearningBuds}
              drillDownData={data}
              isFinanceLearningBudsError={drillDownQuery.isError}
              isFinanceLearningBudsLoading={drillDownQuery.isLoading}
              isFinanceLearningBudsSuccess={drillDownQuery.isSuccess}
              isTranslatePlbFieldsOn={isTranslatePlbFieldsOn}
              navigate={navigate}
              personalization={personalization}
              showCreditCard={showCreditCard}
              user={user}
              isPeopleTab={true}
            />
          )}
        <LearnInTabs>
          {
            <TabPane
              tab={i18n.t(k.GENERIC__DETAIL__PLURAL)}
              key="details"
              style={{
                background: COLORS.Blue50,
                position: 'absolute',
                width: '100%',
                marginTop: '24px',
              }}>
              <LearnInContainer tag={ContainerTags.CommonCard}>
                <Space
                  direction="vertical"
                  size="small"
                  style={{minWidth: '30%'}}>
                  <div>
                    <H3 fontWeight={700}>{data.employeeName}</H3>
                    {formatOutput(userDetails?.email, () => (
                      <a href={`mailto:${userDetails?.email}`}>
                        {userDetails?.email}
                      </a>
                    ))}
                    <div color={COLORS.Neutral900}>
                      {formatOutput(
                        userDetails?.uniqueId,
                        () => `#${userDetails?.uniqueId}`
                      )}
                    </div>
                  </div>
                  <StyledDivider />
                  <FlexContainer>
                    <BodySpan color={COLORS.Neutral900}>
                      {i18n.t(k.DATE__START)}
                    </BodySpan>
                    <BodySpan color={COLORS.Neutral900} fontWeight={500}>
                      {formatOutput(userDetails?.startDate, () =>
                        formattedDate(userDetails?.startDate)
                      )}
                    </BodySpan>
                  </FlexContainer>
                  <FlexContainer>
                    <BodySpan color={COLORS.Neutral900}>
                      {i18n.t(k.GENERIC__CREATED_DATE)}
                    </BodySpan>
                    <BodySpan color={COLORS.Neutral900} fontWeight={500}>
                      {formatOutput(userDetails?.createdDate, () =>
                        formattedDate(userDetails?.createdDate)
                      )}
                    </BodySpan>
                  </FlexContainer>
                  <FlexContainer>
                    <BodySpan color={COLORS.Neutral900}>
                      {i18n.t(k.GENERIC__LAST_LOGIN)}
                    </BodySpan>
                    <BodySpan color={COLORS.Neutral900} fontWeight={500}>
                      {formatOutput(userDetails?.lastLogin, () =>
                        formattedDate(userDetails?.lastLogin)
                      )}
                    </BodySpan>
                  </FlexContainer>
                </Space>
                <StyledSpace direction="vertical" size="middle">
                  <H3 fontWeight={500}>
                    {i18n.t(k.PEOPLE__PROFILE_CONFIGURATION)}
                  </H3>
                  <Row>
                    <Column>
                      <BodySpan size="small" color={COLORS.Neutral900}>
                        {i18n.t(k.EMPLOYEE__JOB_ROLE)}
                      </BodySpan>
                      <BodySpan color={COLORS.Neutral950}>
                        {formatOutput(userDetails?.jobRole)}
                      </BodySpan>
                    </Column>
                    <Column>
                      <BodySpan size="small" color={COLORS.Neutral900}>
                        {i18n.t(k.GENERIC__MANAGER_NAME)}
                      </BodySpan>
                      <BodySpan color={COLORS.Neutral950}>
                        {formatOutput(userDetails?.managerName)}
                      </BodySpan>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <BodySpan size="small" color={COLORS.Neutral900}>
                        {i18n.t(k.COST_CENTER)}
                      </BodySpan>
                      <BodySpan color={COLORS.Neutral950}>
                        {formatOutput(userDetails?.costCenter)}
                      </BodySpan>
                    </Column>
                    <Column>
                      <BodySpan size="small" color={COLORS.Neutral900}>
                        {i18n.t(k.PEOPLE__ACCOUNT_STATUS)}
                      </BodySpan>
                      <BodySpan
                        color={
                          userDetails?.active
                            ? COLORS.Neutral950
                            : COLORS.Red700
                        }>
                        {userDetails?.active
                          ? i18n.t(k.PEOPLE__ACTIVE)
                          : i18n.t(k.PEOPLE__DEACTIVATED)}
                      </BodySpan>
                    </Column>
                  </Row>
                  <Row>
                    <Column>
                      <BodySpan size="small" color={COLORS.Neutral900}>
                        {i18n.t(k.ACCESS__PLURAL)}
                      </BodySpan>
                      <Column>
                        {hasAccess ? (
                          <>
                            <BodySpan color={COLORS.Neutral950}>
                              {userDetails?.isEligibleForPersonalLearningBudget &&
                                i18n.t(k.CONTENT_MARKETPLACE)}
                            </BodySpan>
                            <BodySpan color={COLORS.Neutral950}>
                              {userDetails?.canViewAcademiesFeature &&
                                i18n.t(k.ACADEMY__PLURAL)}
                            </BodySpan>
                          </>
                        ) : (
                          <BodySpan color={COLORS.Neutral950}>
                            {emptyData}
                          </BodySpan>
                        )}
                      </Column>
                    </Column>
                    <Column>
                      <BodySpan size="small" color={COLORS.Neutral900}>
                        {i18n.t(k.PEOPLE__USER_ROLE)}
                      </BodySpan>
                      <Column>
                        {hasAtLeastOneRole ? (
                          userRoles.map(
                            ({key, label}) =>
                              userDetails?.[key] && (
                                <BodySpan key={key} color={COLORS.Neutral950}>
                                  {label}
                                </BodySpan>
                              )
                          )
                        ) : (
                          <BodySpan color={COLORS.Neutral950}>
                            {emptyData}
                          </BodySpan>
                        )}
                      </Column>
                    </Column>
                  </Row>
                </StyledSpace>
              </LearnInContainer>
            </TabPane>
          }
          <TabPane
            tab={planActivityTitle}
            key="plan_details"
            style={{
              background: COLORS.Blue50,
              position: 'absolute',
              width: '100%',
              marginTop: '24px',
            }}>
            {showEmptyState ? (
              <EmptyTable>
                <NoDataText
                  header={i18n.t(k.INSIGHTS__NO_ACTIVITY__TITLE__FORMAT, {
                    firstName,
                  })}
                  subHeader={i18n.t(
                    k.INSIGHTS__NO_ACTIVITY__DESCRIPTION__FORMAT,
                    {
                      firstName,
                    }
                  )}
                />
              </EmptyTable>
            ) : (
              <>
                {data.resources.length !== 0 && (
                  <TableSection>
                    <TableSectionHeader>
                      {i18n.t(k.RESOURCE__PLURAL)}
                    </TableSectionHeader>
                    <Card>
                      <ResourcesTable
                        data={data}
                        user={user}
                        exchangeRate={exchangeRate}
                        showReportStatusTag={showReportStatusTag}
                      />
                    </Card>
                  </TableSection>
                )}
                {data.programs.length !== 0 && (
                  <TableSection>
                    <TableSectionHeader>
                      {i18n.t(k.PROGRAM__PLURAL)}
                    </TableSectionHeader>
                    <Card>
                      <ProgramsTable
                        data={data}
                        user={user}
                        exchangeRate={exchangeRate}
                        showReportStatusTag={showReportStatusTag}
                      />
                    </Card>
                  </TableSection>
                )}
                {data.academies.length !== 0 && (
                  <TableSection>
                    <TableSectionHeader>
                      {i18n.t(k.ACADEMY__PLURAL)}
                    </TableSectionHeader>
                    <Card>
                      <AcademiesTable
                        data={data}
                        showReportStatusTag={showReportStatusTag}
                      />
                    </Card>
                  </TableSection>
                )}
              </>
            )}
          </TabPane>
          {canSeeTransactions && (
            <TabPane
              tab={i18n.t(k.TRANSACTION__PLURAL)}
              key="transactions"
              style={{
                background: COLORS.Blue50,
                position: 'absolute',
                width: '100%',
                marginTop: '24px',
              }}>
              {data.transactions.length !== 0 ? (
                <Card>
                  <TransactionsTable
                    data={data}
                    user={user}
                    exchangeRate={exchangeRate}
                  />
                </Card>
              ) : (
                <EmptyTable>
                  <NoDataText
                    header={i18n.t(
                      k.INSIGHTS__USER_NO_TRANSACTIONS__TITLE__FORMAT,
                      {
                        firstName,
                      }
                    )}
                    subHeader={i18n.t(
                      k.INSIGHTS__USER_NO_TRANSACTIONS__DESCRIPTION
                    )}
                  />
                </EmptyTable>
              )}
            </TabPane>
          )}
        </LearnInTabs>
      </Container>
    </DrilldownDrawer>
  );
};
