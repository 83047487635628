import {i18n, k} from '@i18n/translate';
import {Divider} from 'antd';
import styled from 'styled-components';
import MarketplaceItemSearchCard from '@components/reusable/ItemSearchCard/MarketplaceProgramSearchCard.container';
import {PlanItemType} from '@generated/enums';
import {CategoryName} from '@components/user/pages/userPlan/store/interface';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const SectionHeader = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 24px;
`;

const SectionText = styled.p`
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
  margin-bottom: 24px;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IIncentivesTabProps {
  incentives: any[]; // ProgramInitiativeIncentiveVM | IncentiveInitiativeProgramVM;
  itemType: PlanItemType;
}

function IncentivesTab({incentives, itemType}: IIncentivesTabProps) {
  // If NOT Program Detail Page, show program incentives
  const programIncentives = itemType !== PlanItemType.Program ? incentives : [];
  // If Program Detail Page, show Finance and Finance incentive
  const timeIncentives =
    itemType === PlanItemType.Program
      ? incentives.filter(({type}) => type === CategoryName.TIME)
      : [];
  const financeIncentives =
    itemType === PlanItemType.Program
      ? incentives.filter(({type}) => type === CategoryName.FINANCE)
      : [];
  return (
    <>
      {!!programIncentives.length && (
        <>
          <SectionHeader>{i18n.t(k.PROGRAM__INCENTIVE__PLURAL)}</SectionHeader>
          <SectionText>{i18n.t(k.INCENTIVE__AVAILABLE_TO)}</SectionText>
          {programIncentives.map(
            ({
              id,
              selected,
              title,
              provider,
              cost,
              currency,
              licenseId,
              daysUntilExpiration,
              shortDescription,
              imageSmall,
              providerLogo,
            }) => {
              return (
                <MarketplaceItemSearchCard
                  key={id}
                  description={shortDescription}
                  cost={cost}
                  currency={currency}
                  licenseId={licenseId}
                  daysUntilExpiration={daysUntilExpiration}
                  provider={provider}
                  previewImgSrc={imageSmall}
                  title={title}
                  selected={selected}
                  id={id}
                  providerLogo={providerLogo}
                  itemType={PlanItemType.Program}
                  approvedAmount={cost}
                />
              );
            }
          )}
        </>
      )}

      {!!timeIncentives.length && (
        <>
          <SectionHeader>{i18n.t(k.TIME__INCENTIVE__PLURAL)}</SectionHeader>
          <SectionText>{i18n.t(k.INCENTIVE__TIME__DESCRIPTION)}</SectionText>
          {timeIncentives.map(
            ({
              id,
              selected,
              title,
              provider,
              cost,
              currency,
              licenseId,
              daysUntilExpiration,
              shortDescription,
              imageSmall,
              providerLogo,
            }) => {
              return (
                <MarketplaceItemSearchCard
                  key={id}
                  description={shortDescription}
                  cost={cost}
                  currency={currency}
                  licenseId={licenseId}
                  daysUntilExpiration={daysUntilExpiration}
                  provider={provider}
                  previewImgSrc={imageSmall}
                  title={title}
                  selected={selected}
                  id={id}
                  providerLogo={providerLogo}
                  itemType={PlanItemType.Time}
                  approvedAmount={cost}
                />
              );
            }
          )}
        </>
      )}
      {!!financeIncentives.length && !!timeIncentives.length && (
        <Divider
          style={{
            background: COLORS.Neutral300,
            display: 'block',
            margin: '44px 0px 56px',
          }}
        />
      )}
      {!!financeIncentives.length && (
        <>
          <SectionHeader>{i18n.t(k.INCENTIVE__FINANCE__PLURAL)}</SectionHeader>
          <SectionText>{i18n.t(k.INCENTIVE__FINANCE__DESCRIPTION)}</SectionText>
          {financeIncentives.map(
            ({
              id,
              selected,
              title,
              provider,
              cost,
              currency,
              licenseId,
              daysUntilExpiration,
              shortDescription,
              imageSmall,
              providerLogo,
            }) => {
              return (
                <MarketplaceItemSearchCard
                  key={id}
                  description={shortDescription}
                  cost={cost}
                  currency={currency}
                  licenseId={licenseId}
                  daysUntilExpiration={daysUntilExpiration}
                  provider={provider}
                  previewImgSrc={imageSmall}
                  title={title}
                  selected={selected}
                  id={id}
                  providerLogo={providerLogo}
                  itemType={PlanItemType.Finance}
                  approvedAmount={cost}
                />
              );
            }
          )}
        </>
      )}
    </>
  );
}

export default IncentivesTab;
