import {useState} from 'react';
import styled from 'styled-components';
import {Form} from 'antd';
import LearnInDrawer, {
  FooterButtonsContainer,
} from '@components/reusable/LearnInDrawer';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {CurrencyCode, PreApprovalQuestionInputType} from '@generated/enums';
import {APPROVAL_AUTOPOPULATE_MULTIPLIER, COLORS} from '@utils/constants';
import {
  ApplicationQuestionVM,
  UserPlanReimbursementItemVM,
} from '@models/serverModels';
import {FormInstance} from 'antd/lib/form';
import {i18n, k} from '@i18n/translate';
import {CloseCircleOutlined} from '@ant-design/icons';
import LearnInSelect, {
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {ProviderLogo} from '@blocks/ProviderLogo';
import {
  CardDataHeaderSection,
  Label,
  LabelContainer,
  Value,
} from '@blocks/userPlanCard/UserPlanCard';
import PreApprovalFormItem from '@components/user/pages/components/PreApprovalFormItem/PreApprovalFormItem.container';
import {BodySpan} from '@components/reusable/Typography';

const FormItem = styled(Form.Item)`
  .ant-form-item-label
    > label.ant-form-item-required:not(
      .ant-form-item-required-mark-optional
    )::before {
    display: none;
  }

  .ant-form-item-label > label::after {
    content: '';
  }
`;

const StyledLearnInSelect = styled(LearnInSelect)`
  height: ${(props) => (props.itemId ? '54px' : '')};

  & .ant-select-selector {
    height: 100% !important;
  }
`;

const IconButton = styled.button`
  background: none;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 0 10px;

  path {
    fill: ${COLORS.Neutral600} !important;
  }

  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }
`;

const ItemSelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export interface ReimbursementDrawerProps {
  form: FormInstance;
  formQuestions: ApplicationQuestionVM[];
  isOpen: boolean;
  onClose: () => void;
  onOk: (files) => void;
  items: UserPlanReimbursementItemVM[];
  currency: CurrencyCode;
  selectedItem: UserPlanReimbursementItemVM;
  setCurrentItem: (number) => void;
  filesProgress?: any[];
  isLoading: boolean;
  filesToUpload?: any[];
  setFilesToUpload?: (files: any[]) => void;
  deleteFunction?: (idx) => void;
}

export default function ReimbursementDrawer({
  form,
  formQuestions,
  isOpen,
  onClose,
  onOk,
  items,
  currency,
  selectedItem,
  setCurrentItem,
  filesProgress,
  isLoading,
  deleteFunction,
  filesToUpload,
  setFilesToUpload,
}: ReimbursementDrawerProps) {
  return (
    <LearnInDrawer
      closeButtonPlacement={'right'}
      title={i18n.t(k.REIMBURSEMENT__SUBMIT)}
      onClose={onClose}
      open={isOpen}
      footer={
        <FooterButtonsContainer>
          <LearnInButton tag={ButtonTags.SecondaryBlack} onClick={onClose}>
            {i18n.t(k.CTA__CANCEL)}
          </LearnInButton>
          <LearnInButton
            disabled={isLoading}
            tag={ButtonTags.DrawerFooterPrimary}
            onClick={async () => {
              onOk({files: filesToUpload});
            }}>
            {i18n.t(k.CTA__SUBMIT)}
          </LearnInButton>
        </FooterButtonsContainer>
      }>
      <Form form={form} layout={'vertical'}>
        <ItemSelectContainer>
          <FormItem
            name="itemId"
            style={{
              marginBottom: '24px',
              display: 'block',
              width: '100%',
            }}
            label={
              <BodySpan fontWeight={500}>
                {i18n.t(k.APPROVAL_FORM__QUESTION__WHAT_REIMBURSEMENT_FOR)}
              </BodySpan>
            }
            rules={[
              {
                required: true,
                message: i18n.t(k.VALIDATION__REQUIRED),
              },
            ]}>
            <StyledLearnInSelect
              itemId={selectedItem?.id}
              placeholder={i18n.t(k.VALIDATION__SELECT_ONE)}
              onChange={(e) => setCurrentItem(e)}
              value={selectedItem?.id}>
              {/*Programs */}
              {items?.map((item) => {
                return (
                  <LearnInSelectOption key={item.id} value={item.id}>
                    {/* Top-Left Section */}
                    <CardDataHeaderSection mobileWidth={100} width={'100%'}>
                      <ProviderLogo
                        providerLogoSrc={item?.logo}
                        style={{marginRight: '16px'}}
                        size={56}
                      />
                      <LabelContainer style={{overflowX: 'hidden'}}>
                        <Label>{item?.subTitle}</Label>
                        <Value
                          style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}>
                          {item?.title}
                        </Value>
                      </LabelContainer>
                    </CardDataHeaderSection>
                  </LearnInSelectOption>
                );
              })}
            </StyledLearnInSelect>
          </FormItem>
          {selectedItem?.id && (
            <IconButton
              onClick={() => {
                form.setFieldsValue({itemId: ''});
                setCurrentItem('');
              }}>
              <CloseCircleOutlined />
            </IconButton>
          )}
        </ItemSelectContainer>
        {formQuestions?.map((question) => (
          <PreApprovalFormItem
            filesToUpload={filesToUpload}
            filesProgress={filesProgress}
            form={form}
            formQuestions={formQuestions}
            isCostField={
              question.inputType === PreApprovalQuestionInputType.Money
            }
            item={question}
            key={question?.id}
            maxLength={300}
            programCostFeesMultiplier={APPROVAL_AUTOPOPULATE_MULTIPLIER}
            programCurrency={currency}
            setFilesToUpload={setFilesToUpload}
            deleteFunction={deleteFunction}
            showCount
          />
        ))}
      </Form>
    </LearnInDrawer>
  );
}
