import {GlobalOutlined, LockOutlined} from '@ant-design/icons';
import Grid from '@blocks/Grid';
import RadioButton from '@blocks/RadioButton';
import {InputLabel} from '@components/reusable/LearnInForm';
import {i18n, k} from '@i18n/translate';
import {CSSProperties} from 'react';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface RestrictedAccessFormProps {
  column?: boolean;
  hasCheckmark?: boolean;
  itemStyles?: CSSProperties;
  restrictedAccess: boolean;
  setRestrictedAccess: (restricted: boolean) => void;
  isAcademy?: boolean;
}

function RestrictedAccessForm({
  column,
  hasCheckmark = false,
  itemStyles,
  restrictedAccess,
  setRestrictedAccess,
  isAcademy,
}: RestrictedAccessFormProps) {
  return (
    <fieldset aria-labelledby="access-input-label" aria-required="true">
      <legend className="tw-sr-only">
        Choose whether the academy will have public access or restricted access
      </legend>
      <InputLabel
        id="access-input-label"
        htmlFor="access-input"
        label={`${i18n.t(
          isAcademy ? k.ACADEMY__WHAT_TYPE : k.PROGRAM__WHAT_TYPE
        )}`}
        required
      />
      <Grid.Container column={column} id="access-input">
        <Grid.Box half style={itemStyles}>
          <RadioButton
            Icon={GlobalOutlined}
            title={i18n.t(k.ACCESS__PUBLIC)}
            content={i18n.t(
              isAcademy
                ? k.ACCESS__ALL_EMPLOYEES__ACADEMY
                : k.ACCESS__ALL_EMPLOYEES__PROGRAM
            )}
            onClick={() => setRestrictedAccess(false)}
            isSelected={restrictedAccess === false}
            hasCheckmark={restrictedAccess === false && hasCheckmark}
            required
          />
        </Grid.Box>
        <Grid.Box half style={itemStyles}>
          <RadioButton
            Icon={LockOutlined}
            title={i18n.t(k.ACCESS__RESTRICTED)}
            content={i18n.t(
              isAcademy
                ? k.ACCESS__PERMITTED_EMPLOYEES__ACADEMY
                : k.ACCESS__PERMITTED_EMPLOYEES__PROGRAM
            )}
            onClick={() => setRestrictedAccess(true)}
            isSelected={restrictedAccess === true}
            hasCheckmark={restrictedAccess === true && hasCheckmark}
            required
          />
        </Grid.Box>
      </Grid.Container>
    </fieldset>
  );
}

export default RestrictedAccessForm;
