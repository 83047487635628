import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Form} from 'antd';
import {formatCurrency, exchangeAmount} from '@utils/moneyUtils';
import {EmployeeCurrentFinanceIncentiveDetailsVM} from '@models/serverModels';
import {FormInstance} from 'antd/lib/form';
import {LearnInMoneyInput} from '@components/reusable/Input/Input.style';
import LearnInDrawer, {
  FooterButtonsContainer,
} from '@components/reusable/LearnInDrawer';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {ArrowRightOutlined, EditOutlined} from '@ant-design/icons';
import {FormItems} from '@blocks/addFundsDrawer/AddFundsDrawer.container';
import TextArea from 'antd/lib/input/TextArea';
import {CurrencyCode} from '@generated/enums';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {handleInputErrorText} from '@utils/itemUtils';
import {ErrorMessage} from '@components/reusable/LearnInForm';
import {pxToRem} from '@utils/styleMixins';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const DrawerContainer = styled(LearnInDrawer)`
  .ant-drawer-body {
    background: ${COLORS.Neutral50};
    padding: 0;
  }
  .ant-drawer-header {
    padding-left: 0;
  }
  .ant-drawer-title {
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: ${COLORS.Neutral950};
  }
`;

const FormContainer = styled(Form)`
  background: ${COLORS.White};
  padding: 0 24px;
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-form-item:nth-of-type(2) {
    img {
      padding-bottom: 10px;
    }
  }
  .ant-form-item:nth-of-type(3),
  .ant-form-item:nth-of-type(4) {
    span div {
      &:hover {
        background: none !important;
      }
    }
    img {
      height: 28px;
      width: 28px;
    }
  }
  .admin-notes-input {
    border-top: 1px solid ${COLORS.Neutral200};
    .ant-form-item-control-input-content {
      display: block;
    }
    .ant-form-item-explain-error {
      width: 100% !important;
    }
  }
`;

const FundsItem = styled(Form.Item)`
  .ant-form-item-explain {
    align-items: flex-end;
    display: flex;
    font-size: 0.75rem;
    font-weight: 400;
    justify-content: right;
    line-height: 1rem;
    padding-right: 70px;
    .ant-form-item-explain-error {
      color: ${COLORS.Red800};
      width: 300px;
    }
  }

  .ant-form-item-control {
    border-bottom: 1px solid ${COLORS.Neutral200};
    padding-bottom: 26px;
    ${(props: any) => props.hideItem && `display: none`};
    ${(props: any) =>
      (props.showPLB || props.hideBorder) && `border-bottom: 0`};
  }

  .ant-form-item-label {
    color: ${COLORS.Neutral900};
    font: 500 16px Roboto, serif;
    line-height: 1.25rem;
  }

  .ant-form-item-control-input-content {
    align-items: center;
    background: ${COLORS.White};
    display: flex;
    grid-gap: 3rem;
    padding-top: 16px;
    h1 {
      color: ${COLORS.Neutral900};
      font: 500 16px Roboto, serif;
      line-height: 1.25rem;
    }

    h3 {
      color: ${COLORS.Neutral600};
      font: 400 12px Roboto, serif;
      line-height: 1rem;
    }

    .anticon {
      align-items: center;
      color: ${COLORS.Neutral900};
      display: flex;
      font: 700 16px Roboto, serif;
      line-height: 1.25rem;
    }
  }
`;

const editModeClass = 'editing';
const EditableAmount = styled.button`
  &:hover:not(.${editModeClass}),
  &:focus:not(.${editModeClass}) {
    background: ${COLORS.Blue50};
  }
`;

const EditAmountDiv = styled.div`
  align-items: center;
  display: flex;
  padding: 0.5rem;
  height: 42px;
`;

const FormItemIcon = styled.img`
  height: 36px;
  width: 36px;
`;

const FormItemIconContainer = styled.div`
  background: ${COLORS.Neutral50};
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  max-height: 51px;
  max-width: 51px;
  overflow: hidden;
  padding: 10px;
`;

const RequiredLabel = styled.h1`
  &::after {
    display: inline-block;
    margin-right: 4px;
    color: ${COLORS.Red800};
    font-size: 0.875rem;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
`;

const NoteCounter = styled.div`
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: right;
  position: absolute;
  margin-left: 95%;
  margin-top: 8px;
`;

const MoneyInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ArrowInputStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ErrorContainer = styled.div`
  height: 1rem;
`;

const NoteVisibilityNotice = styled.p`
  margin-top: 6px;
  font-size: ${pxToRem(12, 16)};
`;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

export interface AddFundsDrawerProps {
  amountToAdd: number;
  usersCurrency: CurrencyCode;
  exchangeRate: number;
  form: FormInstance;
  formItems: FormItems[];
  isValidInput: (name) => string | boolean;
  isVisible: boolean;
  onClose: () => void;
  onOk: () => void;
  setAmountToAdd: (amount: any) => void;
  userFinanceIncentive: EmployeeCurrentFinanceIncentiveDetailsVM;
  note: string;
  setNote: (note: string) => void;
  noteError: string;
}

export default function AddFundsDrawer({
  form,
  formItems,
  isValidInput,
  isVisible,
  onClose,
  onOk,
  userFinanceIncentive,
  usersCurrency,
  exchangeRate,
  note,
  setNote,
  noteError,
}: AddFundsDrawerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isHelperTextOn = isFeatureFlagOn.HelperText;
  const adminNotesMaxLength = 240;

  const renderItemField = (item: FormItems) => {
    const commonStyle = {display: 'flex', alignItems: 'flex-start'};
    const field = (
      <>
        <EditAmountDiv>
          {formatCurrency(item.total, usersCurrency, exchangeRate)}
          {item.editable && !item.inputField && (
            <EditOutlined
              aria-hidden="true"
              style={{marginLeft: '.5rem', color: COLORS.Blue800}}
            />
          )}
        </EditAmountDiv>
        {item.inputField && item.editable && (
          <MoneyInputContainer>
            <ArrowInputStyle>
              <ArrowRightOutlined
                aria-hidden="true"
                style={{paddingRight: '1rem'}}
              />
              <LearnInMoneyInput
                currency={usersCurrency}
                defaultValue={exchangeAmount(item.total, exchangeRate)}
                onBlur={() =>
                  item.amountToAdd <= 0 &&
                  item.setAmountToAdd(exchangeAmount(item.total, exchangeRate))
                }
                onChange={(amount: number) => {
                  item.setAmountToAdd(Math.abs(amount));
                }}
                style={{width: '150px'}}
              />
            </ArrowInputStyle>

            <ErrorContainer>
              {isValidInput(item.name) && (
                <ErrorMessage
                  message={isValidInput(item.name) as string}
                  style={{marginLeft: '32px'}}
                />
              )}
            </ErrorContainer>
          </MoneyInputContainer>
        )}
      </>
    );

    return item.editable ? (
      <EditableAmount
        style={commonStyle}
        aria-label={i18n.t(k.CTA__EDIT)}
        onClick={() => {
          item.setShowEditField(true);
        }}
        className={item.editing ? editModeClass : ''}>
        {field}
      </EditableAmount>
    ) : (
      <span style={commonStyle}>{field}</span>
    );
  };

  return (
    <DrawerContainer
      closeButtonPlacement={'right'}
      style={{zIndex: 1006}}
      onClose={onClose}
      title={i18n.t(k.FUND__PLURAL__MANAGE)}
      visible={isVisible}
      footer={
        <FooterButtonsContainer>
          <LearnInButton
            tag={ButtonTags.SecondaryBlackRemovedBorder}
            activeBorderColor={'none !important'}
            onClick={onClose}>
            {i18n.t(k.CTA__CANCEL)}
          </LearnInButton>
          <LearnInButton tag={ButtonTags.DrawerFooterPrimary} onClick={onOk}>
            {i18n.t(k.FUND__UPDATE__PLURAL)}
          </LearnInButton>
        </FooterButtonsContainer>
      }>
      <FormContainer
        key={userFinanceIncentive?.userFinanceIncentiveId}
        form={form}>
        {formItems?.map((item) => (
          <FundsItem
            key={item.name}
            name={item.name}
            rules={[
              {
                validator: () =>
                  isValidInput(item.name)
                    ? Promise.reject(
                        handleInputErrorText(
                          i18n.t(k.VALIDATION__MESSAGE),
                          item.helperText,
                          isHelperTextOn
                        )
                      )
                    : Promise.resolve(),
              },
            ]}
            help={''}
            validateStatus="validating"
            hideBorder={item == formItems[formItems.length - 1]}
            showNumberInput={item.editable && item.inputField}>
            <FormItemIconContainer>
              <FormItemIcon src={item.icon} />
            </FormItemIconContainer>
            <div data-cy={item.dataCy}>
              <h1>{item.header}</h1>
              <h3>{item.subheader}</h3>
              {renderItemField(item)}
            </div>
          </FundsItem>
        ))}
        <FundsItem
          className="admin-notes-input"
          key="note"
          name="note"
          rules={[
            {
              validator: () =>
                !isValidInput('note') ? Promise.reject() : Promise.resolve(),
            },
          ]}>
          <div>
            <RequiredLabel> {i18n.t(k.FUND__ADD__QUESTION_WHY)}</RequiredLabel>
            <TextArea
              maxLength={adminNotesMaxLength}
              onChange={(e) => setNote(e.target.value)}
            />
            {noteError ? (
              <ErrorMessage message={noteError} />
            ) : (
              <NoteVisibilityNotice>
                {i18n.t(k.FUND__ADD__NOTE_VISIBILITY_NOTICE)}
              </NoteVisibilityNotice>
            )}
          </div>
          <NoteCounter>
            {note.length}/{adminNotesMaxLength}
          </NoteCounter>
        </FundsItem>
      </FormContainer>
    </DrawerContainer>
  );
}
