import Initiatives from './Initiatives';
import {getInitiativesRq} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {InitiativeAdminVM} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import {Helmet} from 'react-helmet-async';

interface InitiativesWrapperProps {
  title?: string;
}

export default function InitiativesWrapper({title}: InitiativesWrapperProps) {
  const initiativesRq = getInitiativesRq();
  const initiativesQuery = useQuery<InitiativeAdminVM[]>(
    initiativesRq.queryKey,
    () => simpleQueryFn(initiativesRq.path),
    {
      placeholderData: () => [],
    }
  );

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Initiatives
        data={initiativesQuery.data}
        loading={initiativesQuery.isLoading}
        onRefreshRequested={initiativesQuery.refetch}
      />
    </div>
  );
}
