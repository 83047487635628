import styled from 'styled-components';
import CustomProgramProjectsTable from './CustomProgramProjectsTable.container';
import {useCustomProgramProjectsQuery} from '@generated/hooks';
import {sortProjectByOrder} from '@utils/sort';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  width: 100%;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ProjectsScreenProps {
  customProgramId: number;
}

function ProjectsScreen({customProgramId}: ProjectsScreenProps) {
  // Get Projects
  const {data: customProgramProjects} = useCustomProgramProjectsQuery(
    {
      customProgramId,
    },
    {
      enabled: !!customProgramId,
      select: (customProgramProjects) =>
        customProgramProjects?.sort(sortProjectByOrder) || [],
    }
  );

  return (
    <Container>
      <CustomProgramProjectsTable dataSource={customProgramProjects} />
    </Container>
  );
}

export default ProjectsScreen;
