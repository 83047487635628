import {useState} from 'react';
import {datadogLogs} from '@datadog/browser-logs';
import {DndProvider as DragAndDropProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {getUpdateAcademyLevelStepOrderRm} from '@store/apiEndpoints/academy/mutations';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import AddCustomProgramDrawerContainer from '../../AddCustomProgram/AddCustomProgramDrawer.container';
import AddMarketplaceProgramDrawerContainer from '../../AddMarketplaceProgram/AddMarketplaceProgramDrawer.container';
import LevelNoContentScreen from './LevelNoContentScreen';
import StepCardContainer from '../StepCard/StepCard.container';
import {setShowContentUpsertModal} from '@components/admin/pages/academies/Levels/LevelsScreen';
import {CustomDragLayer} from '@blocks/CustomDragLayer';
import {
  useAcademyLevelVMsQuery,
  useAcademyStepVMsQuery,
} from '@hooks/apiEndpoints/academy/queries';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {getBlockingTitle} from '@utils/requirementGating';
import {ProgramDetailsVM} from '@models/serverModels';
import {AcademyStepVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface LevelNoContentScreenContainerProps {
  selectedLevelId: number;
  setShowCustomContentModal: (show: boolean) => void;
  setShowDiscussionModal: (show: boolean) => void;
  setShowContentUpsertModal: setShowContentUpsertModal;
  setShowCatalogContentDrawer: (show: boolean) => void;
  academyId: number;
}

function LevelNoContentScreenContainer({
  selectedLevelId,
  setShowCustomContentModal,
  setShowContentUpsertModal,
  setShowDiscussionModal,
  setShowCatalogContentDrawer,
  academyId,
}: LevelNoContentScreenContainerProps) {
  const [showMarketplaceProgramDrawer, setShowMarketplaceProgramDrawer] =
    useState(false);
  const [showCustomProgramDrawer, setShowCustomProgramDrawer] = useState(false);
  const [stepReorderIdx, setStepReorderIdx] = useState<null | number>(null);
  const [isDraggingStepOrder, setIsDraggingStepOrder] = useState<null | number>(
    null
  );
  const {data: levels} = useAcademyLevelVMsQuery({
    academyId,
    excludeUserProgress: true,
  });
  const {
    data: steps,
    invalidateExact: invalidateSteps,
    isLoading: isLoadingSteps,
  } = useAcademyStepVMsQuery(selectedLevelId);

  // Academy Level Order Mutator
  const updateAcademyLevelStepOrder = useMutation(
    (args: {id: number; order: number}) => {
      const updateAcademyLevelStepOrder =
        getUpdateAcademyLevelStepOrderRm(args);
      return simpleMutationFn<null>(
        updateAcademyLevelStepOrder.path,
        updateAcademyLevelStepOrder.payload
      );
    },
    {
      onSuccess: async () => {
        invalidateSteps();
      },
      onError: async () => {
        datadogLogs.logger.error(
          'Error while updating academy level step order'
        );
      },
    }
  );

  if (isLoadingSteps) {
    return (
      <>
        <LoadingSpinner />
      </>
    );
  }
  const renderStepPreview = (step: any) => {
    return step.dragPreviewComponent;
  };
  return (
    <>
      {steps?.length === 0 ? (
        <LevelNoContentScreen
          setShowCustomContentModal={() => setShowCustomContentModal(true)}
          setShowContentUpsertModal={setShowContentUpsertModal}
          onClickAddMarketplaceProgram={() => {
            setShowMarketplaceProgramDrawer(true);
          }}
          onClickAddCustomProgram={() => {
            setShowCustomProgramDrawer(true);
          }}
          onClickAddDiscussionModal={() => {
            setShowDiscussionModal(true);
          }}
          onClickAddCatalogContent={() => {
            setShowCatalogContentDrawer(true);
          }}
        />
      ) : (
        <DragAndDropProvider key={1} backend={HTML5Backend} context={window}>
          <CustomDragLayer renderPreview={renderStepPreview} />
          {steps
            ?.sort((a, b) => a.order - b.order)
            .map((step, idx) => {
              const blockingTitle = getBlockingTitle(step.requiredId, steps);
              return (
                <StepCardContainer
                  attachmentGuids={step.attachmentGuids}
                  blockingTitle={blockingTitle}
                  step={step as ProgramDetailsVM & AcademyStepVM}
                  setShowContentUpsertModal={setShowContentUpsertModal}
                  levels={levels}
                  isStepReorderLoading={isLoadingSteps}
                  key={step.id}
                  type={step.type}
                  providerLogoUrl={step.providerLogoUrl}
                  providerName={step.providerName}
                  cost={step.cost}
                  programType={step.programType}
                  duration={step.duration}
                  cardCount={steps.length}
                  isDraggingStepOrder={isDraggingStepOrder}
                  id={step.id}
                  idx={idx}
                  order={step.order}
                  title={step.title}
                  smallImageUrl={step.smallImageUrl}
                  description={step.description}
                  levelId={selectedLevelId}
                  setStepReorderIdx={() => {
                    if (stepReorderIdx !== step.order) {
                      setStepReorderIdx(step.order);
                    }
                  }}
                  setIsDraggingStepOrder={() => {
                    if (isDraggingStepOrder !== step.order) {
                      setIsDraggingStepOrder(step.order);
                    }
                  }}
                  stepReorderIdx={stepReorderIdx}
                  onClickMoveToTop={async () =>
                    await updateAcademyLevelStepOrder.mutateAsync({
                      order: 1,
                      id: step.id,
                    })
                  }
                  onClickMoveToBottom={async () =>
                    await updateAcademyLevelStepOrder.mutateAsync({
                      order: steps.length + 1,
                      id: step.id,
                    })
                  }
                  reorderStepItem={async () => {
                    if (stepReorderIdx !== step.order) {
                      updateAcademyLevelStepOrder.mutateAsync({
                        id: step.id,
                        order: stepReorderIdx,
                      });
                    }
                  }}
                />
              );
            })}
        </DragAndDropProvider>
      )}

      {showMarketplaceProgramDrawer && (
        <AddMarketplaceProgramDrawerContainer
          existingSteps={steps}
          onClose={({shouldRefresh}) => {
            setShowMarketplaceProgramDrawer(false);
            if (shouldRefresh) {
              invalidateSteps();
            }
          }}
          levelId={selectedLevelId}
        />
      )}
      {showCustomProgramDrawer && (
        <AddCustomProgramDrawerContainer
          existingSteps={steps}
          onClose={({shouldRefresh}) => {
            setShowCustomProgramDrawer(false);
            if (shouldRefresh) {
              invalidateSteps();
            }
          }}
          levelId={selectedLevelId}
        />
      )}
    </>
  );
}

export default LevelNoContentScreenContainer;
