import {
  CompositeFeatureFlags,
  initFeatureFlags,
  initOrgInfo,
  LAYOUT_FEATURE_FLAG_KEYS,
  loadFeatureFlags,
  loadLxpPermissions,
  LxpOrgInfo,
} from '@learnin-inc/apollo-react-data-access';
import {
  getLxpEnvironmentName,
  isProductionUS,
} from '@utils/environment-helpers';
import {getLxpLDKey} from '@utils/feature-flag-helpers';
import {useEffect, useState} from 'react';

export const useLxpFeatureFlags = (isLxpApiEnabled: boolean) => {
  const [featureFlags, setFeatureFlags] =
    useState<CompositeFeatureFlags>(initFeatureFlags);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const {authUser} = await loadLxpPermissions(isLxpApiEnabled);
      const organizationId = authUser.defaultOrgId;
      const orgInfo =
        authUser.orgInfo.find(
          (x: LxpOrgInfo) => x.organizationId === organizationId
        ) || initOrgInfo();
      const environmentName = isProductionUS
        ? ''
        : `-${getLxpEnvironmentName()}`;
      const featureFlags = await loadFeatureFlags(authUser, orgInfo, {
        contextKey: `${authUser?.viewerProfile.userProfileKey}${environmentName}`,
        licenseKey: getLxpLDKey(),
        requestedFlags: LAYOUT_FEATURE_FLAG_KEYS,
      });
      setFeatureFlags(featureFlags);
    };

    fetchFeatureFlags();
  }, [isLxpApiEnabled]);

  return featureFlags;
};
