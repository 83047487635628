import {ProjectAdminVM} from '@generated/interfaces';

export const sortAsc = (key: string) => (a, b) => {
  return a[key] - b[key];
};

export const sortByOrder = (a, b) => a.order - b.order;

export const sortProjectByOrder = (a: ProjectAdminVM, b: ProjectAdminVM) => {
  // First attempt to sort by section
  if (a.sectionOrder !== b.sectionOrder) {
    return a.sectionOrder - b.sectionOrder;
  }
  // Then attempt to sort by step
  return a.stepOrder - b.stepOrder;
};
