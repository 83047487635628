import {RefObject, useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import moment from 'moment-timezone';
import {H3} from '@components/reusable/Typography';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {Divider, Form, FormInstance} from 'antd';
import CostCenterField from '@components/reusable/CostCenterField/CostCenterField';
import {ApprovalTypeSpecificFields} from '@components/admin/pages/approvals/components/ApprovalTypeSpecificFields';
import {PreviousRejectionNotes} from '@components/admin/pages/approvals/components/PreviousRejectionNotes';
import {ApproverNotes} from '@components/admin/pages/approvals/components/ApproverNotes';
import FundsExpireOnField from '@components/reusable/FundsExpireOnField/FundsExpireOnField';
import {updateFundsExpireOnFormValue} from './SharedRequestForm';
import {dateToUTCMoment} from '@utils/timeUtils';
import {
  ResourceApprovalRequest,
  ApprovalRequestDetailsVM,
  UserRequestApprovalVM,
} from '@models/serverModels';
import {ApprovalStatus, ApprovalsDiscriminator} from '@generated/enums';
import {CostCenterOption} from '@components/reusable/CostCenterField/CostCenterField.interfaces';
import {AdminScreenApprovalStatus} from '@models/clientEnums';

interface AdditionalRequestFormProps {
  additionalRequest: UserRequestApprovalVM | ResourceApprovalRequest;
  requestLoading: boolean;
  requestSuccess: boolean;
  originalRequest: UserRequestApprovalVM | ResourceApprovalRequest;
  selectedApproval: UserRequestApprovalVM | ResourceApprovalRequest;
  licenseId: number;
  formRef: RefObject<FormInstance>;
  isNoteRequired: boolean;
  showCostCenter: boolean;
  showUnspentFunds: boolean;
  activeTab: ApprovalStatus | AdminScreenApprovalStatus;
  selectedCostCenter?: string;
  costCenterOptions: CostCenterOption[];
  updateCostCenter: (costCenter: string, approvalId: number) => void;
  isReadonly: boolean;
  requestData: ApprovalRequestDetailsVM;
  isResource: (itemType: ApprovalsDiscriminator) => boolean;
  approvalExpiry: string;
}
export const AdditionalRequestForm = ({
  additionalRequest,
  requestLoading,
  requestSuccess,
  requestData,
  formRef,
  isNoteRequired,
  showCostCenter,
  showUnspentFunds,
  originalRequest,
  costCenterOptions,
  selectedCostCenter,
  updateCostCenter,
  activeTab,
  selectedApproval,
  licenseId,
  isResource,
  isReadonly,
  approvalExpiry,
}: AdditionalRequestFormProps) => {
  const [approvalExpirationDate, setApprovalExpirationDate] = useState(
    dateToUTCMoment(approvalExpiry ? moment(approvalExpiry) : null)
  );

  useEffect(() => {
    updateFundsExpireOnFormValue(formRef, approvalExpirationDate);
  }, [approvalExpirationDate, formRef]);

  useEffect(() => {
    setApprovalExpirationDate(approvalExpiry ? moment(approvalExpiry) : null);
  }, [approvalExpiry]);

  return !additionalRequest ? null : (
    <>
      <H3 fontWeight={500}>{i18n.t(k.FUND__REQUEST__NEW)}</H3>
      {requestLoading ? (
        <LoadingSpinner />
      ) : (
        requestSuccess &&
        requestData && (
          <Form
            className={'form-drawer'}
            key={additionalRequest.id}
            ref={formRef}
            layout="vertical">
            <ApprovalTypeSpecificFields
              isResource={isResource}
              originalRequest={originalRequest}
              requestData={requestData}
            />
            {showUnspentFunds && (
              <FundsExpireOnField
                approvalExpirationDate={approvalExpirationDate}
                setApprovalExpirationDate={setApprovalExpirationDate}
              />
            )}
            {showCostCenter && (
              <CostCenterField
                activeTab={activeTab}
                selectedCostCenter={selectedCostCenter}
                costCenterOptions={costCenterOptions}
                originalRequest={originalRequest}
                selectedApproval={selectedApproval}
                updateCostCenter={updateCostCenter}
              />
            )}
            <PreviousRejectionNotes
              initialValue={additionalRequest.previousNotes}
            />
          </Form>
        )
      )}
      <Divider />
      {!licenseId && (
        <H3 fontWeight={500}> {i18n.t(k.FUND__REQUEST__APPROVED)}</H3>
      )}
    </>
  );
};
