import {CSSProperties, ReactNode, useEffect, useState} from 'react';
import styled from 'styled-components';
import {COLORS, KEY_CODES} from '@utils/constants';
import {commonSelectStyles, filterResults} from '../Autocomplete/Autocomplete';
import {Select} from 'antd';
import {DownOutlined} from '@ant-design/icons';

export enum InlineSelectState {
  View,
  Editing,
  Saving,
}

export interface Props {
  value: any;
  onStateChanged?: (newState: InlineSelectState) => void;
  onSubmit: (value: any) => PromiseLike<any>;
  textStyle?: CSSProperties | undefined;
  options?: Partial<{label: any; value: any}>[];
  notFoundContent?: ReactNode;
  readOnly?: boolean;
}

export const StyledSelectSearch = styled(Select)`
  ${commonSelectStyles}
  background: ${(props: {inline: boolean}) =>
    props.inline ? `transparent` : COLORS.White} !important;
  width: 100%;
  border: 1px solid ${COLORS.Neutral300} !important;
  .ant-select-selector {
    background: transparent !important;
  }
  .ant-select-selection-item {
    line-height: 34px !important;
  }
  &.ant-select-open {
    background: ${COLORS.White} !important;
  }
`;

const InlineSelect = ({onSubmit, value, onStateChanged, options}: Props) => {
  const [currentState, setCurrentState] = useState(InlineSelectState.Editing);

  const handleInputKeyPressed = (event) => {
    if (event.key === KEY_CODES.ESCAPE) {
      setCurrentState(InlineSelectState.Editing);
    }
  };

  const handleInputChanged = async (value: any) => {
    setCurrentState(InlineSelectState.Saving);
    try {
      await onSubmit(value);
      setCurrentState(InlineSelectState.Editing);
    } catch (error) {
      setCurrentState(InlineSelectState.Editing);
    }
  };

  useEffect(() => {
    onStateChanged?.(currentState);
  }, [currentState, onStateChanged]);

  // Down icon behavior
  const [isActive, setActive] = useState(true);
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <StyledSelectSearch
      showSearch
      inline={true}
      suffixIcon={
        <DownOutlined
          // Fixes can't click suffixIcon: https://github.com/ant-design/ant-design/issues/23263#issuecomment-1079985930
          className={isActive ? 'ant-select-suffix' : ''}
          onClick={toggleClass}
        />
      }
      options={options}
      optionFilterProp="children"
      filterOption={filterResults}
      defaultValue={value}
      value={value}
      onKeyUp={handleInputKeyPressed}
      onSelect={handleInputChanged}
      disabled={currentState === InlineSelectState.Saving}
      data-testid="inline-select"
    />
  );
};

export default InlineSelect;
