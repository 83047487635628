import {CurrencyCode} from './../enums';
import {PeopleTabUser} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: PeopleTabUser

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPeopleTabUserMockData(
  args?: Partial<PeopleTabUser>
): PeopleTabUser {
  return {
    approvedAmount: faker.datatype.number(), // optional
    costCenter: faker.word.noun(), // optional
    lastLogin: faker.word.noun(), // optional
    managerName: faker.name.fullName(), // optional
    spentAmount: faker.datatype.number(), // optional
    totalBudget: faker.datatype.number(), // optional
    userCompanyId: faker.datatype.number(), // optional
    userCurrency: CurrencyCode.USD, // optional
    userName: faker.name.fullName(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PeopleTabUserMockData: PeopleTabUser = {
  approvedAmount: 5, // optional
  costCenter: 'string', // optional
  lastLogin: 'string', // optional
  managerName: 'Bobby Johnson', // optional
  spentAmount: 5, // optional
  totalBudget: 123, // optional
  userCompanyId: 1, // optional
  userCurrency: CurrencyCode.USD, // optional
  userName: 'Bobby Johnson',
};
