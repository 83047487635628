import {i18n, k} from '@i18n/translate';
import {addTrailingPeriod} from '@utils/stringUtils';
import {FlagOutlined} from '@ant-design/icons';
import {InitiativePurpose} from '@generated/enums';
import {isEqual} from 'lodash';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {COLORS} from '@utils/constants';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {InitiativePurposeString} from '@models/clientEnums';

/*
|--------------------------------------------------------------------------
| Tag Component
|--------------------------------------------------------------------------
*/

export type InitiativePurposeDescriptionsEntry = Record<number, Set<string>>;

function RecommendationTag({
  label,
  tooltipText,
}: {
  label: string;
  tooltipText?: string;
}) {
  const {isFeatureFlagOn} = useFeatureFlags();

  const _Tag = (
    <LearnInTag
      tagStyle={TagStyles.Green}
      style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}}
      label={
        <>
          {!isFeatureFlagOn.NewTags && (
            <FlagOutlined
              aria-hidden="true"
              style={{marginRight: '6px', color: COLORS.White}}
            />
          )}
          {label?.toLocaleUpperCase()}
        </>
      }
    />
  );
  if (tooltipText) {
    return <LearnInTooltip title={tooltipText}>{_Tag}</LearnInTooltip>;
  } else {
    return <>{_Tag}</>;
  }
}

export default RecommendationTag;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

function generateRoleText(
  base: string,
  purposeDescriptions: InitiativePurposeDescriptionsEntry
): string {
  if (!purposeDescriptions) {
    return '';
  }
  const roles = Array.from(
    purposeDescriptions[InitiativePurpose.PrepareForNewRole].values()
  ).join(', ');
  return addTrailingPeriod(`${base}: ${roles}`);
}

function generateOtherText(
  purposeDescriptions: InitiativePurposeDescriptionsEntry
): string {
  if (!purposeDescriptions) {
    return '';
  }
  return Array.from(purposeDescriptions[InitiativePurpose.Other].values()).join(
    '. '
  );
}

/**
 * Exported for testing purposes. Be sure to update tests if changing the text.
 * Given the complexity of the logic here, unit tests are the best way to verify that it's working properly.
 * @param initiativePurposes
 * @param purposeDescriptions
 * @returns label and tooltipText to render
 */
const RECOMMENDED_TEXT = i18n.t(k.GENERIC__RECOMMENDED);

export function generateTagTextAndTooltip(
  initiativePurposes?: string,
  purposeDescriptions?: Record<InitiativePurpose, Set<string>>
): {label: string; tooltipText: string} {
  const _initiativePurposes = initiativePurposes?.split(',').filter(Boolean);

  if (!_initiativePurposes.length) {
    return {label: '', tooltipText: ''};
  }

  let label = '';
  let tooltipText = '';
  if (_initiativePurposes.length === 1) {
    // various single options
    switch (_initiativePurposes[0]) {
      case InitiativePurposeString.LearnCriticalSkill:
        label = i18n.t(k.TAG__CRITICAL_SKILL).toLocaleUpperCase();
        tooltipText = i18n.t(k.PROGRAM__CRITICAL_SKILL__DESCRIPTION);
        break;
      case InitiativePurposeString.PrepareForNewRole:
        label = i18n.t(k.TAG__NEW_ROLE).toLocaleUpperCase();
        const base = i18n.t(k.PROGRAM__CRITICAL_SKILL__DESCRIPTION);
        if (purposeDescriptions?.[InitiativePurpose.PrepareForNewRole]) {
          tooltipText = addTrailingPeriod(
            generateRoleText(base, purposeDescriptions)
          );
        } else {
          tooltipText = `${base}.`;
        }
        break;
      case InitiativePurposeString.Other:
        label = RECOMMENDED_TEXT;
        if (purposeDescriptions?.[InitiativePurpose.Other]) {
          tooltipText = addTrailingPeriod(
            generateOtherText(purposeDescriptions)
          );
        }
        break;
    }
  } else if (_initiativePurposes.length > 1) {
    label = RECOMMENDED_TEXT;
    const purposes = Array.from(new Set(_initiativePurposes)).sort();
    if (
      isEqual(
        purposes,
        [
          InitiativePurposeString.PrepareForNewRole,
          InitiativePurposeString.LearnCriticalSkill,
        ].sort()
      )
    ) {
      const base = i18n.t(k.PROGRAM__CRITICAL_SKILL__DESCRIPTION);
      if (purposeDescriptions?.[InitiativePurpose.PrepareForNewRole]) {
        tooltipText = generateRoleText(base, purposeDescriptions);
      } else {
        tooltipText = `${base}.`;
      }
    } else if (
      isEqual(
        purposes,
        [
          InitiativePurposeString.PrepareForNewRole,
          InitiativePurposeString.Other,
        ].sort()
      )
    ) {
      const base = i18n.t(k.PROGRAM__CRITICAL_SKILL__DESCRIPTION);
      if (purposeDescriptions?.[InitiativePurpose.PrepareForNewRole]) {
        tooltipText = generateRoleText(base, purposeDescriptions);
      } else {
        tooltipText = `${base}.`;
      }
      if (purposeDescriptions?.[InitiativePurpose.Other]) {
        const otherText = generateOtherText(purposeDescriptions);
        tooltipText += ` ${otherText}`;
        tooltipText = addTrailingPeriod(tooltipText);
      }
    } else if (
      isEqual(
        purposes,
        [
          InitiativePurposeString.LearnCriticalSkill,
          InitiativePurposeString.Other,
        ].sort()
      )
    ) {
      tooltipText = i18n.t(k.PROGRAM__CRITICAL_SKILL__DESCRIPTION);
      if (purposeDescriptions?.[InitiativePurpose.Other]) {
        const otherText = generateOtherText(purposeDescriptions);
        tooltipText += ` ${otherText}`;
        tooltipText = addTrailingPeriod(tooltipText);
      }
    } else if (
      isEqual(
        purposes,
        [
          InitiativePurposeString.LearnCriticalSkill,
          InitiativePurposeString.Other,
        ].sort()
      )
    ) {
      tooltipText = i18n.t(k.PROGRAM__CRITICAL_SKILL__DESCRIPTION);
      if (purposeDescriptions?.[InitiativePurpose.Other]) {
        const otherText = generateOtherText(purposeDescriptions);
        tooltipText += ` ${otherText}`;
        tooltipText = addTrailingPeriod(tooltipText);
      }
    } else if (
      isEqual(
        purposes,
        [
          InitiativePurposeString.LearnCriticalSkill,
          InitiativePurposeString.PrepareForNewRole,
          InitiativePurposeString.Other,
        ].sort()
      )
    ) {
      const base = i18n.t(k.PROGRAM__CRITICAL_SKILL__DESCRIPTION);
      tooltipText = generateRoleText(base, purposeDescriptions);
      if (purposeDescriptions?.[InitiativePurpose.Other] && tooltipText) {
        const otherText = generateOtherText(purposeDescriptions);
        tooltipText += ` ${otherText}`;
        tooltipText = addTrailingPeriod(tooltipText);
      }
    }
  }

  return {label, tooltipText};
}
