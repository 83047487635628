import {IExternalLicenseBasicInfo} from './../interfaces';
import {
  ILicenseBasicInfoMockData,
  getILicenseBasicInfoMockData,
} from './ILicenseBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: IExternalLicenseBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIExternalLicenseBasicInfoMockData(
  args?: Partial<IExternalLicenseBasicInfo>
): IExternalLicenseBasicInfo {
  return {
    ...getILicenseBasicInfoMockData(),

    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IExternalLicenseBasicInfoMockData: IExternalLicenseBasicInfo = {
  ...ILicenseBasicInfoMockData,
};
