import {AttachmentType} from './../enums';
import {IUploadedFile} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: IUploadedFile

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIUploadedFileMockData(
  args?: Partial<IUploadedFile>
): IUploadedFile {
  return {
    deleted: faker.datatype.boolean(),
    description: faker.lorem.lines(),
    fileName: faker.name.fullName(),
    filePath: faker.word.noun(),
    id: faker.datatype.number(),
    multipartUploadCompleted: faker.datatype.boolean(),
    multipartUploadId: String(faker.datatype.number()),
    order: faker.datatype.number(),
    successfullyUploadedSize: faker.datatype.number(),
    totalSize: faker.datatype.number(),
    type: AttachmentType.File,
    uploadError: faker.word.noun(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IUploadedFileMockData: IUploadedFile = {
  deleted: true,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  fileName: 'Bobby Johnson',
  filePath: 'string',
  id: 1,
  multipartUploadCompleted: true,
  multipartUploadId: '1',
  order: 123,
  successfullyUploadedSize: 123,
  totalSize: 123,
  type: AttachmentType.File,
  uploadError: 'string',
};
