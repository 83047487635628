import {useMemo} from 'react';
import {
  Container,
  HeaderContainer,
} from './AcademyReportsParticipantsTable.styled';
import {AcademyReportsParticipantsTableViewProps} from './AcademyReportsParticipantsTable.interfaces';
import {i18n, k} from '@i18n/translate';
import LearnInTable, {
  TableColLink,
  renderDateTime,
  dateTimeSorter,
  stringSorter,
  TooltipCol,
} from '@components/reusable/LearnInTable';
import Search from '@components/reusable/Search';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import BulkNudgeButton from '@blocks/BulkNudgeButton.view';

function AcademyReportsParticipantsTableView({
  dataSource,
  onClickRow,
  onChangeSearchInput,
  searchInputValue,
  noDataTableProps,
  onOpenNudgeEmailPreview,
  nudgeEmailsEnabled,
  canShowEmployeeEmail,
  showBulkNudgeButton,
  onClickBulkNudgeButton,
}: AcademyReportsParticipantsTableViewProps) {
  const columns = useMemo(() => {
    return [
      canShowEmployeeEmail
        ? TooltipCol({
            title: i18n.t(k.EMPLOYEE__NAME),
            tooltipDataIndex: 'email',
            key: 'employeeName',
            dataIndex: 'employeeName',
            width: 340,
            renderData(name: string) {
              return (
                <TableColLink style={{width: '100%'}} onClick={onClickRow}>
                  {name}
                </TableColLink>
              );
            },
          })
        : {
            title: i18n.t(k.EMPLOYEE__NAME),
            dataIndex: 'employeeName',
            key: 'employeeName',
            render: (name: string) => (
              <TableColLink style={{width: 340}} onClick={onClickRow}>
                {name}
              </TableColLink>
            ),
          },
      {
        title: i18n.t(k.SECTION__CURRENT),
        dataIndex: 'currentLevel',
        key: 'currentLevel',
        sorter: stringSorter<string>('currentLevel'),
      },
      {
        title: i18n.t(k.ACADEMY__START),
        dataIndex: 'academyStartDate',
        key: 'academyStartDate',
        render: renderDateTime(),
        sorter: dateTimeSorter('academyStartDate'),
      },
      nudgeEmailsEnabled && {
        title: i18n.t(k.GENERIC__LAST_NUDGE_SENT),
        dataIndex: 'lastNudgeSent',
        key: 'lastNudgeSent',
        sorter: dateTimeSorter('lastNudgeSent'),
        render: (date: string) => {
          return date ? renderDateTime()(date) : '-';
        },
      },
      nudgeEmailsEnabled && {
        title: '',
        key: null,
        render: (data) => {
          return (
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
              <LearnInButton
                tag={ButtonTags.SecondaryRemovedBorder}
                style={{fontWeight: 400}}
                onClick={(ev) => {
                  ev.stopPropagation();
                  onOpenNudgeEmailPreview(data);
                }}>
                {i18n.t(k.CTA__SEND_NUDGE)}
              </LearnInButton>
            </div>
          );
        },
      },
    ].filter(Boolean);
  }, []);

  const searchNameText = i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
    item: i18n.t(k.GENERIC__NAME),
  });

  return (
    <Container>
      <HeaderContainer>
        <Search
          id="reports__participant-table"
          style={{width: '270px', margin: '16px 24px'}}
          onChange={onChangeSearchInput}
          value={searchInputValue}
          placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {item: null})}
          label={searchNameText}
          buttonAriaLabel={searchNameText}
        />
        {showBulkNudgeButton && (
          <BulkNudgeButton
            onClick={onClickBulkNudgeButton}
            style={{height: 38, margin: 'auto 16px'}}
          />
        )}
      </HeaderContainer>
      <LearnInTable
        columns={columns}
        dataSource={dataSource}
        onClickRow={onClickRow}
        noDataStyle={noDataTableProps?.noDataStyle}
        noDataHeader={noDataTableProps?.noDataHeader}
        noDataSubheader={noDataTableProps?.noDataSubheader}
      />
    </Container>
  );
}

export default AcademyReportsParticipantsTableView;
