import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {useParams} from 'react-router-dom';
import StepCard from './StepCard';
import {useMutation} from 'react-query';
import queryClient, {
  simpleDeleteFn,
  simpleMutationFn,
} from '@store/queryClient';
import {getDeleteAcademyStepRm} from '@store/apiEndpoints/academy/mutations';
import {notify} from '@components/user/notifications';
import {Divider, Modal} from 'antd';
import {getUploadChunkQueryKey} from '@hooks/useChunkUpload';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {ContentType} from '@generated/enums';
import {
  AcademyStepContentUpdatePayload,
  ProgramDetailsVM,
} from '@models/serverModels';
import CustomContentModal from '../CustomContent/CustomContentModal.container';
import MoveStepLevelModalContainer from './MoveStepLevelModal.container';
import CustomProgramAdminDrawer from '@components/admin/pages/programs/customPrograms/CustomProgramAdminDrawer.container';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {setShowContentUpsertModal} from '@components/admin/pages/academies/Levels/LevelsScreen';
import {useAcademyStepVMsQuery} from '@hooks/apiEndpoints/academy/queries';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import AddEditStepRequirementModalContainer from '../../RequirementGating/AddEditStepRequirement.container';
import {getUpdateAcademyContentStepRm} from '@store/apiEndpoints/academy/mutations';
import {
  useAcademiesAcademyLevelsAcademyStepsMutation,
  useDuplicateAcademyStepContentMutation,
  useDuplicateCustomProgramContentMutation,
} from '@generated/hooks';
import {AcademyStepType} from '@generated/enums';
import {AcademyStepVM, AcademyLevelVM} from '@generated/interfaces';
import {getTitle} from '@utils/enumMapping/MapTextToUserLanguage';
import {useGetLabelsByAcademyStepType} from '@hooks/useGetLabelsByAcademyStepType';

const {confirm} = Modal;

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  margin-bottom: 16px;
`;

const ModalBody = styled.div`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: left;
`;

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface StepCardContainerProps {
  levels: AcademyLevelVM[];
  step: Omit<ProgramDetailsVM, 'currency'> & AcademyStepVM;
  cardCount: number;
  isDraggingStepOrder: number | null;
  id: number;
  idx: number;
  order: number;
  title: string;
  smallImageUrl: string;
  description: string;
  type: AcademyStepType;
  levelId: number;
  stepReorderIdx: number | null;
  providerLogoUrl: string;
  providerName: string;
  cost: number;
  programType: string;
  duration: string;
  date?: string;
  eventTimeZone?: string;
  setIsDraggingStepOrder: () => void;
  setStepReorderIdx: () => void;
  setShowContentUpsertModal: setShowContentUpsertModal;
  onClickMoveToTop: () => void;
  onClickMoveToBottom: () => void;
  reorderStepItem: () => void;
  isStepReorderLoading: boolean;
  blockingTitle: string | undefined;
  attachmentGuids?: string[];
}

function StepCardContainer({
  levels,
  step,
  idx,
  id,
  order,
  title,
  smallImageUrl,
  type,
  cardCount,
  isDraggingStepOrder,
  levelId,
  providerLogoUrl,
  providerName,
  description,
  cost,
  programType,
  duration,
  date,
  eventTimeZone,
  setIsDraggingStepOrder,
  setStepReorderIdx,
  onClickMoveToTop,
  onClickMoveToBottom,
  reorderStepItem,
  stepReorderIdx,
  isStepReorderLoading,
  blockingTitle,
  setShowContentUpsertModal,
  attachmentGuids,
}: StepCardContainerProps) {
  const params = useParams();
  const [showCustomContentModal, setShowCustomContentModal] = useState(false);
  const [showMoveContentModal, setShowMoveContentModal] = useState(false);
  const [customProgramForEdit, setCustomProgramForEdit] = useState<
    AcademyStepVM | undefined
  >();
  const [showCustomProgramDrawer, setShowCustomProgramDrawer] = useState(false);
  const [showRequirementModal, setShowRequirementModal] = useState(false);
  const {invalidateExact: invalidateSteps} = useAcademyStepVMsQuery(levelId);
  const deleteAcademyStepMutation = useMutation(
    () => {
      const deleteAcademyStepRm = getDeleteAcademyStepRm(id);
      return simpleDeleteFn<null>(
        deleteAcademyStepRm.path,
        deleteAcademyStepRm.payload
      );
    },
    {
      onSuccess: async () => {
        invalidateSteps();
      },
      onError: () => {
        notify.deleteCustomProgramContentError();
      },
    }
  );

  const duplicateCustomProgramContent =
    useDuplicateCustomProgramContentMutation({
      onSuccess: async () => {
        invalidateSteps();
      },
      onError: () => {
        notify.duplicateCustomStepContentError();
      },
    });

  const duplicateAcademyStepContent = useDuplicateAcademyStepContentMutation({
    onSuccess: async () => {
      invalidateSteps();
    },
    onError: () => {
      notify.duplicateCustomStepContentError();
    },
  });

  const handleSuccessUpdateContent = () => {
    notify.updateStepSuccess();
    invalidateSteps();
  };

  // Update Content, for allow skip
  const updateCustomContentMutation = useMutation(
    (args: AcademyStepContentUpdatePayload) => {
      const updateCustomContentRm = getUpdateAcademyContentStepRm(args);
      return simpleMutationFn<number>(
        updateCustomContentRm.path,
        updateCustomContentRm.payload
      );
    },
    {
      onSuccess: handleSuccessUpdateContent,
      onError: () => {
        notify.addCustomProgramContentError();
      },
    }
  );

  const academiesAcademyLevelsAcademySteps =
    useAcademiesAcademyLevelsAcademyStepsMutation({
      onSuccess: handleSuccessUpdateContent,
    });

  const {deleteLabel, deletePrompt} = useGetLabelsByAcademyStepType(type);

  const handleClickDelete = () => {
    let contentTypeString;
    switch (type) {
      case AcademyStepType.TextContent:
        contentTypeString = i18n.t(k.LEVEL__STEP__CUSTOM);
        break;
      case AcademyStepType.ProjectContent:
        contentTypeString = i18n.t(k.PROJECT);
        break;
      case AcademyStepType.EventContent:
        contentTypeString = i18n.t(k.EVENT);
        break;
      case AcademyStepType.Discussion:
        contentTypeString = i18n.t(k.DISCUSSION);
        break;
      case AcademyStepType.LxpContent:
        contentTypeString = i18n.t(k.CONTENT);
        break;
      default:
        contentTypeString = i18n.t(k.PROGRAM);
        break;
    }
    confirm({
      icon: false,
      closable: true,
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__DELETE_ITEM_YES__FORMAT, {
        item: contentTypeString,
      }),
      okType: 'danger',
      title: <ModalTitle>{deleteLabel}</ModalTitle>,
      content: (
        <>
          <ModalBody>{deletePrompt}</ModalBody>
          <Divider
            style={{
              position: 'absolute',
              left: 0,
              bottom: '2.75rem',
            }}
          />
        </>
      ),
      bodyStyle: {borderRadius: '25px'},
      okButtonProps: {type: 'primary', shape: 'round', size: 'large'},
      cancelButtonProps: {
        type: 'primary',
        shape: 'round',
        ghost: true,
        size: 'large',
      },
      async onOk() {
        await deleteAcademyStepMutation.mutateAsync();
      },
    });
  };

  const queryKey = getUploadChunkQueryKey(id);
  const contentUploadPercent: number | undefined =
    queryClient.getQueryData(queryKey);

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    step.licenseCurrency || step.currency,
    getUserQuery.data?.currency
  );

  const closeCustomProgramDrawer = () => {
    setShowCustomProgramDrawer(false);
    setCustomProgramForEdit(undefined);
  };

  const onClickEditProgram = (program) => {
    setCustomProgramForEdit(program);
    setShowCustomProgramDrawer(true);
  };
  const editModal = () => {
    switch (type) {
      case AcademyStepType.TextContent:
        setShowContentUpsertModal(true, id, ContentType.Text);
        break;
      case AcademyStepType.ProjectContent:
        setShowContentUpsertModal(true, id, ContentType.Project);
        break;
      case AcademyStepType.EventContent:
        setShowContentUpsertModal(true, id, ContentType.Event);
        break;
      case AcademyStepType.Discussion:
        setShowContentUpsertModal(true, id, ContentType.Discussion);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <StepCard
        onClickToggleAllowSkip={() => {
          if (
            step.type === AcademyStepType.MarketplaceProgram ||
            step.type === AcademyStepType.CustomProgram
          ) {
            academiesAcademyLevelsAcademySteps.mutate({
              payload: {
                allowSkip: !step.allowSkip,
              },
              pathVars: {
                academyId: parseInt(params.academyId),
                academyLevelId: step.academyLevelId,
                academyStepId: step.id,
              },
            });
          } else {
            const link = step.links?.[0];
            updateCustomContentMutation.mutate({
              allowPeerVisibility: step.allowPeerVisibility,
              allowSkip: !step.allowSkip,
              description: step.description,
              dueDate: step?.dueDateUtc,
              dueDateTimeZone: step.dueDateTimeZone,
              id: step.id,
              links: link ? [link] : [],
              submissionType: step.submissionType,
              title: step.title,
            });
          }
        }}
        lxpContentType={step.lxpContentType}
        blockingTitle={blockingTitle}
        step={step as ProgramDetailsVM & AcademyStepVM}
        providerLogoUrl={providerLogoUrl}
        providerName={providerName}
        cost={cost}
        programType={programType}
        duration={duration}
        key={id}
        cardCount={cardCount}
        contentUploadPercent={contentUploadPercent}
        idx={idx}
        type={type}
        id={id}
        order={order}
        title={getTitle(title)}
        smallImageUrl={smallImageUrl}
        description={description}
        onClick={() => editModal()}
        onClickDelete={handleClickDelete}
        onClickDuplicate={async (e) => {
          e.stopPropagation();
          if (params.academyId) {
            duplicateAcademyStepContent.mutate({
              pathVars: {
                contentId: id,
              },
            });
          } else {
            duplicateCustomProgramContent.mutate({
              pathVars: {
                contentId: id,
              },
            });
          }
        }}
        onClickEdit={() => editModal()}
        onClickMoveToTop={async () => onClickMoveToTop()}
        onClickMoveToBottom={async () => onClickMoveToBottom()}
        setIsDraggingStepOrder={setIsDraggingStepOrder}
        isDraggingStepOrder={isDraggingStepOrder}
        isStepReorderLoading={isStepReorderLoading}
        setStepReorderIdx={setStepReorderIdx}
        reorderStepItem={async () => {
          if (order !== stepReorderIdx) {
            reorderStepItem();
          }
        }}
        onClickMoveToLevel={() => setShowMoveContentModal(true)}
        onClickUpdateRequirement={() => setShowRequirementModal(true)}
        showRequirementContextMenuOption={idx !== 0}
        date={date}
        eventTimeZone={eventTimeZone}
        onClickEditProgram={onClickEditProgram}
        exchangeRate={exchangeRate}
        usersCurrency={getUserQuery.data?.currency}
      />
      {showCustomContentModal && (
        <CustomContentModal
          id={id}
          title={title}
          description={description}
          levelId={levelId}
          onCancel={() => setShowCustomContentModal(false)}
          visible={true}
          academyId={parseInt(params.academyId)}
          stepId={id}
          attachmentGuids={attachmentGuids}
        />
      )}
      <MoveStepLevelModalContainer
        currentLevelId={levelId}
        visible={showMoveContentModal}
        academyId={parseInt(params.academyId)}
        onCancel={() => {
          setShowMoveContentModal(false);
        }}
        stepId={id}
        levels={levels}
      />
      <CustomProgramAdminDrawer
        key={customProgramForEdit?.id}
        customProgramId={customProgramForEdit?.programId}
        onClose={closeCustomProgramDrawer}
        visible={showCustomProgramDrawer}
        initialScreen={CustomProgramScreens.Setup}
        refreshParentData={closeCustomProgramDrawer}
      />

      {showRequirementModal && (
        <AddEditStepRequirementModalContainer
          levelId={levelId}
          stepId={id}
          onCancel={() => setShowRequirementModal(false)}
        />
      )}
    </>
  );
}

export default StepCardContainer;
