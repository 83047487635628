import styled from 'styled-components';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {pxToRem} from '@utils/styleMixins';
import {COLORS} from '@utils/constants';

export const StyledLearnInDrawer: typeof LearnInDrawer = styled(LearnInDrawer)`
  .ant-drawer-header {
    padding: 16px 14px;
  }

  .ant-drawer-body {
    padding: 16px 24px;
  }

  .ant-drawer-title {
    font-size: ${pxToRem(20, 16)};
    color: ${COLORS.Neutral950};
  }

  .ql-container {
    height: 203px;
    border-radius: 8px;
  }

  .ql-editor {
    font-size: ${pxToRem(16, 16)};
    line-height: ${pxToRem(20, 16)};
    color: ${COLORS.Neutral950};
    padding: 16px;
  }
  .ql-editor:focus {
    border-radius: 8px;
    border: 1px solid ${COLORS.Blue800};
  }

  #note-content p {
    margin-bottom: 0;
  }
`;

export const Subject = styled.h3`
  font-size: ${pxToRem(16, 16)};
  line-height: ${pxToRem(20, 16)};
  font-weight: 500;
  color: ${COLORS.Neutral900};
  border-bottom: 1px solid ${COLORS.Neutral200};
  padding-bottom: 8px;
  margin-bottom: 56px;
`;

export const CharacterCount = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 8px;
  font-size: ${pxToRem(16, 16)};
  line-height: ${pxToRem(16, 16)};
  color: ${COLORS.Neutral600};
`;

export const FooterContainer = styled.section`
  display: flex;
  justify-content: space-between;

  .btn-group {
    display: flex;
  }
`;

export const EditorContainer = styled.section`
  font-size: ${pxToRem(16, 16)};
  line-height: ${pxToRem(20, 16)};
  max-width: 463px;
  margin: 0 auto;
`;

export const DrawerBodyContent = styled.section`
  width: 463px;
  margin: 0 auto;
`;

export const IntroText = styled.p`
  color: ${COLORS.Neutral900};
  margin-bottom: 8px;
`;
