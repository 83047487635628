import LoadingSpinner from '@blocks/LoadingSpinner';
import {openInNewTab} from '@components/providers/utils';
import ProgramDetailPageContainer from '@components/reusable/PlanItemDetailPage/PlanItemDetailPage.container';
import {PlanItemType} from '@generated/enums';
import {ProgramUrlVM} from '@models/serverModels';
import {getProgramUrlRq} from '@store/apiEndpoints';
import {simpleQueryFn} from '@store/queryClient';
import {ReactNode, SyntheticEvent, useEffect, useState} from 'react';
import {useQuery} from 'react-query';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {CustomProgramUserDrawer} from '@blocks/CustomProgramUserDrawer';

interface Props {
  id: number;
  children?: ReactNode;
}

export const ViewProgramLink = ({id, children}: Props) => {
  const [showDetailPage, setShowDetailPage] = useState(false);
  // It is possible that one query is shared across different instanced of this component (same id)
  // So just relying on the query for state was causing issues. using this to pindown the specific instance
  // that want to react to the completion of this query.
  const [waitingForResults, setWaitingForResults] = useState(false);
  const [enableRequest, setEnabledRequest] = useState(false);
  const [showCustomProgram, setShowCustomProgram] = useState(false);

  const programUrlRq = getProgramUrlRq(id);
  const programUrlQuery = useQuery<ProgramUrlVM>(
    programUrlRq.queryKey,
    () => simpleQueryFn(programUrlRq.path),
    {
      enabled: enableRequest,
    }
  );

  useEffect(() => {
    if (waitingForResults) {
      viewDetails();
      setEnabledRequest(false);
      setWaitingForResults(false);
    }
  }, [programUrlQuery.isSuccess]);

  useEffect(() => {
    if (programUrlQuery.isError) {
      setEnabledRequest(false);
      setWaitingForResults(false);
    }
  }, [programUrlQuery.isError]);

  const onLinkClicked = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!programUrlQuery.data) {
      setEnabledRequest(true);
      setWaitingForResults(true);
    } else {
      viewDetails();
    }
  };

  const viewDetails = () => {
    if (!programUrlQuery.data.isExternalUrl) {
      setShowCustomProgram(true);
      setShowDetailPage(true);
      return;
    }
    if (programUrlQuery.data.url) {
      openInNewTab(programUrlQuery.data.url);
      return;
    }
  };

  return (
    <>
      <button className="pseudo-link-hover" onClick={(e) => onLinkClicked(e)}>
        {children}
        {waitingForResults && (
          <LoadingSpinner style={{fontSize: 'inherit', color: 'inherit'}} />
        )}
      </button>
      {showDetailPage && (
        <ProgramDetailPageContainer
          itemType={PlanItemType.Program}
          id={id}
          visible={showDetailPage}
          onClose={() => {
            setShowDetailPage(false);
          }}
        />
      )}

      {showDetailPage && showCustomProgram && (
        <CustomProgramUserDrawer
          isActivePlanItem={false}
          key={id}
          customProgramId={id}
          visible={showDetailPage}
          onClose={() => setShowDetailPage(false)}
        />
      )}
    </>
  );
};

export default ViewProgramLink;
