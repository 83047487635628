import {useDeferredValue, useMemo, useState} from 'react';
import t from '@i18n/translate';

type NoDataProps = {
  noDataStyle: React.CSSProperties;
  noDataHeader?: string;
  noDataSubheader?: string;
};

/**
 * Custom React hook for performing local search/filtering on an array of objects based on a specified property.
 *
 * @template T - The type of objects in the data array.
 * @param {T[]} data - The array of objects to search/filter.
 * @param {keyof T} property - The property of the objects to search within.
 * @returns {{
 *   filteredData: T[];
 *   searchFilter: string;
 *   setSearchFilter: React.Dispatch<React.SetStateAction<string>>;
 *   getNoDataTableProps: (headers?:{noDataHeader: string;noDataSubheader: string;}) => NoDataProps; // No need to type in JSDocs
 * }} - An object containing the filtered data, the search filter string, the function to update the search filter, and additional utility functions.
 */
function useLocalSearch<T>(data: T[] = [], property: keyof T) {
  const [searchFilter, setSearchFilter] = useState<string>('');
  const deferredSearchFilter = useDeferredValue(searchFilter);

  const filteredData = useMemo(() => {
    return data.filter((item) =>
      (item[property] as string)
        ?.toLocaleLowerCase()
        .includes(deferredSearchFilter.toLocaleLowerCase())
    );
  }, [deferredSearchFilter, data, property]);

  /**
   * Utility function designed to simplify the creation of
   * props for customizing the empty data message in LearnInTable.
   */
  const getNoDataTableProps = (headers?: {
    noDataHeader: string;
    noDataSubheader: string;
  }) => {
    const noDataTextProps: NoDataProps = {
      noDataStyle: {height: 'max-content', margin: '40px 24px'},
    };

    if (!!searchFilter && !filteredData?.length) {
      Object.assign(noDataTextProps, {
        noDataHeader:
          headers?.noDataHeader || t('GENERIC__EMPTY_RESULTS__TITLE'),
        noDataSubheader:
          headers?.noDataSubheader ||
          t('GENERIC__EMPTY_RESULTS__DESCRIPTION_ALT'),
      });
    }

    return noDataTextProps;
  };

  return {
    filteredData,
    searchFilter,
    setSearchFilter,
    getNoDataTableProps,
  };
}

export default useLocalSearch;
