import styled from 'styled-components';
import {Progress} from 'antd';
import {ProgressProps} from 'antd/lib/progress';
import {ProgressStyles} from './ProgressTheme';
import {ProgressTags} from './ProgressEnums';

interface Props extends ProgressProps {
  tag: ProgressTags;
}

const StyledProgressBar = styled(Progress)`
  display: flex !important;
  & .ant-progress-text {
    margin-top: 4px;
    color: ${(props: any) =>
      props.textColor || ProgressStyles[props.tag].textColor};
    font-size: ${(props: any) =>
      props.textFontSize || ProgressStyles[props.tag].textFontSize};
    font-family: ${(props: any) =>
      props.textFontFamily || ProgressStyles[props.tag].textFontFamily};
    line-height: ${(props: any) =>
      props.textLineHeight || ProgressStyles[props.tag].textLineHeight};
  }
  & .ant-progress-bg {
    background-color: ${(props: any) =>
      props.activeBackground || ProgressStyles[props.tag].activeBackground};
  }
`;

/**
 *
 * This is the standard progress bar that should be used in all places where progress bars are needed. You may need to
 * add new tags to this progress bar if you are implementing a new type of progress bar. The default progress bar should cover
 * most use cases  but you can pass in props to override styling if necessary.
 */
export function LearnInProgressBar({...rest}: Props) {
  return <StyledProgressBar {...rest} />;
}
