import {useState} from 'react';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import CustomProgramAdminDrawerHeader from './../CustomProgramAdminDrawerHeader.container';
import CustomProgramAdminDrawerSideNav from './../CustomProgramAdminDrawerSideNav.container';
import {ScreenContainer} from './../CustomProgramAdminDrawer';
import CohortSetupScreen from './CohortsSetupScreen.container';
import {notify} from '@components/user/notifications';
import CurriculumScreen from './../curriculum/CurriculumScreen.container';
import {CustomProgramScreens} from '@utils/ClientPaths';
import CustomProgramPermissionsScreenContainer from '@components/admin/pages/programs/customPrograms/permissions/CustomProgramPermissionsScreen.container';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CohortAdminPageDrawerProps {
  visible: boolean;
  onClose: () => void;
  parentCustomProgramId: number;
  cohortId: number | undefined;
  setCohortForEditIdOnCreate?: (id: number) => void;
}

function CohortAdminPageDrawer({
  visible,
  onClose,
  parentCustomProgramId,
  cohortId,
  setCohortForEditIdOnCreate,
}: CohortAdminPageDrawerProps) {
  const [currentScreen, setCurrentScreen] = useState<CustomProgramScreens>(
    CustomProgramScreens.Setup
  );
  const [modalClassName, setModalClassName] = useState('');

  const updateModalBackground = (toggleWhiteBackgroundOn: boolean) => {
    setModalClassName(toggleWhiteBackgroundOn ? 'white-background' : '');
  };

  const isCurriculumTabSelected =
    currentScreen === CustomProgramScreens.Curriculum;
  return (
    <LearnInPageDrawer
      visible={visible}
      onClose={onClose}
      title={currentScreen}
      className={modalClassName}
      headerContent={
        <CustomProgramAdminDrawerHeader
          customProgramId={parentCustomProgramId}
          cohortId={cohortId}
          isForCohort
        />
      }>
      <CustomProgramAdminDrawerSideNav
        currentScreen={currentScreen}
        onClickSetup={() => setCurrentScreen(CustomProgramScreens.Setup)}
        onClickPermissions={() =>
          setCurrentScreen(CustomProgramScreens.Permissions)
        }
        onClickCurriculum={() => {
          if (!cohortId) {
            return notify.saveProgramRequired();
          }
          setCurrentScreen(CustomProgramScreens.Curriculum);
        }}
      />

      {/* SETUP SCREEN */}
      {currentScreen === CustomProgramScreens.Setup && (
        <ScreenContainer>
          <CohortSetupScreen
            customProgramId={parentCustomProgramId}
            cohortId={cohortId}
            setCohortForEditIdOnCreate={setCohortForEditIdOnCreate}
          />
        </ScreenContainer>
      )}

      {/* Permissions SCREEN */}
      {currentScreen === CustomProgramScreens.Permissions && (
        <ScreenContainer isCurriculumTabSelected={isCurriculumTabSelected}>
          <CustomProgramPermissionsScreenContainer customProgramId={cohortId} />
        </ScreenContainer>
      )}

      {/* CURRICULUM SCREEN */}
      {isCurriculumTabSelected && (
        <ScreenContainer isCurriculumTabSelected={isCurriculumTabSelected}>
          <CurriculumScreen
            customProgramId={cohortId}
            updateModalBackground={updateModalBackground}
          />
        </ScreenContainer>
      )}
    </LearnInPageDrawer>
  );
}

export default CohortAdminPageDrawer;
