import {ReactNode} from 'react';

// Note: LightGrey and DarkGrey use the same grey in the new designs.
// TODO: Remove LightGrey and DarkGrey and create a Grey once the NewTag feature flag is removed.
export enum TagColors {
  Green = 'green',
  LightGrey = 'grey',
  Red = 'red',
  Yellow = 'yellow',
  DarkGrey = 'grey',
}

export interface TagComponetProps {
  tagColor?: TagColors;
  dataTestId?: string;
  label: ReactNode;
}
