import {ProductSwitcher} from '@components/ProductSwitcher/ProductSwitcher';
import {TIsFeatureFlagOn} from './useFeatureFlags';

export const useShowProductSwitcher = (
  isFeatureFlagOn: TIsFeatureFlagOn,
  isAcademiesIntegratedExperienceDisabled: boolean
) => {
  const hideForIntegratedAcademies =
    isFeatureFlagOn.HideProductSwitcher &&
    !isAcademiesIntegratedExperienceDisabled;

  const showProductSwitcher =
    isFeatureFlagOn.ProductSwitcher && !hideForIntegratedAcademies;

  return (
    showProductSwitcher && (
      <span data-testid="product-switcher">
        <ProductSwitcher
          showLxpOption={isFeatureFlagOn.ShowProductSwitcherLxpOption}
        />
      </span>
    )
  );
};
