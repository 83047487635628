import {i18n, k} from '@i18n/translate';
import {
  CustomModalProps,
  LearnInModal,
} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';

const AdminPersonalLearningBudgetIncentiveConfirmationModal = (
  props: Pick<CustomModalProps, 'visible' | 'onCancel' | 'onOk'>
) => {
  return (
    <LearnInModal
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      content={i18n.t(k.INCENTIVE__CONFIRM_PUBLISH_DESCRIPTION)}
      okButtonChild={i18n.t(k.INCENTIVE__CONFIRM_PUBLISH_BUTTON)}
      okButtonProps={{id: 'confirm-create-personal-learning-budget-button'}}
      tag={ModalTags.PublishIncentiveConfirmation}
      title={i18n.t(k.INCENTIVE__CONFIRM_TITLE)}
      {...props}
    />
  );
};

export default AdminPersonalLearningBudgetIncentiveConfirmationModal;
