import {i18n, k} from '@i18n/translate';

export const mapValueToProgressReportSummary = (value: number): string => {
  switch (value) {
    case 0:
      return i18n.t(k.PROGRESS__STATUS__FEELING_GREAT);
    case 1:
      return i18n.t(k.PROGRESS__STATUS__MAKING_PROGRESS);
    case 2:
      return i18n.t(k.PROGRESS__STATUS__TOO_BUSY);
    case 3:
      return i18n.t(k.PROGRESS__STATUS__STRESSED);
    case 4:
      return i18n.t(k.PROGRESS__STATUS__NEED_HELP);
    default:
      console.warn('Unrecognized progress report summary: ', value);
      return '';
  }
};
