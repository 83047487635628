import {CreditCardStatus} from './../enums';
import {ICreditCardBasicInfo} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ICreditCardBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getICreditCardBasicInfoMockData(
  args?: Partial<ICreditCardBasicInfo>
): ICreditCardBasicInfo {
  return {
    createdOn: String(faker.date.past()),
    id: faker.datatype.number(),
    providerCardId: String(faker.datatype.number()),
    status: CreditCardStatus.Pending,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ICreditCardBasicInfoMockData: ICreditCardBasicInfo = {
  createdOn: '2023-03-29T22:17:19.738072',
  id: 1,
  providerCardId: '1',
  status: CreditCardStatus.Pending,
};
