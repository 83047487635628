/**
 * Splits given DOM tree in 2 parts
 * delimited by a "middle element"
 */
export function splitDOM(
  middleElementSelector: string,
  htmlMarkup: HTMLElement,
  parentSelector: string = null
): [HTMLElement, HTMLElement, HTMLElement] {
  const rootElement = parentSelector
    ? htmlMarkup.querySelector(parentSelector)
    : htmlMarkup;

  const middleElement = rootElement.querySelector<HTMLElement>(
    middleElementSelector
  );

  if (!middleElement) {
    return [htmlMarkup, null, null];
  }

  const upperContainer = document.createElement('section');
  const middleContainer = document.createElement('section');
  const lowerContainer = document.createElement('section');

  /**
   * appends the middle element to a container
   * so all containers' content can be retrieved
   * consistently
   * e.g.: using the `innerHTML` property
   */
  middleContainer.appendChild(middleElement.cloneNode(true));

  let currentNode = rootElement.firstChild;

  /**
   * append all elements to the upperContainer
   * until the middle one
   */
  while (currentNode) {
    if (currentNode === middleElement) {
      break;
    }

    const nextNode = currentNode.nextSibling;
    upperContainer.appendChild(currentNode.cloneNode(true));
    currentNode = nextNode;
  }

  /**
   * skip the middle element and append remaining elements
   * to the lowerContainer
   */
  currentNode = currentNode.nextSibling;
  while (currentNode) {
    const nextNode = currentNode.nextSibling;
    lowerContainer.appendChild(currentNode.cloneNode(true));
    currentNode = nextNode;
  }

  /**
   * return the containers in a tuple
   */
  return [upperContainer, middleContainer, lowerContainer];
}
