import CustomProgramUserDrawerAdminSideNav from './CustomProgramUserDrawerAdminSideNav';
import {ContentType} from '@generated/enums';
import {useCustomProgramPreviewQuery} from '@generated/hooks';
import {CustomProgramScreens} from '@utils/ClientPaths';
import {
  SnippetsOutlined,
  BarChartOutlined,
  ExperimentOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useCustomProgramPermissionVMsQuery} from '@hooks/apiEndpoints/permission/queries';
import {i18n, k} from '@i18n/translate';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/
interface CustomProgramUserDrawerAdminSideNavContainer {
  onClickNavItem: (screen: CustomProgramScreens) => void;
  customProgramId: number;
  currentScreen: CustomProgramScreens;
}

function CustomProgramUserDrawerAdminSideNavContainer({
  customProgramId,
  onClickNavItem,
  currentScreen,
}: CustomProgramUserDrawerAdminSideNavContainer) {
  const {isAdmin} = useAuth();

  const {data: customProgramPermissions} =
    useCustomProgramPermissionVMsQuery(null);
  const customProgramPermission = customProgramPermissions?.find(
    (program) => program.customProgramId === customProgramId
  );

  const {data: customProgram} = useCustomProgramPreviewQuery(
    {customProgramId, queryParams: {includeProgress: true}},
    {enabled: false}
  );
  // Check if there are projects in program to show the tab
  const programHasProjects = customProgram?.sections?.some((section) => {
    return section?.content?.some((content) => {
      return content?.contentType === ContentType.Project;
    });
  });

  const showProjectsNavItem =
    programHasProjects &&
    (isAdmin || customProgramPermission?.canReviewProject);

  const showReportsNavItem = isAdmin || customProgramPermission?.canViewReport;

  const navItems = [
    {
      Icon: SnippetsOutlined,
      screen: CustomProgramScreens.Curriculum,
      label: i18n.t(k.PROGRAM__CUSTOM__CURRICULUM),
    },
    {
      Icon: CalendarOutlined,
      screen: CustomProgramScreens.Agenda,
      label: i18n.t(k.CALENDAR),
    },
    showProjectsNavItem && {
      Icon: ExperimentOutlined,
      screen: CustomProgramScreens.Projects,
      label: i18n.t(k.PROJECT__PLURAL),
    },
    showReportsNavItem && {
      Icon: BarChartOutlined,
      screen: CustomProgramScreens.Reports,
      label: i18n.t(k.REPORT__PLURAL),
    },
  ]
    .filter(Boolean)
    .map(({Icon, screen, label}) => ({
      Icon,
      label,
      isSelected: screen === currentScreen,
      onClick: () => onClickNavItem(screen),
    }));

  return <CustomProgramUserDrawerAdminSideNav navItems={navItems} />;
}

export default CustomProgramUserDrawerAdminSideNavContainer;
