import {
  ReimbursementTargetVM,
  ReimbursementVM,
  UserPlanItemCardVM,
} from '@models/serverModels';
import {ReimbursementPayload} from '@models/api/finance/payloads';
import {
  getAddReimbursementRm,
  getFinanceIncentiveRq,
  getReimbursementDetailsRq,
  getUserReimbursementsRq,
} from '@store/apiEndpoints';
import {useMutation, useQuery} from 'react-query';
import {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import ManageFinance from '@pages/manageFinance/ManageFinance';

interface Props {
  item?: UserPlanItemCardVM;
  userPlanItemId: string;
}

const ManageFinanceDataWrapper = ({item, userPlanItemId}: Props) => {
  const financeIncentiveRq = getFinanceIncentiveRq(userPlanItemId);
  const financeIncentiveQuery = useQuery<UserPlanItemCardVM>(
    financeIncentiveRq.queryKey,
    () => simpleQueryFn(financeIncentiveRq.path),
    {
      enabled: !item,
    }
  );

  const userReimbursementsRq = getUserReimbursementsRq(userPlanItemId);
  const userReimbursementsQuery = useQuery<ReimbursementVM[]>(
    userReimbursementsRq.queryKey,
    () => simpleQueryFn(userReimbursementsRq.path),
    {
      enabled: !item,
    }
  );

  const reimbursementDetailsRq = getReimbursementDetailsRq(userPlanItemId);
  const reimbursementDetailsQuery = useQuery<ReimbursementTargetVM>(
    reimbursementDetailsRq.queryKey,
    () => simpleQueryFn(reimbursementDetailsRq.path)
  );

  const addReimbursementsMutation = useMutation(
    (payload: ReimbursementPayload) => {
      const addReimbursementRm = getAddReimbursementRm(payload);
      return simpleMutationFn<number>(
        addReimbursementRm.path,
        addReimbursementRm.queryKey
      );
    }
  );

  return (
    <ManageFinance
      userPlanItemId={userPlanItemId}
      item={item}
      isUserItemFetchLoading={financeIncentiveQuery.isLoading}
      userItemFetchError={financeIncentiveQuery.error?.toString()}
      userItemData={financeIncentiveQuery.data}
      isUserReimbursementFetchLoading={userReimbursementsQuery.isLoading}
      userReimbursementFetchError={userReimbursementsQuery.error?.toString()}
      userReimbursementData={userReimbursementsQuery.data}
      refetchUserReimbursements={userReimbursementsQuery.refetch}
      isUserReimbursementTargetsFetchLoading={
        reimbursementDetailsQuery.isLoading
      }
      userReimbursementTargetsFetchError={reimbursementDetailsQuery.error?.toString()}
      userReimbursementTargetsData={reimbursementDetailsQuery.data}
      addReimbursements={addReimbursementsMutation.mutateAsync}
    />
  );
};

export default ManageFinanceDataWrapper;
