import {useState} from 'react';
import CustomProgramSetupScreen from './CustomProgramSetupScreen';
import {
  CustomProgramAdminDetailVM,
  CustomProgramPayload,
  IGiveRestrictedCustomProgramAccessPayload,
  RemoveRestrictedCustomProgramAccessPayload,
} from '@models/serverModels';
import {useMutation} from 'react-query';
import {simpleMutationFn, simpleDeleteFn} from '@store/queryClient';
import {getCompanyUsers} from '@utils/ClientPaths';
import {
  getGiveRestrictedCustomProgramAccessRm,
  getRemoveRestrictedProgramAccessRm,
  getAddCustomProgramRm,
  getUpdateCustomProgramRm,
  getDeleteCustomProgramCoverImageRm,
} from '@store/apiEndpoints/customProgram/mutations';
import {debounce} from 'lodash';
import {api} from '@store/api';
import {notify} from '@components/user/notifications';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import useTreatment from '@hooks/useTreatment';
import {
  useCustomProgramAdminDetailVMQuery,
  useCustomProgramAdminDetailVMsQuery,
  useGetRestrictedCustomProgramAccessUsersQuery,
  useProgramSkillVMsQuery,
} from '@hooks/apiEndpoints/customProgram/queries';
import {useUserQuery} from '@generated/hooks';
import {mapTextToEnglish} from '@utils/enumMapping/MapTextToUserLanguage';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface ICustomProgramSetupScreenContainerProps {
  customProgramId: number | undefined;
  setCustomProgramForEdit?: (program: CustomProgramAdminDetailVM) => void;
  refreshParentData?: () => void;
  isWelcomeEmailEnabled?: boolean;
  setIsWelcomeEmailEnabled?: (isEnabled: boolean) => void;
}

function CustomProgramSetupScreenContainer({
  customProgramId,
  setCustomProgramForEdit,
  refreshParentData,
  setIsWelcomeEmailEnabled,
  isWelcomeEmailEnabled,
}: ICustomProgramSetupScreenContainerProps) {
  const {data: user} = useUserQuery(null);
  const [companyUsersSearchResults, setCompanyUsers] = useState([]);
  const [isCompanyUserSearchInProgress, setIsCompanyUserSearchInProgress] =
    useState(false);
  const [selectedUsersForAdding, setCompanyUsersForAdding] = useState([]);

  // Feature Flags
  const areSlackControlsOn =
    useTreatment(FeatureFlagExperiments.SlackControls) ===
    FeatureFlagTreatments.On;

  // React Query
  const getCustomProgramsQuery = useCustomProgramAdminDetailVMsQuery({
    enabled: false,
  });
  const customProgramDetailsQuery = useCustomProgramAdminDetailVMQuery(
    customProgramId,
    {enabled: !!customProgramId}
  );

  const restrictedAccessUsersQuery =
    useGetRestrictedCustomProgramAccessUsersQuery(customProgramId, {
      enabled: !!customProgramId,
    });

  const giveRestrictedAccessMutation = useMutation(
    (args: IGiveRestrictedCustomProgramAccessPayload) => {
      const giveRestrictedCustomProgramAccess =
        getGiveRestrictedCustomProgramAccessRm(args);
      return simpleMutationFn<null>(
        giveRestrictedCustomProgramAccess.path,
        giveRestrictedCustomProgramAccess.payload
      );
    },
    {
      onSuccess: async () => {
        notify.recommendUsersSuccess();
        setCompanyUsersForAdding([]);
        await restrictedAccessUsersQuery.refetch();
      },
    }
  );

  const removeRestrictedProgramAccessMutation = useMutation(
    (payload: RemoveRestrictedCustomProgramAccessPayload) => {
      const removeRestrictedProgramAccess =
        getRemoveRestrictedProgramAccessRm(payload);
      return simpleDeleteFn<null>(
        removeRestrictedProgramAccess.path,
        removeRestrictedProgramAccess.payload
      );
    },
    {
      onSuccess: async () => {
        notify.removeUserProgramAccessSuccess();
        await restrictedAccessUsersQuery.refetch();
      },
    }
  );

  // Skill Tags
  const getSkillTagsQuery = useProgramSkillVMsQuery(null);

  // Company Employees Search
  const handleCompanyUserSearch = debounce((name: string) => {
    setIsCompanyUserSearchInProgress(true);
    api.get(getCompanyUsers(user?.companyId, {name})).then(({data}) => {
      setCompanyUsers(data || []);
      setIsCompanyUserSearchInProgress(false);
    });
  }, 800);

  const handleClickRemoveAccess = (
    payload: RemoveRestrictedCustomProgramAccessPayload
  ) => {
    removeRestrictedProgramAccessMutation.mutateAsync(payload);
  };

  const handleClickAddRecommendedUsers = async () => {
    // Program must be saved to add user access
    if (!customProgramId) {
      return notify.saveProgramRequired();
    }
    // Send api call to give users access
    await giveRestrictedAccessMutation.mutateAsync({
      id: customProgramId,
      users: selectedUsersForAdding.map(({value}) => value),
    });
  };

  const deleteCustomProgramCoverImageMutation = useMutation(
    () => {
      const deleteCustomProgramCoverImageRm =
        getDeleteCustomProgramCoverImageRm(customProgramId);
      return simpleDeleteFn<null>(
        deleteCustomProgramCoverImageRm.path,
        deleteCustomProgramCoverImageRm.payload
      );
    },
    {
      onSuccess: async () => {
        notify.deleteCustomProgramCoverImageSuccess();
        await customProgramDetailsQuery.invalidateExact();
        await getCustomProgramsQuery.invalidateExact();
      },
      onError: () => {
        notify.deleteCustomProgramCoverImageError();
      },
    }
  );

  // Add Custom Program
  const addCustomProgramMutation = useMutation(
    (customProgram: CustomProgramPayload) => {
      const addCustomProgramRm = getAddCustomProgramRm(customProgram);
      return simpleMutationFn<CustomProgramAdminDetailVM>(
        addCustomProgramRm.path,
        addCustomProgramRm.payload
      );
    },
    {
      onSuccess: async (customProgram) => {
        // This refreshes the custom program admin drawer with the saved program's information
        if (!!setCustomProgramForEdit) setCustomProgramForEdit(customProgram);
        notify.addCustomProgramSuccess();
        refreshParentData && refreshParentData();
        await customProgramDetailsQuery.invalidateExact();
        await getCustomProgramsQuery.invalidateExact();
      },
    }
  );

  // Update Custom Program
  const updateCustomProgramMutation = useMutation(
    (customProgram: CustomProgramPayload) => {
      const updateCustomProgramRm = getUpdateCustomProgramRm(customProgram);
      return simpleMutationFn<null>(
        updateCustomProgramRm.path,
        updateCustomProgramRm.payload
      );
    },
    {
      onSuccess: async () => {
        await customProgramDetailsQuery.invalidateExact();
        await getCustomProgramsQuery.invalidateExact();
        notify.updateCustomProgramSuccess();
      },
    }
  );

  const handleClickSave = async (customProgram: CustomProgramPayload) => {
    if (customProgram?.id) {
      await updateCustomProgramMutation.mutateAsync({
        ...customProgram,
        title: mapTextToEnglish(customProgram.title),
      });
    } else {
      await addCustomProgramMutation.mutateAsync(customProgram);
    }
  };

  return (
    <CustomProgramSetupScreen
      isWelcomeEmailEnabled={isWelcomeEmailEnabled}
      setIsWelcomeEmailEnabled={setIsWelcomeEmailEnabled}
      companyUsersSearchResults={companyUsersSearchResults}
      customProgramForEdit={
        customProgramId ? customProgramDetailsQuery.data : null
      }
      isCompanyUserSearchInProgress={isCompanyUserSearchInProgress}
      onClickAddRecommendedUsers={handleClickAddRecommendedUsers}
      onClickRemoveAccess={handleClickRemoveAccess}
      onClickSave={handleClickSave}
      onCompanyUserSearch={handleCompanyUserSearch}
      onCompanyUserSearchChange={setCompanyUsersForAdding}
      onDeleteCoverImage={async () =>
        await deleteCustomProgramCoverImageMutation.mutateAsync()
      }
      recommendedUsers={restrictedAccessUsersQuery.data}
      selectedUsersForAdding={selectedUsersForAdding}
      tagSuggestions={getSkillTagsQuery.data}
      showSlackControls={areSlackControlsOn}
    />
  );
}

export default CustomProgramSetupScreenContainer;
