// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/UserProgressStatus.cs

enum UserProgressStatus {
  Upcoming,
  Due,
  Late,
  Submitted,
}

export default UserProgressStatus;
