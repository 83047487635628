import {CustomProgramNudgeUserRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {CustomProgramNudgeUserRequestSchema} from '../zodSchemas/CustomProgramNudgeUserRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramNotificationController.cs

/*
|--------------------------------------------------------------------------
|  {customProgramId}/users/nudge 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsUsersAllNudgeMutation} from '@generated/hooks';
 *
 *  const customProgramsUsersAllNudge = useCustomProgramsUsersAllNudgeMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramsUsersAllNudge.mutate({
 *    payload: {
 *      ...CustomProgramNudgeUserRequest
 *    },
 *    pathVars: {
 *      customProgramId
 *    }
 *  })
 *
 */

export const useCustomProgramsUsersAllNudgeMutation = mutationHookFactory<
  CustomProgramNudgeUserRequest, // payload
  {customProgramId: number}, // path variables
  null
>(
  'useCustomProgramsUsersAllNudgeMutation',
  'post',
  ({customProgramId}) => `custom-programs/${customProgramId}/users/nudge`,
  {payloadSchema: CustomProgramNudgeUserRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  {customProgramId}/users/{userCompanyId}/nudge 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsUsersNudgeMutation} from '@generated/hooks';
 *
 *  const customProgramsUsersNudge = useCustomProgramsUsersNudgeMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramsUsersNudge.mutate({
 *    payload: {
 *      ...CustomProgramNudgeUserRequest
 *    },
 *    pathVars: {
 *      customProgramId,
 *      userCompanyId
 *    }
 *  })
 *
 */

export const useCustomProgramsUsersNudgeMutation = mutationHookFactory<
  CustomProgramNudgeUserRequest, // payload
  {customProgramId: number; userCompanyId: number}, // path variables
  null
>(
  'useCustomProgramsUsersNudgeMutation',
  'post',
  ({customProgramId, userCompanyId}) =>
    `custom-programs/${customProgramId}/users/${userCompanyId}/nudge`,
  {payloadSchema: CustomProgramNudgeUserRequestSchema}
);
