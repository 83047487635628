import {Row} from 'antd';
import {SubTitle} from '@components/user/pages/userPlan/components/userPlanItemCard/UserPlanItemCard';

export interface DurationOrAmountColumnData {
  label: string;
  value: string;
}

export const DurationOrAmountColumn = (props: {
  data: DurationOrAmountColumnData;
  children?: JSX.Element;
}) => {
  return (
    <>
      <Row>
        <SubTitle>{props.data.label}</SubTitle>
      </Row>
      <Row>
        <p className="font-body-large">{props.data.value}</p>
        {!!props.children && props.children}
      </Row>
    </>
  );
};
