import {Demand} from './../enums';
import {AudienceVM} from './../interfaces';
import {
  BusinessGoalVMMockData,
  getBusinessGoalVMMockData,
} from './BusinessGoalVM.mock';
import {faker} from '@faker-js/faker';

// Interface: AudienceVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAudienceVMMockData(args?: Partial<AudienceVM>): AudienceVM {
  return {
    avgSalary: faker.datatype.number(),
    businessGoals: [getBusinessGoalVMMockData()],
    demand: Demand.Low,
    members: faker.datatype.number(),
    onboardingWeeks: faker.datatype.number(),
    recruitmentPercent: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AudienceVMMockData: AudienceVM = {
  avgSalary: 123,
  businessGoals: [BusinessGoalVMMockData],
  demand: Demand.Low,
  members: 123,
  onboardingWeeks: 123,
  recruitmentPercent: 123,
  title: 'Advanced Web Design',
};
