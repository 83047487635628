import {COLORS} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';
import styled from 'styled-components';
import {BodySpan} from '@components/reusable/Typography';
import {Space} from 'antd';

export const Container = styled.div`
  display: flex;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 2;
  width: min-content;
  margin-right: 2rem;

  @media (max-width: ${Breakpoints.Mobile}px) {
    margin-right: 0;
  }
`;

export const BreakdownContainer = styled.article`
  background: ${COLORS.White};
  box-shadow: ${COLORS.BoxShadowStandard};
  border-radius: 8px;
  flex-grow: 0;

  @media (min-width: ${Breakpoints.DesktopLarge}px) {
    width: 450px;
  }
  @media (min-width: ${Breakpoints.Tablet}px) and (max-width: ${Breakpoints.DesktopLarge}px) {
    width: 350px;
  }

  @media (max-width: ${Breakpoints.Tablet}px) {
    width: 260px;
    margin: auto;
    margin-top: 24px;
  }
`;

export const BudgetElement = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${COLORS.Neutral100};
  display: flex;
  justify-content: space-between;
`;

export const BudgetText = styled(BodySpan)`
  line-height: 1.25rem;
  display: inline-flex;
`;

export const DonutStatus = styled(BodySpan)`
  align-self: center;
  max-width: 85%;
`;

export const Dot = styled.div`
  background: ${({color}) => color};
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-top: 2px;
  margin-right: 8px;
`;

export const DonutContainer = styled(Space)`
  justify-content: center;
`;
