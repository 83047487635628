import {ContentType, RsvpStatus} from './../enums';
import {CustomProgramContentProgressResult} from './../interfaces';
import {
  UserItemStatusVMMockData,
  getUserItemStatusVMMockData,
} from './UserItemStatusVM.mock';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramContentProgressResult

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramContentProgressResultMockData(
  args?: Partial<CustomProgramContentProgressResult>
): CustomProgramContentProgressResult {
  return {
    completedOn: String(faker.date.past()), // optional
    id: faker.datatype.number(),
    order: faker.datatype.number(),
    rsvpStatus: RsvpStatus.None, // optional
    status: getUserItemStatusVMMockData(),
    title: faker.company.catchPhrase(),
    type: ContentType.Text,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramContentProgressResultMockData: CustomProgramContentProgressResult =
  {
    completedOn: '2023-03-29T22:17:19.738072', // optional
    id: 1,
    order: 123,
    rsvpStatus: RsvpStatus.None, // optional
    status: UserItemStatusVMMockData,
    title: 'Advanced Web Design',
    type: ContentType.Text,
  };
