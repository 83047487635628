// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/UserProgramStatus.cs

enum UserProgramStatus {
  Planned,
  Applied,
  Admitted,
  Denied,
  InProgress,
  Complete,
  Cancelled,
}

export default UserProgramStatus;
