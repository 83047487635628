import {IntegrationChatChannelType, IntegrationChatProvider} from './../enums';
import {AcademyIntegrationChatChannelResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AcademyIntegrationChatChannelResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyIntegrationChatChannelResponseMockData(
  args?: Partial<AcademyIntegrationChatChannelResponse>
): AcademyIntegrationChatChannelResponse {
  return {
    academyId: faker.datatype.number(),
    channelLink: faker.internet.url(),
    id: faker.datatype.number(),
    integrationChatChannelType: IntegrationChatChannelType.Manual,
    integrationChatProvider: IntegrationChatProvider.Slack,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyIntegrationChatChannelResponseMockData: AcademyIntegrationChatChannelResponse =
  {
    academyId: 1,
    channelLink: 'https://this-person-does-not-exist.com/en',
    id: 1,
    integrationChatChannelType: IntegrationChatChannelType.Manual,
    integrationChatProvider: IntegrationChatProvider.Slack,
  };
