import {useState} from 'react';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';
import {LearnInProgressBar} from '@components/learnin';
import {i18n, k} from '@i18n/translate';
import {startCase} from 'lodash';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  background: ${COLORS.Neutral50};
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  margin: 16px 0;
`;

const Uploading = styled.p`
  color: ${COLORS.Blue800};
  font-size: ${pxToRem(12, 16)};
  margin: 3px 0px;
  font-weight: 500;
`;

const FileName = styled.label`
  font-family: Roboto;
  font-size: ${pxToRem(16, 16)};
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface AttachmentUploadProgressProps {
  fileName: string;
  uploadPercentage: number;
  cancelUpload: () => Promise<void>;
}

function AttachmentUploadProgress({
  fileName,
  uploadPercentage,
  cancelUpload,
}: AttachmentUploadProgressProps) {
  const [isCancelling, setIsCancelling] = useState(false);
  return (
    <Container>
      <FileName>{fileName}</FileName>
      {/* TODO: Add file size */}
      <div>
        <LearnInProgressBar
          percent={uploadPercentage}
          strokeColor={COLORS.Blue800}
          round
        />
      </div>
      <Uploading>{startCase(i18n.t(k.LABEL__UPLOADING))}...</Uploading>
      <button
        onClick={async () => {
          try {
            setIsCancelling(true);
            await cancelUpload();
          } catch (e) {
            console.error(e);
          } finally {
            setIsCancelling(false);
          }
        }}>
        {isCancelling ? i18n.t(k.CTA__CANCELLING) : i18n.t(k.CTA__CANCEL)}
      </button>
    </Container>
  );
}

export default AttachmentUploadProgress;
