import {FinanceAmountType, FinanceType} from './../enums';
import {PreApprovalAmountBalanceVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: PreApprovalAmountBalanceVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPreApprovalAmountBalanceVMMockData(
  args?: Partial<PreApprovalAmountBalanceVM>
): PreApprovalAmountBalanceVM {
  return {
    amountBalance: faker.datatype.number(), // optional
    amountLimit: faker.datatype.number(), // optional
    financeAmountType: FinanceAmountType.UpTo,
    financeType: FinanceType.Loan,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PreApprovalAmountBalanceVMMockData: PreApprovalAmountBalanceVM = {
  amountBalance: 5, // optional
  amountLimit: 5, // optional
  financeAmountType: FinanceAmountType.UpTo,
  financeType: FinanceType.Loan,
};
