import {PlanItemType} from './../enums';
import {NewUserPlanItem} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: NewUserPlanItem

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getNewUserPlanItemMockData(
  args?: Partial<NewUserPlanItem>
): NewUserPlanItem {
  return {
    optionId: faker.datatype.number(),
    planId: faker.datatype.number(), // optional
    planItemType: PlanItemType.Program,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const NewUserPlanItemMockData: NewUserPlanItem = {
  optionId: 1,
  planId: 1, // optional
  planItemType: PlanItemType.Program,
};
