export const GIVEN_NAME_FIELD = 'GivenName';
export const FAMILY_NAME_FIELD = 'FamilyName';
export const JOB_ROLE_FIELD = 'Job';
export const EMAIL_FIELD = 'Email';
export const PHONE_FIELD = 'Phone';
export const LOCATION_FIELD = 'Location';
export const EMAIL_SETTINGS_FIELDS = 'EmailSettings';
export const CURRENCY_FIELD = 'Currency';
export const LANGUAGE_FIELD = 'Language';
// start checkbox group
export const ALLOW_EMAIL_FIELD = 'AllowEmail';
export const PROGRAM_NOMINATION_FIELD = 'ProgramNomination';
export const PROGRAM_ACCESS_FIELD = 'ProgramAccess';
export const APPLICATION_NOTIFICATIONS_FIELD = 'ApplicationNotifications';
export const MANAGER_APPROVAL_REQUESTS_FIELD = 'ManagerApprovalRequests';
export const APPROVAL_REQUESTS_FIELD = 'ApprovalRequests';
export const PROGRAM_NUDGE_REMINDERS = 'ProgramNudgeReminders';
export const DUE_DATE_REMINDERS = 'DueDateReminders';
export const EVENT_REMINDERS = 'EventReminders';
export const PROJECT_SUBMISSION = 'NewProjectSubmission';
export const PROJECT_FEEDBACK = 'NewProjectFeedback';
export const CONTRIBUTOR_UPDATE = 'ContributorUpdate';
