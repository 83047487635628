import {AcademyBrandVM} from './../interfaces';
import {
  IAcademyBrandBasicInfoMockData,
  getIAcademyBrandBasicInfoMockData,
} from './IAcademyBrandBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyBrandVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyBrandVMMockData(
  args?: Partial<AcademyBrandVM>
): AcademyBrandVM {
  return {
    ...getIAcademyBrandBasicInfoMockData(),
    backgroundColor: faker.internet.color(), // optional
    backgroundWelcomeColor: faker.internet.color(), // optional
    buttonDefaultWelcomeColor: faker.internet.color(), // optional
    buttonHoverWelcomeColor: faker.internet.color(), // optional
    isDefaultTheme: faker.datatype.boolean(),
    itemDefaultTextNavColor: faker.internet.color(), // optional
    itemDefaultTextWelcomeColor: faker.internet.color(), // optional
    itemHoverBackgroundNavColor: faker.internet.color(), // optional
    itemHoverTextNavColor: faker.internet.color(), // optional
    itemSelectedBackgroundNavColor: faker.internet.color(), // optional
    itemSelectedTextNavColor: faker.internet.color(), // optional
    primaryBrandColor: faker.internet.color(), // optional
    secondaryBrandColor: faker.internet.color(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyBrandVMMockData: AcademyBrandVM = {
  ...IAcademyBrandBasicInfoMockData,
  backgroundColor: '#FFD7DD', // optional
  backgroundWelcomeColor: '#FFD7DD', // optional
  buttonDefaultWelcomeColor: '#FFD7DD', // optional
  buttonHoverWelcomeColor: '#FFD7DD', // optional
  isDefaultTheme: true,
  itemDefaultTextNavColor: '#FFD7DD', // optional
  itemDefaultTextWelcomeColor: '#FFD7DD', // optional
  itemHoverBackgroundNavColor: '#FFD7DD', // optional
  itemHoverTextNavColor: '#FFD7DD', // optional
  itemSelectedBackgroundNavColor: '#FFD7DD', // optional
  itemSelectedTextNavColor: '#FFD7DD', // optional
  primaryBrandColor: '#FFD7DD', // optional
  secondaryBrandColor: '#FFD7DD', // optional
};
