import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {COLORS} from '@utils/constants';
import {formatDateTimeToMDYYYY} from '@utils/timeUtils';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {ProjectSubmissionAdminVM} from '@generated/interfaces';
import {TableColLink} from '@components/reusable/LearnInTable';
import Avatar from 'antd/lib/avatar/avatar';
import {getInitialsOfName} from '@components/providers/utils';
import {formatUrl} from '@utils/stringUtils';
import {RichTextEditor} from '@components/reusable/RichTextEditor';
import DOMPurify from 'dompurify';
import {pxToRem} from '@utils/styleMixins';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const DrawerContentHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ContentHeader = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: ${COLORS.Neutral950};
`;

const SubmittedOn = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${COLORS.Neutral900};
`;

const ResponseContainer = styled.div`
  background: ${COLORS.Neutral50};
  color: ${COLORS.Neutral950};
  padding: 16px;
  width: 100%;
`;

const FeedbackHeader = styled.div`
  align-items: center;
  color: ${COLORS.Neutral900};
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const EditorContainer = styled.section`
  font-size: ${pxToRem(16, 16)};
  line-height: ${pxToRem(20, 16)};
  margin: 0 auto;

  .ql-container {
    height: 200px;
  }

  .ql-editor {
    font-size: ${pxToRem(16, 16)};
    color: ${COLORS.Neutral950};
    padding: 16px;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ProjectResponseDrawerProps {
  onClickSubmitFeedback: (feedbackText: string) => void;
  onClose: () => void;
  submission: ProjectSubmissionAdminVM | undefined;
  visible: boolean;
  allowFeedback?: boolean;
  onClickUploadLink: (id: number) => void;
  companyId: number;
}

function ProjectResponseDrawer({
  onClickSubmitFeedback,
  onClose,
  submission,
  visible,
  allowFeedback,
  onClickUploadLink,
  companyId,
}: ProjectResponseDrawerProps) {
  const [feedbackText, setFeedbackText] = useState('');
  return (
    <LearnInDrawer
      zIndex={1002}
      closeButtonPlacement="right"
      responsiveSize={650}
      visible={visible}
      title={submission?.participantName}
      onClose={onClose}
      footer={
        !!submission?.feedback?.length || !allowFeedback ? null : (
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <LearnInButton
              tag={ButtonTags.Primary}
              disabled={!feedbackText}
              onClick={() => {
                onClickSubmitFeedback(feedbackText);
              }}>
              {i18n.t(k.FEEDBACK__SUBMIT)}
            </LearnInButton>
          </div>
        )
      }
      placement="right">
      <DrawerContentHeaderContainer>
        <ContentHeader>
          {i18n.t(k.SUBMISSION__FROM__FORMAT, {
            participant: submission?.participantName,
          })}
        </ContentHeader>
        <SubmittedOn
          dangerouslySetInnerHTML={{
            __html: i18n.t(k.STATUS__SUBMITTED_ON__FORMAT, {
              date: formatDateTimeToMDYYYY(submission?.submittedOn),
            }),
          }}></SubmittedOn>
      </DrawerContentHeaderContainer>

      {/* User Submitted Response Display */}
      <ResponseContainer>
        {!!submission?.response && submission?.response}
        {!!submission?.links?.length && (
          <a
            onClick={(e) => e.stopPropagation()}
            href={formatUrl(submission.links[0])}
            target="_blank"
            rel="noreferrer">
            <TableColLink>{i18n.t(k.SUBMISSION__VIEW)}</TableColLink>
          </a>
        )}

        {!!submission?.uploads?.length && (
          <TableColLink
            onClick={(e) => {
              e.stopPropagation();
              onClickUploadLink(
                submission.uploads[submission.uploads.length - 1].id
              );
            }}>
            {i18n.t(k.SUBMISSION__VIEW)}
          </TableColLink>
        )}
      </ResponseContainer>

      {!!submission?.feedback?.length && (
        // Submitted Feedback Display
        <div
          style={{
            borderTop: `1px solid ${COLORS.Neutral200}`,
            marginTop: '24px',
            paddingTop: '24px',
          }}>
          <FeedbackHeader>
            {i18n.t(k.FEEDBACK__TO__FORMAT, {
              user: submission?.participantName,
            })}
          </FeedbackHeader>
          <div style={{display: 'flex'}}>
            <div style={{marginRight: '7px'}}>
              <Avatar
                style={{
                  backgroundColor: COLORS.Neutral200,
                  verticalAlign: 'middle',
                  color: 'black',
                }}
                size="large"
                gap={1}>
                <>{getInitialsOfName(submission.feedback[0].createdBy)}</>
              </Avatar>
            </div>
            <ResponseContainer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}>
                <ContentHeader>
                  {submission.feedback[0].createdBy}
                </ContentHeader>
                <SubmittedOn
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(k.STATUS__SUBMITTED_ON__FORMAT, {
                      date: formatDateTimeToMDYYYY(
                        submission.feedback[0].createdOn
                      ),
                    }),
                  }}></SubmittedOn>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(submission.feedback[0].message),
                }}
              />
            </ResponseContainer>
          </div>
        </div>
      )}

      {!submission?.feedback?.length && allowFeedback && (
        // Admin Feedback Form
        <div style={{marginTop: '16px'}}>
          <FeedbackHeader>
            {i18n.t(k.FEEDBACK__TO_PARTICIPANT__FORMAT, {
              participant: submission?.participantName.split(' ')[0],
            })}
          </FeedbackHeader>
          <EditorContainer>
            <RichTextEditor
              value={feedbackText}
              onChange={setFeedbackText}
              companyId={companyId}
            />
          </EditorContainer>
        </div>
      )}
    </LearnInDrawer>
  );
}

export default ProjectResponseDrawer;
