import Quill from 'quill';

const ImageBlot = Quill.import('formats/image');

class StyledImage extends ImageBlot {
  static create(value: string) {
    const node = super.create(value);
    node.setAttribute('style', 'width: fit-content; max-width: 100%;');
    return node;
  }
}

StyledImage.blotName = 'styledImage';
StyledImage.tagName = 'IMG';

Quill.register({
  'formats/styledImage': StyledImage,
});

export default StyledImage;
