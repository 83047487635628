import {i18n, k} from '@i18n/translate';
import {useMutation, useQuery} from 'react-query';
import {
  getCompleteResourceRm,
  getRemoveUserPlanResourceRm,
  getStartedFinanceIncentivePrograms,
  getStartedTimeIncentivePrograms,
} from '@store/apiEndpoints';
import {getStartedProgramsRq} from '@store/apiEndpoints/program/queries';
import {getStartedCustomPrograms} from '@store/apiEndpoints/customProgram/queries';
import {
  simpleDeleteFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {FinanceType, PlanItemType, UserResourceStatus} from '@generated/enums';
import {
  ProgramForCarousel,
  RemoveUserPlanResourcePayload,
  UserPlanFinanceItemVM,
  UserPlanProgramItemVM,
  UserPlanResourceVM,
  UserPlanTimeItemVM,
} from '@models/serverModels';
import UserPlanSection from './UserPlanSection';
import {isItemComplete} from '@utils/itemUtils';
import NoDataText from '@blocks/NoDataText';
import useTreatment from '@hooks/useTreatment';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import ResourcePlanCardCarousel from '@components/user/components/resources/ResourcePlanCardCarousel';
import {Typography} from 'antd';
import AcademiesCarouselContainer from '@components/user/pages/academy/AcademiesCarousel/AcademiesCarousel.container';
import {useUserQuery} from '@generated/hooks';

interface Props {
  programsSuccess: boolean;
  programsData: UserPlanProgramItemVM[];
  financeSuccess: boolean;
  financeData: UserPlanFinanceItemVM[];
  timeSuccess: boolean;
  timeData: UserPlanTimeItemVM[];
  showCompleted: boolean;
  resources: UserPlanResourceVM[];
  hasResourcesLoaded: boolean;
  refreshResources: () => void;
  academies: any[];
  toUSDExchangeRate: number;
}

export default function UserPlanTab({
  programsSuccess,
  programsData,
  financeSuccess,
  financeData,
  timeSuccess,
  timeData,
  showCompleted,
  resources,
  hasResourcesLoaded,
  refreshResources,
  academies,
  toUSDExchangeRate,
}: Props) {
  const isTimeIncentivesOn =
    useTreatment(FeatureFlagExperiments.TimeIncentives) ===
    FeatureFlagTreatments.On;
  const onlyShowCustomPrograms =
    useTreatment(FeatureFlagExperiments.OnlyCustomProgramsUser) ===
    FeatureFlagTreatments.On;
  const isAcademiesOn =
    useTreatment(FeatureFlagExperiments.Academies) === FeatureFlagTreatments.On;

  const {data: user, isLoading: isUserLoading} = useUserQuery(null);

  const getStartedFinanceProgramsRq = getStartedFinanceIncentivePrograms();
  const getStartedFinanceProgramsQuery = useQuery<ProgramForCarousel[]>(
    getStartedFinanceProgramsRq.queryKey,
    () => simpleQueryFn(getStartedFinanceProgramsRq.path)
  );
  const getStartedTimeProgramsRq = getStartedTimeIncentivePrograms();
  const getStartedTimeProgramsQuery = useQuery<ProgramForCarousel[]>(
    getStartedTimeProgramsRq.queryKey,
    () => simpleQueryFn(getStartedTimeProgramsRq.path)
  );

  const getStartedProgramRq = getStartedProgramsRq();
  const getStartedProgramsQuery = useQuery<ProgramForCarousel[]>(
    getStartedProgramRq.queryKey,
    () => simpleQueryFn(getStartedProgramRq.path),
    {
      placeholderData: () => [],
      enabled: !onlyShowCustomPrograms,
    }
  );

  const getStartedCustomProgramRq = getStartedCustomPrograms();
  const getStartedCustomProgramsQuery = useQuery<ProgramForCarousel[]>(
    getStartedCustomProgramRq.queryKey,
    () => simpleQueryFn(getStartedCustomProgramRq.path),
    {
      placeholderData: () => [],
      enabled: onlyShowCustomPrograms,
    }
  );

  const removeUserPlanResourceMutation = useMutation(
    ({userPlanResourceId, amountSpent}: RemoveUserPlanResourcePayload) => {
      const removeUserPlanResource = getRemoveUserPlanResourceRm({
        userPlanResourceId,
        amountSpent: amountSpent * toUSDExchangeRate,
      });
      return simpleDeleteFn<null>(
        removeUserPlanResource.path,
        removeUserPlanResource.payload
      );
    },
    {
      onSuccess: async () => {
        refreshResources();
        // notification
      },
      onError: () => {
        // notification
      },
    }
  );

  const completeResourceMutation = useMutation(
    (userPlanResourceId: number) => {
      const completeResource = getCompleteResourceRm(userPlanResourceId);
      return simpleMutationFn<null>(
        completeResource.path,
        completeResource.payload
      );
    },
    {
      onSuccess: async () => {
        refreshResources();
      },
    }
  );

  // Mark Resource Complete Functionality

  // Resubmit Resource Request Functionality

  // Render Empty Data Text on completed page if there are no completed items
  const haveAllItemsLoaded =
    programsSuccess && financeSuccess && timeSuccess && hasResourcesLoaded;

  const resourcesToShow = resources?.filter(({status}) => {
    if (showCompleted) {
      return status === UserResourceStatus.Complete;
    } else {
      return status !== UserResourceStatus.Complete;
    }
  });

  const isThereACompleteItem =
    (haveAllItemsLoaded &&
      [...programsData, ...financeData, ...timeData].some((item) =>
        isItemComplete(item)
      )) ||
    (showCompleted && !!resourcesToShow.length);
  if (showCompleted && haveAllItemsLoaded && !isThereACompleteItem) {
    return (
      <NoDataText
        header={i18n.t(k.PLAN__EMPTY__TITLE)}
        subHeader={i18n.t(k.PLAN__EMPTY__DESCRIPTION)}
      />
    );
  }
  return (
    <>
      {!!resourcesToShow?.length && (
        <ResourcePlanCardCarousel
          resources={resourcesToShow}
          removeResource={removeUserPlanResourceMutation.mutateAsync}
          markResourceComplete={completeResourceMutation.mutateAsync}
          refreshResources={refreshResources}
        />
      )}
      <UserPlanSection
        title={i18n.t(k.PROGRAM__PLURAL)}
        planItemType={PlanItemType.Program}
        data={programsData}
        isSuccess={programsSuccess}
        programsForCarousel={
          onlyShowCustomPrograms
            ? getStartedCustomProgramsQuery.data
            : getStartedProgramsQuery.data
        }
        showCompleted={showCompleted}
        isDirectBilling={user?.plbType === FinanceType.DirectBilling}
        user={user}
      />

      <UserPlanSection
        title={i18n.t(k.GENERIC__FINANCING)}
        planItemType={PlanItemType.Finance}
        data={financeData}
        isSuccess={financeSuccess}
        programsForCarousel={getStartedFinanceProgramsQuery.data}
        showCompleted={showCompleted}
        user={user}
      />

      {isTimeIncentivesOn && (
        <UserPlanSection
          title={i18n.t(k.TIME)}
          planItemType={PlanItemType.Time}
          data={timeData}
          isSuccess={timeSuccess}
          programsForCarousel={getStartedTimeProgramsQuery.data}
          showCompleted={showCompleted}
          user={user}
        />
      )}
      {isAcademiesOn &&
        !!academies?.length &&
        user?.canViewAcademiesFeature && (
          <>
            <Typography.Title level={4} className="manage-plan-category-title">
              {i18n.t(k.ACADEMY__PLURAL)}
            </Typography.Title>
            <AcademiesCarouselContainer enrolledAcademies={academies} />
          </>
        )}
    </>
  );
}
