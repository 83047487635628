import {useEffect, useState} from 'react';
import {Form} from 'antd';
import ReimbursementDrawer from '@blocks/reimbursementDrawer/ReimbursementDrawer';
import {
  getUploadAttachmentRm,
  getUploadReimbursementAttachmentRm,
} from '@store/apiEndpoints';
import {UserPlanReimbursementItemVM} from '@models/serverModels';
import queryClient from '@store/queryClient';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import useChunkUpload from '@hooks/useChunkUpload';
import {ReimbursementItemType} from '@generated/enums';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import {
  getExchangeRateBySelection,
  MONEY_DEFAULT,
  REQUEST_CURRENCY_ID,
} from '@utils/moneyUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  useGetReimbursementApprovalApplicationsQuery,
  useGetUserPreApprovedItemsQuery,
} from '@hooks/apiEndpoints/reimbursements/queries';
import {useRequestReimbursementMutation} from '@hooks/apiEndpoints/reimbursements/mutations';
import {formatResponseValue} from '@components/reusable/LearnInForm';
import {
  applyCurrencySelection,
  skipResponseFormatting,
} from '@utils/preApprovalRequest';
import {useDeleteAttachment} from '@hooks/apiEndpoints/attachments/mutations';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface ReimbursementDrawerContainerProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRequestItem?: number;
}

function ReimbursementDrawerContainer({
  isOpen,
  setIsOpen,
  selectedRequestItem,
}: ReimbursementDrawerContainerProps) {
  const [form] = Form.useForm();
  const [selectedItem, setSelectedItem] =
    useState<UserPlanReimbursementItemVM>();
  const [formQuestions, setFormQuestions] = useState([]);
  const getUserQuery = useGetUserQuery();

  const reimbursementApprovalApplicationsQuery =
    useGetReimbursementApprovalApplicationsQuery(null);

  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const {isFeatureFlagOn} = useFeatureFlags();
  const isRequestWithCurrencyOn = isFeatureFlagOn.RequestWithCurrency;

  const userPreApprovedItemsQuery = useGetUserPreApprovedItemsQuery(null);
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: isRequestWithCurrencyOn,
  });
  const exchangeRatesList = getCurrencyExchangeQuery?.data;
  const uploadQueryFn = isFeatureFlagOn.ReimbursementAttachmentsMigration
    ? getUploadAttachmentRm
    : getUploadReimbursementAttachmentRm;
  // Attachment Uploading
  const {chunkUpload, multipleUploadProgress, fileId} = useChunkUpload({
    query: uploadQueryFn,
    customizableLearningCategory: ReimbursementItemType.Program,
  });
  const [filesProgress, setFilesProgress] = useState([]);
  const [attachmentIds, setAttachmentIds] = useState([]);
  const [filesArrayLength, setFilesArrayLength] = useState(0);
  const [filesToUpload, setFilesToUpload] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    queryClient.invalidateQueries();
    setIsOpen(false);
  };
  useEffect(() => {
    if (fileId.length) {
      setAttachmentIds([...attachmentIds, fileId]);
    }
  }, [fileId]);

  const deleteAttachmentMutation = useDeleteAttachment();

  const deleteFunction = (idx) => {
    //Attachment delete API
    deleteAttachmentMutation.mutate({
      attachmentId: attachmentIds[idx],
    });
    setFilesProgress([
      ...filesProgress.slice(0, idx),
      ...filesProgress.slice(idx + 1, filesProgress.length),
    ]);
    setAttachmentIds([
      ...attachmentIds.slice(0, idx),
      ...attachmentIds.slice(idx + 1, attachmentIds.length),
    ]);
    setFilesArrayLength(filesToUpload.length - 1);
  };
  useEffect(() => {
    if (!isFeatureFlagOn.ReimbursementAttachmentsMigration) {
      return;
    }
    //check if a file was added and not deleted
    if (filesToUpload.length > 0 && filesArrayLength < filesToUpload.length) {
      const idx = filesToUpload.length - 1;
      const latestFile = filesToUpload[idx];
      setFilesArrayLength(filesToUpload.length);
      setFilesProgress((filesProgress) => {
        const progressBars = [...filesProgress];
        progressBars[idx] = 1;
        return progressBars;
      });
      chunkUpload(latestFile, {index: idx});
    }
    //uploadAttachments
  }, [filesToUpload.length]);

  useEffect(() => {
    if (!selectedRequestItem) {
      return;
    }
    if (userPreApprovedItemsQuery?.data) {
      setCurrentItem(selectedRequestItem);
      form.setFieldsValue({itemId: selectedRequestItem});
    }
  }, [isOpen, userPreApprovedItemsQuery]);

  useEffect(() => {
    if (!reimbursementApprovalApplicationsQuery?.data?.length) {
      return;
    }
    if (selectedItem?.type == ReimbursementItemType.Program) {
      const typeQuestions = reimbursementApprovalApplicationsQuery?.data?.find(
        (application) => application.reimbursementType == selectedItem?.type
      );
      setFormQuestions(typeQuestions?.questions);
    } else if (selectedItem?.type == ReimbursementItemType.LearningResource) {
      const resourceQuestions =
        reimbursementApprovalApplicationsQuery?.data?.find(
          (application) =>
            application.learningResourceType ==
            selectedItem?.learningResourceType
        );
      setFormQuestions(resourceQuestions?.questions);
    }
  }, [selectedItem, reimbursementApprovalApplicationsQuery.data]);

  const setCurrentItem = (itemId) => {
    const currentItem = userPreApprovedItemsQuery?.data?.find((item) => {
      return item.id == itemId;
    });
    setSelectedItem(currentItem);
  };

  //update progress bars
  useEffect(() => {
    const idx = multipleUploadProgress.index;
    const progressBars = filesProgress;
    if (idx !== '') {
      progressBars[idx] = Math.round(multipleUploadProgress.progress);
    }
    if (progressBars.length > 0) {
      if (progressBars.every((element) => element === 100)) {
        setIsLoading(false);
        !isFeatureFlagOn.ReimbursementAttachmentsMigration && handleClose();
      }
    }
    setFilesProgress(progressBars);
  }, [multipleUploadProgress]);

  const requestReimbursementMutation = useRequestReimbursementMutation();

  const handleClickSubmit = async ({files}) => {
    setIsLoading(true);
    try {
      await form.validateFields();
      const data = form.getFieldsValue();

      // If a currency is selected, use that exchange rate
      const requestExchangeRate = getExchangeRateBySelection(
        data?.[REQUEST_CURRENCY_ID],
        exchangeRatesList,
        exchangeRate
      );

      const application = formQuestions;

      applyCurrencySelection(
        application,
        data?.[REQUEST_CURRENCY_ID],
        getUserQuery.data?.currency
      );

      const appArray = application.map((item) => ({
        ...item,
        response: String(
          skipResponseFormatting(item)
            ? item?.response
            : formatResponseValue({
                type: item.inputType,
                response: data[item.id],
                total: Number(data[item.id]),
                exchangeRate: requestExchangeRate,
              })
        ),
      }));
      //create reimbursement
      const newReimbursement: any =
        await requestReimbursementMutation.mutateAsync({
          itemId: data.itemId,
          application: appArray,
          reimbursementItemType: selectedItem.type,
          attachmentIds: attachmentIds,
        });
      //upload file
      if (files && !isFeatureFlagOn.ReimbursementAttachmentsMigration) {
        for (let i = 0; i < files.length; i++) {
          const element = files[i];
          chunkUpload(element, {contentId: newReimbursement.id, index: i});
        }
        //use the UseEffect method to wait for the images to upload
      } else {
        handleClose();
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <ReimbursementDrawer
      form={form}
      formQuestions={formQuestions}
      isOpen={isOpen}
      onClose={handleClose}
      onOk={handleClickSubmit}
      items={userPreApprovedItemsQuery.data}
      currency={getUserQuery.data?.currency}
      selectedItem={selectedItem}
      setCurrentItem={setCurrentItem}
      filesProgress={filesProgress}
      isLoading={isLoading}
      filesToUpload={filesToUpload}
      setFilesToUpload={setFilesToUpload}
      deleteFunction={deleteFunction}
    />
  );
}

export default ReimbursementDrawerContainer;
