import styled from 'styled-components';
import {COLORS} from '@utils/constants';

export const MainContainer = styled.div`
  margin-top: 46px;
  padding: 0px, 24px, 24px, 24px;
`;

export const ImageContainer = styled.div`
  width: 519px;
  height: 153px;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  img {
    flex-shrink: 0;
    width: 100%;
  }
`;
export const InfoContainer = styled.div`
  color: ${COLORS.Neutral500};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  display: flex;
  gap: 10px;
`;

export const SummaryAndSkillsContainer = styled.div`
  display: flex;
`;

export const Summary = styled.div`
  width: 353px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${COLORS.Neutral800};
  border-right: 1px solid ${COLORS.Neutral200};
`;

export const SkillsContainer = styled.div`
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const SkillsTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`;

export const SkillChip = styled.div`
  padding: 4px 8px 4px 8px;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid ${COLORS.Neutral300};
  background: ${COLORS.Neutral100};
  font-size: 12px;
  font-weight: 900;
  color: ${COLORS.Neutral800};
`;

export const TitleContainer = styled.a`
  color: ${COLORS.Blue800};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const LoadingStateContainer = styled.div`
  margin-left: 50%;
  margin-top: 20%;
`;
