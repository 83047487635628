import './ProgramItemCard.scss';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Row} from 'antd';
import {useNavigate} from 'react-router-dom';
import {UserPlanItemStatus} from '@models/clientEnums';
import ProviderLogo from '@blocks/ProviderLogo';
import {getUserPlanItemCardUrl} from '@utils/ClientPaths';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {CurrencyCode, PlanItemType} from '@generated/enums';
import {formatCurrency, getExchangeRateOfCurrencyPair} from '@utils/moneyUtils';
import {localizeDurationFromParts} from '@utils/l10nUtils';
import {CurrencyRatesVM} from '@models/clientModels';
import useFeatureFlags from '@hooks/useFeatureFlags';

type Props = {
  item: any;
  usersCurrency?: CurrencyCode;
  exchangeRates?: CurrencyRatesVM[];
  showActions?: boolean;
};

const SecondColumn = (props: Props) => {
  const {
    isFeatureFlagOn: {DurationStrings},
  } = useFeatureFlags();
  return (
    <>
      <Row>
        <p className="font-label-small">
          {i18n.t(k.TIME__DURATION).toLocaleUpperCase()}
        </p>
      </Row>
      <Row>
        <p className="font-body-large">
          {props.item.duration
            ? localizeDurationFromParts(
                props.item.duration,
                props.item.durationType,
                DurationStrings
              )
            : i18n.t(k.GENERIC__N_A)}
        </p>
      </Row>
    </>
  );
};

const ThirdColumn = (props: Props) => {
  const exchangeRate = getExchangeRateOfCurrencyPair(
    props.exchangeRates,
    props.usersCurrency,
    props.item.currencyCode
  );
  return (
    <>
      <Row>
        <p className="font-label-small" style={{textTransform: 'uppercase'}}>
          {i18n.t(k.MONEY__COST)}
        </p>
      </Row>
      <Row>
        <p className="font-body-large">
          {props.item.money
            ? formatCurrency(
                props.item.money,
                props.usersCurrency,
                exchangeRate
              )
            : i18n.t(k.GENERIC__N_A)}
        </p>
      </Row>
    </>
  );
};

const convertStatusStringToItemDetails = (
  status: string,
  itemType: PlanItemType
): {status: UserPlanItemStatus; tagStyle: TagStyles; displayString: string} => {
  let displayStr = i18n.t(k.STATUS__READY_TO_APPLY);
  switch (status) {
    case 'Pending': {
      displayStr = i18n.t(k.STATUS__READY_TO_APPLY);
      return {
        status: UserPlanItemStatus.Pending,
        tagStyle: TagStyles.LightGrey,
        displayString: displayStr,
      };
    }
    case 'Approved': {
      displayStr =
        itemType === PlanItemType.Program
          ? i18n.t(k.STATUS__READY_TO_APPLY)
          : i18n.t(k.STATUS__APPROVED);
      return {
        status: UserPlanItemStatus.Approved,
        tagStyle:
          itemType === PlanItemType.Program
            ? TagStyles.LightGrey
            : TagStyles.Green,
        displayString: displayStr,
      };
    }
    case 'Rejected': {
      displayStr = i18n.t(k.STATUS__DECLINED);
      return {
        status: UserPlanItemStatus.Rejected,
        tagStyle: TagStyles.Red,
        displayString: displayStr,
      };
    }
    case 'Cancelled': {
      displayStr = i18n.t(k.STATUS__CANCELLED);
      return {
        status: UserPlanItemStatus.Cancelled,
        tagStyle: TagStyles.Yellow,
        displayString: displayStr,
      };
    }
    case 'Requested': {
      displayStr = i18n.t(k.STATUS__AWAITING_APPROVAL);
      return {
        status: UserPlanItemStatus.Requested,
        tagStyle: TagStyles.Yellow,
        displayString: displayStr,
      };
    }
    case 'Applied': {
      displayStr =
        itemType === PlanItemType.Program
          ? i18n.t(k.STATUS__AWAITING_ADMISSION)
          : i18n.t(k.STATUS__APPLIED);
      return {
        status: UserPlanItemStatus.Applied,
        tagStyle: TagStyles.Yellow,
        displayString: displayStr,
      };
    }
    case 'Admitted': {
      displayStr = i18n.t(k.STATUS__ADMITTED);
      return {
        status: UserPlanItemStatus.Admitted,
        tagStyle: TagStyles.Green,
        displayString: displayStr,
      };
    }
    case 'Denied': {
      displayStr = i18n.t(k.STATUS__DENIED);
      return {
        status: UserPlanItemStatus.Denied,
        tagStyle: TagStyles.Red,
        displayString: displayStr,
      };
    }
    case 'InProgress': {
      displayStr = i18n.t(k.STATUS__IN_PROGRESS);
      return {
        status: UserPlanItemStatus.InProgress,
        tagStyle: TagStyles.Green,
        displayString: displayStr,
      };
    }
    case 'Complete': {
      displayStr = i18n.t(k.STATUS__COMPLETE);
      return {
        status: UserPlanItemStatus.Complete,
        tagStyle: TagStyles.Green,
        displayString: displayStr,
      };
    }
    case 'NotSubmitted': {
      displayStr = i18n.t(k.STATUS__APPROVED);
      return {
        status: UserPlanItemStatus.NotSubmitted,
        tagStyle: TagStyles.Green,
        displayString: displayStr,
      };
    }
    case 'Submitted': {
      displayStr = i18n.t(k.STATUS__SCHEDULE_AWAITING_APPROVAL);
      return {
        status: UserPlanItemStatus.Submitted,
        tagStyle: TagStyles.Yellow,
        displayString: displayStr,
      };
    }
    case 'ScheduleApproved': {
      displayStr = i18n.t(k.STATUS__SCHEDULE_APPROVED);
      return {
        status: UserPlanItemStatus.ScheduleApproved,
        tagStyle: TagStyles.Green,
        displayString: displayStr,
      };
    }
    case 'ScheduleRejected': {
      displayStr = i18n.t(k.STATUS__SCHEDULE_NOT_APPROVED);
      return {
        status: UserPlanItemStatus.ScheduleRejected,
        tagStyle: TagStyles.Red,
        displayString: displayStr,
      };
    }
    case 'PendingUse': {
      displayStr = i18n.t(k.STATUS__APPROVED);
      return {
        status: UserPlanItemStatus.PendingUse,
        tagStyle: TagStyles.Green,
        displayString: displayStr,
      };
    }
    case 'Expired': {
      displayStr = i18n.t(k.STATUS__EXPIRED);
      return {
        status: UserPlanItemStatus.Expired,
        tagStyle: TagStyles.Red,
        displayString: displayStr,
      };
    }
    case 'ReimbursementSubmitted': {
      displayStr = i18n.t(k.REIMBURSEMENT__STATUS__SUBMITTED);
      return {
        status: UserPlanItemStatus.ReimbursementSubmitted,
        tagStyle: TagStyles.Yellow,
        displayString: displayStr,
      };
    }
    case 'ReimbursementApproved': {
      displayStr = i18n.t(k.REIMBURSEMENT__STATUS__APPROVED);
      return {
        status: UserPlanItemStatus.ReimbursementApproved,
        tagStyle: TagStyles.Green,
        displayString: displayStr,
      };
    }
    case 'ReimbursementNeedsReview': {
      displayStr = i18n.t(k.REIMBURSEMENT__STATUS__NEEDS_REVIEW);
      return {
        status: UserPlanItemStatus.ReimbursementNeedsReview,
        tagStyle: TagStyles.Red,
        displayString: displayStr,
      };
    }
    case 'ReimbursementPaymentPending': {
      displayStr = i18n.t(k.STATUS__PAYMENT_PENDING);
      return {
        status: UserPlanItemStatus.ReimbursementPaymentPending,
        tagStyle: TagStyles.Yellow,
        displayString: displayStr,
      };
    }
    case 'ReimbursementPaid': {
      displayStr = i18n.t(k.REIMBURSEMENT__STATUS__PAID);
      return {
        status: UserPlanItemStatus.ReimbursementPaid,
        tagStyle: TagStyles.Green,
        displayString: displayStr,
      };
    }
    default: {
      return {
        status: UserPlanItemStatus.Pending,
        tagStyle: TagStyles.LightGrey,
        displayString: displayStr,
      };
    }
  }
};

const getApplicationStatusTagStyle = (item: any): TagStyles => {
  return convertStatusStringToItemDetails(item.status, item.ItemType).tagStyle;
};

const getApplicationStatusTitle = (status: string, itemType: PlanItemType) => {
  return convertStatusStringToItemDetails(status, itemType).displayString;
};

const ProgramItemCard = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Card
      role="article"
      aria-label={`${i18n.t(k.PROGRAM)}: ${props.item?.title}`}
      key={props.item.id}
      className="program-item-card"
      style={{
        padding: '24px 24px',
        borderRadius: '8px',
        margin: '15px 0px',
        cursor: 'pointer',
      }}
      onClick={() => {
        navigate(
          getUserPlanItemCardUrl(
            props.item.itemType,
            props.item?.programId ??
              props.item?.timeIncentiveId ??
              props.item?.financeIncentiveId
          )
        );
      }}>
      <Row gutter={[8, 0]} align="middle">
        <Col xs={4} md={2}>
          <ProviderLogo
            size={50}
            provider={props.item.provider}
            providerLogo={props.item?.providerLogo}
          />
        </Col>
        <Col xs={20} md={7}>
          <Row>
            <p
              className="font-label-small"
              style={{textTransform: 'uppercase'}}>
              {props.item.provider}
            </p>
          </Row>
          <Row>
            <p
              className="font-body-large-emphasis"
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                display: 'block',
                maxWidth: '210px',
              }}>
              {props.item?.title}
            </p>
          </Row>
        </Col>
        <Col xs={8} md={5}>
          <SecondColumn item={props.item} />
        </Col>
        <Col xs={8} md={5}>
          <ThirdColumn item={props.item} exchangeRates={props.exchangeRates} />
        </Col>
        <Col xs={8} md={5}>
          <Row>
            <p className="font-label-small" style={{marginTop: '2px'}}>
              {i18n.t(k.STATUS__STATUS)}
            </p>
          </Row>
          <Row>
            <LearnInTag
              tagStyle={getApplicationStatusTagStyle(props.item)}
              label={getApplicationStatusTitle(
                props.item.status,
                props.item?.itemType
              )}
            />
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default ProgramItemCard;
