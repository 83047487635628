// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/Demand.cs

enum Demand {
  Low,
  Medium,
  High,
  VeryHigh,
}

export default Demand;
