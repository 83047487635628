import {CurrencyCode} from '@generated/enums';

export interface ProgramReportItem {
  id: number;
  programId: number;
  planItemId: number;
  employeeName: string;
  startDate: Date;
  title: string;
  programType: string;
  programLength: string;
  applicationUrl: string;
  isInternalProgram: boolean;
  status: string;
  cost: number;
  requestedAmount: number;
  currencyCode: string;
  currency: CurrencyCode;
}
export enum ProgressReportItemSummary {
  FeelingGreat,
  MakingProgress,
  TooBusy,
  Stressed,
  NeedHelp,
}
export interface EmployeeEngagementItem {
  month: string;
  category: string;
  value: number;
}
export interface FinanceChartItem {
  color?: string;
  name: string;
  type: string;
  value: number;
}
export interface ProgressChartItem {
  name: string;
  percent: number;
  key: number;
  total: number;
}
export enum AdminReportsTabName {
  INSIGHTS = 'Insights',
  PROGRAM = 'Program',
  FINANCE = 'Finance',
  TIME = 'Time',
  PROGRESS = 'Progress',
  Prepayment = 'Prepayment',
  Programs = 'Programs',
}
