// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/AcademyStatus.cs

enum AcademyStatus {
  Draft,
  Published,
  Deleted,
}

export default AcademyStatus;
