import {LearningResourceType, ReimbursementItemType} from './../enums';
import {UserPlanReimbursementItemVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserPlanReimbursementItemVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanReimbursementItemVMMockData(
  args?: Partial<UserPlanReimbursementItemVM>
): UserPlanReimbursementItemVM {
  return {
    id: faker.datatype.number(),
    learningResourceType: LearningResourceType.Book, // optional
    logo: faker.word.noun(), // optional
    subTitle: faker.company.catchPhrase(),
    title: faker.company.catchPhrase(),
    type: ReimbursementItemType.Program,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanReimbursementItemVMMockData: UserPlanReimbursementItemVM =
  {
    id: 1,
    learningResourceType: LearningResourceType.Book, // optional
    logo: 'string', // optional
    subTitle: 'Advanced Web Design',
    title: 'Advanced Web Design',
    type: ReimbursementItemType.Program,
  };
