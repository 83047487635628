import {RouteElement} from '@models/clientModels';
import {UserPaths} from '@utils/ClientPaths';
import {Navigate} from 'react-router-dom';
import useGetEnabledPrimaryRoutesShared from '@hooks/useGetEnabledPrimaryRoutesShared';

/** Get enabled routes for integrated users
 *  This is used in combination of useBuildRouteElements to handle complexity.
 */
const useGetEnabledIntegratedPrimaryRoutes = ({
  isAdmin,
  isIntegratedUser,
  isManager,
}: {
  isAdmin: boolean;
  isIntegratedUser: boolean;
  isManager: boolean;
}): RouteElement[] => {
  const enabled = true;
  const sharedPrimaryRoutes = useGetEnabledPrimaryRoutesShared({
    isManager,
    isAdmin,
  });
  const routeElements: RouteElement[] = [
    {
      path: '/',
      enabled,
      element: <Navigate to={UserPaths.Index} replace />,
    },
    ...(isIntegratedUser ? sharedPrimaryRoutes : []),
  ];
  return routeElements;
};

export default useGetEnabledIntegratedPrimaryRoutes;
