// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/ApprovalReason.cs

enum ApprovalReason {
  AutoApproved,
  ManagerApproved,
  AdminApproved,
  ManagerAdminApproved,
  AssignedApproverApproved,
}

export default ApprovalReason;
