import {useState, useEffect} from 'react';
import {i18n, k} from '@i18n/translate';
import {CustomProgramUserDrawer} from './CustomProgramUserDrawer';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import NoDataText from '@blocks/NoDataText';
import CustomProgramAdminDrawerContainer from '@components/admin/pages/programs/customPrograms/CustomProgramAdminDrawer.container';
import {CustomProgramScreens} from '@utils/ClientPaths';
import CohortAdminPageDrawerContainer from '@components/admin/pages/programs/customPrograms/cohorts/CohortAdminPageDrawer.container';
import CustomProgramCohortsPage from '@components/admin/pages/programs/customPrograms/cohorts/CustomProgramCohortsPage';
import {ContentType} from '@generated/enums';
import {useCustomProgramPreviewQuery} from '@generated/hooks';
import {
  CustomProgramContentPreviewVM,
  CustomProgramSectionPreviewVM,
} from '@generated/interfaces';
import {ProgramContentStatus} from '@models/clientEnums';

/*
|--------------------------------------------------------------------------
| Container
|--------------------------------------------------------------------------
*/

interface CustomProgramUserDrawerContainerProps {
  contentToNavTo?: {sectionId: number; contentId: number};
  customProgramId: number;
  initialScreen?: CustomProgramScreens;
  showCohortButton?: boolean;
  isOverlay?: boolean;
  onClose: () => void;
  parentCustomProgramId?: number;
  setEffectiveCustomProgramId?: (id: number) => void;
  visible: boolean;
  isActivePlanItem: boolean;
}
export default function CustomProgramUserDrawerContainer({
  contentToNavTo,
  customProgramId,
  showCohortButton,
  initialScreen = CustomProgramScreens.Curriculum,
  isOverlay,
  onClose,
  parentCustomProgramId,
  setEffectiveCustomProgramId,
  visible,
  isActivePlanItem,
}: CustomProgramUserDrawerContainerProps) {
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(
    null
  );
  const [selectedContentId, setSelectedContentId] = useState<number | null>(
    null
  );
  const [restrictAccess, setRestrictAccess] = useState(false);
  const [showCohortsPage, setShowCohortsPage] = useState(false);

  const [showMobileNavMenu, setShowMobileNavMenu] = useState(false);
  const [showCustomProgramAdminDrawer, setShowCustomProgramAdminDrawer] =
    useState(false);

  const {
    data: customProgramPreview,
    isLoading: isCustomProgramPreviewLoading,
    isSuccess: isCustomProgramPreviewSuccess,
    refetch: refetchCustomProgramPreview,
  } = useCustomProgramPreviewQuery(
    {
      customProgramId,
      queryParams: {
        includeProgress: true, // returns steps with updated user progress
        // userRequest
      },
    },
    {
      retry: (_, error: {response: {status: number}}) => {
        return error?.response?.status !== 403;
      },
      enabled: visible && !!customProgramId,
      onError: (err: {response: {status: number}}) => {
        if (err?.response?.status === 403) {
          setRestrictAccess(true);
        }
      },
    }
  );

  const isContentComplete = (program: CustomProgramContentPreviewVM) => {
    switch (program.contentType) {
      case ContentType.Event:
        return !!program.rsvpStatus;
      case ContentType.Project:
        return !!program.projectContentCompletedOn;
      case ContentType.Text:
        return program.textContentCompleted;
    }
  };

  const handleContentCompleteStatusChange = (
    contentId: number,
    newStatus: ProgramContentStatus
  ) => {
    if (newStatus !== ProgramContentStatus.Completed) {
      return;
    }

    const allContents = customProgramPreview.sections.reduce(
      (allContent, section) => allContent.concat(section.content),
      [] as CustomProgramContentPreviewVM[]
    );
    const contentOrder = allContents.filter((x) => x.id === contentId)[0].order;
    const section = customProgramPreview.sections.filter((x) =>
      x.content.some((y) => y.id == contentId)
    )[0];
    const nextInCompletedContentList = section.content.filter(
      (x) => x.order > contentOrder && !isContentComplete(x)
    );
    if (!!nextInCompletedContentList.length) {
      setSelectedContentId(nextInCompletedContentList[0].id);
    }
  };

  useEffect(() => {
    if (customProgramPreview?.sections?.length) {
      setSelectedSectionId(
        contentToNavTo?.sectionId || customProgramPreview?.sections?.[0]?.id
      );
      setSelectedContentId(contentToNavTo?.contentId);
    }
  }, [customProgramPreview?.id]);

  const handleClickSectionNavItem = (id: number) => {
    setSelectedSectionId(id);
    // setting to null to stop auto navigation to content after
    // user is in content drawer
    setSelectedContentId(null);
    if (showMobileNavMenu) {
      setShowMobileNavMenu(false);
    }
  };

  const {selectedSection, nextSection} = (() => {
    let selected: CustomProgramSectionPreviewVM;
    let next: CustomProgramSectionPreviewVM | undefined;
    customProgramPreview?.sections?.forEach((section, idx: number) => {
      if (section.id === selectedSectionId) {
        selected = section;
        next = customProgramPreview?.sections[idx + 1];
      }
    });
    return {selectedSection: selected, nextSection: next};
  })();

  const showProgramHeader =
    !!selectedSection &&
    selectedSection?.id === customProgramPreview?.sections?.[0]?.id;

  const handleCloseRestrictAccessScreen = () => {
    onClose();
    setRestrictAccess(false);
  };

  const handleOpenCustomProgramAdminDrawer = () => {
    // If the drawer is triggered from user drawer, just close it to prevent drawers from looping.
    if (!!isOverlay) {
      onClose();
    } else {
      setShowCustomProgramAdminDrawer(true);
    }
  };

  const handleCloseAdminProgramDrawer = () => {
    // Refetch to show any changes done while editing
    refetchCustomProgramPreview();
    setShowCustomProgramAdminDrawer(false);
  };

  return (
    <>
      {/* Show when user tries to access a restricted program */}
      <LearnInPageDrawer
        onClose={handleCloseRestrictAccessScreen}
        visible={restrictAccess}>
        <NoDataText
          header={i18n.t(k.ACCESS__DENIED__PROGRAM__TITLE)}
          subHeader={i18n.t(k.ACCESS__DENIED__DESCRIPTION)}
        />
      </LearnInPageDrawer>
      {/* Custom Program Drawer */}
      <CustomProgramUserDrawer
        initialScreen={initialScreen}
        isActivePlanItem={isActivePlanItem}
        showCohortButton={showCohortButton}
        onClickViewCohorts={() => setShowCohortsPage(true)}
        onChangeUpcomingCohortSelect={setEffectiveCustomProgramId}
        onClickSectionNavItem={handleClickSectionNavItem}
        onClose={onClose}
        parentCustomProgramId={parentCustomProgramId}
        programId={customProgramPreview?.id}
        programIsSelected={customProgramPreview?.selected}
        programTitle={customProgramPreview?.title}
        selectedContentId={selectedContentId}
        selectedSection={selectedSection}
        nextSection={nextSection}
        showAdminNav={!isActivePlanItem}
        showLoadingScreen={isCustomProgramPreviewLoading}
        showProgramHeader={showProgramHeader}
        visible={visible}
        onContentCompleteStatusChange={handleContentCompleteStatusChange}
        showAddToPlanButton={
          isActivePlanItem &&
          isCustomProgramPreviewSuccess &&
          !customProgramPreview?.selected
        }
        showPlanItemContextMenu={
          isActivePlanItem &&
          isCustomProgramPreviewSuccess &&
          customProgramPreview?.selected
        }
        onClickEditProgram={handleOpenCustomProgramAdminDrawer}
      />
      {!parentCustomProgramId ? (
        <CustomProgramAdminDrawerContainer
          customProgramId={customProgramId}
          isOverlay={true}
          onClose={handleCloseAdminProgramDrawer}
          visible={showCustomProgramAdminDrawer}
        />
      ) : (
        <CohortAdminPageDrawerContainer
          parentCustomProgramId={parentCustomProgramId}
          cohortId={customProgramId}
          onClose={handleCloseAdminProgramDrawer}
          visible={showCustomProgramAdminDrawer}
        />
      )}
      {!!showCohortsPage && (
        <CustomProgramCohortsPage
          visible={showCohortsPage}
          onClose={() => setShowCohortsPage(false)}
          customProgram={customProgramPreview}
        />
      )}
    </>
  );
}
