import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import {AcademyReportEmployeesVM} from '@models/serverModels';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import UserDrillDown from './UserDrillDown';
import {useQueries, useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {
  getAcademyLevelsRq,
  getAcademyOverviewRq,
  getAcademyUserDrillDownRq,
} from '@store/apiEndpoints/academy/queries';
import {COLORS} from '@utils/constants';
import LoadingSpinner from '@blocks/LoadingSpinner';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {AcademyLevelVM, AcademyStepVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  box-sizing: border-box;
  max-width: 90%;
  margin: auto;
  padding-top: 24px;
`;

const Card = styled.div`
  width: 100%;
  margin: 20px 0;
  background: ${COLORS.White};
  box-shadow: ${COLORS.BoxShadowStandard};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 16px;
`;

const CardSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const CardSectionTitle = styled.h3`
  font-family: Roboto;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  letter-spacing: 0;
  text-align: left;
  color: ${COLORS.Neutral600};
`;
const CardSectionContent = styled.p`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 0;
  color: ${COLORS.Neutral950};
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

function UserDrillDownContainer({
  onClose,
  title,
}: {
  onClose: () => void;
  title?: string;
}) {
  const [userAcademyInfo, setUserAcademyInfo] =
    useState<AcademyReportEmployeesVM>();
  const [academyLevelsData, setAcademyLevelsData] = useState([]);
  const {academyId, userId} = useParams();
  useSetPageTitle(title);
  // Data Fetching
  const academyOverviewRq = getAcademyOverviewRq(parseInt(academyId));
  useQuery(
    academyOverviewRq.queryKey,
    () => simpleQueryFn(academyOverviewRq.path),
    {
      onSuccess: (data: AcademyReportEmployeesVM[]) => {
        setUserAcademyInfo(
          data.find(
            (d: AcademyReportEmployeesVM) =>
              d.userCompanyId === parseInt(userId)
          )
        );
      },
    }
  );
  const academyRq = getAcademyLevelsRq(parseInt(academyId));
  const academyQuery = useQuery<AcademyLevelVM[]>(
    academyRq.queryKey,
    () => simpleQueryFn(academyRq.path),
    {
      onSuccess: (data) => {
        setAcademyLevelsData(data?.sort((a, b) => a.order - b.order));
      },
    }
  );
  const academyDrillDownQueries = useQueries(
    academyLevelsData.map((level) => {
      const levelDrillDown = getAcademyUserDrillDownRq(
        level.id,
        parseInt(userId)
      );
      return {
        queryKey: [levelDrillDown.path, levelDrillDown.queryKey],
        queryFn: () => simpleQueryFn(levelDrillDown.path),
        onSuccess: () => academyQuery.refetch(),
      };
    })
  );

  const nestedDataSource = {};
  const percentsComplete = {};
  if (academyDrillDownQueries?.[0]?.isSuccess) {
    academyDrillDownQueries.forEach((drillDown) => {
      if (drillDown.isSuccess) {
        const steps: AcademyStepVM[] = drillDown.data as AcademyStepVM[];
        if (Array.isArray(steps) && steps?.length) {
          const completedAndSkippedSteps = steps.filter((s: AcademyStepVM) => {
            return s.status.isDone;
          }).length;
          const percentComplete =
            steps.length > 0
              ? Math.floor((completedAndSkippedSteps / steps.length) * 100)
              : 100; // i.e. no completable steps = 100%
          percentsComplete[drillDown?.data[0]?.academyLevelId] =
            percentComplete;
          nestedDataSource[drillDown?.data[0]?.academyLevelId] = steps.sort(
            (a, b) => a.order - b.order
          );
        }
      }
    });
  }
  // This is a temporary fix until we can fetch this from the server
  academyLevelsData.map((d) => {
    d.percentComplete = percentsComplete[d.id];
    return d;
  });

  return (
    <>
      <LearnInPageDrawer
        zIndex={1003}
        title={userAcademyInfo?.employeeName || ''}
        visible={true}
        onClose={onClose}>
        <Container>
          <Card>
            <CardSection>
              <CardSectionTitle>{i18n.t(k.SECTION__CURRENT)}</CardSectionTitle>
              <CardSectionContent>
                {userAcademyInfo?.currentLevel}
              </CardSectionContent>
            </CardSection>
            <CardSection>
              <CardSectionTitle>{i18n.t(k.ACADEMY__START)}</CardSectionTitle>
              <CardSectionContent>
                {userAcademyInfo?.academyStartDate}
              </CardSectionContent>
            </CardSection>
          </Card>
          {academyQuery.isLoading ? (
            <LoadingSpinner center />
          ) : (
            <UserDrillDown
              dataSource={academyLevelsData}
              nestedTable={{
                dataSource: nestedDataSource,
              }}
            />
          )}
        </Container>
      </LearnInPageDrawer>
    </>
  );
}

export default UserDrillDownContainer;
