import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  button {
    font-weight: 500;

    + button {
      margin-left: 0;
    }

    &.plb-see-details-button:only-of-type {
      padding-left: 0;
      padding-right: 0;
      outline-offset: 4px 8px;
    }
  }
`;
