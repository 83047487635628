import {i18n, k} from '@i18n/translate';
import {
  PlanItemTypeWithCustomProgram,
  UserPlanCardActions,
  UserPlanItemStatus,
} from '@models/clientEnums';
import {
  ActionMenuItemInfoType,
  convertItemDetailsToActionMenu,
} from '@blocks/userPlanCardActionMenu/UserPlanCardActionMenu';
import {useNavigate} from 'react-router-dom';
import {openProgramExternalUrl} from '@utils/itemUtils';
import {UserPlanProgramItemVM} from '@models/serverModels';
import {getUserManageProgramPath} from '@utils/ClientPaths';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import styled from 'styled-components';
import {timeDetailPath, financeDetailPath} from '@utils/ClientPaths';
import {PlanItemType} from '@generated/enums';

const StyledActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface ActionMenuItemInfoTypeWithUrl extends ActionMenuItemInfoType {
  applicationUrl?: string;
}

interface ActionButtonProps {
  item: ActionMenuItemInfoTypeWithUrl;
  handleShowPreApprovalDrawer?: (value: boolean) => void;
  handleShowViewLicenseModal?: (value: boolean) => void;
  handleShowRequestLicenseDrawer?: (value: boolean) => void;
  onSettings: (action: UserPlanCardActions) => void;
  itemType: PlanItemTypeWithCustomProgram | PlanItemType;
  buttonLabel?: string;
  onPress?: (e?: any) => void;
}

type ButtonRenderProps = {
  actionDisplayStr: string;
  onClick: (e?: any) => void;
  isDisabled: boolean;
};

export default function ActionButton(props: ActionButtonProps) {
  const navigate = useNavigate();
  const itemDetails = convertItemDetailsToActionMenu(
    props.item.status,
    props.item.itemType
  );

  const createActionButtonProps = (
    displayStr: string,
    isDisabled: boolean,
    onClick: (e?: any) => void
  ) => {
    return {
      actionDisplayStr: displayStr,
      isDisabled,
      onClick,
    };
  };

  const getCustomProgramButtonProps = (): ButtonRenderProps => {
    return createActionButtonProps(
      i18n.t(k.PROGRAM__GO_TO),
      false,
      (e: any) => {
        e.stopPropagation();
        props.onPress.call(null);
      }
    );
  };

  const getVisitWebsiteActionButtonProps = (): ButtonRenderProps => {
    return createActionButtonProps(i18n.t(k.CTA__VISIT_WEBSITE), false, (e) => {
      e.stopPropagation();
      //if plan item program doesn't have an external url, go to program details?
      // this is very hacky and fragile
      openProgramExternalUrl(props.item as unknown as UserPlanProgramItemVM);
    });
  };

  const getMarketplaceProgramButtonProps = (): ButtonRenderProps => {
    switch (itemDetails.status) {
      case UserPlanItemStatus.Pending:
        return props.item.programApprovalRequired
          ? getVisitWebsiteActionButtonProps()
          : createActionButtonProps(i18n.t(k.CTA__APPLY), false, (e) => {
              e.stopPropagation();
              props.handleShowPreApprovalDrawer(true);
            });
      case UserPlanItemStatus.Requested:
      case UserPlanItemStatus.Applied:
        return createActionButtonProps(i18n.t(k.STATUS__APPLIED), true, (e) => {
          e.stopPropagation();
        });
      case UserPlanItemStatus.Approved:
        if (props.item.licenseId > 0) {
          if (
            !props.item.daysUntilExpiration ||
            props.item.daysUntilExpiration > -1
          ) {
            return createActionButtonProps(
              i18n.t(k.PROGRAM__ACCESS),
              false,
              (e) => {
                e.stopPropagation();
                props?.handleShowViewLicenseModal(true);
              }
            );
          } else {
            return createActionButtonProps(
              i18n.t(k.REQUEST__APPROVAL),
              false,
              (e) => {
                e.stopPropagation();
                props?.handleShowRequestLicenseDrawer(true);
              }
            );
          }
        } else {
          return createActionButtonProps(
            i18n.t(k.PROGRAM__START__VERB),
            false,
            (e) => {
              e.stopPropagation();
              props.onSettings(UserPlanCardActions.Start);
            }
          );
        }
      case UserPlanItemStatus.InProgress:
        if (props.item.isLicenseProgram) {
          return createActionButtonProps(
            i18n.t(k.REQUEST__APPROVAL),
            false,
            (e) => {
              e.stopPropagation();
              props?.handleShowRequestLicenseDrawer(true);
            }
          );
        } else {
          return getVisitWebsiteActionButtonProps();
        }
      case UserPlanItemStatus.Complete:
        let _onClick: any;
        if (!!props.onPress) {
          _onClick = props.onPress;
        } else {
          _onClick = (e: any) => {
            e.stopPropagation();
            //if plan item program doesn't have an external url, go to program details?
            navigate(getUserManageProgramPath(props.item.id));
          };
        }
        return createActionButtonProps(i18n.t(k.CTA__MANAGE), false, _onClick);
      case UserPlanItemStatus.Rejected:
        return createActionButtonProps(
          i18n.t(k.REQUEST__RESUBMIT),
          false,
          (e) => {
            e.stopPropagation();
            !!props?.handleShowRequestLicenseDrawer
              ? props.handleShowRequestLicenseDrawer(true)
              : props.handleShowPreApprovalDrawer(true);
          }
        );
      // TODO: add default, will throw an error in this case
    }
  };

  const getTimeButtonProps = (): ButtonRenderProps => {
    switch (itemDetails.status) {
      case UserPlanItemStatus.Pending:
        return createActionButtonProps(i18n.t(k.CTA__APPLY), false, (e) => {
          e.stopPropagation();
          props.handleShowPreApprovalDrawer(true);
        });
      case UserPlanItemStatus.Requested:
        return createActionButtonProps(i18n.t(k.STATUS__APPLIED), true, (e) => {
          e.stopPropagation();
        });
      case UserPlanItemStatus.Approved:
      case UserPlanItemStatus.NotSubmitted:
      case UserPlanItemStatus.Submitted:
      case UserPlanItemStatus.ScheduleApproved:
      case UserPlanItemStatus.Complete:
        return createActionButtonProps(i18n.t(k.CTA__APPLY), false, (e) => {
          navigate(timeDetailPath(props.item.id), {state: props.item});
          e.stopPropagation();
        });
      case UserPlanItemStatus.Rejected:
      case UserPlanItemStatus.ScheduleRejected:
        return createActionButtonProps(i18n.t(k.CTA__REAPPLY), false, (e) => {
          e.stopPropagation();
          props.handleShowPreApprovalDrawer(true);
        });
    }
  };

  const getFinanceButtonProps = () => {
    // tells typescript that this is definitely a specific type
    switch (itemDetails.status) {
      case UserPlanItemStatus.Pending:
        return createActionButtonProps(i18n.t(k.CTA__APPLY), false, (e) => {
          e.stopPropagation();
          if (props.item?.applicationUrl) {
            window.open(props.item.applicationUrl);
          }
          props.handleShowPreApprovalDrawer(true);
        });
      case UserPlanItemStatus.Requested:
        return createActionButtonProps(i18n.t(k.STATUS__APPLIED), true, (e) => {
          e.stopPropagation();
        });
      case UserPlanItemStatus.Approved:
      case UserPlanItemStatus.PendingUse:
      case UserPlanItemStatus.ReimbursementSubmitted:
      case UserPlanItemStatus.ReimbursementApproved:
      case UserPlanItemStatus.ReimbursementNeedsReview:
      case UserPlanItemStatus.ReimbursementPaymentPending:
      case UserPlanItemStatus.ReimbursementPaid:
        return createActionButtonProps(i18n.t(k.CTA__MANAGE), false, (e) => {
          navigate(financeDetailPath(props.item.id), {state: props.item});
          e.stopPropagation();
        });
      case UserPlanItemStatus.Expired:
        return createActionButtonProps(i18n.t(k.CTA__RENEW), false, (e) => {
          e.stopPropagation();
          props.handleShowPreApprovalDrawer(true);
        });
      case UserPlanItemStatus.Rejected:
        return createActionButtonProps(i18n.t(k.CTA__REAPPLY), false, (e) => {
          e.stopPropagation();
          props.handleShowPreApprovalDrawer(true);
        });
    }
  };

  let actionDisplayStr: string;
  let isDisabled: boolean;
  let _onClick: any;

  switch (props.itemType) {
    case PlanItemTypeWithCustomProgram.CustomProgram:
      const customProgramResult = getCustomProgramButtonProps();
      actionDisplayStr = customProgramResult.actionDisplayStr;
      isDisabled = customProgramResult.isDisabled;
      _onClick = customProgramResult.onClick;
      break;
    case PlanItemTypeWithCustomProgram.MarketplaceProgram:
      try {
        const marketplaceProgramResult = getMarketplaceProgramButtonProps();
        actionDisplayStr = marketplaceProgramResult.actionDisplayStr;
        isDisabled = marketplaceProgramResult.isDisabled;
        _onClick = marketplaceProgramResult.onClick;
      } catch (error) {
        console.error(error);
      }
      break;
    case PlanItemTypeWithCustomProgram.ProgramLicense:
      const programLicenseResult = getMarketplaceProgramButtonProps();
      actionDisplayStr = programLicenseResult.actionDisplayStr;
      isDisabled = programLicenseResult.isDisabled;
      _onClick = programLicenseResult.onClick;
      break;
    case PlanItemTypeWithCustomProgram.Finance:
      const financeResult = getFinanceButtonProps();
      actionDisplayStr = financeResult.actionDisplayStr;
      isDisabled = financeResult.isDisabled;
      _onClick = financeResult.onClick;
      break;
    case PlanItemTypeWithCustomProgram.Time:
      const timeResult = getTimeButtonProps();
      actionDisplayStr = timeResult.actionDisplayStr;
      isDisabled = timeResult.isDisabled;
      _onClick = timeResult.onClick;
      break;
    default:
      actionDisplayStr = '';
      isDisabled = true;
      _onClick = () => {
        return;
      };
  }

  return (
    <StyledActionButtonContainer>
      <LearnInButton
        style={{height: '42px'}}
        id={`${actionDisplayStr.toLowerCase()}-button-${props.item.id}`}
        tag={ButtonTags.Secondary}
        onClick={_onClick}
        disabled={isDisabled}>
        {props.buttonLabel ?? actionDisplayStr}
      </LearnInButton>
    </StyledActionButtonContainer>
  );
}
