import {
  ApprovalType,
  CurrencyCode,
  PreApprovalQuestionFunctionalityType,
  PreApprovalQuestionInputType,
} from '@generated/enums';
import {APPROVAL_DRAWER_TEXT_AREA_LINE_LENGTH} from '@utils/constants';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {i18n, k} from '@i18n/translate';
import {determinePreApprovalQuestionInitialValue} from '@utils/determinePreApprovalQuestionInitialValue';
import {ReimbursementImages} from '@components/admin/pages/approvals/components/ReimbursementImages';
import {Form} from 'antd';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {
  displayCurrencyCode,
  formatCurrency,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  ApprovalRequestDetailsVM,
  ResourceApprovalRequest,
  UserRequestApprovalVM,
} from '@models/serverModels';
import {isRecurringType} from '@utils/preApprovalRequest';
import {ApprovalImages} from './ApprovalImages';
import {localizeText} from '@utils/l10nUtils';

interface ApprovalTypeSpecificFieldsProps {
  showParent?: boolean;
  requestData: ApprovalRequestDetailsVM;
  originalRequest: UserRequestApprovalVM | ResourceApprovalRequest;
  licenseId?: number;
  isResource?: (type) => boolean;
}
export const ApprovalTypeSpecificFields = ({
  showParent = false,
  requestData,
  originalRequest,
  licenseId,
  isResource,
}: ApprovalTypeSpecificFieldsProps) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {
    LearnInReimbursements: isLearnInReimbursementsOn,
    Subscriptions: isSubscriptionsOn,
  } = isFeatureFlagOn;

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    originalRequest?.currency || MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const parentApproval = requestData?.parentApproval;
  const requestedApproval = requestData?.requestedApproval;
  const isRecurring = (type) => isRecurringType(type);

  const approvalType = requestedApproval?.type;
  const dataOption =
    showParent && parentApproval ? 'parentApproval' : 'requestedApproval';
  const applicationData =
    requestData?.[dataOption]?.applicationDetails?.application || [];

  const applicationImages =
    requestData?.[dataOption]?.applicationDetails?.attachmentIds || [];

  const recurringCost = applicationData?.find(
    ({functionalityType, inputType}) =>
      isRecurring(functionalityType) &&
      inputType === PreApprovalQuestionInputType.Money
  );

  const frequency = applicationData.find(
    ({functionalityType, inputType}) =>
      isRecurring(functionalityType) &&
      inputType === PreApprovalQuestionInputType.Dropdown
  );

  if (
    (licenseId ||
      approvalType === ApprovalType.PLAN_ITEM ||
      isResource(approvalType) ||
      (approvalType === ApprovalType.REIMBURSEMENT &&
        isLearnInReimbursementsOn)) &&
    !!applicationData.length
  ) {
    return (
      <>
        {applicationData.map((app) => {
          //Check if response has line breaks and calculate the height of the textarea.
          const hasLineBreaks = /\r|\n/.exec(app?.response);
          const numOfLines = app?.response?.split(/\r\n|\r|\n/)?.length;
          const Input =
            app.inputType ===
            PreApprovalQuestionInputType.ImageUpload ? null : hasLineBreaks ? (
              <LearnInTextArea
                tag={TextAreaTags.PRIMARY}
                disabled={true}
                rows={numOfLines}
              />
            ) : app.response.length <= APPROVAL_DRAWER_TEXT_AREA_LINE_LENGTH ? (
              <LearnInInput
                tag={InputTags.PRIMARY}
                disabled={true}
                maxLength={500}
              />
            ) : (
              <LearnInTextArea
                tag={TextAreaTags.PRIMARY}
                disabled={true}
                rows={Math.round(
                  app.response.length / APPROVAL_DRAWER_TEXT_AREA_LINE_LENGTH
                )}
              />
            );

          const formattedCurrencyCode = displayCurrencyCode(
            originalRequest?.currency || CurrencyCode.USD
          );
          const monthFrequency = frequency?.response || 1;
          const cost = recurringCost?.response || 0;
          const formattedTotalCost = formatCurrency(
            Number(app.response),
            getUserQuery.data?.currency,
            exchangeRate
          );
          const formattedMonthlyCost = formatCurrency(
            Number(cost) / Number(monthFrequency),
            getUserQuery.data?.currency,
            exchangeRate
          );
          const initialValue =
            app.id === recurringCost?.id
              ? `${formattedTotalCost} ${formattedCurrencyCode} (${formattedMonthlyCost}/${i18n
                  .t(k.DATE__MONTH)
                  .toLowerCase()} x ${monthFrequency} ${i18n
                  .t(k.DATE__MONTH__PLURAL)
                  .toLowerCase()})`
              : determinePreApprovalQuestionInitialValue({
                  app,
                  currency: getUserQuery.data?.currency,
                  exchangeRate,
                });
          return (
            <>
              {Input ? (
                (app.functionalityType ===
                  PreApprovalQuestionFunctionalityType.NotFeatureQuestion ||
                  app.functionalityType ===
                    PreApprovalQuestionFunctionalityType.StartDate ||
                  app.functionalityType ===
                    PreApprovalQuestionFunctionalityType.EndDate ||
                  app.id === recurringCost?.id) && (
                  <Form.Item
                    className="lb-application-drawer"
                    name={'app' + app.id}
                    initialValue={initialValue}
                    label={
                      <b>
                        {localizeText({
                          translationKey: app.localizationId,
                          translationValue: app.value,
                        })}
                      </b>
                    }>
                    {Input}
                  </Form.Item>
                )
              ) : (
                <ReimbursementImages app={app} />
              )}
            </>
          );
        })}
        <>
          {!!applicationImages?.length && (
            <ApprovalImages app={applicationImages} />
          )}
        </>
      </>
    );
  }

  return approvalType === ApprovalType.TIME_USE ? (
    <>{i18n.t(k.GENERIC__UNDER_DEVELOPMENT)}</>
  ) : (
    <Form.Item
      className="lb-application-drawer"
      name="remainingBalance"
      initialValue={requestedApproval?.balance || 0}
      label={i18n.t(k.FUND__REMAINING_APPROVED)}>
      <LearnInInput tag={InputTags.PRIMARY} disabled={true} maxLength={100} />
    </Form.Item>
  );
};
