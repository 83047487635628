// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UserRequestType.cs

enum UserRequestType {
  Funds,
  License,
  ReimbursementPreApproval,
  Reimbursement,
  AdditionalFunds,
}

export default UserRequestType;
