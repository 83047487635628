import {useEffect} from 'react';
import {useGetUserCompanyIdFromNudgePath} from './useGetUserCompanyIdFromNudgePath';
import {UseNudgeEmailConfig} from './useNudgeEmail';

/** Handles re-entry to the nudge email preview drawer if the userCompanyId is available as a path in the URL (see DrawerRoutes.Nudge). This is helps support when a user navigates directly to a nudge path, which requires */
export const useParticipantIfInRoute = (
  config: UseNudgeEmailConfig,
  path: string,
  setEmailPreviewDrawer: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      userName?: string;
      userCompanyId?: number;
    }>
  >
) => {
  const getNudgedUserFromParticipants = (
    userCompanyId: string,
    participants: Partial<{
      userCompanyId: number;
      employeeName: string;
      name: string;
    }>[]
  ) => participants?.find((d) => d.userCompanyId.toString() === userCompanyId);

  const userCompanyIdFromPath = useGetUserCompanyIdFromNudgePath(path);

  useEffect(() => {
    if (userCompanyIdFromPath) {
      const userToOpen = getNudgedUserFromParticipants(
        userCompanyIdFromPath,
        config?.participants // a full, non-paginated list to filter from
      );
      if (userToOpen) {
        setEmailPreviewDrawer({
          isOpen: true,
          userName: userToOpen.employeeName || userToOpen.name,
          userCompanyId: userToOpen.userCompanyId,
        });
      }
    }
  }, [config, setEmailPreviewDrawer, userCompanyIdFromPath]);
};
