import {useCheckPublishedCustomProgramsExistQuery} from '@generated/hooks';
import {UseCanShowProgramsVM} from '@models/clientModels';
import {useEffect, useState} from 'react';
import useFeatureFlags from './useFeatureFlags';
import {useFlagsHaveLoaded} from './useFlagsHaveLoaded';
import {useAuth} from '@utils/oidc-auth-wrapper';

function useCanShowPrograms(): UseCanShowProgramsVM {
  const [response, setResponse] = useState<UseCanShowProgramsVM>(() => {
    return {
      customPrograms: false,
      marketplace: false,
      any: false,
      all: false,
    };
  });

  const {isFeatureFlagOn} = useFeatureFlags();
  const flagsReady = useFlagsHaveLoaded(isFeatureFlagOn);
  const {user} = useAuth();

  // make API call only if custom programs are enabled
  const {data: hasPublishedCustomPrograms, isLoading: isCheckPublishedCustomProgramsExistLoading } =
    useCheckPublishedCustomProgramsExistQuery(null, {
      enabled: user?.canViewAcademiesFeature && isFeatureFlagOn.CustomPrograms,
    });

  /**
   * :: ACA-2163 ::
   * Custom programs are deeply coupled with academies (if you pay for academies, you get to use custom programs)
   * Any time academies flag in the DB is “on” we should also show custom programs
   * Any time academies flag in the DB is “off” we should hide them
   * Update: With HideMarketplace Feature Flag if the marketplace is hidden, the user can still see academies if there are no custom programs.
   */
  useEffect(() => {
    if (!flagsReady) return;

    setResponse({
      customPrograms:
        user?.canViewAcademiesFeature && // Academies are enabled in the DB AND...
        isFeatureFlagOn.CustomPrograms && // Custom Programs are enabled AND
        !!hasPublishedCustomPrograms, // And there are available custom programs to show
      marketplace:
        !isFeatureFlagOn.OnlyCustomProgramsUser && // user is not restricted only to custom programs
        !isFeatureFlagOn.HideMarketplace && //marketplace is not hidden by feature flag
        !!user?.isEligibleForPersonalLearningBudget, // and is elegible for PLB
      get any() {
        // can display either CustomPrograms OR MarketPlace
        return this.customPrograms || this.marketplace;
      },
      get all() {
        // can display both CustomPrograms AND MarketPlace
        return this.customPrograms && this.marketplace;
      },
    });
  }, [flagsReady, user, hasPublishedCustomPrograms]);

  return { ...response, loading: !flagsReady || isCheckPublishedCustomProgramsExistLoading || !user};
}

export default useCanShowPrograms;
