import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ForwardOutlined,
} from '@ant-design/icons';
import {NewWindowIcon} from '@assets/new-window-icon';
import ContextMenuButton from '@blocks/ContextMenu';
import ProgressBar from '@components/learnin/ProgressBar';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {ContentLink} from '@components/reusable/ContentLink';
import {
  ErrorMessage,
  InputLabel,
  LearnInInput,
  LearnInTextArea,
} from '@components/reusable/LearnInForm';
import {DraggerCopy} from '@components/reusable/Upload/Upload.style';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import {notify} from '@components/user/notifications';
import {DisplayStatus, SubmissionType} from '@generated/enums';
import {
  AttachmentVM,
  ContentFeedbackVM,
  UserItemStatusVM,
} from '@generated/interfaces';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {i18n, k} from '@i18n/translate';
import {LinkVM} from '@models/serverModels';
import {COLORS} from '@utils/constants';
import {formatUrl} from '@utils/stringUtils';
import {formatDateTimeToMMMMDYYYY} from '@utils/timeUtils';
import {FileTypeDetails, FileUploadType} from '@utils/uploadUtils';
import {Upload} from 'antd';
import {truncate} from 'lodash';
import * as React from 'react';
import {useState} from 'react';
import styled, {DefaultTheme} from 'styled-components';
import {useProjectSubmissionValidation} from './ProjectSubmissionContentCardFooter.hooks';
import ProjectSubmissionFeedback from './ProjectSubmissionFeedback';

const {Dragger} = Upload;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const CustomDragger = styled(Dragger)`
  & *:focus {
    outline: 2px solid ${(props: DefaultTheme) => props.itemDefaultTextNavColor};
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const ProgramSkippedWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ProgramSkippedText = styled.span`
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0 24px 0 8px;
`;

const SubmissionDisplayContainer = styled.div`
  border-left: 4px solid ${COLORS.Neutral200};
  padding: 6px 16px;
`;

const SubmissionDisplayHeader = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;

const SubmittedOn = styled.span`
  color: ${COLORS.Neutral900};
  font-size: 0.75rem;
  font-weight: 400;
`;

const SubmittedResponse = styled.div`
  color: ${COLORS.Neutral900};
  font-size: 1rem;
  font-weight: 400;
`;

export const ContentLinkText = styled(LearnInButton)`
  align-items: center;
  color: ${COLORS.Blue800};
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  & *:focus {
    outline: 2px solid ${(props: DefaultTheme) => props.itemDefaultTextNavColor};
  }
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const TextResponseTruncateLength = 500;

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

const SubmissionDisplay = ({
  children,
  completedOn,
  feedback,
  canShowFeedback,
  setIsEditingSubmission,
}: {
  children: React.ReactNode;
  completedOn: string;
  feedback?: ContentFeedbackVM[];
  canShowFeedback: boolean;
  setIsEditingSubmission: (isEditing: boolean) => void;
}) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  return (
    <section>
      {isFeatureFlagOn.EditProjectSubmissions && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: '-36px',
          }}>
          <LearnInButton
            style={{fontWeight: 400}}
            icon={<EditOutlined />}
            aria-hidden="true"
            tag={ButtonTags.SecondaryRemovedBorder}
            onClick={() => setIsEditingSubmission(true)}>
            {i18n.t(k.CTA__EDIT)}
          </LearnInButton>
        </div>
      )}
      <SubmissionDisplayContainer>
        {completedOn && (
          <SubmissionDisplayHeader>
            <span aria-label={i18n.t(k.STATUS__SUBMITTED)}>
              <CheckCircleOutlined
                aria-hidden="true"
                style={{marginRight: '8px', color: COLORS.Green600}}
              />
            </span>
            <SubmittedOn
              dangerouslySetInnerHTML={{
                __html: i18n.t(k.STATUS__SUBMITTED_ON__FORMAT, {
                  date: formatDateTimeToMMMMDYYYY(completedOn),
                }),
              }}></SubmittedOn>
          </SubmissionDisplayHeader>
        )}
        {children}
      </SubmissionDisplayContainer>
      {canShowFeedback && <ProjectSubmissionFeedback feedback={feedback} />}
    </section>
  );
};

const CancelButton = ({onClick}: {onClick: () => void}) => {
  return (
    <LearnInButton onClick={onClick} tag={ButtonTags.Secondary}>
      {i18n.t(k.CTA__CANCEL)}
    </LearnInButton>
  );
};

enum ProjectSubmissionType {
  Url,
  File,
  Text,
}
const getAriaLabel = (title: string, type: ProjectSubmissionType) => {
  switch (type) {
    case ProjectSubmissionType.Url:
      return i18n.t(k.PROJECT__SUBMIT_URL__FORMAT, {item: title});
    case ProjectSubmissionType.File:
      return i18n.t(k.PROJECT__SUBMIT_FILE__FORMAT, {item: title});
    case ProjectSubmissionType.Text:
      return i18n.t(k.PROJECT__SUBMIT_TEXT__FORMAT, {item: title});
  }
};

const SubmitButton = ({
  onClick,
  ariaLabel,
}: {
  onClick: () => void;
  ariaLabel: string;
}) => {
  return (
    <LearnInButton
      onClick={onClick}
      aria-label={ariaLabel}
      tag={ButtonTags.Primary}>
      {i18n.t(k.CTA__SUBMIT)}
    </LearnInButton>
  );
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ProjectSubmissionContentCardFooterProps {
  title: string;
  onClickSubmit: (response?: string | Blob) => void;
  onClickSubmittedFileLink: () => void;
  submissionType: SubmissionType;
  submittedFile: AttachmentVM | undefined;
  submittedLink: LinkVM | undefined;
  submittedResponse: string | undefined;
  uploadProgress: number | undefined;
  completedOn: string | null;
  showSubmittedResponse: boolean;
  status: UserItemStatusVM;
  contentId: number;
  skipProgram: (contentId: number) => void;
  unskipProgram: (contentId: number) => void;
  showSkipProjectButton: boolean;
  feedback: ContentFeedbackVM[];
  canShowFeedback: boolean;
}

function ProjectSubmissionContentCardFooter({
  title,
  onClickSubmit,
  onClickSubmittedFileLink,
  submissionType,
  submittedFile,
  submittedLink,
  completedOn,
  submittedResponse,
  uploadProgress,
  showSubmittedResponse,
  contentId,
  status,
  skipProgram,
  unskipProgram,
  showSkipProjectButton,
  feedback,
  canShowFeedback,
}: ProjectSubmissionContentCardFooterProps) {
  const hasResponse = !!submittedFile || !!submittedLink || !!submittedResponse;
  const [textResponse, setTextResponse] = useState(submittedResponse || '');
  const [linkResponse, setLinkResponse] = useState(submittedLink?.url || '');
  const [isEditingSubmission, setIsEditingSubmission] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [fileToUpload, setFileToUpload] = useState<any>();
  const {errors, validateLink, validateText} = useProjectSubmissionValidation();

  const contextTextId = 'project-submission-text';
  const contextFileUploadId = 'project-submission-file-upload';
  const contextLinkId = 'project-link-upload';

  const handleChangeTextResponse = (textResponseValue: string) => {
    setTextResponse(textResponseValue);
    validateText(textResponseValue);
  };

  const handleChangeLinkResponse = (linkResponseValue: string) => {
    setLinkResponse(linkResponseValue);
    validateLink(linkResponseValue);
  };

  switch (submissionType) {
    case SubmissionType.Text:
      // Text Submission Display
      if (
        !!submittedResponse?.length &&
        showSubmittedResponse &&
        !isEditingSubmission
      ) {
        return (
          <SubmissionDisplay
            setIsEditingSubmission={setIsEditingSubmission}
            completedOn={completedOn}
            feedback={feedback}
            canShowFeedback={canShowFeedback}>
            <SubmittedResponse>
              {/* TabIndex should be on a focusable element (input, anchor or button). However, we don't want to change this to a focusable element without testing the impact visually and functionally. It might be a case where we're better off leaving as-is unless there is an a11y issue reported from it. */}
              {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
              <div tabIndex={0}>
                {textResponse.length < TextResponseTruncateLength || showMore
                  ? textResponse
                  : truncate(textResponse, {
                      length: TextResponseTruncateLength,
                    })}
              </div>
              {textResponse.length > TextResponseTruncateLength && (
                <LearnInButton
                  tag={ButtonTags.SecondaryRemovedBorder}
                  tabIndex={0}
                  onClick={() => setShowMore(!showMore)}>
                  {showMore
                    ? i18n.t(k.CTA__SHOW_LESS)
                    : i18n.t(k.CTA__SHOW_MORE)}
                </LearnInButton>
              )}
            </SubmittedResponse>
          </SubmissionDisplay>
        );
      }
      // Text Submission Form
      return (
        <div id={contextTextId}>
          <InputLabel
            htmlFor="text-response"
            label={i18n.t(k.CTA__SUBMIT_RESPONSE)}
          />
          {status.displayStatus !== DisplayStatus.Skipped && (
            <>
              <LearnInTextArea
                required
                id="text-response"
                value={textResponse}
                updateFunc={handleChangeTextResponse}
                placeholder={i18n.t(k.PROJECT__ANSWER_PROMPT)}
                aria-labelledby={`content-${contentId}`}
              />
              {errors.text && <ErrorMessage message={errors.text} />}
            </>
          )}
          <ButtonWrapper>
            {status.displayStatus === DisplayStatus.Skipped &&
            isEditingSubmission === false ? (
              <ProgramSkippedWrapper key="skipped">
                <ForwardOutlined
                  aria-hidden="true"
                  style={{
                    color: COLORS.Neutral600,
                    fontSize: '1.375rem',
                  }}
                />
                <ProgramSkippedText>
                  {i18n.t(k.STATUS__SKIPPED)}
                </ProgramSkippedText>
              </ProgramSkippedWrapper>
            ) : (
              <div style={{display: 'flex'}}>
                <SubmitButton
                  ariaLabel={getAriaLabel(title, ProjectSubmissionType.Text)}
                  onClick={() => {
                    if (validateText(textResponse)) {
                      setIsEditingSubmission(false);
                      onClickSubmit(textResponse);
                    }
                  }}
                />
                {submittedResponse && (
                  <CancelButton
                    onClick={() => {
                      setIsEditingSubmission(false);
                      setTextResponse(submittedResponse);
                    }}
                  />
                )}
              </div>
            )}
            {showSkipProjectButton && !hasResponse && (
              <ContextMenuButton
                key="context"
                size="large"
                popupContainerId={contextTextId}
                itemId={contentId}
                overlayPlacement="bottomRight"
                menuItems={[
                  status.displayStatus !== DisplayStatus.Skipped && {
                    label: i18n.t(k.PROJECT__SKIP),
                    onClick: () => {
                      setIsEditingSubmission(false);
                      skipProgram(contentId);
                    },
                  },
                  status.displayStatus === DisplayStatus.Skipped && {
                    label: i18n.t(k.PROJECT__UNSKIP),
                    onClick: () => {
                      unskipProgram(contentId);
                    },
                  },
                ]}
              />
            )}
          </ButtonWrapper>
        </div>
      );

    case SubmissionType.Link:
      // Link Submission Display
      if (!!submittedLink && showSubmittedResponse && !isEditingSubmission) {
        return (
          <SubmissionDisplay
            setIsEditingSubmission={setIsEditingSubmission}
            completedOn={completedOn}
            feedback={feedback}
            canShowFeedback={canShowFeedback}>
            <ContentLink title={linkResponse} url={formatUrl(linkResponse)} />
          </SubmissionDisplay>
        );
      }
      // Link Submission Form
      return (
        <div id={contextLinkId}>
          <InputLabel
            required
            htmlFor="link-response-input"
            label={i18n.t(k.LINK__SUBMIT)}
          />
          <LearnInInput
            id="link-response-input"
            value={linkResponse}
            updateFunc={handleChangeLinkResponse}
            placeholder={i18n.t(k.CTA__ENTER_URL)}
            aria-labelledby={`content-${contentId}`}
          />
          {status.displayStatus !== DisplayStatus.Skipped && errors.link && (
            <ErrorMessage message={errors.link} />
          )}
          <ButtonWrapper>
            {status.displayStatus === DisplayStatus.Skipped ? (
              <ProgramSkippedWrapper key="skipped">
                <ForwardOutlined
                  style={{
                    color: COLORS.Neutral600,
                    fontSize: '1.375rem',
                  }}
                />
                <ProgramSkippedText>
                  {i18n.t(k.STATUS__SKIPPED)}
                </ProgramSkippedText>
              </ProgramSkippedWrapper>
            ) : (
              <div style={{display: 'flex'}}>
                <SubmitButton
                  ariaLabel={getAriaLabel(title, ProjectSubmissionType.Url)}
                  onClick={() => {
                    if (validateLink(linkResponse)) {
                      onClickSubmit(linkResponse);
                      setIsEditingSubmission(false);
                    }
                  }}
                />
                {submittedLink && (
                  <CancelButton
                    onClick={() => {
                      setIsEditingSubmission(false);
                      setLinkResponse(submittedLink.url);
                    }}
                  />
                )}
              </div>
            )}
            {showSkipProjectButton && isEditingSubmission === false && (
              <ContextMenuButton
                key="context"
                size="large"
                popupContainerId={contextLinkId}
                itemId={contentId}
                overlayPlacement="bottomRight"
                menuItems={[
                  status.displayStatus !== DisplayStatus.Skipped && {
                    label: i18n.t(k.PROJECT__SKIP),
                    onClick: () => {
                      setIsEditingSubmission(false);
                      skipProgram(contentId);
                    },
                  },
                  status.displayStatus === DisplayStatus.Skipped && {
                    label: i18n.t(k.PROJECT__UNSKIP),
                    onClick: () => {
                      unskipProgram(contentId);
                    },
                  },
                ]}
              />
            )}
          </ButtonWrapper>
        </div>
      );

    case SubmissionType.FileUpload:
      // Display progress for uploads in progress
      if (typeof uploadProgress === 'number') {
        return (
          <div>
            <div>
              {fileToUpload?.name || i18n.t(k.FILE__UPLOAD__IN_PROGRESS)}
            </div>
            <ProgressBar percent={uploadProgress} />
          </div>
        );
      }
      // File Upload Display
      if (!!submittedFile && showSubmittedResponse && !isEditingSubmission) {
        return (
          <SubmissionDisplay
            setIsEditingSubmission={setIsEditingSubmission}
            completedOn={completedOn}
            feedback={feedback}
            canShowFeedback={canShowFeedback}>
            <ContentLinkText
              tag={ButtonTags.SecondaryRemovedBorder}
              key={submittedFile.url}
              onClick={onClickSubmittedFileLink}>
              <NewWindowIcon
                style={{marginRight: '9px', width: '18px'}}
                fill={COLORS.Blue800}
              />
              {i18n.t(k.FILE__OPEN_ATTACHMENT)}
            </ContentLinkText>
          </SubmissionDisplay>
        );
      }
      // File Upload Form
      return (
        <div id={contextFileUploadId}>
          {/* Queued File Upload */}
          {!!fileToUpload ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <div>{fileToUpload.name}</div>
              <div>
                <button
                  onClick={() => {
                    setFileToUpload(undefined);
                  }}>
                  <DeleteOutlined
                    alt={i18n.t(k.CTA__DELETE)}
                    style={{
                      cursor: 'pointer',
                      color: COLORS.Red800,
                      fontSize: '1.4em',
                    }}
                  />
                </button>
              </div>
            </div>
          ) : (
            // Upload Form Input
            <CustomDragger
              customRequest={({file}) => {
                if (
                  ((file as File)?.size as number) >
                  UploadConstraints.MaxUserContentUploadSizeBytes
                ) {
                  return notify.uploadContentAttachmentTooLargeError(
                    (file as File)?.name || i18n.t(k.FILE)
                  );
                }
                setFileToUpload(file);
              }}
              showUploadList={false}
              maxCount={1}
              multiple={false}
              accept={[
                ...FileTypeDetails[FileUploadType.Images].fileSuffixes,
                ...FileTypeDetails[FileUploadType.Videos].fileSuffixes,
                ...FileTypeDetails[FileUploadType.Pdf].fileSuffixes,
              ].join(', ')}>
              <div
                // Accessible only through screen readers - reads out the project prompt/content
                className="tw-sr-only"
                dangerouslySetInnerHTML={{
                  __html: document?.getElementById(`content-${contentId}`)
                    ?.innerHTML,
                }}
              />
              <DraggerCopy
                fileTypes={i18n.t(k.FILE__UPLOAD__IMAGES_DOCUMENTS_AND_VIDEOS)}
              />
            </CustomDragger>
          )}
          <ButtonWrapper>
            {status.displayStatus === DisplayStatus.Skipped ? (
              <ProgramSkippedWrapper key="skipped">
                <ForwardOutlined
                  style={{
                    color: COLORS.Neutral600,
                    fontSize: '1.375rem',
                  }}
                />
                <ProgramSkippedText>
                  {i18n.t(k.STATUS__SKIPPED)}
                </ProgramSkippedText>
              </ProgramSkippedWrapper>
            ) : (
              <div style={{display: 'flex'}}>
                <SubmitButton
                  ariaLabel={getAriaLabel(title, ProjectSubmissionType.File)}
                  onClick={() => {
                    setIsEditingSubmission(false);
                    onClickSubmit(fileToUpload);
                  }}
                />
                {submittedFile && (
                  <CancelButton
                    onClick={() => {
                      setIsEditingSubmission(false);
                    }}
                  />
                )}
              </div>
            )}
            {showSkipProjectButton && (
              <ContextMenuButton
                key="context"
                size="large"
                popupContainerId={contextFileUploadId}
                itemId={contentId}
                overlayPlacement="bottomRight"
                menuItems={[
                  status.displayStatus !== DisplayStatus.Skipped && {
                    label: i18n.t(k.PROJECT__SKIP),
                    onClick: () => {
                      setIsEditingSubmission(false);
                      skipProgram(contentId);
                    },
                  },
                  status.displayStatus === DisplayStatus.Skipped && {
                    label: i18n.t(k.PROJECT__UNSKIP),
                    onClick: () => {
                      unskipProgram(contentId);
                    },
                  },
                ]}
              />
            )}
          </ButtonWrapper>
        </div>
      );

    default:
      return null;
  }
}

export default ProjectSubmissionContentCardFooter;
