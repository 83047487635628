import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Form} from 'antd';
import {BankOutlined} from '@ant-design/icons';
import {datadogLogs} from '@datadog/browser-logs';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {Academy} from '@models/clientModels';
import {AdminPaths, editAcademyUrl} from '@utils/ClientPaths';
import {createAcademyRm} from '@store/apiEndpoints/academy/mutations';
import {useNavigate} from 'react-router-dom';
import {COLORS} from '@utils/constants';
import {useGetDefaultAcademy} from '@utils/academyBranding';
import {useUpdateAcademyBrandingMutation} from '@generated/hooks';

import {NewAcademy} from './NewAcademy';

export const AcademyIcon = styled(BankOutlined)`
  background: ${COLORS.Neutral100};
  border-radius: 4px;
  padding: 0.5rem;
`;

export const NewAcademyContainer = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [fieldErrors, setFieldErrors] = useState({restrictedAccess: ''});
  const [restrictedAccess, setRestrictedAccess] = useState<boolean>(null);
  const updateAcademyBranding = useUpdateAcademyBrandingMutation();
  const defaultAcademyTheme = useGetDefaultAcademy();

  const createAcademyMutation = useMutation(
    (args: Academy) => {
      const createAcademy = createAcademyRm(args);
      return simpleMutationFn<null>(createAcademy.path, createAcademy.payload);
    },
    {
      onSuccess: async (newAcademyId) => {
        updateAcademyBranding.mutate({
          payload: {
            ...defaultAcademyTheme,
            academyId: newAcademyId,
          },
        });
        navigate(editAcademyUrl(newAcademyId));
      },
      onError: async (e) => {
        console.log('error', e);
      },
    }
  );

  const handleSubmit = () => {
    if (typeof restrictedAccess !== 'boolean') {
      setFieldErrors({
        ...fieldErrors,
        restrictedAccess: i18n.t(k.VALIDATION__PRIVACY_SETTING),
      });
    } else {
      form
        .validateFields()
        .then((payload) => {
          createAcademyMutation.mutateAsync({
            status: 0,
            restrictedAccess,
            ...payload,
          });
        })
        .catch((error) => {
          datadogLogs.logger.error('Error while submitting new academy', error);
        });
    }
  };

  return (
    <LearnInPageDrawer
      Icon={AcademyIcon}
      onClose={() => navigate(AdminPaths.Academies)}
      title={i18n.t(k.ACADEMY__NEW)}
      visible={true}>
      <NewAcademy
        fieldErrors={fieldErrors}
        form={form}
        onSubmit={handleSubmit}
        restrictedAccess={restrictedAccess}
        setRestrictedAccess={setRestrictedAccess}
      />
    </LearnInPageDrawer>
  );
};
