import {useEffect, useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import PlanItemDetailPage from './PlanItemDetailPage';
import {
  RequestApprovalButton,
  RequestLicenseButton,
  ResubmitApprovalButton,
  SelectedButton,
  SelectProgramButton,
  ViewLicenseButton,
  VisitProgramButton,
} from '@components/reusable/Button/LearnInButtons';
import {Tabs} from 'antd';
import {
  FinanceDetailsVM,
  IncentiveInitiativeProgramVM,
  ProgramDetailsVM,
  ProgramFilterVM,
  ProgramInitiativeIncentiveVM,
  TimeDetailsVM,
} from '@models/serverModels';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {
  getFinanceIncentiveDetailRq,
  getFinanceIncentiveInitiativesRq,
  getFinanceIncentiveProgramTypesRq,
  getTimeIncentiveDetailRq,
  getTimeIncentiveProgramTypesRq,
} from '@store/apiEndpoints';
import {
  getProgramDetailsRq,
  getProgramInitiativesForItemRq,
} from '@store/apiEndpoints/program/queries';
import {
  getExchangeRateOfCurrencyPair,
  MONEY_DEFAULT,
  useLocalizedAmountDisclaimer,
} from '@utils/moneyUtils';
import {isNil, startCase} from 'lodash';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {
  EligibleProgramsTab,
  IncentivesTab,
  NominationsTab,
  OverviewTab,
  ParticipantsTab,
  ReviewsTab,
} from './Tabs';
import {getItemCTAButtonType, ItemCTAButtonType} from '@utils/itemUtils';
import {CurrencyCode, PlanItemType} from '@generated/enums';
import PreApprovalDrawer from '@components/user/pages/components/preApprovalDrawer/PreApprovalDrawer.container';
import useAddToPlan, {
  useDeleteFromPlanById,
  useDeleteFromPlanByResourceId,
} from '@hooks/apiEndpoints/userPlan/mutations';
import {openInNewTab} from '@components/providers/utils';
import {isPaymentOrReimbursable} from '@utils/timeUtils';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import DeleteProgramItemModal from '@blocks/deleteProgramItemModal/DeleteProgramItemModal';
import usePathInfo from '@hooks/usePathInfo';
import {UserPlanItemStatusString} from '@models/clientEnums';
import LicenseViewerModal from '@blocks/licenseViewerModal/LicenseViewerModal.container';
import DeleteLicensedOrCustomProgramItemModal from '@blocks/deleteLicensedOrCustomProgramItemModal/DeleteLicensedOrCustomProgramItemModal';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import ActionButton from '@blocks/actionButton/ActionButton';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import {buildMetadataArray} from '@utils/metadataUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  getPlbL10nIfAvailable,
  getUsersLocale,
  TempLocalizationKeys,
} from '@utils/l10nUtils';
import {CurrencyRatesVM} from '@models/clientModels';
import {getLicenseCost} from '@utils/licenseUtils';
import {getUserMarketplacePath} from '@utils/ClientPaths';
import SkillsTab from './Tabs/SkillsTab';
import {ShareAltOutlined} from '@ant-design/icons';
import {useBackToLxpLink} from '@hooks/integrated/useBackToLxpLink';
import {OverviewSidePanel} from './OverviewSidePanel';
import {OverviewTabWrapper} from './Tabs/OverviewTab';
import {Helmet} from 'react-helmet-async';

const {TabPane} = Tabs;

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export enum SectionTitles {
  Contact = 'contact',
  FAQs = 'faqs',
  PaymentSchedule = 'payment schedule',
}

enum PageTabs {
  Overview = 'Overview',
  Nominations = 'Nominations',
  Reviews = 'Reviews',
  Participants = 'Participants',
  Incentives = 'Incentives',
  EligiblePrograms = 'Programs',
}

export const getPageDisplayDetails = ({
  planItem,
  itemType,
  locale,
  flagIsOn,
}: {
  planItem: Partial<{
    title: string;
    shortDescription: string;
    longDescription: string;
  }>;
  itemType: PlanItemType;
  locale: string;
  flagIsOn: boolean;
}) => {
  const l10nIfAvailable = (nonLocalized: string, key: TempLocalizationKeys) => {
    const isFinanceType = itemType === PlanItemType.Finance;
    return isFinanceType
      ? getPlbL10nIfAvailable({
          locale,
          flagIsOn,
          nonLocalized,
          key,
        })
      : nonLocalized;
  };
  return {
    itemTitle: l10nIfAvailable(planItem?.title, 'plbTitle'),
    itemShortDescription: l10nIfAvailable(
      planItem?.shortDescription,
      'plbDescription'
    ),
    itemLongDescription: l10nIfAvailable(
      planItem?.longDescription,
      'plbDetails'
    ),
  };
};

export const getEffectiveExchangeRate = ({
  itemCurrency,
  licenseCurrency,
  userCurrency,
  exchangeTable,
  defaultRate,
}: {
  itemCurrency: CurrencyCode;
  licenseCurrency?: CurrencyCode;
  userCurrency: CurrencyCode;
  exchangeTable: CurrencyRatesVM[];
  defaultRate: number;
}) => {
  const sourceCurrency = !isNil(licenseCurrency)
    ? licenseCurrency
    : itemCurrency;
  const applyDefault = userCurrency === sourceCurrency;
  return applyDefault
    ? defaultRate
    : getExchangeRateOfCurrencyPair(
        exchangeTable,
        userCurrency,
        sourceCurrency
      );
};

export const getOnClose = ({
  renderPageInsteadOfDrawer,
  isExternalLink,
  linkToLxpMarketplace,
  goToMarketplace,
  onClose,
}: {
  renderPageInsteadOfDrawer: boolean;
  isExternalLink: boolean;
  linkToLxpMarketplace?: string;
  goToMarketplace?: () => void;
  onClose?: () => void;
}) => {
  // Hide when srcpath is not included (i.e. from MS Teams)
  const showNoBackButton = renderPageInsteadOfDrawer && !linkToLxpMarketplace;
  const hasLinkData = isExternalLink || linkToLxpMarketplace;
  if (showNoBackButton) return null;
  if (!hasLinkData) return onClose;
  if (!isExternalLink && !linkToLxpMarketplace) return () => history.back();
  return goToMarketplace;
};

export interface IProgramDetailPageContainerProps {
  id?: number;
  visible: boolean;
  onClose: () => void;
  forAdminInitiative?: boolean;
  itemType: PlanItemType;
  renderPageInsteadOfDrawer?: boolean;
  setRejected?: (rejected) => void;
  setRequested?: (requested) => void;
  rejected?: boolean;
  requested?: boolean;
  title?: string;
}

function ProgramDetailPageContainer({
  id,
  visible,
  onClose,
  forAdminInitiative,
  itemType,
  setRejected,
  setRequested,
  renderPageInsteadOfDrawer,
  rejected,
  requested,
  title,
}: IProgramDetailPageContainerProps) {
  const [showApprovalDrawer, setShowApprovalDrawer] = useState(false);
  const [showRequestLicenseDrawer, setShowRequestLicenseDrawer] =
    useState(false);
  const [showViewLicenseModal, setShowViewLicenseModal] = useState(false);
  const [planItem, setPlanItem] = useState<any>(null); // this will be ProgramDetailsVM or FinanceDetailsVM
  const [incentives, setIncentives] = useState<any[]>([]);
  const [incentiveProgramTypes, setIncentiveProgramTypes] = useState<any[]>([]);
  const [showEligibleProgramTabs, setShowEligibleProgramsTab] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [isRemoveProgramModalVisible, setIsRemoveProgramModalVisible] =
    useState(false);
  const [
    isRemoveLicensedProgramModalVisible,
    setIsRemoveLicensedProgramModalVisible,
  ] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const params = useParams();
  const {isAdminPath} = usePathInfo();
  const {isFeatureFlagOn} = useFeatureFlags();
  const {activePlbExists} = useUserPlanFinanceLearningBudgetOverviewVMQuery();

  const {user, isAdmin, isManager} = useAuth();
  //with router upgrade, param ids do not exists before the route changes
  const isRedesignOn = isFeatureFlagOn.ProgramDetailsRedesign;

  const isNotFinanceIncentive =
    itemType !== PlanItemType.Finance && isRedesignOn;

  if (id === undefined || !id) {
    id = Number(params.id);
  }
  //check if previous item in history is outside of the app
  const location = useLocation();
  const navigate = useNavigate();
  const linkToLxpMarketplace = useBackToLxpLink(user, {
    type: 'program',
    resourceId: params.id,
  });

  // `key` can still be 'default' while `idx` undefined from internal pages
  const isExternalLink =
    location.key === 'default' && window.history.state?.idx === 0;

  const goToMarketplace = () => {
    renderPageInsteadOfDrawer && linkToLxpMarketplace
      ? (window.location.href = linkToLxpMarketplace)
      : navigate(getUserMarketplacePath());
  };
  // Fetch Plan Item Details Data
  // Programs (Detail Data, Incentives)
  const programDetailsRq = getProgramDetailsRq(id);
  const programDetailsQuery = useQuery<ProgramDetailsVM>(
    programDetailsRq.queryKey,
    () => simpleQueryFn(programDetailsRq.path),
    {
      enabled: visible && itemType === PlanItemType.Program,
      onSuccess: (data) => {
        updateCardState(data);
        if (itemType === PlanItemType.Program) {
          setPlanItem(data);
        }
      },
    }
  );

  // Currency handling
  const getUserQuery = useGetUserQuery();
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: !!planItem?.currency,
  });
  const defaultRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery?.data?.currency
  );
  useEffect(() => {
    setExchangeRate(
      getEffectiveExchangeRate({
        itemCurrency: planItem?.currency,
        licenseCurrency: planItem?.licenseCurrency,
        userCurrency: getUserQuery?.data?.currency,
        defaultRate,
        exchangeTable: getCurrencyExchangeQuery?.data,
      })
    );
  }, [
    planItem?.currency,
    planItem?.licenseCurrency,
    defaultRate,
    getCurrencyExchangeQuery?.data,
    getUserQuery?.data?.currency,
  ]);

  // Queries to refresh card data
  const updateCardState = (data) => {
    if (!!planItem?.licenseId) {
      if (data?.status === UserPlanItemStatusString.Rejected) {
        setRejected(true);
      } else if (data?.status === UserPlanItemStatusString.Pending) {
        setRejected(false);
        setRequested(true);
      }
    }
  };

  const handleSettingsActions = useMemo(() => {
    return () => {
      // do nothing
    };
  }, []);

  const programInitiativesForItemRq = getProgramInitiativesForItemRq(id);
  useQuery<ProgramInitiativeIncentiveVM[]>(
    programInitiativesForItemRq.queryKey,
    () => simpleQueryFn(programInitiativesForItemRq.path),
    {
      enabled: visible && itemType === PlanItemType.Program,
      onSuccess: (data) => {
        if (itemType === PlanItemType.Program) {
          setIncentives(data);
        }
      },
    }
  );

  // Finance Incentives (Detail Data, Incentives, Incentive ProgramTypes)
  const financeIncentiveDetailsRq = getFinanceIncentiveDetailRq(id);
  const financeIncentiveDetailsQuery = useQuery<FinanceDetailsVM>(
    financeIncentiveDetailsRq.queryKey,
    () => simpleQueryFn(financeIncentiveDetailsRq.path),
    {
      enabled: visible && itemType === PlanItemType.Finance,
      onSuccess: (data) => {
        updateCardState(data);
        if (itemType === PlanItemType.Finance) {
          setPlanItem(data);
        }
      },
    }
  );

  const financeIncentiveInitiativesRq = getFinanceIncentiveInitiativesRq(id);
  useQuery<IncentiveInitiativeProgramVM[]>(
    financeIncentiveInitiativesRq.queryKey,
    () => simpleQueryFn(financeIncentiveInitiativesRq.path),
    {
      enabled: visible && itemType === PlanItemType.Finance,
      onSuccess: (data) => {
        if (itemType === PlanItemType.Finance) {
          setIncentives(data);
        }
      },
    }
  );

  const financeIncentiveProgramTypesRq = getFinanceIncentiveProgramTypesRq(id);
  useQuery<ProgramFilterVM[]>(
    financeIncentiveProgramTypesRq.queryKey,
    () => simpleQueryFn(financeIncentiveProgramTypesRq.path),
    {
      enabled: visible && itemType === PlanItemType.Finance,
      onSuccess: (data) => {
        if (itemType === PlanItemType.Finance) {
          setShowEligibleProgramsTab(true);
          setIncentiveProgramTypes(data);
        }
      },
    }
  );

  // Time Incentives (Detail Data, Incentives, Incentive ProgramTypes)
  const timeIncentiveDetailsRq = getTimeIncentiveDetailRq(id);
  const getTimeIncentiveDetailQuery = useQuery<TimeDetailsVM>(
    timeIncentiveDetailsRq.queryKey,
    () => simpleQueryFn(timeIncentiveDetailsRq.path),
    {
      enabled: visible && itemType === PlanItemType.Time,
      onSuccess: (data) => {
        updateCardState(data);
        if (itemType === PlanItemType.Time) {
          setPlanItem(data);
        }
      },
    }
  );

  const timeIncentiveInitiativesRq = getFinanceIncentiveInitiativesRq(id);
  useQuery<IncentiveInitiativeProgramVM[]>(
    timeIncentiveInitiativesRq.queryKey,
    () => simpleQueryFn(timeIncentiveInitiativesRq.path),
    {
      enabled: visible && itemType === PlanItemType.Time,
      onSuccess: (data) => {
        if (itemType === PlanItemType.Time) {
          setIncentives(data);
        }
      },
    }
  );

  const timeIncentiveProgramTypesRq = getTimeIncentiveProgramTypesRq(id);
  useQuery<ProgramFilterVM[]>(
    timeIncentiveProgramTypesRq.queryKey,
    () => simpleQueryFn(timeIncentiveProgramTypesRq.path),
    {
      enabled: visible && itemType === PlanItemType.Time,
      onSuccess: (data) => {
        if (itemType === PlanItemType.Time) {
          setShowEligibleProgramsTab(true);
          setIncentiveProgramTypes(data);
        }
      },
    }
  );

  const deleteFromPlanByResourceIdMutation = useDeleteFromPlanByResourceId({
    onFinished: programDetailsQuery.refetch,
  });

  const deletePlanItemByIdMutation = useDeleteFromPlanById({});

  const refreshData = async () => {
    switch (itemType) {
      case PlanItemType.Program:
        await programDetailsQuery.refetch();
        break;
      case PlanItemType.Finance:
        await financeIncentiveDetailsQuery.refetch();
        break;
      case PlanItemType.Time:
        await getTimeIncentiveDetailQuery.refetch();
        break;
    }
  };

  // Approval Drawer
  const handleClickRequestApproval = () => {
    setShowApprovalDrawer(true);
  };

  const handleApprovalDrawerOk = () => {
    refreshData();
    setShowApprovalDrawer(false);
  };

  const handleApprovalDrawerCancel = () => {
    setShowApprovalDrawer(false);
  };

  // Request License Drawer
  const handleClickRequestLicense = () => {
    setShowRequestLicenseDrawer(true);
  };

  // View License Modal
  const handleClickViewLicense = () => {
    setShowViewLicenseModal(true);
  };

  // Add to Plan
  const addToPlanMutation = useAddToPlan({
    onSuccess: () => {
      refreshData();
    },
  });

  const handleClickAddToPlan = () => {
    addToPlanMutation.mutateAsync({
      optionId: id,
      planItemType: itemType,
    });
  };

  // Handle Click Selected (Remove Item from Plan)
  const handleRemoveProgramModalOk = () => {
    refreshData();
  };

  const handleClickSelected = async () => {
    if (!activePlbExists) {
      await deleteFromPlanByResourceIdMutation.mutateAsync({
        resourceId: id,
        itemType: itemType,
      });
    } else {
      planItem?.licenseId
        ? setIsRemoveLicensedProgramModalVisible(true)
        : setIsRemoveProgramModalVisible(true);
    }
  };

  // View Program Website
  const handleClickViewProgramWebsite = () => {
    openInNewTab(planItem?.applyUrl);
  };

  //Copy Program Link
  const copyProgramLink = () => {
    const url = window.location.href;
    navigator.clipboard.writeText(url);
    setIsLinkCopied(true);
    setTimeout(() => {
      setIsLinkCopied(false);
    }, 5000);
  };

  // CTA Buttons
  const buttons = (() => {
    const ctaButtonType = getItemCTAButtonType({
      approvalRequired: planItem?.approvalRequired,
      approvalRequested: !!requested || !!planItem?.approvalRequested,
      approvalReceived: planItem?.status === UserPlanItemStatusString.Approved,
      approvalRejected: rejected,
      forAdminInitiative,
      selected: planItem?.selected,
      cost: planItem?.cost,
      isLicenseProgram: planItem?.isLicenseProgram,
      licenseId: planItem?.licenseId,
      itemType,
    });
    const buttons = [];
    if (
      !isAdminPath &&
      !forAdminInitiative &&
      !financeIncentiveDetailsQuery.data?.learningBudget
    ) {
      switch (ctaButtonType) {
        case ItemCTAButtonType.AddToInitiative:
          // To re-implement later if/when this is used for initiatives
          break;
        case ItemCTAButtonType.AddToPlan:
          buttons.push(
            <SelectProgramButton
              tag={ButtonTags.Primary}
              key="addToPlan"
              onClick={handleClickAddToPlan}
            />
          );
          break;
        case ItemCTAButtonType.RequestLicense:
          buttons.push(
            <RequestLicenseButton
              title={planItem?.title}
              key="requestLicense"
              isPrimary={true}
              onClick={handleClickRequestLicense}
            />
          );
          break;
        case ItemCTAButtonType.ViewLicense:
          buttons.push(
            <ViewLicenseButton
              key="viewLicense"
              isPrimary={true}
              onClick={handleClickViewLicense}
            />
          );
          break;
        case ItemCTAButtonType.RequestApproval:
          buttons.push(
            <RequestApprovalButton
              title={planItem?.title}
              tag={ButtonTags.Primary}
              key="requestApproval"
              onClick={handleClickRequestApproval}
            />
          );
          break;
        case ItemCTAButtonType.Selected:
          buttons.push(
            <SelectedButton key="selected" onClick={handleClickSelected} />
          );
          break;
        case ItemCTAButtonType.Requested:
          buttons.push(
            <LearnInButton disabled key="requested" tag={ButtonTags.Primary}>
              {i18n.t(k.STATUS__REQUESTED)}
            </LearnInButton>
          );
          break;
        case ItemCTAButtonType.Resubmit:
          buttons.push(
            <ResubmitApprovalButton
              key="resubmitApproval"
              onClick={handleClickRequestApproval}
            />
          );
          break;
        case ItemCTAButtonType.Rejected:
          buttons.push(
            <ActionButton
              item={{
                ...programDetailsQuery.data,
                programApprovalRequired: planItem?.approvalRequired,
                itemType,
                status: planItem?.status,
              }}
              itemType={itemType}
              handleShowRequestLicenseDrawer={setShowRequestLicenseDrawer}
              onSettings={handleSettingsActions}
            />
          );
          break;
      }
    }
    if (itemType === PlanItemType.Program) {
      buttons.push(
        <VisitProgramButton
          key="seeDetails"
          onClick={handleClickViewProgramWebsite}
        />
      );
      if (isNotFinanceIncentive) {
        buttons.push(
          <LearnInButton
            tag={ButtonTags.SecondaryRemovedBorder}
            onClick={copyProgramLink}>
            {!isLinkCopied ? <ShareAltOutlined aria-hidden="true" /> : ''}
            {!isLinkCopied
              ? i18n.t(k.GENERIC__SHARE_PROGRAM)
              : i18n.t(k.GENERIC__LINK_COPIED)}
          </LearnInButton>
        );
      }
    }
    return buttons;
  })();

  // Display Items in the header, conditional on type of plan item
  const metaData = () => {
    if (itemType === PlanItemType.Program) {
      const item = {
        programType: startCase(planItem?.programType),
        programLength: startCase(planItem?.duration),
        licenseId: planItem?.isLicenseProgram,
        actualLearningTime: planItem?.actualLearningTime,
      };
      return buildMetadataArray(item, isFeatureFlagOn.DurationStrings);
    }
    if (itemType === PlanItemType.Finance) {
      return [isPaymentOrReimbursable(planItem?.financeType)];
    }
  };

  const localizedAmount = getLicenseCost(
    planItem,
    getUserQuery.data?.currency,
    exchangeRate
  );

  const cost = planItem?.licenseCost || planItem?.cost;

  const tooltip = useLocalizedAmountDisclaimer({
    amount: cost,
    sourceCurrency: planItem?.licenseCurrency || planItem?.currency,
    usersCurrency: getUserQuery?.data?.currency,
    localizedAmount,
    useDisclaimer: exchangeRate !== 1,
  });

  const itemInfo = () => {
    if (itemType === PlanItemType.Program) {
      return {
        title: i18n.t(k.MONEY__TOTAL_COST),
        value: cost ? localizedAmount : i18n.t(k.GENERIC__N_A),
        tooltip,
      };
    }
  };

  const {itemTitle, itemShortDescription, itemLongDescription} =
    getPageDisplayDetails({
      planItem,
      itemType,
      locale: getUsersLocale(),
      flagIsOn: isFeatureFlagOn.TranslatePlbFields,
    });

  const sections = planItem?.sections?.filter(
    (section) =>
      section.title.toLowerCase() !== SectionTitles.PaymentSchedule &&
      section.title.toLowerCase() !== SectionTitles.FAQs &&
      section.title.toLowerCase() !== SectionTitles.Contact
  );

  const faqAndContactSection = planItem?.sections?.filter(
    (section) =>
      section.title.toLowerCase() === SectionTitles.FAQs ||
      section.title.toLowerCase() === SectionTitles.Contact
  );

  const paymentSection = planItem?.sections?.find(
    (section) => section.title.toLowerCase() === SectionTitles.PaymentSchedule
  );

  // Do not show the drawer before data has loaded, otherwise the UI shows flash when the data loads
  if (!planItem?.id) return null;
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PlanItemDetailPage
        id={id}
        visible={visible}
        onClose={getOnClose({
          renderPageInsteadOfDrawer,
          isExternalLink,
          linkToLxpMarketplace,
          goToMarketplace,
          onClose,
        })}
        headerImageUrl={planItem?.imageLarge}
        provider={planItem?.provider}
        title={itemTitle}
        itemInfo={itemInfo()}
        metadata={metaData()}
        description={itemShortDescription}
        providerLogoSrc={planItem?.logo}
        renderPageInsteadOfDrawer={renderPageInsteadOfDrawer}
        buttons={buttons}
        isNotFinanceIncentive={isNotFinanceIncentive}>
        <LearnInTabs
          hideTabs={isFeatureFlagOn.SunsetReviews && !isAdmin && !isManager}>
          <TabPane tab={i18n.t(k.GENERIC__OVERVIEW)} key={PageTabs.Overview}>
            <OverviewTabWrapper
              isRedesignOn={isRedesignOn}
              isNotFinanceIncentive={isNotFinanceIncentive}>
              <OverviewTab
                sections={
                  isFeatureFlagOn.ProgramDetailsRedesignPt2
                    ? sections
                    : planItem?.sections || []
                }
                longDescription={itemLongDescription}
                isRedesignOn={isNotFinanceIncentive}
              />
              {isFeatureFlagOn.ProgramDetailsRedesignPt2
                ? faqAndContactSection?.length > 0 && (
                    <OverviewSidePanel
                      isNotFinanceIncentive={isNotFinanceIncentive}
                      skills={planItem?.skills}
                      paymentSection={paymentSection}
                      contactSections={faqAndContactSection.sort((a, b) =>
                        a.title.localeCompare(b.title)
                      )}
                    />
                  )
                : isNotFinanceIncentive && (
                    <SkillsTab skills={planItem?.skills} />
                  )}
            </OverviewTabWrapper>
          </TabPane>
          {!!incentives?.length && (
            <TabPane
              tab={i18n.t(k.INCENTIVE__PLURAL)}
              key={PageTabs.Incentives}>
              <IncentivesTab incentives={incentives} itemType={itemType} />
            </TabPane>
          )}
          {[PlanItemType.Finance, PlanItemType.Time].includes(itemType) &&
            showEligibleProgramTabs && (
              <TabPane
                tab={i18n.t(k.PROGRAM__PLURAL)}
                key={PageTabs.EligiblePrograms}>
                <EligibleProgramsTab
                  incentiveProgramTypes={incentiveProgramTypes}
                  availability={planItem?.availability}
                />
              </TabPane>
            )}
          {itemType === PlanItemType.Program && isAdmin && (
            <TabPane
              tab={i18n.t(k.PARTICIPANT__PLURAL)}
              key={PageTabs.Participants}>
              <ParticipantsTab programId={id} />
            </TabPane>
          )}
          {isFeatureFlagOn.Nominations &&
            itemType === PlanItemType.Program &&
            (isAdmin || isManager) && (
              <TabPane
                tab={i18n.t(k.GENERIC__NOMINATION__PLURAL)}
                key={PageTabs.Nominations}>
                <NominationsTab programId={id} />
              </TabPane>
            )}
          {itemType === PlanItemType.Program &&
            !isFeatureFlagOn.SunsetReviews && (
              <TabPane tab={i18n.t(k.REVIEW__PLURAL)} key={PageTabs.Reviews}>
                <ReviewsTab programId={id} />
              </TabPane>
            )}
        </LearnInTabs>
      </PlanItemDetailPage>
      {showApprovalDrawer && (
        <PreApprovalDrawer
          itemId={id}
          planItemType={itemType}
          currency={planItem?.currency}
          visible={showApprovalDrawer}
          onOk={handleApprovalDrawerOk}
          onCancel={handleApprovalDrawerCancel}
        />
      )}
      {isRemoveProgramModalVisible && (
        <DeleteProgramItemModal
          isVisible={isRemoveProgramModalVisible}
          setIsVisible={setIsRemoveProgramModalVisible}
          programId={id}
          programCurrency={planItem?.currency}
          onOk={handleRemoveProgramModalOk}
          approvedAmount={planItem?.amount}
        />
      )}
      {isRemoveLicensedProgramModalVisible && (
        <DeleteLicensedOrCustomProgramItemModal
          isVisible={isRemoveLicensedProgramModalVisible}
          setIsVisible={setIsRemoveLicensedProgramModalVisible}
          itemId={id}
          onOk={async () => {
            await deletePlanItemByIdMutation.mutateAsync({
              itemId: id,
              itemType: PlanItemType.Program,
            });
          }}
        />
      )}
      {showRequestLicenseDrawer && (
        <PreApprovalDrawer
          itemId={id}
          planItemType={PlanItemType.Program}
          currency={planItem?.currency}
          licenseId={planItem?.licenseId}
          hasLicense={planItem?.isLicenseProgram}
          visible={showRequestLicenseDrawer}
          onOk={() => {
            setShowRequestLicenseDrawer(false);
            refreshData();
          }}
          onCancel={() => {
            setShowRequestLicenseDrawer(false);
          }}
        />
      )}
      {showViewLicenseModal && (
        <LicenseViewerModal
          provider={planItem?.provider}
          licenseId={planItem?.licenseId}
          programId={id}
          onClose={() => {
            setShowViewLicenseModal(false);
          }}
        />
      )}
    </>
  );
}

export default ProgramDetailPageContainer;
