import {BalanceRecurType, FinanceAmountType} from './../enums';
import {EmployeeCurrentFinanceIncentiveDetailsVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: EmployeeCurrentFinanceIncentiveDetailsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getEmployeeCurrentFinanceIncentiveDetailsVMMockData(
  args?: Partial<EmployeeCurrentFinanceIncentiveDetailsVM>
): EmployeeCurrentFinanceIncentiveDetailsVM {
  return {
    amountType: FinanceAmountType.UpTo,
    balance: faker.datatype.number(),
    preApprovalRequired: faker.datatype.boolean(),
    recurrenceType: BalanceRecurType.NotApplicable,
    spendingLimit: faker.datatype.number(),
    totalApproved: faker.datatype.number(),
    totalSpent: faker.datatype.number(),
    userFinanceIncentiveId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const EmployeeCurrentFinanceIncentiveDetailsVMMockData: EmployeeCurrentFinanceIncentiveDetailsVM =
  {
    amountType: FinanceAmountType.UpTo,
    balance: 123,
    preApprovalRequired: true,
    recurrenceType: BalanceRecurType.NotApplicable,
    spendingLimit: 123,
    totalApproved: 123,
    totalSpent: 123,
    userFinanceIncentiveId: 1,
  };
