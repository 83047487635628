import {CSSProperties, ReactNode} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled, {css} from 'styled-components';
import {CloseOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {
  DisplayStatus as DeprecatedDisplayStatus,
  DisplayStatusWarningLevel as DeprecatedDisplayStatusWarningLevel,
} from '@generated/enums';
import translate from '@i18n/translate';
import {formatDateTimeToMMMMDYYYY} from '@utils/timeUtils';
import {UserItemStatusVM} from '@generated/interfaces';
import {DisplayStatus, DisplayStatusWarningLevel} from '@generated/enums';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {Tag} from './Tag/Tag';
import {getTagColor} from './Tag/utils/tag.utils';

/*
|--------------------------------------------------------------------------
| Reusable Learn In Tag
|--------------------------------------------------------------------------
*/

export enum TagStyles {
  Green,
  LightGrey,
  Red,
  Yellow,
  DarkGrey,
}

const Container = styled.span<{tagStyle?: TagStyles}>`
  align-items: center;
  background: ${COLORS.Neutral200};
  border-radius: 4px;
  color: ${COLORS.Neutral900};
  cursor: default;
  display: flex;
  font-family: Roboto;
  font-size: 0.625rem;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.08em;
  line-height: 0.75rem;
  padding: 4px 8px;
  text-transform: uppercase;
  width: fit-content;

  button:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }

  ${({tagStyle}) => {
    switch (tagStyle) {
      case TagStyles.Green:
        return css`
          background: ${COLORS.Green800};
          color: ${COLORS.White};
        `;
      case TagStyles.Red:
        return css`
          background: ${COLORS.Red800};
          color: ${COLORS.White};
        `;
      case TagStyles.Yellow:
        return css`
          background: ${COLORS.Yellow500};
          color: ${COLORS.Neutral900};
        `;
      case TagStyles.LightGrey:
        return css`
          background: ${COLORS.Neutral200};
          color: ${COLORS.Neutral900};
        `;
      case TagStyles.DarkGrey:
        return css`
          background: ${COLORS.Neutral600};
          color: ${COLORS.White};
        `;
    }
  }}
`;

export interface LearnInTagProps {
  tagStyle?: TagStyles;
  dataTestId?: string;
  onClickX?: () => void;
  label: ReactNode;
  style?: CSSProperties;
}

function LearnInTag({
  label,
  dataTestId,
  style,
  onClickX,
  tagStyle,
}: LearnInTagProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  return isFeatureFlagOn.NewTags && !onClickX ? (
    <Tag
      tagColor={getTagColor(tagStyle)}
      label={label}
      dataTestId={dataTestId}
    />
  ) : (
    <Container dataTestId={dataTestId || ''} style={style} tagStyle={tagStyle}>
      {label}
      {!!onClickX && (
        <button
          onClick={onClickX}
          style={{marginLeft: '9px'}}
          aria-label={i18n.t(k.TAG__REMOVE__FORMAT, {item: label})}>
          <CloseOutlined aria-hidden="true" data-testid={`tag-x-${label}`} />
        </button>
      )}
    </Container>
  );
}

export default LearnInTag;

/*
|--------------------------------------------------------------------------
| Container Component for Display of Multiple Default Tags
|--------------------------------------------------------------------------
*/

export const TagsContainer = ({
  tags,
  style,
  tagStyle,
  getTagDataTestId,
  dataTestId,
}: {
  tags: string[];
  style?: React.CSSProperties;
  tagStyle?: React.CSSProperties;
  getTagDataTestId?: (tag: string) => string;
  dataTestId?: string;
}) => {
  if (!tags?.length) return null;
  return (
    <div
      style={{display: 'flex', flexWrap: 'wrap', ...style}}
      data-testid={dataTestId}>
      {tags?.map((tag) => {
        const tagDataTestId = !!getTagDataTestId && getTagDataTestId(tag);
        return (
          <div
            key={tag}
            style={{marginRight: '8px', marginBottom: '8px', ...tagStyle}}>
            <LearnInTag label={tag} dataTestId={tagDataTestId} />
          </div>
        );
      })}
    </div>
  );
};

/*
|--------------------------------------------------------------------------
| Status Tag with label and color set by server
|--------------------------------------------------------------------------
*/

const StatusTagContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getTagThemeFromWarningLevel = (
  displayStatusWarningLevel:
    | DeprecatedDisplayStatusWarningLevel
    | DisplayStatusWarningLevel
): TagStyles => {
  switch (displayStatusWarningLevel) {
    case DeprecatedDisplayStatusWarningLevel.Valid:
      return TagStyles.Green;
    case DeprecatedDisplayStatusWarningLevel.Warning:
      return TagStyles.Yellow;
    case DeprecatedDisplayStatusWarningLevel.Error:
      return TagStyles.Red;
    case DeprecatedDisplayStatusWarningLevel.Default:
      return TagStyles.LightGrey;
    case DeprecatedDisplayStatusWarningLevel.Unknown:
    default:
      return TagStyles.DarkGrey;
  }
};

const getTranslatedLabel = (
  displayStatus: DeprecatedDisplayStatus | DisplayStatus
): string => {
  switch (displayStatus) {
    case DeprecatedDisplayStatus.Approved:
      return translate('STATUS__APPROVED');
    case DeprecatedDisplayStatus.Declined:
      return translate('STATUS__DECLINED');
    case DeprecatedDisplayStatus['Awaiting Approval']:
    case DisplayStatus.AwaitingApproval:
      return translate('STATUS__AWAITING_APPROVAL');
    case DeprecatedDisplayStatus.Completed:
      return translate('STATUS__COMPLETED');
    case DeprecatedDisplayStatus['Marked As Completed']:
    case DisplayStatus.MarkedAsCompleted:
      return translate('STATUS__MARK_AS_COMPLETED');
    case DeprecatedDisplayStatus.Cancelled:
      return translate('STATUS__CANCELLED');
    case DeprecatedDisplayStatus.Skipped:
      return translate('STATUS__SKIPPED');
    case DeprecatedDisplayStatus.RsvpedYes:
      return translate('EVENT__RSVP_YES');
    case DeprecatedDisplayStatus['In Progress']:
    case DisplayStatus.InProgress:
      return translate('STATUS__IN_PROGRESS');
    case DeprecatedDisplayStatus['Not Started']:
    case DisplayStatus.NotStarted:
      return translate('STATUS__NOT_STARTED');
    case DeprecatedDisplayStatus.RsvpedNo:
      return translate('EVENT__RSVP_NO');
    case DeprecatedDisplayStatus.RsvpedNone:
      return translate('EVENT__RSVP_DID_NOT');
    case DisplayStatus.FundsRequested:
      return translate('STATUS__FUNDS_REQUESTED');
    case DisplayStatus.NotCompleted:
      return translate('STATUS__NOT_COMPLETED');
    case DeprecatedDisplayStatus.Unknown:
    default:
      return translate('STATUS__UNKNOWN');
  }
};

const StatusMetadata = styled.div`
  margin-left: 12px;
  white-space: nowrap;
`;

export const StatusTag = ({
  displayStatus,
  displayStatusWarningLevel,
  completedOn,
  skippedOn,
  completedPercent,
}: {
  displayStatus: DeprecatedDisplayStatus | DisplayStatus;
  displayStatusWarningLevel:
    | DeprecatedDisplayStatusWarningLevel
    | DisplayStatusWarningLevel;
  completedOn?: string;
  skippedOn?: string;
  completedPercent?: number;
}) => {
  const isCompleted = [
    DeprecatedDisplayStatus.Completed,
    DeprecatedDisplayStatus['Marked As Completed'],
    DisplayStatus.Completed,
    DisplayStatus.MarkedAsCompleted,
  ].includes(displayStatus);

  return (
    <StatusTagContainer>
      <LearnInTag
        label={getTranslatedLabel(displayStatus)}
        tagStyle={getTagThemeFromWarningLevel(displayStatusWarningLevel)}
      />
      {isCompleted && completedOn && (
        <StatusMetadata data-testid="completed-on-date">
          Completed on {formatDateTimeToMMMMDYYYY(completedOn)}
        </StatusMetadata>
      )}
      {displayStatus === DisplayStatus.Skipped && skippedOn && (
        <StatusMetadata data-testid="skipped-on-date">
          Skipped on {formatDateTimeToMMMMDYYYY(skippedOn)}
        </StatusMetadata>
      )}
      {displayStatus === DisplayStatus.InProgress &&
        typeof completedPercent === 'number' && (
          <StatusMetadata data-testid="completed-percent">
            {Math.ceil(completedPercent)}% {i18n.t(k.STATUS__COMPLETED)}
          </StatusMetadata>
        )}
    </StatusTagContainer>
  );
};

export const UserItemStatusTag = ({
  status,
  completedOn,
  skippedOn,
}: {
  status: UserItemStatusVM;
  completedOn?: string;
  skippedOn?: string;
}) => {
  return (
    <StatusTag completedOn={completedOn} skippedOn={skippedOn} {...status} />
  );
};
