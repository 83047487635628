import {ReactNode} from 'react';
import styled from 'styled-components';

import {WrapperStyles} from './WrapperTheme';
import {WrapperTags} from './WrapperEnums';

const StyledWrapper = styled.div`
  padding: ${(props) => props.padding || WrapperStyles[props.tag].padding};
  background: ${(props) =>
    props.background || WrapperStyles[props.tag].background};
  border-radius: ${(props) =>
    props.borderRadius || WrapperStyles[props.tag].borderRadius};
  display: ${(props) => props.display || WrapperStyles[props.tag].display};
  justify-content: ${(props) =>
    props.justifyContent || WrapperStyles[props.tag].justifyContent};
  align-items: ${(props) =>
    props.alignItems || WrapperStyles[props.tag].alignItems};
  flex-direction: ${(props) =>
    props.flexDirection || WrapperStyles[props.tag].flexDirection};
  border: ${(props) => props.border || WrapperStyles[props.tag].border};
  cursor: ${(props) => props.cursor || WrapperStyles[props.tag].cursor};
  width: ${(props) => props.width || WrapperStyles[props.tag].width};
`;

interface Props {
  children: ReactNode;
  tag: WrapperTags;
  padding?: string;
  background?: string;
  borderRadius?: string;
  border?: string;
  cursor?: string;
  width?: string;
}

/**
 * * This is a standard wrapper that should be used in all places where wrappers are needed. You may need to
 * add new tags to this wrapper if you are implementing a new type of wrapper however, this should cover most
 * use cases. You may pass in props to override styling if necessary.
 */
export function LearnInWrapper({children, ...rest}: Props) {
  return <StyledWrapper {...rest}>{children}</StyledWrapper>;
}
