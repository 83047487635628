import {i18n, k} from '@i18n/translate';
import {ReactElement} from 'react';
import styled from 'styled-components';
import {Avatar, Divider} from 'antd';
import {getAvatarColorwaysStyles} from '@utils/color';
import {getInitialsOfName} from '@components/providers/utils';
import {RichTextEditor} from '@components/reusable/RichTextEditor';
import {formatUrls} from '@utils/stringUtils';
import {
  formatDateTime,
  DATE_FORMAT,
  parseToLocalMoment,
} from '@utils/timeUtils';
import {COLORS} from '@utils/constants';
import {MessageDto} from '@generated/interfaces';
import {ContentDueDateStatus} from '@blocks/CustomProgramUserDrawer/ContentCard';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {SubmissionForm} from './SubmissionForm';
import {DeleteOutlined} from '@ant-design/icons';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';

const Name = styled.p`
  color: ${COLORS.Neutral950};
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 0px;
`;

const DateTime = styled.p`
  color: ${COLORS.Neutral600};
  text-align: center;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem;
  margin-bottom: 0px;
`;

const DrawerBody = styled.div`
  margin: 0px 24px;
  border-radius: 8px;
  overflow-y: scroll;
  flex-grow: 1;
  overflow-y: scroll;
`;

const DrawerBodyTitle = styled.div`
  justify-content: space-between;
  padding: 0px 9px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: ${COLORS.Neutral900};
  & p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25rem;
    color: ${COLORS.Neutral900};
  }
  .ql-container.ql-disabled .ql-tooltip {
    display: none;
  }
`;

const DueDateInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 25px 4px 25px;
  gap: 8px;
`;

const MessageContainer = styled.div`
  padding: 5px;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 0px;
  height: 36px;
`;

const Message = styled.p`
  font-size: 1rem;
  line-height: 1.25rem;
`;

const Prompt = styled.div`
  max-height: 100px;
  overflow-y: scroll;
`;

const DeleteButton = styled.div`
  margin-left: auto;
`;

export interface DiscussionDrawerProps {
  title: string;
  description: string;
  setShowDrawer: (value: boolean) => void;
  visible: boolean;
  dueDate?: string;
  messages: MessageDto[];
  submissionForm?: ReactElement<typeof SubmissionForm>;
  handleDeleteSubmission: (id: string) => void;
  userId: number;
  userIsAdmin: boolean;
}
export function DiscussionDrawer({
  title,
  dueDate,
  description,
  setShowDrawer,
  visible,
  messages,
  submissionForm,
  handleDeleteSubmission,
  userId,
  userIsAdmin,
}: DiscussionDrawerProps) {
  const formattedDescription = formatUrls(description);
  const formattedDueDate = parseToLocalMoment(dueDate)?.format(
    DATE_FORMAT.MONTH_LONG_DAY_YEAR
  );
  const isCompleted = false;
  return (
    <LearnInDrawer
      title={title}
      placement="right"
      closeButtonPlacement="right"
      onClose={() => setShowDrawer(false)}
      size="large"
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        backgroundColor: COLORS.White,
      }}
      visible={visible}>
      <Prompt>
        {dueDate && (
          <DueDateInfo>
            <ContentDueDateStatus
              dueDateUtc={dueDate}
              completed={isCompleted}
            />{' '}
            <p style={{fontSize: '1rem', fontWeight: '500'}}>
              {formattedDueDate}
            </p>
          </DueDateInfo>
        )}
        <DrawerBodyTitle style={{marginTop: dueDate ? '-20px' : '0px'}}>
          <RichTextEditor
            value={formattedDescription}
            readOnly
            theme="bubble"
          />
        </DrawerBodyTitle>
      </Prompt>
      <Divider style={{margin: '16px auto'}} />
      <DrawerBody>
        {messages.map((message) => {
          const dateTime = new Date(message?.createdOn);
          return message ? (
            <MessageContainer tabIndex={0} key={message.id}>
              <MessageWrapper>
                <Avatar
                  style={{
                    ...getAvatarColorwaysStyles(message.author?.name.length),
                    flexShrink: 0,
                    fontWeight: '700',
                  }}>
                  {getInitialsOfName(message.author?.name)}
                </Avatar>
                <Name>{message.author?.name}</Name>
                <div style={{display: 'flex', gap: '4px'}}>
                  <DateTime>
                    {formatDateTime(dateTime, DATE_FORMAT.MONTH_DAY_SHORT)}
                  </DateTime>
                  <DateTime>
                    {formatDateTime(dateTime, DATE_FORMAT.TIME_12_HR)}
                  </DateTime>
                </div>
                <DeleteButton>
                  {(message.author?.id === userId || userIsAdmin) && (
                    <LearnInButton
                      style={{fontWeight: 400}}
                      icon={<DeleteOutlined />}
                      tag={ButtonTags.DestructiveRemovedBorder}
                      onClick={() => handleDeleteSubmission(message.id)}>
                      {i18n.t(k.CTA__DELETE)}
                    </LearnInButton>
                  )}
                </DeleteButton>
              </MessageWrapper>
              <Message>{message.content}</Message>
            </MessageContainer>
          ) : null;
        })}
      </DrawerBody>
      <div style={{padding: '16px 24px', height: '200px'}}>
        {submissionForm}
      </div>
    </LearnInDrawer>
  );
}
