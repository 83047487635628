import './FinanceReportList.scss';
import {i18n, k} from '@i18n/translate';
import {formatCurrency} from '@utils/moneyUtils';
import {CurrencyCode} from '@generated/enums';
import moment from 'moment';
import {ViewProgramLink} from '../ViewProgramLink';
import LearnInTable, {
  dateTimeSorter,
  numberSorter,
  stringSorter,
  TableCell,
} from '@components/reusable/LearnInTable';
import {ReimbursementsReportResult} from '@models/serverModels';
import {DATE_FORMAT} from '@utils/timeUtils';

interface FinanceReportListProps {
  data: ReimbursementsReportResult[];
  exchangeRate: number;
  usersCurrency: CurrencyCode;
}

const FinanceReportList = ({
  data,
  exchangeRate,
  usersCurrency,
}: FinanceReportListProps) => {
  const financeReportColumns = [
    {
      title: i18n.t(k.EMPLOYEE__NAME),
      dataIndex: 'employeeName',
      key: 'employeeName',
      render: (employee: any) => employee,
      sorter: stringSorter('employeeName'),
    },
    {
      title: i18n.t(k.INCENTIVE),
      dataIndex: 'incentive',
      key: 'incentive',
      render: (incentive: any) => incentive,
      sorter: stringSorter('incentive'),
    },
    {
      title: i18n.t(k.MONEY__AMOUNT_PAID_OUT),
      dataIndex: 'amountPaidOut',
      key: 'amountPaidOut',
      render: (amountPaidOut: any) => {
        return `${formatCurrency(amountPaidOut, usersCurrency, exchangeRate)}`;
      },
      sorter: numberSorter('amountPaidOut'),
    },
    {
      title: i18n.t(k.DATE),
      dataIndex: 'date',
      key: 'date',
      render: (date: any) => {
        const formattedDate = moment(date).format(DATE_FORMAT.MONTH_DAY_YEAR);
        return formattedDate;
      },
      sorter: dateTimeSorter('date'),
    },
    {
      title: i18n.t(k.EMPLOYEE__LINE_OF_BUSINESS),
      dataIndex: 'employeeDepartment',
      key: 'employeeDepartment',
      sorter: stringSorter('employeeDepartment'),
    },
    {
      title: i18n.t(k.PROGRAM),
      dataIndex: 'programTitle',
      render: (_: any, row: any) => {
        return (
          <TableCell>
            <ViewProgramLink id={row.id}>{row.programTitle}</ViewProgramLink>
          </TableCell>
        );
      },
      key: 'programTitle',
      sorter: stringSorter('programTitle'),
    },
    {
      title: i18n.t(k.PROGRAM__TYPE),
      dataIndex: 'programType',
      key: 'programType',
      sorter: stringSorter('programType'),
    },
    {
      title: i18n.t(k.PROVIDER),
      dataIndex: 'providerName',
      key: 'providerName',
      sorter: stringSorter('providerName'),
    },
  ];

  return (
    <LearnInTable
      pagination={false}
      dataSource={data && !!data.length ? data : []}
      columns={financeReportColumns}
    />
  );
};

export default FinanceReportList;
