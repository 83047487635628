import {UserProgramStatus} from './../enums';
import {PlanProgramStatusUpdate} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: PlanProgramStatusUpdate

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPlanProgramStatusUpdateMockData(
  args?: Partial<PlanProgramStatusUpdate>
): PlanProgramStatusUpdate {
  return {
    status: UserProgramStatus.Planned,
    userPlanItemId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PlanProgramStatusUpdateMockData: PlanProgramStatusUpdate = {
  status: UserProgramStatus.Planned,
  userPlanItemId: 1,
};
