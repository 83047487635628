import {DisplayStatus, DisplayStatusWarningLevel} from './../enums';
import {UserItemStatusVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserItemStatusVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserItemStatusVMMockData(
  args?: Partial<UserItemStatusVM>
): UserItemStatusVM {
  return {
    completedOn: String(faker.date.past()), // optional
    completedPercent: faker.datatype.number(), // optional
    displayStatus: DisplayStatus.Approved,
    displayStatusWarningLevel: DisplayStatusWarningLevel.Valid,
    isDone: faker.datatype.boolean(),
    skippedOn: String(faker.date.past()), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserItemStatusVMMockData: UserItemStatusVM = {
  completedOn: '2023-03-29T22:17:19.738072', // optional
  completedPercent: 123, // optional
  displayStatus: DisplayStatus.Approved,
  displayStatusWarningLevel: DisplayStatusWarningLevel.Valid,
  isDone: true,
  skippedOn: '2023-03-29T22:17:19.738072', // optional
};
