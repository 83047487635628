import {ArrowRightOutlined} from '@ant-design/icons';
import Icon from '@blocks/Icon/Icon';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  ColumnAlignment,
  DefaultSortOrder,
  IColumn,
} from '@components/reusable/LearnInTable';
import {CurrencyCode} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {formatCurrency} from '@utils/moneyUtils';
import {DATE_FORMAT} from '@utils/timeUtils';
import moment from 'moment';
import {NavigateFunction} from 'react-router-dom';

export const useAdminUsersGetColumns = ({
  currency,
  exchangeRate = 1,
  isCostCenterOn,
  hasPlb,
  hasPrepayment,
  navigate,
}: {
  currency: CurrencyCode;
  exchangeRate: number;
  isCostCenterOn: boolean;
  hasPlb: boolean;
  hasPrepayment: boolean;
  navigate: NavigateFunction;
}): IColumn[] => [
  {
    title: <>{i18n.t(k.GENERIC__USER_NAME)}</>,
    dataIndex: 'userName',
    key: 'userName',
    sorter: true,
    defaultSortOrder: DefaultSortOrder.Ascend,
    width: '25%',
    render: (userName) => userName || '-',
  },
  {
    title: <>{i18n.t(k.GENERIC__MANAGER_NAME)}</>,
    dataIndex: 'managerName',
    key: 'managerName',
    sorter: true,
    width: '25%',
    render: (managerName) => managerName || '-',
  },
  isCostCenterOn && {
    title: <>{i18n.t(k.COST_CENTER)}</>,
    dataIndex: 'costCenter',
    key: 'costCenter',
    sorter: true,
    render: (costCenter) => costCenter || '-',
  },
  {
    title: <>{i18n.t(k.GENERIC__LAST_LOGIN)}</>,
    dataIndex: 'lastLogin',
    key: 'lastLogin',
    sorter: true,
    render: (requestedDate) =>
      requestedDate
        ? moment(requestedDate).format(DATE_FORMAT.MONTH_DAY_YEAR)
        : '-',
  },
  hasPlb && {
    title: <>{i18n.t(k.GENERIC__TOTAL_BUDGET)}</>,
    dataIndex: 'totalBudget',
    key: 'totalBudget',
    align: ColumnAlignment.Right,
    sorter: false,
    render: (totalBudget) => {
      return totalBudget ? (
        <>
          {formatCurrency(totalBudget, currency, exchangeRate, {
            decimal: true,
          })}
        </>
      ) : (
        '-'
      );
    },
  },
  hasPlb && {
    title: <>{i18n.t(k.APPROVAL__APPROVED_AMOUNT)}</>,
    dataIndex: 'approvedAmount',
    key: 'approvedAmount',
    align: ColumnAlignment.Right,
    sorter: false,
    render: (approvedAmount) => {
      return approvedAmount ? (
        <>
          {formatCurrency(approvedAmount, currency, exchangeRate, {
            decimal: true,
          })}
        </>
      ) : (
        '-'
      );
    },
  },
  hasPrepayment && {
    title: <>{i18n.t(k.MONEY__AMOUNT_SPENT)}</>,
    dataIndex: 'spentAmount',
    key: 'spentAmount',
    align: ColumnAlignment.Right,
    sorter: false,
    render: (spentAmount) => {
      return spentAmount ? (
        <>
          {formatCurrency(spentAmount, currency, exchangeRate, {
            decimal: true,
          })}
        </>
      ) : (
        '-'
      );
    },
  },
  {
    title: '',
    dataIndex: '',
    fixed: 'right',
    key: '',
    render: (_, row) => (
      <LearnInButton
        tag={ButtonTags.Link}
        color={COLORS.Blue800}
        hoverUnderline
        className="btn-admin-approval-view-details"
        data-cy="viewDetails"
        onClick={(e) => {
          e.stopPropagation();
          navigate(`${row.userCompanyId}`);
        }}>
        {i18n.t(k.CTA__VIEW)}
        <Icon icon={<ArrowRightOutlined aria-hidden="true" />} />
      </LearnInButton>
    ),
    sorter: null,
  },
];

export const useAdminUsersGetCounts = (
  current: number,
  totalResults: number,
  pageCount: number = 100
) => ({
  start: current === 1 ? 1 : current * pageCount - (pageCount - 1),
  end: totalResults < current * pageCount ? totalResults : current * pageCount,
  total: totalResults,
});
