import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {CustomProgramAdminSectionVM} from '@models/serverModels';

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: #1b1c23;
  margin-bottom: 16px;
`;

const SelectLabel = styled.label`
  font-weight: normal;
  font-size: 1rem;
  color: #1b1c23;
  display: block;
  margin-bottom: 16px;
`;

interface IMoveContentToNewSectionModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (sectionId: number) => void;
  sections: Pick<CustomProgramAdminSectionVM, 'id' | 'title'>[];
}
function MoveContentToNewSectionModal({
  visible,
  onCancel,
  onOk,
  sections,
}: IMoveContentToNewSectionModalProps) {
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(
    null
  );
  return (
    <LearnInModal
      width={400}
      visible={visible}
      tag={ModalTags.MoneyIncentive}
      okButtonChild={i18n.t(k.CONTENT__MOVE)}
      buttonDisabled={!selectedSectionId || !sections?.length}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      onCancel={() => {
        onCancel();
        setSelectedSectionId(null);
      }}
      onOk={() => {
        onOk(selectedSectionId);
        setSelectedSectionId(null);
      }}
      ariaLabel={i18n.t(k.SECTION__MOVE_TO)}>
      <div>
        <ModalTitle>{i18n.t(k.SECTION__MOVE_TO)}</ModalTitle>
        {!!sections?.length ? (
          <>
            <SelectLabel>{i18n.t(k.LEVEL__MOVE_TO_SECTION)}</SelectLabel>
            <LearnInSelect
              placeholder={i18n.t(k.SECTION__SELECT)}
              onChange={(value) => setSelectedSectionId(value)}
              value={selectedSectionId}
              style={{width: '100%'}}>
              {sections?.map(({id, title}) => {
                return (
                  <LearnInSelectOption
                    key={id}
                    data-testid={`section-option-${id}`}
                    value={id}>
                    {title}
                  </LearnInSelectOption>
                );
              })}
            </LearnInSelect>
          </>
        ) : (
          <div>{i18n.t(k.LEVEL__NO_MOVE_TO_SECTION)}</div>
        )}
      </div>
    </LearnInModal>
  );
}

export default MoveContentToNewSectionModal;
