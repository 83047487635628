// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/AcademyStepType.cs

enum AcademyStepType {
  MarketplaceProgram,
  CustomProgram,
  TextContent,
  ProjectContent,
  EventContent,
  Discussion,
  LxpContent,
}

export default AcademyStepType;
