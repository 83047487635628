import {ApprovalStatus} from './../enums';
import {UserPlanApprovalResult} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserPlanApprovalResult

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanApprovalResultMockData(
  args?: Partial<UserPlanApprovalResult>
): UserPlanApprovalResult {
  return {
    amount: faker.datatype.number(),
    amountType: String(faker.datatype.number()),
    application: faker.word.noun(),
    approvalGuidelines: faker.word.noun(),
    approvalNotes: faker.word.noun(),
    costCenter: faker.word.noun(), // optional
    id: faker.datatype.number(),
    initialAdminApprovalId: faker.datatype.number(), // optional
    itemId: faker.datatype.number(),
    linkedUserRequestApprovalId: faker.datatype.number(), // optional
    manager: faker.word.noun(),
    managerId: faker.datatype.number(), // optional
    rejectedById: String(faker.datatype.number()), // optional
    rejectionNotes: faker.word.noun(),
    remainingBalance: faker.datatype.number(), // optional
    requestedBy: faker.word.noun(),
    requestedById: faker.datatype.number(),
    requestedDate: String(faker.date.past()),
    status: ApprovalStatus.Pending,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanApprovalResultMockData: UserPlanApprovalResult = {
  amount: 5,
  amountType: '5',
  application: 'string',
  approvalGuidelines: 'string',
  approvalNotes: 'string',
  costCenter: 'string', // optional
  id: 1,
  initialAdminApprovalId: 1, // optional
  itemId: 1,
  linkedUserRequestApprovalId: 1, // optional
  manager: 'string',
  managerId: 1, // optional
  rejectedById: '1', // optional
  rejectionNotes: 'string',
  remainingBalance: 123, // optional
  requestedBy: 'string',
  requestedById: 1,
  requestedDate: '2023-03-29T22:17:19.738072',
  status: ApprovalStatus.Pending,
};
