import {i18n, k} from '@i18n/translate';
import {useState} from 'react';

const URL_PATTERN =
  /^(https?:\/\/)?([\w\d.-]+)\.([a-z]{2,})(:\d{1,5})?\/?([\w\d-._~:/?#[\]@!$&'()*+,;=%]*)$/i;

export function useProjectSubmissionValidation() {
  const [errors, setErrors] = useState({
    link: '',
    text: '',
  });

  const validateText = (value: string): boolean => {
    setErrors({
      ...errors,
      text: !value ? i18n.t(k.VALIDATION__PROJECT__ERROR_EMPTY_RESPONSE) : '',
    });
    return !!value;
  };

  const validateLink = (linkValue: string) => {
    const isValidUrl = (url: string): boolean => {
      let tempUrl = url;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        tempUrl = `https://${url}`;
      }

      try {
        new URL(tempUrl);
        return URL_PATTERN.test(tempUrl);
      } catch (error) {
        return false;
      }
    };

    let errorMessage: string;

    if (!linkValue) {
      errorMessage = i18n.t(k.VALIDATION__PROJECT__ERROR_EMPTY_URL);
    } else if (!isValidUrl(linkValue)) {
      errorMessage = i18n.t(k.VALIDATION__URL_INVALID__FORMAT);
    } else {
      errorMessage = '';
    }

    setErrors({
      ...errors,
      link: errorMessage,
    });

    return !errorMessage;
  };

  return {errors, validateLink, validateText};
}
