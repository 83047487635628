import {CreditCardOverviewVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: CreditCardOverviewVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCreditCardOverviewVMMockData(
  args?: Partial<CreditCardOverviewVM>
): CreditCardOverviewVM {
  return {
    balance: faker.datatype.number(),
    cardId: faker.datatype.number(),
    last4: faker.finance.pin(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CreditCardOverviewVMMockData: CreditCardOverviewVM = {
  balance: 123,
  cardId: 1,
  last4: '5678',
};
