import {
  CurrencyCode,
  LicenseRedemptionType,
  LicenseStatus,
  LicenseUsageType,
  VoucherAssignMode,
} from './../enums';
import {UpdateLicenseRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdateLicenseRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdateLicenseRequestMockData(
  args?: Partial<UpdateLicenseRequest>
): UpdateLicenseRequest {
  return {
    approvalGuideline: faker.word.noun(), // optional
    currency: CurrencyCode.USD,
    internalCost: faker.datatype.number(),
    licenseDuration: faker.datatype.number(),
    redemptionGuideline: faker.word.noun(),
    redemptionType: LicenseRedemptionType.Voucher,
    requireAdminApproval: faker.datatype.boolean(),
    requireManagerApproval: faker.datatype.boolean(),
    status: LicenseStatus.Draft,
    type: LicenseUsageType.AllPrograms,
    voucherAssignMode: VoucherAssignMode.NoCodes,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdateLicenseRequestMockData: UpdateLicenseRequest = {
  approvalGuideline: 'string', // optional
  currency: CurrencyCode.USD,
  internalCost: 123,
  licenseDuration: 123,
  redemptionGuideline: 'string',
  redemptionType: LicenseRedemptionType.Voucher,
  requireAdminApproval: true,
  requireManagerApproval: true,
  status: LicenseStatus.Draft,
  type: LicenseUsageType.AllPrograms,
  voucherAssignMode: VoucherAssignMode.NoCodes,
};
