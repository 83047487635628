import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {OVERVIEW_MAX_WIDTH, OVERVIEW_SIDE_CARD_WIDTH} from './OverviewTab';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const SectionTitle = styled.div`
  align-items: center;
  color: ${COLORS.Neutral950};
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  gap: 8px;
  line-height: 1.5rem;
  margin-bottom: 25px;
  svg {
    background: ${COLORS.Neutral50};
    fill: ${COLORS.Red500};
    height: 16px;
    width: 16px;
  }
`;

const SkillBadge = styled.div`
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  background-color: ${COLORS.Neutral200};
  padding: 3px 12px 3px 12px;
  width: max-content;
  border-radius: 4px;
  margin-bottom: 8px;
`;

const StyledLearnInContainer = styled(LearnInContainer)`
  max-width: ${OVERVIEW_SIDE_CARD_WIDTH}px;
  @media (max-width: ${OVERVIEW_MAX_WIDTH}px) {
    max-width: 100%;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IOverviewTabProps {
  skills: string[];
}

function SkillsTab({skills}: IOverviewTabProps) {
  return (
    <Container>
      <StyledLearnInContainer
        flexDirection={'column'}
        tag={ContainerTags.CommonCard}>
        <SectionTitle>{i18n.t(k.PROGRAM_FINDER__SKILLS)}</SectionTitle>
        {skills?.map((skill, idx) => {
          return (
            <div key={idx}>
              <SkillBadge>{skill}</SkillBadge>
            </div>
          );
        })}
      </StyledLearnInContainer>
    </Container>
  );
}

export default SkillsTab;
