import React from 'react';
import styled from 'styled-components';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {pxToRem} from '@utils/styleMixins';
import {Avatar} from 'antd';
import {COLORS} from '@utils/constants';
import {getAvatarColorwaysStyles} from '@utils/color';
import {Breakpoints} from '@utils/responsiveUtils';
import translate from '@i18n/translate';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  background: white;
  border-radius: 8px;
  padding: 16px;
`;

const BackgroundImgContainer = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const Dates = styled.p`
  font-weight: 500;
  font-size: ${pxToRem(16)};
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Participants = styled.div`
  display: flex;
`;

const Title = styled.h2`
  font-weight: 700;
  font-size: ${pxToRem(24)};
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const HeaderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  max-height: 210px;
  border-radius: 4px;
  @media (max-width: ${Breakpoints.Tablet}px) {
    max-height: 180px;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface AvailableCohortCardProps {
  title: string | undefined;
  startDate: string;
  endDate: string;
  onClickPreview: () => void;
  participantInitials: string[];
  additionalParticipantsCount: number;
  headerImgSrc: string | undefined;
}

function AvailableCohortCard({
  startDate,
  endDate,
  onClickPreview,
  participantInitials,
  additionalParticipantsCount,
  headerImgSrc,
  title,
}: AvailableCohortCardProps) {
  return (
    <Container>
      <BackgroundImgContainer>
        <Title>{title || `${startDate} - ${endDate}`}</Title>
        <HeaderImage src={headerImgSrc} />
      </BackgroundImgContainer>
      <Dates>
        {startDate} - {endDate}
      </Dates>
      <Footer>
        <Participants>
          {participantInitials.map((initials, idx) => {
            const key = String(idx) + initials;
            return (
              <Avatar
                key={key}
                style={{
                  ...getAvatarColorwaysStyles(initials?.[0].charCodeAt(0)),
                  verticalAlign: 'middle',
                  marginRight: `-8px`,
                  zIndex: 20 - idx,
                }}
                size="large"
                gap={1}>
                {initials}
              </Avatar>
            );
          })}
          {additionalParticipantsCount ? (
            <Avatar
              style={{
                backgroundColor: COLORS.Neutral200,
                color: COLORS.Neutral900,
                verticalAlign: 'middle',
              }}
              size="large"
              gap={1}>
              + {additionalParticipantsCount}
            </Avatar>
          ) : null}
        </Participants>
        <div>
          <LearnInButton tag={ButtonTags.Secondary} onClick={onClickPreview}>
            {translate('COHORT__PREVIEW_BUTTON_LABEL')}
          </LearnInButton>
        </div>
      </Footer>
    </Container>
  );
}

export default AvailableCohortCard;
