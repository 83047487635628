import {useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import styled from 'styled-components';
import NoDataText from '@blocks/NoDataText';
import ContributorsTable from '@blocks/Permissions/ContributorsTable';
import AddContributorsModal, {
  AddContributorsModalFormInstance,
} from '@blocks/Permissions/AddContributorsModal';
import AddContributorsButton from '@blocks/Permissions/AddContributorsButton';
import {ContributorPermissionType} from '@models/clientEnums';
import {CustomizableLearningCategory} from '@generated/enums';
import {useParams} from 'react-router-dom';
import {
  useAddAcademyUserPermission,
  useDeleteAcademyUserPermission,
  useUpdateAcademyUserPermission,
} from '@hooks/apiEndpoints/academy/mutations';
import debounce from 'lodash/debounce';
import {useUserSearchQuery} from '@hooks/apiEndpoints/user/queries';
import {stringSorter} from '@components/reusable/LearnInTable';
import {AcademyUserPermissionPayload} from '@models/serverModels';
import {SIDE_NAV_WIDTH} from '@utils/constants';
import learnInConfirm from '@components/reusable/learnInConfirm';
import {useAcademyPermissionVMsQuery} from '@hooks/apiEndpoints/permission/queries';
import {useUserQuery, useAcademiesUserPermissionsQuery} from '@generated/hooks';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {Helmet} from 'react-helmet-async';

const Container = styled.div`
  padding: 0 24px 72px ${SIDE_NAV_WIDTH + 24}px;
`;

const AddPeopleButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

function PermissionsScreen({title}: {title?: string}) {
  const [addContributorsModalVisible, setAddContributorsModalVisible] =
    useState(false);
  const [
    disableAddContributorsSubmitButton,
    setDisableAddContributorsSubmitButton,
  ] = useState(true);
  const [form] = Form.useForm<AddContributorsModalFormInstance>();
  const [userSearchName, setUserSearchName] = useState('');
  const openAddContributorsModal = () => setAddContributorsModalVisible(true);
  const params = useParams();
  const academyId = parseInt(params.academyId);
  const {isFeatureFlagOn} = useFeatureFlags();

  // get contributors table dataSource
  const {
    data: contributors,
    invalidateExact: invalidateContributors,
    isSuccess: contributorsLoadedSuccessfully,
  } = useAcademiesUserPermissionsQuery({academyId});

  const {invalidateExact: invalidateUserAcademyPermissions} =
    useAcademyPermissionVMsQuery(null, {
      enabled: false,
    });

  const onSuccessUpdatePermissions = () => {
    invalidateContributors();
    invalidateUserAcademyPermissions();
  };

  // update custom program user permissions
  const updateAcademyUserPermission = useUpdateAcademyUserPermission({
    onSuccess: onSuccessUpdatePermissions,
  });
  // delete custom program contributor user permissions
  const deleteAcademyUserPermission = useDeleteAcademyUserPermission({
    onSuccess: onSuccessUpdatePermissions,
  });
  const {data: user} = useUserQuery(null);
  // get company users for add contributors users search
  const {data: userSearchResults, isLoading: isUserSearchLoading} =
    useUserSearchQuery(
      {name: userSearchName, companyId: user?.companyId},
      {
        enabled: !!userSearchName,
      }
    );

  // add contributors
  const addAcademyUserPermission = useAddAcademyUserPermission({
    onSuccess: onSuccessUpdatePermissions,
  });

  const showNoContributorsScreen =
    contributorsLoadedSuccessfully && !contributors?.length;

  const handleSubmit = async () => {
    form.validateFields().then(async (payload) => {
      const userCompanyIds = payload.userCompanyIds.map(
        (id: number | string) => {
          if (typeof id === 'string') {
            return parseInt(id.split(',')[2]);
          }
          return id;
        }
      );
      const academyUserPermissionPayload: AcademyUserPermissionPayload = {
        academyId,
        canEdit: payload.permissionGroup.includes(
          ContributorPermissionType.canEdit
        ),
        canReviewProject: payload.permissionGroup.includes(
          ContributorPermissionType.canReviewProject
        ),
        canViewReport: payload.permissionGroup.includes(
          ContributorPermissionType.canViewReport
        ),
        userCompanyIds,
      };
      await addAcademyUserPermission.mutateAsync(academyUserPermissionPayload);
      setAddContributorsModalVisible(false);
      setDisableAddContributorsSubmitButton(true);
      form.resetFields();
    });
  };

  const handleClickDelete = (userId: number) => {
    const contributor = contributors.find(
      (userPermission) => userPermission.userCompanyId === userId
    );
    learnInConfirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__REMOVE_CONTRIBUTOR_YES),
      content: i18n.t(k.PERMISSION__PROMPT__REMOVE__FORMAT, {
        contributor: contributor.name,
      }),
      title: i18n.t(k.CONTRIBUTOR__REMOVE),
      onOk() {
        deleteAcademyUserPermission.mutate({
          academyId,
          userCompanyId: userId,
        });
      },
      onCancel() {
        // intentional pass
      },
    });
  };

  const handleClickUpdate = (
    userId: number,
    permissionType: ContributorPermissionType,
    enabled: boolean
  ) => {
    const contributor = contributors.find(
      (userPermission) => userPermission.userCompanyId === userId
    );

    if (!contributor) {
      return;
    }

    contributor[permissionType] = enabled;
    const {canEdit, canReviewProject, canViewReport, userCompanyId} =
      contributor;
    const allPermissionsRemoved =
      !canEdit && !canReviewProject && !canViewReport;

    // If all permissions removed, delete permission
    if (allPermissionsRemoved) {
      handleClickDelete(userCompanyId);
    } else {
      updateAcademyUserPermission.mutateAsync({
        contentId: academyId,
        ...contributor,
      });
    }
  };

  const handleCompanyUserSearch = debounce((name) => {
    setUserSearchName(name);
  }, 800);

  const handleCloseModal = () => {
    setAddContributorsModalVisible(false);
    form.resetFields();
  };

  const filteredUserSearchResults = useMemo(() => {
    // This map allows searching for existing contributors in O(1) time
    const contributorsById = contributors?.reduce(
      (contributorIds, {userCompanyId}) => {
        contributorIds[userCompanyId] = true;
        return contributorIds;
      },
      {}
    );

    return userSearchResults?.filter(({id}) => !contributorsById[id]);
  }, [userSearchResults, contributors]);

  const handleFieldsChange = (_, allFields) => {
    // Disable submit button until all fields are valid
    setDisableAddContributorsSubmitButton(
      allFields.some(({value}) => !value?.length)
    );
  };

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {showNoContributorsScreen ? (
        <NoDataText
          header={i18n.t(k.PERMISSION__NO_CONTRIBUTORS)}
          subHeader={
            <>
              <>{i18n.t(k.PERMISSION__SELECT_CONTRIBUTORS)}</>
              <AddPeopleButtonContainer>
                <AddContributorsButton
                  onClick={openAddContributorsModal}
                  style={{marginTop: '16px'}}
                />
              </AddPeopleButtonContainer>
            </>
          }
        />
      ) : (
        contributorsLoadedSuccessfully && (
          <ContributorsTable
            dataSource={contributors?.sort(stringSorter('name')) ?? []}
            onClickDelete={handleClickDelete}
            onChangePermission={handleClickUpdate}
            onClickAddContributors={openAddContributorsModal}
            learningCategory={CustomizableLearningCategory.Academy}
            canShowApproveProgramsColumn={
              isFeatureFlagOn.AcademyProgramApprovers &&
              !isFeatureFlagOn.AcademiesCleanUp
            }
          />
        )
      )}
      {addContributorsModalVisible && (
        <AddContributorsModal
          learningCategory={CustomizableLearningCategory.Academy}
          visible={addContributorsModalVisible}
          onClose={handleCloseModal}
          onSubmit={handleSubmit}
          userList={filteredUserSearchResults}
          isSearchLoading={isUserSearchLoading && !!userSearchName}
          onSearchUser={handleCompanyUserSearch}
          disableSubmitButton={disableAddContributorsSubmitButton}
          form={form}
          onFieldsChange={handleFieldsChange}
        />
      )}
    </Container>
  );
}

export default PermissionsScreen;
