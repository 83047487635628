import {Container} from './ActionButtons.styled';
import {ActionButtonsViewProps} from './ActionButtons.types';
import {FinanceType} from '@generated/enums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {i18n, k} from '@i18n/translate';
import {getFinanceDetailPath} from '@utils/ClientPaths';
import {COLORS} from '@utils/constants';

function ActionButtonsView({
  ctaButtonLabel,
  ctaButtonMode,
  financeIncentiveId,
  financeType,
  isLearnInReimbursementsOn,
  onCtaButtonClick,
  preapprovedPlanItems,
  push,
  setShowReimbursement,
  showCtaButton,
}: ActionButtonsViewProps) {
  return (
    <Container>
      {financeType === FinanceType.TuitionReimbursement &&
        isLearnInReimbursementsOn &&
        preapprovedPlanItems?.length > 0 && (
          <LearnInButton
            tag={ButtonTags.Secondary}
            onClick={() => setShowReimbursement(true)}>
            {i18n.t(k.REIMBURSEMENT__SUBMIT)}
          </LearnInButton>
        )}
      {showCtaButton && (
        <LearnInButton
          data-testid="plb-cta-button"
          data-button-mode={ctaButtonMode}
          tag={ButtonTags.Secondary}
          onClick={onCtaButtonClick}>
          {ctaButtonLabel}
        </LearnInButton>
      )}
      <LearnInButton
        data-testid="plb-see-details-button"
        tag={ButtonTags.Link}
        color={COLORS.Blue800}
        className="plb-see-details-button"
        onClick={() => {
          return push(getFinanceDetailPath(financeIncentiveId));
        }}>
        {i18n.t(k.CTA__VIEW__DETAIL__PLURAL)}
      </LearnInButton>
    </Container>
  );
}

export default ActionButtonsView;
