import {useTimezonesQuery} from '@generated/hooks';
import {TimeZoneVM} from '@generated/interfaces';
import {getSimplifiedTimeZoneList, getUserTimeZone} from '@utils/timeUtils';
import {useEffect, useMemo} from 'react';
import {QueryKey, UseQueryOptions} from 'react-query';

const useTimeZones = (
  options: {
    initialTimeZoneId: string | null;
    onTimeZoneDataReady: (data: {
      initialTimeZone: {id: string; displayName: string};
    }) => void;
  },
  queryOptions: UseQueryOptions<
    TimeZoneVM[],
    unknown,
    TimeZoneVM[],
    QueryKey
  > = undefined
) => {
  const {data: timeZones} = useTimezonesQuery(null, queryOptions);
  const simplifiedTimeZones = useMemo(
    () => getSimplifiedTimeZoneList(timeZones),
    [timeZones]
  );

  useEffect(
    /**
     * Matches the stored time zone (or browser's default) value with
     * its corresponding item from the simplified time zone list
     */
    function setDefaultTimeZoneValue() {
      if (!timeZones) {
        return;
      }

      const targetTimeZoneId = options?.initialTimeZoneId || getUserTimeZone();
      const initialTimeZone = timeZones.find(
        (timeZone) => timeZone.id === targetTimeZoneId
      );
      const initialSimplifiedTimeZone = simplifiedTimeZones.find((timeZone) =>
        initialTimeZone?.displayName.includes(timeZone.displayName)
      );

      options?.onTimeZoneDataReady?.({
        initialTimeZone: initialSimplifiedTimeZone,
      });
    },
    [timeZones, simplifiedTimeZones, options.initialTimeZoneId]
  );

  return {timeZones, simplifiedTimeZones} as const;
};

export default useTimeZones;
