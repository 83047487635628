// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/UserFinanceIncentiveStatus.cs

enum UserFinanceIncentiveStatus {
  PendingUse,
  Approved,
  Expired,
  Complete,
  Cancelled,
  ReimbursementSubmitted,
  ReimbursementApproved,
  ReimbursementNeedsReview,
  ReimbursementPaymentPending,
  ReimbursementPaid,
}

export default UserFinanceIncentiveStatus;
