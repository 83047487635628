import {UserMetadata} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserMetadata

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserMetadataMockData(
  args?: Partial<UserMetadata>
): UserMetadata {
  return {
    customAttributes: {},
    latestTransactionId: String(faker.datatype.number()), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserMetadataMockData: UserMetadata = {
  customAttributes: {},
  latestTransactionId: '1', // optional
};
