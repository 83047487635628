import styled from 'styled-components';
import {COLORS} from '@utils/constants';

export const Container = styled.div<{static?: boolean}>`
  bottom: 0;
  left: 0;
  position: ${(props) => (props.static ? 'static' : 'fixed')};
  right: 0;
  top: 0px;
  z-index: 100;
  background: white;
`;

export const HeaderContainer = styled.div`
  display: flex;
  height: 72px;
  justify-content: space-between;
  background: white;
  z-index: 1000;
  border-bottom: 1px solid ${COLORS.Neutral200};
`;

export const HeaderLeftContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const BackButton = styled.button`
  padding: 10px 16px;
  margin: 0 20px;
  @media (max-width: 768px) {
    margin: 0;
  }
`;
