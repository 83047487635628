import {useMemo} from 'react';
import AcademyDrawer from './AcademyDrawer';
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import AcademyContent from '../AcademyContent/AcademyContent.container';
import AcademyReportsPage from '@components/admin/pages/academies/AcademyReports/AcademyReportsPage.container';
import AcademiesProjectsTableWithRoutesContainer from '../AcademyProjects/AcademiesProjectsTableWithRoutes.container';
import {
  AcademyPeoplePageTabs,
  AcademyScreens,
  editAcademyUrl,
  getManagerPermissionsAcademyAdminDrawerRoute,
  userAcademiesRoute,
  UserPaths,
} from '@utils/ClientPaths';
import {useAuth} from '@utils/oidc-auth-wrapper';
import AcademyPeoplePage from '@components/admin/pages/academies/People/AcademyPeoplePage';
import {Maybe} from '@utils/typeUtils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {
  useAcademiesUserCompanyByIdQuery,
  useAddAcademyUserMutation,
} from '@generated/hooks';
import {AcademyUserStatus} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {BasePage} from './AcademyDrawer.styled';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {useBackToLxpLink} from '@hooks/integrated/useBackToLxpLink';
import useClickToJoinAcademy from '@components/ClickToJoin/useClickToJoinAcademy.hook';
import {INTEGRATED_INCOMING_LXP_USER} from '@utils/constants';
import {
  useAcademy,
  useAcademyLevels,
  useManagedAcademies,
} from '../academy.queries';
import {getUrlParameter} from '@utils/urlUtils';

const getPeoplePageActiveTab = (
  pathname: string
): Maybe<AcademyPeoplePageTabs> => {
  const pathnameParts = pathname.split('/');
  if (pathnameParts.includes(AcademyPeoplePageTabs.Leadership)) {
    return AcademyPeoplePageTabs.Leadership;
  }
  if (pathnameParts.includes(AcademyPeoplePageTabs.Participants)) {
    return AcademyPeoplePageTabs.Participants;
  }
};

interface AcademyDrawerContainer {
  onClose: () => void;
  getScreenNavItemUrlPath: (academyId: number, screen: string) => string;
  hideEditAcademyLink?: boolean;
  isActivePlanItem: boolean;
  title?: string;
  transitionOnRender?: boolean;
  prevPath?: string;
}

function AcademyDrawerContainer({
  onClose,
  getScreenNavItemUrlPath,
  hideEditAcademyLink,
  isActivePlanItem,
  title,
  transitionOnRender,
  prevPath = '',
}: AcademyDrawerContainer) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {academyId} = useParams();
  const [searchParams] = useSearchParams();
  const {user, isAdmin} = useAuth();

  // get academy
  const {academy, isLoading: isAcademyLoading} = useAcademy(
    parseInt(academyId)
  );

  // get academy levels
  const {levels} = useAcademyLevels({academyId: parseInt(academyId)});

  const linkToLxpAcademies = useBackToLxpLink(user, {
    type: 'academy',
    resourceId: academyId,
    isInternalLink:
      prevPath === userAcademiesRoute() ||
      searchParams.get('from') === 'user-plan',
  });

  const onCloseWithLxpOption = () => {
    linkToLxpAcademies
      ? (window.location.href = linkToLxpAcademies)
      : navigate(userAcademiesRoute());
  };

  const isIntegrated =
    sessionStorage.getItem(INTEGRATED_INCOMING_LXP_USER) === 'true';

  const userNavOnClose = isIntegrated
    ? null // Hide for integrated
    : onClose || onCloseWithLxpOption;

  const joinId = getUrlParameter(location.href, 'joinId');

  // check if user is already in academy
  const {
    data: enrolledAcademyUser,
    invalidateExact: invalidateEnrolledAcademyUserQuery,
  } = useAcademiesUserCompanyByIdQuery(
    {
      academyId: parseInt(academyId),
      userCompanyId: user?.userCompanyId,
    },
    {
      enabled: isActivePlanItem && !joinId && !!user,
      retry: false,
      onError: () => {
        isActivePlanItem &&
          addUserToAcademy.mutate({
            payload: {
              userCompanyId: user?.userCompanyId,
              academyId: parseInt(academyId),
              status: AcademyUserStatus.InProgress,
            },
          });
      },
    }
  );

  const currentLevelTitle = useMemo(() => {
    if (!pathname.includes(UserPaths.Academies)) return 'Preview';
    return (
      levels?.find(({id}) => id === enrolledAcademyUser?.currentLevelId)
        ?.title || ''
    );
  }, [levels, enrolledAcademyUser, pathname]);

  const addUserToAcademy = useAddAcademyUserMutation({
    onSuccess: invalidateEnrolledAcademyUserQuery,
    onError: () => {
      navigate(UserPaths.Academies);
    },
  });

  useSetPageTitle(title);

  const {data: managedAcademies} = useManagedAcademies();

  const managedAcademy = managedAcademies?.find(
    ({academyId}) => academyId === academy?.id
  );
  const hasEditPermission = isAdmin || managedAcademy?.canEdit;

  const handleClickEdit = () => {
    if (isAdmin) {
      window.open('#' + editAcademyUrl(`${academy.id}`), '_blank').focus();
    } else if (managedAcademy?.canEdit) {
      window
        .open(
          '#' +
            getManagerPermissionsAcademyAdminDrawerRoute(
              academy.id,
              AcademyScreens.setup
            ),
          '_blank'
        )
        .focus();
    }
  };

  const reportsPageBasePath = getScreenNavItemUrlPath(
    parseInt(academyId),
    AcademyScreens.reports
  );

  useClickToJoinAcademy(academyId, user?.userCompanyId);

  // Render loading screen if not a preview and until user is enrolled
  if (!enrolledAcademyUser && isActivePlanItem) {
    return <LoadingSpinner center />;
  }

  return (
    <AcademyDrawer
      transitionOnRender={transitionOnRender}
      getScreenNavItemUrlPath={getScreenNavItemUrlPath}
      currentLevelTitle={currentLevelTitle}
      academy={academy}
      showEditAcademyLink={!hideEditAcademyLink && hasEditPermission}
      isLoading={isAcademyLoading}
      onClickEdit={handleClickEdit}
      renderPageInsteadOfDrawer={false}
      onClose={userNavOnClose}
      isActivePlanItem={isActivePlanItem}>
      <Routes>
        <Route
          path="content/*"
          element={
            <AcademyContent
              title={i18n.t(k.GENERIC__OVERVIEW)}
              currentLevelTitle={currentLevelTitle}
              isActivePlanItem={isActivePlanItem}
            />
          }
        />
        <Route
          path={'reports/*'}
          element={
            <BasePage>
              <AcademyReportsPage basePath={reportsPageBasePath} />
            </BasePage>
          }
        />
        <Route
          path="projects/*"
          element={
            <BasePage>
              <AcademiesProjectsTableWithRoutesContainer
                title={i18n.t(k.PROJECT__PLURAL)}
                basePath={getScreenNavItemUrlPath(
                  parseInt(academyId),
                  AcademyScreens.projects
                )}
              />
            </BasePage>
          }
        />
        {isFeatureFlagOn.AcademyPeople && (
          <Route
            path="people/*"
            element={
              <BasePage>
                <AcademyPeoplePage
                  title={i18n.t(k.GENERIC__PEOPLE)}
                  // TODO: update these props to same pattern as AcademyReportsPage
                  activeTabKey={getPeoplePageActiveTab(pathname)}
                  onChangeTabs={(
                    tabKey: AcademyPeoplePageTabs,
                    navigateOptions
                  ) => {
                    const basePath = getScreenNavItemUrlPath(
                      parseInt(academyId),
                      AcademyScreens.people
                    );
                    navigate([basePath, tabKey].join('/'), navigateOptions);
                  }}
                />
              </BasePage>
            }
          />
        )}
      </Routes>
      <Outlet />
    </AcademyDrawer>
  );
}

export default AcademyDrawerContainer;
