import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {i18n, k} from '@i18n/translate';
import {ArrowRightOutlined} from '@ant-design/icons';
import {ContentType, PlanItemType} from '@generated/enums';
import {UserPlanProgramItemVM} from '@models/serverModels';
import {
  PlanItemTypeWithCustomProgram,
  UserPlanCardActions,
  UserPlanItemStatusString,
} from '@models/clientEnums';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {
  getClientAwareProviderName,
  isLearnInProvider,
} from '@utils/custom-client-helpers';
import ProviderLogo from '@blocks/ProviderLogo';
import LearnInProgressBar from '@components/learnin/ProgressBar';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LearnInWrapper} from '@components/reusable/Wrapper/Wrapper.style';
import {WrapperTags} from '@components/reusable/Wrapper/WrapperEnums';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {
  hasMenuItem,
  shouldShowExpandedCardView,
  UserPlanCardActionMenuTest,
} from '@blocks/userPlanCardActionMenu/UserPlanCardActionMenu';
import ActionButton from '@blocks/actionButton/ActionButton';
import {Breakpoints, ResponsiveContainer} from '@utils/responsiveUtils';
import {ContentDueDateStatus} from '@blocks/CustomProgramUserDrawer/ContentCard';
import {LabelAndIconContainer} from '@components/admin/pages/programs/customPrograms/curriculum/CurriculumScreen.container';
import {
  DATE_FORMAT,
  parseToLocalMoment,
  utcStringIsInTheFuture,
} from '@utils/timeUtils';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {LinkButton} from '@blocks/userPlanCard/UserPlanCard';
import {UserPlanCardItem} from '@utils/itemUtils';
import {useGetCompanyPersonalizationQuery} from '@hooks/apiEndpoints/user/queries';
import ContentTypeIcon from '@blocks/ContentTypeIcon';
import ContextMenuButton from '@blocks/ContextMenu';
import {getCompanyProgramPath} from '@utils/ClientPaths';
import {PlanItem} from '@models/clientModels';
import {useCustomProgramPreviewQuery} from '@generated/hooks';
import {
  CustomProgramContentPreviewVM,
  CustomProgramSectionPreviewVM,
} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const StyledLearnInContainer = styled(LearnInContainer)`
  &:hover {
    border: 1px solid ${COLORS.Neutral100};
    box-sizing: border-box;
    box-shadow: ${COLORS.BoxShadowStandard};
    border-radius: 8px;
  }
`;

export const CardDataHeaderContainer = styled.div`
  margin: 24px;
  display: flex;
  @media (max-width: ${Breakpoints.Tablet}px) {
    padding-bottom: 12px;
  }
`;

export const CardDataHeaderSection = styled.div<{
  width?: string;
  mobileWidth?: number;
  flexEndMobile?: boolean;
}>`
  display: flex;
  width: ${({width}) => width || '25%'};
  box-sizing: border-box;
  flex-grow: 1;
  @media (max-width: ${Breakpoints.Tablet}px) {
    width: ${({mobileWidth}) => mobileWidth || '50'}% !important;
    justify-content: ${({flexEndMobile}) => flexEndMobile && 'flex-end'};
  }
`;

export const LabelContainer = styled.div`
  padding-top: 4px;
`;

export const Label = styled.div`
  color: ${COLORS.Neutral600};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.875rem;
  margin-bottom: 6px;
`;

export const Value = styled.div`
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.1875rem;
`;

export const ProgressbarLabelContainer = styled(LabelContainer)`
  background: ${COLORS.Neutral50};
  border-radius: 4px;
  height: fit-content;
  padding: 9px 16px;
  box-sizing: border-box;
  margin: 0 24px 16px;
  @media (max-width: ${Breakpoints.Tablet}px) {
    max-width: 1000px;
  }
`;

const Status = styled.span`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${COLORS.Neutral900};
`;

export const ActionButtonsContainer = styled.div`
  align-items: center;
  column-gap: 8px;
  display: flex;

  @media (max-width: ${Breakpoints.Mobile}px) {
    margin-bottom: 16px;
    margin-right: 16px;
  }
`;

const ContentDueDate = styled.div`
  align-items: center;
  color: ${COLORS.Neutral900};
  display: flex;
  font: 400 0.75em Roboto, serif;
  margin-bottom: 16px;
`;

const ContentDueDateContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const LiveEventTag = ({dateUtc}: {dateUtc: string}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '16px',
        color: COLORS.Neutral900,
      }}>
      <LearnInTag
        label={i18n.t(k.EVENT__LIVE).toLocaleUpperCase()}
        tagStyle={TagStyles.Green}
      />
      <div style={{marginLeft: '16px'}}>
        {parseToLocalMoment(dateUtc).format(DATE_FORMAT.MONTH_LONG_DAY_YEAR)}
      </div>
    </div>
  );
};

/*
|--------------------------------------------------------------------------
| Item Card Data Header
|--------------------------------------------------------------------------
*/

export function UserPlanCardDataHeader({
  item,
  planItemTypeWithCustomProgram,
  showActions,
  handleShowPreApprovalDrawer,
  handleShowRequestLicenseDrawer,
  handleShowViewLicenseModal,
  handleSettingsActions,
}: {
  item: UserPlanCardItem;
  planItemTypeWithCustomProgram: PlanItem;
  showActions: boolean;
  handleShowPreApprovalDrawer: () => void;
  handleShowRequestLicenseDrawer?: () => void;
  handleShowViewLicenseModal?: () => void;
  handleSettingsActions: (action: UserPlanCardActions) => void;
  isCustomLogo?: boolean;
}) {
  const navigate = useNavigate();
  const [parentSection, setParentSection] =
    useState<CustomProgramSectionPreviewVM>(null);
  const [childSections, setChildSections] = useState<
    Array<CustomProgramContentPreviewVM>
  >([]);
  const [providerLabel, setProviderLabel] = useState('');
  const [providerImage, setProviderImage] = useState('');
  const {data: companyPersonalization} = useGetCompanyPersonalizationQuery();

  const showActionButton =
    showActions &&
    !shouldShowExpandedCardView(planItemTypeWithCustomProgram, item.status);
  const showDropdownMenu =
    showActions &&
    item.status !== UserPlanItemStatusString.Complete &&
    hasMenuItem(
      item.status,
      item.itemType,
      item?.approvalRequired,
      item?.licenseId
    );
  const showMultipleMobileRow = showActionButton && showDropdownMenu;

  const customProgramsPreviewDetailsQuery = useCustomProgramPreviewQuery({
    customProgramId: item.programId,
    queryParams: {includeProgress: true, skipEmptySections: true},
  });

  useEffect(() => {
    if (customProgramsPreviewDetailsQuery.data) {
      // Get the first incomplete section then the next 3 incomplete steps to display
      const inCompleteSection = customProgramsPreviewDetailsQuery.data?.sections
        .sort((a, b) => a.order - b.order)
        .find((x) => x.progressPercentage < 100);
      const inCompleteContents =
        inCompleteSection?.content
          .sort((a, b) => a.order - b.order)
          .filter((x) => !x.textContentCompleted)
          .filter(eventContentNotInPass)
          .slice(0, 3) || [];
      setParentSection(inCompleteSection);
      setChildSections(inCompleteContents);
    }
  }, [customProgramsPreviewDetailsQuery.data]);

  useEffect(() => {
    const determineProviderLabel = () => {
      if (
        !!companyPersonalization &&
        planItemTypeWithCustomProgram ===
          PlanItemTypeWithCustomProgram.CustomProgram
      ) {
        setProviderLabel(companyPersonalization?.companyDisplayName);
        return;
      }

      if (
        isLearnInProvider(item?.provider?.toUpperCase()) &&
        !!companyPersonalization
      ) {
        setProviderLabel(
          getClientAwareProviderName(
            companyPersonalization?.companyDisplayName,
            item?.provider?.toUpperCase()
          )
        );
        return;
      }

      setProviderLabel(item?.provider?.toUpperCase());
    };
    determineProviderLabel();
  }, [!!companyPersonalization]);

  useEffect(() => {
    const determineProviderImage = () => {
      if (
        planItemTypeWithCustomProgram ===
        PlanItemTypeWithCustomProgram.CustomProgram
      ) {
        setProviderImage(companyPersonalization?.companyLogoUrlSquare);
        return;
      }

      setProviderImage(item?.providerLogo);
    };
    determineProviderImage();
  }, [!!companyPersonalization]);

  const eventContentNotInPass = (content: CustomProgramContentPreviewVM) => {
    return (
      content.contentType != ContentType.Event ||
      (!!content?.endDateUtc && utcStringIsInTheFuture(content.endDateUtc))
    );
  };
  const formattedDueDate = (date) =>
    parseToLocalMoment(date)?.format(DATE_FORMAT.getMonthLongDayYearWithTime());

  const showReimbursementLink =
    item.status === UserPlanItemStatusString.Approved &&
    item.marketplaceProgram === true &&
    !!item.externalReimbursementApplicationUrl &&
    !item.isLicenseProgram;

  const CardActionButtons = (
    <LabelContainer>
      <ActionButtonsContainer>
        {showReimbursementLink && (
          <LinkButton
            style={{color: COLORS.Blue800, marginRight: '8px'}}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            href={item.externalReimbursementApplicationUrl}>
            {i18n.t(k.REIMBURSEMENT__SUBMIT)}
          </LinkButton>
        )}
        <ActionButton
          item={{
            ...item,
            programApprovalRequired: item.approvalRequired,
            licenseId: item.licenseId,
          }}
          itemType={planItemTypeWithCustomProgram}
          handleShowPreApprovalDrawer={handleShowPreApprovalDrawer}
          handleShowRequestLicenseDrawer={handleShowRequestLicenseDrawer}
          handleShowViewLicenseModal={handleShowViewLicenseModal}
          onSettings={handleSettingsActions}
          onPress={() => {
            navigate(
              getCompanyProgramPath(item.programId, {from: 'user-plan'})
            );
          }}
          buttonLabel={i18n.t(k.PROGRAM__GO_TO)}
        />
        {showDropdownMenu && (
          <ContextMenuButton
            popupContainerId={'main-content'}
            itemId={item.id}
            menuItems={UserPlanCardActionMenuTest({
              item: {
                ...item,
                programApprovalRequired: item.approvalRequired,
                licenseId: item.licenseId,
              },
              onSettings: handleSettingsActions,
            })}
            size={'middle'}
          />
        )}
      </ActionButtonsContainer>
    </LabelContainer>
  );
  return (
    <article aria-label={`${i18n.t(k.STATUS__STATUS)}: ${item.title}`}>
      <CardDataHeaderContainer>
        <CardDataHeaderSection mobileWidth={100}>
          <ProviderLogo
            provider={providerLabel}
            providerLogo={providerImage}
            style={{marginRight: '16px', position: 'relative'}}
            size={56}
          />
          <LabelContainer style={{overflowX: 'hidden'}}>
            <Label>{providerLabel}</Label>
            {!!item.startDateUtc && !!item.endDateUtc && (
              <Label>
                {parseToLocalMoment(item.startDateUtc).format(
                  DATE_FORMAT.MONTH_DAY_YEAR
                )}{' '}
                -{' '}
                {parseToLocalMoment(item.endDateUtc).format(
                  DATE_FORMAT.MONTH_DAY_YEAR
                )}
              </Label>
            )}
            <Value
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}>
              {item?.title}
            </Value>
          </LabelContainer>
        </CardDataHeaderSection>
        <ResponsiveContainer min={Breakpoints.Mobile}>
          <CardDataHeaderSection
            width={'100%'}
            style={{
              flexDirection: 'row-reverse',
              paddingLeft: '0px',
              paddingRight: '0px',
            }}
            mobileWidth={showMultipleMobileRow ? 100 : 100}>
            {CardActionButtons}
          </CardDataHeaderSection>
        </ResponsiveContainer>
      </CardDataHeaderContainer>

      <ProgressbarLabelContainer>
        <Status>{i18n.t(k.STATUS__STATUS)}</Status>
        <LearnInProgressBar
          percent={customProgramsPreviewDetailsQuery.data?.progressPercentage}
        />
      </ProgressbarLabelContainer>
      <CardDataHeaderSection
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
        mobileWidth={100}>
        <ResponsiveContainer max={Breakpoints.Mobile}>
          {CardActionButtons}
        </ResponsiveContainer>
      </CardDataHeaderSection>
      {parentSection && !!childSections.length && (
        <ResponsiveContainer min={Breakpoints.Desktop} display="flex">
          <LearnInWrapper tag={WrapperTags.ProgramStatusCardWrapper}>
            {childSections.map((content, i) => (
              <StyledLearnInContainer
                key={content.id}
                tag={ContainerTags.ProgramStatusCardContainer}
                width="100%"
                role="button"
                tabIndex={0}
                onClick={() => {
                  navigate(
                    getCompanyProgramPath(item.programId, {
                      scrollContentId: content.id.toString(),
                      from: 'user-plan',
                    })
                  );
                }}
                margin={
                  i !== childSections.length - 1 ? '0px 16px 0px 0px' : '0px'
                }>
                <LabelAndIconContainer>
                  <ContentTypeIcon contentType={content.contentType} />
                  <div>
                    <LearnInText tag={TextTags.ProgramStatusCardTextHeader}>
                      {parentSection.order}. {parentSection.title}
                    </LearnInText>
                    <LearnInText tag={TextTags.ProgramStatusCardTextSubHeader}>
                      {parentSection.order}.{content.order}. {content.title}
                    </LearnInText>
                  </div>
                </LabelAndIconContainer>
                <div style={{marginTop: '30px'}}>
                  <LearnInText tag={TextTags.ProgramStatusCardTextLink}>
                    {!!content.dueDateUtc && (
                      <ContentDueDateContainer>
                        <ContentDueDateStatus
                          dueDateUtc={content.dueDateUtc}
                          completed={content.textContentCompleted}
                        />
                        <ContentDueDate>
                          {formattedDueDate(content.dueDateUtc)}
                        </ContentDueDate>
                      </ContentDueDateContainer>
                    )}
                    {content.contentType == ContentType.Event &&
                      utcStringIsInTheFuture(content.endDateUtc) && (
                        <LiveEventTag dateUtc={content.endDateUtc} />
                      )}
                    <span className="pseudo-link">
                      {i18n.t(
                        content.contentType == ContentType.Event
                          ? k.EVENT__GO_TO
                          : k.CONTENT__GO_TO
                      )}
                      <ArrowRightOutlined
                        aria-hidden="true"
                        style={{marginLeft: '15px'}}
                        color={COLORS.Blue800}
                      />
                    </span>
                  </LearnInText>
                </div>
              </StyledLearnInContainer>
            ))}
          </LearnInWrapper>
        </ResponsiveContainer>
      )}
    </article>
  );
}

export const isProgramType = (
  planItemType: PlanItemType,
  item: any
): item is UserPlanProgramItemVM => planItemType === PlanItemType.Program;
