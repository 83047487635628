import {Form} from 'antd';
import {k, i18n} from '@i18n/translate';
import {
  InputLabel,
  StyledLearnInInput as LearnInInput,
} from '@components/reusable/LearnInForm';
import {getFormFieldErrors, CharLimits} from '../AddEditContent.config';
import {FORM_KEYS} from './constants';

const Title = ({initialValue}: {initialValue: string}) => {
  return (
    <>
      <InputLabel
        htmlFor="title-input"
        label={i18n.t(k.GENERIC__TITLE)}
        required
      />
      <Form.Item
        name={FORM_KEYS.TITLE}
        initialValue={initialValue}
        rules={[
          {required: true, message: getFormFieldErrors().MissingTitle},
          {max: CharLimits.Title, message: getFormFieldErrors().CharLimitTitle},
        ]}>
        <LearnInInput id="title-input" />
      </Form.Item>
    </>
  );
};

export default Title;
