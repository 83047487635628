import {DiscussionStatus} from './../enums';
import {UpdateAcademyDiscussionRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdateAcademyDiscussionRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdateAcademyDiscussionRequestMockData(
  args?: Partial<UpdateAcademyDiscussionRequest>
): UpdateAcademyDiscussionRequest {
  return {
    status: DiscussionStatus.Active,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdateAcademyDiscussionRequestMockData: UpdateAcademyDiscussionRequest =
  {
    status: DiscussionStatus.Active,
  };
