import {CreditCardAdminActionVM} from '@models/api/payment/viewModels';
import AdminActivity from './AdminActivity';
import {CurrencyCode} from '@generated/enums';

export interface AdminActivityContainerProps {
  usersCurrency: CurrencyCode;
  exchangeRate: number;
  adminActions?: CreditCardAdminActionVM[];
}

function AdminActivityContainer({
  usersCurrency,
  exchangeRate,
  adminActions,
}: AdminActivityContainerProps) {
  return (
    <>
      {adminActions?.map((activity, index) => {
        return (
          <AdminActivity
            key={activity?.uniqueId}
            activity={activity}
            index={index}
            cardCount={adminActions?.length}
            usersCurrency={usersCurrency}
            exchangeRate={exchangeRate}
          />
        );
      })}
    </>
  );
}

export default AdminActivityContainer;
