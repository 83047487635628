// Do not modify directly
// Generated from file: learnin.core.api/Managers/Calendar/CalendarCallbackRedirect.cs

enum CalendarCallbackRedirect {
  UserSettings,
  UserTime,
  CustomProgram,
  Academy,
}

export default CalendarCallbackRedirect;
