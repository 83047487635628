import {ContentFeedbackVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ContentFeedbackVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getContentFeedbackVMMockData(
  args?: Partial<ContentFeedbackVM>
): ContentFeedbackVM {
  return {
    createdBy: faker.word.noun(),
    createdOn: String(faker.date.past()),
    id: faker.datatype.number(),
    message: faker.lorem.lines(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ContentFeedbackVMMockData: ContentFeedbackVM = {
  createdBy: 'string',
  createdOn: '2023-03-29T22:17:19.738072',
  id: 1,
  message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
};
