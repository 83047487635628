import {CurrencyCode, FinanceType} from './../enums';
import {UserPlanFinanceItemVM} from './../interfaces';
import {
  ReimbursementPreviewVMMockData,
  getReimbursementPreviewVMMockData,
} from './ReimbursementPreviewVM.mock';
import {
  UserPlanItemVMMockData,
  getUserPlanItemVMMockData,
} from './UserPlanItemVM.mock';
import {faker} from '@faker-js/faker';

// Interface: UserPlanFinanceItemVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanFinanceItemVMMockData(
  args?: Partial<UserPlanFinanceItemVM>
): UserPlanFinanceItemVM {
  return {
    ...getUserPlanItemVMMockData(),
    amount: faker.datatype.number(),
    applicationUrl: faker.internet.url(),
    balance: faker.datatype.number(), // optional
    currency: CurrencyCode.USD,
    financeIncentiveId: faker.datatype.number(), // optional
    financeType: FinanceType.Loan,
    reimbursements: [getReimbursementPreviewVMMockData()],
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanFinanceItemVMMockData: UserPlanFinanceItemVM = {
  ...UserPlanItemVMMockData,
  amount: 5,
  applicationUrl: 'https://this-person-does-not-exist.com/en',
  balance: 123, // optional
  currency: CurrencyCode.USD,
  financeIncentiveId: 1, // optional
  financeType: FinanceType.Loan,
  reimbursements: [ReimbursementPreviewVMMockData],
};
