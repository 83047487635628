import {i18n, k} from '@i18n/translate';
import {AcademyScreens} from '@utils/ClientPaths';

export const getLabelByScreen = (screen: AcademyScreens) => {
  switch (screen) {
    case AcademyScreens.branding:
      return i18n.t(k.BRAND__BRANDING);
    case AcademyScreens.overview:
      return i18n.t(k.GENERIC__OVERVIEW);
    case AcademyScreens.permissions:
      return i18n.t(k.PERMISSION__PLURAL);
    case AcademyScreens.setup:
      return i18n.t(k.GENERIC__SETUP);
    default:
      return screen;
  }
};
