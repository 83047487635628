import {useParams} from 'react-router-dom';
import useShowSkillsTagging from '@hooks/useShowSkillsTagging';
import {
  useAcademiesQuery,
  useAcademyEditorsQuery,
  useAcademyLevelsQuery,
  useAcademyProjectReviewersQuery,
  useAcademyQuery,
  useAcademyUsersQuery,
  useProjectsQuery,
  usePublishedAcademiesQuery,
  useUserAcademyLevelStepQuery,
} from '@generated/hooks/academy.get.hooks';
import {useManagedAcademiesQuery} from '@generated/hooks/permission.get.hooks';
import {datadogLogs} from '@datadog/browser-logs';
import {useAcademiesIntegrationsChatChannelsQuery as useChatChannelsQuery} from '@generated/hooks';
import {addStaleTime} from '@store/queryClient';

/**
 * Academy data access hooks with staleTime caching enabled to prevent uneccessary refetching of api calls.
 *
 * Note: Queries will only refetch on next window focus after staleTime has expired, or on invalidations.
 * The enabled property must be true for the refetch to occur. The enabled property is true by default.
 */

// Deafult stale time for all queries
const staleTime = addStaleTime();

export const useAcademies = () => {
  const {data} = useAcademiesQuery(null, {staleTime});
  return {data};
};

export const useAcademy = (academyId: number) => {
  const {showSkillsTagging} = useShowSkillsTagging();

  const {data: academy, isLoading} = useAcademyQuery(
    {
      academyId: academyId,
      queryParams: {
        includeSkills: showSkillsTagging,
      },
    },
    {staleTime}
  );

  return {academy, isLoading};
};

export const useAcademyLevels = ({
  academyId = null,
  enabled = true,
  excludeUserProgress = false,
} = {}) => {
  const {
    data: levels,
    isLoading,
    invalidateExact,
  } = useAcademyLevelsQuery(
    {
      academyId: parseInt(academyId),
      queryParams: {excludeUserProgress},
    },
    {enabled, staleTime}
  );
  return {levels, isLoading, invalidateExact};
};

export const useManagedAcademies = (enabled = true) => {
  const {data} = useManagedAcademiesQuery(null, {
    staleTime,
    enabled,
  });
  return {data};
};

export const usePublishedAcademies = (enabled = true) => {
  const {showSkillsTagging} = useShowSkillsTagging();

  const {data, isLoading, refetch, invalidateExact} =
    usePublishedAcademiesQuery(
      {queryParams: {includeSkills: showSkillsTagging}},
      {
        enabled,
        staleTime,
      }
    );
  return {data, isLoading, refetch, invalidateExact};
};

export const useAcademyLevelStep = (levelId: number, enabled: boolean) => {
  const {academyId} = useParams();
  const {data, isLoading, isSuccess, refetch, invalidateExact} =
    useUserAcademyLevelStepQuery(
      {
        academyLevelId: levelId,
      },
      {
        enabled: enabled,
        staleTime,
        onError: () => {
          datadogLogs.logger.error(
            `Error while fetching academy steps for ${academyId}`
          );
        },
      }
    );
  return {data, isLoading, isSuccess, refetch, invalidateExact};
};

export const useAcademyProjects = (academyId: number, enabled = true) => {
  const {data} = useProjectsQuery(
    {
      academyId,
    },
    {
      enabled,
      staleTime,
    }
  );
  return {data};
};

export const useAcademyChatChannels = (academyId: number, enabled = true) => {
  const {data, isLoading} = useChatChannelsQuery(
    {
      academyId,
    },
    {
      enabled,
      staleTime,
    }
  );
  return {data, isLoading};
};

export const useAcademyEditorPeopleUsers = (
  academyId: string | number,
  enabled = true
) => {
  const {data, isLoading} = useAcademyEditorsQuery(
    {academyId},
    {
      enabled,
      staleTime,
    }
  );
  return {
    data,
    isLoading,
  };
};

export const useAcademyProjectReviewerPeopleUsers = (
  academyId: string | number,
  enabled = true
) => {
  const {data, isLoading} = useAcademyProjectReviewersQuery(
    {academyId},
    {
      enabled,
      staleTime,
    }
  );
  return {
    data,
    isLoading,
  };
};

export const useAcademyUsers = (academyId: string | number, enabled = true) => {
  const {data, isLoading} = useAcademyUsersQuery(
    {academyId},
    {
      enabled,
      staleTime,
    }
  );
  return {data, isLoading};
};
