import './DeleteProgramItemModal.scss';
import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Alert, Form, Radio} from 'antd';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {LearnInMoneyInput} from '@components/reusable/Input/Input.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {WarningOutlined} from '@ant-design/icons';
import {getUserProgramPlanRq} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {UserPlanProgramItemVM} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import {exchangeAmountBack, formatCurrency} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  useDeleteProgramFromPlanByItemId,
  useDeleteProgramFromPlanByProgramId,
} from '@hooks/apiEndpoints/userPlan/mutations';
import {CurrencyCode} from '@generated/enums';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {useFinanceLearningBudgetsQuery} from '@generated/hooks';

const AlertContainer = styled.div`
  font-size: 1rem;
  width: 100%;
  padding-left: 24px;
`;

const AlertText = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0em;
  text-align: left;
`;

interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onOk?: () => void;
  itemId?: number;
  programId?: number;
  programCurrency?: CurrencyCode;
  approvedAmount: number;
  isReimbursementPlan?: boolean;
}

const radioStyle = {display: 'block'};

export default function DeleteProgramItemModal({
  isVisible,
  setIsVisible,
  itemId,
  programId,
  programCurrency,
  onOk,
  approvedAmount,
  isReimbursementPlan,
}: Props) {
  const [isSpentAmountVisible, setIsSpentAmountVisible] = useState(undefined);
  const [form] = Form.useForm();
  const deleteProgramFromPlanByItemIdMutation =
    useDeleteProgramFromPlanByItemId({onFinished: onOk});
  const deleteProgramFromPlanByProgramIdMutation =
    useDeleteProgramFromPlanByProgramId({onFinished: onOk});

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    programCurrency,
    getUserQuery.data?.currency
  );

  //Check the program to show the approved amount
  const userProgramPlanRq = getUserProgramPlanRq(2);
  const userProgramPlanQuery = useQuery<UserPlanProgramItemVM[]>(
    userProgramPlanRq.queryKey,
    () => simpleQueryFn(userProgramPlanRq.path)
  );

  const financeLearningBudgetsQuery = useFinanceLearningBudgetsQuery(null);

  const refreshBudget = () => {
    setTimeout(() => {
      //If we call the API immediately after removing a program / resource, the budget data is not yet updated.
      financeLearningBudgetsQuery.refetch();
    }, 1000);
  };

  const handleOk = async () => {
    if ((await form.validateFields()) && (!!itemId || !!programId)) {
      const amountValue = form.getFieldValue(`remove-program-amount-${itemId}`);
      const amountSpent = exchangeAmountBack(amountValue, exchangeRate);
      if (!!itemId) {
        deleteProgramFromPlanByItemIdMutation.mutate({
          itemId,
          amountSpent,
        });
      } else if (!!programId) {
        // prevent the delete endpoint to be called twice
        deleteProgramFromPlanByProgramIdMutation.mutate({
          programId,
          amountSpent,
        });
      }
      setIsVisible(false);
      refreshBudget();
    }
  };

  useEffect(() => {
    isSpentAmountVisible !== undefined &&
      form.validateFields(['made-purchases']);
  }, [isSpentAmountVisible]);

  //if the approved amount is not provided, search the program in plan
  const getApprovedAmount = () => {
    if (!approvedAmount) {
      const itemFromPlan = userProgramPlanQuery.data.find(
        (program) => program.programId == programId
      );
      const newApprovedAmount = itemFromPlan?.approvedAmount;
      return newApprovedAmount;
    } else {
      return approvedAmount;
    }
  };

  return (
    <LearnInModal
      tag={ModalTags.RemoveProgramFromUserPlan}
      visible={isVisible}
      width={400}
      onOk={handleOk}
      onCancel={() => setIsVisible(false)}
      okButtonChild={
        isReimbursementPlan
          ? i18n.t(k.PROGRAM__REMOVE__CONFIRMED)
          : i18n.t(k.PROGRAM__REMOVE)
      }
      okButtonProps={{id: 'remove-program-item-button'}}
      okButtonTag={ButtonTags.Destructive}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      title={i18n.t(k.PROGRAM__REMOVE)}>
      {isReimbursementPlan ? (
        <div
          style={{padding: '8px 24px 16px 24px'}}
          dangerouslySetInnerHTML={{
            __html: i18n.t(k.REIMBURSEMENT__REMOVE_PROGRAM),
          }}
        />
      ) : (
        <Form key={itemId} form={form} className="delete-program-modal">
          <Form.Item
            key={itemId}
            name="made-purchases"
            rules={[
              {
                required: isSpentAmountVisible === undefined,
                message: i18n.t(k.VALIDATION__REQUIRED),
              },
            ]}>
            <div>{i18n.t(k.CARD__REMOVE_PROGRAM_QUESTION)}</div>
            <Radio.Group
              style={{marginTop: '9px'}}
              onChange={(value) => setIsSpentAmountVisible(value.target.value)}
              value={isSpentAmountVisible}>
              <Radio value={true} style={radioStyle}>
                {i18n.t(k.GENERIC__YES_I_HAVE)}
              </Radio>
              {isSpentAmountVisible && (
                <Form.Item
                  style={{margin: '8px 0px 0px 24px'}}
                  name={`remove-program-amount-${itemId}`}
                  rules={[
                    {
                      required: isSpentAmountVisible,
                      message: i18n.t(k.VALIDATION__REQUIRED),
                    },
                  ]}>
                  <LearnInMoneyInput
                    currency={getUserQuery.data?.currency}
                    min={1}
                    style={{width: '150px'}}
                  />
                </Form.Item>
              )}

              <Radio value={false} style={radioStyle}>
                {i18n.t(k.GENERIC__NO_I_HAVE_NOT)}
              </Radio>
              {isSpentAmountVisible === false && (
                <AlertContainer>
                  <Alert
                    style={{
                      padding: '10px',
                      alignItems: 'center',
                      height: '62px',
                    }}
                    description={
                      <AlertText>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: i18n.t(
                              k.PROGRAM__REMOVE__WARN_VC_DEDUCTION__FORMAT,
                              {
                                localizedAmount: formatCurrency(
                                  getApprovedAmount(),
                                  getUserQuery.data?.currency,
                                  exchangeRate,
                                  {
                                    decimal: true,
                                  }
                                ),
                              }
                            ),
                          }}></span>
                      </AlertText>
                    }
                    type="warning"
                    showIcon
                    icon={<WarningOutlined aria-hidden="true" />}
                  />
                </AlertContainer>
              )}
            </Radio.Group>
          </Form.Item>
        </Form>
      )}
    </LearnInModal>
  );
}
