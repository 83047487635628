import {Form} from 'antd';
import {i18n, k} from '@i18n/translate';
import {BodySpan} from '@components/reusable/Typography';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';

export const PreviousRejectionNotes = ({
  initialValue,
}: {
  initialValue: string;
}) => {
  return (
    <Form.Item
      className="lb-application-drawer"
      name="previousRejectionNotes"
      label={
        <BodySpan fontWeight={500}>
          {i18n.t(k.APPROVAL__PREVIOUS_NOTES)}
        </BodySpan>
      }
      initialValue={initialValue || ''}>
      <LearnInTextArea
        disabled={true}
        maxLength={10000}
        tag={TextAreaTags.PRIMARY}
      />
    </Form.Item>
  );
};
