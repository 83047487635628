import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem;

  & tr:has(tr.ant-table-expanded-row) td {
    border-bottom: none;
  }

  & tr.ant-table-expanded-row tr:last-of-type td {
    border-bottom: none;
  }

  &
    tr.ant-table-expanded-row
    .ant-table
    .ant-table-container
    .ant-table-content {
    padding: 0 !important;
    margin-left: 24px;
  }

  &
    tr.ant-table-expanded-row
    .ant-table
    .ant-table-container
    .ant-table-content
    td:first-of-type {
    padding: 8px !important;
  }

  & tr.ant-table-expanded-row .ant-table-cell {
    padding-right: 4px !important;
  }
`;
