import {useEffect, useState} from 'react';
import {MarketPlaceProgramsTableData} from '@models/serverModels';
import {getAdminMarketplaceProgramsRq} from '@store/apiEndpoints/program/queries';
import {getAddAcademyProgramStepRm} from '@store/apiEndpoints/academy/mutations';
import {useAcademyStepVMsQuery} from '@hooks/apiEndpoints/academy/queries';
import {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import {useMutation, useQuery} from 'react-query';
import AddMarketplaceProgramDrawer from './AddMarketplaceProgramDrawer';
import {debounce} from 'lodash';
import CreateProgramDrawer from '../../programs/CreateProgramDrawer.container';
import {notify} from '@components/user/notifications';
import {AcademyStepType, CurrencyCode} from '@generated/enums';
import {useGetCurrencyExchangeQuery} from '@hooks/apiEndpoints/localization/queries';
import {getExchangeRateOfCurrencyPair} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {AcademyProgramStepPayload, AcademyStepVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface AddMarketplaceProgramDrawerContainerProps {
  onClose: (callbackOptions?: {shouldRefresh: boolean}) => void;
  levelId: number;
  existingSteps?: AcademyStepVM[];
}

function AddMarketplaceProgramDrawerContainer({
  onClose,
  existingSteps,
  levelId,
}: AddMarketplaceProgramDrawerContainerProps) {
  const [programSearchText, setProgramSearchText] = useState('');
  const [
    searchFilteredMarketplacePrograms,
    setSearchFilteredMarketPlacePrograms,
  ] = useState<MarketPlaceProgramsTableData[]>([]);
  const [previouslySelected, setPreviouslySelected] = useState([]);
  const [newlySelected, setNewlySelected] = useState([]);
  const [isAddProgramDrawerVisible, setIsAddProgramDrawerVisible] =
    useState(false);
  const [allSelected, setAllSelected] = useState(false);

  // Marketplace Programs Table Data
  const adminMarketplaceProgramsRq = getAdminMarketplaceProgramsRq();
  const adminMarketplaceProgramsQuery = useQuery<
    MarketPlaceProgramsTableData[]
  >(adminMarketplaceProgramsRq.queryKey, () =>
    simpleQueryFn(
      adminMarketplaceProgramsRq.path,
      (data: MarketPlaceProgramsTableData[]) => {
        const existingStepsIds = [];
        existingSteps.forEach((step) => {
          if (step.type == AcademyStepType.MarketplaceProgram)
            existingStepsIds.push(step.programId);
        });
        setPreviouslySelected(existingStepsIds);
        return data.filter((datum) => !existingStepsIds.includes(datum.id));
      }
    )
  );

  //Search marketplace programs
  const applyMarketPlaceProgramsSearch = debounce(() => {
    const searchResults = adminMarketplaceProgramsQuery.data?.filter(
      ({programTitle, provider}) => {
        if (!programSearchText) {
          return true;
        }
        return new RegExp(programSearchText, 'i').test(
          `${programTitle}${provider}`
        );
      }
    );
    setSearchFilteredMarketPlacePrograms(searchResults);
  }, 750);

  useEffect(() => {
    applyMarketPlaceProgramsSearch();
  }, [adminMarketplaceProgramsQuery.data, programSearchText]);

  const handleClickCheckbox = (id: number) => {
    !newlySelected.includes(id)
      ? setNewlySelected((selectedPrograms) => [...selectedPrograms, id])
      : setNewlySelected([...newlySelected.filter((x) => x !== id)]);
  };

  const handleClearSelectedPrograms = () => {
    setNewlySelected([]);
  };

  const closeMarketplaceProgramForm = () => {
    setIsAddProgramDrawerVisible(false);
  };

  const openMarketplaceProgramForm = () => {
    setIsAddProgramDrawerVisible(true);
  };

  //Set the newest program on top after creating it
  const placeLatestProgramOnTop = debounce(
    (program: MarketPlaceProgramsTableData) => {
      const NewSorted = adminMarketplaceProgramsQuery.data;
      NewSorted.unshift(program);
      NewSorted.pop();
      setSearchFilteredMarketPlacePrograms(NewSorted);
    },
    750
  );

  const refreshAndSelectLastProgram = () => {
    adminMarketplaceProgramsQuery.refetch().then((res) => {
      const latestProgram = res.data[res.data?.length - 1];
      setNewlySelected((selectedPrograms) => [
        ...selectedPrograms,
        latestProgram.id,
      ]);
      placeLatestProgramOnTop(latestProgram);
    });
  };

  const getUserQuery = useGetUserQuery();
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: true,
  });
  const itemsExchangeRate = (currency: CurrencyCode) =>
    getExchangeRateOfCurrencyPair(
      getCurrencyExchangeQuery?.data,
      getUserQuery?.data?.currency,
      currency
    );

  //Check or uncheck all elements
  const handleCheckAll = () => {
    setAllSelected(!allSelected);
  };

  useEffect(() => {
    !!allSelected
      ? setNewlySelected(searchFilteredMarketplacePrograms.map((s) => s.id))
      : setNewlySelected([]);
  }, [allSelected]);

  //Add selected steps to program
  const addMarketplaceProgramAsStepMutation = useMutation(
    (steps: AcademyProgramStepPayload) => {
      const addCustomProgramSectionRm = getAddAcademyProgramStepRm(steps);
      return simpleMutationFn<number>(
        addCustomProgramSectionRm.path,
        addCustomProgramSectionRm.payload
      );
    },
    {
      onSuccess: async () => {
        notify.addStepsSuccess();
        onClose({shouldRefresh: true});
      },
    }
  );

  const addSteps = () => {
    const steps = {
      academyLevelId: levelId,
      type: AcademyStepType.MarketplaceProgram,
      newSteps: previouslySelected.concat(newlySelected), // `newSteps` is actually the full list of step ids, inclusive of those previously existing.
    };
    addMarketplaceProgramAsStepMutation.mutateAsync(steps);
  };

  return (
    <div>
      <AddMarketplaceProgramDrawer
        dataSource={searchFilteredMarketplacePrograms || []}
        newlySelected={newlySelected}
        setProgramSearchText={setProgramSearchText}
        programSearchText={programSearchText}
        onClickCheckbox={handleClickCheckbox}
        clearSelectedPrograms={handleClearSelectedPrograms}
        onClose={onClose}
        openMarketplaceProgramForm={openMarketplaceProgramForm}
        allSelected={allSelected}
        checkAll={handleCheckAll}
        addSteps={addSteps}
        itemsExchangeRate={itemsExchangeRate}
        usersCurrency={getUserQuery?.data?.currency}
      />
      {isAddProgramDrawerVisible && (
        <CreateProgramDrawer
          onClose={closeMarketplaceProgramForm}
          id={null}
          visible={isAddProgramDrawerVisible}
          refreshParentData={refreshAndSelectLastProgram}
        />
      )}
    </div>
  );
}

export default AddMarketplaceProgramDrawerContainer;
