import {mutationHookFactory} from './../../utils';

import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/academy-levels/{academyLevelId}/academy-steps/{academyStepId}/discussions/{discussionId}/messages/{messageId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation} from '@generated/hooks';
 *
 *  const academiesAcademyLevelsAcademyStepsDiscussionsMessagesDelete = useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesAcademyLevelsAcademyStepsDiscussionsMessagesDelete.mutate({
 *    pathVars: {
 *      academyId,
 *      academyLevelId,
 *      academyStepId,
 *      discussionId,
 *      messageId
 *    }
 *  })
 *
 */

export const useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation =
  mutationHookFactory<
    null,
    {
      academyId: number | string;
      academyLevelId: number | string;
      academyStepId: number | string;
      discussionId: string;
      messageId: string;
    }, // path variables
    null
  >(
    'useAcademiesAcademyLevelsAcademyStepsDiscussionsMessagesDeleteMutation',
    'delete',
    ({academyId, academyLevelId, academyStepId, discussionId, messageId}) =>
      `academies/${academyId}/academy-levels/${academyLevelId}/academy-steps/${academyStepId}/discussions/${discussionId}/messages/${messageId}`,
    {payloadSchema: undefined}
  );

/*
|--------------------------------------------------------------------------
|  delete-user-permission/{academyId}/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesDeleteUserPermissionMutation} from '@generated/hooks';
 *
 *  const academiesDeleteUserPermission = useAcademiesDeleteUserPermissionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesDeleteUserPermission.mutate({
 *    pathVars: {
 *      academyId,
 *      userCompanyId
 *    }
 *  })
 *
 */

export const useAcademiesDeleteUserPermissionMutation = mutationHookFactory<
  null,
  {academyId: number; userCompanyId: number}, // path variables
  boolean // return type
>(
  'useAcademiesDeleteUserPermissionMutation',
  'delete',
  ({academyId, userCompanyId}) =>
    `academies/delete-user-permission/${academyId}/${userCompanyId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-academy/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteAcademyMutation} from '@generated/hooks';
 *
 *  const deleteAcademy = useDeleteAcademyMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteAcademy.mutate({
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useDeleteAcademyMutation = mutationHookFactory<
  null,
  {academyId: number | string}, // path variables
  boolean // return type
>(
  'useDeleteAcademyMutation',
  'delete',
  ({academyId}) => `academies/delete-academy/${academyId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-academy-cover-image/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteAcademyCoverImageMutation} from '@generated/hooks';
 *
 *  const deleteAcademyCoverImage = useDeleteAcademyCoverImageMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteAcademyCoverImage.mutate({
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 */

export const useDeleteAcademyCoverImageMutation = mutationHookFactory<
  null,
  {academyId: number | string}, // path variables
  boolean // return type
>(
  'useDeleteAcademyCoverImageMutation',
  'delete',
  ({academyId}) => `academies/delete-academy-cover-image/${academyId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-academy-level/{academyLevelId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteAcademyLevelMutation} from '@generated/hooks';
 *
 *  const deleteAcademyLevel = useDeleteAcademyLevelMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteAcademyLevel.mutate({
 *    pathVars: {
 *      academyLevelId
 *    }
 *  })
 *
 */

export const useDeleteAcademyLevelMutation = mutationHookFactory<
  null,
  {academyLevelId: number | string}, // path variables
  null
>(
  'useDeleteAcademyLevelMutation',
  'delete',
  ({academyLevelId}) => `academies/delete-academy-level/${academyLevelId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-academy-step/{academyStepId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteAcademyStepMutation} from '@generated/hooks';
 *
 *  const deleteAcademyStep = useDeleteAcademyStepMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteAcademyStep.mutate({
 *    pathVars: {
 *      academyStepId
 *    }
 *  })
 *
 */

export const useDeleteAcademyStepMutation = mutationHookFactory<
  null,
  {academyStepId: number | string}, // path variables
  null
>(
  'useDeleteAcademyStepMutation',
  'delete',
  ({academyStepId}) => `academies/delete-academy-step/${academyStepId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-academy-step-content-all-attachments/{academyStepId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteAcademyStepContentAllAttachmentsMutation} from '@generated/hooks';
 *
 *  const deleteAcademyStepContentAllAttachments = useDeleteAcademyStepContentAllAttachmentsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteAcademyStepContentAllAttachments.mutate({
 *    pathVars: {
 *      academyStepId
 *    }
 *  })
 *
 */

export const useDeleteAcademyStepContentAllAttachmentsMutation =
  mutationHookFactory<
    null,
    {academyStepId: number | string}, // path variables
    null
  >(
    'useDeleteAcademyStepContentAllAttachmentsMutation',
    'delete',
    ({academyStepId}) =>
      `academies/delete-academy-step-content-all-attachments/${academyStepId}`,
    {payloadSchema: undefined}
  );

/*
|--------------------------------------------------------------------------
|  delete-academy-step-content-attachment/{attachmentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteAcademyStepContentAttachmentMutation} from '@generated/hooks';
 *
 *  const deleteAcademyStepContentAttachment = useDeleteAcademyStepContentAttachmentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteAcademyStepContentAttachment.mutate({
 *    pathVars: {
 *      attachmentId
 *    }
 *  })
 *
 */

export const useDeleteAcademyStepContentAttachmentMutation =
  mutationHookFactory<
    null,
    {attachmentId: number | string}, // path variables
    null
  >(
    'useDeleteAcademyStepContentAttachmentMutation',
    'delete',
    ({attachmentId}) =>
      `academies/delete-academy-step-content-attachment/${attachmentId}`,
    {payloadSchema: undefined}
  );

/*
|--------------------------------------------------------------------------
|  delete-academy-user/{academyId}/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteAcademyUserMutation} from '@generated/hooks';
 *
 *  const deleteAcademyUser = useDeleteAcademyUserMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteAcademyUser.mutate({
 *    pathVars: {
 *      academyId,
 *      userCompanyId
 *    }
 *  })
 *
 */

export const useDeleteAcademyUserMutation = mutationHookFactory<
  null,
  {academyId: number; userCompanyId: number}, // path variables
  boolean // return type
>(
  'useDeleteAcademyUserMutation',
  'delete',
  ({academyId, userCompanyId}) =>
    `academies/delete-academy-user/${academyId}/${userCompanyId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  delete-self-academy-user/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDeleteSelfAcademyUserMutation} from '@generated/hooks';
 *
 *  const deleteSelfAcademyUser = useDeleteSelfAcademyUserMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  deleteSelfAcademyUser.mutate({
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 */

export const useDeleteSelfAcademyUserMutation = mutationHookFactory<
  null,
  {academyId: number | string}, // path variables
  boolean // return type
>(
  'useDeleteSelfAcademyUserMutation',
  'delete',
  ({academyId}) => `academies/delete-self-academy-user/${academyId}`,
  {payloadSchema: undefined}
);
