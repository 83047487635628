import {useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {AcademyVM} from '@models/serverModels';
import LearnInTable, {
  numberSorter,
  renderProgressBar,
  stringSorter,
} from '@components/reusable/LearnInTable';
import {StatusTag} from '@blocks/LearnInTag';
import {AcademyStepVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div``;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface UserDrillDownProps {
  dataSource: AcademyVM[];
  onClickRow?: (record?: AcademyStepVM, rowIndex?: number) => void;
  nestedTable?: {
    dataSource: Record<number, AcademyStepVM[]>;
    onExpand?: (expanded: boolean, record: AcademyStepVM) => void;
    onClickRow?: (record?: AcademyStepVM, rowIndex?: number) => void;
  };
}

function UserDrillDown({
  dataSource,
  onClickRow,
  nestedTable,
}: UserDrillDownProps) {
  const columns = useMemo(() => {
    return [
      {
        title: i18n.t(k.LEVEL),
        dataIndex: 'title',
        key: 'title',
        sorter: stringSorter<string>('title'),
        width: '65%',
      },
      {
        title: i18n.t(k.PROGRESS),
        dataIndex: 'percentComplete',
        key: 'percentComplete',
        sorter: numberSorter('percentComplete'),
        render: renderProgressBar,
        width: '35%',
      },
    ];
  }, []);

  const nestedColumns = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'title',
        key: 'title',
        sorter: stringSorter<string>('title'),
      },
      {
        title: i18n.t(k.STATUS__STATUS),
        dataIndex: 'status',
        key: 'status',
        render: ({
          displayStatus,
          displayStatusWarningLevel,
          completedPercent,
          completedOn,
          skippedOn,
        }) => {
          return (
            <StatusTag
              displayStatus={displayStatus}
              displayStatusWarningLevel={displayStatusWarningLevel}
              completedPercent={completedPercent}
              completedOn={completedOn}
              skippedOn={skippedOn}
            />
          );
        },
        width: '35%',
      },
    ];
  }, []);

  return (
    <Container>
      <LearnInTable
        dataSource={dataSource}
        columns={columns}
        onClickRow={onClickRow}
        nestedTable={
          !!nestedTable
            ? {
                ...nestedTable,
                columns: nestedColumns,
                showHeader: false,
              }
            : null
        }
        noDataStyle={{height: 0}}
      />
    </Container>
  );
}

export default UserDrillDown;
