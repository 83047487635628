import {useState, useMemo} from 'react';
import CustomProgramProjectParticipantsDrawer from './CustomProgramProjectParticipantsDrawer';
import {ProjectAdminVM} from '@models/serverModels';
import {getSubmitContentFeedbackRm} from '@store/apiEndpoints/customProgram/mutations';
import ProjectResponseDrawer from './ProjectResponseDrawer';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {NewFeedbackPayload} from '@models/serverModels';
import {notify} from '@components/user/notifications';
import {useGetProjectSubmissionAttachmentUrlQuery} from '@hooks/apiEndpoints/customProgram/queries';
import {
  useUserQuery,
  useCustomProgramProjectSubmissionsQuery,
} from '@generated/hooks';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramProjectParticipantsDrawerContainerProps {
  onClose: () => void;
  project: ProjectAdminVM;
}

function CustomProgramProjectParticipantsDrawerContainer({
  project,
  onClose,
}: CustomProgramProjectParticipantsDrawerContainerProps) {
  const [submissionForViewTextResponseId, setSubmissionForViewTextResponseId] =
    useState<undefined | number>();
  const [submissionUploadId, setSubmissionUploadId] = useState<
    undefined | number
  >();

  const {data: user} = useUserQuery(null);

  // Get Project Submissions
  const {
    data: customProgramProjectSubmissions,
    refetch: refetchCustomProgramProjectSubmissions,
  } = useCustomProgramProjectSubmissionsQuery(
    {
      projectId: project?.id,
    },
    {
      enabled: !!project?.id,
    }
  );

  const submitContentFeedbackMutation = useMutation(
    (feedback: string) => {
      if (!feedback) return;
      const getSubmitContentFeedback = getSubmitContentFeedbackRm({
        feedback,
        projectSubmissionId: submissionForViewTextResponseId,
      });
      return simpleMutationFn<NewFeedbackPayload[]>(
        getSubmitContentFeedback.path,
        getSubmitContentFeedback.payload
      );
    },
    {
      onSuccess: () => {
        refetchCustomProgramProjectSubmissions();
        notify.submitCustomProgramProjectFeedbackSuccess();
      },
      onError: () => {
        notify.submitCustomProgramProjectFeedbackError();
      },
    }
  );

  useGetProjectSubmissionAttachmentUrlQuery(submissionUploadId, {
    enabled: !!submissionUploadId,
    onSuccess: (url) => {
      if (!!url) {
        window.open(url, '_blank').focus();
        setSubmissionUploadId(undefined);
      }
    },
  });

  const submissionForViewTextResponse = useMemo(() => {
    return customProgramProjectSubmissions?.find(
      ({id}) => id === submissionForViewTextResponseId
    );
  }, [submissionForViewTextResponseId, customProgramProjectSubmissions]);

  return (
    <>
      <CustomProgramProjectParticipantsDrawer
        dataSource={customProgramProjectSubmissions || []}
        maxSubmitted={customProgramProjectSubmissions?.length || 0}
        onClickUploadLink={setSubmissionUploadId}
        onClose={onClose}
        projectDueDate={project?.dueDateUtc}
        showFeedbackColumn={true}
        title={project?.title || ''}
        totalSubmitted={customProgramProjectSubmissions?.length || 0}
        visible={!!project}
        onClickRow={(row) => setSubmissionForViewTextResponseId(row?.id)}
        onClickSubmitFeedback={setSubmissionForViewTextResponseId}
        showRowHoverStyle={() => true}
      />
      {submissionForViewTextResponseId && (
        <ProjectResponseDrawer
          companyId={user?.companyId}
          allowFeedback
          submission={submissionForViewTextResponse}
          visible={!!submissionForViewTextResponse}
          onClose={() => setSubmissionForViewTextResponseId(undefined)}
          onClickUploadLink={setSubmissionUploadId}
          onClickSubmitFeedback={async (feedbackText) => {
            await submitContentFeedbackMutation.mutateAsync(feedbackText);
          }}
        />
      )}
    </>
  );
}

export default CustomProgramProjectParticipantsDrawerContainer;
