import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from 'react-query';
import {Drilldown} from './Drilldown';
import {getTeamInsightsDrilldownRq} from '@store/apiEndpoints';
import {simpleQueryFn} from '@store/queryClient';
import {TeamInsightsDetailVM} from '@models/serverModels';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {updatePageTitle} from '@hooks/useSetPageTitle';
import {TeamInsightEmployeeDetailVm} from '@generated/interfaces';
import {useAuth} from '@utils/oidc-auth-wrapper';

export const DrilldownContainer = ({title}: {title?: string}) => {
  const {userId} = useParams();
  const drilldownRq = getTeamInsightsDrilldownRq(userId);
  const drilldownQuery = useQuery<TeamInsightEmployeeDetailVm>(
    drilldownRq.queryKey,
    () => simpleQueryFn(drilldownRq.path)
  );
  const {user} = useAuth();
  const {isFeatureFlagOn} = useFeatureFlags();
  const exchangeRate = useExchangeRate(MONEY_DEFAULT.currency, user?.currency);

  useEffect(() => {
    !!drilldownQuery.data && drilldownQuery.refetch();
    updatePageTitle(title);
  }, []);

  return (
    <div>
      {!!drilldownQuery.data ? (
        <Drilldown
          drillDownQuery={drilldownQuery}
          data={drilldownQuery.data}
          user={user}
          exchangeRate={exchangeRate}
          showReportStatusTag={isFeatureFlagOn.ReportStatusTag} // Use StatusTag component
        />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};
