import './NoCreditCard.scss';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {CreditCardDetailsVM} from '@models/api/payment/viewModels';
import {Card, Divider} from 'antd';
import {useMutation} from 'react-query';
import {getCreateCardRm} from '../../store/apiEndpoints';
import {simpleMutationFn} from '../../store/queryClient';
import {ButtonTags} from '../../components/reusable/Button/ButtonEnums';
import {LearnInButton} from '../../components/reusable/Button/Button.style';
import {notify} from '../../components/user/notifications';
import {COLORS} from '@utils/constants';

interface Props {
  planItemId: string | number;
  errors: string[];
  updateCardOverview: (data: CreditCardDetailsVM) => void;
}

const NoCardTitleBar = styled.div`
  justify-content: space-between;
  align-items: center;
`;

const NoCardTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.75rem !important;
  color: #1b1c23;
  margin-bottom: 5px;
`;

const NoCardContainer = styled.div`
  text-align: center;
  margin: auto;
  width: 50%;
`;

const NoCardErrors = styled.div`
  color: ${COLORS.Neutral600};
  margin-bottom: 15px;
`;

const NoCreditCard = ({planItemId, errors, updateCardOverview}: Props) => {
  const createCardMutation = useMutation(
    ({planItemId}: {planItemId: string | number}) => {
      const requestItemPreApprovalRm = getCreateCardRm(planItemId);
      return simpleMutationFn<CreditCardDetailsVM>(
        requestItemPreApprovalRm.path,
        requestItemPreApprovalRm.payload
      );
    },
    {
      onSuccess: (data) => {
        updateCardOverview(data);
      },
      onError: (error) => {
        notify.restoreCardError();
        console.log('Unable to restore virtual card', error);
      },
    }
  );

  return (
    <Card role="article" aria-labelledby="no-card-title">
      <NoCardTitleBar>
        <NoCardTitle id="no-card-title">
          {i18n.t(k.CARD__YOUR_CARD)}
        </NoCardTitle>
        <div>{i18n.t(k.CARD__PROGRAM_DESCRIPTION)}</div>
      </NoCardTitleBar>
      <Divider className="no-card-page-title-divider" />
      <NoCardContainer>
        <NoCardErrors>
          {errors ? (
            <div>
              <NoCardTitle>{i18n.t(k.CARD__ERROR_GENERATING)}</NoCardTitle>
              {errors.map((err, i: number) => (
                <div key={i}>{err}</div>
              ))}
            </div>
          ) : (
            <div>
              <NoCardTitle>{i18n.t(k.ERROR__TRY_AGAIN)}</NoCardTitle>
              <div>{i18n.t(k.CARD__ERROR_CONTACT_ADMIN)}</div>
            </div>
          )}
        </NoCardErrors>
        <LearnInButton
          className="restore-card-button"
          tag={ButtonTags.Secondary}
          onClick={() => {
            createCardMutation.mutate({planItemId});
          }}>
          {i18n.t(k.CARD__RESTORE_YOUR_CARD)}
        </LearnInButton>
      </NoCardContainer>
    </Card>
  );
};

export default NoCreditCard;
