import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {
  formatDescriptionEmailAndUrlToLink,
  formatEmailAndUrlToLink,
} from '@utils/stringUtils';
import {QuillHtmlViewer} from '@components/admin/pages/approvals/components/QuillHtmlViewer';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {
  ApartmentOutlined,
  CheckCircleOutlined,
  InfoCircleOutlined,
  LaptopOutlined,
} from '@ant-design/icons';
import RocketOutlined from '@ant-design/icons/lib/icons/RocketOutlined';
import {UserGroup} from '@assets/svg/UserGroup';
import {localizeGeneric} from '@utils/l10nUtils';
import {Breakpoints} from '@utils/responsiveUtils';
import {useEffect, useState} from 'react';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const OVERVIEW_MAX_WIDTH = 1254;
export const OVERVIEW_SIDE_CARD_WIDTH = 313;
export const OVERVIEW_PANEL_DEFAULT_WIDTH = 600;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LongDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
`;

const SectionTitle = styled.div`
  align-items: center;
  color: ${COLORS.Neutral950};
  display: flex;
  font-size: 1.25rem;
  font-weight: 500;
  gap: 8px;
  line-height: 1.5rem;
  margin-bottom: 25px;
  svg {
    background: ${COLORS.Neutral50};
    fill: ${COLORS.Red500};
    height: 16px;
    width: 16px;
  }
`;

const StyledLearnInContainer = styled(LearnInContainer)<{
  isRedesignOn: boolean;
}>`
  max-width: 100%;
  @media (min-width: ${OVERVIEW_MAX_WIDTH}px) {
    max-width: ${(props) =>
      props.isRedesignOn ? `${OVERVIEW_PANEL_DEFAULT_WIDTH}px` : '100%'};
  }
  width: 100%;
`;

export const OverviewTabWrapper = styled.div<{
  isRedesignOn: boolean;
  isNotFinanceIncentive: boolean;
}>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  @media (max-width: ${OVERVIEW_MAX_WIDTH - 1}px) {
    flex-direction: ${(props) =>
      props.isRedesignOn && props.isNotFinanceIncentive ? 'column' : 'row'};
  }
  @media (max-width: ${Breakpoints.Tablet}px) {
    ${(props) =>
      props.isRedesignOn && props.isNotFinanceIncentive && 'padding-top: 16px;'}
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IOverviewTabProps {
  sections: {title: string; content: string}[];
  longDescription: string;
  isRedesignOn: boolean;
}

function OverviewTab({
  sections,
  longDescription,
  isRedesignOn,
}: IOverviewTabProps) {
  //localSections and localLongDescription are created empty and updated to trigger a re-render so ReactQuill shows the correct format
  const [localSections, setLocalSections] =
    useState<{title: string; content: string}[]>();
  const [localLongDescription, setLocalLongDescription] = useState<string>();
  useEffect(() => {
    setLocalSections(sections);
  }, [longDescription]);
  useEffect(() => {
    setLocalLongDescription(longDescription);
  }, [sections]);

  const programSections = {
    about: {
      icon: <LaptopOutlined aria-hidden="true" />,
      title: i18n.t(k.GENERIC__ABOUT),
    },
    outcomes: {
      icon: <RocketOutlined aria-hidden="true" />,
      title: i18n.t(k.GENERIC__OUTCOME__PLURAL),
    },
    curriculum: {
      icon: <ApartmentOutlined aria-hidden="true" />,
      title: i18n.t(k.PROGRAM__CUSTOM__CURRICULUM),
    },
    instructors: {
      icon: <UserGroup aria-hidden="true" />,
      title: i18n.t(k.GENERIC__INSTRUCTOR__PLURAL),
    },
    prerequisites: {
      icon: <CheckCircleOutlined aria-hidden="true" />,
      title: i18n.t(k.GENERIC__PREREQUISITES),
    },
    info: {
      icon: <InfoCircleOutlined aria-hidden="true" />,
      title: i18n.t(k.GENERIC__INFORMATION__OTHER),
    },
  };
  const formattedLongDescription =
    formatEmailAndUrlToLink(localLongDescription);
  return (
    <Container>
      <StyledLearnInContainer
        isRedesignOn={isRedesignOn}
        flexDirection={'column'}
        tag={ContainerTags.CommonCard}>
        <SectionTitle>
          {programSections.about.icon}
          {i18n.t(k.GENERIC__ABOUT)}
        </SectionTitle>
        <LongDescription>
          <QuillHtmlViewer
            html={formatDescriptionEmailAndUrlToLink(formattedLongDescription)}
          />
        </LongDescription>
      </StyledLearnInContainer>
      {localSections?.map(({title, content}, idx) => {
        const id = title.toLowerCase().replace(/-/g, ''); //lowercase and replace the - from pre-requisites
        const unmapped = {
          icon: null,
          title: localizeGeneric(title),
        };
        const section = programSections[id] || unmapped;
        const formattedContent = formatEmailAndUrlToLink(content);
        return id !== 'details' ? ( //Ignore the details section, since it is passed in the longDescription prop
          <StyledLearnInContainer
            isRedesignOn={isRedesignOn}
            flexDirection={'column'}
            key={`${title}${idx}`}
            tag={ContainerTags.CommonCard}>
            <SectionTitle>
              {section?.icon}
              {section?.title}
            </SectionTitle>
            <LongDescription>
              <QuillHtmlViewer
                html={
                  formattedContent &&
                  formatDescriptionEmailAndUrlToLink(formattedContent)
                }
              />
            </LongDescription>
          </StyledLearnInContainer>
        ) : null;
      })}
    </Container>
  );
}

export default OverviewTab;
