import {i18n, k} from '@i18n/translate';
import {localizeStatus} from '@utils/l10nUtils';

export {default as OverviewTab} from './OverviewTab';
export {default as ParticipantsTab} from './ParticipantsTab.container';
export {default as NominationsTab} from './NominationsTab.container';
export {default as ReviewsTab} from './ReviewsTab.container';
export {default as IncentivesTab} from './IncentivesTab';
export {default as EligibleProgramsTab} from './EligibleProgramsTab';

/**
 * Helper method to map status values returned by backend to appropriate values.
 * @param status status provided by the backend
 */
export const mapStatusForDisplay = (status?: string) => {
  if (!status) {
    return status;
  }
  return localizeStatus(status).toLocaleLowerCase();
};
