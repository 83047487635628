import {AttachmentType} from './../enums';
import {AttachmentMultipartUploadPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AttachmentMultipartUploadPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAttachmentMultipartUploadPayloadMockData(
  args?: Partial<AttachmentMultipartUploadPayload>
): AttachmentMultipartUploadPayload {
  return {
    attachmentId: faker.datatype.number(), // optional
    chunkCheckSum: faker.word.noun(),
    chunkNumber: faker.datatype.number(),
    chunkSize: faker.datatype.number(),
    description: faker.lorem.lines(),
    file: undefined,
    fileName: faker.name.fullName(),
    totalChunks: faker.datatype.number(),
    totalSize: faker.datatype.number(),
    type: AttachmentType.File,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AttachmentMultipartUploadPayloadMockData: AttachmentMultipartUploadPayload =
  {
    attachmentId: 1, // optional
    chunkCheckSum: 'string',
    chunkNumber: 123,
    chunkSize: 123,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    file: undefined,
    fileName: 'Bobby Johnson',
    totalChunks: 123,
    totalSize: 123,
    type: AttachmentType.File,
  };
