import './LearnInDrawer.scss';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Drawer} from 'antd';
import {DrawerProps} from 'antd/lib/drawer';
import {Breakpoints, getResponsiveDrawerSize} from '@utils/responsiveUtils';
import {CloseCircleOutlined} from '@ant-design/icons';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

// This makes space for the Help Chat widget in the bottom right
export const DrawerFormContentContainer = styled.div`
  padding-right: 0px;
`;

export const FormColumnsContainer = styled.div`
  display: flex;
  margin-left: max(10%, 80px);
  @media (max-width: ${Breakpoints.Mobile}px) {
    flex-direction: column;
    margin-left: unset;
  }
`;

export const FormColumn = styled.div`
  max-width: 50%;
  &:not(:last-child) {
    padding-right: max(10%, 80px);
  }
  @media (max-width: ${Breakpoints.Mobile}px) {
    max-width: unset;

    &:not(:last-child) {
      padding-right: unset;
    }
  }
`;

export const FooterButtonsContainer = styled(DrawerFormContentContainer)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  button {
    margin-left: 16px;
  }
`;

const TitleWithCloseButton = styled.div`
  display: flex;
  align-items: center;
`;

const CloseButtonWrapper = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const CloseButton = styled(LearnInButton)`
  padding: 0;
  display: inline-block;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ILearnInDrawerProps extends DrawerProps {
  responsiveSize?: string | number;
  children: React.ReactNode;
  closeButtonPlacement?: 'left' | 'right';
  onClose: () => void;
  visible?: boolean;
}

function LearnInDrawer({
  responsiveSize,
  children,
  closeButtonPlacement,
  onClose,
  visible,
  ...rest
}: ILearnInDrawerProps) {
  let className = 'learnin-drawer';
  if (!rest?.placement || rest?.placement === 'right') {
    className += ' right';
  }

  const contentRef = React.useRef(null);
  React.useLayoutEffect(() => {
    if (!contentRef.current) {
      return;
    }

    const parent = contentRef.current.closest('.ant-drawer-content');
    parent?.setAttribute('aria-label', rest.title as string);
  }, [children, rest.title]);

  const closeButtonProps = {
    closeIcon: null, // We're customizing it in `title`
    title: (
      <TitleWithCloseButton>
        <div>{rest.title}</div>
        <CloseButtonWrapper>
          <CloseButton
            iconSize={'L'}
            tag={ButtonTags.SecondaryBlackRemovedBorder}
            icon={
              <span aria-label={i18n.t(k.CTA__CLOSE)}>
                <CloseCircleOutlined aria-hidden="true" />
              </span>
            }
            onClick={onClose}
          />
        </CloseButtonWrapper>
      </TitleWithCloseButton>
    ),
  };

  return (
    <Drawer
      className={className}
      height={getResponsiveDrawerSize(responsiveSize)}
      width={getResponsiveDrawerSize(responsiveSize)}
      onClose={onClose}
      open={visible}
      {...rest}
      closeIcon={<CloseCircleOutlined tabIndex={0} />}
      {...(closeButtonPlacement === 'right' && closeButtonProps)}>
      <div ref={contentRef}>{children}</div>
    </Drawer>
  );
}

export default LearnInDrawer;

interface FooterProps {
  onClose: () => void;
  onOk: () => void;
  loading?: boolean;
  submitText?: string;
  cancelText?: string;
  disableButton?: boolean;
}

export const renderFooter = ({
  onClose,
  onOk,
  loading,
  submitText = i18n.t(k.CTA__SUBMIT),
  cancelText = i18n.t(k.CTA__CANCEL),
  disableButton,
}: FooterProps) => (
  <FooterButtonsContainer>
    <LearnInButton
      tag={ButtonTags.SecondaryBlackRemovedBorder}
      onClick={onClose}>
      {cancelText}
    </LearnInButton>
    <LearnInButton
      loading={loading}
      disabled={disableButton}
      tag={ButtonTags.DrawerFooterPrimary}
      onClick={onOk}>
      {submitText}
    </LearnInButton>
  </FooterButtonsContainer>
);
