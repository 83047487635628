import {CurrencyCode} from './../enums';
import {ProgramAdminVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ProgramAdminVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramAdminVMMockData(
  args?: Partial<ProgramAdminVM>
): ProgramAdminVM {
  return {
    addedBy: faker.word.noun(),
    available: faker.datatype.boolean(),
    cost: faker.datatype.number(),
    currency: CurrencyCode.USD,
    duration: faker.word.noun(),
    featured: faker.datatype.boolean(),
    id: faker.datatype.number(),
    programTitle: faker.company.catchPhrase(),
    programType: faker.word.noun(), // optional
    provider: faker.word.noun(),
    providerLogo: faker.word.noun(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramAdminVMMockData: ProgramAdminVM = {
  addedBy: 'string',
  available: true,
  cost: 123,
  currency: CurrencyCode.USD,
  duration: 'string',
  featured: true,
  id: 1,
  programTitle: 'Advanced Web Design',
  programType: 'string', // optional
  provider: 'string',
  providerLogo: 'string',
};
