import {ReactNode} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Avatar} from 'antd';

import {getAvatarColorwaysStyles} from '@utils/color';
import {getInitialsOfName} from '@components/providers/utils';
import {COLORS} from '@utils/constants';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {CustomProgramUserResponse} from '@generated/interfaces';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {pxToRem} from '@utils/styleMixins';

const DrawerBody = styled.div`
  border-radius: 8px;
  overflow-y: scroll;
  flex-grow: 1;
  overflow-y: scroll;
  padding-bottom: 80px;
`;

const Name = styled.p`
  color: ${COLORS.Neutral950};
  font-style: normal;
  font-weight: 700;
  font-size: ${pxToRem(12, 16)};
  line-height: ${pxToRem(12, 16)};
  margin: 0;
`;

const Email = styled.small`
  display: block;
  color: ${COLORS.Neutral700};
  font-size: ${pxToRem(12, 16)};
  line-height: ${pxToRem(20, 16)};
`;

const MemberContainer = styled.div`
  padding: 8px 16px;
  border-bottom: 1px solid ${COLORS.Neutral100};
`;

const MemberWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 0px;
  height: 36px;
`;

const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  position: fixed;
  bottom: 16px;
  right: 16px;
`;

const LoaderContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-top: 50px;
`;

const EmptyMessage = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 100px;
`;

interface MembersDrawerProps {
  visible: boolean;
  setShowDrawer: (show: boolean) => void;
  title: string;
  members?: CustomProgramUserResponse[];
  searchComponent: ReactNode;
  isLoading?: boolean;
  paginationComponent: ReactNode;
  membersCount: number | undefined;
  canShowEmployeeEmail?: boolean;
}

export function MembersDrawer({
  visible,
  setShowDrawer,
  title,
  members,
  searchComponent,
  isLoading,
  paginationComponent,
  membersCount,
  canShowEmployeeEmail,
}: MembersDrawerProps) {
  return (
    <LearnInDrawer
      push={false}
      title={`${title} (${membersCount})`}
      placement="right"
      closeButtonPlacement="right"
      onClose={() => setShowDrawer(false)}
      size="large"
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        backgroundColor: COLORS.White,
      }}
      visible={visible}>
      <DrawerBody>
        {searchComponent}
        {!isLoading ? (
          <>
            {members?.map((member, index) => {
              return member ? (
                <MemberContainer key={index}>
                  <MemberWrapper>
                    <Avatar
                      style={{
                        ...getAvatarColorwaysStyles(member.userName.length),
                        flexShrink: 0,
                        fontWeight: '700',
                      }}>
                      {getInitialsOfName(member.userName)}
                    </Avatar>

                    <div>
                      <Name>{member.userName}</Name>
                      {canShowEmployeeEmail ? (
                        <Email>{member.email}</Email>
                      ) : null}
                    </div>
                  </MemberWrapper>
                </MemberContainer>
              ) : null;
            })}
            {Array.isArray(members) && members.length === 0 && (
              <EmptyMessage>
                {i18n.t(k.GENERIC__NO_SEARCH_RESULTS)}
              </EmptyMessage>
            )}
          </>
        ) : (
          <LoaderContainer>
            <LoadingSpinner size="large" />
          </LoaderContainer>
        )}
        <PaginationContainer>{paginationComponent}</PaginationContainer>
      </DrawerBody>
    </LearnInDrawer>
  );
}
