import {LearningResourceType} from './../enums';
import {LearningResourceApprovalRequest} from './../interfaces';
import {
  PreApprovalApplicationMockData,
  getPreApprovalApplicationMockData,
} from './PreApprovalApplication.mock';
import {faker} from '@faker-js/faker';

// Interface: LearningResourceApprovalRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLearningResourceApprovalRequestMockData(
  args?: Partial<LearningResourceApprovalRequest>
): LearningResourceApprovalRequest {
  return {
    applicationDetails: getPreApprovalApplicationMockData(),
    attachmentIds: [String(faker.datatype.number())],
    resourceType: LearningResourceType.Book,
    userBudgetId: faker.datatype.number(), // optional
    userPlanResourceId: faker.datatype.number(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LearningResourceApprovalRequestMockData: LearningResourceApprovalRequest =
  {
    applicationDetails: PreApprovalApplicationMockData,
    attachmentIds: ['1'],
    resourceType: LearningResourceType.Book,
    userBudgetId: 1, // optional
    userPlanResourceId: 1, // optional
  };
