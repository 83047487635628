import {CheckCircleFilled} from '@ant-design/icons';
import styled, {css} from 'styled-components';
import {COLORS} from '@utils/constants';
import {noop} from 'lodash';

export enum RadioButtonIcons {
  People = 'People',
}

const RadioButtonWrapper = styled.button<{isSelected?: boolean}>`
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid ${COLORS.Neutral200};
  padding: 16px;
  text-align: left;
  width: 100%;
  .title {
    font-weight: 500;
    font-size: 1rem;
    color: ${COLORS.Neutral900};
    margin-bottom: 4px;
  }
  .content {
    color: ${COLORS.Neutral900};
    font-weight: normal;
    font-size: 0.75rem;
  }
  .icon {
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .icon:last-child {
    color: ${COLORS.Blue800};
    flex-grow: 1;
    padding-left: 0.5rem;
    ${({hasCheckmark}) => {
      return (
        !hasCheckmark &&
        css`
          visibility: hidden;
        `
      );
    }}
    svg {
      margin-left: auto;
    }
  }
  &:hover {
    background: ${COLORS.Neutral50};
  }
  &:focus {
    background: ${COLORS.Blue50};
  }

  ${({isSelected}) => {
    return (
      isSelected &&
      css`
        cursor: default;
        background: ${COLORS.Blue50};
        border: 1px solid ${COLORS.Blue800};
        &:hover {
          background: ${COLORS.Blue50};
        }
      `
    );
  }}
`;

interface IRadioButtonProps {
  title: string;
  content: string;
  id?: string;
  isSelected?: boolean;
  onClick: () => void;
  Icon: any;
  hasCheckmark?: boolean;
  required?: boolean;
}

function RadioButton({
  title,
  content,
  Icon,
  id,
  hasCheckmark = false,
  isSelected = false,
  onClick = noop,
  required,
}: IRadioButtonProps) {
  const enterKey = 13;
  return (
    <RadioButtonWrapper
      id={id}
      tabIndex={0}
      role="radio"
      aria-checked={isSelected}
      onClick={onClick}
      onKeyDown={(e: any) => e.keyCode === enterKey && onClick()}
      hasCheckmark={hasCheckmark}
      isSelected={isSelected}
      required={required}>
      <Icon
        aria-hidden="true"
        className="icon"
        style={{marginRight: '16px', fontSize: '1.875rem'}}
      />
      <div>
        <div className="title">{title}</div>
        <div className="content">{content}</div>
      </div>
      <CheckCircleFilled aria-hidden="true" className="icon" />
    </RadioButtonWrapper>
  );
}

export default RadioButton;
