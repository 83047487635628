import {AcademyStepType} from './../enums';
import {AcademyOverviewStepUserReportVm} from './../interfaces';
import {
  AcademyOverviewStepUserCompletionReportVmMockData,
  getAcademyOverviewStepUserCompletionReportVmMockData,
} from './AcademyOverviewStepUserCompletionReportVm.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyOverviewStepUserReportVm

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyOverviewStepUserReportVmMockData(
  args?: Partial<AcademyOverviewStepUserReportVm>
): AcademyOverviewStepUserReportVm {
  return {
    academyStepId: faker.datatype.number(),
    academyStepName: faker.name.fullName(),
    academyStepType: AcademyStepType.MarketplaceProgram,
    completionPercentage: faker.datatype.number(),
    order: faker.datatype.number(),
    totalCompleted: faker.datatype.number(),
    totalOutstanding: faker.datatype.number(),
    totalRsvpNo: faker.datatype.number(),
    totalRsvpYes: faker.datatype.number(),
    users: [getAcademyOverviewStepUserCompletionReportVmMockData()],
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyOverviewStepUserReportVmMockData: AcademyOverviewStepUserReportVm =
  {
    academyStepId: 1,
    academyStepName: 'Bobby Johnson',
    academyStepType: AcademyStepType.MarketplaceProgram,
    completionPercentage: 123,
    order: 123,
    totalCompleted: 123,
    totalOutstanding: 123,
    totalRsvpNo: 123,
    totalRsvpYes: 123,
    users: [AcademyOverviewStepUserCompletionReportVmMockData],
  };
