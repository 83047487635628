import React from 'react';
import {BudgetCardContainerProps} from './BudgetCard.interfaces';
import BudgetCardView from './BudgetCard.view';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {FinanceType} from '@generated/enums';
import {
  useFinanceLearningBudgetsQuery,
  usePersonalizationQuery,
  useUserQuery,
} from '@generated/hooks';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import {useNavigate} from 'react-router-dom';

function BudgetCardContainer({
  showExploreProgramButton,
  setShowReimbursement,
  userProgramPlan,
  hasProgramsOrResources,
}: BudgetCardContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const isTranslatePlbFieldsOn = isFeatureFlagOn.TranslatePlbFields;

  const navigate = useNavigate();

  const {data: user} = useUserQuery(null);
  const {data: personalization} = usePersonalizationQuery(null);
  const exchangeRate = useExchangeRate(MONEY_DEFAULT.currency, user?.currency);

  const {
    data: financeLearningBuds,
    isLoading: isFinanceLearningBudsLoading,
    isSuccess: isFinanceLearningBudsSuccess,
    isError: isFinanceLearningBudsError,
  } = useFinanceLearningBudgetsQuery(null);

  const showCreditCard =
    financeLearningBuds?.financeType === FinanceType.Prepayment;

  if (isFinanceLearningBudsLoading) {
    return (
      <LearnInContainer tag={ContainerTags.CommonCard}>
        <LoadingSpinner />
      </LearnInContainer>
    );
  }

  return (
    <BudgetCardView
      hasProgramsOrResources={hasProgramsOrResources}
      userProgramPlan={userProgramPlan}
      direction={showCreditCard ? 'vertical' : 'horizontal'}
      exchangeRate={exchangeRate}
      financeLearningBuds={financeLearningBuds}
      isFinanceLearningBudsError={isFinanceLearningBudsError}
      isFinanceLearningBudsLoading={isFinanceLearningBudsLoading}
      isFinanceLearningBudsSuccess={isFinanceLearningBudsSuccess}
      isTranslatePlbFieldsOn={isTranslatePlbFieldsOn}
      navigate={navigate}
      personalization={personalization}
      setShowReimbursement={setShowReimbursement}
      showCreditCard={showCreditCard}
      showExploreProgramButton={showExploreProgramButton}
      user={user}
    />
  );
}

export default BudgetCardContainer;
