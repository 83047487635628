import {i18n, k} from '@i18n/translate';
import {Empty} from 'antd';

function NoDataImage() {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={i18n.t(k.GENERIC__EMPTY_STATUS__SIMPLE)}
    />
  );
}
export default NoDataImage;
