import '../UserHome.scss';
import {Card, Col, Row} from 'antd';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import {useEffect, useState} from 'react';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import ProviderLogo from '@blocks/ProviderLogo';
import {localizeIfLocated} from '@utils/l10nUtils';

export default function CarouselCard({
  backgroundImage,
  logo,
  subtitle,
  title,
}: {
  backgroundImage: string;
  key: number;
  logo: string;
  subtitle: string;
  title: string;
}) {
  // to check window size and based on that setting ellipsis rows value
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', changeWidth);
  }, []);

  const changeWidth = () => {
    setWidth(window.innerWidth);
  };
  return (
    <Card
      role="article"
      aria-label={`${title}`}
      style={{
        width: '100%',
        border: '1px solid transparent',
        borderRadius: '10px',
        boxShadow: '0.5px 0.5px 10px #e0e0e0',
      }}
      cover={
        <img
          src={backgroundImage || getPlaceholderImgSrc(title?.length)}
          className="carousel-card-cover-img"
          style={{
            height: '180px',
            objectFit: 'cover',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
          }}
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = getPlaceholderImgSrc(title?.length);
          }}
          alt=""
        />
      }>
      <Row className="carousel-card-body">
        <Col xs={8} sm={6}>
          <ProviderLogo provider={subtitle} providerLogo={logo} size={60} />
        </Col>
        <Col xs={16} sm={18}>
          <Text className={'carousel-card-subtitle'}>
            {localizeIfLocated(subtitle)}
          </Text>
          <Title
            level={3}
            className={'carousel-card-title'}
            ellipsis={width <= 700 ? {rows: 3} : {rows: 2}}>
            {title}
          </Title>
        </Col>
      </Row>
    </Card>
  );
}
