import {ApprovalRankType, ApprovalStatus, ApprovalType} from './../enums';
import {ResourceApprovalRequestVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ResourceApprovalRequestVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getResourceApprovalRequestVMMockData(
  args?: Partial<ResourceApprovalRequestVM>
): ResourceApprovalRequestVM {
  return {
    amount: faker.datatype.number(),
    amountOverLimit: faker.datatype.number(), // optional
    amountType: String(faker.datatype.number()),
    id: faker.datatype.number(),
    manager: faker.word.noun(),
    rank: ApprovalRankType.Initial,
    rejectedById: String(faker.datatype.number()), // optional
    requestedBy: faker.word.noun(),
    requestedDate: String(faker.date.past()),
    status: ApprovalStatus.Pending,
    title: faker.company.catchPhrase(),
    type: ApprovalType.PLAN_ITEM,
    uniqueId: String(faker.datatype.number()),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ResourceApprovalRequestVMMockData: ResourceApprovalRequestVM = {
  amount: 5,
  amountOverLimit: 5, // optional
  amountType: '5',
  id: 1,
  manager: 'string',
  rank: ApprovalRankType.Initial,
  rejectedById: '1', // optional
  requestedBy: 'string',
  requestedDate: '2023-03-29T22:17:19.738072',
  status: ApprovalStatus.Pending,
  title: 'Advanced Web Design',
  type: ApprovalType.PLAN_ITEM,
  uniqueId: '1',
};
