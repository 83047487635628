import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInTable, {
  TableColLink,
  stringSorter,
  DefaultSortOrder,
  dateTimeSorter,
} from '@components/reusable/LearnInTable';
import {ContributorPermissionType} from '@models/clientEnums';
import {CustomizableLearningCategory} from '@generated/enums';
import {
  BarChartOutlined,
  EditOutlined,
  ExperimentOutlined,
} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import {parseToLocalMoment, DATE_FORMAT} from '@utils/timeUtils';
import {PermissionAttributes} from '@models/serverModels';
import {Avatar} from 'antd';

const StyledTableContainer = styled.div`
  tr.ant-table-row,
  .ant-table-tbody > tr > td {
    padding: 0px !important;
    height: 45px;
  }
`;

const IconButton = styled.button`
  background: none;
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 8px 10px;
  cursor: pointer;
  &:hover {
    background: ${COLORS.Neutral100};
    path {
      fill: ${COLORS.Blue800} !important;
    }
  }
  path {
    fill: ${COLORS.Neutral600} !important;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }
`;

const iconStyle = {
  fontSize: '1.25rem',
};

export interface ManagerPermissionsTableRow extends PermissionAttributes {
  name: string;
  userCompanyId: number;
  newSubmissionCount?: number;
  startDateUtc?: string;
  endDateUtc?: string;
}

interface ManagerPermissionsTableProps {
  dataSource: ManagerPermissionsTableRow[];
  learningResourceType: CustomizableLearningCategory;
  onClickEdit: (id: number, parentCustomProgramId: number | undefined) => void;
  onClickReviewProjects: (id: number) => void;
  onClickTitle: (id: number) => void;
  onClickViewReports: (id: number) => void;
  showCohortColumn: boolean;
  showEditColumn: boolean;
  showReportsColumn: boolean;
  showProjectsColumn: boolean;
}

function ManagerPermissionsTable({
  dataSource,
  learningResourceType,
  onClickEdit,
  onClickReviewProjects,
  onClickTitle,
  onClickViewReports,
  showCohortColumn,
  showEditColumn,
  showReportsColumn,
  showProjectsColumn,
}: ManagerPermissionsTableProps) {
  const resourceName =
    learningResourceType === CustomizableLearningCategory.Academy
      ? i18n.t(k.ACADEMY)
      : i18n.t(k.PROGRAM);

  const columns = [
    {
      title: `${resourceName} ${i18n.t(k.NAME)}`,
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => {
        return (
          <TableColLink style={{fontSize: '0.875rem'}}>
            <button
              onClick={(e) => {
                e.preventDefault();
                onClickTitle(row.id);
              }}>
              {name}
            </button>
          </TableColLink>
        );
      },
      sorter: stringSorter('name'),
    },
    showCohortColumn && {
      title: i18n.t(k.COHORT),
      key: 'cohort',
      render: (_, row) => {
        return (
          <div>
            {parseToLocalMoment(row?.startDateUtc)?.format(
              DATE_FORMAT.MONTH_DAY_YEAR
            )}{' '}
            -{' '}
            {parseToLocalMoment(row?.endDateUtc)?.format(
              DATE_FORMAT.MONTH_DAY_YEAR
            )}
          </div>
        );
      },
      sorter: dateTimeSorter('startDateUtc'),
      defaultSortOrder: DefaultSortOrder.Descend,
    },
    showReportsColumn && {
      title: i18n.t(k.REPORT__PLURAL__VIEW),
      key: 'viewReports',
      render: (_, row) => {
        if (!row[ContributorPermissionType.canViewReport]) {
          return null;
        }
        return (
          <IconButton onClick={() => onClickViewReports(row.id)}>
            <BarChartOutlined aria-hidden="true" style={iconStyle} />
          </IconButton>
        );
      },
    },
    showEditColumn && {
      title: i18n.t(
        learningResourceType === CustomizableLearningCategory.Academy
          ? k.ACADEMY__EDIT
          : k.PROGRAM__EDIT
      ),
      key: 'edit',
      render: (_, row) => {
        if (!row[ContributorPermissionType.canEdit]) {
          return null;
        }
        return (
          <IconButton
            onClick={() => onClickEdit(row.id, row.parentCustomProgramId)}>
            <EditOutlined aria-hidden="true" style={iconStyle} />
          </IconButton>
        );
      },
    },
    showProjectsColumn && {
      title: i18n.t(k.PROJECT__REVIEW__PLURAL),
      key: 'reviewProjects',
      render: (_, row) => {
        if (!row[ContributorPermissionType.canReviewProject]) {
          return null;
        }
        return (
          <div style={{position: 'relative'}}>
            {!!row.newSubmissionCount && (
              <Avatar
                style={{
                  position: 'absolute',
                  backgroundColor: COLORS.Blue800,
                  verticalAlign: 'middle',
                  transform: 'scale(0.75) translate(22px, -10px)',
                  fontSize: '1.25rem',
                }}>
                {row.newSubmissionCount}
              </Avatar>
            )}
            <IconButton onClick={() => onClickReviewProjects(row.id)}>
              <ExperimentOutlined aria-hidden="true" style={iconStyle} />
            </IconButton>
          </div>
        );
      },
    },
  ].filter(Boolean);

  return (
    <StyledTableContainer>
      <LearnInTable columns={columns} dataSource={dataSource} />
    </StyledTableContainer>
  );
}

export default ManagerPermissionsTable;
