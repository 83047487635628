import './MaskedCreditCard.scss';
import {useState} from 'react';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import Text from 'antd/lib/typography/Text';
import {Button} from 'antd';
import ButtonLoadingSpinner from '../../elements/buttonloadingSpinner/ButtonLoadingSpinner';
import {COLORS} from '@utils/constants';
import {
  formatCurrency,
  hyphenateCreditCardNumber,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {CardInfoModal} from './CardInfoModal';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {CurrencyCode} from '@generated/enums';
import {CreditCardPaymentDetailsVM} from '@generated/interfaces';

const creditCardAspectRatio = 1.586;
const height = 200;
const width = height * creditCardAspectRatio;

const CreditCardContainer = styled.article<{background?: string}>`
  background: ${({background}) =>
    background ||
    `linear-gradient(321.03deg, #d95167 0%, ${COLORS.Red500} 91.45%)`};
  height: ${`${height}px`};
  width: ${`${width}px`};
  padding: 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
`;

const CardNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardNumberRow = styled.div`
  display: flex;
  padding-left: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
const ShowCardInformationButton = styled(Button)`
  padding: 12px 41px;
  font-family: Roboto;
  font-style: normal;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${COLORS.Blue800};
  background: ${COLORS.White};
  width: 100% !important;
  color: ${COLORS.Blue800} !important;
  border: 1px solid ${COLORS.Blue800};
  border-radius: 50px;
  margin-top: 8px;
  transition: none !important;

  &:hover,
  &:focus {
    background: ${COLORS.Blue50};
  }

  &:focus:not(:active) {
    border: 1px solid ${COLORS.White} !important;
    outline: 2px solid ${COLORS.White} !important;
    outline-offset: 2px;
  }
`;

const CardText = styled.div`
  font-family: ${(props: any) => props.fontFamily || 'Nunito Sans'};
  font-size: ${(props: any) => props.fontSize || '16px'};
  font-weight: ${(props: any) => props.fontWeight || '600'};
  font-style: normal;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  color: #ffffff !important;
`;

export interface MaskedCreditCardProps {
  cardId: string;
  balance: number;
  last4: string;
  virtualCardColor: string;
  approvalRequired: boolean;
  currencyCode: CurrencyCode;
  isCardPaymentInfoLoading: boolean;
  isCardPaymentInfoSuccess: boolean;
  isCardPaymentInfoError: boolean;
  cardPaymentInfoError: unknown;
  cardPaymentInfo: CreditCardPaymentDetailsVM;
}

const MaskedCreditCard = ({
  currencyCode,
  balance,
  last4,
  virtualCardColor,
  approvalRequired,
  isCardPaymentInfoLoading,
  isCardPaymentInfoSuccess,
  isCardPaymentInfoError,
  cardPaymentInfoError,
  cardPaymentInfo,
}: MaskedCreditCardProps) => {
  const [showCardNumber, setShowCardNumber] = useState(false);

  const exchangeRate = useExchangeRate(MONEY_DEFAULT.currency, currencyCode);

  const cardNumberHelper = () => {
    return (
      <CardNumberContainer>
        <CardNumberRow>
          <CardText className="NunitoSans" color={COLORS.White}>
            {hyphenateCreditCardNumber(`************${last4}`)}
          </CardText>
        </CardNumberRow>
        <ButtonContainer>
          <ShowCardInformationButton
            onClick={() => {
              setShowCardNumber(true);
            }}>
            {showCardNumber && isCardPaymentInfoLoading ? (
              <ButtonLoadingSpinner />
            ) : (
              i18n.t(k.CARD__SHOW_CARD_NUMBER).toLocaleUpperCase()
            )}
          </ShowCardInformationButton>
        </ButtonContainer>
      </CardNumberContainer>
    );
  };

  const renderCardNumber = () => {
    if (showCardNumber) {
      if (isCardPaymentInfoLoading || isCardPaymentInfoSuccess) {
        return cardNumberHelper();
      } else if (isCardPaymentInfoError) {
        console.error(cardPaymentInfoError);
        const errorText = cardPaymentInfoError.toString().includes('404')
          ? i18n.t(k.ERROR__CONTACT_SUPPORT)
          : i18n.t(k.ERROR__TRY_AGAIN);
        return (
          <CardNumberContainer className={'white'}>
            <CardNumberRow className="white">
              <Text className="white card-number">{errorText}</Text>
            </CardNumberRow>
          </CardNumberContainer>
        );
      }
    } else {
      return cardNumberHelper();
    }
  };

  const handleModalClose = () => {
    setShowCardNumber(false);
  };

  const formattedAmount = formatCurrency(balance, currencyCode, exchangeRate, {
    decimal: true,
  });

  const amountTextSize = formattedAmount.length > 10 ? '25px' : '40px';

  return (
    <>
      <CreditCardContainer
        aria-label={i18n.t(k.CARD__CREDIT_CARD)}
        background={virtualCardColor}>
        <BalanceContainer>
          <CardText fontFamily="Roboto" fontSize="10px" color={COLORS.White}>
            {approvalRequired
              ? i18n.t(k.GENERIC__READY_TO_SPEND).toUpperCase()
              : i18n.t(k.MONEY__REMAINING_BALANCE).toUpperCase()}
          </CardText>
          <CardText
            className="NunitoSans"
            fontWeight="800"
            fontSize={amountTextSize}
            color={COLORS.White}
            data-cy="virtualCardAmount">
            {formattedAmount}
          </CardText>
        </BalanceContainer>
        {renderCardNumber()}
      </CreditCardContainer>
      <CardInfoModal
        onClose={handleModalClose}
        cardInfo={cardPaymentInfo}
        isVisible={showCardNumber && isCardPaymentInfoSuccess}
        aria-hidden={!(showCardNumber && isCardPaymentInfoSuccess)}
      />
    </>
  );
};
export default MaskedCreditCard;
