import {UserProgressSummary} from './../enums';
import {ProgramProgressUpdateResult} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ProgramProgressUpdateResult

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramProgressUpdateResultMockData(
  args?: Partial<ProgramProgressUpdateResult>
): ProgramProgressUpdateResult {
  return {
    employeeName: faker.name.fullName(),
    id: faker.datatype.number(),
    programTitle: faker.company.catchPhrase(),
    rating: faker.datatype.number(),
    skills: faker.word.noun(),
    submittedDate: String(faker.date.past()),
    summary: UserProgressSummary.FeelingGreat, // optional
    viewedBy: faker.word.noun(),
    viewedDate: String(faker.date.past()), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramProgressUpdateResultMockData: ProgramProgressUpdateResult =
  {
    employeeName: 'Bobby Johnson',
    id: 1,
    programTitle: 'Advanced Web Design',
    rating: 123,
    skills: 'string',
    submittedDate: '2023-03-29T22:17:19.738072',
    summary: UserProgressSummary.FeelingGreat, // optional
    viewedBy: 'string',
    viewedDate: '2023-03-29T22:17:19.738072', // optional
  };
