import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import LearnInSelect from '@components/reusable/Select/Select.style';
import {
  EMAIL_PREVIEW_CONTAINER_PADDING,
  EMAIL_PREVIEW_CONTAINER_WIDTH,
} from './EmailPreview.constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';

export const EmailPreviewWrapper = styled.section`
  height: calc(100vh - 8rem);
  padding-bottom: 5rem;
  text-align: center;
  width: 100%;
`;

export const EmailPreviewIframe = styled.iframe`
  background: ${COLORS.White};
  border: none;
  border-radius: 3px;
  box-shadow: 0.125rem 0.25rem 2rem ${COLORS.Neutral300};
  box-sizing: initial;
  height: 100%;
  margin-top: 0;
  padding: 0;
  width: ${EMAIL_PREVIEW_CONTAINER_WIDTH +
  EMAIL_PREVIEW_CONTAINER_PADDING * 2}px;
`;

export const EmailPreviewActions = styled.div`
  margin: 1.5rem auto;
  padding: 0 1rem;
  text-align: left;
`;

export const EmailPreviewLearnInSelect = styled(LearnInSelect)`
  text-align: left;
  width: ${EMAIL_PREVIEW_CONTAINER_WIDTH + 84}px;
`;

export const EmailPreviewActionButton = styled(LearnInButton)`
  color: ${COLORS.Blue800};
  font-size: 1.5rem;
  display: inline-block;
  padding: 0.5rem 0;
`;

export const EmailPreviewActionButtons = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const EmailPreviewActionsInner = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 37rem;
`;
