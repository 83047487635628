import {CurrencyCode, LanguagePreference} from './../enums';
import {ProgramSearchResult} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ProgramSearchResult

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramSearchResultMockData(
  args?: Partial<ProgramSearchResult>
): ProgramSearchResult {
  return {
    actualLearningTime: String(faker.date.past()), // optional
    approvalRequested: faker.datatype.boolean(),
    approvalRequired: faker.datatype.boolean(),
    cost: faker.datatype.number(),
    currency: CurrencyCode.USD,
    id: faker.datatype.number(),
    imageLarge: faker.word.noun(),
    imageSmall: faker.word.noun(),
    initiativePurpose: faker.word.noun(),
    language: LanguagePreference.EN_US,
    licenseCost: faker.datatype.number(), // optional
    licenseCurrency: CurrencyCode.USD, // optional
    licenseExpirationDate: String(faker.date.past()), // optional
    licenseId: faker.datatype.number(),
    longDescription: faker.lorem.lines(),
    presenceType: faker.word.noun(),
    programDuration: faker.datatype.number(), // optional
    programLengthUnit: faker.word.noun(),
    programType: faker.word.noun(),
    provider: faker.word.noun(),
    providerId: faker.datatype.number(), // optional
    providerLogo: faker.word.noun(),
    ptft: faker.word.noun(),
    rank: faker.datatype.number(),
    recommendationLevel: faker.word.noun(),
    requestRemoveConfirmation: faker.datatype.boolean(),
    selected: faker.datatype.boolean(),
    shortDescription: faker.lorem.lines(),
    status: faker.word.noun(),
    timeCommitHoursWeek: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramSearchResultMockData: ProgramSearchResult = {
  actualLearningTime: '2023-03-29T22:17:19.738072', // optional
  approvalRequested: true,
  approvalRequired: true,
  cost: 123,
  currency: CurrencyCode.USD,
  id: 1,
  imageLarge: 'string',
  imageSmall: 'string',
  initiativePurpose: 'string',
  language: LanguagePreference.EN_US,
  licenseCost: 123, // optional
  licenseCurrency: CurrencyCode.USD, // optional
  licenseExpirationDate: '2023-03-29T22:17:19.738072', // optional
  licenseId: 1,
  longDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  presenceType: 'string',
  programDuration: 123, // optional
  programLengthUnit: 'string',
  programType: 'string',
  provider: 'string',
  providerId: 1, // optional
  providerLogo: 'string',
  ptft: 'string',
  rank: 123,
  recommendationLevel: 'string',
  requestRemoveConfirmation: true,
  selected: true,
  shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  status: 'string',
  timeCommitHoursWeek: 5,
  title: 'Advanced Web Design',
};
