import {useMutation, UseMutationResult} from 'react-query';
import {
  getDeleteCompanyIncentiveRm,
  getIncentivesCountRq,
} from '@store/apiEndpoints';
import queryClient, {simpleDeleteFn} from '@store/queryClient';
import {notify} from '../../../components/user/notifications';
import {IncentiveType} from '@generated/enums';

interface Props {
  onSuccess?: () => void;
  onFailure?: () => void;
  onFinished?: () => void;
}

export default function useDeleteCompanyIncentive({
  onSuccess,
  onFailure,
  onFinished,
}: Props): UseMutationResult<unknown, unknown> {
  return useMutation(
    ({
      incentiveType,
      incentiveId,
    }: {
      incentiveType: IncentiveType;
      incentiveId: number;
    }) => {
      const deleteCompanyIncentiveRm = getDeleteCompanyIncentiveRm(
        incentiveType,
        incentiveId
      );
      // no return type
      return simpleDeleteFn<undefined>(
        deleteCompanyIncentiveRm.path,
        deleteCompanyIncentiveRm.payload
      );
    },
    {
      onSuccess: (_, {incentiveType}) => {
        onSuccess && onSuccess();
        switch (incentiveType) {
          case IncentiveType.Finance:
            notify.deleteMoneyIncentiveSuccess();
            break;
          case IncentiveType.Time:
            notify.deleteTimeIncentiveSuccess();
            break;
          default:
            break;
        }
        queryClient.refetchQueries(getIncentivesCountRq().queryKey[0]);
      },
      onError: (_, {incentiveType}) => {
        onFailure && onFailure();
        switch (incentiveType) {
          case IncentiveType.Finance:
            notify.deleteMoneyIncentiveFailure();
            break;
          case IncentiveType.Time:
            notify.deleteTimeIncentiveFailure();
            break;
          default:
            break;
        }
      },
      onSettled: () => {
        onFinished && onFinished();
      },
    }
  );
}
