import CohortAdminPageDrawerContainer from './CohortAdminPageDrawer.container';
import {useParams} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CohortAdminPageDrawerWithRoutingContainerProps {
  onClose: () => void;
  visible: boolean;
  title?: string;
}

function CohortAdminPageDrawerWithRoutingContainer({
  onClose,
  visible,
  title,
}: CohortAdminPageDrawerWithRoutingContainerProps) {
  const {customProgramId, cohortId} = useParams();
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CohortAdminPageDrawerContainer
        parentCustomProgramId={parseInt(customProgramId)}
        onClose={onClose}
        cohortId={parseInt(cohortId)}
        visible={visible}
      />
    </>
  );
}

export default CohortAdminPageDrawerWithRoutingContainer;
