import {PlanItemType} from './../enums';
import {UserPlanItemVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserPlanItemVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanItemVMMockData(
  args?: Partial<UserPlanItemVM>
): UserPlanItemVM {
  return {
    id: faker.datatype.number(),
    itemType: PlanItemType.Program,
    largeImageUrl: faker.image.imageUrl(),
    preApprovalApplicationStatus: faker.word.noun(),
    provider: faker.word.noun(),
    providerLogo: faker.word.noun(),
    requestRemoveConfirmation: faker.datatype.boolean(),
    status: faker.word.noun(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanItemVMMockData: UserPlanItemVM = {
  id: 1,
  itemType: PlanItemType.Program,
  largeImageUrl: 'https://loremflickr.com/640/480',
  preApprovalApplicationStatus: 'string',
  provider: 'string',
  providerLogo: 'string',
  requestRemoveConfirmation: true,
  status: 'string',
  title: 'Advanced Web Design',
};
