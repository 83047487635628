import * as React from 'react';
import styled from 'styled-components';
import {isLearnInProvider} from '@utils/custom-client-helpers';
import placeholderProviderLogo from '@assets/placeholder_provider_logo.svg';
import {COLORS} from '@utils/constants';
import {useGetCompanyPersonalizationQuery} from '@hooks/apiEndpoints/user/queries';

export const Container = styled.div<{size: number; border?: boolean}>`
  height: ${({size}) => size}px;
  min-width: ${({size}) => size}px;
  max-width: ${({size}) => size}px;
  border-radius: 10px;
  border: ${({border}) => (border ? `1px solid ${COLORS.Neutral100}` : null)};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 8px;
  box-sizing: border-box;
  background: white;
`;

export const ProviderLogoImg = styled.img<{size: number}>`
  width: ${({size}) => size}px !important;
`;

function ProviderLogoWrapped({
  alt,
  provider,
  providerLogo,
  size,
  style,
  showCompanyLogo,
  border = true,
}: {
  alt?: string;
  provider: string;
  providerLogo: string | unknown;
  size: number;
  style?: React.CSSProperties;
  showCompanyLogo?: boolean;
  border?: boolean;
}) {
  const {data: companyPersonalization} = useGetCompanyPersonalizationQuery();
  const providerLogoSrc =
    (isLearnInProvider(provider) && !!companyPersonalization) || showCompanyLogo
      ? companyPersonalization?.companyLogoUrlSquare
      : providerLogo;

  return (
    <ProviderLogo
      alt={alt}
      border={border}
      size={size}
      style={style}
      providerLogoSrc={providerLogoSrc || providerLogo}
    />
  );
}

export default ProviderLogoWrapped;

export function ProviderLogo({
  alt,
  size,
  style,
  providerLogoSrc,
  border = true,
}: {
  alt?: string;
  size: number;
  style?: React.CSSProperties;
  providerLogoSrc: string | unknown;
  border?: boolean;
}) {
  return (
    <Container size={size} style={style} border={border}>
      <ProviderLogoImg
        alt={alt || ''}
        size={size}
        src={providerLogoSrc || placeholderProviderLogo}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = placeholderProviderLogo;
        }}
      />
    </Container>
  );
}
