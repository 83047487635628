import './DeleteLicensedOrCustomProgramItemModal.scss';
import {i18n, k} from '@i18n/translate';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';

interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onOk?: () => void;
  itemId?: number;
  programId?: number;
}

export default function DeleteLicensedOrCustomProgramItemModal({
  isVisible,
  setIsVisible,
  onOk,
}: Props) {
  const onCancel = () => setIsVisible(false);
  return (
    <LearnInModal
      tag={ModalTags.RemoveProgramFromUserPlan}
      visible={isVisible}
      onOk={() => {
        onOk();
        onCancel();
      }}
      width={400}
      onCancel={onCancel}
      okButtonChild={i18n.t(k.PROGRAM__REMOVE)}
      okButtonTag={ButtonTags.Destructive}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      title={i18n.t(k.PROGRAM__REMOVE)}>
      <div style={{padding: '10px 25px 25px'}}>
        {i18n.t(k.PROGRAM__REMOVE__CONFIRM)}
      </div>
    </LearnInModal>
  );
}
