import {CostCenterVM} from '@generated/interfaces';
import {CostCenterOption} from './CostCenterField.interfaces';
import {sortOptions} from '../LearnInTable';

export const useGetCostCenterOptions = (
  costCenters: CostCenterVM[]
): CostCenterOption[] => {
  if (!costCenters || costCenters.length === 0) return;

  const costCenterOptions: CostCenterOption[] = costCenters?.map((x) => ({
    label: x.costCenter,
    value: x.costCenter,
  }));

  const hasEmptyCostCenter =
    costCenters?.filter(
      (c) =>
        c.costCenter === null ||
        c.costCenter === undefined || // can't be undefined, but just in case
        c.costCenter?.match(/^\s*$/g) // i.e empty string or all spaces
    )?.length > 0;

  if (!hasEmptyCostCenter) {
    costCenterOptions.push({
      // empty so users can de-select a cost center
      label: ' ',
      value: null,
    });
  }

  return sortOptions(costCenterOptions);
};
