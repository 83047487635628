import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {useQuery} from 'react-query';
import {Radio} from 'antd';
import {debounce, startCase} from 'lodash';
import './PrepaymentsReportList.scss';
import ContextMenuButton from '@blocks/ContextMenu';
import TransactionTableDrawer from './TransactionsTableDrawer';
import {
  basicSorter,
  renderBalanceColumn,
  renderCurrency,
  stringSorter,
} from '@utils/tableUtils';
import {COLORS} from '@utils/constants';
import {
  PrepaymentReportVM,
  TransactionReportVM,
  UserFinanceIncentiveTransactionsVM,
} from '@models/serverModels';
import {simpleInvalidateExactQueryFn, simpleQueryFn} from '@store/queryClient';
import {
  getPrepaymentReportRq,
  getTransactionsReportRq,
  getUserTransactionsReportRq,
} from '@store/apiEndpoints';
import AddFundsDrawerContainer from '@blocks/addFundsDrawer/AddFundsDrawer.container';
import LearnInTable, {
  ColumnAlignment,
  dateTimeSorter,
  TableCell,
} from '@components/reusable/LearnInTable';
import {DATE_FORMAT, parseToLocalMoment} from '@utils/timeUtils';
import {EmptyBlockUntilTrue} from '@components/reusable/EmptyBlockUntilTrue';
import NoDataText from '@blocks/NoDataText';
import {CurrencyCode} from '@generated/enums';
import {ReportYearSelect} from './ReportYearSelect';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {adjustAmountByStatus, MONEY_DEFAULT} from '@utils/moneyUtils';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {CreditCardTransactionVM} from '@generated/interfaces';
import {LearnInSearchInput} from '@components/reusable/Input/LearnInSearchInput.style';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {AdminPaths} from '@utils/ClientPaths';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  LearnInButton,
  LinkStyledButton,
} from '@components/reusable/Button/Button.style';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  .ant-radio-group {
    label:first-child {
      border-radius: 15px 0px 0px 15px;
    }
    label:last-child {
      border-radius: 0px 15px 15px 0px;
    }
  }
`;

const EmployeeName = styled.div`
  color: ${COLORS.Blue800};
  &:hover {
    text-decoration: underline;
  }
`;

const MIN_YEAR = 2022;
/*
|--------------------------------------------------------------------------
| Book Button Component
|--------------------------------------------------------------------------
*/

export enum ToggleOptions {
  Transactions = 'Transactions', // uses getTransactionsReportQuery
  CardHolders = 'Card Holders', // uses getPrepaymentReportQuery
}



interface PrepaymentReportTableProps {
  usersCurrency: CurrencyCode;
}

const PrepaymentReportTable = ({usersCurrency}: PrepaymentReportTableProps) => {
  const toggleOptionsLabels = {
    Transactions: i18n.t(k.TRANSACTION__PLURAL),
    CardHolders: i18n.t(k.CARD__CARDHOLDER__PLURAL),
  };
  
  const toggleOptions = [
    {label: toggleOptionsLabels.Transactions, value: ToggleOptions.Transactions},
    {label: toggleOptionsLabels.CardHolders, value: ToggleOptions.CardHolders},
  ];
  
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTransactionTableVisible, setIsTransactionTableVisible] =
    useState(false);
  const [selectedReportItem, setSelectedReportItem] = useState(null);
  const [showFundsModal, setShowFundsModal] = useState(false);
  const [employeeNameSearchText, setEmployeeNameSearchText] = useState('');
  const vcStrippedOfSpaces = searchParams.get('vc')?.replace(/\s+/g, '');
  const [toggleValue, setToggleValue] = useState<ToggleOptions>(
    ToggleOptions[vcStrippedOfSpaces] || ToggleOptions.Transactions
  );

  const toggleTab = (vcTab) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('vc', vcTab);
    setSearchParams(newSearchParams);
  };
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear); // current year

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );
  const {isFeatureFlagOn} = useFeatureFlags();
  const navigate = useNavigate();
  const location = useLocation();

  const checkYearDropDown = (data) => {
    return data && MIN_YEAR !== currentYear;
  };

  const prepaymentReportRq = getPrepaymentReportRq(selectedYear);
  const getPrepaymentReportQuery = useQuery<PrepaymentReportVM[]>(
    prepaymentReportRq.queryKey,
    () => simpleQueryFn(prepaymentReportRq.path),
    {
      enabled: toggleValue == ToggleOptions.CardHolders,
    }
  );

  const transactionsReportRq = getTransactionsReportRq(selectedYear);
  const getTransactionsReportQuery = useQuery<TransactionReportVM[]>(
    transactionsReportRq.queryKey,
    () => simpleQueryFn(transactionsReportRq.path),
    {
      enabled: toggleValue == ToggleOptions.Transactions,
    }
  );

  const userTransactionsReportRq = getUserTransactionsReportRq(
    selectedReportItem?.userFinanceIncentiveId
  );
  const getUserTransactionsReportQuery =
    useQuery<UserFinanceIncentiveTransactionsVM>(
      userTransactionsReportRq.queryKey,
      () => simpleQueryFn(userTransactionsReportRq.path),
      {enabled: selectedReportItem !== null}
    );
  useEffect(() => {
    if (
      selectedReportItem !== null &&
      selectedReportItem.userFinanceIncentiveId
    ) {
      getUserTransactionsReportQuery.refetch();
    }
  }, [selectedReportItem]);

  const applyIdAttribute = (data, source) =>
    data?.map((item: PrepaymentReportVM & TransactionReportVM) => ({
      ...item,
      id:
        source === ToggleOptions.Transactions
          ? item.uniqueId
          : item.userCompanyId,
    }));

  // Filtering
  const [searchFilteredTransactions, setSearchFilteredTransactions] = useState(
    applyIdAttribute(
      getTransactionsReportQuery?.data,
      ToggleOptions.Transactions
    )
  );
  const [searchFilteredCardholders, setSearchFilteredCardholders] = useState(
    applyIdAttribute(getPrepaymentReportQuery?.data, ToggleOptions.CardHolders)
  );

  const applyEmployeeNameSearch = debounce(() => {
    if (toggleValue === ToggleOptions.Transactions) {
      const searchResultsTransactions =
        getTransactionsReportQuery?.data?.filter(({employeeName}) => {
          if (!employeeNameSearchText) return true;
          return new RegExp(employeeNameSearchText, 'i').test(employeeName);
        });
      setSearchFilteredTransactions(searchResultsTransactions);
    } else {
      const searchResultsCardholders = getPrepaymentReportQuery?.data?.filter(
        ({employeeName}) => {
          if (!employeeNameSearchText) return true;
          return new RegExp(employeeNameSearchText, 'i').test(employeeName);
        }
      );
      setSearchFilteredCardholders(searchResultsCardholders);
    }
  }, 500);
  // Refresh table data
  useEffect(() => {
    if (toggleValue === ToggleOptions.Transactions) {
      getPrepaymentReportQuery.refetch();
    } else {
      getTransactionsReportQuery.refetch();
    }
  }, [toggleValue, selectedYear]);

  // Re-apply the search
  useEffect(() => {
    applyEmployeeNameSearch();
  }, [toggleValue, employeeNameSearchText, getPrepaymentReportQuery?.data]);

  const onCloseTransactionsTable = () => {
    setIsTransactionTableVisible(false);
  };

  const handleAddFundsOk = () => {
    simpleInvalidateExactQueryFn(prepaymentReportRq.queryKey);
    getPrepaymentReportQuery.refetch();
  };

  const handleRow = (record) => {
    setSelectedReportItem(record);
    setIsTransactionTableVisible(true);
  };

  const searchFilteredTransactionsColumns = [
    {
      dataIndex: 'employeeName',
      key: 'employeeName',
      sorter: stringSorter<PrepaymentReportVM>('employeeName'),
      title: i18n.t(k.EMPLOYEE__NAME),
      render: (employeeName, row) => {
        return (
          <LinkStyledButton
            onClick={() =>
              navigate(`${AdminPaths.People}/${row.userCompanyId}`, {
                state: {from: location.pathname + location.search},
              })
            }>
            {employeeName}
          </LinkStyledButton>
        );
      },
    },
    {
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: dateTimeSorter('timestamp'),
      title: i18n.t(k.DATE),
      render: (date: any) =>
        parseToLocalMoment(date).format(DATE_FORMAT.MONTH_DAY_YEAR),
    },
    {
      dataIndex: 'lineOfBusiness',
      key: 'lineOfBusiness',
      sorter: stringSorter('lineOfBusiness'),
      title: i18n.t(k.EMPLOYEE__LINE_OF_BUSINESS),
    },
    {
      dataIndex: 'description',
      key: 'description',
      sorter: stringSorter<PrepaymentReportVM>('description'),
      title: i18n.t(k.GENERIC__DESCRIPTION),
      render: (description: string) =>
        k[description] ? i18n.t(k[description]) : description,
    },
    {
      dataIndex: 'category',
      key: 'category',
      sorter: stringSorter<PrepaymentReportVM>('category'),
      title: i18n.t(k.GENERIC__CATEGORY),
      render: (category) => {
        return <div>{startCase(category || '')}</div>;
      },
    },
    {
      dataIndex: 'amount',
      key: 'amount',
      align: ColumnAlignment.Right,
      render: (amount: number, row: TransactionReportVM) =>
        renderBalanceColumn({
          amount,
          status: row?.status?.toLowerCase(),
          usersCurrency,
          exchangeRate,
        }),
      sorter: basicSorter<PrepaymentReportVM>('amount'),
      title: i18n.t(k.GENERIC__AMOUNT),
    },
    {
      dataIndex: 'status',
      key: 'status',
      sorter: stringSorter<PrepaymentReportVM>('status'),
      title: i18n.t(k.STATUS__STATUS),
      render: (status) => {
        return <div>{status}</div>;
      },
    },
    {
      dataIndex: 'id',
      fixed: 'right',
      title: i18n.t(k.GENERIC__ACTION),
      key: 'id',
      render: (_, row: PrepaymentReportVM) => {
        return (
          <LearnInButton
            tag={ButtonTags.Link}
            color={COLORS.Blue800}
            hoverUnderline
            showArrowIcon
            noPadding
            onClick={() => handleRow(row)}>
            {i18n.t(k.CTA__VIEW)}
          </LearnInButton>
        );
      },
    },
  ];

  const searchFilteredCardholdersColumns = [
    {
      dataIndex: 'employeeName',
      key: 'employeeName',
      sorter: stringSorter<PrepaymentReportVM>('employeeName'),
      title: i18n.t(k.EMPLOYEE__NAME),
      render: (employeeName, row) => {
        return (
          <LinkStyledButton
            onClick={() =>
              navigate(`${AdminPaths.People}/${row.userCompanyId}`, {
                state: {from: location.pathname + location.search},
              })
            }>
            {employeeName}
          </LinkStyledButton>
        );
      },
    },
    {
      dataIndex: 'lastUsed',
      key: 'lastUsed',
      sorter: dateTimeSorter('lastUsed'),
      title: i18n.t(k.GENERIC__LAST_USED),
      render: (lastUsed) => {
        return <div>{lastUsed}</div>;
      },
    },
    {
      dataIndex: 'lineOfBusiness',
      key: 'lineOfBusiness',
      sorter: stringSorter('lineOfBusiness'),
      title: i18n.t(k.EMPLOYEE__LINE_OF_BUSINESS),
    },
    {
      dataIndex: 'spendingLimit',
      align: ColumnAlignment.Right,
      key: 'spendingLimit',
      render: (amount) => {
        return (
          <div data-cy="spendingLimit">
            {renderCurrency({amount, exchangeRate, usersCurrency})}
          </div>
        );
      },
      sorter: basicSorter<PrepaymentReportVM>('spendingLimit'),
      title: i18n.t(k.GENERIC__TOTAL_BUDGET),
    },
    {
      dataIndex: 'totalApproved',
      align: ColumnAlignment.Right,
      key: 'totalApproved',
      render: (amount) => {
        return (
          <div data-cy="totalApproved">
            {renderCurrency({amount, exchangeRate, usersCurrency})}
          </div>
        );
      },
      sorter: basicSorter<PrepaymentReportVM>('totalApproved'),
      title: i18n.t(k.APPROVAL__TOTAL_APPROVED),
    },
    {
      dataIndex: 'totalSpent',
      align: ColumnAlignment.Right,
      key: 'totalSpent',
      render: (amount) => {
        return (
          <div data-cy="totalSpent">
            {renderCurrency({amount, exchangeRate, usersCurrency})}
          </div>
        );
      },
      sorter: basicSorter<PrepaymentReportVM>('totalSpent'),
      title: i18n.t(k.MONEY__TOTAL_SPENT),
    },
    {
      dataIndex: 'balance',
      align: ColumnAlignment.Right,
      key: 'balance',
      render: (amount: any, row: CreditCardTransactionVM) => {
        const status = row?.status?.toLowerCase();
        amount = adjustAmountByStatus(amount, status);

        return (
          <div data-cy="balance">
            {renderCurrency({amount, exchangeRate, usersCurrency})}
          </div>
        );
      },

      sorter: basicSorter<PrepaymentReportVM>('balance'),
      title: i18n.t(k.GENERIC__READY_TO_SPEND),
    },
    {
      dataIndex: 'id',
      fixed: 'right',
      title: i18n.t(k.GENERIC__ACTION),
      key: 'id',
      render: (_, row: PrepaymentReportVM) => {
        return (
          <LearnInButton
            tag={ButtonTags.Link}
            color={COLORS.Blue800}
            hoverUnderline
            showArrowIcon
            noPadding
            onClick={() => handleRow(row)}>
            {i18n.t(k.CTA__VIEW)}
          </LearnInButton>
        );
      },
    },
  ];
  return (
    <>
      {selectedReportItem !== null && (
        <>
          {isTransactionTableVisible && (
            <TransactionTableDrawer
              userFinanceIncentive={selectedReportItem}
              onClose={onCloseTransactionsTable}
              visible={isTransactionTableVisible}
            />
          )}
          {showFundsModal && (
            <AddFundsDrawerContainer
              amountType={getPrepaymentReportQuery.data[0].amountType}
              userFinanceIncentive={selectedReportItem}
              isVisible={showFundsModal}
              setIsVisible={setShowFundsModal}
              onOk={handleAddFundsOk}
            />
          )}
        </>
      )}

      <TopRow style={{padding: '10px 32px', boxSizing: 'border-box'}}>
        <div>
          <LearnInSearchInput
            allowClear
            onChange={(e) => setEmployeeNameSearchText(e.target.value)}
            placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
              item: i18n.t(k.EMPLOYEE__PLURAL),
            })}
            style={{width: '240px', minHeight: '40px'}}
            value={employeeNameSearchText}
          />
          {toggleValue === ToggleOptions.Transactions && (
            <ReportYearSelect
              style={{marginLeft: 16}}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          )}
        </div>
        <ToggleWrapper>
          <span style={{color: COLORS.Neutral600, marginRight: '8px'}}>
            {i18n.t(k.CTA__VIEW_BY)}:
          </span>
          <div data-cy="ReportsRadio">
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => {
                toggleTab(e.target.value);
                setToggleValue(e.target.value);
              }}
              options={toggleOptions}
              optionType="button"
              value={toggleValue}
            />
          </div>
        </ToggleWrapper>
      </TopRow>

      {toggleValue === ToggleOptions.CardHolders ? (
        searchFilteredCardholders === undefined ? (
          <LoadingSpinner center={true} />
        ) : employeeNameSearchText === '' &&
          !searchFilteredCardholders?.length ? (
          <NoDataText
            header={i18n.t(k.TRANSACTION__EMPTY__TITLE)}
            subHeader={i18n.t(k.TRANSACTION__EMPTY__DESCRIPTION)}
          />
        ) : (
          <EmptyBlockUntilTrue
            condition={searchFilteredCardholders?.length > 0}
            emptyHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
            emptyBody={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION)}>
            <LearnInTable
              key="cardholders"
              columns={searchFilteredCardholdersColumns}
              dataSource={applyIdAttribute(
                searchFilteredCardholders,
                ToggleOptions.CardHolders
              )}
              scroll={{x: 'max-content'}}
            />
          </EmptyBlockUntilTrue>
        )
      ) : (
        toggleValue === ToggleOptions.Transactions &&
        (searchFilteredTransactions === undefined ? (
          <LoadingSpinner center={true} />
        ) : employeeNameSearchText === '' &&
          !searchFilteredTransactions?.length ? (
          <NoDataText
            header={i18n.t(k.TRANSACTION__EMPTY__TITLE)}
            subHeader={i18n.t(k.TRANSACTION__EMPTY__DESCRIPTION)}
          />
        ) : (
          <EmptyBlockUntilTrue
            condition={searchFilteredTransactions?.length > 0}
            emptyHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
            emptyBody={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION)}>
            <LearnInTable
              key="transactions"
              columns={searchFilteredTransactionsColumns}
              dataSource={applyIdAttribute(
                searchFilteredTransactions,
                ToggleOptions.Transactions
              )}
              scroll={{x: 'max-content'}}
            />
          </EmptyBlockUntilTrue>
        ))
      )}
    </>
  );
};

export default PrepaymentReportTable;

/*
|--------------------------------------------------------------------------
| Context Menu
|--------------------------------------------------------------------------
*/

function ContextMenu({
  onClickAddFunds,
  onClickViewTransactions,
  showIncreaseLimitsOption,
}: {
  onClickAddFunds: (e) => void;
  onClickViewTransactions: () => void;
  showIncreaseLimitsOption: boolean;
}) {
  return (
    <ContextMenuButton
      menuItems={[
        showIncreaseLimitsOption
          ? {
              label: i18n.t(k.FUND__MANAGE),
              onClick: onClickAddFunds,
              dataCy: 'manageFunds',
            }
          : null,
        {
          label: i18n.t(k.TRANSACTION__PLURAL__VIEW),
          onClick: onClickViewTransactions,
        },
      ]}
      dataCy="reportContextMenu"
    />
  );
}
