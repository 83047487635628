import {
  BalanceRecurType,
  BudgetAllocationType,
  BudgetType,
  FinanceRecurrence,
  LearningResourceType,
} from './../enums';
import {CreateBudgetRequest} from './../interfaces';
import {
  ApprovalConfigMockData,
  getApprovalConfigMockData,
} from './ApprovalConfig.mock';
import {faker} from '@faker-js/faker';

// Interface: CreateBudgetRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCreateBudgetRequestMockData(
  args?: Partial<CreateBudgetRequest>
): CreateBudgetRequest {
  return {
    about: faker.word.noun(),
    active: faker.datatype.boolean(),
    allocationType: BudgetAllocationType.PreAllocated,
    allowDirectBilling: faker.datatype.boolean(),
    allowedResources: [LearningResourceType.Book],
    allowExternalReimbursement: faker.datatype.boolean(),
    allowReimbursement: faker.datatype.boolean(),
    allowVirtualCard: faker.datatype.boolean(),
    approvalConfig: getApprovalConfigMockData(),
    approvalExpirationDays: faker.datatype.number(), // optional
    approvalGuidelines: faker.word.noun(),
    balanceRecurType: BalanceRecurType.NotApplicable,
    companyId: faker.datatype.number(),
    description: faker.lorem.lines(),
    fiscalStartDate: String(faker.date.past()),
    limit: faker.datatype.number(),
    proratingEnabled: faker.datatype.boolean(),
    recurrence: FinanceRecurrence.Never,
    title: faker.company.catchPhrase(),
    type: BudgetType.Personal,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CreateBudgetRequestMockData: CreateBudgetRequest = {
  about: 'string',
  active: true,
  allocationType: BudgetAllocationType.PreAllocated,
  allowDirectBilling: true,
  allowedResources: [LearningResourceType.Book],
  allowExternalReimbursement: true,
  allowReimbursement: true,
  allowVirtualCard: true,
  approvalConfig: ApprovalConfigMockData,
  approvalExpirationDays: 123, // optional
  approvalGuidelines: 'string',
  balanceRecurType: BalanceRecurType.NotApplicable,
  companyId: 1,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  fiscalStartDate: '2023-03-29T22:17:19.738072',
  limit: 123,
  proratingEnabled: true,
  recurrence: FinanceRecurrence.Never,
  title: 'Advanced Web Design',
  type: BudgetType.Personal,
};
