import {BalanceRecurType, FinanceAmountType} from './../enums';
import {PrepaymentTransactionVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: PrepaymentTransactionVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPrepaymentTransactionVMMockData(
  args?: Partial<PrepaymentTransactionVM>
): PrepaymentTransactionVM {
  return {
    amountType: FinanceAmountType.UpTo,
    balance: faker.datatype.number(),
    employeeName: faker.name.fullName(),
    lastUsed: faker.word.noun(),
    lineOfBusiness: faker.word.noun(),
    recurrenceType: BalanceRecurType.NotApplicable,
    spendingLimit: faker.datatype.number(),
    totalApproved: faker.datatype.number(),
    totalSpent: faker.datatype.number(),
    userCompanyId: faker.datatype.number(),
    userFinanceIncentiveId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PrepaymentTransactionVMMockData: PrepaymentTransactionVM = {
  amountType: FinanceAmountType.UpTo,
  balance: 123,
  employeeName: 'Bobby Johnson',
  lastUsed: 'string',
  lineOfBusiness: 'string',
  recurrenceType: BalanceRecurType.NotApplicable,
  spendingLimit: 123,
  totalApproved: 123,
  totalSpent: 123,
  userCompanyId: 1,
  userFinanceIncentiveId: 1,
};
