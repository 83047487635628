import {CustomProgramSkillTag} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramSkillTag

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramSkillTagMockData(
  args?: Partial<CustomProgramSkillTag>
): CustomProgramSkillTag {
  return {
    id: faker.datatype.number(), // optional
    name: faker.name.fullName(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramSkillTagMockData: CustomProgramSkillTag = {
  id: 1, // optional
  name: 'Bobby Johnson',
};
