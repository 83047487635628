import Grid from '@blocks/Grid';
import React from 'react';
import Title from './Form/Title';
import Attachments from './Attachments';
import Description from './Form/Description';
import DueDate from './Form/DueDate';
import {AllowSkipCheckbox} from './Form/FormCheckbox';
import {FormProps} from './AddEditContent.config';
import {getInitialDueDateAndTime} from './Form/utils';
import {CustomizableLearningCategory} from '@generated/enums';

const CustomContentForm: React.FC<FormProps> = ({
  step,
  attachmentProps,
  canShowAllowSkip,
  showRichTextUploads,
  learningCategory,
  stepRequirementComponent,
}) => {
  const canShowDueDate =
    learningCategory === CustomizableLearningCategory.CustomProgram;
  const initialDueDateValues = getInitialDueDateAndTime(step);

  return (
    <Grid.Container>
      <Grid.Box>
        <Title initialValue={step?.title} />
      </Grid.Box>

      <Grid.Box>
        <Description
          initialValue={step?.description}
          richTextUploads={showRichTextUploads}
        />
      </Grid.Box>

      {canShowDueDate && (
        <DueDate
          initialTimeZoneId={step?.dueDateTimeZone}
          initialDueDate={initialDueDateValues?.initialDueDate}
          initialDueDateTime={initialDueDateValues?.initialDueDateTime}
        />
      )}

      {stepRequirementComponent}

      {canShowAllowSkip && (
        <Grid.Box full style={{marginBottom: 12}}>
          <AllowSkipCheckbox initialValue={step?.allowSkip} />
        </Grid.Box>
      )}

      <Attachments {...attachmentProps} />
    </Grid.Container>
  );
};

export default CustomContentForm;
