import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {Tabs} from 'antd';
import CustomProgramCohortTable from './CustomProgramCohortTable';
import {CustomProgramSectionsProgressReportTableWrapped} from './CustomProgramCurriculumProgressTable';
import {CustomProgramProgressVM} from '@models/serverModels';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import ProgressTable, {IProgressTableDataSourceRow} from './ProgressTable';
import {IDataPanelItem} from '@components/reusable/LearnInTable';
import moment from 'moment';
import {DATE_FORMAT} from '@utils/timeUtils';
import {LearnInProgressBar} from '@components/learnin';
import Search from '@components/reusable/Search';
import useLocalSearch from '@hooks/useLocalSearch';
import useNudgeEmail from '@blocks/NudgeEmailPreview/useNudgeEmail';
import NudgeEmailPreview, {Feature} from '@blocks/NudgeEmailPreview';
import BulkNudgeButton from '@blocks/BulkNudgeButton.view';
import BulkNudgeDrawer from '@blocks/BulkNudgeDrawer';
import {
  useCustomProgramContentEmployeesProgressReportQuery,
  useCustomProgramSectionEmployeesProgressReportQuery,
  useCustomProgramContentProgressByEmployeeReportQuery,
  useCustomProgramSectionsProgressByEmployeeReportQuery,
} from '@generated/hooks';
import {CustomProgramActivityResult} from '@generated/interfaces';
import {CustomizableLearningCategory} from '@generated/enums';
import {Helmet} from 'react-helmet-async';

const {TabPane} = Tabs;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  width: 100%;
  & .ant-tabs-content-holder {
    margin: auto;
    width: 85%;
  }
  & .ant-tabs-tab {
    padding: 16px 24px;
  }
`;

const ProgressTableContainer = styled.div`
  box-sizing: border-box;
  max-width: 90%;
  margin: auto;
  padding-top: 24px;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

export enum PageTabs {
  Cohort = 'Cohort',
  Curriculum = 'Curriculum',
}

export enum ProgressTableName {
  EmployeeProgress,
  SectionParticipants,
  ContentParticipants,
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ICustomProgramReportsPageProps {
  customProgramId: number | undefined;
  title?: string;
}

enum ColumnTitle {
  Section = 'section',
  EmployeeName = 'employee name',
}

function CustomProgramReportsPage({
  customProgramId,
  title,
}: ICustomProgramReportsPageProps) {
  const [isProgressTableDrawerVisible, setIsProgressTableDrawerVisible] =
    useState(false);
  const [progressTableTitle, setProgressTableTitle] = useState('');
  const [progressTableColumnTitle, setProgressTableColumnTitle] = useState('');
  const [progressTableDataItems, setProgressTableDataItems] = useState<
    IDataPanelItem[]
  >([]);
  const [progressTableDataSource, setProgressTableDataSource] = useState<
    IProgressTableDataSourceRow[]
  >([]);
  const [progressTableNestedDataSource, setProgressTableNestedDataSource] =
    useState<Record<number, IProgressTableDataSourceRow[]>>({});
  const [isProgressTableNested, setIsProgressTableNested] = useState(false);
  const [sectionId, setSectionId] = useState<null | number>(null);
  const [contentId, setContentId] = useState<null | number>(null);
  const [progressTableName, setProgressTableName] =
    useState<null | ProgressTableName>(null);
  const [employeeId, setEmployeeId] = useState<null | number>(null);
  const [currentTab, setCurrentTab] = useState(PageTabs.Cohort);
  const [bulkNudgeDrawerVisible, setBulkNudgeDrawerVisible] = useState(false);

  // Data Fetching

  useCustomProgramSectionsProgressByEmployeeReportQuery(
    {queryParams: {customProgramId, employeeId}},
    {
      enabled:
        !!employeeId &&
        progressTableName === ProgressTableName.EmployeeProgress,
      onSuccess: (data) => {
        if (progressTableName === ProgressTableName.EmployeeProgress) {
          setProgressTableDataSource(data || []);
        }
      },
    }
  );

  const employeeCustomProgramContentProgressQuery =
    useCustomProgramContentProgressByEmployeeReportQuery(
      {
        queryParams: {
          customProgramId,
          sectionId,
          employeeId,
        },
      },
      {
        enabled: !!sectionId && !!employeeId,
        onSuccess: (data) => {
          if (progressTableName === ProgressTableName.EmployeeProgress) {
            setProgressTableNestedDataSource({
              ...progressTableNestedDataSource,
              [sectionId]: data || [],
            });
          }
        },
      }
    );

  useCustomProgramSectionEmployeesProgressReportQuery(
    {queryParams: {customProgramId, sectionId}},
    {
      enabled:
        !!sectionId &&
        progressTableName === ProgressTableName.SectionParticipants,
      onSuccess: (data) => {
        if (progressTableName === ProgressTableName.SectionParticipants) {
          setProgressTableDataSource(
            data?.map((employee) => ({
              ...employee,
              title: employee.employeeName,
              id: employee.employeeId,
            })) || []
          );
          const total = data?.length || 0;
          const totalComplete =
            data.filter(({progressPercentage}) => progressPercentage === 100)
              ?.length || 0;
          setProgressTableDataItems([
            progressTableDataItems[0],
            {
              label: i18n.t(k.PROGRESS__TOTAL_COMPLETED),
              display: totalComplete,
            },
            {
              label: i18n.t(k.PROGRESS__TOTAL_OUTSTANDING),
              display: total - totalComplete,
            },
          ]);
          setSectionId(null);
        }
      },
    }
  );

  useCustomProgramContentEmployeesProgressReportQuery(
    {
      queryParams: {customProgramId, contentId},
    },
    {
      enabled:
        !!contentId &&
        progressTableName === ProgressTableName.ContentParticipants,
      onSuccess: (data) => {
        if (progressTableName === ProgressTableName.ContentParticipants) {
          setProgressTableDataSource(
            data?.map((employee) => ({
              ...employee,
              title: employee.employeeName,
              id: employee.employeeId,
            })) || []
          );
          const total = data?.length || 0;
          const totalComplete =
            data.filter(({completedOn}) => !!completedOn)?.length || 0;
          setProgressTableDataItems([
            progressTableDataItems[0],
            {
              label: i18n.t(k.PROGRESS__TOTAL_COMPLETED),
              display: totalComplete,
            },
            {
              label: i18n.t(k.PROGRESS__TOTAL_OUTSTANDING),
              display: total - totalComplete,
            },
          ]);
          // setContentId(null);
        }
      },
    }
  );

  const {
    filteredData: filteredProgressTableDataSource,
    searchFilter,
    setSearchFilter,
    getNoDataTableProps,
  } = useLocalSearch<IProgressTableDataSourceRow & {employeeName: string}>(
    progressTableDataSource as (IProgressTableDataSourceRow & {
      employeeName: string;
    })[],
    'employeeName'
  );

  const {drawerProps, nudgeEmailsEnabled, onOpenNudgeEmailPreview} =
    useNudgeEmail({feature: Feature.CustomProgram});

  // Drawer UI handlers
  const handleCloseProgressTableDrawer = () => {
    setIsProgressTableDrawerVisible(false);
  };
  const openProgressTableDrawer = (title: any, columnTitle: any) => {
    setProgressTableTitle(title);
    setProgressTableColumnTitle(columnTitle);

    setIsProgressTableDrawerVisible(true);
  };

  // Table Row Click Handlers
  const handleClickCohortTableRow = (row: CustomProgramActivityResult) => {
    setProgressTableName(ProgressTableName.EmployeeProgress);
    setEmployeeId(row.employeeId);
    setProgressTableDataItems([
      {
        label: i18n.t(k.PROGRAM__PROGRESS),
        display: <LearnInProgressBar percent={row.progressPercentage} />,
      },
      {
        label: i18n.t(k.PROGRAM__START__NOUN),
        display: moment(row.startDate).isValid()
          ? moment(row.startDate).format(DATE_FORMAT.MONTH_DAY_YEAR)
          : '-',
      },
      {
        label: i18n.t(k.GENERIC__LAST_ACTIVE),
        display: moment(row.lastActiveDate).isValid()
          ? moment(row.lastActiveDate).format(DATE_FORMAT.MONTH_DAY_YEAR)
          : '-',
      },
    ]);
    setIsProgressTableNested(true);
    openProgressTableDrawer(row.employeeName, ColumnTitle.Section);
  };

  const handleClickProgressTableSectionRow = (row: CustomProgramProgressVM) => {
    setProgressTableName(ProgressTableName.SectionParticipants);
    setSectionId(row.id);
    setProgressTableDataItems([
      {
        label: i18n.t(k.SECTION__PROGRESS),
        display: <LearnInProgressBar percent={row.progressPercentage} />,
      },
      {
        label: i18n.t(k.PROGRESS__TOTAL_COMPLETED),
        display: null,
      },
      {
        label: i18n.t(k.PROGRESS__TOTAL_OUTSTANDING),
        display: null,
      },
    ]);
    setIsProgressTableNested(false);
    openProgressTableDrawer(row.title, ColumnTitle.EmployeeName);
  };

  const handleClickProgressTableContentRow = (row: CustomProgramProgressVM) => {
    setProgressTableName(ProgressTableName.ContentParticipants);
    setContentId(row.id);
    setProgressTableDataItems([
      {
        label: i18n.t(k.CONTENT__PROGRESS),
        display: <LearnInProgressBar percent={row.progressPercentage} />,
      },
      {
        label: i18n.t(k.PROGRESS__TOTAL_COMPLETED),
        display: null,
      },
      {
        label: i18n.t(k.PROGRESS__TOTAL_OUTSTANDING),
        display: null,
      },
    ]);
    setIsProgressTableNested(false);
    openProgressTableDrawer(row.title, ColumnTitle.EmployeeName);
  };

  const handleExpandProgressTableRow = (expanded: any, row: any) => {
    if (expanded) {
      if (progressTableName === ProgressTableName.EmployeeProgress) {
        setSectionId(row.id);
        employeeCustomProgramContentProgressQuery.refetch();
      }
    }
  };

  const handleTabChange = (key: PageTabs) => {
    setProgressTableName(null);
    setCurrentTab(key);
  };

  const searchNameText = i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
    item: i18n.t(k.GENERIC__NAME),
  });

  const noDataProps = getNoDataTableProps();

  const handleCloseBulkNudgeDrawer = () => {
    setBulkNudgeDrawerVisible(false);
  };

  const handleClickBulkNudge = () => {
    setBulkNudgeDrawerVisible(() => true);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BulkNudgeDrawer
        customizableLearningCategory={
          CustomizableLearningCategory.CustomProgram
        }
        visible={bulkNudgeDrawerVisible}
        onClose={handleCloseBulkNudgeDrawer}
        name={drawerProps.programOrAcademyName}
        contentId={contentId}
        feature={Feature.CustomProgram}
      />
      <Container>
        <LearnInTabs defaultActiveKey={currentTab} onChange={handleTabChange}>
          <TabPane tab={i18n.t(k.COHORT)} key={PageTabs.Cohort}>
            <CustomProgramCohortTable
              customProgramId={customProgramId}
              onClickRow={handleClickCohortTableRow}
              canShowNudgeColumn={nudgeEmailsEnabled}
              onOpenNudgeEmailPreview={onOpenNudgeEmailPreview}
            />
          </TabPane>
          <TabPane
            tab={i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}
            key={PageTabs.Curriculum}>
            <CustomProgramSectionsProgressReportTableWrapped
              customProgramId={customProgramId}
              onClickSectionRow={handleClickProgressTableSectionRow}
              onClickContentRow={handleClickProgressTableContentRow}
            />
          </TabPane>
        </LearnInTabs>

        <LearnInPageDrawer
          title={progressTableTitle}
          visible={isProgressTableDrawerVisible}
          onClose={handleCloseProgressTableDrawer}>
          <ProgressTableContainer>
            <ProgressTable
              showEmailColumn={
                progressTableName === ProgressTableName.ContentParticipants ||
                progressTableName === ProgressTableName.SectionParticipants
              }
              canShowNudgeColumn={
                nudgeEmailsEnabled &&
                progressTableName === ProgressTableName.ContentParticipants
              }
              onOpenNudgeEmailPreview={onOpenNudgeEmailPreview}
              dataSource={
                progressTableColumnTitle === ColumnTitle.EmployeeName
                  ? filteredProgressTableDataSource
                  : progressTableDataSource
              }
              columnTitle={progressTableColumnTitle}
              dataPanelItems={progressTableDataItems}
              showCompleteStatus={
                progressTableName === ProgressTableName.ContentParticipants
              }
              nestedTable={
                isProgressTableNested
                  ? {
                      dataSource: progressTableNestedDataSource,
                      onExpand: handleExpandProgressTableRow,
                      showCompleteStatus:
                        progressTableName ===
                        ProgressTableName.EmployeeProgress,
                    }
                  : null
              }
              searchControl={
                <TableHeader>
                  {progressTableColumnTitle === ColumnTitle.EmployeeName ? (
                    <Search
                      id="reports__participant-table"
                      style={{width: '270px', marginBottom: '24px'}}
                      onChange={setSearchFilter}
                      value={searchFilter}
                      placeholder={i18n.t(k.CTA__SEARCH_ITEM__FORMAT, {
                        item: null,
                      })}
                      label={searchNameText}
                      buttonAriaLabel={searchNameText}
                    />
                  ) : null}
                  <div>
                    {progressTableName ===
                      ProgressTableName.ContentParticipants && (
                      <BulkNudgeButton onClick={handleClickBulkNudge} />
                    )}
                  </div>
                </TableHeader>
              }
              noDataTableProps={
                progressTableColumnTitle === ColumnTitle.EmployeeName
                  ? noDataProps
                  : null
              }
            />
          </ProgressTableContainer>
        </LearnInPageDrawer>
        <NudgeEmailPreview {...drawerProps} />
      </Container>
    </>
  );
}

export default CustomProgramReportsPage;
