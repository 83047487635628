// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/BusinessGoalType.cs

enum BusinessGoalType {
  Retain,
  Mobilize,
  Upskill,
}

export default BusinessGoalType;
