import {i18n, k} from '@i18n/translate';
import Grid from '@blocks/Grid';
import {LearnInInput} from '@components/reusable/LearnInForm';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {DeleteOutlined} from '@ant-design/icons';
import {AttachmentType} from './AttachmentOptions';
import {CustomContentLink} from '@models/serverModels';

interface LinkForm {
  setLink: (link: CustomContentLink) => void;
  link: CustomContentLink;
  setAttachmentType: (attachmentType: AttachmentType) => void;
}
export function LinkForm({link, setLink, setAttachmentType}: LinkForm) {
  return (
    <Grid.Box style={{display: 'flex'}}>
      <div style={{marginRight: '8px', width: '100%'}}>
        <label htmlFor="link-title-input">
          {i18n.t(k.FILE__ATTACHMENT__LINK_TITLE)}
        </label>
        <LearnInInput
          id="link-title-input"
          value={link?.title || ''}
          updateFunc={(val: any) => setLink({...link, title: val})}
        />
      </div>

      <div style={{marginRight: '8px', width: '100%'}}>
        <label htmlFor="link-url-input">{i18n.t(k.GENERIC__URL)}</label>
        <LearnInInput
          id="link-url-input"
          value={link?.url || ''}
          updateFunc={(val: any) => setLink({...link, url: val})}
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '13px',
          fontSize: '1.3rem',
          marginTop: '20px',
        }}>
        <LearnInButton
          icon={<DeleteOutlined alt={i18n.t(k.CTA__DELETE)} />}
          tag={ButtonTags.DestructiveRemovedBorder}
          data-testId="delete-button"
          onClick={() => {
            setLink(undefined);
            setAttachmentType(undefined);
          }}
        />
      </div>
    </Grid.Box>
  );
}
