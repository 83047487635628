import React from 'react';

type CaretDownProps = {
  style: React.CSSProperties;
};

const CaretDown: React.FC<CaretDownProps> = ({style}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      style={style}>
      <path
        d="M13.0107 4.79692C12.8371 4.62336 12.5557 4.62336 12.3821 4.79692L8.21808 8.961C8.17468 9.00439 8.10433 9.00439 8.06094 8.961L3.89687 4.79692C3.7233 4.62336 3.44189 4.62336 3.26833 4.79692L2.79692 5.26833C2.62336 5.44189 2.62336 5.7233 2.79692 5.89687L7.51097 10.6109C7.8581 10.958 8.42091 10.958 8.76805 10.6109L13.4821 5.89687C13.6557 5.7233 13.6557 5.44189 13.4821 5.26833L13.0107 4.79692Z"
        fill="#0062E3"
      />
    </svg>
  );
};

export default CaretDown;
