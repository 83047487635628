import {UserFinanceIncentiveStatus} from './../enums';
import {UserFinanceIncentive} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {faker} from '@faker-js/faker';

// Interface: UserFinanceIncentive

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserFinanceIncentiveMockData(
  args?: Partial<UserFinanceIncentive>
): UserFinanceIncentive {
  return {
    ...getBaseEntityMockData(),
    advanceAmount: faker.datatype.number(),
    amountLimit: faker.datatype.number(),
    balance: faker.datatype.number(),
    concurrencyToken: faker.datatype.number(),
    effectiveDate: String(faker.date.past()),
    estimatedAmount: faker.datatype.number(),
    expirationDate: String(faker.date.past()),
    financeIncentiveId: faker.datatype.number(),
    id: faker.datatype.number(),
    lastRecurringBalanceUpdate: String(faker.date.past()), // optional
    notes: faker.word.noun(),
    status: UserFinanceIncentiveStatus.PendingUse,
    title: faker.company.catchPhrase(),
    totalAmount: faker.datatype.number(),
    updateCreditCardSpendingLimit: faker.datatype.boolean(),
    userBudgetId: faker.datatype.number(), // optional
    userPlanItemId: faker.datatype.number(),
    userPlanProgramId: faker.datatype.number(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserFinanceIncentiveMockData: UserFinanceIncentive = {
  ...BaseEntityMockData,
  advanceAmount: 5,
  amountLimit: 5,
  balance: 123,
  concurrencyToken: 123,
  effectiveDate: '2023-03-29T22:17:19.738072',
  estimatedAmount: 5,
  expirationDate: '2023-03-29T22:17:19.738072',
  financeIncentiveId: 1,
  id: 1,
  lastRecurringBalanceUpdate: '2023-03-29T22:17:19.738072', // optional
  notes: 'string',
  status: UserFinanceIncentiveStatus.PendingUse,
  title: 'Advanced Web Design',
  totalAmount: 5,
  updateCreditCardSpendingLimit: true,
  userBudgetId: 1, // optional
  userPlanItemId: 1,
  userPlanProgramId: 1, // optional
};
