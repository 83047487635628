import {DefaultReimbursementQuestions} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: DefaultReimbursementQuestions

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getDefaultReimbursementQuestionsMockData(
  args?: Partial<DefaultReimbursementQuestions>
): DefaultReimbursementQuestions {
  return {
    addDefaultBookResourceReimbursementQuestions: faker.datatype.boolean(),
    addDefaultConferenceResourceReimbursementQuestions:
      faker.datatype.boolean(),
    addDefaultOtherResourceReimbursementQuestions: faker.datatype.boolean(),
    addDefaultProgramReimbursementQuestions: faker.datatype.boolean(),
    addDefaultSubscriptionResourceReimbursementQuestions:
      faker.datatype.boolean(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const DefaultReimbursementQuestionsMockData: DefaultReimbursementQuestions =
  {
    addDefaultBookResourceReimbursementQuestions: true,
    addDefaultConferenceResourceReimbursementQuestions: true,
    addDefaultOtherResourceReimbursementQuestions: true,
    addDefaultProgramReimbursementQuestions: true,
    addDefaultSubscriptionResourceReimbursementQuestions: true,
  };
