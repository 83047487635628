import {i18n, k} from '@i18n/translate';
import {Tabs} from 'antd';
import {isBetaAny, isProductionAny} from '@utils/environment-helpers';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import AddCompanyAdminForm from '@blocks/addCompanyAdminForm/AddCompanyAdminForm';
import AddUserAdminForm from '@blocks/addUserAdminForm/AddUserAdminForm';
import useSetPageTitle from '@hooks/useSetPageTitle';

const {TabPane} = Tabs;

enum DeveloperAdminTabs {
  AddUser,
  AddCompany,
}

const DeveloperAdminPage = ({title}: {title?: string}) => {
  useSetPageTitle(title);

  if (isProductionAny || isBetaAny) {
    return <></>;
  }

  return (
    <LearnInTabs>
      <TabPane tab={i18n.t(k.USER__ADD)} key={DeveloperAdminTabs.AddUser}>
        <AddUserAdminForm />
      </TabPane>
      <TabPane tab={i18n.t(k.COMPANY__ADD)} key={DeveloperAdminTabs.AddCompany}>
        <AddCompanyAdminForm />
      </TabPane>
    </LearnInTabs>
  );
};

export default DeveloperAdminPage;
