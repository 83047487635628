import styled from 'styled-components';

import {ImageStyles} from './ImageTheme';
import {ImageTags} from './ImageEnums';

const StyledImage = styled.img`
  height: ${(props) => props.height || ImageStyles[props.tag].height};
  width: ${(props) => props.width || ImageStyles[props.tag].width};
  border-radius: ${(props) =>
    props.borderRadius || ImageStyles[props.tag].borderRadius};
`;

interface Props {
  src: string;
  tag: ImageTags;
  height?: string;
  width?: string;
  borderRadius?: string;
}

/**
 *
 * This is the standard image that should be used in all places where images are needed. You may need to
 * add new tags to this image if you are implementing a new type of image. The default image should cover
 * most use cases but you can pass in props to override styling if necessary.
 */
export function LearnInImage({...rest}: Props) {
  return <StyledImage {...rest} />;
}
