export const WrapperStyles = {
  modalProgramHeader: {
    padding: '24px 31px 24px 24px',
    background: '#ffffff',
    borderRadius: '10px',
  },
  modalProgramContent: {
    padding: '24px',
    background: '#F2F2F2',
  },
  moneyIncentiveCardWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  moneyIncentiveCardImageWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  requestPasswordModalWrapper: {
    display: 'flex',
    padding: '26px 44px 31px',
    alignItems: 'center',
    flexDirection: 'column',
  },
  uploadButtonWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: 'fit-content',
  },
  programStatusCardWrapper: {
    padding: '0px 24px 24px',
    display: 'flex',
  },
};
