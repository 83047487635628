import {CurrencyCode, LicenseStatus, LicenseUsageType} from '@generated/enums';
import {
  LICENSE_DURATION_NON_FIXED,
  LicenseDuration,
  LicenseFormFields,
  LicenseInternalCost,
  AddUpdateLicenseVM,
} from './AddUpdateLicenseDrawer.types';

export const getInternalCost = (license: AddUpdateLicenseVM) =>
  license?.internalCost !== LICENSE_DURATION_NON_FIXED
    ? license?.internalCost
    : 0;

export const getInternalCostType = (license: AddUpdateLicenseVM) =>
  license?.[LicenseFormFields.InternalCost] === 0
    ? LicenseInternalCost.Zero
    : license?.[LicenseFormFields.InternalCost] !== LICENSE_DURATION_NON_FIXED
    ? LicenseInternalCost.Fixed
    : LicenseInternalCost.Dependent;

export const getLicenseInternalCost = (license: AddUpdateLicenseVM) =>
  license?.[LicenseFormFields.InternalCost] !== LICENSE_DURATION_NON_FIXED
    ? license?.[LicenseFormFields.InternalCost]
    : 0;

export const getLicenseInternalCostByType = (
  internalCostType: LicenseInternalCost,
  internalCost: number
) => {
  switch (internalCostType) {
    case LicenseInternalCost.Dependent:
      return LICENSE_DURATION_NON_FIXED;
    case LicenseInternalCost.Zero:
      return 0;
    default:
      return internalCost;
  }
};

export const getIsLicenseNew = (license: AddUpdateLicenseVM) =>
  !license?.[LicenseFormFields.Id];

export const getLicenseStatus = (license: AddUpdateLicenseVM) =>
  license?.[LicenseFormFields.Status] ?? LicenseStatus.Published;

export const getLicenseType = (license: AddUpdateLicenseVM) =>
  license?.[LicenseFormFields.Type] ?? LicenseUsageType.AllPrograms;

export const getLicenseDuration = (license: AddUpdateLicenseVM) =>
  license?.[LicenseFormFields.LicenseDuration] !== LICENSE_DURATION_NON_FIXED
    ? license?.[LicenseFormFields.LicenseDuration]
    : 0;

export const getLicenseDurationType = (license: AddUpdateLicenseVM) => {
  if (!license) return null;

  return license?.[LicenseFormFields.LicenseDuration] !==
    LICENSE_DURATION_NON_FIXED
    ? LicenseDuration.Fixed
    : LicenseDuration.NoExpiration;
};

export const getLicenseDurationByType = (
  licenseDurationType: LicenseDuration,
  licenseDuration: number
) =>
  licenseDurationType === LicenseDuration.NoExpiration
    ? LICENSE_DURATION_NON_FIXED
    : licenseDuration;

export const getRequireManagerApproval = (license: AddUpdateLicenseVM) =>
  license?.[LicenseFormFields.RequireManagerApproval] || false;

export const getRequireAdminApproval = (license: AddUpdateLicenseVM) =>
  license?.[LicenseFormFields.RequireAdminApproval] || false;

export const getLicenseCurrency = (
  license: AddUpdateLicenseVM,
  usersCurrency: CurrencyCode
) =>
  getIsLicenseNew(license)
    ? usersCurrency
    : license?.[LicenseFormFields.Currency];

export const getRequireLicenseDurationType = (license: AddUpdateLicenseVM) => {
  if (!license) return null;

  return license?.[LicenseFormFields.LicenseDuration] !==
    LICENSE_DURATION_NON_FIXED
    ? LicenseDuration.Fixed
    : LicenseDuration.NoExpiration;
};
