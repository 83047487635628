import {useEffect, useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/../components/reusable/Button/ButtonEnums';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import Grid from '@blocks/Grid';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import 'react-quill/dist/quill.snow.css';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {
  charLimitErrorMessage,
  ErrorMessage,
  InputLabel,
  InputLabelStub,
} from '@components/reusable/LearnInForm';
import {
  CustomProgramAdminDetailVM,
  IRestrictedProgramRecommendedUserVM,
  SkillTag,
  RemoveRestrictedCustomProgramAccessPayload,
} from '@models/serverModels';
import {DurationUnitType} from '@generated/enums';
import LearnInAutocomplete from '@components/reusable/Autocomplete/Autocomplete';
import LearnInTag from '@blocks/LearnInTag';
import {COLORS} from '@utils/constants';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import {
  FileTypeDetails,
  FileUploadType,
  generateFileUploadMediaTypes,
  getBase64,
  validateUserUpload,
} from '@utils/uploadUtils';
import {b64toBlob} from '@utils/image-utils';
import UploadPreviewLogo from '@blocks/UploadPreviewLogo';
import {DeleteOutlined} from '@ant-design/icons';
import LearnInTable, {
  TooltipCol,
  dateTimeSorter,
  renderDateTime,
  stringSorter,
} from '@components/reusable/LearnInTable';
import {Divider, Spin} from 'antd';
import RestrictedAccessForm from '@components/reusable/RestrictedAccess/RestrictedAccessForm';
import Dragger from 'antd/lib/upload/Dragger';
import {RcFile} from 'antd/lib/upload';
import {notify} from '@components/user/notifications';
import EmailNotificationsContainer from './emailNotifications/EmailNotifications.container';
import {
  FooterButtonContainer,
  FooterContainer,
} from '@components/reusable/Footer/Footer';
import TempSlackConfigurationForm from '@components/admin/pages/programs/customPrograms/setup/TempSlackConfigurationForm';
import UploadInstructions from '@components/reusable/Upload/UploadInstructions';
import {UserSearchResultVM} from '@generated/interfaces';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {DoubleLineOption} from '@components/reusable/Select/OptionItem.style';
import {getTitle} from '@utils/enumMapping/MapTextToUserLanguage';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const ContentContainer = styled.div`
  width: 100%;
`;

const ModalTitleContainer = styled.div`
  margin-bottom: 24px;
`;

export const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral900};
`;

export const ModalSubtitle = styled.div`
  font-weight: normal;
  font-size: 1rem;
  color: ${COLORS.Neutral900};
`;

export const AttachmentCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 9px 16px;
  border: 1px solid #bcbdbe;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 16px;

  button {
    height: fit-content;
    padding: 0;
  }
`;

const ProgramTagsContainer = styled.div`
  display: flex;
  margin-top: 9px;
  flex-wrap: wrap;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface IFormFieldErrors {
  title?: string;
  programLengthDuration?: string;
  programLengthUnit?: string;
  tags?: string;
  shortDescription?: string;
  privacySetting?: string;
}

export interface ICustomProgramSetupScreenProps {
  tagSuggestions: any[];
  customProgramForEdit?: CustomProgramAdminDetailVM;
  onClickSave: any;
  onDeleteCoverImage: () => void;
  onCompanyUserSearch: (name: string) => void;
  companyUsersSearchResults: UserSearchResultVM[];
  isCompanyUserSearchInProgress: boolean;
  selectedUsersForAdding: any[];
  onCompanyUserSearchChange: (searchText: [string]) => void;
  recommendedUsers: IRestrictedProgramRecommendedUserVM[];
  onClickAddRecommendedUsers: () => void;
  onClickRemoveAccess: ({
    userCompanyId,
    customProgramId,
  }: RemoveRestrictedCustomProgramAccessPayload) => void;
  showSlackControls: boolean;
  isWelcomeEmailEnabled?: boolean;
  setIsWelcomeEmailEnabled: (isEnabled?: boolean) => void;
}

function CustomProgramSetupScreen({
  setIsWelcomeEmailEnabled,
  tagSuggestions,
  customProgramForEdit,
  companyUsersSearchResults,
  onClickSave,
  onDeleteCoverImage,
  onCompanyUserSearch,
  isCompanyUserSearchInProgress,
  selectedUsersForAdding,
  onCompanyUserSearchChange,
  recommendedUsers,
  onClickAddRecommendedUsers,
  onClickRemoveAccess,
  showSlackControls,
  isWelcomeEmailEnabled,
}: ICustomProgramSetupScreenProps) {

  const SHORT_DESCRIPTION_CHAR_LIMIT = 600;
  const MAX_TAG_LENGTH = 5;
  
  const ErrorMessages = {
    MissingTitle: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
      fieldName: i18n.t(k.PROGRAM__TITLE).toLocaleLowerCase(),
    }),
    MissingDescription: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
      fieldName: i18n.t(k.PROGRAM__DESCRIPTION).toLocaleLowerCase(),
    }),
    MissingProgramLengthDuration: i18n.t(
      k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
      {
        fieldName: i18n.t(k.PROGRAM__LENGTH).toLocaleLowerCase(),
      }
    ),
    MissingProgramLengthUnit: i18n.t(
      k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
      {
        fieldName: i18n.t(k.PROGRAM__LENGTH_UNIT).toLocaleLowerCase(),
      }
    ),
    TagLimit: i18n.t(k.VALIDATION__CANT_ADD_MORE_TAGS__FORMAT, {
      max: MAX_TAG_LENGTH,
    }),
    RestrictedAccess: i18n.t(k.VALIDATION__PRIVACY_SETTING),
    ShortDescriptionCharacterLimit: charLimitErrorMessage(
      i18n.t(k.GENERIC__DESCRIPTION),
      SHORT_DESCRIPTION_CHAR_LIMIT
    ),
  };

  const defaultEmailNotificationSetting = true;
  const [createProgramInProgress, setCreateProgramInProgress] = useState(false);
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [programLengthDuration, setProgramLengthDuration] = useState(null);
  const [programLengthUnit, setProgramLengthUnit] = useState(null);
  const [longDescription, setLongDescription] = useState('');
  const [tags, setTags] = useState<SkillTag[]>([]);
  const [tag, setTag] = useState<string>('');
  const [formFieldErrors, setFormFieldErrors] = useState<IFormFieldErrors>({});
  const [previewUrl, setPreviewUrl] = useState('');
  const [restrictedAccess, setRestrictedAccess] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [_isWelcomeEmailEnabled, _setIsWelcomeEmailEnabled] = useState(
    customProgramForEdit?.isWelcomeEmailEnabled ??
      defaultEmailNotificationSetting
  );
  const [_isNudgeEmailEnabled, setIsNudgeEmailEnabled] = useState(
    customProgramForEdit?.isNudgeEmailEnabled ?? defaultEmailNotificationSetting
  );
  const [_isProgramCompletionEmailEnabled, setIsProgramCompletionEmailEnabled] =
    useState(
      customProgramForEdit?.isProgramCompletionEmailEnabled ??
        defaultEmailNotificationSetting
    );

  const {isFeatureFlagOn} = useFeatureFlags();

  useEffect(() => {
    if (!attachments.length) return;
    const url = URL.createObjectURL(b64toBlob(attachments[0].fileContent));
    setPreviewUrl(url);
  }, [attachments]);

  useEffect(() => {
    if (restrictedAccess !== null) {
      setFormFieldErrors({...formFieldErrors, privacySetting: null});
    }
  }, [restrictedAccess]);

  const handleDeleteAttachment = async () => {
    if (customProgramForEdit?.id) {
      onDeleteCoverImage();
    }

    setAttachments([]);
    setPreviewUrl('');
  };

  // Input Change Handlers
  const handleChangeTitle = (e: any) => {
    setTitle(e.target.value);
    if (!!e.target.value && formFieldErrors.title) {
      setFormFieldErrors({...formFieldErrors, title: null});
    } else if (!e.target.value && !formFieldErrors.title) {
      setFormFieldErrors({
        ...formFieldErrors,
        title: ErrorMessages.MissingTitle,
      });
    }
  };

  const handleChangeDescription = (e: any) => {
    setShortDescription(e.target.value);
    if (
      e.target.value?.length <= SHORT_DESCRIPTION_CHAR_LIMIT &&
      !!formFieldErrors.shortDescription
    ) {
      setFormFieldErrors({...formFieldErrors, shortDescription: null});
    } else if (
      e.target.value?.length > SHORT_DESCRIPTION_CHAR_LIMIT &&
      !formFieldErrors.shortDescription
    ) {
      setFormFieldErrors({
        ...formFieldErrors,
        shortDescription: ErrorMessages.ShortDescriptionCharacterLimit,
      });
    }
  };

  const handleChangeProgramLengthDuration = (e: any) => {
    setProgramLengthDuration(Number(e.target.value));
    if (!!e.target.value && formFieldErrors.programLengthDuration) {
      setFormFieldErrors({...formFieldErrors, programLengthDuration: null});
    }
  };

  const handleChangeProgramLengthType = (value: any) => {
    setProgramLengthUnit(value);
    if (!!value && formFieldErrors.programLengthUnit) {
      setFormFieldErrors({...formFieldErrors, programLengthUnit: null});
    }
  };

  const handlePressEnterTagInput = (e?: any) => {
    if (e.key === 'Enter') {
      // Check if tag is a duplicate
      const existingTagOnThisProgram = tags.find(
        (t) =>
          t.name.trim().toLocaleLowerCase() === tag.trim().toLocaleLowerCase()
      );
      if (existingTagOnThisProgram) return;

      // Check if tag is a suggestion
      const suggestedTag = tagSuggestions.find(
        (value) =>
          value.name.trim().toLocaleLowerCase() ===
          tag.trim().toLocaleLowerCase()
      );
      if (suggestedTag) {
        setTags([...tags, suggestedTag]);
        return setTag('');
      }

      setTags([...tags, {name: tag}].sort());
      setTag('');
      if (tags.length >= MAX_TAG_LENGTH) {
        setFormFieldErrors({...formFieldErrors, tags: ErrorMessages.TagLimit});
      }
    }
  };

  const handleTagSuggestionClick = (id: number): void => {
    // Check that tags can be added
    if (tags.length >= MAX_TAG_LENGTH) return;

    // Check if tag is a duplicate
    const existingTagOnThisProgram = tags.find((t) => t.id === id);
    if (existingTagOnThisProgram) return;

    // Add tag suggestion
    const tagSuggestion = tagSuggestions.find((t) => t.id === id);
    setTags([...tags, tagSuggestion]);
    setTag('');
  };

  const handleChangeTag = (value: any) => {
    setTag(value);
  };

  // Form fields validation
  const getFormFieldErrors = () => {
    const errors: IFormFieldErrors = {};
    if (!title) {
      errors.title = ErrorMessages.MissingTitle;
    }
    if (tags.length > 5) {
      errors.tags = ErrorMessages.TagLimit;
    }
    if (shortDescription?.length > SHORT_DESCRIPTION_CHAR_LIMIT) {
      errors.shortDescription = ErrorMessages.ShortDescriptionCharacterLimit;
    }
    if (restrictedAccess === null) {
      errors.privacySetting = ErrorMessages.RestrictedAccess;
    }
    if (!!programLengthDuration && !Number.isInteger(programLengthUnit)) {
      errors.programLengthUnit = ErrorMessages.MissingProgramLengthUnit;
    }

    if (!programLengthDuration && !!Number.isInteger(programLengthUnit)) {
      errors.programLengthDuration = ErrorMessages.MissingProgramLengthDuration;
    }
    return errors;
  };

  const handleClickSave = () => {
    // Check for form errors, and if there are any display errors to user without saving
    const formFieldErrors = getFormFieldErrors();
    const formHasErrors = Object.values(formFieldErrors).some(
      (error) => !!error
    );
    if (formHasErrors) {
      return setFormFieldErrors(formFieldErrors);
    }

    // If no errors, clear all error messages and save the custom program
    setFormFieldErrors({});
    if (!customProgramForEdit?.id) {
      setCreateProgramInProgress(true);
    }
    onClickSave({
      id: customProgramForEdit?.id,
      title,
      shortDescription,
      longDescription,
      programLength: Number.isInteger(programLengthDuration)
        ? programLengthDuration
        : null,
      programLengthUnit: Number.isInteger(programLengthUnit)
        ? programLengthUnit
        : null,
      skills: tags,
      imageUrl: previewUrl,
      images: attachments,
      restrictedAccess,
      isWelcomeEmailEnabled: _isWelcomeEmailEnabled,
      isNudgeEmailEnabled: _isNudgeEmailEnabled,
      isProgramCompletionEmailEnabled: _isProgramCompletionEmailEnabled,
    });
  };

  // Reset Form
  const resetForm = () => {
    setTitle('');
    setShortDescription('');
    setLongDescription('');
    setTags([]);
    setProgramLengthDuration(null);
    setProgramLengthUnit(null);
    setFormFieldErrors({});
    setPreviewUrl('');
    setRestrictedAccess(null);
    setIsNudgeEmailEnabled(defaultEmailNotificationSetting);
    setIsProgramCompletionEmailEnabled(defaultEmailNotificationSetting);
    _setIsWelcomeEmailEnabled(defaultEmailNotificationSetting);
    setIsWelcomeEmailEnabled(defaultEmailNotificationSetting);
  };

  useEffect(() => {
    if (
      customProgramForEdit !== undefined &&
      _isWelcomeEmailEnabled !== undefined &&
      _isWelcomeEmailEnabled !== customProgramForEdit?.isWelcomeEmailEnabled
    ) {
      handleClickSave();
    }
  }, [_isWelcomeEmailEnabled, customProgramForEdit]);

  // Populate Form For Update or Duplicate On Open
  useEffect(() => {
    if (!!customProgramForEdit) {
      const customProgramTitle = getTitle(customProgramForEdit.title);
      setTitle(customProgramTitle || '');
      setShortDescription(customProgramForEdit.shortDescription || '');
      setLongDescription(customProgramForEdit.longDescription || '');
      setTags(customProgramForEdit.skills || []);
      if (!previewUrl) {
        setPreviewUrl(customProgramForEdit.imageUrl || '');
      }
      setRestrictedAccess(customProgramForEdit.restrictedAccess);
      setProgramLengthDuration(customProgramForEdit.programLength);
      setProgramLengthUnit(customProgramForEdit.programLengthUnit);
      if (createProgramInProgress) {
        setCreateProgramInProgress(false);
      }

      setIsNudgeEmailEnabled(customProgramForEdit.isNudgeEmailEnabled);
      setIsProgramCompletionEmailEnabled(
        customProgramForEdit.isProgramCompletionEmailEnabled
      );
      _setIsWelcomeEmailEnabled(customProgramForEdit.isWelcomeEmailEnabled);
      setIsWelcomeEmailEnabled(customProgramForEdit.isWelcomeEmailEnabled);
    } else {
      resetForm();
    }
  }, [customProgramForEdit]);

  const permittedUserTableCols = useMemo(() => {
    return [
      isFeatureFlagOn.ShowEmployeeEmail
        ? TooltipCol({
            dataIndex: 'userName',
            title: i18n.t(k.EMPLOYEE__NAME),
            tooltipDataIndex: 'email',
            width: 350,
          })
        : {
            title: i18n.t(k.EMPLOYEE__NAME),
            dataIndex: 'userName',
            key: 'userName',
            sorter: stringSorter<any>('userName'),
          },
      {
        title: i18n.t(k.DATE__ADDED),
        dataIndex: 'createdOn',
        key: 'createdOn',
        render: renderDateTime(),
        sorter: dateTimeSorter('createdOn'),
      },
      {
        title: i18n.t(k.GENERIC__ACTION),
        dataIndex: 'status',
        key: 'status',
        render: (_: any, {userCompanyId, customProgramId}) => (
          <LearnInButton
            tag={ButtonTags.Footer}
            onClick={() => {
              onClickRemoveAccess({userCompanyId, customProgramId});
            }}>
            {i18n.t(k.CTA__REMOVE)}
          </LearnInButton>
        ),
      },
    ];
  }, []);
  return (
    <>
      <LearnInContainer
        tag={ContainerTags.CommonCard}
        style={{maxWidth: '928px', marginBottom: '24px', width: '100%'}}>
        <ContentContainer>
          <ModalTitleContainer>
            <ModalTitle>{i18n.t(k.GENERIC__SETUP)}</ModalTitle>
            <ModalSubtitle>
              {i18n.t(k.PROGRAM__CUSTOM__SETUP__INFO)}
            </ModalSubtitle>
          </ModalTitleContainer>
          <Grid.Container>
            <Grid.Box>
              <InputLabel
                htmlFor="program-title-input"
                label={i18n.t(k.PROGRAM__TITLE)}
                required
              />
              <LearnInInput
                id="program-title-input"
                data-testid="program-title-input"
                tag={InputTags.PRIMARY}
                value={title}
                onChange={handleChangeTitle}
                isError={!!formFieldErrors?.title}
              />
              <ErrorMessage
                message={formFieldErrors?.title}
                data-testid="title-input-error-message"
              />
            </Grid.Box>
            <Grid.Box>
              <InputLabel
                htmlFor="program-short-description-input"
                label={i18n.t(k.GENERIC__DESCRIPTION__SHORT)}
              />
              <LearnInTextArea
                id="program-short-description-input"
                data-testid="program-short-description"
                tag={TextAreaTags.PRIMARY}
                value={shortDescription}
                onChange={handleChangeDescription}
                isError={!!formFieldErrors?.shortDescription}
              />
              <ErrorMessage
                message={formFieldErrors?.shortDescription}
                data-testid="short-description-char-length-input-error-message"
              />
            </Grid.Box>
            <Grid.Box quarter>
              <InputLabel
                htmlFor="program-length-duration-input"
                label={i18n.t(k.PROGRAM__LENGTH)}
              />
              <LearnInInput
                id="program-length-duration-input"
                data-testid="program-length-duration-input"
                tag={InputTags.PRIMARY}
                type="number"
                min={1}
                placeholder={i18n.t(k.VALIDATION__MISSING_FIELD__FORMAT, {
                  fieldName: i18n.t(k.GENERIC__AMOUNT),
                })}
                value={programLengthDuration}
                onChange={handleChangeProgramLengthDuration}
                isError={!!formFieldErrors?.programLengthDuration}
              />
              <ErrorMessage
                message={formFieldErrors?.programLengthDuration}
                data-testid="duration-length-input-error-message"
              />
            </Grid.Box>
            <Grid.Box quarter>
              <InputLabelStub />
              <LearnInSelect
                aria-label={i18n.t(k.PROGRAM__LENGTH_UNIT)}
                data-testid="program-length-type-select"
                placeholder={i18n.t(k.VALIDATION__SELECT_SPAN)}
                onChange={handleChangeProgramLengthType}
                value={programLengthUnit}
                isError={!!formFieldErrors?.programLengthUnit}>
                <LearnInSelectOption
                  data-testid="time-span-hours"
                  value={DurationUnitType.Hours}>
                  {i18n.t(k.DATE__HOUR__PLURAL)}
                </LearnInSelectOption>
                <LearnInSelectOption
                  data-testid="time-span-days"
                  value={DurationUnitType.Days}>
                  {i18n.t(k.DATE__DAY__PLURAL)}
                </LearnInSelectOption>
                <LearnInSelectOption
                  data-testid="time-span-weeks"
                  value={DurationUnitType.Weeks}>
                  {i18n.t(k.DATE__WEEK__PLURAL)}
                </LearnInSelectOption>
                <LearnInSelectOption
                  data-testid="time-span-months"
                  value={DurationUnitType.Months}>
                  {i18n.t(k.DATE__MONTH__PLURAL)}
                </LearnInSelectOption>
                <LearnInSelectOption
                  data-testid="time-span-years"
                  value={DurationUnitType.Years}>
                  {i18n.t(k.DATE__YEAR__PLURAL)}
                </LearnInSelectOption>
              </LearnInSelect>
              <ErrorMessage
                message={formFieldErrors?.programLengthUnit}
                data-testid="duration-type-input-error-message"
              />
            </Grid.Box>
            <Grid.Box half>
              <InputLabel
                htmlFor="program-tag-autocomplete-field"
                label={i18n.t(k.TAG__PLURAL)}
              />
              <LearnInAutocomplete
                id="program-tag-autocomplete-field"
                data-testid="program-tag-autocomplete"
                disabled={tags.length >= MAX_TAG_LENGTH}
                style={{width: '100%'}}
                options={tagSuggestions?.map((tag) => ({
                  label: tag.name,
                  value: tag.id,
                }))}
                value={tag}
                onChange={handleChangeTag}
                onSelect={(id: any) => handleTagSuggestionClick(Number(id))}
                onKeyPress={handlePressEnterTagInput}
                placeholder={i18n.t(k.TAG__SEARCH_OR_ADD)}
              />
              <ErrorMessage message={formFieldErrors?.tags} />
              <ProgramTagsContainer>
                {tags.map((tag) => {
                  const onClickX = () => {
                    setTags(
                      tags.filter(
                        (t) =>
                          t.name.trim().toLocaleLowerCase() !==
                          tag.name.trim().toLocaleLowerCase()
                      )
                    );
                    if (
                      tags.length <= MAX_TAG_LENGTH &&
                      !!formFieldErrors.tags
                    ) {
                      setFormFieldErrors({...formFieldErrors, tags: null});
                    }
                  };
                  return (
                    <div
                      key={tag.id}
                      style={{marginRight: '6px', marginBottom: '6px'}}>
                      <LearnInTag label={tag.name} onClickX={onClickX} />
                    </div>
                  );
                })}
              </ProgramTagsContainer>
            </Grid.Box>
            <Grid.Box>
              <InputLabel
                htmlFor="file-upload-input"
                label={i18n.t(
                  previewUrl ? k.PROGRAM__IMAGE : k.FILE__UPLOAD__IMAGE
                )}
              />
              {previewUrl ? (
                <AttachmentCard>
                  <UploadPreviewLogo imageSrc={previewUrl} size={75} />
                  <LearnInButton
                    aria-label={i18n.t(k.CTA__DELETE)}
                    icon={<DeleteOutlined aria-hidden="true" />}
                    onClick={handleDeleteAttachment}
                    tag={ButtonTags.DestructiveRemovedBorder}
                  />
                </AttachmentCard>
              ) : (
                <>
                  <Dragger
                    id="file-upload-input"
                    customRequest={async ({file}) => {
                      if (
                        validateUserUpload(
                          file as any,
                          [],
                          UploadConstraints.CustomProgramHeaderImageLimit,
                          'bytes',
                          generateFileUploadMediaTypes([FileUploadType.Images])
                        )
                      ) {
                        getBase64(file, (data: any) =>
                          setAttachments((images: any) => [
                            ...images,
                            {
                              fileName: (file as RcFile).name,
                              fileContent: btoa(data),
                              description: '',
                            },
                          ])
                        );
                      } else {
                        notify.uploadContentAttachmentIsInvalid();
                      }
                    }}
                    showUploadList={false}
                    maxCount={1}
                    height={100}
                    multiple={false}
                    accept={[
                      ...FileTypeDetails[FileUploadType.Images].fileSuffixes,
                    ].join(', ')}>
                    <UploadInstructions
                      fileTypes=".png, .jpg, .jpeg"
                      maxSize="1 MB"
                    />
                    <p style={{color: COLORS.Red800}}>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                        }}></button>
                    </p>
                  </Dragger>
                </>
              )}
            </Grid.Box>
          </Grid.Container>
        </ContentContainer>
      </LearnInContainer>
      <LearnInContainer
        tag={ContainerTags.CommonCard}
        style={{maxWidth: '928px', width: '100%', marginBottom: '24px'}}>
        <ContentContainer>
          <ModalTitleContainer>
            <ModalTitle>{i18n.t(k.GENERIC__PRIVACY_SETTINGS)}</ModalTitle>
            <ModalSubtitle>
              {i18n.t(k.PROGRAM__CUSTOM__SETUP__AVAILABILITY)}
            </ModalSubtitle>
          </ModalTitleContainer>
          <RestrictedAccessForm
            restrictedAccess={restrictedAccess}
            setRestrictedAccess={setRestrictedAccess}
          />
          <Grid.Container>
            {restrictedAccess && (
              <>
                <Divider style={{margin: '-24px 0px 24px'}} />
                <Grid.Box half>
                  <ModalTitle>{i18n.t(k.EMPLOYEE__PLURAL__ADDED)}</ModalTitle>
                </Grid.Box>
                <Grid.Box half style={{display: 'flex'}}>
                  <LearnInSelect
                    style={{marginRight: '12px', width: '100%'}}
                    mode="multiple"
                    className="program-nomination-list-search-bar"
                    labelInValue
                    value={selectedUsersForAdding}
                    placeholder={i18n.t(k.EMPLOYEE__SEARCH_TO_ADD)}
                    notFoundContent={
                      isCompanyUserSearchInProgress ? (
                        <Spin size="small" />
                      ) : null
                    }
                    filterOption={false}
                    onSearch={onCompanyUserSearch}
                    onChange={onCompanyUserSearchChange}
                    optionLabelProp="label">
                    {companyUsersSearchResults?.map(({id, name, email}) => {
                      // Filter out already recommended users
                      if (recommendedUsers?.some((user) => user.id === id))
                        return null;
                      return (
                        <LearnInSelectOption value={id} key={id} label={name}>
                          {isFeatureFlagOn.ShowEmployeeEmail ? (
                            <DoubleLineOption>
                              {name}
                              <span>{email}</span>
                            </DoubleLineOption>
                          ) : (
                            name
                          )}
                        </LearnInSelectOption>
                      );
                    })}
                  </LearnInSelect>
                  <div>
                    <LearnInButton
                      tag={ButtonTags.Secondary}
                      onClick={onClickAddRecommendedUsers}>
                      {i18n.t(k.GENERIC__ADD)}
                    </LearnInButton>
                  </div>
                </Grid.Box>
                <div style={{width: '100%'}}>
                  <LearnInTable
                    noDataStyle={{height: 'auto'}}
                    dataSource={recommendedUsers || []}
                    columns={permittedUserTableCols}
                  />
                </div>
              </>
            )}
          </Grid.Container>
          <ErrorMessage message={formFieldErrors?.privacySetting} />
        </ContentContainer>
      </LearnInContainer>
      <div
        style={{
          marginBottom: '36px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <EmailNotificationsContainer
          isWelcomeEmailEnabled={_isWelcomeEmailEnabled}
          onChangeWelcomeEmail={() => {
            _setIsWelcomeEmailEnabled((prev) => !prev);
            setIsWelcomeEmailEnabled(!isWelcomeEmailEnabled);
          }}
          customProgramId={customProgramForEdit?.id}
          isNudgeEmailEnabled={_isNudgeEmailEnabled}
          onChangeNudgeEmail={() => setIsNudgeEmailEnabled((prev) => !prev)}
          isProgramCompletionEmailEnabled={_isProgramCompletionEmailEnabled}
          onChangeCompletionEmail={() =>
            setIsProgramCompletionEmailEnabled((prev) => !prev)
          }
        />
      </div>
      {!!showSlackControls && !!customProgramForEdit && (
        <LearnInContainer
          tag={ContainerTags.CommonCard}
          style={{maxWidth: '928px', width: '100%', marginBottom: '24px'}}>
          <ContentContainer>
            <ModalTitleContainer>
              <ModalTitle>{i18n.t(k.SLACK__CONFIGURATION__TITLE)}</ModalTitle>
              <ModalSubtitle>
                {i18n.t(k.SLACK__CONFIGURATION__DESCRIPTION)}
              </ModalSubtitle>
            </ModalTitleContainer>
            <TempSlackConfigurationForm
              customProgramForEdit={customProgramForEdit}
            />
          </ContentContainer>
        </LearnInContainer>
      )}

      <FooterContainer>
        <FooterButtonContainer>
          <LearnInButton
            data-testid="custom-program-save-button"
            tag={ButtonTags.Primary}
            onClick={handleClickSave}>
            {i18n.t(createProgramInProgress ? k.STATUS__SAVING : k.CTA__SAVE)}
          </LearnInButton>
        </FooterButtonContainer>
      </FooterContainer>
    </>
  );
}

export default CustomProgramSetupScreen;
