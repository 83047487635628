import {
  ApprovalRankType,
  ApprovalStatus,
  ApprovalType,
  CurrencyCode,
  UserRequestType,
} from './../enums';
import {UserRequestApprovalVM} from './../interfaces';
import {
  PreApprovalApplicationVMMockData,
  getPreApprovalApplicationVMMockData,
} from './PreApprovalApplicationVM.mock';
import {faker} from '@faker-js/faker';

// Interface: UserRequestApprovalVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserRequestApprovalVMMockData(
  args?: Partial<UserRequestApprovalVM>
): UserRequestApprovalVM {
  return {
    amount: faker.datatype.number(),
    amountOverLimit: faker.datatype.number(), // optional
    amountType: String(faker.datatype.number()),
    applicationDetails: getPreApprovalApplicationVMMockData(),
    approvalExpirationDate: String(faker.date.past()), // optional
    approvalGuidelines: faker.word.noun(), // optional
    balance: faker.datatype.number(), // optional
    costCenter: faker.word.noun(),
    employeeReasonForApplication: faker.word.noun(),
    id: faker.datatype.number(),
    itemId: faker.datatype.number(),
    licenseCurrency: CurrencyCode.USD, // optional
    licenseId: faker.datatype.number(), // optional
    manager: faker.word.noun(),
    previousNotes: faker.word.noun(),
    previousRejectionNotes: faker.word.noun(),
    provider: faker.word.noun(),
    rank: ApprovalRankType.Initial,
    requestedBy: faker.word.noun(),
    requestedById: faker.datatype.number(),
    requestedDate: String(faker.date.past()),
    status: ApprovalStatus.Pending,
    title: faker.company.catchPhrase(),
    type: ApprovalType.PLAN_ITEM,
    userCurrencyAtRequest: CurrencyCode.USD,
    userRequestType: UserRequestType.Funds,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserRequestApprovalVMMockData: UserRequestApprovalVM = {
  amount: 5,
  amountOverLimit: 5, // optional
  amountType: '5',
  applicationDetails: PreApprovalApplicationVMMockData,
  approvalExpirationDate: '2023-03-29T22:17:19.738072', // optional
  approvalGuidelines: 'string', // optional
  balance: 123, // optional
  costCenter: 'string',
  employeeReasonForApplication: 'string',
  id: 1,
  itemId: 1,
  licenseCurrency: CurrencyCode.USD, // optional
  licenseId: 1, // optional
  manager: 'string',
  previousNotes: 'string',
  previousRejectionNotes: 'string',
  provider: 'string',
  rank: ApprovalRankType.Initial,
  requestedBy: 'string',
  requestedById: 1,
  requestedDate: '2023-03-29T22:17:19.738072',
  status: ApprovalStatus.Pending,
  title: 'Advanced Web Design',
  type: ApprovalType.PLAN_ITEM,
  userCurrencyAtRequest: CurrencyCode.USD,
  userRequestType: UserRequestType.Funds,
};
