import {i18n, k} from '@i18n/translate';
import {Modal} from 'antd';
import {notify} from '@components/user/notifications';
import {ContentType} from '@generated/enums';
import {useCustomProgramAdminSectionVMsQuery} from './apiEndpoints/customProgram/queries';
import {useDeleteCustomProgramContentMutation} from '@generated/hooks';

const {confirm} = Modal;

function useDeleteContent({
  contentId,
  customProgramId,
  contentType,
  onOk,
  onCancel,
}: {
  contentId: number;
  customProgramId?: number;
  contentType?: ContentType;
  onOk?: () => void;
  onCancel?: (...args: any[]) => any;
}): {handleDeleteContent: () => void} {
  const contentTypeText: string =
    contentType === ContentType.Text
      ? 'Content'
      : contentType === ContentType.Project
      ? 'Project'
      : contentType === ContentType.Event
      ? 'Event'
      : '';
  const customProgramSectionsQuery = useCustomProgramAdminSectionVMsQuery(
    customProgramId,
    {enabled: false}
  );

  const deleteCustomProgramContentMutation =
    useDeleteCustomProgramContentMutation({
      onSuccess: () => {
        notify.deleteCustomProgramContentSuccess();
        if (!!customProgramId) customProgramSectionsQuery.invalidateExact();
      },
      onError: () => {
        notify.deleteCustomProgramContentError();
      },
    });

  const handleDeleteContent = () =>
    confirm({
      cancelText: i18n.t(k.CTA__CANCEL),
      okText: i18n.t(k.PROMPT__DELETE_ITEM_YES__FORMAT, {
        item: contentType ? contentTypeText : i18n.t(k.CONTENT),
      }),
      okType: 'danger',
      title: i18n.t(k.PROMPT__DELETE_ITEM__FORMAT, {
        item: contentType ? contentTypeText : i18n.t(k.CONTENT),
      }),
      onOk: async () => {
        await deleteCustomProgramContentMutation.mutateAsync({
          pathVars: {contentId},
        });
        !!onOk && onOk();
      },
      onCancel: !!onCancel && onCancel(),
    });

  return {handleDeleteContent};
}

export default useDeleteContent;
