import {AcademyLeadershipCardContainerProps} from './AcademyLeadershipCard.interfaces';
import AcademyLeadershipCardView from './AcademyLeadershipCard.view';

function AcademyLeadershipCardContainer(
  props: AcademyLeadershipCardContainerProps
) {
  return <AcademyLeadershipCardView {...props} />;
}

export default AcademyLeadershipCardContainer;
