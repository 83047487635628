import * as React from 'react';
import {CloseCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';

const Button = styled.button`
  background: transparent;
  border: none;
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

const CircleIcon = styled(CloseCircleOutlined)`
  margin-left: 6px;
  font-size: 1.25rem;
`;

type CircleCloseButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

function CircleCloseButton({onClick}: CircleCloseButtonProps) {
  return (
    <Button onClick={onClick} aria-label={i18n.t(k.CTA__CLOSE)}>
      <CircleIcon aria-hidden="true" />
    </Button>
  );
}

export default CircleCloseButton;
