import './ProgressChart.scss';
import {Fragment} from 'react';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Row, Skeleton} from 'antd';
import {Progress} from '@ant-design/charts';
import {AdminReportsTabName} from '../../../../store/interface';
import {ProgressChartItem} from '../../../../store/interface';
import {COLORS} from '@utils/constants';

interface Props {
  onTabChange: (tabName: any) => void;
  progressChartData: ProgressChartItem[];
  progressChartLoading: boolean;
}

const config: any = {
  height: 80,
  color: [COLORS.Green500, COLORS.Neutral100],
};

const ProgressChart = ({
  onTabChange,
  progressChartData,
  progressChartLoading,
}: Props) => {
  return (
    <Fragment>
      <Card
        title={i18n.t(k.PROGRESS)}
        role="figure"
        aria-label={i18n.t(k.PROGRESS)}
        key="key"
        className="progress-item-card"
        style={{borderRadius: '8px'}}
        extra={
          <button
            className="pseudo-link-hover"
            onClick={() => onTabChange(AdminReportsTabName.PROGRESS)}>
            {i18n.t(k.CTA__VIEW_MORE)}
          </button>
        }>
        <Row gutter={[8, 8]} align="middle" style={{padding: '20px 24px'}}>
          {!progressChartLoading ? (
            progressChartData && progressChartData.length ? (
              progressChartData.map((progressChartItem: ProgressChartItem) => (
                <div key={progressChartItem.key}>
                  <Row justify="space-between" align="middle">
                    <Col xs={24} md={21}>
                      <p>{progressChartItem.name}</p>
                    </Col>
                    <Col xs={24} md={3} style={{float: 'right'}}>
                      <h1>{progressChartItem.percent}%</h1>
                    </Col>
                    <Col xs={24} md={24} style={{marginTop: -20}}>
                      <Progress
                        className="progress-chart"
                        {...config}
                        percent={progressChartItem.percent / 100}
                      />
                    </Col>
                  </Row>
                </div>
              ))
            ) : (
              <div style={{textAlign: 'center', wordWrap: 'break-word'}}>
                <Row justify="center" align="middle" style={{marginTop: '40%'}}>
                  <Col xs={3}></Col>
                  <Col xs={18}>
                    <h1>{i18n.t(k.REPORT__PROGRESS__NO_SUBMISSIONS)}</h1>
                    <p>{i18n.t(k.REPORT__PROGRESS__LACKING_DATA)}</p>
                  </Col>
                  <Col xs={3}></Col>
                </Row>
              </div>
            )
          ) : (
            <>
              <Col xs={24}>
                <Skeleton active />
              </Col>
              <Col xs={24}>
                <Skeleton active />
              </Col>
              <Col xs={24}>
                <Skeleton active />
              </Col>
            </>
          )}
        </Row>
      </Card>
    </Fragment>
  );
};

export default ProgressChart;
