import {
  BudgetElement,
  BreakdownContainer,
  Container,
  BudgetText,
  Dot,
  DonutContainer,
  DonutStatus,
  StatusContainer,
} from './BudgetDonut.styled';

import {i18n, k} from '@i18n/translate';
import NoDataImage from '@blocks/NoDataImage';
import {BudgetDonutViewProps} from './BudgetDonut.interfaces';
import {formatCurrency} from '@utils/moneyUtils';
import CircleWithLines from './circleFilledLines.svg';
import {COLORS} from '@utils/constants';
import {Pie} from '@ant-design/charts';
import {BudgetSections} from '@models/clientEnums';

function BudgetDonutView({
  config,
  data,
  determinePlbGaugeStatus,
  getColor,
  isFinanceLearningBudsSuccess,
  hasChart,
  user,
  exchangeRate,
  isDirectBilling,
}: BudgetDonutViewProps) {
  return (
    <Container>
      {isFinanceLearningBudsSuccess ? (
        <DonutContainer align="start" size="large" data-cy="budgetDisplay" wrap>
          {hasChart && (
            <StatusContainer>
              <div data-cy="budgetDonut">
                <Pie {...config} />
              </div>
              <DonutStatus color={COLORS.Neutral600} size="small">
                {determinePlbGaugeStatus}
              </DonutStatus>
            </StatusContainer>
          )}
          <BreakdownContainer>
            {data
              .filter((value) => !!value)
              .map(({type, title, value}, idx) => (
                <BudgetElement
                  key={idx}
                  data-cy={'budget' + title.split(' ').join('')}>
                  <BudgetText>
                    {hasChart &&
                      (type === 'pending' ? (
                        <img
                          style={{marginRight: '8px'}}
                          src={CircleWithLines}
                          alt={i18n.t(k.STATUS__PENDING)}
                        />
                      ) : (
                        <Dot color={getColor(type)} />
                      ))}
                    {title}
                  </BudgetText>
                  <BudgetText>
                    {isDirectBilling &&
                    (type === BudgetSections.Ready ||
                      type === BudgetSections.Awaiting)
                      ? formatCurrency(value, user?.currency, 1) //format only, since conversion is done in the sum
                      : formatCurrency(value, user?.currency, exchangeRate)}
                  </BudgetText>
                </BudgetElement>
              ))}
          </BreakdownContainer>
        </DonutContainer>
      ) : (
        <NoDataImage />
      )}
    </Container>
  );
}

export default BudgetDonutView;
