import {i18n, k} from '@i18n/translate';
import {List, Card, Row, Col} from 'antd';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';
import PlusImage from '../../assets/add-circle.svg';
import EmptyDataText from '@blocks/NoDataText';
import {ReimbursementVM} from '@models/serverModels';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {DATE_FORMAT} from '@utils/timeUtils';
import {ReimbursementStatus} from '@generated/enums';

interface Props {
  reimbursementData?: ReimbursementVM[];
  setShowAddReimbursementDrawer: (isVisible: boolean) => void;
}

export default function ProgressCard({
  reimbursementData,
  setShowAddReimbursementDrawer,
}: Props) {
  return (
    <Card
      role="article"
      aria-labelledby="progress-card-title"
      bordered={false}
      bodyStyle={{padding: '20px 0px 15px 0px'}}
      style={{borderRadius: '12px', padding: 0}}>
      <Row
        style={{
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '13px',
        }}>
        <Col xs={22}>
          <Title level={4} id="progress-card-title">
            {i18n.t(k.TUITION__REIMBURSEMENT_REQUESTS)}
          </Title>
        </Col>
        <Col xs={2}>
          <Row justify="end">
            <button onClick={() => setShowAddReimbursementDrawer(true)}>
              <img
                src={PlusImage}
                alt={i18n.t(k.CTA__ADD)}
                style={{width: '30px', height: '30px', cursor: 'pointer'}}
              />
            </button>
          </Row>
        </Col>
      </Row>
      <hr style={{opacity: 0.2, margin: 0}} />
      {reimbursementData?.length ? (
        <List
          itemLayout="horizontal"
          dataSource={reimbursementData}
          renderItem={(item: ReimbursementVM) => (
            <List.Item>
              <Row
                style={{
                  width: '100%',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}>
                <Col xs={4}>
                  <Row>
                    <Text strong style={{fontSize: '1rem'}}>
                      {moment(item.requestedOn).format(
                        DATE_FORMAT.MONTH_DAY_YEAR_PADDED
                      )}
                    </Text>
                  </Row>
                </Col>
                <Col xs={4}>
                  <Row>
                    <Text strong style={{fontSize: '1rem'}}>
                      ${item.totalRequestedAmount}
                    </Text>
                  </Row>
                </Col>
                <Col xs={10}>
                  <Row>
                    <Text strong style={{fontSize: '1rem'}}>
                      {item.title}
                    </Text>
                  </Row>
                </Col>
                <Col xs={6}>
                  <Row justify="end">
                    {item.status === ReimbursementStatus.NotPaid && (
                      <LearnInTag
                        label={i18n
                          .t(k.STATUS__NOT_SUBMITTED)
                          .toLocaleLowerCase()}
                      />
                    )}
                    {item.status === ReimbursementStatus.Paid && (
                      <LearnInTag
                        tagStyle={TagStyles.Green}
                        label={i18n.t(k.STATUS__PAID).toLocaleLowerCase()}
                      />
                    )}
                    {item.status === ReimbursementStatus.PaymentPending && (
                      <LearnInTag
                        tagStyle={TagStyles.Yellow}
                        label={i18n
                          .t(k.STATUS__PAYMENT_PENDING)
                          .toLocaleLowerCase()}
                      />
                    )}
                    {/* BEN-5321: item.status is ReimbursementStatus, however, the numbers handled in the UI go past the server enum, which ends at 2.
                    
                    These other states were probably kept for historic reasons, but they should be considered for removal now since non-old reimbursements won't reach these states: */}
                    {(item.status as unknown) === 3 && (
                      <LearnInTag
                        tagStyle={TagStyles.Red}
                        label={i18n.t(k.STATUS__REJECTED).toLocaleLowerCase()}
                      />
                    )}
                    {(item.status as unknown) === 4 && (
                      <LearnInTag
                        tagStyle={TagStyles.Yellow}
                        label={i18n.t(k.STATUS__CANCELLED).toLocaleLowerCase()}
                      />
                    )}
                    {(item.status as unknown) === 5 && (
                      <LearnInTag
                        tagStyle={TagStyles.Yellow}
                        label={i18n
                          .t(k.STATUS__PAYMENT_PENDING)
                          .toLocaleLowerCase()}
                      />
                    )}
                    {(item.status as unknown) === 6 && (
                      <LearnInTag
                        tagStyle={TagStyles.Green}
                        label={i18n.t(k.STATUS__PAID).toLocaleLowerCase()}
                      />
                    )}
                  </Row>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      ) : (
        <EmptyDataText
          header={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
          subHeader=""
        />
      )}
    </Card>
  );
}
