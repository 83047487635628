import {ReactQueryRequest} from './../utils';
import {NEW_BASE_PATH, BASE_PATH} from '.';

export const getCustomProgramContentUrlRq = (
  attachmentId: number
): ReactQueryRequest => {
  const path = [BASE_PATH, 'get-content-attachment-url', attachmentId].join(
    '/'
  );
  return {
    path,
    queryKey: [path],
  };
};

// returns an array CustomProgramAgendaVM[]
// of all content that has dates (events or other content with due dates)
// FE can filter Past & Upcoming based on the startDateUtc
// returns 403 if user doesn't have access to the program, 200 otherwise
export const getCustomProgramAgendaRq = (
  programId: number
): ReactQueryRequest => {
  const path = [BASE_PATH, 'get-custom-program-agenda', programId].join('/');
  return {
    path,
    queryKey: [path],
  };
};

export const getCheckPublishedCustomProgramsExistRq = (): ReactQueryRequest => {
  const path = [BASE_PATH, 'check-published-custom-programs-exist'].join('/');
  return {
    path,
    queryKey: [path],
  };
};

export const getRestrictedCustomProgramAccessUsersRq = (
  customProgramId: number
): ReactQueryRequest => {
  const path = [NEW_BASE_PATH, customProgramId, 'users'].join('/');
  return {
    path,
    queryKey: [path],
  };
};

export const getCustomProgramCohortAdminVMsRq = (
  customProgramId: number
): ReactQueryRequest => {
  const path = [BASE_PATH, 'get-cohorts', customProgramId].join('/');
  return {
    path,
    queryKey: [path],
  };
};

export const getCustomProgramProjectsRq = (
  customProgramId: number
): ReactQueryRequest => {
  const path = [BASE_PATH, 'get-custom-program-projects', customProgramId].join(
    '/'
  );
  return {
    path,
    queryKey: [path],
  };
};

export const getCustomProgramProjectSubmissionsRq = (
  projectId: number
): ReactQueryRequest => {
  const path = [BASE_PATH, 'get-project-submissions', projectId].join('/');
  return {
    path,
    queryKey: [path],
  };
};

export const getProjectSubmissionAttachmentUrlRq = (
  submissionId: number
): ReactQueryRequest => {
  const path = [BASE_PATH, 'get-project-submission-url', submissionId].join(
    '/'
  );
  return {
    path,
    queryKey: [path],
  };
};

export const getCustomProgramPermissionVMsRq = (
  customProgramId: number
): ReactQueryRequest => {
  const path = `${BASE_PATH}/get-user-permissions/${customProgramId}`;
  return {
    path,
    queryKey: [path, String(customProgramId)],
  };
};

export const getCustomProgramAdminDetailVMRq = (id: number) => {
  const baseUrl = `/${BASE_PATH}/get-custom-program/${id}`;
  const queryKey = [baseUrl];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getProgramSkillVMsRq = () => {
  const baseUrl = '/custom-program/get-skill-tags';
  const queryKey = [baseUrl];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getCustomProgramContentLinksRq = (id?: number) => {
  const baseUrl = `custom-program/get-custom-program-content-links/${id}`;
  const queryKey = [baseUrl, id?.toString() || ''];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getCustomProgramContentAttachmentsRq = (
  id?: number
): ReactQueryRequest => {
  const baseUrl = `custom-program/get-custom-program-content-attachments`;
  const path = `${baseUrl}/${id}`;
  const queryKey = [baseUrl, id?.toString() || ''];

  return {
    path,
    queryKey,
  };
};

export const getCustomProgramAdminDetailVMsRq = () => {
  const baseUrl = '/custom-program/get-parent-custom-programs';
  const queryKey = [baseUrl];

  return {
    path: baseUrl,
    queryKey,
  };
};

export const getStartedCustomPrograms = (): ReactQueryRequest => {
  const baseUrl = 'custom-program/get-started';
  const queryKey = [baseUrl];

  return {
    path: baseUrl,
    queryKey,
  };
};
