import {
  useAcademyEditorPeopleUsers,
  useAcademyProjectReviewerPeopleUsers,
} from '@components/user/pages/academy/academy.queries';
import {AcademyPeopleLeadershipTabContainerProps} from './AcademyPeopleLeadershipTab.interfaces';
import AcademyPeopleLeadershipTabView from './AcademyPeopleLeadershipTab.view';
import {useParams} from 'react-router-dom';

function AcademyPeopleLeadershipTabContainer({}: AcademyPeopleLeadershipTabContainerProps) {
  const {academyId} = useParams();
  const {data: editors} = useAcademyEditorPeopleUsers(parseInt(academyId));
  const {data: projectReviewers} = useAcademyProjectReviewerPeopleUsers(
    parseInt(academyId)
  );

  return (
    <AcademyPeopleLeadershipTabView
      editors={editors}
      projectReviewers={projectReviewers}
      showEditors={!!editors?.length}
      showProjectReviewers={!!projectReviewers?.length}
    />
  );
}

export default AcademyPeopleLeadershipTabContainer;
