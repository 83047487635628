import {IntegrationChatChannelType, IntegrationChatProvider} from './../enums';
import {UpdateAcademyIntegrationChatChannelRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdateAcademyIntegrationChatChannelRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdateAcademyIntegrationChatChannelRequestMockData(
  args?: Partial<UpdateAcademyIntegrationChatChannelRequest>
): UpdateAcademyIntegrationChatChannelRequest {
  return {
    channelExternalId: String(faker.datatype.number()), // optional
    channelLink: faker.internet.url(),
    channelName: faker.name.fullName(), // optional
    integrationChatChannelType: IntegrationChatChannelType.Manual,
    integrationChatProvider: IntegrationChatProvider.Slack,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdateAcademyIntegrationChatChannelRequestMockData: UpdateAcademyIntegrationChatChannelRequest =
  {
    channelExternalId: '1', // optional
    channelLink: 'https://this-person-does-not-exist.com/en',
    channelName: 'Bobby Johnson', // optional
    integrationChatChannelType: IntegrationChatChannelType.Manual,
    integrationChatProvider: IntegrationChatProvider.Slack,
  };
