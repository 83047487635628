import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';

export const DrawerTitle = styled.span`
  margin-left: 16px;
`;

export const FooterContainer = styled.section`
  display: flex;
  justify-content: space-between;

  .btn-group {
    display: flex;
  }
`;

export const ParticipantsCountTag = styled.article`
  padding: 8px;
  border-radius: 4px;
  background-color: ${COLORS.Yellow200};
  color: ${COLORS.Neutral900};
  font-size: ${pxToRem(16, 16)};
  line-height: ${pxToRem(20, 16)};
  display: flex;
  align-items: center;

  .count {
    font-weight: 500;
  }
`;
