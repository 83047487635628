import {LicenseUsageType, VoucherAssignMode} from './../enums';
import {IVoucherLicenseBasicInfo} from './../interfaces';
import {
  ILicenseBasicInfoMockData,
  getILicenseBasicInfoMockData,
} from './ILicenseBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: IVoucherLicenseBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIVoucherLicenseBasicInfoMockData(
  args?: Partial<IVoucherLicenseBasicInfo>
): IVoucherLicenseBasicInfo {
  return {
    ...getILicenseBasicInfoMockData(),
    licenseDuration: faker.datatype.number(),
    type: LicenseUsageType.AllPrograms,
    voucherAssignMode: VoucherAssignMode.NoCodes,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IVoucherLicenseBasicInfoMockData: IVoucherLicenseBasicInfo = {
  ...ILicenseBasicInfoMockData,
  licenseDuration: 123,
  type: LicenseUsageType.AllPrograms,
  voucherAssignMode: VoucherAssignMode.NoCodes,
};
