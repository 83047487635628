import './AdminReports.scss';
import {i18n, k} from '@i18n/translate';
import {Col, Row, Tabs} from 'antd';
import moment from 'moment';
import {AdminReportsTabName} from './store/interface';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import ProgramReportList from './components/ProgramReportList/ProgramReportList';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import FinanceReportList from './components/FinanceReportList/FinanceReportList';
import PrepaymentsReportList from './components/PrepaymentsReportList/PrepaymentsReportList';
import InsightsReportWrapper from './components/InsightsReport/InsightsReportWrapper';
import {useState} from 'react';
import useTreatment from '@hooks/useTreatment';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {
  ProgramProgressUpdateResult,
  ProgramUsageResult,
  ReimbursementsReportResult,
} from '@models/serverModels';
import ProgressReportListWrapper from '@components/admin/pages/reports/components/ProgressReportList/ProgressReportListWrapper';
import styled from 'styled-components';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {DATE_FORMAT} from '@utils/timeUtils';
import {useSearchParams} from 'react-router-dom';
import Select from 'react-select';

const Container = styled.div`
  .ant-tabs-tabpane {
    min-height: 50px;
  }
`;

interface Props {
  programReportData: ProgramUsageResult[];
  financeReportData: ReimbursementsReportResult[];
  progressReportData: ProgramProgressUpdateResult[];
  programReportLoading: boolean;
  financeReportLoading: boolean;
  progressReportLoading: boolean;
  showReimbursementsTab: boolean;
  fetchReport: (activeKey: AdminReportsTabName | string) => void;
  activeKey: any;
  isDirectBillingPlan: boolean;
  isReimbursementPlan: boolean;
}

const minYear = 2022;
const maxYear = moment().format(DATE_FORMAT.YEAR);

const AdminReports = ({
  programReportData,
  financeReportData,
  progressReportData,
  programReportLoading,
  financeReportLoading,
  progressReportLoading,
  showReimbursementsTab,
  fetchReport,
  activeKey,
  isDirectBillingPlan,
  isReimbursementPlan,
}: Props) => {
  const areProgressUpdatesEnabled =
    useTreatment(FeatureFlagExperiments.DisableProgressUpdates) ===
    FeatureFlagTreatments.Off;
  const areVirtualCardsEnabled =
    useTreatment(FeatureFlagExperiments.DisableVirtualCards) ===
    FeatureFlagTreatments.Off;
  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const [currentYear, setCurrentYear] = useState<number | string>(
    parseInt(maxYear)
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const toggleTab = (tabName) =>
    setSearchParams({tab: tabName, ...searchParams});

  const renderYearSelectDropdown = () => {
    const yearOptions = [];
    for (let year = minYear; year <= parseInt(maxYear); year++) {
      yearOptions.push({label: year.toString(), value: year});
    }
    return (
      <Col
        xs={8}
        style={{minWidth: 119, marginTop: 16, marginLeft: 32, maxWidth: 150}}>
        <Select
          options={yearOptions}
          onChange={(year) => setCurrentYear(year.value)}
          aria-label={i18n.t(k.DATE__YEAR)}
          menuPortalTarget={document.body}
          value={{value: currentYear, label: currentYear.toString()}}
        />
      </Col>
    );
  };
  return (
    <Container>
      <PageTitleBar fullWidth={true} title={i18n.t(k.REPORT__PLURAL)} />
      <LearnInTabs
        defaultActiveKey={AdminReportsTabName.INSIGHTS}
        activeKey={activeKey}
        onChange={(activeKey) => {
          toggleTab(activeKey);
          fetchReport(activeKey);
        }}>
        <Tabs.TabPane
          tab={i18n.t(k.INSIGHT__PLURAL)}
          key={AdminReportsTabName.INSIGHTS}>
          <PageContentLayout fullWidth>
            <Row className="program-report-list" gutter={[32, 10]}>
              {renderYearSelectDropdown()}
              <Col xs={24} md={24}>
                <InsightsReportWrapper
                  isDirectBillingPlan={isDirectBillingPlan}
                  currentYear={currentYear}
                  onTabChange={(activeKey) => fetchReport(activeKey)}
                />
              </Col>
            </Row>
          </PageContentLayout>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={i18n.t(k.PROGRAM__PLURAL)}
          key={AdminReportsTabName.Programs}>
          <PageContentLayout fullWidth>
            <Row gutter={[32, 8]}>
              <Col xs={24} md={24}>
                {!programReportLoading ? (
                  <ProgramReportList data={programReportData} />
                ) : (
                  <LoadingSpinner />
                )}
              </Col>
            </Row>
          </PageContentLayout>
        </Tabs.TabPane>
        {showReimbursementsTab && (
          <Tabs.TabPane
            tab={i18n.t(k.REIMBURSEMENT__PLURAL)}
            key={AdminReportsTabName.FINANCE}>
            <PageContentLayout fullWidth>
              <Row gutter={[32, 8]}>
                <Col xs={24} md={24}>
                  {!financeReportLoading ? (
                    <FinanceReportList
                      data={financeReportData}
                      exchangeRate={exchangeRate}
                      usersCurrency={getUserQuery.data?.currency}
                    />
                  ) : (
                    <LoadingSpinner />
                  )}
                </Col>
              </Row>
            </PageContentLayout>
          </Tabs.TabPane>
        )}
        {!isDirectBillingPlan &&
          !isReimbursementPlan &&
          areVirtualCardsEnabled && (
            <Tabs.TabPane
              tab={i18n.t(k.CARD__VIRTUAL_CARD__PLURAL)}
              key={AdminReportsTabName.Prepayment}>
              <PageContentLayout fullWidth>
                <Row gutter={[32, 8]}>
                  <Col xs={24} md={24}>
                    <PrepaymentsReportList
                      usersCurrency={getUserQuery.data?.currency}
                    />
                  </Col>
                </Row>
              </PageContentLayout>
            </Tabs.TabPane>
          )}
        {areProgressUpdatesEnabled && (
          <Tabs.TabPane
            tab={i18n.t(k.PROGRESS)}
            key={AdminReportsTabName.PROGRESS}>
            <PageContentLayout fullWidth>
              <Row gutter={[32, 8]}>
                <Col xs={24} md={24}>
                  {!progressReportLoading ? (
                    <ProgressReportListWrapper data={progressReportData} />
                  ) : (
                    <LoadingSpinner />
                  )}
                </Col>
              </Row>
            </PageContentLayout>
          </Tabs.TabPane>
        )}
      </LearnInTabs>
    </Container>
  );
};

export default AdminReports;
