import {Form} from 'antd';
import styled from 'styled-components';
import {
  InputLabel,
  StyledLearnInInput as LearnInInput,
} from '@components/reusable/LearnInForm';
import {i18n, k} from '@i18n/translate';
import React from 'react';
import {FORM_KEYS} from './constants';

const DEFAULT_ID = 'event-link';

const MeetingLink = styled.div`
  margin-top: 24px;
`;

interface EventLinkProps {
  initialValue?: string;
  fieldName?: string;
  id?: string;
}

const EventLink: React.FC<EventLinkProps> = ({
  fieldName = FORM_KEYS.EVENT_LINK,
  id = DEFAULT_ID,
  initialValue,
}) => {
  return (
    <MeetingLink>
      <InputLabel htmlFor={id} label={i18n.t(k.EVENT__MEETING_LINK)} />
      <Form.Item name={fieldName} initialValue={initialValue}>
        <LearnInInput id={id} />
      </Form.Item>
    </MeetingLink>
  );
};

export default EventLink;
