import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {CustomProgramAdminSectionVM} from '@models/serverModels';
import {Radio} from 'antd';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: #1b1c23;
  margin-bottom: 16px;
`;

const SelectLabel = styled.label`
  font-weight: normal;
  font-size: 1rem;
  color: #1b1c23;
  display: block;
  margin-bottom: 16px;
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

enum ActionOptions {
  Move,
  Delete,
}

/*
|--------------------------------------------------------------------------
| Modal Component
|--------------------------------------------------------------------------
*/

interface IDeleteCustomProgramSectionModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (moveContentToSectionId?: number) => void;
  moveContentSectionOptions: Pick<
    CustomProgramAdminSectionVM,
    'id' | 'title'
  >[];
  doesSectionHaveNoContent: boolean;
}

function DeleteCustomProgramSectionModal({
  visible,
  onCancel,
  onOk,
  moveContentSectionOptions,
  doesSectionHaveNoContent,
}: IDeleteCustomProgramSectionModalProps) {
  const [selectedAction, setSelectedAction] = useState<ActionOptions | null>(
    null
  );
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(
    null
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(
    doesSectionHaveNoContent
  );
  const isOkButtonEnabled =
    selectedAction === ActionOptions.Delete ||
    (selectedAction === ActionOptions.Move && selectedSectionId) ||
    doesSectionHaveNoContent;
  return (
    <LearnInModal
      data-testid-prefix="delete-custom-program-section"
      width={450}
      visible={visible}
      tag={ModalTags.MoneyIncentive}
      okButtonChild={i18n.t(k.SECTION__DELETE)}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      onCancel={() => {
        if (showDeleteConfirmation && !doesSectionHaveNoContent) {
          setShowDeleteConfirmation(false);
        } else {
          onCancel();
        }
      }}
      onOk={() => {
        if (doesSectionHaveNoContent) {
          onOk();
        } else {
          onOk(selectedSectionId);
        }
      }}
      okButtonTag={ButtonTags.Destructive}
      buttonDisabled={!isOkButtonEnabled}
      ariaLabel={i18n.t(k.SECTION__DELETE)}>
      {showDeleteConfirmation ? (
        <div>
          <ModalTitle>{i18n.t(k.SECTION__DELETE)}</ModalTitle>
          <SelectLabel>{i18n.t(k.SECTION__DELETE__PROMPT)}</SelectLabel>
        </div>
      ) : (
        <div>
          <ModalTitle>{i18n.t(k.SECTION__DELETE)}</ModalTitle>
          <SelectLabel>{i18n.t(k.SECTION__MOVE_WHERE)}</SelectLabel>
          <Radio
            disabled={!moveContentSectionOptions?.length}
            checked={selectedAction === ActionOptions.Move}
            onClick={() => setSelectedAction(ActionOptions.Move)}
            style={{marginBottom: '16px'}}>
            {i18n.t(k.SECTION__MOVE_TO_DIFFERENT)}
          </Radio>

          {selectedAction === ActionOptions.Move && (
            <div style={{marginBottom: '16px'}}>
              <LearnInSelect
                placeholder={i18n.t(k.SECTION__SELECT)}
                onChange={(value) => setSelectedSectionId(value)}
                value={selectedSectionId}
                style={{width: '100%'}}>
                {moveContentSectionOptions?.map(({id, title}, i: number) => {
                  return (
                    <LearnInSelectOption
                      key={`${title}${i}`}
                      data-testid={`section-option-${id}`}
                      value={id}>
                      {title}
                    </LearnInSelectOption>
                  );
                })}
              </LearnInSelect>
            </div>
          )}
          <Radio
            data-testid="delete-content-radio-button"
            checked={selectedAction === ActionOptions.Delete}
            onClick={() => setSelectedAction(ActionOptions.Delete)}>
            {i18n.t(k.SECTION__DELETE_PERMANENTLY)}
          </Radio>
        </div>
      )}
    </LearnInModal>
  );
}

export default DeleteCustomProgramSectionModal;
