import {
  Container,
  SectionHeader,
  SubSectionHeader,
  LeadershipCardsContainer,
  SubSectionHeaderContainer,
  AcademyLeadershipCardContainer,
} from './AcademyPeopleLeadershipTab.styled';
import {AcademyPeopleLeadershipTabViewProps} from './AcademyPeopleLeadershipTab.interfaces';
import {Avatar} from 'antd';
import {EditOutlined, EyeOutlined} from '@ant-design/icons';
import {AvatarColorWays, getAvatarColorwaysStyles} from '@utils/color';
import AcademyLeadershipCard from '../AcademyLeadershipCard';
import {getInitialsOfName} from '@components/providers/utils';
import {i18n, k} from '@i18n/translate';

function AcademyPeopleLeadershipTabView({
  editors,
  projectReviewers,
  showEditors,
  showProjectReviewers,
}: AcademyPeopleLeadershipTabViewProps) {
  return (
    <Container>
      <SectionHeader>{i18n.t(k.ACADEMY__CONTRIBUTOR__PLURAL)}</SectionHeader>
      {showEditors && (
        <>
          <SubSectionHeaderContainer>
            <Avatar
              style={getAvatarColorwaysStyles(AvatarColorWays.Blue)}
              icon={
                <EditOutlined
                  aria-hidden="true"
                  style={getAvatarColorwaysStyles(AvatarColorWays.Blue)}
                />
              }
            />{' '}
            <SubSectionHeader>
              {i18n.t(k.GENERIC__EDITOR__PLURAL)}
            </SubSectionHeader>
          </SubSectionHeaderContainer>
          <LeadershipCardsContainer>
            {editors?.map((editor) => (
              <AcademyLeadershipCardContainer key={editor.userCompanyId}>
                <AcademyLeadershipCard
                  initials={getInitialsOfName(editor.name)}
                  {...editor}
                />
              </AcademyLeadershipCardContainer>
            ))}
          </LeadershipCardsContainer>
        </>
      )}
      {showProjectReviewers && (
        <>
          <SubSectionHeaderContainer>
            <Avatar
              style={getAvatarColorwaysStyles(AvatarColorWays.Purple)}
              icon={
                <EyeOutlined
                  aria-hidden="true"
                  style={getAvatarColorwaysStyles(AvatarColorWays.Purple)}
                />
              }
            />{' '}
            <SubSectionHeader>
              {i18n.t(k.PROJECT__REVIEWER__PLURAL)}
            </SubSectionHeader>
          </SubSectionHeaderContainer>
          <LeadershipCardsContainer>
            {projectReviewers?.map((reviewer) => (
              <AcademyLeadershipCardContainer key={reviewer.userCompanyId}>
                <AcademyLeadershipCard
                  initials={getInitialsOfName(reviewer.name)}
                  {...reviewer}
                />
              </AcademyLeadershipCardContainer>
            ))}
          </LeadershipCardsContainer>
        </>
      )}
    </Container>
  );
}

export default AcademyPeopleLeadershipTabView;
