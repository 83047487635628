import {CreateAcademyUserRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {CreateAcademyUserRequestSchema} from '../zodSchemas/CreateAcademyUserRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyUsersController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/users/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUsersPostMutation} from '@generated/hooks';
 *
 *  const academiesUsersPost = useAcademiesUsersPostMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesUsersPost.mutate({
 *    payload: {
 *      ...CreateAcademyUserRequest
 *    },
 *    pathVars: {
 *      academyId,
 *      userCompanyId
 *    }
 *  })
 *
 */

export const useAcademiesUsersPostMutation = mutationHookFactory<
  CreateAcademyUserRequest, // payload
  {academyId: number; userCompanyId: number}, // path variables
  null
>(
  'useAcademiesUsersPostMutation',
  'post',
  ({academyId, userCompanyId}) =>
    `academies/${academyId}/users/${userCompanyId}`,
  {payloadSchema: CreateAcademyUserRequestSchema}
);
