import {useState, useEffect} from 'react';
import translate, {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import ConnectCalendarModalContainer from '@blocks/connectCalendarModal/ConnectCalendarModal.container';
import ContextMenuButton from '@blocks/ContextMenu';
import {MenuOutlined, CloseCircleOutlined} from '@ant-design/icons';
import {Breakpoints} from '@utils/responsiveUtils';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {parseToLocalMoment, DATE_FORMAT} from '@utils/timeUtils';
import {CustomProgramCohortAdminVM} from '@generated/interfaces';
import {CalendarOutlined} from '@ant-design/icons/lib/icons';
import {CalendarCallbackRedirect} from '@generated/enums';
import AvailableCohortCard from './AvailableCohortCard.view';
import {pxToRem} from '@utils/styleMixins';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import moment from 'moment';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  display: flex;
`;

const SelectLabel = styled.div`
  font-size: 1rem;
  font-weight: 700;
  margin-right: 12px;
`;

const CalendarButton: typeof LearnInButton = styled(LearnInButton)`
  color: ${COLORS.Blue800};
  cursor: pointer;
`;

const HeaderLink = styled.button`
  color: ${COLORS.Blue800};
  cursor: pointer;
  font-weight: normal;
  font-size: 1rem;
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: transparent;
  border: none;

  &:not(:first-child):before {
    content: '|';
    padding-left: 14px;
    padding-right: 14px;
  }
`;

const HeaderLinkContainer = styled.div`
  width: max-content;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: flex-end;
`;

/* Desktop */
const DesktopNavBarContainer = styled.div`
  @media (max-width: ${Breakpoints.Tablet}px) {
    display: none;
  }
`;

/* Mobile */
const MobileNavBarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  @media (min-width: ${Breakpoints.Tablet}px) {
    display: none;
  }
`;

const CohortsModalContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(rgba(53, 60, 66, 1), rgba(53, 60, 66, 0));
  z-index: 1000;
  overflow: scroll;
`;

const ModalTitle = styled.h1`
  font-weight: 700;
  font-size: ${pxToRem(24)};
  color: white;
`;

const ModalContentContainer = styled.div`
  margin: 8% auto 16%;
  width: fit-content;
`;

const AvailableCohortCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 464px 464px;
  grid-template-rows: auto auto;
  column-gap: 24px;
  row-gap: 24px;
  @media (max-width: ${Breakpoints.Tablet}px) {
    grid-template-columns: 420px;
    grid-template-rows: auto auto auto auto;
  }
`;

const CardContainer = styled.div`
  box-sizing: border-box;
  // @media (max-width: ${Breakpoints.Tablet}px) {
  //   max-width: 540px;
  // }
`;

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

export interface MobileNavBarProps {
  onCloseMobileNavMenu: () => void;
  onOpenMobileNavMenu: () => void;
  isMobileNavOpen: boolean;
}

function formatDate(date: string): string {
  return moment(date).format(DATE_FORMAT.MONTH_DAY_YEAR_ABBR);
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramUserDrawerHeaderProps {
  customProgramId: number;
  onChangeUpcomingCohortSelect: (id: number) => void;
  onClickAddToCalendar: () => void;
  onClickAddToPlan: () => void;
  onClickEditProgram: () => void;
  onClickViewCohorts: () => void;
  onClickRemove: () => void;
  onCloseMobileNavMenu: () => void;
  onOpenMobileNavMenu: () => void;
  showAddToCalendarButton: boolean;
  showAddToPlanButton: boolean;
  showEditProgramLink: boolean;
  showCohortLink: boolean;
  showCalendarMenu: boolean;
  showMobileNavMenu: boolean;
  showPlanItemContextMenu: boolean;
  upcomingCohorts: CustomProgramCohortAdminVM[] | undefined;
  showAddToCalenderButtonAsLoading?: boolean;
  cohortCounts?: number;
}

function CustomProgramUserDrawerHeader({
  customProgramId,
  onChangeUpcomingCohortSelect,
  onClickAddToCalendar,
  onClickAddToPlan,
  onClickEditProgram,
  onClickViewCohorts,
  onClickRemove,
  onCloseMobileNavMenu,
  onOpenMobileNavMenu,
  showCohortLink,
  showAddToCalendarButton,
  showAddToPlanButton,
  showCalendarMenu,
  showMobileNavMenu,
  showPlanItemContextMenu,
  upcomingCohorts,
  showAddToCalenderButtonAsLoading,
  showEditProgramLink,
}: CustomProgramUserDrawerHeaderProps) {
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showCohortsModal, setShowCohortsModal] = useState(false);
  const [cohortHasBeenSelectedFromModal, setCohortHasBeenSelectedFromModal] =
    useState(false);
  const [modalCohortId, setModalCohortId] = useState<null | number>(
    customProgramId
  );
  useEffect(() => {
    if (
      !showCohortsModal &&
      !!upcomingCohorts?.length &&
      showAddToPlanButton &&
      !cohortHasBeenSelectedFromModal
    ) {
      setShowCohortsModal(true);
    }
  }, [upcomingCohorts]);

  useEffect(() => {
    if (!!customProgramId && !modalCohortId) {
      setModalCohortId(customProgramId);
    }
  }, [customProgramId]);

  const customProgramUserDrawerHeaderId = `custom-program-user-drawer-header`;

  // increase width for any cohorts that have a title
  const upcomingCohortSelectWidth = upcomingCohorts?.some(
    ({cohortTitle}) => !!cohortTitle
  )
    ? 300
    : 200;
  function handleOpenCalendarModal() {
    setShowCalendarModal(true);
  }

  function handleCloseCalendarModal() {
    setShowCalendarModal(false);
  }

  function renderAddToPlan({value, onChange, showAddToPlanButton, showLabel}) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        {!!upcomingCohorts?.length && (
          <div
            style={{
              marginRight: '16px',
              display: 'flex',
              alignItems: 'center',
            }}>
            {showLabel && <SelectLabel>{i18n.t(k.COHORT)}</SelectLabel>}
            <div>
              <LearnInSelect
                onChange={onChange}
                value={value}
                placeholder={i18n.t(k.COHORT__SELECT)}
                style={{width: upcomingCohortSelectWidth}}>
                {upcomingCohorts?.map(
                  ({cohortTitle, id, startDateUtc, endDateUtc}) => {
                    const dateDisplay = `${parseToLocalMoment(
                      startDateUtc
                    ).format(DATE_FORMAT.MONTH_DAY_YEAR)} - 
                    ${parseToLocalMoment(endDateUtc).format(
                      DATE_FORMAT.MONTH_DAY_YEAR
                    )}`;
                    return (
                      <LearnInSelectOption value={id} key={id}>
                        {!!cohortTitle && (
                          <span style={{marginRight: 6}}>{cohortTitle}</span>
                        )}
                        {dateDisplay}
                      </LearnInSelectOption>
                    );
                  }
                )}
              </LearnInSelect>
            </div>
          </div>
        )}
        {showAddToPlanButton && (
          <LearnInButton tag={ButtonTags.Primary} onClick={onClickAddToPlan}>
            {i18n.t(k.PROGRAM__JOIN)}
          </LearnInButton>
        )}
      </div>
    );
  }

  function renderAdditionalButtons() {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        {showCalendarMenu && (
          <CalendarButton
            tag={ButtonTags.Link}
            onClick={handleOpenCalendarModal}>
            <CalendarOutlined aria-hidden="true" style={{width: '18px'}} />
            {i18n.t(k.CALENDAR__CONNECT)}
          </CalendarButton>
        )}
        {showAddToCalendarButton && (
          <CalendarButton
            tag={ButtonTags.Link}
            onClick={onClickAddToCalendar}
            loading={showAddToCalenderButtonAsLoading}>
            <CalendarOutlined style={{width: '18px'}} />
            {i18n.t(k.CALENDAR__ADD_TO)}
          </CalendarButton>
        )}
        {showPlanItemContextMenu && (
          <ContextMenuButton
            popupContainerId={customProgramUserDrawerHeaderId}
            itemId={customProgramId}
            menuItems={[
              {
                label: i18n.t(k.CTA__REMOVE),
                onClick: onClickRemove,
              },
            ]}
          />
        )}
      </div>
    );
  }
  function MobileNavBar({
    isMobileNavOpen,
    onCloseMobileNavMenu,
    onOpenMobileNavMenu,
  }: MobileNavBarProps) {
    const ToggleIcon = isMobileNavOpen ? (
      <button
        onClick={onCloseMobileNavMenu}
        style={{fontSize: '2em', marginLeft: '15px'}}
        aria-label={i18n.t(k.CTA__CLOSE)}>
        <CloseCircleOutlined aria-hidden="true" />
      </button>
    ) : (
      <button
        onClick={onOpenMobileNavMenu}
        style={{fontSize: '2em', marginLeft: '15px'}}
        aria-label={i18n.t(k.CTA__VIEW_MORE)}>
        <MenuOutlined aria-hidden="true" />
      </button>
    );
    return (
      <MobileNavBarContainer>
        {ToggleIcon}
        {showAddToPlanButton &&
          renderAddToPlan({
            value: customProgramId,
            onChange: onChangeUpcomingCohortSelect,
            showAddToPlanButton: true,
            showLabel: true,
          })}
        {renderAdditionalButtons()}
      </MobileNavBarContainer>
    );
  }

  const handleCloseCohortModal = () => {
    setShowCohortsModal(false);
    setCohortHasBeenSelectedFromModal(true);
  };

  return (
    <>
      <Container id={customProgramUserDrawerHeaderId}>
        <HeaderLinkContainer>
          {showCohortLink && (
            <HeaderLink
              tag={ButtonTags.Link}
              padding="10px 0px"
              onClick={onClickViewCohorts}>
              {i18n.t(k.COHORT__VIEW__PLURAL)}
            </HeaderLink>
          )}
          {showEditProgramLink && (
            <HeaderLink
              tag={ButtonTags.Link}
              className="after"
              onClick={onClickEditProgram}>
              {i18n.t(k.PROGRAM__EDIT)}
            </HeaderLink>
          )}
        </HeaderLinkContainer>
        <DesktopNavBarContainer>
          {showAddToPlanButton &&
            renderAddToPlan({
              value: customProgramId,
              onChange: onChangeUpcomingCohortSelect,
              showAddToPlanButton: true,
              showLabel: true,
            })}
          {renderAdditionalButtons()}
        </DesktopNavBarContainer>
        <MobileNavBar
          isMobileNavOpen={showMobileNavMenu}
          onCloseMobileNavMenu={onCloseMobileNavMenu}
          onOpenMobileNavMenu={onOpenMobileNavMenu}
        />
        <ConnectCalendarModalContainer
          visible={showCalendarModal}
          id={customProgramId}
          redirectOption={CalendarCallbackRedirect.CustomProgram}
          handleCloseCalendarModal={
            handleCloseCalendarModal
          }></ConnectCalendarModalContainer>
      </Container>
      {showCohortsModal && (
        <CohortsModalContainer onClick={handleCloseCohortModal}>
          <ModalContentContainer>
            <ModalTitle>
              {translate('COHORT__PREVIEW_AVAILABLE_MODAL_TITLE')}
            </ModalTitle>
            <AvailableCohortCardsContainer>
              {upcomingCohorts?.map((cohort) => {
                return (
                  <CardContainer key={cohort.id}>
                    <AvailableCohortCard
                      headerImgSrc={getPlaceholderImgSrc(cohort.id)}
                      title={cohort.cohortTitle}
                      startDate={formatDate(cohort.startDateUtc)}
                      endDate={formatDate(cohort.endDateUtc)}
                      onClickPreview={() => {
                        onChangeUpcomingCohortSelect(cohort.id);
                        handleCloseCohortModal();
                      }}
                      participantInitials={cohort.participantPreview?.map(
                        (p) => p.initials
                      )}
                      additionalParticipantsCount={Math.max(
                        cohort.participantCount - 3,
                        0
                      )}
                    />
                  </CardContainer>
                );
              })}
            </AvailableCohortCardsContainer>
          </ModalContentContainer>
        </CohortsModalContainer>
      )}
    </>
  );
}

export default CustomProgramUserDrawerHeader;
