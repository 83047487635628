import {BusinessGoalType} from './../enums';
import {BusinessGoalVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: BusinessGoalVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getBusinessGoalVMMockData(
  args?: Partial<BusinessGoalVM>
): BusinessGoalVM {
  return {
    featuredOnCard: faker.datatype.boolean(),
    newRole: faker.name.jobTitle(),
    retentionValueMultiplier: faker.datatype.number(),
    topic: faker.word.noun(),
    type: BusinessGoalType.Retain,
    updatedOn: String(faker.date.past()),
    value: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const BusinessGoalVMMockData: BusinessGoalVM = {
  featuredOnCard: true,
  newRole: 'Assistant to the Regional Manager',
  retentionValueMultiplier: 123,
  topic: 'string',
  type: BusinessGoalType.Retain,
  updatedOn: '2023-03-29T22:17:19.738072',
  value: 123,
};
