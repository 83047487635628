import {i18n, k} from '@i18n/translate';
import {localizeDuration, localizeGeneric} from './l10nUtils';

interface ProgramMetadata {
  programType: string;
  programLength: string;
  licenseId?: number | boolean;
  actualLearningTime?: string;
}

export const buildMetadataArray = (
  item: ProgramMetadata,
  isDurationStringsOn?: boolean
) => {
  const itemProgramLength = `${localizeDuration(item.programLength, isDurationStringsOn)}${
    item.actualLearningTime ? ` • ${item.actualLearningTime}` : ''
  }`;
  const itemLicenseAvailable = item.licenseId
    ? i18n.t(k.LICENSE__AVAILABLE)
    : '';
  return [
    localizeGeneric(item.programType),
    itemProgramLength,
    itemLicenseAvailable,
  ];
};
