export enum LxpPaths {
  Dashboard = '/me/dashboard',
  Marketplace = '/learning/search?term=&orgId=-2&isMarketplaceCat=true',
  Production = 'https://degreed.com',
  StagingAcm = 'https://staging.degreed.dev',
  StagingLxp = 'https://staging.degreed.com',
}

// Domains for Lxp Blob storage
export enum LxpBlobDomains {
  Staging = 'https://lxp.staging.degreedcdn.com',
  USBeta = 'https://lxp.usbeta.degreedcdn.com',
  EUBeta = 'https://lxp.eubeta.degreedcdn.com',
  USProd = 'https://prod.degreedcdn.com',
  EUProd = 'https://eu.degreedcdn.com',
}
