import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled, {css} from 'styled-components';
import ContextMenuButton from '@blocks/ContextMenu';
import {MenuOutlined} from '@ant-design/icons';
import {useDragAndDrop} from '@hooks/useDragAndDrop';
import {COLORS} from '@utils/constants';
import {DATE_FORMAT, parseToLocalMoment} from '@utils/timeUtils';
import {ContentType} from '@generated/enums';
import {
  LabelAndIconContainer,
  LabelSubContainer,
} from './CurriculumScreen.container';
import {midnight} from '@components/admin/pages/programs/customPrograms/curriculum/UpsertCustomContentModal.container';
import ContentTypeIcon from '@blocks/ContentTypeIcon';
import {useGetLabelsByContentType} from '@hooks/useGetLabelsByContentType';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.article<{
  isDraggingContentHigher: boolean;
  isDraggingContentLower: boolean;
  isOver: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  text-align: left;
  .draggable-indicator {
    display: none;
  }
  &:hover {
    .draggable-indicator {
      display: inline-block;
    }
    .order-number-display {
      display: none;
    }
  }
  ${({isOver, isDraggingContentHigher, isDraggingContentLower}) => {
    if (isOver) {
      if (isDraggingContentHigher) {
        return css`
          border-top: 4px solid ${COLORS.Blue200};
        `;
      }
      if (isDraggingContentLower) {
        return css`
          border-bottom: 4px solid ${COLORS.Blue200};
        `;
      }
    }
  }}
`;

const IndexRule = styled.div`
  border-left: 1px solid ${COLORS.Neutral200};
  width: 1px;
  height: 50%;
  position: absolute;
  left: 15px;
  z-index: 10;
`;

const IndexRuleTop = styled(IndexRule)`
  top: 0;
`;

const IndexRuleBottom = styled(IndexRule)`
  top: 50%;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 1rem;
  color: #1b1c23;
`;

const INDEX_SIZE = 32;
const Index = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.Neutral200};
  border-radius: 100%;
  color: #747578;
  font-weight: 500;
  font-size: 1rem;
  margin-right: 16px;
  width: ${INDEX_SIZE}px;
  height: ${INDEX_SIZE}px;
  background: white;
  z-index: 20;
  padding: 12px;
  cursor: grab;
`;

const CardContainer = styled.button<{
  first: boolean;
  last: boolean;
  isDragging: boolean;
  isContentReorderLoading: boolean;
}>`
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  cursor: grab;
  transition: 0.3s opacity;
  text-align: left;
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: -2px;
  }
  &:hover {
    background: ${COLORS.Neutral100};
  }
  ${({first, last}) => {
    if (first && last) {
      return css`
        border-radius: 8px 8px 8px 8px;
        border: 1px solid ${COLORS.Neutral200};
      `;
    } else if (first) {
      return css`
        border-radius: 8px 8px 0px 0px;
        border: 1px solid ${COLORS.Neutral200};
      `;
    } else if (last) {
      return css`
        border-bottom: 1px solid ${COLORS.Neutral200};
        border-left: 1px solid ${COLORS.Neutral200};
        border-right: 1px solid ${COLORS.Neutral200};
        box-sizing: border-box;
        border-radius: 0px 0px 8px 8px;
      `;
    } else {
      return css`
        border-left: 1px solid ${COLORS.Neutral200};
        border-right: 1px solid ${COLORS.Neutral200};
        border-bottom: 1px solid ${COLORS.Neutral200};
      `;
    }
  }}
  ${({isDragging, isContentReorderLoading}) => {
    if (isDragging || isContentReorderLoading) {
      return css`
        opacity: 0.35;
      `;
    }
  }}
`;

const ItemDate = styled.div`
  color: ${COLORS.Neutral900};
  font-weight: 400;
  font-size: 0.75rem;
`;

/*
|--------------------------------------------------------------------------
| Card Component
|--------------------------------------------------------------------------
*/

interface IProgramCurriculumCardProps {
  id: number;
  idx: number;
  cardCount: number;
  title: string;
  order: number;
  contentType: ContentType;
  onClickEdit: (e: React.MouseEvent) => void;
  onClickDuplicate: (e: React.MouseEvent) => void;
  onClickAddContentAbove?: (e: React.MouseEvent) => void;
  onClickAddContentBelow?: (e: React.MouseEvent) => void;
  onClickMoveToTop: (e: React.MouseEvent) => void;
  onClickMoveToBottom: (e: React.MouseEvent) => void;
  onClickDelete: (e: React.MouseEvent) => void;
  onClickMoveToSection: (e: React.MouseEvent) => void;
  setContentReorderIdx: () => void;
  reorderContentItem: () => void;
  isDraggingContentOrder: number | null;
  setIsDraggingContentOrder: () => void;
  isContentReorderLoading: boolean;
  onClick: () => void;
  dueDateUtc: string | undefined;
  eventStartDateTimeUtc?: string;
  timeZoneAbbreviation?: string;
}

function ProgramCurriculumCard({
  cardCount = 1,
  id,
  idx = 0,
  isContentReorderLoading,
  isDraggingContentOrder,
  contentType,
  onClick,
  onClickDelete,
  onClickDuplicate,
  onClickEdit,
  onClickMoveToBottom,
  onClickMoveToSection,
  onClickMoveToTop,
  order,
  reorderContentItem,
  setContentReorderIdx,
  setIsDraggingContentOrder,
  title,
  dueDateUtc,
  eventStartDateTimeUtc,
  timeZoneAbbreviation,
}: IProgramCurriculumCardProps) {
  const programCurriculumCardId = `program-curriculum-card-${id}`;
  const first = idx <= 0;
  const last = idx >= cardCount - 1;
  const item = {id, order};
  const {drag, drop, isOver, isDragging} = useDragAndDrop({
    item,
    reorderItem: reorderContentItem,
    setReorderIdx: setContentReorderIdx,
    setIsDraggingOrder: setIsDraggingContentOrder,
  });
  const isDraggingContentHigher = isDraggingContentOrder > order;
  const isDraggingContentLower = isDraggingContentOrder < order;

  const {editLabel, deleteLabel, duplicateLabel} =
    useGetLabelsByContentType(contentType);

  const eventDate = !!eventStartDateTimeUtc
    ? parseToLocalMoment(eventStartDateTimeUtc).format(
        DATE_FORMAT.TIME_24_HR_PADDED
      ) === midnight
      ? parseToLocalMoment(eventStartDateTimeUtc).format(
          DATE_FORMAT.MONTH_LONG_DAY_YEAR
        )
      : parseToLocalMoment(eventStartDateTimeUtc).format(
          DATE_FORMAT.getMonthLongDayYearWithTime()
        )
    : null;

  return (
    <Container
      id={programCurriculumCardId}
      aria-label={`${i18n.t(k.PROGRAM__CUSTOM__CURRICULUM)}: ${title}`}
      isOver={isOver}
      onClick={onClick}
      role="button"
      tabindex="0"
      ref={(node: any) => drag(drop(node))}
      isDraggingContentHigher={isDraggingContentHigher}
      isDraggingContentLower={isDraggingContentLower}>
      {!first && cardCount > 1 && <IndexRuleTop />}
      {!last && cardCount > 1 && <IndexRuleBottom />}
      <Index>
        <span className="order-number-display">{idx + 1}</span>
        <span
          className="draggable-indicator"
          aria-label={i18n.t(k.CTA__DRAG_AND_DROP)}>
          <MenuOutlined aria-hidden="true" />
        </span>
      </Index>
      <CardContainer
        first={first}
        last={last}
        isOver={isOver}
        isContentReorderLoading={isContentReorderLoading}
        isDragging={isDragging}
        isDraggingContentHigher={isDraggingContentHigher}
        isDraggingContentLower={isDraggingContentLower}>
        <LabelAndIconContainer>
          <ContentTypeIcon contentType={contentType} />

          <LabelSubContainer>
            <Title>{title}</Title>
            {!!eventStartDateTimeUtc && (
              <ItemDate>
                {!!dueDateUtc && (
                  <span style={{marginRight: 4}}>{i18n.t(k.DATE__DUE)}:</span>
                )}
                {`${eventDate} ${timeZoneAbbreviation}`}
              </ItemDate>
            )}
          </LabelSubContainer>
        </LabelAndIconContainer>
        <div>
          <ContextMenuButton
            popupContainerId={programCurriculumCardId}
            itemId={id}
            overlayPlacement="bottomRight"
            menuItems={[
              {
                label: editLabel,
                noBottomBorder: true,
                onClick: onClickEdit,
              },
              {
                label: duplicateLabel,
                onClick: onClickDuplicate,
              },
              {
                label: i18n.t(k.CTA__MOVE_TO_TOP),
                noBottomBorder: true,
                onClick: onClickMoveToTop,
              },
              {
                label: i18n.t(k.CTA__MOVE_TO_BOTTOM),
                noBottomBorder: true,
                onClick: onClickMoveToBottom,
              },
              {
                label: i18n.t(k.SECTION__MOVE_TO),
                onClick: onClickMoveToSection,
              },
              {
                label: (
                  <span style={{color: COLORS.Red800}}>{deleteLabel}</span>
                ),
                onClick: onClickDelete,
              },
            ]}
          />
        </div>
      </CardContainer>
    </Container>
  );
}

export default ProgramCurriculumCard;
