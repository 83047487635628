import {CurrencyCode} from './../enums';
import {UserPlanProgramItemVM} from './../interfaces';
import {
  ProgramProgressPreviewVMMockData,
  getProgramProgressPreviewVMMockData,
} from './ProgramProgressPreviewVM.mock';
import {
  UserCustomProgramContentPreviewVMMockData,
  getUserCustomProgramContentPreviewVMMockData,
} from './UserCustomProgramContentPreviewVM.mock';
import {
  UserPlanItemVMMockData,
  getUserPlanItemVMMockData,
} from './UserPlanItemVM.mock';
import {faker} from '@faker-js/faker';

// Interface: UserPlanProgramItemVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanProgramItemVMMockData(
  args?: Partial<UserPlanProgramItemVM>
): UserPlanProgramItemVM {
  return {
    ...getUserPlanItemVMMockData(),
    applicationUrl: faker.internet.url(),
    approvalNotes: faker.word.noun(),
    approvalRequired: faker.datatype.boolean(),
    approvedAmount: faker.datatype.number(),
    cost: faker.datatype.number(),
    currency: CurrencyCode.USD,
    daysUntilExpiration: faker.datatype.number(), // optional
    declinedAmount: faker.datatype.number(),
    endDateUtc: String(faker.date.past()), // optional
    externalReimbursementApplicationUrl: faker.internet.url(), // optional
    externalUrl: faker.internet.url(),
    incompleteCustomProgramContent: [
      getUserCustomProgramContentPreviewVMMockData(),
    ],
    isLicenseProgram: faker.datatype.boolean(),
    lastCustomProgramNudgeSent: faker.word.noun(), // optional
    licenseCost: faker.datatype.number(), // optional
    licenseCurrency: CurrencyCode.USD, // optional
    licenseId: faker.datatype.number(), // optional
    marketplaceProgram: faker.datatype.boolean(),
    nextUpdateDue: String(faker.date.past()), // optional
    programId: faker.datatype.number(),
    progressPercentage: faker.datatype.number(),
    progressUpdates: [getProgramProgressPreviewVMMockData()],
    purchaseMade: faker.datatype.boolean(), // optional
    reimbursementApprovedAmount: faker.datatype.number(), // optional
    reimbursementRequestedAmount: faker.datatype.number(), // optional
    requestedAmount: faker.datatype.number(),
    startDateUtc: String(faker.date.past()), // optional
    voucherCode: faker.word.noun(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanProgramItemVMMockData: UserPlanProgramItemVM = {
  ...UserPlanItemVMMockData,
  applicationUrl: 'https://this-person-does-not-exist.com/en',
  approvalNotes: 'string',
  approvalRequired: true,
  approvedAmount: 5,
  cost: 123,
  currency: CurrencyCode.USD,
  daysUntilExpiration: 123, // optional
  declinedAmount: 5,
  endDateUtc: '2023-03-29T22:17:19.738072', // optional
  externalReimbursementApplicationUrl:
    'https://this-person-does-not-exist.com/en', // optional
  externalUrl: 'https://this-person-does-not-exist.com/en',
  incompleteCustomProgramContent: [UserCustomProgramContentPreviewVMMockData],
  isLicenseProgram: true,
  lastCustomProgramNudgeSent: 'string', // optional
  licenseCost: 123, // optional
  licenseCurrency: CurrencyCode.USD, // optional
  licenseId: 1, // optional
  marketplaceProgram: true,
  nextUpdateDue: '2023-03-29T22:17:19.738072', // optional
  programId: 1,
  progressPercentage: 123,
  progressUpdates: [ProgramProgressPreviewVMMockData],
  purchaseMade: true, // optional
  reimbursementApprovedAmount: 5, // optional
  reimbursementRequestedAmount: 5, // optional
  requestedAmount: 5,
  startDateUtc: '2023-03-29T22:17:19.738072', // optional
  voucherCode: 'string', // optional
};
