import {computeFileChecksumMd5} from '@utils/fileUtils';
import {isLocal} from '@utils/environment-helpers';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import {AttachmentType} from '@generated/enums';
import {notify} from '@components/user/notifications';
import queryClient, {
  largeAttachmentQueryFn,
  multipleAttachmentProgressQueryFn,
} from '@store/queryClient';
import {getUploadAttachments} from '@store/apiEndpoints/academy/mutations';
import UrlConfig from '../../../UrlConfig';

export const getUploadChunkQueryKey = (contentId: number): string[] => {
  return ['attachmentUpload', String(contentId)];
};

export const upload = async (
  attachment: File,
  companyId: number,
  onComplete?: () => void,
  id?: number,
  index?: number
) => {
  const {WEB_URL, DIRECT_API_URL} = new UrlConfig();

  try {
    const totalChunks = Math.ceil(
      attachment.size / UploadConstraints.MinChunkSize
    );
    let uploadedCount = 0;
    let contentId = id;
    const queryKey = getUploadChunkQueryKey(contentId);
    queryClient.setQueryData(queryKey, 0);

    // Set initial download progress
    const queryRq = getUploadAttachments(contentId);

    const uploadChunk = async (chunkIdx: number, attachmentId?: number) => {
      try {
        // Base case, exit condition
        if (chunkIdx === totalChunks) {
          queryClient.setQueryData(queryKey, undefined);
          !!onComplete && onComplete();
          return notify.uploadContentAttachmentSuccess(attachment?.name);
        }

        // Get chunk
        const chunk = attachment.slice(
          chunkIdx * UploadConstraints.MinChunkSize,
          (chunkIdx + 1) * UploadConstraints.MinChunkSize
        );

        const chunkCheckSum = await computeFileChecksumMd5(chunk);

        // Upload chunk
        let uploadId = undefined;
        if (totalChunks === 1) {
          uploadId = await largeAttachmentQueryFn(queryRq.path, {
            file: chunk,
            id: contentId,
            chunkNumber: chunkIdx + 1, // 1-indexed, not 0-indexed
            chunkSize: chunk.size,
            totalChunks,
            totalSize: attachment.size,
            chunkCheckSum,
            fileName: attachment?.name,
            type: attachment?.type?.includes('video')
              ? AttachmentType.Video
              : AttachmentType.File,
            attachmentId,
          });
        } else {
          uploadId = await multipleAttachmentProgressQueryFn(
            queryRq.path,
            {
              file: chunk,
              chunkNumber: chunkIdx + 1, // 1-indexed, not 0-indexed
              chunkSize: chunk.size,
              totalChunks,
              totalSize: attachment.size,
              chunkCheckSum,
              fileName: attachment?.name,
              type: attachment?.type?.includes('video')
                ? AttachmentType.Video
                : AttachmentType.File,
              attachmentId,
            },
            index,
            () => {
              uploadedCount++;
            }
          );
        }

        // Update progress
        const progress = Number((chunkIdx + 1) / totalChunks);
        contentId = uploadId;
        queryClient.setQueryData(queryKey, progress);
        if (uploadedCount !== totalChunks && uploadedCount !== 0) {
          return uploadChunk(chunkIdx + 1, uploadId);
        } else {
          return uploadId;
        }
      } catch (e) {
        console.warn('error', e);
      }
    };

    await uploadChunk(0, undefined);

    // Remove trailing slash, if there's any
    const baseURL = isLocal
      ? DIRECT_API_URL.slice(0, -1)
      : WEB_URL.endsWith('/')
      ? WEB_URL.slice(0, -1)
      : WEB_URL;

    return `${baseURL}/${
      isLocal ? '' : 'api/'
    }attachments/${contentId}/view/${companyId}`;
  } catch (e) {
    console.warn('error', e);
  }
};
