import {NotificationSettings} from './../interfaces';
import {
  NotificationConfigMockData,
  getNotificationConfigMockData,
} from './NotificationConfig.mock';
import {faker} from '@faker-js/faker';

// Interface: NotificationSettings

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getNotificationSettingsMockData(
  args?: Partial<NotificationSettings>
): NotificationSettings {
  return {
    allowEmails: faker.datatype.boolean(),
    allowTexts: faker.datatype.boolean(),
    approvalRequested: getNotificationConfigMockData(),
    approvalRequestUpdated: getNotificationConfigMockData(),
    completeYourPlan: getNotificationConfigMockData(),
    contributorUpdate: getNotificationConfigMockData(),
    dueDateReminders: getNotificationConfigMockData(),
    eventReminders: getNotificationConfigMockData(),
    managerApprovalRequested: getNotificationConfigMockData(),
    newProjectFeedback: getNotificationConfigMockData(),
    newProjectSubmission: getNotificationConfigMockData(),
    programAccess: getNotificationConfigMockData(),
    programNomination: getNotificationConfigMockData(),
    programNudgeReminders: getNotificationConfigMockData(),
    programProgressUpdate: getNotificationConfigMockData(),
    weeklyFollowUp: getNotificationConfigMockData(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const NotificationSettingsMockData: NotificationSettings = {
  allowEmails: true,
  allowTexts: true,
  approvalRequested: NotificationConfigMockData,
  approvalRequestUpdated: NotificationConfigMockData,
  completeYourPlan: NotificationConfigMockData,
  contributorUpdate: NotificationConfigMockData,
  dueDateReminders: NotificationConfigMockData,
  eventReminders: NotificationConfigMockData,
  managerApprovalRequested: NotificationConfigMockData,
  newProjectFeedback: NotificationConfigMockData,
  newProjectSubmission: NotificationConfigMockData,
  programAccess: NotificationConfigMockData,
  programNomination: NotificationConfigMockData,
  programNudgeReminders: NotificationConfigMockData,
  programProgressUpdate: NotificationConfigMockData,
  weeklyFollowUp: NotificationConfigMockData,
};
