import './ManagerReports.scss';
import ManagerReportProgressList from './ManagerReportProgressList';
import {getProgressReportRq} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {ProgramProgressUpdateResult} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import {ReportAccessType} from '@generated/enums';
import useSetPageTitle from '@hooks/useSetPageTitle';

interface ManagerReportsWrapperProps {
  title?: string;
}

const ManagerReportsWrapper = ({title}: ManagerReportsWrapperProps) => {
  const progressReportRq = getProgressReportRq(ReportAccessType.Manager);
  const progressReportQuery = useQuery<ProgramProgressUpdateResult[]>(
    progressReportRq.queryKey,
    () => simpleQueryFn(progressReportRq.path),
    {
      placeholderData: () => {
        return [];
      },
    }
  );
  useSetPageTitle(title);
  return (
    <div>
      <ManagerReportProgressList
        data={progressReportQuery.data}
        loading={progressReportQuery.isLoading}
      />
    </div>
  );
};

export default ManagerReportsWrapper;
