export {default} from './AdminApprovalsList';
import {ApprovalStatus} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {AdminScreenApprovalStatus} from '@models/clientEnums';

export const APPROVAL_ROUTE = {
  PROGRAM: 'program',
  TIME: 'time',
  FINANCE: 'finance',
  RESOURCE: 'resource',
};

export const COLUMN_KEY_TO_SORTABLE = {
  amount: 'RequestedAmount',
  managerName: 'Manager',
  requestedByName: 'Name',
  requestedDate: 'RequestedDate',
  programTitle: 'ProgramName',
  resourceType: 'ResourceType',
  userRequestType: 'Type',
};

function makeEmptyStateProps(headerKey: string, subHeaderKey: string) {
  return {
    header: i18n.t(headerKey),
    subHeader: i18n.t(subHeaderKey),
  };
}

export function getEmptyStateProps(status: ApprovalStatus, isSearch?: boolean) {
  const EMPTY_STATE_BY_STATUS = {
    [ApprovalStatus.Approved]: makeEmptyStateProps(
      k.APPROVAL__APPROVED__TITLE,
      k.APPROVAL__APPROVED__DESCRIPTION
    ),
    [ApprovalStatus.Rejected]: makeEmptyStateProps(
      k.APPROVAL__REJECTED__TITLE,
      k.APPROVAL__REJECTED__DESCRIPTION
    ),
    [ApprovalStatus.Requested]: makeEmptyStateProps(
      k.APPROVAL__PENDING__TITLE,
      k.APPROVAL__PENDING__DESCRIPTION
    ),
    [ApprovalStatus.Pending]: makeEmptyStateProps(
      k.APPROVAL__PENDING__TITLE,
      k.APPROVAL__PENDING__DESCRIPTION
    ),
  };
  
  if (isSearch) {
    return makeEmptyStateProps(
      k.GENERIC__EMPTY_RESULTS__TITLE,
      k.GENERIC__EMPTY_RESULTS__DESCRIPTION
    );
  }

  return EMPTY_STATE_BY_STATUS[status];
}

export const STATUS_TAB = {
  REQUESTS: 'requests',
  APPROVED: 'approved',
  DECLINED: 'declined',
  PENDING_REVIEW: 'pending-review',
};

export const approvalStatusByTab = {
  [STATUS_TAB.REQUESTS]: ApprovalStatus.Requested,
  [STATUS_TAB.APPROVED]: ApprovalStatus.Approved,
  [STATUS_TAB.DECLINED]: ApprovalStatus.Rejected,
  [STATUS_TAB.PENDING_REVIEW]: ApprovalStatus.Pending,
};

export const statusQueryParamByTab = {
  [STATUS_TAB.REQUESTS]: ApprovalStatus.Requested,
  [STATUS_TAB.APPROVED]: ApprovalStatus.Approved,
  [STATUS_TAB.DECLINED]: ApprovalStatus.Rejected,
  [STATUS_TAB.PENDING_REVIEW]: ApprovalStatus.Requested,
};

export const adminScreenApprovalStatusByTab = {
  [STATUS_TAB.REQUESTS]: AdminScreenApprovalStatus.Requested,
  [STATUS_TAB.APPROVED]: AdminScreenApprovalStatus.Approved,
  [STATUS_TAB.DECLINED]: AdminScreenApprovalStatus.Rejected,
  [STATUS_TAB.PENDING_REVIEW]: AdminScreenApprovalStatus.PendingManagerApproval,
};
