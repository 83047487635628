import Grid from '@blocks/Grid';
import {AcademyStepVM} from '@generated/interfaces';
import React from 'react';
import {
  AllowSkipCheckbox,
  Description,
  EventDate,
  EventLink,
  Title,
} from './Form';
import Attachments from './Attachments';
import {DATE_FORMAT, formatUtcTime} from '@utils/timeUtils';
import moment from 'moment';
import {FormProps} from './AddEditContent.config';

const EventForm: React.FC<FormProps> = ({
  step,
  showRichTextUploads,
  stepRequirementComponent,
  canShowAllowSkip,
  attachmentProps,
}) => {
  const initialDate = step?.startDateUtc
    ? formatUtcTime(step?.startDateUtc, step?.eventTimeZone)
    : undefined;
  const startTime = step?.startDateUtc
    ? initialDate?.format(DATE_FORMAT.TIME_24_HR_PADDED)
    : undefined;

  const endTime =
    step?.endDateUtc &&
    formatUtcTime(step.endDateUtc, step?.eventTimeZone)?.format(
      DATE_FORMAT.TIME_24_HR_PADDED
    );

  return (
    <Grid.Container>
      <Grid.Box>
        <Title initialValue={step?.title} />
      </Grid.Box>

      <EventDate
        initialTimeZoneId={step?.eventTimeZone}
        initialDate={initialDate}
        initialStartTime={
          startTime
            ? startTime === '00:00' && !endTime
              ? undefined
              : moment(startTime, DATE_FORMAT.TIME_24_HR_PADDED)
            : undefined
        }
        initialEndTime={
          endTime ? moment(endTime, DATE_FORMAT.TIME_24_HR_PADDED) : undefined
        }
      />

      <Grid.Box>
        <EventLink initialValue={step?.eventLink} />
      </Grid.Box>

      <Grid.Box>
        <Description
          initialValue={step?.description}
          richTextUploads={showRichTextUploads}
        />
      </Grid.Box>

      {/* 
        TODO: modularize and declare statically */}
      {stepRequirementComponent}

      {canShowAllowSkip && (
        <Grid.Box full style={{marginBottom: 12}}>
          <AllowSkipCheckbox initialValue={step?.allowSkip} />
        </Grid.Box>
      )}

      <Attachments {...attachmentProps} />
    </Grid.Container>
  );
};

export default EventForm;
