import CustomProgramAdminDrawer from './CustomProgramAdminDrawer';
import {CustomProgramAdminDetailVM} from '@models/serverModels';
import {CustomProgramScreens} from '@utils/ClientPaths';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramAdminDrawerContainerProps {
  customProgramId: number;
  onClose: () => void;
  visible: boolean;
  initialScreen?: CustomProgramScreens;
  setCustomProgramForEdit?: (program: CustomProgramAdminDetailVM) => void;
  isOverlay?: boolean;
  refreshParentData?: () => void;
}

function CustomProgramAdminDrawerContainer({
  customProgramId,
  onClose,
  visible,
  initialScreen = CustomProgramScreens.Setup,
  setCustomProgramForEdit,
  isOverlay,
  refreshParentData,
}: CustomProgramAdminDrawerContainerProps) {
  return (
    <>
      <CustomProgramAdminDrawer
        customProgramId={customProgramId}
        onClose={onClose}
        visible={visible}
        initialScreen={initialScreen}
        setCustomProgramForEdit={setCustomProgramForEdit}
        isOverlay={isOverlay}
        refreshParentData={refreshParentData}
      />
    </>
  );
}

export default CustomProgramAdminDrawerContainer;
