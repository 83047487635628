import {useLocation} from 'react-router-dom';
import {isShowingRichTextEditor} from './isShowingRichTextEditor';

function useShouldShowRichTextEditor() {
  const {pathname} = useLocation();

  const showRichTextUploads = isShowingRichTextEditor({
    pathname,
  });

  return showRichTextUploads;
}

export default useShouldShowRichTextEditor;
