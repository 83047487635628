import useFeatureFlags from '@hooks/useFeatureFlags';
import {AcademyParticipantsTableContainerProps} from './AcademyParticipantsTable.interfaces';
import AcademyParticipantsTableView from './AcademyParticipantsTable.view';

function AcademyParticipantsTableContainer(
  props: AcademyParticipantsTableContainerProps
) {
  const {isFeatureFlagOn} = useFeatureFlags();
  return (
    <AcademyParticipantsTableView
      canShowEmployeeEmail={isFeatureFlagOn.ShowEmployeeEmail}
      {...props}
    />
  );
}

export default AcademyParticipantsTableContainer;
