import {PreApprovalQuestionResponseOptionVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: PreApprovalQuestionResponseOptionVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPreApprovalQuestionResponseOptionVMMockData(
  args?: Partial<PreApprovalQuestionResponseOptionVM>
): PreApprovalQuestionResponseOptionVM {
  return {
    id: String(faker.datatype.number()),
    responseValue: faker.word.noun(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PreApprovalQuestionResponseOptionVMMockData: PreApprovalQuestionResponseOptionVM =
  {
    id: '1',
    responseValue: 'string',
  };
