import {BrandingVM} from './../models/serverModels';
import {theme} from './color';
import {AcademyVM} from '@generated/interfaces';
import {useUpdateTheme} from '@utils/Theme';
import {useContext, useEffect} from 'react';
import {ThemeContext} from 'styled-components';
import {useAcademies} from '@components/user/pages/academy/academy.queries';

/** Warning. Be wary of this hook. It seems like there's an underlying issue here that's being masked. If called by useEffect, it infinitely loops. It can only currently be used if it is excluded from the useEffect's exhaustive-deps. */
export const useUpdateTheming = (updatedValue) => {
  const update = useUpdateTheme();
  const currentTheme: BrandingVM = useContext(ThemeContext);
  const updateAcademyTheme = () => update({...currentTheme, ...updatedValue});
  return {updateAcademyTheme};
};

export const useGetDefaultAcademy = () => {
  const defaultTheme = theme; // the default theme is the one hardcoded in color.ts, not the last active theme.
  const {data: academies} = useAcademies();

  const defaultAcademy = academies?.find((val) => val?.branding.isDefaultTheme);
  return defaultAcademy?.branding?.primaryBrandColor
    ? defaultAcademy?.branding
    : defaultTheme;
};

export const useGetDefaultAcademyTheme = (academy: AcademyVM) => {
  const defaultAcademy = useGetDefaultAcademy();
  const {updateAcademyTheme} = useUpdateTheming(
    academy?.branding?.primaryBrandColor ? academy?.branding : defaultAcademy
  );
  useEffect(() => {
    updateAcademyTheme();
    // Intentionally not including updateAcademyTheme in exhaustive-deps so
    // it doesn't infinitely loop. See hook for details.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [academy]);
};
