// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/RecommendationItemType.cs

enum RecommendationItemType {
  Program,
  Time,
  Finance,
  Initiative,
}

export default RecommendationItemType;
