import {basicSorter} from '@components/reusable/LearnInTable';
import {SectionNavViewProps} from './SectionNav.interfaces';
import {convertHexToHSL, getAcademyBrandingBorderColor} from '@utils/color';
import {SectionNavCardContainer} from '../SectionNavCard';
import {getBlockingTitle} from '@utils/requirementGating';
import translate from '@i18n/translate';
import {Container, Heading} from './SectionNav.styled';
import SectionNavBrandingDisplay from './SectionNavBrandingDisplay';
import {truncate} from 'lodash';

export const SectionNav = ({
  isLoading,
  sections,
  branding,
  levelId,
  showBrandingScreenDisplay,
  onClickSectionNavCard,
  top,
  isActivePlanItem,
}: SectionNavViewProps) => {
  return (
    <Container branding={branding} top={top} isPreview={!isActivePlanItem}>
      <Heading itemDefaultTextNavColor={branding?.itemDefaultTextNavColor}>
        {translate('SECTION__PLURAL')}
      </Heading>
      {isLoading ? (
        <>
          <SectionNavCardContainer isSkeleton={true} />
          <SectionNavCardContainer isSkeleton={true} />
          <SectionNavCardContainer isSkeleton={true} />
        </>
      ) : showBrandingScreenDisplay ? (
        <SectionNavBrandingDisplay branding={branding} />
      ) : (
        sections?.sort(basicSorter('order')).map((section) => {
          const isSelected = levelId === section.id;
          const blockingTitle = getBlockingTitle(section?.requiredId, sections);
          const borderColor = getAcademyBrandingBorderColor(
            branding?.itemDefaultTextNavColor
          );
          return (
            <SectionNavCardContainer
              onClick={() => onClickSectionNavCard(section.id)}
              key={section.id}
              title={truncate(section.title, {length: 30})}
              textColor={
                isSelected
                  ? branding?.itemSelectedTextNavColor
                  : branding?.itemDefaultTextNavColor
              }
              backgroundColor={
                isSelected
                  ? branding?.itemSelectedBackgroundNavColor
                  : convertHexToHSL(branding?.itemDefaultTextNavColor, 0.1)
              }
              borderColor={
                isSelected
                  ? branding?.itemSelectedBackgroundNavColor
                  : borderColor
              }
              hoverBorderColor={branding?.itemSelectedBackgroundNavColor}
              progressColor={branding?.secondaryBrandColor}
              showPercentComplete={!blockingTitle}
              percentComplete={section.status.completedPercent}
              showLock={!!blockingTitle}
              blockingTitle={blockingTitle}
            />
          );
        })
      )}
    </Container>
  );
};
