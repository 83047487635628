import {FinanceType} from '@generated/enums';
import {UserPlanReimbursementItemVM} from '@generated/interfaces';
import {NavigateFunction} from 'react-router-dom';

export interface ActionButtonsViewProps {
  ctaButtonLabel?: string;
  ctaButtonMode?: string;
  financeIncentiveId?: number;
  financeType?: FinanceType;
  isLearnInReimbursementsOn?: boolean;
  onCtaButtonClick?: () => void;
  preapprovedPlanItems?: UserPlanReimbursementItemVM[];
  push?: NavigateFunction;
  setShowReimbursement?: (show) => void;
  showCtaButton?: boolean;
  showExploreProgramButton?: boolean;
}

export interface ActionButtonsContainerProps {
  financeIncentiveId: number;
  financeType: FinanceType;
  navigate: NavigateFunction;
  setShowReimbursement: (show) => void;
  showActiveCard: boolean;
  showExploreProgramButton: boolean;
  userPlanItemId: number;
}

export const enum ActionButtonMode {
  ActiveCard = 'active-card',
  LxpMarketplace = 'lxp-marketplace',
  Default = 'default',
}
