import {
  AcademyStepType,
  CurrencyCode,
  DisplayStatus,
  DisplayStatusWarningLevel,
} from '@generated/enums';

export function useGetMockAcademyStep(args?: any) {
  return {
    academyLevelId: 1,
    allowPeerVisibility: true,
    attachments: [],
    cohortDisplayed: null,
    requiredId: 1,
    completedPercent: 1,
    completionDate: 'Lorem Ipsum',
    cost: 1,
    currency: CurrencyCode.USD,
    description: 'Lorem Ipsum',
    dueDate: 'Lorem Ipsum',
    dueDateTimeZone: 'Lorem Ipsum',
    dueDateUtc: 'Lorem Ipsum',
    dueTime: 'Lorem Ipsum',
    duration: 'Lorem Ipsum',
    endDateUtc: 'Lorem Ipsum',
    eventLink: 'Lorem Ipsum',
    eventTimeZone: 'Lorem Ipsum',
    id: 1,
    isEventConnectedToCalendar: true,
    isFutureCohort: true,
    isSubmissionTypeEditable: true,
    licenseId: 1,
    links: [],
    order: 1,
    peerSubmissionCount: 1,
    planItemId: 1,
    programId: 1,
    programType: 'Lorem Ipsum',
    projectContentCompletedOn: 'Lorem Ipsum',
    providerDescription: 'Lorem Ipsum',
    providerLogoUrl: 'Lorem Ipsum',
    providerName: 'Lorem Ipsum',
    rsvpStatus: 1,
    showToUser: true,
    smallImageUrl: 'Lorem Ipsum',
    startDateUtc: 'Lorem Ipsum',
    status: {
      displayStatus: DisplayStatus.Approved,
      displayStatusWarningLevel: DisplayStatusWarningLevel.Default,
      completedPercent: 35,
      isDone: false,
    },
    submissionType: null,
    submittedFiles: [],
    submittedLinks: [],
    submittedResponse: 'Lorem Ipsum',
    title: 'Lorem Ipsum',
    type: AcademyStepType.TextContent,
    userCompanyId: 1,
    ...args,
  };
}
