import {mutationHookFactory} from './../../utils';

import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramMessagesController.cs

/*
|--------------------------------------------------------------------------
|  {customProgramId}/sections/{sectionId}/contents/{contentId}/discussions/{discussionId}/messages/{messageId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation} from '@generated/hooks';
 *
 *  const customProgramsSectionsContentsDiscussionsMessagesDelete = useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramsSectionsContentsDiscussionsMessagesDelete.mutate({
 *    pathVars: {
 *      contentId,
 *      customProgramId,
 *      discussionId,
 *      messageId,
 *      sectionId
 *    }
 *  })
 *
 */

export const useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation =
  mutationHookFactory<
    null,
    {
      contentId: number;
      customProgramId: number;
      discussionId: string;
      messageId: string;
      sectionId: number;
    }, // path variables
    null
  >(
    'useCustomProgramsSectionsContentsDiscussionsMessagesDeleteMutation',
    'delete',
    ({customProgramId, sectionId, contentId, discussionId, messageId}) =>
      `custom-programs/${customProgramId}/sections/${sectionId}/contents/${contentId}/discussions/${discussionId}/messages/${messageId}`,
    {payloadSchema: undefined}
  );
