import {i18n, k} from '@i18n/translate';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {CopyToClipboard} from '@blocks/CopyToClipboard';
import {notify} from '@components/user/notifications';
import {CreditCardPaymentDetailsVM} from '@generated/interfaces';

const CardInfoWrapper = styled.div`
  padding-bottom: 24px;
`;

const StyledTable = styled.table`
  width: 100%;
  th {
    color: ${COLORS.Neutral950};
    font-size: 0.6875rem;
    font-weight: normal;
    line-height: 0.875rem;
    padding-top: 12px;
  }
  td {
    color: ${COLORS.Neutral950};
    font-size: 1rem;
  }
  address {
    margin: 0;
  }
`;

export interface ICardInfoModalProps {
  cardInfo?: CreditCardPaymentDetailsVM;
  isVisible: boolean;
  onClose: () => void;
}

export function CardInfoModal({
  cardInfo,
  isVisible,
  onClose,
}: ICardInfoModalProps) {
  if (!cardInfo) return null;
  const title = i18n.t(k.CARD__VIRTUAL_CARD);
  const separator = ', ';
  const cols = 3;
  const dataDogPrivacyMask = {'data-dd-privacy': 'mask'};
  const content = (
    <CardInfoWrapper>
      <StyledTable role="table" aria-label={title}>
        <tr role="row">
          <th scope="col" role="rowheader">
            {i18n.t(k.CARD__VIRTUAL_CARD)}
          </th>
          <th scope="col" role="rowheader">
            {i18n.t(k.CARD__EXP)}
          </th>
          <th scope="col" role="rowheader">
            {i18n.t(k.CARD__CVC)}
          </th>
        </tr>
        <tr>
          <td>
            <span {...dataDogPrivacyMask}>
              {cardInfo?.cardNumber.replace(/\d{4}(?=.)/g, '$& ')}{' '}
            </span>
            <CopyToClipboard
              ariaLabel={i18n.t(k.CARD__CLIPBOARD_CARD)}
              onTextCopied={() => notify.creditCardNumberCopied()}
              text={cardInfo?.cardNumber}
            />
          </td>
          <td {...dataDogPrivacyMask}>{cardInfo?.expiration}</td>
          <td {...dataDogPrivacyMask}>
            {cardInfo?.cvc}
            <CopyToClipboard
              ariaLabel={i18n.t(k.CARD__CLIPBOARD_CVC)}
              onTextCopied={() => notify.CVVCopied()}
              text={cardInfo?.cvc}
            />
          </td>
        </tr>
        <tr>
          <th
            scope="col"
            role="rowheader"
            colSpan={cols}
            {...dataDogPrivacyMask}>
            {i18n.t(k.CARD__CARDHOLDER)}
          </th>
        </tr>
        <tr>
          <td colSpan={cols} {...dataDogPrivacyMask}>
            {cardInfo?.cardholderName}
          </td>
        </tr>
        <tr>
          <th
            scope="col"
            role="rowheader"
            colSpan={cols}
            {...dataDogPrivacyMask}>
            {i18n.t(k.CARD__ADDRESS)}
          </th>
        </tr>
        <tr>
          <td colSpan={cols}>
            <address {...dataDogPrivacyMask}>
              {cardInfo.address ? cardInfo.address + separator : ''}
              <br />
              {cardInfo.city ? cardInfo.city + separator : ''}
              {cardInfo.state ? cardInfo.state + separator : ''}
              {cardInfo.postalCode}
            </address>
          </td>
        </tr>
      </StyledTable>
    </CardInfoWrapper>
  );

  return (
    <LearnInModal
      width={'400px'}
      visible={isVisible}
      tag={ModalTags.AddFundsToCreditCard}
      buttonDisabled={true}
      closable={true}
      maskClosable={true}
      onCancel={onClose}
      footer={null}
      title={title}
      content={content}
    />
  );
}

export default CardInfoModal;
