import {CreditCardAdminActionVM} from './../interfaces';
import {
  AdminActionDetailVMMockData,
  getAdminActionDetailVMMockData,
} from './AdminActionDetailVM.mock';
import {faker} from '@faker-js/faker';

// Interface: CreditCardAdminActionVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCreditCardAdminActionVMMockData(
  args?: Partial<CreditCardAdminActionVM>
): CreditCardAdminActionVM {
  return {
    details: [getAdminActionDetailVMMockData()],
    note: faker.word.noun(),
    timestamp: String(faker.datatype.number()),
    uniqueId: String(faker.datatype.number()),
    updatedBy: String(faker.date.past()),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CreditCardAdminActionVMMockData: CreditCardAdminActionVM = {
  details: [AdminActionDetailVMMockData],
  note: 'string',
  timestamp: '5',
  uniqueId: '1',
  updatedBy: '2023-03-29T22:17:19.738072',
};
