import {ReactElement, SyntheticEvent} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import Dragger, {DraggerProps} from 'antd/lib/upload/Dragger';
import {
  FileUploadType,
  generateFileUploadMediaTypes,
  validateUserUpload,
} from '@utils/uploadUtils';
import {AntdUploadFile} from '@utils/util.interface';
import {getDraggerProps} from '@utils/add-incentive-helpers';
import {UploadStyles} from './UploadTheme';
import {UploadConstraints, UploadTags} from './UploadEnum';
import {COLORS} from '@utils/constants';
import UploadInstructions from './UploadInstructions';

interface LearnInUploadProps extends DraggerProps {
  children: ReactElement | Array<ReactElement>; // dynamic children
  attachments: Array<AntdUploadFile>;
  fileList: Array<AntdUploadFile>;
  setFileList: React.Dispatch<React.SetStateAction<Array<AntdUploadFile>>>;
  setAttachments: React.Dispatch<React.SetStateAction<Array<AntdUploadFile>>>;
  fileName?: string;
  dragHeight?: string;
  tag: UploadTags;
  maxUploadSize?: number;
  acceptedFileTypes?: FileUploadType[];
}

/**
 * TODO
 * @param {children} - Customized inside of the upload
 * @returns
 */
export function LearnInUploadDragger({
  children,
  fileList,
  setFileList,
  attachments,
  setAttachments,
  fileName,
  maxUploadSize = UploadConstraints.MaxUserContentUploadSizeBytes,
  acceptedFileTypes = [
    FileUploadType.Images,
    FileUploadType.Pdf,
    FileUploadType.Videos,
  ],
  ...rest
}: LearnInUploadProps): ReactElement {
  const [draggerProps, setDraggerProps] = React.useState<unknown>();

  React.useEffect(() => {
    setDraggerProps(
      getDraggerProps(fileList, setFileList, setAttachments, acceptedFileTypes)
    );
  }, [attachments, fileList, setFileList, setAttachments, fileName]);

  return (
    <StyledDragger
      {...(draggerProps as DraggerProps)}
      listType="picture"
      beforeUpload={(file: any) =>
        validateUserUpload(
          file,
          fileList,
          maxUploadSize,
          'bytes',
          generateFileUploadMediaTypes(acceptedFileTypes)
        )
      }
      {...rest}>
      {children}
    </StyledDragger>
  );
}

const StyledDragger = styled(Dragger)`
  height: ${(props: any) =>
    props.dragHeight || UploadStyles[props.tag].dragHeight} !important;
`;

export const DraggerCopy = ({
  onClickCancel,
  fileTypes,
}: {
  fileTypes?: string;
  onClickCancel?: (e: SyntheticEvent) => void;
}) => {
  return (
    <>
      <UploadInstructions fileTypes={fileTypes} />
      {!!onClickCancel && (
        <p style={{color: COLORS.Red800}}>
          <button onClick={onClickCancel}>{i18n.t(k.CTA__CANCEL)}</button>
        </p>
      )}
    </>
  );
};
