import {StatusTag} from '@blocks/LearnInTag';
import LoadingSpinner from '@blocks/LoadingSpinner';
import NoDataImage from '@blocks/NoDataImage';
import NoDataText from '@blocks/NoDataText';
import {IProgressTableDataSourceRow} from '@components/admin/pages/programs/customPrograms/reports/ProgressTable';
import {LearnInProgressBar} from '@components/learnin';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {i18n, k} from '@i18n/translate';
import {COLORS, KEY_CODES} from '@utils/constants';
import {localizeDuration} from '@utils/l10nUtils';
import {pxToRem} from '@utils/styleMixins';
import {DATE_FORMAT} from '@utils/timeUtils';
import {ConfigProvider, Table} from 'antd';
import {GetRowKey, SortOrder} from 'antd/lib/table/interface';
import moment from 'moment';
import * as React from 'react';
import {
  CSSProperties,
  JSXElementConstructor,
  ReactNode,
  useLayoutEffect,
  useRef,
} from 'react';
import styled from 'styled-components';
import {IconWrapper} from './OptionForm';
import WithTooltip from './TooltipColumn/WithTooltip';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

// This function uppercases all column titles
function formatColumns(columns) {
  return (
    columns?.filter(Boolean).map((col) => {
      return {
        ...col,
        dataIndex: col.dataIndex || col.key,
      };
    }) || []
  );
}

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div<{hasContainer?: boolean}>`
  .ant-table-content {
    padding: 0 0;
  }
`;

export const TableContainer = styled.div<{
  hasContainer?: boolean;
  nonFullWidth?: boolean;
}>`
  .ant-pagination-prev:focus-visible,
  .ant-pagination-next:focus-visible,
  .ant-pagination-item:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }

  .ant-table-column-sorter {
    color: ${COLORS.Neutral600};
  }

  .ant-table-column-sorters {
    justify-content: flex-start !important;

    .ant-table-column-title {
      flex: initial !important;
      z-index: auto !important;
    }
  }

  // Right-aligned columns
  [style*='text-align: right'] {
    &.ant-table-cell {
      padding-right: 2rem !important;
    }
    .ant-table-column-sorters {
      justify-content: flex-end !important;
    }
  }

  //this is to remove the line between column headers (https://linear.app/learnin/issue/LI-3109/remove-vertical-lines-on-table-headers)
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  .ant-table-thead > tr th {
    font-size: 0.75rem;
  }

  .ant-table-container {
    ${({noData}) => noData && 'max-height: 208px;'}
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid ${COLORS.Neutral200} !important;
  }

  th.ant-table-cell,
  td.ant-table-cell {
    min-height: 48px;

    &:first-child {
      padding-left: 32px !important;
    }

    .ant-checkbox-input:focus-visible + .ant-checkbox-inner {
      outline: 2px solid ${COLORS.Blue950};
      outline-offset: 2px;
    }

    a:focus-visible {
      outline: 2px solid ${COLORS.Blue950};
      outline-offset: 8px;
    }

    button:focus-visible {
      outline: 2px solid ${COLORS.Blue950};
      outline-offset: 8px;
    }
  }

  .ant-table-content {
    .ant-table-content {
      padding: ${({hasContainer}) =>
        hasContainer ? '8px 16px 16px 16px' : '0px'};
    }
  }

  tr.ant-table-row,
  .ant-table-tbody > tr > td {
    padding: 0;
  }

  tr:focus-visible.ant-table-row > td,
  .ant-table-cell.ant-table-column-has-sorters:focus-visible {
    > .ant-table-column-sorters {
      outline: 2px solid ${COLORS.Blue950};
      outline-offset: 6px;
    }
    transition: none;
  }

  th.ant-table-cell {
    background: ${COLORS.White} !important;
  }

  td.ant-table-cell {
    padding: 8px 8px !important;
    background: ${COLORS.White};
    min-height: 48px;
  }

  .ant-table-wrapper {
    background: ${COLORS.White};
    padding: ${({hasContainer}) => (hasContainer ? null : '0px !important')};
  }

  .clickable-table-row {
    background: ${COLORS.Neutral100};
    cursor: pointer;
  }

  .ant-table-tbody > tr.non-clickable-table-row > td:hover {
    background: ${COLORS.White} !important;
  }

  .table-expanded-row:hover > td,
  tr.ant-table-expanded-row > td,
  tr.ant-table-expanded-row:hover > td {
    background: ${COLORS.White} !important;
  }

  .ant-table-tbody > tr.ant-table-row.non-clickable-table-row:hover > td {
    background: ${COLORS.White} !important;
  }

  ${({nonFullWidth}) =>
    !!nonFullWidth
      ? `
    border-radius: 4px 10px 10px 10px;
    border: 1px solid ${COLORS.Neutral200};
    `
      : null}

  ${({hasContainer}) =>
    !!hasContainer ? `box-shadow: ${COLORS.BoxShadowStandard};` : null}
`;

export const TableCell = styled.div`
  min-height: 48px;
  display: flex;
  align-items: center;
`;

const NestedTableContainer = styled.div<{
  showHeader?: boolean;
}>`
  margin-left: 1rem;
  background: none;
  position: relative;
  ${({showHeader}) =>
    !showHeader
      ? `.ant-table-thead {
    display: none;
  }`
      : null}
`;

NestedTableContainer.displayName = 'NestedTableContainer';

const NestedTableOrnaments = styled.div`
  position: absolute;
  left: -2px;
  top: 8px;
  z-index: 1;
`;

NestedTableOrnaments.displayName = 'NestedTableOrnaments';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
interface TablePaginationConfig {
  position: (
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
  )[];
  pageSize: number;
  showSizeChanger: boolean;
  hideOnSinglePage: boolean;
}

export const paginationProps: TablePaginationConfig = {
  position: ['bottomRight'],
  pageSize: 50,
  showSizeChanger: false,
  hideOnSinglePage: true,
};

export enum DefaultSortOrder {
  Ascend = 'ascend',
  Descend = 'descend',
}

type FixedDirection = boolean | string;

export enum ColumnAlignment {
  Center = 'center',
  Left = 'left',
  Right = 'right',
}

type SorterAction = (a: any, b: any) => number;
export interface IColumn<T = any> {
  align?: ColumnAlignment;
  title: string | ReactNode;
  dataIndex?: keyof T | string | Array<keyof T | string>;
  key: string;
  fixed?: FixedDirection;
  width?: number | string;
  render?: (val: any, row: T) => ReactNode;
  sorter?: SorterAction | boolean;
  defaultSortOrder?: DefaultSortOrder;
}

export interface Scroll {
  x?: number | true | string;
  y?: number | string;
  scrollToFirstRowOnChange?: boolean;
}
export interface ILearnInTable {
  dataSource: any[];
  columns: IColumn[];
  noDataHeader?: string;
  noDataSubheader?: string;
  noDataStyle?: CSSProperties;
  scroll?: Scroll;
  isLoading?: boolean;
  noSearchResults?: boolean;
  pagination?: boolean;
  nestedTable?: {
    columns: IColumn[];
    dataSource: Record<number, any[]>;
    onExpand?: (expanded: boolean, record: any) => void;
    onClickRow?: (record?: any, rowIndex?: number) => void;
    showHeader?: boolean;
    showRowHoverStyle?: (record?: unknown) => boolean;
    renderOrnaments?: (dataSource: unknown[]) => JSX.Element | JSX.Element[];
  };
  onClickRow?: (record?: any, rowIndex?: number) => void;
  showRowHoverStyle?: (record?: unknown) => boolean;
  dataPanelItems?: IDataPanelItem[];
  hasContainer?: boolean;
  nonFullWidth?: boolean;
  expandRowByClick?: boolean;
  customContent?: React.ReactElement | React.ReactElement[];
  ['data-testid']?: string;
  showHeader?: boolean;
  onChange?: any;
  totalPages?: number;
  pageSize?: number;
  currentPage?: number;
  rowKey?: string | GetRowKey<any>;
}

// Story @ stories/LearnInTable.stories.tsx
function LearnInTable({
  dataSource,
  columns,
  noDataHeader,
  noDataSubheader,
  noDataStyle,
  isLoading = false,
  noSearchResults = false,
  nestedTable,
  onClickRow,
  dataPanelItems,
  hasContainer,
  pagination,
  scroll,
  showRowHoverStyle,
  nonFullWidth = false,
  customContent,
  onChange,
  totalPages,
  pageSize,
  currentPage,
  rowKey = 'id',
  ...rest
}: ILearnInTable) {
  const localizations = {
    triggerDesc: i18n.t(k.CTA__SORT__DESC),
    triggerAsc: i18n.t(k.CTA__SORT__ASC),
    cancelSort: i18n.t(k.CTA__SORT__NONE),
  };

  const containerRef = useRef<HTMLDivElement>(null);

  // https://ant.design/components/table#components-table-demo-head
  // The second 'ascend' in the SortOrder[] is intentional: per AntD's API, this removes the unwanted "reset" direction.
  const sortDirections: SortOrder[] = ['ascend', 'descend', 'ascend'];

  useLayoutEffect(() => {
    document.querySelectorAll('th').forEach((header) => {
      header.setAttribute('tabindex', '0');
      header.setAttribute('role', 'button');
      const columnTitle = header.getAttribute('aria-label');
      const ariaLabel = i18n.t(k.A11Y__SORTABLE_TABLE_COLUMN__FORMAT, {
        column: columnTitle,
      });
      const ariaLabelIsApplied =
        columnTitle?.indexOf(
          i18n.t(k.A11Y__SORTABLE_TABLE_COLUMN__FORMAT, {
            column: '',
          })
        ) !== -1;
      // This useEffect can be hit multiple times. We only want this set once or else it causes duplicates passed into the i18n key SORTABLE_TABLE_COLUMN (i.e. "{{column}} is a sortable column is a sortable column is a sortable column" if the useEffect runs 3x).
      if (columnTitle && !ariaLabelIsApplied) {
        header.setAttribute('aria-label', ariaLabel);
      }
      // Remove aria-labels AntD erroneously adds to presentation elements
      header.querySelectorAll('[role="presentation"]').forEach((el) => {
        el.removeAttribute('aria-label');
      });
    });
    document.querySelectorAll('tr').forEach((row) => {
      row.addEventListener('keydown', (event: KeyboardEvent) => {
        // If a combobox is being interacted with, don't hijack the arrow keys
        const hasComboBoxRole =
          (event?.target as HTMLElement)?.getAttribute('role') === 'combobox';
        if (hasComboBoxRole) return;

        // Any other scenarios we want to bypass too should go here.

        // Otherwise, we'll allow using the arrow keys to navigate the table
        if (event.key === KEY_CODES.ARROW_UP) {
          event.preventDefault();
          const previous = row.closest('tr')
            .previousElementSibling as HTMLElement;
          previous?.focus();
        } else if (event.key === KEY_CODES.ARROW_DOWN) {
          event.preventDefault();
          const next = row.closest('tr').nextElementSibling as HTMLElement;
          next?.focus();
        }
      });
    });
    document
      .querySelectorAll('.ant-pagination-prev')
      .forEach((previousButton) => {
        previousButton.removeAttribute('aria-disabled');
        previousButton.setAttribute('tabindex', '0');
        previousButton.setAttribute('role', 'button');
        previousButton.setAttribute(
          'aria-label',
          i18n.t(k.GENERIC__PAGE__PREVIOUS)
        );
      });
    document.querySelectorAll('.ant-pagination-next').forEach((nextButton) => {
      nextButton.removeAttribute('aria-disabled');
      nextButton.setAttribute('tabindex', '0');
      nextButton.setAttribute('role', 'button');
      nextButton.setAttribute('aria-label', i18n.t(k.GENERIC__PAGE__NEXT));
    });
    document.querySelectorAll('.ant-pagination-item').forEach((button) => {
      button.setAttribute('tabindex', '0');
      button.setAttribute('role', 'button');
      const isActive = button.classList.contains('ant-pagination-item-active');
      const title = button.getAttribute('title');
      if (title) {
        button.setAttribute(
          'aria-label',
          `${i18n.t(k.A11Y__PAGE__FORMAT, {
            number: title,
          })} ${isActive ? i18n.t(k.GENERIC__PAGE__CURRENT) : ''}`
        );
      }
    });
  }, [dataSource]);
  const renderNestedTable = !nestedTable?.columns?.length
    ? null
    : (record: any) => {
        // Get the subset of nested table data for a specific row
        const rowData = nestedTable?.dataSource?.[record.id] || [];
        const filteredNestedColumns = formatColumns(nestedTable?.columns);

        return (
          <NestedTableContainer showHeader={nestedTable?.showHeader}>
            <NestedTableOrnaments>
              {nestedTable.renderOrnaments?.(rowData)}
            </NestedTableOrnaments>
            <Table
              columns={filteredNestedColumns}
              sortDirections={sortDirections}
              dataSource={rowData || []}
              rowKey={(row) => row?.id}
              pagination={false}
              locale={localizations}
              rowClassName={(row) => {
                if (!!nestedTable?.showRowHoverStyle) {
                  return showRowHoverStyle(row)
                    ? 'clickable-table-row'
                    : 'non-clickable-table-row';
                }
                if (!!nestedTable?.onClickRow) return 'clickable-table-row';
                return 'non-clickable-table-row';
              }}
              onRow={(record, rowIndex) => {
                return {
                  tabIndex: 0,
                  onClick: () => {
                    if (typeof nestedTable?.onClickRow === 'function') {
                      nestedTable?.onClickRow(record, rowIndex);
                    }
                  },
                };
              }}
            />
          </NestedTableContainer>
        );
      };
  const formattedColumns = formatColumns(columns);
  if (pageSize) {
    paginationProps.pageSize = pageSize;
  }
  return (
    <Container hasContainer={hasContainer}>
      <ConfigProvider
        renderEmpty={() => {
          if (noSearchResults) {
            return (
              <NoDataText
                style={noDataStyle}
                header={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
                subHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION)}
              />
            );
          } else if (isLoading) {
            return (
              <NoDataText
                style={noDataStyle}
                header={i18n.t(k.STATUS__LOADING)}
                subHeader={<LoadingSpinner />}
              />
            );
          } else {
            return noDataHeader || noDataSubheader ? (
              <NoDataText
                style={noDataStyle}
                header={noDataHeader || null}
                subHeader={noDataSubheader || null}
              />
            ) : (
              <NoDataImage />
            );
          }
        }}>
        {!!dataPanelItems?.length && (
          <div style={{marginBottom: '24px'}}>
            <DataPanel items={dataPanelItems} />
          </div>
        )}
        {/** element/s intended to be injected between the data panel and the table*/}
        {customContent}
        <TableContainer
          ref={containerRef}
          hasContainer={hasContainer}
          nonFullWidth={nonFullWidth}
          noData={!dataSource?.length}>
          <Table
            size="small"
            locale={localizations}
            pagination={
              pagination === false
                ? pagination
                : {
                    ...paginationProps,
                    current: currentPage,
                    total:
                      totalPages > 0
                        ? pageSize * totalPages
                        : dataSource?.length || 0,
                    onChange: () =>
                      containerRef.current?.scrollIntoView({
                        behavior: 'smooth',
                      }),
                  }
            }
            rowClassName={(row) => {
              if (!!showRowHoverStyle) {
                return showRowHoverStyle(row)
                  ? 'clickable-table-row'
                  : 'non-clickable-table-row';
              }
              if (!!onClickRow) return 'clickable-table-row';
              return 'non-clickable-table-row';
            }}
            columns={formattedColumns}
            sortDirections={sortDirections}
            scroll={{...scroll}}
            dataSource={dataSource?.filter(Boolean) || []}
            rowKey={rowKey}
            style={{padding: '10px', margin: '0px', borderRadius: '8px'}}
            bordered={false}
            tableLayout="auto"
            expandable={{
              expandedRowRender: renderNestedTable,
              onExpand: nestedTable?.onExpand || null,
              expandIcon: ({expanded, onExpand, record}) => {
                if (!!nestedTable) {
                  return (
                    <LearnInButton
                      aria-label={
                        expanded
                          ? i18n.t(k.A11Y__EXPANDED__FORMAT, {
                              item: record.title,
                            })
                          : i18n.t(k.A11Y__COLLAPSED__FORMAT, {
                              item: record.title,
                            })
                      }
                      chevron
                      color={COLORS.Neutral600}
                      isChevronOpen={expanded}
                      noPadding
                      onClick={(e) => {
                        e.stopPropagation();
                        onExpand(record, e);
                      }}
                      tag={ButtonTags.SecondaryRemovedBorder}
                    />
                  );
                }
              },
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: () => {
                  if (typeof onClickRow === 'function') {
                    onClickRow(record, rowIndex);
                  }
                },
              };
            }}
            onChange={onChange}
            {...rest}
          />
        </TableContainer>
      </ConfigProvider>
    </Container>
  );
}

export default LearnInTable;

/*
|--------------------------------------------------------------------------
| Sorting
|--------------------------------------------------------------------------
*/

export function basicSorter<T>(key: string) {
  return (a: T, b: T) => (a[key] || 0) - (b[key] || 0);
}

export function stringSorter<T>(key: string) {
  return (a: T, b: T) => (a[key] || '').localeCompare(b[key] || '');
}

export function numberSorter<T>(key: string) {
  return (a: T, b: T) => (a[key] ?? 0) - (b[key] ?? 0);
}

const durationTimeValue = {
  Hours: 1,
  Days: 24,
  Weeks: 24 * 7,
  Months: 24 * 30,
};

function getDurationValue(duration: string) {
  if (!duration) return 0;
  const [length, type] = duration.split(' ');
  return Number(length) * durationTimeValue[type];
}

export function durationSorter(key: string) {
  return (a: any, b: any) =>
    getDurationValue(a[key]) - getDurationValue(b[key]);
}

export function dateTimeSorter<ObjectType = any>(
  key: keyof ObjectType,
  parseFormat?: string
) {
  const _parseFormat = parseFormat || 'x';
  const getDateVal = (val) => {
    if (!!val) return val;
    return moment().subtract(-1000, 'years').format(_parseFormat); // return a date that will make this the last
  };
  return (a: ObjectType, b: ObjectType) => {
    // based on suggestion found in this post: https://stackoverflow.com/a/56624014
    return (
      moment(getDateVal(a[key])).unix() - moment(getDateVal(b[key])).unix()
    );
  };
}

/*
|--------------------------------------------------------------------------
| Cols & Renders
|--------------------------------------------------------------------------
*/

interface EmailAddressColProps {
  dataIndex?: string;
  key?: string;
  sortKey?: string;
  ['data-testId']?: string;
}

export function EmailAddressCol(props?: EmailAddressColProps) {
  return {
    title: i18n.t(k.GENERIC__EMAIL),
    dataIndex: props?.dataIndex || 'email',
    key: props?.key || props?.dataIndex || 'email',
    render: (email: string) => {
      return (
        <span
          data-testid={props?.['data-testId'] || null}
          style={{fontSize: pxToRem(16, 16), color: COLORS.Neutral800}}>
          {email}
        </span>
      );
    },
    sorter: stringSorter(props?.sortKey || props?.dataIndex || 'email'),
  };
}

/**
 * Displays an info icon ⓘ next to the main piece of data.
 *
 * Displays additional info on hover, which appears as a tooltip
 * above the info icon.
 *
 * Allows the possibility of restricting the width of the column,
 * causing the main data text to render an ellipsis when it exceeds
 * the available space.
 *
 */
export function TooltipCol(config: {
  title: string;
  dataIndex?: string;
  key?: string;
  wrapperStyle?: React.CSSProperties;
  width?: number;
  tooltipDataIndex?: string;
  disableSorting?: boolean;
  renderData?: (text: string) => string | number | React.ReactNode;
}) {
  return {
    title: config.title,
    dataIndex: config.dataIndex || 'name',
    key: config.key || config.dataIndex || 'name',
    sorter: !config.disableSorting
      ? stringSorter(config.key || config.dataIndex || 'name')
      : undefined,
    width: config.width,
    render: (text: string, rowData) => {
      const tooltipMessage = rowData[config.tooltipDataIndex];
      return (
        <WithTooltip title={tooltipMessage} width={config.width}>
          {config.renderData ? config.renderData(text) : text}
        </WithTooltip>
      );
    },
  };
}

export const DurationCol = (isDurationsStringsOn?: boolean) => {
  return {
    title: i18n.t(k.TIME__DURATION),
    dataIndex: 'duration',
    key: 'duration',
    render: (duration: any, {id}) => {
      return (
        <span
          className="admin-program-list-data"
          data-testid={`table-cell-duration-${id}`}>
          {localizeDuration(duration, isDurationsStringsOn)}
        </span>
      );
    },
    sorter: durationSorter('duration'),
  };
};

export const renderDateTime =
  (parseFormat?: string, displayFormat?: string) =>
  (dateTime: string | number) => {
    const parsedDateTime = !!parseFormat
      ? moment(dateTime, parseFormat)
      : moment(dateTime);
    return !parsedDateTime.isValid()
      ? null
      : parsedDateTime.format(displayFormat || DATE_FORMAT.MONTH_DAY_YEAR);
  };

export const renderProgressBar = (progressPercentage: number) => {
  return <LearnInProgressBar percent={progressPercentage} />;
};

//RSVP status

export const renderCompleteStatus = (
  completedOn: null | string,
  dataSource: IProgressTableDataSourceRow
) => {
  if (!dataSource.status) {
    return null;
  }
  return <StatusTag completedOn={completedOn} {...dataSource.status} />;
};

export const sortOptions = (options: any[], key = 'label') =>
  options?.sort((a, b) =>
    a?.[key]?.toString().localeCompare(b?.[key]).toString()
  );

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const TableColText = styled.div`
  font-size: 1rem;
  min-height: 48px;
  display: flex;
  align-items: center;
`;

export const TableColLink = styled.button`
  color: ${COLORS.Blue800};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  overflow: inherit;
  text-overflow: inherit;
  &:hover {
    text-decoration: underline;
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }
`;

/*
|--------------------------------------------------------------------------
| Data Panel
|--------------------------------------------------------------------------
*/

const DataPanelContainer = styled.div`
  background: ${COLORS.White};
  border-radius: 10px;
  box-shadow: ${COLORS.BoxShadowStandard};
  display: flex;
  width: 100%;

  & .ant-progress {
    min-width: 100px;
  }
`;

const Label = styled.div`
  color: ${COLORS.Neutral900};
  font-size: 0.75rem;
  font-weight: normal;
`;

const ItemContainer = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 16px;
  &:not(:last-child) {
    border-right: 1px solid ${COLORS.Neutral200};
  }
`;

export const DisplayText = styled.div`
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  font-weight: normal;
`;

export interface IDataPanelItem {
  label: string;
  display: React.ReactNode;
  icon?: {
    background: COLORS;
    color: COLORS;
    Icon: JSXElementConstructor<{style: CSSProperties; alt: string}>;
  };
}
export interface IDataPanelProps {
  items: IDataPanelItem[];
}

export function DataPanel({items}: IDataPanelProps) {
  return (
    <DataPanelContainer>
      {items?.map(({label, display, icon}) => {
        return (
          <ItemContainer key={label}>
            {!!icon && (
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '24px',
                }}>
                <IconWrapper background={icon.background}>
                  <icon.Icon
                    alt=""
                    style={{color: icon.color, fontSize: '1.75em'}}
                  />
                </IconWrapper>
              </div>
            )}
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
              }}>
              <div>
                <Label>{label}</Label>
                <div>
                  {['number', 'string'].includes(typeof display) ? (
                    <DisplayText>{display}</DisplayText>
                  ) : (
                    display
                  )}
                </div>
              </div>
            </div>
          </ItemContainer>
        );
      })}
    </DataPanelContainer>
  );
}
