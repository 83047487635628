// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/BalanceRecurType.cs

enum BalanceRecurType {
  NotApplicable,
  Rollover,
  Reset,
}

export default BalanceRecurType;
