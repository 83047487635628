import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {Divider, Space} from 'antd';
import {MessageOutlined, QuestionCircleOutlined} from '@ant-design/icons';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {validateEmail, validateUrl} from '@utils/itemUtils';
import {LinkButton} from '@blocks/userPlanCard/UserPlanCard';
import {ButtonStyles} from '@components/reusable/Button/ButtonTheme';
import {formatUrl} from '@utils/stringUtils';
import {SectionTitles} from './PlanItemDetailPage.container';
import {BodyParagraph, H3} from '../Typography';
import {OVERVIEW_MAX_WIDTH, OVERVIEW_SIDE_CARD_WIDTH} from './Tabs/OverviewTab';
/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  max-width: 100%;
  @media (min-width: ${OVERVIEW_MAX_WIDTH}px) {
    width: ${OVERVIEW_SIDE_CARD_WIDTH}px;
  }
  display: flex;
  flex-direction: column;
  gap: 16px;
  a {
    width: fit-content;
    padding: 10px 16px !important;
    gap: 8px;
  }
`;

const SectionTitle = styled(H3)`
  svg {
    background: ${COLORS.Neutral50};
    fill: ${COLORS.Red500};
    height: 16px;
    width: 16px;
  }
`;

const SectionDescription = styled(BodyParagraph)`
  margin-bottom: 0 !important;
`;

const SectionDivider = styled(Divider)`
  border-top: 1px solid ${COLORS.Neutral300};
`;
const SkillBadge = styled.div`
  font-size: 0.75rem;
  line-height: 18px;
  font-weight: 600;
  background-color: ${COLORS.Neutral100};
  padding: 3px 12px 3px 12px;
  width: max-content;
  border-radius: 4px;
  margin-bottom: 8px;
`;

const StyledLearnInContainer = styled(LearnInContainer)`
  max-width: ${OVERVIEW_SIDE_CARD_WIDTH}px;
  @media (max-width: ${OVERVIEW_MAX_WIDTH}px) {
    max-width: 100%;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IFaqAndContactProps {
  contactSections: {title: string; content: string}[];
  skills: string[];
  isNotFinanceIncentive?: boolean;
  paymentSection?: {title: string; content: string};
}

export const OverviewSidePanel = ({
  contactSections,
  skills,
  isNotFinanceIncentive,
  paymentSection,
}: IFaqAndContactProps) => {
  return (
    <Container>
      <StyledLearnInContainer
        flexDirection={'column'}
        tag={ContainerTags.CommonCard}>
        {paymentSection && (
          <>
            <SectionTitle fontWeight={500}>
              {i18n.t(k.GENERIC__PAYMENT_SCHEDULE)}
            </SectionTitle>
            <SectionDescription>{paymentSection.content}</SectionDescription>
            <SectionDivider />
          </>
        )}
        {isNotFinanceIncentive && (
          <>
            <SectionTitle fontWeight={500}>
              {i18n.t(k.PROGRAM_FINDER__SKILLS)}
            </SectionTitle>
            {skills?.map((skill, idx) => {
              return (
                <div key={idx}>
                  <SkillBadge>{skill}</SkillBadge>
                </div>
              );
            })}
            <SectionDivider />
          </>
        )}
        <Space direction="vertical">
          {contactSections?.map(({title, content}) => {
            if (
              title.toLowerCase() === SectionTitles.Contact &&
              validateUrl(content)
            )
              return (
                <LinkButton
                  style={{...ButtonStyles.secondary}}
                  target="_blank"
                  href={
                    validateEmail(content)
                      ? `mailto:${content}`
                      : formatUrl(content)
                  }
                  key={`contact-${title.replace(/\s/g, '')}`}
                  tag={ButtonTags.Secondary}>
                  <MessageOutlined aria-hidden="true" />
                  {i18n.t(k.PROVIDER__CONTACT__VERB)}
                </LinkButton>
              );

            if (
              title.toLowerCase() === SectionTitles.FAQs &&
              validateUrl(content) &&
              !validateEmail(content)
            )
              return (
                <LinkButton
                  onClick={(e) => e.stopPropagation()}
                  style={{...ButtonStyles.secondary}}
                  target="_blank"
                  href={formatUrl(content)}
                  tag={ButtonTags.Secondary}
                  key={`contact-btn-${title.replace(/\s/g, '')}`}>
                  <QuestionCircleOutlined aria-hidden="true" />
                  {i18n.t(k.GENERIC__FAQ__PLURAL)}
                </LinkButton>
              );
          })}
        </Space>
      </StyledLearnInContainer>
    </Container>
  );
};
