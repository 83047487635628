// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/UpdateApprovalRequest.cs

enum ApprovalType {
  PLAN_ITEM,
  TIME_USE,
  REIMBURSEMENT,
  RESOURCE,
}

export default ApprovalType;
