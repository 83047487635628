import {
  ContentFeedbackVM,
  CustomProgramAdminDetailVM,
  CustomProgramCohortPayload,
  CustomProgramCohortUpdateRequest,
  CustomProgramEmailMessagePayload,
  CustomProgramPayload,
  CustomProgramSectionAdminVM,
  CustomProgramSectionPayload,
  CustomProgramUserPermissionPayload,
  NewCustomProgramContentPayload,
  NewFeedbackPayload,
  UpdateContentSectionPayload,
  UpdateCustomProgramContentPayload,
  UpdateCustomProgramStatus,
  UpdateItemsOrderPayload,
  UserPermissionUpdatePayload,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {CustomProgramPayloadSchema} from '../zodSchemas/CustomProgramPayloadSchema.schema';
import {CustomProgramEmailMessagePayloadSchema} from '../zodSchemas/CustomProgramEmailMessagePayloadSchema.schema';
import {CustomProgramSectionPayloadSchema} from '../zodSchemas/CustomProgramSectionPayloadSchema.schema';
import {UpdateItemsOrderPayloadSchema} from '../zodSchemas/UpdateItemsOrderPayloadSchema.schema';
import {NewCustomProgramContentPayloadSchema} from '../zodSchemas/NewCustomProgramContentPayloadSchema.schema';
import {UpdateCustomProgramContentPayloadSchema} from '../zodSchemas/UpdateCustomProgramContentPayloadSchema.schema';
import {UpdateContentSectionPayloadSchema} from '../zodSchemas/UpdateContentSectionPayloadSchema.schema';
import {UpdateCustomProgramStatusSchema} from '../zodSchemas/UpdateCustomProgramStatusSchema.schema';
import {NewFeedbackPayloadSchema} from '../zodSchemas/NewFeedbackPayloadSchema.schema';
import {CustomProgramCohortUpdateRequestSchema} from '../zodSchemas/CustomProgramCohortUpdateRequestSchema.schema';
import {CustomProgramCohortPayloadSchema} from '../zodSchemas/CustomProgramCohortPayloadSchema.schema';
import {CustomProgramUserPermissionPayloadSchema} from '../zodSchemas/CustomProgramUserPermissionPayloadSchema.schema';
import {UserPermissionUpdatePayloadSchema} from '../zodSchemas/UserPermissionUpdatePayloadSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramController.cs

/*
|--------------------------------------------------------------------------
|  add-all-dates-to-my-calendar/{customProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddAllDatesToMyCalendarMutation} from '@generated/hooks';
 *
 *  const addAllDatesToMyCalendar = useAddAllDatesToMyCalendarMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addAllDatesToMyCalendar.mutate({
 *    pathVars: {
 *      customProgramId
 *    }
 *  })
 *
 */

export const useAddAllDatesToMyCalendarMutation = mutationHookFactory<
  null,
  {customProgramId: number | string}, // path variables
  number // return type
>(
  'useAddAllDatesToMyCalendarMutation',
  'post',
  ({customProgramId}) =>
    `custom-program/add-all-dates-to-my-calendar/${customProgramId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  add-cohort 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddCohortMutation} from '@generated/hooks';
 *
 *  const addCohort = useAddCohortMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addCohort.mutate({
 *    payload: {
 *      ...CustomProgramCohortPayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAddCohortMutation = mutationHookFactory<
  CustomProgramCohortPayload, // payload
  null,
  number // return type
>('useAddCohortMutation', 'post', () => `custom-program/add-cohort`, {
  payloadSchema: CustomProgramCohortPayloadSchema,
});

/*
|--------------------------------------------------------------------------
|  add-custom-program 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddCustomProgramMutation} from '@generated/hooks';
 *
 *  const addCustomProgram = useAddCustomProgramMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addCustomProgram.mutate({
 *    payload: {
 *      ...CustomProgramPayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAddCustomProgramMutation = mutationHookFactory<
  CustomProgramPayload, // payload
  null,
  CustomProgramAdminDetailVM // return type
>(
  'useAddCustomProgramMutation',
  'post',
  () => `custom-program/add-custom-program`,
  {payloadSchema: CustomProgramPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  add-custom-program-section 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddCustomProgramSectionMutation} from '@generated/hooks';
 *
 *  const addCustomProgramSection = useAddCustomProgramSectionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addCustomProgramSection.mutate({
 *    payload: {
 *      ...CustomProgramSectionPayload
 *    },
 *  })
 *
 */

export const useAddCustomProgramSectionMutation = mutationHookFactory<
  CustomProgramSectionPayload, // payload
  null,
  number // return type
>(
  'useAddCustomProgramSectionMutation',
  'post',
  () => `custom-program/add-custom-program-section`,
  {payloadSchema: CustomProgramSectionPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  add-custom-program-section-content 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddCustomProgramSectionContentMutation} from '@generated/hooks';
 *
 *  const addCustomProgramSectionContent = useAddCustomProgramSectionContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addCustomProgramSectionContent.mutate({
 *    payload: {
 *      ...NewCustomProgramContentPayload
 *    },
 *  })
 *
 */

export const useAddCustomProgramSectionContentMutation = mutationHookFactory<
  NewCustomProgramContentPayload, // payload
  null,
  number // return type
>(
  'useAddCustomProgramSectionContentMutation',
  'post',
  () => `custom-program/add-custom-program-section-content`,
  {payloadSchema: NewCustomProgramContentPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  add-date-to-my-calendar/{contentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramAddDateToMyCalendarMutation} from '@generated/hooks';
 *
 *  const customProgramAddDateToMyCalendar = useCustomProgramAddDateToMyCalendarMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramAddDateToMyCalendar.mutate({
 *    pathVars: {
 *      contentId
 *    }
 *  })
 *
 */

export const useCustomProgramAddDateToMyCalendarMutation = mutationHookFactory<
  null,
  {contentId: number | string}, // path variables
  number // return type
>(
  'useCustomProgramAddDateToMyCalendarMutation',
  'post',
  ({contentId}) => `custom-program/add-date-to-my-calendar/${contentId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  add-user-permissions 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramAddUserPermissionsMutation} from '@generated/hooks';
 *
 *  const customProgramAddUserPermissions = useCustomProgramAddUserPermissionsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramAddUserPermissions.mutate({
 *    payload: {
 *      ...CustomProgramUserPermissionPayload
 *    },
 *  })
 *
 */

export const useCustomProgramAddUserPermissionsMutation = mutationHookFactory<
  CustomProgramUserPermissionPayload, // payload
  null,
  null
>(
  'useCustomProgramAddUserPermissionsMutation',
  'post',
  () => `custom-program/add-user-permissions`,
  {payloadSchema: CustomProgramUserPermissionPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  submit-content-feedback 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramSubmitContentFeedbackMutation} from '@generated/hooks';
 *
 *  const customProgramSubmitContentFeedback = useCustomProgramSubmitContentFeedbackMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramSubmitContentFeedback.mutate({
 *    payload: {
 *      ...NewFeedbackPayload
 *    },
 *  })
 *
 */

export const useCustomProgramSubmitContentFeedbackMutation =
  mutationHookFactory<
    NewFeedbackPayload, // payload
    null,
    ContentFeedbackVM[] // return type
  >(
    'useCustomProgramSubmitContentFeedbackMutation',
    'post',
    () => `custom-program/submit-content-feedback`,
    {payloadSchema: NewFeedbackPayloadSchema}
  );

/*
|--------------------------------------------------------------------------
|  update-custom-program-content 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramUpdateCustomProgramContentMutation} from '@generated/hooks';
 *
 *  const customProgramUpdateCustomProgramContent = useCustomProgramUpdateCustomProgramContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramUpdateCustomProgramContent.mutate({
 *    payload: {
 *      ...UpdateCustomProgramContentPayload
 *    },
 *  })
 *
 */

export const useCustomProgramUpdateCustomProgramContentMutation =
  mutationHookFactory<
    UpdateCustomProgramContentPayload, // payload
    null,
    number // return type
  >(
    'useCustomProgramUpdateCustomProgramContentMutation',
    'post',
    () => `custom-program/update-custom-program-content`,
    {payloadSchema: UpdateCustomProgramContentPayloadSchema}
  );

/*
|--------------------------------------------------------------------------
|  update-user-permission 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramUpdateUserPermissionMutation} from '@generated/hooks';
 *
 *  const customProgramUpdateUserPermission = useCustomProgramUpdateUserPermissionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramUpdateUserPermission.mutate({
 *    payload: {
 *      ...UserPermissionUpdatePayload
 *    },
 *  })
 *
 */

export const useCustomProgramUpdateUserPermissionMutation = mutationHookFactory<
  UserPermissionUpdatePayload, // payload
  null,
  null
>(
  'useCustomProgramUpdateUserPermissionMutation',
  'post',
  () => `custom-program/update-user-permission`,
  {payloadSchema: UserPermissionUpdatePayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  upload-content-attachment/{contentId} 
|--------------------------------------------------------------------------
*/

/**
 *
 *  @obsolete Do Not Use
 *
 *  @example
 *
 *  import {useCustomProgramUploadContentAttachmentMutation} from '@generated/hooks';
 *
 *  const customProgramUploadContentAttachment = useCustomProgramUploadContentAttachmentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramUploadContentAttachment.mutate({
 *    pathVars: {
 *      contentId
 *    }
 *  })
 *
 *
 *  @obsolete Do Not Use
 */

export const useCustomProgramUploadContentAttachmentMutation =
  mutationHookFactory<
    null,
    {contentId: number}, // path variables
    number // return type
  >(
    'useCustomProgramUploadContentAttachmentMutation',
    'post',
    ({contentId}) => `custom-program/upload-content-attachment/${contentId}`,
    {payloadSchema: undefined}
  );

/*
|--------------------------------------------------------------------------
|  duplicate-custom-program/{customProgramId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDuplicateCustomProgramMutation} from '@generated/hooks';
 *
 *  const duplicateCustomProgram = useDuplicateCustomProgramMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  duplicateCustomProgram.mutate({
 *    pathVars: {
 *      customProgramId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useDuplicateCustomProgramMutation = mutationHookFactory<
  null,
  {customProgramId: number}, // path variables
  number // return type
>(
  'useDuplicateCustomProgramMutation',
  'post',
  ({customProgramId}) =>
    `custom-program/duplicate-custom-program/${customProgramId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  duplicate-custom-program-content/{contentId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDuplicateCustomProgramContentMutation} from '@generated/hooks';
 *
 *  const duplicateCustomProgramContent = useDuplicateCustomProgramContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  duplicateCustomProgramContent.mutate({
 *    pathVars: {
 *      contentId
 *    }
 *  })
 *
 */

export const useDuplicateCustomProgramContentMutation = mutationHookFactory<
  null,
  {contentId: number | string}, // path variables
  number // return type
>(
  'useDuplicateCustomProgramContentMutation',
  'post',
  ({contentId}) =>
    `custom-program/duplicate-custom-program-content/${contentId}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  re-order-custom-program-content 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useReOrderCustomProgramContentMutation} from '@generated/hooks';
 *
 *  const reOrderCustomProgramContent = useReOrderCustomProgramContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  reOrderCustomProgramContent.mutate({
 *    payload: {
 *      ...UpdateItemsOrderPayload
 *    },
 *  })
 *
 */

export const useReOrderCustomProgramContentMutation = mutationHookFactory<
  UpdateItemsOrderPayload, // payload
  null,
  null
>(
  'useReOrderCustomProgramContentMutation',
  'post',
  () => `custom-program/re-order-custom-program-content`,
  {payloadSchema: UpdateItemsOrderPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  re-order-custom-program-sections 
|--------------------------------------------------------------------------
*/

/**
 *
 *  @obsolete Use CustomProgramStepsController.PatchSteps instead
 *
 *  @example
 *
 *  import {useReOrderCustomProgramSectionsMutation} from '@generated/hooks';
 *
 *  const reOrderCustomProgramSections = useReOrderCustomProgramSectionsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  reOrderCustomProgramSections.mutate({
 *    payload: {
 *      ...UpdateItemsOrderPayload
 *    },
 *  })
 *
 *
 *  @obsolete Use CustomProgramStepsController.PatchSteps instead
 */

export const useReOrderCustomProgramSectionsMutation = mutationHookFactory<
  UpdateItemsOrderPayload, // payload
  null,
  CustomProgramSectionAdminVM[] // return type
>(
  'useReOrderCustomProgramSectionsMutation',
  'post',
  () => `custom-program/re-order-custom-program-sections`,
  {payloadSchema: UpdateItemsOrderPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-cohort 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateCohortMutation} from '@generated/hooks';
 *
 *  const updateCohort = useUpdateCohortMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateCohort.mutate({
 *    payload: {
 *      ...CustomProgramCohortUpdateRequest
 *    },
 *  })
 *
 */

export const useUpdateCohortMutation = mutationHookFactory<
  CustomProgramCohortUpdateRequest, // payload
  null,
  number // return type
>('useUpdateCohortMutation', 'post', () => `custom-program/update-cohort`, {
  payloadSchema: CustomProgramCohortUpdateRequestSchema,
});

/*
|--------------------------------------------------------------------------
|  update-content-section 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateContentSectionMutation} from '@generated/hooks';
 *
 *  const updateContentSection = useUpdateContentSectionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateContentSection.mutate({
 *    payload: {
 *      ...UpdateContentSectionPayload
 *    },
 *  })
 *
 */

export const useUpdateContentSectionMutation = mutationHookFactory<
  UpdateContentSectionPayload, // payload
  null,
  number // return type
>(
  'useUpdateContentSectionMutation',
  'post',
  () => `custom-program/update-content-section`,
  {payloadSchema: UpdateContentSectionPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-custom-program 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateCustomProgramMutation} from '@generated/hooks';
 *
 *  const updateCustomProgram = useUpdateCustomProgramMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateCustomProgram.mutate({
 *    payload: {
 *      ...CustomProgramPayload
 *    },
 *  })
 *
 */

export const useUpdateCustomProgramMutation = mutationHookFactory<
  CustomProgramPayload, // payload
  null,
  CustomProgramAdminDetailVM // return type
>(
  'useUpdateCustomProgramMutation',
  'post',
  () => `custom-program/update-custom-program`,
  {payloadSchema: CustomProgramPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-custom-program-email-message 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateCustomProgramEmailMessageMutation} from '@generated/hooks';
 *
 *  const updateCustomProgramEmailMessage = useUpdateCustomProgramEmailMessageMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateCustomProgramEmailMessage.mutate({
 *    payload: {
 *      ...CustomProgramEmailMessagePayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useUpdateCustomProgramEmailMessageMutation = mutationHookFactory<
  CustomProgramEmailMessagePayload, // payload
  null,
  number // return type
>(
  'useUpdateCustomProgramEmailMessageMutation',
  'post',
  () => `custom-program/update-custom-program-email-message`,
  {payloadSchema: CustomProgramEmailMessagePayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-custom-program-section 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateCustomProgramSectionMutation} from '@generated/hooks';
 *
 *  const updateCustomProgramSection = useUpdateCustomProgramSectionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateCustomProgramSection.mutate({
 *    payload: {
 *      ...CustomProgramSectionPayload
 *    },
 *  })
 *
 */

export const useUpdateCustomProgramSectionMutation = mutationHookFactory<
  CustomProgramSectionPayload, // payload
  null,
  number // return type
>(
  'useUpdateCustomProgramSectionMutation',
  'post',
  () => `custom-program/update-custom-program-section`,
  {payloadSchema: CustomProgramSectionPayloadSchema}
);

/*
|--------------------------------------------------------------------------
|  update-custom-program-status 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateCustomProgramStatusMutation} from '@generated/hooks';
 *
 *  const updateCustomProgramStatus = useUpdateCustomProgramStatusMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateCustomProgramStatus.mutate({
 *    payload: {
 *      ...UpdateCustomProgramStatus
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useUpdateCustomProgramStatusMutation = mutationHookFactory<
  UpdateCustomProgramStatus, // payload
  null,
  null
>(
  'useUpdateCustomProgramStatusMutation',
  'post',
  () => `custom-program/update-custom-program-status`,
  {payloadSchema: UpdateCustomProgramStatusSchema}
);
