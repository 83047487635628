import {FinanceType} from '@generated/enums';

export const mapFinanceTypeStringToFinanceType = (
  value: string
): FinanceType | null => {
  if (!value) {
    return null;
  }
  switch (value?.toLowerCase()) {
    case 'prepayment':
      return FinanceType.Prepayment;
    case 'tuitionreimbursement':
    case 'tuition reimbursement':
      return FinanceType.TuitionReimbursement;
    case 'external reimbursement':
      return FinanceType.ExternalReimbursement;
    case 'discount':
      return FinanceType.Discount;
    case 'loan':
      return FinanceType.Loan;
    case 'isa':
      return FinanceType.ISA;
    case 'completion bonus':
      return FinanceType.CompletionBonus;
    case 'other':
      return FinanceType.Other;
    case 'scholarship':
      return FinanceType.Scholarship;
    default:
      console.warn('Unrecognized FinanceType: ', value);
      return null;
  }
};
