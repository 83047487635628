import {useEffect, useState} from 'react';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import SkillsTagger from '@components/reusable/SkillsTagger';
import {i18n, k} from '@i18n/translate';
import {datadogLogs} from '@datadog/browser-logs';
import {useNavigate, useParams} from 'react-router-dom';
import styled from 'styled-components';
import {
  COLORS,
  HEADER_HEIGHT,
  SECTIONS_NAV_WIDTH,
  SIDE_NAV_WIDTH,
} from '@utils/constants';
import {PlusCircleOutlined} from '@ant-design/icons';
import {simpleMutationFn} from '@store/queryClient';
import {useMutation} from 'react-query';
import {
  getAddAcademyLevelRm,
  getUpdateAcademyLevelOrderRm,
  getUpdateAcademyLevelRm,
} from '@store/apiEndpoints/academy/mutations';
import {DndProvider as DragAndDropProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import LevelItem from './LevelItem.container';
import {
  useAcademyLevelVMsQuery,
  useAcademyStepVMsQuery,
} from '@hooks/apiEndpoints/academy/queries';
import {editAcademyUrl, userEditAcademyUrl} from '@utils/ClientPaths';
import usePathInfo from '@hooks/usePathInfo';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {getBlockingTitle} from '@utils/requirementGating';
import {
  AcademyLevelVM,
  AcademyLevelPayload,
  CreateAcademyLevelRequest,
} from '@generated/interfaces';
import {
  mapTextToEnglish,
  mapTextToUserLanguage,
} from '@utils/enumMapping/MapTextToUserLanguage';
import useShowSkillsTagging from '@hooks/useShowSkillsTagging';

const Container = styled.div`
  background: white;
  width: ${() => SECTIONS_NAV_WIDTH}px;
  position: absolute;
  top: ${() => HEADER_HEIGHT}px;
  left: ${() => SIDE_NAV_WIDTH}px;
  bottom: 72px;
  border-right: 1px solid ${() => COLORS.Neutral200};
  z-index: 80;
  overflow-y: scroll;
  height: calc(100% - ${() => HEADER_HEIGHT}px);

  button:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

const SectionsContainerHeader = styled.div`
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export interface LevelsProps {
  academyId: number;
  selectedLevelId: number;
  setHasRenamedLevel: (renamed: boolean) => void;
  getScreenNavItemUrlPath: (levelId: number, _new?: boolean) => string;
}

function SideNav({
  academyId,
  selectedLevelId,
  setHasRenamedLevel,
  getScreenNavItemUrlPath,
}: LevelsProps) {
  const {showSkillsTagging} = useShowSkillsTagging();
  const navigate = useNavigate();
  const params = useParams();
  const {isAdminPath} = usePathInfo();
  const [levelReorderIdx, setSectionReorderIdx] = useState<null | number>(null);
  const [isDraggingSectionOrder, setIsDraggingSectionOrder] = useState<
    null | number
  >(null);

  // Academy Level Order Mutator
  const updateAcademyLevelOrder = useMutation(
    (args: {id: number; order: number}) => {
      const updateAcademyLevelOrder = getUpdateAcademyLevelOrderRm(args);
      return simpleMutationFn<null>(
        updateAcademyLevelOrder.path,
        updateAcademyLevelOrder.payload
      );
    },
    {
      onSuccess: async () => {
        invalidateLevels();
      },
      onError: async () => {
        datadogLogs.logger.error('Error while updating academy level order');
      },
    }
  );

  // Update academy level
  const updateAcademyLevel = useMutation(
    (args: AcademyLevelPayload) => {
      const updateAcademyLevel = getUpdateAcademyLevelRm(args);
      return simpleMutationFn<null>(
        updateAcademyLevel.path,
        updateAcademyLevel.payload
      );
    },
    {
      onSuccess: () => {
        invalidateLevels();
        setHasRenamedLevel(true);
      },
      onError: async () => {
        datadogLogs.logger.error('Error while updating academy level');
      },
    }
  );

  // Add Academy Level Mutator
  const addAcademyLevel = useMutation(
    (args: CreateAcademyLevelRequest) => {
      const addAcademyLevel = getAddAcademyLevelRm(args);
      return simpleMutationFn<null>(
        addAcademyLevel.path,
        addAcademyLevel.payload
      );
    },
    {
      onSuccess: async (newLevelId) => {
        invalidateLevels();
        if (typeof newLevelId === 'number') {
          navigate(getScreenNavItemUrlPath(newLevelId, true));
        }
      },
      onError: async () => {
        datadogLogs.logger.error('Error while adding academy level');
      },
    }
  );

  // GET Academy Level Data
  const {
    data: levels,
    isLoading: isLoadingLevels,
    invalidateExact: invalidateLevels,
  } = useAcademyLevelVMsQuery({academyId, excludeUserProgress: true});

  const {data: steps} = useAcademyStepVMsQuery(selectedLevelId);
  useEffect(() => {
    if (params.levelId === '0') {
      invalidateLevels();
      if (levels[0]?.id) {
        navigate(getScreenNavItemUrlPath(levels[0].id, false));
      } else {
        if (isAdminPath) {
          navigate(editAcademyUrl(academyId));
        } else {
          navigate(userEditAcademyUrl(academyId));
        }
      }
    }
  }, [
    academyId,
    isAdminPath,
    levels,
    navigate,
    invalidateLevels,
    getScreenNavItemUrlPath,
    params,
  ]);

  return (
    <Container>
      {showSkillsTagging && <SkillsTagger academyId={academyId} />}
      <SectionsContainerHeader>
        <div
          style={{
            color: COLORS.Neutral600,
            fontSize: '0.75rem',
            fontWeight: 'bold',
          }}>
          {i18n.t(k.SECTION__PLURAL)}{' '}
          {isLoadingLevels && <LoadingSpinner size="small" />}
        </div>
        <LearnInTooltip
          title={i18n.t(k.CTA__ADD_ITEM__FORMAT, {
            item: i18n.t(k.SECTION__NEW),
          })}
          childHasButton={true}>
          <LearnInButton
            data-testid="add-section-button-academies"
            aria-label={i18n.t(k.CTA__ADD_ITEM__FORMAT, {
              item: i18n.t(k.SECTION__NEW),
            })}
            onClick={() => {
              addAcademyLevel.mutate({
                academyId,
                title: i18n.t(k.SECTION__NEW),
                description: '',
              });
            }}
            tag={ButtonTags.SecondaryRemovedBorder}
            icon={<PlusCircleOutlined aria-hidden="true" />}
            padding={'0'}
            iconSize={'L'}
          />
        </LearnInTooltip>
      </SectionsContainerHeader>
      <DragAndDropProvider backend={HTML5Backend} context={window}>
        {levels
          ?.sort((a, b) => a.order - b.order)
          .map(
            (
              {id, title, order, description, requiredId}: AcademyLevelVM,
              index: number
            ) => {
              const blockingTitle = getBlockingTitle(requiredId, levels);
              return (
                <LevelItem
                  blockingTitle={blockingTitle}
                  getScreenNavItemUrlPath={getScreenNavItemUrlPath}
                  steps={steps}
                  updateTitle={(newTitle: string) => {
                    updateAcademyLevel.mutateAsync({
                      id,
                      academyId,
                      title: mapTextToEnglish(newTitle),
                      description,
                      order,
                      requiredId,
                    });
                  }}
                  isFirst={index === 0}
                  key={id || index}
                  id={id || undefined}
                  title={title}
                  academyId={academyId}
                  order={order}
                  active={id === selectedLevelId}
                  onClick={() => {
                    navigate(getScreenNavItemUrlPath(id));
                  }}
                  isSectionContentEmpty={steps?.length === 0}
                  levels={levels}
                  levelReorderIdx={levelReorderIdx}
                  onClickMoveToTop={async () =>
                    await updateAcademyLevelOrder.mutateAsync({
                      order: 1,
                      id,
                    })
                  }
                  onClickMoveToBottom={async () =>
                    await updateAcademyLevelOrder.mutateAsync({
                      order: levels.length,
                      id,
                    })
                  }
                  reorderSectionItem={async () => {
                    if (levelReorderIdx !== order) {
                      updateAcademyLevelOrder.mutateAsync({
                        id,
                        order: levelReorderIdx,
                      });
                    }
                  }}
                  setSectionReorderIdx={() => {
                    if (levelReorderIdx !== order) {
                      setSectionReorderIdx(order);
                    }
                  }}
                  setIsDraggingSectionOrder={() => {
                    if (isDraggingSectionOrder !== order) {
                      setIsDraggingSectionOrder(order);
                    }
                  }}
                  isDraggingSectionOrder={isDraggingSectionOrder}
                />
              );
            }
          )}
      </DragAndDropProvider>
    </Container>
  );
}

export default SideNav;
