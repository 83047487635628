import {
  AcademyNudgeUserRequest,
  AcademyNudgeUsersRequest,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {AcademyNudgeUsersRequestSchema} from '../zodSchemas/AcademyNudgeUsersRequestSchema.schema';
import {AcademyNudgeUserRequestSchema} from '../zodSchemas/AcademyNudgeUserRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyNotificationController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/users/nudge 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUsersAllNudgeMutation} from '@generated/hooks';
 *
 *  const academiesUsersAllNudge = useAcademiesUsersAllNudgeMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesUsersAllNudge.mutate({
 *    payload: {
 *      ...AcademyNudgeUsersRequest
 *    },
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 */

export const useAcademiesUsersAllNudgeMutation = mutationHookFactory<
  AcademyNudgeUsersRequest, // payload
  {academyId: number | string}, // path variables
  null
>(
  'useAcademiesUsersAllNudgeMutation',
  'post',
  ({academyId}) => `academies/${academyId}/users/nudge`,
  {payloadSchema: AcademyNudgeUsersRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  {academyId}/users/{userCompanyId}/nudge 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesUsersNudgeMutation} from '@generated/hooks';
 *
 *  const academiesUsersNudge = useAcademiesUsersNudgeMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesUsersNudge.mutate({
 *    payload: {
 *      ...AcademyNudgeUserRequest
 *    },
 *    pathVars: {
 *      academyId,
 *      userCompanyId
 *    }
 *  })
 *
 */

export const useAcademiesUsersNudgeMutation = mutationHookFactory<
  AcademyNudgeUserRequest, // payload
  {academyId: number; userCompanyId: number}, // path variables
  null
>(
  'useAcademiesUsersNudgeMutation',
  'post',
  ({academyId, userCompanyId}) =>
    `academies/${academyId}/users/${userCompanyId}/nudge`,
  {payloadSchema: AcademyNudgeUserRequestSchema}
);
