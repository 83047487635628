import {
  AttachmentResponse,
  CustomContentLink,
  AttachmentVM,
} from '@models/serverModels';
import {useEffect, useState} from 'react';
import {AttachmentType} from '@components/reusable/Attachment';

interface UseAttachmentsLogicOptions {
  initialAttachmentValue?: AttachmentVM | AttachmentResponse;
  initialLinkValue?: CustomContentLink;
  contentUploadPercent?: number;
  onCancelUpload?: () => Promise<void>;
}

const useAttachmentsLogic = ({
  initialAttachmentValue,
  initialLinkValue,
  contentUploadPercent,
  onCancelUpload,
}: UseAttachmentsLogicOptions) => {
  const [attachment, setAttachment] = useState<
    AttachmentVM | AttachmentResponse | undefined
  >();
  const [newLink, setNewLink] = useState<CustomContentLink | undefined>();
  const [attachmentType, setAttachmentType] = useState<
    AttachmentType | undefined
  >();
  const [fileToUpload, setFileToUpload] = useState();
  const [isDeletingAttachment, setIsDeletingAttachment] = useState(false);
  const [shouldDeleteAttachment, setShouldDeleteAttachment] = useState(false);

  const showAttachmentTypeOptions =
    !attachmentType &&
    !isDeletingAttachment &&
    !attachment &&
    !newLink &&
    !fileToUpload &&
    contentUploadPercent === undefined;

  const showAttachmentUploadInput =
    (shouldDeleteAttachment && !fileToUpload) ||
    (attachmentType === AttachmentType.File &&
      !attachment &&
      !fileToUpload &&
      contentUploadPercent === undefined);

  useEffect(() => {
    if (!!initialLinkValue) {
      setNewLink(initialLinkValue);
      setAttachmentType(AttachmentType.Link);
    } else if (!!initialAttachmentValue) {
      setAttachment(initialAttachmentValue);
      setAttachmentType(AttachmentType.File);
    } else {
      setAttachment(undefined);
      setNewLink(undefined);
      setAttachmentType(null);
    }
  }, [initialLinkValue, initialAttachmentValue]);

  const waitForAttachmentDeletion = async (
    deleteAttachmentService: () => Promise<void> | void
  ) => {
    if (shouldDeleteAttachment) {
      setIsDeletingAttachment(true);
      await deleteAttachmentService();
      setIsDeletingAttachment(false);
      setShouldDeleteAttachment(false);
    }
  };

  function getAttachmentPayload() {
    return {
      file: fileToUpload,
      link: attachmentType === AttachmentType.Link && newLink,
    };
  }

  function handleOnCancelUpload() {
    if (onCancelUpload) {
      setIsDeletingAttachment(true);
      setAttachment(undefined);
      setAttachmentType(undefined);
      return onCancelUpload();
    }
  }

  return {
    getAttachmentPayload,
    waitForAttachmentDeletion,
    showAttachmentTypeOptions,
    showAttachmentUploadInput,
    setAttachment,
    setNewLink,
    attachmentType,
    setAttachmentType,
    fileToUpload,
    setFileToUpload,
    isDeletingAttachment,
    setIsDeletingAttachment,
    shouldDeleteAttachment,
    setShouldDeleteAttachment,
    attachment,
    newLink,
    contentUploadPercent,
    cancelUpload: handleOnCancelUpload,
  };
};

export default useAttachmentsLogic;
