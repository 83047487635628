import {TeamInsightEmployeeDetailVm} from '@generated/interfaces';
import {i18n, k} from '@i18n/translate';
import {TeamInsightsDetailVM} from '@models/serverModels';

export const useDrilldownHasEmptyData = (data: TeamInsightEmployeeDetailVm) =>
  data.academies.length === 0 &&
  data.resources.length === 0 &&
  data.programs.length === 0;

export const useDrilldownPlanActivityTitle = ({
  firstName,
  isUsersTabOn,
  isYourPlanNameChangeOn,
}: {
  firstName: string;
  isUsersTabOn: boolean;
  isYourPlanNameChangeOn: boolean;
}) =>
  isUsersTabOn
    ? i18n.t(k.GENERIC__ACTIVITY)
    : isYourPlanNameChangeOn
    ? i18n.t(k.INSIGHTS__USERS_ACTIVITY__FORMAT, {firstName})
    : i18n.t(k.INSIGHTS__USERS_PLAN__FORMAT, {firstName});

export const useDrilldownCanSeeTransactions = ({
  flagOn,
  hasPrepayment,
  isEligibleForPLB,
}: {
  flagOn: boolean;
  hasPrepayment: boolean;
  isEligibleForPLB: boolean;
}) => flagOn && isEligibleForPLB && hasPrepayment;
