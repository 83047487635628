import {
  BackButton,
  Container,
  HeaderContainer,
  HeaderLeftContainer,
  Title,
} from './DrawerPage.styled';
import {DrawerPageViewProps} from './DrawerPage.interfaces';
import {LeftOutlined} from '@ant-design/icons';
import {i18n, k} from '@i18n/translate';

function DrawerPageView({
  children,
  headerRightContent = null,
  useStaticPosition = false,
  onClickBack,
  title,
}: DrawerPageViewProps) {
  return (
    <Container static={useStaticPosition}>
      {/* Header */}
      <HeaderContainer>
        {/* Left Section */}
        <HeaderLeftContainer>
          {onClickBack && (
            <BackButton
              onClick={onClickBack}
              aria-label={i18n.t(k.GENERIC__BACK)}>
              <LeftOutlined style={{fontSize: '1.7em'}} />
            </BackButton>
          )}
          <Title>{title}</Title>
        </HeaderLeftContainer>

        {/* Custom Right Section */}
        {headerRightContent}
      </HeaderContainer>

      {/* Page Content */}
      {children}
    </Container>
  );
}

export default DrawerPageView;
