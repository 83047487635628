// This scss file is needed because putting css in a wrapper style component won't work when the component renders through a portal
import {LeftOutlined} from '@ant-design/icons';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {Breakpoints, getResponsiveDrawerSize} from '@utils/responsiveUtils';
import {Drawer} from 'antd';
import * as React from 'react';
import {JSXElementConstructor, ReactNode, ReactNodeArray} from 'react';
import styled from 'styled-components';
import './LearnInPageDrawer.scss';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

export const HEADER_HEIGHT = 72;
export const NAV_PANEL_WIDTH = 272;

const HeaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0px;
  right: 0;
  width: 100%;
  box-shadow: inset 0px -1px 0px ${COLORS.Neutral200};
  height: ${HEADER_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 16px;
  z-index: 100;
  background: white;

  a:focus-visible,
  button:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }

  @media (max-width: ${Breakpoints.Mobile}px) {
    body {
      font-size: 0.958em;
    }
  }
`;

const HeaderTitle = styled.span`
  display: flex;
  justify-content: start;
  padding-left: 20px;
  flex-grow: 1;
`;

export const TitleContainer = styled.div`
  align-items: center;
  color: ${COLORS.Neutral900};
  display: grid;
  font-size: 1.5rem;
  font-weight: 500;
  grid-gap: 0.5rem;
  grid-template-columns: auto auto;
  line-height: 1.75rem;
  padding-left: 16px;
`;

const ContentContainer = styled.div`
  padding-top: ${HEADER_HEIGHT}px;
`;

export const IconContainer = styled.div`
  & :first-child {
    margin: 8px;
    font-size: 1.375rem;
    background-color: ${COLORS.Neutral200};
    border-radius: 4px;
  }
`;

const TitleText = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExternalElementsContainer = styled.div`
  flex-shrink: 0;
`;

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

export interface IDrawerHeaderProps {
  onClose: () => void;
  title?: ReactNode;
  headerContent?: React.ReactNode;
  Icon?: JSXElementConstructor<{role?: string}>;
  LearnInPageDrawer?: boolean;
}

export function Header({
  onClose,
  title,
  headerContent,
  Icon,
}: IDrawerHeaderProps) {
  return (
    <HeaderContainer>
      <HeaderTitle>
        {onClose && (
          <LearnInButton
            aria-label={i18n.t(k.A11Y__DRAWER__CLOSE)}
            onClick={onClose}
            color={COLORS.Neutral600}
            iconSize={'L'}
            activeBorderColor={'none'}
            tag={ButtonTags.SecondaryBlackRemovedBorder}
            icon={<LeftOutlined aria-hidden="true" />}
          />
        )}
        <TitleContainer>
          {Icon && <Icon aria-hidden="true" />}
          <TitleText>{title}</TitleText>
        </TitleContainer>
      </HeaderTitle>
      <ExternalElementsContainer>{headerContent}</ExternalElementsContainer>
    </HeaderContainer>
  );
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ILearnInPageDrawerProps extends IDrawerHeaderProps {
  children: ReactNode;
  headerContent?: ReactNode | ReactNodeArray;
  onClose: () => void;
  title?: ReactNode;
  visible: boolean;
  destroyOnClose?: boolean;
  className?: string;
  drawerStyle?: React.CSSProperties;
  Icon?: JSXElementConstructor<{role?: string}>;
  zIndex?: number;
  contentWrapperStyle?: React.CSSProperties;
  contentContainerStyle?: React.CSSProperties;
}

function LearnInPageDrawer({
  onClose,
  title,
  children,
  headerContent,
  visible,
  className,
  drawerStyle,
  contentWrapperStyle,
  contentContainerStyle,
  Icon,
  ...rest
}: ILearnInPageDrawerProps) {
  const _className = ['learnin-page-drawer', className]
    .filter(Boolean)
    .join(' ');

  const contentRef = React.useRef<HTMLDivElement>(null);
  React.useLayoutEffect(() => {
    if (!contentRef.current || typeof title !== 'string') {
      return;
    }

    const parent = contentRef.current.closest('.ant-drawer-content');
    parent?.setAttribute('aria-label', title);
  }, [children, title]);

  return (
    <Drawer
      className={_className}
      width={getResponsiveDrawerSize('100%')}
      placement="right"
      open={visible}
      {...rest}
      headerStyle={{display: 'none'}}
      contentWrapperStyle={contentWrapperStyle}
      style={drawerStyle ?? null}>
      <Header
        onClose={onClose}
        Icon={Icon}
        title={title}
        headerContent={headerContent || null}
      />
      <ContentContainer ref={contentRef} style={contentContainerStyle ?? null}>
        {children}
      </ContentContainer>
    </Drawer>
  );
}

export default LearnInPageDrawer;
