import {CalendarCallbackRedirect, CalendarProvider} from './../enums';
import {SyncCalendarRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: SyncCalendarRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getSyncCalendarRequestMockData(
  args?: Partial<SyncCalendarRequest>
): SyncCalendarRequest {
  return {
    calendar: CalendarProvider.Google,
    id: faker.datatype.number(), // optional
    redirectOption: CalendarCallbackRedirect.UserSettings,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const SyncCalendarRequestMockData: SyncCalendarRequest = {
  calendar: CalendarProvider.Google,
  id: 1, // optional
  redirectOption: CalendarCallbackRedirect.UserSettings,
};
