import {
  ChatExternalChannelVisibility,
  CustomProgramStatus,
  DurationUnitType,
} from './../enums';
import {CustomProgramAdminDetailVM} from './../interfaces';
import {
  CustomProgramSkillTagMockData,
  getCustomProgramSkillTagMockData,
} from './CustomProgramSkillTag.mock';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramAdminDetailVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramAdminDetailVMMockData(
  args?: Partial<CustomProgramAdminDetailVM>
): CustomProgramAdminDetailVM {
  return {
    chatEnabled: faker.datatype.boolean(),
    chatExternalChannelName: faker.name.fullName(),
    chatExternalChannelVisibility: ChatExternalChannelVisibility.Public,
    cohortCount: faker.datatype.number(),
    duration: faker.word.noun(),
    endDateUtc: String(faker.date.past()), // optional
    hasExternalChatId: faker.datatype.boolean(),
    id: faker.datatype.number(),
    imageUrl: faker.image.imageUrl(), // optional
    isNudgeEmailEnabled: faker.datatype.boolean(),
    isProgramCompletionEmailEnabled: faker.datatype.boolean(),
    isWelcomeEmailEnabled: faker.datatype.boolean(),
    joinId: String(faker.datatype.number()),
    longDescription: faker.lorem.lines(),
    programLength: faker.datatype.number(), // optional
    programLengthUnit: DurationUnitType.Days, // optional
    restrictedAccess: faker.datatype.boolean(),
    shortDescription: faker.lorem.lines(),
    skills: [getCustomProgramSkillTagMockData()],
    startDateUtc: String(faker.date.past()), // optional
    status: CustomProgramStatus.Draft,
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramAdminDetailVMMockData: CustomProgramAdminDetailVM = {
  chatEnabled: true,
  chatExternalChannelName: 'Bobby Johnson',
  chatExternalChannelVisibility: ChatExternalChannelVisibility.Public,
  cohortCount: 5,
  duration: 'string',
  endDateUtc: '2023-03-29T22:17:19.738072', // optional
  hasExternalChatId: true,
  id: 1,
  imageUrl: 'https://loremflickr.com/640/480', // optional
  isNudgeEmailEnabled: true,
  isProgramCompletionEmailEnabled: true,
  isWelcomeEmailEnabled: true,
  joinId: '1',
  longDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  programLength: 123, // optional
  programLengthUnit: DurationUnitType.Days, // optional
  restrictedAccess: true,
  shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  skills: [CustomProgramSkillTagMockData],
  startDateUtc: '2023-03-29T22:17:19.738072', // optional
  status: CustomProgramStatus.Draft,
  title: 'Advanced Web Design',
};
