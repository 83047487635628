import {DrawerRoutes} from '@utils/ClientPaths';

export const useGetUserCompanyIdFromNudgePath = (path: string) => {
  if (!path) return;
  const isNudgeRoute = path?.endsWith(DrawerRoutes.Nudge);
  const splitBeforeNudge = path?.split('/' + DrawerRoutes.Nudge)[0]?.split('/');
  const userCompanyIdFromPath =
    isNudgeRoute && splitBeforeNudge[splitBeforeNudge.length - 1];
  return userCompanyIdFromPath;
};
