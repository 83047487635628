import {ReactNode} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {Tabs} from 'antd';
import {TabsProps} from 'antd/lib/tabs';

import {COLORS} from '@utils/constants';

interface Props extends TabsProps {
  children: ReactNode;
  activeKey?: string;
  addIcon?: ReactNode;
  animated?: boolean | {inkBar: boolean; tabPane: boolean};
  centered?: boolean;
  defaultActiveKey?: string;
  hideAdd?: boolean;
  moreIcon?: ReactNode;
  renderTabBar?: (props: TabsProps) => React.ReactElement;
  size?: 'large' | 'small' | 'middle';
  tabBarExtraContent?: ReactNode | {left?: ReactNode; right?: ReactNode};
  tabBarGutter?: number;
  tabPosition?: 'top' | 'right' | 'bottom' | 'left';
  type?: 'line' | 'card' | 'editable-card';
  onChange?: (activeKey: string) => void;
  onEdit?: (targetKey, action) => void;
  tabBarSticky?: boolean;
  hideTabs?: boolean;
}

export function LearnInTabs({
  children,
  renderTabBar,
  tabBarSticky,
  hideTabs,
  ...rest
}: Props) {
  const renderStickyTabBar: TabsProps['renderTabBar'] = (
    props,
    DefaultTabBar
  ) => (
    <StickyTabWrapper>
      <DefaultTabBar {...props} />
    </StickyTabWrapper>
  );

  const selectedRenderTabBar = tabBarSticky ? renderStickyTabBar : renderTabBar;

  return (
    <StyledTabs
      renderTabBar={selectedRenderTabBar}
      {...rest}
      hideTabs={hideTabs}>
      {children}
    </StyledTabs>
  );
}

const StickyTabWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 80;
  background-color: ${COLORS.Neutral50};
`;

const StyledTabs = styled(Tabs)<{hideTabs?: boolean}>`
  width: 100%;

  & .ant-tabs-tab {
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 21px;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
  }
  & .ant-tabs-ink-bar::after {
    background: ${COLORS.Blue800};
    height: 1px;
  }

  &.ant-tabs-top > .ant-tabs-nav {
    margin: 0px;
  }

  & .ant-tabs-tab .ant-tabs-tab-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px 16px 16px !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
    text-transform: none !important;
    &:focus-visible {
      outline: 2px solid ${COLORS.Blue950};
      outline-offset: 8px;
    }
  }
  & .ant-tabs-tab {
    padding-top: 16px !important;
    color: ${COLORS.Neutral900} !important;
  }
  & .ant-tabs-tab:hover {
    color: ${COLORS.Neutral950};
  }
  .ant-tabs-tabpane {
    padding: 2px;
  }

  div.ant-tabs-nav-wrap {
    border-bottom: 1px solid ${COLORS.Neutral200} !important;
    ${({hideTabs}) => (hideTabs ? 'display: none !important;' : '')}
  }

  ${StickyTabWrapper} > .ant-tabs-nav {
    margin-bottom: 0;
  }
`;
