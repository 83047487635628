import React from 'react';
import {
  Container,
  ResponsiveDivider,
  ResponsiveSpace,
} from './BudgetCard.styled';
import {BudgetCardViewProps} from './BudgetCard.interfaces';
import {getUsersLocale} from '@utils/l10nUtils';
import BudgetCreditCard from '@components/user/pages/userPlan/components/personalLearningBudgetCard/BudgetCreditCard';
import BudgetUptoDisplay from '@components/user/pages/userPlan/components/personalLearningBudgetCard/BudgetUptoDisplay';
import ActionButtons from '../ActionButtons';
import {Space} from 'antd';
import TitleDescription from '../TitleDescription';

function BudgetCardView({
  direction,
  exchangeRate,
  financeLearningBuds,
  isFinanceLearningBudsError,
  isFinanceLearningBudsLoading,
  isFinanceLearningBudsSuccess,
  isTranslatePlbFieldsOn,
  navigate,
  personalization,
  setShowReimbursement,
  showCreditCard,
  showExploreProgramButton,
  user,
  userProgramPlan,
  hasProgramsOrResources,
  isPeopleTab,
  drillDownData,
}: BudgetCardViewProps) {
  return (
    <Container removeBorder={isPeopleTab}>
      <Space direction="vertical" size="large">
        <Space
          className="responsive-flex"
          align="start"
          wrap
          direction={direction}
          size="large">
          <ResponsiveSpace
            wrap
            split={<ResponsiveDivider type="vertical" />}
            size="large">
            {showCreditCard && (
              <BudgetCreditCard
                exchangeRate={exchangeRate}
                financeLearningBuds={financeLearningBuds}
                isFinanceLearningBudsError={isFinanceLearningBudsError}
                isFinanceLearningBudsLoading={isFinanceLearningBudsLoading}
                isFinanceLearningBudsSuccess={isFinanceLearningBudsSuccess}
                personalization={personalization}
                user={user}
              />
            )}
            <BudgetUptoDisplay
              exchangeRate={exchangeRate}
              financeLearningBuds={financeLearningBuds}
              isFinanceLearningBudsSuccess={isFinanceLearningBudsSuccess}
              personalization={personalization}
              user={user}
              userProgramPlan={userProgramPlan}
              hasProgramsOrResources={hasProgramsOrResources}
              drillDownData={drillDownData}
            />
          </ResponsiveSpace>
          {!isPeopleTab ? (
            <TitleDescription
              direction={direction}
              description={financeLearningBuds?.description}
              isTranslatePlbFieldsOn={isTranslatePlbFieldsOn}
              locale={getUsersLocale()}
              title={financeLearningBuds?.title}
            />
          ) : (
            ''
          )}
        </Space>
        {!isPeopleTab && (
          <ActionButtons
            financeIncentiveId={financeLearningBuds?.financeIncentiveId}
            financeType={financeLearningBuds?.financeType}
            navigate={navigate}
            setShowReimbursement={setShowReimbursement}
            showActiveCard={!!financeLearningBuds?.creditCard?.cardId}
            showExploreProgramButton={showExploreProgramButton}
            userPlanItemId={financeLearningBuds?.userPlanItemId}
          />
        )}
      </Space>
    </Container>
  );
}

export default BudgetCardView;
