import {LicenseStatus} from './../enums';
import {UpdatePartialLicenseRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdatePartialLicenseRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdatePartialLicenseRequestMockData(
  args?: Partial<UpdatePartialLicenseRequest>
): UpdatePartialLicenseRequest {
  return {
    status: LicenseStatus.Draft, // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdatePartialLicenseRequestMockData: UpdatePartialLicenseRequest =
  {
    status: LicenseStatus.Draft, // optional
  };
