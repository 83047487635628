import {i18n, k} from '@i18n/translate';
import moment from 'moment';
import LearnInTable, {
  ColumnAlignment,
  renderProgressBar,
} from '@components/reusable/LearnInTable';
import LearnInTag, {StatusTag, TagStyles} from '@blocks/LearnInTag';
import {
  TeamInsightsDetailVM,
  UserItemStatusVM,
  UserResourceInsightVM,
} from '@models/serverModels';
import {
  TeamInsightEmployeeDetailVm,
  UserDetailsVM,
} from '@generated/interfaces';
import {
  DisplayStatus,
  DisplayStatusWarningLevel,
  LearningResourceType,
} from '@generated/enums';
import {formatCurrency} from '@utils/moneyUtils';
import {DATE_FORMAT} from '@utils/timeUtils';
import {COLUMN_WIDTHS} from '@components/manager/pages/team-insights/utils';

export const ResourcesTable = ({
  data,
  user,
  exchangeRate,
  showReportStatusTag,
}: {
  data: TeamInsightEmployeeDetailVm;
  user: UserDetailsVM;
  exchangeRate: number;
  showReportStatusTag: boolean;
}) => {
  return (
    <LearnInTable
      columns={[
        {
          title: i18n.t(k.DATE__REQUESTED),
          width: COLUMN_WIDTHS.date,
          dataIndex: 'dateRequested',
          key: 'dateRequested',
          render: (dateRequested: string) =>
            moment(dateRequested).format(DATE_FORMAT.MONTH_DAY_YEAR),
          sorter: (a: UserResourceInsightVM, b: UserResourceInsightVM) =>
            a.dateRequested.localeCompare(b.dateRequested),
        },
        {
          title: i18n.t(k.GENERIC__TYPE),
          width: COLUMN_WIDTHS.type,
          dataIndex: 'resourceType',
          key: 'resourceType',
          render: (resourceType: LearningResourceType) =>
            LearningResourceType[resourceType],
          sorter: (a: UserResourceInsightVM, b: UserResourceInsightVM) =>
            LearningResourceType[a.resourceType].localeCompare(
              LearningResourceType[b.resourceType]
            ),
        },
        {
          title: i18n.t(k.REQUEST),
          dataIndex: 'title',
          key: 'title',
          sorter: (a: UserResourceInsightVM, b: UserResourceInsightVM) =>
            a.title.localeCompare(b.title),
        },
        {
          title: i18n.t(k.MONEY__COST),
          width: COLUMN_WIDTHS.amount,
          align: ColumnAlignment.Right,
          dataIndex: 'approvedAmount',
          key: 'approvedAmount',
          render: (approvedAmount: number) =>
            formatCurrency(approvedAmount, user?.currency, exchangeRate, {
              decimal: true,
            }),
          sorter: (a: UserResourceInsightVM, b: UserResourceInsightVM) =>
            a.approvedAmount - b.approvedAmount,
        },
        {
          title: i18n.t(k.STATUS__STATUS),
          dataIndex: 'status',
          width: COLUMN_WIDTHS.status,
          key: 'status',
          render: ({
            displayStatus,
            displayStatusWarningLevel,
            completedPercent,
          }: UserItemStatusVM) => {
            if (showReportStatusTag) {
              return (
                <StatusTag
                  displayStatus={displayStatus}
                  displayStatusWarningLevel={displayStatusWarningLevel}
                />
              );
            }
            const style =
              DisplayStatusWarningLevel.Valid === displayStatusWarningLevel
                ? TagStyles.Green
                : DisplayStatusWarningLevel.Warning ===
                  displayStatusWarningLevel
                ? TagStyles.Yellow
                : TagStyles.Red;
            return completedPercent !== null &&
              completedPercent < 100 &&
              completedPercent > 0 ? (
              renderProgressBar(completedPercent)
            ) : (
              <LearnInTag
                label={DisplayStatus[displayStatus]}
                tagStyle={style}
              />
            );
          },
          sorter: (a: UserResourceInsightVM, b: UserResourceInsightVM) =>
            a.status.toString().localeCompare(b.status.toString()),
        },
      ]}
      dataSource={data ? data?.resources : []}
    />
  );
};
