import {COLORS} from '@utils/constants';
import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  max-width: 95%;
  padding: 24px;
  width: 1200px;
`;

export const SectionHeader = styled.h1`
  color: ${COLORS.Neutral950};
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
`;

export const SubSectionHeader = styled.h2`
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0 0 0 10px;
  padding: 0;
`;

export const SubSectionHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  margin: 24px 0 16px;
`;

export const LeadershipCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const AcademyLeadershipCardContainer = styled.div`
  margin: 0 32px 16px 0;
  display: flex;
  align-items: stretch;
`;
