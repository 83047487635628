import {InitiativeStatus} from './../enums';
import {UpdateInitiativeStatus} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UpdateInitiativeStatus

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUpdateInitiativeStatusMockData(
  args?: Partial<UpdateInitiativeStatus>
): UpdateInitiativeStatus {
  return {
    id: faker.datatype.number(),
    status: InitiativeStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UpdateInitiativeStatusMockData: UpdateInitiativeStatus = {
  id: 1,
  status: InitiativeStatus.Draft,
};
