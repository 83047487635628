import {CustomProgramContentStatus} from './../enums';
import {UserPlanContentStatusUpdate} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserPlanContentStatusUpdate

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanContentStatusUpdateMockData(
  args?: Partial<UserPlanContentStatusUpdate>
): UserPlanContentStatusUpdate {
  return {
    contentId: faker.datatype.number(),
    status: CustomProgramContentStatus.Planned,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanContentStatusUpdateMockData: UserPlanContentStatusUpdate =
  {
    contentId: 1,
    status: CustomProgramContentStatus.Planned,
  };
