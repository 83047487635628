import {LearningResourceType} from './../enums';
import {NewResourceApprovalApplicationVM} from './../interfaces';
import {
  PreApprovalQuestionVMMockData,
  getPreApprovalQuestionVMMockData,
} from './PreApprovalQuestionVM.mock';
import {faker} from '@faker-js/faker';

// Interface: NewResourceApprovalApplicationVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getNewResourceApprovalApplicationVMMockData(
  args?: Partial<NewResourceApprovalApplicationVM>
): NewResourceApprovalApplicationVM {
  return {
    questions: [getPreApprovalQuestionVMMockData()],
    resourceType: LearningResourceType.Book,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const NewResourceApprovalApplicationVMMockData: NewResourceApprovalApplicationVM =
  {
    questions: [PreApprovalQuestionVMMockData],
    resourceType: LearningResourceType.Book,
  };
