import {i18n, k} from '@i18n/translate';
import {
  LicenseCharLimits,
  LicenseDuration,
  LicenseFormFieldErrors,
  LicenseFormFields,
  LicenseInternalCost,
} from './AddUpdateLicenseDrawer.types';
import {hasContentsInHtmlString} from '@utils/stringUtils';
import {LicenseRedemptionType} from '@generated/enums';

export function validateProviderId({
  formFieldErrors,
  id,
  setFormFieldErrors,
  shouldSetError = true,
}: {
  formFieldErrors: LicenseFormFieldErrors;
  id: number;
  setFormFieldErrors;
  shouldSetError?: boolean;
}) {
  const msg = isNaN(id) ? i18n.t(k.VALIDATION__LICENSE_PROVIDER) : null;
  const error = {[LicenseFormFields.ProviderId]: msg};
  shouldSetError &&
    setFormFieldErrors?.({
      ...formFieldErrors,
      ...error,
    });
  return error;
}

export function validateLicenseCost({
  cost,
  formFieldErrors,
  internalCostType,
  shouldSetError = true,
  setFormFieldErrors,
}: {
  cost: number;
  formFieldErrors;
  internalCostType: LicenseInternalCost;
  shouldSetError: boolean;
  setFormFieldErrors;
}) {
  const msg =
    internalCostType === LicenseInternalCost.Fixed &&
    (isNaN(cost) || Number(cost) < 0)
      ? i18n.t(k.VALIDATION__AMOUNT)
      : null;
  const error = {[LicenseFormFields.InternalCost]: msg};
  shouldSetError &&
    setFormFieldErrors?.({
      ...formFieldErrors,
      ...error,
    });
  return error;
}

export function validateLicenseDuration({
  duration,
  formFieldErrors,
  licenseDurationType,
  redemptionType,
  setFormFieldErrors,
  shouldSetError = true,
}) {
  const invalid =
    (!licenseDurationType && LicenseRedemptionType.Voucher) ||
    (redemptionType === LicenseRedemptionType.Voucher &&
      licenseDurationType === LicenseDuration.Fixed &&
      (isNaN(duration) || duration < 0));
  const msg = invalid ? i18n.t(k.VALIDATION__ENTER_DURATION__POLITE) : null;
  const error = {[LicenseFormFields.LicenseDuration]: msg};
  shouldSetError &&
    setFormFieldErrors?.({
      ...formFieldErrors,
      ...error,
    });
  return error;
}

export function validateLicenseFormHasErrors(errors) {
  return Object.values(errors).some((error) => !!error);
}

export function validateRedemptionType({
  redemptionType,
  formFieldErrors = null,
  setFormFieldErrors = null,
  shouldSetError = true,
}) {
  let msg = null;
  if (
    redemptionType !== LicenseRedemptionType.External &&
    redemptionType !== LicenseRedemptionType.Voucher
  ) {
    msg = i18n.t(k.VALIDATION__REDEMPTION_TYPE__POLITE);
  }
  const error = {[LicenseFormFields.RedemptionType]: msg};
  shouldSetError &&
    setFormFieldErrors?.({
      ...formFieldErrors,
      ...error,
    });
  return error;
}

export function validateRedemptionGuideline({
  formFieldErrors,
  guideline,
  setFormFieldErrors,
  shouldSetError = true,
}) {
  let msg = null;
  if (!hasContentsInHtmlString(guideline)) {
    msg = i18n.t(k.VALIDATION__REDEMPTION_GUIDELINES__POLITE);
  } else if (guideline.length > LicenseCharLimits.Description) {
    msg = i18n.t(k.VALIDATION__CHAR_LIMIT_REACHED__FORMAT, {
      max: LicenseCharLimits.Description,
    });
  } else {
    msg = null;
  }
  const error = {[LicenseFormFields.RedemptionGuideline]: msg};
  shouldSetError &&
    setFormFieldErrors?.({
      ...formFieldErrors,
      ...error,
    });
  return error;
}

export function validateLicenseApprovalGuideline({
  formFieldErrors,
  guideline,
  setFormFieldErrors,
  shouldSetError = true,
}) {
  const msg =
    guideline?.length > LicenseCharLimits.Description
      ? i18n.t(k.VALIDATION__CHAR_LIMIT_REACHED__FORMAT, {
          max: LicenseCharLimits.Description,
        })
      : null;
  const error = {[LicenseFormFields.ApprovalGuideline]: msg};
  shouldSetError &&
    setFormFieldErrors?.({
      ...formFieldErrors,
      ...error,
    });
  return error;
}

export function validateAllForLicense({
  approvalGuideline,
  formFieldErrors,
  internalCost,
  internalCostType,
  licenseDuration,
  licenseDurationType,
  providerId,
  redemptionGuideline,
  redemptionType,
  setFormFieldErrors,
}) {
  const errors = {
    ...validateProviderId({
      formFieldErrors,
      id: providerId,
      setFormFieldErrors,
      shouldSetError: false,
    }),
    ...validateLicenseCost({
      formFieldErrors,
      cost: internalCost,
      internalCostType,
      setFormFieldErrors,
      shouldSetError: false,
    }),
    ...validateLicenseDuration({
      duration: licenseDuration,
      redemptionType,
      formFieldErrors,
      licenseDurationType,
      setFormFieldErrors,
      shouldSetError: false,
    }),
    ...validateRedemptionGuideline({
      guideline: redemptionGuideline,
      formFieldErrors,
      setFormFieldErrors,
      shouldSetError: false,
    }),
    ...validateRedemptionType({
      redemptionType,
      formFieldErrors,
      setFormFieldErrors,
      shouldSetError: false,
    }),
    ...validateLicenseApprovalGuideline({
      guideline: approvalGuideline,
      formFieldErrors,
      setFormFieldErrors,
      shouldSetError: false,
    }),
  };
  setFormFieldErrors(errors);
  return errors;
}
