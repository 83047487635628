import {useEffect, useState, useRef} from 'react';
import {UnprivilegedEditor} from 'react-quill';
import DOMPurify from 'dompurify';
import {useParams} from 'react-router-dom';
import {useAcademy} from '@hooks/apiEndpoints/academy/queries';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {
  useAcademiesMutation,
  useAcademyByAcademyIdIntQuery as useAcademyWelcomeEmail,
  useUserQuery,
  usePersonalizationQuery,
} from '@generated/hooks';
import {splitDOM} from '@utils/domUtils';
import InvitationEmailDrawer from './InvitationEmailDrawer.view';
import {notify} from '@components/user/notifications';

interface InvitationEmailDrawerProps {
  onClose: () => void;
  open: boolean;
}

type PathParams = {academyId: string};

const InvitationEmailDrawerContainer = ({
  onClose,
  open,
}: InvitationEmailDrawerProps) => {
  const params = useParams<PathParams>();
  const {data: user} = useUserQuery(null);

  const companyPersonalizationQuery = usePersonalizationQuery(null);

  /**
   * Fetch current academy data
   */
  const {data: academy} = useAcademy(parseInt(params.academyId), {
    enabled: !!params?.academyId && open,
  });

  /**
   * Fetch email preview
   */
  const {data: emailHtmlContent, invalidateExact: refreshEmailContent} =
    useAcademyWelcomeEmail(
      {
        academyId: parseInt(params.academyId),
        queryParams: {
          forceShowNote: false,
          companyLogoUrl:
            companyPersonalizationQuery.data?.companyLogoUrlRectangle,
        },
      },
      {
        enabled:
          !!params?.academyId && open && companyPersonalizationQuery.isSuccess,
      }
    );

  const {mutate: mutateEmailNote} = useAcademiesMutation({
    onSuccess: () => {
      refreshEmailContent();
      notify.saveUpdateAcademyWelcomeEmailSuccess();
      setIsEditingNote(false);
      setDeletePendingSave(false);
      setEmailNote('');
    },
  });

  /**
   * Ref to parse HTML string into actual DOM elements
   */
  const htmlParserElementRef = useRef(null);

  /**
   * Keeps the "Save Changes" button enabled for persisting note deletion
   */
  const [isDeletePendingSave, setDeletePendingSave] = useState<boolean>(false);

  /**
   * DOM sections obtained from parsed HTML
   * [upper, middle element, lower]
   */
  const [emailSections, setEmailSections] =
    useState<[HTMLElement, HTMLElement, HTMLElement]>(null);

  /**
   * RichTextEditor HTML
   */
  const [emailNote, setEmailNote] = useState('');

  /**
   * Text included in the original email that acts as an intro
   * to the note and here's manually added above the editor
   */
  const [emailNoteIntro, setEmailNoteIntro] = useState('');

  /**
   * RichTextEditor character count (text content only)
   */
  const [emailNoteCharCount, setEmailNoteCharCount] = useState<number>(0);

  /**
   * Indicates whether the email contains a note
   */
  const [emailHasNote, setEmailHasNote] = useState<boolean>(false);

  /**
   * Indicates whether the component should be in edit mode
   * and display the text editor
   */
  const [isEditingNote, setIsEditingNote] = useState<boolean>(false);

  useEffect(() => {
    // Once react has a reference to the injected HTML, parse it
    if (htmlParserElementRef.current && emailHtmlContent) {
      // (A11y) removes buttons and anchors from keyboard navigation flow
      htmlParserElementRef.current
        .querySelectorAll('button, a')
        .forEach((element: HTMLElement) => {
          element.setAttribute('inert', '');
          element.setAttribute('tab-index', '-1');
        });

      const emailSections = splitDOM(
        '#notes',
        htmlParserElementRef.current,
        'table:first-child > tbody > tr > td'
      );
      setEmailSections(emailSections);
      if (emailSections[1]) {
        const noteIntro =
          emailSections[1].firstElementChild.getAttribute('data-note-intro');
        setEmailNoteIntro(noteIntro);
        setEmailHasNote(!!emailSections[1]?.textContent?.trim());
      }
    }
  }, [open, emailHtmlContent]);

  const handleAddNote = () => {
    setIsEditingNote(true);
    setDeletePendingSave(false);
  };

  const handleRemoveNote = () => {
    setIsEditingNote(false);
    if (emailHasNote) {
      setDeletePendingSave(true);
    } else {
      setEmailNote('');
      setEmailNoteCharCount(0);
    }
  };

  const handleOnCancel = () => {
    if (isEditingNote) {
      setIsEditingNote(false);
    } else {
      onClose();
    }
    setEmailNote('');
    setEmailNoteCharCount(0);
    setDeletePendingSave(false);
  };

  const handleSaveChanges = () => {
    /**
     * triggers mutation to persist the email note
     * `note: null` deletes the note
     */
    mutateEmailNote({
      pathVars: {academyId: parseInt(params?.academyId)},
      payload: {
        isNoteUpdated: true,
        note: !!emailNote ? encodeURIComponent(emailNote) : null,
      },
    });
  };

  const handleOnNoteChange = (
    htmlValue: string,
    _delta,
    _source,
    quillEditor: UnprivilegedEditor
  ) => {
    setEmailNote(htmlValue);
    setEmailNoteCharCount(quillEditor.getLength());
  };

  const handleEditNote = () => {
    setEmailNote(emailSections[1].querySelector('#note-content').innerHTML);
    setIsEditingNote(true);
  };

  const handleCloseDrawer = () => {
    setEmailNote('');
    setIsEditingNote(false);
    setEmailNoteCharCount(0);
    setDeletePendingSave(false);
    onClose();
  };

  if (!academy) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {/* div below used only as a medium to parse the email HTML string,
          converting it into an actual DOM tree */}
      <div
        style={{display: 'none'}}
        ref={htmlParserElementRef}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(emailHtmlContent as string),
        }}
      />
      <InvitationEmailDrawer
        companyId={user?.companyId}
        onClose={handleCloseDrawer}
        open={open}
        academy={academy}
        emailNote={emailNote}
        emailHasNote={emailHasNote}
        emailNoteIntro={emailNoteIntro}
        isEditingNote={isEditingNote}
        onNoteChange={handleOnNoteChange}
        emailSections={emailSections}
        onAddNote={handleAddNote}
        onRemoveNote={handleRemoveNote}
        onCancel={handleOnCancel}
        onSaveChanges={handleSaveChanges}
        emailNoteCharCount={emailNoteCharCount}
        onEditNote={handleEditNote}
        isDeletePendingSave={isDeletePendingSave}
      />
    </>
  );
};

export default InvitationEmailDrawerContainer;
