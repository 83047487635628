import {Author} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: Author

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAuthorMockData(args?: Partial<Author>): Author {
  return {
    id: faker.datatype.number(),
    name: faker.name.fullName(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AuthorMockData: Author = {
  id: 1,
  name: 'Bobby Johnson',
};
