import {GetStartedItemVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: GetStartedItemVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getGetStartedItemVMMockData(
  args?: Partial<GetStartedItemVM>
): GetStartedItemVM {
  return {
    featured: faker.datatype.number(),
    id: faker.datatype.number(),
    imageSmall: faker.word.noun(),
    logo: faker.word.noun(),
    orderBy: faker.datatype.number(),
    recommendationLevel: faker.datatype.number(),
    subtitle: faker.company.catchPhrase(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const GetStartedItemVMMockData: GetStartedItemVM = {
  featured: 123,
  id: 1,
  imageSmall: 'string',
  logo: 'string',
  orderBy: 123,
  recommendationLevel: 123,
  subtitle: 'Advanced Web Design',
  title: 'Advanced Web Design',
};
