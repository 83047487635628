import 'antd/dist/antd.css';

import {useCallback} from 'react';
import {i18n, k} from '@i18n/translate';
import {usePlaidLink} from 'react-plaid-link';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {PlaidConfigVM} from '@models/serverModels';

interface Props {
  plaidConfig: PlaidConfigVM;
  addCompanyBankAccountMutation: any;
}

const Plaid = ({plaidConfig, addCompanyBankAccountMutation}: Props) => {
  const onSuccess = useCallback((public_token: string, metadata: any) => {
    console.log('Plaid onSuccess', public_token, metadata);
    addCompanyBankAccountMutation.mutate({
      publicToken: public_token,
      accountId: metadata.accounts[0].id,
    });
  }, []);
  const onEvent = useCallback((eventName: string, metadata: any) => {
    console.log('Plaid onEvent', eventName, metadata);
  }, []);
  const onExit = useCallback((err: null | any, metadata: any) => {
    console.log('Plaid onExit', err, metadata);
  }, []);

  const {open, ready, error} = usePlaidLink({
    token: plaidConfig.token,
    onSuccess,
    onEvent,
    onExit,
    clientName: plaidConfig.clientName,
    env: plaidConfig.environment,
    product: plaidConfig.products,
  });

  return (
    <LearnInButton
      tag={ButtonTags.Primary}
      onClick={() => open()}
      disabled={!ready || error != null}>
      {i18n.t(k.ACCOUNT__ADD)}
    </LearnInButton>
  );
};
export default Plaid;
