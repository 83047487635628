import {CohortParticipantPreviewVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: CohortParticipantPreviewVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCohortParticipantPreviewVMMockData(
  args?: Partial<CohortParticipantPreviewVM>
): CohortParticipantPreviewVM {
  return {
    initials: faker.word.noun(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CohortParticipantPreviewVMMockData: CohortParticipantPreviewVM = {
  initials: 'string',
};
