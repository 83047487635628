import {Pagination} from 'antd';
import {i18n, k} from '@i18n/translate';

interface PaginatorProps {
  current: number;
  total: number;
  pageSize: number;
  onChange: (page: number) => void;
  showSizeChanger?: boolean;
}
export function Paginator({
  current,
  total,
  pageSize,
  onChange,
  ...rest
}: PaginatorProps) {
  return total > pageSize ? (
    <div>
      <Pagination
        {...rest}
        current={current}
        defaultCurrent={1}
        onChange={onChange}
        pageSize={pageSize}
        total={total}
        itemRender={(page, type, originalElement) => {
          let ariaLabel = 'pagination button';
          switch (type) {
            case 'prev':
              ariaLabel = i18n.t(k.GENERIC__PAGE__PREVIOUS);
              break;
            case 'next':
              ariaLabel = i18n.t(k.GENERIC__PAGE__NEXT);
              break;
            case 'jump-prev':
              ariaLabel = i18n.t(k.PAGINATION__JUMP_PREV);
              break;
            case 'jump-next':
              ariaLabel = i18n.t(k.PAGINATION__JUMP_NEXT);
              break;
            case 'page':
              ariaLabel = i18n.t(k.A11Y__PAGE__FORMAT, {number: page});
              break;
          }
          return <span aria-label={ariaLabel}>{originalElement}</span>;
        }}
      />
    </div>
  ) : null;
}
