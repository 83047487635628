import {AddSkillTagPayload} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {AddSkillTagPayloadSchema} from '../zodSchemas/AddSkillTagPayloadSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Skills/SkillsController.cs

/*
|--------------------------------------------------------------------------
|  create 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCreateSkillsMutation} from '@generated/hooks';
 *
 *  const create = useCreateSkillsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  create.mutate({
 *    payload: {
 *      ...AddSkillTagPayload
 *    },
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useCreateSkillsMutation = mutationHookFactory<
  AddSkillTagPayload, // payload
  null,
  boolean // return type
>('useCreateSkillsMutation', 'post', () => `skills/create`, {
  payloadSchema: AddSkillTagPayloadSchema,
});
