import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {
  CalendarOutlined,
  ExperimentOutlined,
  PaperClipOutlined,
  MessageOutlined,
} from '@ant-design/icons/lib/icons';
import {ContentType} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const DEFAULT_ICON_SIZE = 32;
export const Container = styled.div<{size?: number}>`
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-right: 16px;
  & :first-child {
    margin: 3px;
    font-size: 19px;
  }
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

export const ContentIcons = {
  [ContentType.Text]: PaperClipOutlined,
  [ContentType.Project]: ExperimentOutlined,
  [ContentType.Event]: CalendarOutlined,
  [ContentType.Discussion]: MessageOutlined,
};

export const ContentIconColors = {
  [ContentType.Text]: {
    color: COLORS.Green600,
    backgroundColor: COLORS.Green200,
  },
  [ContentType.Project]: {
    color: COLORS.Red500,
    backgroundColor: COLORS.Red50,
  },
  [ContentType.Event]: {
    color: COLORS.Blue800,
    backgroundColor: COLORS.Blue50,
  },
  [ContentType.Discussion]: {
    color: COLORS.Purple150,
    backgroundColor: COLORS.Purple350,
  },
};

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ContentTypeIconProps {
  contentType: ContentType;
  size?: number;
  noAlt?: boolean;
}

function ContentTypeIcon({
  contentType,
  size = DEFAULT_ICON_SIZE,
  noAlt = false,
}: ContentTypeIconProps) {
  const Icon = ContentIcons[contentType];
  const colors = ContentIconColors[contentType];
  if (!Icon) return null;

  const altKey = ((c) => {
    switch (c) {
      case ContentType.Event:
        return k.EVENT;
      case ContentType.Project:
        return k.PROJECT;
      case ContentType.Text:
        return k.GENERIC__TEXT;
      case ContentType.Discussion:
        return k.DISCUSSION;
      default:
        return k.CONTENT;
    }
  })(contentType);

  return (
    <Container style={{...colors}} size={size}>
      <Icon alt={noAlt ? '' : i18n.t(altKey)} />
    </Container>
  );
}

export default ContentTypeIcon;
