import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {
  LearnInButton,
  ToggleChevron,
} from '@components/reusable/Button/Button.style';
import {ContentType} from '@generated/enums';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {
  CalendarOutlined,
  MessageOutlined,
  EditOutlined,
  ExperimentOutlined,
  PlusOutlined,
  SnippetsOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
import {setShowContentUpsertModal} from '@components/admin/pages/academies/Levels/LevelsScreen';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useAuth} from '@utils/oidc-auth-wrapper';

const OptionButton = styled.button`
  display: flex;
  padding: 16px 24px;
  text-align: left;
  width: 100%;
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.Neutral100};
  }
  &:hover {
    background: ${COLORS.Neutral100};
  }
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

const OptionFooterContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-around;
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.Neutral100};
  }
  &:hover {
    background: ${COLORS.Neutral100};
  }
`;

const ButtonsContainer = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: ${COLORS.BoxShadowStandard};
  cursor: pointer;
  max-width: 470px;
  min-width: 470px;
  margin: auto;
`;

const LXPButtonsContainer = styled.div`
  max-width: 616px;
  min-width: 616px;
  margin: auto;
`;

const ICON_WRAPPER_SIZE = 65;
const IconWrapper = styled.div<{background: string}>`
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${ICON_WRAPPER_SIZE}px;
  height: ${ICON_WRAPPER_SIZE}px;
  background: ${({background}) => background};
`;
const MainTitle = styled.div`
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 25px;
`;

const Title = styled.div`
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  font-weight: 500;
`;
const Subtitle = styled.div`
  color: ${COLORS.Neutral600};
  font-size: 0.75rem;
`;

const OptionButtonContainer = styled.button`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 16px;
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
  &:not(:last-child) {
    border-bottom: 1px solid #f2f2f2;
  }
  &:hover {
    background: ${COLORS.Neutral100};
  }
`;
const LXP_ICON_WRAPPER_SIZE = 32;
const LXPIconWrapper = styled.div<{background: string}>`
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${LXP_ICON_WRAPPER_SIZE}px;
  height: ${LXP_ICON_WRAPPER_SIZE}px;
  background-color: ${({background}) => background};
`;

export interface LevelNoContentScreenProps {
  onClickAddMarketplaceProgram: () => void;
  onClickAddCustomProgram: () => void;
  setShowCustomContentModal: () => void;
  setShowContentUpsertModal: setShowContentUpsertModal;
  onClickAddDiscussionModal: () => void;
  onClickAddCatalogContent: () => void;
}

function LevelNoContentScreen({
  onClickAddDiscussionModal,
  onClickAddMarketplaceProgram,
  onClickAddCustomProgram,
  setShowCustomContentModal,
  setShowContentUpsertModal,
  onClickAddCatalogContent,
}: LevelNoContentScreenProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {user} = useAuth();
  const isLXPUser =
    !user?.isAcademiesIntegratedExperienceDisabled &&
    isFeatureFlagOn.CatalogContentAcademies;
  return (
    <>
      <MainTitle style={{marginTop: isLXPUser ? '0px' : '80px'}}>
        {i18n.t(k.LEVEL__GET_STARTED)}
      </MainTitle>
      {isLXPUser ? (
        <LXPButtonsContainer>
          <OptionButtonContainer
            style={{background: COLORS.Blue150}}
            onClick={onClickAddCatalogContent}>
            <div style={{display: 'flex'}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '24px',
                }}>
                <LXPIconWrapper background={COLORS.Blue250}>
                  <SnippetsOutlined
                    style={{color: COLORS.Blue350, fontSize: '20px'}}
                  />
                </LXPIconWrapper>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  color: COLORS.Blue350,
                }}>
                <Title>{i18n.t(k.CONTENT__CATALOG)}</Title>
                <Subtitle>{i18n.t(k.CONTENT__ADD_FROM_CATALOG)}</Subtitle>
              </div>
            </div>
            <ToggleChevron
              openDirection="left"
              closeDirection="left"
              open={false}
              style={{
                color: COLORS.Neutral800,
                fontSize: 24,
              }}
            />
          </OptionButtonContainer>

          <OptionButtonContainer
            style={{background: COLORS.Pink500}}
            onClick={() =>
              setShowContentUpsertModal(true, undefined, ContentType.Project)
            }>
            <div style={{display: 'flex'}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '24px',
                }}>
                <LXPIconWrapper background={COLORS.Pink400}>
                  <ExperimentOutlined
                    style={{color: COLORS.Pink100, fontSize: '20px'}}
                  />
                </LXPIconWrapper>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  color: COLORS.Pink100,
                }}>
                <Title>{i18n.t(k.PROJECT)}</Title>
                <Subtitle>
                  {i18n.t(k.GENERIC__ADD_PROJECT_OR_ASSIGNMENT)}
                </Subtitle>
              </div>
            </div>
            <ToggleChevron
              openDirection="left"
              closeDirection="left"
              open={false}
              style={{
                color: COLORS.Neutral800,
                fontSize: 24,
              }}
            />
          </OptionButtonContainer>

          <OptionButtonContainer
            style={{background: COLORS.Purple350}}
            onClick={() => onClickAddDiscussionModal()}>
            <div style={{display: 'flex'}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '24px',
                }}>
                <LXPIconWrapper background={COLORS.Purple200}>
                  <MessageOutlined
                    style={{color: COLORS.Purple150, fontSize: '20px'}}
                  />
                </LXPIconWrapper>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  color: COLORS.Purple150,
                }}>
                <Title>{i18n.t(k.DISCUSSION)}</Title>
                <Subtitle>{i18n.t(k.DISCUSSION__CREATE)}</Subtitle>
              </div>
            </div>
            <ToggleChevron
              openDirection="left"
              closeDirection="left"
              open={false}
              style={{
                color: COLORS.Neutral800,
                fontSize: 24,
              }}
            />
          </OptionButtonContainer>

          <OptionButtonContainer
            style={{background: COLORS.Neutral100, marginBottom: 0}}
            onClick={() => setShowCustomContentModal()}>
            <div style={{display: 'flex'}}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: '24px',
                }}>
                <LXPIconWrapper background={COLORS.Neutral200}>
                  <PaperClipOutlined
                    style={{color: COLORS.Neutral700, fontSize: '20px'}}
                  />
                </LXPIconWrapper>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  color: COLORS.Neutral700,
                }}>
                <Title>{i18n.t(k.CONTENT__BASIC)}</Title>
                <Subtitle>{i18n.t(k.CONTENT__ADD_CUSTOMIZED)}</Subtitle>
              </div>
            </div>
            <ToggleChevron
              openDirection="left"
              closeDirection="left"
              open={false}
              style={{
                color: COLORS.Neutral800,
                fontSize: 24,
              }}
            />
          </OptionButtonContainer>
        </LXPButtonsContainer>
      ) : (
        <ButtonsContainer>
          <OptionButton onClick={onClickAddMarketplaceProgram}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'left',
                marginRight: '24px',
              }}>
              <IconWrapper background={COLORS.Green200}>
                <PlusOutlined
                  aria-hidden="true"
                  style={{color: COLORS.Green600, fontSize: '1.75rem'}}
                />
              </IconWrapper>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <Title>{i18n.t(k.PROGRAM__MARKETPLACE__ADD__NON_TITLE)}</Title>
                <Subtitle>
                  {i18n.t(k.PROGRAM__ADD__EXTERNAL_FROM_LEARN_IN)}
                </Subtitle>
              </div>
            </div>
          </OptionButton>
          <OptionButton onClick={onClickAddCustomProgram}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'left',
                marginRight: '24px',
              }}>
              <IconWrapper background={COLORS.Yellow200}>
                <EditOutlined
                  aria-hidden="true"
                  style={{color: COLORS.Yellow500, fontSize: '1.75rem'}}
                />
              </IconWrapper>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <Title>{i18n.t(k.PROGRAM__CUSTOM__PLURAL)}</Title>
                <Subtitle>
                  {i18n.t(k.PROGRAM__CUSTOM__ADD_PROGRAMS_BY)}
                </Subtitle>
              </div>
            </div>
          </OptionButton>
          <OptionButton
            onClick={() =>
              setShowContentUpsertModal(true, undefined, ContentType.Project)
            }>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'left',
                marginRight: '24px',
              }}>
              <IconWrapper background={COLORS.Red200}>
                <ExperimentOutlined
                  aria-hidden="true"
                  style={{color: COLORS.Red500, fontSize: '1.75rem'}}
                />
              </IconWrapper>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <Title>{i18n.t(k.PROJECT)}</Title>
                <Subtitle>
                  {i18n.t(k.GENERIC__ADD_PROJECT_OR_ASSIGNMENT)}
                </Subtitle>
              </div>
            </div>
          </OptionButton>

          <OptionButton
            onClick={() =>
              setShowContentUpsertModal(true, undefined, ContentType.Event)
            }>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'left',
                marginRight: '24px',
              }}>
              <IconWrapper background={COLORS.Blue50}>
                <CalendarOutlined
                  aria-hidden="true"
                  style={{color: COLORS.Blue800, fontSize: '1.75rem'}}
                />
              </IconWrapper>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <Title>{i18n.t(k.EVENT)}</Title>
                <Subtitle>{i18n.t(k.EVENT__SCHEDULE_LIVE)}</Subtitle>
              </div>
            </div>
          </OptionButton>

          <OptionButton onClick={() => onClickAddDiscussionModal()}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'left',
                marginRight: '24px',
              }}>
              <IconWrapper background={COLORS.Purple350}>
                <MessageOutlined
                  aria-hidden="true"
                  style={{color: COLORS.Purple150, fontSize: '1.75rem'}}
                />
              </IconWrapper>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <Title>{i18n.t(k.DISCUSSION)}</Title>
                <Subtitle>{i18n.t(k.DISCUSSION__CREATE)}</Subtitle>
              </div>
            </div>
          </OptionButton>

          <OptionFooterContainer>
            <div style={{display: 'flex'}}>
              {i18n.t(k.LEVEL__FOOTER_PHRASE)}{' '}
              <LearnInButton
                tag={ButtonTags.Link}
                style={{
                  padding: '0 0 0 5px',
                  fontSize: '0.875rem',
                  color: COLORS.Blue950,
                  textDecoration: 'underline',
                }}
                onClick={setShowCustomContentModal}>
                {i18n.t(k.LEVEL__FOOTER_PHRASE__CREATE_CONTENT)}
              </LearnInButton>
            </div>
          </OptionFooterContainer>
        </ButtonsContainer>
      )}
    </>
  );
}

export default LevelNoContentScreen;
