export function EnumToListItem<EnumType>(e: EnumType) {
  return Object.keys(e)
    .filter((key) => Number.isNaN(Number(key))) // Remove number keys
    .map((type) => {
      return {
        label: type,
        value: e[type],
      };
    });
}
