import LoadingSpinner from '@blocks/LoadingSpinner';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import LearnInTable from '@components/reusable/LearnInTable';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  RequestApprovalType,
} from '@generated/enums';
import {UserRequestApproval} from '@generated/interfaces';
import {useUpdateCostCenterAsAdmin} from '@hooks/apiEndpoints/adminApprovals/mutations';
import {i18n, k} from '@i18n/translate';
import {CostCenterOverridePayload} from '@models/serverModels';
import {getUserRequestType} from '@utils/getUserRequestType';
import {Input, Tabs} from 'antd';
import debounce from 'lodash/debounce';
import React, {useCallback, useEffect, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  adminScreenApprovalStatusByTab,
  approvalStatusByTab,
  COLUMN_KEY_TO_SORTABLE,
  getEmptyStateProps,
  STATUS_TAB,
  statusQueryParamByTab,
} from '.';
import AdminPreApprovalDrawer from '../AdminPreApprovalDrawer/AdminPreApprovalDrawer';
import {useColumns} from './AdminApprovalsList.columns';
import NoDataText from '@blocks/NoDataText';
import {
  CenterView,
  StyledCol,
  StyledLearnInContainer as LearnInContainer,
} from './AdminApprovalsList.styled';
import {useAdminApprovalsUserRequestApprovalsQuery} from '@generated/hooks';
import {Helmet} from 'react-helmet-async';

interface AdminApprovalsListProps {
  title: string;
  approvalType: RequestApprovalType;
}

const AdminApprovalsList: React.FC<AdminApprovalsListProps> = ({
  title,
  approvalType,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openDrawerId = searchParams.get('drawer');
  const [selectedApproval, setSelectedApproval] =
    useState<UserRequestApproval>(null);
  const [columnSorting, setColumnSorting] = useState<{
    column: string;
    order: string;
  }>({column: 'requestedDate', order: 'descend'});

  const statusTab = searchParams.get('status');

  const [isOverride, setIsOverride] = useState<boolean>(false);

  const toggleDrawer = (drawerId: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (drawerId) {
      newSearchParams.set('drawer', drawerId);
    } else {
      newSearchParams.delete('drawer');
    }

    setSearchParams(newSearchParams);
  };

  const [searchTerm, setSearchTerm] = useState<string>('');
  const columns = useColumns(
    {approvalType, status: approvalStatusByTab[statusTab], columnSorting},
    {
      onViewDetails: (row: UserRequestApproval) => {
        setSelectedApproval(row);
        setIsOverride(true);
        toggleDrawer(row.id.toString());
      },
      onReviewApproval: (row: UserRequestApproval) => {
        setSelectedApproval(row);
        setIsOverride(true);
        toggleDrawer(row.id.toString());
      },
      onUpdateCostCenter: handleUpdateCostCenter,
    }
  );

  useEffect(
    function goToDefaultTabIfUnset() {
      if (!statusTab) {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set('status', STATUS_TAB.REQUESTS);
        setSearchParams(newSearchParams);
      }
    },
    [statusTab]
  );

  const {
    data,
    isLoading,
    isSuccess,
    invalidateExact: invalidateUserRequestApprovals,
  } = useAdminApprovalsUserRequestApprovalsQuery(
    {
      queryParams: {
        sortColumn: COLUMN_KEY_TO_SORTABLE[columnSorting.column],
        isAscending: columnSorting.order === 'ascend',
        requestType: approvalType,
        searchTerm,
        status: statusQueryParamByTab[statusTab],
        isPendingManagerRequest: statusTab === STATUS_TAB.PENDING_REVIEW,
        pageNumber: searchParams.has('page')
          ? parseInt(searchParams.get('page'))
          : 1,
      },
    },
    {
      enabled: !!statusTab,
      refetchOnWindowFocus: false,
    }
  );

  const updateCostCenterAsAdminMutation = useUpdateCostCenterAsAdmin({
    onSuccess: () => {
      invalidateUserRequestApprovals();
    },
  });

  function handleUpdateCostCenter(
    costCenter: string,
    userRequestApprovalId: number,
    onSuccess?: () => void,
    onError?: () => void
  ) {
    if (onSuccess) onSuccess();
    const payload: CostCenterOverridePayload = {
      costCenter,
      userRequestApprovalId,
    };
    updateCostCenterAsAdminMutation.mutate(payload, {
      onError, // rollback to original if update failed
    });
  }

  const debouncedlyUpdateSearchTerm = useCallback(
    debounce((searchTerm: string) => {
      setSearchTerm(searchTerm);
    }, 1000),
    []
  );

  const handleSearch = (ev: React.ChangeEvent<HTMLInputElement>) => {
    debouncedlyUpdateSearchTerm(ev.target.value);
  };

  const handleClickRow = (record: UserRequestApproval) => {
    if (approvalStatusByTab[statusTab] === ApprovalStatus.Requested) {
      setSelectedApproval(record);
      setIsOverride(false);
      toggleDrawer(record.id.toString());
    }
  };

  const handleCloseDrawer = () => {
    toggleDrawer(null);
    setSelectedApproval(null);
  };

  const handleDrawerOk = () => {
    invalidateUserRequestApprovals();
    handleCloseDrawer();
  };

  const handleTableChanges = (
    pagination: {
      current: number;
      pageSize: number;
      total: number;
    },
    _,
    sorter: {order: 'ascend' | 'descend'; columnKey: string},
    {action}
  ) => {
    if (action === 'paginate') {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('page', pagination.current.toString());
      setSearchParams(newSearchParams);
    } else if (action === 'sort') {
      setColumnSorting({
        column: sorter.columnKey,
        order: sorter.order,
      });
    }
  };

  const handleChangeTab = (tab: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('status', tab);
    setSearchParams(newSearchParams);
  };

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div>
        <AdminPreApprovalDrawer
          approvalItemType={
            approvalType === RequestApprovalType.Program
              ? ApprovalsDiscriminator.Program
              : ApprovalsDiscriminator.LearningResource
          }
          approvalId={parseInt(searchParams.get('drawer'))}
          approvalType={selectedApproval?.approvalType}
          showPreApprovalDrawer={
            parseInt(openDrawerId) === parseInt(searchParams.get('drawer'))
          }
          isReadonly={
            approvalStatusByTab[statusTab] !== ApprovalStatus.Requested &&
            approvalStatusByTab[statusTab] !== ApprovalStatus.Pending
          }
          isOverride={isOverride}
          activeTab={adminScreenApprovalStatusByTab[statusTab]}
          updateCostCenter={handleUpdateCostCenter}
          selectedCostCenter={selectedApproval?.costCenter}
          onOk={handleDrawerOk}
          onCancel={handleCloseDrawer}
          licenseId={
            selectedApproval &&
            ('licenseId' in selectedApproval
              ? selectedApproval?.licenseId
              : null)
          }
          userRequestType={
            selectedApproval &&
            ('userRequestType' in selectedApproval
              ? getUserRequestType(selectedApproval?.userRequestType)
              : null)
          }
        />
      </div>
      <PageTitleBar fullWidth={true} title={title} />
      <div>
        <StyledCol xs={24}>
          <LearnInTabs
            style={{width: '100%'}}
            defaultActiveKey={STATUS_TAB.REQUESTS}
            activeKey={searchParams.get('status')}
            tabBarGutter={50}
            onChange={handleChangeTab}>
            <Tabs.TabPane
              tab={i18n.t(k.REQUEST__PLURAL)}
              key={STATUS_TAB.REQUESTS}
            />
            <Tabs.TabPane
              tab={i18n.t(k.STATUS__APPROVED)}
              key={STATUS_TAB.APPROVED}
            />
            <Tabs.TabPane
              tab={i18n.t(k.STATUS__DECLINED)}
              key={STATUS_TAB.DECLINED}
            />
            <Tabs.TabPane
              tab={i18n.t(k.STATUS__PENDING_MANAGER_REVIEW)}
              key={STATUS_TAB.PENDING_REVIEW}
            />
          </LearnInTabs>
        </StyledCol>
        <Input.Search
          allowClear
          onChange={handleSearch}
          placeholder={i18n.t(k.APPROVAL__PLURAL__SEARCH)}
          style={{width: '400px', padding: '10px 32px'}}
        />
        <LearnInContainer tag={ContainerTags.TableBottomPadding}>
          {isLoading ? (
            <CenterView>
              <LoadingSpinner />
            </CenterView>
          ) : null}

          {isSuccess && data?.totalResults === 0 ? (
            <NoDataText
              {...getEmptyStateProps(
                approvalStatusByTab[statusTab],
                !!searchTerm
              )}
            />
          ) : null}

          {isSuccess && data?.totalResults > 0 ? (
            <LearnInTable
              dataSource={data?.requests}
              scroll={{x: 'max-content'}}
              columns={columns}
              onClickRow={handleClickRow}
              pagination={true}
              pageSize={data?.resultsPerPage}
              totalPages={data?.totalPages}
              currentPage={data?.currentPage}
              onChange={handleTableChanges}
            />
          ) : null}
        </LearnInContainer>
      </div>
    </div>
  );
};

export default AdminApprovalsList;
