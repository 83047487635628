import styled from 'styled-components';

export const Container = styled.div``;
export const LoadingScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20vh;
`;
