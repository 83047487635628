export enum RequireApprovalTypeStatus {
  PREAPPROVAL_MANAGER = '0',
  PREAPPROVAL_ADMIN = '1',
  REDEMPTION_MANAGER = '2',
  REDEMPTION_ADMIN = '3',
}

export enum FinanceIncentiveType {
  Loan,
  ISA,
  Scholarship,
  Discount,
  Prepayment,
  TuitionReimbursement,
  CompletionBonus,
  Other,
  ExternalReimbursement,
  DirectBilling,
}

export enum Permissions {
  RequiresApproval = '0',
  RequestResources = '1',
  AllowRemoval = '2',
}

export interface ProgramType {
  id: number;
  description: string;
}

export type ProgramTypesVM = ProgramType[];
