import styled from 'styled-components';
import {BodySpan} from '@components/reusable/Typography';
import {COLORS} from '@utils/constants';

export const Container = styled.div`
  align-items: center;
  background: white;
  border-radius: 9px;
  box-shadow: ${COLORS.BoxShadowStandard};
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 300px;
`;

export const FullName = styled.span`
  color: ${COLORS.Neutral950};
  font-size: 1rem;
  margin-top: 16px;
  margin-bottom: 4px;
  font-weight: 500;
`;

export const Role = styled(BodySpan)`
  line-height: 1rem;
`;

export const Initials = styled.span`
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
`;
