import styled from 'styled-components';

export const Container = styled.div``;

export const ParticipantNameCell = styled.div`
  display: flex;
  align-items: center;
`;

export const ParticipantName = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
