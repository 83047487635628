import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Card} from 'antd';
import {COLORS} from '@utils/constants';
import ContextMenuButton from '@blocks/ContextMenu';
import ProviderLogo from '@blocks/ProviderLogo';
import {LicenseCardVM} from '@models/serverModels';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const LicenseName = styled.h4`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: 0 24px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StatsTable = styled.table`
  border-top: 1px solid ${COLORS.Neutral200};
  color: ${COLORS.Neutral900};
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0 auto;
  width: calc(100% - 48px);

  th {
    font-weight: 400;
    text-align: left;
    padding: 11px 0;
  }

  td {
    font-weight: 500;
    padding: 10px 0 6px 40px;
    text-align: right;
    vertical-align: top;
  }

  tr:first-child {
    th,
    td {
      padding-top: 16px;
    }
  }

  tr:last-child {
    th,
    td {
      padding-bottom: 6px;
    }
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface LicenseCardProps {
  license: LicenseCardVM;
  handleUpdateLicenseClick: (id: number) => void;
  handleDeleteLicenseClick: (id: number) => void;
}

function LicenseCard({
  license,
  handleUpdateLicenseClick,
  handleDeleteLicenseClick,
}: LicenseCardProps) {
  return (
    <Card
      role="article"
      aria-label={`${i18n.t(k.LICENSE__PROVIDER__COLON)} ${
        license?.providerName
      }`}
      bordered={false}
      bodyStyle={{padding: '20px 0px 15px 0px'}}
      style={{borderRadius: '12px', padding: 0, width: '354px'}}>
      <div style={{display: 'flex', padding: '0 20px 20px'}}>
        <div style={{flexGrow: 1}}>
          <ProviderLogo
            provider={license?.providerName}
            providerLogo={license?.providerLogo}
            size={56}
          />
        </div>
        <ContextMenuButton
          title={license?.providerName}
          size="large"
          popupContainerId="main-content"
          itemId={license.id}
          overlayPlacement="bottomRight"
          dataCy="licenseCardContextMenu"
          menuItems={[
            {
              label: i18n.t(k.LICENSE__EDIT),
              noBottomBorder: true,
              onClick: () => {
                handleUpdateLicenseClick(license.id);
              },
              dataCy: 'editLicenseButton',
            },
            {
              label: i18n.t(k.LICENSE__DELETE),
              noBottomBorder: true,
              onClick: () => {
                handleDeleteLicenseClick(license.id);
              },
            },
          ]}
        />
      </div>
      <LicenseName>{license?.providerName}</LicenseName>
      <StatsTable>
        <tbody>
          <tr>
            <th>{i18n.t(k.EMPLOYEE__ELIGIBLE)}</th>
            <td>{license.eligibleEmployees}</td>
          </tr>
          <tr>
            <th>{i18n.t(k.LICENSE__APPROVED_TOTAL)}</th>
            <td>{license.totalApprovedLicenses}</td>
          </tr>
          <tr>
            <th>{i18n.t(k.LICENSE__APPROVED_TOTAL_30_DAYS)}</th>
            <td>{license.approvedInPast30Days}</td>
          </tr>
        </tbody>
      </StatsTable>
    </Card>
  );
}

export default LicenseCard;
