import DeleteCustomProgramSectionModal from './DeleteCustomProgramSectionModal';
import {notify} from '@components/user/notifications';
import {
  useCustomProgramSectionsQuery,
  useDeleteCustomProgramSectionMutation,
} from '@generated/hooks';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface DeleteCustomProgramSectionModalContainerProps {
  visible: boolean;
  onCancel: () => void;
  customProgramId: number;
  sectionId: number;
  doesSectionHaveNoContent: boolean;
  setSelectedSectionId: (id: number) => void;
  selectedSectionId: number;
}

function DeleteCustomProgramSectionModalContainer({
  visible,
  onCancel,
  customProgramId,
  sectionId,
  doesSectionHaveNoContent,
  setSelectedSectionId,
  selectedSectionId,
}: DeleteCustomProgramSectionModalContainerProps) {
  const {
    data: customProgramSections,
    invalidateExact: invalidateCustomProgramSections,
  } = useCustomProgramSectionsQuery({
    customProgramId,
  });

  const deleteCustomProgramSection = useDeleteCustomProgramSectionMutation({
    onSuccess: async () => {
      // If the currently selected section is deleted
      if (sectionId === selectedSectionId) {
        // find the previous section and select that
        const findSectionIdx = customProgramSections?.findIndex(
          ({id}) => id === sectionId
        );
        if (findSectionIdx > 0) {
          setSelectedSectionId(customProgramSections?.[findSectionIdx - 1]?.id);
        } else {
          // If the last section was deleted, unselect the selected id,
          // The new section will be reselected automatically
          setSelectedSectionId(undefined);
        }
      }
      await invalidateCustomProgramSections();
      notify.deleteCustomProgramSectionSuccess();
      onCancel();
    },
  });

  const handleOk = (moveContentToSectionId?: number) => {
    deleteCustomProgramSection.mutate({
      payload: {
        moveContentToSectionId,
      },
      pathVars: {
        sectionId,
      },
    });
  };

  return (
    <DeleteCustomProgramSectionModal
      visible={visible}
      onCancel={onCancel}
      onOk={handleOk}
      moveContentSectionOptions={customProgramSections
        ?.filter(({id}) => id !== sectionId)
        .map(({id, title}) => ({id, title}))}
      doesSectionHaveNoContent={doesSectionHaveNoContent}
    />
  );
}

export default DeleteCustomProgramSectionModalContainer;
