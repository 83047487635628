import '../../home/UserHome.scss';
import {i18n, k} from '@i18n/translate';
import {getPlaceholderImgSrc} from '@utils/image-utils';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import ContextMenuButton from '@blocks/ContextMenu';
import {Breakpoints} from '@utils/responsiveUtils';

export const ACADEMY_CARD_HEIGHT = '264px';

const AcademyCard = styled.article`
  height: ${ACADEMY_CARD_HEIGHT};
  left: 0;
  top: 0;
  background: ${COLORS.White};
  border-radius: 8px;
  display: flex;
  box-shadow: ${COLORS.BoxShadowStandard};
`;

const AcademyImg = styled.img`
  width: 30%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const AcademyCardContent = styled.div`
  width: 100%;
  margin: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Title = styled.div`
  font-family: Roboto;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0;
  text-align: left;
  color: ${COLORS.Neutral900};
`;

const Description = styled.div`
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  letter-spacing: 0;
  text-align: left;
  color: ${COLORS.Neutral600};
  margin-top: 8px;
  height: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  @media (max-width: ${Breakpoints.Tablet}px) {
    height: 150px;
    overflow: scroll;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function AcademiesCarouselCard({
  id,
  backgroundImage,
  description,
  title,
  onClickGoToAcademy,
  onClickRemoveAcademy,
}: {
  id: number;
  backgroundImage: string;
  description: string;
  title: string;
  onClickGoToAcademy: () => void;
  onClickRemoveAcademy: () => void;
}) {
  return (
    <AcademyCard aria-label={`${i18n.t(k.ACADEMY)}: ${title}`}>
      <AcademyImg
        alt=""
        src={backgroundImage || getPlaceholderImgSrc(title?.length)}
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = getPlaceholderImgSrc(title?.length);
        }}
      />

      <AcademyCardContent>
        <div>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </div>
        <Footer>
          <LearnInButton
            aria-label={i18n.t(k.A11Y__GO_TO_ITEM__FORMAT, {item: title})}
            tag={ButtonTags.Secondary}
            onClick={onClickGoToAcademy}>
            {i18n.t(k.ACADEMY__GO_TO)}
          </LearnInButton>
          <ContextMenuButton
            title={title}
            popupContainerId="main-content"
            itemId={id}
            size="middle"
            menuItems={[
              {
                label: i18n.t(k.ACADEMY__REMOVE),
                onClick: onClickRemoveAcademy,
              },
            ]}
          />
        </Footer>
      </AcademyCardContent>
    </AcademyCard>
  );
}
