import {useRef} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import {Button, Checkbox, Col, Form, Radio, Row} from 'antd';
import {BulbOutlined} from '@ant-design/icons';
import 'react-quill/dist/quill.snow.css';
import {
  FinanceIncentiveType,
  Permissions,
  RequireApprovalTypeStatus,
} from '../store/interfaces';
import {IncentiveAvailability} from '@components/user/store/interface';
import {
  BalanceRecurType,
  CurrencyCode,
  FinanceAmountType,
  FinanceRecurrence,
  LearningResourceType,
} from '@generated/enums';
import {
  LearnInInput,
  LearnInMoneyInput,
  LearnInNumberInput,
} from '@components/reusable/Input/Input.style';
import {COLORS} from '@utils/constants';
import {InputTags} from '@components/reusable/Input/InputEnums';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {EnumToListItem} from '@utils/enumUtils';
import {FormInstance} from 'antd/lib/form';
import {ErrorMessage, LearnInSelect} from '@components/reusable/LearnInForm';
import {UnprivilegedEditor} from './AddPersonalLearningBudgetIncentiveDrawer.container';
import {formatPastingOptions} from '@utils/reactQuillUtils';
import {LearnInSelectOption} from '@components/reusable/Select/Select.style';
import {getMonths} from '@utils/timeUtils';
import LearnInQuill from '@components/reusable/LearnInQuill';

/*
|--------------------------------------------------------------------------
| Constants & Configuration
|--------------------------------------------------------------------------
*/

const ResourceTypesListItem = EnumToListItem(LearningResourceType);

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-wrapper-disabled.ant-radio-wrapper-in-form-item > span {
    color: ${COLORS.Neutral600};
  }
`;

const StyledCheckboxGroup = styled(Checkbox.Group)`
  &.ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      margin-left: 0px;
    }
  }

  .ant-checkbox-wrapper-disabled.ant-checkbox-wrapper-in-form-item > span {
    color: ${COLORS.Neutral600};
  }
`;

const StyledContrastCheckboxGroup = styled(Checkbox.Group)`
  .ant-checkbox-wrapper-disabled.ant-checkbox-wrapper-in-form-item > span {
    color: ${COLORS.Neutral600};
  }
`;

const StyledHelperText = styled.span`
  display: block;
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;

const StyledProrateSelect = styled(LearnInSelect)`
  font-size: 1rem;
  width: 265px !important;
`;

const SpacedCheckboxRow = styled.div`
  display: flex;
  justify-content: start;
  gap: 10px;
  margin-left: 4px;
`;

const NoPaddingFormItem = styled(Form.Item)`
  &.ant-form-item {
    margin-bottom: 0px;
  }
`;

const InfoBanner = styled.div`
  background: ${COLORS.Yellow50};
  border-radius: 4px;
  color: ${COLORS.Neutral900};
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 30px;
  padding: 18px;
`;

const StyledForm = styled(Form)`
  .required-field,
  .ant-form-item-label > label,
  .ant-radio-wrapper,
  .ant-checkbox-wrapper,
  .ant-col,
  .ant-row label {
    font-size: 1rem !important;
    line-height: 1.25rem !important;
  }

  .ant-form-item-label > label,
  .ant-row > label,
  .ant-col > label,
  .required-field {
    font-weight: 500;
    margin-bottom: 2px;
  }

  .ant-radio-wrapper {
    padding-left: 0;
  }

  .ant-form-item-label {
    padding-bottom: 0 !important;
    font-size: 1rem !important;
  }

  .ant-form-item {
    margin-bottom: 22px;
  }

  .ant-select-single {
    .ant-select-selector {
      .ant-select-selection-placeholder {
        color: ${COLORS.Neutral500};
        font-size: 1rem;
        line-height: 40px;
      }
    }
  }

  .ql-editor {
    background: ${COLORS.White};
  }
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

export enum PlbFormFields {
  APPROVAL_GUIDELINE_FIELD = 'approvalGuidelines',
  APPROVALS_DETAIL_FIELDS = 'approvalDetails',
  AVAILABILITY_FIELD = 'availability',
  BALANCE_RECUR_TYPE_FIELD = 'balanceRenewType',
  EXTERNAL_REIMBURSEMENT_EXPENSE_REPORT_URL = 'externalReimbursementApplicationUrl',
  EXPIRATION_IN_DAYS = 'expirationInDays',
  MONEY_FIELD = 'money',
  MONEY_USE_FIELD = 'moneyUse',
  PERMISSIONS_FIELD = 'permissions',
  PROGRAM_TYPE_FIELD = 'programType',
  RECURRING_FIELD = 'singleOrRecurring',
  RECURRING_FREQUENCY_FIELD = 'recurrence',
  REDEMPTION_TYPE_FIELD = 'redemptionType',
  RESOURCES_FIELD = 'allowedLearningResources',
  SHORT_DESCRIPTION_FIELD = 'shortDescription',
  TITLE_FIELD = 'title',
  PRE_APPROVAL_DETAILS = 'preApprovalDetails',
  PRORATED_FIELD = 'isProratingEnabled',
  PRORATED_RENEWAL_MONTH = 'renewalMonth',
}

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/

const radioStyle = {
  display: 'block',
};

/*
|--------------------------------------------------------------------------
| Drawer Component
|--------------------------------------------------------------------------
*/

interface Props {
  approvalGuidelines: string;
  shortDescription: string;
  defaultApprovalExpirationInDays: number | undefined;
  approvalGuidelinesErrorMessage: string | undefined;
  shortDescriptionErrorMessage: string | undefined;
  currency: CurrencyCode;
  details: string;
  disableEditFrequency: boolean;
  disableEditing: boolean;
  form: FormInstance;
  disableFinanceRecurrenceMonthlyRadioOption: boolean;
  disableFinanceRecurrenceYearlyRadioOption: boolean;
  isDirectBillingOn: boolean;
  isProratingOn: boolean;
  isSubmitLoading: boolean;
  isSunsetMonthlyRenewalOn: boolean;
  isHideEveryYearDirectBillingOn: boolean;
  isHideEveryYearReimbursement: boolean;
  isHideRenewalDate: boolean;
  isHideRollOver: boolean;
  onChangeShortDescription: (
    _: any,
    __: any,
    ___: any,
    editor: UnprivilegedEditor
  ) => void;
  onChangeApprovalGuidelines: (
    _: any,
    __: any,
    ___: any,
    editor: UnprivilegedEditor
  ) => void;
  onChangeDetails: (details: string) => void;
  onChangeMoneyUseRadioGroup: (value: FinanceAmountType) => void;
  onChangeRecurrenceFrequencyRadioGroup: (value: FinanceRecurrence) => void;
  onChangeRedemptionTypeRadioGroup: (value: any) => void;
  onClickAllowResources: () => void;
  onClickDelete: () => void;
  onClickRequireApproval: () => void;
  onClickAllowRemoval: () => void;
  onClickSubmit: () => void;
  onClose: () => void;
  requiresApprovalCheckBoxLabel: string;
  showBalanceRecurTypeRadioGroup: boolean;
  showDeleteButton: boolean;
  showExternalReimbursementRadioOption: boolean;
  showExternalReimbursementUrlInput: boolean;
  showPermissionsFields: boolean;
  showRequireApprovalOptions: boolean;
  showRequirePreApprovalFromOptions: boolean;
  showRequireRedemptionApprovalFromOptions: boolean;
  showReimbursementRequireRedemptionApprovalFromOptions: boolean;
  showDirectBillingOption: boolean;
  showResourceOptions: boolean;
  showSpecificAmountInput: boolean;
  showExpirationOption: boolean;
  showHowOftenInput: boolean;
  showProratingInput: boolean;
  title: string;
}

// https://linear.app/learnin/issue/LI-1673/consolidate-add-money-incentive-and-add-personal-learning-budget-where
export default function AddPersonalLearningBudgetIncentiveDrawer({
  approvalGuidelines,
  shortDescription,
  approvalGuidelinesErrorMessage,
  shortDescriptionErrorMessage,
  defaultApprovalExpirationInDays,
  currency,
  details,
  disableEditFrequency,
  disableEditing,
  form,
  disableFinanceRecurrenceMonthlyRadioOption,
  disableFinanceRecurrenceYearlyRadioOption,
  isDirectBillingOn,
  isProratingOn,
  isSubmitLoading,
  isSunsetMonthlyRenewalOn,
  onChangeShortDescription,
  onChangeApprovalGuidelines,
  onChangeDetails,
  onChangeMoneyUseRadioGroup,
  onChangeRecurrenceFrequencyRadioGroup,
  onChangeRedemptionTypeRadioGroup,
  onClickAllowResources,
  onClickDelete,
  onClickAllowRemoval,
  onClickRequireApproval,
  onClickSubmit,
  onClose,
  requiresApprovalCheckBoxLabel,
  showBalanceRecurTypeRadioGroup,
  showDeleteButton,
  showExternalReimbursementRadioOption,
  showExternalReimbursementUrlInput,
  showDirectBillingOption,
  showPermissionsFields,
  showRequireApprovalOptions,
  showRequirePreApprovalFromOptions,
  showRequireRedemptionApprovalFromOptions,
  showResourceOptions,
  showSpecificAmountInput,
  showExpirationOption,
  title,
  showReimbursementRequireRedemptionApprovalFromOptions,
  showHowOftenInput,
  showProratingInput,
  isHideEveryYearDirectBillingOn,
  isHideEveryYearReimbursement,
  isHideRenewalDate,
  isHideRollOver,
}: Props) {
  const descriptionFieldRef = useRef<ReactQuill>();
  const detailsFieldRef = useRef<ReactQuill>();
  const approvalGuidelinesFieldRef = useRef<ReactQuill>();
  return (
    <LearnInDrawer
      responsiveSize={'100%'}
      placement="bottom"
      title={title}
      visible
      footer={[
        <Row key="submit" justify="end">
          {showDeleteButton && (
            <Button
              onClick={onClickDelete}
              className="white-btn drawer-footer-delete-btn"
              shape={'round'}>
              <p id="delete-personal-learning-budget-button">
                {i18n.t(k.CTA__DELETE)}
              </p>
            </Button>
          )}
          <Button
            className="black-btn drawer-footer-btn"
            loading={isSubmitLoading}
            onClick={onClickSubmit}
            id="submit-upsert-personal-learning-budget-button"
            shape="round"
            type="primary">
            {i18n.t(k.CTA__SUBMIT)}
          </Button>
        </Row>,
      ]}
      onClose={onClose}>
      <StyledForm
        className="form-drawers"
        form={form}
        key={'plb'}
        layout="vertical">
        <div className="form-content">
          <InfoBanner>
            <BulbOutlined
              aria-hidden="true"
              style={{
                color: COLORS.Yellow500,
                marginRight: '16px',
                fontSize: '1.4em',
              }}
            />
            {i18n.t(k.PLB__ONLY_ONE)}
          </InfoBanner>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              <Form.Item
                className="lb-application-drawer"
                label={i18n.t(k.GENERIC__TITLE)}
                name={PlbFormFields.TITLE_FIELD}
                rules={[
                  {
                    required: true,
                    message: i18n.t(
                      k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                      {
                        fieldName: i18n.t(k.GENERIC__TITLE).toLocaleLowerCase(),
                      }
                    ),
                  },
                ]}>
                <LearnInInput
                  tag={InputTags.PRIMARY}
                  placeholder={i18n.t(k.VALIDATION__MISSING_FIELD__FORMAT, {
                    fieldName: i18n.t(k.GENERIC__TITLE),
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{paddingBottom: '25px'}}>
            <Col xs={24}>
              <Row style={{paddingBottom: '8px'}}>
                <Col>
                  {/* The onClick supports ReactQuill focus handling. */}
                  {/* eslint-disable-next-line */}
                  <label
                    htmlFor="description-field"
                    onClick={() => descriptionFieldRef.current?.focus()}
                    className="ant-form-item-required required-field">
                    {i18n.t(k.GENERIC__DESCRIPTION__SHORT)}
                  </label>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <LearnInQuill
                    id="description-field"
                    formats={formatPastingOptions}
                    value={shortDescription}
                    theme="snow"
                    onChange={onChangeShortDescription}
                    ref={descriptionFieldRef}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {!!shortDescriptionErrorMessage && (
                    <ErrorMessage message={shortDescriptionErrorMessage} />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Col xs={24} sm={12}>
            <Form.Item
              className="lb-application-drawer"
              name={PlbFormFields.REDEMPTION_TYPE_FIELD}
              label={i18n.t(k.INCENTIVE__HOW_REDEEM)}
              valuePropName={'value'}
              rules={[
                {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
              ]}>
              <StyledRadioGroup
                disabled={disableEditing}
                onChange={({target: {value}}) =>
                  onChangeRedemptionTypeRadioGroup(value)
                }>
                <Radio
                  style={radioStyle}
                  value={FinanceIncentiveType.TuitionReimbursement}>
                  {i18n.t(k.REIMBURSEMENT__COMPANY)}
                </Radio>
                {showExternalReimbursementRadioOption && (
                  <Radio
                    style={radioStyle}
                    value={FinanceIncentiveType.ExternalReimbursement}>
                    {i18n.t(k.REIMBURSEMENT__EXTERNAL)}
                  </Radio>
                )}
                {showExternalReimbursementUrlInput && (
                  <div>
                    <Form.Item
                      style={{marginBottom: '0px', marginLeft: '24px'}}
                      rules={[
                        {
                          required: true,
                          message: i18n.t(k.VALIDATION__REQUIRED),
                        },
                      ]}
                      name={
                        PlbFormFields.EXTERNAL_REIMBURSEMENT_EXPENSE_REPORT_URL
                      }>
                      <LearnInInput
                        style={{width: '400px'}}
                        placeholder={i18n.t(
                          k.INCENTIVE__LINK_TO_EXPENSE_REPORT
                        )}
                      />
                    </Form.Item>
                  </div>
                )}
                <Radio
                  style={radioStyle}
                  value={FinanceIncentiveType.Prepayment}
                  data-testid="add-plb-vc-option">
                  {i18n.t(k.CARD__VIRTUAL_CARD)}
                </Radio>
                {isDirectBillingOn && (
                  <Radio
                    style={radioStyle}
                    value={FinanceIncentiveType.DirectBilling}>
                    {i18n.t(k.DIRECT_BILLING)}
                  </Radio>
                )}
              </StyledRadioGroup>
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name={PlbFormFields.MONEY_USE_FIELD}
              id="moneyUse"
              label={i18n.t(k.INCENTIVE__HOW_MUCH_USE)}
              rules={[
                {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
              ]}>
              <StyledRadioGroup
                disabled={disableEditing}
                onChange={(e) => onChangeMoneyUseRadioGroup(e.target.value)}>
                <Radio style={radioStyle} value={FinanceAmountType.UpTo}>
                  {i18n.t(k.MONEY_AMOUNT_SPECIFIC)}
                </Radio>
                {showSpecificAmountInput && (
                  <Form.Item
                    style={{margin: '8px 0px 8px 24px'}}
                    name={PlbFormFields.MONEY_FIELD}
                    className="lb-application-drawer"
                    rules={[
                      {
                        required: true,
                        message: i18n.t(k.VALIDATION__AMOUNT),
                      },
                    ]}>
                    <LearnInMoneyInput
                      currency={currency}
                      disabled={disableEditing}
                      max={100000}
                      min={1}
                      style={{width: '150px'}}
                    />
                  </Form.Item>
                )}
                {showHowOftenInput && (
                  <Form.Item
                    style={{margin: '0 0 8px 24px'}}
                    className="lb-application-drawer"
                    name={PlbFormFields.RECURRING_FREQUENCY_FIELD}
                    label={i18n.t(k.INCENTIVE__HOW_OFTEN)}
                    valuePropName={'value'}
                    rules={[
                      {
                        required: true,
                        message: i18n.t(k.VALIDATION__SELECT_ONE),
                      },
                    ]}>
                    <StyledRadioGroup
                      disabled={disableEditFrequency}
                      onChange={({target: {value}}) => {
                        onChangeRecurrenceFrequencyRadioGroup(value);
                      }}>
                      <Radio style={radioStyle} value={FinanceRecurrence.Never}>
                        {i18n.t(k.DATE__FREQUENCY__ONE_TIME)}
                      </Radio>
                      {!isSunsetMonthlyRenewalOn && (
                        <Radio
                          style={radioStyle}
                          value={FinanceRecurrence.Monthly}
                          disabled={disableFinanceRecurrenceMonthlyRadioOption}>
                          {i18n.t(k.DATE__FREQUENCY__FIRST_MONTH)}
                        </Radio>
                      )}
                      {(showDirectBillingOption &&
                        isHideEveryYearDirectBillingOn) ||
                      (showRequireRedemptionApprovalFromOptions &&
                        isHideEveryYearReimbursement) ? (
                        ''
                      ) : (
                        <Radio
                          style={radioStyle}
                          value={FinanceRecurrence.Yearly}
                          disabled={disableFinanceRecurrenceYearlyRadioOption}>
                          {i18n.t(
                            isProratingOn
                              ? k.DATE__FREQUENCY__EVERY_YEAR
                              : k.DATE__FREQUENCY__FIRST_YEAR
                          )}
                        </Radio>
                      )}
                    </StyledRadioGroup>
                  </Form.Item>
                )}
                {showProratingInput && (
                  <div style={{margin: '0 0 0 48px'}}>
                    {!isHideRenewalDate && (
                      <>
                        <Form.Item
                          name={PlbFormFields.PRORATED_RENEWAL_MONTH}
                          rules={[
                            {
                              required: true,
                              message: i18n.t(
                                k.PLB__VALIDATION__RENEWAL_MONTH_REQUIRED
                              ),
                            },
                          ]}
                          className="lb-application-drawer"
                          style={{marginBottom: '0'}}>
                          <StyledProrateSelect
                            placeholder={i18n.t(
                              k.INITIATIVE__PRORATE__RENEWAL_MONTH_FIELD
                            )}>
                            {getMonths().map((month) => {
                              return (
                                <LearnInSelectOption
                                  value={month.iso}
                                  key={`month-${month.value}`}
                                  style={{fontSize: '1rem'}}>
                                  {i18n.t(k[month.i18nKey])}
                                </LearnInSelectOption>
                              );
                            })}
                          </StyledProrateSelect>
                        </Form.Item>
                        <StyledHelperText style={{marginBottom: '1rem'}}>
                          {i18n.t(k.INITIATIVE__PRORATE__RENEWAL_MONTH_HELP)}
                        </StyledHelperText>
                      </>
                    )}
                    <Form.Item
                      className="lb-application-drawer"
                      name={PlbFormFields.PRORATED_FIELD}
                      valuePropName="checked"
                      rules={[
                        {
                          required: false,
                        },
                      ]}>
                      <Checkbox data-testid="add-plb-prorate-checkbox">
                        {i18n.t(k.INITIATIVE__PRORATE_FIELD)}
                        <StyledHelperText>
                          {i18n.t(k.INITIATIVE__PRORATE_HELP)}
                        </StyledHelperText>
                      </Checkbox>
                    </Form.Item>
                  </div>
                )}
                <Radio style={radioStyle} value={FinanceAmountType.Any}>
                  {i18n.t(k.MONEY_AMOUNT_SPECIFIC__NONE)}
                </Radio>
              </StyledRadioGroup>
            </Form.Item>

            {showBalanceRecurTypeRadioGroup && (
              <Form.Item
                className="lb-application-drawer"
                name={PlbFormFields.BALANCE_RECUR_TYPE_FIELD}
                label={i18n.t(k.INITIATIVE__HOW_RELOAD)}
                valuePropName={'value'}
                rules={[
                  {
                    required: true,
                    message: i18n.t(k.VALIDATION__BALANCE_RENEWAL),
                  },
                ]}>
                <StyledRadioGroup>
                  {!isHideRollOver && (
                    <Radio style={radioStyle} value={BalanceRecurType.Rollover}>
                      {i18n.t(k.MONEY__BALANCE__ROLLOVER_PREVIOUS)}
                    </Radio>
                  )}

                  <Radio style={radioStyle} value={BalanceRecurType.Reset}>
                    {i18n.t(k.MONEY__BALANCE__RESET_EACH_TIME)}
                  </Radio>
                </StyledRadioGroup>
              </Form.Item>
            )}
            <Form.Item
              name={PlbFormFields.AVAILABILITY_FIELD}
              className={'lb-application-drawer'}
              label={i18n.t(k.INITIATIVE__VISIBILITY)}
              rules={[
                {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
              ]}>
              <StyledRadioGroup disabled={disableEditing}>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.AvailableToAll}>
                  {i18n.t(k.GENERIC__EVERYONE)}
                </Radio>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.NotAvailable}>
                  {i18n.t(k.AVAILABILITY__DRAFT)}
                </Radio>
              </StyledRadioGroup>
            </Form.Item>

            {/*This hides the field until we know what text to show*/}
            {(showPermissionsFields || showDirectBillingOption) && (
              <>
                <Form.Item
                  name={PlbFormFields.PERMISSIONS_FIELD}
                  className={'lb-application-drawer'}
                  label={i18n.t(k.PERMISSION__PLURAL)}>
                  <StyledContrastCheckboxGroup disabled={disableEditing}>
                    {showPermissionsFields ? (
                      <>
                        <Checkbox
                          value={Permissions.RequiresApproval}
                          onClick={onClickRequireApproval}
                          data-testid="add-plb-approval-checkbox">
                          {requiresApprovalCheckBoxLabel}
                        </Checkbox>
                        {showRequireApprovalOptions && (
                          <>
                            <br />
                            <Checkbox
                              style={{marginLeft: '0px'}}
                              onClick={onClickAllowResources}
                              value={Permissions.RequestResources}>
                              {i18n.t(k.PLB__ALLOW_REQUESTS)}
                              {showResourceOptions && (
                                <>
                                  <br />
                                  <NoPaddingFormItem
                                    name={PlbFormFields.RESOURCES_FIELD}
                                    label={''}>
                                    <StyledCheckboxGroup
                                      disabled={disableEditing}>
                                      {ResourceTypesListItem.map(
                                        ({label, value}) => (
                                          <Checkbox key={value} value={value}>
                                            {label}
                                          </Checkbox>
                                        )
                                      )}
                                    </StyledCheckboxGroup>
                                  </NoPaddingFormItem>
                                </>
                              )}
                            </Checkbox>
                          </>
                        )}
                      </>
                    ) : (
                      <Checkbox
                        value={Permissions.AllowRemoval}
                        onClick={onClickAllowRemoval}>
                        {i18n.t(k.PLB__ALLOW_REMOVAL)}
                      </Checkbox>
                    )}
                  </StyledContrastCheckboxGroup>
                </Form.Item>
              </>
            )}
          </Col>
          <Row>
            <Col xs={24} sm={12}>
              {showRequireApprovalOptions &&
                showReimbursementRequireRedemptionApprovalFromOptions && (
                  <Form.Item
                    name={PlbFormFields.PRE_APPROVAL_DETAILS}
                    rules={[
                      {
                        required: true,
                        message: i18n.t(k.APPROVAL__SELECT),
                      },
                    ]}>
                    {showReimbursementRequireRedemptionApprovalFromOptions &&
                      showRequireRedemptionApprovalFromOptions && (
                        <StyledContrastCheckboxGroup
                          style={{width: '100%'}}
                          disabled={disableEditing}>
                          <Row>
                            <Col>
                              <label>
                                {i18n.t(k.GENERIC__PRE_APPROVAL_REQUIRED_BY)}
                              </label>
                            </Col>
                          </Row>
                          <Row gutter={[8, 8]}>
                            <SpacedCheckboxRow>
                              <Checkbox
                                value={
                                  RequireApprovalTypeStatus.PREAPPROVAL_MANAGER
                                }>
                                {i18n.t(k.GENERIC__MANAGER)}
                              </Checkbox>
                              <Checkbox
                                value={
                                  RequireApprovalTypeStatus.PREAPPROVAL_ADMIN
                                }>
                                {i18n.t(k.ADMIN)}
                              </Checkbox>
                            </SpacedCheckboxRow>
                          </Row>
                        </StyledContrastCheckboxGroup>
                      )}
                  </Form.Item>
                )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}>
              {showRequireApprovalOptions && (
                <Form.Item
                  name={PlbFormFields.APPROVALS_DETAIL_FIELDS}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(
                        i18n.t(k.VALIDATION__SELECT_REQUIRED_APPROVALS)
                      ),
                    },
                  ]}>
                  {(showRequirePreApprovalFromOptions ||
                    showRequireRedemptionApprovalFromOptions) && (
                    <StyledContrastCheckboxGroup
                      style={{width: '100%'}}
                      disabled={disableEditing}>
                      <Row>
                        <Col>
                          <label>
                            {i18n.t(k.GENERIC__APPROVAL_REQUIRED_BY)}
                          </label>
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]}>
                        {showRequirePreApprovalFromOptions && (
                          <SpacedCheckboxRow>
                            <Checkbox
                              value={
                                RequireApprovalTypeStatus.PREAPPROVAL_MANAGER
                              }>
                              {i18n.t(k.GENERIC__MANAGER)}
                            </Checkbox>
                            <Checkbox
                              value={
                                RequireApprovalTypeStatus.PREAPPROVAL_ADMIN
                              }>
                              {i18n.t(k.ADMIN)}
                            </Checkbox>
                          </SpacedCheckboxRow>
                        )}
                        {showRequireRedemptionApprovalFromOptions && (
                          <SpacedCheckboxRow>
                            <Checkbox
                              value={
                                RequireApprovalTypeStatus.REDEMPTION_MANAGER
                              }>
                              {i18n.t(k.GENERIC__MANAGER)}
                            </Checkbox>
                            <Checkbox
                              value={
                                RequireApprovalTypeStatus.REDEMPTION_ADMIN
                              }>
                              {i18n.t(k.ADMIN)}
                            </Checkbox>
                          </SpacedCheckboxRow>
                        )}
                      </Row>
                    </StyledContrastCheckboxGroup>
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
          {showExpirationOption && (
            <Row>
              <Col xs={24} sm={12}>
                <Form.Item
                  name={PlbFormFields.EXPIRATION_IN_DAYS}
                  className="lb-application-drawer"
                  label={i18n.t(k.INCENTIVE__EXPIRES_WHEN)}
                  initialValue={defaultApprovalExpirationInDays}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      min: 1,
                      max: 365,
                      message: i18n.t(k.VALIDATION__NUMBER_UP_BETWEEN__FORMAT, {
                        min: 1,
                        max: 365,
                      }),
                    },
                  ]}
                  style={{display: 'inline-block'}}>
                  <LearnInNumberInput
                    disabled={disableEditing}
                    precision={0}
                    suffix={i18n.t(k.INCENTIVE__EXPIRES_DAYS_AFTER_APPROVAL)}
                    style={{width: '70px'}}
                    data-testid="add-plb-expiry-field"
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
          {showRequireApprovalOptions && (
            <Row style={{paddingBottom: '25px'}}>
              <Col xs={24}>
                <>
                  <Row style={{paddingBottom: '8px'}}>
                    <Col>
                      <Row>
                        <Col>
                          {/* The onClick supports ReactQuill focus handling. */}
                          {/* eslint-disable-next-line */}
                          <label
                            htmlFor="approval-guidelines-field"
                            onClick={() =>
                              approvalGuidelinesFieldRef.current?.focus()
                            }>
                            {i18n.t(k.APPROVAL__GUIDELINES)}
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>{i18n.t(k.APPROVAL__HELP)}</Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <ReactQuill
                        id="approval-guidelines-field"
                        formats={formatPastingOptions}
                        value={approvalGuidelines}
                        theme="snow"
                        onChange={onChangeApprovalGuidelines}
                        ref={approvalGuidelinesFieldRef}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {!!approvalGuidelinesErrorMessage && (
                        <ErrorMessage
                          message={approvalGuidelinesErrorMessage}
                        />
                      )}
                    </Col>
                  </Row>
                </>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={24} sm={12} />
          </Row>
          <Row style={{marginBottom: '4px'}}>
            {/* The onClick supports ReactQuill focus handling. */}
            {/* eslint-disable-next-line */}
            <label
              htmlFor="details-field"
              onClick={() => detailsFieldRef.current?.focus()}>
              {i18n.t(k.GENERIC__DETAIL__PLURAL)}
            </label>
          </Row>
          <ReactQuill
            id="details-field"
            formats={formatPastingOptions}
            theme="snow"
            value={details}
            onChange={onChangeDetails}
            ref={detailsFieldRef}
          />
        </div>
      </StyledForm>
    </LearnInDrawer>
  );
}
