import styled from 'styled-components';
import {COLORS} from '@utils/constants';

export const Container = styled.div``;

export const ContentCardTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral900};
`;
