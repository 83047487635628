import {Container, StyledButton, StyledRadioButton} from './Welcome.styled';
import {WelcomeViewProps} from './Welcome.interfaces';
import Icon from '@ant-design/icons';
import {ProgramFinderIcon} from '@assets/ProgramFinderIcon';
import {Form, Radio} from 'antd';
import {H3} from '@components/reusable/Typography';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {programFinderPath} from '@components/ProgramType/programFinderHelpers';
import {ProgramFinderHeadings} from '@pages/ProgramFinder/ProgramFinderHeadings';

function WelcomeView({
  firstName,
  isButtonVisible,
  next,
  setIsButtonVisible,
  setSelectedOption,
}: WelcomeViewProps) {
  return (
    <Container>
      <Icon
        component={() => <ProgramFinderIcon size={112} circleSize={32} />}
      />
      <ProgramFinderHeadings
        subtitle={i18n.t(k.PROGRAM_FINDER__DESCRIPTION)}
        title={i18n.t(k.PROGRAM_FINDER__GREETING__FORMAT, {
          firstName,
        })}
      />
      <Form.Item style={{marginTop: '2.5rem'}} name="path">
        <Radio.Group
          size="large"
          onChange={(e) => {
            setSelectedOption(e.target.value);
            setIsButtonVisible(true);
          }}>
          {programFinderPath.map(({key, value}) => (
            <StyledRadioButton key={key} value={key}>
              <H3 color={COLORS.Neutral600}>{value}</H3>
            </StyledRadioButton>
          ))}
        </Radio.Group>
      </Form.Item>
      <StyledButton
        visible={isButtonVisible}
        tag={ButtonTags.Primary}
        onClick={next}>
        {i18n.t(k.PROGRAM_FINDER__BUTTON__LETS_GO)}
      </StyledButton>
    </Container>
  );
}

export default WelcomeView;
