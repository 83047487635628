import React from 'react';
import {TitleDescriptionContainerProps} from './TitleDescription.interfaces';
import TitleDescriptionView from './TitleDescription.view';
import {getPlbL10nIfAvailable} from '@utils/l10nUtils';

function TitleDescriptionContainer({
  title,
  description,
  locale,
  isTranslatePlbFieldsOn,
  direction,
}: TitleDescriptionContainerProps) {
  const displayTitle = getPlbL10nIfAvailable({
    nonLocalized: title,
    key: 'plbTitle',
    locale,
    flagIsOn: isTranslatePlbFieldsOn,
  });

  const displayDescription = getPlbL10nIfAvailable({
    nonLocalized: description,
    key: 'plbDescription',
    locale,
    flagIsOn: isTranslatePlbFieldsOn,
  });
  return (
    <TitleDescriptionView
      displayTitle={displayTitle}
      displayDescription={displayDescription}
      direction={direction}
    />
  );
}

export default TitleDescriptionContainer;
