import {UserPersonalLearningBudgetSnapshot} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {faker} from '@faker-js/faker';

// Interface: UserPersonalLearningBudgetSnapshot

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPersonalLearningBudgetSnapshotMockData(
  args?: Partial<UserPersonalLearningBudgetSnapshot>
): UserPersonalLearningBudgetSnapshot {
  return {
    ...getBaseEntityMockData(),
    amountLimit: faker.datatype.number(),
    balance: faker.datatype.number(),
    estimatedAmount: faker.datatype.number(),
    id: faker.datatype.number(),
    totalAmount: faker.datatype.number(),
    totalApprovedAmountSum: faker.datatype.number(),
    totalApprovedClosedTransactions: faker.datatype.number(),
    totalPendingAuthorizations: faker.datatype.number(),
    totalRequestedAmountSum: faker.datatype.number(),
    userBudgetId: faker.datatype.number(), // optional
    userCompanyId: faker.datatype.number(),
    userFinanceIncentiveId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPersonalLearningBudgetSnapshotMockData: UserPersonalLearningBudgetSnapshot =
  {
    ...BaseEntityMockData,
    amountLimit: 5,
    balance: 123,
    estimatedAmount: 5,
    id: 1,
    totalAmount: 5,
    totalApprovedAmountSum: 5,
    totalApprovedClosedTransactions: 123,
    totalPendingAuthorizations: 123,
    totalRequestedAmountSum: 5,
    userBudgetId: 1, // optional
    userCompanyId: 1,
    userFinanceIncentiveId: 1,
  };
