import {BudgetAllocationType} from './../enums';
import {IBudgetToOldFinanceIncentiveEnum} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: IBudgetToOldFinanceIncentiveEnum

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIBudgetToOldFinanceIncentiveEnumMockData(
  args?: Partial<IBudgetToOldFinanceIncentiveEnum>
): IBudgetToOldFinanceIncentiveEnum {
  return {
    allocationType: BudgetAllocationType.PreAllocated,
    allowDirectBilling: faker.datatype.boolean(),
    allowExternalReimbursement: faker.datatype.boolean(),
    allowReimbursement: faker.datatype.boolean(),
    allowVirtualCard: faker.datatype.boolean(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IBudgetToOldFinanceIncentiveEnumMockData: IBudgetToOldFinanceIncentiveEnum =
  {
    allocationType: BudgetAllocationType.PreAllocated,
    allowDirectBilling: true,
    allowExternalReimbursement: true,
    allowReimbursement: true,
    allowVirtualCard: true,
  };
