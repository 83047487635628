import {useEffect} from 'react';
import AddEditRequirementModal, {
  AddEditRequirementFormNames,
  AddEditRequirementFormFields,
} from './AddEditRequirementModal.view';
import {Form} from 'antd';
import {useAcademyLevelVMsQuery} from '@hooks/apiEndpoints/academy/queries';
import {useUpdateAcademyLevel} from '@hooks/apiEndpoints/academy/mutations';
import {Maybe} from '@utils/typeUtils';
import {basicSorter} from '@components/reusable/LearnInTable';
import {i18n, k} from '@i18n/translate';
import {hasRequirement} from '@utils/requirementGating';
import {AcademyLevelVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

const getLevelById = (
  levelId: number,
  levels: AcademyLevelVM[]
): Maybe<AcademyLevelVM> => levels?.find(({id}) => id === levelId);

const getPreviousLevels = (
  order: number,
  levels: AcademyLevelVM[]
): Maybe<AcademyLevelVM[]> => {
  return levels?.filter((l) => l.order < order);
};

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface AddEditLevelRequirementModalContainerProps {
  academyId: number;
  levelId: number;
  onCancel: () => void;
  visible: boolean;
}

function AddEditLevelRequirementModalContainer({
  academyId,
  onCancel,
  levelId,
  visible,
}: AddEditLevelRequirementModalContainerProps) {
  // Util Hooks
  const [form] = Form.useForm<AddEditRequirementFormFields>();

  // This does not make an api call, but only gets data from the cache
  const {cacheData: levels, refetch: invalidateLevels} =
    useAcademyLevelVMsQuery(
      {academyId, excludeUserProgress: true},
      {enabled: false}
    );

  useEffect(() => {
    if (visible) {
      const level = getLevelById(levelId, levels);
      form.setFieldsValue({
        [AddEditRequirementFormNames.RequiredId]: level?.requiredId,
      });
    }
  }, [visible]);

  const updateAcademyLevel = useUpdateAcademyLevel({
    onSuccess: () => {
      invalidateLevels();
      onCancel();
    },
  });

  const level = getLevelById(levelId, levels);
  const levelHasRequirement = hasRequirement(level);

  const levelUpdateParams = {
    academyId,
    description: level?.description || '',
    id: levelId,
    order: level?.order || levels?.length + 1,
    title: level?.title || '',
  };

  const handleClickRemove = () => {
    updateAcademyLevel.mutate({
      payload: {
        ...levelUpdateParams,
        requiredId: null,
      },
    });
  };

  const handleOk = async () => {
    await form.validateFields().then((fields) => {
      updateAcademyLevel.mutate({
        payload: {
          ...levelUpdateParams,
          requiredId: fields[AddEditRequirementFormNames.RequiredId],
        },
      });
    });
  };

  return (
    <AddEditRequirementModal
      form={form}
      onCancel={onCancel}
      onClickRemove={handleClickRemove}
      onOk={handleOk}
      showRemoveRequirementButton={levelHasRequirement}
      title={`${levelHasRequirement ? 'Edit' : 'Add'} Requirement`}
      selectInputLabel={i18n.t(k.SECTION__REQUIRES_ACCESS__FORMAT, {
        levelTitle: level?.title,
      })}
      visible={visible}
      options={getPreviousLevels(level?.order, levels)
        ?.sort(basicSorter('order'))
        .map(({id, title}) => ({id, title}))}
    />
  );
}

export default AddEditLevelRequirementModalContainer;
