import {CreateCustomProgramUserRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {CreateCustomProgramUserRequestSchema} from '../zodSchemas/CreateCustomProgramUserRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramUsersController.cs

/*
|--------------------------------------------------------------------------
|  {customProgramId}/users/{userCompanyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsUsersPostMutation} from '@generated/hooks';
 *
 *  const customProgramsUsersPost = useCustomProgramsUsersPostMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramsUsersPost.mutate({
 *    payload: {
 *      ...CreateCustomProgramUserRequest
 *    },
 *    pathVars: {
 *      customProgramId,
 *      userCompanyId
 *    }
 *  })
 *
 */

export const useCustomProgramsUsersPostMutation = mutationHookFactory<
  CreateCustomProgramUserRequest, // payload
  {customProgramId: number | string; userCompanyId: number | string}, // path variables
  null
>(
  'useCustomProgramsUsersPostMutation',
  'post',
  ({customProgramId, userCompanyId}) =>
    `custom-programs/${customProgramId}/users/${userCompanyId}`,
  {payloadSchema: CreateCustomProgramUserRequestSchema}
);
