import {StripeConfigDTO} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: StripeConfigDTO

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getStripeConfigDTOMockData(
  args?: Partial<StripeConfigDTO>
): StripeConfigDTO {
  return {
    allowedMerchantCategoryMcCs: [faker.word.noun()],
    blockedMerchantCategoryMcCs: [faker.word.noun()],
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const StripeConfigDTOMockData: StripeConfigDTO = {
  allowedMerchantCategoryMcCs: ['string'],
  blockedMerchantCategoryMcCs: ['string'],
};
