import {COLORS} from '../../../utils/constants';

export const ProgressStyles = {
  primary: {
    activeBackground: COLORS.Green600,
    textColor: COLORS.Neutral900,
    textfontSize: '1rem',
    textlineHeight: '1.1875rem',
    textFontFamily: 'Roboto',
  },

  completed: {
    activeBackground: COLORS.Green600,
    textColor: COLORS.Green600,
    textfontSize: '1rem',
    textlineHeight: '1.1875rem',
    textFontFamily: 'Roboto',
  },
};
