import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {COLORS} from '@utils/constants';
import {AcademyLevelVM} from '@generated/interfaces';

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  margin-bottom: 16px;
`;

const SelectLabel = styled.label`
  font-weight: normal;
  font-size: 1rem;
  color: ${COLORS.Neutral950};
  display: block;
  margin-bottom: 16px;
`;

interface MoveStepLevelModalProps {
  visible: boolean;
  onCancel: () => void;
  onOk: (sectionId: number) => void;
  levels: Pick<AcademyLevelVM, 'id' | 'title'>[];
}
function MoveStepLevelModal({
  visible,
  onCancel,
  onOk,
  levels,
}: MoveStepLevelModalProps) {
  const [selectedLevelId, setSelectedLevelId] = useState<number | null>(null);
  return (
    <LearnInModal
      width={400}
      visible={visible}
      tag={ModalTags.MoneyIncentive}
      okButtonChild={i18n.t(k.CONTENT__MOVE)}
      buttonDisabled={!selectedLevelId || !levels?.length}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      onCancel={() => {
        onCancel();
        setSelectedLevelId(null);
      }}
      onOk={() => {
        onOk(selectedLevelId);
        setSelectedLevelId(null);
      }}
      ariaLabel={i18n.t(k.SECTION__MOVE_TO)}>
      <div>
        <ModalTitle>{i18n.t(k.SECTION__MOVE_TO)}</ModalTitle>
        {!!levels?.length ? (
          <>
            <SelectLabel>{i18n.t(k.LEVEL__SELECT_TO_MOVE)}</SelectLabel>
            <LearnInSelect
              placeholder={i18n.t(k.LEVEL__SELECT)}
              onChange={(value) => setSelectedLevelId(value)}
              value={selectedLevelId}
              style={{width: '100%'}}>
              {levels?.map(({id, title}) => {
                return (
                  <LearnInSelectOption
                    key={id}
                    data-testid={`section-option-${id}`}
                    value={id}>
                    {title}
                  </LearnInSelectOption>
                );
              })}
            </LearnInSelect>
          </>
        ) : (
          <div>{i18n.t(k.LEVEL__NO_MOVE_TO_LEVEL)}</div>
        )}
      </div>
    </LearnInModal>
  );
}

export default MoveStepLevelModal;
