import {useState} from 'react';
import CustomProgramSectionNavItem from './CustomProgramSectionNavItem';
import {LearnInInput} from '@components/reusable/LearnInForm';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {
  getUpdateCustomProgramSectionRm,
  getReorderCustomProgramSectionRm,
} from '@store/apiEndpoints/customProgram/mutations';
import {notify} from '@components/user/notifications';
import DeleteCustomProgramSectionModal from './DeleteCustomProgramSectionModal.container';
import CircleCloseButton from '@blocks/CircleCloseButton';
import {KEY_CODES} from '@utils/constants';
import {useCustomProgramSectionsQuery} from '@generated/hooks';
import {mapTextToEnglish} from '@utils/enumMapping/MapTextToUserLanguage';
import {NEW_SECTION} from '@components/admin/constants';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

const getTitleInputId = (id: any) => `edit-title-input-section-nav-item-${id}`;

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramSectionNavItemContainerProps {
  active: boolean;
  customProgramId: number;
  doesSectionHaveNoContent: boolean;
  id: number;
  isDraggingSectionOrder: number | null;
  onClick: () => void;
  order: number;
  sectionReorderIdx: number | null;
  selectedSectionId: number;
  setIsDraggingSectionOrder: () => void;
  setSectionReorderIdx: () => void;
  setSelectedSectionId: (id: number | undefined) => void;
  title: string;
}

function CustomProgramSectionNavItemContainer({
  active,
  customProgramId,
  doesSectionHaveNoContent,
  id,
  isDraggingSectionOrder,
  onClick,
  order,
  sectionReorderIdx,
  selectedSectionId,
  setIsDraggingSectionOrder,
  setSectionReorderIdx,
  setSelectedSectionId,
  title,
}: CustomProgramSectionNavItemContainerProps) {
  const [showSectionTitleEdit, setShowSectionTitleEdit] = useState(false);
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const [_title, setTitle] = useState('');

  const {
    data: customProgramSections,
    invalidateExact: invalidateCustomProgramSections,
  } = useCustomProgramSectionsQuery(
    {
      customProgramId,
    },
    {enabled: false}
  );

  const updateSectionTitleMutation = useMutation(
    () => {
      const updateSectionRm = getUpdateCustomProgramSectionRm({
        title: _title,
        sectionId: id,
        customProgramId,
      });
      return simpleMutationFn<null>(
        updateSectionRm.path,
        updateSectionRm.payload
      );
    },
    {
      onSuccess: async () => {
        notify.updateCustomProgramSuccess();
        await invalidateCustomProgramSections();
      },
    }
  );

  const reorderSectionMutation = useMutation(
    (newOrder: number) => {
      const updateSectionRm = getReorderCustomProgramSectionRm({id, newOrder});
      return simpleMutationFn<null>(
        updateSectionRm.path,
        updateSectionRm.payload
      );
    },
    {
      onSuccess: async () => {
        await invalidateCustomProgramSections();
      },
    }
  );

  const handleUpdateTitle = async () => {
    // Only update if the title has changed and we are not showing the New Section text
    if (_title === title || mapTextToEnglish(_title) === NEW_SECTION) return;
    await updateSectionTitleMutation.mutateAsync();
  };

  const handleClickRenameSection = () => {
    setTitle(title);
    setShowSectionTitleEdit(true);
    setTimeout(() => {
      const editTitleInput = document.getElementById(getTitleInputId(id));
      editTitleInput?.focus();
    }, 400);
  };

  return (
    <>
      {showSectionTitleEdit ? (
        <div
          style={{display: 'flex', alignItems: 'center', padding: '0px 16px'}}>
          <LearnInInput
            id={getTitleInputId(id)}
            value={_title}
            updateFunc={setTitle}
            style={{width: '360px'}}
            onKeyPress={(e) => {
              if (e.key === KEY_CODES.ENTER) {
                handleUpdateTitle();
                setShowSectionTitleEdit(false);
              } else if (e.key === KEY_CODES.ESCAPE) {
                setShowSectionTitleEdit(false);
              }
            }}
            onBlur={() => {
              handleUpdateTitle();
              setShowSectionTitleEdit(false);
            }}
          />
          <CircleCloseButton onClick={() => setShowSectionTitleEdit(false)} />
        </div>
      ) : (
        <CustomProgramSectionNavItem
          id={id}
          active={active}
          order={order}
          onClick={onClick}
          onClickRenameSection={handleClickRenameSection}
          title={title}
          onClickDelete={() => setShowDeleteSectionModal(true)}
          onClickMoveToTop={async () =>
            await reorderSectionMutation.mutateAsync(1)
          }
          onClickMoveToBottom={async () =>
            await reorderSectionMutation.mutateAsync(
              customProgramSections?.length
            )
          }
          setIsDraggingSectionOrder={setIsDraggingSectionOrder}
          isDraggingSectionOrder={isDraggingSectionOrder}
          setSectionReorderIdx={setSectionReorderIdx}
          reorderSectionItem={async () => {
            if (order !== sectionReorderIdx) {
              await reorderSectionMutation.mutateAsync(sectionReorderIdx);
            }
          }}
        />
      )}
      {showDeleteSectionModal && (
        <DeleteCustomProgramSectionModal
          customProgramId={customProgramId}
          doesSectionHaveNoContent={doesSectionHaveNoContent}
          onCancel={() => setShowDeleteSectionModal(false)}
          sectionId={id}
          selectedSectionId={selectedSectionId}
          setSelectedSectionId={setSelectedSectionId}
          visible={showDeleteSectionModal}
        />
      )}
    </>
  );
}

export default CustomProgramSectionNavItemContainer;
