import {CustomizableLearningCategory} from './../enums';
import {UserPlanProjectSubmission} from './../interfaces';
import {LinkPayloadMockData, getLinkPayloadMockData} from './LinkPayload.mock';
import {faker} from '@faker-js/faker';

// Interface: UserPlanProjectSubmission

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanProjectSubmissionMockData(
  args?: Partial<UserPlanProjectSubmission>
): UserPlanProjectSubmission {
  return {
    contentId: faker.datatype.number(),
    customizableLearningCategory: CustomizableLearningCategory.CustomProgram,
    links: [getLinkPayloadMockData()], // optional
    response: faker.word.noun(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanProjectSubmissionMockData: UserPlanProjectSubmission = {
  contentId: 1,
  customizableLearningCategory: CustomizableLearningCategory.CustomProgram,
  links: [LinkPayloadMockData], // optional
  response: 'string', // optional
};
