import {i18n, k} from '@i18n/translate';
import styled, {css} from 'styled-components';
import ContextMenuButton from '@blocks/ContextMenu';
import {MenuOutlined} from '@ant-design/icons';
import {useDragAndDrop} from '@hooks/useDragAndDrop';
import {COLORS} from '@utils/constants';
import Typography from '@components/reusable/Typography';

const {Body} = Typography;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.button<{
  active: boolean;
  isDraggingContentHigher: boolean;
  isDraggingContentLower: boolean;
  isOver: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${COLORS.Neutral900};
  background: ${({active}) => (active ? COLORS.Blue50 : 'white')};
  min-height: 47px;
  cursor: pointer;
  width: 100%;
  .context-menu-container {
    display: none;
  }
  .draggable-indicator {
    display: none;
  }
  &:hover {
    background: ${({active}) => (active ? COLORS.Blue50 : COLORS.Neutral50)};
    .context-menu-container {
      display: block;
    }
    .draggable-indicator {
      display: inline-block;
    }
    .order-number-display {
      display: none;
    }
  }
  ${({isOver, isDraggingContentHigher, isDraggingContentLower}) => {
    if (isOver) {
      if (isDraggingContentHigher) {
        return css`
          border-top: 4px solid ${COLORS.Blue200};
        `;
      }
      if (isDraggingContentLower) {
        return css`
          border-bottom: 4px solid ${COLORS.Blue200};
        `;
      }
    }
  }}
`;

const LevelName = styled.div<{
  active: boolean;
}>`
  display: flex;
  font-weight: ${({active}) => (active ? '500' : '400')};
  &:hover {
    font-weight: 400;
  }
`;

/*
|--------------------------------------------------------------------------
| Nav Item Component
|--------------------------------------------------------------------------
*/

interface LevelItemProps {
  id: number;
  order: number;
  title: string;
  onClick: (e) => void;
  active: boolean;
  onClickDelete?: () => void;
  onClickMoveToTop?: () => void;
  onClickMoveToBottom?: () => void;
  onClickRenameSection?: () => void;
  onClickUpdateRequirement: () => void;
  reorderSectionItem: () => void;
  setSectionReorderIdx: () => void;
  setIsDraggingSectionOrder: () => void;
  isDraggingSectionOrder: number | null;
  updateRequirementOptionLabel: string;
  showUpdateRequirementOption: boolean;
  blockingTitle?: string;
}

function LevelItem({
  id,
  title,
  order,
  onClick,
  active,
  onClickDelete,
  onClickMoveToTop,
  onClickMoveToBottom,
  onClickRenameSection,
  reorderSectionItem,
  setSectionReorderIdx,
  setIsDraggingSectionOrder,
  isDraggingSectionOrder,
  onClickUpdateRequirement,
  updateRequirementOptionLabel,
  showUpdateRequirementOption,
  blockingTitle,
}: LevelItemProps) {
  const {drag, drop, isOver} = useDragAndDrop({
    item: {id, order},
    reorderItem: reorderSectionItem,
    setReorderIdx: setSectionReorderIdx,
    setIsDraggingOrder: setIsDraggingSectionOrder,
  });
  const isDraggingContentHigher = isDraggingSectionOrder > order;
  const isDraggingContentLower = isDraggingSectionOrder < order;
  return (
    <Container
      role="button"
      tabindex="0"
      onClick={onClick}
      active={active}
      ref={(node) => drag(drop(node))}
      isOver={isOver}
      isDraggingContentHigher={isDraggingContentHigher}
      isDraggingContentLower={isDraggingContentLower}>
      <div>
        <LevelName active={active}>
          <div
            style={{
              marginRight: '4px',
              minWidth: '20px',
              width: 'fit-content',
            }}>
            <span className="order-number-display">{order}.</span>
            <span
              className="draggable-indicator"
              aria-label={i18n.t(k.CTA__DRAG_AND_DROP)}>
              <MenuOutlined aria-hidden="true" />
            </span>
          </div>{' '}
          {title}
        </LevelName>
        {blockingTitle && (
          <Body.Span color={COLORS.Neutral600} size={0.875}>
            {i18n.t(k.GENERIC__REQUIRED)}: {blockingTitle}
          </Body.Span>
        )}
      </div>
      <div className="context-menu-container">
        <ContextMenuButton
          menuItems={[
            {
              label: i18n.t(k.SECTION__RENAME),
              onClick: onClickRenameSection,
            },
            {
              label: i18n.t(k.SECTION__TO_BOTTOM),
              noBottomBorder: true,
              onClick: onClickMoveToBottom,
            },
            {
              label: i18n.t(k.SECTION__TO_TOP),
              onClick: onClickMoveToTop,
            },
            showUpdateRequirementOption && {
              label: updateRequirementOptionLabel,
              onClick: onClickUpdateRequirement,
            },
            {
              label: (
                <span style={{color: COLORS.Red800}}>
                  {i18n.t(k.SECTION__DELETE)}
                </span>
              ),
              onClick: onClickDelete,
            },
          ].filter(Boolean)}
        />
      </div>
    </Container>
  );
}

export default LevelItem;
