import {CurrencyCode, LanguagePreference} from './../enums';
import {UserSettingsUpdateRequest} from './../interfaces';
import {
  NotificationSettingsMockData,
  getNotificationSettingsMockData,
} from './NotificationSettings.mock';
import {faker} from '@faker-js/faker';

// Interface: UserSettingsUpdateRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserSettingsUpdateRequestMockData(
  args?: Partial<UserSettingsUpdateRequest>
): UserSettingsUpdateRequest {
  return {
    currency: CurrencyCode.USD,
    languagePreference: LanguagePreference.EN_US,
    notificationSettings: getNotificationSettingsMockData(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserSettingsUpdateRequestMockData: UserSettingsUpdateRequest = {
  currency: CurrencyCode.USD,
  languagePreference: LanguagePreference.EN_US,
  notificationSettings: NotificationSettingsMockData,
};
