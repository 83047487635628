import styled, {css} from 'styled-components';
import {COLORS} from '@utils/constants';
import {StyledLearnInToggleButtonProps} from './LearnInToggle.interfaces';

export const Container = styled.section`
  display: flex;
  background-color: ${COLORS.Neutral100};
  border-radius: 4px;
  padding: 1px 2px;
  width: fit-content;
`;

export const ToggleButton = styled.button<StyledLearnInToggleButtonProps>`
  width: 151px;
  min-height: 36px;
  font-size: 1rem;
  line-height: 20px;
  color: ${COLORS.Neutral950};
  background-color: transparent;
  border: none;
  border-radius: 2px;
  text-align: center;

  &:hover {
    color: ${COLORS.Blue800};
  }

  &:focus {
    outline: 1px solid ${COLORS.Blue950};
  }

  ${({isSelected}) => {
    return (
      isSelected &&
      css`
        background-color: ${COLORS.White};
        box-shadow: ${COLORS.BoxShadowStandard};
      `
    );
  }}
`;
