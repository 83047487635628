import './AdminApprovalsList.scss';
import {useEffect, useMemo, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {useMutation, useQuery} from 'react-query';
import styled from 'styled-components';
import {Col, Input, Row, Tabs, Typography} from 'antd';
import {ArrowRightOutlined} from '@ant-design/icons';
import {
  applyLicenseCurrencyOrDefault,
  formatCurrency,
  getExchangeRateOfCurrencyPair,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import moment from 'moment';
import {debounce} from 'lodash';
import AdminPreApprovalDrawer from '../AdminPreApprovalDrawer/AdminPreApprovalDrawer';
import {api} from '@store/api';
import {notify} from '@components/user/notifications';
import {
  LearnInButton,
  LinkStyledButton,
} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  ApprovalType,
  UserRequestType,
} from '@generated/enums';
import {
  ApprovalRequestDetailsVM,
  CostCenterOverridePayload,
  ResourceApprovalRequest,
  UserRequestApprovalVM,
  UserRequestDetailsVM,
} from '@models/serverModels';
import {
  parallelQueryResults,
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleQueryFn,
} from '@store/queryClient';
import {
  getAdminPendingManagerUserRequestApprovalsRq,
  getAdminUpdateUserRequestApprovalRm,
  getAdminUserRequestApprovalsRq,
  ReactQueryRequest,
} from '@store/apiEndpoints';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {COLORS} from '@utils/constants';
import {getApprovalsPlanType} from '@utils/approvals-helper';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import LearnInTable, {
  ColumnAlignment,
  dateTimeSorter,
  DefaultSortOrder,
  IColumn,
  stringSorter,
  TableCell,
} from '@components/reusable/LearnInTable';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import NoDataText from '@blocks/NoDataText';
import {ViewProgramLink} from '@components/admin/pages/reports/components/ViewProgramLink';
import {ExceedsRemainingBudgetWarning} from '@components/manager/pages/approvals/components/managerApprovalsList/ManagerApprovalsList';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useUpdateCostCenterAsAdmin} from '@hooks/apiEndpoints/adminApprovals/mutations';
import {useGetCostCentersVMQuery} from '@hooks/apiEndpoints/company/queries';
import useTrackIds from '@hooks/useTrackIds';
import {filterQuery} from '@utils/filterUtils';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import {DATE_FORMAT} from '@utils/timeUtils';
import {getUserRequestType} from '@utils/getUserRequestType';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {localizeResourceType} from '@utils/l10nUtils';
import {InlineSelect} from '@components/reusable/InlineSelect';
import {useGetCostCenterOptions} from '@components/reusable/CostCenterField/CostCenterField.hooks';
import Icon from '@blocks/Icon/Icon';
import {
  AdminScreenApprovalStatus,
  ApprovalsDiscriminatorWithTime,
} from '@models/clientEnums';
import {AdminPaths} from '@utils/ClientPaths';
import {Helmet} from 'react-helmet-async';

const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

interface ApprovalPageType {
  isFinancePage: boolean;
  isTimePage: boolean;
  isProgramPage: boolean;
  isResourcePage: boolean;
}

type ApprovalRequestRowVM = UserRequestApprovalVM & {hasLicense?: boolean};
type ResourceApprovalRequestRowVM = UserRequestApprovalVM &
  ResourceApprovalRequest & {
    hasLicense?: boolean;
  };
type ApprovalRowVM = ResourceApprovalRequestRowVM;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

const getApprovalItemType = (
  pageType: ApprovalPageType
): ApprovalsDiscriminator => {
  if (pageType.isFinancePage) return ApprovalsDiscriminator.Incentive;
  if (pageType.isTimePage) return ApprovalsDiscriminator.Incentive;
  if (pageType.isProgramPage) return ApprovalsDiscriminator.Program;
  if (pageType.isResourcePage) return ApprovalsDiscriminator.LearningResource;
};

function convertToApprovalStatus(
  adminScreenApprovalStatus: AdminScreenApprovalStatus
): ApprovalStatus {
  switch (adminScreenApprovalStatus) {
    case AdminScreenApprovalStatus.Approved:
      return ApprovalStatus.Approved;
    case AdminScreenApprovalStatus.Rejected:
      return ApprovalStatus.Rejected;
    case AdminScreenApprovalStatus.PendingManagerApproval:
      return ApprovalStatus.Pending;
    case AdminScreenApprovalStatus.Requested:
    default:
      return ApprovalStatus.Requested;
  }
}

//Get Admin Approvals
function resolveApprovalsRq(
  approvalItemType: ApprovalsDiscriminator | ApprovalsDiscriminatorWithTime,
  selectedApprovalStatus: AdminScreenApprovalStatus
): ReactQueryRequest {
  const approvalStatus = convertToApprovalStatus(selectedApprovalStatus);
  if (approvalItemType === ApprovalsDiscriminator.LearningResource) {
    return getAdminUserRequestApprovalsRq(
      ApprovalsDiscriminator.LearningResource,
      approvalStatus
    );
  }
  let planItemType = ApprovalsDiscriminator.Program;
  if (approvalItemType == ApprovalsDiscriminator.Incentive) {
    planItemType = ApprovalsDiscriminator.Incentive;
  } else if (approvalItemType == ApprovalsDiscriminatorWithTime.Time) {
    planItemType = ApprovalsDiscriminator.Incentive;
  }
  return getAdminUserRequestApprovalsRq(planItemType, approvalStatus);
}

/*
|--------------------------------------------------------------------------
| Page Component
|--------------------------------------------------------------------------
*/

function AdminApprovals({title}: {title?: string}) {
  const navigate = useNavigate();
  const location = useLocation();

  const getUserQuery = useGetUserQuery();
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );
  const {isFeatureFlagOn} = useFeatureFlags();
  const isCostCenterOn = isFeatureFlagOn.CostCenter;
  const isLearnInReimbursementsOn = isFeatureFlagOn.LearnInReimbursements;

  const {pathname} = useLocation();
  const pageType = getApprovalsPlanType(pathname);
  const [approvalItemType, setApprovalItemType] =
    useState<ApprovalsDiscriminator>(getApprovalItemType(pageType));
  const [searchParams, setSearchParams] = useSearchParams();
  const openDrawerId = searchParams.get('drawer');
  const selectedTabName =
    searchParams.get('tab') || AdminScreenApprovalStatus.Requested.toString();

  const toggleDrawer = (drawerId) =>
    drawerId
      ? setSearchParams({
          drawer: drawerId,
          tab: selectedTabName,
        })
      : setSearchParams({tab: selectedTabName});

  const toggleTab = (tabName) =>
    setSearchParams({tab: tabName, ...searchParams});

  const [selectedApprovalStatus, setSelectedApprovalStatus] =
    useState<AdminScreenApprovalStatus>(AdminScreenApprovalStatus.Requested);
  const [selectedApproval, setSelectedApproval] = useState<
    UserRequestApprovalVM | ResourceApprovalRequest | null
  >(null);

  const [approvalsRq, setApprovalsRq] = useState<ReactQueryRequest>(
    resolveApprovalsRq(approvalItemType, selectedApprovalStatus)
  );

  const [adminUserRequestApprovalsRq, setAdminUserRequestApprovalsRq] =
    useState<ReactQueryRequest>(
      getAdminUserRequestApprovalsRq(
        ApprovalsDiscriminator.License,
        convertToApprovalStatus(selectedApprovalStatus)
      )
    );

  const [programSearchText, setProgramSearchText] = useState('');

  const {ids: loadingIds, updateIds: updateLoadingIds} = useTrackIds();

  const isPendingManagerApprovalScreen =
    selectedApprovalStatus === AdminScreenApprovalStatus.PendingManagerApproval;

  const getAdminApprovalsQuery = useQuery<ApprovalRequestDetailsVM[]>(
    approvalsRq.queryKey,
    () => simpleQueryFn(approvalsRq.path)
  );

  const adminPendingManagerApprovalsRq =
    getAdminPendingManagerUserRequestApprovalsRq(approvalItemType);
  const getAdminPendingManagerApprovalsQuery = useQuery(
    adminPendingManagerApprovalsRq.queryKey,
    () => simpleQueryFn(adminPendingManagerApprovalsRq.path)
  );

  const adminPendingManagerUserRequestApprovalsRq =
    getAdminPendingManagerUserRequestApprovalsRq(
      ApprovalsDiscriminator.License
    );

  const getAdminPendingManagerUserRequestApprovalsQuery = useQuery<
    UserRequestDetailsVM[]
  >(adminPendingManagerUserRequestApprovalsRq.queryKey, () =>
    simpleQueryFn(adminPendingManagerUserRequestApprovalsRq.path)
  );

  const filterLicenseRequestsFromResourcesTable = (request) => {
    if (approvalItemType === ApprovalsDiscriminator.LearningResource) {
      return !request?.requestedApproval?.hasLicense;
    }
    return true;
  };

  const getCombinedAdminPendingManagerApprovalsQuery = parallelQueryResults({
    queries: [
      getAdminPendingManagerApprovalsQuery,
      getAdminPendingManagerUserRequestApprovalsQuery,
    ],
    sortBy: (a, b) =>
      new Date(a.requestedDate).getTime() - new Date(b.requestedDate).getTime(),
    filterBy: filterLicenseRequestsFromResourcesTable,
  });

  const getAdminUserRequestApprovalsQuery = useQuery<UserRequestDetailsVM[]>(
    adminUserRequestApprovalsRq.queryKey,
    () => simpleQueryFn(adminUserRequestApprovalsRq.path)
  );

  const getCombinedAdminApprovalsQuery = parallelQueryResults({
    queries: [getAdminApprovalsQuery, getAdminUserRequestApprovalsQuery],
    sortBy: (a, b) =>
      new Date(a.requestedDate).getTime() - new Date(b.requestedDate).getTime(),
    filterBy: filterLicenseRequestsFromResourcesTable,
  });
  useEffect(() => {
    if (selectedTabName) setSelectedApprovalStatus(parseInt(selectedTabName));
  }, [selectedTabName]);

  useEffect(() => {
    setApprovalsRq(
      resolveApprovalsRq(approvalItemType, selectedApprovalStatus)
    );
    setAdminUserRequestApprovalsRq(
      getAdminUserRequestApprovalsRq(
        ApprovalsDiscriminator.License,
        convertToApprovalStatus(selectedApprovalStatus)
      )
    );
  }, [approvalItemType, selectedApprovalStatus]);

  useEffect(() => {
    setApprovalItemType(getApprovalItemType(pageType));
  }, [pathname]);

  const adminUpdateApprovalMutation = useMutation(
    ({
      approvalId,
      approvalType,
      approvalStatus,
      notes,
      costCenter,
    }: {
      approvalId: number;
      approvalType: ApprovalType;
      approvalStatus: AdminScreenApprovalStatus;
      notes: string;
      costCenter;
    }) => {
      let approvalStatusParam: ApprovalStatus = ApprovalStatus.Approved;
      if (approvalStatus === AdminScreenApprovalStatus.Rejected) {
        approvalStatusParam = ApprovalStatus.Rejected;
      }
      const adminUpdateApprovalRm = getAdminUpdateUserRequestApprovalRm(
        approvalId,
        approvalType,
        approvalStatusParam,
        notes,
        costCenter
      );
      updateLoadingIds(approvalId);
      return simpleMutationFn<UserRequestApprovalVM>(
        adminUpdateApprovalRm.path,
        adminUpdateApprovalRm.payload
      );
    },
    {
      onSuccess: async (data: UserRequestApprovalVM) => {
        if (data.status == ApprovalStatus.Approved) notify.approveSuccessful();
        else if (data.status == ApprovalStatus.Rejected)
          notify.rejectSuccessful();
        await simpleInvalidateExactQueryFn(approvalsRq.queryKey);
        await simpleInvalidateExactQueryFn(
          adminUserRequestApprovalsRq.queryKey
        );
      },
      onError: () => {
        notify.approvalUpdateError();
      },
    }
  );

  const {data: costCentersList} = useGetCostCentersVMQuery(null, {
    enabled: isCostCenterOn,
  });
  const costCenters = costCentersList?.length ? [...costCentersList] : null;
  const isCostCenterAvailable = isCostCenterOn && costCenters;
  const costCenterOptions = useGetCostCenterOptions(costCenters);

  const updateCostCenterAsAdminMutation = useUpdateCostCenterAsAdmin({
    onSuccess: async () => {
      await simpleInvalidateExactQueryFn(approvalsRq.queryKey);
      await simpleInvalidateExactQueryFn(adminUserRequestApprovalsRq.queryKey);
    },
  });

  const updateCostCenter = async (
    costCenter: string,
    userRequestApprovalId: number,
    onSuccess?: () => void,
    onError?: () => void
  ) => {
    if (onSuccess) onSuccess();
    const payload: CostCenterOverridePayload = {
      costCenter,
      userRequestApprovalId,
    };
    return await updateCostCenterAsAdminMutation.mutateAsync(payload, {
      onError, // rollback to original if update failed
    });
  };

  const [isOverride, setIsOverride] = useState(false);

  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: true,
  });
  const exchangeRatesList = getCurrencyExchangeQuery?.data;
  // base columns
  let columns: IColumn[] = [
    {
      title:
        approvalItemType === ApprovalsDiscriminator.Program
          ? i18n.t(k.PROGRAM__NAME)
          : i18n.t(k.GENERIC__TITLE),
      dataIndex: 'title',
      key: 'title',
      render: (_, row: UserRequestApprovalVM) => {
        return (
          <TableCell>
            <Row align="middle">
              <Col xs={24} onClick={(e) => e.stopPropagation()}>
                {pageType.isProgramPage ? (
                  <ViewProgramLink id={row.itemId}>{row.title}</ViewProgramLink>
                ) : (
                  <Typography.Text className="program-title">
                    {row.title}
                  </Typography.Text>
                )}
                <br />
              </Col>
            </Row>
          </TableCell>
        );
      },
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: <>{i18n.t(k.NAME)}</>,
      dataIndex: 'requestedBy',
      key: 'requestedBy',
      sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy),
      render: (_, row: UserRequestApprovalVM) => {
        return (
          <TableCell>
            <Row align="middle">
              <Col xs={24} onClick={(e) => e.stopPropagation()}>
                <LinkStyledButton
                  onClick={() =>
                    navigate(`${AdminPaths.People}/${row.requestedById}`, {
                      state: {
                        from: `${location.pathname + location.search}`,
                      },
                    })
                  }>
                  {row.requestedBy}
                </LinkStyledButton>
              </Col>
            </Row>
          </TableCell>
        );
      },
    },
    {
      title: i18n.t(k.FUND__REQUEST__AMOUNT),
      align: ColumnAlignment.Right,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, row: ApprovalRequestRowVM) => {
        const exchangeRate = getExchangeRateOfCurrencyPair(
          exchangeRatesList,
          getUserQuery.data?.currency,
          applyLicenseCurrencyOrDefault(row)
        );
        return (
          <span data-cy="estimatedCost">
            {(approvalItemType as unknown as ApprovalsDiscriminatorWithTime) ===
            ApprovalsDiscriminatorWithTime.Time ? (
              `${amount} ${row.amountType}`.toLocaleLowerCase()
            ) : (
              <>
                {formatCurrency(
                  amount,
                  getUserQuery.data?.currency,
                  exchangeRate,
                  {
                    decimal: true,
                  }
                )}
                {!!row.amountOverLimit && (
                  <ExceedsRemainingBudgetWarning amount={row.balance} />
                )}
              </>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: i18n.t(k.DATE__REQUESTED),
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      sorter: dateTimeSorter('requestedDate'),
      defaultSortOrder: DefaultSortOrder.Descend,
      render: (requestedDate) =>
        moment(requestedDate).format(DATE_FORMAT.MONTH_DAY_YEAR),
    },
  ];

  // A La Carte Columns

  const costCenterColumn = {
    title: i18n.t(k.COST_CENTER),
    dataIndex: 'costCenter',
    key: 'provider',
    render: (provider, row) => {
      const originalValue = row.costCenter;
      const updateInlineValue = (value: string) => {
        row.costCenter = value;
      };
      return (
        <button onClick={(e) => e.stopPropagation()} style={{width: '100%'}}>
          <InlineSelect
            onSubmit={(value) =>
              updateCostCenter(
                value,
                row.id,
                () => updateInlineValue(value),
                () => updateInlineValue(originalValue)
              )
            }
            value={provider}
            options={costCenterOptions}
            readOnly={false}
            notFoundContent={i18n.t(k.GENERIC__NOT_FOUND)}
          />
        </button>
      );
    },
    sorter: stringSorter('provider'),
  };

  const typeColumn = {
    title: i18n.t(k.GENERIC__TYPE),
    dataIndex: 'userRequestType',
    key: 'userRequestType',
    render: (userRequestType: UserRequestType) =>
      getUserRequestType(userRequestType),
    sorter: (a, b) => a.userRequestType - b.userRequestType,
  };
  i18n.t(k.PROVIDER);
  const managerColumn = {
    title: i18n.t(k.GENERIC__MANAGER),
    dataIndex: 'manager',
    key: 'manager',
    render: (manager) => manager,
    sorter: (a, b) => a.manager?.localeCompare(b.manager),
  };
  i18n.t(k.GENERIC__REASON);
  const approveRejectColumn =
    (selectedApprovalStatus ===
      AdminScreenApprovalStatus.PendingManagerApproval && {
      title: <>{`${i18n.t(k.CTA__REJECT)} / ${i18n.t(k.CTA__APPROVE)}`}</>,
      dataIndex: '',
      fixed: 'right',
      key: '',
      render: (title, row: ApprovalRowVM) => {
        return (
          <LearnInButton
            tag={ButtonTags.Link}
            color={COLORS.Blue800}
            fontWeight="500"
            className="btn-admin-approval-view-details"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedApproval(row);
              setIsOverride(true);
              toggleDrawer(row.id);
            }}>
            {i18n.t(k.CTA__OVERRIDE)}
          </LearnInButton>
        );
      },
      sorter: null,
    }) ||
    null;

  const viewDetailsColumn: IColumn = {
    title: '',
    dataIndex: '',
    fixed: 'right',
    key: '',
    render: (_, row) => (
      <LearnInButton
        tag={ButtonTags.Link}
        color={COLORS.Blue800}
        fontSize={'1rem'}
        fontWeight={'500'}
        hoverUnderline
        className="btn-admin-approval-view-details"
        data-cy="viewDetails"
        onClick={(e) => {
          e.stopPropagation();
          if (loadingIds.includes(row.id)) return;
          setSelectedApproval(row);
          setIsOverride(false);
          toggleDrawer(row.id);
        }}>
        {selectedApprovalStatus === AdminScreenApprovalStatus.Requested
          ? i18n.t(k.GENERIC__REVIEW)
          : i18n.t(k.GENERIC__DETAIL__PLURAL)}
        <Icon icon={<ArrowRightOutlined />} />
      </LearnInButton>
    ),
    sorter: null,
  };

  const resourcesApprovalColumns: IColumn[] = [
    {
      title: <>{i18n.t(k.NAME)}</>,
      dataIndex: 'requestedBy',
      key: 'requestedBy',
      render: (_, row: ResourceApprovalRequest) => {
        return (
          <TableCell>
            <Row align="middle">
              <Col xs={24} onClick={(e) => e.stopPropagation()}>
                <LinkStyledButton
                  onClick={() =>
                    navigate(`${AdminPaths.People}/${row.requestedById}`, {
                      state: {
                        from: `${location.pathname + location.search}`,
                      },
                    })
                  }>
                  {row.requestedBy}
                </LinkStyledButton>
              </Col>
            </Row>
          </TableCell>
        );
      },
      sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy),
    },
    {
      title: i18n.t(k.DATE__REQUESTED),
      dataIndex: 'requestedDate',
      key: 'requestedDate',
      sorter: (date1, date2) => {
        const dateObject1 = new Date(date1.requestedDate);
        const dateObject2 = new Date(date2.requestedDate);
        if (dateObject1 < dateObject2) {
          return -1;
        }
        if (dateObject1 > dateObject2) {
          return 1;
        }
        return 0;
      },
      defaultSortOrder: DefaultSortOrder.Descend,
      render: (requestedDate) =>
        moment(requestedDate).format(DATE_FORMAT.MONTH_DAY_YEAR),
    },
    {
      title: i18n.t(k.RESOURCE),
      dataIndex: 'title',
      key: 'title',
      render: (_, row: UserRequestApprovalVM) => {
        return localizeResourceType(row.title);
      },
      sorter: (a, b) => a.requestedBy.localeCompare(b.requestedBy),
    },
    {
      title: i18n.t(k.FUND__REQUEST__AMOUNT),
      align: ColumnAlignment.Right,
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, row: ApprovalRequestDetailsVM) => {
        return (
          <span data-cy="estimatedCost">
            {pageType.isTimePage ? (
              `${amount} ${row.requestedApproval.amountType}`.toLocaleLowerCase()
            ) : (
              <>
                {formatCurrency(
                  amount,
                  getUserQuery.data?.currency,
                  exchangeRate,
                  {
                    decimal: true,
                  }
                )}
                {!!row.parentApproval?.amountOverLimit && (
                  <ExceedsRemainingBudgetWarning
                    amount={row.parentApproval?.balance}
                  />
                )}
              </>
            )}
          </span>
        );
      },
      sorter: (a, b) => a.amount - b.amount,
    },
    isLearnInReimbursementsOn && {
      title: i18n.t(k.GENERIC__TYPE),
      dataIndex: 'userRequestType',
      key: 'userRequestType',
      render: (userRequestType: UserRequestType) =>
        getUserRequestType(userRequestType),
      sorter: (a, b) => a.userRequestType - b.userRequestType,
    },
  ];

  //Make Type column visible in all tables
  if (isLearnInReimbursementsOn) columns = [...columns, typeColumn];

  if (
    approvalItemType === ApprovalsDiscriminator.Program &&
    isCostCenterAvailable
  ) {
    columns = [...columns, costCenterColumn];
  }

  if (
    selectedApprovalStatus == AdminScreenApprovalStatus.Approved ||
    selectedApprovalStatus == AdminScreenApprovalStatus.Rejected
  ) {
    columns.push(viewDetailsColumn);
    resourcesApprovalColumns.push(viewDetailsColumn);
  } else if (
    selectedApprovalStatus == AdminScreenApprovalStatus.PendingManagerApproval
  ) {
    columns.push(managerColumn, approveRejectColumn);
    resourcesApprovalColumns.push(managerColumn, approveRejectColumn);
  } else {
    columns.push(approveRejectColumn, viewDetailsColumn);
    resourcesApprovalColumns.push(approveRejectColumn, viewDetailsColumn);
  }

  const changeApprovalStatus = (key: string) => {
    let newValue: AdminScreenApprovalStatus;
    switch (key) {
      case AdminScreenApprovalStatus.Approved.toString():
        toggleTab(AdminScreenApprovalStatus.Approved);
        newValue = AdminScreenApprovalStatus.Approved;
        break;
      case AdminScreenApprovalStatus.Rejected.toString():
        toggleTab(AdminScreenApprovalStatus.Rejected);
        newValue = AdminScreenApprovalStatus.Rejected;
        break;
      case AdminScreenApprovalStatus.PendingManagerApproval.toString():
        toggleTab(AdminScreenApprovalStatus.PendingManagerApproval);
        newValue = AdminScreenApprovalStatus.PendingManagerApproval;
        break;
      case AdminScreenApprovalStatus.Requested.toString():
      default:
        toggleTab(AdminScreenApprovalStatus.Requested);
        newValue = AdminScreenApprovalStatus.Requested;
        break;
    }
  };

  const getTableDataSource = useMemo(() => {
    let data: [];
    const searchParameters = ['title', 'requestedBy', 'provider'];
    switch (selectedApprovalStatus) {
      case AdminScreenApprovalStatus.PendingManagerApproval:
        data = getCombinedAdminPendingManagerApprovalsQuery.data.map((a) => {
          a.requestedApproval.parentApproval = a.parentApproval;
          return a.requestedApproval;
        });
        //return the result
        return filterQuery(data, programSearchText, searchParameters);
      case AdminScreenApprovalStatus.Requested:
      default:
        data = getCombinedAdminApprovalsQuery.data.map((a) => {
          a.requestedApproval.parentApproval = a.parentApproval;
          return a.requestedApproval;
        });
        return filterQuery(data, programSearchText, searchParameters);
    }
  }, [
    programSearchText,
    selectedApprovalStatus,
    getCombinedAdminPendingManagerApprovalsQuery.data,
    getCombinedAdminApprovalsQuery.data,
  ]);

  useEffect(() => {
    getTableDataSource.filter((item) => {
      if (item.id === parseInt(openDrawerId)) setSelectedApproval(item);
    });
  }, [openDrawerId, getTableDataSource]);

  const handleSearch = (e) => {
    const searchText = e.target.value;
    searchWithDebounce(searchText);
  };

  const searchWithDebounce = debounce((searchText) => {
    setProgramSearchText(searchText);
  }, 500);

  const commonRefetch = async () => {
    await getCombinedAdminPendingManagerApprovalsQuery.refetch();
    await getCombinedAdminApprovalsQuery.refetch();
  };

  const commonResetDrawer = async () => {
    await commonRefetch();
  };
  const closeDrawer = () => {
    toggleDrawer(null);
    setSelectedApproval(null);
    commonResetDrawer();
  };

  const downloadCSV = () => {
    api
      .get('report/get-reimbursements-export', {
        headers: {
          'Content-Type': 'text/csv',
          Accept: 'text/csv',
        },
        responseType: 'blob',
      })
      .then((res) => {
        if (res.status === 200) {
          const blob = new Blob([res.data], {type: 'text/csv;charset=utf-8;'});
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.setAttribute('href', blobUrl);
          link.setAttribute(
            'download',
            `${i18n.t(k.REIMBURSEMENT)}${i18n.t(
              k.CTA__EXPORT
            )}_${moment().format(DATE_FORMAT.TIMESTAMP_AM_PM)}.csv`
          );
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (res.status === 204) {
          notify.emptyReportSuccess();
        }
      });
  };

  let pageTitle: string;
  switch (approvalItemType as unknown as ApprovalsDiscriminatorWithTime) {
    case ApprovalsDiscriminatorWithTime.Time:
      pageTitle = i18n.t(k.APPROVAL__TIME__PLURAL);
      break;
    case ApprovalsDiscriminatorWithTime.Incentive:
      pageTitle = i18n.t(k.APPROVAL__FINANCE__PLURAL);
      break;
    case ApprovalsDiscriminatorWithTime.LearningResource:
      pageTitle = i18n.t(k.APPROVAL__RESOURCE__PLURAL);
      break;
    case ApprovalsDiscriminatorWithTime.Program:
    default:
      pageTitle = i18n.t(k.APPROVAL__PROGRAM__PLURAL);
      break;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageTitleBar fullWidth={true} title={pageTitle} />
      <div>
        {selectedApproval && (
          <Row>
            <AdminPreApprovalDrawer
              selectedApproval={selectedApproval}
              approvalItemType={approvalItemType}
              approvalId={selectedApproval?.id}
              approvalType={selectedApproval?.type}
              showPreApprovalDrawer={
                parseInt(openDrawerId) === selectedApproval?.id ||
                !!selectedApproval?.id
              }
              isReadonly={
                selectedApprovalStatus !==
                  AdminScreenApprovalStatus.Requested &&
                selectedApprovalStatus !==
                  AdminScreenApprovalStatus.PendingManagerApproval
              }
              isOverride={isOverride}
              activeTab={selectedApprovalStatus}
              updateCostCenter={updateCostCenter}
              selectedCostCenter={selectedApproval?.costCenter}
              onOk={closeDrawer}
              onCancel={closeDrawer}
              licenseId={
                selectedApproval &&
                ('licenseId' in selectedApproval
                  ? selectedApproval?.licenseId
                  : null)
              }
              userRequestType={
                selectedApproval &&
                ('userRequestType' in selectedApproval
                  ? getUserRequestType(selectedApproval?.userRequestType)
                  : null)
              }
            />
          </Row>
        )}
        <Row>
          <StyledCol xs={24}>
            <LearnInTabs
              style={{width: '100%'}}
              defaultActiveKey={AdminScreenApprovalStatus.Requested.toString()}
              activeKey={
                selectedApprovalStatus === AdminScreenApprovalStatus.Requested
                  ? AdminScreenApprovalStatus.Requested.toString()
                  : selectedApprovalStatus.toString()
              }
              tabBarGutter={50}
              onChange={changeApprovalStatus}>
              <Tabs.TabPane
                tab={i18n.t(k.REQUEST__PLURAL)}
                key={AdminScreenApprovalStatus.Requested}
              />
              <Tabs.TabPane
                tab={i18n.t(k.STATUS__APPROVED)}
                key={AdminScreenApprovalStatus.Approved.toString()}
              />
              <Tabs.TabPane
                tab={i18n.t(k.STATUS__DECLINED)}
                key={AdminScreenApprovalStatus.Rejected.toString()}
              />
              <Tabs.TabPane
                tab={i18n.t(k.STATUS__PENDING_MANAGER_REVIEW)}
                key={AdminScreenApprovalStatus.PendingManagerApproval.toString()}
              />
            </LearnInTabs>
            {selectedApprovalStatus == AdminScreenApprovalStatus.Approved &&
              approvalItemType === ApprovalsDiscriminator.Incentive && (
                <LearnInButton
                  showCloudIcon
                  tag={ButtonTags.Primary}
                  style={{
                    height: '42px',
                    width: '185px',
                    position: 'absolute',
                    bottom: '27px',
                    right: '15px',
                  }}
                  onClick={() => {
                    downloadCSV();
                  }}>
                  {i18n.t(k.FILE__EXPORT__TO_CSV)}
                </LearnInButton>
              )}
          </StyledCol>
          <Input.Search
            allowClear
            onChange={handleSearch}
            placeholder={i18n.t(k.APPROVAL__PLURAL__SEARCH)}
            style={{width: '400px', padding: '10px 32px'}}
          />
          <Col xs={24} md={24}>
            {((!isPendingManagerApprovalScreen &&
              getCombinedAdminApprovalsQuery.isSuccess &&
              getTableDataSource.length > 0) ||
              (isPendingManagerApprovalScreen &&
                getCombinedAdminPendingManagerApprovalsQuery.isSuccess &&
                getTableDataSource.length > 0)) && (
              <LearnInContainer tag={ContainerTags.TableBottomPadding}>
                <LearnInTable
                  dataSource={getTableDataSource}
                  scroll={{x: 'max-content'}}
                  columns={
                    approvalItemType === ApprovalsDiscriminator.LearningResource
                      ? resourcesApprovalColumns
                      : columns
                  }
                  onClickRow={(
                    record: UserRequestApprovalVM | ResourceApprovalRequest
                  ) => {
                    if (
                      (approvalItemType !== ApprovalsDiscriminator.Program &&
                        approvalItemType !==
                          ApprovalsDiscriminator.LearningResource) ||
                      selectedApprovalStatus !==
                        AdminScreenApprovalStatus.Requested
                    ) {
                      return;
                    }
                    if (loadingIds.includes(record.id)) {
                      return;
                    }
                    setSelectedApproval(record);
                    setIsOverride(false);
                    toggleDrawer(record.id);
                  }}
                />
              </LearnInContainer>
            )}
            {/* only display loading animation/icon when data is fetching and there isn't any data already loaded to prevent bounce */}
            {((!isPendingManagerApprovalScreen &&
              getCombinedAdminApprovalsQuery.isFetching &&
              !getCombinedAdminApprovalsQuery.data.length) ||
              (isPendingManagerApprovalScreen &&
                getCombinedAdminPendingManagerApprovalsQuery.isFetching &&
                !getCombinedAdminPendingManagerApprovalsQuery.data
                  ?.length)) && <LoadingSpinner />}
            {!isPendingManagerApprovalScreen &&
              getTableDataSource.length === 0 &&
              getCombinedAdminApprovalsQuery.isSuccess && (
                <>
                  {programSearchText !== '' ? (
                    <NoDataText
                      header={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
                      subHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION)}
                    />
                  ) : (
                    <>
                      {pageType.isFinancePage && (
                        <>
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Rejected && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__REJECTED__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__REJECTED__DESCRIPTION
                              )}
                            />
                          )}
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Approved && (
                            <NoDataText
                              header={i18n.t(
                                k.APPROVAL__PENDING_FINANCE__TITLE
                              )}
                              subHeader={i18n.t(
                                k.APPROVAL__PENDING__DESCRIPTION
                              )}
                            />
                          )}
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Requested && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__PENDING__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__PENDING__DESCRIPTION
                              )}
                            />
                          )}
                        </>
                      )}
                      {pageType.isTimePage && (
                        <>
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Rejected && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__REJECTED_TIME__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__REJECTED_TIME__DESCRIPTION__COMPANY
                              )}
                            />
                          )}
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Approved && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__APPROVED_TIME__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__APPROVED_TIME__DESCRIPTION__COMPANY
                              )}
                            />
                          )}
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Requested && (
                            <NoDataText
                              header={i18n.t(
                                k.APPROVAL__REQUESTED_TIME__TITLE__COMPANY
                              )}
                              subHeader={i18n.t(
                                k.APPROVAL__REQUESTED_TIME__DESCRIPTION__COMPANY
                              )}
                            />
                          )}
                        </>
                      )}
                      {pageType.isProgramPage && (
                        <>
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Rejected && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__REJECTED__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__REJECTED__DESCRIPTION
                              )}
                            />
                          )}
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Approved && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__APPROVED__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__APPROVED__DESCRIPTION
                              )}
                            />
                          )}
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Requested && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__PENDING__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__PENDING__DESCRIPTION
                              )}
                            />
                          )}
                        </>
                      )}
                      {pageType.isResourcePage && (
                        <>
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Rejected && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__REJECTED__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__REJECTED__DESCRIPTION
                              )}
                            />
                          )}
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Approved && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__APPROVED__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__APPROVED__DESCRIPTION
                              )}
                            />
                          )}
                          {selectedApprovalStatus ===
                            AdminScreenApprovalStatus.Requested && (
                            <NoDataText
                              header={i18n.t(k.APPROVAL__PENDING__TITLE)}
                              subHeader={i18n.t(
                                k.APPROVAL__PENDING__DESCRIPTION
                              )}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            {isPendingManagerApprovalScreen &&
              getTableDataSource.length === 0 && (
                <>
                  {programSearchText !== '' ? (
                    <NoDataText
                      header={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
                      subHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION)}
                    />
                  ) : (
                    <>
                      {pageType.isFinancePage && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__PENDING_FINANCE__TITLE)}
                          subHeader={i18n.t(
                            k.APPROVAL__PENDING_FINANCE__DESCRIPTION
                          )}
                        />
                      )}
                      {pageType.isTimePage && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__PENDING_TIME__TITLE)}
                          subHeader={i18n.t(
                            k.APPROVAL__PENDING_TIME__DESCRIPTION
                          )}
                        />
                      )}
                      {(pageType.isProgramPage || pageType.isResourcePage) && (
                        <NoDataText
                          header={i18n.t(k.APPROVAL__PENDING__TITLE)}
                          subHeader={i18n.t(k.APPROVAL__PENDING__DESCRIPTION)}
                        />
                      )}
                    </>
                  )}
                </>
              )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AdminApprovals;
