import {datadogLogs} from '@datadog/browser-logs';
import {AcademyStepType} from '@generated/enums';
import {i18n, k} from '@i18n/translate';

export interface AcademyStepLabels {
  editLabel: string;
  deleteLabel: string;
  deletePrompt: string;
  label: string;
}

export const useGetLabelsByAcademyStepType = (
  academyStep: AcademyStepType
): AcademyStepLabels => {
  const programLabels = {
    editLabel: k.PROGRAM__EDIT,
    deleteLabel: k.PROGRAM__DELETE,
    deletePrompt: k.PROGRAM__DELETE__PROMPT,
    label: k.PROGRAM,
  };

  const content = {
    [AcademyStepType.MarketplaceProgram]: {
      ...programLabels,
    },
    [AcademyStepType.CustomProgram]: {
      ...programLabels,
    },
    [AcademyStepType.TextContent]: {
      editLabel: k.CONTENT__EDIT,
      deleteLabel: k.CONTENT__DELETE,
      deletePrompt: k.CONTENT__DELETE__PROMPT,
      label: k.CONTENT,
    },
    [AcademyStepType.ProjectContent]: {
      editLabel: k.PROJECT__EDIT,
      deleteLabel: k.PROJECT__DELETE,
      deletePrompt: k.PROJECT__DELETE__PROMPT,
      label: k.PROJECT,
    },
    [AcademyStepType.EventContent]: {
      editLabel: k.EVENT__EDIT,
      deleteLabel: k.EVENT__DELETE,
      deletePrompt: k.EVENT__DELETE__PROMPT,
      label: k.EVENT,
    },
    [AcademyStepType.Discussion]: {
      editLabel: k.DISCUSSION__EDIT,
      deleteLabel: k.DISCUSSION__DELETE,
      deletePrompt: k.EVENT__DISCUSSION__PROMPT,
      label: k.DISCUSSION,
    },
    [AcademyStepType.LxpContent]: {
      editLabel: k.CONTENT__EDIT,
      deleteLabel: k.CONTENT__DELETE,
      deletePrompt: k.CONTENT__DELETE__PROMPT,
      label: k.CONTENT,
    },
  };

  const labels = content[academyStep];

  if (!labels) {
    datadogLogs.logger.error('Error getting academy step type name', {
      academyStep: academyStep,
    });
  }

  const getLabel = (label: string) => (labels ? i18n.t(labels[label]) : '');

  return {
    editLabel: getLabel('editLabel'),
    deleteLabel: getLabel('deleteLabel'),
    deletePrompt: getLabel('deletePrompt'),
    label: getLabel('label'),
  };
};
