import {AcademyUserStatus} from './../enums';
import {IAcademyUserBasicInfo} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: IAcademyUserBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIAcademyUserBasicInfoMockData(
  args?: Partial<IAcademyUserBasicInfo>
): IAcademyUserBasicInfo {
  return {
    academyId: faker.datatype.number(),
    status: AcademyUserStatus.InProgress,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IAcademyUserBasicInfoMockData: IAcademyUserBasicInfo = {
  academyId: 1,
  status: AcademyUserStatus.InProgress,
};
