import {Container, ToggleButton} from './LearnInToggle.styled';
import {LearnInToggleViewProps} from './LearnInToggle.interfaces';
import identity from 'lodash/identity';

function LearnInToggleView({
  options,
  onToggle,
  formatLabel = identity,
}: LearnInToggleViewProps) {
  return (
    <Container>
      {options?.map((option) => (
        <ToggleButton
          key={option.id || option.value}
          isSelected={option.isSelected}
          onClick={() => {
            onToggle(option);
          }}>
          {formatLabel(option.label)}
        </ToggleButton>
      ))}
    </Container>
  );
}

export default LearnInToggleView;
