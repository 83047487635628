import {
  CurrencyCode,
  LicenseRedemptionType,
  LicenseStatus,
  LicenseUsageType,
  VoucherAssignMode,
} from '@generated/enums';
import {ProgramFilterVM} from '@generated/interfaces';
import {LicenseAddOrUpdate} from '@models/clientModels';
import {
  getAddLicenseRm,
  getLicenseRq,
  getUpdateLicenseRm,
} from '@store/apiEndpoints';
import {getProvidersRq} from '@store/apiEndpoints/program/queries';
import {simpleMutationFn, simplePutFn, simpleQueryFn} from '@store/queryClient';
import {FormInstance} from 'antd';
import {useEffect, useMemo} from 'react';
import {useMutation, useQuery} from 'react-query';
import {
  handleOnSaveLicenseError,
  handleOnSaveLicenseSuccess,
} from './AddUpdateLicenseDrawer.handlers';
import {
  LicenseDuration,
  LicenseFormFieldErrors,
  LicenseFormFields,
  LicenseInternalCost,
  AddUpdateLicenseVM,
} from './AddUpdateLicenseDrawer.types';
import {
  getInternalCost,
  getInternalCostType,
  getLicenseCurrency,
  getLicenseDuration,
  getLicenseDurationType,
  getLicenseInternalCost,
  getLicenseStatus,
  getLicenseType,
  getRequireAdminApproval,
  getRequireLicenseDurationType,
  getRequireManagerApproval,
} from './AddUpdateLicenseDrawer.utils';
import {validateLicenseFormHasErrors} from './AddUpdateLicenseDrawer.validation';

export const useGetProvidersQuery = () => {
  const providersRq = getProvidersRq();
  const providersQuery = useQuery<ProgramFilterVM[]>(providersRq.queryKey, () =>
    simpleQueryFn(providersRq.path)
  );
  return providersQuery;
};

export const useGetLicenseQuery = (id: number) => {
  const licenseRq = getLicenseRq(id);
  const licenseQuery = useQuery<AddUpdateLicenseVM>(
    licenseRq.queryKey,
    () => simpleQueryFn(licenseRq.path),
    {
      enabled: !!id,
    }
  );
  return licenseQuery;
};

export const useSaveLicenseMutation = (
  refreshParentData: () => void,
  onClose: () => void,
  setA11yAlertMessageSuccess?: () => void,
  setA11yAlertMessageError?: () => void
) =>
  useMutation(
    (payload: LicenseAddOrUpdate) => {
      const isUpdating = !!payload.id;
      if (isUpdating) {
        const updateLicenseRm = getUpdateLicenseRm(payload.id, payload);
        return simplePutFn<null>(updateLicenseRm.path, updateLicenseRm.payload);
      }
      const saveLicenseRm = getAddLicenseRm(payload);
      return simpleMutationFn<null>(saveLicenseRm.path, saveLicenseRm.payload);
    },
    {
      onSuccess: () => {
        handleOnSaveLicenseSuccess(refreshParentData, onClose);
        setA11yAlertMessageSuccess?.();
      },
      onError: () => {
        handleOnSaveLicenseError();
        setA11yAlertMessageError?.();
      },
    }
  );

export const useGetProviderSuggestions = (
  providersQueryData: ProgramFilterVM[]
) => {
  const getProviderSuggestions = ({id, description}: ProgramFilterVM) => ({
    id,
    label: description,
    value: description,
  });

  return useMemo(
    () => providersQueryData?.map(getProviderSuggestions) || [],
    [providersQueryData]
  );
};

export const useGetDefaultLicenseFormValues = ({
  approvalGuideline,
  companyId,
  currency,
  internalCost,
  internalCostType,
  license,
  licenseDuration,
  providerId,
  redemptionGuideline,
  redemptionType,
  status,
  type,
  voucherAssignMode,
}: {
  approvalGuideline: string;
  companyId: number;
  currency: CurrencyCode;
  internalCost: number;
  internalCostType: LicenseInternalCost;
  license: AddUpdateLicenseVM;
  licenseDuration: number;
  providerId: number;
  redemptionGuideline: string;
  redemptionType: LicenseRedemptionType;
  status: LicenseStatus;
  type: LicenseUsageType;
  voucherAssignMode: VoucherAssignMode;
}) => {
  return {
    [LicenseFormFields.CompanyId]: companyId,
    [LicenseFormFields.Currency]: currency,
    [LicenseFormFields.Id]: license?.id,
    [LicenseFormFields.ProviderId]: providerId,
    [LicenseFormFields.InternalCost]: internalCost,
    [LicenseFormFields.InternalCostType]: internalCostType,
    [LicenseFormFields.Status]: status,
    [LicenseFormFields.Type]: type,
    [LicenseFormFields.LicenseDuration]: licenseDuration,
    [LicenseFormFields.LicenseDurationType]: getLicenseDurationType(license),
    [LicenseFormFields.RequireManagerApproval]:
      getRequireManagerApproval(license),
    [LicenseFormFields.RequireAdminApproval]: getRequireAdminApproval(license),
    [LicenseFormFields.RedemptionGuideline]: redemptionGuideline,
    [LicenseFormFields.RedemptionType]: redemptionType,
    [LicenseFormFields.ApprovalGuideline]: approvalGuideline,
    [LicenseFormFields.VoucherAssignMode]: voucherAssignMode,
  };
};

export const useLicenseFormHasErrorsEffect = (
  formFieldErrors: LicenseFormFieldErrors,
  setFormHasErrors: (isValid: boolean) => void
) =>
  useEffect(() => {
    setFormHasErrors(validateLicenseFormHasErrors(formFieldErrors));
  }, [formFieldErrors, setFormHasErrors]);

export const useLicenseFormUpdatesEffect = ({
  currency,
  form,
  license,
  setApprovalGuideline,
  setInternalCost,
  setInternalCostType,
  setLicenseDuration,
  setLicenseDurationType,
  setProviderId,
  setRedemptionGuideline,
  setRedemptionType,
  setRequireAdminApproval,
  setRequireManagerApproval,
  setStatus,
  setType,
  setVoucherAssignMode,
}: {
  currency: CurrencyCode;
  form: FormInstance;
  license: AddUpdateLicenseVM;
  setApprovalGuideline: (guideline: string) => void;
  setInternalCost: (cost: number) => void;
  setInternalCostType: (costType: LicenseInternalCost) => void;
  setLicenseDuration: (duration: number) => void;
  setLicenseDurationType: (durationType: LicenseDuration) => void;
  setProviderId: (providerId: number) => void;
  setRedemptionGuideline: (guideline: string) => void;
  setRedemptionType: (redemptionType: LicenseRedemptionType) => void;
  setRequireAdminApproval: (requireAdminApproval: boolean) => void;
  setRequireManagerApproval: (requireManagerApproval: boolean) => void;
  setStatus: (status: LicenseStatus) => void;
  setType: (type: LicenseUsageType) => void;
  setVoucherAssignMode: (mode: VoucherAssignMode) => void;
}) =>
  useEffect(() => {
    setProviderId(license?.providerId);
    setInternalCostType(getInternalCostType(license));
    setInternalCost(getInternalCost(license));
    setStatus(getLicenseStatus(license));
    setType(getLicenseType(license));
    setLicenseDuration(getLicenseDuration(license));
    setLicenseDurationType(getRequireLicenseDurationType(license));
    setRequireManagerApproval(getRequireManagerApproval(license));
    setRequireAdminApproval(getRequireAdminApproval(license));
    setRedemptionGuideline(license?.[LicenseFormFields.RedemptionGuideline]);
    setRedemptionType(license?.[LicenseFormFields.RedemptionType]);
    setApprovalGuideline(license?.[LicenseFormFields.ApprovalGuideline]);
    setVoucherAssignMode(license?.[LicenseFormFields.VoucherAssignMode]);

    if (license) {
      form.setFieldsValue({
        [LicenseFormFields.ProviderId]: license.providerId,
        [LicenseFormFields.InternalCost]: getLicenseInternalCost(license),
        [LicenseFormFields.Currency]: getLicenseCurrency(license, currency),
        [LicenseFormFields.InternalCostType]: getInternalCostType(license),
        [LicenseFormFields.Status]: getLicenseStatus(license),
        [LicenseFormFields.Type]: getLicenseType(license),
        [LicenseFormFields.LicenseDuration]: getLicenseDuration(license),
        [LicenseFormFields.LicenseDurationType]:
          getLicenseDurationType(license),
        [LicenseFormFields.RequireManagerApproval]:
          license.requireManagerApproval,
        [LicenseFormFields.RequireAdminApproval]: license.requireAdminApproval,
        [LicenseFormFields.RedemptionGuideline]:
          license?.[LicenseFormFields.RedemptionGuideline],
        [LicenseFormFields.RedemptionType]:
          license?.[LicenseFormFields.RedemptionType],
        [LicenseFormFields.ApprovalGuideline]:
          license?.[LicenseFormFields.ApprovalGuideline],
        [LicenseFormFields.VoucherAssignMode]:
          license?.[LicenseFormFields.VoucherAssignMode],
      });
    }
  }, [
    currency,
    form,
    license,
    setApprovalGuideline,
    setInternalCost,
    setInternalCostType,
    setLicenseDuration,
    setLicenseDurationType,
    setProviderId,
    setRedemptionGuideline,
    setRedemptionType,
    setRequireAdminApproval,
    setRequireManagerApproval,
    setStatus,
    setType,
    setVoucherAssignMode,
  ]);

/**
 * If any field needs to be manually synced between form & state, use this
 **/
export const useLicenseFormForceValueSetEffect = (
  internalCostType: LicenseInternalCost,
  form: FormInstance
) => {
  /* When switching between "Type of Funding" options, the Internal Cost Type
 value is not updated inside AntD's form instance, automatically. This causes
 bugs if left unaddressed. So, when internalCostType changes, forcing a 
 setFieldValue on the field keeps the value in sync between state and form. */
  useEffect(() => {
    if (internalCostType === LicenseInternalCost.Fixed)
      form.setFieldValue(LicenseFormFields.InternalCostType, internalCostType);
  }, [form, internalCostType]);
};
