import {CreateCustomProgramMessageRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {CreateCustomProgramMessageRequestSchema} from '../zodSchemas/CreateCustomProgramMessageRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Program/CustomProgramMessagesController.cs

/*
|--------------------------------------------------------------------------
|  {customProgramId}/sections/{sectionId}/contents/{contentId}/discussions/{discussionId}/messages 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation} from '@generated/hooks';
 *
 *  const customProgramsSectionsContentsDiscussionsMessagesPost = useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  customProgramsSectionsContentsDiscussionsMessagesPost.mutate({
 *    payload: {
 *      ...CreateCustomProgramMessageRequest
 *    },
 *    pathVars: {
 *      contentId,
 *      customProgramId,
 *      discussionId,
 *      sectionId
 *    }
 *  })
 *
 */

export const useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation =
  mutationHookFactory<
    CreateCustomProgramMessageRequest, // payload
    {
      contentId: number;
      customProgramId: number;
      discussionId: string;
      sectionId: number;
    }, // path variables
    null
  >(
    'useCustomProgramsSectionsContentsDiscussionsMessagesPostMutation',
    'post',
    ({customProgramId, sectionId, contentId, discussionId}) =>
      `custom-programs/${customProgramId}/sections/${sectionId}/contents/${contentId}/discussions/${discussionId}/messages`,
    {payloadSchema: CreateCustomProgramMessageRequestSchema}
  );
