import React from 'react';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import t from '@i18n/translate';
import {SendOutlined} from '@ant-design/icons';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {
  FooterContainer,
  ParticipantsCountTag,
} from './NudgeEmailPreview.styled';
import UsersMultiple from '@assets/svg/UsersMultiple';

interface Props {
  canSaveChanges: boolean;
  canSendNudge: boolean;
  onSaveChanges: () => void;
  onSendNudge: () => void;
  showParticipantCount?: boolean;
  participantCount?: number;
}

const NudgeEmailPreviewFooter: React.FC<Props> = ({
  canSaveChanges,
  canSendNudge,
  onSaveChanges,
  onSendNudge,
  showParticipantCount,
  participantCount,
}) => {
  return (
    <FooterContainer>
      <section>
        {showParticipantCount && canSendNudge && (
          <ParticipantsCountTag>
            <UsersMultiple />
            <span
              style={{marginLeft: 8}}
              dangerouslySetInnerHTML={{
                __html: t('NUDGE_EMAIL__PARTICIPANTS_COUNT__FORMAT', {
                  count: participantCount?.toString(),
                }),
              }}
            />
          </ParticipantsCountTag>
        )}
      </section>
      <div className="btn-group">
        {canSendNudge ? (
          <LearnInButton onClick={onSendNudge} tag={ButtonTags.Primary}>
            {t('CTA__SEND_NUDGE')}
            <SendOutlined color={COLORS.White} />
          </LearnInButton>
        ) : (
          <LearnInButton
            onClick={onSaveChanges}
            tag={ButtonTags.Primary}
            disabled={!canSaveChanges}>
            {t('CTA__SAVE_CHANGES')}
          </LearnInButton>
        )}
      </div>
    </FooterContainer>
  );
};

export default NudgeEmailPreviewFooter;
