import {UserDetailsVM} from '@generated/interfaces';
import {useEffect} from 'react';
import useSearchParamUtils from '@hooks/useSearchParamUtils';
import {QueryClient} from 'react-query';
import {UserPaths} from '@utils/ClientPaths';
import {LxpPaths} from '@utils/lxpPaths';
import useFeatureFlags from '../useFeatureFlags';
import {useLxpDomain} from './useGetLxpDomain';
import {useIsIntegratedUser} from './useIsIntegratedUser';
import {INTEGRATED_INCOMING_LXP_USER} from '@utils/constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
    },
  },
});

interface UrlData {
  type: 'academy' | 'plan' | 'program' | 'custom-program' | 'settings';
  resourceId?: number | string;
  isInternalLink?: boolean;
}

export const useBackToLxpLink = (user: UserDetailsVM, urlData: UrlData) => {
  const {isFeatureFlagOn} = useFeatureFlags();
  const {type, isInternalLink, resourceId} = urlData;
  const isIntegratedUser = useIsIntegratedUser(
    isFeatureFlagOn.UpdatedLxpToCmFlow,
    user
  );

  // Create a unique store key for each resource to save lxp path
  const storeKey = `${type}${resourceId ? '-' + resourceId : ''}`;

  // Get the search params from the url
  const {
    searchParams: {srcpath: lxpReturnPath, source, ssoprovider},
    updateSearchParamsAndNavigate,
  } = useSearchParamUtils();

  // Use nav params or feature flag to determine shared Lxp and ACM user flow
  // until a better solution is in place
  const acmNavFlags =
    isFeatureFlagOn.LxpToCmFlow ||
    isFeatureFlagOn.AcademyAndCpLxpFlow ||
    isIntegratedUser;

  const fromLxpParam = source === 'lxp' || ssoprovider === 'degreed';

  const isAnLxpUser = acmNavFlags || fromLxpParam;

  const incomingFromIntegratedLxp =
    fromLxpParam && !user?.isAcademiesIntegratedExperienceDisabled;
  if (incomingFromIntegratedLxp) {
    sessionStorage.setItem(INTEGRATED_INCOMING_LXP_USER, 'true');
  }

  // Check if back link should goto an internal ACM link vs the LXP
  const allowInternalLink = isInternalLink && acmNavFlags;

  const lxpDomain = useLxpDomain();

  useEffect(() => {
    if (isAnLxpUser && !getLxpPathFromStore(storeKey)) {
      const returnPath = getReturnPath(
        (lxpReturnPath as string) ?? '',
        lxpDomain
      );
      saveLxpPathToStore(storeKey, returnPath);

      // Except on plan, remove the search params for srcpath and source.
      // Plan requires more places to check the hash than just this,
      // so prematurely removing the search params here prevents
      // proper rendering.
      const isPlan = location.hash.indexOf(UserPaths.ManagePlan) > -1;
      if (!isPlan) {
        updateSearchParamsAndNavigate(
          {srcpath: undefined, source: undefined},
          {replace: true}
        );
      }
    }
  }, [
    isAnLxpUser,
    lxpReturnPath,
    source,
    ssoprovider,
    storeKey,
    updateSearchParamsAndNavigate,
  ]);

  return allowInternalLink ? undefined : getLxpPathFromStore(storeKey);
};

const getLxpPathFromStore = (key: string): string => {
  return queryClient.getQueryData(key);
};

const saveLxpPathToStore = (key: string, value: string): void => {
  queryClient.setQueryData(key, value);
};

const getReturnPath = (lxpReturnPath: string, lxpDomain: string) => {
  if (!lxpReturnPath) return `${lxpDomain}${LxpPaths.Dashboard}`;
  const isUnqualifiedPath = lxpReturnPath.substring(0, 1) === '/';
  const srcPath = decodeURIComponent(lxpReturnPath);
  return isUnqualifiedPath ? `${lxpDomain}${srcPath}` : srcPath;
};
