import './AddReimbursementDrawer.scss';

import * as React from 'react';
import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Col, Form, message, Row} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {CloseCircleOutlined} from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import {getBase64, validateUserUpload} from '@utils/uploadUtils';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInMoneyInput} from '@components/reusable/Input/Input.style';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {getResponsiveDrawerSize} from '@utils/responsiveUtils';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {
  exchangeAmountBack,
  FormatCurrencyOptionsProps,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {CurrencyCode, ReimbursementStatus} from '@generated/enums';
import {ReimbursementPayload} from '@models/api/finance/payloads';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';

interface Props {
  visible: any;
  targets: any;
  userPlanItemId: any;
  formatCurrency: (
    amount: number,
    currency: CurrencyCode,
    exchangeRate: number,
    options?: FormatCurrencyOptionsProps
  ) => string;
  onOk: any;
  onCancel: any;
  addReimbursements: (payload: ReimbursementPayload) => void;
}

function AddReimbursementDrawer(props: Props) {
  const {visible, targets, userPlanItemId, formatCurrency, onOk, onCancel} =
    props;
  const getUserQuery = useGetUserQuery();
  const formRef = React.useRef<FormInstance>(null);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [userFinanceInfo, setUserFinanceInfo] = React.useState(null);
  const [programs, setPrograms] = React.useState([]);
  const [fileList, setFileList] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [programCost, setProgramCost] = React.useState(0);
  const [otherCost, setOtherCost] = React.useState(0);
  const [selectedProgram, setSelectedProgram] = React.useState(0);
  const [requestedCost, setRequestedCost] = useState(0);
  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  useEffect(() => {
    setRequestedCost(programCost + otherCost);
  }, [programCost, otherCost]);

  React.useEffect(() => {
    const programs = [];
    if (targets) {
      targets.programs &&
        targets.programs.map((item: any) => {
          if (item.userPlanProgramId) {
            programs.push({
              id: item.userPlanProgramId,
              title: item.programTitle,
              linked: item.linked,
            });
          }
        });
      setPrograms(programs);
      const selectedProgramId = programDefault(programs)?.id;
      setSelectedProgram(
        selectedProgramId != null ? Number(selectedProgramId) : 0
      );
      setUserFinanceInfo({
        id: targets.userFinanceIncentiveId,
        limit: targets.userFinanceTotalAmount,
        balance: targets.userFinanceBalance,
        pendingAmount: targets.userPendingAmount,
      });
    }
  }, [targets]);

  function beforeUpload(file: any) {
    return validateUserUpload(
      file,
      fileList,
      UploadConstraints.MaxUserContentUploadSizeBytes,
      'bytes'
    );
  }

  const draggerProps: any = {
    name: 'file',
    multiple: true,
    accept: '.png, .jpg, .pdf',
    fileList: [...fileList],
    customRequest: ({onSuccess}) => {
      setTimeout(() => {
        onSuccess('ok');
      }, 0);
    },
    onRemove(info: any) {
      let tempFileList = fileList;
      let tempImages = images;
      tempFileList = tempFileList.filter((f) => f.uid !== info.uid);
      setFileList(tempFileList);
      tempImages = tempImages.filter((f) => f.fileName !== info.name);
      setImages(tempImages);
    },
    onChange(info: any) {
      const {status, errMsg} = info.file;

      if (status === 'done') {
        getBase64(info.file.originFileObj, (data: any) =>
          setImages((images) => [
            ...images,
            {
              fileName: info.file.name,
              fileContent: btoa(data),
              description: '',
            },
          ])
        );
        setFileList((fileList) => [...fileList, info.file]);
        message.success(
          i18n.t(k.FILE__UPLOAD__SUCCESS__FORMAT, {file: info.file.name})
        );
      } else if (status === 'error') {
        errMsg
          ? message.error(errMsg)
          : message.error(
              i18n.t(k.FILE__UPLOAD__ERROR__FORMAT, {file: info.file.name})
            );
      }
    },
  };

  let fileListLength = 0;
  const normFile = (e: any) => {
    fileListLength =
      e.fileList.length > fileListLength ? e.fileList.length : fileListLength;
    // Fix to avoid multiple files upload issue
    if (e.fileList.length >= fileListLength) {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    }
  };

  const selectOnChange = (value: any) => {
    setSelectedProgram(value);
  };

  const handleCostChange = (e: any) => {
    e.target.name === 'programCost'
      ? setProgramCost(e.target.value)
      : setOtherCost(e.target.value);
  };

  const programDefault = (programArr: any) => {
    let defaultID = {id: '', title: ''};
    for (let index = 0; index < programArr.length; index++) {
      if (programArr[index].linked) {
        defaultID = programArr[index];
        break;
      }
    }
    return defaultID;
  };

  const commentCharMax = 500;

  return (
    <LearnInDrawer
      placement="bottom"
      visible={visible}
      onClose={() => {
        formRef.current.resetFields();
        onCancel();
      }}
      title={i18n.t(k.REIMBURSEMENT__NEW_REQUEST)}
      closeIcon={
        <span aria-label={i18n.t(k.CTA__CLOSE)}>
          <CloseCircleOutlined aria-hidden="true" />
        </span>
      }
      footer={[
        <Row key="submit" justify="end">
          <span
            className="msg-error-cost"
            style={{
              display:
                requestedCost > userFinanceInfo?.balance ? 'flex' : 'none',
            }}>
            {i18n.t(k.REIMBURSEMENT__EXCEEDS_BALANCE)}
          </span>
          <LearnInButton
            tag={ButtonTags.DrawerFooterPrimary}
            style={{marginRight: '11%'}}
            disabled={requestedCost > userFinanceInfo?.balance}
            onClick={async () => {
              setConfirmLoading(true);
              try {
                await formRef.current.validateFields();
                const data = formRef.current.getFieldsValue();
                await props.addReimbursements({
                  userPlanItemId: Number(userPlanItemId),
                  title: null,
                  programCosts:
                    exchangeAmountBack(
                      parseInt(data.programCost),
                      exchangeRate
                    ) || parseInt(data.programCost),
                  otherCosts: data.otherCost
                    ? exchangeAmountBack(
                        parseInt(data.otherCost),
                        exchangeRate
                      ) || parseInt(data.otherCost)
                    : 0,
                  userNotes: data.comments,
                  approverNotes: '',
                  status: ReimbursementStatus.PaymentPending,
                  linkedUserProgramId: selectedProgram,
                  images: images,
                });

                onOk();
                setConfirmLoading(false);
                setImages([]);
                setFileList([]);
                formRef.current.resetFields();
              } catch (e) {
                console.log('Error', e);
                setConfirmLoading(false);
                return;
              }
            }}
            loading={confirmLoading}>
            {i18n.t(k.CTA__SUBMIT)}
          </LearnInButton>
        </Row>,
      ]}
      height={getResponsiveDrawerSize(700)}>
      <Form className="form-drawers" ref={formRef} layout="vertical">
        <div className="form-content">
          <Row gutter={[30, 0]}>
            <Col xs={16} sm={8} lg={8}>
              <p
                className="your-reimbursement-labels"
                style={{padding: '5px 0px'}}>
                {i18n.t(k.REIMBURSEMENT__YOUR_LIMIT)}
              </p>
              <p
                className="font body-small"
                style={{color: COLORS.Neutral900, padding: '5px 0px'}}>
                {formatCurrency(
                  userFinanceInfo?.limit ?? 0,
                  getUserQuery.data?.currency,
                  exchangeRate
                )}
              </p>
            </Col>
            <Col xs={16} sm={8} lg={8}>
              <p
                className="your-reimbursement-labels"
                style={{padding: '5px 0px'}}>
                {i18n.t(k.REIMBURSEMENT__AMOUNT_PENDING)}
              </p>
              <p
                className="font body-small "
                style={{color: COLORS.Neutral900, padding: '5px 0px'}}>
                {formatCurrency(
                  userFinanceInfo?.pendingAmount ?? 0,
                  getUserQuery.data?.currency,
                  exchangeRate
                )}
              </p>
            </Col>
            <Col xs={16} sm={8} lg={8}>
              <p
                className="your-reimbursement-labels"
                style={{padding: '5px 0px'}}>
                {i18n.t(k.REIMBURSEMENT__FUNDS_REMAINING)}
              </p>
              <p
                className="font body-small "
                style={{color: COLORS.Neutral900, padding: '5px 0px'}}>
                {formatCurrency(
                  userFinanceInfo?.balance ?? 0,
                  getUserQuery.data?.currency,
                  exchangeRate
                )}
              </p>
            </Col>
          </Row>
          <Row gutter={[30, 0]}>
            <Col xs={12}>
              <Form.Item
                className="lb-application-drawer"
                name="program"
                label={i18n.t(k.PROGRAM)}
                rules={[
                  {
                    required: !programDefault(programs)?.title,
                    message: i18n.t(k.PROGRAM__REQUIRED),
                  },
                ]}>
                <LearnInSelect
                  placeholder={
                    programs?.length && programDefault(programs).title
                      ? programDefault(programs).title
                      : i18n.t(k.VALIDATION__SELECT_PROGRAM)
                  }
                  disabled={!!programDefault(programs).id || !!selectedProgram}
                  value={programDefault(programs).id}
                  onChange={selectOnChange}>
                  {programs.map((p) => (
                    <LearnInSelectOption key={p.id} value={p.id}>
                      {p.title}
                    </LearnInSelectOption>
                  ))}
                </LearnInSelect>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[30, 0]}>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                className="lb-application-drawer"
                name="programCost"
                label={i18n.t(k.PROGRAM__COST)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(k.PROGRAM__COST_REQUIRED),
                  },
                ]}>
                <LearnInMoneyInput
                  currency={getUserQuery.data?.currency}
                  id="costId"
                  name="programCost"
                  min={0}
                  placeholder="0"
                  style={{width: '100%'}}
                  onChange={handleCostChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <Form.Item
                className="lb-application-drawer"
                label={i18n.t(k.MONEY__OTHER_COSTS)}
                name="otherCost">
                <LearnInMoneyInput
                  currency={getUserQuery.data?.currency}
                  id="otherCost"
                  name="otherCost"
                  min={0}
                  placeholder="0"
                  style={{width: '100%'}}
                  onChange={handleCostChange}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[30, 0]}>
            <Col xs={24}>
              <Form.Item
                className="lb-application-drawer"
                name="comments"
                label={i18n.t(k.COMMENT__PLURAL)}
                rules={[
                  {
                    max: commentCharMax,
                    message: i18n.t(k.VALIDATION__CHAR_LIMIT_REACHED__FORMAT, {
                      max: commentCharMax,
                    }),
                  },
                ]}>
                <LearnInTextArea
                  tag={TextAreaTags.PRIMARY}
                  className="cost-inputs"
                  maxLength={5000}
                  placeholder={i18n.t(k.A11Y__WRITE_HERE)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[30, 0]}>
            <Col xs={24}>
              <Form.Item
                className="lb-application-drawer"
                name="uploadFile"
                label={`${i18n.t(k.FILE__ATTACHMENT__ATTACH)}:`}
                valuePropName="fileList"
                getValueFromEvent={normFile}>
                <Dragger
                  {...draggerProps}
                  listType="picture"
                  beforeUpload={beforeUpload}>
                  <p className="font label">{i18n.t(k.CTA__DRAG_AND_DROP)}</p>
                  <p className="font label">
                    {i18n.t(k.GENERIC__OR).toLocaleLowerCase()}
                  </p>
                  <p className="font label">{i18n.t(k.FILE__UPLOAD)}</p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </LearnInDrawer>
  );
}

export default AddReimbursementDrawer;
