// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/LicenseStatus.cs

enum LicenseStatus {
  Draft,
  Published,
  Deleted,
}

export default LicenseStatus;
