import {useState} from 'react';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {PlusCircleOutlined} from '@ant-design/icons';
import {notify} from '@components/user/notifications';
import {CustomProgramSectionPayload} from '@models/serverModels';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {getAddCustomProgramSectionRm} from '@store/apiEndpoints/customProgram/mutations';
import {DndProvider as DragAndDropProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import CustomProgramSectionNavItem from '@components/admin/pages/programs/customPrograms/curriculum/CustomProgramSectionNavItem.container';
import {
  HEADER_HEIGHT,
  SIDE_NAV_WIDTH,
  SECTIONS_NAV_WIDTH,
} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {useCustomProgramSectionsQuery} from '@generated/hooks';
import {mapTextToUserLanguage} from '@utils/enumMapping/MapTextToUserLanguage';
import {NEW_SECTION} from '@components/admin/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  background: white;
  width: ${() => SECTIONS_NAV_WIDTH}px;
  position: absolute;
  top: ${() => HEADER_HEIGHT}px;
  left: ${() => SIDE_NAV_WIDTH}px;
  bottom: 72px;
  border-right: 1px solid ${() => COLORS.Neutral200};
  z-index: 80;
  overflow-y: scroll;
  height: calc(100% - ${() => HEADER_HEIGHT}px);
  button:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

const SectionsContainerHeader = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CurriculumScreenNavContainerProps {
  customProgramId: number;
  selectedSectionId: number;
  setSelectedSectionId: (id: number | undefined) => void;
  setIsNewSection: (isNew: boolean) => void;
}

function CurriculumScreenNavContainer({
  customProgramId,
  selectedSectionId,
  setSelectedSectionId,
  setIsNewSection,
}: CurriculumScreenNavContainerProps) {
  const [sectionReorderIdx, setSectionReorderIdx] = useState<null | number>(
    null
  );
  const [isDraggingSectionOrder, setIsDraggingSectionOrder] = useState<
    null | number
  >(null);

  const {
    data: customProgramSections,
    invalidateExact: invalidateCustomProgramSections,
  } = useCustomProgramSectionsQuery({
    customProgramId,
  });

  const addCustomProgramSectionMutation = useMutation(
    (programContent: CustomProgramSectionPayload) => {
      const addCustomProgramSectionRm =
        getAddCustomProgramSectionRm(programContent);
      return simpleMutationFn<number>(
        addCustomProgramSectionRm.path,
        addCustomProgramSectionRm.payload
      );
    },
    {
      onSuccess: async (newSectionId) => {
        if (typeof newSectionId === 'number') {
          await invalidateCustomProgramSections();
          setIsNewSection(true);
          notify.addCustomProgramSectionSuccess();
          setSelectedSectionId(newSectionId);
        }
      },
    }
  );

  return (
    <Container>
      <SectionsContainerHeader>
        <div
          style={{
            color: COLORS.Neutral600,
            fontSize: '0.75rem',
            fontWeight: 'bold',
          }}>
          {i18n.t(k.SECTION__PLURAL)}
        </div>
        <LearnInTooltip
          title={i18n.t(k.CTA__ADD_ITEM__FORMAT, {
            item: i18n.t(k.SECTION__NEW),
          })}
          childHasButton={true}>
          <LearnInButton
            data-testid="add-section-button-custom-programs"
            aria-label={i18n.t(k.CTA__ADD_ITEM__FORMAT, {
              item: i18n.t(k.SECTION__NEW),
            })}
            onClick={async () =>
              await addCustomProgramSectionMutation.mutateAsync({
                customProgramId,
                title: NEW_SECTION, // set to english default to translate later on FE
              })
            }
            tag={ButtonTags.SecondaryRemovedBorder}
            icon={
              <span aria-label={i18n.t(k.SECTION__NEW)}>
                <PlusCircleOutlined aria-hidden="true" />
              </span>
            }
            padding={'0'}
            iconSize={'L'}
          />
        </LearnInTooltip>
      </SectionsContainerHeader>
      <DragAndDropProvider backend={HTML5Backend} context={window}>
        {customProgramSections
          ?.sort((a, b) => a.order - b.order)
          .map(({id, title, order, content}) => {
            return (
              <CustomProgramSectionNavItem
                key={id}
                id={id}
                title={mapTextToUserLanguage(title)}
                customProgramId={customProgramId}
                order={order}
                active={id === selectedSectionId}
                onClick={() => setSelectedSectionId(id)}
                doesSectionHaveNoContent={!content?.length}
                sectionReorderIdx={sectionReorderIdx}
                setSectionReorderIdx={() => {
                  if (sectionReorderIdx !== order) {
                    setSectionReorderIdx(order);
                  }
                }}
                setIsDraggingSectionOrder={() => {
                  if (isDraggingSectionOrder !== order) {
                    setIsDraggingSectionOrder(order);
                  }
                }}
                isDraggingSectionOrder={isDraggingSectionOrder}
                selectedSectionId={selectedSectionId}
                setSelectedSectionId={setSelectedSectionId}
              />
            );
          })}
      </DragAndDropProvider>
    </Container>
  );
}

export default CurriculumScreenNavContainer;
