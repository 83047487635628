import {i18n, k} from '@i18n/translate';
import {BalanceRecurType, FinanceRecurrence} from '@generated/enums';
import {
  exchangeAmount,
  formatCurrency,
  getExchangeRateOfCurrencyPair,
} from '@utils/moneyUtils';

export const useDeterminePlbGaugeStatus = (
  budgetTerm: number,
  budgetType: number,
  advancedAmount: number,
  financeIncentiveAmount: number,
  currency: any,
  exchangeRate: any
): string => {
  if (
    budgetTerm === FinanceRecurrence.Yearly &&
    budgetType === BalanceRecurType.Rollover &&
    !advancedAmount
  ) {
    return i18n.t(k.FUND__INCREASE__FIRST_OF_YEAR__FORMAT, {
      amount: formatCurrency(financeIncentiveAmount, currency, exchangeRate),
    });
  }
  if (
    budgetTerm === FinanceRecurrence.Yearly &&
    budgetType === BalanceRecurType.Reset &&
    !advancedAmount
  ) {
    return i18n.t(k.FUND__RESET__FIRST_OF_YEAR__FORMAT, {
      amount: formatCurrency(financeIncentiveAmount, currency, exchangeRate),
    });
  }
  if (
    budgetTerm === FinanceRecurrence.Monthly &&
    budgetType === BalanceRecurType.Rollover &&
    !advancedAmount
  ) {
    return i18n.t(k.FUND__INCREASE__FIRST_OF_MONTH__FORMAT, {
      amount: formatCurrency(financeIncentiveAmount, currency, exchangeRate),
    });
  }
  if (
    budgetTerm === FinanceRecurrence.Monthly &&
    budgetType === BalanceRecurType.Reset &&
    !advancedAmount
  ) {
    return i18n.t(k.FUND__RESET__FIRST_OF_MONTH__FORMAT, {
      amount: formatCurrency(financeIncentiveAmount, currency, exchangeRate),
    });
  }
  if (budgetTerm === FinanceRecurrence.Never) return '';
  if (advancedAmount) return i18n.t(k.PLB__NO_INCREASE_ADVANCE);
};

export const getCost = (
  licenseCost,
  licenseCurrency,
  currencyExchange,
  userCurrency
) => {
  const rate = getExchangeRateOfCurrencyPair(
    currencyExchange,
    userCurrency,
    licenseCurrency
  );
  return exchangeAmount(licenseCost, rate);
};
