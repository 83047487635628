/* eslint-disable react/prop-types */
function CheckMark(props) {
  return (
    <svg
      width="17"
      height="13"
      viewBox="0 0 17 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.0057 0.783742C14.8896 0.672059 14.706 0.672015 14.5898 0.783644L5.76708 9.26238L2.41896 6.04176C2.30311 5.93032 2.12002 5.93 2.00378 6.04103L0.792862 7.19763C0.733733 7.2541 0.700218 7.33227 0.700074 7.41404C0.699929 7.49581 0.733167 7.57409 0.792098 7.63078L5.55901 12.2162C5.67516 12.3279 5.85882 12.3279 5.97496 12.2162L16.208 2.37276C16.2668 2.3162 16.3001 2.23814 16.3001 2.15655C16.3001 2.07497 16.2668 1.9969 16.208 1.94034L15.0057 0.783742Z"
        fill={props?.fill || '#747578'}
      />
    </svg>
  );
}

export default CheckMark;
