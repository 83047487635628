import {useLocation} from 'react-router-dom';
import {Breakpoints} from '@utils/responsiveUtils';
import {i18n, k} from '@i18n/translate';
import {AcademyVM} from '@generated/interfaces';
import {Content} from './Content';
import DrawerPage from '@components/reusable/DrawerPage';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';

export interface AcademyWelcomeDrawerProps {
  academy?: AcademyVM;
  userFirstName: string;
  onClose: () => void;
  clickJoinAcademy: () => void;
  renderPageInsteadOfDrawer?: boolean;
  transitionOnRender?: boolean;
}

function AcademyWelcomeDrawer({
  academy,
  userFirstName,
  onClose,
  clickJoinAcademy,
  renderPageInsteadOfDrawer,
  transitionOnRender,
}: AcademyWelcomeDrawerProps) {
  const {pathname} = useLocation();
  const breakpoint = pathname.includes('branding')
    ? Breakpoints.Desktop
    : Breakpoints.Mobile;
  return renderPageInsteadOfDrawer ? (
    <DrawerPage
      title={academy?.name ?? i18n.t(k.STATUS__LOADING)}
      onClickBack={onClose}>
      <Content
        breakpoint={breakpoint}
        userFirstName={userFirstName}
        academy={academy}
        clickJoinAcademy={clickJoinAcademy}
        transitionOnRender={transitionOnRender}
      />
    </DrawerPage>
  ) : (
    <LearnInPageDrawer
      onClose={onClose}
      visible={true}
      title={academy?.name ?? i18n.t(k.STATUS__LOADING)}>
      <Content
        breakpoint={breakpoint}
        userFirstName={userFirstName}
        academy={academy}
        clickJoinAcademy={clickJoinAcademy}
        transitionOnRender={transitionOnRender}
      />
    </LearnInPageDrawer>
  );
}

export default AcademyWelcomeDrawer;
