import {Upload} from 'antd';
import styled from 'styled-components';
import {DeleteOutlined, LoadingOutlined} from '@ant-design/icons';
import Grid from '@blocks/Grid';
import {
  AttachmentOptions,
  AttachmentType,
  LinkForm,
} from '@components/reusable/Attachment';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import UploadInstructions from '@components/reusable/Upload/UploadInstructions';
import {notify} from '@components/user/notifications';
import {i18n, k} from '@i18n/translate';
import {FileTypeDetails, FileUploadType} from '@utils/uploadUtils';
import AttachmentUploadProgress from './AttachmentUploadProgress.view';
import AttachmentCard from './AttachmentCard.view';
import {
  AttachmentResponse,
  AttachmentVM,
  CustomContentLink,
} from '@models/serverModels';

const {Dragger} = Upload;

const DeleteButtonWrapper = styled.span`
  display: flex;
  justify-content: space-around;
`;

interface AttachmentsProps {
  showAttachmentTypeOptions: boolean;
  showAttachmentUploadInput: boolean;
  attachment: AttachmentVM | AttachmentResponse;
  newLink: CustomContentLink;
  setNewLink: (newLink: CustomContentLink) => void;
  attachmentType: AttachmentType;
  setAttachmentType: (attachmentType: AttachmentType) => void;
  fileToUpload: Partial<{name: string}>;
  setFileToUpload: (file) => void;
  isDeletingAttachment: boolean;
  shouldDeleteAttachment: boolean;
  setShouldDeleteAttachment: (shouldSet: boolean) => void;
  contentUploadPercent: number;
  cancelUpload: () => Promise<void>;
}

const Attachments = ({
  showAttachmentTypeOptions,
  showAttachmentUploadInput,
  attachment,
  newLink,
  setNewLink,
  attachmentType,
  setAttachmentType,
  fileToUpload,
  setFileToUpload,
  isDeletingAttachment,
  shouldDeleteAttachment,
  setShouldDeleteAttachment,
  contentUploadPercent,
  cancelUpload,
}: AttachmentsProps) => {
  return (
    <>
      {/* Attachment Type Options */}
      {showAttachmentTypeOptions && (
        <AttachmentOptions setAttachmentType={setAttachmentType} />
      )}

      {/* Deleting Attachment Indicator */}
      {isDeletingAttachment && (
        <div style={{marginBottom: '20px'}}>
          <LoadingOutlined
            aria-hidden="true"
            style={{fontSize: '1.4em', marginRight: '9px'}}
          />{' '}
          {i18n.t(
            fileToUpload
              ? k.FILE__ATTACHMENT__DELETING_OLD
              : k.FILE__ATTACHMENT__DELETING
          )}
        </div>
      )}

      {/* Link Form */}
      {attachmentType === AttachmentType.Link && (
        <LinkForm
          setLink={setNewLink}
          link={newLink}
          setAttachmentType={setAttachmentType}
        />
      )}

      {/* Upload Progress Indicator */}
      {contentUploadPercent !== undefined && (
        <AttachmentUploadProgress
          cancelUpload={cancelUpload}
          fileName={fileToUpload.name}
          uploadPercentage={contentUploadPercent}
        />
      )}

      {/* Attachment Form */}
      <Grid.Box>
        {showAttachmentUploadInput && (
          <Dragger
            customRequest={async ({file}) => {
              // Prevent files that are too large from being uploaded
              if (
                ((file as File)?.size as number) >
                UploadConstraints.MaxUserContentUploadSizeBytes
              ) {
                return notify.uploadContentAttachmentTooLargeError(
                  (file as File)?.name || 'File'
                );
              }
              setFileToUpload(file);
            }}
            showUploadList={false}
            maxCount={1}
            multiple={false}
            accept={[
              ...FileTypeDetails[FileUploadType.Images].fileSuffixes,
              ...FileTypeDetails[FileUploadType.Videos].fileSuffixes,
              ...FileTypeDetails[FileUploadType.Pdf].fileSuffixes,
            ].join(', ')}>
            <UploadInstructions
              fileTypes={i18n.t(k.FILE__UPLOAD__IMAGES_DOCUMENTS_AND_VIDEOS)}
            />
            <DeleteButtonWrapper>
              <LearnInButton
                tag={ButtonTags.DestructiveRemovedBorder}
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachmentType(null);
                  setShouldDeleteAttachment(false);
                }}>
                {i18n.t(k.CTA__CANCEL)}
              </LearnInButton>
            </DeleteButtonWrapper>
          </Dragger>
        )}

        {/* Current Attachment */}
        {!!attachment && !shouldDeleteAttachment && (
          <AttachmentCard
            fileName={attachment?.description || attachment?.fileName}
            onClickDelete={() => {
              setShouldDeleteAttachment(true);
            }}
          />
        )}

        {/* Queued Attachment */}
        {!!fileToUpload && contentUploadPercent === undefined && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <div>{fileToUpload.name}</div>
            <LearnInButton
              icon={<DeleteOutlined alt={i18n.t(k.CTA__DELETE)} />}
              tag={ButtonTags.DestructiveRemovedBorder}
              onClick={() => setFileToUpload(undefined)}
            />
          </div>
        )}
      </Grid.Box>
    </>
  );
};

export default Attachments;
