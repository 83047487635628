import {CurrencyCode} from './../enums';
import {ProgramDetailsVM} from './../interfaces';
import {DetailsVMMockData, getDetailsVMMockData} from './DetailsVM.mock';
import {faker} from '@faker-js/faker';

// Interface: ProgramDetailsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramDetailsVMMockData(
  args?: Partial<ProgramDetailsVM>
): ProgramDetailsVM {
  return {
    ...getDetailsVMMockData(),
    actualLearningTime: String(faker.date.past()), // optional
    applyUrl: faker.internet.url(), // optional
    cost: faker.datatype.number(),
    currency: CurrencyCode.USD,
    duration: faker.word.noun(),
    isLicenseProgram: faker.datatype.boolean(),
    licenseCost: faker.datatype.number(), // optional
    licenseCurrency: CurrencyCode.USD, // optional
    licenseId: faker.datatype.number(), // optional
    monthlySubscriptionCost: faker.datatype.number(), // optional
    presenceType: faker.word.noun(),
    programType: faker.word.noun(), // optional
    requestRemoveConfirmation: faker.datatype.boolean(),
    skills: [faker.word.noun()],
    status: faker.word.noun(),
    timeCommitment: String(faker.date.past()),
    timeCommitmentType: String(faker.date.past()),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramDetailsVMMockData: ProgramDetailsVM = {
  ...DetailsVMMockData,
  actualLearningTime: '2023-03-29T22:17:19.738072', // optional
  applyUrl: 'https://this-person-does-not-exist.com/en', // optional
  cost: 123,
  currency: CurrencyCode.USD,
  duration: 'string',
  isLicenseProgram: true,
  licenseCost: 123, // optional
  licenseCurrency: CurrencyCode.USD, // optional
  licenseId: 1, // optional
  monthlySubscriptionCost: 123, // optional
  presenceType: 'string',
  programType: 'string', // optional
  requestRemoveConfirmation: true,
  skills: ['string'],
  status: 'string',
  timeCommitment: '2023-03-29T22:17:19.738072',
  timeCommitmentType: '2023-03-29T22:17:19.738072',
};
