import './ManageUserPlan.scss';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import PageContentLayout from '../../../../../providers/pageContentLayout/PageContentLayout';
import PageTitleBar from '../../../../../providers/pageTitleBar/PageTitleBar';
import {useLocation} from 'react-router-dom';
import UserPlanItemCard from '../userPlanItemCard/UserPlanItemCard';
import Calendar from './FullCalendar';
import LoadingSpinner from '../../../../../../elements/loadingSpinner/LoadingSpinner';
import {Row, Col, Skeleton} from 'antd';
import moment from 'moment';
import {
  NewUserEvent,
  UpdateUserEvent,
  UserEventVM,
  UserPlanItemCardVM,
} from '@models/serverModels';
import {mergeDateTime} from '@utils/timeUtils';
import {SyncCalendarRequest} from '@generated/interfaces';
const {useEffect} = React;

interface Props {
  FETCH_USER_EVENTS_DATA: UserEventVM[];
  FETCH_USER_ITEM_DATA_DATA: UserPlanItemCardVM;
  FETCH_USER_ITEM_DATA_LOADING: boolean;
  FETCH_USER_EVENTS_LOADING: boolean;
  connectCalendar: ({payload}: {payload: SyncCalendarRequest}) => void;
  addEvent: (payload: NewUserEvent) => void;
  fetchUserEvents: (start: any, end: any, type: any) => void;
  updateEvent: (payload: UpdateUserEvent) => void;
  fetchUserItem: (id: any) => void;
  CALENDAR_START_DATE: string;
  CALENDAR_END_DATE: string;
  CALENDAR_VIEW: string;
}

function ManageUserPlan({
  FETCH_USER_EVENTS_DATA,
  FETCH_USER_ITEM_DATA_DATA,
  FETCH_USER_ITEM_DATA_LOADING,
  FETCH_USER_EVENTS_LOADING,
  connectCalendar,
  addEvent,
  fetchUserEvents,
  updateEvent,
  fetchUserItem,
  CALENDAR_START_DATE,
  CALENDAR_END_DATE,
  CALENDAR_VIEW,
}: Props): React.ReactElement {
  const location: any = useLocation();

  const transformedUserEventsData = FETCH_USER_EVENTS_DATA?.map((event) => {
    return {
      id: String(event.id),
      start: mergeDateTime(event.startDate, event.startTime),
      end: mergeDateTime(event.endDate, event.endTime),
      title: event.title,
      description: event.notes,
      allDay: !event.startTime && !event.endTime,
      externalId: event.externalId,
      approvalStatus: event.approvalStatus,
      eventColor:
        event.isEditable || event.externalId
          ? event.color
          : 'rgb(152, 152, 152)',
      color: 'transparent',
      isEditable: event.isEditable,
      recurring: event.recurring,
      recurringDetail: event.recurringDetail,
    };
  });

  const item = location.state?.item;

  const id = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );

  useEffect(() => {
    if (!item) {
      fetchUserItem?.(id);
    }
    const currentDate = moment();
    const startDate = moment(currentDate).startOf('week');
    const endDate = moment(currentDate).endOf('week');
    fetchUserEvents?.(startDate, endDate, 'timeGridWeek');
  }, []);

  return (
    <>
      <PageTitleBar title={i18n.t(k.TIME__MANAGE)} />
      <PageContentLayout>
        {FETCH_USER_ITEM_DATA_LOADING && (
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Skeleton active />
            </Col>
          </Row>
        )}
        {(item != null ||
          (FETCH_USER_ITEM_DATA_DATA != null &&
            !FETCH_USER_ITEM_DATA_LOADING)) && (
          <UserPlanItemCard
            key={'1'}
            showActions={false}
            item={item || FETCH_USER_ITEM_DATA_DATA}
          />
        )}
        <br />
        {FETCH_USER_EVENTS_LOADING && <LoadingSpinner />}
        {transformedUserEventsData != null && !FETCH_USER_EVENTS_LOADING && (
          <Calendar
            planItemId={item?.id || id}
            data={transformedUserEventsData}
            fetchUserEvents={fetchUserEvents}
            start={CALENDAR_START_DATE}
            end={CALENDAR_END_DATE}
            view={CALENDAR_VIEW}
            connectCalendar={connectCalendar}
            addEvent={addEvent}
            updateEvent={updateEvent}
          />
        )}
      </PageContentLayout>
    </>
  );
}

export default ManageUserPlan;
