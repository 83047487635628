import {UserPlanCardActions} from '../../models/clientEnums';

/**
 * There are different values used on UI so we cover both cases
 * @param value
 */
export const mapStringToUserPlanCardActions = (
  value: string
): UserPlanCardActions => {
  switch (value.toLowerCase()) {
    case 'request':
    case 'requested':
      return UserPlanCardActions.Request;
    case 'remove':
    case 'removed':
      return UserPlanCardActions.Remove;
    case 'apply':
    case 'applied':
      return UserPlanCardActions.Apply;
    case 'admit':
    case 'admitted':
      return UserPlanCardActions.Admit;
    case 'start':
    case 'started':
      return UserPlanCardActions.Start;
    case 'complete':
    case 'completed':
      return UserPlanCardActions.Complete;
    case 'finish':
    case 'finished':
      return UserPlanCardActions.Finish;
    case 'reapply':
    case 'reapplied':
      return UserPlanCardActions.Reapply;
    case 'viewlicense':
      return UserPlanCardActions.ViewLicense;
    case 'website':
      return UserPlanCardActions.ViewWebsite;
    case 'details':
      return UserPlanCardActions.ViewDetails;
    default:
      console.warn('Unrecognized user plan card action: ', value);
      return undefined;
  }
};
