import {CustomProgramAgendaVM} from '@models/serverModels';
import {useMemo} from 'react';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {Divider} from 'antd';
import AgendaCard from '../AgendaCard/AgendaCard.container';
import {AgendaCardContainerStyled} from '../AgendaCard/AgendaCard';
import {DATE_FORMAT, parseToLocalMoment} from '@utils/timeUtils';
import sortBy from 'lodash/sortBy';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

// needs this before AgendaDaySectionContainer
const AgendaRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const AgendaDaySectionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  ${AgendaRow} > ${AgendaCardContainerStyled} {
    border-radius: 8px 8px 8px 8px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
  }
  /* Skipping  */
  ${AgendaRow}:nth-child(2) > ${AgendaCardContainerStyled} {
    border: 1px solid ${COLORS.Neutral200};
    border-bottom: none;
    border-radius: 8px 8px 0 0;
    box-shadow: ${COLORS.BoxShadowStandard};
  }

  ${AgendaRow}:not(:nth-child(2)):not(:last-child) > ${AgendaCardContainerStyled} {
    border-bottom: none;
    border-radius: 0;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
  }

  ${AgendaRow}:last-child > ${AgendaCardContainerStyled} {
    border-bottom: 1px solid ${COLORS.Neutral200};
    border-left: 1px solid ${COLORS.Neutral200};
    border-right: 1px solid ${COLORS.Neutral200};
    box-sizing: border-box;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
  }

  ${AgendaRow}:last-child:not(:nth-child(2)) > ${AgendaCardContainerStyled} {
    border-radius: 0 0 8px 8px;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.1);
  }

  ${AgendaRow}:last-child:nth-child(2) > ${AgendaCardContainerStyled} {
    border-radius: 8px 8px 8px 8px;
    box-shadow: ${COLORS.BoxShadowStandard};
  }
`;

const SectionTitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const DividerContainer = styled.div`
  width: 100%;
`;

const SectionTitleRowDate = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  width: 80px;
  margin-right: 10px;
`;

const AgendaRowTime = styled.div`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  width: 80px;
  margin-right: 10px;
`;

/*
|--------------------------------------------------------------------------
| Components
|--------------------------------------------------------------------------
*/
export interface IAgendaDaySectionProps {
  agendas: CustomProgramAgendaVM[];
  customProgramId: number;
  handleCardSelected: (id: number | undefined) => void;
  isActivePlanItem: boolean;
  selectedCardId: number | undefined;
}

export default function AgendaDaySection({
  agendas,
  customProgramId,
  handleCardSelected,
  isActivePlanItem,
  selectedCardId,
}: IAgendaDaySectionProps) {
  const DateString = parseToLocalMoment(agendas[0].startDateUtc).format(
    DATE_FORMAT.MONTH_DAY_SHORT
  );

  const sortedAgendas = useMemo(
    () =>
      sortBy(agendas, [
        (agenda: CustomProgramAgendaVM) =>
          parseToLocalMoment(agenda.startDateUtc).format(),
      ]),
    [agendas]
  );
  return (
    <AgendaDaySectionContainer>
      <SectionTitleRow>
        <SectionTitleRowDate>{DateString}</SectionTitleRowDate>
        <DividerContainer>
          <Divider></Divider>
        </DividerContainer>
      </SectionTitleRow>
      {sortedAgendas.map((agenda) => {
        const time = parseToLocalMoment(agenda.startDateUtc).format(
          DATE_FORMAT.TIME_12_HR
        );
        return (
          <AgendaRow key={agenda.id}>
            <AgendaRowTime>{time}</AgendaRowTime>
            <AgendaCard
              isActivePlanItem={isActivePlanItem}
              agenda={agenda}
              handleCardSelected={handleCardSelected}
              selectedCardId={selectedCardId}
              customProgramId={customProgramId}
            />
          </AgendaRow>
        );
      })}
    </AgendaDaySectionContainer>
  );
}
