import ParticipantsTab from './ParticipantsTab';
import {ProgramParticipantVM} from '@models/serverModels';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {getProgramParticipantsRq} from '@store/apiEndpoints/program/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface IParticipantsTabContainerProps {
  programId: number;
}

function ParticipantsTabContainer({programId}: IParticipantsTabContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const programParticipantsRq = getProgramParticipantsRq(programId);

  const programParticipantsQuery = useQuery<ProgramParticipantVM[]>(
    programParticipantsRq.queryKey,
    () => simpleQueryFn(programParticipantsRq.path)
  );
  return (
    <ParticipantsTab
      dataSource={programParticipantsQuery.data || []}
      canShowEmployeeEmail={isFeatureFlagOn.ShowEmployeeEmail}
    />
  );
}

export default ParticipantsTabContainer;
