import {RecommendationLevel} from './../enums';
import {RecommendProgram} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: RecommendProgram

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getRecommendProgramMockData(
  args?: Partial<RecommendProgram>
): RecommendProgram {
  return {
    id: faker.datatype.number(),
    level: RecommendationLevel.Featured,
    users: [faker.datatype.number()],
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const RecommendProgramMockData: RecommendProgram = {
  id: 1,
  level: RecommendationLevel.Featured,
  users: [123],
};
