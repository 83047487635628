import {useEffect, useState} from 'react';
import {useMutation, useQuery} from 'react-query';
import {
  getPreApprovalFormRq,
  getRequestItemPreApprovalRm,
  getRequestResourceApprovalRm,
  getResourceApprovalApplicationsRq,
} from '@store/apiEndpoints';
import queryClient, {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import {
  ApplicationQuestionVM,
  PreApprovalApplication,
  PreApprovalQuestion,
  RequestResourceApprovalPayload,
  UserPlanProgramItemVM,
  UserPlanResourceVM,
  UserPlanTemplate,
} from '@models/serverModels';
import {ApprovalStatus, PlanItemType} from '@generated/enums';
import RequestAdditionalFundsModal from './RequestAdditionalFundsModal';
import {getPlanItemQueryInvalidateKey} from '@utils/query-invalidation-helper';
import {REQUEST_CURRENCY_ID} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  applyCurrencySelection,
  conditionallyFormatResponse,
} from '@utils/preApprovalRequest';
import {datadogLogs} from '@datadog/browser-logs';

type Props = {
  isVisible: boolean;
  onClose: () => void;
  planItemType?: PlanItemType;
  program?: UserPlanProgramItemVM;
  resource?: UserPlanResourceVM;
  refreshResources?: () => void;
};

const RequestAdditionalFundsModalContainer = ({
  refreshResources,
  resource,
  isVisible,
  onClose,
  planItemType,
  program,
}: Props) => {
  const [showError, setShowError] = useState(false);
  const [questions, setQuestions] = useState<
    PreApprovalQuestion[] | ApplicationQuestionVM[]
  >();
  const getUserQuery = useGetUserQuery();
  const requestResourcesApprovalMutation = useMutation(
    (args: RequestResourceApprovalPayload) => {
      const requestResourcesApproval = getRequestResourceApprovalRm(args);
      return simpleMutationFn<null>(
        requestResourcesApproval.path,
        requestResourcesApproval.payload
      );
    },
    {
      onSuccess: async () => {
        notify.AddResourceWithApprovalSuccess();
        refreshResources();
        onClose();
      },
      onError: () => {
        setShowError(true);
        notify.AddResourceWithApprovalError();
      },
    }
  );
  const requestItemPreApprovalMutation = useMutation(
    ({
      planItemId,
      preApprovalApplication,
    }: {
      planItemId: number;
      preApprovalApplication: PreApprovalApplication;
    }) => {
      const requestItemPreApprovalRm = getRequestItemPreApprovalRm(
        preApprovalApplication,
        planItemId
      );
      return simpleMutationFn<UserPlanTemplate>(
        requestItemPreApprovalRm.path,
        requestItemPreApprovalRm.payload
      );
    },
    {
      onSuccess: () => {
        notify.applyPlanSuccessful();
        queryClient.refetchQueries(getPlanItemQueryInvalidateKey(planItemType));
        onClose();
      },
      onError: () => {
        setShowError(true);
        notify.applyPlanError();
      },
    }
  );

  const applicationRq = resource
    ? getResourceApprovalApplicationsRq(1)
    : getPreApprovalFormRq(planItemType, program?.programId, program?.id, 1);
  const query = useQuery(applicationRq.queryKey, () =>
    simpleQueryFn(applicationRq.path)
  );

  useEffect(() => {
    if (query?.isSuccess) {
      const questions = resource
        ? query?.data?.[resource?.resourceType]?.questions
        : query?.data?.application;
      if (questions?.length > 0) {
        setQuestions(questions);
      } else {
        datadogLogs.logger.error(
          'Empty questions provided for additional funds',
          {
            resource,
            data: query?.data,
          }
        );
      }
    }
  }, [query?.data, query?.isSuccess, resource]);

  async function onSubmit(values) {
    setShowError(false);

    applyCurrencySelection(
      questions,
      values?.[REQUEST_CURRENCY_ID],
      getUserQuery.data?.currency
    );

    const application: ApplicationQuestionVM[] | PreApprovalQuestion[] =
      questions.map((item) => ({
        ...item,
        response: conditionallyFormatResponse({
          item,
          values,
        }),
      }));

    if (resource) {
      await requestResourcesApprovalMutation.mutateAsync({
        userPlanResourceId: resource?.id,
        resourceType: resource?.resourceType,
        applicationDetails: {
          approvalRankType: 1,
          status: ApprovalStatus.Requested,
          application: application as ApplicationQuestionVM[],
        },
      });
    } else if (program) {
      await requestItemPreApprovalMutation.mutateAsync({
        planItemId: program?.id,
        preApprovalApplication: {
          approvalRankType: 1,
          id: query?.data?.id,
          status: ApprovalStatus.Requested,
          application: application as PreApprovalQuestion[],
        },
      });
    }
  }
  return (
    <RequestAdditionalFundsModal
      currency={getUserQuery.data?.currency}
      formQuestions={questions}
      isFormDisabled={
        requestResourcesApprovalMutation.isLoading ||
        requestItemPreApprovalMutation.isLoading
      }
      isTextAreaDisabled={
        query?.data?.status === ApprovalStatus.Pending &&
        query?.data?.status === ApprovalStatus.Rejected
      }
      isVisible={isVisible}
      onClose={onClose}
      onSubmit={onSubmit}
      planItemType={planItemType}
      showError={showError}
    />
  );
};

export default RequestAdditionalFundsModalContainer;
