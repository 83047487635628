// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/SubmissionType.cs

enum SubmissionType {
  Text,
  FileUpload,
  Link,
}

export default SubmissionType;
