import {LxpContentType} from '@generated/enums';
import {i18n, k} from '@i18n/translate';

export const renderInputType = (inputType: number) => {
  switch (inputType) {
    case 1:
      return i18n.t(k.GENERIC__PATHWAY);
    case 2:
      return i18n.t(k.GENERIC__TARGETED);
    case 3:
      return i18n.t(k.GENERIC__COURSE);
    case 4:
      return i18n.t(k.GENERIC__VIDEO);
    case 5:
      return i18n.t(k.GENERIC__PODCAST);
    case 6:
      return i18n.t(k.GENERIC__ARTICLE);
    case 7:
      return i18n.t(k.RESOURCE__BOOK);
    case 8:
      return i18n.t(k.EVENT);
    case 9:
      return i18n.t(k.GENERIC__ASSESSMENT);
  }
};

export const getLxpContentNameByAcademyStepType = (
  stepType: LxpContentType
) => {
  switch (stepType) {
    case LxpContentType.Course:
      return i18n.t(k.GENERIC__COURSE);
    case LxpContentType.Pathway:
      return i18n.t(k.GENERIC__PATHWAY);
    case LxpContentType.Assessment:
      return i18n.t(k.GENERIC__ASSESSMENT);
    case LxpContentType.Episode:
      return i18n.t(k.GENERIC__PODCAST);
    case LxpContentType.Video:
      return i18n.t(k.GENERIC__VIDEO);
    case LxpContentType.Event:
      return i18n.t(k.EVENT);
    case LxpContentType.Article:
      return i18n.t(k.GENERIC__ARTICLE);
    case LxpContentType.Book:
      return i18n.t(k.RESOURCE__BOOK);
  }
};

/**
 * Maps inputType to its corresponding LxpContentType equivalent
 */
export const getLxpContentTypeFromInputType = (
  inputType: string
): LxpContentType => {
  switch (inputType) {
    case 'episode':
      return LxpContentType.Episode;
    case 'pathway':
      return LxpContentType.Pathway;
    case 'article':
      return LxpContentType.Article;
    case 'event':
      return LxpContentType.Event;
    case 'assessment':
      return LxpContentType.Assessment;
    case 'course':
      return LxpContentType.Course;
    case 'video':
      return LxpContentType.Video;
    case 'book':
      return LxpContentType.Book;
    default:
      return LxpContentType.Targeted;
  }
};

export function translateLxpDurationUnitType(
  durationUnitType: string,
  isPlural: boolean = false
) {
  let key = k.GENERIC__UNKNOWN;
  switch (durationUnitType?.toLowerCase()) {
    case 'years':
      key = isPlural ? k.DATE__YEAR__PLURAL : k.DATE__YEAR;
      break;
    case 'months':
      key = isPlural ? k.DATE__MONTH__PLURAL : k.DATE__MONTH;
      break;
    case 'weeks':
      key = isPlural ? k.DATE__WEEK__PLURAL : k.DATE__WEEK;
      break;
    case 'days':
      key = isPlural ? k.DATE__DAY__PLURAL : k.DATE__DAY;
      break;
    case 'hours':
      key = isPlural ? k.DATE__HOUR__PLURAL : k.DATE__HOUR;
      break;
    case 'minutes':
      key = isPlural ? k.GENERIC__MINUTE__PLURAL : k.GENERIC__MINUTE;
      break;
    case 'seconds':
      key = isPlural ? k.GENERIC__SECOND__PLURAL : k.GENERIC__SECOND;
      break;
    case 'words':
      key = isPlural ? k.GENERIC__WORD__PLURAL : k.GENERIC__WORD;
      break;
    case 'pages':
      key = isPlural ? k.GENERIC__PAGE__PLURAL : k.GENERIC__PAGE;
      break;
    case 'questions':
      key = isPlural ? k.GENERIC__QUESTION__PLURAL : k.GENERIC__QUESTION;
      break;
    case 'unknown':
      key = k.GENERIC__UNKNOWN;
      break;
    default:
      return '';
  }

  return i18n.t(key);
}
