import MoveContentToNewSectionModal from './MoveContentToNewSectionModal';
import {CustomProgramAdminSectionVM} from '@models/serverModels';
import {getUpdateCustomProgramContentSectionRm} from '@store/apiEndpoints/customProgram/mutations';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import {useCustomProgramAdminSectionVMsQuery} from '@hooks/apiEndpoints/customProgram/queries';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface MoveContentToNewSectionModalContainerProps {
  visible: boolean;
  onCancel: () => void;
  sections: Pick<CustomProgramAdminSectionVM, 'id' | 'title'>[];
  contentId: number;
  customProgramId: number;
}

function MoveContentToNewSectionModalContainer({
  contentId,
  visible,
  onCancel,
  sections,
  customProgramId,
}: MoveContentToNewSectionModalContainerProps) {
  const customProgramSectionsQuery = useCustomProgramAdminSectionVMsQuery(
    customProgramId,
    {enabled: false}
  );

  const moveContentToAnotherSectionMutation = useMutation(
    (newSectionId: number) => {
      const updateCustomProgramContentSectionRm =
        getUpdateCustomProgramContentSectionRm({
          contentId,
          newSectionId,
        });
      return simpleMutationFn<null>(
        updateCustomProgramContentSectionRm.path,
        updateCustomProgramContentSectionRm.payload
      );
    },
    {
      onSuccess: async () => {
        await customProgramSectionsQuery.invalidateExact();
        onCancel();
      },
      onError: () => {
        notify.moveCustomProgramContentError();
      },
    }
  );

  return (
    <MoveContentToNewSectionModal
      visible={visible}
      onCancel={onCancel}
      sections={sections}
      onOk={async (newSectionId: number) => {
        await moveContentToAnotherSectionMutation.mutateAsync(newSectionId);
      }}
    />
  );
}

export default MoveContentToNewSectionModalContainer;
