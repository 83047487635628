import {RecurrenceType} from './../enums';
import {RecurringDetail} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: RecurringDetail

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getRecurringDetailMockData(
  args?: Partial<RecurringDetail>
): RecurringDetail {
  return {
    dayOfMonth: faker.datatype.number(),
    dayOfWeek: faker.datatype.number(),
    maxOccurrences: faker.datatype.number(),
    monthOfYear: faker.datatype.number(),
    recurrenceType: RecurrenceType.Daily,
    separationCount: faker.datatype.number(),
    weekOfMonth: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const RecurringDetailMockData: RecurringDetail = {
  dayOfMonth: 123,
  dayOfWeek: 123,
  maxOccurrences: 123,
  monthOfYear: 123,
  recurrenceType: RecurrenceType.Daily,
  separationCount: 5,
  weekOfMonth: 123,
};
