import {AcademyStatus} from './../enums';
import {AcademyVM} from './../interfaces';
import {
  AcademyBrandVMMockData,
  getAcademyBrandVMMockData,
} from './AcademyBrandVM.mock';
import {
  AcademySkillVMMockData,
  getAcademySkillVMMockData,
} from './AcademySkillVM.mock';
import {
  IAcademyBasicInfoMockData,
  getIAcademyBasicInfoMockData,
} from './IAcademyBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyVMMockData(args?: Partial<AcademyVM>): AcademyVM {
  return {
    ...getIAcademyBasicInfoMockData(),
    academyProfileImageUrl: faker.image.imageUrl(), // optional
    academySkillVMs: [getAcademySkillVMMockData()],
    branding: getAcademyBrandVMMockData(),
    degreedId: String(faker.datatype.number()), // optional
    description: faker.lorem.lines(),
    discussionId: String(faker.datatype.number()), // optional
    groupId: String(faker.datatype.number()), // optional
    id: faker.datatype.number(),
    invitationEmail: faker.datatype.boolean(),
    joinId: String(faker.datatype.number()),
    name: faker.name.fullName(),
    restrictedAccess: faker.datatype.boolean(),
    status: AcademyStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyVMMockData: AcademyVM = {
  ...IAcademyBasicInfoMockData,
  academyProfileImageUrl: 'https://loremflickr.com/640/480', // optional
  academySkillVMs: [AcademySkillVMMockData],
  branding: AcademyBrandVMMockData,
  degreedId: '1', // optional
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  discussionId: '1', // optional
  groupId: '1', // optional
  id: 1,
  invitationEmail: true,
  joinId: '1',
  name: 'Bobby Johnson',
  restrictedAccess: true,
  status: AcademyStatus.Draft,
};
