import {CompanySetting} from './../enums';
import {ICompanySettingCommand} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ICompanySettingCommand

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getICompanySettingCommandMockData(
  args?: Partial<ICompanySettingCommand>
): ICompanySettingCommand {
  return {
    companyId: faker.datatype.number(),
    settingKey: CompanySetting.LxpDomain,
    settingValue: faker.word.noun(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ICompanySettingCommandMockData: ICompanySettingCommand = {
  companyId: 1,
  settingKey: CompanySetting.LxpDomain,
  settingValue: 'string', // optional
};
