import {useState} from 'react';
import {i18n, k} from '@i18n/translate';

import {MembersDrawer} from './MembersDrawer';
import {useCustomProgramsUsersQuery} from '@generated/hooks';
import {MembersDrawerSearch} from './MembersDrawerSearch';
import {Paginator} from './Paginator';
import useFeatureFlags from '@hooks/useFeatureFlags';

interface MembersDrawerContainerProps {
  visible: boolean;
  setShowDrawer: (show: boolean) => void;
  customProgramId: number;
  membersCount: number;
}

export function MembersDrawerContainer({
  visible,
  setShowDrawer,
  customProgramId,
  membersCount,
}: MembersDrawerContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [page, setPage] = useState<number>(0);
  const limit = 15;

  const {
    data: members,
    isLoading,
    invalidateExact,
  } = useCustomProgramsUsersQuery({
    customProgramId,
    queryParams: {
      searchText: searchTerm,
      limit,
      page,
    },
  });

  function onSearch(term: string) {
    setSearchTerm(term);
    invalidateExact();
  }

  return (
    <MembersDrawer
      membersCount={(members as any)?.meta?.headers?.['x-total-count'] || 0}
      isLoading={isLoading}
      searchComponent={<MembersDrawerSearch onSearch={onSearch} />}
      paginationComponent={
        <Paginator
          current={page + 1}
          onChange={(newPage: number) => setPage(newPage - 1)}
          total={membersCount}
          pageSize={limit}
        />
      }
      visible={visible}
      title={i18n.t(k.COHORT__YOUR_COHORT)}
      members={members}
      setShowDrawer={setShowDrawer}
      canShowEmployeeEmail={isFeatureFlagOn.ShowEmployeeEmail}
    />
  );
}
