import {useRef, useState, useCallback} from 'react';
import {i18n, k} from '@i18n/translate';
import {Col, Form, Rate, Row, Typography} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {HeartFilled} from '@ant-design/icons';
import {
  ProgramProgressUpdatesDrawerProps,
  UserProgressSummary,
} from '../../store/interface';
import {parseDateOrDefault} from '@utils/timeUtils';
import {mapValueToProgressReportSummary} from '@utils/enumMapping/ProgressReportSummaryMapper';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {StyledLearnInDatePicker} from '@components/reusable/LearnInDatePicker';

function ProgramProgressUpdatesDrawer(
  props: ProgramProgressUpdatesDrawerProps
) {
  const {
    visible,
    currentProgressItem,
    currentPlanId,
    onOk,
    onCancel,
    programProgressUpdateSubmit,
    skills,
  } = props;
  const formRef = useRef<FormInstance>(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const renderSkills = useCallback(() => {
    if (!visible || !skills?.length) {
      return null;
    }

    return skills.map((skill, index) => {
      return (
        <LearnInSelectOption value={skill.name} key={index}>
          {skill.name}
        </LearnInSelectOption>
      );
    });
  }, [visible, currentProgressItem]);

  const formatToSkillsObj = (skillsArr: any) => {
    const newSkillsObj = [];
    skillsArr.forEach((skill: any) => {
      const currSkill = skills.find((s) => s.name === skill);
      if (currSkill) {
        newSkillsObj.push(currSkill);
      } else {
        newSkillsObj.push({id: null, name: skill});
      }
    });
    return newSkillsObj;
  };

  return (
    <LearnInDrawer
      placement="bottom"
      responsiveSize={625}
      title={i18n.t(k.PROGRESS__UPDATES)}
      visible={visible}
      footer={[
        <Row key="submit">
          <Col span={1} offset={20}>
            <LearnInButton
              tag={ButtonTags.DrawerFooterPrimary}
              style={{marginRight: '11%'}}
              loading={confirmLoading}
              onClick={async () => {
                setConfirmLoading(true);
                try {
                  await formRef.current.validateFields();
                  const data = formRef.current.getFieldsValue();
                  const payload = {
                    id: currentProgressItem.id,
                    title: data.title,
                    content: data.note,
                    submitted: true,
                    skills: formatToSkillsObj(data.skills),
                    rating: data.ratings,
                    summary: data.summary,
                  };
                  programProgressUpdateSubmit(currentPlanId, payload);
                  onOk();
                  setConfirmLoading(false);
                  // formRef.current.resetFields();
                } catch (error) {
                  console.log('Error', error);
                  setConfirmLoading(false);
                  return;
                }
              }}>
              {i18n.t(k.CTA__SUBMIT)}
            </LearnInButton>
          </Col>
        </Row>,
      ]}
      onClose={() => {
        formRef.current.resetFields();
        onCancel();
      }}>
      <Row className="progress-drawer-content-layout">
        <Col xs={24} md={7} className="progress-drawer-paragraph">
          <Row>
            <Typography.Title>
              {i18n.t(k.PROGRESS__UPDATES__TITLE)}
            </Typography.Title>
          </Row>
          <Row>
            <Typography.Text>
              {i18n.t(k.PROGRESS__UPDATES__DESCRIPTION)}
            </Typography.Text>
          </Row>
        </Col>
        <Col xs={24} md={7} className="progress-drawer-form-layout">
          <Form className="form-drawer" ref={formRef} layout="vertical">
            <Form.Item
              className="lb-application-drawer"
              name="title"
              label={i18n.t(k.GENERIC__TITLE).toLocaleUpperCase()}
              initialValue={
                currentProgressItem?.title ? currentProgressItem.title : ''
              }
              rules={[
                {
                  required: true,
                  message: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                    fieldName: i18n.t(k.GENERIC__TITLE),
                  }),
                },
              ]}>
              <LearnInInput tag={InputTags.PRIMARY} />
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="date"
              label={i18n.t(k.DATE).toLocaleUpperCase()}
              initialValue={parseDateOrDefault(
                currentProgressItem?.date?.toString()
              )}
              rules={[
                {
                  required: false,
                  message: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                    fieldName: i18n.t(k.DATE).toLocaleLowerCase(),
                  }),
                },
              ]}>
              <StyledLearnInDatePicker disabled={true} />
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="note"
              label={i18n.t(k.PROGRESS).toLocaleUpperCase()}
              initialValue={
                currentProgressItem?.content ? currentProgressItem.content : ''
              }
              rules={[
                {
                  required: true,
                  message: i18n.t(k.PROGRAM__VALIDATION__CONTENT),
                },
              ]}>
              <LearnInTextArea tag={TextAreaTags.PRIMARY} maxLength={500} />
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="summary"
              label={i18n.t(k.GENERIC__SUMMARY).toLocaleUpperCase()}
              initialValue={mapValueToProgressReportSummary(
                currentProgressItem?.summary
              )}
              rules={[{required: false}]}>
              <LearnInSelect
                style={{width: '100%'}}
                placeholder={i18n.t(k.PROGRESS__HOW_FEELING)}>
                <LearnInSelectOption value={UserProgressSummary.FeelingGreat}>
                  {i18n.t(k.PROGRESS__STATUS__FEELING_GREAT)}
                </LearnInSelectOption>
                <LearnInSelectOption value={UserProgressSummary.MakingProgress}>
                  {i18n.t(k.PROGRESS__STATUS__MAKING_PROGRESS)}
                </LearnInSelectOption>
                <LearnInSelectOption value={UserProgressSummary.TooBusy}>
                  {i18n.t(k.PROGRESS__STATUS__TOO_BUSY)}
                </LearnInSelectOption>
                <LearnInSelectOption value={UserProgressSummary.Stressed}>
                  {i18n.t(k.PROGRESS__STATUS__STRESSED)}
                </LearnInSelectOption>
                <LearnInSelectOption value={UserProgressSummary.NeedHelp}>
                  {i18n.t(k.PROGRESS__STATUS__NEED_HELP)}
                </LearnInSelectOption>
              </LearnInSelect>
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="skills"
              label={i18n.t(k.GENERIC__SKILL__PLURAL).toLocaleUpperCase()}
              rules={[
                {
                  required: true,
                  message: i18n.t(k.PROGRAM__VALIDATION__CONTENT),
                },
              ]}>
              <LearnInSelect
                mode="tags"
                style={{width: '100%'}}
                placeholder={i18n.t(k.PROGRESS__SKILLS_YOU_LEARNED)}>
                {renderSkills()}
              </LearnInSelect>
            </Form.Item>
            <Form.Item
              className="lb-application-drawer"
              name="ratings"
              label={i18n.t(k.GENERIC__RATING__PLURAL).toLocaleUpperCase()}>
              <Rate
                aria-label={i18n.t(k.GENERIC__RATING)}
                character={<HeartFilled aria-hidden="true" />}
                style={{color: '#ff6d85'}}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </LearnInDrawer>
  );
}

export default ProgramProgressUpdatesDrawer;
