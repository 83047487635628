import {SubmissionType} from './../enums';
import {BaseCustomProgramContentPayload} from './../interfaces';
import {LinkPayloadMockData, getLinkPayloadMockData} from './LinkPayload.mock';
import {faker} from '@faker-js/faker';

// Interface: BaseCustomProgramContentPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getBaseCustomProgramContentPayloadMockData(
  args?: Partial<BaseCustomProgramContentPayload>
): BaseCustomProgramContentPayload {
  return {
    allowPeerVisibility: faker.datatype.boolean(),
    description: faker.lorem.lines(),
    dueDate: String(faker.date.past()), // optional
    dueDateTimeZone: faker.address.timeZone(), // optional
    dueTime: String(faker.date.past()), // optional
    endDate: String(faker.date.past()), // optional
    endTime: String(faker.date.past()), // optional
    eventLink: faker.internet.url(), // optional
    eventTimeZone: faker.address.timeZone(), // optional
    links: [getLinkPayloadMockData()],
    startDate: String(faker.date.past()), // optional
    startTime: String(faker.date.past()), // optional
    submissionType: SubmissionType.Text, // optional
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const BaseCustomProgramContentPayloadMockData: BaseCustomProgramContentPayload =
  {
    allowPeerVisibility: true,
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    dueDate: '2023-03-29T22:17:19.738072', // optional
    dueDateTimeZone: 'Asia/Dubai', // optional
    dueTime: '2023-03-29T22:17:19.738072', // optional
    endDate: '2023-03-29T22:17:19.738072', // optional
    endTime: '2023-03-29T22:17:19.738072', // optional
    eventLink: 'https://this-person-does-not-exist.com/en', // optional
    eventTimeZone: 'Asia/Dubai', // optional
    links: [LinkPayloadMockData],
    startDate: '2023-03-29T22:17:19.738072', // optional
    startTime: '2023-03-29T22:17:19.738072', // optional
    submissionType: SubmissionType.Text, // optional
    title: 'Advanced Web Design',
  };
