// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CustomProgramStatus.cs

enum CustomProgramStatus {
  Draft,
  Published,
  Deleted,
}

export default CustomProgramStatus;
