import React from 'react';
import t from '@i18n/translate';
import {Sources} from 'quill';
import {UnprivilegedEditor} from 'react-quill';
import {AcademyVM} from '@generated/interfaces';
import InvitationEmailDrawerFooter from './InvitationEmailDrawer.footer';
import EmailNoteEditor from '@components/reusable/EmailNoteEditorDrawer';

// rich text editor character limit for validation
const MAX_NOTES_CHAR = 800;

interface InvitationEmailDrawerProps {
  onClose: () => void;
  open: boolean;
  academy: AcademyVM;
  emailNote?: string;
  emailHasNote: boolean;
  isEditingNote: boolean;
  onNoteChange: (
    newValue: string,
    delta: Record<string, unknown>,
    source: Sources,
    editor: UnprivilegedEditor
  ) => void;
  emailSections: [HTMLElement, HTMLElement, HTMLElement];
  emailNoteCharCount: number;
  isDeletePendingSave: boolean;
  transformNoteBeforeRender?: (noteQuillMarkup: string) => string;
  emailNoteIntro?: string;
  onAddNote: () => void;
  onCancel: () => void;
  onRemoveNote: () => void;
  onSaveChanges: () => void;
  onEditNote: () => void;
  companyId: number;
}

const InvitationEmailDrawer: React.FC<InvitationEmailDrawerProps> = ({
  onClose,
  open,
  academy,
  emailHasNote,
  emailSections,
  emailNoteIntro,
  isEditingNote,
  onNoteChange,
  emailNote,
  onAddNote,
  onCancel,
  onRemoveNote,
  onSaveChanges,
  emailNoteCharCount,
  transformNoteBeforeRender,
  onEditNote,
  isDeletePendingSave,
  companyId,
}) => {
  return (
    <EmailNoteEditor
      open={open}
      transformNoteBeforeRender={transformNoteBeforeRender}
      companyId={companyId}
      emailHasNote={emailHasNote}
      emailNoteCharCount={emailNoteCharCount}
      emailSections={emailSections}
      isDeletePendingSave={isDeletePendingSave}
      isEditingNote={isEditingNote}
      onClose={onClose}
      onEditNote={onEditNote}
      onNoteChange={onNoteChange}
      drawerTitle={t('ACADEMY__INVITATION_EMAIL__PREVIEW')}
      emailSubject={t('ACADEMY__INVITATION_EMAIL__SUBJECT__FORMAT', {
        academy: academy.name,
      })}
      emailNote={emailNote}
      emailNoteIntro={emailNoteIntro}
      footer={
        <InvitationEmailDrawerFooter
          canSaveChanges={
            (emailNoteCharCount <= MAX_NOTES_CHAR &&
              emailNoteCharCount > 0 &&
              isEditingNote) ||
            isDeletePendingSave
          }
          hasNote={emailHasNote}
          isEditing={isEditingNote}
          onAddNote={onAddNote}
          onCancel={onCancel}
          onRemoveNote={onRemoveNote}
          onSaveChanges={onSaveChanges}
          isDeletePendingSave={isDeletePendingSave}
        />
      }
    />
  );
};

export default InvitationEmailDrawer;
