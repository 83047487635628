import {MouseEvent, useRef} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInTable, {
  DataPanel,
  TableColLink,
  TableCell,
} from '@components/reusable/LearnInTable';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import {CheckCircleOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import LearnInTag, {TagStyles} from '@blocks/LearnInTag';
import {truncate} from 'lodash';
import {ProjectSubmissionAdminVM} from '@generated/interfaces';
import NoDataText from '@blocks/NoDataText';
import {formatUrl} from '@utils/stringUtils';
import {getUsersLocale} from '@utils/l10nUtils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const ContentContainer = styled.div`
  padding: 24px;
  max-width: 1200px;
  margin: auto;
`;

const DataPanelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const DataPanelContainer = styled.div`
  width: calc(50% - 20px);
`;

const BigDataPanelDisplayText = styled.span`
  color: ${COLORS.Neutral900};
  font-weight: 400;
  font-size: 2rem;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramProjectDetailsDrawerProps {
  dataSource: ProjectSubmissionAdminVM[];
  showFeedbackColumn: boolean;
  maxSubmitted: number;
  onClickRow: (row: ProjectSubmissionAdminVM) => void;
  onClickSubmitFeedback: (id: number, triggerSelector: string) => void;
  onClickUploadLink: (id: number) => void;
  onClose: () => void;
  projectDueDate: string | undefined;
  showRowHoverStyle: (row: ProjectSubmissionAdminVM) => boolean;
  title: string;
  totalSubmitted: number;
  visible: boolean;
}

function CustomProgramProjectDetailsDrawer({
  dataSource,
  showFeedbackColumn,
  maxSubmitted,
  onClickRow,
  onClickSubmitFeedback,
  onClickUploadLink,
  onClose,
  projectDueDate,
  showRowHoverStyle,
  title,
  totalSubmitted,
  visible,
}: CustomProgramProjectDetailsDrawerProps) {
  const triggerRef = useRef(null);
  const columns = [
    {
      title: i18n.t(k.PARTICIPANT),
      dataIndex: 'participantName',
      key: 'participantName',
      render: (participantName: string, row: ProjectSubmissionAdminVM) => {
        return <button>{participantName}</button>;
      },
    },
    {
      title: i18n.t(k.DATE__SUBMITTED),
      dataIndex: 'submittedOn',
      key: 'submittedOn',
      render: (submittedOn: ProjectSubmissionAdminVM['submittedOn']) => {
        if (!!submittedOn)
          return (
            <button>
              {new Date(submittedOn).toLocaleDateString(getUsersLocale())}
            </button>
          );
        if (!!projectDueDate && new Date() > new Date(projectDueDate))
          return (
            <button>
              <LearnInTag
                tagStyle={TagStyles.Red}
                label={i18n.t(k.DATE__PAST_DUE)}
              />
            </button>
          );
        return <button>{i18n.t(k.STATUS__NOT_SUBMITTED)}</button>;
      },
    },
    {
      title: i18n.t(k.SUBMISSION),
      key: 'submission',
      render: (
        _: undefined,
        {links, uploads, response}: ProjectSubmissionAdminVM
      ) => {
        if (!!response)
          return (
            <button>
              <div>{truncate(response, {length: 250})}</div>
            </button>
          );
        if (!!links?.length)
          return (
            <a
              onClick={(e) => e.stopPropagation()}
              href={formatUrl(links[0])}
              target="_blank"
              rel="noreferrer">
              <TableColLink>{i18n.t(k.SUBMISSION__VIEW)}</TableColLink>
            </a>
          );
        if (!!uploads?.length)
          return (
            <div>
              <TableColLink
                onClick={(e: MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  onClickUploadLink(uploads[uploads.length - 1].id);
                }}>
                {i18n.t(k.SUBMISSION__VIEW)}
              </TableColLink>
            </div>
          );

        return null;
      },
    },
    showFeedbackColumn && {
      title: i18n.t(k.FEEDBACK),
      key: 'feedback',
      dataIndex: 'feedback',
      render: (feedback: string, row: ProjectSubmissionAdminVM) => {
        if (!!feedback?.length) {
          return (
            <TableCell style={{paddingLeft: '24px'}}>
              <CheckCircleOutlined
                aria-hidden="true"
                style={{
                  color: COLORS.Green700,
                  fontSize: '1.25rem',
                }}
              />
            </TableCell>
          );
        }

        return (
          <TableCell>
            <TableColLink
              id={`submit-feedback-${row?.id}`}
              onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onClickSubmitFeedback(row?.id, `#submit-feedback-${row?.id}`);
              }}>
              {i18n.t(k.FEEDBACK__SUBMIT)}
            </TableColLink>
          </TableCell>
        );
      },
    },
  ].filter(Boolean);

  return (
    <LearnInPageDrawer
      onClose={onClose}
      visible={visible}
      title={title}
      zIndex={998}>
      <ContentContainer>
        <DataPanelsContainer>
          <DataPanelContainer style={{width: '100%'}}>
            <DataPanel
              items={[
                {
                  label: i18n.t(k.FUND__TOTAL_SUBMITTED).toLocaleUpperCase(),
                  display: (
                    <div>
                      <BigDataPanelDisplayText style={{marginRight: '6px'}}>
                        {totalSubmitted}
                      </BigDataPanelDisplayText>
                    </div>
                  ),
                  icon: {
                    Icon: CheckCircleOutlined,
                    color: COLORS.Green700,
                    background: COLORS.Green200,
                  },
                },
              ]}
            />
          </DataPanelContainer>
        </DataPanelsContainer>
        {!!totalSubmitted ? (
          <LearnInTable
            dataSource={dataSource}
            columns={columns}
            onClickRow={onClickRow}
            showRowHoverStyle={showRowHoverStyle}
          />
        ) : (
          <NoDataText
            header={i18n.t(k.PARTICIPANT__PROGRAM__EMPTY__TITLE)}
            subHeader={i18n.t(k.PARTICIPANT__PROGRAM__EMPTY__DESCRIPTION)}
          />
        )}
      </ContentContainer>
    </LearnInPageDrawer>
  );
}

export default CustomProgramProjectDetailsDrawer;
