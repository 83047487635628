import {LinkPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: LinkPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLinkPayloadMockData(
  args?: Partial<LinkPayload>
): LinkPayload {
  return {
    id: faker.datatype.number(), // optional
    title: faker.company.catchPhrase(),
    url: faker.internet.url(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LinkPayloadMockData: LinkPayload = {
  id: 1, // optional
  title: 'Advanced Web Design',
  url: 'https://this-person-does-not-exist.com/en',
};
