import {
  getUserFinancePlanRq,
  getUserProgramPlanRq,
  getUserTimePlanRq,
  getUserLearningBudgetInfoRq,
} from '@store/apiEndpoints';
import {PlanItemType} from '@generated/enums';
import {PlanItem} from '@models/clientModels';

export function getPlanItemQueryInvalidateKey(
  itemType: PlanItem,
  learningBudgets = false
): string {
  let queryKey: string[];

  switch (itemType) {
    case PlanItemType.Finance:
      if (learningBudgets) {
        queryKey = getUserLearningBudgetInfoRq().queryKey;
      } else {
        queryKey = getUserFinancePlanRq().queryKey;
      }
      break;
    case PlanItemType.Program:
      queryKey = getUserProgramPlanRq().queryKey;
      break;
    case PlanItemType.Time || PlanItemType.Time:
      queryKey = getUserTimePlanRq().queryKey;
      break;
  }
  return queryKey[0];
}
