import {IncentiveAvailability} from './../enums';
import {IncentivePayload} from './../interfaces';
import {
  ApprovalConfigMockData,
  getApprovalConfigMockData,
} from './ApprovalConfig.mock';
import {
  AttachmentPayloadMockData,
  getAttachmentPayloadMockData,
} from './AttachmentPayload.mock';
import {faker} from '@faker-js/faker';

// Interface: IncentivePayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIncentivePayloadMockData(
  args?: Partial<IncentivePayload>
): IncentivePayload {
  return {
    approvalConfig: getApprovalConfigMockData(),
    approvalGuidelines: faker.word.noun(),
    availability: IncentiveAvailability.All,
    details: faker.word.noun(),
    featured: faker.datatype.boolean(),
    id: faker.datatype.number(), // optional
    images: [getAttachmentPayloadMockData()],
    learningBudget: faker.datatype.boolean(),
    longDescription: faker.lorem.lines(),
    programTypeIds: [faker.datatype.number()],
    shortDescription: faker.lorem.lines(),
    subTitle: faker.company.catchPhrase(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IncentivePayloadMockData: IncentivePayload = {
  approvalConfig: ApprovalConfigMockData,
  approvalGuidelines: 'string',
  availability: IncentiveAvailability.All,
  details: 'string',
  featured: true,
  id: 1, // optional
  images: [AttachmentPayloadMockData],
  learningBudget: true,
  longDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  programTypeIds: [1],
  shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  subTitle: 'Advanced Web Design',
  title: 'Advanced Web Design',
};
