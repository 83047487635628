import {COLORS} from '@utils/constants';
import {Checkbox, Col, Form, Row} from 'antd';
import {LearnInSelect} from '@components/reusable/LearnInForm';
import styled from 'styled-components';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {HomeOutlined} from '@ant-design/icons';

export const settingsProfileColWidth = 360;

const baseSpacing = 24;
const baseSpacingPx = `${baseSpacing}px`;

export const SettingsForm = styled(Form)`
  padding: ${baseSpacingPx} 8px;
  label {
    color: ${COLORS.Neutral800};
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.25rem;
  }
  .ant-form-item-label {
    padding-bottom: 6px;
  }
  .ant-checkbox-wrapper,
  .ant-radio-wrapper {
    font-size: 1rem;
    font-weight: 400;
    padding: 0;
  }
`;

export const SettingsBox = styled.div`
  border-radius: 8px;
  box-shadow: ${COLORS.BoxShadowStandard};
  margin-bottom: ${baseSpacingPx};
  padding: ${baseSpacingPx};
`;

export const SettingsProfileRow = styled(Row)`
  max-width: ${settingsProfileColWidth * 2 + baseSpacing * 3}px;

  @media (max-width: 575px) {
    max-width: 100% !important;
  }
`;

export const SettingsProfileCol = styled(Col)`
  max-width: ${settingsProfileColWidth + baseSpacing}px;

  &:nth-child(odd) {
    .ant-form-item {
      padding-right: ${baseSpacingPx} !important;
    }
  }

  @media (max-width: 575px) {
    max-width: 100% !important;
    padding-right: 0 !important;

    .ant-form-item {
      padding-right: 0 !important;
    }

    &:nth-child(odd) {
      .ant-form-item {
        padding-right: 0 !important;
      }
    }
  }
`;

export const SettingsFormItem = styled(Form.Item)`
  margin-top: ${baseSpacingPx};
  margin-bottom: 0;
`;

export const SettingsNotificationsFormItem = styled(Form.Item)`
  margin: ${baseSpacingPx} 0;
`;

export const SettingsCheckboxGroup = styled(Checkbox.Group)`
  padding: ${baseSpacingPx} 0 0;
`;

export const SettingsSubTitle = styled.div`
  font-weight: normal;
  font-size: 1rem;
  color: ${COLORS.Neutral600};
  padding-bottom: 16px;
`;

export const SettingsTitle = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: 0;
`;

export const SettingsCurrencySelect = styled(LearnInSelect)`
  .ant-select-selection-placeholder {
    line-height: 40px !important;
  }
  @media (max-width: 575px) {
    width: 100% !important;
  }
`;

export const SettingsIntegrations = styled.div`
  ul {
    margin-bottom: 0 !important;
    margin-top: 24px !important;
    padding-bottom: 0 !important;
  }
`;

export const SettingsAnimatedGif = styled.img`
  height: 166px;
  width: 166px;
  margin-bottom: 8px;
`;

export const SettingsFooter = styled.div`
  padding-top: ${baseSpacingPx};
  text-align: right;
`;

export const SettingsSaveButton = styled(LearnInButton)`
  display: inline-block;
`;

export const SettingsPaymentTerms = styled.div`
  margin-bottom: ${baseSpacingPx};
  margin-top: ${baseSpacingPx};
`;

export const SettingsHomeOutlined = styled(HomeOutlined)`
  margin-right: 0.6666rem;
  font-size: 1.25rem;
  position: relative;
  top: 0.1rem;
`;

export const SettingsIconText = styled.span`
  color: ${COLORS.Blue800};
  font-size: 1rem;
  font-weight: 500;
  padding: 0.625rem 1rem;
  vertical-align: middle;
`;
