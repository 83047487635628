import {
  ApprovalReason,
  ApprovalsDiscriminator,
  ApprovalStatus,
  CurrencyCode,
  UserRequestRedemptionType,
} from './../enums';
import {UserRequest} from './../interfaces';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {ILongIdMockData, getILongIdMockData} from './ILongId.mock';
import {
  IUserRequestBasicInfoMockData,
  getIUserRequestBasicInfoMockData,
} from './IUserRequestBasicInfo.mock';
import {
  PreApprovalQuestionMockData,
  getPreApprovalQuestionMockData,
} from './PreApprovalQuestion.mock';
import {UserBudgetMockData, getUserBudgetMockData} from './UserBudget.mock';
import {faker} from '@faker-js/faker';

// Interface: UserRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserRequestMockData(
  args?: Partial<UserRequest>
): UserRequest {
  return {
    ...getBaseEntityMockData(),
    ...getIUserRequestBasicInfoMockData(),
    ...getILongIdMockData(),
    amount: faker.datatype.number(),
    amountRequested: faker.datatype.number(),
    amountType: String(faker.datatype.number()),
    application: [getPreApprovalQuestionMockData()],
    approvalExpirationDate: String(faker.date.past()), // optional
    approvalReason: ApprovalReason.AutoApproved, // optional
    currencyRequested: CurrencyCode.USD,
    discriminator: ApprovalsDiscriminator.Incentive,
    expiredAmount: faker.datatype.number(), // optional
    id: faker.datatype.number(),
    initialAdminApprovalId: faker.datatype.number(), // optional
    isRawAmountRequested: faker.datatype.boolean(),
    migratedData: faker.datatype.boolean(),
    oldTableAdminRecord: faker.datatype.number(), // optional
    preMigrationData: faker.datatype.boolean(),
    redemptionType: UserRequestRedemptionType.VirtualCard, // optional
    reminderSendUtc: String(faker.date.past()), // optional
    requestCostCenter: faker.word.noun(), // optional
    requestedById: faker.datatype.number(),
    requestedDate: String(faker.date.past()),
    requestedItemId: faker.datatype.number(),
    shouldApprovalExpire: faker.datatype.boolean(),
    status: ApprovalStatus.Pending,
    statusChangedById: faker.datatype.number(), // optional
    statusFinalizedDate: String(faker.date.past()),
    userBudget: getUserBudgetMockData(),
    userBudgetId: faker.datatype.number(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserRequestMockData: UserRequest = {
  ...BaseEntityMockData,
  ...IUserRequestBasicInfoMockData,
  ...ILongIdMockData,
  amount: 5,
  amountRequested: 5,
  amountType: '5',
  application: [PreApprovalQuestionMockData],
  approvalExpirationDate: '2023-03-29T22:17:19.738072', // optional
  approvalReason: ApprovalReason.AutoApproved, // optional
  currencyRequested: CurrencyCode.USD,
  discriminator: ApprovalsDiscriminator.Incentive,
  expiredAmount: 5, // optional
  id: 1,
  initialAdminApprovalId: 1, // optional
  isRawAmountRequested: true,
  migratedData: true,
  oldTableAdminRecord: 123, // optional
  preMigrationData: true,
  redemptionType: UserRequestRedemptionType.VirtualCard, // optional
  reminderSendUtc: '2023-03-29T22:17:19.738072', // optional
  requestCostCenter: 'string', // optional
  requestedById: 1,
  requestedDate: '2023-03-29T22:17:19.738072',
  requestedItemId: 1,
  shouldApprovalExpire: true,
  status: ApprovalStatus.Pending,
  statusChangedById: 1, // optional
  statusFinalizedDate: '2023-03-29T22:17:19.738072',
  userBudget: UserBudgetMockData,
  userBudgetId: 1, // optional
};
