import {RefObject, useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import moment from 'moment-timezone';
import {Form, FormInstance} from 'antd';
import {GridContainer} from '@blocks/Grid';
import {BodyParagraph, BodySpan} from '@components/reusable/Typography';
import CostCenterField from '@components/reusable/CostCenterField/CostCenterField';
import {ApproverNotes} from './ApproverNotes';
import {ApprovalTypeSpecificFields} from './ApprovalTypeSpecificFields';
import FundsExpireOnField from '@components/reusable/FundsExpireOnField/FundsExpireOnField';
import {updateFundsExpireOnFormValue} from './SharedRequestForm';
import {ApprovalStatus, ApprovalsDiscriminator} from '@generated/enums';
import {
  ResourceApprovalRequest,
  ApprovalRequestDetailsVM,
  UserRequestApprovalVM,
} from '@models/serverModels';
import {CostCenterOption} from '@components/reusable/CostCenterField/CostCenterField.interfaces';
import {AdminScreenApprovalStatus} from '@models/clientEnums';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {getAdminProgramDetailPath} from '@utils/ClientPaths';
import {Link} from 'react-router-dom';

const ProgramTitleLink = styled(Link)`
  color: ${COLORS.Blue800} !important;
`;

interface OriginalRequestFormProps {
  requestLoading: boolean;
  requestSuccess: boolean;
  originalRequest: UserRequestApprovalVM | ResourceApprovalRequest;
  selectedApproval: UserRequestApprovalVM | ResourceApprovalRequest;
  formRef: RefObject<FormInstance>;
  isNoteRequired: boolean;
  itemType: ApprovalsDiscriminator;
  showCostCenter: boolean;
  showUnspentFunds: boolean;
  activeTab: ApprovalStatus | AdminScreenApprovalStatus;
  selectedCostCenter?: string;
  costCenterOptions: CostCenterOption[];
  updateCostCenter: (costCenter: string, approvalId: number) => void;
  isReadonly: boolean;
  requestData: ApprovalRequestDetailsVM;
  isResource: (itemType: ApprovalsDiscriminator) => boolean;
  approvalExpiry: string;
}
export const OriginalRequestForm = ({
  requestLoading,
  requestSuccess,
  originalRequest,
  selectedApproval,
  formRef,
  isNoteRequired,
  itemType,
  showCostCenter,
  showUnspentFunds,
  activeTab,
  selectedCostCenter,
  costCenterOptions,
  updateCostCenter,
  isReadonly,
  requestData,
  isResource,
  approvalExpiry,
}: OriginalRequestFormProps) => {
  const [approvalExpirationDate, setApprovalExpirationDate] = useState(
    approvalExpiry ? moment(approvalExpiry) : null
  );

  useEffect(() => {
    updateFundsExpireOnFormValue(formRef, approvalExpirationDate);
  }, [approvalExpirationDate, formRef]);

  useEffect(() => {
    setApprovalExpirationDate(approvalExpiry ? moment(approvalExpiry) : null);
  }, [approvalExpiry]);

  const isProgram = itemType === ApprovalsDiscriminator.Program;
  const requestLabel = isProgram
    ? i18n.t(k.FUND__REQUESTED_PROGRAM)
    : isResource(itemType)
    ? i18n.t(k.FUND__REQUEST__WHAT_TYPE)
    : i18n.t(k.GENERIC__TITLE);

  const unavailable =
    requestLoading ||
    (!(requestSuccess && originalRequest) && !selectedApproval);

  return unavailable ? null : (
    <>
      <GridContainer column>
        <BodySpan fontWeight={500}>{i18n.t(k.APPROVAL__REQUESTOR)}</BodySpan>
        <BodyParagraph>
          {originalRequest.requestedBy || ''}
          {requestData?.requestedEmail
            ? ` (${requestData?.requestedEmail})`
            : ''}
        </BodyParagraph>
      </GridContainer>
      <GridContainer column>
        <BodySpan fontWeight={500}>{requestLabel}</BodySpan>
        <BodyParagraph>
          {isResource(itemType) ? (
            originalRequest.title || ''
          ) : (
            <ProgramTitleLink
              to={getAdminProgramDetailPath(originalRequest.itemId)}
              data-cy="programTitle">
              {originalRequest.title || ''}
            </ProgramTitleLink>
          )}
        </BodyParagraph>
      </GridContainer>
      <Form key={originalRequest.id} ref={formRef} layout="vertical">
        <ApprovalTypeSpecificFields
          showParent
          isResource={isResource}
          requestData={requestData}
          originalRequest={originalRequest}
        />
        {showUnspentFunds && (
          <FundsExpireOnField
            approvalExpirationDate={approvalExpirationDate}
            setApprovalExpirationDate={setApprovalExpirationDate}
          />
        )}
        {showCostCenter && (
          <CostCenterField
            activeTab={activeTab}
            costCenterOptions={costCenterOptions}
            selectedCostCenter={selectedCostCenter}
            originalRequest={originalRequest}
            selectedApproval={selectedApproval}
            updateCostCenter={updateCostCenter}
          />
        )}
        <ApproverNotes
          isReadonly={isReadonly}
          isNoteRequired={isNoteRequired}
        />
      </Form>
    </>
  );
};
