import {Input} from 'antd';
import styled from 'styled-components';

export const LearnInSearchInput = styled(Input.Search)`
  .ant-input-affix-wrapper,
  .ant-input-search-button {
    height: 40px;
  }
  .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-radius: 4px 0 0 4px !important;
  }
  .ant-input-search-button {
    border-radius: 0 4px 4px 0 !important;
  }
`;
