import {BalanceRecurType, BudgetAllocationType} from './../enums';
import {UserBudgetRepositoryTransactionResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserBudgetRepositoryTransactionResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserBudgetRepositoryTransactionResponseMockData(
  args?: Partial<UserBudgetRepositoryTransactionResponse>
): UserBudgetRepositoryTransactionResponse {
  return {
    allocationType: BudgetAllocationType.PreAllocated,
    amountAllocated: faker.datatype.number(),
    balance: faker.datatype.number(),
    employeeName: faker.name.fullName(),
    lastUsed: faker.word.noun(),
    lineOfBusiness: faker.word.noun(),
    preApprovalRequired: faker.datatype.boolean(),
    recurrenceType: BalanceRecurType.NotApplicable,
    totalApproved: faker.datatype.number(),
    totalRequested: faker.datatype.number(),
    userBudgetId: faker.datatype.number(),
    userCompanyId: faker.datatype.number(),
    userPlanItemId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserBudgetRepositoryTransactionResponseMockData: UserBudgetRepositoryTransactionResponse =
  {
    allocationType: BudgetAllocationType.PreAllocated,
    amountAllocated: 5,
    balance: 123,
    employeeName: 'Bobby Johnson',
    lastUsed: 'string',
    lineOfBusiness: 'string',
    preApprovalRequired: true,
    recurrenceType: BalanceRecurType.NotApplicable,
    totalApproved: 123,
    totalRequested: 123,
    userBudgetId: 1,
    userCompanyId: 1,
    userPlanItemId: 1,
  };
