import {AcademyStepContentModalPayload} from '@models/clientModels';
import {DATE_FORMAT} from '@utils/timeUtils';
import {FormInstance} from 'antd';
import identity from 'lodash/identity';
import omit from 'lodash/omit';
import moment, {Moment} from 'moment';
import {FORM_KEYS} from './Form/constants';
import {mapTextToEnglish} from '@utils/enumMapping/MapTextToUserLanguage';

/**
 * Normalizes form data into request payload
 */
export function normalizeFormOutput(
  form: FormInstance
): Partial<AcademyStepContentModalPayload> {
  const normalizedValues = {};

  const paylodKeys = omit(FORM_KEYS, ['EVENT_DATE']);

  const deriveKeyValue = {
    [FORM_KEYS.EVENT_START_DATE]: FORM_KEYS.EVENT_DATE,
    [FORM_KEYS.EVENT_END_DATE]: FORM_KEYS.EVENT_DATE,
  };

  Object.values(paylodKeys).forEach((fieldKey) => {
    const key = deriveKeyValue[fieldKey] || fieldKey;
    const fieldValue = form.getFieldValue(key);
    const normalizer = fieldNormalizer[fieldKey] || identity;
    normalizedValues[fieldKey] = normalizer(fieldValue);
  });

  return normalizedValues;
}

/**
 * Custom normalizer functions for form fields
 */
const fieldNormalizer: Record<string, Function> = {
  [FORM_KEYS.DUE_DATE]: dateNormalizer,
  [FORM_KEYS.DUE_TIME]: timeNormalizer,
  [FORM_KEYS.EVENT_START_TIME]: timeNormalizer,
  [FORM_KEYS.EVENT_END_TIME]: timeNormalizer,
  [FORM_KEYS.EVENT_START_DATE]: dateNormalizer,
  [FORM_KEYS.EVENT_END_DATE]: dateNormalizer,
  [FORM_KEYS.TITLE]: mapTextToEnglish,
};

/**
 * Converts moment.js date into an ISO date formatted string
 *
 * @example
 * const normalized = dateNormalizer(moment())
 * console.log(normalized) // --> 2024-04-18
 */
function dateNormalizer(date: string | Moment | null | undefined) {
  if (!date) {
    return;
  }

  if (typeof date === 'string') {
    return date;
  } else if (moment.isMoment(date)) {
    return date.format(DATE_FORMAT.ISO_DATE);
  }
}

/**
 * Normalizes time value from a moment.js object
 *
 * @example
 * const normalized = timeNormalizer(moment())
 * console.log(normalized) // --> 11:20
 */
function timeNormalizer(time: Moment | undefined) {
  return time?.format(DATE_FORMAT.TIME_24_HR_PADDED);
}
