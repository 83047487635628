import {useEffect, useMemo, useRef, useState} from 'react';
import CreateProgramDrawer, {FormFields} from './CreateProgramDrawer';
import {FormInstance} from 'antd/lib/form';
import {EditProgram, ProgramFilterVM} from '@models/serverModels';
import {CurrencyCode} from '@generated/enums';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {AddNewProgram} from '@store/apiEndpoints';
import {
  getAddCompanyProgramRm,
  getUpdateMarketplaceProgramRm,
} from '@store/apiEndpoints/program/mutations';
import {notify} from '@components/user/notifications';
import {
  mapTimeCommitmentTypeString,
  PresenceType,
} from '@components/admin/pages/programs/store/interface';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {
  useGetProgramDetailsQuery,
  useGetProgramsProgramTypesQuery,
  useGetProvidersQuery,
} from '@hooks/apiEndpoints/program/queries';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {mapDurationTypeString} from '@utils/enumMapping/MapDurationTypeString';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

function getProviderSuggestions({id, description}: ProgramFilterVM): {
  value: string;
  label: string;
  id: number;
} {
  return {id, label: description, value: description};
}

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CreateProgramDrawerContainerProps {
  id?: number;
  visible: boolean;
  onClose: () => void;
  refreshParentData?: () => void;
}

function CreateProgramDrawerContainer({
  id,
  onClose,
  visible,
  refreshParentData,
}: CreateProgramDrawerContainerProps) {
  const formRef = useRef<FormInstance>(null);
  const [provider, setProvider] = useState('');
  const getUserQuery = useGetUserQuery();

  useEffect(() => {
    formRef?.current?.resetFields();
  }, [visible]);

  // Get Program Types
  const programTypesQuery = useGetProgramsProgramTypesQuery(null);

  // Get Provider Suggestions
  const providersQuery = useGetProvidersQuery(null);

  // Get Marketplace program data
  useGetProgramDetailsQuery(id, {
    enabled: visible && !!id,
    onSuccess: ({
      cost,
      currency,
      presenceType,
      duration,
      shortDescription,
      timeCommitmentType,
      applyUrl,
      title,
      provider,
      programType,
    }) => {
      // Format data
      const [programLength, programLengthUnit] = duration.split(' ');
      const _programType = programTypesQuery.data?.find(
        ({description}) => description === programType
      );

      // Set Form default values to fetched program
      formRef.current.setFieldsValue({
        [FormFields.Cost]: cost,
        [FormFields.Currency]: currency
          ? CurrencyCode[currency].toString()
          : MONEY_DEFAULT.currency,
        [FormFields.PresenceType]: PresenceType[presenceType],
        [FormFields.Provider]: provider,
        [FormFields.ProgramLength]: Number(programLength),
        [FormFields.ProgramLengthUnit]:
          mapDurationTypeString(programLengthUnit),
        [FormFields.ProgramTypeId]: _programType?.id,
        [FormFields.ShortDescription]: shortDescription,
        [FormFields.TimeCommitmentType]:
          mapTimeCommitmentTypeString(timeCommitmentType),
        [FormFields.Title]: title,
        [FormFields.Url]: applyUrl,
      });
      setProvider(provider);
    },
  });

  // Add Marketplace Program
  const addCompanyProgramMutation = useMutation(
    (args: AddNewProgram) => {
      const addProgram = getAddCompanyProgramRm(args);
      return simpleMutationFn<null>(addProgram.path, addProgram.payload);
    },
    {
      onSuccess: () => {
        notify.newProgramAddedByAdminSuccess();
        refreshParentData && refreshParentData();
        onClose();
      },
      onError: () => {
        notify.newProgramAddError();
      },
    }
  );

  // Update Marketplace Program
  const updateMarketplaceProgramMutation = useMutation(
    (args: EditProgram) => {
      const updateMarketplaceProgram = getUpdateMarketplaceProgramRm(args);
      return simpleMutationFn<null>(
        updateMarketplaceProgram.path,
        updateMarketplaceProgram.payload
      );
    },
    {
      onSuccess: () => {
        refreshParentData && refreshParentData();
        notify.updateProgramAddedByAdminSuccess();
        onClose();
      },
      onError: () => {
        notify.updateProgramAddedByAdminError();
      },
    }
  );

  // Provider suggestions are used in the Provider select input
  const providerSuggestions = useMemo(() => {
    return providersQuery.data?.map(getProviderSuggestions) || [];
  }, [!!providersQuery.data]);

  // Update Marketplace Program
  const handleSubmitForm = async ({
    url,
    provider,
    ...program
  }: AddNewProgram & {provider?: string}) => {
    // If a known provider is selected, find that provider's id
    const suggestedProvider = providerSuggestions.find(
      ({label}) => label === provider
    );
    const formattedPostPayload = {
      ...program,
      url: encodeURIComponent(url),
      providerId: suggestedProvider?.id || 0,
      providerName: provider || null,
    };

    // If no id is provided, we are creating a program
    if (!id) {
      await addCompanyProgramMutation.mutateAsync(formattedPostPayload);
    } else {
      await updateMarketplaceProgramMutation.mutateAsync({
        programId: id,
        ...formattedPostPayload,
      });
    }
  };

  return (
    <CreateProgramDrawer
      id={id}
      onClose={onClose}
      onSubmitForm={handleSubmitForm}
      programTypes={programTypesQuery.data || []}
      providerSuggestions={providerSuggestions}
      userCurrency={getUserQuery.data?.currency}
      ref={formRef}
      visible={visible}
      provider={provider}
      onChangeProvider={setProvider}
      user={getUserQuery.data}
    />
  );
}

export default CreateProgramDrawerContainer;
