// Do not modify directly
// Generated from file: learnin.core.dal/Core/DTOs/Enums/ProgramSearchCategory.cs

enum ProgramSearchCategory {
  Degree,
  Course,
  Bootcamp,
  Unknown,
}

export default ProgramSearchCategory;
