import './Programs.scss';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Col, Input, Row, Skeleton, Tabs} from 'antd';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import styled from 'styled-components';
import {notify} from '@components/user/notifications';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  CustomProgramAdminDetailVM,
  MarketPlaceProgramsTableData,
} from '@models/serverModels';
import {debounce} from 'lodash';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import MarketPlaceProgramsTable from './MarketPlaceProgramsTable';
import CustomProgramsTable from './CustomProgramsTable.container';
import CreateProgramDrawer from './CreateProgramDrawer.container';
import CustomProgramAdminDrawer from './customPrograms/CustomProgramAdminDrawer.container';
import NewProgramOptionForm from './NewProgramOptionForm';
import useTreatment from '@hooks/useTreatment';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {
  AdminProgramPageTabKey,
  adminProgramsCustomRoute,
  adminProgramsMarketplaceRoute,
  adminProgramsPageRoute,
} from '@utils/ClientPaths';
import {useCustomProgramAdminDetailVMsQuery} from '@hooks/apiEndpoints/customProgram/queries';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useGetCurrencyExchangeQuery} from '@hooks/apiEndpoints/localization/queries';
import {useGetAdminMarketplaceProgramsQuery} from '@hooks/apiEndpoints/program/queries';
import {filterQuery} from '@utils/filterUtils';
import {
  useUpdateFeatureProgramMutation,
  useUpdateProgramAvailabilityMutation,
} from '@hooks/apiEndpoints/program/mutations';
import {KEY_CODES} from '@utils/constants';
import {useIsLxpAdmin} from '@hooks/integrated/useIsLxpAdmin';
import {Helmet} from 'react-helmet-async';

const {TabPane} = Tabs;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const SingleTableContainer = styled.div`
  padding: 16px 32px 120px;
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
const PROGRAM_SEARCH_VALUE = 'program-search-value';

/*
|--------------------------------------------------------------------------
| Page Component
|--------------------------------------------------------------------------
*/

interface ProgramsProps {
  title?: string;
}

const Programs = ({title}: ProgramsProps) => {
  const [updateMarketPlaceProgramId, setUpdateMarketplaceProgramId] = useState<
    number | null
  >(null);
  const [isAddProgramDrawerVisible, setIsAddProgramDrawerVisible] =
    useState(false);
  const [customProgramForEdit, setCustomProgramForEdit] = useState<Pick<
    CustomProgramAdminDetailVM,
    'id' | 'status'
  > | null>(null);
  const [showCustomProgramAdminDrawer, setShowCustomProgramAdminDrawer] =
    useState(false);
  //Make search persistent
  const [programSearchText, setProgramSearchText] = useState(
    localStorage.getItem(PROGRAM_SEARCH_VALUE) || ''
  );
  const [loading, setLoading] = useState(false);
  const [
    searchFilteredMarketplacePrograms,
    setSearchFilteredMarketPlacePrograms,
  ] = useState<MarketPlaceProgramsTableData[]>([]);
  const [searchFilteredCustomPrograms, setSearchFilteredCustomPrograms] =
    useState<CustomProgramAdminDetailVM[]>([]);

  const {tab} = useParams();
  const navigate = useNavigate();

  const getUserQuery = useGetUserQuery();
  const exchangeRates = useGetCurrencyExchangeQuery({
    enabled: true,
  });

  const openUpsertMarketplaceProgramDrawer = (id?: number) => {
    setIsAddProgramDrawerVisible(true);
    if (!!id) setUpdateMarketplaceProgramId(id);
  };

  const closeUpsertMarketplaceProgramDrawer = () => {
    setIsAddProgramDrawerVisible(false);
    setUpdateMarketplaceProgramId(null);
  };

  // Feature Flags
  const isCustomProgramsOn =
    useTreatment(FeatureFlagExperiments.CustomPrograms) ===
    FeatureFlagTreatments.On;
  const isOnlyShowCustomProgramsOn =
    useTreatment(FeatureFlagExperiments.OnlyCustomProgramsUser) ===
      FeatureFlagTreatments.On && isCustomProgramsOn;

  // Determine which programs to show
  const onlyShowCustomPrograms =
    isCustomProgramsOn && isOnlyShowCustomProgramsOn;
  const onlyShowMarketplacePrograms = !isCustomProgramsOn;
  const showAllProgramTypes = isCustomProgramsOn && !onlyShowCustomPrograms;

  // Ensure that a valid url tab param exists
  useEffect(() => {
    if (
      onlyShowCustomPrograms &&
      tab !== AdminProgramPageTabKey.CustomPrograms
    ) {
      navigate(adminProgramsCustomRoute());
    } else if (
      onlyShowMarketplacePrograms &&
      tab !== AdminProgramPageTabKey.MarketPlacePrograms
    ) {
      navigate(adminProgramsMarketplaceRoute());
    } else if (
      showAllProgramTypes &&
      Object.values(AdminProgramPageTabKey).every((val) => tab !== val)
    ) {
      navigate(adminProgramsMarketplaceRoute());
    }
  }, [tab]);

  // Marketplace Programs Table Data
  const adminMarketplaceProgramsQuery =
    useGetAdminMarketplaceProgramsQuery(null);

  // Custom Programs List Table Data
  const getCustomProgramsAdminDetailQuery =
    useCustomProgramAdminDetailVMsQuery();

  const updateFeatureProgramMutation = useUpdateFeatureProgramMutation({
    onSuccess: (_, variables) => {
      if (variables.payload.feature) {
        notify.markFeaturedSuccess();
      } else {
        notify.markRemovedFeaturedSuccess();
      }
    },
    onSettled: () => {
      adminMarketplaceProgramsQuery.refetch();
    },
  });

  /*** MarketPlaceProgramsTable Methods ***/
  const handleToggleIsProgramFeatured = (
    program: MarketPlaceProgramsTableData,
    event: any
  ) => {
    const feature = event.target.checked;
    updateFeatureProgramMutation.mutate(
      {payload: {id: program.id, feature}},
      {
        onError() {
          notify.markFeaturedFailure(program?.programTitle);
        },
      }
    );
  };

  const updateProgramAvailabilityMutation =
    useUpdateProgramAvailabilityMutation({
      onSuccess(_, {payload: {available}}) {
        if (available) {
          notify.markAvailableSuccess();
        } else {
          notify.markUnavailableSuccess();
        }
      },
      onSettled() {
        adminMarketplaceProgramsQuery.refetch();
      },
    });

  const handleToggleIsProgramAvailable = (
    program: MarketPlaceProgramsTableData,
    event: any
  ) => {
    const available = event.target.checked;
    updateProgramAvailabilityMutation.mutate(
      {payload: {id: program?.id, available}},
      {
        onError() {
          notify.markAvailabilityFailure(program?.programTitle);
        },
      }
    );
  };

  /*** Marketplace and Custom Program Table Text Search ***/
  const applyMarketPlaceProgramsSearch = debounce(() => {
    const searchParameters = ['programTitle', 'provider'];
    const searchResults = filterQuery(
      adminMarketplaceProgramsQuery.data,
      programSearchText,
      searchParameters
    );
    setSearchFilteredMarketPlacePrograms(searchResults);
    setLoading(false);
  }, 750);

  const applyCustomProgramsSearch = debounce(() => {
    const searchParameters = ['title'];
    const searchResults = filterQuery(
      getCustomProgramsAdminDetailQuery.data,
      programSearchText,
      searchParameters
    );
    setSearchFilteredCustomPrograms(searchResults);
    setLoading(false);
  }, 750);

  React.useEffect(() => {
    if (tab === AdminProgramPageTabKey.CustomPrograms) {
      applyCustomProgramsSearch();
    } else if (tab === AdminProgramPageTabKey.MarketPlacePrograms) {
      applyMarketPlaceProgramsSearch();
    }
  }, [
    adminMarketplaceProgramsQuery.data,
    getCustomProgramsAdminDetailQuery.data,
    programSearchText,
    tab,
  ]);

  React.useEffect(() => {
    applyCustomProgramsSearch();
  }, [getCustomProgramsAdminDetailQuery.isSuccess]);

  useIsLxpAdmin(false); // Even if LXP admin, we want to show the ACM admin nav

  useEffect(() => {
    setLoading(true);
    localStorage.removeItem(PROGRAM_SEARCH_VALUE);

    document.querySelectorAll('.ant-input-clear-icon').forEach((element) => {
      element.setAttribute('aria-label', i18n.t(k.CTA__CLEAR_ALL));
      element.setAttribute('tabindex', '0');
      element.addEventListener('keydown', (event: KeyboardEvent) => {
        event.key === KEY_CODES.ENTER && setProgramSearchText('');
      });
    });
  }, []);

  const renderMarketPlaceProgramsTable = () => {
    return (
      <>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {loading ? (
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Skeleton active />
            </Col>
            <Col xs={24}>
              <Skeleton active />
            </Col>
            <Col xs={24}>
              <Skeleton active />
            </Col>
          </Row>
        ) : (
          <MarketPlaceProgramsTable
            dataSource={searchFilteredMarketplacePrograms || []}
            usersCurrency={getUserQuery.data?.currency}
            exchangeRates={exchangeRates}
            isLoading={adminMarketplaceProgramsQuery.isLoading}
            noSearchResults={
              adminMarketplaceProgramsQuery.isSuccess &&
              !!programSearchText &&
              !searchFilteredMarketplacePrograms?.length &&
              !!adminMarketplaceProgramsQuery.data?.length
            }
            onToggleIsProgramAvailable={handleToggleIsProgramAvailable}
            onToggleIsProgramFeatured={handleToggleIsProgramFeatured}
            onClickEditIcon={openUpsertMarketplaceProgramDrawer}
            programSearchText={programSearchText}
          />
        )}
      </>
    );
  };

  const renderCustomProgramsTable = () => {
    return (
      <>
        {loading ? (
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Skeleton active />
            </Col>
            <Col xs={24}>
              <Skeleton active />
            </Col>
            <Col xs={24}>
              <Skeleton active />
            </Col>
          </Row>
        ) : (
          <CustomProgramsTable
            key={customProgramForEdit?.id}
            dataSource={searchFilteredCustomPrograms || []}
            openCustomProgramEditDrawerAfterDuplication={(customProgram) => {
              setShowCustomProgramAdminDrawer(true);
              setCustomProgramForEdit(customProgram);
            }}
            pagination
          />
        )}
      </>
    );
  };

  return (
    <>
      <PageTitleBar
        fullWidth={true}
        title={i18n.t(k.PROGRAM__PLURAL)}
        jsxComponent={
          <div style={{display: 'flex'}}>
            <form
              aria-label={i18n.t(k.PROGRAM__SEARCH_PROGRAMS_OR_PROVIDERS)}
              role="search">
              <fieldset style={{display: 'flex', position: 'relative'}}>
                <Input.Search
                  allowClear
                  onChange={(e) => setProgramSearchText(e.target.value)}
                  onSubmit={(e) => e.preventDefault()}
                  placeholder={i18n.t(k.PROGRAM__SEARCH_PROGRAMS_OR_PROVIDERS)}
                  style={{width: '270px', marginRight: '16px'}}
                  value={programSearchText}
                />
              </fieldset>
            </form>
            {isCustomProgramsOn ? (
              <NewProgramOptionForm
                onClickAddExistingProgram={() => {
                  setIsAddProgramDrawerVisible(true);
                }}
                onClickBuildCustom={() => {
                  setShowCustomProgramAdminDrawer(true);
                }}
              />
            ) : (
              <LearnInButton
                data-testid="new-program-button"
                tag={ButtonTags.Primary}
                onClick={() => {
                  setIsAddProgramDrawerVisible(true);
                }}>
                {i18n.t(k.PROGRAM__NEW)}
              </LearnInButton>
            )}
          </div>
        }
      />

      {/* Drawer for Creating and Editing Custom Programs */}
      {showCustomProgramAdminDrawer && (
        <CustomProgramAdminDrawer
          customProgramId={customProgramForEdit?.id}
          onClose={() => {
            setShowCustomProgramAdminDrawer(false);
            setCustomProgramForEdit(undefined);
          }}
          setCustomProgramForEdit={setCustomProgramForEdit}
          visible={showCustomProgramAdminDrawer}
        />
      )}

      {/* Drawer to Create New Marketplace Program */}
      {isAddProgramDrawerVisible && (
        <CreateProgramDrawer
          onClose={closeUpsertMarketplaceProgramDrawer}
          id={updateMarketPlaceProgramId}
          visible={isAddProgramDrawerVisible}
          refreshParentData={adminMarketplaceProgramsQuery.refetch}
        />
      )}

      {showAllProgramTypes && (
        <LearnInTabs
          onChange={(key: AdminProgramPageTabKey) => {
            navigate(adminProgramsPageRoute(key));
          }}
          activeKey={tab}>
          <TabPane
            tab={i18n.t(k.PROGRAM__MARKETPLACE__PLURAL)}
            key={AdminProgramPageTabKey.MarketPlacePrograms}>
            {renderMarketPlaceProgramsTable()}
          </TabPane>
          <TabPane
            tab={i18n.t(k.PROGRAM__CUSTOM__PLURAL)}
            key={AdminProgramPageTabKey.CustomPrograms}>
            {renderCustomProgramsTable()}
          </TabPane>
        </LearnInTabs>
      )}
      {onlyShowMarketplacePrograms && (
        <SingleTableContainer>
          {renderMarketPlaceProgramsTable()}
        </SingleTableContainer>
      )}
      {onlyShowCustomPrograms && (
        <SingleTableContainer>
          {renderCustomProgramsTable()}
        </SingleTableContainer>
      )}
      <Outlet />
    </>
  );
};

export default Programs;
