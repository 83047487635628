import CohortAdminPageDrawer from './CohortAdminPageDrawer';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CohortAdminPageDrawerContainerProps {
  parentCustomProgramId: number;
  onClose: () => void;
  visible: boolean;
  setCohortForEditIdOnCreate?: (id: number) => void;
  cohortId: number;
}

function CohortAdminPageDrawerContainer({
  parentCustomProgramId,
  cohortId,
  onClose,
  visible,
  setCohortForEditIdOnCreate,
}: CohortAdminPageDrawerContainerProps) {
  return (
    <CohortAdminPageDrawer
      parentCustomProgramId={parentCustomProgramId}
      onClose={onClose}
      cohortId={cohortId}
      visible={visible}
      setCohortForEditIdOnCreate={setCohortForEditIdOnCreate}
    />
  );
}

export default CohortAdminPageDrawerContainer;
