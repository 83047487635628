import {i18n, k} from '@i18n/translate';
import {DeleteOutlined} from '@ant-design/icons';
import LearnInTable, {
  ColumnAlignment,
  IColumn,
  TooltipCol,
} from '@components/reusable/LearnInTable';
import {ContributorPermissionType} from '@models/clientEnums';
import {CustomizableLearningCategory} from '@generated/enums';
import {COLORS} from '@utils/constants';
import {Checkbox, Radio} from 'antd';
import styled from 'styled-components';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import AddContributorsButton from './AddContributorsButton';
import {PermissionVM} from '@models/serverModels';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';

const StyledDeleteOutlined = styled(DeleteOutlined)`
  &:hover {
    color: ${COLORS.Red100};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Header = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral900};
  margin-bottom: 4px;
  padding-left: 0px;
`;

const Subheader = styled.h3`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  max-width: 675px;
  color: ${COLORS.Neutral600};
`;

export interface ContributorsTableProps {
  dataSource: PermissionVM[];
  learningCategory: CustomizableLearningCategory;
  onClickAddContributors: () => void;
  onClickDelete: (userId: number) => void;
  onChangePermission: (
    userId: number,
    permissionType: string,
    enabled: boolean
  ) => void;
  canShowApproveProgramsColumn?: boolean;
}

function ContributorsTable({
  dataSource,
  learningCategory,
  onChangePermission,
  onClickAddContributors,
  onClickDelete,
  canShowApproveProgramsColumn,
}: ContributorsTableProps) {
  const isAcademy = learningCategory === CustomizableLearningCategory.Academy;
  const learningResourceName = i18n.t(isAcademy ? k.ACADEMY : k.PROGRAM);

  function renderCheckBox(permissionType: string, checkboxLabel: string) {
    return function PermissionCheckbox(checked: boolean, row: PermissionVM) {
      return (
        <Checkbox
          onChange={(e) => {
            onChangePermission(
              row.userCompanyId,
              permissionType,
              e.target.checked
            );
          }}
          aria-label={checkboxLabel}
          checked={checked}
        />
      );
    };
  }

  const columns: IColumn<PermissionVM>[] = [
    TooltipCol({
      title: i18n.t(k.GENERIC__NAME),
      tooltipDataIndex: 'email',
      width: 340,
    }),
    isAcademy &&
      canShowApproveProgramsColumn && {
        key: ContributorPermissionType.canApproveProgram,
        dataIndex: ContributorPermissionType.canApproveProgram,
        title: i18n.t(k.PROGRAM__PLURAL__APPROVE),
        align: ColumnAlignment.Center,
        render: (checked: boolean, data: PermissionVM) => {
          return (
            <Radio
              checked={checked}
              aria-label={i18n.t(k.PROGRAM__PLURAL__APPROVE)}
              onClick={() => {
                onChangePermission(
                  data.userCompanyId,
                  ContributorPermissionType.canApproveProgram,
                  !checked
                );
              }}
            />
          );
        },
      },
    {
      title: i18n.t(k.PROJECT__REVIEW__PLURAL),
      key: ContributorPermissionType.canReviewProject,
      dataIndex: ContributorPermissionType.canReviewProject,
      align: ColumnAlignment.Center,
      render: renderCheckBox(
        ContributorPermissionType.canReviewProject,
        i18n.t(k.PROJECT__REVIEW__PLURAL)
      ),
    },
    {
      title: i18n.t(k.REPORT__PLURAL__VIEW),
      key: ContributorPermissionType.canViewReport,
      dataIndex: ContributorPermissionType.canViewReport,
      align: ColumnAlignment.Center,
      render: renderCheckBox(
        ContributorPermissionType.canViewReport,
        i18n.t(k.REPORT__PLURAL__VIEW)
      ),
    },
    {
      title: i18n.t(
        learningCategory === CustomizableLearningCategory.Academy
          ? k.ACADEMY__EDIT
          : k.PROGRAM__EDIT
      ),
      key: ContributorPermissionType.canEdit,
      dataIndex: ContributorPermissionType.canEdit,
      align: ColumnAlignment.Center,
      render: renderCheckBox(
        ContributorPermissionType.canEdit,
        i18n.t(
          learningCategory === CustomizableLearningCategory.Academy
            ? k.ACADEMY__EDIT
            : k.PROGRAM__EDIT
        )
      ),
    },
    {
      title: '',
      key: 'action',
      dataIndex: '',
      render: (_, row) => {
        return (
          <LearnInButton
            onClick={() => onClickDelete(row.userCompanyId)}
            tag={ButtonTags.DestructiveRemovedBorder}
            icon={
              <span aria-label={i18n.t(k.CTA__DELETE)}>
                <StyledDeleteOutlined aria-hidden="true" />
              </span>
            }
          />
        );
      },
    },
  ].filter(Boolean);

  const getSubHeaderText = () => {
    if (canShowApproveProgramsColumn) {
      return i18n.t(k.CONTRIBUTOR__ACADEMY_PERMISSIONS_APPROVER_DETAILS);
    }

    // default heading
    return i18n.t(
      isAcademy
        ? k.CONTRIBUTOR__PERMISSIONS__DETAILS__ACADEMY
        : k.CONTRIBUTOR__PERMISSIONS__DETAILS__PROGRAM
    );
  };

  return (
    <LearnInContainer
      tag={ContainerTags.CommonCard}
      style={{
        display: 'block',
        width: '100%',
        maxWidth: '1200px',
        margin: '24px auto',
      }}>
      <HeaderContainer>
        <div>
          <Header>
            {i18n.t(
              isAcademy
                ? k.CONTRIBUTOR__PERMISSIONS__TITLE__ACADEMY
                : k.CONTRIBUTOR__PERMISSIONS__TITLE__PROGRAM
            )}
          </Header>
          <Subheader>{getSubHeaderText()}</Subheader>
        </div>
        <div>
          <AddContributorsButton onClick={onClickAddContributors} />
        </div>
      </HeaderContainer>
      <LearnInTable dataSource={dataSource} columns={columns} />
    </LearnInContainer>
  );
}

export default ContributorsTable;
