import styled from 'styled-components';
import {LearnInDatePicker} from '@components/reusable/LearnInDatePicker';
import {COLORS} from '@utils/constants';
import {TimePicker as AntdTimePicker} from 'antd';

export const DueDateContainer = styled.div`
  column-gap: 30px;
  display: flex;
  margin-bottom: 24px;
  width: 100%;
  & .ant-picker {
    height: 40px;
  }
  & .ant-picker ::placeholder {
    font-size: 1rem;
  }
`;

export const DatePickerContainer: typeof LearnInDatePicker = styled(
  LearnInDatePicker
)`
  border-color: ${COLORS.Neutral600} !important;
  border-radius: 4px !important;
  width: 275px;
  .ant-picker-input > input::placeholder,
  .ant-picker-suffix {
    color: ${COLORS.Neutral600};
    font-size: 1rem;
  }
`;

export const TimezoneSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  .ant-select-selection-item {
    color: ${COLORS.Blue800};
  }
  .ant-select-selection-search {
    width: 5rem;
  }
`;

export const TimePicker: typeof AntdTimePicker = styled(AntdTimePicker)`
  width: 275px;
  bordercolor: ${COLORS.Neutral600} !important;
  borderradius: 4px !important;
`;
