// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/DisplayStatus.cs

enum DisplayStatusWarningLevel {
  Valid,
  Warning,
  Error,
  Default,
  Unknown,
}

export default DisplayStatusWarningLevel;
