import {TimePicker as AntdTimePicker} from 'antd';
import {Moment} from 'moment';
import {
  LearnInDatePicker,
  LearnInDatePickerProps,
} from '@components/reusable/LearnInDatePicker';
import {createFormItemAdapter} from '../utils';

export const DatePicker = createFormItemAdapter<LearnInDatePickerProps, Moment>(
  LearnInDatePicker,
  'setValue',
  'value'
);

export const TimePicker = createFormItemAdapter(
  AntdTimePicker,
  'onSelect',
  'value'
);
