import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Divider, Image, Row, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import {TimeEventsStatus} from '../../store/interface';
import moment from 'moment';
import PreApprovalDrawer from '../../../components/preApprovalDrawer/PreApprovalDrawer.container';
import TimeEmptyStateImage from '../../../../../../assets/empty-time-plan.png';
import {startCase} from 'lodash';
import {UserPlanCardActions} from '@models/clientEnums';
import {UserPlanTimeItemVM} from '@models/serverModels';
import {UserPlanCardDataHeader} from '@blocks/userPlanCard/UserPlanCard';
import {getTimeAllocationDetailsPath} from '@store/apiEndpoints';
import {shouldShowExpandedCardView} from '@blocks/userPlanCardActionMenu/UserPlanCardActionMenu';
import ActionButton from '@blocks/actionButton/ActionButton';
import {PlanItemType} from '@generated/enums';
import {DATE_FORMAT} from '@utils/timeUtils';

interface Props {
  showActions: boolean;
  item: UserPlanTimeItemVM;
  handleSettingsActions: (action: UserPlanCardActions) => void;
}

const UserPlanTimeCard = ({
  showActions,
  item,
  handleSettingsActions,
}: Props) => {
  const navigate = useNavigate();

  const [showPreApprovalDrawer, setShowPreApprovalDrawer] = useState(false);

  const getClassNameForEventCard = (event: {
    approvalStatus: string;
  }): string => {
    if (
      event.approvalStatus === TimeEventsStatus.NOT_SUBMITTED ||
      event.approvalStatus === TimeEventsStatus.SCHEDULEREJECTED ||
      event.approvalStatus === TimeEventsStatus.CANCELLED
    ) {
      return 'event-card-main event-card-main-pending';
    } else {
      return 'event-card-main event-card-main-approved';
    }
  };

  return (
    <article aria-label={i18n.t(k.TIME)} data-cy={`time-${item.id}`}>
      <Row align="middle" justify="center">
        <Col xs={24}>
          {showPreApprovalDrawer && (
            <PreApprovalDrawer
              visible={showPreApprovalDrawer}
              itemId={item.timeIncentiveId}
              planItemId={item.id}
              planItemType={item.itemType}
              onOk={() => {
                setShowPreApprovalDrawer(false);
              }}
              onCancel={() => {
                setShowPreApprovalDrawer(false);
              }}
            />
          )}
        </Col>
      </Row>
      <Card
        role="region"
        aria-labelledby="user-plan-time-card-title"
        key={item.id}
        className="user-plan-item-full-card">
        <UserPlanCardDataHeader
          item={item}
          planItemTypeWithCustomProgram={PlanItemType.Time}
          showActions={showActions}
          handleSettingsActions={handleSettingsActions}
          handleShowPreApprovalDrawer={() => setShowPreApprovalDrawer(true)}
          onClickContainer={() =>
            navigate(getTimeAllocationDetailsPath(item.timeIncentiveId))
          }
        />

        {/* Expanded View */}
        {shouldShowExpandedCardView(PlanItemType.Time, item.status) && (
          <Row
            align="middle"
            style={{margin: '0 0 12px 0', alignItems: 'end'}}
            role="button"
            tabIndex={0}
            onClick={() => {
              navigate(`/user/manage/time/${item.id}`);
            }}>
            <Divider style={{margin: '0 0 24px 0'}} />

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                marginBottom: '20px',
              }}>
              <Typography.Text
                style={{
                  textTransform: 'uppercase',
                  fontWeight: 600,
                  fontSize: '0.75rem',
                  lineHeight: '0.75rem',
                }}
                id="user-plan-time-card-title">
                {i18n.t(k.TIME__SCHEDULED)}
              </Typography.Text>

              <ActionButton
                item={{
                  ...item,
                  ...{
                    itemType: PlanItemType.Time,
                    programApprovalRequired: false,
                  },
                }}
                itemType={PlanItemType.Time}
                handleShowPreApprovalDrawer={setShowPreApprovalDrawer}
                onSettings={handleSettingsActions}
                buttonLabel={i18n.t(k.CTA__VIEW__DETAIL__PLURAL)}
              />
            </div>

            {item.events?.length ? (
              <Row>
                {item.events.map((event, index) => {
                  return (
                    <Col key={index}>
                      <div className={getClassNameForEventCard(event)}>
                        <div className="event-card-header">
                          {moment(event.startTime, 'hh:mm:ss').format(
                            DATE_FORMAT.HOUR_MINUTE_24_HR_AM_PM
                          )}{' '}
                          -{' '}
                          {moment(event.endTime, 'hh:mm:ss').format(
                            DATE_FORMAT.HOUR_MINUTE_24_HR_AM_PM
                          )}
                        </div>
                        <div className="event-card-body">
                          <div className="event-card-title">
                            {moment(event.startDate).format(
                              DATE_FORMAT.WEEKDAY
                            )}
                            <br />
                            {moment(event.startDate).format(
                              DATE_FORMAT.MONTH_DAY_SHORT
                            )}
                          </div>
                          <div className="event-card-status">
                            {startCase(event.approvalStatus)}
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <>
                <Col sm={24}>
                  <Image src={TimeEmptyStateImage} />
                </Col>
              </>
            )}
          </Row>
        )}
      </Card>
    </article>
  );
};

export default UserPlanTimeCard;
