import './AddUpdateEventDrawer.scss';
import {i18n, k} from '@i18n/translate';
import {Checkbox, Col, Form, Row, TimePicker} from 'antd';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {StyledLearnInDatePicker} from '@components/reusable/LearnInDatePicker';

export enum CALENDAR_RECURRENCE_TYPE {
  Daily,
  Weekly,
  Monthly,
  Yearly,
}

type Props = {
  eventId: any;
  onCloseDrawer: any;
  visibleAddEventDrawer: any;
  handleCancel: any;
  handleOk: any;
  setToFullDayEvent: any;
  validateDate: any;
  allDayEvent: any;
  isRecurringEvent: any;
  onIsRecurringCheckboxChange: any;
  formRef: any;
};

function AddUpdateEventDrawer(props: Props) {
  const {
    eventId,
    onCloseDrawer,
    visibleAddEventDrawer,
    handleCancel,
    handleOk,
    setToFullDayEvent,
    validateDate,
    allDayEvent,
    isRecurringEvent,
    onIsRecurringCheckboxChange,
    formRef,
  } = props;
  return (
    <LearnInDrawer
      placement="bottom"
      title={i18n.t(eventId ? k.EVENT__UPDATE : k.EVENT__ADD)}
      onClose={onCloseDrawer}
      visible={visibleAddEventDrawer}
      footer={[
        <Row key="submit" justify="end">
          <LearnInButton
            tag={ButtonTags.DrawerFooterSecondary}
            style={{marginRight: '16px'}}
            onClick={handleCancel}>
            {i18n.t(k.CTA__CANCEL)}
          </LearnInButton>
          <LearnInButton
            tag={ButtonTags.DrawerFooterPrimary}
            style={{marginRight: '11%'}}
            onClick={handleOk}>
            {i18n.t(k.CTA__SAVE)}
          </LearnInButton>
        </Row>,
      ]}>
      <Form className="form-drawers" layout="vertical" ref={formRef}>
        <div className="form-content">
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="Title"
                label={i18n.t(k.GENERIC__TITLE)}
                className="lb-application-drawer"
                rules={[
                  {
                    required: true,
                    message: i18n
                      .t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                        item: i18n.t(k.GENERIC__TITLE),
                      })
                      .toLocaleLowerCase(),
                  },
                ]}>
                <LearnInInput tag={InputTags.PRIMARY} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              <Form.Item
                name="AllDayEvent"
                className="lb-application-drawer"
                valuePropName="checked">
                <Checkbox onChange={(e) => setToFullDayEvent(e.target.checked)}>
                  {i18n.t(k.EVENT__MAKE_ALL_DAY)}
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="space-between" align="top" gutter={[8, 8]}>
            <Col xs={24} md={12}>
              <Row justify="space-between" align="top" gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    name="StartDate"
                    label={i18n.t(k.DATE__START).toLocaleUpperCase()}
                    rules={[
                      {
                        required: true,
                        message: i18n
                          .t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                            item: i18n.t(k.DATE__START),
                          })
                          .toLocaleLowerCase(),
                      },
                    ]}>
                    <StyledLearnInDatePicker onChange={validateDate} />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    className="lb-application-drawer"
                    name="StartTime"
                    label={i18n.t(k.TIME__START)}
                    rules={[
                      {
                        required: true,
                        message: i18n
                          .t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                            item: i18n.t(k.TIME__START),
                          })
                          .toLocaleLowerCase(),
                      },
                    ]}>
                    <TimePicker format="HH:mm" disabled={allDayEvent} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xs={24} md={12}>
              <Row justify="space-between" align="top" gutter={[8, 8]}>
                <Col xs={12}>
                  <Form.Item
                    className="lb-application-drawer"
                    name="EndDate"
                    label={i18n.t(k.DATE__END)}
                    rules={[
                      {
                        required: true,
                        message: i18n
                          .t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                            item: i18n.t(k.DATE__END),
                          })
                          .toLocaleLowerCase(),
                      },
                    ]}>
                    <StyledLearnInDatePicker
                      disabled={allDayEvent || isRecurringEvent}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    className="lb-application-drawer"
                    name="EndTime"
                    label={i18n.t(k.TIME__END)}
                    rules={[
                      {
                        required: true,
                        message: i18n
                          .t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                            item: i18n.t(k.TIME__END),
                          })
                          .toLocaleLowerCase(),
                      },
                    ]}>
                    <TimePicker format="HH:mm" disabled={allDayEvent} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Form.Item
            className="lb-application-drawer"
            name="Recurring"
            valuePropName="checked">
            <Checkbox onChange={onIsRecurringCheckboxChange}>
              {i18n.t(k.EVENT__RECURRING)}
            </Checkbox>
          </Form.Item>
          {isRecurringEvent && (
            <Form.Item
              className="lb-application-drawer"
              name="RecurrenceType"
              label={i18n.t(k.EVENT__RECURS_HOW_OFTEN)}
              rules={[
                {
                  required: isRecurringEvent,
                  message: i18n.t(k.VALIDATION__SELECT_DURATION__POLITE),
                },
              ]}>
              <LearnInSelect
                placeholder={i18n.t(k.VALIDATION__SELECT_DURATION)}>
                <LearnInSelectOption value={CALENDAR_RECURRENCE_TYPE.Daily}>
                  {i18n.t(k.DATE__DAILY)}
                </LearnInSelectOption>
                <LearnInSelectOption value={CALENDAR_RECURRENCE_TYPE.Weekly}>
                  {i18n.t(k.DATE__WEEKLY)}
                </LearnInSelectOption>
                <LearnInSelectOption value={CALENDAR_RECURRENCE_TYPE.Monthly}>
                  {i18n.t(k.DATE__MONTHLY)}
                </LearnInSelectOption>
              </LearnInSelect>
            </Form.Item>
          )}
          <Form.Item
            className="lb-application-drawer"
            name="Description"
            label={i18n.t(k.GENERIC__DESCRIPTION)}
            rules={[
              {
                required: false,
                message: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                  item: i18n.t(k.GENERIC__DESCRIPTION).toLocaleLowerCase(),
                }),
              },
              {
                max: 5000,
                message: i18n.t(k.VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT, {
                  item: i18n.t(k.GENERIC__DESCRIPTION),
                  max: 5000,
                }),
              },
            ]}>
            <LearnInTextArea tag={TextAreaTags.PRIMARY} maxLength={5000} />
          </Form.Item>
          {eventId && (
            <Form.Item
              className="lb-application-drawer"
              name={i18n.t(k.CTA__CANCEL)}
              valuePropName="checked">
              <Checkbox>{i18n.t(k.EVENT__CANCEL)}</Checkbox>
            </Form.Item>
          )}
        </div>
      </Form>
    </LearnInDrawer>
  );
}

export default AddUpdateEventDrawer;
