import {PlanItemType} from './../enums';
import {PlanItemNewApprovalRequest} from './../interfaces';
import {
  PreApprovalApplicationMockData,
  getPreApprovalApplicationMockData,
} from './PreApprovalApplication.mock';
import {faker} from '@faker-js/faker';

// Interface: PlanItemNewApprovalRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPlanItemNewApprovalRequestMockData(
  args?: Partial<PlanItemNewApprovalRequest>
): PlanItemNewApprovalRequest {
  return {
    applicationDetails: getPreApprovalApplicationMockData(),
    attachmentIds: [String(faker.datatype.number())],
    optionId: faker.datatype.number(),
    planItemType: PlanItemType.Program,
    userBudgetId: faker.datatype.number(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PlanItemNewApprovalRequestMockData: PlanItemNewApprovalRequest = {
  applicationDetails: PreApprovalApplicationMockData,
  attachmentIds: ['1'],
  optionId: 1,
  planItemType: PlanItemType.Program,
  userBudgetId: 1, // optional
};
