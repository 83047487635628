import './UserPlan.scss';
import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {useQuery} from 'react-query';
import {Tabs} from 'antd';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {
  getDoProgramsRequireApprovalRq,
  getUserFinancePlanRq,
  getUserPlanResourcesRq,
  getUserProgramPlanRq,
  getUserTimePlanRq,
} from '@store/apiEndpoints';
import {cacheForeverOptions, simpleQueryFn} from '@store/queryClient';
import {
  UserPlanFinanceItemVM,
  UserPlanProgramItemVM,
  UserPlanResourceVM,
  UserPlanTimeItemVM,
} from '@models/serverModels';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import {PersonalLearningBudgetCard} from './components/personalLearningBudgetCard/PersonalLearningBudgetCard';
import UserPlanTab from './components/userPlanTab/UserPlanTab';
import {UserPlanItemStatusString, YourPlanTabName} from '@models/clientEnums';
import EmptyDataText from '@blocks/NoDataText';
import RequestResourceApprovalDrawer from '@components/user/components/resources/RequestResourceApprovalDrawer.container';
import CreateUserProgramDrawerContainer from '../components/createUserProgramDrawer/CreateUserProgramDrawer.container';
import {
  CreditCardApprovalStatus,
  FinanceType,
  UserResourceStatus,
} from '@generated/enums';
import {
  useHasResourcesEnabledQuery,
  useUserPlanFinanceLearningBudgetOverviewVMQuery,
} from '@hooks/apiEndpoints/userPlan/queries';
import {
  useGetCompanyPersonalizationQuery,
  useGetUserQuery,
} from '@hooks/apiEndpoints/user/queries';
import {
  useEnrolledAcademyVMsQuery,
  usePublishedAcademies,
} from '@hooks/apiEndpoints/academy/queries';
import {MONEY_DEFAULT} from '@utils/moneyUtils';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {
  useGetRequestState,
  useGetRequestText,
} from '@components/user/components/request/Request.hooks';
import {COLORS} from '@utils/constants';
import {useHidePrimaryNavigation} from '@hooks/integrated/useHidePrimaryNavigation';
import {useBackToLxpLink} from '@hooks/integrated/useBackToLxpLink';
import {useUrlContainsIndicator} from '@hooks/integrated/useUrlContainsIndicator';
import {useForceHidePrimaryNavigation} from '@hooks/integrated/useForceHidePrimaryNavigation';
import {useAuth} from '@utils/oidc-auth-wrapper';
import {useCanManageApprovals} from '@hooks/useCanManageApprovals';

const {TabPane} = Tabs;

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/
export const PersonalLearningBudgetCardWrapper = styled.div`
  margin-top: 16px;
`;

const BankDisclosure = styled.div`
  text-align: left;
  color: ${COLORS.Neutral600};
`;

const StyledPageContentLayout = styled.section`
  margin-bottom: 24px;
  padding: 0px 16px;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

const UserPlan = ({title}: {title?: string}) => {
  const {data: companyPersonalization} = useGetCompanyPersonalizationQuery();
  const {user} = useAuth();
  const {isEligibleForPersonalLearningBudget, currency, firstName} = user;
  const {isFeatureFlagOn} = useFeatureFlags();
  const isApprover = useCanManageApprovals(user);
  const forceNavOff = useForceHidePrimaryNavigation(
    user,
    isFeatureFlagOn,
    isApprover
  );

  const hidePrimaryNavigation = useHidePrimaryNavigation({
    user,
    location: window.location,
    forceOff: forceNavOff,
  });

  const [showRequestResourceForm, setShowRequestResourceForm] = useState(false);
  const [showCreateUserProgram, setShowCreateUserProgram] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState(YourPlanTabName.INPROGRESS);

  useSetPageTitle(title);

  const {data: hasResourcesEnabled} = useHasResourcesEnabledQuery();

  const isOnlyCustomProgramsUser = isFeatureFlagOn.OnlyCustomProgramsUser;
  const headerTitle = useUrlContainsIndicator(
    location.href,
    isFeatureFlagOn.LXPAdminLayout
  )
    ? i18n.t(k.PLB__YOUR_BALANCE)
    : isFeatureFlagOn.WelcomeBackHeader
      ? i18n.t(k.HOME__WELCOME_BACK__FORMAT, {
          firstName: firstName,
        })
      : i18n.t(k.PLAN__MANAGE_YOUR);

  const userProgramPlanRq = getUserProgramPlanRq(2);
  const userProgramPlanQuery = useQuery<UserPlanProgramItemVM[]>(
    userProgramPlanRq.queryKey,
    () => simpleQueryFn(userProgramPlanRq.path)
  );

  const userTimePlanRq = getUserTimePlanRq();
  const userTimePlanQuery = useQuery<UserPlanTimeItemVM[]>(
    userTimePlanRq.queryKey,
    () => simpleQueryFn(userTimePlanRq.path)
  );

  const userFinancePlanRq = getUserFinancePlanRq();
  const userFinancePlanQuery = useQuery<UserPlanFinanceItemVM[]>(
    userFinancePlanRq.queryKey,
    () => simpleQueryFn(userFinancePlanRq.path)
  );

  const userPlanResourcesRq = getUserPlanResourcesRq();
  const userPlanResourcesQuery = useQuery<UserPlanResourceVM[]>(
    userPlanResourcesRq.queryKey,
    () => simpleQueryFn(userPlanResourcesRq.path),
    {
      onError: () => {
        // notification
      },
      select: (resources) => (!!resources ? resources : []), // if there is no data, response data is an empty string
    }
  );

  const doProgramsRequireApprovalRq = getDoProgramsRequireApprovalRq();
  const doProgramsRequireApprovalQuery = useQuery<boolean>(
    doProgramsRequireApprovalRq.queryKey,
    () => simpleQueryFn(doProgramsRequireApprovalRq.path),
    {...cacheForeverOptions}
  );

  const getLearningBudgetInfoQuery =
    useUserPlanFinanceLearningBudgetOverviewVMQuery({retry: 1});

  const toUSDExchangeRate = useExchangeRate(MONEY_DEFAULT.currency, currency);

  const completeItemsToDisplay = (
    programArray: UserPlanProgramItemVM[],
    timeArray: UserPlanTimeItemVM[],
    financeArray: UserPlanFinanceItemVM[],
    resources: UserPlanResourceVM[]
  ) => {
    return (
      programArray?.some(
        (e) => e.status === UserPlanItemStatusString.Complete
      ) ||
      timeArray?.some((e) => e.status === UserPlanItemStatusString.Complete) ||
      financeArray?.some(
        (e) => e.status === UserPlanItemStatusString.Complete
      ) ||
      resources?.some((e) => e.status === UserResourceStatus.Complete)
    );
  };
  const handleCloseRequestResourceDrawer = () => {
    setShowRequestResourceForm(false);
  };
  const handleCloseCreateUserProgramDrawer = () => {
    setShowCreateUserProgram(false);
  };

  const {data: enrolledAcademies} = useEnrolledAcademyVMsQuery(null);
  const {data: publishedAcademies} = usePublishedAcademies(null);

  //refetch list if a program was added
  const handleUserProgramDrawerOk = () => {
    userProgramPlanQuery.refetch();
  };

  const returnUrl = useBackToLxpLink(user, {
    type: 'plan',
  });

  const onTitleBackClick = () => {
    window.location.href = returnUrl;
  };

  const {showExploreProgramButton, hasProgramsOrResources} = useGetRequestState(
    {
      isHideMarketplaceOn: isFeatureFlagOn.HideMarketplace,
      isOnlyCustomProgramsUser,
      userProgramPlanQuery,
      userPlanResourcesQuery,
    }
  );
  useGetRequestText(
    !!companyPersonalization,
    doProgramsRequireApprovalQuery,
    hasResourcesEnabled
  );
  return (
    <>
      {showCreateUserProgram && (
        <CreateUserProgramDrawerContainer
          visible={showCreateUserProgram}
          onClose={handleCloseCreateUserProgramDrawer}
          onOk={handleUserProgramDrawerOk}
        />
      )}
      {showRequestResourceForm && (
        <RequestResourceApprovalDrawer
          visible={showRequestResourceForm}
          onCloseSuccess={handleCloseRequestResourceDrawer}
          refreshParentData={userPlanResourcesQuery.refetch}
        />
      )}
      <PageTitleBar
        title={headerTitle}
        onClickBack={
          hidePrimaryNavigation && returnUrl ? onTitleBackClick : null
        }
      />
      <LearnInTabs
        defaultActiveKey={YourPlanTabName.INPROGRESS}
        activeKey={activeTabKey}
        onTabClick={(key) => {
          switch (key) {
            case YourPlanTabName.INPROGRESS:
              return setActiveTabKey(YourPlanTabName.INPROGRESS);
            case YourPlanTabName.COMPLETED:
              return setActiveTabKey(YourPlanTabName.COMPLETED);
            default:
              return false;
          }
        }}>
        <TabPane
          tab={i18n.t(k.STATUS__IN_PROGRESS)}
          key={YourPlanTabName.INPROGRESS}>
          <StyledPageContentLayout>
            {getLearningBudgetInfoQuery.isSuccess &&
              isEligibleForPersonalLearningBudget &&
              getLearningBudgetInfoQuery.data.creditCardApprovalStatus !==
                CreditCardApprovalStatus.NoCreditCardExists && (
                <PersonalLearningBudgetCardWrapper>
                  <PersonalLearningBudgetCard
                    userProgramPlan={userProgramPlanQuery?.data}
                    showExploreProgramButton={showExploreProgramButton}
                    hasProgramsOrResources={hasProgramsOrResources}
                  />
                </PersonalLearningBudgetCardWrapper>
              )}
            <UserPlanTab
              programsSuccess={userProgramPlanQuery.isSuccess}
              programsData={userProgramPlanQuery.data}
              financeSuccess={userFinancePlanQuery.isSuccess}
              financeData={userFinancePlanQuery.data}
              timeSuccess={userTimePlanQuery.isSuccess}
              timeData={userTimePlanQuery.data}
              resources={userPlanResourcesQuery.data}
              hasResourcesLoaded={userPlanResourcesQuery.isSuccess}
              showCompleted={false}
              refreshResources={userPlanResourcesQuery.refetch}
              academies={enrolledAcademies}
              toUSDExchangeRate={toUSDExchangeRate}
            />
            <BankDisclosure>
              {getLearningBudgetInfoQuery.data?.financeType ==
                FinanceType.Prepayment &&
                `Visa® ${i18n.t(k.CREDIT_CARD_DISCLOSURE)}`}
            </BankDisclosure>
          </StyledPageContentLayout>
        </TabPane>
        <TabPane
          tab={i18n.t(k.STATUS__COMPLETED)}
          key={YourPlanTabName.COMPLETED}>
          <PageContentLayout>
            {!completeItemsToDisplay(
              userProgramPlanQuery.data,
              userTimePlanQuery.data,
              userFinancePlanQuery.data,
              userPlanResourcesQuery.data
            ) ? (
              <EmptyDataText
                header={i18n.t(k.PLAN__EMPTY__TITLE)}
                subHeader={i18n.t(k.PLAN__EMPTY__DESCRIPTION)}
              />
            ) : (
              <UserPlanTab
                programsSuccess={userProgramPlanQuery.isSuccess}
                programsData={userProgramPlanQuery.data}
                financeSuccess={userFinancePlanQuery.isSuccess}
                financeData={userFinancePlanQuery.data}
                timeSuccess={userTimePlanQuery.isSuccess}
                timeData={userTimePlanQuery.data}
                resources={userPlanResourcesQuery.data}
                hasResourcesLoaded={userPlanResourcesQuery.isSuccess}
                refreshResources={userPlanResourcesQuery.refetch}
                academies={publishedAcademies?.filter(
                  (academy) => academy?.hasCompleted
                )}
                toUSDExchangeRate={toUSDExchangeRate}
                showCompleted
              />
            )}
          </PageContentLayout>
        </TabPane>
      </LearnInTabs>
    </>
  );
};

export default UserPlan;
