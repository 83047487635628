import {CurrencyCode} from './../enums';
import {IExchangeRateDetailedInfo} from './../interfaces';
import {
  IExchangeRateBasicInfoMockData,
  getIExchangeRateBasicInfoMockData,
} from './IExchangeRateBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: IExchangeRateDetailedInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIExchangeRateDetailedInfoMockData(
  args?: Partial<IExchangeRateDetailedInfo>
): IExchangeRateDetailedInfo {
  return {
    ...getIExchangeRateBasicInfoMockData(),
    fromCurrencyCode: CurrencyCode.USD,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IExchangeRateDetailedInfoMockData: IExchangeRateDetailedInfo = {
  ...IExchangeRateBasicInfoMockData,
  fromCurrencyCode: CurrencyCode.USD,
};
