interface Props extends React.SVGProps<SVGSVGElement> {
  alt?: string;
}

export const LinkIcon = (props: Props) => {
  return (
    <svg
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <title>{props.alt}</title>
      <path
        d="M12.6602 15.7781C12.6199 15.7366 12.5655 15.7133 12.5088 15.7133C12.4522 15.7133 12.3978 15.7366 12.3575 15.7781L9.24499 19.0203C7.80392 20.5214 5.37178 20.6804 3.78071 19.0203C2.18696 17.3601 2.33963 14.8294 3.78071 13.3283L6.89321 10.0861C6.97624 9.99964 6.97624 9.85735 6.89321 9.77085L5.82713 8.66036C5.78686 8.61882 5.73247 8.59551 5.6758 8.59551C5.61912 8.59551 5.56473 8.61882 5.52446 8.66036L2.41196 11.9025C0.145884 14.263 0.145884 18.0828 2.41196 20.4405C4.67803 22.7982 8.34499 22.801 10.6084 20.4405L13.7209 17.1983C13.8039 17.1118 13.8039 16.9695 13.7209 16.883L12.6602 15.7781ZM19.587 2.55266C17.3209 0.192165 13.6539 0.192165 11.3905 2.55266L8.27535 5.79484C8.23547 5.8368 8.2131 5.89345 8.2131 5.95249C8.2131 6.01153 8.23547 6.06818 8.27535 6.11013L9.33874 7.21784C9.42178 7.30433 9.55838 7.30433 9.64142 7.21784L12.7539 3.97565C14.195 2.47453 16.6271 2.31549 18.2182 3.97565C19.812 5.6358 19.6593 8.1665 18.2182 9.66761L15.1057 12.9098C15.0658 12.9518 15.0435 13.0084 15.0435 13.0674C15.0435 13.1265 15.0658 13.1831 15.1057 13.2251L16.1718 14.3356C16.2548 14.4221 16.3914 14.4221 16.4745 14.3356L19.587 11.0934C21.8503 8.7329 21.8503 4.91315 19.587 2.55266ZM13.6271 7.60009C13.5869 7.55855 13.5325 7.53525 13.4758 7.53525C13.4191 7.53525 13.3647 7.55855 13.3245 7.60009L7.25749 13.9171C7.21761 13.959 7.19524 14.0157 7.19524 14.0747C7.19524 14.1337 7.21761 14.1904 7.25749 14.2323L8.31821 15.3373C8.40124 15.4238 8.53785 15.4238 8.62089 15.3373L14.6852 9.02029C14.7682 8.9338 14.7682 8.7915 14.6852 8.705L13.6271 7.60009Z"
        fill={props.fill || '#747578'}
      />
    </svg>
  );
};
