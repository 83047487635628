import './ProgressReportDetailsDrawer.scss';
import moment from 'moment';
import {Col, Form, Rate, Row, Typography} from 'antd';
import {HeartFilled} from '@ant-design/icons';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {ProgramProgressUpdateDetailsVM} from '@models/serverModels';
import LearnInTag from '@blocks/LearnInTag';

interface Props {
  toggleDrawer: (toggle: boolean) => void;
  visible: boolean;
  data: ProgramProgressUpdateDetailsVM[];
  getSummaryString: (summary: any) => void;
}

function ProgressReportDetailsDrawer(props: Props) {
  const {toggleDrawer, visible, data, getSummaryString} = props;
  const [form] = Form.useForm();

  // TODO: Needs to be properly implmeneted
  return null;

  // return (
  //   <LearnInDrawer
  //     title="Program Progress Details"
  //     placement="bottom"
  //     onClose={() => {
  //       toggleDrawer(false);
  //     }}
  //     visible={visible}
  //     footer={[
  //       <Row key="submit" justify="end">
  //         <LearnInButton
  //           tag={ButtonTags.DrawerFooterPrimary}
  //           style={{marginRight: '11%'}}
  //           onClick={() => {
  //             toggleDrawer(false);
  //           }}>
  //           Close
  //         </LearnInButton>
  //       </Row>,
  //     ]}>
  //     <Row className="progress-report-drawer-content-layout">
  //       <Col xs={24} md={7} className="progress-report-drawer-paragraph">
  //         <Row>
  //           <Typography.Title>Review Details</Typography.Title>
  //         </Row>
  //         <Row>
  //           <Typography.Text>
  //             Review the details of the progress update to gain a better insight
  //             on how the employee is doing with their program.
  //           </Typography.Text>
  //         </Row>
  //       </Col>
  //       <Col xs={24} md={7} className="progress-report-drawer-form-layout">
  //         <Form
  //           className="form-drawer"
  //           key={data?.id}
  //           layout="vertical"
  //           form={form}>
  //           <Form.Item
  //             className="lb-application-drawer"
  //             name="employeeName"
  //             initialValue={data?.employeeName || '-'}
  //             label="EMPLOYEE NAME">
  //             <LearnInInput
  //               tag={InputTags.PRIMARY}
  //               disabled={true}
  //               maxLength={100}
  //             />
  //           </Form.Item>
  //           <Form.Item
  //             className="lb-application-drawer"
  //             name="date"
  //             initialValue={
  //               data?.submittedDate
  //                 ? moment(data?.submittedDate).format(DATE_FORMAT.MONTH_DAY_YEAR)
  //                 : '-'
  //             }
  //             label="SUBMITTED DATE">
  //             <LearnInInput
  //               tag={InputTags.PRIMARY}
  //               disabled={true}
  //               maxLength={100}
  //             />
  //           </Form.Item>
  //           <Form.Item
  //             className="lb-application-drawer"
  //             name="summary"
  //             initialValue={getSummaryString(data?.summary)}
  //             label="SUMMARY">
  //             <LearnInInput
  //               tag={InputTags.PRIMARY}
  //               disabled={true}
  //               maxLength={100}
  //             />
  //           </Form.Item>
  //           <Form.Item className="lb-application-drawer" label="SKILLS">
  //             {data?.skills.split(',').map((skill) => (
  //               <LearnInTag key={skill} label={skill} />
  //             ))}
  //           </Form.Item>
  //           <Form.Item
  //             className="lb-application-drawer"
  //             name="ratings"
  //             label="RATINGS"
  //             initialValue={data?.rating}>
  //             <Rate
  //               disabled={true}
  //               character={<HeartFilled />}
  //               style={{color: '#ff6d85'}}
  //             />
  //           </Form.Item>
  //           {/* <Form.Item className="lb-application-drawer" name="viewedBy" initialValue={data?.viewedBy || '-'} label="VIEWED BY">
  //               <Input disabled={true} maxLength={100} />
  //             </Form.Item>
  //             <Form.Item
  //               className="lb-application-drawer"
  //               name="viewedByDate"
  //               initialValue={data?.viewedDate ? moment(data?.viewedDate).format(DATE_FORMAT.MONTH_DAY_YEAR) : '-'}
  //               label="VIEWED BY DATE">
  //               <Input disabled={true} maxLength={100} />
  //             </Form.Item> */}
  //         </Form>
  //       </Col>
  //     </Row>
  //   </LearnInDrawer>
  // );
}

export default ProgressReportDetailsDrawer;
