import {Outlet} from 'react-router-dom';
import {AcademiesAdminPage} from './AcademiesAdminPage';
import {Helmet} from 'react-helmet-async';

interface AcademiesAdminPageContainerProps {
  title?: string;
}

const AcademiesAdminPageContainer = ({
  title,
}: AcademiesAdminPageContainerProps) => {
  return (
    <section>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AcademiesAdminPage />
      <Outlet />
    </section>
  );
};

export default AcademiesAdminPageContainer;
