import {RsvpStatus} from './../enums';
import {UserPlanContentRsvpUpdate} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserPlanContentRsvpUpdate

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanContentRsvpUpdateMockData(
  args?: Partial<UserPlanContentRsvpUpdate>
): UserPlanContentRsvpUpdate {
  return {
    contentId: faker.datatype.number(),
    rsvpStatus: RsvpStatus.None,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanContentRsvpUpdateMockData: UserPlanContentRsvpUpdate = {
  contentId: 1,
  rsvpStatus: RsvpStatus.None,
};
