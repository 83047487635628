import {
  PreApprovalQuestionInputType,
  PreApprovalQuestionResponseSource,
} from '@generated/enums';
import {DYNAMIC_KEYS} from '@i18n/DYNAMIC_KEYS';
import {formatCurrency} from '@utils/moneyUtils';
import moment from 'moment';
import {DATE_FORMAT} from '@utils/timeUtils';
import {CurrencyCode} from '@generated/enums';
import {ApplicationQuestionVM} from '@models/serverModels';

interface DetermineInitialValueProps {
  app: ApplicationQuestionVM;
  currency: CurrencyCode;
  exchangeRate: number;
}

export function determinePreApprovalQuestionInitialValue({
  app,
  currency,
  exchangeRate,
}: DetermineInitialValueProps) {
  if (
    app.responseType ===
      PreApprovalQuestionResponseSource.UserPlanProgramAmount ||
    app.inputType === PreApprovalQuestionInputType.Money ||
    app.localizationId?.indexOf(DYNAMIC_KEYS.INDICATORS.MONEY_FIELD) > -1
  ) {
    return formatCurrency(parseFloat(app.response), currency, exchangeRate, {
      format: false,
    });
  } else if (app.inputType == PreApprovalQuestionInputType.Date) {
    return moment(app.response).format(DATE_FORMAT.MONTH_DAY_YEAR);
  } else {
    return app.response;
  }
}
