import {
  ALLOW_EMAIL_FIELD,
  APPLICATION_NOTIFICATIONS_FIELD,
  APPROVAL_REQUESTS_FIELD,
  CONTRIBUTOR_UPDATE,
  DUE_DATE_REMINDERS,
  EVENT_REMINDERS,
  MANAGER_APPROVAL_REQUESTS_FIELD,
  PROGRAM_ACCESS_FIELD,
  PROGRAM_NOMINATION_FIELD,
  PROGRAM_NUDGE_REMINDERS,
  PROJECT_FEEDBACK,
  PROJECT_SUBMISSION,
} from './Settings.constants';
import {i18n, k} from '@i18n/translate';
import {AvailableEmailSettingsProps} from './Settings.interfaces';

export const getAvailableEmailSettings = ({
  limitEmailPreferences,
  isAdmin,
  isManager,
  showContributorUpdateCheckbox,
  allowEmails,
}: AvailableEmailSettingsProps) => [
  {
    label: i18n.t(k.SETTING__ALLOW_EMAIL),
    value: ALLOW_EMAIL_FIELD,
    show: true,
    enabled: true,
  },
  {
    label: i18n.t(k.SETTING__PROGRAM_NOMINATIONS),
    value: PROGRAM_NOMINATION_FIELD,
    show: !limitEmailPreferences,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__PROGRAM_ACCESS),
    value: PROGRAM_ACCESS_FIELD,
    show: !limitEmailPreferences,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__APPLICATION_NOTIFICATIONS),
    value: APPLICATION_NOTIFICATIONS_FIELD,
    show: !limitEmailPreferences,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__MANAGER_APPROVAL_REQUESTS),
    value: MANAGER_APPROVAL_REQUESTS_FIELD,
    show: isManager && !limitEmailPreferences,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__ADMIN_APPROVAL_REQUESTS),
    value: APPROVAL_REQUESTS_FIELD,
    show: isAdmin && !limitEmailPreferences,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__PROGRAM_NUDGE_REMINDERS),
    value: PROGRAM_NUDGE_REMINDERS,
    show: true,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__PROJECT_FEEDBACK),
    value: PROJECT_FEEDBACK,
    show: true,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__PROJECT_SUBMISSION),
    value: PROJECT_SUBMISSION,
    show: true,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__DUE_DATE_REMINDERS),
    value: DUE_DATE_REMINDERS,
    show: true,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__EVENT_REMINDERS),
    value: EVENT_REMINDERS,
    show: !limitEmailPreferences,
    enabled: allowEmails,
  },
  {
    label: i18n.t(k.SETTING__PERMISSIONS_UPDATES),
    value: CONTRIBUTOR_UPDATE,
    show: showContributorUpdateCheckbox,
    enabled: allowEmails,
  },
];
