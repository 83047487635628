import styled from 'styled-components';
import {COLORS} from '@utils/constants';

export const TimezoneSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  .ant-select-selection-item {
    color: ${COLORS.Blue800};
  }
  .ant-select-selection-search {
    width: 5rem;
  }
`;

export const EventTime = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimeRange = styled.div`
  display: flex;
  column-gap: 16px;
`;

export const StartTime = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EndTime = styled.div`
  display: flex;
  flex-direction: column;
`;

export const EventDateTime = styled.div`
  display: flex;
  column-gap: 16px;
  .ant-form-item {
    margin-bottom: 0;
  }
`;
