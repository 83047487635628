import {ReimbursementStatus} from './../enums';
import {UserResourceReimbursementRequest} from './../interfaces';
import {
  IUserRequestBasicInfoMockData,
  getIUserRequestBasicInfoMockData,
} from './IUserRequestBasicInfo.mock';
import {UserRequestMockData, getUserRequestMockData} from './UserRequest.mock';
import {faker} from '@faker-js/faker';

// Interface: UserResourceReimbursementRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserResourceReimbursementRequestMockData(
  args?: Partial<UserResourceReimbursementRequest>
): UserResourceReimbursementRequest {
  return {
    ...getUserRequestMockData(),
    ...getIUserRequestBasicInfoMockData(),
    reimbursementStatus: ReimbursementStatus.NotPaid,
    userFinanceIncentiveId: faker.datatype.number(),
    userPlanReimbursementResourceId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserResourceReimbursementRequestMockData: UserResourceReimbursementRequest =
  {
    ...UserRequestMockData,
    ...IUserRequestBasicInfoMockData,
    reimbursementStatus: ReimbursementStatus.NotPaid,
    userFinanceIncentiveId: 1,
    userPlanReimbursementResourceId: 1,
  };
