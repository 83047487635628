import {useEffect} from 'react';
import {BudgetContainerProps} from './Budget.interfaces';
import BudgetView from './Budget.view';
import {formatCurrency, MONEY_DEFAULT} from '@utils/moneyUtils';

function BudgetContainer({
  handleAction,
  currencyCode,
  form,
  maxRequestAmount,
}: BudgetContainerProps) {
  const formattedCurrency = (value: number) =>
    formatCurrency(value, currencyCode, MONEY_DEFAULT.exchangeRate);

  useEffect(() => {
    if (currencyCode || currencyCode === 0)
      form.setFieldsValue({min: 0, max: maxRequestAmount});
  }, [maxRequestAmount, currencyCode]);

  return (
    <BudgetView
      handleAction={handleAction}
      formattedCurrency={formattedCurrency}
      form={form}
      maxRequestAmount={maxRequestAmount}
    />
  );
}

export default BudgetContainer;
