import {DiscussionStatus} from './../enums';
import {AcademyDiscussionResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AcademyDiscussionResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyDiscussionResponseMockData(
  args?: Partial<AcademyDiscussionResponse>
): AcademyDiscussionResponse {
  return {
    academyId: faker.datatype.number(),
    discussionId: String(faker.datatype.number()),
    status: DiscussionStatus.Active,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyDiscussionResponseMockData: AcademyDiscussionResponse = {
  academyId: 1,
  discussionId: '1',
  status: DiscussionStatus.Active,
};
