const defaultBlockButtons = [{list: 'ordered'}, {list: 'bullet'}];
const defaultFormatting = ['clean'];
const defaultInlineButtons = ['bold', 'italic', 'underline', 'strike'];

export const slimToolbarOptions = [
  defaultInlineButtons,
  defaultBlockButtons,
  defaultFormatting,
];

export const slimToolbarOptionsWithLink = [
  [...defaultInlineButtons, 'link'],
  defaultBlockButtons,
  defaultFormatting,
];

export const formatPastingOptions = [
  'strike',
  'underline',
  'bold',
  'header',
  'italic',
  'link',
  'image',
  'list',
  'indent',
];
