import React, {useState} from 'react';
import {AcademyStepVM} from '@generated/interfaces';
import {DisplayStatus} from '@generated/enums';
import {ContentCompleteButton} from '@blocks/CustomProgramUserDrawer/ContentCompleteButton';
import {i18n, k} from '@i18n/translate';
import {LearnInButton} from '../../Button/Button.style';
import {ButtonTags} from '../../Button/ButtonEnums';
import {
  ButtonsContainer,
  CardContainer,
  ContentCompleteButtonWrapper,
  ContentContainer,
  Description,
  FootContainer,
  InnerContainer,
  LxpPreviewImageContainer,
  LxpPreviewImage,
  MetaData,
  PreviewImgContainer,
} from './LxpContentCard.styled';
import {
  getBlobUrl,
  getDefaultContentImage,
  getMetaData,
} from './LxpContentCard.utils';
import SearchCardHeader from '../SearchCardHeader';
import DOMPurify from 'dompurify';

interface LxpContentCardProps {
  step: AcademyStepVM;
  onToggleCompletion: () => void;
  smallImageUrl: string;
}

const LxpContentCard: React.FC<LxpContentCardProps> = ({
  step,
  onToggleCompletion,
  smallImageUrl,
}) => {
  const {icon, backgroundColor} = getDefaultContentImage(step.lxpContentType);
  const [imgSrc] = useState<string>(getBlobUrl(smallImageUrl) || icon);
  const handleClickViewContent = () => {
    window.open(step.links[0].url, '_blank', 'noopener noreferrer');
  };
  const isVerifiedCompleted =
    step.status.displayStatus === DisplayStatus.VerifiedCompleted;
  const isCompleted = step.status?.isDone;

  const viewContentBtn = (
    <LearnInButton
      aria-label={i18n.t(k.CTA__VIEW_ITEM__FORMAT, {
        item: step.title,
      })}
      key="viewContent"
      tag={ButtonTags.Secondary}
      onClick={handleClickViewContent}>
      {i18n.t(k.A11Y__VIEW_ITEM__FORMAT, {item: i18n.t(k.CONTENT)})}
    </LearnInButton>
  );
  const markCompleteButton = (
    <ContentCompleteButtonWrapper>
      <ContentCompleteButton
        title={step.title}
        key="complete"
        completed={step.status?.isDone}
        onClick={onToggleCompletion}
        disabled={!step.isCompletable || isVerifiedCompleted}
        isVerifiedCompleted={isVerifiedCompleted}
      />
    </ContentCompleteButtonWrapper>
  );

  const metadata = getMetaData(step);

  // For pathways and content that is verified complete show mark complete button
  // but only when item is completed
  const buttons = step.isCompletable
    ? [viewContentBtn, markCompleteButton]
    : isCompleted || isVerifiedCompleted
      ? [viewContentBtn, markCompleteButton]
      : [viewContentBtn];

  return (
    <CardContainer
      aria-label={`${i18n.t(k.GENERIC__SEARCH_RESULTS)}: ${step.title}`}
      data-cy={`lxp-content-${step.id}`}
      removeBottomMargin={true}>
      <InnerContainer>
        <PreviewImgContainer>
          <LxpPreviewImageContainer
            style={{
              backgroundColor: backgroundColor,
            }}>
            <LxpPreviewImage
              isIcon={!imgSrc || imgSrc === icon}
              src={imgSrc}
              alt={`${step.title} ${i18n.t(k.LXP__CONTENT)}`}
            />
          </LxpPreviewImageContainer>
        </PreviewImgContainer>
        <ContentContainer>
          <SearchCardHeader
            provider={undefined}
            providerImgSrc={''}
            title={step.title}
            headerData={null}
          />
          {metadata ? <MetaData>{metadata}</MetaData> : null}
          <Description>
            {DOMPurify.sanitize(step.description, {ALLOWED_TAGS: []})}
          </Description>
          <FootContainer>
            <ButtonsContainer>{buttons}</ButtonsContainer>
          </FootContainer>
        </ContentContainer>
      </InnerContainer>
    </CardContainer>
  );
};

export default LxpContentCard;
