import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {Button, Modal, Typography} from 'antd';

export interface IDeleteModalProps {
  isVisible: boolean;
  onOkClicked: () => void;
  OnCancelClicked: () => void;
}

export function DeleteModal({
  isVisible,
  OnCancelClicked,
  onOkClicked,
}: IDeleteModalProps) {
  return (
    <Modal
      open={isVisible}
      className="admin-initiative-modal"
      style={{top: '150px'}}
      footer={
        <>
          <Button
            className="btn admin-initiatives-blue-outline-btn"
            shape="round"
            onClick={() => {
              OnCancelClicked?.call(true);
            }}>
            {i18n.t(k.CTA__CANCEL)}
          </Button>
          <Button
            className="btn admin-initiatives-red-filled-btn"
            shape="round"
            onClick={async () => {
              onOkClicked?.call(this);
            }}>
            {i18n.t(k.INITIATIVE__DELETE)}
          </Button>
        </>
      }>
      <Typography.Title style={{fontSize: '1.25rem'}}>
        {i18n.t(k.PROMPT__DELETE_ITEM__FORMAT, {
          item: i18n.t(k.INITIATIVE),
        })}
      </Typography.Title>
      <Typography.Paragraph style={{color: COLORS.Neutral600, fontWeight: 400}}>
        {i18n.t(k.INITIATIVE__DELETE__PROMPT)}
      </Typography.Paragraph>
    </Modal>
  );
}
