import CustomProgramProjectParticipantsDrawer from '@components/admin/pages/programs/customPrograms/projects/CustomProgramProjectParticipantsDrawer';
import ProjectResponseDrawerWithRoutes from './ProjectResponseDrawerWithRoutes.container';
import {Route, Routes, useNavigate, useParams} from 'react-router-dom';
import useOpenUpload from '@hooks/useOpenUpload';
import {CustomizableLearningCategory} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {
  useProjectsQuery,
  useAcademiesProjectSubmissionsQuery,
} from '@generated/hooks';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramProjectParticipantsDrawerContainerProps {
  onClose: () => void;
  title?: string;
}

function CustomProgramProjectParticipantsDrawerContainer({
  onClose,
  title,
}: CustomProgramProjectParticipantsDrawerContainerProps) {
  const openUpload = useOpenUpload(CustomizableLearningCategory.Academy);
  const {projectId, academyId} = useParams();
  const navigate = useNavigate();

  useSetPageTitle(title);

  const {data: projects} = useProjectsQuery(
    {
      academyId,
    },
    {
      enabled: !!projectId,
    }
  );

  const project = projects?.find(({id}) => id === parseInt(projectId));

  // Get Project Submissions
  const {data: academiesProjectSubmissions} =
    useAcademiesProjectSubmissionsQuery(
      {
        projectId: project?.id,
      },
      {
        enabled: !!project?.id,
        select: (academiesProjectSubmissions) =>
          academiesProjectSubmissions.filter(({submittedOn}) => !!submittedOn),
      }
    );

  return (
    <>
      <CustomProgramProjectParticipantsDrawer
        dataSource={academiesProjectSubmissions || []}
        maxSubmitted={academiesProjectSubmissions?.length || 0}
        onClickUploadLink={openUpload}
        onClose={onClose}
        projectDueDate={project?.dueDateUtc}
        showFeedbackColumn
        title={project?.title || ''}
        totalSubmitted={academiesProjectSubmissions?.length || 0}
        visible={!!project}
        onClickRow={(row) => navigate(`${row?.id}/feedback`)}
        onClickSubmitFeedback={(id: number, triggerSelector: string) =>
          navigate(`${id}/feedback`, {state: {triggerSelector}})
        }
        showRowHoverStyle={() => true}
      />
      <Routes>
        <Route
          path=":submissionId/feedback"
          element={
            <ProjectResponseDrawerWithRoutes
              title={i18n.t(k.FEEDBACK)}
              allowFeedback
            />
          }
        />
      </Routes>
    </>
  );
}

export default CustomProgramProjectParticipantsDrawerContainer;
