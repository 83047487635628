import {useState} from 'react';
// DELETE if resolve to keep BudgetRedesign
import ReimbursementDrawerContainer from '@blocks/reimbursementDrawer/ReimbursementDrawer.container';
import BudgetCard from '@components/user/pages/userPlan/components/personalLearningBudgetCard/BudgetCard';
import {UserPlanProgramItemVM} from '@models/serverModels';

export interface PersonalLearningBudgetCardProps {
  showExploreProgramButton: boolean;
  userProgramPlan?: UserPlanProgramItemVM[];
  hasProgramsOrResources: boolean;
}

export function PersonalLearningBudgetCard({
  showExploreProgramButton,
  userProgramPlan,
  hasProgramsOrResources,
}: PersonalLearningBudgetCardProps) {
  const [showReimbursement, setShowReimbursement] = useState(false);

  return (
    <div>
      {showReimbursement && (
        <ReimbursementDrawerContainer
          isOpen={showReimbursement}
          setIsOpen={setShowReimbursement}
        />
      )}
      <BudgetCard
        userProgramPlan={userProgramPlan}
        setShowReimbursement={setShowReimbursement}
        showExploreProgramButton={showExploreProgramButton}
        hasProgramsOrResources={hasProgramsOrResources}
      />
    </div>
  );
}
