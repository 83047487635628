import React, {useState} from 'react';
import {CustomProgramCohortTableContainerProps} from './CustomProgramCohortTable.interfaces';
import {i18n, k} from '@i18n/translate';
import {notify} from '@components/user/notifications';
import {EmptyBlockUntilTrue} from '@components/reusable/EmptyBlockUntilTrue';
import useLocalSearch from '@hooks/useLocalSearch';
import {useCustomProgramActivityReportQuery} from '@generated/hooks';
import useFeatureFlags from '@hooks/useFeatureFlags';
import CustomProgramCohortTableView from './CustomProgramCohortTable.view';
import BulkNudgeDrawer from '@blocks/BulkNudgeDrawer';
import {CustomizableLearningCategory} from '@generated/enums';
import useNudgeEmail from '@blocks/NudgeEmailPreview/useNudgeEmail';
import {Feature} from '@blocks/NudgeEmailPreview';

function CustomProgramCohortTableContainer({
  customProgramId,
  onClickRow,
  canShowNudgeColumn,
  onOpenNudgeEmailPreview,
}: CustomProgramCohortTableContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();
  const [bulkNudgeDrawerVisible, setBulkNudgeDrawerVisible] = useState(false);
  const customProgramActivityReportQuery = useCustomProgramActivityReportQuery(
    {
      queryParams: {customProgramId},
    },
    {
      enabled: !!customProgramId,
      onError: () => {
        notify.tableDataLoadingError('Cohort');
      },
    }
  );

  const dataSource = !!customProgramActivityReportQuery.data
    ? customProgramActivityReportQuery.data.map((row) => ({
        ...row,
        id: row.employeeId,
      }))
    : [];

  const {filteredData, searchFilter, setSearchFilter, getNoDataTableProps} =
    useLocalSearch(dataSource, 'employeeName');

  const {drawerProps} = useNudgeEmail({feature: Feature.CustomProgram});

  return (
    <>
      <EmptyBlockUntilTrue
        condition={!!customProgramActivityReportQuery.data}
        emptyBody={i18n.t(k.COHORT__EMPTY__DESCRIPTION)}
        emptyHeader={i18n.t(k.COHORT__EMPTY__TITLE)}>
        <CustomProgramCohortTableView
          dataSource={filteredData}
          onClickRow={onClickRow}
          searchInputValue={searchFilter}
          onChangeSearchInput={setSearchFilter}
          noDataTableProps={getNoDataTableProps()}
          onOpenNudgeEmailPreview={onOpenNudgeEmailPreview}
          canShowNudgeColumn={canShowNudgeColumn}
          showEmailColumn={isFeatureFlagOn.ShowEmployeeEmail}
          onClickBulkNudgeButton={() => setBulkNudgeDrawerVisible(true)}
          showBulkNudgeButton={isFeatureFlagOn.BulkNudgeAllParticipants}
        />
      </EmptyBlockUntilTrue>
      <BulkNudgeDrawer
        customizableLearningCategory={
          CustomizableLearningCategory.CustomProgram
        }
        visible={bulkNudgeDrawerVisible}
        onClose={() => setBulkNudgeDrawerVisible(false)}
        name={drawerProps.programOrAcademyName}
        feature={Feature.CustomProgram}
        bypassParticipantsList
      />
    </>
  );
}

export default CustomProgramCohortTableContainer;
