// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/LxpContent/LxpContentType.cs

enum LxpContentType {
  Unknown,
  Pathway = 1,
  Targeted = 2,
  Course = 3,
  Video = 4,
  Episode = 5,
  Article = 6,
  Book = 7,
  Event = 8,
  Assessment = 9,
}

export default LxpContentType;
