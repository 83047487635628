import {UserProgressSummary} from './../enums';
import {ProgramProgressUpdateDetailsVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ProgramProgressUpdateDetailsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramProgressUpdateDetailsVMMockData(
  args?: Partial<ProgramProgressUpdateDetailsVM>
): ProgramProgressUpdateDetailsVM {
  return {
    content: faker.word.noun(),
    employeeName: faker.name.fullName(),
    id: faker.datatype.number(),
    programTitle: faker.company.catchPhrase(),
    rating: faker.datatype.number(),
    skills: faker.word.noun(),
    submittedDate: String(faker.date.past()),
    summary: UserProgressSummary.FeelingGreat, // optional
    updateTitle: faker.company.catchPhrase(),
    viewedBy: faker.word.noun(),
    viewedDate: String(faker.date.past()), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramProgressUpdateDetailsVMMockData: ProgramProgressUpdateDetailsVM =
  {
    content: 'string',
    employeeName: 'Bobby Johnson',
    id: 1,
    programTitle: 'Advanced Web Design',
    rating: 123,
    skills: 'string',
    submittedDate: '2023-03-29T22:17:19.738072',
    summary: UserProgressSummary.FeelingGreat, // optional
    updateTitle: 'Advanced Web Design',
    viewedBy: 'string',
    viewedDate: '2023-03-29T22:17:19.738072', // optional
  };
