import {i18n, k} from '@i18n/translate';
import {FinanceAmountType} from '@generated/enums';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {formatCurrency} from '@utils/moneyUtils';
import {CurrencyCode} from '@generated/enums';

const creditCardAspectRatio = 1.586;
const height = 202;
const width = height * creditCardAspectRatio;

export interface CreditCardProps {
  totalAmount: number;
  hasCardCreationError: boolean;
  financeAmountType: FinanceAmountType;
  currencyCode: CurrencyCode;
  exchangeRate: number;
}

const PersonalLearningBudgetPlaceholderCreditCard = ({
  totalAmount,
  financeAmountType,
  hasCardCreationError,
  currencyCode,
  exchangeRate,
}: CreditCardProps) => {
  const tagLineText =
    financeAmountType === FinanceAmountType.Any
      ? i18n.t(k.FUND__REQUEST_APPROVAL_TO).toLocaleUpperCase()
      : i18n.t(k.FUND__GET_UP_TO).toLocaleUpperCase();
  const fundAmountText =
    financeAmountType === FinanceAmountType.Any
      ? i18n.t(k.FUND__UNLOCK__PLURAL)
      : formatCurrency(totalAmount, currencyCode, exchangeRate, {
          decimal: true,
        });
  const fundAmountTextSize = fundAmountText.length > 10 ? '25px' : '32px';

  return (
    <CreditCardContainer>
      <BalanceContainer
        padding={financeAmountType !== FinanceAmountType.Any ? '0' : '10px'}>
        <LearnInText tag={TextTags.LearnInPersonalBudgetCCHeader}>
          {tagLineText}
        </LearnInText>
        <div style={{display: 'flex'}}>
          <LearnInText
            fontSize={fundAmountTextSize}
            tag={TextTags.LearnInPersonalBudgetCCMoney}>
            {fundAmountText}
          </LearnInText>
        </div>
        {financeAmountType !== FinanceAmountType.Any && (
          <ApprovalTextWrapper>
            <LearnInText tag={TextTags.LearnInPersonalBudgetCCApproval}>
              {i18n.t(k.CARD__UPON_APPROVAL).toLocaleUpperCase()}
            </LearnInText>
          </ApprovalTextWrapper>
        )}
      </BalanceContainer>
      <LearnInText
        style={{flex: 'none'}}
        tag={TextTags.LearnInPersonalBudgetCCFooter}>
        {i18n.t(
          hasCardCreationError ? k.CARD__ERROR_CREATING : k.CARD__ACTIVE_ONCE
        )}
      </LearnInText>
    </CreditCardContainer>
  );
};
export default PersonalLearningBudgetPlaceholderCreditCard;

const CreditCardContainer = styled.div`
  background: ${COLORS.Neutral100};
  height: ${`${height}px`};
  width: ${`${width}px`};
  padding: 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 24px;
`;

const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: ${({padding}) => padding};
`;

const ApprovalTextWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 3%;
  flex-wrap: nowrap;
  width: 100%;
  text-align: end;
`;
