import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {CustomProgramAdminSectionVM} from '@models/serverModels';
import {Radio} from 'antd';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  margin-bottom: 16px;
`;

const SelectLabel = styled.label`
  font-weight: normal;
  font-size: 1rem;
  color: ${COLORS.Neutral950};
  display: block;
  margin-bottom: 16px;
`;

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

enum ActionOptions {
  Move,
  Delete,
}

/*
|--------------------------------------------------------------------------
| Modal Component
|--------------------------------------------------------------------------
*/

interface DeleteLevelModalProps {
  onCancel: () => void;
  onOk: (moveContentToSectionId?: number) => void;
  moveContentSectionOptions: Pick<
    CustomProgramAdminSectionVM,
    'id' | 'title'
  >[];
  isSectionContentEmpty: boolean;
}

function DeleteLevelModal({
  onCancel,
  onOk,
  moveContentSectionOptions,
  isSectionContentEmpty,
}: DeleteLevelModalProps) {
  const [selectedAction, setSelectedAction] = useState<ActionOptions | null>(
    null
  );
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(
    null
  );
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(
    isSectionContentEmpty
  );
  useEffect(() => {
    setShowDeleteConfirmation(isSectionContentEmpty);
  }, [isSectionContentEmpty]);
  const isOkButtonEnabled =
    selectedAction === ActionOptions.Delete ||
    (selectedAction === ActionOptions.Move && selectedSectionId) ||
    isSectionContentEmpty;
  return (
    <LearnInModal
      width={450}
      visible={true}
      tag={ModalTags.MoneyIncentive}
      okButtonChild={i18n.t(k.CTA__DELETE)}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      onCancel={() => {
        if (showDeleteConfirmation && !isSectionContentEmpty) {
          setShowDeleteConfirmation(false);
        } else {
          onCancel();
        }
      }}
      onOk={() => {
        if (isSectionContentEmpty) {
          onOk();
        } else if (!showDeleteConfirmation) {
          setShowDeleteConfirmation(true);
        } else {
          onOk(selectedSectionId);
        }
      }}
      okButtonTag={ButtonTags.Destructive}
      buttonDisabled={!isOkButtonEnabled}
      ariaLabel={
        showDeleteConfirmation
          ? i18n.t(k.SECTION__DELETE)
          : i18n.t(k.SECTION__MOVE_TO)
      }>
      {showDeleteConfirmation ? (
        <div>
          <ModalTitle>{i18n.t(k.SECTION__DELETE)}</ModalTitle>
          <SelectLabel>{i18n.t(k.SECTION__DELETE__PROMPT)}</SelectLabel>
        </div>
      ) : (
        <div>
          <ModalTitle>{i18n.t(k.SECTION__MOVE_TO)}</ModalTitle>
          <SelectLabel>{i18n.t(k.SECTION__MOVE_WHERE)}</SelectLabel>
          <Radio
            disabled={!moveContentSectionOptions?.length}
            checked={selectedAction === ActionOptions.Move}
            onClick={() => setSelectedAction(ActionOptions.Move)}
            style={{marginBottom: '16px'}}>
            {i18n.t(k.SECTION__MOVE_TO_DIFFERENT)}
          </Radio>

          {selectedAction === ActionOptions.Move && (
            <div style={{marginBottom: '16px'}}>
              <LearnInSelect
                placeholder={i18n.t(k.LEVEL__SELECT)}
                onChange={(value) => setSelectedSectionId(value)}
                value={selectedSectionId}
                style={{width: '100%'}}>
                {moveContentSectionOptions?.map(({id, title}, i: number) => {
                  return (
                    <LearnInSelectOption
                      key={`${title}${i}`}
                      data-testid={`section-option-${id}`}
                      value={id}>
                      {title}
                    </LearnInSelectOption>
                  );
                })}
              </LearnInSelect>
            </div>
          )}
          <Radio
            checked={selectedAction === ActionOptions.Delete}
            onClick={() => setSelectedAction(ActionOptions.Delete)}>
            {i18n.t(k.SECTION__DELETE_PERMANENTLY)}
          </Radio>
        </div>
      )}
    </LearnInModal>
  );
}

export default DeleteLevelModal;
