import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {Form} from 'antd';
import {useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import {getOnSubmitLicenseHandler} from './AddUpdateLicenseDrawer.handlers';
import {
  useGetDefaultLicenseFormValues,
  useGetLicenseQuery,
  useGetProvidersQuery,
  useGetProviderSuggestions,
  useLicenseFormForceValueSetEffect,
  useLicenseFormHasErrorsEffect,
  useLicenseFormUpdatesEffect,
  useSaveLicenseMutation,
} from './AddUpdateLicenseDrawer.hooks';
import {
  AddUpdateLicenseDrawerContainerProps,
  LicenseFormFieldErrors,
  LicenseFormFields,
} from './AddUpdateLicenseDrawer.types';
import {
  getInternalCost,
  getInternalCostType,
  getLicenseCurrency,
  getLicenseDuration,
  getLicenseDurationType,
  getLicenseStatus,
  getLicenseType,
  getRequireAdminApproval,
  getRequireManagerApproval,
} from './AddUpdateLicenseDrawer.utils';
import AddUpdateLicenseDrawerView from './AddUpdateLicenseDrawer.view';
import {UseMutationResult} from 'react-query';
import {i18n, k} from '@i18n/translate';

function AddUpdateLicenseDrawerContainer({
  id,
  onClose,
  providersInUse,
  refreshParentData,
  visible,
}: AddUpdateLicenseDrawerContainerProps) {
  // Hooks that don't depend on state
  const getUserQuery = useGetUserQuery();
  const {isLoading: licenseIsLoading, data: license} = useGetLicenseQuery(id);
  const providersQuery = useGetProvidersQuery();
  const providerSuggestions = useGetProviderSuggestions(providersQuery?.data);
  const saveLicenseMutation = useSaveLicenseMutation(
    refreshParentData,
    onClose,
    () => {
      setA11yAlertMessage(i18n.t(k.NOTIFICATION__LICENSE__SAVED__SUCCESS));
      setTimeout(() => setA11yAlertMessage(''), 5000);
    },
    () => {
      setA11yAlertMessage(i18n.t(k.NOTIFICATION__LICENSE__SAVED__ERROR));
      setTimeout(() => setA11yAlertMessage(''), 5000);
    }
  );

  // State
  const [form] = Form.useForm();
  const [providerId, setProviderId] = useState(license?.providerId);
  const [provider, setProvider] = useState(null);
  const [internalCostType, setInternalCostType] = useState(
    getInternalCostType(license)
  );
  const [internalCost, setInternalCost] = useState(getInternalCost(license));
  const [status, setStatus] = useState(getLicenseStatus(license));
  const [type, setType] = useState(getLicenseType(license));
  const [licenseDuration, setLicenseDuration] = useState(
    getLicenseDuration(license)
  );
  const [licenseDurationType, setLicenseDurationType] = useState(
    getLicenseDurationType(license)
  );
  const [requireManagerApproval, setRequireManagerApproval] = useState(
    getRequireManagerApproval(license)
  );
  const [requireAdminApproval, setRequireAdminApproval] = useState(
    getRequireAdminApproval(license)
  );
  const [redemptionGuideline, setRedemptionGuideline] = useState(
    license?.[LicenseFormFields.RedemptionGuideline]
  );
  const [redemptionType, setRedemptionType] = useState(
    license?.[LicenseFormFields.RedemptionType]
  );
  const [approvalGuideline, setApprovalGuideline] = useState(
    license?.[LicenseFormFields.ApprovalGuideline]
  );
  const [voucherAssignMode, setVoucherAssignMode] = useState(
    license?.[LicenseFormFields.VoucherAssignMode]
  );
  const [formFieldErrors, setFormFieldErrors] =
    useState<LicenseFormFieldErrors>({});

  const [_, setFormHasErrors] = useState<boolean>(false);
  const [a11yAlertMessage, setA11yAlertMessage] = useState('');
  const currency = getLicenseCurrency(license, getUserQuery.data?.currency);

  // Hooks that depend on state
  const defaultFormValues = useGetDefaultLicenseFormValues({
    approvalGuideline,
    companyId: getUserQuery.data?.companyId,
    currency,
    internalCost,
    internalCostType,
    license,
    licenseDuration,
    providerId,
    redemptionGuideline,
    redemptionType,
    status,
    type,
    voucherAssignMode,
  });
  // Handlers that depend on state
  const handleOnSubmit = (saveLicenseMutation: UseMutationResult) => {
    getOnSubmitLicenseHandler({
      approvalGuideline,
      companyId: getUserQuery.data?.companyId,
      currency: form.getFieldValue([LicenseFormFields.Currency]),
      id,
      internalCost,
      internalCostType,
      license,
      licenseDuration,
      licenseDurationType,
      providerId,
      redemptionGuideline,
      redemptionType,
      requireAdminApproval,
      requireManagerApproval,
      saveLicenseMutation,
      setFormFieldErrors,
      status,
      type,
      voucherAssignMode,
    });
  };

  // Effects
  useLicenseFormHasErrorsEffect(formFieldErrors, setFormHasErrors);
  useLicenseFormForceValueSetEffect(internalCostType, form);
  useLicenseFormUpdatesEffect({
    currency,
    form,
    license,
    setApprovalGuideline,
    setInternalCost,
    setInternalCostType,
    setLicenseDuration,
    setLicenseDurationType,
    setProviderId,
    setRedemptionGuideline,
    setRedemptionType,
    setRequireAdminApproval,
    setRequireManagerApproval,
    setStatus,
    setType,
    setVoucherAssignMode,
  });

  return (
    <AddUpdateLicenseDrawerView
      approvalGuideline={approvalGuideline}
      approvalGuidelineRef={useRef<ReactQuill>()}
      currency={currency}
      defaultFormValues={defaultFormValues}
      form={form}
      formFieldErrors={formFieldErrors}
      handleOnSubmit={() => handleOnSubmit(saveLicenseMutation)}
      internalCost={internalCost}
      internalCostType={internalCostType}
      isNew={!license?.[LicenseFormFields.Id]}
      license={license}
      licenseDuration={licenseDuration}
      licenseDurationType={licenseDurationType}
      loading={providersQuery.isLoading && licenseIsLoading}
      onClose={onClose}
      provider={provider}
      providerId={providerId}
      providers={providersQuery.data}
      providersInUse={providersInUse}
      providerSuggestions={providerSuggestions}
      redemptionGuideline={redemptionGuideline}
      redemptionGuidelineRef={useRef<ReactQuill>()}
      redemptionType={redemptionType}
      requireAdminApproval={requireAdminApproval}
      requireManagerApproval={requireManagerApproval}
      setApprovalGuideline={setApprovalGuideline}
      setFormFieldErrors={setFormFieldErrors}
      setInternalCost={setInternalCost}
      setInternalCostType={setInternalCostType}
      setLicenseDuration={setLicenseDuration}
      setLicenseDurationType={setLicenseDurationType}
      setProvider={setProvider}
      setProviderId={setProviderId}
      setRedemptionGuideline={setRedemptionGuideline}
      setRedemptionType={setRedemptionType}
      setRequireAdminApproval={setRequireAdminApproval}
      setRequireManagerApproval={setRequireManagerApproval}
      setStatus={setStatus}
      setType={setType}
      visible={visible}
      voucherAssignMode={voucherAssignMode}
      setVoucherAssignMode={setVoucherAssignMode}
      a11yAlertMessage={a11yAlertMessage}
    />
  );
}

export default AddUpdateLicenseDrawerContainer;
