import {notify} from './../../../components/user/notifications';
import {
  CustomProgramUserPermissionPayload,
  DeleteCustomProgramUserPermissionPayload,
  UserPermissionUpdatePayload,
} from '@models/serverModels';
import {
  getAddCustomProgramUserPermissionsRm,
  getDeleteCustomProgramUserPermissionRm,
} from '@store/apiEndpoints/customProgram/mutations';
import {simpleMutationFn, simpleDeleteFn} from '@store/queryClient';
import {UseMutationResult, useMutation, UseMutationOptions} from 'react-query';
import {mutationHookFactory, MutationMethods} from '../utils';

export const BASE_PATH = 'custom-program';

export function useAddCustomProgramUserPermissions(
  options: UseMutationOptions<
    CustomProgramUserPermissionPayload,
    unknown,
    unknown
  >
): UseMutationResult<CustomProgramUserPermissionPayload, unknown> {
  return useMutation(
    (payload: CustomProgramUserPermissionPayload) => {
      const mutationRm = getAddCustomProgramUserPermissionsRm(payload);
      return simpleMutationFn<CustomProgramUserPermissionPayload>(
        mutationRm.path,
        mutationRm.payload
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context);
        notify.addCustomProgramUserPermissionsSuccess();
      },
      onError: (error, variables, context) => {
        options.onError && options.onError(error, variables, context);
        notify.addCustomProgramUserPermissionsError();
      },
    }
  );
}

export const useUpdateCustomProgramUserPermission = mutationHookFactory<
  UserPermissionUpdatePayload,
  null
>(MutationMethods.Post, `${BASE_PATH}/update-user-permission`, {
  onSuccessNotification: notify.updateCustomProgramUserPermissionSuccess,
  onErrorNotification: notify.updateCustomProgramUserPermissionError,
});

export function useDeleteCustomProgramUserPermission(
  options: UseMutationOptions<
    DeleteCustomProgramUserPermissionPayload,
    unknown,
    unknown
  >
): UseMutationResult<DeleteCustomProgramUserPermissionPayload, unknown> {
  return useMutation(
    (payload: DeleteCustomProgramUserPermissionPayload) => {
      const mutationRm = getDeleteCustomProgramUserPermissionRm(payload);
      return simpleDeleteFn<DeleteCustomProgramUserPermissionPayload>(
        mutationRm.path,
        mutationRm.payload
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context);
        notify.deleteCustomProgramUserPermissionSuccess();
      },
      onError: (error, variables, context) => {
        options.onError && options.onError(error, variables, context);
        notify.deleteCustomProgramUserPermissionError();
      },
    }
  );
}
