import {Checkbox, Col, Form, Row} from 'antd';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {getCompaniesRq, getDevAddUserRm} from '@store/apiEndpoints';
import {useMutation, useQuery} from 'react-query';
import {simpleMutationFn, simpleQueryFn} from '@store/queryClient';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {notify} from '@components/user/notifications';
import {ActiveCompanyVM, NewDevUser} from '@generated/interfaces';

const Container = styled.div`
  padding-left: 10px;
  padding-top: 10px;
`;

enum Fields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  CompanyId = 'companyId',
  Roles = 'roles',
  Features = 'features',
}

enum Features {
  IsEligibleForPersonalLearningBudget = 'isEligibleForPersonalLearningBudget',
  CanViewAcademiesFeature = 'canViewAcademiesFeature',
}

enum Roles {
  LearnInAdmin = 'LearnInAdmin',
  Admin = 'Admin',
  Manager = 'Manager',
  BetaUser = 'BetaUser',
  Dev = 'Dev',
}

const UserRoles = [
  Roles.LearnInAdmin,
  Roles.Admin,
  Roles.Manager,
  Roles.BetaUser,
  Roles.Dev,
];

interface FormValues {
  companyId: number;
  email: string;
  firstName: string;
  lastName: string;
  roles: Roles[];
  features: Features[];
}

export default function AddUserAdminForm() {
  const [form] = Form.useForm();

  const companiesRq = getCompaniesRq();
  const companiesQuery = useQuery<ActiveCompanyVM[]>(companiesRq.queryKey, () =>
    simpleQueryFn(companiesRq.path)
  );

  const addUserMutation = useMutation(
    (payload: NewDevUser) => {
      const devAddUserRm = getDevAddUserRm(payload);
      return simpleMutationFn<string>(devAddUserRm.path, devAddUserRm.payload);
    },
    {
      onSuccess: async () => {
        form.resetFields();
        notify.addUserSuccess();
      },
      onError: () => {
        notify.addUserError();
      },
    }
  );

  const onFinish = async (values: FormValues) => {
    const payload: NewDevUser = {
      companyId: values?.companyId,
      email: values?.email,
      firstName: values?.firstName,
      lastName: values?.lastName,
      roles: {
        learnInAdmin: !!values?.roles?.includes(Roles.LearnInAdmin),
        admin: !!values?.roles?.includes(Roles.Admin),
        manager: !!values?.roles?.includes(Roles.Manager),
        betaUser: !!values?.roles?.includes(Roles.BetaUser),
        dev: !!values?.roles?.includes(Roles.Dev),
      },
      canViewAcademiesFeature: !!values?.features?.includes(
        Features.CanViewAcademiesFeature
      ),
      isEligibleForPersonalLearningBudget: !!values?.features?.includes(
        Features.IsEligibleForPersonalLearningBudget
      ),
      sendAccessEmail: false,
      costCenter: null,
    };

    await addUserMutation.mutateAsync(payload);
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const NON_I18N = {
    FEATURE__PLURAL: 'Features',
    PLB_ELIGIBLE: 'Personal Learning Budget Eligible',
  };

  const featureDefaults = [
    Features.CanViewAcademiesFeature,
    Features.IsEligibleForPersonalLearningBudget,
  ];

  return (
    <Container>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={Fields.FirstName}
              label={i18n.t(k.GENERIC__FIRST_NAME)}
              rules={[
                {
                  required: true,
                  message: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                    fieldName: i18n
                      .t(k.GENERIC__FIRST_NAME)
                      .toLocaleLowerCase(),
                  }),
                },
              ]}>
              <LearnInInput tag={InputTags.PRIMARY} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={Fields.LastName}
              label={i18n.t(k.GENERIC__LAST_NAME)}
              rules={[
                {
                  required: true,
                  message: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                    fieldName: i18n.t(k.GENERIC__LAST_NAME).toLocaleLowerCase(),
                  }),
                },
              ]}>
              <LearnInInput tag={InputTags.PRIMARY} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={Fields.Email}
              label={i18n.t(k.GENERIC__EMAIL)}
              rules={[
                {
                  required: true,
                  message: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                    fieldName: i18n.t(k.GENERIC__EMAIL).toLocaleLowerCase(),
                  }),
                },
              ]}>
              <LearnInInput tag={InputTags.PRIMARY} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={Fields.CompanyId}
              label={i18n.t(k.COMPANY)}
              rules={[
                {
                  required: true,
                  message: i18n.t(k.USER__ADD__INVALID_COMPANY),
                },
              ]}>
              <LearnInSelect>
                {companiesQuery.isSuccess &&
                  companiesQuery.data.map((companyVm) => (
                    <LearnInSelectOption
                      title={companyVm.companyName}
                      value={companyVm.companyId}
                      key={companyVm.companyId}>
                      {`${companyVm.companyName} (id: ${companyVm.companyId})`}
                    </LearnInSelectOption>
                  ))}
              </LearnInSelect>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <Form.Item name={Fields.Roles} label={i18n.t(k.USER__ROLES)}>
              <LearnInSelect mode={'multiple'}>
                {UserRoles.map((role) => (
                  <LearnInSelectOption value={role} title={role} key={role}>
                    {role}
                  </LearnInSelectOption>
                ))}
              </LearnInSelect>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col>
            <Form.Item
              name={Fields.Features}
              label={NON_I18N.FEATURE__PLURAL}
              initialValue={featureDefaults}>
              <Checkbox.Group>
                <div>
                  <Checkbox
                    name={Features.CanViewAcademiesFeature}
                    value={Features.CanViewAcademiesFeature}>
                    {i18n.t(k.ACADEMY__PLURAL__VIEW)}
                  </Checkbox>
                </div>
                <div>
                  <Checkbox
                    name={Features.IsEligibleForPersonalLearningBudget}
                    value={Features.IsEligibleForPersonalLearningBudget}>
                    {NON_I18N.PLB_ELIGIBLE}
                  </Checkbox>
                </div>
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <Form.Item>
              <LearnInButton tag={ButtonTags.Primary} htmlType="submit">
                {i18n.t(k.CTA__SUBMIT)}
              </LearnInButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
