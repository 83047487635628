import {StarFilled, StarOutlined} from '@ant-design/icons';
import {range} from 'lodash';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import styled from 'styled-components';

/*
|--------------------------------------------------------------------------
| Settings & Types
|--------------------------------------------------------------------------
*/

const MAX_RATING = 5;

interface IStarRatingProps {
  maxRating?: number;
  rating: number;
  onClick: (rating) => void;
}

/*
|--------------------------------------------------------------------------
| Star Rating Display
|--------------------------------------------------------------------------
*/

export const StarRatingDisplay = ({
  maxRating = MAX_RATING,
  rating = 0,
}: Omit<IStarRatingProps, 'onClick'>) => {
  const starElements = range(maxRating).map((_, idx) => {
    return (
      <span
        key={idx}
        aria-label={i18n.t(k.PROGRAM__DETAILS__STAR__REVIEW__FORMAT, {
          rating: idx + 1,
          maxRating: maxRating,
        })}>
        <StarFilled
          aria-hidden="true"
          size={30}
          style={{
            color: idx >= rating ? COLORS.Neutral200 : COLORS.Yellow500,
            cursor: 'default',
            marginRight: '6px',
            fontSize: '1.25rem',
          }}
        />
      </span>
    );
  });
  return <>{starElements}</>;
};

/*
|--------------------------------------------------------------------------
| Star Rating Form
|--------------------------------------------------------------------------
*/
const StarRating = styled.div`
  display: flex;
  gap: 0.5rem;
  button:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
  }
`;

export const StarRatingForm = ({
  maxRating = MAX_RATING,
  rating = 0,
  onClick,
}: IStarRatingProps) => {
  return (
    <StarRating>
      {range(0, rating).map((_, idx) => {
        return (
          <LearnInButton
            key={`star-existing-rating-${idx + 1}`}
            padding={'0'}
            onClick={() => onClick(Number(idx))}
            tag={ButtonTags.SecondaryBlackRemovedBorder}
            color={COLORS.Yellow500}
            iconSize={'XL'}
            icon={
              <StarFilled
                alt={i18n.t(k.PROGRAM__DETAILS__STAR__REVIEW__FORMAT, {
                  rating: idx + 1,
                  maxRating: maxRating,
                })}
                data-testid={`star-input-${idx}`}
                key={idx}
              />
            }
          />
        );
      })}
      {range(rating, maxRating).map((_, idx) => {
        return (
          <LearnInButton
            key={`star-new-rating-${idx + 1}`}
            aria-label={i18n.t(k.PROGRAM__DETAILS__STAR__REVIEW__FORMAT, {
              rating: idx + 1,
              maxRating: maxRating,
            })}
            padding={'0'}
            onClick={() => onClick(Number(idx) + Number(rating))}
            tag={ButtonTags.SecondaryBlackRemovedBorder}
            color={COLORS.Neutral600}
            iconSize={'XL'}
            icon={
              <StarOutlined
                aria-hidden="true"
                data-testid={`star-input-${rating + idx}`}
                key={idx}
              />
            }
          />
        );
      })}
    </StarRating>
  );
};
