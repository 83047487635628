import {simpleQueryFn, simpleInvalidateExactQueryFn} from '@store/queryClient';
import {useQuery, UseQueryOptions} from 'react-query';
import {IncentiveAdminVM, FinanceDetailsVM} from '@models/serverModels';
import {
  getIncentiveAdminFinanceRq,
  getFinanceIncentiveDetailRq,
} from '@store/apiEndpoints';
import {IncentiveAvailability} from '@components/user/store/interface';
import {queryHookFactory} from '../utils';

export const useGetFinanceIncentiveAdminVMsQuery = (
  queryOptions?: UseQueryOptions<IncentiveAdminVM[]>
) => {
  const queryRq = getIncentiveAdminFinanceRq();
  const query = useQuery<IncentiveAdminVM[]>(
    queryRq.queryKey,
    () => simpleQueryFn(queryRq.path),
    queryOptions
  );

  const invalidate = async () =>
    await simpleInvalidateExactQueryFn(queryRq.queryKey);

  const activePlbExists = query?.data?.some(
    (incentive) =>
      incentive.learningBudget &&
      incentive.availability !== IncentiveAvailability.NotAvailable
  );

  return {
    ...query,
    invalidate,
    activePlbExists,
  };
};

export const useGetFinanceDetailsVMQuery = (
  id: number,
  queryOptions?: UseQueryOptions<FinanceDetailsVM>
) => {
  const queryRq = getFinanceIncentiveDetailRq(id);
  const query = useQuery<FinanceDetailsVM>(
    queryRq.queryKey,
    () => simpleQueryFn(queryRq.path),
    {
      enabled: !!id,
      ...queryOptions,
    }
  );

  const invalidate = async () =>
    await simpleInvalidateExactQueryFn(queryRq.queryKey);

  return {
    ...query,
    invalidate,
  };
};
