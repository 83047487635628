import LoadingSpinner from '@blocks/LoadingSpinner';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {Form} from 'antd';
import {
  LicenseButtonsContainer,
  LicenseCard,
  LicenseContainer,
  LicenseSectionTitle,
  LicenseSectionTitleContainer,
} from './AddUpdateLicenseDrawer.styled';
import {AddUpdateLicenseDrawerViewProps} from './AddUpdateLicenseDrawer.types';
import LicenseApprovalByItem from './FormItems/LicenseApprovalByItem';
import LicenseApprovalGuidelinesItem from './FormItems/LicenseApprovalGuidelinesItem';
import LicenseEligibilityItem from './FormItems/LicenseEligibilityItem';
import LicenseFundingTypeItem from './FormItems/LicenseFundingTypeItem';
import LicenseProviderItem from './FormItems/LicenseProviderItem';
import LicenseRedemptionGuidelinesItem from './FormItems/LicenseRedemptionGuidelinesItem';

function AddUpdateLicenseDrawerView({
  approvalGuideline,
  approvalGuidelineRef,
  currency,
  defaultFormValues,
  form,
  formFieldErrors,
  handleOnSubmit,
  internalCost,
  internalCostType,
  isNew,
  licenseDuration,
  licenseDurationType,
  loading,
  onClose,
  provider,
  providerId,
  providers,
  providersInUse,
  providerSuggestions,
  redemptionGuideline,
  redemptionGuidelineRef,
  redemptionType,
  requireAdminApproval,
  requireManagerApproval,
  setApprovalGuideline,
  setFormFieldErrors,
  setInternalCost,
  setInternalCostType,
  setLicenseDuration,
  setLicenseDurationType,
  setProvider,
  setProviderId,
  setRedemptionGuideline,
  setRedemptionType,
  setRequireAdminApproval,
  setRequireManagerApproval,
  setStatus,
  setType,
  visible,
  voucherAssignMode,
  setVoucherAssignMode,
  a11yAlertMessage,
}: AddUpdateLicenseDrawerViewProps) {
  return loading ? (
    <LoadingSpinner style={{margin: 'auto'}} />
  ) : (
    <LearnInDrawer
      title={i18n.t(isNew ? k.LICENSE__ADD : k.LICENSE__EDIT)}
      placement="bottom"
      closable={true}
      onClose={onClose}
      visible={visible}
      bodyStyle={{background: COLORS.Neutral50, padding: 0}}
      responsiveSize={'100%'}
      footer={[
        <LicenseButtonsContainer key="buttonContainer">
          <LearnInButton
            tag={ButtonTags.DrawerFooterSecondary}
            onClick={onClose}>
            {i18n.t(k.CTA__CANCEL)}
          </LearnInButton>
          <LearnInButton
            tag={ButtonTags.DrawerFooterPrimary}
            htmlType="submit"
            form="addUpdateLicenseForm"
            data-cy="saveLicenseButton">
            {i18n.t(k.LICENSE__SAVE)}
          </LearnInButton>
        </LicenseButtonsContainer>,
      ]}>
      <LicenseContainer>
        <Form
          form={form}
          id="addUpdateLicenseForm"
          onFinish={handleOnSubmit}
          initialValues={defaultFormValues}
          layout={'vertical'}>
          <LicenseCard>
            <LicenseSectionTitleContainer>
              <LicenseSectionTitle>
                {i18n.t(k.LICENSE__APPLICABLE_PROGRAMS)}
              </LicenseSectionTitle>
            </LicenseSectionTitleContainer>
            <LicenseProviderItem
              formFieldErrors={formFieldErrors}
              isNew={isNew}
              provider={provider}
              providerId={providerId}
              providers={providers}
              providersInUse={providersInUse}
              providerSuggestions={providerSuggestions}
              setFormFieldErrors={setFormFieldErrors}
              setProvider={setProvider}
              setProviderId={setProviderId}
            />
          </LicenseCard>

          <LicenseCard>
            <LicenseSectionTitleContainer>
              <LicenseSectionTitle>
                {i18n.t(k.LICENSE__FUNDING_METHOD)}
              </LicenseSectionTitle>
            </LicenseSectionTitleContainer>

            <LicenseFundingTypeItem
              currency={currency}
              formFieldErrors={formFieldErrors}
              internalCost={internalCost}
              internalCostType={internalCostType}
              licenseDuration={licenseDuration}
              licenseDurationType={licenseDurationType}
              redemptionType={redemptionType}
              voucherAssignMode={voucherAssignMode}
              setFormFieldErrors={setFormFieldErrors}
              setInternalCost={setInternalCost}
              setInternalCostType={setInternalCostType}
              setLicenseDuration={setLicenseDuration}
              setLicenseDurationType={setLicenseDurationType}
              setRedemptionType={setRedemptionType}
              setType={setType}
              setVoucherAssignMode={setVoucherAssignMode}
            />

            <LicenseRedemptionGuidelinesItem
              formFieldErrors={formFieldErrors}
              redemptionGuideline={redemptionGuideline}
              redemptionGuidelineRef={redemptionGuidelineRef}
              setFormFieldErrors={setFormFieldErrors}
              setRedemptionGuideline={setRedemptionGuideline}
            />
          </LicenseCard>

          <LicenseCard>
            <LicenseSectionTitleContainer>
              <LicenseSectionTitle>
                {i18n.t(k.LICENSE__ELIGIBILITY)}
              </LicenseSectionTitle>
            </LicenseSectionTitleContainer>
            <LicenseEligibilityItem setStatus={setStatus} />
          </LicenseCard>

          <LicenseCard>
            <LicenseSectionTitleContainer>
              <LicenseSectionTitle>
                {i18n.t(k.LICENSE__APPROVALS)}
              </LicenseSectionTitle>
            </LicenseSectionTitleContainer>
            <LicenseApprovalByItem
              requireAdminApproval={requireAdminApproval}
              requireManagerApproval={requireManagerApproval}
              setRequireAdminApproval={setRequireAdminApproval}
              setRequireManagerApproval={setRequireManagerApproval}
            />
            <LicenseApprovalGuidelinesItem
              approvalGuideline={approvalGuideline}
              approvalGuidelineRef={approvalGuidelineRef}
              formFieldErrors={formFieldErrors}
              setApprovalGuideline={setApprovalGuideline}
              setFormFieldErrors={setFormFieldErrors}
            />
          </LicenseCard>
          <div
            aria-live="polite"
            role="status"
            style={{
              visibility: a11yAlertMessage ? 'visible' : 'hidden',
              height: 0,
            }}>
            {a11yAlertMessage}
          </div>
        </Form>
      </LicenseContainer>
    </LearnInDrawer>
  );
}

export default AddUpdateLicenseDrawerView;
