import {updateCostCenterAsManagerRq} from '../../../store/apiEndpoints/index';
import {simpleMutationFn} from '@store/queryClient';
import {useMutation, UseMutationOptions, UseMutationResult} from 'react-query';
import {CostCenterOverridePayload} from '@models/serverModels';
import {notify} from '@components/user/notifications';

export function useUpdateCostCenterAsManager(
  options: UseMutationOptions<CostCenterOverridePayload, unknown, unknown>
): UseMutationResult<CostCenterOverridePayload, unknown> {
  return useMutation(
    (payload: CostCenterOverridePayload) => {
      const mutationRm = updateCostCenterAsManagerRq(payload);
      return simpleMutationFn<CostCenterOverridePayload>(
        mutationRm.path,
        mutationRm.payload
      );
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options.onSuccess && options.onSuccess(data, variables, context);
        notify.updateCostCenterSuccess();
      },
      onError: (error, variables, context) => {
        options.onError && options.onError(error, variables, context);
        notify.updateCostCenterError();
      },
    }
  );
}
