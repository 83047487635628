import * as React from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  fullWidth?: boolean;
  style?: React.CSSProperties;
}

export default function PageContentLayout({
  children,
  fullWidth,
  style,
  className = '',
}: Props) {
  return (
    <section
      style={{
        padding: fullWidth ? `0px 0px` : '0px 16px',
        marginBottom: '80px',
        ...style,
      }}
      className={className}>
      <div>{children}</div>
    </section>
  );
}
