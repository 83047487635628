import {CustomProgramStatus} from './../enums';
import {CustomProgramCohortSearchVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramCohortSearchVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramCohortSearchVMMockData(
  args?: Partial<CustomProgramCohortSearchVM>
): CustomProgramCohortSearchVM {
  return {
    endDateUtc: String(faker.date.past()),
    id: faker.datatype.number(), // optional
    parentCustomProgramId: faker.datatype.number(),
    restrictedAccess: faker.datatype.boolean(),
    startDateUtc: String(faker.date.past()),
    status: CustomProgramStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramCohortSearchVMMockData: CustomProgramCohortSearchVM =
  {
    endDateUtc: '2023-03-29T22:17:19.738072',
    id: 1, // optional
    parentCustomProgramId: 1,
    restrictedAccess: true,
    startDateUtc: '2023-03-29T22:17:19.738072',
    status: CustomProgramStatus.Draft,
  };
