import {ApprovalsDiscriminator, LanguagePreference} from './../enums';
import {ApprovalRequestUpdateEmailPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ApprovalRequestUpdateEmailPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getApprovalRequestUpdateEmailPayloadMockData(
  args?: Partial<ApprovalRequestUpdateEmailPayload>
): ApprovalRequestUpdateEmailPayload {
  return {
    amountRequested: String(faker.datatype.number()), // optional
    approvalsDiscriminator: ApprovalsDiscriminator.Incentive,
    approved: faker.datatype.boolean(),
    approver: faker.word.noun(), // optional
    approverNotes: faker.word.noun(), // optional
    buttonUrl: faker.internet.url(),
    canUsePlb: faker.datatype.boolean(),
    daysUntilExpiration: faker.word.noun(), // optional
    imageUrl: faker.image.imageUrl(), // optional
    isAdditional: faker.datatype.boolean(),
    isReimbursement: faker.datatype.boolean(),
    itemType: faker.word.noun(),
    languagePreference: LanguagePreference.EN_US,
    name: faker.name.fullName(),
    provider: faker.word.noun(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ApprovalRequestUpdateEmailPayloadMockData: ApprovalRequestUpdateEmailPayload =
  {
    amountRequested: '5', // optional
    approvalsDiscriminator: ApprovalsDiscriminator.Incentive,
    approved: true,
    approver: 'string', // optional
    approverNotes: 'string', // optional
    buttonUrl: 'https://this-person-does-not-exist.com/en',
    canUsePlb: true,
    daysUntilExpiration: 'string', // optional
    imageUrl: 'https://loremflickr.com/640/480', // optional
    isAdditional: true,
    isReimbursement: true,
    itemType: 'string',
    languagePreference: LanguagePreference.EN_US,
    name: 'Bobby Johnson',
    provider: 'string',
    title: 'Advanced Web Design',
  };
