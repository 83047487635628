import {SubmissionType} from './../enums';
import {ProjectAdminVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ProjectAdminVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProjectAdminVMMockData(
  args?: Partial<ProjectAdminVM>
): ProjectAdminVM {
  return {
    dueDateTimeZone: faker.address.timeZone(), // optional
    dueDateUtc: String(faker.date.past()), // optional
    id: faker.datatype.number(),
    numberOfParticipants: faker.datatype.number(),
    numberOfSubmissions: faker.datatype.number(),
    sectionOrder: faker.datatype.number(),
    stepOrder: faker.datatype.number(),
    submissionType: SubmissionType.Text,
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProjectAdminVMMockData: ProjectAdminVM = {
  dueDateTimeZone: 'Asia/Dubai', // optional
  dueDateUtc: '2023-03-29T22:17:19.738072', // optional
  id: 1,
  numberOfParticipants: 123,
  numberOfSubmissions: 123,
  sectionOrder: 123,
  stepOrder: 123,
  submissionType: SubmissionType.Text,
  title: 'Advanced Web Design',
};
