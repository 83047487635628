import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {PlusOutlined} from '@ant-design/icons';

interface AddContributorsButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
}

function AddContributorsButton({onClick, style}: AddContributorsButtonProps) {
  return (
    <LearnInButton onClick={onClick} tag={ButtonTags.Secondary} style={style}>
      <PlusOutlined aria-hidden="true" style={{marginRight: '10px'}} />{' '}
      {i18n.t(k.CONTRIBUTOR__ADD)}
    </LearnInButton>
  );
}

export default AddContributorsButton;
