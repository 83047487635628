import {mutationHookFactory, MutationMethods} from '../utils';
import {FeatureProgram, HideProgram} from '@models/serverModels';

const BASE_PATH = 'program';

export const useUpdateFeatureProgramMutation =
  mutationHookFactory<FeatureProgram>(
    MutationMethods.Post,
    `${BASE_PATH}/feature-program`
  );

export const useUpdateProgramAvailabilityMutation =
  mutationHookFactory<HideProgram>(
    MutationMethods.Post,
    `${BASE_PATH}/update-program-availability`
  );
