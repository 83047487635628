import {FinanceType} from './../enums';
import {ReimbursementTargetVM} from './../interfaces';
import {
  ReimbursementTargetProgramVMMockData,
  getReimbursementTargetProgramVMMockData,
} from './ReimbursementTargetProgramVM.mock';
import {faker} from '@faker-js/faker';

// Interface: ReimbursementTargetVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getReimbursementTargetVMMockData(
  args?: Partial<ReimbursementTargetVM>
): ReimbursementTargetVM {
  return {
    financeType: FinanceType.Loan,
    programs: [getReimbursementTargetProgramVMMockData()],
    userFinanceBalance: faker.datatype.number(),
    userFinanceIncentiveId: faker.datatype.number(),
    userFinanceTotalAmount: faker.datatype.number(),
    userPendingAmount: faker.datatype.number(),
    userPlanItemId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ReimbursementTargetVMMockData: ReimbursementTargetVM = {
  financeType: FinanceType.Loan,
  programs: [ReimbursementTargetProgramVMMockData],
  userFinanceBalance: 123,
  userFinanceIncentiveId: 1,
  userFinanceTotalAmount: 5,
  userPendingAmount: 5,
  userPlanItemId: 1,
};
