import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import LevelItem from './LevelItem';
import {LearnInInput} from '@components/reusable/LearnInForm';
import DeleteLevelItem from './DeleteLevelItem.container';
import {KEY_CODES} from '@utils/constants';
import CircleCloseButton from '@blocks/CircleCloseButton';
import AddEditLevelRequirementModalContainer from '../../RequirementGating/AddEditLevelRequirementModal.container';
import {Maybe} from '@utils/typeUtils';
import {AcademyStepVM, AcademyLevelVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

const getTitleInputId = (id: any) => `edit-title-input-level-nav-item-${id}`;

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface LevelsContainerProps {
  active: boolean;
  academyId: number;
  isSectionContentEmpty: boolean;
  id: number;
  isDraggingSectionOrder: number | null;
  onClick: () => void;
  order: number;
  levelReorderIdx: number | null;
  levels: AcademyLevelVM[];
  setIsDraggingSectionOrder: () => void;
  setSectionReorderIdx: () => void;
  onClickMoveToTop: () => void;
  onClickMoveToBottom: () => void;
  reorderSectionItem: () => void;
  title: string;
  updateTitle: (newTitle: string) => void;
  steps: AcademyStepVM[];
  isFirst: boolean;
  blockingTitle: Maybe<string>;
  getScreenNavItemUrlPath: (levelId: number, _new?: boolean) => string;
}

function LevelsContainer({
  active,
  academyId,
  isSectionContentEmpty,
  id,
  isDraggingSectionOrder,
  onClick,
  order,
  levelReorderIdx,
  levels,
  setIsDraggingSectionOrder,
  setSectionReorderIdx,
  onClickMoveToTop,
  onClickMoveToBottom,
  reorderSectionItem,
  title,
  updateTitle,
  steps,
  isFirst,
  blockingTitle,
  getScreenNavItemUrlPath,
}: LevelsContainerProps) {
  const [showSectionTitleEdit, setShowSectionTitleEdit] = useState(false);
  const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);
  const [showAddEditRequirementModal, setShowAddEditRequirementModal] =
    useState(false);
  const [_title, setTitle] = useState('');

  const handleClickRenameSection = () => {
    setTitle(title);
    setShowSectionTitleEdit(true);
    setTimeout(() => {
      const editTitleInput = document.getElementById(getTitleInputId(id));
      editTitleInput?.focus();
    }, 400);
  };

  return (
    <>
      {showSectionTitleEdit ? (
        <div
          style={{display: 'flex', alignItems: 'center', padding: '0px 16px'}}>
          <LearnInInput
            id={getTitleInputId(id)}
            value={_title}
            updateFunc={setTitle}
            style={{width: '360px'}}
            onChange={(e) => setTitle(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === KEY_CODES.ENTER) {
                setShowSectionTitleEdit(false);
                updateTitle(_title);
              } else if (e.key === KEY_CODES.ESCAPE) {
                setShowSectionTitleEdit(false);
                updateTitle(_title);
              }
            }}
            onBlur={() => {
              setShowSectionTitleEdit(false);
              updateTitle(_title);
            }}
          />
          <CircleCloseButton onClick={() => setShowSectionTitleEdit(false)} />
        </div>
      ) : (
        <LevelItem
          id={id}
          active={active}
          order={order}
          blockingTitle={blockingTitle}
          onClick={(e) => {
            if (e.detail == 2) {
              handleClickRenameSection();
            }
            onClick();
          }}
          showUpdateRequirementOption={!isFirst}
          onClickUpdateRequirement={() => setShowAddEditRequirementModal(true)}
          updateRequirementOptionLabel={
            !blockingTitle
              ? i18n.t(k.LEVEL__REQUIREMENT__ADD)
              : i18n.t(k.LEVEL__REQUIREMENT__EDIT)
          }
          onClickRenameSection={handleClickRenameSection}
          title={title}
          onClickDelete={() => {
            onClick();
            setShowDeleteSectionModal(true);
          }}
          onClickMoveToTop={async () => onClickMoveToTop()}
          onClickMoveToBottom={async () => onClickMoveToBottom()}
          setIsDraggingSectionOrder={setIsDraggingSectionOrder}
          isDraggingSectionOrder={isDraggingSectionOrder}
          setSectionReorderIdx={setSectionReorderIdx}
          reorderSectionItem={async () => {
            if (order !== levelReorderIdx) {
              reorderSectionItem();
            }
          }}
        />
      )}
      {showDeleteSectionModal && (
        <DeleteLevelItem
          getScreenNavItemUrlPath={getScreenNavItemUrlPath}
          academyId={academyId}
          key={id}
          onCancel={() => setShowDeleteSectionModal(false)}
          levelId={id}
          moveContentSectionOptions={levels
            ?.filter(({id: _id}) => {
              return _id !== id;
            })
            .map(({id, title}) => {
              return {title, id};
            })}
          steps={steps}
          isSectionContentEmpty={isSectionContentEmpty}
        />
      )}
      {showAddEditRequirementModal && (
        <AddEditLevelRequirementModalContainer
          visible={showAddEditRequirementModal}
          academyId={academyId}
          levelId={id}
          onCancel={() => setShowAddEditRequirementModal(false)}
        />
      )}
    </>
  );
}

export default LevelsContainer;
