import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {DeleteOutlined, EditOutlined} from '@ant-design/icons';
import {Avatar} from 'antd';
import {getAvatarColorwaysStyles} from '@utils/color';
import {getInitialsOfName} from '@components/providers/utils';
import {COLORS} from '@utils/constants';
import {formatDateTime, DATE_FORMAT} from '@utils/timeUtils';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';

const Name = styled.p`
  color: ${COLORS.Neutral950};
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  margin-bottom: 0px;
`;

const DateTime = styled.p`
  color: ${COLORS.Neutral600};
  text-align: center;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem;
  margin-bottom: 0px;
`;

const Content = styled.p`
  color: ${COLORS.Black}
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  margin: 12px 36px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MetadataWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface SubmissionPreviewProps {
  firstName: string;
  lastName: string;
  submissionText: string;
  timestamp: string;
  handleDeleteSubmission: () => void;
  handleEditSubmission: () => void;
}

export function SubmissionPreview({
  firstName,
  lastName,
  submissionText,
  timestamp,
  handleDeleteSubmission,
  handleEditSubmission,
}: SubmissionPreviewProps) {
  const name = `${firstName} ${lastName}`;
  const dateTime = new Date(timestamp);

  return (
    <>
      <HeaderWrapper>
        <MetadataWrapper>
          <Avatar
            style={{
              ...getAvatarColorwaysStyles(name.length),
              flexShrink: 0,
              fontWeight: '700',
            }}>
            {getInitialsOfName(name)}
          </Avatar>
          <Name>
            {firstName} {lastName}
          </Name>
          <DateTime>
            {formatDateTime(dateTime, DATE_FORMAT.MONTH_DAY_SHORT)}
          </DateTime>
          <DateTime>
            {formatDateTime(dateTime, DATE_FORMAT.TIME_12_HR)}
          </DateTime>
        </MetadataWrapper>
        <div style={{display: 'flex'}}>
          <LearnInButton
            style={{fontWeight: 400}}
            icon={<EditOutlined />}
            tag={ButtonTags.SecondaryRemovedBorder}
            onClick={handleEditSubmission}>
            {i18n.t(k.CTA__EDIT)}
          </LearnInButton>
          <LearnInButton
            style={{fontWeight: 400}}
            icon={<DeleteOutlined />}
            tag={ButtonTags.DestructiveRemovedBorder}
            onClick={handleDeleteSubmission}>
            {i18n.t(k.CTA__DELETE)}
          </LearnInButton>
        </div>
      </HeaderWrapper>
      <Content>{submissionText}</Content>
    </>
  );
}
