import {CurrencyCode, LicenseRedemptionType, LicenseStatus} from './../enums';
import {ILicenseBasicInfo} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ILicenseBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getILicenseBasicInfoMockData(
  args?: Partial<ILicenseBasicInfo>
): ILicenseBasicInfo {
  return {
    approvalGuideline: faker.word.noun(),
    companyId: faker.datatype.number(),
    currency: CurrencyCode.USD,
    id: faker.datatype.number(),
    internalCost: faker.datatype.number(),
    redemptionGuideline: faker.word.noun(),
    redemptionType: LicenseRedemptionType.Voucher,
    requireAdminApproval: faker.datatype.boolean(),
    requireManagerApproval: faker.datatype.boolean(),
    status: LicenseStatus.Draft,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ILicenseBasicInfoMockData: ILicenseBasicInfo = {
  approvalGuideline: 'string',
  companyId: 1,
  currency: CurrencyCode.USD,
  id: 1,
  internalCost: 123,
  redemptionGuideline: 'string',
  redemptionType: LicenseRedemptionType.Voucher,
  requireAdminApproval: true,
  requireManagerApproval: true,
  status: LicenseStatus.Draft,
};
