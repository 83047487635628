import {IncentiveAvailability} from './../enums';
import {IncentiveAdminVM} from './../interfaces';
import {
  ApprovalConfigMockData,
  getApprovalConfigMockData,
} from './ApprovalConfig.mock';
import {faker} from '@faker-js/faker';

// Interface: IncentiveAdminVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getIncentiveAdminVMMockData(
  args?: Partial<IncentiveAdminVM>
): IncentiveAdminVM {
  return {
    approvalConfig: getApprovalConfigMockData(),
    approvalGuidelines: faker.word.noun(),
    availability: IncentiveAvailability.All,
    eligibleEmployees: faker.datatype.number(),
    id: faker.datatype.number(),
    imageLarge: faker.word.noun(),
    imageSmall: faker.word.noun(),
    incentiveType: faker.word.noun(),
    learningBudget: faker.datatype.boolean(),
    timesPlanned: faker.datatype.number(),
    timesUsed: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const IncentiveAdminVMMockData: IncentiveAdminVM = {
  approvalConfig: ApprovalConfigMockData,
  approvalGuidelines: 'string',
  availability: IncentiveAvailability.All,
  eligibleEmployees: 123,
  id: 1,
  imageLarge: 'string',
  imageSmall: 'string',
  incentiveType: 'string',
  learningBudget: true,
  timesPlanned: 5,
  timesUsed: 5,
  title: 'Advanced Web Design',
};
