import {
  adjustAmountByStatus,
  formatCurrency,
  getColorByAmountAndStatus,
} from './moneyUtils';
import get from 'lodash/get';
import {formatDateTime, DATE_FORMAT} from '@utils/timeUtils';
import {CurrencyCode} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Sorting
|--------------------------------------------------------------------------
*/

export function basicSorter<T>(key: string) {
  return (a: T, b: T) => (a[key] || 0) - (b[key] || 0);
}

export function basicSorterNestedKey<T>(keyPath: string) {
  return (a: T, b: T) => (get(a, keyPath) || 0) - (get(b, keyPath) || 0);
}

export function stringSorter<T>(key: string) {
  return (a: T, b: T) => (a[key] || '').localeCompare(b[key] || '');
}

export function booleanSorter(key: string) {
  return (a: unknown, b: unknown) => {
    // goal is to sort true before false
    if (a[key] && !b[key]) return -1;
    if (!a[key] && b[key]) return 1;
    return 0;
  };
}

export function dateSorter(key: string) {
  return (a: unknown, b: unknown) => {
    const aDate = Date.parse(a[key] || 0);
    const bDate = Date.parse(b[key] || 0);
    return aDate - bDate;
  };
}

/*
|--------------------------------------------------------------------------
| Renders
|--------------------------------------------------------------------------
*/
export const renderCurrency = ({
  amount,
  exchangeRate,
  usersCurrency,
}: {
  amount: number;
  exchangeRate: number;
  usersCurrency: CurrencyCode;
}) => {
  return (
    <div>
      {formatCurrency(amount, usersCurrency, exchangeRate, {
        decimal: true,
      })}
    </div>
  );
};

export const renderBalanceColumn = ({
  amount,
  status,
  usersCurrency,
  exchangeRate,
}: {
  amount: number;
  status: string;
  usersCurrency: CurrencyCode;
  exchangeRate: number;
}) => {
  status = status?.toLowerCase();
  amount = adjustAmountByStatus(amount, status);

  return (
    <div style={{display: 'flex', justifyContent: 'end'}}>
      <span
        data-testid="balance"
        data-cy="balance"
        style={{
          color: getColorByAmountAndStatus(amount, status),
          whiteSpace: 'nowrap',
        }}>
        {formatCurrency(amount, usersCurrency, exchangeRate, {
          decimal: true,
        })}
      </span>
    </div>
  );
};

export const renderDateTime = (dateTime: Date | number) => {
  return <div>{formatDateTime(dateTime, DATE_FORMAT.MONTH_DAY_YEAR)}</div>;
};

/*
|--------------------------------------------------------------------------
| Pagination
|--------------------------------------------------------------------------
*/

interface TablePaginationConfig {
  position: (
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
  )[];
  pageSize: number;
  showSizeChanger: boolean;
  onChange: () => void;
}

export const paginationProps: TablePaginationConfig = {
  position: ['bottomRight'],
  pageSize: 50,
  showSizeChanger: false,
  onChange: () => {
    // Scroll to page top on page change
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  },
};
