import {UserRequestType} from '@generated/enums';
import {i18n, k} from '@i18n/translate';
import {datadogLogs} from '@datadog/browser-logs';

export const getUserRequestType = (type: UserRequestType) => {
  let userRequestType: string;
  switch (type) {
    case UserRequestType.ReimbursementPreApproval:
      userRequestType = i18n.t(k.REIMBURSEMENT__PRE);
      break;
    case UserRequestType.AdditionalFunds:
      userRequestType = i18n.t(k.MONEY__ADDITIONAL_FUNDS);
      break;
    case UserRequestType.Funds:
      userRequestType = i18n.t(k.FUND__PLURAL);
      break;
    case UserRequestType.License:
      userRequestType = i18n.t(k.LICENSE);
      break;
    case UserRequestType.Reimbursement:
      userRequestType = i18n.t(k.REIMBURSEMENT);
      break;
    default:
      userRequestType = null;
      datadogLogs.logger.error('Type is not found in UserRequestType');
  }
  return userRequestType;
};
