export const getUrlParameter = (href: string, param: string): string =>
  new URL(href).searchParams.get(param)?.split('/')[0]; // removes trailing slash

export const removeParameter = (href: string, param: string) => {
  const url = new URL(href);
  if (url.searchParams.has(param)) {
    url.searchParams.delete(param);
    window.history.replaceState(window.history.state, '', url.toString());
  }
};
