import {i18n, k} from '@i18n/translate';
import {LearnInWrapper} from '@components/reusable/Wrapper/Wrapper.style';
import {LearnInText} from '@components/reusable/Text/Text.style';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ProgramCardLayout} from '@components/reusable/layout/ProgramCard.layout';
import {LearnInImage} from '@components/reusable/Image/Image.style';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {TextTags} from '@components/reusable/Text/TextEnums';
import {WrapperTags} from '@components/reusable/Wrapper/WrapperEnums';
import {ImageTags} from '@components/reusable/Image/ImageEnums';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LEARN_IN_LIVE} from '@utils/constants';
import useFeatureFlags from '@hooks/useFeatureFlags';

interface Props {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}

export default function LicenseAutoAddModal({isVisible, setIsVisible}: Props) {
  const {isFeatureFlagOn} = useFeatureFlags();
  return (
    <LearnInModal
      tag={ModalTags.LicenseAuto}
      visible={isVisible}
      onOk={() => setIsVisible(false)}
      onCancel={() => setIsVisible(false)}
      okButtonChild={
        isFeatureFlagOn.YourPlanNameChange
          ? i18n.t(k.HOME__YOUR__GO_TO)
          : i18n.t(k.PLAN__YOUR__GO_TO)
      }
      cancelButtonChild={i18n.t(k.CTA__OK)}
      ariaLabel={i18n.t(k.LICENSE__AVAILABLE)}>
      <LearnInWrapper tag={WrapperTags.ModalProgramHeader}>
        <LearnInText tag={TextTags.ModalHeader}>
          {i18n.t(k.LICENSE__AVAILABLE)}
        </LearnInText>
        <LearnInText tag={TextTags.ModalSubHeader}>
          {i18n.t(k.LICENSE__AUTO_ADD__DESCRIPTION)}
        </LearnInText>
      </LearnInWrapper>
      <LearnInWrapper tag={WrapperTags.ModalProgramContent}>
        <LearnInContainer tag={ContainerTags.ModalProgramCard}>
          <ProgramCardLayout>
            <div>
              <LearnInImage
                tag={ImageTags.ModalProgramImage}
                src={
                  'https://news.mit.edu/sites/default/files/download/201205/d20120501152015-2.jpg'
                }
              />

              <div>
                <LearnInText tag={TextTags.ModalProgramCompany}>
                  {LEARN_IN_LIVE.toLocaleUpperCase()}
                </LearnInText>
                <LearnInText tag={TextTags.ModalProgramTitle}>
                  {i18n.t(k.LICENSE__AUTO_ADD__EDX_LICENSE)}
                </LearnInText>
              </div>
            </div>
            <div>
              <LearnInText tag={TextTags.ModalProgramLicenseHeader}>
                {i18n.t(k.LICENSE)}
              </LearnInText>
              <LearnInText tag={TextTags.ModalProgramText}>
                {i18n.t(k.LICENSE__AUTO_ADD__INTERESTED)}
              </LearnInText>
            </div>
          </ProgramCardLayout>
        </LearnInContainer>
      </LearnInWrapper>
    </LearnInModal>
  );
}
