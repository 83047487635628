import '../Incentives.scss';
import * as React from 'react';
import {useCallback, useEffect, useRef, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {Checkbox, Col, Form, Modal, Radio, Row, Typography} from 'antd';
import {DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import {
  Permissions,
  ProgramTypesVM,
  RequireApprovalTypeStatus,
} from '../store/interfaces';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {APPROVAL_GUIDELINE_MAX_COUNT, COLORS} from '@utils/constants';
import {
  FileTypeDetails,
  FileUploadType,
  generateFileUploadMediaTypes,
  getBase64,
  validateUserUpload,
} from '@utils/uploadUtils';
import {IncentiveAvailability} from '@components/user/store/interface';
import {
  EditedIncentiveVM,
  TimeDetailsVM,
  TimeIncentivePayload,
} from '@models/serverModels';
import {IncentiveType} from '@generated/enums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import useDeleteCompanyIncentive from '@hooks/apiEndpoints/incentive/mutations';
import {
  LearnInSelect,
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';
import {
  getAddTimeIncentiveRm,
  getIncentivesCountRq,
  getUpdateTimeIncentiveRm,
} from '@store/apiEndpoints';
import {useMutation} from 'react-query';
import queryClient, {simpleMutationFn, simplePutFn} from '@store/queryClient';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import LearnInDrawer from '@components/reusable/LearnInDrawer';
import {isOverMaxRichTextCharacterCount} from '@utils/form-validation-helpers';
import {b64toBlob} from '@utils/image-utils';
import {AttachmentCard} from '@components/admin/pages/programs/customPrograms/setup/CustomProgramSetupScreen';
import UploadPreviewLogo from '@blocks/UploadPreviewLogo';
import {RcFile} from 'antd/lib/upload';
import {notify} from '@components/user/notifications';
import {formatPastingOptions} from '@utils/reactQuillUtils';
import UploadInstructions from '@components/reusable/Upload/UploadInstructions';

interface Props {
  confirmLoading: boolean;
  onOk: (e: boolean) => void;
  onCancel: (e: boolean) => void;
  updatingData: TimeDetailsVM;
  programTypesData: ProgramTypesVM;
}

const {confirm} = Modal;

const TITLE_FIELD = 'title';
const SHORT_DESCRIPTION_FIELD = 'shortDescription';
const LONG_DESCRIPTION_FIELD = 'longDescription';
const TIME_TO_USE_FIELD = 'timeToUse';
const TIME_SPAN_FIELD = 'timeSpan';
const DURATION_FIELD = 'duration';
const AVAILABILITY_FIELD = 'availability';
const PERMISSIONS_FIELD = 'permissions';
const APPROVALS_DETAIL_FIELDS = 'approvalDetails';
const PROGRAM_TYPE_FIELD = 'programType';

const PER_UNIT_DEFAULT = 1;

enum TimeSpan {
  Hours,
  Days,
  Weeks,
  Months,
}

enum DurationTypes {
  Day,
  Week,
  Month,
  Year,
}

const determineIfRequiresApproval = (
  approvalConfig: Record<string, boolean>
): boolean =>
  Object.values(approvalConfig).reduce(
    (prev: boolean, curr: boolean) => prev || curr
  );

const PER_TEXT_STYLING = {
  color: COLORS.Neutral600,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export default function AddTimeIncentiveDrawer(props: Props) {
  const {onOk, onCancel, updatingData} = props;
  const [formRef] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [approvalGuidelines, setApprovalGuidelines] = useState('');
  const [approvalGuidelinesError, setApprovalGuidelinesError] = useState(false);

  const [details, setDetails] = React.useState('');
  const [programTypeDropdownEnabled, setProgramTypeDropdownEnabled] =
    React.useState(
      updatingData
        ? updatingData.availability === IncentiveAvailability.CertainPrograms
        : false
    );

  const [previewUrl, setPreviewUrl] = useState('');

  const [images, setImages] = React.useState([]);

  useEffect(() => {
    if (!images.length) {
      setPreviewUrl(null);
      return;
    }
    const url = URL.createObjectURL(b64toBlob(images[0].fileContent));
    setPreviewUrl(url);
  }, [images]);

  const [showRequireApproval, setShowRequireApproval] = React.useState(false);
  useEffect(() => {
    if (!updatingData) return;
    setShowRequireApproval(
      determineIfRequiresApproval(updatingData.approvalConfig)
    );

    setPreviewUrl(updatingData.imageSmall);

    // add an element to represent the image that was already stored
    setImages([
      {
        fileName: 'original',
        fileContent: '',
        description: '',
      },
    ]);
  }, [updatingData]);

  const deleteCompanyIncentiveMutation = useDeleteCompanyIncentive({
    onSuccess: () => {
      onOk(true);
    },
  });

  const onSubmitSuccess = useCallback(() => {
    queryClient.refetchQueries(getIncentivesCountRq().queryKey[0]);
    setDetails('');
    setConfirmLoading(false);
    onOk(true);
  }, []);

  const onSubmitError = useCallback((error) => {
    console.log('Error', error);
    setConfirmLoading(false);
  }, []);

  const onApprovalGuidelinesChange = (
    _0: string,
    _1: any,
    _2: any,
    editor: any
  ) => {
    const value = editor.getHTML();
    if (isOverMaxRichTextCharacterCount(value, APPROVAL_GUIDELINE_MAX_COUNT)) {
      setApprovalGuidelinesError(true);
    } else {
      setApprovalGuidelinesError(false);
    }
    setApprovalGuidelines(value);
  };

  const addTimeIncentiveMutation = useMutation(
    ({incentiveToAdd}: {incentiveToAdd: TimeIncentivePayload}) => {
      const addTimeIncentiveRm = getAddTimeIncentiveRm(incentiveToAdd);
      return simpleMutationFn<EditedIncentiveVM>(
        addTimeIncentiveRm.path,
        addTimeIncentiveRm.payload
      );
    },
    {
      onSuccess: () => {
        onSubmitSuccess();
      },
      onError: (error) => onSubmitError(error),
    }
  );

  const updateTimeIncentiveMutation = useMutation(
    ({incentiveToAdd}: {incentiveToAdd: TimeIncentivePayload}) => {
      const updateTimeIncentiveRm = getUpdateTimeIncentiveRm(incentiveToAdd);
      return simplePutFn<EditedIncentiveVM>(
        updateTimeIncentiveRm.path,
        updateTimeIncentiveRm.payload
      );
    },
    {
      onSuccess: () => {
        onSubmitSuccess();
      },
      onError: (error) => onSubmitError(error),
    }
  );

  const detailsHTMLContent =
    updatingData && updatingData.sections.length && !details
      ? (
          updatingData.sections.find(
            (section) => section.title === 'Details'
          ) || {}
        ).content
      : details;

  const radioStyle = {
    display: 'block',
    paddingLeft: '15px',
  };

  const detailsFieldRef = useRef<ReactQuill>();
  const approvalGuidelinesFieldRef = useRef<ReactQuill>();

  /**
   * This sets the initial value for all fields
   */
  const getDefaultFormValues = useCallback(() => {
    if (!updatingData) {
      return {};
    }

    const approvalSettings = [];
    const approvalConfig = updatingData.approvalConfig;
    approvalConfig.adminPreApprovalRequired &&
      approvalSettings.push(RequireApprovalTypeStatus.PREAPPROVAL_ADMIN);
    approvalConfig.managerPreApprovalRequired &&
      approvalSettings.push(RequireApprovalTypeStatus.PREAPPROVAL_MANAGER);
    approvalConfig.adminRedemptionApprovalRequired &&
      approvalSettings.push(RequireApprovalTypeStatus.REDEMPTION_ADMIN);
    approvalConfig.managerRedemptionApprovalRequired &&
      approvalSettings.push(RequireApprovalTypeStatus.REDEMPTION_MANAGER);

    const permissionSettings = [];
    determineIfRequiresApproval(updatingData.approvalConfig) &&
      permissionSettings.push(Permissions.RequiresApproval);

    return {
      [TITLE_FIELD]: updatingData.title,
      [SHORT_DESCRIPTION_FIELD]: updatingData.shortDescription,
      [LONG_DESCRIPTION_FIELD]: null,
      [AVAILABILITY_FIELD]:
        updatingData.availability === null
          ? IncentiveAvailability.NotAvailable
          : updatingData.availability,
      [TIME_TO_USE_FIELD]: updatingData.unit,
      [TIME_SPAN_FIELD]: updatingData.unitType,
      [DURATION_FIELD]: updatingData.perUnitType,
      [PERMISSIONS_FIELD]: permissionSettings,
      [PROGRAM_TYPE_FIELD]:
        updatingData.availability === IncentiveAvailability.CertainPrograms
          ? updatingData.programTypeIds
          : [],
      [APPROVALS_DETAIL_FIELDS]: approvalSettings,
    };
  }, [updatingData]);
  const title = !updatingData
    ? i18n.t(k.TIME__INCENTIVE__CREATE)
    : i18n.t(k.TIME__INCENTIVE__UPDATE);
  return (
    <LearnInDrawer
      visible
      placement="bottom"
      responsiveSize={'100%'}
      title={<span data-testid="time-incentive-drawer-title">{title}</span>}
      footer={[
        <Row key="submit" justify="end">
          {updatingData && (
            <LearnInButton
              tag={ButtonTags.DrawerFooterSecondary}
              id={'delete-time-incentive-button'}
              style={{marginRight: '16px'}}
              onClick={() => {
                confirm({
                  title: i18n.t(k.PROMPT__DELETE),
                  icon: <ExclamationCircleOutlined aria-hidden="true" />,
                  okText: i18n.t(k.CTA__DELETE),
                  okType: 'danger',
                  cancelText: i18n.t(k.CTA__CANCEL),
                  onOk() {
                    deleteCompanyIncentiveMutation.mutate({
                      incentiveType: IncentiveType.Time,
                      incentiveId: updatingData.id,
                    });
                  },
                  onCancel() {
                    // intentional pass
                  },
                });
              }}>
              {i18n.t(k.CTA__DELETE)}
            </LearnInButton>
          )}
          <LearnInButton
            tag={ButtonTags.DrawerFooterPrimary}
            style={{marginRight: '11%'}}
            onClick={async () => {
              setConfirmLoading(true);
              try {
                await formRef.validateFields();
                const data = formRef.getFieldsValue();

                const requestData: TimeIncentivePayload = {
                  images: images,
                  title: data[TITLE_FIELD],
                  subTitle: '',
                  shortDescription: data[SHORT_DESCRIPTION_FIELD],
                  approvalGuidelines: approvalGuidelines,
                  longDescription: data[LONG_DESCRIPTION_FIELD],
                  availability: data[AVAILABILITY_FIELD],
                  programTypeIds:
                    data[AVAILABILITY_FIELD] ===
                    IncentiveAvailability.CertainPrograms
                      ? data[PROGRAM_TYPE_FIELD]
                      : [],
                  unit: parseInt(data[TIME_TO_USE_FIELD]),
                  unitType: data[TIME_SPAN_FIELD],
                  perUnit: PER_UNIT_DEFAULT,
                  perUnitType: data[DURATION_FIELD],
                  approvalConfig: {
                    adminRedemptionApprovalRequired:
                      data[APPROVALS_DETAIL_FIELDS]?.includes(
                        RequireApprovalTypeStatus.REDEMPTION_ADMIN
                      ) ?? false,
                    managerPreApprovalRequired:
                      data[APPROVALS_DETAIL_FIELDS]?.includes(
                        RequireApprovalTypeStatus.PREAPPROVAL_MANAGER
                      ) ?? false,
                    adminPreApprovalRequired:
                      data[APPROVALS_DETAIL_FIELDS]?.includes(
                        RequireApprovalTypeStatus.PREAPPROVAL_ADMIN
                      ) ?? false,
                    managerRedemptionApprovalRequired:
                      data[APPROVALS_DETAIL_FIELDS]?.includes(
                        RequireApprovalTypeStatus.REDEMPTION_MANAGER
                      ) ?? false,
                  },
                  details: details,
                  featured: false,
                  learningBudget: false,
                };

                if (updatingData) {
                  await updateTimeIncentiveMutation.mutateAsync({
                    incentiveToAdd: {id: updatingData.id, ...requestData},
                  });
                } else {
                  await addTimeIncentiveMutation.mutateAsync({
                    incentiveToAdd: requestData,
                  });
                }
              } catch (e) {
                console.log('Error', e);
                setConfirmLoading(false);
                return;
              }
            }}
            loading={confirmLoading}>
            {i18n.t(k.CTA__SUBMIT)}
          </LearnInButton>
        </Row>,
      ]}
      onClose={() => {
        formRef.resetFields();
        setDetails('');
        onCancel(true);
      }}>
      <Form
        data-testid="time-incentive-upsert-drawer"
        className="form-drawers"
        form={formRef}
        initialValues={getDefaultFormValues()}
        key={'time'}
        layout="vertical">
        <div className="form-content">
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={12}>
              <Form.Item
                className="lb-application-drawer"
                name={TITLE_FIELD}
                label={i18n.t(k.GENERIC__TITLE)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(
                      k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                      {
                        item: i18n.t(k.GENERIC__TITLE).toLocaleLowerCase(),
                      }
                    ),
                  },
                ]}>
                <LearnInInput tag={InputTags.PRIMARY} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                className="lb-application-drawer"
                name={SHORT_DESCRIPTION_FIELD}
                label={i18n.t(k.GENERIC__DESCRIPTION__SHORT)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(
                      k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                      {
                        fieldName: i18n.t(k.GENERIC__DESCRIPTION__SHORT),
                      }
                    ),
                  },
                  {
                    max: 1000,
                    message: i18n.t(
                      k.VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT,
                      {
                        fieldName: i18n.t(k.GENERIC__DESCRIPTION__SHORT),
                        max: 1000,
                      }
                    ),
                  },
                ]}>
                <LearnInTextArea tag={TextAreaTags.PRIMARY} maxLength={1000} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[8, 8]}>
            <Col xs={8}>
              <Form.Item
                className="lb-application-drawer"
                name={TIME_TO_USE_FIELD}
                label={i18n.t(k.INCENTIVE__HOW_MUCH_TIME)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(
                      k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
                      {
                        fieldName: i18n.t(k.INCENTIVE__HOW_MUCH_TIME),
                      }
                    ),
                  },
                ]}>
                <LearnInInput
                  tag={InputTags.PRIMARY}
                  type="number"
                  placeholder={i18n.t(k.VALIDATION__MISSING_FIELD__FORMAT, {
                    fieldName: i18n.t(k.GENERIC__AMOUNT),
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Form.Item
                className="lb-application-drawer"
                name={TIME_SPAN_FIELD}
                rules={[
                  {
                    required: true,
                    message: i18n.t(k.VALIDATION__SELECT_SPAN__POLITE),
                  },
                ]}>
                <LearnInSelect
                  placeholder={i18n.t(k.VALIDATION__SELECT_SPAN__POLITE)}>
                  <LearnInSelectOption
                    data-testid="time-span-hours"
                    value={TimeSpan.Hours}>
                    {i18n.t(k.DATE__HOUR__PLURAL)}
                  </LearnInSelectOption>
                  <LearnInSelectOption
                    data-testid="time-span-days"
                    value={TimeSpan.Days}>
                    {i18n.t(k.DATE__DAY__PLURAL)}
                  </LearnInSelectOption>
                  <LearnInSelectOption
                    data-testid="time-span-weeks"
                    value={TimeSpan.Weeks}>
                    {i18n.t(k.DATE__WEEK__PLURAL)}
                  </LearnInSelectOption>
                  <LearnInSelectOption
                    data-testid="time-span-months"
                    value={TimeSpan.Months}>
                    {i18n.t(k.DATE__MONTH__PLURAL)}
                  </LearnInSelectOption>
                </LearnInSelect>
              </Form.Item>
            </Col>
            <Col xs={2} flex={'auto'}>
              <Typography.Text style={PER_TEXT_STYLING}>
                {i18n.t(k.GENERIC__PER)}
              </Typography.Text>
            </Col>
            <Col xs={6}>
              <Form.Item
                className="lb-application-drawer"
                name={DURATION_FIELD}
                rules={[
                  {
                    required: true,
                    message: i18n.t(k.VALIDATION__BALANCE_RENEWAL),
                  },
                ]}>
                <LearnInSelect
                  placeholder={i18n.t(k.VALIDATION__SELECT_DURATION)}>
                  <LearnInSelectOption
                    data-testid="duration-day"
                    value={DurationTypes.Day}>
                    {i18n.t(k.DATE__DAY)}
                  </LearnInSelectOption>
                  <LearnInSelectOption
                    data-testid="duration-week"
                    value={DurationTypes.Week}>
                    {i18n.t(k.DATE__WEEK)}
                  </LearnInSelectOption>
                  <LearnInSelectOption
                    data-testid="duration-month"
                    value={DurationTypes.Month}>
                    {i18n.t(k.DATE__MONTH)}
                  </LearnInSelectOption>
                  <LearnInSelectOption
                    data-testid="duration-year"
                    value={DurationTypes.Year}>
                    {i18n.t(k.DATE__YEAR)}
                  </LearnInSelectOption>
                </LearnInSelect>
              </Form.Item>
            </Col>
          </Row>
          <Col xs={24} sm={12}>
            <Form.Item
              name={AVAILABILITY_FIELD}
              className={'lb-application-drawer'}
              label={i18n.t(k.INITIATIVE__VISIBILITY)}
              rules={[
                {required: true, message: i18n.t(k.VALIDATION__SELECT_ONE)},
              ]}>
              <Radio.Group
                onChange={(value) =>
                  setProgramTypeDropdownEnabled(
                    value.target.value === IncentiveAvailability.CertainPrograms
                  )
                }>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.AvailableToAll}>
                  {i18n.t(k.GENERIC__EVERYONE)}
                </Radio>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.CertainPrograms}>
                  {i18n.t(k.AVAILABILITY__PROGRAM_TYPES)}
                </Radio>
                <Form.Item name={PROGRAM_TYPE_FIELD} noStyle={true}>
                  <LearnInSelect
                    disabled={!programTypeDropdownEnabled}
                    placeholder={i18n.t(k.VALIDATION__SELECT_PROGRAM)}
                    style={{marginLeft: '40px'}}
                    mode="multiple">
                    {props.programTypesData.map((programType) => (
                      <LearnInSelectOption
                        key={programType.id}
                        value={programType.id}>
                        {programType.description}
                      </LearnInSelectOption>
                    ))}
                  </LearnInSelect>
                </Form.Item>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.CertainInitiatives}>
                  {i18n.t(k.AVAILABILITY__INITIATIVE_GROUP)}
                </Radio>
                <Radio
                  style={radioStyle}
                  value={IncentiveAvailability.NotAvailable}>
                  {i18n.t(k.AVAILABILITY__DRAFT)}
                </Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={PERMISSIONS_FIELD}
              className={'lb-application-drawer'}
              label={i18n.t(k.PERMISSION__PLURAL)}>
              <Checkbox.Group>
                <Checkbox
                  value={Permissions.RequiresApproval}
                  onChange={() => setShowRequireApproval((value) => !value)}>
                  {i18n.t(k.AVAILABILITY__APPROVAL_REQUIRED)}
                </Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Col>
          <Row>
            <Col xs={24} sm={12}>
              {showRequireApproval && (
                <Form.Item
                  name={APPROVALS_DETAIL_FIELDS}
                  rules={[
                    {
                      required: true,
                      message: i18n.t(k.VALIDATION__SELECT_REQUIRED_APPROVALS),
                    },
                  ]}>
                  <Checkbox.Group style={{width: '100%'}}>
                    <Row>
                      <Col>
                        <Typography.Text>
                          {i18n.t(k.INCENTIVE__APPROVAL_REQUIRES)}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col xs={24} sm={12} data-testid="manager-preapproval">
                        <Checkbox
                          value={RequireApprovalTypeStatus.PREAPPROVAL_MANAGER}>
                          {i18n.t(k.GENERIC__MANAGER)}
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={12} data-testid="admin-preapproval">
                        <Checkbox
                          value={RequireApprovalTypeStatus.PREAPPROVAL_ADMIN}>
                          {i18n.t(k.ADMIN)}
                        </Checkbox>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col>
                        <Typography.Text>
                          {i18n.t(k.INCENTIVE__REDEMPTION_REQUIRES)}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[8, 32]}>
                      <Col xs={24} sm={12} data-testid="manager-redemption">
                        <Checkbox
                          data-testid="manager-redemption"
                          value={RequireApprovalTypeStatus.REDEMPTION_MANAGER}>
                          {i18n.t(k.GENERIC__MANAGER)}
                        </Checkbox>
                      </Col>
                      <Col xs={24} sm={12} data-testid="admin-redemption">
                        <Checkbox
                          value={RequireApprovalTypeStatus.REDEMPTION_ADMIN}>
                          {i18n.t(k.ADMIN)}
                        </Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row style={{marginBottom: '25px'}}>
            <Col xs={24}>
              {showRequireApproval && (
                <>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          {/* Intentional onClick for ReactQuill support */}
                          {/* eslint-disable-next-line */}
                          <label
                            htmlFor="approval-guidelines-field"
                            // eslint-disable-next-line
                            onClick={() =>
                              approvalGuidelinesFieldRef.current?.focus()
                            }>
                            <Typography.Text>
                              {i18n.t(k.APPROVAL__GUIDELINES)}
                            </Typography.Text>
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Typography.Text>
                            {i18n.t(k.APPROVAL__HELP)}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <ReactQuill
                        id="approval-guidelines-field"
                        formats={formatPastingOptions}
                        value={
                          approvalGuidelines ||
                          updatingData?.approvalGuidelines ||
                          ''
                        }
                        theme="snow"
                        onChange={onApprovalGuidelinesChange}
                        ref={approvalGuidelinesFieldRef}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {approvalGuidelinesError && (
                        <Typography.Text style={{color: COLORS.Red800}}>
                          {i18n.t(
                            k.VALIDATION__CHAR_LIMIT_REACHED_ITEM__FORMAT,
                            {
                              item: i18n.t(k.APPROVAL__GUIDELINES),
                              max: APPROVAL_GUIDELINE_MAX_COUNT,
                            }
                          )}
                        </Typography.Text>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={12}></Col>
          </Row>
          <Row style={{marginBottom: '4px'}}>
            <label>{i18n.t(k.GENERIC__IMAGE).toLocaleUpperCase()}</label>
          </Row>
          {previewUrl ? (
            <AttachmentCard>
              <UploadPreviewLogo imageSrc={previewUrl} size={75} />
              <button
                aria-label={i18n.t(k.CTA__DELETE)}
                onClick={(x) => setImages([])}>
                <DeleteOutlined
                  aria-hidden="true"
                  style={{cursor: 'pointer', color: COLORS.Red800}}
                />
              </button>
            </AttachmentCard>
          ) : (
            <Dragger
              customRequest={async ({file}) => {
                if (
                  validateUserUpload(
                    file as any,
                    [],
                    UploadConstraints.CustomProgramHeaderImageLimit,
                    'bytes',
                    generateFileUploadMediaTypes([FileUploadType.Images])
                  )
                ) {
                  getBase64(file, (data: any) =>
                    setImages((images: any) => [
                      ...images,
                      {
                        fileName: (file as RcFile).name,
                        fileContent: btoa(data),
                        description: '',
                      },
                    ])
                  );
                } else {
                  notify.uploadContentAttachmentIsInvalid();
                }
              }}
              showUploadList={false}
              maxCount={1}
              height={100}
              multiple={false}
              accept={[
                ...FileTypeDetails[FileUploadType.Images].fileSuffixes,
              ].join(', ')}>
              <UploadInstructions
                fileTypes=".png, .jpg, .jpeg"
                maxSize="1 MB"
              />
              <p style={{color: COLORS.Red800}}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                  }}></button>
              </p>
            </Dragger>
          )}
          <Row style={{marginBottom: '4px'}}>
            {/* Intentional onClick for ReactQuill support */}
            {/* eslint-disable-next-line */}
            <label
              htmlFor="details-field"
              onClick={() => detailsFieldRef.current?.focus()}>
              {i18n.t(k.GENERIC__DETAIL__PLURAL).toLocaleUpperCase()}
            </label>
          </Row>

          <ReactQuill
            id="details-field"
            formats={formatPastingOptions}
            theme="snow"
            value={detailsHTMLContent || ''}
            onChange={(e) => setDetails(e)}
            ref={detailsFieldRef}
          />
        </div>
      </Form>
    </LearnInDrawer>
  );
}
