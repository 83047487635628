import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import CustomProgramUserDrawerWithRouting from './CustomProgramUserDrawerWithRouting';
import LearnInPageDrawer from '@components/reusable/LearnInPageDrawer';
import NoDataText from '@blocks/NoDataText';
import CustomProgramAdminDrawerContainer from '@components/admin/pages/programs/customPrograms/CustomProgramAdminDrawer.container';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {
  CustomProgramScreens,
  UserPaths,
  adminProgramsCustomProgramManage,
  adminProgramsCustomRoute,
  getManagerPermissionsCustomProgramAdminDrawerRoute,
  getUserProgramsCustomProgramUserDrawer,
} from '@utils/ClientPaths';
import useSearchParamUtils from '@hooks/useSearchParamUtils';
import CustomProgramCohortsPage from '@components/admin/pages/programs/customPrograms/cohorts/CustomProgramCohortsPage';
import {createCustomProgramScreenUtils} from '@utils/customProgramScreens';
import {useCustomProgramPreviewQuery, useCohortsQuery} from '@generated/hooks';
import {CustomProgramSectionPreviewVM} from '@generated/interfaces';
import useSetPageTitle from '@hooks/useSetPageTitle';
import {useJoinCustomProgram} from '@components/ClickToJoin/useJoinCustomProgram.hook';
import {useBackToLxpLink} from '@hooks/integrated/useBackToLxpLink';
import {useAuth} from '@utils/oidc-auth-wrapper';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/
export const DEFAULT_SCREEN = CustomProgramScreens.Curriculum;
const {getCurrentScreenFromUrl, isCurrenScreenUrlPathInvalid} =
  createCustomProgramScreenUtils(DEFAULT_SCREEN);

// https://linear.app/learnin/issue/LI-4846/custom-program-preview-temporary-hardcoded-redirect-for-1-url
// This hotfixes urls sent out with the contentId instead of the custom program id; temp fix
const li_4846HotfixInfo = (id: number | string) => {
  return {isHotfixId: id === '3059', hotfixCustomProgramId: '12638'};
};

const createCustomProgramEditURLGetter = (currentPath: string) => {
  if (currentPath.includes(UserPaths.Permissions)) {
    return getManagerPermissionsCustomProgramAdminDrawerRoute;
  } else {
    return adminProgramsCustomProgramManage;
  }
};
/*
|--------------------------------------------------------------------------
| Container
|--------------------------------------------------------------------------
*/

export default function CustomProgramUserDrawerContainerWithRouting({
  contentToNavTo,
  onClose,
  parentCustomProgramId,
  showCohortButton,
  getScreenNavItemUrlPath,
  isActivePlanItem,
  title,
}: {
  getScreenNavItemUrlPath: (customProgramId: number, screen) => string;
  contentToNavTo?: {sectionId: number; contentId: number};
  isOverlay?: boolean;
  onClose: () => void;
  showCohortButton?: boolean;
  parentCustomProgramId?: number;
  isActivePlanItem: boolean;
  title?: string;
}) {
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(
    null
  );
  const [selectedContentId, setSelectedContentId] = useState<number | null>(
    null
  );
  const [showCohortsPage, setShowCohortsPage] = useState(false);
  const [restrictAccess, setRestrictAccess] = useState(false);
  const [showMobileNavMenu, setShowMobileNavMenu] = useState(false);
  const [showCustomProgramAdminDrawer, setShowCustomProgramAdminDrawer] =
    useState(false);
  const {user} = useAuth();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {searchParams} = useSearchParamUtils();
  const params = useParams();
  const {isHotfixId, hotfixCustomProgramId} = li_4846HotfixInfo(
    params.customProgramId
  );
  const customProgramId = parseInt(
    isHotfixId ? hotfixCustomProgramId : params.customProgramId
  );

  useSetPageTitle(title);

  // Note: Join Program header button will be hidden when the checking if user can join plan.
  // This is to prevent the user from seeing a flashing Join Program button.
  const isCheckingClickToJoin = useJoinCustomProgram(customProgramId);

  const {data: cohorts} = useCohortsQuery({
    parentCustomProgramId,
  });

  const {
    data: customProgramPreview,
    isSuccess: isCustomProgramPreviewSuccess,
    isLoading: isCustomProgramPreviewLoading,
    refetch: refetchCustomProgramPreview,
  } = useCustomProgramPreviewQuery({
    customProgramId,
    queryParams: {
      includeProgress: true,
    },
  });

  useEffect(() => {
    if (customProgramPreview?.sections?.length) {
      setSelectedSectionId(
        contentToNavTo?.sectionId || customProgramPreview?.sections?.[0]?.id
      );
      setSelectedContentId(contentToNavTo?.contentId);
    }
  }, [customProgramPreview?.id]);

  const scrollToContentFromUrlSearchParam = () => {
    const {scrollContentId} = searchParams;
    const hotfixScrollContentId = isHotfixId
      ? params.customProgramId
      : scrollContentId;
    if (!!hotfixScrollContentId) {
      const contentId = parseInt(String(hotfixScrollContentId));
      const contentSection = customProgramPreview?.sections?.find(
        ({content}) => {
          return content.some(({id}) => id === contentId);
        }
      );
      if (!!contentSection) {
        setSelectedSectionId(contentSection?.id);
        setSelectedContentId(contentId);
      }
    }
  };

  useEffect(scrollToContentFromUrlSearchParam, [isCustomProgramPreviewSuccess]);

  const handleClickSectionNavItem = (id: number) => {
    setSelectedSectionId(id);
    // setting to null to stop auto navigation to content after
    // user is in content drawer
    setSelectedContentId(null);
    if (showMobileNavMenu) {
      setShowMobileNavMenu(false);
    }
  };

  const {selectedSection, nextSection} = (() => {
    let selected: CustomProgramSectionPreviewVM;
    let next: CustomProgramSectionPreviewVM | undefined;
    customProgramPreview?.sections?.forEach((section, idx: number) => {
      if (section.id === selectedSectionId) {
        selected = section;
        next = customProgramPreview?.sections[idx + 1];
      }
    });
    return {selectedSection: selected, nextSection: next};
  })();

  const showProgramHeader =
    !!selectedSection &&
    selectedSection?.id === customProgramPreview?.sections?.[0]?.id;

  const handleCloseRestrictAccessScreen = () => {
    onClose();
    setRestrictAccess(false);
  };

  const handleOpenCustomProgramAdminDrawer = () => {
    const getCustomProgramEditUrl = createCustomProgramEditURLGetter(pathname);
    navigate(
      getCustomProgramEditUrl(customProgramId, CustomProgramScreens.Setup)
    );
  };

  const handleCloseAdminProgramDrawer = () => {
    // Refetch to show any changes done while editing
    refetchCustomProgramPreview();
    setShowCustomProgramAdminDrawer(false);
  };

  const assureValidUrl = () => {
    // invalid screens default to display curriculum screen, but "curriculum" is not in the url path
    if (isCurrenScreenUrlPathInvalid(pathname)) {
      navigate(getScreenNavItemUrlPath(customProgramId, DEFAULT_SCREEN));
    }
  };

  // When an invalid screen is found in the url, this falls back to the curriculum screen
  useEffect(assureValidUrl, [pathname]);

  const handleChangeUpcomingCohort = (cohortId: number) => {
    const screen = !isCurrenScreenUrlPathInvalid(pathname)
      ? getCurrentScreenFromUrl(pathname)
      : DEFAULT_SCREEN;
    navigate(getUserProgramsCustomProgramUserDrawer(cohortId, screen));
  };

  const linkToLxpAcademies = useBackToLxpLink(user, {
    type: 'custom-program',
    resourceId: customProgramId,
    isInternalLink: searchParams?.from === 'custom-programs',
  });

  const handleCloseDrawer = () => {
    // 'admin-custom-programs' (Admin view) is distinct from the 'custom-programs' version (User view)
    if (searchParams?.from === 'admin-custom-programs') {
      navigate(adminProgramsCustomRoute());
    } else if (searchParams?.from === 'user-plan') {
      navigate(UserPaths.ManagePlan);
    } else if (searchParams?.from === 'edit') {
      navigate(
        adminProgramsCustomProgramManage(
          customProgramId,
          (searchParams?.screen as CustomProgramScreens) ||
            CustomProgramScreens.Setup
        )
      );
    } else {
      const onCloseWithLxpOption = () => {
        window.location.href = linkToLxpAcademies;
      };

      const userNavOnClose = linkToLxpAcademies
        ? onCloseWithLxpOption
        : onClose;
      userNavOnClose();
    }
  };

  const showFullPageContent = selectedSection?.isFullScreen;

  return (
    <>
      {/* Show when user tries to access a restricted program */}
      <LearnInPageDrawer
        onClose={handleCloseRestrictAccessScreen}
        visible={restrictAccess}>
        <NoDataText
          header={i18n.t(k.ACCESS__DENIED__PROGRAM__TITLE)}
          subHeader={i18n.t(k.ACCESS__DENIED__DESCRIPTION)}
        />
      </LearnInPageDrawer>
      {/* Custom Program Drawer */}
      <CustomProgramUserDrawerWithRouting
        showFullPageContent={showFullPageContent}
        isActivePlanItem={isActivePlanItem}
        currentScreen={getCurrentScreenFromUrl(pathname)}
        getScreenNavItemUrlPath={getScreenNavItemUrlPath}
        navigate={navigate}
        nextSection={nextSection}
        onChangeUpcomingCohortSelect={handleChangeUpcomingCohort}
        onClickSectionNavItem={handleClickSectionNavItem}
        onClose={handleCloseDrawer}
        parentCustomProgramId={parentCustomProgramId}
        programId={customProgramPreview?.id}
        programTitle={customProgramPreview?.title}
        selectedContentId={selectedContentId}
        selectedSection={selectedSection}
        showAdminNav={!isActivePlanItem}
        showLoadingScreen={isCustomProgramPreviewLoading}
        showProgramHeader={showProgramHeader}
        visible
        showAddToPlanButton={
          isActivePlanItem &&
          isCustomProgramPreviewSuccess &&
          !customProgramPreview?.selected &&
          !isCheckingClickToJoin
        }
        showPlanItemContextMenu={
          isActivePlanItem &&
          isCustomProgramPreviewSuccess &&
          customProgramPreview?.selected
        }
        onClickEditProgram={handleOpenCustomProgramAdminDrawer}
        onClickViewCohorts={() => setShowCohortsPage(true)}
        showCohortButton={showCohortButton && !!cohorts?.length}
      />
      <CustomProgramAdminDrawerContainer
        customProgramId={customProgramId}
        isOverlay
        onClose={handleCloseAdminProgramDrawer}
        visible={showCustomProgramAdminDrawer}
      />
      {!!showCohortsPage && (
        <CustomProgramCohortsPage
          visible={showCohortsPage}
          onClose={() => setShowCohortsPage(false)}
          customProgram={customProgramPreview}
        />
      )}
    </>
  );
}
