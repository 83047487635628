import {ReimbursementStatus} from './../enums';
import {ReimbursementVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ReimbursementVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getReimbursementVMMockData(
  args?: Partial<ReimbursementVM>
): ReimbursementVM {
  return {
    id: faker.datatype.number(),
    requestedOn: String(faker.date.past()),
    status: ReimbursementStatus.NotPaid,
    title: faker.company.catchPhrase(),
    totalRequestedAmount: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ReimbursementVMMockData: ReimbursementVM = {
  id: 1,
  requestedOn: '2023-03-29T22:17:19.738072',
  status: ReimbursementStatus.NotPaid,
  title: 'Advanced Web Design',
  totalRequestedAmount: 5,
};
