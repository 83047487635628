import * as React from 'react';

export const ExternalLinkIcon = ({style}: {style?: React.CSSProperties}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}>
    <path d="M21.8576 22.7109H2.14328C1.66917 22.7109 1.28613 22.3279 1.28613 21.8538V2.13951C1.28613 1.6654 1.66917 1.28236 2.14328 1.28236H11.7861C11.904 1.28236 12.0004 1.37879 12.0004 1.49665V2.99665C12.0004 3.11451 11.904 3.21094 11.7861 3.21094H3.2147V20.7824H20.7861V12.2109C20.7861 12.0931 20.8826 11.9967 21.0004 11.9967H22.5004C22.6183 11.9967 22.7147 12.0931 22.7147 12.2109V21.8538C22.7147 22.3279 22.3317 22.7109 21.8576 22.7109Z" />
    <path d="M18.9222 3.65951L17.524 2.2613C17.4956 2.23283 17.4759 2.19699 17.4669 2.15785C17.4579 2.1187 17.4601 2.07783 17.4733 2.03988C17.4864 2.00193 17.5099 1.96842 17.5411 1.94316C17.5723 1.9179 17.61 1.90191 17.6498 1.89701L22.4552 1.33451C22.5918 1.31844 22.7097 1.43362 22.6936 1.5729L22.1311 6.37826C22.1097 6.55505 21.8927 6.63005 21.7668 6.50415L20.3632 5.10058L13.5007 11.9631C13.4177 12.0461 13.2811 12.0461 13.1981 11.9631L12.0623 10.8274C11.9793 10.7443 11.9793 10.6077 12.0623 10.5247L15.4923 7.0921L18.9222 3.65951Z" />
  </svg>
);
