import {useEffect, useState} from 'react';

/** @returns boolean of on/off state. Only supports booleans. */
export const useParamInSessionStorage = (
  featureFlagValue: boolean,
  sessionItemName: string,
  queryString: string
) => {
  const [param, setParam] = useState(
    sessionStorage.getItem(sessionItemName) === 'true'
  );
  
  useEffect(() => {
    const searchParams = new URLSearchParams(queryString);
    const sessionItemValue = searchParams.get(sessionItemName);

    if (sessionItemValue === 'true' && featureFlagValue === true) {
      sessionStorage.setItem(sessionItemName, 'true');
      setParam(true);
    } else if (sessionItemValue === 'false' || featureFlagValue === false) {
      sessionStorage.removeItem(sessionItemName);
      setParam(false);
    }
  }, [queryString, featureFlagValue, sessionItemName]);

  return param;
};
