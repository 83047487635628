import {Space} from 'antd';
import {H2, H3} from '@components/reusable/Typography';
import {COLORS} from '@utils/constants';

export const ProgramFinderHeadings = ({
  subtitle,
  title,
}: {
  subtitle?: string | JSX.Element;
  title: string | JSX.Element;
}) => (
  <Space direction="vertical" size="small">
    <H2 color={COLORS.Neutral950} fontWeight={500}>
      {title}
    </H2>
    {subtitle && <H3 color={COLORS.Neutral600}>{subtitle}</H3>}
  </Space>
);
