import styled from 'styled-components';
import {Breakpoints} from '@utils/responsiveUtils';
import {
  HEADER_HEIGHT,
  NAV_PANEL_WIDTH,
} from '@components/reusable/LearnInPageDrawer';
import {
  getAcademyBrandingBorderColor,
  getAcademyBrandingBackgroundColor,
} from '@utils/color';
import {AcademyBrandVM} from '@generated/interfaces';

export const ACADEMY_OVERVIEW_SECTION_NAV_WIDTH = 252;

export const Container = styled.div<{branding: AcademyBrandVM; top?: number}>`
  align-items: left;
  background: ${(props) =>
    getAcademyBrandingBackgroundColor(props.branding?.primaryBrandColor, 45)};
  border-right: 1px solid
    ${(props) =>
      getAcademyBrandingBorderColor(props.branding?.itemDefaultTextNavColor)};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  left: ${NAV_PANEL_WIDTH}px;
  max-height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow-y: auto;
  padding: ${({isPreview}) => (isPreview ? '40px' : '4px')} 16px 0 16px;
  position: absolute;
  top: ${({top}) => `${Number.isInteger(top) ? top : HEADER_HEIGHT}px`};
  width: ${ACADEMY_OVERVIEW_SECTION_NAV_WIDTH}px;
  @media (max-width: ${Breakpoints.Desktop}px) {
    background: ${(props) => props.branding?.primaryBrandColor};
    position: static;
    overflow-x: auto;
    width: 100%;
    z-index: 30;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 12px;
  }
`;

export const Heading = styled.p<{itemDefaultTextNavColor: string}>`
  color: ${(props) => props.itemDefaultTextNavColor};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  padding-top: 16px;
  @media (max-width: ${Breakpoints.Desktop}px) {
    display: none;
  }
`;
