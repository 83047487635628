import {notify} from '@components/user/notifications';
import {EMAIL_TEMPLATE_DATA} from './EmailPreview.constants';
import {addQueryParams} from '@store/apiEndpoints';
import {COLORS} from '@utils/constants';
import {simpleQueryFn} from '@store/queryClient';
import {
  EmailPreview,
  PreviewEmailTemplateProps,
} from './EmailPreview.interfaces';

const getEmailPreviewBaseUrl = (template: string) => `emails/${template}`;

const getEmailPreviewTemplate = (searchParams: URLSearchParams) =>
  searchParams.get('template') || '';

export const getEmailPreviewTemplateById = (templateId: string) =>
  EMAIL_TEMPLATE_DATA.find((t) => t.id === templateId);

export const getEmailPreviewUrl = (searchParams: URLSearchParams) => {
  const queryParams = [searchParams?.toString()];
  return addQueryParams('', queryParams);
};

export const getEmailPreviewApiUrl = (
  template: string,
  searchParams: URLSearchParams
) => {
  const queryParams = [searchParams?.toString()];
  return addQueryParams(getEmailPreviewBaseUrl(template), queryParams);
};

export const getEmailPreviewRq = (searchParams: URLSearchParams) => {
  const template = getEmailPreviewTemplate(searchParams);
  const baseUrl = getEmailPreviewBaseUrl(template);
  const queryKey = [baseUrl];
  return {
    path: getEmailPreviewApiUrl(template, searchParams),
    queryKey,
  };
};

export const generateEmailPreview = (
  previewerIframe: HTMLIFrameElement,
  htmlString: string
) => {
  const injectHtml = (_iframeBody: HTMLElement, _htmlString: string) => {
    _iframeBody.innerHTML = _htmlString;
  };

  const injectPreviewStyles = (
    _iframeBody: HTMLElement,
    _htmlString: string
  ) => {
    const extraPreviewStyling = `<style>a, a:visited { color: ${COLORS.Blue800}; }</style>`;
    _iframeBody.innerHTML += extraPreviewStyling;
  };

  const injectForceLinksInNewTab = (header: HTMLElement) => {
    const baseElement = document.createElement('base');
    baseElement.setAttribute('target', '_blank');
    header.appendChild(baseElement);
  };

  const generate = (iframe: HTMLIFrameElement) => {
    const iframeDocument = iframe.contentWindow.document;
    const iframeBody = iframeDocument.body;

    injectHtml(iframeBody, htmlString);
    injectPreviewStyles(iframeBody, htmlString);
    injectForceLinksInNewTab(iframeDocument.getElementsByTagName('head')[0]);
  };

  generate(previewerIframe);
};

export const onGetEmailPreviewSuccess = (data: string) => {
  generateEmailPreview(
    document.getElementById('email-preview') as HTMLIFrameElement,
    data
  );
};

export const onGetEmailPreviewError = (err: unknown) => {
  console.error(err);
  notify.devFailure();
};

export const getHandlerOnTemplateSelected =
  (setSelectedTemplate: (template: EmailPreview) => void) =>
  (templateId: string) => {
    const template = getEmailPreviewTemplateById(templateId);
    setSelectedTemplate(template);
  };

export const previewEmailTemplate = ({
  navigate,
  params,
  setPreviewHtml,
  templateId,
  languagePreference,
}: PreviewEmailTemplateProps) => {
  const paramsWithLocale = {
    ...params,
    languagePreference: languagePreference?.toString(),
  };
  navigate(getEmailPreviewUrl(new URLSearchParams(paramsWithLocale)));
  simpleQueryFn(
    getEmailPreviewApiUrl(templateId, new URLSearchParams(paramsWithLocale)),
    (data: string) => {
      try {
        onGetEmailPreviewSuccess(data);
        setPreviewHtml(data);
      } catch (err) {
        onGetEmailPreviewError(err);
      }
    }
  );
};
