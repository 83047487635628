import {AcademyStepType, SubmissionType} from './../enums';
import {AcademyStepContentPayload} from './../interfaces';
import {
  IAcademyStepBasicInfoMockData,
  getIAcademyStepBasicInfoMockData,
} from './IAcademyStepBasicInfo.mock';
import {LinkPayloadMockData, getLinkPayloadMockData} from './LinkPayload.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademyStepContentPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademyStepContentPayloadMockData(
  args?: Partial<AcademyStepContentPayload>
): AcademyStepContentPayload {
  return {
    ...getIAcademyStepBasicInfoMockData(),
    academyLevelId: faker.datatype.number(),
    allowPeerVisibility: faker.datatype.boolean(),
    allowSkip: faker.datatype.boolean(),
    description: faker.lorem.lines(),
    dueDate: String(faker.date.past()), // optional
    dueDateTimeZone: faker.address.timeZone(), // optional
    dueTime: String(faker.date.past()), // optional
    endDate: String(faker.date.past()), // optional
    endTime: String(faker.date.past()), // optional
    eventLink: faker.internet.url(), // optional
    eventTimeZone: faker.address.timeZone(), // optional
    id: faker.datatype.number(),
    links: [getLinkPayloadMockData()], // optional
    requiredId: faker.datatype.number(), // optional
    startDate: String(faker.date.past()), // optional
    startTime: String(faker.date.past()), // optional
    submissionType: SubmissionType.Text, // optional
    title: faker.company.catchPhrase(),
    type: AcademyStepType.MarketplaceProgram,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademyStepContentPayloadMockData: AcademyStepContentPayload = {
  ...IAcademyStepBasicInfoMockData,
  academyLevelId: 1,
  allowPeerVisibility: true,
  allowSkip: true,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  dueDate: '2023-03-29T22:17:19.738072', // optional
  dueDateTimeZone: 'Asia/Dubai', // optional
  dueTime: '2023-03-29T22:17:19.738072', // optional
  endDate: '2023-03-29T22:17:19.738072', // optional
  endTime: '2023-03-29T22:17:19.738072', // optional
  eventLink: 'https://this-person-does-not-exist.com/en', // optional
  eventTimeZone: 'Asia/Dubai', // optional
  id: 1,
  links: [LinkPayloadMockData], // optional
  requiredId: 1, // optional
  startDate: '2023-03-29T22:17:19.738072', // optional
  startTime: '2023-03-29T22:17:19.738072', // optional
  submissionType: SubmissionType.Text, // optional
  title: 'Advanced Web Design',
  type: AcademyStepType.MarketplaceProgram,
};
