import {i18n, k} from '@i18n/translate';
import {PlanItemType} from '@generated/enums';

export const mapValueToPlanItemType = (value: PlanItemType): string => {
  switch (value) {
    case 0:
      return i18n.t(k.PROGRAM);
    case 1:
      return i18n.t(k.TIME);
    case 2:
      return i18n.t(k.GENERIC__FINANCE);
    default:
      console.warn('Unrecognized plan item type: ', value);
      return '';
  }
};
