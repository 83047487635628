import NoDataText from '@blocks/NoDataText';
import {ReactNode} from 'react';
import * as React from 'react';

export interface Props {
  condition: boolean;
  children: ReactNode;
  emptyHeader?: ReactNode;
  emptyBody: ReactNode | string;
  style?: React.CSSProperties;
}

const EmptyBlockUntilTrue = ({
  condition,
  emptyHeader,
  emptyBody,
  children,
  style,
}: Props) => {
  return condition ? (
    <>{children}</>
  ) : (
    <NoDataText header={emptyHeader} subHeader={emptyBody} style={style} />
  );
};
export default EmptyBlockUntilTrue;
