import {useState, useEffect, useCallback} from 'react';
import debounce from 'lodash/debounce';

const DEBOUNCE_DELAY_MS = 600;

type ViewportSize = {
  height: number;
  width: number;
};

type HookOptions = {
  debounceDelayMs?: number;
};

const defaultOptions: HookOptions = {
  debounceDelayMs: DEBOUNCE_DELAY_MS,
};

/**
 * Subscribes to changes in the size of the browser's viewport
 * and returns updated values
 *
 * @returns {ViewportSize} object that describes the viewport width and height
 */
const useViewportSize = ({debounceDelayMs} = defaultOptions): ViewportSize => {
  const [viewportSize, setViewportSize] = useState<ViewportSize>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const debouncedUpdateSize = useCallback(
    debounce(() => {
      setViewportSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, debounceDelayMs),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', debouncedUpdateSize);

    return () => {
      window.removeEventListener('resize', debouncedUpdateSize);
    };
  }, []);

  return viewportSize;
};

export default useViewportSize;
