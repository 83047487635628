import {DurationUnitType} from './../enums';
import {CustomProgramPayload} from './../interfaces';
import {
  AttachmentPayloadMockData,
  getAttachmentPayloadMockData,
} from './AttachmentPayload.mock';
import {
  CustomProgramSkillTagMockData,
  getCustomProgramSkillTagMockData,
} from './CustomProgramSkillTag.mock';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramPayloadMockData(
  args?: Partial<CustomProgramPayload>
): CustomProgramPayload {
  return {
    id: faker.datatype.number(), // optional
    images: [getAttachmentPayloadMockData()],
    imageUrl: faker.image.imageUrl(),
    isNudgeEmailEnabled: faker.datatype.boolean(),
    isProgramCompletionEmailEnabled: faker.datatype.boolean(),
    isWelcomeEmailEnabled: faker.datatype.boolean(),
    longDescription: faker.lorem.lines(),
    programLength: faker.datatype.number(), // optional
    programLengthUnit: DurationUnitType.Days, // optional
    restrictedAccess: faker.datatype.boolean(),
    shortDescription: faker.lorem.lines(),
    skills: [getCustomProgramSkillTagMockData()],
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramPayloadMockData: CustomProgramPayload = {
  id: 1, // optional
  images: [AttachmentPayloadMockData],
  imageUrl: 'https://loremflickr.com/640/480',
  isNudgeEmailEnabled: true,
  isProgramCompletionEmailEnabled: true,
  isWelcomeEmailEnabled: true,
  longDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  programLength: 123, // optional
  programLengthUnit: DurationUnitType.Days, // optional
  restrictedAccess: true,
  shortDescription: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  skills: [CustomProgramSkillTagMockData],
  title: 'Advanced Web Design',
};
