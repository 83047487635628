import {CurrencyCode} from './../enums';
import {ExchangeRatePayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ExchangeRatePayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getExchangeRatePayloadMockData(
  args?: Partial<ExchangeRatePayload>
): ExchangeRatePayload {
  return {
    currencyCode: CurrencyCode.USD,
    exchangeRate: faker.datatype.number(),
    id: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ExchangeRatePayloadMockData: ExchangeRatePayload = {
  currencyCode: CurrencyCode.USD,
  exchangeRate: 123,
  id: 1,
};
