import {
  useDrag,
  useDrop,
  DragSourceMonitor,
  DropTargetMonitor,
  DragElementWrapper,
  DragSourceOptions,
} from 'react-dnd';

export interface IReorderItem {
  id: number;
  order: number;
}

export interface IUseDragAndDropProps {
  item: IReorderItem;
  reorderItem: () => void;
  setIsDraggingOrder: () => void;
  setReorderIdx: () => void;
}

export interface IUseDragAndDropReturnVal {
  drag: DragElementWrapper<DragSourceOptions>;
  drop: DragElementWrapper<any>;
  isDragging: boolean;
  isOver: boolean;
}

export const useDragAndDrop = ({
  item,
  reorderItem,
  setReorderIdx,
  setIsDraggingOrder,
}: IUseDragAndDropProps) => {
  const [{isDragging}, drag, preview] = useDrag(
    () => ({
      type: 'card',
      item,
      collect: (monitor: DragSourceMonitor) => {
        const isDragging = monitor.isDragging();
        if (isDragging) {
          setIsDraggingOrder();
        }
        return {
          isDragging,
        };
      },
      end: () => {
        reorderItem();
      },
    }),
    [item]
  );

  const [{isOver}, drop] = useDrop(
    () => ({
      accept: 'card',
      canDrop: () => true,
      collect: (monitor: DropTargetMonitor) => {
        const isOver = monitor.isOver();
        if (isOver) {
          setReorderIdx();
        }
        return {
          isOver: monitor.isOver(),
        };
      },
    }),
    [item]
  );

  return {isDragging, drag, drop, isOver, preview};
};
