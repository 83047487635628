// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/UserResourceStatus.cs

enum UserResourceStatus {
  Pending,
  Requested,
  Approved,
  Purchased,
  Rejected,
  Cancelled,
  Complete,
}

export default UserResourceStatus;
