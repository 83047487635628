import CustomProgramAdminDrawerHeader from './CustomProgramAdminDrawerHeader';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {getUpdateCustomProgramStatusAsyncRm} from '@store/apiEndpoints/customProgram/mutations';
import {notify} from '@components/user/notifications';
import {CustomProgramStatus} from '@generated/enums';
import {
  useCustomProgramAdminDetailVMQuery,
  useCustomProgramAdminDetailVMsQuery,
  useGetRestrictedCustomProgramAccessUsersQuery,
} from '@hooks/apiEndpoints/customProgram/queries';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  CustomProgramScreens,
  UserPaths,
  adminProgramsCustomProgramPreview,
  getCompanyProgramPath,
  getManagerPermissionsCustomProgramUserDrawerRoute,
} from '@utils/ClientPaths';
import {} from '@blocks/CustomProgramUserDrawer/CustomProgramUserDrawerWithRouting.container';
import {createCustomProgramScreenUtils} from '@utils/customProgramScreens';
import {useCohortsQuery} from '@generated/hooks';
import {ClickToJoinShareModal} from '@components/ClickToJoin/ClickToJoinShareModal';
import {getCTJCustomProgram} from '@components/ClickToJoin/ClickToJoin.model';
import {useState} from 'react';
import {getFullUrl} from '@components/ClickToJoin/utils';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {useAuth} from '@utils/oidc-auth-wrapper';

const {getCurrentScreenFromUrl} = createCustomProgramScreenUtils(
  CustomProgramScreens.Setup
);

const createCustomProgramPreviewURLGetter = (currentPath: string) => {
  if (currentPath.includes(UserPaths.Permissions)) {
    return getManagerPermissionsCustomProgramUserDrawerRoute;
  } else {
    return adminProgramsCustomProgramPreview;
  }
};
/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramAdminDrawerHeaderContainerProps {
  customProgramId: number;
  cohortId?: number;
  isForCohort?: boolean;
  isOverlay?: boolean;
  closeDrawer?: () => void;
  isWelcomeEmailEnabled?: boolean;
}

function CustomProgramAdminDrawerHeaderContainer({
  cohortId,
  customProgramId,
  isForCohort = false,
  isOverlay = false,
  isWelcomeEmailEnabled,
  closeDrawer,
}: CustomProgramAdminDrawerHeaderContainerProps) {
  const {user} = useAuth();
  const effectiveCustomProgramId = isForCohort ? cohortId : customProgramId;
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const {isFeatureFlagOn} = useFeatureFlags();

  // Get Details for Custom Program or Cohort
  const customProgramDetailsQuery = useCustomProgramAdminDetailVMQuery(
    effectiveCustomProgramId,
    {enabled: !!effectiveCustomProgramId}
  );

  // Used to refresh the data for a Custom Program's Cohorts Table
  const {refetch: refetchCohorts} = useCohortsQuery(
    {
      parentCustomProgramId: customProgramId,
    },
    {
      enabled: !!isForCohort,
    }
  );

  // Used to Refresh Custom Program's Table
  const getCustomProgramsQuery = useCustomProgramAdminDetailVMsQuery({
    enabled: false,
  });
  const restrictedAccessUsersQuery =
    useGetRestrictedCustomProgramAccessUsersQuery(customProgramId, {
      enabled: !!customProgramId,
    });

  const publishCustomProgramMutation = useMutation(
    () => {
      const updateCustomProgramStatusRm = getUpdateCustomProgramStatusAsyncRm({
        id: effectiveCustomProgramId,
        status: CustomProgramStatus.Published,
      });
      return simpleMutationFn<null>(
        updateCustomProgramStatusRm.path,
        updateCustomProgramStatusRm.payload
      );
    },
    {
      onSuccess: async () => {
        notify.programPublishedSuccess();
        await customProgramDetailsQuery.invalidateExact();
        if (isForCohort) {
          await refetchCohorts();
        } else {
          await getCustomProgramsQuery.invalidateExact();
        }
      },
      onError: () => {
        notify.programPublishedError();
      },
    }
  );

  const handleClickShare = () => {
    setIsShareModalVisible(!isShareModalVisible);
  };

  const handleClickPreviewProgram = () => {
    if (!effectiveCustomProgramId) {
      return notify.saveProgramRequired();
    }
    if (!!isOverlay) {
      closeDrawer();
    } else {
      const getPreviewUrl = createCustomProgramPreviewURLGetter(pathname);
      navigate(
        getPreviewUrl(
          effectiveCustomProgramId,
          CustomProgramScreens.Curriculum,
          new URLSearchParams({
            from: 'edit',
            screen: getCurrentScreenFromUrl(pathname),
          })
        )
      );
    }
  };

  const getPreviewWithJoinId = () => {
    const ssoProviderParam = user?.ssoProvider
      ? {
          ssoprovider: user?.ssoProvider, // keep 'ssoprovider' key lowercase
        }
      : null;

    const joinIdParam = {
      joinId: customProgramDetailsQuery.data?.joinId,
    };

    return getFullUrl(getCompanyProgramPath(effectiveCustomProgramId), {
      ...ssoProviderParam,
      ...joinIdParam,
    });
  };

  return (
    <>
      <CustomProgramAdminDrawerHeader
        isWelcomeEmailEnabled={isWelcomeEmailEnabled}
        programUserCount={restrictedAccessUsersQuery?.data?.length || 0}
        showPublishButton={
          customProgramDetailsQuery.data?.status === CustomProgramStatus.Draft
        }
        onClickPublish={async () =>
          await publishCustomProgramMutation.mutateAsync()
        }
        onClickShareProgram={handleClickShare}
        canShowShareableLink={
          isFeatureFlagOn.ShowCustomProgramClickToJoin &&
          customProgramDetailsQuery.data?.status ===
            CustomProgramStatus.Published &&
          !!customProgramDetailsQuery.data?.joinId
        }
        onClickPreviewProgram={handleClickPreviewProgram}
      />
      <ClickToJoinShareModal
        ui={getCTJCustomProgram(isShareModalVisible, getPreviewWithJoinId())}
        onClose={handleClickShare}
      />
    </>
  );
}

export default CustomProgramAdminDrawerHeaderContainer;
