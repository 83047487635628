import {UserFinanceIncentiveUpdateType} from './../enums';
import {AdminActionDetailResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AdminActionDetailResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAdminActionDetailResponseMockData(
  args?: Partial<AdminActionDetailResponse>
): AdminActionDetailResponse {
  return {
    amountAfterUpdate: faker.datatype.number(),
    amountBeforeUpdate: faker.datatype.number(),
    type: UserFinanceIncentiveUpdateType.TotalApproved,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AdminActionDetailResponseMockData: AdminActionDetailResponse = {
  amountAfterUpdate: 5,
  amountBeforeUpdate: 5,
  type: UserFinanceIncentiveUpdateType.TotalApproved,
};
