import {CustomProgramStatus} from './../enums';
import {CustomProgramCohortPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramCohortPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramCohortPayloadMockData(
  args?: Partial<CustomProgramCohortPayload>
): CustomProgramCohortPayload {
  return {
    cohortTitle: faker.company.catchPhrase(),
    endDate: String(faker.date.past()),
    id: faker.datatype.number(), // optional
    isAccessRestricted: faker.datatype.boolean(),
    isNudgeEmailEnabled: faker.datatype.boolean(),
    isProgramCompletionEmailEnabled: faker.datatype.boolean(),
    isWelcomeEmailEnabled: faker.datatype.boolean(),
    parentCustomProgramId: faker.datatype.number(),
    startDate: String(faker.date.past()),
    status: CustomProgramStatus.Draft,
    timezone: faker.address.timeZone(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramCohortPayloadMockData: CustomProgramCohortPayload = {
  cohortTitle: 'Advanced Web Design',
  endDate: '2023-03-29T22:17:19.738072',
  id: 1, // optional
  isAccessRestricted: true,
  isNudgeEmailEnabled: true,
  isProgramCompletionEmailEnabled: true,
  isWelcomeEmailEnabled: true,
  parentCustomProgramId: 1,
  startDate: '2023-03-29T22:17:19.738072',
  status: CustomProgramStatus.Draft,
  timezone: 'Asia/Dubai',
};
