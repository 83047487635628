import './ManageProgram.scss';
import {useCallback, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import PageContentLayout from '../../../../../providers/pageContentLayout/PageContentLayout';
import PageTitleBar from '../../../../../providers/pageTitleBar/PageTitleBar';
import {useLocation} from 'react-router-dom';
import {Col, Row, Skeleton, Typography} from 'antd';
import ProgramItemCard from './components/ProgramItemCard/ProgramItemCard';
import ProgramProgressCard from './components/ProgramProgressCard/ProgramProgressCard';
import {
  FeatureFlagExperiments,
  FeatureFlagTreatments,
} from '@utils/feature-flag-helpers';
import ProgramProgressUpdatesDrawer from './components/ProgramProgressUpdatesDrawer/ProgramProgressUpdatesDrawer';
import {api} from '@store/api';
import ViewProgressReportDrawer from '../../../../../manager/pages/approvals/components/ManagerReports/ViewProgressReportDrawer';
import useTreatment from '@hooks/useTreatment';
import {
  getFinanceIncentiveRq,
  getProgramProgressUpdatesRq,
} from '@store/apiEndpoints';
import {useQuery} from 'react-query';
import {ProgramProgressVM, UserPlanItemCardVM} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useGetCurrencyExchangeQuery} from '@hooks/apiEndpoints/localization/queries';

type Props = Record<string, never>;

const ManageProgram = (props: Props) => {
  const location: any = useLocation();
  const areProgressUpdatesEnabled =
    useTreatment(FeatureFlagExperiments.DisableProgressUpdates) ===
    FeatureFlagTreatments.Off;

  const item = location.state?.item;

  const programId = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );

  const financeIncentiveRq = getFinanceIncentiveRq(programId);
  const financeIncentiveQuery = useQuery<UserPlanItemCardVM>(
    financeIncentiveRq.queryKey,
    () => simpleQueryFn(financeIncentiveRq.path),
    {
      enabled: !!programId,
    }
  );

  const programProgressUpdateRq = getProgramProgressUpdatesRq(programId);
  const programProgressUpdateQuery = useQuery<ProgramProgressVM[]>(
    programProgressUpdateRq.queryKey,
    () => simpleQueryFn(programProgressUpdateRq.path),
    {
      enabled: !!programId,
      placeholderData: () => [],
    }
  );

  const getUserQuery = useGetUserQuery();
  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: !!getUserQuery.data?.currency,
  });

  const [
    showProgramProgressUpdatesDrawer,
    setShowProgramProgressUpdatesDrawer,
  ] = useState(false);
  const [isReadOnlyCardVisible, setIsReadOnlyCardVisible] = useState(false);
  const [currentProgressItem, setCurrentProgressItem] = useState(null);
  const [skills, setSkills] = useState([]);

  const handleShowProgramProgressUpdatesDrawer = (showDrawer: any) => {
    if (showDrawer) {
      api
        .get(`program/get-skills/${programId}`)
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.length
          ) {
            setSkills(response.data);
            setShowProgramProgressUpdatesDrawer(true);
          } else {
            setSkills([]);
            setShowProgramProgressUpdatesDrawer(true);
          }
        })
        .catch((error) => console.log(error));
    } else {
      setShowProgramProgressUpdatesDrawer(false);
    }
  };

  const handleChangeCurrentProgressItem = (currProgressItem: any) => {
    setCurrentProgressItem(currProgressItem);
  };

  const getSkillsString = useCallback(() => {
    if (!currentProgressItem?.skills?.length) {
      return '';
    }

    return currentProgressItem.skills.map((skill: any) => skill.name).join(',');
  }, [currentProgressItem]);

  return (
    <>
      <PageTitleBar title={i18n.t(k.PROGRAM__MANAGE_YOUR)} />
      <PageContentLayout>
        <Row align="middle" justify="center">
          <Col xs={24}>
            <ProgramProgressUpdatesDrawer
              currentPlanId={programId}
              currentProgressItem={currentProgressItem}
              visible={showProgramProgressUpdatesDrawer}
              skills={skills}
              onOk={() => {
                handleShowProgramProgressUpdatesDrawer(false);
              }}
              onCancel={() => {
                handleShowProgramProgressUpdatesDrawer(false);
              }}
              programProgressUpdateSubmit={() => {
                // https://linear.app/learnin/issue/LI-3901/implement-programprogressupdatesubmit
              }}
            />
          </Col>
          {isReadOnlyCardVisible && (
            <Col xs={24}>
              <ViewProgressReportDrawer
                showDrawer={isReadOnlyCardVisible}
                closeDrawer={() => setIsReadOnlyCardVisible(false)}
                id={currentProgressItem.id}
                employeeName={undefined}
                programTitle={currentProgressItem.title}
                rating={currentProgressItem.rating}
                skills={getSkillsString()}
                summary={currentProgressItem.summary}
                submittedDate={currentProgressItem.date}
              />
            </Col>
          )}
        </Row>
        {financeIncentiveQuery.isLoading && (
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Skeleton active />
            </Col>
          </Row>
        )}
        {(item ||
          (financeIncentiveQuery.data && !financeIncentiveQuery.isLoading)) && (
          <>
            <Typography.Title className="card-title" level={4}>
              {i18n.t(k.PROGRAM).toLocaleUpperCase()}
            </Typography.Title>
            <ProgramItemCard
              key={'1'}
              showActions={false}
              exchangeRates={getCurrencyExchangeQuery.data}
              item={item || financeIncentiveQuery.data}
            />
          </>
        )}
        {(item ||
          (programProgressUpdateQuery.data &&
            !programProgressUpdateQuery.isLoading)) &&
          areProgressUpdatesEnabled && (
            <ProgramProgressCard
              handleChangeCurrentProgressItem={handleChangeCurrentProgressItem}
              programProgressData={programProgressUpdateQuery.data}
              handleShowProgramProgressUpdatesDrawer={
                handleShowProgramProgressUpdatesDrawer
              }
              handleSetIsReadOnlyCardVisible={setIsReadOnlyCardVisible}
            />
          )}
      </PageContentLayout>
    </>
  );
};

export default ManageProgram;
