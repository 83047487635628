import './PrepaymentCard.scss';
import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {CreditCardDetailsVM} from '@models/api/payment/viewModels';
import {Card, Col, Divider, Row} from 'antd';
import styled from 'styled-components';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import MaskedCreditCard from '../maskedCreditCard/MaskedCreditCard';
import {openWidget} from '@utils/chat/chat-utils';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {COLORS} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';
import {
  usePersonalizationQuery,
  useCardPaymentInfoQuery,
  useUserQuery,
  useFinanceLearningBudgetsQuery,
} from '@generated/hooks';
import useFeatureFlags from '@hooks/useFeatureFlags';
interface Props {
  creditCard: CreditCardDetailsVM;
}

const BillingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 27.5px;
  @media (max-width: ${Breakpoints.Mobile}px) {
    align-items: flex-start;
    font-size: 0.625rem;
    margin-left: 0px;
    margin-bottom: 20px;
  }
`;

const BillingDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 29px;
  @media (max-width: ${Breakpoints.Mobile}px) {
    margin-bottom: 20px;
  }
`;

const CreditCardContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  flex-shrink: 1.4;
  margin-right: 27.5px;
  @media (max-width: ${Breakpoints.Mobile}px) {
    align-items: flex-start;
  }
`;

const CardInformationTitle = styled(Text)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 0.75rem;
  letter-spacing: 0.1em;
  color: #44444a;
  margin-bottom: 5px;
  text-transform: uppercase;
  @media (max-width: ${Breakpoints.Mobile}px) {
    margin-bottom: 0px;
  }
`;

const CardMessageWrapper = styled.div`
  margin-top: 10px;
  margin-left: 10px;
  line-height: 120%;
`;

const PrepaymentCard = ({creditCard}: Props) => {
  const [learnHowModalOpen, setLearnHowModalOpen] = useState(false);
  const {data: user} = useUserQuery(null);
  const {data: personalization} = usePersonalizationQuery(null);
  const {
    data: cardPaymentInfo,
    isLoading: isCardPaymentInfoLoading,
    isSuccess: isCardPaymentInfoSuccess,
    isError: isCardPaymentInfoError,
    error: cardPaymentInfoError,
  } = useCardPaymentInfoQuery(
    {
      cardId: parseInt(creditCard?.cardId),
    },
    {
      enabled: !!creditCard?.cardId,
    }
  );
  const {data: financeLearningBuds} = useFinanceLearningBudgetsQuery(null);
  const {isFeatureFlagOn} = useFeatureFlags();

  return (
    <Card
      role="article"
      aria-labelledby="prepayment-card-title"
      bordered={false}
      bodyStyle={{padding: '20px 0px 15px 0px', margin: '17px 0px 0px 0px'}}
      style={{borderRadius: '12px', padding: 0}}>
      <LearnInModal
        onCancel={() => setLearnHowModalOpen(false)}
        tag={ModalTags.LicenseAuto}
        width={400}
        footer={
          <LearnInButton
            onClick={() => setLearnHowModalOpen(false)}
            tag={ButtonTags.Primary}>
            {i18n.t(k.CTA__OK)}
          </LearnInButton>
        }
        title={i18n.t(k.CARD__ADD_TO_WALLET)}
        visible={learnHowModalOpen}
        okButtonChild={
          isFeatureFlagOn.YourPlanNameChange
            ? i18n.t(k.HOME__YOUR__GO_TO)
            : i18n.t(k.PLAN__YOUR__GO_TO)
        }
        cancelButtonChild={i18n.t(k.CTA__OK)}>
        <div style={{padding: '0px 23px 23px 23px'}}>
          {i18n.t(k.CARD__APPLE_GOOGLE_INSTRUCTIONS)}
        </div>
      </LearnInModal>
      <>
        <Row>
          <Col span={24}>
            <Title className="prepayment-card-title" id="prepayment-card-title">
              {i18n.t(k.CARD__YOUR_CARD)}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Text className="prepayment-card-title-text">
              {i18n.t(k.CARD__PAY_FOR_PROGRAMS)}
            </Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider />
          </Col>
        </Row>
        <Row justify={'center'} align={'middle'}>
          <Col sm={{span: 24, order: 1}} md={{span: 12, order: 2}}>
            <BillingInfoContainer className="card-information-details">
              <BillingDetailsContainer>
                <CardInformationTitle>
                  {i18n.t(k.CARD__CARD_HOLDER)}
                </CardInformationTitle>
                <Text>{creditCard.cardHolder}</Text>
              </BillingDetailsContainer>

              <BillingDetailsContainer>
                <CardInformationTitle>
                  {i18n.t(k.CARD__BILLING_ADDRESS)}
                </CardInformationTitle>
                <Text>{creditCard.billingAddress}</Text>
                <Text>
                  {creditCard.billingCity}
                  {','} {creditCard.billingState} {creditCard.billingPostalCode}
                </Text>
              </BillingDetailsContainer>
            </BillingInfoContainer>
          </Col>
          <Col sm={{span: 24, order: 2}} md={{span: 12, order: 1}}>
            <CreditCardContainer>
              <div>
                <MaskedCreditCard
                  cardPaymentInfo={cardPaymentInfo}
                  isCardPaymentInfoLoading={isCardPaymentInfoLoading}
                  isCardPaymentInfoSuccess={isCardPaymentInfoSuccess}
                  isCardPaymentInfoError={isCardPaymentInfoError}
                  cardPaymentInfoError={cardPaymentInfoError}
                  currencyCode={user?.currency}
                  virtualCardColor={
                    personalization?.virtualCardColor || COLORS.Red500
                  }
                  approvalRequired={creditCard.approvalRequired}
                  balance={
                    // https://linear.app/learnin/issue/LI-1681/add-error-handling-when-parsing-values-for-credit-card
                    parseFloat(financeLearningBuds?.balance.toFixed(2))
                  }
                  cardId={financeLearningBuds?.creditCard?.cardId?.toString()}
                  last4={financeLearningBuds?.creditCard?.last4}
                />

                <CardMessageWrapper>
                  <Text
                    className="card-details-contact"
                    style={{textAlign: 'left'}}>
                    <div>
                      {i18n.t(k.CARD__HELP_PAY_WITHOUT_CARD)}{' '}
                      <button
                        onClick={() => openWidget()}
                        className="pseudo-link">
                        {i18n.t(k.SUPPORT__CONTACT_US)}
                      </button>
                    </div>
                    <div>
                      {i18n.t(k.CARD__PHYSICAL_STORES)}{' '}
                      <button
                        onClick={() => setLearnHowModalOpen(true)}
                        className="pseudo-link">
                        {i18n.t(k.CTA__LEARN_HOW)}
                      </button>
                    </div>
                  </Text>
                </CardMessageWrapper>
              </div>
            </CreditCardContainer>
          </Col>
        </Row>
      </>
    </Card>
  );
};

export default PrepaymentCard;
