import {useState, useRef, useLayoutEffect, useMemo} from 'react';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const CarouselFrame = styled.div`
  /* Make space for the box shadow so overflow doesn't eat it, then align it back with the container using margin + scroll-padding tricks */
  margin: -8px -16px;
  scroll-padding-left: 16px;
  /* End for box shadow */

  overflow-x: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const CarouselContainer = styled.div`
  width: fit-content;
  padding: 16px;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  transition: 1s transform;
`;

const CarouselItemContainer = styled.div`
  width: fit-content;
  min-height: 200px;
  max-height: 360px;
  padding: 0px 6px;
  &:first-child {
    padding-left: 0px;
  }
  margin-bottom: 4px;
`;

const NavigationDotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const NavigationDot = styled.button`
  background: ${COLORS.Neutral300};
  width: 8px;
  height: 8px;
  margin: 0 3px;
  border-radius: 100%;
  cursor: pointer;
  &hover: {
    background: ${COLORS.Neutral600};
  }
`;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/

const APPROX_PADDING_DISPLACEMENT = 8;

/*
|--------------------------------------------------------------------------
| Util Components
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ICarouselProps {
  items: unknown[];
  carouselItemWidth: number;
  carouselId?: string;
}

function Carousel({carouselItemWidth, items, carouselId}: ICarouselProps) {
  const _carouselId = useMemo(() => carouselId || String(Date.now()), []);
  const [currentPage, setCurrentPage] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [navDotsCount, setNavDotsCount] = useState(0);
  const [itemsVisible, setItemsVisible] = useState(0);
  const frameRef = useRef(null);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    setCarouselWidth(frameRef.current?.clientWidth);
    const itemsVisible = Math.floor(
      frameRef.current.clientWidth / carouselItemWidth
    );
    setItemsVisible(itemsVisible);
    if (items.length > itemsVisible) {
      setNavDotsCount(Math.max(Math.ceil(items.length / itemsVisible), 2));
    }
  }, [items]);

  const navDots = (() => {
    // This try prevents an error when navDotCounts is Infinity (can't have infinity length array)
    // This happens when itemsVisible = 0 temporarily in the useLayoutEffects calculations
    try {
      return Array(navDotsCount).fill(null);
    } catch {
      return [];
    }
  })();

  const handleScrollCarouselFrame = () => {
    const scrollPosition = frameRef?.current?.scrollLeft;
    if (
      scrollPosition >
      items.length * (carouselItemWidth + APPROX_PADDING_DISPLACEMENT) -
        carouselWidth
    ) {
      // This checks if the carousel is scrolled far enough right to activate last navigation dot
      if (currentPage !== navDotsCount - 1)
        return setCurrentPage(navDotsCount - 1);
    } else {
      const _currentPage = Math.floor(
        scrollPosition / (carouselItemWidth * itemsVisible)
      );
      if (_currentPage !== currentPage) setCurrentPage(_currentPage);
    }
  };

  return (
    <>
      <CarouselFrame ref={frameRef} onScroll={handleScrollCarouselFrame}>
        <CarouselContainer carouselWidth={carouselWidth} ref={containerRef}>
          {items.map((item, idx) => {
            return (
              <CarouselItemContainer
                key={`${_carouselId}-${idx}`}
                id={`carousel-item-container-${_carouselId}-${idx}`}>
                {item}
              </CarouselItemContainer>
            );
          })}
        </CarouselContainer>
      </CarouselFrame>
      <NavigationDotsContainer>
        {navDots.map((_, idx) => {
          return (
            <NavigationDot
              key={idx}
              style={{background: idx === currentPage && 'tomato'}}
              onClick={() => {
                const id = idx * itemsVisible;
                const carouselItemContainer = document.getElementById(
                  `carousel-item-container-${_carouselId}-${id}`
                );
                carouselItemContainer.scrollIntoView({
                  behavior: 'smooth',
                  inline: 'start',
                  block: 'nearest',
                });
              }}
            />
          );
        })}
      </NavigationDotsContainer>
    </>
  );
}

export default Carousel;
