import {TagStyles} from '@blocks/LearnInTag';
import {TagColors} from '../Tag.model';

/**
 * Converts legacy tag color to new Apollo tag color
 * @param tagColor - TagStyles
 * @returns TagColor
 */
export function getTagColor(tagColor: TagStyles): TagColors {
  if (tagColor === undefined) {
    return TagColors.LightGrey;
  }
  return TagColors[TagStyles[tagColor]];
}
