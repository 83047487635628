import {COLORS} from '@utils/constants';
import styled from 'styled-components';

const BULLET_POINT_OFFSET = '12px';
const COLOR = COLORS.Neutral200;

export const IndicatorContainer = styled.div`
  position: relative;
  height: 41.8px;
  width: 8px;
  overflow: hidden;
`;

export const CircleIndicator = styled.div`
  height: 5px;
  width: 5px;
  background-color: ${COLOR};
  position: absolute;
  border-radius: 100%;
  top: ${BULLET_POINT_OFFSET};
`;

export const LineInidicator = styled.div`
  width: 1px;
  background-color: ${COLOR};
  position: absolute;
  left: 2px;
`;

export const LineIndicatorFirst = styled(LineInidicator)`
  top: ${BULLET_POINT_OFFSET};
  bottom: 100%;
  height: calc(100% - ${BULLET_POINT_OFFSET});
`;

export const LineIndicatorLast = styled(LineInidicator)`
  top: 0;
  bottom: ${BULLET_POINT_OFFSET};
  height: ${BULLET_POINT_OFFSET};
`;

export const LineIndicatorMiddle = styled(LineInidicator)`
  top: 0;
  bottom: 100%;
  height: 100%;
`;

LineIndicatorFirst.displayName = 'LineIndicatorFirst';
LineIndicatorMiddle.displayName = 'LineIndicatorMiddle';
LineIndicatorLast.displayName = 'LineIndicatorLast';
