// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/RecommendationLevel.cs

enum RecommendationLevel {
  Featured,
  Nominated,
  Initiative,
  Recommended,
  RestrictedAccess,
}

export default RecommendationLevel;
