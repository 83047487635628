import {useMemo} from 'react';
import {Container} from './ReportsBySection.styled';
import LearnInTable, {
  numberSorter,
  renderProgressBar,
  stringSorter,
} from '@components/reusable/LearnInTable';
import {i18n, k} from '@i18n/translate';
import {TableCellButtonLink} from '@components/reusable/TableCellButtonLink/TableCellButtonLink';
import {createStepIndicator} from '../StepIndicator/StepIndicator';
import {ReportsBySectionViewProps} from './ReportsBySection.interfaces';
import {AcademyOverviewLevelReportVm} from '@generated/interfaces';

function ReportsBySectionView({
  dataSource,
  nestedTableDataSource,
  onClickLevelName,
  onClickRowNestedTable,
}: ReportsBySectionViewProps) {
  const columns = useMemo(() => {
    return [
      {
        title: i18n.t(k.SECTION),
        dataIndex: 'academyLevelName',
        key: 'academyLevelName',
        sorter: stringSorter<string>('academyLevelName'),
        width: '75%',
        render: (text: string, row) => (
          <TableCellButtonLink
            onClick={() => {
              onClickLevelName(row);
            }}>
            {text}
          </TableCellButtonLink>
        ),
      },
      {
        title: i18n.t(k.PROGRESS),
        dataIndex: 'completionPercentage',
        key: 'completionPercentage',
        sorter: numberSorter('completionPercentage'),
        render: renderProgressBar,
        width: '25%',
      },
    ];
  }, []);

  const nestedColumns = useMemo(() => {
    return [
      {
        title: i18n.t(k.SECTION),
        dataIndex: 'academyStepName',
        key: 'academyStepName',
        sorter: stringSorter<string>('academyStepName'),
        width: '75%',
        render: (text: string) => (
          <TableCellButtonLink>{text}</TableCellButtonLink>
        ),
      },
      {
        title: i18n.t(k.PROGRESS),
        dataIndex: 'completionPercentage',
        key: 'completionPercentage',
        sorter: numberSorter('completionPercentage'),
        render: renderProgressBar,
        width: '25%',
      },
    ];
  }, []);
  for (const key in nestedTableDataSource) {
    nestedTableDataSource[key] = nestedTableDataSource[key].map((nested) => {
      return {...nested, id: nested.academyStepId};
    });
  }

  return (
    <Container>
      <LearnInTable
        expandRowByClick
        hasContainer
        dataSource={formatDataSource(dataSource)}
        columns={columns}
        nestedTable={{
          dataSource: nestedTableDataSource,
          columns: nestedColumns,
          showHeader: false,
          renderOrnaments: createStepIndicator,
          onClickRow: onClickRowNestedTable,
        }}
      />
    </Container>
  );
}

export default ReportsBySectionView;

/*
|--------------------------------------------------------------------------
| Utils
|--------------------------------------------------------------------------
*/

function formatDataSource(dataSource: AcademyOverviewLevelReportVm[]) {
  return dataSource?.map((data) => ({
    ...data,
    id: data.academyLevelId,
  }));
}
