import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {FormInstance} from 'antd/es/form';
import {Form} from 'antd';
import LearnInSelect, {
  LearnInSelectOption,
} from '@components/reusable/Select/Select.style';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  padding: 0px 16px;
`;

const RemoveButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export enum AddEditRequirementFormNames {
  RequiredId = 'requiredId',
}

export interface AddEditRequirementFormFields {
  [AddEditRequirementFormNames.RequiredId]: number;
}

export interface AddEditRequirementModalProps {
  form: FormInstance<AddEditRequirementFormFields>;
  onCancel: () => void;
  onClickRemove: () => void;
  onOk: () => void;
  options: {id: number; title: string}[];
  showRemoveRequirementButton: boolean;
  title: string;
  visible: boolean;
  selectInputLabel: string;
}

function AddEditRequirementModal({
  form,
  onCancel,
  onClickRemove,
  onOk,
  showRemoveRequirementButton,
  options,
  title,
  visible,
  selectInputLabel,
}: AddEditRequirementModalProps) {
  return (
    <LearnInModal
      title={title}
      tag={ModalTags.ConnectCalendar}
      width={600}
      onCancel={onCancel}
      onOk={onOk}
      visible={visible}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      okButtonChild={i18n.t(k.LEVEL__REQUIREMENT__SAVE)}
      okButtonProps={{style: {marginLeft: '8px'}}}
      footerLeft={
        showRemoveRequirementButton && (
          <RemoveButtonContainer>
            <LearnInButton
              noPadding
              color={COLORS.Red800}
              tag={ButtonTags.DestructiveRemovedBorder}
              onClick={onClickRemove}>
              {i18n.t(k.LEVEL__REQUIREMENT__REMOVE)}
            </LearnInButton>
          </RemoveButtonContainer>
        )
      }>
      <Container>
        <Form form={form} layout="vertical">
          <Form.Item
            name={AddEditRequirementFormNames.RequiredId}
            label={
              <label style={{color: COLORS.Neutral900, fontWeight: 600}}>
                {selectInputLabel}
              </label>
            }>
            <LearnInSelect
              placeholder={i18n.t(k.LEVEL__SEARCH)}
              style={{marginRight: '12px', width: '100%'}}
              showSearch
              allowClear
              filterOption={false}>
              {options?.map(({id, title}) => {
                return (
                  <LearnInSelectOption value={id} key={id}>
                    {title}
                  </LearnInSelectOption>
                );
              })}
            </LearnInSelect>
          </Form.Item>
        </Form>
      </Container>
    </LearnInModal>
  );
}

export default AddEditRequirementModal;
