import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Checkbox, Col, Form, Row} from 'antd';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {useMutation} from 'react-query';
import {NewDevCompany} from '@generated/interfaces';
import {getCompaniesRq, getCreateCompanyRm} from '@store/apiEndpoints';
import {
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
} from '@store/queryClient';
import {notify} from '@components/user/notifications';
import {useState} from 'react';

const Container = styled.div`
  padding-left: 10px;
  padding-top: 10px;
`;

const StyledFormItem = styled(Form.Item)`
  margin: 0;
`;

const StyledSubmitFormItem = styled(Form.Item)`
  margin-top: 24px;
`;

enum Fields {
  Name = 'name',
  IsAcademiesIntegratedExperienceDisabled = 'isAcademiesIntegratedExperienceDisabled',
  IsContentMarketplaceIntegratedExperienceDisabled = 'isContentMarketplaceIntegratedExperienceDisabled',
}

export default function AddCompanyAdminForm() {
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [form] = Form.useForm();

  const addCompanyMutation = useMutation(
    (payload: NewDevCompany) => {
      setIsSubmitButtonLoading(true);
      const devAddCompanyRm = getCreateCompanyRm(payload);
      return simpleMutationFn<null>(
        devAddCompanyRm.path,
        devAddCompanyRm.payload
      );
    },
    {
      onSuccess: async () => {
        form.resetFields();
        setIsSubmitButtonLoading(false);
        await simpleInvalidateExactQueryFn(getCompaniesRq().queryKey);
        notify.addCompanySuccess();
      },
      onError: () => {
        setIsSubmitButtonLoading(false);
        notify.addCompanyError();
      },
    }
  );

  const onFinish = async (values: NewDevCompany) => {
    // invert booleans for the backend since it's isDisabled*
    values.isAcademiesIntegratedExperienceDisabled =
      !values.isAcademiesIntegratedExperienceDisabled;

    values.isContentMarketplaceIntegratedExperienceDisabled =
      !values.isContentMarketplaceIntegratedExperienceDisabled;

    await addCompanyMutation.mutateAsync(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log(errorInfo);
  };

  const doNotTranslate = {
    academiesIntegrationLabel: 'Use Integrated Academies',
    marketplaceIntegrationLabel: 'Use Integrated Content Marketplace',
  };

  return (
    <Container>
      <p>{i18n.t(k.ADMIN__COMPANY__ADD__DESCRIPTION)}</p>
      <Form
        form={form}
        layout={'vertical'}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={Fields.Name}
              label={i18n.t(k.COMPANY__NAME)}
              rules={[
                {
                  required: true,
                  message: i18n.t(k.VALIDATION__MISSING_FIELD__POLITE__FORMAT, {
                    fieldName: i18n.t(k.COMPANY__NAME).toLocaleLowerCase(),
                  }),
                },
              ]}>
              <LearnInInput tag={InputTags.PRIMARY} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={12}>
            <StyledFormItem
              valuePropName="checked"
              name={Fields.IsAcademiesIntegratedExperienceDisabled}>
              <Checkbox>{doNotTranslate.academiesIntegrationLabel}</Checkbox>
            </StyledFormItem>
            <StyledFormItem
              valuePropName="checked"
              name={Fields.IsContentMarketplaceIntegratedExperienceDisabled}>
              <Checkbox>{doNotTranslate.marketplaceIntegrationLabel}</Checkbox>
            </StyledFormItem>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={12}>
            <StyledSubmitFormItem>
              <LearnInButton
                tag={ButtonTags.Primary}
                htmlType="submit"
                loading={isSubmitButtonLoading}>
                {i18n.t(k.CTA__SUBMIT)}
              </LearnInButton>
            </StyledSubmitFormItem>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
