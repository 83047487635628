// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/ApproverType.cs

enum ApproverType {
  Manager,
  Admin,
  AssignedApprover,
}

export default ApproverType;
