// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/RecurrenceType.cs

enum RecurrenceType {
  Daily,
  Weekly,
  Monthly,
  Yearly,
}

export default RecurrenceType;
