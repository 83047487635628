import {CustomizableLearningCategory} from './../enums';
import {
  CustomProgramContentVM,
  LearningResourceApprovalRequest,
  NewUserPlanItem,
  PlanItemNewApprovalRequest,
  PlanProgramStatusUpdate,
  PreApprovalApplication,
  ProgramProgressUpdate,
  ProgramProgressUpdateDetailsVM,
  UserPlanContentRsvpUpdate,
  UserPlanContentStatusUpdate,
  UserPlanItemNewApprovalRequest,
  UserPlanProjectSubmission,
  UserPlanTemplate,
} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {NewUserPlanItemSchema} from '../zodSchemas/NewUserPlanItemSchema.schema';
import {PlanProgramStatusUpdateSchema} from '../zodSchemas/PlanProgramStatusUpdateSchema.schema';
import {UserPlanContentStatusUpdateSchema} from '../zodSchemas/UserPlanContentStatusUpdateSchema.schema';
import {UserPlanContentRsvpUpdateSchema} from '../zodSchemas/UserPlanContentRsvpUpdateSchema.schema';
import {UserPlanProjectSubmissionSchema} from '../zodSchemas/UserPlanProjectSubmissionSchema.schema';
import {ProgramProgressUpdateSchema} from '../zodSchemas/ProgramProgressUpdateSchema.schema';
import {LearningResourceApprovalRequestSchema} from '../zodSchemas/LearningResourceApprovalRequestSchema.schema';
import {PlanItemNewApprovalRequestSchema} from '../zodSchemas/PlanItemNewApprovalRequestSchema.schema';
import {UserPlanItemNewApprovalRequestSchema} from '../zodSchemas/UserPlanItemNewApprovalRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/UserPlan/UserPlanController.cs

/*
|--------------------------------------------------------------------------
|  add-item 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAddItemMutation} from '@generated/hooks';
 *
 *  const addItem = useAddItemMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  addItem.mutate({
 *    payload: {
 *      ...NewUserPlanItem
 *    },
 *  })
 *
 */

export const useAddItemMutation = mutationHookFactory<
  NewUserPlanItem, // payload
  null,
  null
>('useAddItemMutation', 'post', () => `user-plan/add-item`, {
  payloadSchema: NewUserPlanItemSchema,
});

/*
|--------------------------------------------------------------------------
|  complete-resource 
|--------------------------------------------------------------------------
*/

/**
*  @example
*
*  import {useCompleteResourceMutation} from '@generated/hooks';
*
*  const completeResource = useCompleteResourceMutation({
*    onSuccess: () => {},
*  });
*
*  completeResource.mutate({

*  }) 
*
*/

export const useCompleteResourceMutation = mutationHookFactory<
  null,
  null,
  boolean // return type
>('useCompleteResourceMutation', 'post', () => `user-plan/complete-resource`, {
  payloadSchema: undefined,
});

/*
|--------------------------------------------------------------------------
|  request-item-pre-approval 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRequestItemPreApprovalMutation} from '@generated/hooks';
 *
 *  const requestItemPreApproval = useRequestItemPreApprovalMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  requestItemPreApproval.mutate({
 *    payload: {
 *      ...UserPlanItemNewApprovalRequest
 *    },
 *  })
 *
 */

export const useRequestItemPreApprovalMutation = mutationHookFactory<
  UserPlanItemNewApprovalRequest, // payload
  null,
  UserPlanTemplate // return type
>(
  'useRequestItemPreApprovalMutation',
  'post',
  () => `user-plan/request-item-pre-approval`,
  {payloadSchema: UserPlanItemNewApprovalRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  request-pre-approval 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRequestPreApprovalMutation} from '@generated/hooks';
 *
 *  const requestPreApproval = useRequestPreApprovalMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  requestPreApproval.mutate({
 *    payload: {
 *      ...PlanItemNewApprovalRequest
 *    },
 *  })
 *
 */

export const useRequestPreApprovalMutation = mutationHookFactory<
  PlanItemNewApprovalRequest, // payload
  null,
  PreApprovalApplication // return type
>(
  'useRequestPreApprovalMutation',
  'post',
  () => `user-plan/request-pre-approval`,
  {payloadSchema: PlanItemNewApprovalRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  request-resource-approval 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRequestResourceApprovalMutation} from '@generated/hooks';
 *
 *  const requestResourceApproval = useRequestResourceApprovalMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  requestResourceApproval.mutate({
 *    payload: {
 *      ...LearningResourceApprovalRequest
 *    },
 *  })
 *
 */

export const useRequestResourceApprovalMutation = mutationHookFactory<
  LearningResourceApprovalRequest, // payload
  null,
  PreApprovalApplication // return type
>(
  'useRequestResourceApprovalMutation',
  'post',
  () => `user-plan/request-resource-approval`,
  {payloadSchema: LearningResourceApprovalRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  submit-project 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useSubmitProjectMutation} from '@generated/hooks';
 *
 *  const submitProject = useSubmitProjectMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  submitProject.mutate({
 *    payload: {
 *      ...UserPlanProjectSubmission
 *    },
 *  })
 *
 */

export const useSubmitProjectMutation = mutationHookFactory<
  UserPlanProjectSubmission, // payload
  null,
  UserPlanTemplate // return type
>('useSubmitProjectMutation', 'post', () => `user-plan/submit-project`, {
  payloadSchema: UserPlanProjectSubmissionSchema,
});

/*
|--------------------------------------------------------------------------
|  update-custom-program-content-rsvp 
|--------------------------------------------------------------------------
*/

/**
 *
 *  @obsolete Use UpdateCustomProgramContentAsync instead.
 *
 *  @example
 *
 *  import {useUpdateCustomProgramContentRsvpMutation} from '@generated/hooks';
 *
 *  const updateCustomProgramContentRsvp = useUpdateCustomProgramContentRsvpMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateCustomProgramContentRsvp.mutate({
 *    payload: {
 *      ...UserPlanContentRsvpUpdate
 *    },
 *  })
 *
 *
 *  @obsolete Use UpdateCustomProgramContentAsync instead.
 */

export const useUpdateCustomProgramContentRsvpMutation = mutationHookFactory<
  UserPlanContentRsvpUpdate, // payload
  null,
  CustomProgramContentVM // return type
>(
  'useUpdateCustomProgramContentRsvpMutation',
  'post',
  () => `user-plan/update-custom-program-content-rsvp`,
  {payloadSchema: UserPlanContentRsvpUpdateSchema}
);

/*
|--------------------------------------------------------------------------
|  update-program-progress 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateProgramProgressMutation} from '@generated/hooks';
 *
 *  const updateProgramProgress = useUpdateProgramProgressMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateProgramProgress.mutate({
 *    payload: {
 *      ...ProgramProgressUpdate
 *    },
 *  })
 *
 */

export const useUpdateProgramProgressMutation = mutationHookFactory<
  ProgramProgressUpdate, // payload
  null,
  UserPlanTemplate // return type
>(
  'useUpdateProgramProgressMutation',
  'post',
  () => `user-plan/update-program-progress`,
  {payloadSchema: ProgramProgressUpdateSchema}
);

/*
|--------------------------------------------------------------------------
|  update-program-status 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUpdateProgramStatusMutation} from '@generated/hooks';
 *
 *  const updateProgramStatus = useUpdateProgramStatusMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  updateProgramStatus.mutate({
 *    payload: {
 *      ...PlanProgramStatusUpdate
 *    },
 *  })
 *
 */

export const useUpdateProgramStatusMutation = mutationHookFactory<
  PlanProgramStatusUpdate, // payload
  null,
  UserPlanTemplate // return type
>(
  'useUpdateProgramStatusMutation',
  'post',
  () => `user-plan/update-program-status`,
  {payloadSchema: PlanProgramStatusUpdateSchema}
);

/*
|--------------------------------------------------------------------------
|  upload-project-submission/{projectId}/{customizableLearningCategory} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUploadProjectSubmissionMutation} from '@generated/hooks';
 *
 *  const uploadProjectSubmission = useUploadProjectSubmissionMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  uploadProjectSubmission.mutate({
 *    pathVars: {
 *      customizableLearningCategory,
 *      projectId
 *    }
 *  })
 *
 */

export const useUploadProjectSubmissionMutation = mutationHookFactory<
  null,
  {
    customizableLearningCategory: CustomizableLearningCategory;
    projectId: number;
  }, // path variables
  number // return type
>(
  'useUploadProjectSubmissionMutation',
  'post',
  ({projectId, customizableLearningCategory}) =>
    `user-plan/upload-project-submission/${projectId}/${customizableLearningCategory}`,
  {payloadSchema: undefined}
);

/*
|--------------------------------------------------------------------------
|  request-approval/{academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserPlanRequestApprovalMutation} from '@generated/hooks';
 *
 *  const userPlanRequestApproval = useUserPlanRequestApprovalMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  userPlanRequestApproval.mutate({
 *    payload: {
 *      ...PlanItemNewApprovalRequest
 *    },
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 */

export const useUserPlanRequestApprovalMutation = mutationHookFactory<
  PlanItemNewApprovalRequest, // payload
  {academyId: number}, // path variables
  PreApprovalApplication // return type
>(
  'useUserPlanRequestApprovalMutation',
  'post',
  ({academyId}) => `user-plan/request-approval/${academyId}`,
  {payloadSchema: PlanItemNewApprovalRequestSchema}
);

/*
|--------------------------------------------------------------------------
|  update-custom-program-content 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useUserPlanUpdateCustomProgramContentMutation} from '@generated/hooks';
 *
 *  const userPlanUpdateCustomProgramContent = useUserPlanUpdateCustomProgramContentMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  userPlanUpdateCustomProgramContent.mutate({
 *    payload: {
 *      ...UserPlanContentStatusUpdate
 *    },
 *  })
 *
 */

export const useUserPlanUpdateCustomProgramContentMutation =
  mutationHookFactory<
    UserPlanContentStatusUpdate, // payload
    null,
    CustomProgramContentVM // return type
  >(
    'useUserPlanUpdateCustomProgramContentMutation',
    'post',
    () => `user-plan/update-custom-program-content`,
    {payloadSchema: UserPlanContentStatusUpdateSchema}
  );

/*
|--------------------------------------------------------------------------
|  view-program-progress-details/{id} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useViewProgramProgressDetailsMutation} from '@generated/hooks';
 *
 *  const viewProgramProgressDetails = useViewProgramProgressDetailsMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  viewProgramProgressDetails.mutate({
 *    pathVars: {
 *      id
 *    }
 *  })
 *
 *  @access Admin or Manager Access Only
 *
 */

export const useViewProgramProgressDetailsMutation = mutationHookFactory<
  null,
  {id: number}, // path variables
  ProgramProgressUpdateDetailsVM[] // return type
>(
  'useViewProgramProgressDetailsMutation',
  'post',
  ({id}) => `user-plan/view-program-progress-details/${id}`,
  {payloadSchema: undefined}
);
