import {useEffect, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import styled from 'styled-components';
import RestrictedAccessForm from '@components/reusable/RestrictedAccess/RestrictedAccessForm';
import {ModalSubtitle, ModalTitle} from './../setup/CustomProgramSetupScreen';
import {
  ErrorMessage,
  InputLabel,
  LearnInInput,
} from '@components/reusable/LearnInForm';
import {COLORS} from '@utils/constants';
import Grid from '@blocks/Grid';
import {TagsContainer} from '@blocks/LearnInTag';
import moment, {Moment} from 'moment';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/../components/reusable/Button/ButtonEnums';
import {
  DATE_FORMAT,
  disableDatesBefore,
  disableDatesInThePast,
  parseToLocalMoment,
} from '@utils/timeUtils';
import CustomProgramAddedEmployees from '@components/reusable/RestrictedAccess/AddedEmployees/CustomProgramAddedEmployees.container';
import {StyledLearnInDatePicker} from '@components/reusable/LearnInDatePicker';
import EmailNotificationsContainer from '../setup/emailNotifications/EmailNotifications.container';
import {
  FooterButtonContainer,
  FooterContainer,
} from '@components/reusable/Footer/Footer';
import {
  CustomProgramCohortPayload,
  CustomProgramAdminDetailVM,
} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div``;

const SetupDataLabel = styled.div`
  font-weight: 400;
  font-size: 0.75rem;
  color: ${COLORS.Neutral600};
`;

const SetupMetaData = styled.div`
  font-weight: 400;
  font-size: 1rem;
  color: ${COLORS.Neutral950};
`;

const CustomProgramImg = styled.img`
  height: 30px;
  width: 30px;
`;

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

const resetFieldErrors = () => ({
  startDate: '',
  endDate: '',
  restrictedAccess: '',
});

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CohortSetupScreenProps {
  cohort?: CustomProgramAdminDetailVM;
  imageUrl: string | undefined;
  programLength: string;
  shortDescription: string;
  tags: string[];
  title: string;
  loading: boolean;
  onClickSave: (
    args: Omit<
      CustomProgramCohortPayload,
      'parentCustomProgramId' | 'id' | 'status'
    >
  ) => void;
}

function CohortSetupScreen({
  title,
  shortDescription,
  programLength,
  tags,
  imageUrl,
  onClickSave,
  cohort,
  loading,
}: CohortSetupScreenProps) {
  const [_restrictedAccess, setRestrictedAccess] = useState<
    undefined | boolean
  >();
  const [_startDate, setStartDate] = useState<null | string | Moment>(null);
  const [_endDate, setEndDate] = useState<null | string | Moment>(null);
  const [_cohortTitle, setCohortTitle] = useState<string | undefined>('');
  const [fieldErrors, setFieldErrors] = useState(resetFieldErrors());

  const [_isWelcomeEmailEnabled, setIsWelcomeEmailEnabled] = useState(false); // NOTE: This hardcoded value should be be replace when LI-4274 is complete
  const [_isNudgeEmailEnabled, setIsNudgeEmailEnabled] = useState(false); // NOTE: This hardcoded value should be be replace when LI-4274 is complete
  const [_isProgramCompletionEmailEnabled, setIsProgramCompletionEmailEnabled] =
    useState(false); // NOTE: This hardcoded value should be be replace when LI-4274 is complete

  const resetForm = () => {
    setRestrictedAccess(
      typeof cohort?.restrictedAccess === 'boolean'
        ? cohort?.restrictedAccess
        : undefined
    );
    setStartDate(
      !!cohort?.startDateUtc
        ? parseToLocalMoment(cohort.startDateUtc).format(
            DATE_FORMAT.MONTH_DAY_YEAR
          )
        : null
    );
    setEndDate(
      !!cohort?.endDateUtc
        ? parseToLocalMoment(cohort.endDateUtc).format(
            DATE_FORMAT.MONTH_DAY_YEAR
          )
        : null
    );
    setCohortTitle(cohort?.title || '');
    resetFieldErrors();
    setIsWelcomeEmailEnabled(cohort?.isWelcomeEmailEnabled ? true : false);
    setIsNudgeEmailEnabled(cohort?.isNudgeEmailEnabled ? true : false);
    setIsProgramCompletionEmailEnabled(
      cohort?.isProgramCompletionEmailEnabled ? true : false
    );
  };

  useEffect(() => {
    resetForm();
  }, [cohort?.id]);

  const areFormFieldsValid = () => {
    const fieldErrors = resetFieldErrors();
    if (!_startDate)
      fieldErrors.startDate = i18n.t(
        k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
        {
          fieldName: i18n.t(k.DATE__START).toLocaleLowerCase(),
        }
      );
    if (!_endDate)
      fieldErrors.endDate = i18n.t(
        k.VALIDATION__MISSING_FIELD__POLITE__FORMAT,
        {
          fieldName: i18n.t(k.DATE__END).toLocaleLowerCase(),
        }
      );
    if (typeof _restrictedAccess !== 'boolean')
      fieldErrors.restrictedAccess = i18n.t(k.VALIDATION__PRIVACY_SETTING);
    if (Object.values(fieldErrors).some((error) => !!error)) {
      setFieldErrors(fieldErrors);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    // Remove form field errors as user enters valid responses
    if (
      (!!_startDate && !!fieldErrors.startDate) ||
      (!!_endDate && !!fieldErrors.endDate) ||
      (typeof _restrictedAccess === 'boolean' && !!fieldErrors.restrictedAccess)
    ) {
      areFormFieldsValid();
    }
  }, [_startDate, _endDate, _restrictedAccess]);

  const handleClickSave = () => {
    if (!areFormFieldsValid()) return;
    // Clear errors and submit form on successful validation
    setFieldErrors(resetFieldErrors());

    // Format dateTime values to beginning of the day
    const adjustedStartDate = new Date(String(_startDate));
    adjustedStartDate.setHours(0, 0, 0, 0);
    const adjustedEndDate = new Date(String(_endDate));
    adjustedEndDate.setHours(0, 0, 0, 0);
    onClickSave({
      isNudgeEmailEnabled: _isNudgeEmailEnabled,
      isWelcomeEmailEnabled: _isWelcomeEmailEnabled,
      isProgramCompletionEmailEnabled: _isProgramCompletionEmailEnabled,
      cohortTitle: _cohortTitle,
      endDate: adjustedEndDate.toISOString(),
      isAccessRestricted: _restrictedAccess,
      startDate: adjustedStartDate.toISOString(),
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, // https://stackoverflow.com/questions/6939685/get-client-time-zone-from-browser
    });
  };

  return (
    <Container>
      {/* Custom Program Data (Un-editable) */}
      <LearnInContainer
        tag={ContainerTags.CommonCard}
        style={{maxWidth: '928px', marginBottom: '24px', width: '100%'}}>
        <div>
          <ModalTitle>{i18n.t(k.GENERIC__SETUP)}</ModalTitle>
          <ModalSubtitle style={{marginBottom: '24px'}}>
            {i18n.t(k.COHORT__SETUP__METADATA)}
          </ModalSubtitle>
          <Grid.Container>
            <Grid.Box>
              <SetupDataLabel>{i18n.t(k.PROGRAM__NAME)}</SetupDataLabel>
              <SetupMetaData>{title}</SetupMetaData>
            </Grid.Box>
            <Grid.Box>
              <SetupDataLabel>
                {i18n.t(k.GENERIC__DESCRIPTION__SHORT)}
              </SetupDataLabel>
              <SetupMetaData>{shortDescription}</SetupMetaData>
            </Grid.Box>
            <Grid.Box half>
              <SetupDataLabel>{i18n.t(k.PROGRAM__LENGTH)}</SetupDataLabel>
              <SetupMetaData>{programLength}</SetupMetaData>
            </Grid.Box>
            <Grid.Box half>
              <SetupDataLabel>{i18n.t(k.TAG__PLURAL)}</SetupDataLabel>
              <SetupMetaData>
                {!!tags.length ? (
                  <TagsContainer tags={tags} />
                ) : (
                  <SetupMetaData>
                    {i18n.t(k.TAG__NONE_FOR_PROGRAM)}
                  </SetupMetaData>
                )}
              </SetupMetaData>
            </Grid.Box>
            {!!imageUrl && (
              <Grid.Box>
                <SetupDataLabel>{i18n.t(k.GENERIC__IMAGE)}</SetupDataLabel>
                <CustomProgramImg src={imageUrl} />
              </Grid.Box>
            )}
          </Grid.Container>
        </div>
      </LearnInContainer>

      {/* Cohort Data (Editable) */}
      <LearnInContainer
        tag={ContainerTags.CommonCard}
        style={{
          maxWidth: '928px',
          marginBottom: '24px',
          width: '100%',
          display: 'block',
        }}>
        <div>
          <ModalTitle>{i18n.t(k.COHORT__SETTINGS)}</ModalTitle>
          <div style={{display: 'flex', marginTop: '24px'}}>
            <div>
              <InputLabel
                htmlFor="start-date-input"
                required
                label={i18n.t(k.COHORT__DATE__START)}
              />
              <div style={{display: 'flex', alignItems: 'center'}}>
                <StyledLearnInDatePicker
                  id="start-date-input"
                  format={DATE_FORMAT.MONTH_DAY_YEAR}
                  onChange={setStartDate}
                  value={
                    !!_startDate
                      ? moment(_startDate, DATE_FORMAT.MONTH_DAY_YEAR)
                      : null
                  }
                  disabledDate={disableDatesInThePast}
                />{' '}
                <span style={{margin: '0px 14px'}}>-</span>
              </div>
              <ErrorMessage message={fieldErrors.startDate} />
            </div>
            <div>
              <InputLabel
                htmlFor="end-date-input"
                required
                label={i18n.t(k.COHORT__DATE__END)}
              />
              <StyledLearnInDatePicker
                id="end-date-input"
                format={DATE_FORMAT.MONTH_DAY_YEAR}
                onChange={setEndDate}
                value={
                  !!_endDate
                    ? moment(_endDate, DATE_FORMAT.MONTH_DAY_YEAR)
                    : null
                }
                disabledDate={disableDatesBefore(_startDate || new Date())}
              />
              <ErrorMessage message={fieldErrors.endDate} />
            </div>
          </div>
        </div>
        <div style={{marginTop: 16, maxWidth: 480}}>
          <InputLabel
            htmlFor="cohort-title-input"
            label={i18n.t(k.COHORT__TITLE)}
          />
          <LearnInInput updateFunc={setCohortTitle} value={_cohortTitle} />
        </div>
      </LearnInContainer>

      <LearnInContainer
        tag={ContainerTags.CommonCard}
        style={{
          maxWidth: '928px',
          marginBottom: '24px',
          width: '100%',
          display: 'block',
        }}>
        <div>
          <ModalTitle>{i18n.t(k.GENERIC__PRIVACY_SETTINGS)}</ModalTitle>
          <ModalSubtitle style={{marginBottom: '24px'}}>
            {i18n.t(k.COHORT__PARTICIPANTS)}
          </ModalSubtitle>
          <RestrictedAccessForm
            restrictedAccess={_restrictedAccess}
            setRestrictedAccess={setRestrictedAccess}
          />
          <ErrorMessage message={fieldErrors.restrictedAccess} />
        </div>
        {_restrictedAccess === true && (
          <div>
            <CustomProgramAddedEmployees customProgramId={cohort?.id} />
          </div>
        )}
      </LearnInContainer>
      <div
        style={{
          marginBottom: '36px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}>
        <EmailNotificationsContainer
          isWelcomeEmailEnabled={_isWelcomeEmailEnabled}
          onChangeWelcomeEmail={() => setIsWelcomeEmailEnabled((prev) => !prev)}
          customProgramId={cohort?.id}
          isNudgeEmailEnabled={_isNudgeEmailEnabled}
          onChangeNudgeEmail={() => setIsNudgeEmailEnabled((prev) => !prev)}
          isProgramCompletionEmailEnabled={_isProgramCompletionEmailEnabled}
          onChangeCompletionEmail={() =>
            setIsProgramCompletionEmailEnabled((prev) => !prev)
          }
        />
      </div>
      <FooterContainer>
        <FooterButtonContainer>
          <LearnInButton
            loading={loading}
            tag={ButtonTags.Primary}
            onClick={handleClickSave}>
            {i18n.t(k.CTA__SAVE)}
          </LearnInButton>
        </FooterButtonContainer>
      </FooterContainer>
    </Container>
  );
}

export default CohortSetupScreen;
