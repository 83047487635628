import {ProgramNotificationType} from './../enums';
import {CustomProgramEmailMessagePayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: CustomProgramEmailMessagePayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getCustomProgramEmailMessagePayloadMockData(
  args?: Partial<CustomProgramEmailMessagePayload>
): CustomProgramEmailMessagePayload {
  return {
    customProgramId: faker.datatype.number(),
    message: faker.lorem.lines(),
    type: ProgramNotificationType.Welcome,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const CustomProgramEmailMessagePayloadMockData: CustomProgramEmailMessagePayload =
  {
    customProgramId: 1,
    message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    type: ProgramNotificationType.Welcome,
  };
