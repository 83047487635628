import {i18n, k} from '@i18n/translate';
import {
  ProgramFinderButtonType,
  trackProgramFinderWelcomeModalView,
} from '@utils/analytics';
import {
  ProgramFinderPath,
  programFinderPath,
} from '@components/ProgramType/programFinderHelpers';
import {FormInstance} from 'antd';

interface TrackViewProps {
  buttonType: ProgramFinderButtonType;
  form: FormInstance;
  selectedPath: ProgramFinderPath;
  currentPath;
  step: number;
}

export const trackView = ({
  buttonType,
  form,
  selectedPath,
  currentPath,
  step,
}: TrackViewProps) => {
  const stepToField = {
    [i18n.t(k.PROGRAM_FINDER__SKILLS)]: 'skills',
    [i18n.t(k.PROGRAM__PLURAL)]: 'skills',
    [i18n.t(k.PROGRAM_FINDER__PROGRAM_TYPE)]: 'programType',
    [i18n.t(k.PROGRAM_FINDER__BUDGET)]: ['min', 'max'],
  };
  const path = programFinderPath.find(({key: path}) => path === selectedPath);
  const currentStep = currentPath[step];
  const fieldMapping = stepToField[currentStep?.title];
  let fieldValue;

  if (Array.isArray(fieldMapping))
    fieldValue = fieldMapping.map((field) => form.getFieldsValue()[field]);
  else fieldValue = form.getFieldsValue()[fieldMapping];

  trackProgramFinderWelcomeModalView(
    {
      programFinder: {
        path,
        buttonType,
        currentScreen: currentStep?.title,
        selectedCriteria:
          buttonType !== ProgramFinderButtonType.Skip && fieldValue,
      },
    },
    {send_immediately: true}
  );
};
