import {ResourceSkillType} from './../enums';
import {AddSkillTagPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AddSkillTagPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAddSkillTagPayloadMockData(
  args?: Partial<AddSkillTagPayload>
): AddSkillTagPayload {
  return {
    entityId: faker.datatype.number(),
    entityType: ResourceSkillType.Academy,
    lxpInputId: faker.datatype.number(),
    name: faker.name.fullName(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AddSkillTagPayloadMockData: AddSkillTagPayload = {
  entityId: 1,
  entityType: ResourceSkillType.Academy,
  lxpInputId: 1,
  name: 'Bobby Johnson',
  title: 'Advanced Web Design',
};
