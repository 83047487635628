import {ReimbursementPreviewVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ReimbursementPreviewVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getReimbursementPreviewVMMockData(
  args?: Partial<ReimbursementPreviewVM>
): ReimbursementPreviewVM {
  return {
    amount: faker.datatype.number(),
    approvalStatus: faker.word.noun(),
    date: String(faker.date.past()),
    id: faker.datatype.number(),
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ReimbursementPreviewVMMockData: ReimbursementPreviewVM = {
  amount: 5,
  approvalStatus: 'string',
  date: '2023-03-29T22:17:19.738072',
  id: 1,
  title: 'Advanced Web Design',
};
