import styled from 'styled-components';
import {COLORS} from '@utils/constants';

export const EventFooterCTAsContent = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

export const AcademyEventFooterLink = styled.a`
  &:focus-visible {
    outline: 2px solid ${COLORS.Blue950};
    outline-offset: 2px;
  }
  color: ${COLORS.Blue800};
  font-weight: 400;
  font-size: 1rem;
`;

export const Divider = styled.div`
  line-height: 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  margin: 0 8px;
  color: ${COLORS.Neutral600};
`;
