import {Form} from 'antd';
import {i18n, k} from '@i18n/translate';
import {TextAreaTags} from '@components/reusable/TextArea/TextAreaEnums';
import {LearnInTextArea} from '@components/reusable/TextArea/TextArea.style';

export const ApproverNotes = ({
  isNoteRequired,
  isReadonly,
}: {
  isNoteRequired: boolean;
  isReadonly: boolean;
}) => {
  return (
    !isReadonly && (
      <Form.Item
        rules={[
          {
            required: isNoteRequired,
            message: i18n.t(k.APPROVAL__NOTE_REQUIRED_ON_DECLINE),
          },
        ]}
        className="lb-application-drawer"
        name="approverNotes"
        label={i18n.t(k.NOTE__FOR_EMPLOYEE)}>
        <LearnInTextArea tag={TextAreaTags.PRIMARY} maxLength={500} />
      </Form.Item>
    )
  );
};
