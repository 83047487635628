import {
  BalanceRecurType,
  FinanceAmountType,
  FinanceRecurrence,
  LearningResourceType,
} from './../enums';
import {FinanceDetailsVM} from './../interfaces';
import {DetailsVMMockData, getDetailsVMMockData} from './DetailsVM.mock';
import {faker} from '@faker-js/faker';

// Interface: FinanceDetailsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getFinanceDetailsVMMockData(
  args?: Partial<FinanceDetailsVM>
): FinanceDetailsVM {
  return {
    ...getDetailsVMMockData(),
    allowApprovedItemsCancellation: faker.datatype.boolean(),
    allowedLearningResources: [LearningResourceType.Book],
    amount: faker.datatype.number(),
    amountType: FinanceAmountType.UpTo,
    applicationUrl: faker.internet.url(), // optional
    approvalExpirationInDays: faker.datatype.number(), // optional
    balanceRecurType: BalanceRecurType.NotApplicable,
    financeType: faker.word.noun(),
    isProratingEnabled: faker.datatype.boolean(),
    recurrence: FinanceRecurrence.Never,
    recurring: faker.datatype.boolean(),
    renewalMonth: faker.word.noun(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const FinanceDetailsVMMockData: FinanceDetailsVM = {
  ...DetailsVMMockData,
  allowApprovedItemsCancellation: true,
  allowedLearningResources: [LearningResourceType.Book],
  amount: 5,
  amountType: FinanceAmountType.UpTo,
  applicationUrl: 'https://this-person-does-not-exist.com/en', // optional
  approvalExpirationInDays: 123, // optional
  balanceRecurType: BalanceRecurType.NotApplicable,
  financeType: 'string',
  isProratingEnabled: true,
  recurrence: FinanceRecurrence.Never,
  recurring: true,
  renewalMonth: 'string', // optional
};
