import {CurrencyCode} from './../enums';
import {ProgramUsageResult} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: ProgramUsageResult

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramUsageResultMockData(
  args?: Partial<ProgramUsageResult>
): ProgramUsageResult {
  return {
    applicationUrl: faker.internet.url(),
    cost: faker.datatype.number(),
    costCenter: faker.word.noun(),
    currency: CurrencyCode.USD,
    employeeName: faker.name.fullName(),
    isInternalProgram: faker.datatype.boolean(),
    planItemId: faker.datatype.number(),
    programId: faker.datatype.number(),
    programLength: faker.word.noun(),
    programType: faker.word.noun(),
    requestedAmount: faker.datatype.number(),
    startDate: String(faker.date.past()), // optional
    status: faker.word.noun(),
    title: faker.company.catchPhrase(),
    userCompanyId: faker.datatype.number(),
    userRequestId: faker.datatype.number(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramUsageResultMockData: ProgramUsageResult = {
  applicationUrl: 'https://this-person-does-not-exist.com/en',
  cost: 123,
  costCenter: 'string',
  currency: CurrencyCode.USD,
  employeeName: 'Bobby Johnson',
  isInternalProgram: true,
  planItemId: 1,
  programId: 1,
  programLength: 'string',
  programType: 'string',
  requestedAmount: 5,
  startDate: '2023-03-29T22:17:19.738072', // optional
  status: 'string',
  title: 'Advanced Web Design',
  userCompanyId: 1,
  userRequestId: 1, // optional
};
