import {ApprovalStatus} from './../enums';
import {TimeApprovalPayload} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: TimeApprovalPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getTimeApprovalPayloadMockData(
  args?: Partial<TimeApprovalPayload>
): TimeApprovalPayload {
  return {
    status: ApprovalStatus.Pending, // optional
    userPlanItemId: faker.datatype.number(), // optional
    userTimeIncentiveId: faker.datatype.number(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const TimeApprovalPayloadMockData: TimeApprovalPayload = {
  status: ApprovalStatus.Pending, // optional
  userPlanItemId: 1, // optional
  userTimeIncentiveId: 1, // optional
};
