import {DurationUnitType, TimeUnitType} from './../enums';
import {TimeIncentivePayload} from './../interfaces';
import {
  IncentivePayloadMockData,
  getIncentivePayloadMockData,
} from './IncentivePayload.mock';
import {faker} from '@faker-js/faker';

// Interface: TimeIncentivePayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getTimeIncentivePayloadMockData(
  args?: Partial<TimeIncentivePayload>
): TimeIncentivePayload {
  return {
    ...getIncentivePayloadMockData(),
    perUnit: faker.datatype.number(),
    perUnitType: DurationUnitType.Days,
    unit: faker.datatype.number(),
    unitType: TimeUnitType.Hours,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const TimeIncentivePayloadMockData: TimeIncentivePayload = {
  ...IncentivePayloadMockData,
  perUnit: 123,
  perUnitType: DurationUnitType.Days,
  unit: 123,
  unitType: TimeUnitType.Hours,
};
