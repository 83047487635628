import {i18n, k} from '@i18n/translate';
import styled, {css} from 'styled-components';
import ContextMenuButton from '@blocks/ContextMenu';
import {MenuOutlined} from '@ant-design/icons';
import {useDragAndDrop} from '@hooks/useDragAndDrop';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.button<{
  active: boolean;
  isDraggingContentHigher: boolean;
  isDraggingContentLower: boolean;
  isOver: boolean;
}>`
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 9px 16px;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  color: ${COLORS.Neutral900};
  background: ${({active}) => (active ? COLORS.Blue50 : 'white')};
  min-height: 42px;
  width: 100%;
  .context-menu-container {
    display: none;
  }
  .draggable-indicator {
    display: none;
  }
  &:hover {
    background: ${({active}) => (active ? COLORS.Blue50 : COLORS.Neutral50)};
    .context-menu-container {
      display: block;
    }
    .draggable-indicator {
      display: inline-block;
    }
    .order-number-display {
      display: none;
    }
  }
  ${({isOver, isDraggingContentHigher, isDraggingContentLower}) => {
    if (isOver) {
      if (isDraggingContentHigher) {
        return css`
          border-top: 4px solid ${COLORS.Blue200};
        `;
      }
      if (isDraggingContentLower) {
        return css`
          border-bottom: 4px solid ${COLORS.Blue200};
        `;
      }
    }
  }}
`;

/*
|--------------------------------------------------------------------------
| Nav Item Component
|--------------------------------------------------------------------------
*/

interface ICustomProgramSectionNavItemProps {
  id: number;
  order: number;
  title: string;
  onClick: () => void;
  active: boolean;
  onClickDelete?: (e: any) => void;
  onClickMoveToTop?: (e: any) => void;
  onClickMoveToBottom?: (e: any) => void;
  onClickRenameSection?: (e: any) => void;
  reorderSectionItem: () => void;
  setSectionReorderIdx: () => void;
  setIsDraggingSectionOrder: () => void;
  isDraggingSectionOrder: number | null;
}

function CustomProgramSectionNavItem({
  id,
  title,
  order,
  onClick,
  active,
  onClickDelete,
  onClickMoveToTop,
  onClickMoveToBottom,
  onClickRenameSection,
  reorderSectionItem,
  setSectionReorderIdx,
  setIsDraggingSectionOrder,
  isDraggingSectionOrder,
}: ICustomProgramSectionNavItemProps) {
  const {drag, drop, isOver} = useDragAndDrop({
    item: {id, order},
    reorderItem: reorderSectionItem,
    setReorderIdx: setSectionReorderIdx,
    setIsDraggingOrder: setIsDraggingSectionOrder,
  });
  const isDraggingContentHigher = isDraggingSectionOrder > order;
  const isDraggingContentLower = isDraggingSectionOrder < order;
  return (
    <Container
      onClick={onClick}
      role="button"
      // This has a role="button" so is an interactive element: false flag
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      active={active}
      ref={(node) => drag(drop(node))}
      isOver={isOver}
      isDraggingContentHigher={isDraggingContentHigher}
      isDraggingContentLower={isDraggingContentLower}>
      <div style={{display: 'flex'}}>
        <div
          style={{marginRight: '4px', minWidth: '20px', width: 'fit-content'}}>
          <span className="order-number-display">{order}.</span>
          <span
            className="draggable-indicator"
            aria-label={i18n.t(k.CTA__DRAG_AND_DROP)}>
            <MenuOutlined aria-hidden="true" />
          </span>
        </div>
        {title}
      </div>
      <div
        // tabIndex was required by audit item LI-5285
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className="context-menu-container">
        <ContextMenuButton
          menuItems={[
            {
              label: i18n.t(k.SECTION__RENAME),
              onClick: onClickRenameSection,
            },
            {
              label: i18n.t(k.SECTION__TO_BOTTOM),
              noBottomBorder: true,
              onClick: onClickMoveToBottom,
            },
            {
              label: i18n.t(k.SECTION__TO_TOP),

              onClick: onClickMoveToTop,
            },
            {
              label: (
                <span style={{color: COLORS.Red800}}>
                  {i18n.t(k.SECTION__DELETE)}
                </span>
              ),
              onClick: onClickDelete,
            },
          ]}
        />
      </div>
    </Container>
  );
}

export default CustomProgramSectionNavItem;
