import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {
  COLORS,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
  SECTIONS_NAV_WIDTH,
  SIDE_NAV_WIDTH,
} from '@utils/constants';
import styled from 'styled-components';
import {FormInstance} from 'antd/lib/form';
import {Collapse, Form} from 'antd';
import SideColorSelector from './SideColorSelector';
import ColorInputField from './ColorInputField';
import {DownOutlined} from '@ant-design/icons';

const {Panel} = Collapse;

const Container = styled.div`
  background: white;
  width: ${() => SECTIONS_NAV_WIDTH + 88}px;
  position: absolute;
  top: ${() => HEADER_HEIGHT}px;
  left: ${() => SIDE_NAV_WIDTH}px;
  bottom: ${() => FOOTER_HEIGHT + HEADER_HEIGHT}px;
  border-right: 1px solid ${() => COLORS.Neutral200};
  z-index: 80;
  overflow-y: auto;
  height: calc(100% - ${() => HEADER_HEIGHT}px);
`;

const SectionsContainer = styled.div`
  padding: 16px 16px 72px;
  display: flex;
  flex-direction: Column;
`;

const Title = styled.h1`
  font-size: 1.25rem;
  font-weight: 500;
  color: ${COLORS.Neutral900};
  margin-bottom: 4px;
`;

const SubTitle = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  color: ${COLORS.Neutral600};
  margin-bottom: 16px;
  padding: 0;
`;

const CollapsedContainer = styled(Collapse)`
  .ant-collapse-item {
    display: flex;
    flex-direction: column;
  }
`;

const CollapsedPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 0 !important;
    color: ${COLORS.Blue800} !important;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
  }
`;

export interface SideColorSelectorContainerProps {
  form?: FormInstance;
  handleChange?: (e) => void;
}

function SideColorSelectorContainer({
  form,
  handleChange,
}: SideColorSelectorContainerProps) {
  const [show, setShow] = useState(false);
  return (
    <Container>
      <SectionsContainer>
        <Title>{i18n.t(k.BRAND__CREATE_A_CUSTOM_THEME)}</Title>
        <SubTitle>{i18n.t(k.BRAND__ADD_YOUR_COLORS)}</SubTitle>
        <Form
          form={form}
          layout="vertical"
          name="brandSelector"
          onChange={handleChange}
          requiredMark={false}>
          <SideColorSelector groupTitle={i18n.t(k.BRAND__YOUR)}>
            <ColorInputField
              handleChange={handleChange}
              name="primaryBrandColor"
              inputLabel={i18n.t(k.BRAND__PRIMARY)}
            />
            <ColorInputField
              handleChange={handleChange}
              name="secondaryBrandColor"
              inputLabel={i18n.t(k.BRAND__SECONDARY)}
            />
          </SideColorSelector>

          <CollapsedContainer
            ghost
            expandIconPosition="end"
            expandIcon={({isActive}) => (
              <span
                aria-label={
                  isActive ? i18n.t(k.CTA__HIDE) : i18n.t(k.CTA__SHOW)
                }>
                <DownOutlined rotate={isActive ? -180 : 0} />
              </span>
            )}
            onChange={() => setShow(!show)}>
            <CollapsedPanel
              style={{flexDirection: 'column-reverse'}}
              header={`${i18n.t(show ? k.CTA__HIDE : k.CTA__SHOW)} ${i18n.t(
                k.SETTING__ADVANCED__PLURAL
              )}`}
              key="1">
              <SideColorSelector groupTitle={i18n.t(k.GENERIC__NAVIGATION)}>
                <ColorInputField
                  handleChange={handleChange}
                  name="backgroundColor"
                  inputLabel={i18n.t(k.BRAND__BG)}
                />
                <ColorInputField
                  handleChange={handleChange}
                  name="itemDefaultTextNavColor"
                  inputLabel={i18n.t(k.BRAND__DEFAULT_TEXT)}
                />
                <ColorInputField
                  handleChange={handleChange}
                  name="itemHoverBackgroundNavColor"
                  inputLabel={i18n.t(k.BRAND__HOVER_BG)}
                />

                <ColorInputField
                  handleChange={handleChange}
                  name="itemSelectedTextNavColor"
                  inputLabel={i18n.t(k.BRAND__SELECTED_TEXT)}
                />

                <ColorInputField
                  handleChange={handleChange}
                  name="itemSelectedBackgroundNavColor"
                  inputLabel={i18n.t(k.BRAND__SELECTED_BG)}
                />
              </SideColorSelector>
              <SideColorSelector groupTitle={i18n.t(k.BRAND__WELCOME_SCREEN)}>
                <ColorInputField
                  handleChange={handleChange}
                  name="buttonDefaultWelcomeColor"
                  inputLabel={i18n.t(k.BRAND__BUTTON_DEFAULT_COLOR)}
                />
                <ColorInputField
                  handleChange={handleChange}
                  name="buttonHoverWelcomeColor"
                  inputLabel={i18n.t(k.BRAND__WELCOME_BUTTON_HOVER_COLOR)}
                />
                <ColorInputField
                  handleChange={handleChange}
                  name="backgroundWelcomeColor"
                  inputLabel={i18n.t(k.BRAND__BG_COLOR)}
                />
                <ColorInputField
                  handleChange={handleChange}
                  name="itemDefaultTextWelcomeColor"
                  inputLabel={i18n.t(k.BRAND__ITEM_DEFAULT_TEXT)}
                />
              </SideColorSelector>
            </CollapsedPanel>
          </CollapsedContainer>
        </Form>
      </SectionsContainer>
    </Container>
  );
}

export default SideColorSelectorContainer;
