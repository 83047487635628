// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/DisplayStatus.cs

enum DisplayStatus {
  Approved,
  Declined,
  AwaitingApproval,
  Completed,
  MarkedAsCompleted,
  Cancelled,
  Skipped,
  RsvpedYes,
  InProgress,
  NotStarted,
  Unknown,
  RsvpedNo,
  RsvpedNone,
  FundsRequested,
  NotCompleted,
  VerifiedCompleted,
}

export default DisplayStatus;
