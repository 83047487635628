import {useState} from 'react';
import {StyledLearnInMoneyInput} from './Budget.styled';
import {BudgetViewProps} from './Budget.interfaces';
import {H3} from '../reusable/Typography';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {parseNumberFromCurrency} from '@utils/moneyUtils';
import {Form, Space} from 'antd';
import {MinusOutlined} from '@ant-design/icons';
import {ProgramFinderHeadings} from '@pages/ProgramFinder/ProgramFinderHeadings';

function BudgetView({
  formattedCurrency,
  form,
  maxRequestAmount,
  handleAction,
}: BudgetViewProps) {
  const [isOverBudget, setIsOverBudget] = useState(false);

  return (
    <Space direction="vertical" size="large">
      <ProgramFinderHeadings
        title={i18n.t(k.PROGRAM_FINDER__BUDGET__TITLE)}
        subtitle={i18n.t(k.PROGRAM_FINDER__BUDGET__SUBTITLE__FORMAT, {
          budget: formattedCurrency(maxRequestAmount),
        })}
      />
      <Space size="small">
        <Form.Item name="min">
          <StyledLearnInMoneyInput
            formatter={formattedCurrency}
            parser={parseNumberFromCurrency}
            size="large"
            bordered={false}
            onChange={handleAction}
          />
        </Form.Item>
        <MinusOutlined aria-hidden="true" />
        <Form.Item initialValue={maxRequestAmount} name="max">
          <StyledLearnInMoneyInput
            defaultValue={maxRequestAmount}
            onChange={(value) => {
              handleAction(value);
              setIsOverBudget(value > maxRequestAmount);
              form.setFieldsValue({max: value});
            }}
            formatter={formattedCurrency}
            parser={parseNumberFromCurrency}
            size="large"
            bordered={false}
            min={form.getFieldValue('min') || 0}
          />
        </Form.Item>
      </Space>
      {isOverBudget && (
        <H3 data-testid="over-budget-message" color={COLORS.Neutral600}>
          {i18n.t(k.PROGRAM_FINDER__BUDGET__OVER_BUDGET__FORMAT, {
            amount: formattedCurrency(
              form.getFieldValue('max') - maxRequestAmount
            ),
          })}
        </H3>
      )}
    </Space>
  );
}

export default BudgetView;
