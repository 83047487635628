import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {COLORS} from '@utils/constants';
import {Divider, Modal} from 'antd';
import {LegacyButtonType} from 'antd/lib/button/button';

const {confirm} = Modal;

export interface LearnInConfirmArgs {
  cancelText?: string;
  content?: React.ReactNode;
  icon?: React.ReactNode;
  okText: string;
  onCancel?: () => void;
  onOk?: () => void;
  title: string;
  okButton?: {color: COLORS; type: LegacyButtonType};
  showCancel?: boolean;
}

const learnInConfirm = ({
  cancelText = i18n.t(k.CTA__CANCEL),
  content = null,
  icon = null,
  okText,
  onCancel = () => null,
  onOk = () => null,
  okButton = {color: COLORS.Red800, type: 'danger'},
  title,
  showCancel: hideCancel = true,
}: LearnInConfirmArgs) => {
  return confirm({
    okCancel: hideCancel,
    content: (
      <>
        <p>{content}</p>
        <Divider
          style={{
            position: 'absolute',
            left: 0,
            bottom: '2.75rem',
          }}
        />
      </>
    ),
    bodyStyle: {borderRadius: '25px', padding: '16px 24px'},
    cancelButtonProps: {
      type: 'primary',
      shape: 'round',
      ghost: true,
      style: {
        border: `1px solid ${COLORS.Blue800}`,
        color: COLORS.Blue800,
        fontSize: '1rem',
        lineHeight: '1.25rem',
      },
    },
    cancelText,
    icon,
    okButtonProps: {
      type: 'primary',
      shape: 'round',
      style: {
        background: okButton.color,
        color: COLORS.White,
        fontSize: '1rem',
        lineHeight: '1.25rem',
      },
    },
    okText,
    okType: okButton.type,
    onCancel: () => {
      if (!!onCancel) {
        onCancel();
      }
    },
    onOk,
    title,
  });
};

export default learnInConfirm;
