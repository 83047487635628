import {i18n, k} from '@i18n/translate';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {EmployeeOverviewVM, TeamInsightsOverviewVM} from '@models/serverModels';
import LearnInTable, {ColumnAlignment} from '@components/reusable/LearnInTable';
import {formatCurrency} from '@utils/moneyUtils';
import {DATE_FORMAT} from '@utils/timeUtils';
import {UserDetailsVM} from '@generated/interfaces';
import useSetPageTitle from '@hooks/useSetPageTitle';
import styled from 'styled-components';
import {InfoCircleOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {COLORS} from '@utils/constants';
import {TIsFeatureFlagOn} from '@hooks/useFeatureFlags';

const EmployeeNameAndEmail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  svg {
    color: ${COLORS.Blue800};
    &:hover {
      color: ${COLORS.Blue700};
    }
  }
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  svg {
    color: ${COLORS.Neutral500};
  }
`;

export const TeamInsightsOverview = ({
  data,
  user,
  exchangeRate,
  isFeatureFlagOn,
  title,
}: {
  data: TeamInsightsOverviewVM;
  user: UserDetailsVM;
  exchangeRate: number;
  isFeatureFlagOn: TIsFeatureFlagOn;
  title?: string;
}) => {
  useSetPageTitle(title);

  const usersCurrency = user?.currency;
  const plbType = user?.plbType;
  const hasPlb = plbType !== null;

  // BEN-6240: Use the if when the flag is lifted
  const showApprovedAmount = isFeatureFlagOn.TeamInsightsHideColumns
    ? hasPlb
    : true;

  // BEN-6240: Use the if when the flag is lifted
  const showSpentAmount = isFeatureFlagOn.TeamInsightsHideColumns
    ? hasPlb
    : data?.hasPlb;

  const tableData = data?.employeeOverviewVms;

  return (
    <LearnInTable
      columns={[
        {
          title: i18n.t(k.NAME),
          dataIndex: 'employeeName',
          key: 'employeeName',
          render: (employeeName: string, row: EmployeeOverviewVM) => {
            return (
              <EmployeeNameAndEmail>
                <Link to={row.userCompanyId.toString()}>{employeeName}</Link>
                <LearnInTooltip title={row.email} childHasButton={true}>
                  <Button
                    type="link"
                    icon={<StyledInfoCircleOutlined aria-hidden="true" />}
                  />
                </LearnInTooltip>
              </EmployeeNameAndEmail>
            );
          },
          sorter: (a: EmployeeOverviewVM, b: EmployeeOverviewVM) =>
            a?.employeeName?.localeCompare(b?.employeeName),
        },
        {
          title: i18n.t(k.GENERIC__LAST_LOGIN),
          width: 155,
          dataIndex: 'lastLoginUTC',
          key: 'lastLoginUTC',
          render: (lastLoginUTC: string) =>
            lastLoginUTC && lastLoginUTC.length !== 0
              ? moment(lastLoginUTC).format(DATE_FORMAT.MONTH_DAY_YEAR)
              : i18n.t(k.GENERIC__HAS_NOT_LOGGED_IN),
          sorter: (a: EmployeeOverviewVM, b: EmployeeOverviewVM) => {
            return a?.lastLoginUTC?.localeCompare(b?.lastLoginUTC);
          },
        },
        showApprovedAmount && {
          title: i18n.t(k.APPROVAL__APPROVED_AMOUNT),
          width: 155,
          dataIndex: 'approvedAmount',
          align: ColumnAlignment.Right,
          key: 'approvedAmount',
          render: (amount: number) =>
            formatCurrency(amount, usersCurrency, exchangeRate, {
              decimal: true,
            }),
          sorter: (a: EmployeeOverviewVM, b: EmployeeOverviewVM) =>
            a?.approvedAmount - b?.approvedAmount,
        },
        showSpentAmount && {
          title: i18n.t(k.MONEY__AMOUNT_SPENT),
          width: 155,
          align: ColumnAlignment.Right,
          dataIndex: 'spentAmount',
          key: 'spentAmount',
          render: (amount: number, row: EmployeeOverviewVM) =>
            row.isEligibleForPersonalLearningBudget ? (
              formatCurrency(amount, usersCurrency, exchangeRate, {
                decimal: true,
              })
            ) : (
              <span style={{marginRight: '14px'}}>–</span>
            ),
          sorter: (a: EmployeeOverviewVM, b: EmployeeOverviewVM) =>
            a?.spentAmount - b?.spentAmount,
        },
      ]}
      dataSource={tableData}
    />
  );
};
