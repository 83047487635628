import {i18n, k} from '@i18n/translate';
import {Tabs} from 'antd';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import ManagerPermissionsTable from './ManagedPermissionsTable';
import {ContributorPermissionType} from '@models/clientEnums';
import {CustomizableLearningCategory} from '@generated/enums';
import {ManagerPermissionsTableRow} from '@components/user/pages/managedPermissions/ManagedPermissionsTable';
import NoDataText from '@blocks/NoDataText';
import LoadingSpinner from '@blocks/LoadingSpinner';
import {ManagedPermissionsPageTabKey} from '@utils/ClientPaths';

const {TabPane} = Tabs;

interface ManagerPermissionsPageProps {
  onChangeTabs: (key: ManagedPermissionsPageTabKey) => void;
  academiesTableVisible: boolean;
  customProgramTableVisible: boolean;
  showAcademiesTable: boolean;
  showProgramsTable: boolean;
  managerPermissionsTableDataSource: ManagerPermissionsTableRow[];
  showEmptyScreen: boolean;
  isLoading: boolean;
  onClickTitle: (
    id: number,
    learningCategory: CustomizableLearningCategory
  ) => void;
  onClickTableIcon: (
    id: number,
    learningCategory: CustomizableLearningCategory,
    permissionType: ContributorPermissionType,
    parentCustomProgramId?: number | undefined
  ) => void;
}

function ManagerPermissionsPage({
  onChangeTabs,
  academiesTableVisible,
  customProgramTableVisible,
  showAcademiesTable,
  showProgramsTable,
  managerPermissionsTableDataSource,
  onClickTableIcon,
  onClickTitle,
  showEmptyScreen,
  isLoading,
}: ManagerPermissionsPageProps) {
  const showEditColumn = managerPermissionsTableDataSource?.some(
    ({canEdit}) => canEdit
  );
  const showReportsColumn = managerPermissionsTableDataSource?.some(
    ({canViewReport}) => canViewReport
  );
  const showProjectsColumn = managerPermissionsTableDataSource?.some(
    ({canReviewProject}) => canReviewProject
  );
  const showCohortColumn = managerPermissionsTableDataSource?.some(
    ({startDateUtc}) => !!startDateUtc
  );

  const renderAcademiesPermissionsTable = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (showEmptyScreen) {
      return (
        <NoDataText header={i18n.t(k.GENERIC__EMPTY_STATE)} subHeader="" />
      );
    }
    return (
      <ManagerPermissionsTable
        showEditColumn={showEditColumn}
        showReportsColumn={showReportsColumn}
        showProjectsColumn={showProjectsColumn}
        showCohortColumn={showCohortColumn}
        dataSource={managerPermissionsTableDataSource}
        learningResourceType={CustomizableLearningCategory.Academy}
        onClickEdit={(id: number, parentCustomProgramId: number | undefined) =>
          onClickTableIcon(
            id,
            CustomizableLearningCategory.Academy,
            ContributorPermissionType.canEdit,
            parentCustomProgramId
          )
        }
        onClickReviewProjects={(id) =>
          onClickTableIcon(
            id,
            CustomizableLearningCategory.Academy,
            ContributorPermissionType.canReviewProject
          )
        }
        onClickTitle={(id) =>
          onClickTitle(id, CustomizableLearningCategory.Academy)
        }
        onClickViewReports={(id) =>
          onClickTableIcon(
            id,
            CustomizableLearningCategory.Academy,
            ContributorPermissionType.canViewReport
          )
        }
      />
    );
  };

  const renderCustomProgramsPermissionsTable = () => {
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (showEmptyScreen) {
      return (
        <NoDataText
          header={i18n.t(k.GENERIC__EMPTY_RESULTS__TITLE)}
          subHeader={i18n.t(k.GENERIC__EMPTY_RESULTS__DESCRIPTION)}
        />
      );
    }

    return (
      <ManagerPermissionsTable
        showEditColumn={showEditColumn}
        showReportsColumn={showReportsColumn}
        showProjectsColumn={showProjectsColumn}
        showCohortColumn={showCohortColumn}
        dataSource={managerPermissionsTableDataSource}
        learningResourceType={CustomizableLearningCategory.CustomProgram}
        onClickEdit={(id, parentCustomProgramId) =>
          onClickTableIcon(
            id,
            CustomizableLearningCategory.CustomProgram,
            ContributorPermissionType.canEdit,
            parentCustomProgramId
          )
        }
        onClickReviewProjects={(id) =>
          onClickTableIcon(
            id,
            CustomizableLearningCategory.CustomProgram,
            ContributorPermissionType.canReviewProject
          )
        }
        onClickTitle={(id) =>
          onClickTitle(id, CustomizableLearningCategory.CustomProgram)
        }
        onClickViewReports={(id) =>
          onClickTableIcon(
            id,
            CustomizableLearningCategory.CustomProgram,
            ContributorPermissionType.canViewReport
          )
        }
      />
    );
  };

  const getActiveKey = () => {
    if (showAcademiesTable) {
      return ManagedPermissionsPageTabKey.Academies;
    }
    if (showProgramsTable) {
      return ManagedPermissionsPageTabKey.Programs;
    }
  };

  const renderPageTabs = () => {
    return (
      <LearnInTabs onChange={onChangeTabs} activeKey={getActiveKey()}>
        <TabPane
          tab={i18n.t(k.PROGRAM__PLURAL)}
          key={ManagedPermissionsPageTabKey.Programs}>
          {renderCustomProgramsPermissionsTable()}
        </TabPane>
        <TabPane
          tab={i18n.t(k.ACADEMY__PLURAL)}
          key={ManagedPermissionsPageTabKey.Academies}>
          {renderAcademiesPermissionsTable()}
        </TabPane>
      </LearnInTabs>
    );
  };

  const onlyShowAcademies = academiesTableVisible && !customProgramTableVisible;
  const onlyShowPrograms = customProgramTableVisible && !academiesTableVisible;

  const pageTitleBarTitle = onlyShowAcademies
    ? i18n.t(k.ACADEMY__PLURAL__MANAGE)
    : onlyShowPrograms
    ? i18n.t(k.PROGRAM__PLURAL__MANAGE)
    : i18n.t(k.CTA__MANAGE);

  return (
    <div>
      <PageTitleBar title={pageTitleBarTitle} />
      <PageContentLayout fullWidth>
        {onlyShowAcademies
          ? renderAcademiesPermissionsTable()
          : onlyShowPrograms
          ? renderCustomProgramsPermissionsTable()
          : renderPageTabs()}
      </PageContentLayout>
    </div>
  );
}

export default ManagerPermissionsPage;
