import {CurrencyCode, PlanItemType} from './../enums';
import {UserPlanItemCardVM} from './../interfaces';
import {
  ApprovalConfigMockData,
  getApprovalConfigMockData,
} from './ApprovalConfig.mock';
import {faker} from '@faker-js/faker';

// Interface: UserPlanItemCardVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserPlanItemCardVMMockData(
  args?: Partial<UserPlanItemCardVM>
): UserPlanItemCardVM {
  return {
    applicationUrl: faker.internet.url(),
    approvalConfig: getApprovalConfigMockData(),
    currency: CurrencyCode.USD,
    duration: faker.datatype.number(), // optional
    durationType: faker.word.noun(),
    estimatedAmount: faker.datatype.number(), // optional
    exceedsEstimatedCostAmount: faker.datatype.number(), // optional
    externalUrl: faker.internet.url(),
    financeIncentiveId: faker.datatype.number(), // optional
    id: faker.datatype.number(),
    isAnyAmountIncentive: faker.datatype.boolean(),
    isPersonalLearningBudget: faker.datatype.boolean(),
    itemType: PlanItemType.Program,
    money: faker.datatype.number(), // optional
    preApprovalApplicationStatus: faker.word.noun(),
    programId: faker.datatype.number(), // optional
    provider: faker.word.noun(),
    providerLogo: faker.word.noun(),
    redemptionApprovalState: faker.word.noun(),
    redemptionApprovedBy: faker.word.noun(),
    startDate: String(faker.date.past()), // optional
    status: faker.word.noun(),
    timeIncentiveId: faker.datatype.number(), // optional
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserPlanItemCardVMMockData: UserPlanItemCardVM = {
  applicationUrl: 'https://this-person-does-not-exist.com/en',
  approvalConfig: ApprovalConfigMockData,
  currency: CurrencyCode.USD,
  duration: 123, // optional
  durationType: 'string',
  estimatedAmount: 5, // optional
  exceedsEstimatedCostAmount: 5, // optional
  externalUrl: 'https://this-person-does-not-exist.com/en',
  financeIncentiveId: 1, // optional
  id: 1,
  isAnyAmountIncentive: true,
  isPersonalLearningBudget: true,
  itemType: PlanItemType.Program,
  money: 123, // optional
  preApprovalApplicationStatus: 'string',
  programId: 1, // optional
  provider: 'string',
  providerLogo: 'string',
  redemptionApprovalState: 'string',
  redemptionApprovedBy: 'string',
  startDate: '2023-03-29T22:17:19.738072', // optional
  status: 'string',
  timeIncentiveId: 1, // optional
  title: 'Advanced Web Design',
};
