import {CurrencyCode, LanguagePreference} from './../enums';
import {UserSettingsVM} from './../interfaces';
import {
  NotificationSettingsMockData,
  getNotificationSettingsMockData,
} from './NotificationSettings.mock';
import {faker} from '@faker-js/faker';

// Interface: UserSettingsVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserSettingsVMMockData(
  args?: Partial<UserSettingsVM>
): UserSettingsVM {
  return {
    currency: CurrencyCode.USD,
    email: String(faker.internet.email()),
    familyName: faker.name.lastName(),
    givenName: faker.name.firstName(),
    jobTitle: faker.company.catchPhrase(),
    languagePreference: LanguagePreference.EN_US,
    notificationSettings: getNotificationSettingsMockData(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserSettingsVMMockData: UserSettingsVM = {
  currency: CurrencyCode.USD,
  email: 'bjohnson@gmail.com',
  familyName: 'Johnson',
  givenName: 'Bobby',
  jobTitle: 'Advanced Web Design',
  languagePreference: LanguagePreference.EN_US,
  notificationSettings: NotificationSettingsMockData,
};
