import {useEffect} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {COLORS} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import RestrictedAccessForm from '@components/reusable/RestrictedAccess/RestrictedAccessForm';
import {AcademyPermissions} from '@models/clientEnums';
import {AddedEmployeesContainer} from '@components/admin/pages/academies/AddedEmployees/AddedEmployees.container';
import {
  InputLabel,
  charLimitErrorMessage,
} from '@components/reusable/LearnInForm';

import {InputTags} from '@components/reusable/Input/InputEnums';
import UploadPreviewLogo from '@blocks/UploadPreviewLogo';
import {DeleteOutlined} from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import {RcFile} from 'antd/lib/upload';
import {notify} from '@components/user/notifications';
import {AcademySetupFormInstance} from './Setup.container';
import {
  FileTypeDetails,
  FileUploadType,
  generateFileUploadMediaTypes,
  getBase64,
  validateUserUpload,
} from '@utils/uploadUtils';
import {UploadConstraints} from '@components/reusable/Upload/UploadEnum';
import {
  FooterButtonContainer,
  FooterContainer,
} from '@components/reusable/Footer/Footer';
import {AttachmentPayload} from '@models/api/shared/payloads';
import UploadInstructions from '@components/reusable/Upload/UploadInstructions';
import {ChatChannel} from '@models/serverModels';
import {InvitationEmailCard} from './cards';
import {
  Card,
  CardContent,
  Header,
  Page,
  Subheader,
  AttachmentCard,
  NameInput,
  TextArea,
} from './Setup.styled';
import InvitationEmailDrawer from '../InvitationEmail/InvitationEmailDrawer';
import {Helmet} from 'react-helmet-async';

const FormItem = Form.Item;
const SHORT_DESCRIPTION_CHAR_LIMIT = 600;

interface Props {
  isLoadingChatLink?: boolean;
  handleDeleteChatLink: () => void;
  chatLink?: ChatChannel;
  form?: FormInstance<AcademySetupFormInstance>;
  onSubmit: () => void;
  privacyState: AcademyPermissions;
  setPrivacyState: (state: AcademyPermissions) => void;
  previewUrl: string;
  setAttachments: React.Dispatch<React.SetStateAction<any[]>>;
  handleDeleteAttachment: () => Promise<void>;
  handleAddLink: (link: string) => void;
  handleUpdateLink: (link: string) => void;
  title: string;
  shouldSendEmailInvite: boolean;
  setShouldSendEmailInvite: (shouldSend: boolean) => void;
}

export const Setup = ({
  isLoadingChatLink,
  handleDeleteChatLink,
  chatLink,
  privacyState,
  setPrivacyState,
  form,
  onSubmit,
  previewUrl,
  setAttachments,
  handleDeleteAttachment,
  handleAddLink,
  handleUpdateLink,
  title,
  setShouldSendEmailInvite,
  shouldSendEmailInvite,
}: Props) => {
  const [localChatLink, setChatLink] = React.useState<string | null>(null);
  const [updatingChat, setUpdatingChat] = React.useState(false);
  const [emailPreviewEnabled, setEmailPreviewEnabled] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (chatLink?.channelLink) {
      return setUpdatingChat(true);
    }
    return setUpdatingChat(false);
  }, [isLoadingChatLink, chatLink]);

  useEffect(() => {
    setChatLink(chatLink?.channelLink);
  }, [chatLink]);
  return (
    <Page>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Card role="article" aria-labelledby="academy-setup-title">
        <CardContent>
          <Header id="academy-setup-title">
            {i18n.t(k.ACADEMY__SETUP__TITLE)}
          </Header>
          <Subheader>{i18n.t(k.ACADEMY__SETUP__DESCRIPTION)}</Subheader>
          <Form
            form={form}
            layout="vertical"
            name="newAcademy"
            requiredMark={false}>
            <InputLabel
              htmlFor="academy-name-input"
              label={i18n.t(k.ACADEMY__NAME)}
              required
            />
            <FormItem
              id="academy-name-input"
              label={i18n.t(k.ACADEMY__NAME)}
              name="name"
              rules={[
                {
                  required: true,
                  message: i18n.t(k.ACADEMY__VALIDATION__ACADEMY_NAME),
                },
              ]}>
              <NameInput
                tag={InputTags.PRIMARY}
                label={i18n.t(k.NAME)}
                size="large"
              />
            </FormItem>
            <InputLabel
              htmlFor="short-description-input"
              label={i18n.t(k.ACADEMY__VALIDATION__SHORT_DESCRIPTION)}
              required
            />
            <FormItem
              id="short-description-input"
              name="description"
              rules={[
                {
                  required: true,
                  message: i18n.t(k.ACADEMY__VALIDATION__SHORT_TITLE),
                },
                {
                  max: SHORT_DESCRIPTION_CHAR_LIMIT,
                  message: charLimitErrorMessage(
                    i18n.t(k.GENERIC__DESCRIPTION),
                    SHORT_DESCRIPTION_CHAR_LIMIT
                  ),
                },
              ]}>
              <TextArea
                aria-label={i18n.t(k.GENERIC__DESCRIPTION)}
                rows={4}
                showCount
              />
            </FormItem>
            <>
              <InputLabel
                htmlFor="upload-input"
                label={
                  previewUrl
                    ? i18n.t(k.ACADEMY__IMAGE)
                    : i18n.t(k.FILE__UPLOAD__IMAGE)
                }
              />
              {previewUrl ? (
                <AttachmentCard>
                  <UploadPreviewLogo imageSrc={previewUrl} size={75} />
                  <button
                    id="upload-input"
                    aria-label={i18n.t(k.CTA__DELETE)}
                    onClick={handleDeleteAttachment}>
                    <DeleteOutlined
                      aria-hidden="true"
                      style={{cursor: 'pointer', color: COLORS.Red800}}
                    />
                  </button>
                </AttachmentCard>
              ) : (
                <>
                  <Dragger
                    id="upload-input"
                    customRequest={async ({file}) => {
                      if (
                        validateUserUpload(
                          file as any,
                          [],
                          UploadConstraints.CustomProgramHeaderImageLimit,
                          'bytes',
                          generateFileUploadMediaTypes([FileUploadType.Images])
                        )
                      ) {
                        getBase64(file, (data: any) =>
                          setAttachments((images: AttachmentPayload[]) => [
                            ...images,
                            {
                              fileName: (file as RcFile).name,
                              fileContent: btoa(data),
                              description: '',
                            },
                          ])
                        );
                      } else {
                        notify.uploadContentAttachmentIsInvalid();
                      }
                    }}
                    showUploadList={false}
                    maxCount={1}
                    height={100}
                    multiple={false}
                    accept={[
                      ...FileTypeDetails[FileUploadType.Images].fileSuffixes,
                    ].join(', ')}>
                    <UploadInstructions
                      fileTypes=".png, .jpg, .jpeg"
                      maxSize="1 MB"
                    />
                    <p style={{color: COLORS.Red800}}>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                        }}></button>
                    </p>
                  </Dragger>
                </>
              )}
            </>
          </Form>
        </CardContent>
      </Card>
      <Card role="article" aria-labelledby="academy-setup-privacy-title">
        <CardContent>
          <Header id="academy-setup-privacy-title">
            {i18n.t(k.GENERIC__PRIVACY_SETTINGS)}
          </Header>
          <Subheader>{i18n.t(k.ACADEMY__SETUP__PRIVACY_DESCRIPTION)}</Subheader>
          <RestrictedAccessForm
            hasCheckmark={true}
            isAcademy
            restrictedAccess={privacyState === AcademyPermissions.Restricted}
            setRestrictedAccess={(state) =>
              setPrivacyState(
                state === true
                  ? AcademyPermissions.Restricted
                  : AcademyPermissions.Public
              )
            }
          />
          <AddedEmployeesContainer
            restrictedAccess={privacyState === AcademyPermissions.Restricted}
          />
        </CardContent>
      </Card>

      {privacyState === AcademyPermissions.Restricted && (
        <>
          <InvitationEmailCard
            checkboxValue={shouldSendEmailInvite}
            onCheckboxChange={(ev) => {
              setShouldSendEmailInvite(ev.target.checked);
            }}
            onClickEditAndPreview={() => setEmailPreviewEnabled(true)}
          />

          <InvitationEmailDrawer
            onClose={() => setEmailPreviewEnabled(false)}
            open={emailPreviewEnabled}
          />
        </>
      )}

      <Card role="article" aria-labelledby="academy-chat-link">
        <CardContent>
          <Header id="academy-chat-link-title">
            {i18n.t(k.ACADEMY__CHAT_APPLICATION_TITLE)}
          </Header>
          <Subheader>
            {i18n.t(k.ACADEMY__CHAT_APPLICATION_DESCRIPTION)}
          </Subheader>
          <Form layout="horizontal" name="chatLink" requiredMark={false}>
            <InputLabel
              htmlFor="academy-chat-application-input"
              label={i18n.t(k.ACADEMY__CHAT_APPLICATION_TITLE)}
            />
            <div
              style={{
                display: 'flex',
              }}>
              <div style={{flex: '2'}}>
                <NameInput
                  disabled={isLoadingChatLink}
                  placeholder={
                    isLoadingChatLink
                      ? i18n.t(k.STATUS__LOADING)
                      : i18n.t(k.ACADEMY__CHAT_APPLICATION_PLACEHOLDER_TEXT)
                  }
                  value={localChatLink}
                  tag={InputTags.PRIMARY}
                  aria-label={i18n.t(k.ACADEMY__CHAT_APPLICATION_TITLE)}
                  size="large"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setChatLink(e.target.value)
                  }
                />
              </div>
              {!isLoadingChatLink && (
                <div style={{marginLeft: '15px'}}>
                  <LearnInButton
                    tag={ButtonTags.Secondary}
                    onClick={() => {
                      if (!localChatLink) {
                        setUpdatingChat(false);
                        handleDeleteChatLink();
                      } else if (!updatingChat) {
                        handleAddLink(localChatLink);
                        setUpdatingChat(true);
                      } else {
                        handleUpdateLink(localChatLink);
                        setUpdatingChat(true);
                      }
                    }}>
                    {!updatingChat
                      ? i18n.t(k.ACADEMY__CHAT_APPLICATION_ADD_LINK)
                      : i18n.t(k.ACADEMY__CHAT_APPLICATION_UPDATE_LINK)}
                  </LearnInButton>
                </div>
              )}
            </div>
            {updatingChat && (
              <LearnInButton
                style={{padding: '20px 2px'}}
                tag={ButtonTags.DestructiveRemovedBorder}
                onClick={() => {
                  setChatLink('');
                  setUpdatingChat(false);
                  handleDeleteChatLink();
                }}>
                {i18n.t(k.ACADEMY__CHAT_APPLICATION_REMOVE_LINK)}
              </LearnInButton>
            )}
          </Form>
        </CardContent>
      </Card>
      <FooterContainer>
        <FooterButtonContainer>
          <LearnInButton
            htmlType="submit"
            onClick={onSubmit}
            tag={ButtonTags.Primary}>
            {i18n.t(k.CTA__SAVE)}
          </LearnInButton>
        </FooterButtonContainer>
      </FooterContainer>
    </Page>
  );
};
