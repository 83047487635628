import {useLocation, useNavigate} from 'react-router-dom';
import {useCallback} from 'react';
import querystring from 'querystring';

function useSearchParamUtils() {
  const {pathname, search = ''} = useLocation();
  const navigate = useNavigate();
  const searchParams = querystring.parse(search.substring(1)); // .substring removes the leading "?"

  const setSearchParams = (newParams: Record<string, string>) => {
    const searchParams = new URLSearchParams(search);
    Object.entries(newParams).forEach(([key, value]) => {
      value ? searchParams.set(key, value) : searchParams.delete(key);
    });
    const newQueryString = searchParams.toString();
    return newQueryString ? `${pathname}?${newQueryString}` : pathname;
  };

  const setSearchParamsAndNavigate = useCallback(
    (newParams: Record<string, string>) => {
      const searchParams = new URLSearchParams(search);
      Object.entries(newParams).forEach(([key, value]) => {
        value ? searchParams.set(key, value) : searchParams.delete(key);
      });
      const newQueryString = searchParams.toString();
      newQueryString
        ? navigate(`${pathname}?${newQueryString}`)
        : navigate(pathname);
    },
    [navigate, pathname, search]
  );

  const updateSearchParamsAndNavigate = (
    paramUpdates: Record<string, string | number>,
    navigateOptions?: any
  ) => {
    const searchParams = querystring.parse(search.substring(1)); // .substring removes the leading "?"
    const combinedParams = {...searchParams, ...paramUpdates};
    // Remove search params if the value is falsey, excluding 0
    const newSearchParams = Object.keys(combinedParams).reduce(
      (params, key) => {
        if (combinedParams[key] || combinedParams[key] === 0) {
          params[key] = combinedParams[key];
        }
        return params;
      },
      {}
    );
    const newQueryString = querystring.stringify(newSearchParams);
    if (!newQueryString.length) {
      navigate(pathname + newQueryString, navigateOptions);
    } else {
      navigate(pathname + '?' + newQueryString, navigateOptions);
    }
  };

  return {
    searchParams,
    setSearchParams,
    setSearchParamsAndNavigate,
    updateSearchParamsAndNavigate,
  };
}

export default useSearchParamUtils;

export function getQueryParamsFromHash() {
  const hash = window.location.hash;
  const queryString = hash.includes('?') ? hash.split('?')[1] : '';
  return new URLSearchParams(queryString);
}
