import {EyeOutlined} from '@ant-design/icons/lib/icons';
import {LearnInTabs} from '@components/reusable/Tabs/Tabs.style';
import AcademyContentLevelScreenView from '@components/user/pages/academy/AcademyContentLevelScreen/AcademyContentLevelScreen.view';
import AcademyDrawerSideBar from '@components/user/pages/academy/AcademyDrawer/AcademyDrawerSideBar.container';
import {Content} from '@components/user/pages/academy/AcademyWelcome/Content';
import {SectionNav} from '@components/user/pages/academy/SectionNav/SectionNav.view';
import {DisplayStatus} from '@generated/enums';
import {AcademyBrandVM, AcademyVM} from '@generated/interfaces';
import {
  getAcademyLevelVMMockData,
  getAcademyVMMockData,
} from '@generated/mocks';
import {i18n, k} from '@i18n/translate';
import {getAdminAcademiesBrandingUrl} from '@utils/ClientPaths';
import {getAcademyBrandingBackgroundColor, theme} from '@utils/color';
import {COLORS} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';
import {Tabs} from 'antd';
import {useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import {BrandingTab} from './Branding.types';
import {useGetMockAcademyStep} from './useGetMockAcademyStep';

const AcademyWelcomeDrawerContentContainer = styled.div`
  box-shadow: inset 0 1px 0 ${COLORS.Neutral200};
  box-sizing: border-box;
  margin: 16px;
  padding: 0;
  width: unset;
  > div {
    height: calc(100vh - 230px);
    border-radius: 8px;
  }

  .academy-welcome-drawer-bg {
    height: calc(100vh - 230px);
    background: 0;
    padding: 0;
  }
`;

const PreviewNavContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
  box-shadow: ${COLORS.BoxShadowStandard};
  position: relative;
  height: calc(100vh - 230px);
  margin: 16px;
  background: ${(props) =>
    getAcademyBrandingBackgroundColor(props.branding?.primaryBrandColor, 45)};
`;

function BrandingPreviewPane({
  academyData,
  branding,
}: {
  academyData: AcademyVM;
  branding: AcademyBrandVM;
}) {
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const activeTabKey = pathname.split('/').find((part) => {
    return Object.values(BrandingTab).includes(part as any);
  });

  const basePath = getAdminAcademiesBrandingUrl(academyData.id);

  const ensureValidTabKey = () => {
    if (
      activeTabKey === undefined ||
      Object.values(BrandingTab).every((key) => key !== activeTabKey)
    ) {
      navigate([basePath, BrandingTab.Overview].join('/'), {
        replace: true,
      });
    }
  };

  useEffect(ensureValidTabKey, [activeTabKey, basePath, navigate]);

  const onChangeTab = (tabKey: BrandingTab) => {
    navigate([basePath, tabKey].join('/'));
    const tabTitle =
      tabKey === BrandingTab.Overview
        ? i18n.t(k.BRAND__PREVIEW_OVERVIEW)
        : i18n.t(k.BRAND__PREVIEW_WELCOME);
    document.title = `${i18n.t(k.BRAND__BRANDING)} - ${tabTitle}`;
  };

  return (
    <LearnInTabs activeKey={activeTabKey} onChange={onChangeTab}>
      <Tabs.TabPane
        tab={
          <>
            <EyeOutlined aria-hidden="true" />{' '}
            {i18n.t(k.BRAND__PREVIEW_OVERVIEW)}
          </>
        }
        key={BrandingTab.Overview}>
        <PreviewNavContainer
          branding={
            !!branding?.primaryBrandColor // Legacy academies without brand colors will use the default theme
              ? branding
              : theme
          }>
          <AcademyDrawerSideBar
            getScreenNavItemUrlPath={() => ''}
            isLoading={false}
            academy={academyData}
            topAlign={0}
            isActivePlanItem={true}>
            <SectionNav
              levelId={1}
              sections={[]}
              branding={branding}
              showBrandingScreenDisplay
              isActivePlanItem={true}
              onClickSectionNavCard={() => {
                /* pass */
              }}
              top={0}
            />
            <AcademyContentLevelScreenView
              top={0}
              academy={getAcademyVMMockData({
                branding,
              })}
              addEventsToCalendarLoading={false}
              blockingTitle=""
              isActivePlanItem={false}
              isExternalCalendarConnected={false}
              level={getAcademyLevelVMMockData({
                title: i18n.t(k.SECTION__IN_PROGRESS),
                description:
                  'Quasi non voluptatibus reprehenderit autem architecto amet vitae.',
              })}
              onClickAddDatesToCalendar={() => {
                /* pass */
              }}
              onClickConnectCalendar={() => {
                /* pass */
              }}
              plan={null}
              selectedContentId={1}
              showConnectCalendarCard
              steps={[
                useGetMockAcademyStep({
                  title: `${i18n.t(k.CONTENT)} 1`,
                  description: `Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.`,
                  status: {
                    displayStatus: DisplayStatus.Completed,
                  },
                }),
                useGetMockAcademyStep({
                  title: `${i18n.t(k.CONTENT)} 2`,
                  description: `Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?`,
                }),
              ]}
              isLoading={false}
              showGatedCardIfStepIsBlocked={false}
            />
          </AcademyDrawerSideBar>
        </PreviewNavContainer>
      </Tabs.TabPane>
      <Tabs.TabPane
        tab={
          <>
            <EyeOutlined aria-hidden="true" />{' '}
            {i18n.t(k.BRAND__PREVIEW_WELCOME)}
          </>
        }
        key={BrandingTab.Welcome}>
        <AcademyWelcomeDrawerContentContainer>
          <Content
            breakpoint={Breakpoints.Desktop}
            isBranding={true}
            userFirstName={i18n.t(k.ADMIN)}
            academy={academyData}
          />
        </AcademyWelcomeDrawerContentContainer>
      </Tabs.TabPane>
    </LearnInTabs>
  );
}

export default BrandingPreviewPane;
