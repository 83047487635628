import React from 'react';
import styled from 'styled-components';
import {InputLabel} from '@components/reusable/LearnInForm';
import {SubmissionType as SubmissionTypeEnum} from '@generated/enums';
import {COLORS} from '@utils/constants';
import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import {
  LearnInSelectOption,
  LearnInSelect,
} from '@components/reusable/Select/Select.style';
import {FORM_KEYS} from './constants';
import {getFormFieldErrors} from '../AddEditContent.config';

const LearnInSelectContainer = styled(LearnInSelect)`
  width: 184px !important;
  .ant-select-selector {
    align-items: center;
    display: flex;
    .ant-select-selection-placeholder {
      color: ${COLORS.Neutral600};
      font-size: 1rem;
    }
  }
`;

const SubmissionTypeWrapper = styled.section`
  .ant-form-item {
    margin-bottom: 0;
  }
`;

interface SubmissionTypeProps {
  disabled?: boolean;
  initialValue: SubmissionTypeEnum;
}

const SubmissionType: React.FC<SubmissionTypeProps> = ({
  disabled,
  initialValue,
}) => {
  return (
    <SubmissionTypeWrapper>
      <InputLabel
        htmlFor="free-response-select-option"
        label={i18n.t(k.PROGRAM__CUSTOM__SUBMISSION_TYPE)}
        required
      />
      <Form.Item
        name={FORM_KEYS.SUBMISSION_TYPE}
        rules={[{required: true, message: getFormFieldErrors().submissionType}]}
        initialValue={initialValue}>
        <LearnInSelectContainer
          id="free-response-select-option"
          disabled={disabled}
          data-testid="submission-type-select"
          placeholder={i18n.t(k.VALIDATION__SELECT_ONE)}
          showArrow>
          <LearnInSelectOption
            data-testid="free-response-select-option"
            value={SubmissionTypeEnum.Text}>
            {i18n.t(k.GENERIC__FREE_RESPONSE)}
          </LearnInSelectOption>
          <LearnInSelectOption
            data-testid="upload-a-file-select-option"
            value={SubmissionTypeEnum.FileUpload}>
            {i18n.t(k.FILE__UPLOAD__VERB)}
          </LearnInSelectOption>
          <LearnInSelectOption
            data-testid="submit-a-link-select-option"
            value={SubmissionTypeEnum.Link}>
            {i18n.t(k.CTA__SUBMIT_A_LINK)}
          </LearnInSelectOption>
        </LearnInSelectContainer>
      </Form.Item>
    </SubmissionTypeWrapper>
  );
};

export default SubmissionType;
