import {
  CurrencyCode,
  LicenseRedemptionType,
  LicenseStatus,
  LicenseUsageType,
  VoucherAssignMode,
} from './../enums';
import {LicenseResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: LicenseResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLicenseResponseMockData(
  args?: Partial<LicenseResponse>
): LicenseResponse {
  return {
    approvalGuideline: faker.word.noun(),
    companyId: faker.datatype.number(),
    currency: CurrencyCode.USD,
    id: faker.datatype.number(),
    internalCost: faker.datatype.number(),
    licenseDuration: faker.datatype.number(),
    providerId: faker.datatype.number(),
    redemptionGuideline: faker.word.noun(),
    redemptionType: LicenseRedemptionType.Voucher,
    requireAdminApproval: faker.datatype.boolean(),
    requireManagerApproval: faker.datatype.boolean(),
    status: LicenseStatus.Draft,
    type: LicenseUsageType.AllPrograms,
    voucherAssignMode: VoucherAssignMode.NoCodes,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LicenseResponseMockData: LicenseResponse = {
  approvalGuideline: 'string',
  companyId: 1,
  currency: CurrencyCode.USD,
  id: 1,
  internalCost: 123,
  licenseDuration: 123,
  providerId: 1,
  redemptionGuideline: 'string',
  redemptionType: LicenseRedemptionType.Voucher,
  requireAdminApproval: true,
  requireManagerApproval: true,
  status: LicenseStatus.Draft,
  type: LicenseUsageType.AllPrograms,
  voucherAssignMode: VoucherAssignMode.NoCodes,
};
