import {COLORS} from '@utils/constants';

interface Props {
  disabled?: boolean;
}

export const WalletIcon = ({disabled}: Props) => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M31.7853 0.933594H2.21383C1.50267 0.933594 0.928116 1.50815 0.928116 2.21931V31.7907C0.928116 32.5019 1.50267 33.0765 2.21383 33.0765H31.7853C32.4964 33.0765 33.071 32.5019 33.071 31.7907V2.21931C33.071 1.50815 32.4964 0.933594 31.7853 0.933594ZM30.1781 19.5765H17.6424V14.4336H30.1781V19.5765ZM30.1781 30.1836H3.82097V3.82645H30.1781V11.8622H16.3567C15.6455 11.8622 15.071 12.4367 15.071 13.1479V20.8622C15.071 21.5733 15.6455 22.1479 16.3567 22.1479H30.1781V30.1836ZM19.7317 17.005C19.7317 17.4313 19.901 17.84 20.2024 18.1414C20.5038 18.4428 20.9126 18.6122 21.3388 18.6122C21.7651 18.6122 22.1739 18.4428 22.4753 18.1414C22.7767 17.84 22.946 17.4313 22.946 17.005C22.946 16.5788 22.7767 16.17 22.4753 15.8686C22.1739 15.5672 21.7651 15.3979 21.3388 15.3979C20.9126 15.3979 20.5038 15.5672 20.2024 15.8686C19.901 16.17 19.7317 16.5788 19.7317 17.005Z"
      fill={disabled ? COLORS.Neutral300 : COLORS.Blue800}
    />
  </svg>
);
