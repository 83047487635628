import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import LearnInProgressBar from '@components/learnin/ProgressBar';

const ReimbursementStatusBar = styled(LearnInProgressBar)`
  .ant-progress-bg {
    background: ${COLORS.Neutral300} !important;
  }
  .ant-progress-success-bg {
    background: ${COLORS.Green700} !important;
  }
`;

interface ReimbursementProgressBarProps {
  pending: number;
  reimbursed: number;
  approved: number;
}

export default function ReimbursementProgressBar({
  pending,
  reimbursed,
  approved,
}: ReimbursementProgressBarProps) {
  return (
    <ReimbursementStatusBar
      showInfo={false}
      percent={((pending + reimbursed) / approved) * 100}
      success={{
        percent: (reimbursed / approved) * 100,
      }}
    />
  );
}
