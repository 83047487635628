import {useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import LearnInTable, {
  TableColLink,
  TableCell,
} from '@components/reusable/LearnInTable';
import CaretRight from '@assets/svg/CaretRight';
import {DATE_FORMAT, parseToLocalMoment} from '@utils/timeUtils';
import {Breakpoints} from '@utils/responsiveUtils';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  width: 90%;
  margin: auto;

  @media (max-width: ${Breakpoints.Mobile}px) {
    width: calc(100% - 32px);
    margin: 16px;
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CustomProgramProjectsTableProps {
  dataSource: any[];
  onClickRow: (row: any) => void;
}

function CustomProgramProjectsTable({
  dataSource,
  onClickRow,
}: CustomProgramProjectsTableProps) {
  const columns = useMemo(() => {
    return [
      {
        title: i18n.t(k.PROJECT__PLURAL),
        dataIndex: 'title',
        key: 'title',
        render: (title) => {
          return (
            <TableCell>
              <TableColLink onClick={onClickRow}>{title}</TableColLink>
            </TableCell>
          );
        },
      },
      {
        title: i18n.t(k.DATE__DUE_DATE),
        dataIndex: 'dueDateUtc',
        key: 'dueDateUtc',

        render: (dueDateUtc) => {
          if (!dueDateUtc) return null;
          return (
            <div>
              {parseToLocalMoment(dueDateUtc).format(
                DATE_FORMAT.MONTH_DAY_YEAR
              )}
            </div>
          );
        },
      },
      {
        title: i18n.t(k.SUBMISSION__PLURAL),
        key: 'submissions',
        render: (_, {numberOfParticipants, numberOfSubmissions}) => {
          return (
            <div>
              {numberOfSubmissions} {i18n.t(k.GENERIC__OF)}{' '}
              {numberOfParticipants}
            </div>
          );
        },
      },
      {
        title: null,
        key: 'openRowDetails',
        render: () => {
          return (
            <div>
              <CaretRight style={{cursor: 'pointer'}} />
            </div>
          );
        },
      },
    ];
  }, []);
  return (
    <Container>
      <LearnInTable
        hasContainer={true}
        columns={columns}
        dataSource={dataSource}
        onClickRow={onClickRow}
      />
    </Container>
  );
}

export default CustomProgramProjectsTable;
