import {ProgramSearchCategory} from './../enums';
import {ProgramSearchVM} from './../interfaces';
import {
  ProgramSearchResultMockData,
  getProgramSearchResultMockData,
} from './ProgramSearchResult.mock';
import {faker} from '@faker-js/faker';

// Interface: ProgramSearchVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getProgramSearchVMMockData(
  args?: Partial<ProgramSearchVM>
): ProgramSearchVM {
  return {
    category: ProgramSearchCategory.Degree,
    count: faker.datatype.number(),
    programs: [getProgramSearchResultMockData()],
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ProgramSearchVMMockData: ProgramSearchVM = {
  category: ProgramSearchCategory.Degree,
  count: 5,
  programs: [ProgramSearchResultMockData],
};
