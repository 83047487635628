// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/ProgramNotificationType.cs

enum ProgramNotificationType {
  Welcome,
  Nudge,
  ProgramCompletion,
}

export default ProgramNotificationType;
