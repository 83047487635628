import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import SearchCard from './SearchCard';
import {
  SelectedButton,
  AddToInitiativeButton,
  ViewDetailsButton,
} from '@components/reusable/Button/LearnInButtons';
import PlanItemDetailPage from '@components/reusable/PlanItemDetailPage/PlanItemDetailPage.container';
import {CurrencyCode, PlanItemType} from '@generated/enums';
import {formatCurrency, MONEY_DEFAULT} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface IInitiativeCardProps {
  description: string;
  title: string;
  provider: string;
  selected: boolean;
  onClickAddToInitiative: () => void;
  onClickSelected: () => void;
  headerData?: {
    title: string;
    value: string;
  };
  metadata?: string[];
  previewImgSrc: string;
  providerImgSrc: string;
  financeIncentiveType?: string;
  notAvailable?: boolean;
  id: number;
  itemType: PlanItemType;
  cost?: number;
  amount?: number;
  currency?: CurrencyCode;
}

function InitiativeCard({
  description,
  financeIncentiveType,
  metadata,
  notAvailable,
  onClickAddToInitiative,
  onClickSelected,
  previewImgSrc,
  provider,
  providerImgSrc,
  selected,
  title,
  id,
  itemType,
  cost,
  currency,
}: IInitiativeCardProps) {
  const [showDetailPage, setShowDetailPage] = useState(false);
  const _provider = provider;
  const getUserQuery = useGetUserQuery();
  const usersCurrency = getUserQuery.data?.currency;
  const rateForUser = useExchangeRate(currency, getUserQuery.data?.currency);
  const exchangeRate = cost ? rateForUser : MONEY_DEFAULT.exchangeRate;

  const handleClickSeeDetails = () => {
    setShowDetailPage(true);
  };
  const handleCloseDetailPage = () => {
    setShowDetailPage(false);
  };
  const buttons = [
    selected ? (
      <SelectedButton onClick={onClickSelected} />
    ) : (
      <AddToInitiativeButton onClick={onClickAddToInitiative} />
    ),
    <ViewDetailsButton
      title={title}
      key="seeDetailsButton"
      onClick={handleClickSeeDetails}
    />,
  ];

  const headerData = (() => {
    switch (itemType) {
      case PlanItemType.Program:
        return {
          title: i18n.t(k.MONEY__TOTAL_COST).toLocaleUpperCase(),
          value: formatCurrency(cost || 0, usersCurrency, exchangeRate),
        };
      case PlanItemType.Finance:
        return {
          title: i18n.t(k.GENERIC__AMOUNT).toLocaleUpperCase(),
          value: !!cost
            ? formatCurrency(cost, usersCurrency, exchangeRate)
            : i18n.t(k.GENERIC__ANY),
        };
      case PlanItemType.Time:
        return null;
    }
  })();

  return (
    <>
      <PlanItemDetailPage
        itemType={itemType}
        id={id}
        visible={showDetailPage}
        onClose={handleCloseDetailPage}
        forAdminInitiative
      />
      <SearchCard
        id={id}
        buttons={buttons}
        description={description}
        headerData={headerData}
        metadata={metadata}
        previewImgSrc={previewImgSrc}
        provider={_provider}
        providerImgSrc={providerImgSrc}
        title={title}
        financeIncentiveType={financeIncentiveType}
        notAvailable={notAvailable}
      />
    </>
  );
}

export default InitiativeCard;
