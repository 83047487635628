import {datadogLogs} from '@datadog/browser-logs';
import {useNavigate} from 'react-router-dom';
import DeleteLevelItem from './DeleteLevelItem';
import {
  getDeleteLevelRm,
  getUpdateAcademyStepsLevelRm,
} from '@store/apiEndpoints/academy/mutations';
import {useMutation} from 'react-query';
import {simpleDeleteFn, simpleMutationFn} from '@store/queryClient';
import {
  CustomProgramAdminSectionVM,
  AcademyLevelStepMovePayload,
} from '@models/serverModels';
import {useAcademyLevelVMsQuery} from '@hooks/apiEndpoints/academy/queries';
import {notify} from '@components/user/notifications';
import {AcademyStepVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface DeleteLevelItemContainerProps {
  onCancel: () => void;
  academyId: number;
  levelId: number;
  moveContentSectionOptions: Pick<
    CustomProgramAdminSectionVM,
    'id' | 'title'
  >[];
  isSectionContentEmpty: boolean;
  steps: AcademyStepVM[];
  getScreenNavItemUrlPath: (levelId: number, _new?: boolean) => string;
}

function DeleteLevelItemContainer({
  onCancel,
  moveContentSectionOptions,
  isSectionContentEmpty,
  levelId,
  steps,
  academyId,
  getScreenNavItemUrlPath,
}: DeleteLevelItemContainerProps) {
  const navigate = useNavigate();
  const {data: levels, refetch: refetchAcademies} = useAcademyLevelVMsQuery({
    academyId,
    excludeUserProgress: true,
  });

  const level = levels?.find(({id}) => id === levelId);

  const moveStepsToOtherLevel = useMutation(
    (args: AcademyLevelStepMovePayload) => {
      const moveStepsRequest = getUpdateAcademyStepsLevelRm(args);
      return simpleMutationFn<null>(
        moveStepsRequest.path,
        moveStepsRequest.payload
      );
    },
    {
      onSuccess() {
        deleteLevelMutation.mutateAsync(levelId);
      },
      onError() {
        datadogLogs.logger.error('Error while moving steps between levels');
      },
    }
  );

  const deleteLevelMutation = useMutation(
    (id: number) => {
      const deleteLevel = getDeleteLevelRm(id);
      return simpleDeleteFn<null>(deleteLevel.path, deleteLevel.payload);
    },
    {
      onSuccess: async () => {
        const {data: levels} = await refetchAcademies();
        const levelToNavigateTo =
          level?.order === 1
            ? levels?.[0]
            : levels?.find(({order}) => order + 1 === level?.order);

        navigate(getScreenNavItemUrlPath(levelToNavigateTo?.id || 0, false));
        notify.deleteAcademyLevelSuccess();
        onCancel();
      },
      onError() {
        datadogLogs.logger.error('Error while deleting level');
      },
    }
  );

  return (
    <DeleteLevelItem
      onCancel={onCancel}
      onOk={(newLevel) => {
        if (newLevel) {
          moveStepsToOtherLevel.mutateAsync({
            oldAcademyLevelId: levelId,
            newAcademyLevelId: newLevel,
            steps: steps.map((s: {id: number}) => s.id),
          });
        } else {
          deleteLevelMutation.mutateAsync(levelId);
        }
      }}
      moveContentSectionOptions={moveContentSectionOptions}
      isSectionContentEmpty={isSectionContentEmpty}
    />
  );
}

export default DeleteLevelItemContainer;
