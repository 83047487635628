import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {notify} from '@components/user/notifications';
import ProgressTable from './ProgressTable';
import {EmptyBlockUntilTrue} from '@components/reusable/EmptyBlockUntilTrue';
import {
  useCustomProgramContentProgressReportQuery,
  useCustomProgramSectionsProgressReportQuery,
} from '@generated/hooks';
import {CustomProgramCurriculumProgressResult} from '@generated/interfaces';
/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  margin: 16px 0px;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface ICustomProgramSectionsProgressReportTableProps {
  dataSource: CustomProgramCurriculumProgressResult[];
  nestedDataSource: Record<number, CustomProgramCurriculumProgressResult[]>;
  onExpandRow: (id: number) => void;
  onClickSectionRow: (record: CustomProgramCurriculumProgressResult) => void;
  onClickContentRow: (record: CustomProgramCurriculumProgressResult) => void;
  onChangeSearchInput?: (value: string) => void;
  searchInputValue?: string;
  canShowNudgeColumn?: boolean;
  onOpenNudgeEmailPreview?: (data: {
    employeeName: string;
    userCompanyId: number;
  }) => void;
}

function CustomProgramSectionsProgressReportTable({
  dataSource,
  nestedDataSource,
  onExpandRow,
  onClickSectionRow,
  onClickContentRow,
  canShowNudgeColumn,
  onOpenNudgeEmailPreview,
}: ICustomProgramSectionsProgressReportTableProps) {
  return (
    <Container>
      <ProgressTable
        canShowNudgeColumn={canShowNudgeColumn}
        onOpenNudgeEmailPreview={onOpenNudgeEmailPreview}
        columnTitle={i18n.t(k.SECTION)}
        dataSource={dataSource}
        onClickRow={onClickSectionRow}
        nestedTable={{
          dataSource: nestedDataSource,
          onClickRow: onClickContentRow,
          onExpand: (
            expanded,
            record: CustomProgramCurriculumProgressResult
          ) => {
            if (expanded) {
              onExpandRow(record?.id);
            }
          },
        }}
      />
    </Container>
  );
}

export default CustomProgramSectionsProgressReportTable;

/*
|--------------------------------------------------------------------------
| Wrapper
|--------------------------------------------------------------------------
*/

interface ICustomProgramSectionsProgressReportTableWrapped {
  customProgramId: number;
  onClickSectionRow: (record: CustomProgramCurriculumProgressResult) => void;
  onClickContentRow: (record: CustomProgramCurriculumProgressResult) => void;
}

export function CustomProgramSectionsProgressReportTableWrapped({
  customProgramId,
  onClickSectionRow,
  onClickContentRow,
}: ICustomProgramSectionsProgressReportTableWrapped) {
  const [sectionId, setSectionId] = useState<number | null>(null);
  const [contentProgressData, setContentProgressData] = useState<
    Record<number, CustomProgramCurriculumProgressResult[]>
  >({});

  // Fetch Progress Data
  const customProgramSectionsProgressReportQuery =
    useCustomProgramSectionsProgressReportQuery(
      {queryParams: {customProgramId}},
      {
        enabled: !!customProgramId,
        onError: () => {
          notify.tableDataLoadingError(
            `${i18n.t(k.SECTION)} ${i18n.t(k.PROGRESS)}`
          );
        },
      }
    );

  useCustomProgramContentProgressReportQuery(
    {queryParams: {customProgramId, sectionId}},
    {
      onSuccess: (data) => {
        setContentProgressData({...contentProgressData, [sectionId]: data});
      },
      enabled: !!sectionId,
      onError: () => {
        notify.tableDataLoadingError(
          `${i18n.t(k.CONTENT)} ${i18n.t(k.PROGRESS)}`
        );
      },
    }
  );

  // Event Handlers
  const handleExpandRow = (sectionId: number) => {
    setSectionId(sectionId);
    // Fetch content progress data
    customProgramSectionsProgressReportQuery.refetch();
  };

  return (
    <EmptyBlockUntilTrue
      condition={!!customProgramSectionsProgressReportQuery.data}
      emptyBody={i18n.t(k.COHORT__EMPTY__DESCRIPTION)}
      emptyHeader={i18n.t(k.COHORT__EMPTY__TITLE)}>
      <CustomProgramSectionsProgressReportTable
        dataSource={customProgramSectionsProgressReportQuery.data}
        onExpandRow={handleExpandRow}
        nestedDataSource={contentProgressData}
        onClickSectionRow={onClickSectionRow}
        onClickContentRow={onClickContentRow}
      />
    </EmptyBlockUntilTrue>
  );
}
