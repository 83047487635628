import {useState} from 'react';
import {useMutation} from 'react-query';
import queryClient, {
  simpleMutationFn,
  simpleDeleteFn,
} from '@store/queryClient';
import {
  getAddCustomProgramContentRm,
  getUpdateCustomProgramContentRm,
  getDeleteCustomProgramContentAttachmentRm,
  getUpdateCustomProgramAttachmentRm,
} from '@store/apiEndpoints/customProgram/mutations';
import {AcademyStepContentModalPayload} from '@models/clientModels';
import {
  AttachmentResponse,
  AttachmentVM,
  NewCustomProgramContentPayload,
  UpdateCustomProgramContentPayload,
} from '@models/serverModels';
import {notify} from '@components/user/notifications';
import useChunkUpload, {getUploadChunkQueryKey} from '@hooks/useChunkUpload';
import useDeleteContent from '@hooks/useDeleteContent';
import {
  ContentType,
  CustomizableLearningCategory,
  SubmissionType,
} from '@generated/enums';
import {
  useCustomProgramAdminSectionVMsQuery,
  useGetCustomProgramContentAttachmentsQuery,
  useGetCustomProgramContentLinksQuery,
} from '@hooks/apiEndpoints/customProgram/queries';
import {useGetTimezonesQuery} from '@hooks/apiEndpoints/timeIncentive/queries';
import {Form} from 'antd';
import useFeatureFlags from '@hooks/useFeatureFlags';
import {AttachmentLinkType} from '@generated/enums';
import {AddDiscussionStepModal} from '@components/reusable/AddDiscussionStepModal/AddDiscussionStepModal';
import {useCustomProgramSectionsQuery, useUserQuery} from '@generated/hooks';
import AddEditContent from '@blocks/AddEditContentModal/AddEditContent';
import {AcademyStepVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export const midnight = '00:00';

export interface UpsertCustomContentModalContainerProps {
  contentType: ContentType;
  customProgramId?: number | undefined;
  description?: string;
  dueDateTimeZone?: string;
  dueDateUtc?: string;
  eventEndDateTimeUtc?: string;
  eventLink?: string;
  eventStartDateTimeUtc?: string;
  eventTimeZone?: string;
  handleSaveAcademies?: (args: AcademyStepContentModalPayload) => void;
  id?: number | undefined;
  isSubmissionTypeEditable?: boolean;
  onCancel: () => void;
  sectionId?: number;
  submissionType?: SubmissionType;
  title?: string;
  visible: boolean;
  noModal?: boolean;
  allowPeerVisibility?: boolean;
}

function UpsertCustomContentModalContainer({
  contentType,
  customProgramId,
  description,
  dueDateTimeZone,
  dueDateUtc,
  eventEndDateTimeUtc,
  eventLink,
  eventStartDateTimeUtc,
  eventTimeZone,
  handleSaveAcademies,
  id,
  isSubmissionTypeEditable = true,
  onCancel,
  sectionId,
  submissionType,
  title,
  visible,
  noModal,
  allowPeerVisibility,
}: UpsertCustomContentModalContainerProps) {
  const [form] = Form.useForm();
  const {isFeatureFlagOn} = useFeatureFlags();
  const [uploadProgress, setUploadProgress] = useState(undefined);

  const {data: user} = useUserQuery(null);

  const {invalidateExact: invalidateSections} = useCustomProgramSectionsQuery(
    {customProgramId},
    {enabled: false}
  );

  const showDiscussionModal = isFeatureFlagOn.CustomProgramDiscussionSteps;

  // Attachment Uploading
  const {chunkUpload, cancelUpload} = useChunkUpload({
    query: getUpdateCustomProgramAttachmentRm,
    customizableLearningCategory: CustomizableLearningCategory.CustomProgram,
    onComplete: () => {
      invalidateSections();
      contentAttachmentsQuery.refetch();
    },
  });

  const queryKey = getUploadChunkQueryKey(id);
  const contentUploadPercent: number | undefined =
    queryClient.getQueryData(queryKey);

  // Deleting Content
  const {handleDeleteContent} = useDeleteContent({
    customProgramId,
    contentId: id,
    contentType,
    onOk: onCancel,
  });

  const timeZoneQuery = useGetTimezonesQuery(null);

  const customProgramSectionsQuery = useCustomProgramAdminSectionVMsQuery(
    customProgramId,
    {enabled: false}
  );

  // Get Content Links and Attachments
  const getCustomProgramLinksQuery = useGetCustomProgramContentLinksQuery(id, {
    placeholderData: () => [],
    enabled: !!id && visible,
  });

  const contentAttachmentsQuery = useGetCustomProgramContentAttachmentsQuery(
    id,
    {
      placeholderData: () => [],
      enabled: !!id && visible,
    }
  );

  // Create and Update Content
  const addCustomProgramContentMutation = useMutation(
    (programContent: NewCustomProgramContentPayload) => {
      const addCustomProgramContentRm =
        getAddCustomProgramContentRm(programContent);
      return simpleMutationFn<number>(
        addCustomProgramContentRm.path,
        addCustomProgramContentRm.payload
      );
    },
    {
      onSuccess: async () => {
        onCancel();
        customProgramSectionsQuery.invalidateExact();
      },
      onError: () => {
        notify.addCustomProgramContentError();
      },
    }
  );

  // Update Content
  const updateCustomProgramContentMutation = useMutation(
    (programContent: UpdateCustomProgramContentPayload) => {
      const updateCustomProgramContentRm =
        getUpdateCustomProgramContentRm(programContent);
      return simpleMutationFn<number>(
        updateCustomProgramContentRm.path,
        updateCustomProgramContentRm.payload
      );
    },
    {
      onSuccess: async () => {
        onCancel();
        notify.updateCustomProgramContentSuccess();
        customProgramSectionsQuery.invalidateExact();
      },
      onError: () => {
        notify.updateCustomProgramContentError();
      },
    }
  );

  const handleOk = async ({
    title: _title,
    description: _description,
    contentType: _contentType,
    dueDate: _dueDate,
    submissionType: _submissionType,
    eventLink: _eventLink,
    startDate: _startDate,
    startTime: _startTime,
    endDate: _endDate,
    endTime: _endTime,
    eventTimeZone: _eventTimeZone,
    link: _link,
    file: _file,
    shouldDeleteAttachment: _shouldDeleteAttachment,
    dueDateTimeZone: _dueDateTimeZone,
    dueTime: _dueTime,
    allowPeerVisibility,
  }: AcademyStepContentModalPayload) => {
    const links = !!_link ? [_link] : [];
    let contentId = id;
    if (!id) {
      // Add Content
      const newContentId = await addCustomProgramContentMutation.mutateAsync({
        customProgramId,
        sectionId,
        contentType: _contentType,
        submissionType: _submissionType,
        eventLink: _eventLink,
        startDate: _startDate,
        startTime: _startTime,
        endDate: _endTime ? _endDate : undefined,
        endTime: _endTime,
        eventTimeZone: !!_startDate || !!_endDate ? _eventTimeZone : undefined,
        title: _title,
        description: _description,
        links,
        dueDate: _dueDate,
        dueDateTimeZone: !!_dueDate ? _dueDateTimeZone : undefined,
        dueTime: _dueTime,
        allowPeerVisibility,
      });
      contentId = newContentId;
    } else {
      // Update Content
      await updateCustomProgramContentMutation.mutateAsync({
        id,
        title: _title,
        description: _description,
        submissionType: _submissionType,
        eventLink: _eventLink,
        startDate: _startDate,
        startTime: _startTime ? _startTime : _startDate ? midnight : undefined,
        endDate: _endTime ? _endDate : undefined,
        endTime: _endTime,
        eventTimeZone: !!_startDate || !!_endDate ? _eventTimeZone : undefined,
        links,
        dueDate: _dueDate,
        dueDateTimeZone: !!_dueDate ? _dueDateTimeZone : undefined,
        dueTime: _dueTime,
        allowPeerVisibility,
      });
    }
    if (_shouldDeleteAttachment) {
      await deleteContentAttachmentMutation.mutateAsync();
    }
    if (!!_file) {
      chunkUpload(_file, {
        contentId,
        linkType: AttachmentLinkType.CustomProgramContentAttachment,
      });
    }
  };

  const deleteContentAttachmentMutation = useMutation(
    () => {
      const deleteCustomProgramContentAttachmentRm =
        getDeleteCustomProgramContentAttachmentRm(id);
      return simpleDeleteFn<null>(
        deleteCustomProgramContentAttachmentRm.path,
        deleteCustomProgramContentAttachmentRm.payload
      );
    },
    {
      onSuccess: async () => {
        invalidateSections();
        await contentAttachmentsQuery.refetch();
        notify.deleteCustomProgramContentAttachmentSuccess();
      },
      onError: () => {
        notify.deleteCustomProgramContentAttachmentError();
      },
    }
  );

  let attachmentData: AttachmentResponse | AttachmentVM;
  if (!!id) {
    attachmentData = contentAttachmentsQuery.data?.[0];
  }

  return contentType === ContentType.Discussion && showDiscussionModal ? (
    timeZoneQuery.isSuccess && (
      <AddDiscussionStepModal
        showAllowSkip={false}
        timeZones={timeZoneQuery.data}
        handleClose={onCancel}
        onOk={handleOk}
        form={form}
        companyId={user?.companyId}
        initialValues={{
          title,
          description,
          dueDate: dueDateUtc,
          dueDateTimeZone,
        }}
      />
    )
  ) : (
    <AddEditContent
      key={id}
      onCancelUpload={cancelUpload}
      noModal={noModal}
      step={
        {
          id,
          title,
          description,
          eventLink,
          startDateUtc: eventStartDateTimeUtc,
          endDateUtc: eventEndDateTimeUtc,
          eventTimeZone,
          dueDateTimeZone,
          allowPeerVisibility,
          dueDateUtc,
          submissionType,
          isSubmissionTypeEditable,
          links: !!id ? getCustomProgramLinksQuery.data : undefined,
        } as AcademyStepVM
      }
      attachment={attachmentData}
      contentType={contentType}
      onCancel={onCancel}
      onClickDelete={handleDeleteContent}
      onDeleteAttachment={deleteContentAttachmentMutation.mutateAsync}
      onSubmit={handleSaveAcademies ? handleSaveAcademies : handleOk}
      uploadProgress={uploadProgress}
      isLoading={
        addCustomProgramContentMutation.isLoading ||
        updateCustomProgramContentMutation.isLoading
      }
      learningCategory={CustomizableLearningCategory.CustomProgram}
      visible
    />
  );
}

export default UpsertCustomContentModalContainer;
