import {NotificationConfig} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: NotificationConfig

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getNotificationConfigMockData(
  args?: Partial<NotificationConfig>
): NotificationConfig {
  return {
    isEmailEnabled: faker.datatype.boolean(),
    isTextEnabled: faker.datatype.boolean(),
    name: faker.name.fullName(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const NotificationConfigMockData: NotificationConfig = {
  isEmailEnabled: true,
  isTextEnabled: true,
  name: 'Bobby Johnson',
};
