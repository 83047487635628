import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';
import {ApprovedIcon} from '@assets/svg/ApprovedIcon';
import {LinkIcon} from '@assets/link-icon';
import {ClickToJoinDisplayConfig} from './ClickToJoin.model';
import './ClickToJoinShareModal.scss';
import {copyToClipboard} from '@utils/copyToClipboard';
import {notify} from '@components/user/notifications';
import {i18n} from '@i18n/translate';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/
const ModalContainer = styled.div`
  padding: 16px 24px;
  color: ${COLORS.Neutral950};
`;

const ModalTitle = styled.div`
  font-size: ${pxToRem(24)};
`;
const ModalSubHeader = styled.p`
  font-size: ${pxToRem(16)};
  font-weight: 400;
  line-height: ${pxToRem(20)};
  margin: 16px 0px;
`;
const ModalBody = styled.div`
  font-size: ${pxToRem(16)};
  font-weight: 400;
  color: ${COLORS.Neutral800};
`;

interface ClickToJoinShareModalProps {
  ui: ClickToJoinDisplayConfig;
  onClose: () => void;
}

/**
 * Renders a modal for sharing the ClickToJoin participant link for Custom programs, Academies and Cohorts.
 * @param ui - The UI configuration for the ClickToJoin feature.
 */
export function ClickToJoinShareModal({
  ui,
  onClose,
}: ClickToJoinShareModalProps) {
  // Copies the participant link to the clipboard
  async function copyParticipantLink() {
    const done = await copyToClipboard(ui.participantLink);
    done && notify.onLinkCopied();
  }

  return (
    <LearnInModal
      width={400}
      visible={ui.visible}
      tag={ModalTags.Default}
      buttonDisabled={true}
      closable={true}
      maskClosable={true}
      onCancel={onClose}
      footer={null}
      ariaLabel={i18n.t(ui.text.shareLabel)}>
      <ModalContainer>
        <ModalTitle>{i18n.t(ui.text.shareLabel)}</ModalTitle>
        <ModalSubHeader>{i18n.t(ui.text.linkUse)}</ModalSubHeader>
        <ModalBody>
          <div className="ctj-view-access-box">
            <ApprovedIcon style={{width: pxToRem(75)}} />
            <div className="text-container">
              <span className="text-header">{i18n.t(ui.text.accessView)}</span>
              <span className="text">{i18n.t(ui.text.eligibility)}</span>
            </div>
          </div>
          <div className="ctj-button-container">
            <LearnInButton
              tag={ButtonTags.Primary}
              padding={'10px'}
              icon={<LinkIcon fill={COLORS.White} />}
              iconSize={'L'}
              onClick={copyParticipantLink}>
              {i18n.t(ui.text.participantLinkLabel)}
            </LearnInButton>
          </div>
        </ModalBody>
      </ModalContainer>
    </LearnInModal>
  );
}
