import {mutationHookFactory} from './../../utils';

import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyIntegrationController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/integrations/chat/channels 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesIntegrationsChatChannelsDeleteMutation} from '@generated/hooks';
 *
 *  const academiesIntegrationsChatChannelsDelete = useAcademiesIntegrationsChatChannelsDeleteMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesIntegrationsChatChannelsDelete.mutate({
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAcademiesIntegrationsChatChannelsDeleteMutation =
  mutationHookFactory<
    null,
    {academyId: number | string}, // path variables
    null
  >(
    'useAcademiesIntegrationsChatChannelsDeleteMutation',
    'delete',
    ({academyId}) => `academies/${academyId}/integrations/chat/channels`,
    {payloadSchema: undefined}
  );
