import * as React from 'react';
import styled from 'styled-components';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';

const Container = styled.span<{center?: boolean}>`
  .ant-spin-dot {
    display: flex;
  }
  ${({center}) => {
    if (center) {
      return `
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
      `;
    }
  }})}
`;

export default function LoadingSpinner({
  center,
  ...props
}: {
  style?: React.CSSProperties;
  size?: 'small' | 'large' | 'default';
  center?: boolean;
  ['data-testid']?: string;
}) {
  const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />;
  return (
    <Container center={center}>
      <Spin size="large" indicator={antIcon} {...props} />
    </Container>
  );
}
