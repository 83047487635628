import {BalanceRecurType, BudgetAllocationType} from './../enums';
import {UserBudgetSpentBasicInfo} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UserBudgetSpentBasicInfo

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserBudgetSpentBasicInfoMockData(
  args?: Partial<UserBudgetSpentBasicInfo>
): UserBudgetSpentBasicInfo {
  return {
    allocationType: BudgetAllocationType.PreAllocated,
    amountAllocated: faker.datatype.number(),
    balance: faker.datatype.number(),
    employeeName: faker.name.fullName(),
    lastUsed: faker.word.noun(),
    lineOfBusiness: faker.word.noun(),
    preApprovalRequired: faker.datatype.boolean(),
    recurrenceType: BalanceRecurType.NotApplicable,
    totalApproved: faker.datatype.number(),
    totalSpent: faker.datatype.number(),
    userBudgetId: faker.datatype.number(),
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserBudgetSpentBasicInfoMockData: UserBudgetSpentBasicInfo = {
  allocationType: BudgetAllocationType.PreAllocated,
  amountAllocated: 5,
  balance: 123,
  employeeName: 'Bobby Johnson',
  lastUsed: 'string',
  lineOfBusiness: 'string',
  preApprovalRequired: true,
  recurrenceType: BalanceRecurType.NotApplicable,
  totalApproved: 123,
  totalSpent: 123,
  userBudgetId: 1,
  userCompanyId: 1,
};
