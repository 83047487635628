import './NewInitiative.scss';
import StepsProgress, {
  StepsInterface,
} from '../../../../../providers/stepsProgress/StepsProgress';
import TheBasics from './components/theBasics/TheBasics';
import InitiativeProgramsPanel from './components/programs/InitiativeProgramsPanel';
import InitiativeTimeIncentivePanel from './components/timeIncentives/InitiativeTimeIncentivePanel';
import InitiativeFinanceIncentivePanel from './components/financialIncentives/InitiativeFinanceIncentivePanel';
import {InitiativeFlowSteps} from '../../store/interface';
import {FormInstance} from 'antd/lib/form';

import {InitiativePayload} from '@models/serverModels';
import {PlanItemType} from '@generated/enums';

interface Props {
  onUpdateSelectedInitiativeItem: (
    toBeAdded: boolean,
    id: number,
    itemType: PlanItemType
  ) => void;
  initialValue?: InitiativePayload;
  isNewInitiative?: boolean;
  basicForm: FormInstance;
  steps: StepsInterface[];
  onStepChangeRequested: (step: number) => void;
  onBasicsFormDirty: () => void;
  currentStep: number;
  changesAreUnsaved: boolean;
  onUserWantsToNavigateToPathRequest: (path: string) => void;
}

export default function NewInitiative({
  initialValue,
  onUpdateSelectedInitiativeItem,
  isNewInitiative,
  basicForm,
  steps,
  currentStep,
  onStepChangeRequested,
  onBasicsFormDirty,
  onUserWantsToNavigateToPathRequest,
}: Props) {
  const NewInitiativeSubComponents = () => {
    switch (currentStep) {
      case InitiativeFlowSteps.TheBasics:
        return (
          <TheBasics
            onFormDirty={onBasicsFormDirty}
            formInstance={basicForm}
            data={initialValue}
            isVisible={currentStep === InitiativeFlowSteps.TheBasics}
          />
        );
      case InitiativeFlowSteps.Programs:
        return (
          <InitiativeProgramsPanel
            updateSelectedInitiativeItem={onUpdateSelectedInitiativeItem}
            data={initialValue}
          />
        );
      case InitiativeFlowSteps.Time:
        return (
          <InitiativeTimeIncentivePanel
            updateSelectedInitiativeItem={onUpdateSelectedInitiativeItem}
            data={initialValue}
          />
        );
      case InitiativeFlowSteps.Finance:
        return (
          <InitiativeFinanceIncentivePanel
            onUserWantsToNavigateToPathRequest={
              onUserWantsToNavigateToPathRequest
            }
            updateSelectedInitiativeItem={onUpdateSelectedInitiativeItem}
            data={initialValue}
          />
        );
      default:
        break;
    }
  };

  return (
    <>
      <StepsProgress
        current={currentStep}
        canSkip={!isNewInitiative}
        steps={steps}
        setCurrentStep={onStepChangeRequested}
      />

      <div style={{margin: '16px 32px'}}>{NewInitiativeSubComponents()}</div>
    </>
  );
}
