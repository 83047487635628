import React from 'react';
import {Container, ContentCardTitle} from './ContentDisplay.styled';
import {ContentDisplayViewProps} from './ContentDisplay.interfaces';
import {LabelAndIconContainer} from '@components/admin/pages/programs/customPrograms/curriculum/CurriculumScreen.container';
import ContentTypeIcon from '@blocks/ContentTypeIcon';
import {ContentType} from '@generated/enums';

function ContentDisplayView({title, contentType}: ContentDisplayViewProps) {
  return (
    <Container>
      <LabelAndIconContainer marginBottom={'16px'}>
        <ContentTypeIcon contentType={contentType ?? ContentType.Text} />
        <ContentCardTitle>{title}</ContentCardTitle>
      </LabelAndIconContainer>
    </Container>
  );
}

export default ContentDisplayView;
