import {ApprovalsDiscriminator, LanguagePreference} from './../enums';

import {queryHookFactory, addQueryParams} from './../../utils';

import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Emails/EmailController.cs

/*
|--------------------------------------------------------------------------
|  academy/added-as-contributor-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyAddedAsContributorEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyAddedAsContributorEmail,
 *    isLoading: isAcademyAddedAsContributorEmailLoading,
 *    invalidateExact: invalidateAcademyAddedAsContributorEmail,
 *  } = useAcademyAddedAsContributorEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        permissions,
 *        programTitle,
 *        programUrl,
 *        type
 *    }
 *  })
 *
 */

export const useAcademyAddedAsContributorEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      permissions?: string;
      programTitle?: string;
      programUrl?: string;
      type?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useAcademyAddedAsContributorEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/academy/added-as-contributor-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  academy/{academyId:int}/welcome-preview-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyByAcademyIdIntQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyByAcademyIdInt,
 *    isLoading: isAcademyByAcademyIdIntLoading,
 *    invalidateExact: invalidateAcademyByAcademyIdInt,
 *  } = useAcademyByAcademyIdIntQuery({
 *    academyId,
 *      queryParams: {
 *        companyLogoUrl,
 *        forceShowNote,
 *        languagePreference
 *    }
 *  })
 *
 */

export const useAcademyByAcademyIdIntQuery = queryHookFactory<
  {
    academyId: number | string;
    queryParams?: {
      companyLogoUrl?: string;
      forceShowNote?: boolean;
      languagePreference?: LanguagePreference;
    };
  }, // path variables
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useAcademyByAcademyIdIntQuery',
  ({academyId, queryParams}) =>
    addQueryParams(
      ({academyId}) => `emails/academy/${academyId}/welcome-preview-email`,
      {academyId},
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  academy/nudge-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademyNudgeEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: academyNudgeEmail,
 *    isLoading: isAcademyNudgeEmailLoading,
 *    invalidateExact: invalidateAcademyNudgeEmail,
 *  } = useAcademyNudgeEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        note,
 *        subject
 *    }
 *  })
 *
 */

export const useAcademyNudgeEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      note?: string;
      subject?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useAcademyNudgeEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/academy/nudge-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  account/access-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAccountAccessEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: accountAccessEmail,
 *    isLoading: isAccountAccessEmailLoading,
 *    invalidateExact: invalidateAccountAccessEmail,
 *  } = useAccountAccessEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        setPasswordUrl
 *    }
 *  })
 *
 */

export const useAccountAccessEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      setPasswordUrl?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useAccountAccessEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/account/access-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  account/email-confirmation-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAccountEmailConfirmationEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: accountEmailConfirmationEmail,
 *    isLoading: isAccountEmailConfirmationEmailLoading,
 *    invalidateExact: invalidateAccountEmailConfirmationEmail,
 *  } = useAccountEmailConfirmationEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        confirmUrl,
 *        languagePreference,
 *        name
 *    }
 *  })
 *
 */

export const useAccountEmailConfirmationEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      confirmUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useAccountEmailConfirmationEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/account/email-confirmation-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  account/password-changed-confirmation-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAccountPasswordChangedConfirmationEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: accountPasswordChangedConfirmationEmail,
 *    isLoading: isAccountPasswordChangedConfirmationEmailLoading,
 *    invalidateExact: invalidateAccountPasswordChangedConfirmationEmail,
 *  } = useAccountPasswordChangedConfirmationEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        languagePreference,
 *        name
 *    }
 *  })
 *
 */

export const useAccountPasswordChangedConfirmationEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useAccountPasswordChangedConfirmationEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/account/password-changed-confirmation-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  custom-program/nudge-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useCustomProgramNudgeEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: customProgramNudgeEmail,
 *    isLoading: isCustomProgramNudgeEmailLoading,
 *    invalidateExact: invalidateCustomProgramNudgeEmail,
 *  } = useCustomProgramNudgeEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        note,
 *        subject
 *    }
 *  })
 *
 */

export const useCustomProgramNudgeEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      note?: string;
      subject?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useCustomProgramNudgeEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/custom-program/nudge-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  dev/notification-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useDevNotificationEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: devNotificationEmail,
 *    isLoading: isDevNotificationEmailLoading,
 *    invalidateExact: invalidateDevNotificationEmail,
 *  } = useDevNotificationEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        contents,
 *        languagePreference,
 *        name
 *    }
 *  })
 *
 */

export const useDevNotificationEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      contents?: string;
      languagePreference?: LanguagePreference;
      name?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useDevNotificationEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/dev/notification-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  event/reminder-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useEventReminderEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: eventReminderEmail,
 *    isLoading: isEventReminderEmailLoading,
 *    invalidateExact: invalidateEventReminderEmail,
 *  } = useEventReminderEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        eventUrl,
 *        googleEventUrl,
 *        languagePreference,
 *        message,
 *        name,
 *        outlookEventUrl,
 *        parentTitle,
 *        title,
 *        type
 *    }
 *  })
 *
 */

export const useEventReminderEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      eventUrl?: string;
      googleEventUrl?: string;
      languagePreference?: LanguagePreference;
      message?: string;
      name?: string;
      outlookEventUrl?: string;
      parentTitle?: string;
      title?: string;
      type?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useEventReminderEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/event/reminder-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  payment/card-declined-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePaymentCardDeclinedEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: paymentCardDeclinedEmail,
 *    isLoading: isPaymentCardDeclinedEmailLoading,
 *    invalidateExact: invalidatePaymentCardDeclinedEmail,
 *  } = usePaymentCardDeclinedEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        languagePreference,
 *        name
 *    }
 *  })
 *
 */

export const usePaymentCardDeclinedEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'usePaymentCardDeclinedEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/payment/card-declined-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  plan/added-to-plan-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {usePlanAddedToPlanEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: planAddedToPlanEmail,
 *    isLoading: isPlanAddedToPlanEmailLoading,
 *    invalidateExact: invalidatePlanAddedToPlanEmail,
 *  } = usePlanAddedToPlanEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        programTitle,
 *        programUrl
 *    }
 *  })
 *
 */

export const usePlanAddedToPlanEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      programTitle?: string;
      programUrl?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'usePlanAddedToPlanEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/plan/added-to-plan-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  program/completion-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramCompletionEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programCompletionEmail,
 *    isLoading: isProgramCompletionEmailLoading,
 *    invalidateExact: invalidateProgramCompletionEmail,
 *  } = useProgramCompletionEmailQuery({
 *    queryParams: {
 *        adminNote,
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        programTitle,
 *        programUrl
 *    }
 *  })
 *
 */

export const useProgramCompletionEmailQuery = queryHookFactory<
  {
    queryParams?: {
      adminNote?: string;
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      programTitle?: string;
      programUrl?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useProgramCompletionEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/program/completion-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  program/nomination-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramNominationEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programNominationEmail,
 *    isLoading: isProgramNominationEmailLoading,
 *    invalidateExact: invalidateProgramNominationEmail,
 *  } = useProgramNominationEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        nominationUrl,
 *        programName,
 *        providerImageUrl,
 *        providerName
 *    }
 *  })
 *
 */

export const useProgramNominationEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      nominationUrl?: string;
      programName?: string;
      providerImageUrl?: string;
      providerName?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useProgramNominationEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/program/nomination-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  program/nudge-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramNudgeEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programNudgeEmail,
 *    isLoading: isProgramNudgeEmailLoading,
 *    invalidateExact: invalidateProgramNudgeEmail,
 *  } = useProgramNudgeEmailQuery({
 *    queryParams: {
 *        adminNote,
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        programTitle,
 *        programUrl
 *    }
 *  })
 *
 */

export const useProgramNudgeEmailQuery = queryHookFactory<
  {
    queryParams?: {
      adminNote?: string;
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      programTitle?: string;
      programUrl?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useProgramNudgeEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/program/nudge-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  program/welcome-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProgramWelcomeEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: programWelcomeEmail,
 *    isLoading: isProgramWelcomeEmailLoading,
 *    invalidateExact: invalidateProgramWelcomeEmail,
 *  } = useProgramWelcomeEmailQuery({
 *    queryParams: {
 *        adminNote,
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        programTitle,
 *        programUrl
 *    }
 *  })
 *
 */

export const useProgramWelcomeEmailQuery = queryHookFactory<
  {
    queryParams?: {
      adminNote?: string;
      companyLogoUrl?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      programTitle?: string;
      programUrl?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useProgramWelcomeEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/program/welcome-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  project/due-date-reminder-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProjectDueDateReminderEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: projectDueDateReminderEmail,
 *    isLoading: isProjectDueDateReminderEmailLoading,
 *    invalidateExact: invalidateProjectDueDateReminderEmail,
 *  } = useProjectDueDateReminderEmailQuery({
 *    queryParams: {
 *        buttonUrl,
 *        companyLogoUrl,
 *        contentImageUrl,
 *        contentType,
 *        dueDate,
 *        languagePreference,
 *        name,
 *        parentTitle,
 *        parentType,
 *        title
 *    }
 *  })
 *
 */

export const useProjectDueDateReminderEmailQuery = queryHookFactory<
  {
    queryParams?: {
      buttonUrl?: string;
      companyLogoUrl?: string;
      contentImageUrl?: string;
      contentType?: string;
      dueDate?: string;
      languagePreference?: LanguagePreference;
      name?: string;
      parentTitle?: string;
      parentType?: string;
      title?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useProjectDueDateReminderEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/project/due-date-reminder-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  project/submission-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProjectSubmissionEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: projectSubmissionEmail,
 *    isLoading: isProjectSubmissionEmailLoading,
 *    invalidateExact: invalidateProjectSubmissionEmail,
 *  } = useProjectSubmissionEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        employeeName,
 *        languagePreference,
 *        message,
 *        name,
 *        parentTitle,
 *        parentType,
 *        projectUrl,
 *        title
 *    }
 *  })
 *
 */

export const useProjectSubmissionEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      employeeName?: string;
      languagePreference?: LanguagePreference;
      message?: string;
      name?: string;
      parentTitle?: string;
      parentType?: string;
      projectUrl?: string;
      title?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useProjectSubmissionEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/project/submission-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  project/submission-feedback-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useProjectSubmissionFeedbackEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: projectSubmissionFeedbackEmail,
 *    isLoading: isProjectSubmissionFeedbackEmailLoading,
 *    invalidateExact: invalidateProjectSubmissionFeedbackEmail,
 *  } = useProjectSubmissionFeedbackEmailQuery({
 *    queryParams: {
 *        companyLogoUrl,
 *        employeeName,
 *        languagePreference,
 *        message,
 *        name,
 *        parentTitle,
 *        title,
 *        type
 *    }
 *  })
 *
 */

export const useProjectSubmissionFeedbackEmailQuery = queryHookFactory<
  {
    queryParams?: {
      companyLogoUrl?: string;
      employeeName?: string;
      languagePreference?: LanguagePreference;
      message?: string;
      name?: string;
      parentTitle?: string;
      title?: string;
      type?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useProjectSubmissionFeedbackEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/project/submission-feedback-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  request/approval-update-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRequestApprovalUpdateEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: requestApprovalUpdateEmail,
 *    isLoading: isRequestApprovalUpdateEmailLoading,
 *    invalidateExact: invalidateRequestApprovalUpdateEmail,
 *  } = useRequestApprovalUpdateEmailQuery({
 *    queryParams: {
 *        amountRequested,
 *        approvalsDiscriminator,
 *        approved,
 *        approver,
 *        approverNotes,
 *        buttonUrl,
 *        canUsePlb,
 *        daysUntilExpiration,
 *        imageUrl,
 *        isAdditional,
 *        isReimbursement,
 *        itemType,
 *        languagePreference,
 *        name,
 *        provider,
 *        title
 *    }
 *  })
 *
 */

export const useRequestApprovalUpdateEmailQuery = queryHookFactory<
  {
    queryParams?: {
      amountRequested?: string;
      approvalsDiscriminator: ApprovalsDiscriminator;
      approved: boolean;
      approver?: string;
      approverNotes?: string;
      buttonUrl: string;
      canUsePlb: boolean;
      daysUntilExpiration?: string;
      imageUrl?: string;
      isAdditional: boolean;
      isReimbursement: boolean;
      itemType: string;
      languagePreference: LanguagePreference;
      name: string;
      provider: string;
      title: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useRequestApprovalUpdateEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/request/approval-update-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  request/reimbursement-request-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRequestReimbursementRequestEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: requestReimbursementRequestEmail,
 *    isLoading: isRequestReimbursementRequestEmailLoading,
 *    invalidateExact: invalidateRequestReimbursementRequestEmail,
 *  } = useRequestReimbursementRequestEmailQuery({
 *    queryParams: {
 *        amount,
 *        approved,
 *        approverName,
 *        approverNote,
 *        companyLogoUrl,
 *        languagePreference,
 *        name,
 *        programName,
 *        programUrl,
 *        providerImageUrl,
 *        providerName
 *    }
 *  })
 *
 */

export const useRequestReimbursementRequestEmailQuery = queryHookFactory<
  {
    queryParams?: {
      amount: string;
      approved: boolean;
      approverName?: string;
      approverNote?: string;
      companyLogoUrl?: string;
      languagePreference: LanguagePreference;
      name: string;
      programName: string;
      programUrl: string;
      providerImageUrl?: string;
      providerName: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useRequestReimbursementRequestEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/request/reimbursement-request-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  request/requested-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRequestRequestedEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: requestRequestedEmail,
 *    isLoading: isRequestRequestedEmailLoading,
 *    invalidateExact: invalidateRequestRequestedEmail,
 *  } = useRequestRequestedEmailQuery({
 *    queryParams: {
 *        amount,
 *        approvalRank,
 *        buttonUrl,
 *        companyLogoUrl,
 *        itemType,
 *        languagePreference,
 *        name,
 *        programName,
 *        providerImageUrl,
 *        providerName,
 *        requesterName
 *    }
 *  })
 *
 */

export const useRequestRequestedEmailQuery = queryHookFactory<
  {
    queryParams?: {
      amount: string;
      approvalRank?: string;
      buttonUrl: string;
      companyLogoUrl?: string;
      itemType: string;
      languagePreference: LanguagePreference;
      name: string;
      programName: string;
      providerImageUrl?: string;
      providerName: string;
      requesterName: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useRequestRequestedEmailQuery',
  ({queryParams}) =>
    addQueryParams(() => `emails/request/requested-email`, null, queryParams),
  {responseSchema: null}
);

/*
|--------------------------------------------------------------------------
|  request/voucher-request-email 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useRequestVoucherRequestEmailQuery} from '@generated/hooks';
 *
 *  const {
 *    data: requestVoucherRequestEmail,
 *    isLoading: isRequestVoucherRequestEmailLoading,
 *    invalidateExact: invalidateRequestVoucherRequestEmail,
 *  } = useRequestVoucherRequestEmailQuery({
 *    queryParams: {
 *        adminName,
 *        adminNote,
 *        approved,
 *        companyLogoUrl,
 *        languagePreference,
 *        managerName,
 *        managerNote,
 *        name,
 *        programName,
 *        programUrl,
 *        providerImageUrl,
 *        providerName,
 *        redemptionGuidelines,
 *        voucherCode,
 *        voucherUrl
 *    }
 *  })
 *
 */

export const useRequestVoucherRequestEmailQuery = queryHookFactory<
  {
    queryParams?: {
      adminName?: string;
      adminNote?: string;
      approved: boolean;
      companyLogoUrl?: string;
      languagePreference: LanguagePreference;
      managerName?: string;
      managerNote?: string;
      name: string;
      programName: string;
      programUrl: string;
      providerImageUrl?: string;
      providerName: string;
      redemptionGuidelines?: string;
      voucherCode?: string;
      voucherUrl?: string;
    };
  },
  unknown // NO RETURN TYPE SPECIFIED, PLEASE UPDATE ON SERVER
>(
  'useRequestVoucherRequestEmailQuery',
  ({queryParams}) =>
    addQueryParams(
      () => `emails/request/voucher-request-email`,
      null,
      queryParams
    ),
  {responseSchema: null}
);
