import {useEffect, useState} from 'react';
import {AttachmentPreviewImg} from '@blocks/CustomProgramUserDrawer/ContentCard';
import attachmentPreviewImg from '@assets/contentAttachmentPreview.png';

import {Form} from 'antd';
import {useGetAttachmentUrlQuery} from '@hooks/apiEndpoints/reimbursements/queries';
import {getAttachmentURLRq} from '@store/apiEndpoints';
import {simpleQueryFn} from '@store/queryClient';

export const ApprovalImages = (imageIds) => {
  const [attachmentId, setAttachmentId] = useState();
  const [imageArray, setImageArray] = useState([]);
  useGetAttachmentUrlQuery(attachmentId, {
    enabled: !!attachmentId,
    onSuccess(attachment: any) {
      if (!!attachment.url) {
        window.open(attachment.url, '_blank').focus();
        setAttachmentId(undefined);
      }
    },
  });
  const Ids = imageIds.app;

  //get the thumbnails
  useEffect(() => {
    Ids.forEach((image) => {
      const attachmentUrlRq = getAttachmentURLRq(image);
      simpleQueryFn(attachmentUrlRq.path).then((res) => {
        const newImage = {
          id: image,
          url: res.url,
        };
        setImageArray((array) => [...array, newImage]);
      });
    });
  }, [imageIds.app]);
  //set the default image as src for non image files
  const updateSrc = (idx) => {
    setImageArray((imageArray) => {
      const errorImages = [...imageArray];
      errorImages[idx].url = attachmentPreviewImg;
      return errorImages;
    });
  };
  return (
    <Form.Item>
      {imageArray.map((img, idx) => (
        <button
          key={img.id}
          onClick={() => setAttachmentId(img.id)}
          style={{marginLeft: '16px', marginBottom: '10px'}}>
          <AttachmentPreviewImg
            style={{maxHeight: '200px', maxWidth: '200px'}}
            src={img.url}
            onError={() => updateSrc(idx)}
          />
        </button>
      ))}
    </Form.Item>
  );
};
