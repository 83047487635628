import {ReimbursementItemType} from './../enums';
import {ReimbursementPayload} from './../interfaces';
import {
  PreApprovalQuestionMockData,
  getPreApprovalQuestionMockData,
} from './PreApprovalQuestion.mock';
import {faker} from '@faker-js/faker';

// Interface: ReimbursementPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getReimbursementPayloadMockData(
  args?: Partial<ReimbursementPayload>
): ReimbursementPayload {
  return {
    application: [getPreApprovalQuestionMockData()],
    attachmentIds: [String(faker.datatype.number())],
    itemId: faker.datatype.number(),
    reimbursementItemType: ReimbursementItemType.Program,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const ReimbursementPayloadMockData: ReimbursementPayload = {
  application: [PreApprovalQuestionMockData],
  attachmentIds: ['1'],
  itemId: 1,
  reimbursementItemType: ReimbursementItemType.Program,
};
