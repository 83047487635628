import {ReimbursementStatus} from './../enums';
import {UserProgramReimbursementRequest} from './../interfaces';
import {
  IUserRequestBasicInfoMockData,
  getIUserRequestBasicInfoMockData,
} from './IUserRequestBasicInfo.mock';
import {UserRequestMockData, getUserRequestMockData} from './UserRequest.mock';
import {faker} from '@faker-js/faker';

// Interface: UserProgramReimbursementRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUserProgramReimbursementRequestMockData(
  args?: Partial<UserProgramReimbursementRequest>
): UserProgramReimbursementRequest {
  return {
    ...getUserRequestMockData(),
    ...getIUserRequestBasicInfoMockData(),
    reimbursementProgramItemId: faker.datatype.number(),
    reimbursementStatus: ReimbursementStatus.NotPaid,
    userFinanceIncentiveId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UserProgramReimbursementRequestMockData: UserProgramReimbursementRequest =
  {
    ...UserRequestMockData,
    ...IUserRequestBasicInfoMockData,
    reimbursementProgramItemId: 1,
    reimbursementStatus: ReimbursementStatus.NotPaid,
    userFinanceIncentiveId: 1,
  };
