import {AcademyUserStatus} from './../enums';
import {AcademySingleUserPayload} from './../interfaces';
import {
  IAcademyUserBasicInfoMockData,
  getIAcademyUserBasicInfoMockData,
} from './IAcademyUserBasicInfo.mock';
import {faker} from '@faker-js/faker';

// Interface: AcademySingleUserPayload

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAcademySingleUserPayloadMockData(
  args?: Partial<AcademySingleUserPayload>
): AcademySingleUserPayload {
  return {
    ...getIAcademyUserBasicInfoMockData(),
    academyId: faker.datatype.number(),
    status: AcademyUserStatus.InProgress,
    userCompanyId: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AcademySingleUserPayloadMockData: AcademySingleUserPayload = {
  ...IAcademyUserBasicInfoMockData,
  academyId: 1,
  status: AcademyUserStatus.InProgress,
  userCompanyId: 1,
};
