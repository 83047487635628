import {AttachmentVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AttachmentVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAttachmentVMMockData(
  args?: Partial<AttachmentVM>
): AttachmentVM {
  return {
    description: faker.lorem.lines(),
    fileName: faker.name.fullName(),
    id: faker.datatype.number(),
    image: faker.word.noun(),
    type: faker.word.noun(),
    url: faker.internet.url(), // optional
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AttachmentVMMockData: AttachmentVM = {
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  fileName: 'Bobby Johnson',
  id: 1,
  image: 'string',
  type: 'string',
  url: 'https://this-person-does-not-exist.com/en', // optional
};
