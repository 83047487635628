// Do not modify directly
// Generated from file: learnin.core.dal/Core/Models/Enums/CompanySetting.cs

enum CompanySetting {
  LxpDomain = 1,
  LxpAcademyIntegrationEnabled,
  LxpContentMarketplaceIntegrationEnabled,
  LxpEventHubEnabled,
  LxpSsoProvider,
  LxpSsoTenantId,
}

export default CompanySetting;
