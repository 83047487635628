import {
  BalanceRecurType,
  BudgetAllocationType,
  BudgetType,
  FinanceRecurrence,
  LearningResourceType,
} from './../enums';
import {Budget} from './../interfaces';
import {
  ApprovalConfigMockData,
  getApprovalConfigMockData,
} from './ApprovalConfig.mock';
import {BaseEntityMockData, getBaseEntityMockData} from './BaseEntity.mock';
import {
  IBudgetToOldFinanceIncentiveEnumMockData,
  getIBudgetToOldFinanceIncentiveEnumMockData,
} from './IBudgetToOldFinanceIncentiveEnum.mock';
import {faker} from '@faker-js/faker';

// Interface: Budget

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getBudgetMockData(args?: Partial<Budget>): Budget {
  return {
    ...getBaseEntityMockData(),
    ...getIBudgetToOldFinanceIncentiveEnumMockData(),
    about: faker.word.noun(),
    active: faker.datatype.boolean(),
    allocationType: BudgetAllocationType.PreAllocated,
    allowApprovedItemsCancellation: faker.datatype.boolean(),
    allowDirectBilling: faker.datatype.boolean(),
    allowedResources: [LearningResourceType.Book],
    allowExternalReimbursement: faker.datatype.boolean(),
    allowReimbursement: faker.datatype.boolean(),
    allowVirtualCard: faker.datatype.boolean(),
    approvalConfig: getApprovalConfigMockData(),
    approvalExpirationDays: faker.datatype.number(), // optional
    approvalGuidelines: faker.word.noun(),
    balanceRecurType: BalanceRecurType.NotApplicable,
    companyId: faker.datatype.number(),
    description: faker.lorem.lines(),
    fiscalStartDate: String(faker.date.past()),
    id: faker.datatype.number(),
    limit: faker.datatype.number(),
    migratedData: faker.datatype.boolean(),
    oldFinanceIncentiveId: faker.datatype.number(), // optional
    proratingEnabled: faker.datatype.boolean(),
    recurrence: FinanceRecurrence.Never,
    title: faker.company.catchPhrase(),
    type: BudgetType.Personal,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const BudgetMockData: Budget = {
  ...BaseEntityMockData,
  ...IBudgetToOldFinanceIncentiveEnumMockData,
  about: 'string',
  active: true,
  allocationType: BudgetAllocationType.PreAllocated,
  allowApprovedItemsCancellation: true,
  allowDirectBilling: true,
  allowedResources: [LearningResourceType.Book],
  allowExternalReimbursement: true,
  allowReimbursement: true,
  allowVirtualCard: true,
  approvalConfig: ApprovalConfigMockData,
  approvalExpirationDays: 123, // optional
  approvalGuidelines: 'string',
  balanceRecurType: BalanceRecurType.NotApplicable,
  companyId: 1,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  fiscalStartDate: '2023-03-29T22:17:19.738072',
  id: 1,
  limit: 123,
  migratedData: true,
  oldFinanceIncentiveId: 1, // optional
  proratingEnabled: true,
  recurrence: FinanceRecurrence.Never,
  title: 'Advanced Web Design',
  type: BudgetType.Personal,
};
