import * as React from 'react';
import styled, {DefaultTheme} from 'styled-components';
import {COLORS} from '@utils/constants';
import {NewWindowIcon} from '@assets/new-window-icon';
import Icon from '@blocks/Icon/Icon';

const ContentLinkContainer = styled.a`
  align-items: center;
  color: ${COLORS.Blue800};
  display: flex;
  font-weight: 500;
  font-size: 1rem;
  gap: 0.5rem;
  word-break: break-all;

  svg {
    width: 18px;
  }
  &:focus-visible,
  &:hover,
  svg:hover {
    svg {
      color: ${COLORS.Blue800};
    }
  }
  &:focus {
    outline: 2px solid ${(props: DefaultTheme) => props.itemDefaultTextNavColor};
    border-radius: 3px;
  }
`;

interface Props {
  ariaLabel?: string;
  url: string;
  title: string;
  onClick?: (e?: Event) => void;
  style?: React.CSSProperties;
}

const ContentLink = ({ariaLabel, url, title, style}: Props) => (
  <ContentLinkContainer
    tabIndex={0}
    aria-label={ariaLabel}
    target="_blank"
    href={url}
    style={style}
    rel="noopener noreferrer">
    <Icon color={COLORS.Neutral900} icon={<NewWindowIcon />} />
    {title}
  </ContentLinkContainer>
);

export default ContentLink;
