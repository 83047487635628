import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInInput} from '@components/reusable/LearnInForm';
import {CloseCircleOutlined, EditOutlined} from '@ant-design/icons';
import {COLORS, KEY_CODES} from '@utils/constants';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/../components/reusable/Button/ButtonEnums';
import ContextMenuButton from '@blocks/ContextMenu';
import CurriculumScreenNewContentButton from './CurriculumScreenNewContentButton';
import {ContentType} from '@generated/enums';
import {LearnInToggleOption} from '@components/reusable/LearnInToggle/LearnInToggle.interfaces';
import LearnInToggle from '@components/reusable/LearnInToggle';
import {Breakpoints} from '@utils/responsiveUtils';
import {LearnInTooltip} from '@components/reusable/Tooltip';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  margin-bottom: 24px;
  padding-left: ${({showFullPageForm}) => !showFullPageForm && '51px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  button {
    &:focus-visible {
      outline: 2px solid ${COLORS.Blue950};
      cursor: pointer;
    }
  }
  .ant-input-group-wrapper,
  .ant-input-group-addon:last-child {
    background: none;
    border: none;
    padding: 0;
  }
  @media (max-width: ${({showSectionTitleEdit}) =>
      showSectionTitleEdit ? Breakpoints.Desktop : Breakpoints.Tablet}px) {
    flex-direction: column;
  }
`;

const ProgramTitleButton = styled.button`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral950};
  cursor: pointer;
  &:hover {
    color: ${COLORS.Blue800};
  }
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export interface CurriculumScreenHeaderProps {
  title: string;
  onUpdateTitle: (title: string) => void;
  onClickAddContent: (contentType: ContentType) => void;
  moveSectionToTop: () => void;
  moveSectionToBottom: () => void;
  onClickDeleteSection: () => void;
  showSectionTitleEdit: boolean;
  setShowSectionTitleEdit: (show: boolean) => void;
  _title: string;
  setTitle: (title: string) => void;
  showEditTitleInput: (title: string) => void;
  displayOptions: LearnInToggleOption[];
  onToggleDisplayOptions: (option: LearnInToggleOption) => void;
  showFullPageToggle: boolean;
  showAddContentButton: boolean;
  showFullPageForm: boolean;
}

function CurriculumScreenHeader({
  title,
  onUpdateTitle,
  onClickAddContent,
  moveSectionToTop,
  moveSectionToBottom,
  onClickDeleteSection,
  showSectionTitleEdit,
  setShowSectionTitleEdit,
  _title,
  setTitle,
  showEditTitleInput,
  showFullPageToggle,
  displayOptions,
  onToggleDisplayOptions,
  showAddContentButton,
  showFullPageForm,
}: CurriculumScreenHeaderProps) {
  const curriculumScreenHeaderId = `curriculum-screen-header`;

  return (
    <Container
      id={curriculumScreenHeaderId}
      showSectionTitleEdit={showSectionTitleEdit}
      showFullPageForm={showFullPageForm}>
      {showSectionTitleEdit ? (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <LearnInInput
            id="edit-title-input"
            value={_title}
            updateFunc={setTitle}
            style={{width: '360px'}}
            onKeyPress={async (e) => {
              if (e.key === KEY_CODES.ENTER) {
                onUpdateTitle(_title);
                setShowSectionTitleEdit(false);
              } else if (e.key === KEY_CODES.ESCAPE) {
                setShowSectionTitleEdit(false);
              }
            }}
            onBlur={async () => onUpdateTitle(_title)}
            addonAfter={
              <LearnInButton
                style={{marginLeft: '8px'}}
                padding={'0'}
                tag={ButtonTags.SecondaryBlackRemovedBorder}
                icon={<CloseCircleOutlined aria-hidden="true" />}
                onClick={() => setShowSectionTitleEdit(false)}
              />
            }
          />
        </div>
      ) : (
        <LearnInTooltip
          title={i18n.t(k.CTA__EDIT_ITEM__FORMAT, {
            item: i18n.t(k.SECTION__TITLE),
          })}
          childHasButton={true}>
          <ProgramTitleButton
            data-testid="edit-title-button-custom-programs"
            aria-label={i18n.t(k.CTA__EDIT_ITEM__FORMAT, {
              item: i18n.t(k.SECTION__TITLE),
            })}
            onClick={() => showEditTitleInput(title)}>
            {title}
            <EditOutlined
              aria-hidden="true"
              style={{marginLeft: '9px', color: COLORS.Blue800}}
            />
          </ProgramTitleButton>
        </LearnInTooltip>
      )}
      <div style={{display: 'flex', alignItems: 'center'}}>
        {showFullPageToggle && (
          <div style={{marginRight: 12}}>
            <LearnInToggle
              options={displayOptions}
              onToggle={onToggleDisplayOptions}
            />
          </div>
        )}

        {showAddContentButton && (
          <div style={{marginRight: '9px'}}>
            <CurriculumScreenNewContentButton
              onClickContent={onClickAddContent}
            />
          </div>
        )}

        <div>
          <ContextMenuButton
            size="large"
            popupContainerId={curriculumScreenHeaderId}
            itemId={curriculumScreenHeaderId}
            menuItems={[
              {
                label: i18n.t(k.SECTION__RENAME),
                noBottomBorder: true,
                onClick: () => showEditTitleInput(title),
              },
              {
                label: i18n.t(k.SECTION__TO_BOTTOM),
                noBottomBorder: true,
                onClick: moveSectionToBottom,
              },
              {
                label: i18n.t(k.SECTION__TO_TOP),
                onClick: moveSectionToTop,
              },
              {
                label: (
                  <span style={{color: COLORS.Red800}}>
                    {i18n.t(k.SECTION__DELETE)}
                  </span>
                ),
                onClick: onClickDeleteSection,
              },
            ]}
          />
        </div>
      </div>
    </Container>
  );
}

export default CurriculumScreenHeader;
