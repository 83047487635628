import {i18n, k} from '@i18n/translate';
import {
  PlanItemTypeWithCustomProgram,
  UserPlanCardActions,
  UserPlanItemStatus,
  UserPlanItemStatusString,
} from '@models/clientEnums';
import {Menu} from 'antd';
import {mapStringToUserPlanCardActions} from '@utils/enumMapping/UserPlanCardActionsMapper';
import {TagStyles} from '@blocks/LearnInTag';
import {PlanItemType} from '@generated/enums';
import {PlanItem} from '@models/clientModels';

export interface ActionMenuItemInfoType {
  itemType: PlanItemType;
  id: number | string;
  status: UserPlanItemStatusString;
  programApprovalRequired: boolean | undefined;
  requestedAmount?: number;
  cost?: number;
  licenseId?: number;
  isLicenseProgram?: boolean;
  daysUntilExpiration?: number;
  isReimbursementPlan?: boolean;
  purchaseMade?: boolean;
}

interface UserPlanCardActionMenuProps {
  item: ActionMenuItemInfoType;
  onSettings: (value: UserPlanCardActions) => void;
}

interface GearItem {
  key: string;
  displayStr: string;
}

interface ActionMenuData {
  status: UserPlanItemStatus;
  displayString: string;
  gearItems?: GearItem[];
  tagStyle: TagStyles;
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export default function UserPlanCardActionMenu({
  item,
  onSettings,
}: UserPlanCardActionMenuProps) {
  const menuDetails = convertItemDetailsToActionMenu(
    item.status,
    item.itemType,
    item.licenseId,
    item?.programApprovalRequired,
    item.purchaseMade,
    !!item.requestedAmount || item?.cost === 0,
    item.daysUntilExpiration
  );
  if (!menuDetails.gearItems) {
    return null;
  }

  return (
    <Menu>
      {menuDetails.gearItems.map((menuItem) => {
        return (
          <Menu.Item
            key={menuItem.key}
            id={`${menuItem.displayStr.toLowerCase()}-action-${item.id}`}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onSettings(mapStringToUserPlanCardActions(menuItem.key));
              }}>
              {menuItem.displayStr}
            </button>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}

/*
|--------------------------------------------------------------------------
| External Helper Functions
|--------------------------------------------------------------------------
*/

export function UserPlanCardActionMenuTest({
  item,
  onSettings,
}: UserPlanCardActionMenuProps) {
  const menuDetails = convertItemDetailsToActionMenu(
    item.status,
    item.itemType,
    item.licenseId,
    item?.programApprovalRequired,
    item.purchaseMade,
    !!item.requestedAmount || item?.cost === 0,
    item.daysUntilExpiration
  );
  if (!menuDetails.gearItems) {
    return null;
  }
  const mapToMenuItems = (menuItem) => ({
    label: menuItem.displayStr,
    onClick: () => onSettings(mapStringToUserPlanCardActions(menuItem.key)),
  });

  const menuItems = item.purchaseMade
    ? menuDetails.gearItems.filter((menuItem) => menuItem.key !== 'remove')
    : menuDetails.gearItems;

  return menuItems.map(mapToMenuItems);
}

export function hasMenuItem(
  status: string,
  itemType: PlanItemType,
  approvalRequired: boolean | undefined,
  licenseId?: number,
  purchaseMade?: boolean
): boolean {
  return (
    convertItemDetailsToActionMenu(
      status,
      itemType,
      licenseId,
      approvalRequired,
      purchaseMade
    ).gearItems.length > 0
  );
}

export function convertItemDetailsToActionMenu(
  status: string,
  itemType: PlanItem,
  licenseId?: number,
  approvalRequired?: boolean,
  purchaseMade?: boolean,
  additionalFundsRequested?: boolean,
  daysUntilExpiration?: number | null,
  isReimbursementPlan?: boolean
): ActionMenuData {
  switch (itemType) {
    case PlanItemTypeWithCustomProgram.MarketplaceProgram:
    case PlanItemTypeWithCustomProgram.ProgramLicense:
    case PlanItemTypeWithCustomProgram.CustomProgram:
      return getActionDetailMenuForProgram(
        status as UserPlanItemStatusString,
        itemType,
        approvalRequired,
        licenseId,
        additionalFundsRequested,
        daysUntilExpiration,
        isReimbursementPlan,
        purchaseMade
      );
    case PlanItemTypeWithCustomProgram.Finance:
    case PlanItemTypeWithCustomProgram.Time:
      return getActionDetailMenuForFinanceOrTime(
        status as UserPlanItemStatusString
      );
    default:
      console.error(
        'Unsure how to convertItemDetailsToActionMenu for this type'
      );
      return createActionMenuData(
        UserPlanItemStatus.Pending,
        i18n.t(k.GENERIC__N_A),
        [],
        TagStyles.LightGrey
      );
  }
}

const getItemStatus = (daysUntilExpiration, status) =>
  daysUntilExpiration < 0 ? i18n.t(k.STATUS__EXPIRED) : status;

export const getApplicationStatusTagStyle = (item: ActionMenuItemInfoType) => {
  const convertStatus = convertItemDetailsToActionMenu(
    getItemStatus(item.daysUntilExpiration, item.status),
    item.itemType,
    item.licenseId,
    item?.programApprovalRequired,
    item?.purchaseMade,
    !!item.requestedAmount,
    item?.daysUntilExpiration,
    item?.isReimbursementPlan
  );
  return convertStatus.tagStyle;
};

export const getApplicationStatusTitle = (
  status: string,
  itemType: PlanItemType,
  licenseId: number,
  programApprovalRequired: boolean | null,
  daysUntilExpiration = 0,
  purchaseMade: boolean
) => {
  const convertStatus = convertItemDetailsToActionMenu(
    getItemStatus(daysUntilExpiration, status),
    itemType,
    licenseId,
    programApprovalRequired,
    purchaseMade
  );
  return convertStatus.displayString;
};

export const shouldShowExpandedCardView = (
  itemType: PlanItem,
  status: UserPlanItemStatusString
): boolean => {
  switch (itemType) {
    case PlanItemTypeWithCustomProgram.CustomProgram:
      return true;
    case PlanItemTypeWithCustomProgram.MarketplaceProgram:
      switch (status) {
        case UserPlanItemStatusString.Complete:
          return true;
        default:
          return false;
      }
    case PlanItemTypeWithCustomProgram.Time:
      switch (status) {
        case UserPlanItemStatusString.Approved:
        case UserPlanItemStatusString.NotSubmitted:
        case UserPlanItemStatusString.Submitted:
        case UserPlanItemStatusString.ScheduleApproved:
        case UserPlanItemStatusString.Complete:
          return true;
        default:
          return false;
      }
    case PlanItemTypeWithCustomProgram.Finance:
      switch (status) {
        case UserPlanItemStatusString.Approved:
        case UserPlanItemStatusString.PendingUse:
        case UserPlanItemStatusString.ReimbursementSubmitted:
        case UserPlanItemStatusString.ReimbursementApproved:
        case UserPlanItemStatusString.ReimbursementNeedsReview:
        case UserPlanItemStatusString.ReimbursementPaymentPending:
        case UserPlanItemStatusString.ReimbursementPaid:
          return true;
        default:
          return false;
      }
    default:
      return false;
  }
};

/*
|--------------------------------------------------------------------------
| Internal Helper Functions
|--------------------------------------------------------------------------
*/

function createActionMenuData(
  status: UserPlanItemStatus,
  displayString: string,
  gearItems: GearItem[],
  tagStyle: TagStyles
) {
  return {
    status,
    displayString,
    gearItems,
    tagStyle,
  };
}

function getActionDetailMenuForProgram(
  status: UserPlanItemStatusString,
  itemType: PlanItemTypeWithCustomProgram,
  approvalRequired: boolean,
  licenseId: number,
  additionalFundsRequested?: boolean,
  daysUntilExpiration?: number | null,
  isReimbursementPlan?: boolean,
  purchaseMade?: boolean
): ActionMenuData {
  // BEN-3696: Please fix "Rules of Hooks" issue. React Hook "useTreatment" is called in function "getActionDetailMenuForProgram" that is neither a React function component nor a custom React Hook function.

  const isExpired = daysUntilExpiration && daysUntilExpiration < 0;

  switch (status) {
    case UserPlanItemStatusString.Pending: {
      const actions = [
        {
          key: 'remove',
          displayStr: i18n.t(k.PROGRAM__REMOVE),
        },
      ];
      addDefaultMarketPlaceItems(itemType, actions);
      return approvalRequired
        ? createActionMenuData(
            UserPlanItemStatus.Pending,
            i18n.t(k.STATUS__AWAITING_APPROVAL),
            actions,
            TagStyles.Yellow
          )
        : createActionMenuData(
            UserPlanItemStatus.Pending,
            i18n.t(k.STATUS__READY_TO_APPLY),
            actions,
            TagStyles.LightGrey
          );
    }
    case UserPlanItemStatusString.Approved: {
      const actions = [];

      if (!purchaseMade) {
        actions.push({
          key: 'remove',
          displayStr: i18n.t(k.PROGRAM__REMOVE),
        });
      }

      if (itemType === PlanItemTypeWithCustomProgram.Program) {
        actions.push({
          key: 'complete',
          displayStr: i18n.t(k.CTA__MARK_COMPLETE),
        });
      }

      if (daysUntilExpiration >= 0 && licenseId) {
        actions.unshift({
          key: 'viewLicense',
          displayStr: i18n.t(k.LICENSE__VIEW),
        });
      }
      if (!additionalFundsRequested && !licenseId && !isReimbursementPlan) {
        actions.unshift({
          key: 'request',
          displayStr: i18n.t(k.FUND__REQUEST__ADDITIONAL),
        });
      }
      addDefaultMarketPlaceItems(itemType, actions);
      return createActionMenuData(
        UserPlanItemStatus.Approved,
        approvalRequired
          ? i18n.t(k.STATUS__APPROVED)
          : i18n.t(k.STATUS__READY_TO_START),
        actions,
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Rejected: {
      const actions = [
        {
          key: 'remove',
          displayStr: i18n.t(k.PROGRAM__REMOVE),
        },
      ];
      addDefaultMarketPlaceItems(itemType, actions);
      return createActionMenuData(
        UserPlanItemStatus.Rejected,
        i18n.t(k.STATUS__DECLINED),
        actions,
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.Cancelled: {
      return createActionMenuData(
        UserPlanItemStatus.Cancelled,
        i18n.t(k.STATUS__CANCELLED),
        [],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.Requested: {
      const actions = [
        {
          key: 'remove',
          displayStr: i18n.t(k.PROGRAM__REMOVE),
        },
      ];
      addDefaultMarketPlaceItems(itemType, actions);
      return createActionMenuData(
        UserPlanItemStatus.Requested,
        i18n.t(k.STATUS__AWAITING_APPROVAL),
        actions,
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.Applied: {
      const actions = [
        {
          key: 'remove',
          displayStr: i18n.t(k.PROGRAM__REMOVE),
        },
      ];
      addDefaultMarketPlaceItems(itemType, actions);
      return createActionMenuData(
        UserPlanItemStatus.Applied,
        i18n.t(k.STATUS__APPLIED),
        actions,
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.Admitted: {
      return createActionMenuData(
        UserPlanItemStatus.Admitted,
        i18n.t(k.STATUS__ADMITTED),
        [
          {key: 'remove', displayStr: i18n.t(k.CTA__REMOVE)},
          {
            key: 'website',
            displayStr: i18n.t(k.PROGRAM__WEBSITE__VIEW),
          },
        ],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Denied: {
      const actions = [
        {
          key: 'remove',
          displayStr: i18n.t(k.PROGRAM__REMOVE),
        },
      ];
      addDefaultMarketPlaceItems(itemType, actions);
      return createActionMenuData(
        UserPlanItemStatus.Denied,
        i18n.t(k.STATUS__DENIED),
        actions,
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.InProgress: {
      const actions = [
        {
          key: 'remove',
          displayStr: i18n.t(k.PROGRAM__REMOVE),
        },
        {key: 'complete', displayStr: i18n.t(k.CTA__MARK_COMPLETE)},
      ];
      if (!additionalFundsRequested && !licenseId && !isReimbursementPlan) {
        actions.unshift({
          key: 'request',
          displayStr: i18n.t(k.FUND__REQUEST__ADDITIONAL),
        });
      }
      addDefaultMarketPlaceItems(itemType, actions);
      return createActionMenuData(
        UserPlanItemStatus.InProgress,
        i18n.t(k.STATUS__IN_PROGRESS),
        actions,
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Complete: {
      return createActionMenuData(
        UserPlanItemStatus.Complete,
        i18n.t(k.STATUS__COMPLETE),
        [],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.NotSubmitted: {
      return createActionMenuData(
        UserPlanItemStatus.NotSubmitted,
        i18n.t(k.STATUS__NOT_SUBMITTED),
        [],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Submitted: {
      return createActionMenuData(
        UserPlanItemStatus.Submitted,
        i18n.t(k.STATUS__SCHEDULE_AWAITING_APPROVAL),
        [],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.ScheduleApproved: {
      return createActionMenuData(
        UserPlanItemStatus.ScheduleApproved,
        i18n.t(k.STATUS__SCHEDULE_APPROVED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.ScheduleRejected: {
      return createActionMenuData(
        UserPlanItemStatus.ScheduleRejected,
        i18n.t(k.STATUS__SCHEDULE_NOT_APPROVED),
        [{key: 'remove', displayStr: i18n.t(k.CTA__REMOVE)}],
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.PendingUse: {
      return createActionMenuData(
        UserPlanItemStatus.PendingUse,
        i18n.t(k.STATUS__APPROVED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Expired: {
      return createActionMenuData(
        UserPlanItemStatus.Expired,
        i18n.t(k.STATUS__EXPIRED),
        [
          {key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)},
          {key: 'remove', displayStr: i18n.t(k.CTA__REMOVE)},
        ],
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.ReimbursementSubmitted: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementSubmitted,
        i18n.t(k.REIMBURSEMENT__STATUS__SUBMITTED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.ReimbursementApproved: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementApproved,
        i18n.t(k.REIMBURSEMENT__STATUS__APPROVED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.ReimbursementNeedsReview: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementNeedsReview,
        i18n.t(k.REIMBURSEMENT__STATUS__NEEDS_REVIEW),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.ReimbursementPaymentPending: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementPaymentPending,
        i18n.t(k.STATUS__PAYMENT_PENDING),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.ReimbursementPaid: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementPaid,
        i18n.t(k.REIMBURSEMENT__STATUS__PAID),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    default: {
      return createActionMenuData(
        UserPlanItemStatus.Pending,
        i18n.t(k.STATUS__READY_TO_APPLY),
        [{key: 'remove', displayStr: i18n.t(k.CTA__REMOVE)}],
        TagStyles.LightGrey
      );
    }
  }
}

function addDefaultMarketPlaceItems(
  itemType: PlanItem,
  actions: {key: string; displayStr: string}[]
) {
  if (itemType === PlanItemType.Program) {
    actions.push({
      key: 'details',
      displayStr: i18n.t(k.PROGRAM__DETAILS__VIEW),
    });
    actions.push({
      key: 'website',
      displayStr: i18n.t(k.PROGRAM__WEBSITE__VIEW),
    });
  }
}

function getActionDetailMenuForFinanceOrTime(
  status: UserPlanItemStatusString
): ActionMenuData {
  switch (status) {
    case UserPlanItemStatusString.Pending: {
      return createActionMenuData(
        UserPlanItemStatus.Pending,
        i18n.t(k.STATUS__READY_TO_APPLY),
        [{key: 'remove', displayStr: i18n.t(k.CTA__REMOVE)}],
        TagStyles.LightGrey
      );
    }
    case UserPlanItemStatusString.Approved: {
      return createActionMenuData(
        UserPlanItemStatus.Approved,
        i18n.t(k.STATUS__APPROVED),
        [],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Rejected: {
      return createActionMenuData(
        UserPlanItemStatus.Rejected,
        i18n.t(k.STATUS__DECLINED),
        [],
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.Cancelled: {
      return createActionMenuData(
        UserPlanItemStatus.Cancelled,
        i18n.t(k.STATUS__CANCELLED),
        [],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.Requested: {
      return createActionMenuData(
        UserPlanItemStatus.Requested,
        i18n.t(k.STATUS__AWAITING_APPROVAL),
        [],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.Applied: {
      return createActionMenuData(
        UserPlanItemStatus.Applied,
        i18n.t(k.STATUS__APPLIED),
        [],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.Admitted: {
      return createActionMenuData(
        UserPlanItemStatus.Admitted,
        i18n.t(k.STATUS__ADMITTED),
        [],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Denied: {
      return createActionMenuData(
        UserPlanItemStatus.Denied,
        i18n.t(k.STATUS__DENIED),
        [],
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.InProgress: {
      return createActionMenuData(
        UserPlanItemStatus.InProgress,
        i18n.t(k.STATUS__IN_PROGRESS),
        [
          {
            key: 'remove',
            displayStr: i18n.t(k.PROGRAM__REMOVE),
          },
        ],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Complete: {
      return createActionMenuData(
        UserPlanItemStatus.Complete,
        i18n.t(k.STATUS__COMPLETE),
        [],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.NotSubmitted: {
      return createActionMenuData(
        UserPlanItemStatus.NotSubmitted,
        i18n.t(k.STATUS__APPROVED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Submitted: {
      return createActionMenuData(
        UserPlanItemStatus.Submitted,
        i18n.t(k.STATUS__SCHEDULE_AWAITING_APPROVAL),
        [],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.ScheduleApproved: {
      return createActionMenuData(
        UserPlanItemStatus.ScheduleApproved,
        i18n.t(k.STATUS__SCHEDULE_APPROVED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.ScheduleRejected: {
      return createActionMenuData(
        UserPlanItemStatus.ScheduleRejected,
        i18n.t(k.STATUS__SCHEDULE_NOT_APPROVED),
        [],
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.PendingUse: {
      return createActionMenuData(
        UserPlanItemStatus.PendingUse,
        i18n.t(k.STATUS__APPROVED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.Expired: {
      return createActionMenuData(
        UserPlanItemStatus.Expired,
        i18n.t(k.STATUS__EXPIRED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.ReimbursementSubmitted: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementSubmitted,
        i18n.t(k.REIMBURSEMENT__STATUS__SUBMITTED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.ReimbursementApproved: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementApproved,
        i18n.t(k.REIMBURSEMENT__STATUS__APPROVED),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    case UserPlanItemStatusString.ReimbursementNeedsReview: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementNeedsReview,
        i18n.t(k.REIMBURSEMENT__STATUS__NEEDS_REVIEW),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Red
      );
    }
    case UserPlanItemStatusString.ReimbursementPaymentPending: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementPaymentPending,
        i18n.t(k.STATUS__PAYMENT_PENDING),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Yellow
      );
    }
    case UserPlanItemStatusString.ReimbursementPaid: {
      return createActionMenuData(
        UserPlanItemStatus.ReimbursementPaid,
        i18n.t(k.REIMBURSEMENT__STATUS__PAID),
        [{key: 'finished', displayStr: i18n.t(k.STATUS__FINISHED)}],
        TagStyles.Green
      );
    }
    default: {
      return createActionMenuData(
        UserPlanItemStatus.Pending,
        i18n.t(k.STATUS__READY_TO_APPLY),
        [],
        TagStyles.LightGrey
      );
    }
  }
}
