import './PopularProgramList.scss';
import {Fragment} from 'react';
import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Row, Divider, Skeleton} from 'antd';
import {AdminReportsTabName} from '../../../../store/interface';
import ProviderLogo from '@blocks/ProviderLogo';
import {PopularProgramVM} from '@models/serverModels';
import {ViewProgramLink} from '../../../ViewProgramLink';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';

const ViewMoreLink = styled.button`
  color: ${COLORS.Blue800} !important;
`;
interface Props {
  onTabChange: (tabName: any) => void;
  popularProgramData: PopularProgramVM[];
  popularProgramLoading: boolean;
}

const PopularProgramList = ({
  onTabChange,
  popularProgramData,
  popularProgramLoading,
}: Props) => {
  return (
    <Fragment>
      <Card
        title={i18n.t(k.REPORT__POPULAR_PROGRAM__PLURAL)}
        role="figure"
        aria-label={i18n.t(k.REPORT__POPULAR_PROGRAM__PLURAL)}
        key="key"
        className="popular-program-item-card"
        style={{borderRadius: '8px', margin: '15px 0px', flex: 2}}
        extra={
          <ViewMoreLink
            aria-label={i18n.t(k.PROGRAM__PLURAL__VIEW_MORE)}
            onClick={() => onTabChange(AdminReportsTabName.PROGRAM)}
            className="pseudo-link-hover">
            {i18n.t(k.CTA__VIEW_MORE)}
          </ViewMoreLink>
        }>
        <Row align="middle" style={{padding: '24px 24px'}}>
          <Col xs={24} md={24}>
            {!popularProgramLoading ? (
              popularProgramData?.length ? (
                popularProgramData.map((popularProgramItem) => (
                  <React.Fragment key={popularProgramItem.id}>
                    <Row justify="space-between" align="middle">
                      <Col sm={1}>
                        <ProviderLogo
                          alt={popularProgramItem.providerName}
                          provider=""
                          providerLogo={popularProgramItem.providerLogo}
                          size={30}
                        />
                      </Col>
                      <Col sm={22} style={{marginTop: '5px'}}>
                        <ViewProgramLink id={popularProgramItem.id}>
                          {popularProgramItem.title}
                        </ViewProgramLink>
                      </Col>
                    </Row>
                    <Divider style={{width: '100%', margin: '6px'}} />
                  </React.Fragment>
                ))
              ) : (
                <div style={{textAlign: 'center', wordWrap: 'break-word'}}>
                  <Row justify="center" align="middle">
                    <Col xs={3}></Col>
                    <Col xs={18}>
                      <h1>
                        {i18n.t(k.REPORT__POPULAR_PROGRAM__NO_ENROLLMENTS)}
                      </h1>
                      <p>{i18n.t(k.REPORT__POPULAR_PROGRAM__LACKING_DATA)}</p>
                    </Col>
                    <Col xs={3}></Col>
                  </Row>
                </div>
              )
            ) : (
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  <Skeleton active />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default PopularProgramList;
