import {i18n, k} from '@i18n/translate';
import Grid from '@blocks/Grid';
import {Space} from 'antd';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {COLORS} from '@utils/constants';
import {InputLabel} from '@components/reusable/LearnInForm';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {UploadIcon} from '@assets/upload-icon';
import {LinkIcon} from '@assets/link-icon';

export enum AttachmentType {
  Link = 'link',
  File = 'file',
}

interface AttachmentOptionsProps {
  setAttachmentType: (attachmentType: AttachmentType) => void;
}

export function AttachmentOptions({setAttachmentType}: AttachmentOptionsProps) {
  return (
    <Grid.Box>
      <InputLabel
        htmlFor="upload-attachment-button"
        label={i18n.t(k.FILE__ATTACHMENT)}
      />
      <Space size={'middle'}>
        <LearnInTooltip
          title={i18n.t(k.FILE__UPLOAD__VERB)}
          childHasButton={true}>
          <LearnInButton
            data-testid="add-attachment-button-attachment-options"
            aria-label={i18n.t(k.FILE__UPLOAD__VERB)}
            borderRadius={'4px'}
            padding={'10px'}
            tag={ButtonTags.SecondaryBlack}
            id="upload-attachment-button"
            border={`1px solid ${COLORS.Neutral300}`}
            onClick={() => setAttachmentType(AttachmentType.File)}
            icon={
              <UploadIcon
                fill={COLORS.Neutral600}
                alt={i18n.t(k.FILE__UPLOAD__VERB)}
              />
            }
            iconSize={'L'}
          />
        </LearnInTooltip>
        <LearnInTooltip title={i18n.t(k.ADD_URL_LINK)} childHasButton={true}>
          <LearnInButton
            data-testid="add-link-button-attachment-options"
            aria-label={i18n.t(k.ADD_URL_LINK)}
            borderRadius={'4px'}
            padding={'10px'}
            tag={ButtonTags.SecondaryBlack}
            id="link-attachment-button"
            border={`1px solid ${COLORS.Neutral300}`}
            icon={<LinkIcon alt={i18n.t(k.ADD_URL_LINK)} />}
            iconSize={'L'}
            onClick={() => setAttachmentType(AttachmentType.Link)}
          />
        </LearnInTooltip>
      </Space>
    </Grid.Box>
  );
}
