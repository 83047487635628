import {ReactElement, useMemo} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Form, Checkbox} from 'antd';
import {COLORS} from '@utils/constants';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import LearnInTable from '@components/reusable/LearnInTable';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {ProgramNotificationType} from '@generated/enums';

const ModalTitleContainer = styled.div`
  margin-bottom: 24px;
`;

const ModalTitle = styled.div`
  font-weight: 500;
  font-size: 1.25rem;
  color: ${COLORS.Neutral900};
`;

const ModalSubtitle = styled.div`
  font-weight: normal;
  font-size: 1rem;
  color: ${COLORS.Neutral900};
`;

export interface NotificationRow {
  notificationDescription: ReactElement;
  title: string;
  email: string;
  message: string;
  type: ProgramNotificationType;
  isChecked: boolean;

  onChange: () => void;
  onEditEmailClicked: (notification: NotificationRow) => void;
}
export interface IEmailNotificationsProps {
  canEditMessage: boolean;
  emailNotificationOptions: NotificationRow[];
}

function EmailNotifications({
  canEditMessage,
  emailNotificationOptions,
}: IEmailNotificationsProps) {
  const emailNotificationsTableCols = useMemo(() => {
    return [
      {
        title: i18n.t(k.NOTIFICATION__TYPE),
        dataIndex: 'notificationDescription',
        key: 'notificationDescription',
      },
      {
        title: i18n.t(k.GENERIC__EMAIL),
        dataIndex: 'email',
        key: 'email',
        render: (text, row: NotificationRow) => {
          return (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'baseline',
              }}>
              <Form.Item
                className="lb-application-drawer"
                valuePropName="checked">
                <Checkbox
                  checked={row.isChecked}
                  onChange={row.onChange}
                  aria-label={i18n.t(k.CTA__CHECK_ITEM)}></Checkbox>
              </Form.Item>
              {canEditMessage && (
                <LearnInButton
                  color={COLORS.Blue800}
                  tag={ButtonTags.Link}
                  onClick={(e) => {
                    e.preventDefault();
                    row.onEditEmailClicked(row);
                  }}>
                  {i18n.t(k.MESSAGE__EDIT)}
                </LearnInButton>
              )}
            </div>
          );
        },
      },
    ];
  }, [canEditMessage]);
  return (
    <LearnInContainer
      maxWidth="928px"
      width="100%"
      flexDirection="column"
      tag={ContainerTags.CommonCard}>
      <ModalTitleContainer>
        <ModalTitle>{i18n.t(k.NOTIFICATION__PLURAL)}</ModalTitle>
        <ModalSubtitle>
          {i18n.t(k.NOTIFICATION__PREFERENCE__PLURAL)}
        </ModalSubtitle>
      </ModalTitleContainer>
      <LearnInTable
        dataSource={emailNotificationOptions || []}
        columns={emailNotificationsTableCols}
      />
    </LearnInContainer>
  );
}

export default EmailNotifications;
