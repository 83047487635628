import {UserFinanceIncentiveUpdateType} from './../enums';
import {AdminActionDetailVM} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: AdminActionDetailVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getAdminActionDetailVMMockData(
  args?: Partial<AdminActionDetailVM>
): AdminActionDetailVM {
  return {
    amountAfterUpdate: faker.datatype.number(), // optional
    amountBeforeUpdate: faker.datatype.number(), // optional
    type: UserFinanceIncentiveUpdateType.TotalApproved,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const AdminActionDetailVMMockData: AdminActionDetailVM = {
  amountAfterUpdate: 5, // optional
  amountBeforeUpdate: 5, // optional
  type: UserFinanceIncentiveUpdateType.TotalApproved,
};
