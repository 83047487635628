import {
  PreApprovalQuestionFunctionalityType,
  PreApprovalQuestionInputType,
  PreApprovalQuestionResponseSource,
} from './../enums';
import {PreApprovalQuestionVM} from './../interfaces';
import {
  AttachmentVMMockData,
  getAttachmentVMMockData,
} from './AttachmentVM.mock';
import {
  PreApprovalQuestionResponseOptionVMMockData,
  getPreApprovalQuestionResponseOptionVMMockData,
} from './PreApprovalQuestionResponseOptionVM.mock';
import {faker} from '@faker-js/faker';

// Interface: PreApprovalQuestionVM

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getPreApprovalQuestionVMMockData(
  args?: Partial<PreApprovalQuestionVM>
): PreApprovalQuestionVM {
  return {
    functionalityType: PreApprovalQuestionFunctionalityType.NotFeatureQuestion,
    helperText: faker.word.noun(),
    id: faker.datatype.number(),
    images: [getAttachmentVMMockData()],
    inputType: PreApprovalQuestionInputType.Text,
    isConditionalOnAnswerId: faker.datatype.number(), // optional
    localizationId: String(faker.datatype.number()),
    options: [getPreApprovalQuestionResponseOptionVMMockData()],
    required: faker.datatype.boolean(),
    response: faker.word.noun(),
    responseType: PreApprovalQuestionResponseSource.Text,
    value: faker.word.noun(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const PreApprovalQuestionVMMockData: PreApprovalQuestionVM = {
  functionalityType: PreApprovalQuestionFunctionalityType.NotFeatureQuestion,
  helperText: 'string',
  id: 1,
  images: [AttachmentVMMockData],
  inputType: PreApprovalQuestionInputType.Text,
  isConditionalOnAnswerId: 1, // optional
  localizationId: '1',
  options: [PreApprovalQuestionResponseOptionVMMockData],
  required: true,
  response: 'string',
  responseType: PreApprovalQuestionResponseSource.Text,
  value: 'string',
};
