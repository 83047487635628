import * as React from 'react';
import styled from 'styled-components';
import {getPlaceholderImgSrc} from '@utils/image-utils';

const Container = styled.div`
  height: max(250px, calc(30vw - 40px));
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
`;

interface IDetailPageBannerImageProps {
  id: number;
  src?: string;
  imgStyle?: React.CSSProperties;
  style?: React.CSSProperties;
}

function DetailPageBannerImage({
  id,
  src,
  imgStyle,
  style,
}: IDetailPageBannerImageProps) {
  return (
    <Container style={style}>
      <img alt="" src={src || getPlaceholderImgSrc(id)} style={imgStyle} />
    </Container>
  );
}

export default DetailPageBannerImage;
