import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {Space} from 'antd';
import LearnInAutocomplete from '@components/reusable/Autocomplete/Autocomplete';

export const Container = styled(Space)`
  .ant-select-selector {
    height: 3.5rem !important;
    align-items: center;

    .ant-select-selection-search input {
      height: 100% !important;
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    outline: 0 !important;
    background: ${COLORS.Neutral50} !important;
    box-shadow: none !important;
  }
`;

export const StyledSelect = styled(LearnInAutocomplete)`
  border: none !important;
  border-bottom: 1px solid ${COLORS.Blue800} !important;
  border-radius: 0;
  color: ${COLORS.Neutral950};
  font-size: 1.5rem;
  width: 100%;

  &:focus-within {
    border: none !important;
    border-bottom: 1px solid ${COLORS.Blue800} !important;
  }
`;
