import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';
import {
  HEADER_HEIGHT,
  NAV_PANEL_WIDTH,
} from '@components/reusable/LearnInPageDrawer';
import {ACADEMY_OVERVIEW_SECTION_NAV_WIDTH} from './../SectionNav/SectionNav.styled';
import {AcademyBrandVM} from '@generated/interfaces';
import {getAcademyBrandingBackgroundColor} from '@utils/color';

export const Container = styled.div<{branding: AcademyBrandVM; top?: number}>`
  bottom: 0;
  background: ${(props) =>
    getAcademyBrandingBackgroundColor(props.branding?.primaryBrandColor, 45)};
  left: ${ACADEMY_OVERVIEW_SECTION_NAV_WIDTH + NAV_PANEL_WIDTH}px;
  max-height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow-y: scroll;
  padding: 24px;
  position: absolute;
  right: 0;
  top: ${({top}) => `${Number.isInteger(top) ? top : HEADER_HEIGHT}px`};
  margin-top: ${({isPreview}) => (isPreview ? '36px' : 'unset')};
  @media (max-width: ${Breakpoints.Desktop}px) {
    position: static;
    width: 100%;
    z-index: 30;
    padding: 8px 0 24px 0;
    height: calc(100vh - 115px); // TODO: calculate heights from constants
  }
`;

export const ScreenContainer = styled.div`
  background: ${(props) =>
    getAcademyBrandingBackgroundColor(props.branding?.primaryBrandColor, 45)};
  display: flex;
  @media (max-width: ${Breakpoints.Desktop}px) {
    display: block;
  }
`;

export const TitleCard = styled.div<{branding: AcademyBrandVM}>`
  background: ${(props) => props.branding?.secondaryBrandColor};
  border-radius: 10px;
  box-shadow: ${COLORS.BoxShadowStandard};
  color: ${(props) => props.branding?.secondaryBrandTextColor};
  margin-bottom: 16px;
  padding: 24px;
  width: 100%;

  @media (max-width: ${Breakpoints.Desktop}px) {
    border-radius: 4px;
  }
`;

export const Title = styled.div`
  color: ${(props) => props.branding?.itemSelectedTextNavColor};
  font-size: 24px;
  font-weight: 500;
`;

export const Description = styled.div`
  color: ${(props) => props.branding?.itemSelectedTextNavColor};
  font-size: 16px;
  margin-top: 8px;
  font-weight: 400;
`;

export const CalendarConnectCard = styled.div`
  background: ${COLORS.Blue50};
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px;
  width: 100%;

  @media (max-width: ${Breakpoints.Desktop}px) {
    border-radius: 4px;
  }
`;

export const BlockedScreenContainer = styled.div<{branding: AcademyBrandVM}>`
  align-items: center;
  background: ${(props) =>
    getAcademyBrandingBackgroundColor(props.branding?.primaryBrandColor, 45)};
  bottom: 0;
  color: ${(props) => props.branding?.itemDefaultTextNavColor};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: ${ACADEMY_OVERVIEW_SECTION_NAV_WIDTH + NAV_PANEL_WIDTH}px;
  max-height: calc(100vh - ${HEADER_HEIGHT}px);
  overflow-y: scroll;
  padding: 24px;
  position: absolute;
  right: 0;
  top: ${HEADER_HEIGHT}px;
  @media (max-width: ${Breakpoints.Desktop}px) {
    position: static;
    width: 100%;
    z-index: 30;
    height: calc(
      100vh - ${72 + 68 + 72}px
    ); // TODO: calculate heights from constants
  }
`;

export const BlockedScreenTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
`;

export const BlockedScreenMeta = styled.span``;

export const StepsContainer = styled.div`
  margin: auto;
  max-width: 1002px;
`;

export const HeaderGroup = styled.section`
  @media (max-width: ${Breakpoints.Desktop}px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`;
