import {useEffect, useState} from 'react';
import CurriculumScreenHeader from './CurriculumScreenHeader';
import {useMutation} from 'react-query';
import {simpleMutationFn} from '@store/queryClient';
import {
  getUpdateCustomProgramSectionRm,
  getReorderCustomProgramSectionRm,
} from '@store/apiEndpoints/customProgram/mutations';
import {notify} from '@components/user/notifications';
import UpsertCustomContentModal from './../curriculum/UpsertCustomContentModal.container';
import DeleteCustomProgramSectionModal from './DeleteCustomProgramSectionModal.container';
import {ContentType} from '@generated/enums';
import {getContentDisplayOptions} from '@utils/customProgramFullPage';
import {
  useUpdateCustomProgramSectionMutation,
  useCustomProgramSectionsQuery,
} from '@generated/hooks';
import {
  mapTextToEnglish,
  mapTextToUserLanguage,
} from '@utils/enumMapping/MapTextToUserLanguage';
import {NEW_SECTION} from '@components/admin/constants';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface CurriculumScreenHeaderContainerProps {
  customProgramId: number;
  setSelectedSectionId: (id: number) => void;
  selectedSectionId: number;
  isNewSection: boolean;
  setIsNewSection: (isNew: boolean) => void;
  showFullPageToggle: boolean;
  showAddContentButton: boolean;
  showFullPageForm: boolean;
}

function CurriculumScreenHeaderContainer({
  customProgramId,
  setSelectedSectionId,
  selectedSectionId,
  isNewSection,
  setIsNewSection,
  showFullPageToggle,
  showAddContentButton,
  showFullPageForm,
}: CurriculumScreenHeaderContainerProps) {
  const [showAddContentModal, setShowAddContentModal] = useState(false);
  const [showDeleteContentModal, setShowDeleteContentModal] = useState(false);
  const [contentType, setContentType] = useState<ContentType | undefined>(
    undefined
  );
  const [showSectionTitleEdit, setShowSectionTitleEdit] = useState(false);
  const [_title, setTitle] = useState('');

  const {
    data: customProgramSections,
    invalidateExact: invalidateCustomProgramSections,
  } = useCustomProgramSectionsQuery({
    customProgramId,
  });

  const section = customProgramSections?.find(({id}) => {
    return id === selectedSectionId;
  });

  const updateCustomProgramSection = useUpdateCustomProgramSectionMutation({
    onSuccess: () => {
      invalidateCustomProgramSections();
    },
  });

  useEffect(() => {
    if (isNewSection) {
      showEditTitleInput(section?.title);
      setIsNewSection(false);
    }
  }, [customProgramId]);

  const updateSectionTitleMutation = useMutation(
    (title: string) => {
      const updateSectionRm = getUpdateCustomProgramSectionRm({
        title,
        sectionId: section.id,
        customProgramId,
      });
      return simpleMutationFn<null>(
        updateSectionRm.path,
        updateSectionRm.payload
      );
    },
    {
      onSuccess: async () => {
        notify.updateCustomProgramSuccess();
        invalidateCustomProgramSections();
      },
    }
  );

  const updateSectionOrderMutation = useMutation(
    (newOrder: number) => {
      const updateSectionRm = getReorderCustomProgramSectionRm({
        id: section?.id,
        newOrder,
      });
      return simpleMutationFn<null>(
        updateSectionRm.path,
        updateSectionRm.payload
      );
    },
    {
      onSuccess: async () => {
        invalidateCustomProgramSections();
      },
    }
  );

  const moveSectionToTop = async () => {
    await updateSectionOrderMutation.mutateAsync(1);
  };

  const moveSectionToBottom = async () => {
    await updateSectionOrderMutation.mutateAsync(customProgramSections?.length);
  };

  const showEditTitleInput = (title: string) => {
    setShowSectionTitleEdit(true);
    setTitle(mapTextToUserLanguage(title));
    setTimeout(() => {
      const editTitleInput = document.getElementById('edit-title-input');
      editTitleInput?.focus();
    }, 400);
  };

  const displayOptions = getContentDisplayOptions().map((opt) => {
    return {
      ...opt,
      isSelected:
        (opt.value && section?.isFullScreen) ||
        (!opt.value && section?.isFullScreen === false), // add logic here
    };
  });

  const handleToggleDisplayOptions = (option) => {
    updateCustomProgramSection.mutate({
      payload: {
        customProgramId,
        isFullScreen: option.value,
        sectionId: selectedSectionId,
        title: section.title,
      },
    });
  };

  return (
    <>
      <CurriculumScreenHeader
        showFullPageForm={showFullPageForm}
        showFullPageToggle={showFullPageToggle}
        displayOptions={displayOptions}
        onToggleDisplayOptions={handleToggleDisplayOptions}
        title={mapTextToUserLanguage(section?.title)}
        onUpdateTitle={async (_title) => {
          // When text is New Section, do not update
          if (mapTextToEnglish(_title) === NEW_SECTION) return;
          // Only update if the title is changed
          if (_title !== section.title)
            await updateSectionTitleMutation.mutateAsync(_title);
        }}
        onClickAddContent={(contentType: ContentType) => {
          setContentType(contentType);
          setShowAddContentModal(true);
        }}
        moveSectionToTop={moveSectionToTop}
        moveSectionToBottom={moveSectionToBottom}
        onClickDeleteSection={() => setShowDeleteContentModal(true)}
        showSectionTitleEdit={showSectionTitleEdit}
        setShowSectionTitleEdit={setShowSectionTitleEdit}
        _title={_title}
        setTitle={setTitle}
        showEditTitleInput={showEditTitleInput}
        showAddContentButton={showAddContentButton}
      />
      {showAddContentModal && (
        <UpsertCustomContentModal
          key={section?.id}
          customProgramId={customProgramId}
          sectionId={section?.id}
          visible={showAddContentModal}
          contentType={contentType}
          onCancel={() => setShowAddContentModal(false)}
        />
      )}
      {showDeleteContentModal && (
        <DeleteCustomProgramSectionModal
          visible={showDeleteContentModal}
          onCancel={() => setShowDeleteContentModal(false)}
          customProgramId={customProgramId}
          sectionId={section?.id}
          setSelectedSectionId={setSelectedSectionId}
          selectedSectionId={selectedSectionId}
          doesSectionHaveNoContent={!section?.content.length}
        />
      )}
    </>
  );
}

export default CurriculumScreenHeaderContainer;
