import * as React from 'react';
import {useState} from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {ManageYourPlanTitle} from '@components/reusable/Text/Text.style';
import ResourcePlanCard, {
  COMPLETE_BUTTON_STATES,
  REMOVE_BUTTON_STATES,
} from './ResourcePlanCard';
import {
  RemoveUserPlanResourcePayload,
  UserPlanResourceVM,
} from '@models/serverModels';
import RemoveResourceModal from './RemoveResourceModal';
import {RequestAdditionalFundsModal} from '@blocks/RequestAdditionalFundsModal';
import RequestResourceApprovalDrawer from '@components/user/components/resources/RequestResourceApprovalDrawer.container';
import {FinanceType, UserResourceStatus} from '@generated/enums';
import LearnInCarousel from '@components/reusable/Carousel';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import ReimbursementDrawerContainer from '@blocks/reimbursementDrawer/ReimbursementDrawer.container';
import {useFinanceLearningBudgetsQuery} from '@generated/hooks';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  margin: 25px 0px 16px;
  .slick-list {
    padding: 16px 0px !important;
  }
  .slick-slide {
    width: fit-content !important;
    margin-right: 24px !important;
  }
`;

const StyledManageYourPlanTitle = styled(ManageYourPlanTitle)`
  margin-bottom: 0;
`;

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface ResourcePlanCardCarouselProps {
  resources: UserPlanResourceVM[];
  removeResource: (args: RemoveUserPlanResourcePayload) => void;
  markResourceComplete: (id: number) => void;
  refreshResources: () => void;
}

function ResourcePlanCardCarousel({
  refreshResources,
  resources,
  removeResource,
  markResourceComplete,
}: ResourcePlanCardCarouselProps) {
  const [resourceToRemove, setResourceToRemove] =
    useState<null | UserPlanResourceVM>(null);
  const [showRequestResourceForm, setShowRequestResourceForm] = useState(false);
  const [rerequestResource, setRerequestResource] = useState<
    UserPlanResourceVM | undefined
  >();

  const getLearningBudgetInfoQuery =
    useUserPlanFinanceLearningBudgetOverviewVMQuery();
  const isReimbursementPlan =
    getLearningBudgetInfoQuery.data?.financeType ===
    FinanceType.TuitionReimbursement;
  const getUserQuery = useGetUserQuery();
  const userQueryData = getUserQuery?.data;
  const userCurrency = getUserQuery.data?.currency;

  const [showRequestAdditionalFundsModal, setShowRequestAdditionalFundsModal] =
    useState(null);

  const [showReimbursementDrawer, setShowReimbursementDrawer] =
    React.useState(false);
  const [selectedResource, setSelectedResource] = React.useState<number>();
  const handleCloseRequestResourceDrawer = () => {
    setShowRequestResourceForm(false);
    setRerequestResource(undefined);
  };

  const financeLearningBudgetsQuery = useFinanceLearningBudgetsQuery(null);

  const refreshBudget = () => {
    setTimeout(() => {
      //If we call the API immediately after removing a program / resource, the budget data is not yet updated.
      financeLearningBudgetsQuery.refetch();
    }, 1000);
  };

  const handleCloseRemoveResourceModal = () => {
    refreshBudget();
    setResourceToRemove(null);
  };

  const handleOkRemoveResourceModal = (hasMadePurchases: boolean) => {
    removeResource({
      userPlanResourceId: resourceToRemove.id,
      amountSpent: hasMadePurchases
        ? resourceToRemove.approvedAmount +
          resourceToRemove.additionalRequestedAmount
        : 0,
    });
    handleCloseRemoveResourceModal();
  };

  return (
    <>
      {resourceToRemove && (
        <RemoveResourceModal
          approvedAmount={
            resourceToRemove?.approvedAmount +
            resourceToRemove?.additionalRequestedAmount
          }
          currency={resourceToRemove?.currency}
          visible={!!resourceToRemove}
          onCancel={handleCloseRemoveResourceModal}
          onOk={handleOkRemoveResourceModal}
          isReimbursementPlan={isReimbursementPlan}
        />
      )}
      {showRequestAdditionalFundsModal && (
        <RequestAdditionalFundsModal
          refreshResources={refreshResources}
          resource={resources.find(
            (r) => r.id === showRequestAdditionalFundsModal
          )}
          onClose={() => setShowRequestAdditionalFundsModal(false)}
          isVisible={showRequestAdditionalFundsModal}
        />
      )}

      <RequestResourceApprovalDrawer
        visible={showRequestResourceForm}
        onCloseSuccess={handleCloseRequestResourceDrawer}
        userPlanResourceId={rerequestResource?.id}
        rerequestResourceType={rerequestResource?.resourceType}
      />
      <Container>
        <StyledManageYourPlanTitle>
          {i18n.t(k.RESOURCE__PLURAL)}
        </StyledManageYourPlanTitle>
        {showReimbursementDrawer && (
          <ReimbursementDrawerContainer
            isOpen={showReimbursementDrawer}
            setIsOpen={setShowReimbursementDrawer}
            selectedRequestItem={selectedResource}
          />
        )}
        <LearnInCarousel
          carouselItemWidth={264}
          items={resources?.map((resource) => {
            const handleClickRemoveResource = async () => {
              if (
                [
                  UserResourceStatus.Approved,
                  UserResourceStatus.Purchased,
                ].includes(resource.status)
              ) {
                setResourceToRemove(resource);
              } else {
                removeResource({
                  userPlanResourceId: resource.id,
                  amountSpent: 0,
                });
              }
            };
            const handleClickRequestAdditionalResources = (id: number) =>
              setShowRequestAdditionalFundsModal(id);
            const handleClickMarkComplete = () =>
              markResourceComplete(resource.id);
            const handleClickResubmitResubmit = () => {
              setRerequestResource(resource);
              setShowRequestResourceForm(true);
            };
            return (
              <>
                <ResourcePlanCard
                  key={resource.id}
                  {...resource}
                  onClickMarkComplete={handleClickMarkComplete}
                  onClickRemoveResource={handleClickRemoveResource}
                  onClickRequestAdditionalResources={
                    handleClickRequestAdditionalResources
                  }
                  onClickResubmitRequest={handleClickResubmitResubmit}
                  showMarkCompleteOption={COMPLETE_BUTTON_STATES.has(
                    resource.status
                  )}
                  showRemoveResourceOption={
                    REMOVE_BUTTON_STATES.has(resource.status) &&
                    !resource.purchaseMade
                  }
                  userCurrency={userCurrency}
                  isReimbursementPlan={isReimbursementPlan}
                  userQueryData={userQueryData}
                  handleShowReimbursementModal={() => {
                    setShowReimbursementDrawer(true);
                    setSelectedResource(resource.id);
                  }}
                />
              </>
            );
          })}
        />
      </Container>
    </>
  );
}

export default ResourcePlanCardCarousel;
