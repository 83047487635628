import {formatCurrency} from './moneyUtils';
import {CurrencyCode} from '@generated/enums';

export const getLicenseCost = (
  step: any,
  usersCurrency: CurrencyCode,
  exchangeRate: number
) => {
  const licenseCost = formatCurrency(
    step?.licenseCost === 0
      ? step?.licenseCost
      : (step?.licenseCost !== -1 && step?.licenseCost !== null
          ? step?.licenseCost
          : step?.cost) ||
          step?.cost ||
          0,
    usersCurrency,
    exchangeRate
  );
  return licenseCost;
};
