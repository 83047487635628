import {forwardRef} from 'react';
import * as React from 'react';
import styled from 'styled-components';
import {i18n, k} from '@i18n/translate';
import {MenuOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  left: 9px;
  .draggable-indicator {
    display: none;
  }
  .ant-dropdown.ant-dropdown-placement-bottomRight {
    text-align: left;
  }
`;

const IndexRule = styled.div`
  border-left: 1px solid ${COLORS.Neutral200};
  width: 1px;
  height: 100%;
  position: absolute;
  left: 15px;
  z-index: 10;
`;

const IndexRuleTop = styled(IndexRule)`
  height: 0px;
`;

const IndexRuleBottom = styled(IndexRule)`
  top: 0;
`;

const INDEX_SIZE = 16;
const Index = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.Neutral200};
  border-radius: 100%;
  color: ${COLORS.Neutral600};
  font-weight: 500;
  font-size: 1rem;
  margin-right: 16px;
  margin-left: 8px;
  width: ${INDEX_SIZE}px;
  height: ${INDEX_SIZE}px;
  background: ${COLORS.Neutral200};
  z-index: 20;
  padding: 7px;
`;

const EmptyIndex = styled.div`
  width: 42px;
`;

type StepIndexRuleProps = {
  cardCount: number;
  index: number;
  children?: React.ReactNode;
  role?: string;
};

function WithStepIndexRule(
  {cardCount, index, children, ...props}: StepIndexRuleProps,
  ref
) {
  const first = index === 0;
  const last = index === cardCount - 1;

  return (
    <Container ref={ref} {...props}>
      {!first && cardCount > 1 && <IndexRuleTop />}
      {!last && cardCount > 1 && <IndexRuleBottom />}
      {cardCount > 1 ? (
        <Index>
          <span
            className="draggable-indicator"
            aria-label={i18n.t(k.CTA__DRAG_AND_DROP)}>
            <MenuOutlined aria-hidden="true" />
          </span>
        </Index>
      ) : (
        <EmptyIndex />
      )}
      {children}
    </Container>
  );
}

export default forwardRef(WithStepIndexRule);
