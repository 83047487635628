import {CurrencyCode} from './../enums';
import {LicenseOverviewResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: LicenseOverviewResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLicenseOverviewResponseMockData(
  args?: Partial<LicenseOverviewResponse>
): LicenseOverviewResponse {
  return {
    approvedInPast30Days: faker.datatype.number(),
    currency: CurrencyCode.USD,
    eligibleEmployees: faker.datatype.number(),
    id: faker.datatype.number(),
    providerId: faker.datatype.number(),
    providerLogo: faker.word.noun(),
    providerName: faker.name.fullName(),
    totalApprovedLicenses: faker.datatype.number(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LicenseOverviewResponseMockData: LicenseOverviewResponse = {
  approvedInPast30Days: 123,
  currency: CurrencyCode.USD,
  eligibleEmployees: 123,
  id: 1,
  providerId: 1,
  providerLogo: 'string',
  providerName: 'Bobby Johnson',
  totalApprovedLicenses: 123,
};
