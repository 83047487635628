import PropTypes from 'prop-types';
import {CSSProperties} from 'react';

export function ApprovedIcon(style: PropTypes.InferType<CSSProperties>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      style={style}>
      <path
        d="M22.2412 8.66055V6.89908C22.2412 6.73761 22.1136 6.6055 21.9575 6.6055H8.34051C8.18448 6.6055 8.05682 6.73761 8.05682 6.89908V8.66055C8.05682 8.82202 8.18448 8.95413 8.34051 8.95413H21.9575C22.1136 8.95413 22.2412 8.82202 22.2412 8.66055ZM8.34051 11.8899C8.18448 11.8899 8.05682 12.022 8.05682 12.1835V13.945C8.05682 14.1064 8.18448 14.2385 8.34051 14.2385H14.8653C15.0214 14.2385 15.149 14.1064 15.149 13.945V12.1835C15.149 12.022 15.0214 11.8899 14.8653 11.8899H8.34051ZM21.6738 16.1468C17.4433 16.1468 14.0143 19.6954 14.0143 24.0734C14.0143 28.4514 17.4433 32 21.6738 32C25.9043 32 29.3334 28.4514 29.3334 24.0734C29.3334 19.6954 25.9043 16.1468 21.6738 16.1468ZM25.4859 28.0183C24.4682 29.0716 23.1136 29.6514 21.6738 29.6514C20.2341 29.6514 18.8795 29.0716 17.8618 28.0183C16.8441 26.9651 16.2838 25.5633 16.2838 24.0734C16.2838 22.5835 16.8441 21.1817 17.8618 20.1284C18.8795 19.0752 20.2341 18.4954 21.6738 18.4954C23.1136 18.4954 24.4682 19.0752 25.4859 20.1284C26.5036 21.1817 27.0639 22.5835 27.0639 24.0734C27.0639 25.5633 26.5036 26.9651 25.4859 28.0183ZM24.8299 21.2844H23.2589C23.1667 21.2844 23.0816 21.3284 23.0285 21.4055L20.7767 24.6275L19.9575 23.4569C19.9313 23.4191 19.8967 23.3883 19.8565 23.3673C19.8164 23.3462 19.772 23.3354 19.727 23.3358H18.1632C17.9327 23.3358 17.798 23.6073 17.9327 23.8018L20.5497 27.5486C20.6632 27.7101 20.8937 27.7101 21.0072 27.5486L25.0568 21.7505C25.1951 21.556 25.0604 21.2844 24.8299 21.2844ZM13.4469 28.4771H5.21994V2.6422H25.0781V15.2661C25.0781 15.4275 25.2058 15.5596 25.3618 15.5596H27.3476C27.5036 15.5596 27.6313 15.4275 27.6313 15.2661V1.17431C27.6313 0.524771 27.1242 0 26.4965 0H3.8015C3.17384 0 2.66675 0.524771 2.66675 1.17431V29.945C2.66675 30.5945 3.17384 31.1193 3.8015 31.1193H13.4469C13.6029 31.1193 13.7306 30.9872 13.7306 30.8257V28.7706C13.7306 28.6092 13.6029 28.4771 13.4469 28.4771Z"
        fill="#44444A"
      />
    </svg>
  );
}
