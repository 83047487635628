import {i18n, k} from '@i18n/translate';
import {EditOutlined, PlusOutlined} from '@ant-design/icons';
import {COLORS} from '@utils/constants';
import OptionsForm from '@components/reusable/OptionForm';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';

/*
|--------------------------------------------------------------------------
| Option Form Component
|--------------------------------------------------------------------------
*/

function NewProgramOptionForm({
  onClickAddExistingProgram,
  onClickBuildCustom,
}: {
  onClickAddExistingProgram: () => void;
  onClickBuildCustom: () => void;
}) {
  return (
    <OptionsForm
      buttonProps={{tag: ButtonTags.Primary}}
      buttonLabel={i18n.t(k.PROGRAM__NEW)}
      options={[
        {
          title: i18n.t(k.PROGRAM__ADD__EXISTING),
          subtitle: i18n.t(k.PROGRAM__ADD__FROM_DIFF),
          onClick: onClickAddExistingProgram,
          color: COLORS.Green600,
          background: COLORS.Green200,
          Icon: PlusOutlined,
        },
        {
          title: i18n.t(k.PROGRAM__BUILD_CUSTOM__TITLE),
          subtitle: i18n.t(k.PROGRAM__CUSTOM__ADD__DESCRIPTION),
          onClick: onClickBuildCustom,
          color: COLORS.Red500,
          background: COLORS.Red50,
          Icon: EditOutlined,
        },
      ]}
    />
  );
}

export default NewProgramOptionForm;
