import CohortsSetupScreen from './CohortsSetupScreen';
import {notify} from '@components/user/notifications';
import {
  useUpdateCohortMutation,
  useAddCohortMutation,
  useCustomProgramQuery,
  useCohortsQuery,
  useParentCustomProgramsQuery,
} from '@generated/hooks';
import {CustomProgramCohortPayload} from '@generated/interfaces';
import {CustomProgramStatus} from '@generated/enums';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface ICohortsSetupScreenContainerProps {
  customProgramId: number;
  cohortId: number | undefined;
  setCohortForEditIdOnCreate?: (id: number) => void;
}

function CohortsSetupScreenContainer({
  customProgramId,
  cohortId,
  setCohortForEditIdOnCreate,
}: ICohortsSetupScreenContainerProps) {
  const {isFeatureFlagOn} = useFeatureFlags();

  const {data: customProgram} = useCustomProgramQuery({
    customProgramId,
  });

  const {data: cohort, invalidateExact: invalidateCohort} =
    useCustomProgramQuery({
      customProgramId: cohortId,
    });

  const {invalidateExact: invalidateParentCustomPrograms} =
    useParentCustomProgramsQuery({});

  const {refetch: refetchCohorts} = useCohortsQuery(
    {
      parentCustomProgramId: customProgramId,
    },
    {
      enabled: !!customProgramId,
    }
  );

  const updateCohort = useUpdateCohortMutation({
    onSuccess: async () => {
      notify.updateCohortSuccess();
      invalidateParentCustomPrograms();
      refetchCohorts();
      await invalidateCohort();
    },
    onError: () => {
      notify.updateCohortError();
    },
  });

  const addCohort = useAddCohortMutation({
    onSuccess: (newCohortId: number) => {
      setCohortForEditIdOnCreate?.(newCohortId);
      invalidateParentCustomPrograms();
      refetchCohorts();
      notify.addCohortSuccess();
    },
    onError: () => {
      notify.addCohortError();
    },
  });

  const handleClickSave = async (
    args: Omit<
      CustomProgramCohortPayload,
      'parentCustomProgramId' | 'id' | 'status'
    >
  ) => {
    if (!!cohortId) {
      updateCohort.mutate({
        payload: {
          ...args,
          id: cohortId,
          parentCustomProgramId: customProgramId,
          status: cohort?.status || CustomProgramStatus.Draft,
        },
      });
    } else {
      addCohort.mutate({
        payload: {
          ...args,
          parentCustomProgramId: customProgramId,
          status: CustomProgramStatus.Draft,
        },
      });
    }
  };

  return (
    <CohortsSetupScreen
      key={`${customProgram}-${cohortId || '0'}`}
      cohort={cohort}
      title={customProgram?.title}
      imageUrl={customProgram?.imageUrl}
      programLength={customProgram?.duration}
      shortDescription={customProgram?.shortDescription}
      tags={customProgram?.skills?.map(({name}) => name) || []}
      onClickSave={handleClickSave}
      loading={updateCohort.isLoading || addCohort.isLoading}
    />
  );
}

export default CohortsSetupScreenContainer;
