import {useState, useEffect, MouseEvent, useCallback} from 'react';
import {ContentCompleteButton} from './ContentCompleteButton';
import {CalendarCallbackRedirect} from '@generated/enums';
import {useMutation} from 'react-query';
import {
  getCompleteProgramContentRm,
  getUserProgramPlanRq,
} from '@store/apiEndpoints';
import {simpleMutationFn} from '@store/queryClient';
import debounce from 'lodash/debounce';
import {notify} from '@components/user/notifications';
import ProgramCompletedModal from '../ProgramCompletedModal';
import queryClient from '@store/queryClient';
import ConnectCalendarModalContainer from '@blocks/connectCalendarModal/ConnectCalendarModal.container';
import {useCustomProgramPreviewQuery} from '@generated/hooks';
import {useAcademyLevelVMsQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {ProgramContentStatus} from '@models/clientEnums';
import {CustomProgramPreviewVM} from '@generated/interfaces';
import {useAuth} from '@utils/oidc-auth-wrapper';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface ContentCompleteButtonContainerProps {
  disabled: boolean;
  isFullPage?: boolean;
  academyId?: number;
  completed: boolean;
  contentId: number;
  customProgramId: number;
  customProgramSelected?: boolean;
  isActivePlanItem: boolean;
  title?: string;
  onContentCompleteStatusChange?: (
    contentId: number,
    newStatus: ProgramContentStatus
  ) => void;
}

export function ContentCompleteButtonContainer({
  academyId,
  completed,
  contentId,
  customProgramId,
  customProgramSelected,
  isActivePlanItem,
  isFullPage,
  title,
  onContentCompleteStatusChange,
}: ContentCompleteButtonContainerProps) {
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [completedForPreviewOnly, setCompletedForPreviewOnly] = useState(false);
  const {user} = useAuth();
  const userProgramPlanRq = getUserProgramPlanRq(2)[0];

  const customProgramsPreviewDetailsQuery = useCustomProgramPreviewQuery(
    {customProgramId, queryParams: {includeProgress: true}},
    {
      enabled: false,
    }
  );

  const {invalidateExact: invalidateLevels} = useAcademyLevelVMsQuery(
    academyId,
    {
      enabled: false,
    }
  );

  const completeContentMutation = useMutation(
    () => {
      const status = completed
        ? ProgramContentStatus.Planned
        : ProgramContentStatus.Completed;
      const completeProgramContentRm = getCompleteProgramContentRm({
        contentId,
        status,
      });
      return simpleMutationFn(
        completeProgramContentRm.path,
        completeProgramContentRm.payload
      );
    },
    {
      onSuccess: async () => {
        !!academyId && invalidateLevels();
        const newStatus = completed
          ? ProgramContentStatus.Planned
          : ProgramContentStatus.Completed;
        onContentCompleteStatusChange?.(contentId, newStatus);
        customProgramsPreviewDetailsQuery.invalidateExact(); // Found that calling refetch by itself wasn't updating the UI, but logic below
        // required the refetch data, so called invalidate to ensure the UI got updated.
        customProgramsPreviewDetailsQuery
          .refetch()
          .then(({data: customProgram}: {data: CustomProgramPreviewVM}) => {
            if (
              customProgramSelected === false &&
              user?.connectedCalendar === null
            ) {
              setShowCalendarModal(true);
            }

            if (customProgram?.progressPercentage === 100) {
              queryClient.invalidateQueries(userProgramPlanRq);
              setShowCompleteModal(true);
            }
          });
      },
      onError: () => {
        notify.markContentCompleteError();
      },
    }
  );

  const handleClick = useCallback(
    (ev: MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const debouncedFn = debounce(
        () => {
          ev?.stopPropagation();
          if (!isActivePlanItem) {
            setCompletedForPreviewOnly((completed) => !completed);
          } else {
            completeContentMutation.mutateAsync();
          }
        },
        1000,
        {leading: true, trailing: false}
      );
      debouncedFn();
    },
    [completeContentMutation, isActivePlanItem]
  );

  // Custom Program Full Page triggers completion on navigation to the page
  // and don't show the button
  useEffect(() => {
    if (isFullPage && !completed) {
      handleClick(null);
    }
  }, [completed, handleClick, isFullPage]);

  if (isFullPage) {
    return null;
  }

  return (
    <>
      <ContentCompleteButton
        completed={isActivePlanItem ? completed : completedForPreviewOnly}
        onClick={handleClick}
        title={title}
        disabled={false}
      />
      <ProgramCompletedModal
        visible={showCompleteModal}
        onClose={() => setShowCompleteModal(false)}
      />
      <ConnectCalendarModalContainer
        visible={showCalendarModal}
        id={customProgramId}
        redirectOption={CalendarCallbackRedirect.CustomProgram}
        handleCloseCalendarModal={() => {
          setShowCalendarModal(false);
        }}
      />
    </>
  );
}
