import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {i18n, k} from '@i18n/translate';
import {DeleteOutlined} from '@ant-design/icons';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  background: ${COLORS.Neutral50};
  padding: 16px;
  border-radius: 4px;
  width: 100%;
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
`;

const FileName = styled.label`
  font-family: Roboto;
  font-size: ${pxToRem(16, 16)};
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

interface AttachmentUploadProgressProps {
  fileName: string;
  onClickDelete: () => void;
}

function AttachmentUploadProgress({
  fileName,
  onClickDelete,
}: AttachmentUploadProgressProps) {
  return (
    <Container>
      <FileName>{fileName}</FileName>
      <LearnInButton
        icon={<DeleteOutlined alt={i18n.t(k.CTA__DELETE)} />}
        tag={ButtonTags.DestructiveRemovedBorder}
        onClick={onClickDelete}
      />
    </Container>
  );
}

export default AttachmentUploadProgress;
