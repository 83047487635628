import {PartialUpdateAcademyRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {PartialUpdateAcademyRequestSchema} from '../zodSchemas/PartialUpdateAcademyRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyController.cs

/*
|--------------------------------------------------------------------------
|  {academyId} 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesMutation} from '@generated/hooks';
 *
 *  const academies = useAcademiesMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academies.mutate({
 *    payload: {
 *      ...PartialUpdateAcademyRequest
 *    },
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 */

export const useAcademiesMutation = mutationHookFactory<
  PartialUpdateAcademyRequest, // payload
  {academyId: number}, // path variables
  null
>('useAcademiesMutation', 'patch', ({academyId}) => `academies/${academyId}`, {
  payloadSchema: PartialUpdateAcademyRequestSchema,
});
