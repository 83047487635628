import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {Form, Input} from 'antd';
import {FormInstance} from 'antd/lib/form';
import {COLORS} from '@utils/constants';
import {Breakpoints} from '@utils/responsiveUtils';
import {ErrorMessage} from '@components/reusable/LearnInForm';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import RestrictedAccessForm from '@components/reusable/RestrictedAccess/RestrictedAccessForm';
import {LearnInInput} from '@components/reusable/Input/Input.style';
import {NEW_ACADEMY_PAGE_DECORATION} from '@assets/cloudinary-urls';
import {FooterButtonContainer} from '@components/reusable/Footer/Footer';

const Page = styled.section`
  padding: 41px 0;
`;
const FormItem = styled(Form.Item)`
  margin-bottom: 19px !important;
`;
const Card = styled.section`
  display: flex;
  justify-content: space-around;
  margin: 2rem;
`;

const CardContent = styled.section`
  max-width: 558px;
  h3 {
    color: ${COLORS.Neutral600};
    font-weight: 300;
    margin-bottom: 3rem;
  }
  @media (min-width: ${Breakpoints.Desktop}px) {
    width: 50%;
  }
`;

const Image = styled.section`
  margin: auto 0;
  @media (max-width: ${Breakpoints.Desktop}px) {
    display: none;
  }
  img {
    max-width: 500px;
  }
`;

const Footer = styled.section`
  align-items: center;
  background: white;
  bottom: 0;
  box-shadow: inset 0px 1px 0px ${COLORS.BoxShadow1};
  display: flex;
  height: 4.5rem;
  justify-content: flex-end;
  padding-right: 1.5rem;
  position: fixed;
  width: 100%;
`;

const Header = styled.h1`
  font-family: Roboto;
  color: ${COLORS.Neutral950};
  font-size: 2.5em;
  font-weight: 300;
`;

const Subheader = styled.h3`
  /// important was added because CardContent styling seem to be a high level in the cascade
  color: ${COLORS.Neutral900} !important;
  font-size: 1em !important;
  font-weight: 400 !important;
  margin-bottom: 32px !important;
`;

const TextArea = styled(Input.TextArea)`
  border-radius: 4px;
  border-color: ${COLORS.Neutral300};
`;

const NameInput = styled(LearnInInput)`
  border-radius: 4px;
  border-color: ${COLORS.Neutral300};
  font-size: 0.875rem;
`;

const Label = styled.p`
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin: 0;
`;

interface Props {
  fieldErrors: {restrictedAccess: string};
  form?: FormInstance;
  onSubmit: () => void;
  restrictedAccess: boolean;
  setRestrictedAccess: (state: boolean) => void;
}

export const NewAcademy = ({
  fieldErrors,
  form,
  onSubmit,
  restrictedAccess,
  setRestrictedAccess,
}: Props) => {
  return (
    <Page>
      <Card role="article" aria-labelledby="new-academy-title">
        <CardContent>
          <Header id="new-academy-title">
            {i18n.t(k.ACADEMY__ADD__TITLE)}
          </Header>
          <Subheader>{i18n.t(k.ACADEMY__ADD__DESCRIPTION)}</Subheader>
          <Form
            form={form}
            layout="vertical"
            name="newAcademy"
            requiredMark={false}>
            <FormItem
              label={<Label>{i18n.t(k.ACADEMY__NAME)}</Label>}
              name="name"
              rules={[
                {
                  required: true,
                  message: i18n.t(k.ACADEMY__VALIDATION__ACADEMY_NAME),
                },
              ]}>
              <NameInput aria-label={i18n.t(k.NAME)} size="large" />
            </FormItem>
            <FormItem
              label={
                <Label>
                  {i18n.t(k.ACADEMY__VALIDATION__SHORT_DESCRIPTION)}
                </Label>
              }
              name="description"
              rules={[
                {
                  required: true,
                  message: i18n.t(k.ACADEMY__VALIDATION__SHORT_TITLE),
                },
              ]}>
              <TextArea aria-label={i18n.t(k.GENERIC__DESCRIPTION)} rows={4} />
            </FormItem>
            <RestrictedAccessForm
              column
              hasCheckmark
              isAcademy
              itemStyles={{
                background: 'white',
                width: '100%',
                marginBottom: '16px',
              }}
              restrictedAccess={restrictedAccess}
              setRestrictedAccess={setRestrictedAccess}
            />
            <ErrorMessage message={fieldErrors.restrictedAccess} />
          </Form>
        </CardContent>
        <Image>
          <img src={NEW_ACADEMY_PAGE_DECORATION} alt="" />
        </Image>
      </Card>
      <Footer>
        <FooterButtonContainer>
          <LearnInButton
            htmlType="submit"
            onClick={onSubmit}
            tag={ButtonTags.Primary}>
            {i18n.t(k.CTA__SAVE_AND_CONTINUE)}
          </LearnInButton>
        </FooterButtonContainer>
      </Footer>
    </Page>
  );
};
