import './EmployeeEngagementChart.scss';
import {Fragment, useState, useEffect} from 'react';
import {i18n, k} from '@i18n/translate';
import {Card, Col, Row, Skeleton} from 'antd';
import {Line} from '@ant-design/charts';
import {EmployeeEngagementItem} from '../../../../store/interface';
import {CHART_COLORS_SEQUENCES} from '@utils/constants';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {FinanceType} from '@generated/enums';

interface Props {
  employeeEngagementData: EmployeeEngagementItem[];
  employeeEngagementLoading: boolean;
  isDirectBillingPlan?: boolean;
}

const EmployeeEngagementChart = ({
  employeeEngagementData,
  employeeEngagementLoading,
  isDirectBillingPlan,
}: Props) => {
  const [data, setData] = useState([]);
  const getLearningBudgetInfoQuery =
    useUserPlanFinanceLearningBudgetOverviewVMQuery();
  const isReimbursementPlan =
    getLearningBudgetInfoQuery.data?.financeType ===
    FinanceType.TuitionReimbursement;

  const config: any = {
    data: data || [],
    // height:325,
    xField: 'month',
    yField: 'value',
    seriesField: 'category',
    yAxis: {
      label: {
        formatter: function formatter(v: any) {
          // NOTE: copied from the antd charts library
          return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
            return ''.concat(s, ',');
          });
        },
      },
      position: 'right',
    },
    smooth: true,
    colorField: 'color',
    color: CHART_COLORS_SEQUENCES.PRIMARY,
    point: {
      shape: function shape(_ref: any) {
        return 'circle';
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'top-right',
      marker: {
        symbol: 'circle',
      },
    },
  };

  // NOTE: this is custom logic for adding the checkbox which is not needed at the time
  //   const onSelectCheckBoxData = (checkboxData: any) => {
  //     if (checkboxData.checked) {
  //       checkBoxToggleData.push(checkboxData.value);
  //       setCheckBoxToggleData(checkBoxToggleData);
  //     } else {
  //       const index = checkBoxToggleData.indexOf(checkboxData.value);
  //       checkBoxToggleData.splice(index, 1);
  //       setCheckBoxToggleData(checkBoxToggleData);
  //     }
  //     if (checkBoxToggleData.length > 0) {
  //       let filterData = [];
  //       checkBoxToggleData.forEach((detail) =>
  //         employeeEngagementData.filter((filterDetail) => filterDetail.category === detail && filterData.push(filterDetail))
  //       );
  //       return filterData;
  //     } else {
  //       return [];
  //     }
  //   };

  //   const onLegendChange = (checkedValues: any) => {
  //     const allData = onSelectCheckBoxData(checkedValues.target);
  //     setData(allData);
  //   };

  //   const checkBoxData = () => {
  //     return (
  //       <div style={{display: 'flex'}}>
  //         <Checkbox defaultChecked value={EmployeeEngagementData.ENROLL_IN_A_PROGRAM} onChange={onLegendChange}>
  //           <span className="dot" style={{backgroundColor: ColorPlate.ENROLL_IN_A_PROGRAM}} />
  //           {EmployeeEngagementData.ENROLL_IN_A_PROGRAM}
  //         </Checkbox>
  //         <Checkbox defaultChecked value={EmployeeEngagementData.LOGGED_IN} onChange={onLegendChange}>
  //           <span className="dot" style={{backgroundColor: ColorPlate.LOGGED_IN}} />
  //           {EmployeeEngagementData.LOGGED_IN}
  //         </Checkbox>
  //         <Checkbox defaultChecked value={EmployeeEngagementData.CREATED_A_PLAN} onChange={onLegendChange}>
  //           <span className="dot" style={{backgroundColor: ColorPlate.CREATED_A_PLAN}} />
  //           {EmployeeEngagementData.CREATED_A_PLAN}
  //         </Checkbox>
  //       </div>
  //     );
  //   };

  useEffect(() => {
    setData(
      isReimbursementPlan || isDirectBillingPlan
        ? employeeEngagementData?.filter(
            (item) => item.category !== i18n.t(k.REPORT__USED_VC)
          )
        : employeeEngagementData
    );
  }, [employeeEngagementData]);

  return (
    <Fragment>
      <Row gutter={[8, 8]} align="middle" justify="center">
        <Col xs={24} md={24}>
          <Card
            title={i18n.t(k.REPORT__EMPLOYEE_ACTIVITY)}
            aria-label={i18n.t(k.REPORT__EMPLOYEE_ACTIVITY)}
            role="figure"
            key="key"
            className="employee-engagement-item-card"
            style={{borderRadius: '8px', margin: '10px 0px'}}>
            <Row
              gutter={[8, 8]}
              align="middle"
              justify="center"
              style={{padding: '24px 24px'}}>
              <Col xs={24} md={24}>
                {!employeeEngagementLoading ? (
                  employeeEngagementData?.length ? (
                    <Line autoFit {...config} />
                  ) : (
                    <div style={{textAlign: 'center', wordWrap: 'break-word'}}>
                      <Row
                        justify="center"
                        align="middle"
                        style={{marginTop: '10%'}}>
                        <Col xs={3}></Col>
                        <Col xs={18}>
                          <h1>{i18n.t(k.REPORT__EMPLOYEE_ACTIVITY__EMPTY)}</h1>
                          <p>
                            {i18n.t(k.REPORT__EMPLOYEE__ENGAGEMENT__SUMMARY)}
                          </p>
                        </Col>
                        <Col xs={3}></Col>
                      </Row>
                    </div>
                  )
                ) : (
                  <Row gutter={[16, 16]}>
                    <Col xs={24}>
                      <Skeleton active />
                    </Col>
                    <Col xs={24}>
                      <Skeleton active />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default EmployeeEngagementChart;
