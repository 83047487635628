import {useEffect, useState} from 'react';
import AddEditStepRequirementModal, {
  AddEditStepRequirementFormNames,
  AddEditStepRequirementFormFields,
} from './AddEditStepRequirementModal.view';
import {Form} from 'antd';
import {StepRequirementFields} from '@components/reusable/StepRequirementFields';
import {useAcademyStepVMsQuery} from '@hooks/apiEndpoints/academy/queries';
import {useUpdateAcademyStepRequirement} from '@hooks/apiEndpoints/academy/mutations';
import {Maybe} from '@utils/typeUtils';
import {basicSorter} from '@components/reusable/LearnInTable';
import {i18n, k} from '@i18n/translate';
import {hasRequirement} from '@utils/requirementGating';
import {AcademyStepVM} from '@generated/interfaces';

/*
|--------------------------------------------------------------------------
| Util Methods
|--------------------------------------------------------------------------
*/

const getStepById = (
  stepId: number,
  steps: AcademyStepVM[]
): Maybe<AcademyStepVM> => steps?.find(({id}) => id === stepId);

const getPreviousSteps = (
  step: AcademyStepVM | undefined,
  steps: AcademyStepVM[]
): Maybe<AcademyStepVM[]> => {
  return steps?.filter((s) => !step || s.order < step.order);
};

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface AddEditStepRequirementModalContainerProps {
  levelId: number;
  stepId: number;
  onCancel: () => void;
}

function AddEditStepRequirementModalContainer({
  levelId,
  onCancel,
  stepId,
}: AddEditStepRequirementModalContainerProps) {
  // Util Hooks
  const [form] = Form.useForm<AddEditStepRequirementFormFields>();
  const requiredId = Form.useWatch('requiredId', form);
  const [previousStepIsRequired, setPreviousStepIsRequired] =
    useState<boolean>(false);
  // This does not make an api call, but only gets data from the cache
  const {cacheData: steps, invalidateExact: invalidateSteps} =
    useAcademyStepVMsQuery(levelId, {
      enabled: false,
    });

  const updateAcademyStepRequirement = useUpdateAcademyStepRequirement({
    onSuccess: () => {
      invalidateSteps();
      onCancel();
    },
  });

  useEffect(() => {
    const step = getStepById(stepId, steps);
    form.setFieldsValue({
      [AddEditStepRequirementFormNames.RequiredId]: step?.requiredId,
      requirementProperty: step?.requirementProperty,
    });
  }, [form, stepId, steps]);
  const step = getStepById(stepId, steps);
  const previousSteps = getPreviousSteps(step, steps);
  const previousStepId = previousSteps[previousSteps.length - 1]?.id;

  const stepHasRequirement = hasRequirement(step);

  const handleClickRemove = () => {
    updateAcademyStepRequirement.mutate({
      payload: {
        blockedId: stepId,
        requiredId: null,
      },
    });
  };

  const handleOk = async () => {
    await form.validateFields().then((fields) => {
      updateAcademyStepRequirement.mutate({
        payload: {
          blockedId: stepId,
          requiredId: fields[AddEditStepRequirementFormNames.RequiredId],
          requirementProperty: fields.requirementProperty,
        },
      });
    });
  };

  useEffect(() => {
    if (previousStepIsRequired && requiredId === null) {
      form.setFieldsValue({requiredId: previousStepId});
    }
  }, [previousStepIsRequired, requiredId, form, previousStepId]);

  useEffect(() => {
    if (previousStepId !== requiredId && previousStepIsRequired) {
      setPreviousStepIsRequired(false);
    }
  }, [previousStepId, requiredId, previousStepIsRequired]);

  return (
    <AddEditStepRequirementModal
      form={form}
      onCancel={onCancel}
      onClickRemove={handleClickRemove}
      onOk={handleOk}
      fieldsComponent={
        <StepRequirementFields
          currentlySelectedRequirementId={requiredId}
          previousStepIsRequired={previousStepIsRequired}
          setPreviousStepIsRequired={(isRequired) => {
            form.setFieldValue('requiredId', previousStepId);
            setPreviousStepIsRequired(isRequired);
          }}
          stepRequirementOptions={getPreviousSteps(step, steps)
            ?.sort(basicSorter('order'))
            .map(({id, title, type}) => ({id, title, type}))}
          showFields={true}
        />
      }
      showRemoveRequirementButton={stepHasRequirement}
      title={`${stepHasRequirement ? 'Edit' : 'Add'} Requirement`}
      selectInputLabel={i18n.t(k.STEP__REQUIRES_ACCESS__FORMAT, {
        stepTitle: step?.title,
      })}
      options={getPreviousSteps(step, steps)
        ?.sort(basicSorter('order'))
        .map(({id, title}) => ({id, title}))}
    />
  );
}

export default AddEditStepRequirementModalContainer;
