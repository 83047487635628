import {LxpContentType} from './../enums';
import {LxpContentResponse} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: LxpContentResponse

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getLxpContentResponseMockData(
  args?: Partial<LxpContentResponse>
): LxpContentResponse {
  return {
    contentType: LxpContentType.Unknown,
    description: faker.lorem.lines(),
    durationUnitType: faker.word.noun(), // optional
    durationValue: faker.datatype.number(), // optional
    externalCompletionOnly: faker.datatype.boolean(),
    format: faker.word.noun(),
    id: faker.datatype.number(),
    imageUrl: faker.image.imageUrl(), // optional
    link: faker.internet.url(),
    skills: [faker.word.noun()],
    title: faker.company.catchPhrase(),
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const LxpContentResponseMockData: LxpContentResponse = {
  contentType: LxpContentType.Unknown,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  durationUnitType: 'string', // optional
  durationValue: 123, // optional
  externalCompletionOnly: true,
  format: 'string',
  id: 1,
  imageUrl: 'https://loremflickr.com/640/480', // optional
  link: 'https://this-person-does-not-exist.com/en',
  skills: ['string'],
  title: 'Advanced Web Design',
};
