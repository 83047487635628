export const getApprovalsPlanType = (
  pathname: string
): {
  isFinancePage: boolean;
  isTimePage: boolean;
  isProgramPage: boolean;
  isResourcePage: boolean;
} => {
  const pageType = pathname?.split('/').pop().toLowerCase();
  return {
    isFinancePage: pageType === 'finance',
    isTimePage: pageType === 'time',
    isProgramPage: pageType === 'program',
    isResourcePage: pageType === 'resource',
  };
};
