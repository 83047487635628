import Progress, {ProgressProps} from 'antd/lib/progress';
import {COLORS} from '@utils/constants';
import styled from 'styled-components';

const Container = styled.div`
  .ant-progress-inner {
    background-color: ${COLORS.Neutral200};
  }
`;
export interface ILearnInProgressBarProps extends ProgressProps {
  percent: number;
  round?: boolean;
}

function LearnInProgressBar({
  percent,
  round = true,
  showInfo = true,
  ...rest
}: ILearnInProgressBarProps) {
  return (
    <Container>
      <Progress
        percent={round ? Math.round(percent) : percent}
        strokeColor={COLORS.Green500}
        showInfo={showInfo}
        {...rest}
      />
    </Container>
  );
}

export default LearnInProgressBar;
