import './Prepayment.scss';
import 'antd/dist/antd.css';
import {Fragment, useMemo, useState} from 'react';
import Plaid from './components/plaid';
import {i18n, k} from '@i18n/translate';
import {useMutation, useQuery} from 'react-query';
import {Col, Divider, Form, Modal, Row, Skeleton} from 'antd';
import {LearnInTooltip} from '@components/reusable/Tooltip';
import {QuestionCircleOutlined} from '@ant-design/icons';
import {notify} from '@components/user/notifications';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import ButtonLoadingSpinner from '@elements/buttonloadingSpinner/ButtonLoadingSpinner';
import LoadingSpinner from '@elements/loadingSpinner/LoadingSpinner';
import PageTitleBar from '@components/providers/pageTitleBar/PageTitleBar';
import {openInNewTab} from '@components/providers/utils';
import PageContentLayout from '@components/providers/pageContentLayout/PageContentLayout';
import {
  BalanceDetailsVM,
  ConnectedBankAccountVM,
  PlaidConfigVM,
  TopUpReceiptVM,
} from '@models/serverModels';
import {
  simpleDeleteFn,
  simpleInvalidateAllQueriesFn,
  simpleInvalidateExactQueryFn,
  simpleMutationFn,
  simpleQueryFn,
  simpleSetQueryDataFn,
} from '@store/queryClient';
import {
  getAddCompanyBankAccountRm,
  getConnectedBankAccountsRq,
  getDefaultBankAccountRm,
  getDeleteCompanyBankAccountRm,
  getIsOnboardingStartedRq,
  getIssuingBalanceDetailsRq,
  getIssuingBalanceHistoryRq,
  getOnboardingLinkRq,
  getPlaidLinkConfigRq,
  getTopUpIssuingBalanceRm,
} from '@store/apiEndpoints';
import {
  LearnInInput,
  LearnInMoneyInput,
} from '@components/reusable/Input/Input.style';
import {InputTags} from '@components/reusable/Input/InputEnums';
import {LearnInContainer} from '@components/reusable/Container/Container';
import {ContainerTags} from '@components/reusable/Container/ContainerEnums';
import {LearnInForm, LearnInFormItem} from '@components/reusable/Form/Form';
import {FormItemTags} from '@components/reusable/Form/FormEnums';
import {datadogLogs} from '@datadog/browser-logs';
import LearnInTable, {TableCell} from '@components/reusable/LearnInTable';
import {
  exchangeAmountBack,
  formatCurrency,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {useGetUserQuery} from '@hooks/apiEndpoints/user/queries';
import {
  useExchangeRate,
  useGetCurrencyExchangeQuery,
} from '@hooks/apiEndpoints/localization/queries';
import useFeatureFlags from '@hooks/useFeatureFlags';
import NoDataImage from '@blocks/NoDataImage';
import {Helmet} from 'react-helmet-async';

const Prepayment = ({title}: {title?: string}) => {
  const plaidLinkConfigRq = getPlaidLinkConfigRq();
  const getPlaidLinkConfigQuery = useQuery<PlaidConfigVM>(
    plaidLinkConfigRq.queryKey,
    () => simpleQueryFn(plaidLinkConfigRq.path)
  );

  const connectedBankAccountsRq = getConnectedBankAccountsRq();
  const getConnectedBankAccountsQuery = useQuery<ConnectedBankAccountVM[]>(
    connectedBankAccountsRq.queryKey,
    () => simpleQueryFn(connectedBankAccountsRq.path)
  );

  const issuingBalanceDetailsRq = getIssuingBalanceDetailsRq();
  const getIssuingBalanceDetailsQuery = useQuery<BalanceDetailsVM>(
    issuingBalanceDetailsRq.queryKey,
    () => simpleQueryFn(issuingBalanceDetailsRq.path)
  );

  const issuingBalanceHistoryRq = getIssuingBalanceHistoryRq();
  const getIssuingBalanceHistoryQuery = useQuery<TopUpReceiptVM[]>(
    issuingBalanceHistoryRq.queryKey,
    () => simpleQueryFn(issuingBalanceHistoryRq.path)
  );

  const isOnboardingStartedRq = getIsOnboardingStartedRq();
  const getIsOnboardingStartedQuery = useQuery<boolean>(
    isOnboardingStartedRq.queryKey,
    () => simpleQueryFn(isOnboardingStartedRq.path)
  );

  const {isFeatureFlagOn} = useFeatureFlags();
  const isPrepaymentTabOn = isFeatureFlagOn.PrepaymentTab;
  const isLinkingNewBankAccountOn =
    isFeatureFlagOn.PrepaymentLinkNewBankAccount;

  const [enableOnboardingLink, setEnableOnboardingLink] =
    useState<boolean>(false);
  const onboardingLinkRq = getOnboardingLinkRq();
  const {isLoading} = useQuery<string>(
    onboardingLinkRq.queryKey,
    () => simpleQueryFn(onboardingLinkRq.path),
    {
      refetchOnWindowFocus: false,
      enabled: enableOnboardingLink,
      onSuccess: (onboardingUrl) => {
        setEnableOnboardingLink(false);
        if (onboardingUrl !== null) {
          openInNewTab(onboardingUrl);
        }
      },
    }
  );

  const getUserQuery = useGetUserQuery();

  const getCurrencyExchangeQuery = useGetCurrencyExchangeQuery({
    enabled: true,
  });

  const exchangeRate = useExchangeRate(
    MONEY_DEFAULT.currency,
    getUserQuery.data?.currency
  );

  const renderAmount = (amount: number) => {
    return formatCurrency(amount, getUserQuery.data?.currency, exchangeRate);
  };

  const addCompanyBankAccountMutation = useMutation(
    ({publicToken, accountId}: {publicToken: string; accountId: string}) => {
      const addCompanyBankAccountRm = getAddCompanyBankAccountRm(
        publicToken,
        accountId
      );
      return simpleMutationFn<ConnectedBankAccountVM>(
        addCompanyBankAccountRm.path,
        addCompanyBankAccountRm.payload
      );
    },
    {
      onSuccess: (data) => {
        notify.addCompanyBankAccountSuccess();
        simpleSetQueryDataFn(connectedBankAccountsRq.queryKey, data);
      },
      onError: () => {
        notify.addCompanyBankAccountFailure();
        datadogLogs.logger.error('Add company bank account error');
      },
    }
  );

  const addDefaultBankAccountMutation = useMutation(
    () => {
      const addCompanyBankAccountRm = getDefaultBankAccountRm();
      return simpleMutationFn<ConnectedBankAccountVM>(
        addCompanyBankAccountRm.path,
        addCompanyBankAccountRm.payload
      );
    },
    {
      onSuccess: (data) => {
        notify.addCompanyBankAccountSuccess();
        simpleSetQueryDataFn(connectedBankAccountsRq.queryKey, data);
      },
      onError: () => {
        notify.addCompanyBankAccountFailure();
        datadogLogs.logger.error('Add company bank account error');
      },
    }
  );

  const deleteCompanyBankAccountMutation = useMutation(
    ({bankAccountId}: {bankAccountId: string}) => {
      const deleteCompanyBankAccountRm =
        getDeleteCompanyBankAccountRm(bankAccountId);
      return simpleDeleteFn<undefined>(
        deleteCompanyBankAccountRm.path,
        deleteCompanyBankAccountRm.payload
      );
    },
    {
      onSuccess: () => {
        notify.deleteCompanyBankAccountSuccess();
        simpleInvalidateAllQueriesFn();
      },
      onError: (error: any) => {
        notify.deleteCompanyBankAccountFailure();
        console.log('Delete company bank account error: ', error);
      },
    }
  );

  const topUpIssuingBalanceMutation = useMutation(
    ({amount, description}: {amount: number; description: string}) => {
      // Do not format, it gets sent to the API as a number
      const inBaseCurrency = exchangeAmountBack(amount, exchangeRate);
      amount = Number(inBaseCurrency);

      const topUpIssuingBalanceRm = getTopUpIssuingBalanceRm(
        amount,
        description
      );

      return simpleMutationFn<ConnectedBankAccountVM>(
        topUpIssuingBalanceRm.path,
        topUpIssuingBalanceRm.payload
      );
    },
    {
      onSuccess: (data) => {
        notify.topUpBalanceSuccess();
        simpleSetQueryDataFn(issuingBalanceDetailsRq.queryKey, data);
        simpleInvalidateExactQueryFn(issuingBalanceHistoryRq.queryKey);
      },
      onError: (error) => {
        notify.topUpBalanceFailure();
        console.log('Top-up balance error: ', error);
      },
    }
  );

  const [topUpForm] = Form.useForm();

  const onSubmitTopUp = (values: any) => {
    topUpIssuingBalanceMutation.mutate({
      amount: values.amount,
      description: values.description,
    });
    setIsFundsModalVisible(false);
  };

  const onTopUpFailed = (errorInfo: any) => {
    notify.testFailure();
    console.error('Failed:', errorInfo);
  };

  const deleteBankAccount = (bankAccountId: string): void => {
    if (bankAccountId) {
      deleteCompanyBankAccountMutation.mutate({
        bankAccountId: bankAccountId,
      });
    }
  };

  const [isFundsModalVisible, setIsFundsModalVisible] = useState(false);

  const showFundsModal = () => {
    setIsFundsModalVisible(true);
  };

  const handleFundsModalOk = () => {
    setIsFundsModalVisible(false);
  };

  const handleFundsModalCancel = () => {
    setIsFundsModalVisible(false);
  };

  const handleOnboardingForm = () => {
    setEnableOnboardingLink(true);
  };

  const historyColumns = useMemo(() => {
    return [
      {
        title: i18n.t(k.DATE),
        dataIndex: 'date',
        key: 'date',
        render: (date: any) => <TableCell>{date}</TableCell>,
        sorter: (a: any, b: any) => a.date.localeCompare(b.audience),
      },
      {
        title: i18n.t(k.ACCOUNT),
        dataIndex: 'accountLast4',
        key: 'accountLast4',
        render: (accountLast4: any) => {
          return <span>xxxxxx{accountLast4}</span>;
        },
      },
      {
        title: i18n.t(k.GENERIC__AMOUNT),
        dataIndex: 'amount',
        key: 'amount',
        render: (amount: any) => {
          return <span>{renderAmount(amount)}</span>;
        },
        sorter: (a: any, b: any) => a.amount - b.amount,
      },
      {
        title: i18n.t(k.GENERIC__DESCRIPTION),
        dataIndex: 'description',
        key: 'description',
        render: (description: string) =>
          k[description] ? i18n.t(k[description]) : description,
      },
      {
        title: i18n.t(k.STATUS__STATUS),
        dataIndex: 'status',
        key: 'status',
        render: (status: any) => status,
        sorter: (a: any, b: any) => a.status - b.status,
      },
    ];
  }, [exchangeRate]);

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <PageContentLayout>
        <div>
          <PageTitleBar title={i18n.t(k.CARD__VIRTUAL_CARD__FUNDS)} />
          <div className="prepayment-container">
            <div>
              <p className="prepayment-subtitle">
                {i18n.t(k.PREPAYMENT__ONBOARDING)}
              </p>
              {getIsOnboardingStartedQuery.isLoading ? (
                <LoadingSpinner />
              ) : getIsOnboardingStartedQuery.isSuccess &&
                getIsOnboardingStartedQuery.data ? (
                <div
                  className="prepayment-no-account-container"
                  dangerouslySetInnerHTML={{
                    __html: i18n.t(k.PREPAYMENT__LINK__USED),
                  }}></div>
              ) : (
                <div className="prepayment-no-account-container">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: i18n.t(k.PREPAYMENT__LINK__TRANSIENT),
                    }}></div>
                  <div className="prepayment-centered-button-container">
                    <LearnInButton
                      tag={ButtonTags.Primary}
                      onClick={handleOnboardingForm}>
                      {isLoading ? (
                        <ButtonLoadingSpinner />
                      ) : (
                        i18n.t(k.PREPAYMENT__ONBOARDING__FORM)
                      )}
                    </LearnInButton>
                  </div>
                </div>
              )}
            </div>

            {isPrepaymentTabOn && (
              <div>
                {getConnectedBankAccountsQuery.isSuccess &&
                getConnectedBankAccountsQuery?.data?.length > 0 ? (
                  <Fragment>
                    <p className="prepayment-subtitle">
                      {i18n.t(k.PREPAYMENT__CONNECTED_ACCOUNT)}
                    </p>
                    {getConnectedBankAccountsQuery.data.map((bankAccount) => (
                      <Row
                        key={bankAccount.id}
                        className="prepayment-row prepayment-bordered-container">
                        <Col span={18} order={1}>
                          <label className="prepayment-label">
                            {bankAccount.bankName}
                          </label>
                        </Col>
                        <Col span={6} order={2}>
                          {!bankAccount.default &&
                            !deleteCompanyBankAccountMutation.isLoading && (
                              <LearnInButton
                                tag={ButtonTags.DestructiveRemovedBorder}
                                onClick={() => {
                                  deleteBankAccount(bankAccount.id);
                                }}>
                                {i18n.t(k.ACCOUNT__REMOVE)}
                              </LearnInButton>
                            )}
                          {!bankAccount.default &&
                            deleteCompanyBankAccountMutation.isLoading && (
                              <ButtonLoadingSpinner />
                            )}
                        </Col>
                      </Row>
                    ))}
                    {isLinkingNewBankAccountOn && (
                      <Row className="prepayment-row">
                        {!addCompanyBankAccountMutation.isLoading &&
                        !getPlaidLinkConfigQuery.isLoading &&
                        getPlaidLinkConfigQuery.data !== null ? (
                          <Plaid
                            plaidConfig={getPlaidLinkConfigQuery.data}
                            addCompanyBankAccountMutation={
                              addCompanyBankAccountMutation
                            }
                          />
                        ) : (
                          <ButtonLoadingSpinner />
                        )}
                      </Row>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="prepayment-no-account-container">
                      <p className="prepayment-subtitle">
                        {i18n.t(k.PREPAYMENT__CONNECT_BANK)}
                      </p>
                      <p>{i18n.t(k.PREPAYMENT__CONNECT_BANK__DESCRIPTION)}</p>
                      <div className="prepayment-centered-button-container">
                        {!getConnectedBankAccountsQuery.isLoading &&
                        !getPlaidLinkConfigQuery.isLoading &&
                        getPlaidLinkConfigQuery.data !== null &&
                        !addCompanyBankAccountMutation.isLoading &&
                        !addDefaultBankAccountMutation.isLoading ? (
                          <div>
                            <LearnInButton
                              tag={ButtonTags.Primary}
                              onClick={() =>
                                addDefaultBankAccountMutation.mutate()
                              }>
                              {i18n.t(k.PREPAYMENT__ADD_DEFAULT_ACCOUNT)}
                            </LearnInButton>
                            <br />
                            <Plaid
                              plaidConfig={getPlaidLinkConfigQuery.data}
                              addCompanyBankAccountMutation={
                                addCompanyBankAccountMutation
                              }
                            />
                          </div>
                        ) : (
                          <ButtonLoadingSpinner />
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
            )}

            <div>
              <p className="prepayment-subtitle">
                {i18n.t(k.CARD__VIRTUAL_CARD__BALANCE)}
              </p>
              {getConnectedBankAccountsQuery?.data?.length > 0 ? (
                <div>
                  {getIssuingBalanceDetailsQuery.isSuccess &&
                    getIssuingBalanceDetailsQuery.data && (
                      <Fragment>
                        <Row className="prepayment-row prepayment-bordered-container">
                          <Col span={5}>
                            <label className="prepayment-balance-label">
                              {i18n.t(k.MONEY__BALANCE__CURRENT)}{' '}
                              <LearnInTooltip
                                title={i18n.t(
                                  k.CARD__VIRTUAL_CARD__AVAILABLE__TOOLTIP
                                )}>
                                <QuestionCircleOutlined aria-hidden="true" />
                              </LearnInTooltip>
                            </label>
                            <br />
                            {renderAmount(
                              getIssuingBalanceDetailsQuery.data.currentBalance
                            )}
                          </Col>
                          <Col span={1}>
                            <Divider type="vertical" style={{height: '100%'}} />
                          </Col>
                          <Col span={5}>
                            <label className="prepayment-balance-label">
                              {i18n.t(k.APPROVAL__TOTAL_APPROVED)}{' '}
                              <LearnInTooltip
                                title={i18n.t(
                                  k.APPROVAL__TOTAL_APPROVED__TOOLTIP
                                )}>
                                <QuestionCircleOutlined aria-hidden="true" />
                              </LearnInTooltip>
                            </label>
                            <br />
                            {renderAmount(
                              getIssuingBalanceDetailsQuery.data
                                .allocatedBalance
                            )}
                          </Col>
                          <Col span={1}>
                            <Divider type="vertical" style={{height: '100%'}} />
                          </Col>
                          <Col span={5}>
                            <label className="prepayment-balance-label">
                              {i18n.t(k.MONEY__PROJECTED_BALANCE)}{' '}
                              <LearnInTooltip
                                title={i18n.t(k.MONEY__BALANCE_MINUS_APPROVED)}>
                                <QuestionCircleOutlined aria-hidden="true" />
                              </LearnInTooltip>
                            </label>
                            <br />
                            {renderAmount(
                              getIssuingBalanceDetailsQuery.data
                                .currentBalance -
                                getIssuingBalanceDetailsQuery.data
                                  .allocatedBalance
                            )}
                          </Col>
                          <Col span={1}>
                            <Divider type="vertical" style={{height: '100%'}} />
                          </Col>
                          <Col span={5}>
                            <label className="prepayment-balance-label">
                              {i18n.t(k.MONEY__SPENT_YEAR__FORMAT, {
                                year: new Date().getFullYear().toString(),
                              })}{' '}
                              <LearnInTooltip
                                title={i18n.t(k.MONEY__SPENT_YEAR__TOOLTIP)}>
                                <QuestionCircleOutlined aria-hidden="true" />
                              </LearnInTooltip>
                            </label>
                            <br />
                            {renderAmount(
                              getIssuingBalanceDetailsQuery.data.spentYTD
                            )}
                          </Col>
                        </Row>
                      </Fragment>
                    )}
                  {isPrepaymentTabOn && (
                    <Fragment>
                      <LearnInButton
                        tag={ButtonTags.Primary}
                        onClick={showFundsModal}>
                        {getIssuingBalanceDetailsQuery.isLoading ||
                        topUpIssuingBalanceMutation.isLoading ? (
                          <ButtonLoadingSpinner />
                        ) : (
                          i18n.t(k.FUND__ADD__PLURAL)
                        )}
                      </LearnInButton>
                      <Modal
                        title={i18n.t(k.FUND__ADD__PLURAL)}
                        open={isFundsModalVisible}
                        onOk={handleFundsModalOk}
                        onCancel={handleFundsModalCancel}
                        footer={
                          <LearnInContainer
                            tag={ContainerTags.AddFundsButtonContainer}>
                            <LearnInButton
                              tag={ButtonTags.Secondary}
                              key="back"
                              onClick={handleFundsModalCancel}>
                              {i18n.t(k.CTA__CANCEL)}
                            </LearnInButton>
                            <LearnInButton
                              form="topup"
                              tag={ButtonTags.Primary}
                              key="submit"
                              htmlType="submit"
                              disabled={topUpIssuingBalanceMutation.isLoading}>
                              {i18n.t(k.MONEY__SCHEDULE_TRANSFER)}
                            </LearnInButton>
                          </LearnInContainer>
                        }>
                        <p>{i18n.t(k.CARD__VIRTUAL_CARD__FOR_PREPAYMENT)}</p>
                        <LearnInForm
                          id="topup"
                          form={topUpForm}
                          initialValues={{amount: 1}}
                          onFinish={onSubmitTopUp}
                          onFinishFailed={onTopUpFailed}>
                          <>
                            <LearnInFormItem
                              tag={FormItemTags.AddFundsModal}
                              label={i18n.t(k.GENERIC__AMOUNT)}
                              name="amount"
                              rules={[
                                {
                                  required: true,
                                  message: i18n.t(
                                    k.VALIDATION__GREATER_THAN_ZERO
                                  ),
                                },
                              ]}>
                              <LearnInMoneyInput
                                currency={getUserQuery.data?.currency}
                                min={0.01}
                                max={50000}
                                step={0.01}
                                style={{width: '150px'}}
                              />
                            </LearnInFormItem>
                            <LearnInFormItem
                              tag={FormItemTags.AddFundsModal}
                              label={i18n.t(k.GENERIC__DESCRIPTION)}
                              name="description"
                              rules={[{required: false}]}>
                              <LearnInInput tag={InputTags.PRIMARY} />
                            </LearnInFormItem>
                          </>
                        </LearnInForm>{' '}
                      </Modal>
                    </Fragment>
                  )}
                </div>
              ) : (
                <Fragment>
                  {i18n.t(k.CARD__VIRTUAL_CARD__NO_BALANCE_DETAILS)}
                </Fragment>
              )}
            </div>

            <div>
              <p className="prepayment-subtitle">
                {i18n.t(k.CARD__TRANSFER_HISTORY)}
              </p>
              {getIssuingBalanceHistoryQuery.isLoading ||
              topUpIssuingBalanceMutation.isLoading ||
              addCompanyBankAccountMutation.isLoading ||
              getCurrencyExchangeQuery.isLoading ? (
                <Skeleton active />
              ) : getIssuingBalanceHistoryQuery.isSuccess &&
                getIssuingBalanceHistoryQuery.data ? (
                <LearnInTable
                  dataSource={getIssuingBalanceHistoryQuery.data}
                  columns={historyColumns}
                  nonFullWidth={true}
                />
              ) : (
                <NoDataImage />
              )}
            </div>
          </div>
        </div>
      </PageContentLayout>
    </div>
  );
};
export default Prepayment;
