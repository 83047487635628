import {UpdateAcademyIntegrationChatChannelRequest} from './../interfaces';

import {mutationHookFactory} from './../../utils';
import {UpdateAcademyIntegrationChatChannelRequestSchema} from '../zodSchemas/UpdateAcademyIntegrationChatChannelRequestSchema.schema';
import {z} from 'zod';

// Do not modify directly
// Generated from file: learnin.core.api/Controllers/Academies/AcademyIntegrationController.cs

/*
|--------------------------------------------------------------------------
|  {academyId}/integrations/chat/channels 
|--------------------------------------------------------------------------
*/

/**
 *  @example
 *
 *  import {useAcademiesIntegrationsChatChannelsPutMutation} from '@generated/hooks';
 *
 *  const academiesIntegrationsChatChannelsPut = useAcademiesIntegrationsChatChannelsPutMutation({
 *    onSuccess: () => {},
 *  });
 *
 *  academiesIntegrationsChatChannelsPut.mutate({
 *    payload: {
 *      ...UpdateAcademyIntegrationChatChannelRequest
 *    },
 *    pathVars: {
 *      academyId
 *    }
 *  })
 *
 *  @access Admin Access Only
 *
 */

export const useAcademiesIntegrationsChatChannelsPutMutation =
  mutationHookFactory<
    UpdateAcademyIntegrationChatChannelRequest, // payload
    {academyId: number}, // path variables
    null
  >(
    'useAcademiesIntegrationsChatChannelsPutMutation',
    'put',
    ({academyId}) => `academies/${academyId}/integrations/chat/channels`,
    {payloadSchema: UpdateAcademyIntegrationChatChannelRequestSchema}
  );
