import {AttachmentLinkType, AttachmentType} from './../enums';
import {UploadAttachmentRequest} from './../interfaces';
import {faker} from '@faker-js/faker';

// Interface: UploadAttachmentRequest

/*
|--------------------------------------------------------------------------
| Generate Fake Data and Attributes, Dynamic
|--------------------------------------------------------------------------
*/

export function getUploadAttachmentRequestMockData(
  args?: Partial<UploadAttachmentRequest>
): UploadAttachmentRequest {
  return {
    attachmentId: String(faker.datatype.number()), // optional
    chunkCheckSum: faker.word.noun(),
    chunkNumber: faker.datatype.number(),
    chunkSize: faker.datatype.number(),
    description: faker.lorem.lines(), // optional
    file: undefined,
    fileName: faker.name.fullName(),
    linkedItemId: faker.datatype.number(), // optional
    linkType: AttachmentLinkType.AcademyStepAttachment, // optional
    totalChunks: faker.datatype.number(),
    totalSize: faker.datatype.number(),
    type: AttachmentType.File,
    ...args,
  };
}

/*
|--------------------------------------------------------------------------
| Fake Data Example, Static
|--------------------------------------------------------------------------
*/

export const UploadAttachmentRequestMockData: UploadAttachmentRequest = {
  attachmentId: '1', // optional
  chunkCheckSum: 'string',
  chunkNumber: 123,
  chunkSize: 123,
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', // optional
  file: undefined,
  fileName: 'Bobby Johnson',
  linkedItemId: 1, // optional
  linkType: AttachmentLinkType.AcademyStepAttachment, // optional
  totalChunks: 123,
  totalSize: 123,
  type: AttachmentType.File,
};
