import {useParams} from 'react-router-dom';
import {notify} from '@components/user/notifications';
import {AcademyLevelVM} from '@generated/interfaces';
import MoveStepLevelModal from './MoveStepLevelModal';
import {
  useAcademyLevelStepQuery,
  useUpdateAcademyStepsLevelMutation,
} from '@generated/hooks';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface Props {
  visible: boolean;
  onCancel: () => void;
  levels: AcademyLevelVM[];
  academyId: number;
  currentLevelId: number;
  stepId: number;
}

function MoveStepLevelModalContainer({
  visible,
  onCancel,
  levels,
  currentLevelId,
  stepId,
}: Props) {
  const {levelId} = useParams();

  const {invalidateExact: invalidateAcademyLevel} = useAcademyLevelStepQuery(
    {academyLevelId: parseInt(levelId)},
    {enabled: false}
  );

  const {mutate: updateAcademySteps} = useUpdateAcademyStepsLevelMutation({
    onError: notify.moveCustomProgramContentError,
    onSuccess: () => {
      invalidateAcademyLevel();
      onCancel();
    },
  });

  const handleUpdateAcademySteps = (newLevelId: number) => {
    updateAcademySteps({
      payload: {
        oldAcademyLevelId: currentLevelId,
        newAcademyLevelId: newLevelId,
        steps: [stepId],
      },
    });
  };

  return (
    <MoveStepLevelModal
      visible={visible}
      onCancel={onCancel}
      levels={levels}
      onOk={handleUpdateAcademySteps}
    />
  );
}

export default MoveStepLevelModalContainer;
