import {InitiativePurpose} from '@generated/enums';

export enum InitiativeStatus {
  PUBLISHED = 'published',
  DRAFT = 'draft',
}

export enum InitiativeStatusEnum {
  DRAFT,
  PUBLISHED,
}

export enum InitiativeFor {
  WHOLE_COMPANY = 'Whole Company',
  SPECIFIC_AUDIENCE = 'Specific Audience',
}

export interface Steps {
  title: string;
}

export enum ListTypes {
  PROGRAM = 'Program',
  TIME = 'Time',
  FINANCE = 'Finance',
}

export interface InitiativeListItem {
  id: number;
  title: string;
  audience: string;
  status: string;
  programs?: number;
  incentives?: number;
}

export interface PublishInitiative {
  id?: number;
  title: string;
  audience: string;
  purpose: InitiativePurpose;
  purposeDescription: string;
  programIds?: number[];
  timeIncentiveIds: number[];
  financeIncentiveIds: number[];
  status?: InitiativeStatus | InitiativeStatusEnum;
}

export enum ConfirmationOptions {
  SAVE_CHANGES = 1,
  DISCARD_CHANGES = 2,
  DELETE = 3,
}

export enum InitiativeFlowSteps {
  End = -1,
  TheBasics = 0,
  Programs = 1,
  Time = 2,
  Finance = 3,
}
