import FirstSectionContentHeader from './FirstSectionContentHeader';
import {getCompanyPersonalizationRq} from '@store/apiEndpoints/user/queries';
import {UserPersonalizationVM} from '@models/serverModels';
import {useQuery} from 'react-query';
import {simpleQueryFn} from '@store/queryClient';
import {useCustomProgramPreviewQuery} from '@generated/hooks';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface FirstSectionContentHeaderContainerProps {
  customProgramId: number;
}

function FirstSectionContentHeaderContainer({
  customProgramId,
}: FirstSectionContentHeaderContainerProps) {
  const {data: customProgram} = useCustomProgramPreviewQuery(
    {customProgramId, queryParams: {includeProgress: true}},
    {enabled: false}
  );

  const getCompanyPersonalization = getCompanyPersonalizationRq();
  const {data: company} = useQuery<UserPersonalizationVM>(
    getCompanyPersonalization.queryKey,
    () => simpleQueryFn(getCompanyPersonalization.path)
  );

  return (
    <FirstSectionContentHeader
      customProgramHeaderImgSrc={customProgram?.imageUrl}
      programTitle={customProgram?.title}
      programTags={customProgram?.skills}
      companyName={company?.companyDisplayName}
      companyLogoSrc={company?.companyLogoUrlSquare}
      programId={customProgram?.id}
    />
  );
}

export default FirstSectionContentHeaderContainer;
