import {k} from '@i18n/translate';

enum ProgramType {
  Academy = 'Academy',
  Cohort = 'Cohort',
  CustomProgram = 'CustomProgram',
}

interface ClickToJoinText {
  shareLabel?: string;
  accessView?: string;
  eligibility?: string;
  participantLinkLabel?: string;
  linkUse?: string;
}

export interface ClickToJoinDisplayConfig {
  type: ProgramType;
  text: ClickToJoinText;
  participantLink?: string;
  visible: boolean;
}

const text = {
  [ProgramType.Academy]: {
    shareLabel: k.CTA__COPY_ACADEMY_LINK,
    accessView: k.CTJ__VIEW_ACCESS,
    eligibility: k.CTJ__ACADEMY_ELIGIBILITY,
    participantLinkLabel: k.CTJ__COPY_PARTICIPANT_LINK,
    linkUse: k.CTJ__LINK_USE,
  },
  [ProgramType.Cohort]: {},
  [ProgramType.CustomProgram]: {
    shareLabel: k.PROGRAM__CUSTOM__SHARE,
    accessView: k.CTJ__VIEW_ACCESS,
    eligibility: k.CTJ__CUSTOM_PROGRAM_ELIGIBILITY,
    participantLinkLabel: k.CTJ__COPY_PARTICIPANT_LINK,
    linkUse: k.CTJ__LINK_USE,
  },
};

export function getCTJCustomProgram(visible: boolean, participantLink: string) {
  return {
    type: ProgramType.CustomProgram,
    text: text[ProgramType.CustomProgram],
    visible,
    participantLink,
  };
}

export function getCTJAcademy(visible: boolean, participantLink: string) {
  return {
    type: ProgramType.Academy,
    text: text[ProgramType.Academy],
    visible,
    participantLink,
  };
}
