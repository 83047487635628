import React from 'react';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';
import {pxToRem} from '@utils/styleMixins';

export const Container = styled.section``;

export const ItemContainer = styled.article`
  background-color: ${COLORS.Neutral100};
  padding: 12px 16px;
  border-radius: 4px;
  margin-top: 8px;
`;

export const ItemHeader = styled.div`
  color: ${COLORS.Black};
  font-size: 0.75rem;
  line-height: 22px;
  margin-bottom: 8px;
  .name {
    font-weight: 500;
  }
`;

type SpanElem = React.FC<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >
>;
export const Message: SpanElem = styled.span`
  color: ${COLORS.Neutral900};
  font-size: 1rem;
  line-height: 20px;

  h1 {
    font-size: ${pxToRem(32, 16)};
  }
  h2 {
    font-size: ${pxToRem(29, 16)};
  }
  h3 {
    font-size: ${pxToRem(20, 16)};
  }
  h4 {
    font-size: ${pxToRem(16, 16)};
  }
  h5 {
    font-size: ${pxToRem(13, 16)};
  }
`;
