import * as React from 'react';
import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {COLORS} from '@utils/constants';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.section`
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 3rem 1rem;
  justify-content: center;
  align-items: center;
`;

const Header = styled.h2`
  color: ${COLORS.Neutral950};
  font-size: ${(props: {headerSize: number}) => props.headerSize}rem;
  line-height: 1.4375rem;
  text-align: center;
  margin: 0px 0px 8px 0px;
  padding: 0;
  font-weight: 500;
`;

const SubHeader = styled.h3`
  color: ${COLORS.Neutral600};
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  font-weight: 400;
  padding: 0;
  display: block;
`;

const Wrapper = styled.span`
  margin-top: 1.5rem;
`;

/*
|--------------------------------------------------------------------------
| Text Component
|--------------------------------------------------------------------------
*/

function EmptyDataText({
  header,
  subHeader,
  style,
  headerSize = 1.25,
  jsxComponent,
}: {
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  style?: React.CSSProperties;
  headerSize?: number;
  jsxComponent?: React.ReactNode;
}) {
  return (
    <Container style={style} aria-label={i18n.t(k.GENERIC__EMPTY_STATE)}>
      <Header headerSize={headerSize}>{header}</Header>
      {!!subHeader && <SubHeader>{subHeader}</SubHeader>}
      {!!jsxComponent && <Wrapper>{jsxComponent}</Wrapper>}
    </Container>
  );
}

export default EmptyDataText;
