import {i18n, k} from '@i18n/translate';
import {Form} from 'antd';
import {
  ResourceApprovalRequest,
  UserRequestApprovalVM,
} from '@models/serverModels';
import {ApprovalStatus} from '@generated/enums';
import {filterResults} from '../Autocomplete/Autocomplete';
import {StyledSelectSearch} from '../InlineSelect/InlineSelect';
import {DownOutlined} from '@ant-design/icons';
import {CostCenterOption} from './CostCenterField.interfaces';
import {useState} from 'react';
import {AdminScreenApprovalStatus} from '@models/clientEnums';

export interface CostCenterFieldProps {
  originalRequest: UserRequestApprovalVM | ResourceApprovalRequest;
  costCenterOptions: CostCenterOption[];
  updateCostCenter: (costCenter: string, approvalId: number) => void;
  activeTab: ApprovalStatus | AdminScreenApprovalStatus;
  selectedApproval: UserRequestApprovalVM | ResourceApprovalRequest;
  selectedCostCenter?: string;
}

function CostCenterField({
  originalRequest,
  costCenterOptions,
  updateCostCenter,
  selectedApproval,
  selectedCostCenter,
}: CostCenterFieldProps) {
  const handleOnChangeCostCenter = (
    costCenter: string,
    selectedApproval: Partial<{id: number}>
  ) => {
    const requestApprovalId = selectedApproval.id;
    updateCostCenter(costCenter, requestApprovalId);
    originalRequest.costCenter = costCenter;
  };

  // Down icon behavior
  const [isActive, setActive] = useState(true);
  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <Form.Item
      className="lb-application-drawer"
      name="costCenter"
      label={i18n.t(k.COST_CENTER)}
      initialValue={selectedCostCenter}>
      <StyledSelectSearch
        showSearch
        suffixIcon={
          <DownOutlined
            // Fixes can't click suffixIcon: https://github.com/ant-design/ant-design/issues/23263#issuecomment-1079985930
            className={isActive ? 'ant-select-suffix' : ''}
            onClick={toggleClass}
          />
        }
        options={costCenterOptions}
        optionFilterProp="children"
        filterOption={filterResults}
        value={selectedCostCenter}
        style={{width: '100%'}}
        onSearch={(text) => {
          originalRequest.costCenter = text;
        }}
        onSelect={(costCenter: string) => {
          handleOnChangeCostCenter(costCenter, selectedApproval);
        }}
        data-testid="inline-select"
      />
    </Form.Item>
  );
}

export default CostCenterField;
