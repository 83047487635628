import {useEffect, useMemo, useRef, useState} from 'react';
import {i18n, k} from '@i18n/translate';
import {
  ApprovalsDiscriminator,
  ApprovalStatus,
  CurrencyCode,
  InitiativePurpose,
  PlanItemType,
} from '@generated/enums';
import useAddToPlan, {
  useDeleteFromPlanById,
  useDeleteFromPlanByResourceId,
} from '@hooks/apiEndpoints/userPlan/mutations';
import PreApprovalDrawer from '@components/user/pages/components/preApprovalDrawer/PreApprovalDrawer.container';
import {
  getClientAwareProviderName,
  isLearnInProvider,
} from '@utils/custom-client-helpers';
import {resizeIfCloudinary} from '@utils/image-utils';
import {
  getItemCTAButtonType,
  ItemCTAButtonType,
  transformApprovalResponse,
} from '@utils/itemUtils';
import {generateTagTextAndTooltip} from '@components/reusable/ItemSearchCard/RecommendationTag';
import LicenseAutoAddModal from '@blocks/licenseAutoAddModal/LicenseAutoAddModal';
import SearchCard from './SearchCard';

import {
  RequestApprovalButton,
  RequestLicenseButton,
  ResubmitApprovalButton,
  ViewDetailsButton,
  SelectProgramButton,
  SelectedButton,
  ViewLicenseButton,
} from '@components/reusable/Button/LearnInButtons';
import {
  getFinanceIncentiveDetailRq,
  getManagerUserRequestApprovalsRq,
  getTimeIncentiveDetailRq,
} from '@store/apiEndpoints';
import {getProgramDetailsRq} from '@store/apiEndpoints/program/queries';
import {useQuery} from 'react-query';
import {
  ApprovalRequestDetailsVM,
  FinanceDetailsVM,
  ProgramDetailsVM,
  TimeDetailsVM,
  UserRequestDetailsVM,
} from '@models/serverModels';
import {simpleQueryFn} from '@store/queryClient';
import PlanItemDetailPage from '@components/reusable/PlanItemDetailPage/PlanItemDetailPage.container';
import DeleteProgramItemModal from '@blocks/deleteProgramItemModal/DeleteProgramItemModal';
import LicenseViewerModal from '@blocks/licenseViewerModal/LicenseViewerModal.container';
import DeleteLicensedOrCustomProgramItemModal from '@blocks/deleteLicensedOrCustomProgramItemModal/DeleteLicensedOrCustomProgramItemModal';
import {UserPlanItemStatusString} from '@models/clientEnums';
import {useUserPlanFinanceLearningBudgetOverviewVMQuery} from '@hooks/apiEndpoints/userPlan/queries';
import {
  formatCurrency,
  getCurrencyEnum,
  useLocalizedAmountDisclaimer,
  MONEY_DEFAULT,
} from '@utils/moneyUtils';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import ActionButton from '@blocks/actionButton/ActionButton';
import {
  MarketplaceProgramCardCta,
  trackMarketplaceProgramCardButtonClick,
} from '@utils/analytics';
import useIsInViewport from '@hooks/useIsInViewport';
import {
  useGetCompanyPersonalizationQuery,
  useGetUserQuery,
} from '@hooks/apiEndpoints/user/queries';
import {useExchangeRate} from '@hooks/apiEndpoints/localization/queries';
import {getProgramDetailPath} from '@utils/ClientPaths';
import {useNavigate} from 'react-router-dom';
import useFeatureFlags from '@hooks/useFeatureFlags';

/*
|--------------------------------------------------------------------------
| Container Component
|--------------------------------------------------------------------------
*/

export interface IMarketplaceProgramSearchCardContainerProps {
  approvalRequired?: boolean;
  approvalRequested?: boolean;
  description: string;
  initiativePurposes?: string;
  provider: string;
  previewImgSrc: string;
  title: string;
  selected: boolean;
  id: number;
  forAdminInitiative?: boolean;
  providerLogo: string;
  cost: number;
  currency?: CurrencyCode | string;
  licenseId?: number;
  daysUntilExpiration?: number;
  itemType: PlanItemType;
  purposeDescriptions?: Record<InitiativePurpose, Set<string>>;
  financeIncentiveType?: string;
  notAvailable?: boolean;
  metadata?: string[];
  approvedAmount: number;
  planStatus?: UserPlanItemStatusString;
  trackView?: (programInfo: {
    id: number;
    title: string;
    cost: number;
    provider: string;
  }) => void;
}

function MarketplaceProgramSearchCardContainer({
  approvalRequired,
  approvalRequested,
  description,
  initiativePurposes,
  provider,
  previewImgSrc,
  title,
  selected,
  id,
  forAdminInitiative,
  providerLogo,
  cost,
  currency,
  licenseId,
  daysUntilExpiration,
  itemType,
  purposeDescriptions,
  financeIncentiveType,
  notAvailable,
  metadata,
  approvedAmount,
  planStatus,
  trackView,
}: IMarketplaceProgramSearchCardContainerProps) {
  const [prevFocusedElem, setPrevFocusedElem] = useState(null);
  const [_selected, setSelected] = useState(selected);
  const [_approvalRequested, setApprovalRequested] = useState(
    planStatus === UserPlanItemStatusString.Pending || approvalRequested
  );
  const [_rejectedRequest, setRejectedRequest] = useState(
    planStatus === UserPlanItemStatusString.Rejected
  );
  const [showApprovalDrawer, setShowApprovalDrawer] = useState(false);
  const [showRequestLicenseDrawer, setShowRequestLicenseDrawer] =
    useState(false);
  const [showViewLicenseModal, setShowViewLicenseModal] = useState(false);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [showAutoAddLicenseModal, setShowAutoAddLicenseModal] = useState(false);
  const [isRemoveProgramModalVisible, setIsRemoveProgramModalVisible] =
    useState(false);
  const [
    isRemoveLicensedProgramModalVisible,
    setIsRemoveLicensedProgramModalVisible,
  ] = useState(false);
  const {data: companyPersonalization} = useGetCompanyPersonalizationQuery();
  const [hasViewBeenTracked, setHasViewBeenTracked] = useState(false);

  const cardRef = useRef();
  const isInViewport = useIsInViewport(cardRef);
  const navigate = useNavigate();

  const {isFeatureFlagOn} = useFeatureFlags();
  const isSunsetInitiativesOn = isFeatureFlagOn.SunsetInitiatives;

  useEffect(() => {
    if (!!trackView && isInViewport && !hasViewBeenTracked) {
      trackView(analyticsProgramInfo);
      setHasViewBeenTracked(true);
    }
  }, [isInViewport]);

  // Queries to refresh card data
  const updateCardState = (data) => {
    if (!!licenseId) {
      if (data?.status === UserPlanItemStatusString.Rejected) {
        setRejectedRequest(true);
      } else if (data?.status === UserPlanItemStatusString.Pending) {
        setRejectedRequest(false);
        setApprovalRequested(true);
      }
    } else {
      setSelected(data?.selected);
      setApprovalRequested(data?.approvalRequested);
    }
  };

  // Note: The following queries have enabled set to false so that they do not run when the card is rendered, but only be calling refetch
  const programDetailsRq = getProgramDetailsRq(id);
  const getProgramDetailsQuery = useQuery<ProgramDetailsVM>(
    programDetailsRq.queryKey,
    () => simpleQueryFn(programDetailsRq.path),
    {
      enabled: false,
      onSuccess: updateCardState,
      refetchOnWindowFocus: false,
    }
  );

  const handleSettingsActions = useMemo(() => {
    return () => {
      // do nothing
    };
  }, []);

  const {activePlbExists} = useUserPlanFinanceLearningBudgetOverviewVMQuery();

  const deleteFromPlanByResourceIdMutation = useDeleteFromPlanByResourceId({
    onFinished: getProgramDetailsQuery.refetch,
  });

  const financeIncentiveDetailsRq = getFinanceIncentiveDetailRq(id);
  const getFinanceIncentiveDetailQuery = useQuery<FinanceDetailsVM>(
    financeIncentiveDetailsRq.queryKey,
    () => simpleQueryFn(financeIncentiveDetailsRq.path),
    {
      enabled: false,
      onSuccess: updateCardState,
      refetchOnWindowFocus: false,
    }
  );

  const timeIncentiveDetailsRq = getTimeIncentiveDetailRq(id);
  const getTimeIncentiveDetailQuery = useQuery<TimeDetailsVM>(
    timeIncentiveDetailsRq.queryKey,
    () => simpleQueryFn(timeIncentiveDetailsRq.path),
    {
      enabled: false,
      onSuccess: updateCardState,
      refetchOnWindowFocus: false,
    }
  );
  const managerApprovalsProgramRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminator.Program,
    ApprovalStatus.Requested
  );
  const getManagerApprovalsQueryProgram = useQuery<ApprovalRequestDetailsVM[]>(
    managerApprovalsProgramRq.queryKey,
    () => simpleQueryFn(managerApprovalsProgramRq.path, JSON.parse)
  );

  const managerUserRequestApprovalsRq = getManagerUserRequestApprovalsRq(
    ApprovalsDiscriminator.License,
    ApprovalStatus.Requested
  );
  const getManagerUserRequestApprovalsQuery = useQuery<UserRequestDetailsVM[]>(
    managerUserRequestApprovalsRq.queryKey,
    () =>
      simpleQueryFn(
        managerUserRequestApprovalsRq.path,
        transformApprovalResponse
      )
  );

  const refreshData = () => {
    switch (itemType) {
      case PlanItemType.Program:
        getManagerApprovalsQueryProgram.refetch();
        getManagerUserRequestApprovalsQuery.refetch();
        getProgramDetailsQuery.refetch();
        break;
      case PlanItemType.Finance:
        return getFinanceIncentiveDetailQuery.refetch();
      case PlanItemType.Time:
        return getTimeIncentiveDetailQuery.refetch();
    }
  };

  const deletePlanItemByIdMutation = useDeleteFromPlanById({});

  // Handle Add to Plan
  const addToPlanMutation = useAddToPlan({
    onSuccess: () => {
      refreshData();
    },
  });

  const analyticsProgramInfo = {
    id,
    title,
    cost,
    provider,
  };

  const handleClickAddToPlan = () => {
    trackMarketplaceProgramCardButtonClick(
      analyticsProgramInfo,
      MarketplaceProgramCardCta.AddToPlan
    );
    addToPlanMutation.mutateAsync({
      optionId: id,
      planItemType: itemType,
    });
  };

  // Handle Request License
  const handleClickRequestLicense = () => {
    trackMarketplaceProgramCardButtonClick(
      analyticsProgramInfo,
      MarketplaceProgramCardCta.RequestLicense
    );
    setShowRequestLicenseDrawer(true);
  };

  // Handle View License
  const handleClickViewLicense = () => {
    trackMarketplaceProgramCardButtonClick(
      analyticsProgramInfo,
      MarketplaceProgramCardCta.ViewLicense
    );
    setShowViewLicenseModal(true);
  };

  // Handle Request Approval
  const handleClickRequestApproval =
    (buttonType: MarketplaceProgramCardCta) => () => {
      trackMarketplaceProgramCardButtonClick(analyticsProgramInfo, buttonType);
      setShowApprovalDrawer(true);
    };

  // Handle See Details
  const handleClickSeeDetails = () => {
    trackMarketplaceProgramCardButtonClick(
      analyticsProgramInfo,
      MarketplaceProgramCardCta.SeeDetails
    );
    navigate(getProgramDetailPath(id));
    setPrevFocusedElem(document.activeElement);
  };

  // Handle Click Selected
  const handleRemoveProgramModalOk = () => {
    refreshData();
    setApprovalRequested(false);
  };

  const handleClickSelected = async () => {
    trackMarketplaceProgramCardButtonClick(
      analyticsProgramInfo,
      MarketplaceProgramCardCta.Selected
    );
    if (!activePlbExists) {
      await deleteFromPlanByResourceIdMutation.mutateAsync({
        resourceId: id,
        itemType: itemType,
      });
    } else {
      isLicenseProgram
        ? setIsRemoveLicensedProgramModalVisible(true)
        : setIsRemoveProgramModalVisible(true);
    }
  };

  // Approval Drawer event handlers
  const handleApprovalDrawerOk = () => {
    setApprovalRequested(true);
    setShowApprovalDrawer(false);
    setShowRequestLicenseDrawer(false);
    refreshData();
  };

  const handleApprovalDrawerCancel = () => {
    setShowApprovalDrawer(false);
  };

  const handleLicenseDrawerCancel = () => {
    setShowRequestLicenseDrawer(false);
  };

  const handleLicenseDrawerOk = () => {
    setApprovalRequested(true);
    setShowRequestLicenseDrawer(false);
    refreshData();
  };

  // Detail Page event handlers
  const handleCloseDetailPage = () => {
    setShowDetailsPage(false);
    if (prevFocusedElem) {
      prevFocusedElem.focus();
    }
    refreshData();
  };

  const _provider = getClientAwareProviderName(
    companyPersonalization?.companyDisplayName,
    provider
  );

  const providerImgSrc = (() => {
    const _isLearnInProvider = isLearnInProvider(provider);
    if (_isLearnInProvider && !!companyPersonalization?.companyLogoUrlSquare)
      return companyPersonalization?.companyLogoUrlSquare;
    if (typeof providerLogo === 'string')
      return resizeIfCloudinary(providerLogo, null, 98);
  })();

  const isLicenseProgram = licenseId > 0;
  const currencyEnum = getCurrencyEnum(currency);
  const getUserQuery = useGetUserQuery();
  const rateForUser = useExchangeRate(
    currencyEnum,
    getUserQuery.data?.currency
  );

  const exchangeRate = !isNaN(currencyEnum)
    ? rateForUser
    : MONEY_DEFAULT.exchangeRate;

  const tooltip = useLocalizedAmountDisclaimer({
    amount: cost,
    localizedAmount: formatCurrency(
      cost,
      getUserQuery.data?.currency,
      exchangeRate
    ),
    sourceCurrency: currencyEnum,
    usersCurrency: getUserQuery.data?.currency,
  });

  // Get Top Right Header Display
  const headerData = (() => {
    switch (itemType) {
      case PlanItemType.Program:
        return {
          title: i18n.t(k.MONEY__TOTAL_COST),
          value: formatCurrency(
            cost,
            getUserQuery.data?.currency,
            currencyEnum === getUserQuery.data?.currency
              ? MONEY_DEFAULT.exchangeRate
              : exchangeRate
          ),
          tooltip,
        };
      case PlanItemType.Finance:
        return {
          title: i18n.t(k.GENERIC__AMOUNT),
          value: !!cost
            ? formatCurrency(cost, getUserQuery.data?.currency, exchangeRate)
            : i18n.t(k.GENERIC__ANY),
        };
      case PlanItemType.Time:
        return null;
    }
  })();

  const recommendationTag = (() => {
    if (!initiativePurposes || isSunsetInitiativesOn) return null;
    return generateTagTextAndTooltip(initiativePurposes, purposeDescriptions);
  })();

  // CTA Button
  const buttons = (() => {
    const ctaButtonType = getItemCTAButtonType({
      approvalRequested: _approvalRequested,
      approvalRequired,
      approvalReceived: planStatus === UserPlanItemStatusString.Approved,
      approvalRejected: _rejectedRequest,
      itemType,
      cost,
      selected: _selected,
      isLicenseProgram,
      daysUntilExpiration,
      licenseId: licenseId,
      forAdminInitiative,
    });
    const buttons = [];
    switch (ctaButtonType) {
      case ItemCTAButtonType.AddToInitiative:
        // To re-implement later when this card is used for initiatives
        break;
      case ItemCTAButtonType.AddToPlan:
        buttons.push(
          <SelectProgramButton
            key="addToPlan"
            onClick={handleClickAddToPlan}
            disabled={notAvailable}
          />
        );
        break;
      case ItemCTAButtonType.RequestLicense:
        buttons.push(
          <RequestLicenseButton
            title={title}
            key="requestLicense"
            onClick={handleClickRequestLicense}
            disabled={notAvailable}
          />
        );
        break;
      case ItemCTAButtonType.ViewLicense:
        buttons.push(
          <ViewLicenseButton
            aria-label={i18n.t(k.A11Y__VIEW_ITEM__FORMAT, {item: title})}
            key="viewLicense"
            onClick={handleClickViewLicense}
            disabled={notAvailable}
          />
        );
        break;
      case ItemCTAButtonType.RequestApproval:
        buttons.push(
          <RequestApprovalButton
            title={title}
            key="requestApproval"
            onClick={handleClickRequestApproval(
              MarketplaceProgramCardCta.RequestApproval
            )}
            disabled={notAvailable}
          />
        );
        break;
      case ItemCTAButtonType.Requested:
        buttons.push(
          <LearnInButton disabled key="requested" tag={ButtonTags.Primary}>
            Requested
          </LearnInButton>
        );
        break;
      case ItemCTAButtonType.Selected:
        buttons.push(
          <SelectedButton
            key="selected"
            onClick={handleClickSelected}
            disabled={notAvailable}
          />
        );
        break;
      case ItemCTAButtonType.Resubmit:
        buttons.push(
          <ResubmitApprovalButton
            key="resubmitApproval"
            onClick={handleClickRequestApproval(
              MarketplaceProgramCardCta.ResubmitApproval
            )}
            disabled={notAvailable}
          />
        );
        break;
      case ItemCTAButtonType.Rejected:
        buttons.push(
          <ActionButton
            item={{
              ...getProgramDetailsQuery.data,
              programApprovalRequired: approvalRequired,
              itemType,
              status: planStatus,
            }}
            itemType={itemType}
            handleShowRequestLicenseDrawer={setShowRequestLicenseDrawer}
            onSettings={handleSettingsActions}
          />
        );
        break;
    }
    return [
      ...buttons,
      <ViewDetailsButton
        title={title}
        key="seeDetails"
        onClick={handleClickSeeDetails}
        disabled={notAvailable}
      />,
    ];
  })();

  return (
    <div ref={cardRef}>
      <SearchCard
        id={id}
        buttons={buttons}
        description={description}
        headerData={headerData}
        metadata={metadata}
        previewImgSrc={previewImgSrc}
        provider={_provider}
        providerImgSrc={providerImgSrc}
        recommendationTag={recommendationTag}
        title={title}
        financeIncentiveType={financeIncentiveType}
        notAvailable={notAvailable}
      />
      {showApprovalDrawer && (
        <PreApprovalDrawer
          itemId={id}
          planItemType={itemType}
          currency={getCurrencyEnum(currency)}
          visible={showApprovalDrawer}
          onOk={handleApprovalDrawerOk}
          onCancel={handleApprovalDrawerCancel}
        />
      )}
      {showDetailsPage && (
        <PlanItemDetailPage
          id={id}
          visible={showDetailsPage}
          onClose={handleCloseDetailPage}
          itemType={itemType}
          setRejected={setRejectedRequest}
          setRequested={setApprovalRequested}
          rejected={_rejectedRequest}
          requested={_approvalRequested}
        />
      )}
      {showViewLicenseModal && (
        <LicenseViewerModal
          provider={_provider}
          licenseId={licenseId}
          programId={id}
          onClose={() => {
            setShowViewLicenseModal(false);
          }}
        />
      )}
      {showRequestLicenseDrawer && (
        <PreApprovalDrawer
          visible={showRequestLicenseDrawer}
          itemId={id}
          planItemType={itemType}
          currency={getCurrencyEnum(currency)}
          onOk={handleLicenseDrawerOk}
          onCancel={handleLicenseDrawerCancel}
          licenseId={licenseId}
        />
      )}
      {showAutoAddLicenseModal && (
        <LicenseAutoAddModal
          isVisible={showAutoAddLicenseModal}
          setIsVisible={setShowAutoAddLicenseModal}
        />
      )}
      {isRemoveProgramModalVisible && (
        <DeleteProgramItemModal
          isVisible={isRemoveProgramModalVisible}
          setIsVisible={setIsRemoveProgramModalVisible}
          programId={id}
          programCurrency={getCurrencyEnum(currency)}
          onOk={handleRemoveProgramModalOk}
          approvedAmount={approvedAmount}
        />
      )}
      {isRemoveLicensedProgramModalVisible && (
        <DeleteLicensedOrCustomProgramItemModal
          isVisible={isRemoveLicensedProgramModalVisible}
          setIsVisible={setIsRemoveLicensedProgramModalVisible}
          itemId={id}
          onOk={async () => {
            await deletePlanItemByIdMutation.mutateAsync({
              itemId: id,
              itemType: PlanItemType.Program,
            });
          }}
        />
      )}
    </div>
  );
}

export default MarketplaceProgramSearchCardContainer;
