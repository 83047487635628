import {i18n, k} from '@i18n/translate';
import styled from 'styled-components';
import {LearnInModal} from '@components/reusable/Modal/Modal.style';
import {ModalTags} from '@components/reusable/Modal/ModalEnums';
import {FormInstance} from 'antd/es/form';
import {Form} from 'antd';
import {LearnInButton} from '@components/reusable/Button/Button.style';
import {ButtonTags} from '@components/reusable/Button/ButtonEnums';
import {COLORS} from '@utils/constants';
import {AcademyStepRequirementProperty} from '@generated/enums';

/*
|--------------------------------------------------------------------------
| Styled Components
|--------------------------------------------------------------------------
*/

const Container = styled.div`
  padding: 0px 16px;
`;

const RemoveButtonContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/

export enum AddEditStepRequirementFormNames {
  RequiredId = 'requiredId',
}

export interface AddEditStepRequirementFormFields {
  [AddEditStepRequirementFormNames.RequiredId]: number;
  requirementProperty: AcademyStepRequirementProperty;
}

export interface AddEditStepRequirementModalProps {
  form: FormInstance<AddEditStepRequirementFormFields>;
  onCancel: () => void;
  onClickRemove: () => void;
  onOk: () => void;
  options: {id: number; title: string}[];
  showRemoveRequirementButton: boolean;
  title: string;
  selectInputLabel: string;
  fieldsComponent: React.ReactNode;
}

function AddEditStepRequirementModal({
  form,
  onCancel,
  onClickRemove,
  onOk,
  showRemoveRequirementButton,
  title,
  fieldsComponent,
}: AddEditStepRequirementModalProps) {
  return (
    <LearnInModal
      title={title}
      tag={ModalTags.ConnectCalendar}
      width={600}
      onCancel={onCancel}
      onOk={onOk}
      visible={true}
      cancelButtonChild={i18n.t(k.CTA__CANCEL)}
      okButtonChild={i18n.t(k.LEVEL__REQUIREMENT__SAVE)}
      okButtonProps={{style: {marginLeft: '8px'}}}
      footerLeft={
        showRemoveRequirementButton && (
          <RemoveButtonContainer>
            <LearnInButton
              noPadding
              color={COLORS.Red800}
              tag={ButtonTags.DestructiveRemovedBorder}
              onClick={onClickRemove}>
              {i18n.t(k.LEVEL__REQUIREMENT__REMOVE)}
            </LearnInButton>
          </RemoveButtonContainer>
        )
      }>
      <Container>
        <Form form={form} layout="vertical">
          {fieldsComponent}
        </Form>
      </Container>
    </LearnInModal>
  );
}

export default AddEditStepRequirementModal;
