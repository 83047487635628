import {LanguagePreference} from '@generated/enums';
import i18next from 'i18next';

export default function useLanguagePreference(): LanguagePreference {
  // Future improvement: Right now, we have to update this file each time a new language is added. However, the relationship between i18next.language and our database's enum is stored in LANGUAGE (@utils/l10nUtils), which can allow us to dynamically generate the return, rather than hardcode it.

  switch (i18next.language?.toLowerCase()) {
    case 'en-us':
      return LanguagePreference.EN_US;
    case 'fr-ca':
      return LanguagePreference.FR_CA;
    case 'es-419':
      return LanguagePreference.ES_419;
    case 'pt-br':
      return LanguagePreference.PT_BR;
    case 'de-de':
      return LanguagePreference.DE_DE;
    case 'en-gb':
      return LanguagePreference.EN_GB;
    case 'fr-fr':
      return LanguagePreference.FR_FR;
    case 'ja-jp':
      return LanguagePreference.JA_JP;
    case 'zh-cn':
      return LanguagePreference.ZH_CN;
    case 'da_dk':
      return LanguagePreference.DA_DK;
    case 'es_es':
      return LanguagePreference.ES_ES;
    case 'it_it':
      return LanguagePreference.IT_IT;
    case 'ko_kr':
      return LanguagePreference.KO_KR;
    case 'nl_nl':
      return LanguagePreference.NL_NL;
    case 'pl_pl':
      return LanguagePreference.PL_PL;
    case 'ru_ru':
      return LanguagePreference.RU_RU;
    case 'sv_se':
      return LanguagePreference.SV_SE;
    default:
      return LanguagePreference.EN_US;
  }
}
